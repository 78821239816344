import { Box, HStack, Text } from "@chakra-ui/react";
import { isAfter, isBefore } from "date-fns";
import { isEmpty } from "lodash";
import { ReactNode, useMemo } from "react";
import { SegmentEventType } from "../../../../../../../../common/types/connection";
import { FormatDate } from "../../../../../../../../components/DateTimeRangeFilter";

function DataWithLabel({
  label,
  value,
}: {
  label: string;
  value: string | ReactNode;
}) {
  return (
    <Box>
      <Text fontSize="12px" color="text.50" fontWeight="500">
        {label}
      </Text>
      {typeof value === "string" ? <Text fontSize="14px">{value}</Text> : value}
    </Box>
  );
}

export default function AccordionDatePanel({
  segmentEvent,
  segmentEventPropertyKeys,
}: {
  segmentEvent: SegmentEventType;
  segmentEventPropertyKeys: string[];
}) {
  const segmentEventProperties = segmentEvent?.properties;

  const maxCount = useMemo(() => {
    let maxValue = 0;
    if (segmentEvent.count || segmentEvent.count === 0) {
      maxValue = segmentEvent.count;
    } else if (!isEmpty(segmentEventProperties)) {
      segmentEventPropertyKeys.forEach((key) => {
        if (segmentEventProperties[key].count > maxValue) {
          maxValue = segmentEventProperties[key].count;
        }
      });
    }
    return maxValue;
  }, [segmentEvent.count, segmentEventProperties, segmentEventPropertyKeys]);

  const lastSeen = useMemo(() => {
    let latestDate = "";
    if (segmentEvent.last_seen) {
      latestDate = segmentEvent.last_seen;
    } else if (!isEmpty(segmentEventProperties)) {
      latestDate =
        segmentEventProperties[segmentEventPropertyKeys[0]].last_seen;

      segmentEventPropertyKeys.forEach((key) => {
        if (
          isAfter(
            new Date(segmentEventProperties[key].last_seen),
            new Date(latestDate)
          )
        ) {
          latestDate = segmentEventProperties[key].last_seen;
        }
      });
    }
    return latestDate;
  }, [
    segmentEvent.last_seen,
    segmentEventProperties,
    segmentEventPropertyKeys,
  ]);

  const firstSeen = useMemo(() => {
    let earliestDate = "";
    if (segmentEvent.first_seen) {
      earliestDate = segmentEvent.first_seen;
    } else if (!isEmpty(segmentEventProperties)) {
      earliestDate =
        segmentEventProperties[segmentEventPropertyKeys[0]].first_seen;

      segmentEventPropertyKeys.forEach((key) => {
        if (
          isBefore(
            new Date(segmentEventProperties[key].first_seen),
            new Date(earliestDate)
          )
        ) {
          earliestDate = segmentEventProperties[key].first_seen;
        }
      });
    }
    return earliestDate;
  }, [
    segmentEvent.first_seen,
    segmentEventProperties,
    segmentEventPropertyKeys,
  ]);

  return (
    <HStack
      bg="blackAlpha.50"
      py={6}
      px={4}
      fontSize="sm"
      width="100%"
      spacing={10}
      borderRadius="md"
    >
      <DataWithLabel
        label="First seen"
        value={<FormatDate date={firstSeen} showTime />}
      />
      <DataWithLabel
        label="Last seen"
        value={<FormatDate date={lastSeen} showTime />}
      />
      <DataWithLabel label="Number of occurrences" value={maxCount} />
    </HStack>
  );
}
