import { useState, useCallback, useRef, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { useChakraColors } from "../../common/hooks/commonHooks";
import {
  ReactSelectDefaultOptionsType,
  DropdownPaginatedListType,
} from "../../common/types/common";
import { Text, Box } from "@chakra-ui/react";
import { debounce } from "lodash";
import { SelectInstance } from "react-select";

export default function AsyncSearchableDropdown({
  value,
  paginatedData,
  onChange,
  validateText,
  loadOptions,
  invalidMsg,
  isReadOnly,
}: {
  value: string;
  onChange: (val: string) => void;
  paginatedData: DropdownPaginatedListType;
  loadOptions: (
    searchKeyword: string,
    pageNo: number,
    isFilterApplied?: boolean
  ) => void;
  validateText?: (val: string) => string;
  invalidMsg?: string;
  isReadOnly?: boolean;
}) {
  const { gray100, gray200, gray300, gray400, gray800, red500, text200 } =
    useChakraColors();

  const { options, page, loadingList, totalPageCount } = paginatedData;
  const [inputValue, setInputValue] = useState(value);

  //control focus of the component
  const creatableRef =
    useRef<SelectInstance<ReactSelectDefaultOptionsType | null>>(null);

  const searchIfValid = useCallback(
    (search: string) => {
      loadOptions(search, 1, true);
    },
    [loadOptions]
  );
  const debounceSearch = useRef(debounce(searchIfValid, 1000)).current;

  //validate and change the input after user types
  const delayInputChange = useMemo(() => debounce(setInputValue, 1000), []);

  const validateOnChange = useCallback(
    (val: string) => {
      const validatedTxt = validateText?.(val) ?? val;
      onChange(validatedTxt);
      delayInputChange(validatedTxt);
      debounceSearch(validatedTxt);
    },
    [validateText, onChange, delayInputChange, debounceSearch]
  );

  //useRef to avoid re-rendering
  const debounceSetValue = useMemo(
    () => debounce(validateOnChange, 1000),
    [validateOnChange]
  );

  function loadOptionsOnFocus() {
    if (!isReadOnly && options === null) {
      loadOptions(value, 1);
    }
  }

  function onInputValueChange(val: string) {
    delayInputChange.cancel();
    setInputValue(val);
    debounceSearch(val);

    // debounce to avoid re-rendering
    if (val) {
      debounceSetValue(val);
    } else {
      debounceSetValue.cancel();
      onChange(val);
    }
  }

  function onValueSelect(val: string) {
    delayInputChange.cancel();
    setInputValue(val);
    debounceSetValue.cancel();
    onChange(val);

    if (creatableRef.current && creatableRef.current.inputRef) {
      creatableRef.current.blur();
      creatableRef.current.inputRef.select();
    }
  }

  function onBottomReached() {
    if (!loadingList && page + 1 <= (totalPageCount ?? 1)) {
      loadOptions(inputValue, page + 1, false);
    }
  }

  return (
    <>
      <Text fontSize="14px" hidden={!isReadOnly}>
        {value}
      </Text>

      <Box minW="180px" flex="1" hidden={isReadOnly}>
        <CreatableSelect
          ref={creatableRef}
          onFocus={loadOptionsOnFocus}
          options={options ?? []}
          isLoading={loadingList}
          value={{
            label: inputValue,
            value: inputValue,
          }}
          onChange={(option) => {
            onValueSelect(option?.value ?? "");
          }}
          inputValue={inputValue ?? ""}
          onInputChange={(val, { action }) => {
            if (action === "input-change") {
              onInputValueChange(val ?? "");
            }
          }}
          noOptionsMessage={() => null}
          onMenuScrollToBottom={onBottomReached}
          placeholder=""
          components={{
            DropdownIndicator: null,
          }}
          isValidNewOption={() => {
            return false;
          }}
          styles={{
            control: (base, state) => ({
              ...base,
              height: "32px",
              minHeight: "32px",
              borderColor: invalidMsg ? red500 : gray200,
              boxShadow: invalidMsg ? `0 0 0 1px ${red500}` : "",
              fontSize: "14px",
              cursor: state.isDisabled ? "not-allowed" : "text",
              opacity: state.isDisabled ? 0.6 : 1,
              backgroundColor: state.isDisabled
                ? "hsl(0, 10%, 100%)"
                : "rgb(255,255,255)",
              borderRadius: "6px",
              color: state.isDisabled ? text200 : gray800,
            }),

            option: (base, state) => ({
              ...base,
              fontSize: "14px",
              color: state.isDisabled ? gray400 : gray800,
              backgroundColor: state.isSelected
                ? gray300
                : state.isFocused
                ? gray100
                : "white",
              cursor: "pointer",
              "&:hover": {
                backgroundColor:
                  state.isSelected || state.isDisabled ? undefined : gray100,
              },
              whiteSpace: "nowrap",
            }),
            menu: (base) => ({
              ...base,
              zIndex: 999,
              minWidth: "max-content",
              position: "absolute",
            }),
            menuList: (base) => ({
              ...base,
              "::-webkit-scrollbar": {
                width: "6px",
              },
              "::-webkit-scrollbar-track": {
                background: "white",
              },
              "::-webkit-scrollbar-thumb": {
                background: gray200,
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: gray300,
              },
            }),
            singleValue: (base, state) => ({
              ...base,
              color: state.isDisabled ? text200 : gray800,
              opacity: state.isDisabled ? 0.8 : 1,
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 1450,
            }),
          }}
          isSearchable
        />
      </Box>
    </>
  );
}
