import { CONNECTOR, DynamicListChildL2, TYPE } from "../types/campaign";
import { FILTER_TYPE, PROPERTY_FILTER_ID } from "./campaign";

export const EMAIL_FIELD_KEY = "email";

export enum MAPPING_RULES {
  ALWAYS_OVERWRITE = "ALWAYS_OVERWRITE",
  UPDATE_IF_EMPTY = "UPDATE_IF_EMPTY",
}

export const FORM_IS_OPERATOR = "form_is_equal";

export const FORM_IS_NOT_OPERATOR = "form_is_not_equal";

export const FORM_FIELD_PROPERTY_GROUP_CHILD: DynamicListChildL2 = {
  type: TYPE.EXPRESSION,
  filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
  filter: PROPERTY_FILTER_ID.FORM_FIELD,
  property: null,
  operator: null,
  value: [""],
  connector: CONNECTOR.AND,
};
