import { Text } from "@chakra-ui/react";

export function DomainDeleteModalContent({
  domainName,
  isSubdomain = false,
}: {
  domainName: string;
  isSubdomain?: boolean;
}) {
  const subdomainText = isSubdomain ? "subdomain" : "domain";
  return (
    <>
      <Text fontSize="14px" fontWeight={500}>
        Are you sure you want to remove{" "}
        <Text as="span" color="blackAlpha.900" fontWeight={800}>
          {domainName}
        </Text>{" "}
        {subdomainText} from the tracking list?
      </Text>
      <Text fontSize="14px" color="blackAlpha.700" pt={2}>
        This action will stop all tracking activities for this {subdomainText}.
        <br />
        Click 'Remove' to confirm or 'Cancel' to keep it.
      </Text>
    </>
  );
}
