import {
  Box,
  Center,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import WidgetContainer from "../WidgetContainer";
import { useMemo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  SF_CAMPAIGN_CONTEXT_TYPE,
  SF_CAMPAIGN_SELECTION_TYPE,
  SalesforceCampaignSyncDrawerData,
  FlowSfCampaignSyncActionOptions,
  SF_CAMPAIGN_PERSON_CONTEXT,
} from "../../../../../../common/types/campaign";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { useAppDispatch } from "../../../../../../store";
import inflectionToSalesforce from "../../../../../../common/img/salesforceToInflection.png";
import SalesforceCampaignSyncDrawer from "./SalesforceCampaignSyncDrawer";
import {
  isFinished,
  isInit,
  isSuccess,
} from "../../../../../../common/helper/commonHelper";
import DwCallout from "../../../../../../common/img/connections.svg";
import SpinnerContainer from "../../../../../../components/SpinnerContainer";
import { LOADING_STATES } from "../../../../../../common/constants/common";
import {
  isResolveContextType,
  isResolveDirectType,
} from "../../../../../../common/helper/salesforceLegacyServiceHelper";
import {
  getPersonMappingColumns,
  getSalesforceCampaigns,
  getSalesforceCampaignStatus,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";
import { useSalesforceConnectionList } from "../../../../../../common/hooks/salesforce";

const OPTION_DETAILS = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SALESFORCE_CAMPAIGN];

function NoSalesforceConnectionWarning() {
  return (
    <Center h="120px" rounded="sm" bg="grayV2.100">
      <VStack spacing="10px">
        <Image h="40px" src={DwCallout} />
        <Text fontSize="sm" fontWeight="bold" color="grayV2.500">
          You have no Salesforce connections. Connect to get started.
        </Text>
      </VStack>
    </Center>
  );
}

// NOTE: Adding empty states even though it is inaccessible by current flow, to accomodate empty state in existing journeys
function ClickToSetupCta({ onOpen }: { onOpen: () => void }) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onOpen}
      emptyStateDetails={{
        icon: inflectionToSalesforce,
        header: "Click to set up the flow-step",
      }}
    />
  );
}

function ClickToEditCta({ data }: { data: SalesforceCampaignSyncDrawerData }) {
  const dispatch = useAppDispatch();

  const {
    salesforceCampaigns: { personMappingColumns, campaigns, campaignStatus },
  } = useSelector(selectFlow);

  useEffect(() => {
    if (isInit(campaignStatus.loading)) {
      dispatch(getSalesforceCampaignStatus());
    }
    if (isInit(campaigns.loading)) {
      dispatch(getSalesforceCampaigns());
    }
    if (isInit(personMappingColumns.loading)) {
      dispatch(getPersonMappingColumns());
    }
  }, [
    dispatch,
    campaignStatus.loading,
    campaigns.loading,
    personMappingColumns.loading,
  ]);

  function findCampaignName(id: string | null) {
    return campaigns.data.find((x) => x.campaignId === id)?.campaignName ?? id;
  }

  function findFieldName(name: string | null) {
    return (
      personMappingColumns.data.find((x) => x.name === name)?.display ?? name
    );
  }

  return (
    <VStack alignItems="flex-start" p="10px">
      {data.campaignIdSource === SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_CONTEXT ? (
        <>
          <Text fontWeight="bold" fontSize="sm" color="text.50">
            Add Contact to Salesforce Campaign from Contact Field
          </Text>
          <SpinnerContainer loading={!isFinished(personMappingColumns.loading)}>
            <Text fontSize="sm" color="text.50">
              <Text as="span">
                Members will be added to the Salesforce campaigns defined by ID
                in the Inflection contact’s
              </Text>
              <Text fontWeight="semibold" as="span" mx="3px">
                {findFieldName(data.personField)}
              </Text>
              <Text as="span">field</Text>
            </Text>
          </SpinnerContainer>
        </>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="sm" color="text.50">
            Add Contact to an Existing Salesforce Campaign
          </Text>
          <SpinnerContainer loading={!isFinished(LOADING_STATES.SUCCESS)}>
            <Text fontSize="sm" color="text.50">
              <Text as="span">
                Members will be added to the Salesforce campaign
              </Text>
              <Text as="span" fontWeight="semibold" mx="3px">
                {findCampaignName(data.salesforceCampaign)}
              </Text>
            </Text>
          </SpinnerContainer>
        </>
      )}
    </VStack>
  );
}

export default function SalesforceCampaignSyncWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { data: sfConnectionList, loading: sfConnectionListLoading } =
    useSalesforceConnectionList();

  const { flowValidity } = useSelector(selectFlow);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const { saveDraft, readonly, setActions } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);

  const actionOptions = useMemo(
    () => action.action_options as FlowSfCampaignSyncActionOptions,
    [action]
  );

  const formData: SalesforceCampaignSyncDrawerData = useMemo(() => {
    return {
      campaignIdSource: actionOptions.campaign_selection_type,
      salesforceCampaign: actionOptions.campaign_id ?? null,
      personField:
        (actionOptions.context as SF_CAMPAIGN_PERSON_CONTEXT)?.person_context
          .field ?? null,
      salesforceCampaignStatus: actionOptions.campaign_member_status,
    };
  }, [actionOptions]);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  useEffect(() => {
    // there is only empty state (invalid) and valid state
    // partially valid state is not possible since drawer prevents that
    // to check if data is empty or not just have to-
    // -check for a non optional value is present in the action options
    setValidityCallback(!!actionOptions.campaign_selection_type);
  }, [actionOptions, setValidityCallback]);

  function onCloseDrawer(
    data: { formData: SalesforceCampaignSyncDrawerData } | null
  ) {
    if (data) {
      const { formData } = data;

      let updatedActionOptions: Partial<FlowSfCampaignSyncActionOptions> = {
        campaign_selection_type: formData.campaignIdSource!,
        campaign_member_status: formData.salesforceCampaignStatus,
        connection_id: sfConnectionList?.[0]?.connectionId,
      };

      if (isResolveContextType(formData.campaignIdSource)) {
        updatedActionOptions.context = {
          context_type: SF_CAMPAIGN_CONTEXT_TYPE.PERSON,
          person_context: {
            field: formData.personField!,
          },
        };
        updatedActionOptions.campaign_id = undefined;
      } else if (isResolveDirectType(formData.campaignIdSource)) {
        updatedActionOptions.campaign_id = formData.salesforceCampaign!;
        updatedActionOptions.context = undefined;
      }

      // Propagate changes to parent
      setActions(
        updatedActionOptions as FlowSfCampaignSyncActionOptions,
        identities.actionId,
        groupId
      );

      saveDraft(
        { ...actionOptions, ...updatedActionOptions },
        { actionId: identities.actionId, groupId }
      );
    }
    onClose();
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId]
          ? ""
          : "Salesforce campaign is required"
      }
      identities={identities}
      title={OPTION_DETAILS.label}
      icon={OPTION_DETAILS.icon}
      color={OPTION_DETAILS.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Box w="100%" p="4">
        {isSuccess(sfConnectionListLoading) && !sfConnectionList?.length ? (
          <NoSalesforceConnectionWarning />
        ) : (
          <Center
            rounded="sm"
            bg="grayV2.100"
            cursor="pointer"
            onClick={readonly ? () => {} : onOpen}
          >
            {formData.campaignIdSource ? (
              <ClickToEditCta data={formData} />
            ) : (
              <ClickToSetupCta onOpen={onOpen} />
            )}
          </Center>
        )}
      </Box>

      <SalesforceCampaignSyncDrawer
        data={formData}
        isOpen={isOpen}
        onClose={onCloseDrawer}
      />
    </WidgetContainer>
  );
}
