import { ReactNode } from "react";
import { VStack, Box, Text, Icon, HStack } from "@chakra-ui/react";
import {
  TRIGGER_CRITERIA_HEADER,
  TRIGGER_DL_NODE_TYPES,
  TRIGGER_SEQUENCE_HEADER,
} from "../../../../../common/constants/dynamicList";

export default function TriggerGroupWrapper({
  type,
  index,
  nodeType,
  children,
  isReadOnly,
}: {
  type: string;
  index: number;
  nodeType: TRIGGER_DL_NODE_TYPES;
  children: ReactNode;
  isReadOnly: boolean | undefined;
}) {
  return (
    <>
      {!isReadOnly ? (
        <VStack
          bg="grayV2.100"
          w="90%"
          p={4}
          borderRadius="6px"
          alignItems="flex-start"
          borderWidth={1}
          borderColor="grayV2.100"
        >
          <HStack mb={3}>
            <Box
              width="20px"
              height="20px"
              borderWidth="1px"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              background="brandBlue.500"
            >
              <Icon
                color="basic.white"
                as={
                  nodeType !== TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN
                    ? TRIGGER_CRITERIA_HEADER[type]?.icon
                    : TRIGGER_SEQUENCE_HEADER[type]?.icon
                }
                marginLeft="4px"
                fontSize="10px"
              ></Icon>
            </Box>
            <Text fontSize="14px" fontWeight="600">
              {nodeType !== TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN
                ? TRIGGER_CRITERIA_HEADER[type]?.label
                : TRIGGER_SEQUENCE_HEADER[type]?.label}{" "}
              {index + 1}
            </Text>
          </HStack>
          {children}
        </VStack>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
