import { Container, useTheme, VStack } from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";

export default function WrapperWithScrollBar({
  maxHeight,
  children,
  actionId,
}: {
  maxHeight: string;
  children: ReactNode;
  actionId: string;
}) {
  const theme = useTheme();

  const colors = {
    gray200: theme.__cssVars["--chakra-colors-gray-200"],
    gray300: theme.__cssVars["--chakra-colors-gray-300"],
  };

  const innerContainerId = useMemo(() => `inner-${actionId}`, [actionId]);
  const outerContainerId = useMemo(() => `outer-${actionId}`, [actionId]);

  //This is to check if overflown, then wheel event should scroll inside node else, it should pan
  function isOverflown(element: React.WheelEvent<HTMLDivElement>) {
    const outerTarget = document.getElementById(outerContainerId);
    const outerHeight = outerTarget?.clientHeight;
    const innerTarget = document.getElementById(innerContainerId);
    const innerHeight = innerTarget?.clientHeight;
    if (innerHeight && outerHeight && innerHeight > outerHeight) {
      element.stopPropagation();
    }
  }

  return (
    <VStack
      alignItems="left"
      alignSelf="flex-start"
      w="100%"
      spacing={2}
      px={2}
      maxHeight={maxHeight}
      overflowY="auto"
      id={outerContainerId}
      onWheelCapture={(e) => isOverflown(e)}
      css={{
        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          background: "white",
        },
        "::-webkit-scrollbar-thumb": {
          background: colors.gray200,
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: colors.gray300,
        },
      }}
    >
      <Container w="100%" id={innerContainerId}>
        {children}
      </Container>
    </VStack>
  );
}
