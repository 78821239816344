import { ButtonProps, HStack, StackProps, TextProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { ListFilterProps } from "../common/types/common";
import FilterButton from "./filters/FilterButton";
import FilterDisplay from "./filters/FilterDisplay";
import IButton, { BUTTON } from "./IButton";
import PrimaryHeader, { DefaultHeaderType } from "./PrimaryHeader";
import { SearchField, SearchFieldType } from "./SearchField";

export type CreateButtonProps = ButtonProps & {
  onClick: () => void;
  text: string;
  name: string;
};

type SearchInputButtonProps = {
  searchInputProps?: SearchFieldType;
  createButtonProps?: CreateButtonProps;
  ctaMenu?: ReactNode;
  filterProps?: ListFilterProps;
};

export function SearchInputAndCreateButton({
  //separate component for search and create button , can be used in future
  searchInputProps,
  createButtonProps,
  ctaMenu,
  filterProps,
}: SearchInputButtonProps) {
  return (
    <HStack>
      {searchInputProps && (
        <SearchField size="sm" w="210px" {...searchInputProps} />
      )}
      {filterProps && !filterProps.hidden && <FilterButton {...filterProps} />}
      {createButtonProps && (
        <IButton variant={BUTTON.PRIMARY} {...createButtonProps}>
          {createButtonProps.text}
        </IButton>
      )}
      {ctaMenu}
    </HStack>
  );
}

// common header with search input field for listing Assets page
export function CommonListHeader({
  heading,
  goBack,
  isLoading,
  children,
  headerStackProps,
  titleProps,
  createButtonProps,
  searchInputProps,
  componentAfterHeader,
  filterProps,
  ctaMenu,
}: DefaultHeaderType & {
  headerStackProps?: StackProps;
  titleProps?: TextProps;
  componentAfterHeader?: ReactNode;
} & SearchInputButtonProps) {
  return (
    <>
      <PrimaryHeader
        heading={heading}
        goBack={goBack}
        isLoading={isLoading}
        componentAfterHeader={componentAfterHeader}
        titleProps={titleProps}
        {...headerStackProps}
      >
        <HStack>
          {children}
          <SearchInputAndCreateButton
            createButtonProps={createButtonProps}
            searchInputProps={searchInputProps}
            filterProps={filterProps}
            ctaMenu={ctaMenu}
          />
        </HStack>
      </PrimaryHeader>
      {filterProps && !filterProps.hidden && (
        <FilterDisplay
          variant={filterProps.variant}
          appliedFilters={filterProps.filters}
          handleFilterChange={filterProps.handleFilterChange}
        />
      )}
    </>
  );
}
