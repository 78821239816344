import {
  VStack,
  FormControl,
  DrawerFooter,
  Alert,
  AlertDescription,
  AlertIcon,
  HStack,
  Icon,
  Text,
  useRadioGroup,
  FormHelperText,
  IconButton,
  Tooltip,
  Box,
  ButtonProps,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaList, FaAddressCard, FaSync } from "react-icons/fa";
import { useSelector } from "react-redux";
import { SF_CAMPAIGN_SYNC_DRAWER_VALIDITY } from "../../../../../../common/constants/campaign";
import {
  isFailed,
  isInit,
  isLoading,
  isSuccess,
} from "../../../../../../common/helper/commonHelper";
import {
  isResolveContextType,
  isResolveDirectType,
} from "../../../../../../common/helper/salesforceLegacyServiceHelper";
import {
  SF_CAMPAIGN_SELECTION_TYPE,
  SalesforceCampaignSyncDrawerData,
  Validity,
} from "../../../../../../common/types/campaign";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import IFormLabel from "../../../../../../components/IFormLabel";
import ListRadio from "../../../../../../components/ListRadio";
import { useAppDispatch } from "../../../../../../store";
import CommonDrawer from "../../../components/CommonDrawer";
import {
  getPersonMappingColumns,
  getSalesforceCampaigns,
  getSalesforceCampaignStatus,
  selectFlow,
} from "../../flowSlice";

const SF_CAMPAIGN_MEMBER_STATUS_DEFAULT = [
  {
    label: "Sent",
    value: "sent",
    id: "",
    campaignId: "",
  },
  {
    label: "Responded",
    value: "responded",
    id: "",
    campaignId: "",
  },
];

function CampaignFieldMemberStatusAlert() {
  return (
    <Alert status="info" fontSize="14px" bg="grayV2.100">
      <AlertIcon color="brandBlue.500" />
      <AlertDescription>
        Only the default statues (Send and Responded) are available when reading
        from a campaign ID field
      </AlertDescription>
    </Alert>
  );
}

const RADIO_OPTIONS = [
  {
    label: "Select Salesforce Campaign",
    value: SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_DIRECT,
    icon: FaList,
  },
  {
    label: "Get ID from a contact field",
    value: SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_CONTEXT,
    icon: FaAddressCard,
  },
];

function RadioMenu({
  value,
  onChange,
  isInvalid,
}: {
  value: SF_CAMPAIGN_SELECTION_TYPE | null;
  onChange: (val: SF_CAMPAIGN_SELECTION_TYPE) => void;
  isInvalid?: boolean;
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "campaignIdSource",
    defaultValue: value ?? undefined,
    onChange: onChange,
  });

  const group = getRootProps();

  return (
    <VStack
      {...group}
      w="100%"
      spacing="0"
      border={isInvalid ? "1px red solid" : ""}
    >
      {RADIO_OPTIONS.map(({ label, value, icon }) => {
        const radio = getRadioProps({ value });
        return (
          <ListRadio key={value} {...radio}>
            <HStack>
              <Icon as={icon} />
              <Text>{label}</Text>
            </HStack>
          </ListRadio>
        );
      })}
    </VStack>
  );
}

export default function SalesforceCampaignSyncDrawer({
  data,
  isOpen,
  onClose,
  primaryButtonProps,
}: {
  data: SalesforceCampaignSyncDrawerData;
  isOpen: boolean;
  onClose: (
    data: { formData: SalesforceCampaignSyncDrawerData; valid: boolean } | null
  ) => void;
  primaryButtonProps?: ButtonProps;
}) {
  const dispatch = useAppDispatch();

  const [validity, setValidity] = useState<Validity | null>(null);

  const {
    salesforceCampaigns: { personMappingColumns, campaignStatus, campaigns },
  } = useSelector(selectFlow);

  const [campaignIdSource, setCampaignIdSource] =
    useState<SF_CAMPAIGN_SELECTION_TYPE | null>(data.campaignIdSource);
  const [salesforceCampaign, setSalesforceCampaign] = useState<string | null>(
    data.salesforceCampaign
  );
  const [personField, setPersonField] = useState<string | null>(
    data.personField
  );
  const [salesforceCampaignStatus, setSalesforceCampaignStatus] = useState<
    string | null
  >(data.salesforceCampaignStatus);

  function forceFetchLatestSalesforceData() {
    dispatch(getSalesforceCampaignStatus(true));
    dispatch(getSalesforceCampaigns(true));
  }

  function onCloseDrawer() {
    setValidity(null);
    onClose(null);
  }

  function isValid(data: SalesforceCampaignSyncDrawerData) {
    let validity = { ...SF_CAMPAIGN_SYNC_DRAWER_VALIDITY };

    switch (data.campaignIdSource) {
      case SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_DIRECT:
        validity = {
          ...validity,
          salesforceCampaign: !!data.salesforceCampaign,
          salesforceCampaignStatus: !!data.salesforceCampaignStatus,
        };
        break;

      case SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_CONTEXT:
        validity = {
          ...validity,
          personField: !!data.personField,
          salesforceCampaignStatus: !!data.salesforceCampaignStatus,
        };
        break;

      default:
        validity = {
          ...validity,
          campaignIdSource: false,
        };
    }

    let valid = Object.values(validity).every((val) => val);

    return { valid, validity };
  }

  function onSave() {
    const formData: SalesforceCampaignSyncDrawerData = {
      campaignIdSource,
      salesforceCampaign,
      personField,
      salesforceCampaignStatus,
    };
    const { valid, validity } = isValid(formData);
    setValidity(validity);
    if (valid) {
      onClose({ formData, valid });
    }
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(getSalesforceCampaignStatus());
      dispatch(getSalesforceCampaigns());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (isOpen && isInit(personMappingColumns.loading)) {
      dispatch(getPersonMappingColumns());
    }
  }, [dispatch, isOpen, personMappingColumns.loading]);

  useEffect(() => {
    setCampaignIdSource(data.campaignIdSource);
    setSalesforceCampaign(data.salesforceCampaign);
    setPersonField(data.personField);
    setSalesforceCampaignStatus(data.salesforceCampaignStatus);
  }, [data, isOpen]);

  useEffect(() => {
    setSalesforceCampaign(null);
    setPersonField(null);
    setSalesforceCampaignStatus(null);
  }, [campaignIdSource]);

  const memberStatusValue =
    (isResolveContextType(campaignIdSource)
      ? SF_CAMPAIGN_MEMBER_STATUS_DEFAULT
      : campaignStatus.data
    ).find((x) => x.value === salesforceCampaignStatus) ?? null;

  const memberStatusOptions = isResolveContextType(campaignIdSource)
    ? SF_CAMPAIGN_MEMBER_STATUS_DEFAULT
    : campaignStatus.data.filter((x) => x.campaignId === salesforceCampaign);

  return (
    <>
      <CommonDrawer
        size="md"
        title="Salesforce Campaign Sync"
        placement="right"
        isOpen={isOpen}
        onClose={onCloseDrawer}
        closeOnOverlayClick={false}
        footer={
          <DrawerFooter p={0}>
            <IButton mr={3} onClick={onCloseDrawer} variant={BUTTON.SECONDARY}>
              Cancel
            </IButton>
            <IButton
              variant={BUTTON.PRIMARY}
              children="Save"
              onClick={onSave}
              {...primaryButtonProps}
            />
          </DrawerFooter>
        }
      >
        <VStack spacing="4" alignItems="flex-start" position="relative">
          <FormControl>
            <IFormLabel>Campaign ID Source</IFormLabel>
            <RadioMenu
              value={campaignIdSource}
              onChange={(val) => {
                setValidity(null);
                setCampaignIdSource(val);
              }}
              isInvalid={validity ? !validity?.campaignIdSource : false}
            />
          </FormControl>

          {campaignIdSource && (
            <>
              <Box position="absolute" right="0px" top="100px" zIndex="1">
                <Tooltip label="Fetch latest Salesforce data">
                  <IconButton
                    icon={<FaSync />}
                    variant="link"
                    isLoading={
                      isLoading(campaignStatus.loading) ||
                      isLoading(campaigns.loading)
                    }
                    onClick={forceFetchLatestSalesforceData}
                    aria-label="Fetch latest Salesforce data"
                  />
                </Tooltip>
              </Box>

              {isResolveDirectType(campaignIdSource) && (
                <FormControl>
                  <IFormLabel>Salesforce Campaign</IFormLabel>
                  <DropdownWithSearch
                    isSearchable
                    isLoading={isLoading(campaigns.loading)}
                    value={campaigns.data.find(
                      (x) => x.campaignId === salesforceCampaign
                    )}
                    getOptionValue={(value) => value.campaignId}
                    getOptionLabel={(value) => value.campaignName}
                    onChange={(e) =>
                      setSalesforceCampaign(e?.campaignId ?? null)
                    }
                    options={campaigns.data}
                    isInvalid={validity ? !validity?.salesforceCampaign : false}
                  />
                </FormControl>
              )}

              {isResolveContextType(campaignIdSource) &&
                isSuccess(personMappingColumns.loading) && (
                  <FormControl>
                    <IFormLabel>Campaign ID Field</IFormLabel>
                    <DropdownWithSearch
                      isSearchable
                      isLoading={isLoading(personMappingColumns.loading)}
                      value={personMappingColumns.data.find(
                        (x) => x.name === personField
                      )}
                      getOptionValue={(value) => value.name}
                      getOptionLabel={(value) => value.display}
                      onChange={(e) => setPersonField(e?.name ?? null)}
                      options={personMappingColumns.data}
                      isInvalid={validity ? !validity?.personField : false}
                    />
                  </FormControl>
                )}

              <FormControl>
                <IFormLabel>Campaign Member Status</IFormLabel>
                <DropdownWithSearch
                  isSearchable
                  isLoading={isLoading(campaignStatus.loading)}
                  value={memberStatusValue}
                  onChange={(value) =>
                    setSalesforceCampaignStatus(value?.value ?? null)
                  }
                  options={memberStatusOptions}
                  isInvalid={
                    validity ? !validity?.salesforceCampaignStatus : false
                  }
                />
                {isFailed(campaignStatus.loading) && (
                  <FormHelperText color="yellow.500">
                    Campaign Member Status failed to load. Please try again
                    later!
                  </FormHelperText>
                )}
              </FormControl>

              {isResolveContextType(campaignIdSource) && (
                <CampaignFieldMemberStatusAlert />
              )}
            </>
          )}
        </VStack>
      </CommonDrawer>
    </>
  );
}
