import {
  FormControl,
  FormLabel,
  Flex,
  Icon,
  FormErrorMessage,
  VStack,
  HStack,
  Text,
  FormHelperText,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import {
  FaExclamationTriangle,
  FaInfoCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { isBlank } from "../../../../common/helper/commonHelper";
import { EmailTokenUpdate } from "../../../../common/types/campaign";
import { InvalidFields } from "../../../../common/types/token";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { TOKEN_RETURN_TYPE } from "../../../../common/constants/token";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";

const DEFAULT_VALUE = "default_value";

function EmptyFallbackInfo() {
  return (
    <HStack bg="blackAlpha.50" fontSize="sm" p="2" rounded="md">
      <FaExclamationCircle />
      <Text>Empty string will be returned as fallback value</Text>
    </HStack>
  );
}

function InputFieldForFallbackValue({
  invalidFields,
  value,
  onChangeHandler,
  tokenType,
  parserReturnType,
  valueReset,
}: {
  invalidFields: InvalidFields;
  value: EmailTokenUpdate;
  onChangeHandler: (updateValue: any, field: keyof EmailTokenUpdate) => void;
  tokenType?: string;
  parserReturnType?: string;
  valueReset?: boolean;
}) {
  const returnType = tokenType || parserReturnType;

  function warningMessage(message: string, error?: boolean) {
    return (
      <FormControl>
        <FormLabel fontSize="12" marginBottom="1" paddingY="2">
          Fallback Value
        </FormLabel>
        <Flex
          alignItems="center"
          color={error ? "red.500" : "orange.500"}
          p="2"
          fontSize="sm"
          rounded="md"
          bg="blackAlpha.50"
        >
          <Icon as={error ? FaExclamationTriangle : FaInfoCircle} mr="2"></Icon>
          <Text fontSize="14">{message}</Text>
        </Flex>
      </FormControl>
    );
  }

  function booleanField() {
    const options = [
      { label: "True", value: true },
      { label: "False", value: false },
    ];
    return (
      <FormControl width={"300px"} isInvalid={!!invalidFields.default_value}>
        <FormLabel fontSize={12} marginBottom={1}>
          Fallback value
        </FormLabel>
        <DropdownWithSearch
          options={options}
          value={options.find((item) => item.value === value.default_value)}
          onChange={(option) =>
            onChangeHandler(option?.value ?? true, DEFAULT_VALUE)
          }
          isInvalid={!!invalidFields.default_value}
          controlStyle={{
            minHeight: "32px",
            height: "32px",
            borderRadius: "2px",
          }}
        />
        <FormErrorMessage fontSize="12px">
          {invalidFields.default_value}
        </FormErrorMessage>
        {valueReset && (
          <FormHelperText fontSize="xs">
            Fallback value has been reset as the token type changed!
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  function fallbackInputField(type: "number" | "text" | "date") {
    return (
      <Tooltip
        isDisabled={!!returnType}
        label="Please fill in token details first"
        shouldWrapChildren
        placement="bottom"
      >
        <InputFieldWithError
          labelText="Fallback value"
          placeholder="Token fallback value"
          formLabelProps={{ fontSize: 12, marginBottom: 1 }}
          size="sm"
          isDisabled={!returnType}
          value={value.default_value ?? ""}
          onChange={(e) => onChangeHandler(e.target.value, DEFAULT_VALUE)}
          errorMsg={invalidFields.default_value}
          maxWidth="340px"
          name={DEFAULT_VALUE}
          type={type}
          helperText={
            valueReset
              ? "Fallback value has been reset as the token type changed!"
              : ""
          }
        />
      </Tooltip>
    );
  }

  if (
    tokenType &&
    parserReturnType &&
    parserReturnType !== "undefined" &&
    tokenType !== parserReturnType
  ) {
    return warningMessage(
      `Token type cannot be modified from ${tokenType} to ${parserReturnType}`,
      true
    );
  }

  switch (returnType) {
    case TOKEN_RETURN_TYPE.STRING:
      return fallbackInputField("text");
    case TOKEN_RETURN_TYPE.NUMBER:
      return fallbackInputField("number");
    case TOKEN_RETURN_TYPE.DATETIME:
      return fallbackInputField("date");
    case TOKEN_RETURN_TYPE.BOOLEAN:
      return booleanField();
    case TOKEN_RETURN_TYPE.ARRAY:
    case TOKEN_RETURN_TYPE.HASHMAP:
      return warningMessage(
        `Empty ${returnType} will be returned as fallback value`
      );
    default:
      return fallbackInputField("text");
  }
}

export default function TokenForm({
  value,
  tokenType,
  parserReturnType,
  onChange,
  invalidFields,
  fallbackValueReset,
  isLoading,
}: {
  value: EmailTokenUpdate;
  tokenType?: string;
  parserReturnType?: string;
  onChange: (value: EmailTokenUpdate) => void;
  invalidFields: InvalidFields;
  fallbackValueReset?: boolean;
  isLoading: boolean;
}) {
  function onChangeHandler(updateValue: any, field: keyof EmailTokenUpdate) {
    const newValue = cloneDeep(value);

    if (field === DEFAULT_VALUE) {
      switch (parserReturnType) {
        case TOKEN_RETURN_TYPE.NUMBER:
          newValue[field] = !isBlank(updateValue) ? Number(updateValue) : "";
          break;
        case TOKEN_RETURN_TYPE.ARRAY:
          newValue[field] = [];
          break;
        case TOKEN_RETURN_TYPE.HASHMAP:
          newValue[field] = {};
          break;
        case TOKEN_RETURN_TYPE.BOOLEAN:
          newValue[field] = !!updateValue;
          break;
        default:
          newValue[field] = updateValue;
      }
    } else {
      newValue[field] = updateValue;
    }
    onChange(newValue);
  }

  return (
    <Flex alignItems="center">
      <VStack flex="1" alignItems="top" spacing={2}>
        <InputFieldWithError
          textArea
          maxH="300px"
          labelText="Description"
          placeholder="Token description"
          formLabelProps={{ fontSize: 12, marginBottom: 1 }}
          size="sm"
          value={value.description}
          onChange={(e) => onChangeHandler(e.target.value, "description")}
          errorMsg={invalidFields.description}
          name="description"
          isLoading={isLoading}
        />
        {isLoading ? (
          <FormControl
            width={"300px"}
            isInvalid={!!invalidFields.default_value}
          >
            <FormLabel fontSize={12} marginBottom={1}>
              Fallback value
            </FormLabel>
            <Box h="32px" w="100%">
              <ISkeleton
                variant={SKELETON_VARIANT.INPUT}
                isLoaded={!isLoading}
                border="1px solid"
                borderColor="grayV2.200"
              />
            </Box>
          </FormControl>
        ) : (
          <InputFieldForFallbackValue
            invalidFields={invalidFields}
            value={value}
            onChangeHandler={onChangeHandler}
            tokenType={tokenType}
            parserReturnType={parserReturnType}
            valueReset={fallbackValueReset}
          />
        )}
        {parserReturnType === TOKEN_RETURN_TYPE.STRING &&
          value.default_value === "" && <EmptyFallbackInfo />}
      </VStack>
    </Flex>
  );
}
