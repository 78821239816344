import {
  Container,
  Divider,
  VStack,
  Center,
  Box,
  Flex,
  Badge,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { isEmpty, startCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVITY_TYPE } from "../../../../../common/constants/connection";
import ITitle from "../../../../../components/ITitle";
import Pagination from "../../../../../components/Pagination";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import UserNameWithAvatar from "../../../../../components/UserNameWithAvatar";
import { selectAccount, getUserList } from "../../../../account/accountSlice";
import {
  selectConnection,
  activityLog,
  setActivityLogPage,
} from "../../connectionSlice";
import SubHeader from "../../components/SubHeader";

function ActivityLogItemTitle({
  title,
  username,
  bg,
}: {
  title: string;
  username: string;
  bg?: string;
}) {
  return (
    <Flex
      justifyContent="space-between"
      bg={bg}
      alignItems="center"
      py="4"
      px="5"
    >
      <Text fontWeight="semibold">{title}</Text>
      <UserNameWithAvatar username={username} />
    </Flex>
  );
}

function TableList({ title, items }: { title: string; items: string[] }) {
  return (
    <Box>
      <ITitle fontSize="sm" mb="2" fontWeight="semibold" title={title} />
      <Flex flexWrap="wrap" height="100%">
        {items.map((item, index) => {
          return (
            <Badge
              p="2"
              mr="2"
              mb="2"
              fontSize="12px"
              textTransform="none"
              height="35px"
              key={index}
            >
              {item}
            </Badge>
          );
        })}
      </Flex>
    </Box>
  );
}

function ActivityLogListItem({
  date,
  activityType,
  username,
  tablesAdded,
  tablesUpdated,
}: {
  date: string;
  activityType: ACTIVITY_TYPE;
  username: string;
  tablesAdded?: string[];
  tablesUpdated?: string[];
}) {
  function getBgColor(activityType: ACTIVITY_TYPE) {
    switch (activityType) {
      case ACTIVITY_TYPE.SYNC_PAUSED:
        return "red.50";
      case ACTIVITY_TYPE.SYNC_RESUMED:
        return "green.50";
      default:
        return undefined;
    }
  }

  return (
    <Box>
      <Flex>
        <Flex fontSize="sm" flex="1" py="5" fontWeight="semibold">
          {format(new Date(date), "pp, PP")}
        </Flex>

        <Flex flexDir="column" flex="3">
          <ActivityLogItemTitle
            bg={getBgColor(activityType)}
            username={username}
            title={startCase(activityType)}
          />

          {(!isEmpty(tablesAdded) || !isEmpty(tablesUpdated)) && (
            <>
              <Divider mb="5" />

              <VStack alignItems="normal" spacing="5" px="5">
                {!isEmpty(tablesAdded) && (
                  <TableList
                    title="Tables added"
                    items={tablesAdded as string[]}
                  />
                )}

                {!isEmpty(tablesUpdated) && (
                  <TableList
                    title="Tables updated"
                    items={tablesUpdated as string[]}
                  />
                )}
              </VStack>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

export default function ActivityLog({
  connectionId,
  refresh,
}: {
  connectionId: string;
  refresh: boolean;
}) {
  const dispatch = useDispatch();

  const { usersList, fetchingUsersList } = useSelector(selectAccount);

  const { activityLogs, fetchingActivityLog, togglingSync } =
    useSelector(selectConnection);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect(() => {
    // refresh activity log on sync toggle, pagination,
    if (!togglingSync) {
      const params = {
        connectionId,
        pageNumber: activityLogs.currentPageNumber,
        pageSize: activityLogs.pageSize,
      };
      dispatch(activityLog(params));
    }
  }, [
    activityLogs.currentPageNumber,
    activityLogs.pageSize,
    connectionId,
    dispatch,
    refresh,
    togglingSync,
  ]);

  return (
    <Container maxW="container.lg" py={5}>
      <SubHeader title="Activity Log" height="80px" />
      <Divider mb="8" />
      <SpinnerContainer
        loading={fetchingActivityLog || fetchingUsersList}
        height="200px"
      >
        {!isEmpty(activityLogs.records) && !fetchingUsersList ? (
          <>
            <VStack alignItems="normal" spacing={8}>
              {activityLogs.records?.map((record) => {
                return (
                  <ActivityLogListItem
                    date={record.activity_time}
                    activityType={record.activity_type}
                    username={usersList[record.account_id.toString()]?.name}
                    tablesAdded={record.additional_info?.tables_added}
                    tablesUpdated={record.additional_info?.tables_edited}
                    key={record.id}
                  />
                );
              })}
            </VStack>
            <Pagination
              mt="5"
              currentPage={activityLogs.currentPageNumber}
              onPageChange={(selectedPageNo) =>
                dispatch(setActivityLogPage(selectedPageNo))
              }
              pageCount={activityLogs.totalPageCount}
            />
          </>
        ) : (
          <Center height="300px">
            <ITitle title="No Activities Yet!" />
          </Center>
        )}
      </SpinnerContainer>
    </Container>
  );
}
