import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import SubPropertyItemList from "./components/SubPropertyItemList";

export default function SubPropertyItem({
  subProperties,
}: {
  subProperties: any;
}) {
  const subPropertyLength = useMemo(
    () => (subProperties ? Object.keys(subProperties).length : 0),
    [subProperties]
  );

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton>
          <AccordionIcon />
          <HStack>
            <Text fontSize={12} color="gray.500">
              {subPropertyLength} Sub-properties
            </Text>
            <Spacer />
            <Text fontWeight="bold" fontSize={12}>
              Note:
            </Text>
            <Text fontSize={12} color="gray.500" pl="1">
              Sub-properties cannot be enabled for usage
            </Text>
          </HStack>
        </AccordionButton>
        <AccordionPanel>
          <SubPropertyItemList
            subPropertyKeyList={Object.keys(subProperties)}
            subPropertyList={subProperties}
            leftPadding={4}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
