import {
  DynamicListChildL2,
  CONNECTOR,
  TYPE,
  DynamicListChild,
  DynamicListChildL1,
  WebsiteActivityFilter,
  OperatorType,
} from "../types/campaign";
import {
  PROPERTY_FILTER_ID,
  FILTER_TYPE,
  CHILD_FILTER_TYPE,
  FORM_SUBMITTED_EVENT,
  WEBSITE_ACTIVITY_META,
  WEBSITE_ACTIVITY_EVENTS,
} from "../constants/campaign";
import { isEmpty, isUndefined } from "lodash";
import { MarketingActivity } from "../types/person";
import {
  ListFilterParams,
  ReactSelectDefaultOptionsType,
} from "../types/common";
import { OperatorList } from "../types/campaign";
import { useCallback } from "react";
import { useAppDispatch } from "../../store";
import {
  getPageVisitReferrerList,
  getSessionReferrerList,
  getSessionCustomParamsList,
  getSessionLandingPageUrlList,
  getSessionPageVisitUrlList,
  getSessionFormSubmissionPageList,
  getPageVisitCustomParamsList,
} from "../../components/dynamic-list/dynamicListSlice";
import { isFulfilled } from "./commonHelper";

type PropertyGroupIndices = {
  timeFrameIndex: number | null;
  srcOfVisitIndex: number | null;
};

export function isTypeGroup(type: TYPE) {
  return type === TYPE.GROUP;
}

export function findFirstGroupIndex(data: DynamicListChildL1[]) {
  return data.findIndex((item) => isTypeGroup(item.type));
}

export function getFilterGroupChild(
  filterGroup: DynamicListChild,
  index: number | null
) {
  return index !== null ? filterGroup.children[index] ?? null : null;
}

export function findChildWithFilter(
  data: DynamicListChildL2[] | undefined,
  filter: PROPERTY_FILTER_ID
) {
  return data?.find((child) => child.filter === filter) ?? null;
}

export function getOperatorsListForDisplay(
  operator: OperatorType | null,
  operators: OperatorList | null
): ReactSelectDefaultOptionsType[] {
  return operators && operator
    ? Object.values(operators[operator]).map(({ display, id }) => ({
        label: display,
        value: id,
      }))
    : [];
}

//marketing activity

export function initMarketingActivity({
  type,
  filter,
  connector,
}: {
  type?: TYPE;
  filter?: PROPERTY_FILTER_ID | CHILD_FILTER_TYPE | string;
  connector?: CONNECTOR;
}): DynamicListChildL2 {
  return {
    type: type ?? TYPE.EXPRESSION,
    filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
    filter: filter ?? null,
    property: null,
    operator: null,
    value: [""],
    connector: connector ?? CONNECTOR.AND,
  };
}

export function findFormSessionGroupInFilterGroup(
  filterGroupChild: DynamicListChildL1[]
) {
  const formSessionGroupIndex = filterGroupChild.findIndex(
    ({ filter, type }) =>
      isTypeGroup(type) && filter === PROPERTY_FILTER_ID.FORM_SUBMISSION_SESSION
  );

  return formSessionGroupIndex > -1 ? formSessionGroupIndex : null;
}

export function isFormSubmittedEvent(filter: string | null) {
  return filter === FORM_SUBMITTED_EVENT;
}

export function findMarketingActivityInEventList(
  marketingActivityEventList: MarketingActivity[] | null,
  activitySelected: string | null
) {
  return marketingActivityEventList && activitySelected
    ? marketingActivityEventList?.find(({ id }) => id === activitySelected) ??
        null
    : null;
}

export function filterMarketingActivityByLevel(
  marketingActivityEventList: MarketingActivity[] | null,
  activitySelected: string,
  level: number
) {
  // filters that can be applied to the activity chosen , at particular level
  return findMarketingActivityInEventList(
    marketingActivityEventList,
    activitySelected
  )?.arguments?.filter(({ display_level }) => display_level === level);
}

export function getMarketingActivityArgumentList(
  marketingActivityEventList: MarketingActivity[] | null,
  activitySelected: string | null
) {
  return (
    findMarketingActivityInEventList(
      marketingActivityEventList,
      activitySelected
    )?.arguments?.map((arg) => arg.id) ?? null
  );
}

// website tracking activity

export function initWebsiteActivity({
  filter,
  connector,
}: {
  filter?: string;
  connector?: CONNECTOR;
}): DynamicListChildL2 {
  return {
    type: TYPE.EXPRESSION,
    filter_type: FILTER_TYPE.WEBSITE_ACTIVITY,
    filter: filter ?? "",
    property: null,
    operator: null,
    value: [""],
    connector: connector ?? CONNECTOR.AND,
  };
}

export function getWebActivityLevelOneFilterIndices(
  data: DynamicListChild
): PropertyGroupIndices {
  let propertyGroup: PropertyGroupIndices = {
    timeFrameIndex: null,
    srcOfVisitIndex: null,
  };
  if (isEmpty(data.children)) return propertyGroup;

  for (let i = 0; i < data.children.length; i++) {
    switch (data.children[i].filter) {
      case CHILD_FILTER_TYPE.TIME_FRAME:
        propertyGroup.timeFrameIndex = i;
        break;
      case CHILD_FILTER_TYPE.VISITOR_SOURCE:
        propertyGroup.srcOfVisitIndex = i;
        break;
    }
    if (
      propertyGroup.timeFrameIndex !== null &&
      propertyGroup.srcOfVisitIndex !== null
    ) {
      break;
    }
  }
  return propertyGroup;
}

export function findWebsiteActivityInEventList(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string | null
) {
  return websiteActivityEventList && activitySelected
    ? websiteActivityEventList?.find(({ id }) => id === activitySelected) ??
        null
    : null;
}

export function filterWebsiteActivityByLevel(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string | null,
  level: number
) {
  // filters that can be applied to the activity chosen , at particular level
  return findWebsiteActivityInEventList(
    websiteActivityEventList,
    activitySelected
  )?.arguments.filter(({ display_level }) => display_level === level);
}

export function getWebActivityArgumentIds(
  websiteActivityEventList: WebsiteActivityFilter[] | null,
  activitySelected: string | null,
  level?: number
) {
  if (isUndefined(level)) {
    return (
      findWebsiteActivityInEventList(
        websiteActivityEventList,
        activitySelected
      )?.arguments.map((arg) => arg.id) ?? null
    );
  }
  return (
    filterWebsiteActivityByLevel(
      websiteActivityEventList,
      activitySelected,
      level
    )?.map((arg) => arg.id) ?? null
  );
}

export function addEllipsisToText(text: string) {
  return `...${text}...`;
}

function getLabelValuePair(data: string[] | null) {
  return data ? data.map((value) => ({ label: value, value })) : null;
}

export function useWebsiteActivityMetaApis() {
  const dispatch = useAppDispatch();

  const processMetaData = useCallback((resp: any) => {
    if (isFulfilled(resp.meta.requestStatus)) {
      const {
        data,
        pagination: { total_pages },
      } = resp.payload;
      return {
        options: getLabelValuePair(data),
        totalPageCount: total_pages,
      };
    }
    return null;
  }, []);

  const getParametersFromSession = useCallback(
    async (
      websiteActivityMeta: WEBSITE_ACTIVITY_META,
      filterParams: ListFilterParams,
      mainFilter?: string | null
    ) => {
      switch (websiteActivityMeta) {
        case WEBSITE_ACTIVITY_META.VISITOR_SOURCE:
          return processMetaData(
            await dispatch(getSessionReferrerList(filterParams))
          );

        case WEBSITE_ACTIVITY_META.CUSTOM_PARAM:
          return processMetaData(
            await dispatch(getSessionCustomParamsList(filterParams))
          );

        case WEBSITE_ACTIVITY_META.WEBPAGE_URL:
          if (mainFilter === FORM_SUBMITTED_EVENT) {
            return processMetaData(
              await dispatch(getSessionLandingPageUrlList(filterParams))
            );
          } else {
            return processMetaData(
              await dispatch(getSessionPageVisitUrlList(filterParams))
            );
          }

        case WEBSITE_ACTIVITY_META.SESSION_LANDING_PAGE_URL:
          return processMetaData(
            await dispatch(getSessionLandingPageUrlList(filterParams))
          );

        case WEBSITE_ACTIVITY_META.FORM_SUBMISSION_PAGE:
          return processMetaData(
            await dispatch(getSessionFormSubmissionPageList(filterParams))
          );
      }
      return null;
    },
    [dispatch, processMetaData]
  );

  const getParametersFromPageVisits = useCallback(
    async (
      websiteActivityMeta: WEBSITE_ACTIVITY_META,
      filterParams: ListFilterParams
    ) => {
      switch (websiteActivityMeta) {
        case WEBSITE_ACTIVITY_META.VISITOR_SOURCE:
          return processMetaData(
            await dispatch(getPageVisitReferrerList(filterParams))
          );

        case WEBSITE_ACTIVITY_META.CUSTOM_PARAM:
          return processMetaData(
            await dispatch(getPageVisitCustomParamsList(filterParams))
          );

        case WEBSITE_ACTIVITY_META.WEBPAGE_URL:
          return processMetaData(
            await dispatch(getSessionPageVisitUrlList(filterParams))
          );
      }
      return null;
    },
    [dispatch, processMetaData]
  );

  const getWebsiteActivityMetaApiData = useCallback(
    async (
      websiteActivityMeta: WEBSITE_ACTIVITY_META,
      filterParams: ListFilterParams,
      mainParentFilter?: string | null
    ): Promise<{
      options: ReactSelectDefaultOptionsType[] | null;
      totalPageCount: number;
    } | null> => {
      switch (mainParentFilter) {
        //Web page filter
        case WEBSITE_ACTIVITY_EVENTS.WEB_PAGE:
          return await getParametersFromPageVisits(
            websiteActivityMeta,
            filterParams
          );

        //Web session and form submitted event
        case WEBSITE_ACTIVITY_EVENTS.WEB_SESSION:
        case FORM_SUBMITTED_EVENT:
        default:
          return await getParametersFromSession(
            websiteActivityMeta,
            filterParams,
            mainParentFilter
          );
      }
    },
    [getParametersFromPageVisits, getParametersFromSession]
  );

  return { getWebsiteActivityMetaApiData };
}
