import { FormikErrors } from "formik";
import { cloneDeep, isEmpty, isInteger, isArray } from "lodash";
import {
  FILTER_TYPE,
  IS_EQUAL_OPERATOR,
  MANY_ARGUMENTS,
  PERIODIC_FREQUENCIES,
  RECURRING_FREQUENCIES,
} from "../../../../common/constants/campaign";
import { isBlank } from "../../../../common/helper/commonHelper";
import {
  CampaignSchedule,
  DynamicListChild,
  DynamicListChildL1,
  DynamicListChildL2,
  DynamicListType,
  OperatorList,
  RunConfig,
  RUN_TYPE,
  ValueTypes,
  CONNECTOR,
} from "../../../../common/types/campaign";
import { isTypeGroup } from "../../../../common/helper/dynamicListHelper";

const UTM_CUSTOM_PARAM_OPERATORS = [
  "utm_parameter_string_compare",
  "custom_parameter_string_compare",
];

type OperatorArgCount = { [key: string]: string };

// Dynamic list validation

export function validateDynamicList(
  dynamicList: DynamicListType[],
  operatorsArgCount: OperatorArgCount
) {
  let invalid = false;
  let dynamicListCopy = cloneDeep(dynamicList);
  if (!dynamicListCopy || isEmpty(dynamicListCopy)) {
    invalid = true;
  } else {
    // validate each groups
    dynamicListCopy.forEach((outerGroup) => {
      ({ invalid, outerGroup } = validateOuterGroup(
        outerGroup,
        invalid,
        operatorsArgCount
      ));
    });
    if (dynamicListCopy.length) dynamicListCopy[0].connector = CONNECTOR.EMPTY;
  }

  return { dynamicListValid: !invalid, dynamicListValidated: dynamicListCopy };
}

export function validateOuterGroup(
  outerGroup: DynamicListType,
  invalid: boolean,
  operatorsArgCount: OperatorArgCount
) {
  if (outerGroup.children?.length) {
    outerGroup.children.forEach((filterGroup) => {
      ({ invalid, filterGroup } = validateFilterGroup(
        filterGroup,
        invalid,
        operatorsArgCount
      ));
    });
    outerGroup.validation_error = "";
    outerGroup.children[0].connector = CONNECTOR.EMPTY;
  } else if (outerGroup.operator) {
    outerGroup.validation_error = "";
  } else {
    invalid = true;
    outerGroup.validation_error = "Outer group cannot be empty";
  }
  return { outerGroup, invalid };
}

export function validateFilterGroup(
  filterGroup: DynamicListChild,
  invalid: boolean,
  operatorsArgCount: OperatorArgCount
) {
  //check if filterGroup itself has any operators (frequency)
  if (
    filterGroup.operator &&
    isInvalidOperatorsArgCount(operatorsArgCount, filterGroup)
  ) {
    invalid = true;
    filterGroup.validation_error = "Invalid values";
  }

  if (filterGroup.children?.length) {
    filterGroup.children.forEach((filterRow) => {
      // Filter Group Row (person, activity, time, link , source of visit)
      ({ invalid, filterRow } = validateFilterRow(
        //property group with type - exp
        filterRow,
        invalid,
        operatorsArgCount
      ));

      // Property Group with type - group
      if (isTypeGroup(filterRow.type) && filterRow.children?.length) {
        //TODO: write validations for separate activity
        filterRow.children.forEach((propertyGroup) => {
          // Property groups row

          ({ invalid, propertyGroup } = validatePropertyGroupRows(
            propertyGroup,
            invalid,
            operatorsArgCount,
            isInvalidProperty(filterRow.filter_type, propertyGroup.property)
          ));
        });

        filterRow.children[0].connector = CONNECTOR.EMPTY;
      }
    });

    filterGroup.children[0].connector = CONNECTOR.EMPTY;
  } else {
    invalid = true;
    filterGroup.validation_error = "Filter group cannot be empty";
  }
  return { filterGroup, invalid };
}

export function validateFilterRow(
  filterRow: DynamicListChildL1,
  invalid: boolean,
  operatorArgCount: OperatorArgCount
) {
  if (
    !isTypeGroup(filterRow.type) &&
    (!filterRow.filter ||
      !filterRow.operator ||
      isInvalidOperatorsArgCount(operatorArgCount, filterRow))
  ) {
    invalid = true;

    filterRow.validation_error = "Invalid values";
  } else {
    filterRow.validation_error = "";
  }
  return { filterRow, invalid };
}

function validateGroup(
  group: DynamicListChildL1,
  invalid: boolean,
  operatorArgCount: OperatorArgCount
) {
  if (isTypeGroup(group.type) && group.children?.length) {
    group.children.forEach((property: DynamicListChildL2) => {
      const validatedProperty = validateFilterRow(
        property,
        invalid,
        operatorArgCount
      );
      property = validatedProperty.filterRow as DynamicListChildL2;
      invalid = validatedProperty.invalid;
      const validatedRow = validateGroup(property, invalid, operatorArgCount);
      property = validatedRow.group as DynamicListChildL2;
      invalid = validatedRow.invalid;
    });
    group.children[0].connector = CONNECTOR.EMPTY;
    return { group, invalid };
  }

  const rowFilter = validateFilterRow(group, invalid, operatorArgCount);
  return { group: rowFilter.filterRow, invalid: rowFilter.invalid };
}

export function validatePropertyGroupRows(
  propertyGroup: any,
  invalid: boolean,
  operatorArgCount: OperatorArgCount,
  propertyInvalid: boolean
) {
  if (isTypeGroup(propertyGroup.type) && propertyGroup.children?.length) {
    const validatedGroup = validateGroup(
      propertyGroup,
      invalid,
      operatorArgCount
    );

    propertyGroup = validatedGroup.group;
    invalid = validatedGroup.invalid;
  } else {
    // check for property row  if there is no children
    if (
      propertyInvalid ||
      !propertyGroup.operator ||
      isInvalidOperatorsArgCount(operatorArgCount, propertyGroup)
    ) {
      invalid = true;
      propertyGroup.validation_error = "Invalid values";
    } else {
      propertyGroup.validation_error = "";
    }
  }

  return { propertyGroup, invalid };
}

function isInvalidOperatorsArgCount(
  operatorArgCount: OperatorArgCount,
  dynamicListRow: any
): boolean {
  const { operator, value } = dynamicListRow;
  const numOfArg = operator ? operatorArgCount[operator] : "";

  if (UTM_CUSTOM_PARAM_OPERATORS.includes(operator)) {
    let [filter, op, val] = value;
    val = value[2] ?? [];
    return (
      !value ||
      !filter || //filter
      !op || //operator
      isInvalidOperatorsArgCount(operatorArgCount, {
        operator: op,
        value: isArray(val) ? val : [val], //value
      })
    );
  }

  //check if operator is in operatorArgCount
  if (numOfArg) {
    const containsEmptyValue = value?.some((val: ValueTypes) => isBlank(val)); //check for null,"",undefined

    if (containsEmptyValue || !value) {
      return true;
    }

    switch (numOfArg) {
      case "0":
        return value.length !== 0;

      case MANY_ARGUMENTS:
        return value.length === 0;

      default:
        const numOfArgInt = parseInt(numOfArg);
        return numOfArgInt >= 1 && value.length !== numOfArgInt;
    }
  }

  //operators not returned by the operators endpoint
  return isInvalidExemptedOperators(dynamicListRow);
}

function isInvalidExemptedOperators(dynamicListRow: any) {
  const { operator, value, filter, filter_type } = dynamicListRow;

  const containsEmptyValue = value?.some((val: ValueTypes) => isBlank(val));

  switch (operator) {
    case IS_EQUAL_OPERATOR:
      return (
        !filter ||
        containsEmptyValue ||
        ![
          FILTER_TYPE.MARKETING_ACTIVITY,
          FILTER_TYPE.PRODUCT_ACTIVITY,
          FILTER_TYPE.WEBSITE_ACTIVITY,
        ].includes(filter_type)
      );
  }

  return false;
}

export function filterOperatorsArgCount(operators: OperatorList | null) {
  const operatorsArgCount: { [key: string]: string } = {};
  if (operators) {
    Object.values(operators).forEach((operator) => {
      Object.values(operator).forEach((op) => {
        if (!(op.id in operatorsArgCount)) {
          operatorsArgCount[op.id] = op.arguments;
        }
      });
    });
  }

  return operatorsArgCount;
}

function isInvalidProperty(
  filterType: FILTER_TYPE | null,
  property: null | string
) {
  switch (filterType) {
    // property should not be empty for product activity
    case FILTER_TYPE.PRODUCT_ACTIVITY:
      return !property;
    //property value should be empty for marketing activity
    case FILTER_TYPE.MARKETING_ACTIVITY:
      return property !== null;
    default:
      return false;
  }
}

// schedule validation

export function validateSchedule(values: CampaignSchedule) {
  // Previously, we used <form> tag's onChange event. But with the dropdown changes, its doesn't the fire the onChange event
  // So we are checking if the form changed using formik validation which will be called on every change in form fields given validateOnChange is true.

  let errors: FormikErrors<CampaignSchedule> = {};

  const { run_config, run_type } = values;

  function addError(error: { [key: string]: string }) {
    errors = {
      ...errors,
      run_config: {
        ...errors.run_config,
        ...error,
      },
    };
  }

  if (!run_type) {
    return { ...errors, run_type: "Select a schedule type" };
  }
  if (run_type === RUN_TYPE.AT_ONCE) {
    return errors;
  }
  if (!run_config.start_date) {
    addError({ start_date: "Enter a schedule start date and time" });
  }

  if (run_type === RUN_TYPE.ONCE) {
    return errors;
  }
  if (run_config.end_date === "") {
    addError({ end_date: "Enter a schedule end date and time" });
  }
  if (
    run_config.end_date !== "" &&
    run_config.end_date !== undefined &&
    new Date(run_config.end_date) <= new Date(run_config.start_date)
  ) {
    addError({ end_date: "End date must be after start date" });
  }

  if (run_type === RUN_TYPE.PERIOD) {
    errors = validatePeriodicCampaign(run_config, errors);
  }

  if (run_type === RUN_TYPE.RECURRING) {
    errors = validateRecurringCampaign(run_config, errors);
  }
  return errors;
}

function validatePeriodicCampaign(
  runConfig: RunConfig,
  errors: FormikErrors<CampaignSchedule>
) {
  let newErrors: FormikErrors<CampaignSchedule> = errors;

  function addError(error: { [key: string]: string }) {
    newErrors = {
      ...newErrors,
      run_config: {
        ...newErrors.run_config,
        ...error,
      },
    };
  }

  if (!runConfig.frequency) {
    addError({ frequency: "Select a frequency" });
  } else if (
    !Object.values(PERIODIC_FREQUENCIES).includes(
      runConfig.frequency as PERIODIC_FREQUENCIES
    )
  ) {
    addError({ frequency: "Select a valid frequency" });
  }

  if (!runConfig.unit) {
    addError({ unit: "Enter a valid unit" });
  } else if (
    runConfig.frequency === PERIODIC_FREQUENCIES.MINUTES &&
    runConfig.unit < 10
  ) {
    addError({ unit: "Frequency can't be less than 10 minutes" });
  } else if (
    runConfig.frequency === PERIODIC_FREQUENCIES.HOURS &&
    runConfig.unit < 1
  ) {
    addError({ unit: "Frequency can't be less than 1 hour" });
  }

  return newErrors;
}

function validateRecurringCampaign(
  runConfig: RunConfig,
  errors: FormikErrors<CampaignSchedule>
) {
  let newErrors: FormikErrors<CampaignSchedule> = errors;

  function addError(errorMessage: { [key: string]: string }) {
    newErrors = {
      ...newErrors,
      run_config: {
        ...newErrors.run_config,
        freq_options: errorMessage,
      },
    };
  }

  switch (runConfig.frequency) {
    case RECURRING_FREQUENCIES.WEEK:
      if (!runConfig.freq_options.day) {
        addError({ day: "Select a schedule" });
      }
      break;
    case RECURRING_FREQUENCIES.YEAR:
      if (!runConfig.freq_options.date) {
        addError({ date: "Select a schedule year" });
      }
      break;
    case RECURRING_FREQUENCIES.MONTH:
      if (!runConfig.freq_options.date_string) {
        addError({ date_string: "Select a valid option" });
      }

      if (runConfig.freq_options.date_string === "custom") {
        if (
          (runConfig.freq_options.day &&
            (+runConfig.freq_options.day > 31 ||
              +runConfig.freq_options.day < 1)) ||
          !isInteger(runConfig.freq_options.day)
        ) {
          addError({
            ...newErrors.run_config?.freq_options,
            day: "Insert a valid day (1-31)",
          });
        }
        if (!runConfig.freq_options.day) {
          addError({
            ...newErrors.run_config?.freq_options,
            day: "Insert a valid day",
          });
        }
      }
      break;
    case RECURRING_FREQUENCIES.DAY:
      if (!runConfig.freq_options.unit) {
        addError({ unit: "Enter a valid frequency" });
      }
      break;
    default:
      newErrors = {
        ...newErrors,
        run_config: {
          ...newErrors.run_config,
          frequency: "Select a frequency",
        },
      };
  }
  return newErrors;
}
