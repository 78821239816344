import { Flex } from "@chakra-ui/layout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { isLoading } from "../../../common/helper/commonHelper";
import { PersonDestinationFields } from "../../../common/types/person";
import { SOURCES } from "../../../common/types/unifiedMapping";
import SideBar from "../../../components/SideBar";
import SpinnerContainer from "../../../components/SpinnerContainer";
import SecondarySidebar from "../../../components/v2/SecondarySidebar";
import SidebarBackButton from "../../../components/v2/SidebarBackButton";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import {
  getAllConnections,
  selectConnection,
} from "../connection/connectionSlice";
import {
  getAccount,
  getAccountMappingDetails,
  getAccountMembers,
  selectDirectoryAccount,
} from "./accountDbSlice";
import Details from "./components/Details";
import { useSalesforceConnection } from "../../../common/hooks/salesforce";

const SIDEBAR_ITEMS = ["Account Fields", "Members"];

export default function AccountDashboard() {
  const {
    accountDetails: { data: accountDetails, loading: fetchingDetails },
    accountMembersList,
    accountMappingDetails: { data: accountMappingDetails },
  } = useSelector(selectDirectoryAccount);
  const {
    unifiedConnection: {
      connectionList: { list },
    },
  } = useSelector(selectConnection);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!list) {
      dispatch(getAllConnections());
    }
  }, [dispatch, list]);

  useEffect(() => {
    dispatch(getAccountMappingDetails());
  }, [dispatch]);

  const salesforceConnectionId = useMemo(() => {
    const salesforceConnection = list
      ? list.find((item) => item.source === SOURCES.SALESFORCE)
      : null;
    return salesforceConnection?.connection_id ?? null;
  }, [list]);

  const { data: connection } = useSalesforceConnection({
    connectionId: salesforceConnectionId,
  });

  const instanceUrl = useMemo(
    () => (connection ? connection.instanceUrl : ""),
    [connection]
  );

  const details = useMemo(() => {
    if (accountDetails && accountDetails.length && accountMappingDetails) {
      let detailsAccount: PersonDestinationFields = {
        ...accountMappingDetails,
      };
      Object.entries(accountDetails[0]).forEach(([key, details]) => {
        if (key in detailsAccount) {
          detailsAccount[key] = {
            ...detailsAccount[key],
            value: details,
          };
        }
      });
      return detailsAccount;
    }
    return accountMappingDetails;
  }, [accountDetails, accountMappingDetails]);

  useEffect(() => {
    if (id) dispatch(getAccount(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) dispatch(getAccountMembers(id));
  }, [dispatch, id, accountMembersList.currentPageNo]);

  useEffect(() => {
    if (salesforceConnectionId && !SIDEBAR_ITEMS.includes("Salesforce")) {
      SIDEBAR_ITEMS.push("Salesforce");
    }
  }, [salesforceConnectionId]);

  const goToPersonList = useCallback(() => {
    navigate(urls.account);
  }, [navigate]);

  return (
    <Flex>
      <SecondarySidebar
        heading={accountDetails[0]?.name ?? ""}
        backButton={
          <SidebarBackButton
            onClick={goToPersonList}
            text="Back to account list"
          />
        }
      >
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedTab}
          select={setSelectedTab}
        />
      </SecondarySidebar>
      <SpinnerContainer loading={isLoading(fetchingDetails)} height="80vh">
        {accountDetails.length && (
          <Details
            details={details}
            accountMembersList={accountMembersList}
            accountName={accountDetails[0].name}
            accountId={accountDetails[0].id}
            instanceUrl={instanceUrl || ""}
            selectedTab={selectedTab}
          />
        )}
      </SpinnerContainer>
    </Flex>
  );
}
