import IModal from "../../../../components/IModal";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../../store";
import { getOrganisationTraits, selectPerson } from "../personDbSlice";
import { useSelector } from "react-redux";
import {
  formatDataForDisplay,
  isLoading,
} from "../../../../common/helper/commonHelper";
import DisplayGrid from "./DisplayGrid";
import {
  ProductPersonType,
  MAP_TO_DESTINATION_TYPE,
} from "../../../../common/types/person";
import { DESTINATION_TYPES } from "../../../../common/types/unifiedMapping";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { omit } from "lodash";
import { TableList } from "../../../../common/types/campaign";

type OrganisationTraitData = { [key: string]: ProductPersonType[] };

type OrganisationData = {
  traits: OrganisationTraitData;
  properties: OrganisationTraitData;
};

export default function OrgTraitModal({
  isOpen,
  onClose,
  orgId,
  allTableList,
}: {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  allTableList: TableList;
}) {
  const dispatch = useAppDispatch();
  const { organisationDetails } = useSelector(selectPerson);

  useEffect(() => {
    if (orgId) {
      dispatch(getOrganisationTraits(orgId));
    }
  }, [dispatch, orgId]);

  const orgData: OrganisationData = useMemo(() => {
    let newOrgData: OrganisationData = {
      traits: {},
      properties: {},
    };
    if (organisationDetails.data) {
      const filteredObjects = omit(organisationDetails.data, [
        "properties",
        "properties_timestamp",
        "created_time",
      ]);
      const id = organisationDetails.data["id"];
      const filteredOrgKeys = Object.keys(filteredObjects);
      filteredOrgKeys.sort();
      filteredOrgKeys.reverse();
      let organisation = [];
      organisation = filteredOrgKeys.map((value) =>
        formatDataForDisplay(
          value,
          organisationDetails.data[value],
          MAP_TO_DESTINATION_TYPE[allTableList["organisation"]?.[value].type]
        )
      );
      const { properties } = organisationDetails.data;
      newOrgData.properties[id] = Object.entries(properties ?? {}).map(
        ([key, value]) =>
          formatDataForDisplay(key, value as string, DESTINATION_TYPES.STRING)
      );
      newOrgData.traits[id] = organisation;
    }
    return newOrgData;
  }, [organisationDetails.data, allTableList]);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      header={{
        title: `Org Details for ${orgId}`,
      }}
      scrollBehavior="inside"
    >
      <SpinnerContainer loading={isLoading(organisationDetails.loading)}>
        {orgData.traits[orgId] ? (
          <>
            <Box>
              <DisplayGrid entries={orgData.traits[orgId]} />
              {orgData.properties[orgId].length ? (
                <Accordion allowMultiple>
                  <AccordionItem borderColor="white">
                    <AccordionButton>
                      <Box>
                        <AccordionIcon
                          fontWeight="400"
                          fontSize="24px"
                          mr={2}
                        />
                        Org Properties
                      </Box>
                    </AccordionButton>

                    <AccordionPanel px={10}>
                      <DisplayGrid entries={orgData.properties[orgId]} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : null}
            </Box>
          </>
        ) : (
          <Text align="center">No org traits available</Text>
        )}
      </SpinnerContainer>
    </IModal>
  );
}
