import { Box, Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  clearConnectionDetailsData,
  getSyncDetails,
  listConnection,
  listDestinationTables,
  listSourceTableColumns,
  listSourceTables,
  saveSync,
  selectConnection,
  setConnectionDetails,
} from "../connectionSlice";
import {
  TableColumn,
  SyncTablesData,
  TableLink,
  ColSave,
} from "../../../../common/types/connection";
import { CUSTOM_TABLE } from "../../../../common/constants/campaign";
import { cloneDeep, isEmpty } from "lodash";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../../../urls";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { toast } from "react-toastify";
import { PERSON_ENTITY } from "../../../../common/constants/campaign";
import { isLoading, isSuccess } from "../../../../common/helper/commonHelper";
import {
  formatSyncDetails,
  formatTableColumns,
  getSortedColumnsList,
} from "./helper";
import TableImportColumnsList from "./components/TableImportColumnsList";
import SelectedTableSidebar from "./components/SelectedTableSidebar";
import { Header, SubHeader } from "./components/Headers";
import TableSelectForm from "./components/TableSelectForm";

function MainContainer({ children }: { children: React.ReactNode }) {
  return (
    <Flex px={10} alignItems="center" flexDir="column" h="calc(100vh - 130px)">
      <Box w="100%" h="100%" px={2}>
        {children}
      </Box>
    </Flex>
  );
}

export default function ConnectionTableImport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams<{ id: string }>();
  const [syncFrequeny, setSyncFrequency] = useState("1h");
  const [selectedTables, setSelectedTables] = useState<TableLink[]>([]);
  const [activeTableId, setActiveTableId] = useState<number | null>(null);
  const [isOpenDeleteModal, setIsOpenConfirmationModal] = useState(false);
  const [valid, setValidity] = useState<{
    frequency: boolean;
    required: number[];
    pk: boolean;
  }>({ frequency: true, required: [], pk: true });
  const [syncDirty, setSyncDirty] = useState(false);
  const [saveActive, setSaveActive] = useState(false);

  const cancelRef = useRef(null);

  const editMode = useRef(false);

  const onChangeFrequency = (value: string) => {
    setValidity({ ...valid, frequency: !!value });
    setSyncDirty(true);
    setSyncFrequency(value);
  };

  const {
    fetchingDestinationTables,
    fetchingSourceTables,
    fetchingColumns,
    tableList,
    columnList,
    connectionDetails,
    destinationTables,
    savingSync,
    syncDetails,
    connectionList,
  } = useSelector(selectConnection);

  useEffect(() => {
    if (!tableList && connectionDetails) {
      dispatch(listSourceTables({ connectionId: connectionDetails.id }));
    }
  }, [dispatch, tableList, connectionDetails]);

  useEffect(() => {
    if (!destinationTables) {
      dispatch(listDestinationTables());
    }
  }, [dispatch, destinationTables]);

  useEffect(() => {
    if (id) {
      dispatch(getSyncDetails(id));
      editMode.current = true;
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (syncDetails && editMode.current) {
      setSyncFrequency(syncDetails.sync_interval);
      const tableLinks: TableLink[] = formatSyncDetails(
        syncDetails,
        destinationTables
      );
      setSelectedTables(tableLinks);
    }
  }, [syncDetails, destinationTables]);

  useEffect(() => {
    if (editMode.current) {
      if (!connectionList) {
        dispatch(listConnection());
      } else if (!isEmpty(connectionList)) {
        const currentConn = connectionList.find((conn) => conn.id === id);
        dispatch(setConnectionDetails(currentConn || null));
      }
    }
  }, [dispatch, connectionList, id]);

  useEffect(() => {
    return () => {
      dispatch(clearConnectionDetailsData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(savingSync)) navigate(urls.unifiedMapping);
  }, [savingSync, navigate]);

  const getUnmappedColumns = useCallback(
    (table: TableLink): ColSave[] => {
      const selectedColumnsNames = table.columns.map((col) => col.column_name);
      let unselectedColumns: ColSave[] = [];
      if (columnList[table.table_name]) {
        unselectedColumns = columnList[table.table_name]
          .filter((col) => !selectedColumnsNames.includes(col.name))
          .map((x) => {
            return {
              column_name: x.name,
              destination_column_name: "",
              checked: false,
              disabled: false,
              primary_key: false,
            } as ColSave;
          });
      }
      return unselectedColumns;
    },
    [columnList]
  );

  const updateActiveTable = useCallback(
    (activeTableId: number | null, activeTable: TableLink) => {
      const newSelectedTables = cloneDeep(selectedTables);
      newSelectedTables.forEach((table) => {
        if (table.tempId === activeTableId) {
          table = activeTable;
        }
      });
      setSelectedTables(newSelectedTables);
    },
    [selectedTables]
  );

  const setColumnListForActiveTable = useCallback(() => {
    const activeTable = selectedTables.find(
      (table) => table.tempId === activeTableId
    );

    if (activeTable && !isEmpty(columnList[activeTable.table_name])) {
      const unmappedColumns = activeTable
        ? getUnmappedColumns(activeTable)
        : [];
      if (editMode.current && !isEmpty(unmappedColumns)) {
        activeTable.columns = [...activeTable.columns, ...unmappedColumns];
        updateActiveTable(activeTableId, activeTable);
      } else if (isEmpty(activeTable.columns)) {
        activeTable.columns = columnList[activeTable.table_name].map<ColSave>(
          (col) => {
            return {
              column_name: col.name,
              destination_column_name: "",
              checked: false,
            };
          }
        );
        updateActiveTable(activeTableId, activeTable);
      }
    }
  }, [
    getUnmappedColumns,
    updateActiveTable,
    activeTableId,
    columnList,
    selectedTables,
  ]);

  useEffect(() => {
    setColumnListForActiveTable();
  }, [columnList, activeTableId, selectedTables, setColumnListForActiveTable]);

  const fetchColumns = (selectedTable: TableLink) => {
    if (connectionDetails) {
      dispatch(
        listSourceTableColumns({
          id: connectionDetails.id,
          tableName: selectedTable.table_name,
        })
      );
    }
    setActiveTableId(selectedTable.tempId);
  };

  function unlinkTable(tempId: number) {
    const filteredTables = selectedTables.filter(
      (table) => table.tempId !== tempId
    );

    setSelectedTables([...filteredTables]);
    setActiveTableId(null);
  }

  function addNewTable(table: TableLink) {
    setSelectedTables([...selectedTables, table]);
    fetchColumns(table);
  }

  function toggleCheckbox(
    event: ChangeEvent<HTMLInputElement>,
    column: ColSave
  ) {
    const newSelectedTables = cloneDeep(selectedTables);

    newSelectedTables.forEach((table) => {
      if (table.tempId === activeTableId) {
        table.columns.forEach((col) => {
          if (col.column_name === column.column_name) {
            col.checked = event.target.checked;
          }
        });
        validateRequired(table);
      }
    });
    setSyncDirty(true);
    setSelectedTables(newSelectedTables);
  }

  function validateRequired(table: TableLink) {
    let requiredValid = true,
      customValid = true;
    //custom table atleast one column check
    if (table.destination_table_name === CUSTOM_TABLE) {
      customValid = table.columns.some((column) => column.checked);
    }
    //default table required columns check
    else {
      const destinationTableColumnsSorted = getSortedColumnsList(
        destinationTables ?? [],
        table.destination_table_name
      );
      destinationTableColumnsSorted
        .filter((column) => column.required)
        .forEach((destination) => {
          if (
            table.columns.every(
              (source) => source.destination_column_name !== destination.name
            )
          ) {
            requiredValid = false;
          }
        });
    }

    let invalidList = valid.required;
    if (
      requiredValid &&
      customValid &&
      table.tempId &&
      invalidList.includes(table.tempId)
    ) {
      const index = invalidList.indexOf(table.tempId);
      invalidList.splice(index, 1);
    } else if (
      !(requiredValid && customValid) &&
      table.tempId &&
      !invalidList.includes(table.tempId)
    ) {
      invalidList.push(table.tempId);
    }

    //primary key for person table check
    if (table.destination_table_name === PERSON_ENTITY) {
      const pkValid = table.columns.some((column) => column.primary_key);
      setValidity({
        ...valid,
        required: invalidList,
        pk: pkValid,
      });
      return [requiredValid, customValid, pkValid];
    } else {
      setValidity({ ...valid, required: invalidList });
      return [requiredValid, customValid, true];
    }
  }

  function validateForm() {
    let requiredValid = true,
      customValid = true,
      pkValid = true;
    let errors = [];
    selectedTables.forEach((table) => {
      const [required, custom, pk] = validateRequired(table);
      requiredValid = requiredValid && required;
      customValid = customValid && custom;
      pkValid = pkValid && pk;
    });
    if (!requiredValid) errors.push("Required fields are missing");
    if (!customValid) errors.push("Custom table needs atleast one column");
    if (!pkValid)
      errors.push("Primary key should be selected for person table");
    const valid = requiredValid && customValid && pkValid;
    if (!valid) toast.error(errors.join(", "));
    return valid;
  }

  function setDestinationColumnName(
    sourceColumnName: string,
    destinationColumn: TableColumn
  ) {
    const newSelectedTables = cloneDeep(selectedTables);
    newSelectedTables.forEach((table) => {
      if (table.tempId === activeTableId) {
        table.columns.forEach((col) => {
          if (col.destination_column_name === destinationColumn.name) {
            col.destination_column_name = "";
            col.required = undefined;
          }
        });
        if (sourceColumnName) {
          table.columns.forEach((col) => {
            if (col.column_name === sourceColumnName) {
              col.destination_column_name = destinationColumn.name;
              col.required = destinationColumn.required;
            }
          });
        }
        saveActive && validateRequired(table);
      }
    });
    setSyncDirty(true);
    setSelectedTables(newSelectedTables);
  }

  function saveSyncHandler() {
    if (connectionDetails) {
      const tables = selectedTables.map(
        ({ columns, destination_table_name, table_name }) => {
          const formattedColumns = formatTableColumns(columns);
          if (destination_table_name !== CUSTOM_TABLE) {
            return {
              columns: formattedColumns,
              destination_table_name,
              table_name,
            };
          } else {
            return {
              columns: formattedColumns,
              table_name,
            };
          }
        }
      );

      const data: SyncTablesData = {
        connection_id: connectionDetails.id,
        tables,
        sync_interval: syncFrequeny,
        enable_sync: true,
      };

      dispatch(saveSync(data));
      setIsOpenConfirmationModal(false);
    }
  }

  function selectAllCustomColumns(checked: boolean) {
    const newSelectedTables = cloneDeep(selectedTables);

    newSelectedTables.forEach((table) => {
      if (table.tempId === activeTableId) {
        table.columns.forEach((col) => {
          if (col.disabled && editMode.current) {
            return;
          }
          col.checked = checked;
        });
        validateRequired(table);
      }
    });
    setSyncDirty(true);
    setSelectedTables(newSelectedTables);
  }

  function openSaveConfirmation() {
    setSaveActive(true);
    if (validateForm() && valid.frequency) setIsOpenConfirmationModal(true);
  }

  function navigateBack() {
    if (editMode.current) {
      navigate(`${urls.unifiedMapping}/${id}`);
    } else {
      navigate(urls.unifiedMapping);
    }
  }

  function togglePrimaryKey(
    tableCol: TableColumn,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const newSelectedTables = cloneDeep(selectedTables);

    newSelectedTables.forEach((table) => {
      if (table.tempId === activeTableId) {
        table.columns.forEach((col) => {
          if (
            col.destination_column_name &&
            col.destination_column_name === tableCol.name
          ) {
            col.primary_key = event.target.checked;
          } else {
            col.primary_key = false;
          }
        });
        if (table.destination_table_name === PERSON_ENTITY) {
          setValidity({
            ...valid,
            pk: table.columns.some((column) => column.primary_key),
          });
        }
      }
    });
    setSyncDirty(true);
    setSelectedTables(newSelectedTables);
  }

  return (
    <SpinnerContainer
      loading={fetchingSourceTables || fetchingDestinationTables}
    >
      <Box>
        <Header
          navigateBack={navigateBack}
          savingSync={isLoading(savingSync)}
          syncDirty={syncDirty}
          connectionName={connectionDetails?.label || ""}
          saveSync={openSaveConfirmation}
        />
        <MainContainer>
          <SubHeader
            syncFrequeny={syncFrequeny}
            onChangeFrequency={onChangeFrequency}
          />

          {tableList && destinationTables && (
            <TableSelectForm
              selectedTables={selectedTables}
              sourceTables={tableList}
              destinationTables={destinationTables}
              addNewTable={addNewTable}
            />
          )}

          <Flex h="auto" overflowX="scroll">
            <SelectedTableSidebar
              selectedTables={selectedTables}
              activeTableId={activeTableId}
              fetchColumns={fetchColumns}
              unlinkTable={unlinkTable}
              invalidList={valid.required}
              primaryKeyValid={valid.pk}
            />

            <TableImportColumnsList
              togglePrimaryKey={togglePrimaryKey}
              loading={fetchingColumns}
              activeTable={selectedTables.find(
                (table) => table.tempId === activeTableId
              )}
              destinationTables={destinationTables}
              toggleCheckbox={toggleCheckbox}
              setDestinationColumnName={setDestinationColumnName}
              selectAllCustomColumns={selectAllCustomColumns}
              editMode={editMode}
              saveActive={saveActive}
              invalidList={valid.required}
              pkValid={valid.pk}
            />
          </Flex>
        </MainContainer>
        <ConfirmationModal
          title="Syncs are not editable later"
          cancelButtonText="Edit sync instead"
          confirmButtonText="Continue to save sync"
          isOpen={isOpenDeleteModal}
          cancelRef={cancelRef}
          onCancel={() => setIsOpenConfirmationModal(false)}
          submitHandler={saveSyncHandler}
        >
          <Text mb={5} color="gray.600">
            Once saved a sync you can’t delete columns or tables although
            <Text
              as="span"
              ml="2"
              fontWeight="semibold"
              fontStyle="italic"
              color="blue.700"
            >
              you can still add new columns.
            </Text>
          </Text>
        </ConfirmationModal>
      </Box>
    </SpinnerContainer>
  );
}
