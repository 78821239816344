import { defineStyleConfig } from "@chakra-ui/react";

const drawerStyle = defineStyleConfig({
  sizes: {
    "2xl": {
      dialog: {
        maxWidth: "1008px",
      },
    },
    "3xl": {
      dialog: {
        maxW: "1152px",
      },
    },
    "4xl": {
      dialog: {
        maxW: "1300px",
      },
    },
    "filter-sm": {
      dialog: {
        maxW: "550px",
      },
    },
    "filter-md": {
      dialog: {
        maxW: "650px",
      },
    },
    "filter-lg": {
      dialog: {
        maxW: "800px",
      },
    },
  },
});

export default drawerStyle;
