import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  Image,
  Menu,
  MenuButton,
  HStack,
  MenuList,
  MenuItem,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SearchField } from "../../../../../components/SearchField";
import { useAppDispatch } from "../../../../../store";
import { useSelector } from "react-redux";
import {
  clearTemplateCreatedId,
  createTemplate,
  getTemplate,
  listInfiniteTemplates,
  resetInfiniteTemplateList,
  selectTemplate,
  setInfiniteTemplateListPageNo,
} from "../../../templates/templateSlice";
import CommonDrawer from "../../components/CommonDrawer";
import { useHover } from "../../../../../common/hooks/commonHooks";
import SortFilter, {
  SortAssetsRequestsType,
} from "../../../../../components/SortFilter";

import {
  TemplateType,
  TEMPLATE_EDITOR_MODE,
} from "../../../../../common/types/template";
import { HiEye } from "react-icons/hi";
import {
  FaChevronDown,
  FaExternalLinkAlt,
  FaSortAmountDownAlt,
  FaSortAmountUpAlt,
} from "react-icons/fa";
import { RiMailAddFill } from "react-icons/ri";
import {
  generateRandomString,
  isInit,
  isLoading,
  openAssetInNewTab,
} from "../../../../../common/helper/commonHelper";
import { createTemplateApi } from "../../../../../common/api/campaign/template";
import {
  SORT_BY_OPTIONS,
  SORT_ORDER_OPTIONS,
} from "../../../../../components/SortFilter";
import CreateNewTemplateModal from "../../../templates/CreateNewTemplateModal";
import IDuplicateComponent from "../../../../../components/IDuplicateComponent";
import { IVirtualList } from "../../../../../components/IVirtual";
import ISkeleton, {
  SKELETON_VARIANT,
} from "../../../../../components/ISkeleton";
import { ASSET_TYPES } from "../../../../../common/constants/common";

const NEW_TEMPLATE_PREFIX = "template-";
const SORT_MENU_OPTIONS = [
  {
    label: "Name (A-Z)",
    sortByOption: SORT_BY_OPTIONS.NAME,
    sortOrder: SORT_ORDER_OPTIONS.ASC,
    sortOrderIcon: <FaSortAmountUpAlt />,
  },
  {
    label: "Last modified",
    sortByOption: SORT_BY_OPTIONS.LAST_MODIFIED_AT,
    sortOrder: SORT_ORDER_OPTIONS.DESC,
    sortOrderIcon: <FaSortAmountDownAlt />,
  },
  {
    label: "Last created",
    sortByOption: SORT_BY_OPTIONS.CREATED_AT,
    sortOrder: SORT_ORDER_OPTIONS.DESC,
    sortOrderIcon: <FaSortAmountDownAlt />,
  },
];
const INIT_SORT_FILTER = {
  sortByOption: SORT_BY_OPTIONS.LAST_MODIFIED_AT,
  sortOrder: SORT_ORDER_OPTIONS.DESC,
};
function TemplateListLoader() {
  return (
    <IDuplicateComponent duplicateCount={5}>
      <ISkeleton variant={SKELETON_VARIANT.TEMPLATE} mb={3} ml={3} />
    </IDuplicateComponent>
  );
}

function NoResultsFoundView({ searchKeyword }: { searchKeyword: string }) {
  const {
    onOpen: openCreateTemplateModal,
    onClose: closeCreateTemplateModal,
    isOpen: isCreateTemplateModalOpen,
  } = useDisclosure();

  const dispatch = useAppDispatch();

  async function handleOnSubmitCreateTemplate(
    value: string | null,
    editorType: TEMPLATE_EDITOR_MODE
  ) {
    const response = await dispatch(
      createTemplate({
        name: value ?? "",
        templateType: editorType,
      })
    );
    const { template } = response.payload as Awaited<
      ReturnType<typeof createTemplateApi>
    >;
    closeCreateTemplateModal();
    openAssetInNewTab(ASSET_TYPES.TEMPLATE, template.template_id);
    dispatch(clearTemplateCreatedId());
  }

  return (
    <>
      <Box
        h={150}
        w="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text color="gray.400">No results found</Text>
      </Box>
      <Divider />
      <Box
        mt={2}
        h={90}
        borderRadius={10}
        w="100%"
        bg="gray.200"
        style={{ cursor: "pointer" }}
        onClick={() => {
          openCreateTemplateModal();
        }}
      >
        <Grid gridTemplateColumns={"65px 1fr"} p="15px">
          <GridItem>
            <Icon as={RiMailAddFill} fontSize={60} color="brandBlue.500" />
          </GridItem>
          <GridItem>
            <Box fontSize="10px" fontWeight="600" color="brandBlue.500" pt={3}>
              Interested in creating a new Email?
            </Box>
            <Text fontSize="10px" color="brandBlue.500" fontWeight="400" pt={1}>
              Click here to navigate to the email editor to create a new email
              asset{" "}
              <Text as="span">
                <Icon
                  as={FaExternalLinkAlt}
                  color="brandBlue.500"
                  fontSize="10px"
                />
              </Text>
            </Text>
          </GridItem>
        </Grid>
      </Box>
      <CreateNewTemplateModal
        isOpen={isCreateTemplateModalOpen}
        onClose={closeCreateTemplateModal}
        templateName={searchKeyword}
        handleSubmit={handleOnSubmitCreateTemplate}
      />
    </>
  );
}

function DarkOverlayForThumbnailOnHover({ ...props }: BoxProps) {
  return (
    <Box
      {...props}
      position="absolute"
      bg="blackAlpha.800"
      w="100%"
      h="100%"
      borderRadius="6px"
    />
  );
}

// adding custom values to reuse the component in the email widget

export function ThumbnailContainer({
  templateDetails,
  handleTemplatePreview,
}: {
  templateDetails: TemplateType;
  handleTemplatePreview: () => void;
}) {
  const dispatch = useAppDispatch();

  function handleOnClickThumbnail() {
    handleTemplatePreview();
    // if the template details are present in collection, get api won't be called
    dispatch(getTemplate({ id: templateDetails.template_id, isPreview: true }));
  }

  const [hovered, eventHandlers] = useHover();

  return (
    <Box
      {...eventHandlers}
      bg="gray.200"
      borderRadius="6px"
      w="93px"
      h="100px"
      position="relative"
      display="flex"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      role="group"
      boxShadow="0px 0px 5px rgba(0, 0, 0, 0.05)"
      onClick={handleOnClickThumbnail}
    >
      {templateDetails.previews ? (
        <Image
          src={templateDetails.previews?.img_thumb}
          alt="Email asset unavailable"
          height="fit-contemt"
          maxH="100%"
        />
      ) : (
        <Box
          w="70px"
          h="100%"
          bg="whiteAlpha.900"
          fontSize="6px"
          color="gray.400"
          pt={10}
          pl={1}
        >
          No email asset added
        </Box>
      )}

      <DarkOverlayForThumbnailOnHover
        opacity={hovered ? "100%" : "0%"}
        transition=" opacity 0.2s ease-in-out"
      />

      <Flex
        direction="column"
        align="center"
        position="absolute"
        opacity={hovered ? "100%" : "0%"}
        transition="opacity 0.2s ease-in-out"
        style={{ cursor: "pointer" }}
      >
        <Icon as={HiEye} color="gray.300" fontSize="20px" />
        <Text fontSize="10px" color="gray.300" fontWeight="600">
          Click to preview
        </Text>
      </Flex>
    </Box>
  );
}

function TemplateItemContent({
  templateDetails,
  onSelectTemplate,
  handleTemplatePreview,
  loading,
}: {
  templateDetails: TemplateType;
  onSelectTemplate: () => void;
  handleTemplatePreview: () => void;
  loading?: boolean;
}) {
  const [hovered, eventHandlers] = useHover();
  const [iconHovered = hovered, iconHoveredEventHandlers = eventHandlers] =
    useHover();

  return (
    <Grid
      gridTemplateColumns={"110px 300px 20px"}
      key={templateDetails.template_id}
      style={{
        padding: "10px",
        width: "465px",
        height: "120px",
        borderRadius: "10px",
        cursor: loading ? "wait" : "pointer",
      }}
      _hover={iconHovered ? {} : { bg: "grayV2.100" }}
      {...eventHandlers}
    >
      <GridItem>
        <ThumbnailContainer
          templateDetails={templateDetails}
          handleTemplatePreview={handleTemplatePreview}
        />
      </GridItem>
      <GridItem onClick={onSelectTemplate}>
        <Box
          fontSize="sm"
          fontWeight={600}
          color="brandBlue.500"
          maxW="330px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {templateDetails.name}
        </Box>
        <Box
          fontSize="sm"
          color="brandBlue.500"
          pt={2}
          maxW="330px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {templateDetails.subject ?? "(empty)"}
        </Box>
      </GridItem>
      <GridItem>
        {hovered && (
          <IconButton
            icon={<FaExternalLinkAlt />}
            fontSize="xs"
            aria-label="external-link"
            color="brandBlue.500"
            variant="ghost"
            onClick={() =>
              openAssetInNewTab(
                ASSET_TYPES.TEMPLATE,
                templateDetails.template_id
              )
            }
            {...iconHoveredEventHandlers}
          />
        )}
      </GridItem>
    </Grid>
  );
}

function AddNewEmailDropDown() {
  const dispatch = useAppDispatch();

  async function handleAddEmailMenuOptions(templateType: TEMPLATE_EDITOR_MODE) {
    const response = await dispatch(
      createTemplate({
        name: generateRandomString(5, NEW_TEMPLATE_PREFIX),
        templateType,
      })
    );
    const { template } = response.payload as Awaited<
      ReturnType<typeof createTemplateApi>
    >;
    openAssetInNewTab(ASSET_TYPES.TEMPLATE, template.template_id);
  }
  return (
    <Menu>
      <MenuButton
        height="32px"
        width="90px"
        borderRadius="4px"
        border="1px solid"
        borderColor="gray.200"
        p={2}
      >
        <HStack>
          <Icon as={RiMailAddFill} fontSize="lg" color="brandBlue.500" />
          <Text fontSize="sm" color="brandBlue.400">
            New
          </Text>
          <Icon as={FaChevronDown} fontSize="xs" color="brandBlue.500" />
        </HStack>
      </MenuButton>
      <MenuList fontSize="sm">
        <MenuItem
          onClick={() => {
            handleAddEmailMenuOptions(TEMPLATE_EDITOR_MODE.DRAG_DROP);
          }}
        >
          Visual editor
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAddEmailMenuOptions(TEMPLATE_EDITOR_MODE.CODE);
          }}
        >
          HTML editor (Advanced)
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default function TemplatesDrawer({
  onClose,
  isOpen,
  handleSelectChange,
  openTemplateSelectionPreview,
  loading = false,
}: {
  onClose: () => void;
  isOpen: boolean;
  handleSelectChange: (templateDetails: TemplateType) => void;
  openTemplateSelectionPreview: () => void;
  loading?: boolean;
}) {
  const dispatch = useAppDispatch();

  const {
    infiniteTemplateList: {
      list: templateList,
      loadingList,
      currentPageNo,
      totalPageCount,
    },
  } = useSelector(selectTemplate);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortFilter, setSortFilter] =
    useState<Required<SortAssetsRequestsType>>(INIT_SORT_FILTER);

  useEffect(() => {
    if (isOpen) {
      dispatch(resetInfiniteTemplateList());
      dispatch(
        listInfiniteTemplates({
          searchKeyword,
          ...sortFilter,
          isPreview: true,
        })
      );
    } else {
      setSearchKeyword("");
      setSortFilter(INIT_SORT_FILTER);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen]);

  const searchIfValid = useCallback(
    (searchKeyword: string) => {
      dispatch(
        listInfiniteTemplates({
          searchKeyword,
          ...sortFilter,
          isPreview: true,
          isFilterUpdated: true,
        })
      );
    },
    [dispatch, sortFilter]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  //sort options

  const handleSortOptionChange = useCallback(
    (sortByOption: SORT_BY_OPTIONS, sortOrder: SORT_ORDER_OPTIONS) => {
      setSortFilter({
        sortByOption,
        sortOrder,
      });
      dispatch(
        listInfiniteTemplates({
          searchKeyword,
          isPreview: true,
          isFilterUpdated: true,
          sortByOption,
          sortOrder,
        })
      );
    },
    [searchKeyword, dispatch]
  );

  const renderTemplateListItem = useMemo(
    () => (index: number, templateDetails: TemplateType) => {
      return (
        <TemplateItemContent
          key={templateDetails.template_id}
          templateDetails={templateDetails}
          onSelectTemplate={() => handleSelectChange(templateDetails)}
          handleTemplatePreview={openTemplateSelectionPreview}
          loading={loading}
        />
      );
    },
    [handleSelectChange, openTemplateSelectionPreview, loading]
  );

  function loadMoreTemplates() {
    const fetchNextPage = currentPageNo + 1 <= (totalPageCount ?? 1);

    if (fetchNextPage && !isLoading(loadingList)) {
      dispatch(setInfiniteTemplateListPageNo(currentPageNo + 1));
      dispatch(
        listInfiniteTemplates({
          searchKeyword,
          isPreview: true,
          ...sortFilter,
        })
      );
    }
  }

  return (
    <CommonDrawer
      title="Email library"
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      drawerBodyProps={{
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Flex direction="row" gap="2" flexWrap={"wrap"} mb={3}>
        <SearchField
          flex="1"
          placeholder="Search for email assets"
          value={searchKeyword}
          name="search-input"
          onSearch={(keyword: string) => {
            setSearchKeyword(keyword);
            debouncedSearch(keyword);
          }}
        />
        <SortFilter
          menuButtonProps={{
            width: "135px",
          }}
          sortMenuOptions={SORT_MENU_OPTIONS}
          handleSortChange={handleSortOptionChange}
          defaultSortByOptionLabel={SORT_MENU_OPTIONS[1].label}
        />
        <AddNewEmailDropDown />
      </Flex>
      <IVirtualList
        list={templateList}
        fetchingList={isLoading(loadingList) || isInit(loadingList)}
        loadMoreData={loadMoreTemplates}
        LoaderAtEnd={TemplateListLoader}
        renderListItem={renderTemplateListItem}
        containerProps={{
          height: "calc(100% - 40px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        emptyState={<NoResultsFoundView searchKeyword={searchKeyword} />}
      />
    </CommonDrawer>
  );
}
