import { Box, HStack, Icon, Text, VStack, Spacer } from "@chakra-ui/react";
import { SearchField } from "../../../../components/SearchField";
import { useMemo, useState, useEffect, useCallback } from "react";
import { DataTable } from "../../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { FaArrowRight } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import TextWithWarning from "../../../../components/TextWithWarning";
import { useAppDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import {
  selectPerson,
  getPersonMappingDetails,
} from "../../persondb/personDbSlice";
import {
  getUploadSummary,
  selectContactUpload,
  updateCsvMapping,
  updateMappedFields,
} from "../contactUploadSlice";
import {
  useDebouncedSearch,
  useExponentialPolling,
} from "../../../../common/hooks/commonHooks";
import {
  addSuffixForPlural,
  isBlank,
  isFailed,
  isFulfilled,
  isLoading,
  isSuccess,
} from "../../../../common/helper/commonHelper";
import { isEmpty } from "lodash";
import {
  MappedFieldsWithDestId,
  DestIdType,
  CsvMappingWithDestId,
} from "../../../../common/types/contactUpload";
import IButton from "../../../../components/IButton";
import { DESTINATION_TYPE_SPECIFIC_ICONS } from "../../../../common/constants/unifiedMapping";
import { COLUMN_FILTERS } from "../../../../common/types/contactUpload";
import { DestinationField, CsvColumnFilter } from "./HelperComponents";
import PageNavigationButtons from "../../../../components/PageNavigationButtons";
import { getUploadSummaryApi } from "../../../../common/api/campaign/contactUpload";

const EMAIL_ID = "email";
const EMPTY_VALUE = "(empty)";

function applySearchToCsvHeaders(
  mapping: MappedFieldsWithDestId[],
  keyword: string
): MappedFieldsWithDestId[] {
  const searchText = keyword.toLowerCase();
  return mapping.filter(({ csvColumn }) =>
    csvColumn.toLowerCase().includes(searchText)
  );
}

function applySearchAndFilter(
  mapping: MappedFieldsWithDestId[],
  searchKeyword: string,
  filter: COLUMN_FILTERS
): MappedFieldsWithDestId[] {
  if (filter === COLUMN_FILTERS.ALL_FIELDS) {
    return applySearchToCsvHeaders(mapping, searchKeyword);
  }

  const filteredFields = mapping.filter(({ destId }) =>
    filter === COLUMN_FILTERS.MAPPED_FIELDS ? !!destId : !destId
  );
  return applySearchToCsvHeaders(filteredFields, searchKeyword);
}

export default function CsvToContactDbMapper({
  goToNext,
}: {
  goToNext: () => void;
}) {
  const {
    personMappingDetails: {
      data: personMappingData,
      loading: loadingPersonMapping,
    },
  } = useSelector(selectPerson);

  const {
    fileUpload: { sampleCsvRecords, csvColumnHeaders, uploadId },
    csvSmartMapping: { loading: smartMappingLoading, isUpdating, mappedFields },
    uploadSummary: { loading: summaryLoading },
  } = useSelector(selectContactUpload);

  const [mappingData, setMappingData] = useState(mappedFields);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [columnsFilter, setColumnsFilter] = useState(COLUMN_FILTERS.ALL_FIELDS);
  const [sampleIndex, setSampleIndex] = useState(0);

  const [isFiltering, setIsFiltering] = useState(false);
  const [mappingChanged, setMappingChanged] = useState(false);

  const dispatch = useAppDispatch();

  //filtering and searching is done in FE
  const filterMappingData = useCallback(
    (searchTxt: string, columnFilter = columnsFilter) => {
      if (!isEmpty(mappedFields)) {
        setIsFiltering(true);
        const filteredData = applySearchAndFilter(
          mappedFields,
          searchTxt,
          columnFilter
        );
        setMappingData(filteredData);

        setIsFiltering(false);
      }
    },
    [mappedFields, columnsFilter]
  );

  useEffect(() => {
    dispatch(getPersonMappingDetails());
  }, [dispatch]);

  useEffect(() => {
    filterMappingData(searchKeyword);

    // searchKeyword and mappedFields omitted here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsFilter]);

  useEffect(() => {
    const filteredData = applySearchAndFilter(
      mappedFields,
      searchKeyword,
      columnsFilter
    );
    setMappingData(filteredData);

    //searchKeyword and columnsFilter omitted here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedFields]);

  const filterDestinationCols = useMemo(() => {
    return Object.keys(personMappingData)
      .filter((destName) => !personMappingData[destName].hidden)
      .map((destName) => {
        const { display, sync_preference, type } = personMappingData[destName];
        return {
          label: display,
          value: destName,
          icon: DESTINATION_TYPE_SPECIFIC_ICONS[type],
          required: destName === EMAIL_ID,
          salesforcePreference: sync_preference,
        };
      });
  }, [personMappingData]);

  const searchColumns = useCallback(
    (keyword: string) => {
      filterMappingData(keyword);
    },
    [filterMappingData]
  );

  const debounceSearch = useDebouncedSearch({
    searchIfValid: searchColumns,
  });

  const onMappingChange = useCallback(
    (csvColumn: string, destId: DestIdType | null) => {
      setMappingChanged(true);
      dispatch(updateMappedFields({ csvColumn, destId }));
    },
    [dispatch]
  );

  const [polling, setPolling] = useState(false);

  const pollReportGeneration = useCallback(async () => {
    const {
      meta: { requestStatus },
      payload: summaryResp,
    } = await dispatch(getUploadSummary(uploadId));
    if (isFulfilled(requestStatus)) {
      const { data, error } = summaryResp as Awaited<
        ReturnType<typeof getUploadSummaryApi>
      >;
      const stopPolling = !!error || !isEmpty(data);
      setPolling(!stopPolling);
      if (stopPolling) {
        goToNext();
      }
    } else {
      setPolling(false);
    }
  }, [dispatch, uploadId, goToNext]);

  useExponentialPolling({
    onPolling: pollReportGeneration,
    shouldPoll: polling,
    initialWaitTime: 0,
  });

  async function updateMappingGetSummary() {
    if (!isSuccess(isUpdating) || isFailed(summaryLoading) || mappingChanged) {
      const mappedFieldsData: CsvMappingWithDestId = Object.assign(
        {},
        ...mappedFields.map(({ csvColumn, destId }) => {
          return {
            [csvColumn]: destId ?? null,
          };
        })
      );

      const updateResp = await dispatch(
        updateCsvMapping({ uploadId, mappedFields: mappedFieldsData })
      );

      if (isFulfilled(updateResp.meta.requestStatus)) {
        setPolling(true);
      }
    } else {
      goToNext();
    }
  }

  const columnHelper = useMemo(
    () => createColumnHelper<MappedFieldsWithDestId>(),
    []
  );
  const emailMapped = useMemo(
    () => mappedFields.some(({ destId }) => destId === EMAIL_ID),
    [mappedFields]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("csvColumn", {
        cell: (info) => (
          <Text fontSize="14px" px={3} py={2}>
            {info.row.original.csvColumn}
          </Text>
        ),
        header: "CSV Field Name",
        minSize: 270,
      }),
      columnHelper.display({
        cell: (info) => (
          <Box p={2} w="30px">
            <Icon as={FaArrowRight} fontSize="14px" />
          </Box>
        ),
        id: "mapped_to_arrow",
        minSize: 10,
      }),
      columnHelper.display({
        cell: (info) => {
          const { csvColumn, destId } = info.row.original;
          return (
            <DestinationField
              fieldData={{
                csvColumn,
                destId,
              }}
              onDestinationChange={onMappingChange}
              personDestColumns={filterDestinationCols}
              isDestFieldsLoading={isLoading(loadingPersonMapping)}
              mappedFields={mappedFields}
              isDisabled={isLoading(isUpdating) || isLoading(summaryLoading)}
            />
          );
        },

        header: () => {
          return (
            <TextWithWarning
              text="Contact Property"
              warningText={
                emailMapped
                  ? ""
                  : "It is required to map the Email contact property to the CSV field to save the mapping"
              }
            />
          );
        },
        minSize: 300,
        id: "destination_map",
      }),
      columnHelper.display({
        cell: (info) => (
          <Box p={2} w="50px">
            {info.row.original.destId && (
              <Icon as={MdCheck} color="green.500" fontSize="20px" />
            )}
          </Box>
        ),
        id: "mapped",
        maxSize: 10,
      }),
      columnHelper.display({
        cell: (info) => {
          const sampleValue =
            sampleCsvRecords[sampleIndex]?.[info.row.original.csvColumn];
          return (
            <Box bg="gray.50" minH="50px" p={3}>
              {isBlank(sampleValue) ? (
                <Text color="gray.400">{EMPTY_VALUE}</Text>
              ) : (
                <Text> {sampleValue}</Text>
              )}
            </Box>
          );
        },
        header: () => (
          <HStack justifyContent="space-between" w="100%">
            <Text>Sample Preview</Text>
            <PageNavigationButtons
              pageNo={sampleIndex + 1}
              totalPageCount={6}
              forwardButtonProps={{
                onClick: () => setSampleIndex(sampleIndex + 1),
              }}
              backwardButtonProps={{
                onClick: () => setSampleIndex(sampleIndex - 1),
              }}
            />
          </HStack>
        ),
        minSize: 270,
        id: "display-fields",
      }),
    ],
    [
      columnHelper,
      onMappingChange,
      filterDestinationCols,
      loadingPersonMapping,
      mappedFields,
      isUpdating,
      summaryLoading,
      emailMapped,
      sampleCsvRecords,
      sampleIndex,
    ]
  );
  const mappedItemsCount = mappedFields.filter(({ destId }) => !!destId).length;
  const totalItems = csvColumnHeaders.length;
  const shouldGoToNext =
    isLoading(isUpdating) ||
    isLoading(smartMappingLoading) ||
    isLoading(summaryLoading) ||
    polling;

  return (
    <VStack w="100%" h="100%" alignItems="flex-start" spacing={2}>
      <Text fontSize="14px">
        Make sure your file contains email addresses before uploading it, as
        these are necessary to create or update Contacts.
      </Text>
      <HStack w="100%" justifyContent="space-between" pt={2}>
        <Text fontSize="12px" color="gray.500">
          {mappedItemsCount} of {totalItems}{" "}
          {addSuffixForPlural("item", totalItems)} mapped
        </Text>
        <Spacer />
        <SearchField
          placeholder="Search columns"
          name="search-columns"
          value={searchKeyword}
          onSearch={(keyword) => {
            setSearchKeyword(keyword);
            debounceSearch(keyword);
          }}
          w="210px"
        />
        <CsvColumnFilter
          value={columnsFilter}
          onFilterChange={setColumnsFilter}
        />
      </HStack>

      <DataTable
        columns={columns}
        list={mappingData}
        fetchingList={isLoading(smartMappingLoading) || isFiltering}
        changingPage={false}
        //initial page to load
        totalPageSize={mappingData.length ? mappingData.length : 10}
        setPage={() => {}}
        totalPageCount={1}
        currentPage={1}
        tableHeight="calc(100vh - 210px)"
        emptyMsg="No fields to map"
        tablePadding={{ customCellPadding: "0px" }}
      />

      <IButton
        isLoading={shouldGoToNext}
        isDisabled={!emailMapped}
        onClick={updateMappingGetSummary}
        alignSelf="flex-end"
        px={4}
      >
        Next
      </IButton>
    </VStack>
  );
}
