import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { Text } from "@chakra-ui/react";
import { useRef } from "react";

export default function AbortExportModal({
  isOpen,
  onCancelExport,
  onContinueExport,
}: {
  isOpen: boolean;
  onCancelExport: () => void;
  onContinueExport: () => void;
}) {
  const cancelRef = useRef(null);
  return (
    <ConfirmationModal
      isOpen={isOpen}
      cancelRef={cancelRef}
      title="Cancel Export"
      onCancel={onCancelExport}
      submitHandler={onContinueExport}
      cancelButtonText="Cancel export"
      confirmButtonText="Continue export"
    >
      <Text>
        The selected lists are still undergoing processing. Would you like to
        cancel the export?
      </Text>
    </ConfirmationModal>
  );
}
