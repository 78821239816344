import { Image } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StaticListDetails } from "../../../common/types/campaign";
import {
  findUserFromMapping,
  getTableRowLinkProps,
  getUserStatusFromMapping,
  isLoading,
  isSuccess,
} from "../../../common/helper/commonHelper";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  LOADING_STATES,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import { useNavigate } from "react-router-dom";
import urls from "../../../urls";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import {
  createStaticList,
  deleteStaticList,
  listStaticLists,
  renameStaticList,
  resetData,
  resetStaticListsList,
  selectStaticList,
} from "./staticlistSlice";
import { staticListColumnDefs } from "./components/tableDefinitions";
import { getUserList, selectAccount } from "../../account/accountSlice";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { DataTable } from "../../../components/data-table/DataTable";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import {
  HeaderSearchProps,
  ListFilterProps,
  PaginationFilterParams,
} from "../../../common/types/common";
import InitialEmptyState from "../../../components/InitialEmptyState";
import StaticListEmptyState from "../../../common/img/emptyStateLogos/staticList.svg";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

function StaticListHeader({
  clickHandler,
  loading,
  searchProps: { searchKeyword, onSearch, hidden },
  filterProps,
}: {
  clickHandler: () => void;
  loading: LOADING_STATES;
  searchProps: HeaderSearchProps;
  filterProps?: ListFilterProps;
}) {
  return (
    <CommonListHeader
      heading="Static lists"
      searchInputProps={{
        placeholder: "Search static lists",
        name: "search-input",
        onSearch: onSearch,
        defaultValue: searchKeyword,
        hidden: hidden,
      }}
      createButtonProps={{
        name: "add-static-list",
        onClick: clickHandler,
        isLoading: isLoading(loading),
        text: "New static list",
      }}
      filterProps={filterProps}
    />
  );
}

export default function StaticLists() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditModalOpen, setIsEditModalOpen] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<StaticListDetails | null>(
    null
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState("");

  const { staticLists, updatingStaticList, createdStaticListId } =
    useSelector(selectStaticList);
  const { usersList, fetchingUsersList } = useSelector(selectAccount);

  const fetchStaticLists = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listStaticLists({
          pageNo,
          searchKeyword,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchStaticLists,
    fetchingList: staticLists.fetchingList,
  });

  useEffect(() => {
    return () => {
      dispatch(resetStaticListsList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(updatingStaticList)) {
      handlePageChange(staticLists.currentPageNo);
      dispatch(resetData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updatingStaticList]);

  useEffect(() => {
    if (createdStaticListId) {
      dispatch(resetData());
      navigate(`${urls.staticList}/${createdStaticListId}`);
    }
  }, [navigate, dispatch, createdStaticListId]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  function onDeleteClick(row: StaticListDetails) {
    setSelectedRow(row);
    setIsOpenDeleteModal(row.static_list_id);
  }

  function deleteStaticListHandler() {
    if (isOpenDeleteModal) {
      dispatch(deleteStaticList(isOpenDeleteModal));
      setIsOpenDeleteModal("");
      setSelectedRow(null);
    }
  }

  function onRenameClick(row: StaticListDetails) {
    setSelectedRow(row);
    setIsEditModalOpen(row.name);
  }

  function renameStaticListHandler(name: string) {
    if (selectedRow) {
      dispatch(
        renameStaticList({ name, staticListId: selectedRow.static_list_id })
      );
      setIsEditModalOpen(null);
      setSelectedRow(null);
    }
  }

  function createStaticListHandler(name: string) {
    setIsEditModalOpen(null);
    dispatch(createStaticList(name));
  }

  function goToStaticListDetails(list: StaticListDetails) {
    navigate(`${urls.staticList}/${list.static_list_id}`);
  }

  const getColumns = useCallback(
    () =>
      staticListColumnDefs(
        usersList,
        onDeleteClick,
        onRenameClick,
        findUserFromMapping,
        getUserStatusFromMapping
      ),
    [usersList]
  );

  const isInitEmpty = !(staticLists.totalPageCount || isFiltersApplied);

  return (
    <>
      <StaticListHeader
        clickHandler={() => setIsEditModalOpen("")}
        loading={updatingStaticList}
        searchProps={{
          searchKeyword: searchKeyword,
          onSearch: handleSearchChange,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.STATIC_LIST,
          hidden: isInitEmpty,
        }}
      />
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
          <DataTable
            fetchingList={fetchingList || fetchingUsersList}
            changingPage={staticLists.changingPage}
            list={staticLists.list as StaticListDetails[]}
            totalPageSize={staticLists.pageSize}
            totalPageCount={staticLists.totalPageCount}
            currentPage={staticLists.currentPageNo}
            onRowClick={goToStaticListDetails}
            setPage={handlePageChange}
            columns={getColumns()}
            emptyMsg={`No static list found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps(
                { to: urls.staticList, editParam: "static_list_id" },
                data
              )
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Create lists of your Contacts for quick access"
          message="No Static lists"
          ctaProps={{
            children: "Create static list",
            name: "new-static-list",
            onClick: () => setIsEditModalOpen(""),
            isLoading: isLoading(updatingStaticList),
          }}
        >
          <Image src={StaticListEmptyState} alt="Static list" />
        </InitialEmptyState>
      )}
      <EditNameModal
        value={isEditModalOpen ?? ""}
        action={
          isEditModalOpen
            ? ASSET_NAME_ACTIONS.RENAME
            : ASSET_NAME_ACTIONS.CREATE
        }
        isOpen={isEditModalOpen !== null}
        onClose={() => setIsEditModalOpen(null)}
        onSubmit={
          isEditModalOpen ? renameStaticListHandler : createStaticListHandler
        }
        asset="static list"
      />
      <DeleteConfirmationModal
        isOpen={!!isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal("")}
        submitHandler={deleteStaticListHandler}
        itemSpecificName={selectedRow?.name ?? ""}
        deleteItemType={
          DELETION_MODAL_TYPES_INFO[ASSET_TYPES.STATIC_LIST].display
        }
        isLoading={isLoading(updatingStaticList)}
      />
    </>
  );
}
