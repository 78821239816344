import { Heading } from "@chakra-ui/react";
import ITitle from "../../../../components/ITitle";

export function SyncStatsHeader({ title }: { title: string }) {
  return (
    <Heading
      as="h3"
      mb={1}
      fontSize="sm"
      fontWeight="extrabold"
      color="orange.400"
    >
      <ITitle title={title} />
    </Heading>
  );
}
