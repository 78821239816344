import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import { Flex } from "@chakra-ui/react";
import { PauseDisconnectHeader } from "../salesforceSyncRun/SyncStatusEditButtons";
import { forwardRef } from "react";
import SfApiLimitSummary from "../SfApiLimitSummary";
import SyncErrorsTab from "../salesforceSyncErrors/SyncErrorsTab";

const SfSyncHealthAndSyncErrors = forwardRef(
  (
    {
      connection,
      refreshConnection,
      onUpdateConnection,
      isLoading = false,
      isUpdatingConnection,
    }: {
      connection: SalesforceConnectionV2 | null;
      onUpdateConnection: (data: SalesforceConnectionV2 | null) => void;
      refreshConnection: VoidFunction;
      isLoading?: boolean;
      isUpdatingConnection?: boolean;
    },
    ref
  ) => {
    return (
      <ContentContainerWithHeader>
        <PauseDisconnectHeader />
        <Flex flexDir="column" gap="3">
          <SfApiLimitSummary
            connection={connection}
            refreshAction={refreshConnection}
            isLoading={isLoading}
            onUpdateConnection={onUpdateConnection}
            isUpdatingConnection={isUpdatingConnection}
          />
          <SyncErrorsTab ref={ref} connection={connection} />
        </Flex>
      </ContentContainerWithHeader>
    );
  }
);

export default SfSyncHealthAndSyncErrors;
