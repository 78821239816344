import { TRANSACTION_STATUS } from "../../constants/common";
import { InflectionResponse } from "../../types/common";
import { PersonComplete } from "../../types/person";
import { coreIntegrationsRequest } from "./api";

interface PersonGetResponse {
  email: string;
  id: string;
  properties: PersonComplete;
}

type TransactionIdResponse =
  | TransactionIdResponseNotError
  | TransactionIdResponseError;

interface TransactionIdResponseNotError {
  transactionId: string;
  status: Exclude<TRANSACTION_STATUS, TRANSACTION_STATUS.ERROR>;
  error: null;
}

interface TransactionIdResponseError {
  transactionId: string;
  status: TRANSACTION_STATUS.ERROR;
  error: string;
}

const BASE_PATH = "/person";

export async function getPersonApi(
  personId: string
): Promise<InflectionResponse<PersonGetResponse>> {
  return coreIntegrationsRequest({
    method: "GET",
    url: `${BASE_PATH}/${personId}`,
  });
}

export async function getPersonWithEmailApi(
  email: string
): Promise<InflectionResponse<PersonGetResponse>> {
  return coreIntegrationsRequest({
    method: "GET",
    url: `${BASE_PATH}/email/${email}`,
    isErrorHandled: true,
  });
}

export async function createPersonApi(
  email: string
): Promise<InflectionResponse<TransactionIdResponse>> {
  return coreIntegrationsRequest({
    url: `${BASE_PATH}`,
    data: { email },
  });
}

export async function removePersonApi(
  personId: string
): Promise<InflectionResponse<TransactionIdResponse>> {
  return coreIntegrationsRequest({
    method: "DELETE",
    url: `${BASE_PATH}/${personId}`,
  });
}

export async function updatePersonApi(
  personId: string,
  data: any
): Promise<InflectionResponse<TransactionIdResponse>> {
  return coreIntegrationsRequest({
    method: "PUT",
    url: `${BASE_PATH}/${personId}`,
    data,
  });
}

export async function getPersonTransactionIdStatusApi(
  transactionId: string
): Promise<InflectionResponse<TransactionIdResponse>> {
  return coreIntegrationsRequest({
    method: "GET",
    url: `${BASE_PATH}/status/${transactionId}`,
    isErrorHandled: true,
  });
}

export async function getPersonTransactionsStatusApi(
  personId: string
): Promise<InflectionResponse<TransactionIdResponse>> {
  return coreIntegrationsRequest({
    method: "GET",
    url: `${BASE_PATH}/${personId}/status`,
    isErrorHandled: true,
  });
}
