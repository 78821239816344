import { Box, Flex, Badge, VStack, Text, HStack, Wrap } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { ReactNode, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { FILTER_TYPE, NEW_FILTER_GROUP } from "../../common/constants/campaign";
import { createGroupId } from "../../common/helper/commonHelper";
import {
  DynamicListType,
  DynamicListChild,
  CONNECTOR,
  OperatorType,
  CAMPAIGN_CONTEXT,
  OperatorList,
} from "../../common/types/campaign";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import AddOuterGroupButton from "./AddOuterGroupButton";
import { DynamicListContext } from "./DynamicList";
import DynamicListLogicGate from "./DynamicListLogicGate";
import { selectDynamicList } from "./dynamicListSlice";
import { ValueSelectFields } from "./DynamicListValueFields";
import { EditableGroupName } from "./EditableGroupName";
import ListMembershipFilterGroup from "./ListMembershipFilterGroup";
import MarketingActivityFilterGroup from "./MarketingActivityFilterGroup";
import PersonFilterGroup from "./PersonFilterGroup";
import ProductActivityFilterGroup from "./ProductActivityFilterGroup";
import WebsiteActivityFilterGroup from "./WebsiteActivityFilterGroup";

function ContainerWithLogicGate({
  index,
  logicGate,
  children,
}: {
  index: number;
  logicGate: ReactNode;
  children: ReactNode;
}) {
  return (
    <Box key={index} w="100%">
      {logicGate}
      {children}
    </Box>
  );
}

function filterOperators(
  operators: OperatorList | null,
  operatorType: OperatorType
) {
  return operators
    ? Object.values(operators[operatorType] ?? {}).map((op) => ({
        label: op.display,
        value: op.id,
      }))
    : [];
}

function WrapIfReadOnly({
  children,
  isReadOnly,
}: {
  children: ReactNode;
  isReadOnly: boolean;
}) {
  if (isReadOnly) {
    return <Wrap>{children}</Wrap>;
  } else {
    return <HStack>{children}</HStack>;
  }
}

function AggregateSupport({
  operators,
  data,
  onChangeOuterGroupFilter,
  isReadOnly,
  activeErrorCheck,
  campaignContext,
}: {
  operators: OperatorList | null;
  data: DynamicListType;
  onChangeOuterGroupFilter: (operator: string) => void;
  isReadOnly?: boolean;
  activeErrorCheck: boolean;
  campaignContext: CAMPAIGN_CONTEXT;
}) {
  const options = useMemo(() => {
    switch (campaignContext) {
      case CAMPAIGN_CONTEXT.ORG:
        return filterOperators(operators, OperatorType.ORG_AGGREGATE_SPECIFIC);
      case CAMPAIGN_CONTEXT.PERSON:
        return filterOperators(
          operators,
          OperatorType.ACCOUNT_AGGREGATE_SPECIFIC
        );
      default:
        return [];
    }
  }, [operators, campaignContext]);

  const aggregateItem = useMemo(() => {
    switch (campaignContext) {
      case CAMPAIGN_CONTEXT.ORG:
        return "org";
      case CAMPAIGN_CONTEXT.PERSON:
        return "account";
      default:
        return [];
    }
  }, [campaignContext]);

  return (
    <Box fontSize="sm" mb="3">
      <WrapIfReadOnly isReadOnly={!!isReadOnly}>
        <>
          <Text>...associated with an {aggregateItem}</Text>
          <ValueSelectFields
            options={options}
            value={(data.operator as string) || ""}
            onChange={(value: string) => onChangeOuterGroupFilter(value)}
            validationError={activeErrorCheck ? data.validation_error : ""}
            isReadOnly={isReadOnly}
          />
          <Text>at least one member</Text>
        </>
      </WrapIfReadOnly>
    </Box>
  );
}

export default function OuterGroup({
  label,
  id,
  data,
  onChange,
  onRemove,
  isReadOnly,
  campaignContext,
  group,
}: {
  label: string;
  id: string;
  data: DynamicListType;
  onRemove: () => void;
  onChange: (data: DynamicListType) => void;
  isReadOnly?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
  group: DynamicListType;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  function onChangeOuterGroupFilter(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    onChange(dataCopy);
  }

  function onAddFilterGroup(type: FILTER_TYPE) {
    const dataCopy = cloneDeep(data);
    dataCopy.children.push(NEW_FILTER_GROUP[type]);
    onChange(dataCopy);
  }

  function onRemoveFilterGroup(index: number) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.children.length <= 1) {
      onRemove();
    } else {
      dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
      onChange(dataCopy);
    }
  }

  function updateFilterGroup(index: number, newData: DynamicListChild) {
    const dataCopy = cloneDeep(data);
    dataCopy.children[index] = newData;
    onChange(dataCopy);
  }

  function setConnector(connector: CONNECTOR, index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children[index].connector = connector;
    onChange(dataCopy);
  }

  function changeOuterGroupName(name: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.name = name;
    onChange(dataCopy);
  }

  function renderFilterGroup(filterGroup: DynamicListChild, index: number) {
    switch (filterGroup.children[0].filter_type) {
      case FILTER_TYPE.PRODUCT_ACTIVITY:
        return (
          <ProductActivityFilterGroup
            data={filterGroup}
            id={createGroupId(id, "filter", index + 1)}
            label={
              filterGroup.name ? filterGroup.name : `Filter group ${index + 1}`
            }
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.PERSON:
        return (
          <PersonFilterGroup
            data={filterGroup}
            id={createGroupId(id, "filter", index + 1)}
            label={
              filterGroup.name ? filterGroup.name : `Filter group ${index + 1}`
            }
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.MARKETING_ACTIVITY:
        return (
          <MarketingActivityFilterGroup
            data={filterGroup}
            id={createGroupId(id, "filter", index + 1)}
            label={
              filterGroup.name ? filterGroup.name : `Filter group ${index + 1}`
            }
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.LIST_MEMBERSHIP:
        return (
          <ListMembershipFilterGroup
            data={filterGroup}
            id={createGroupId(id, "filter", index + 1)}
            label={
              filterGroup.name ? filterGroup.name : `Filter group ${index + 1}`
            }
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.WEBSITE_ACTIVITY:
        return (
          <WebsiteActivityFilterGroup
            id={createGroupId(id, "filter", index + 1)}
            label={
              filterGroup.name ? filterGroup.name : `Filter group ${index + 1}`
            }
            filterGroup={filterGroup}
            onChangeFilterGroup={(data) => updateFilterGroup(index, data)}
            onRemoveFilterGroup={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      default:
        throw new Error("Invalid Filter Type");
    }
  }

  const isAggregateSupported = useMemo(
    () => campaignContext && (!group.children.length || group.operator),
    [campaignContext, group.children.length, group.operator]
  );

  if (isReadOnly) {
    return (
      <Box
        p="2"
        rounded="lg"
        bg="grayV2.100"
        width="100%"
        alignItems="flex-start"
        id={id}
      >
        {isAggregateSupported && (
          <AggregateSupport
            data={data}
            onChangeOuterGroupFilter={onChangeOuterGroupFilter}
            isReadOnly={isReadOnly}
            operators={operators}
            activeErrorCheck={activeErrorCheck}
            campaignContext={campaignContext!}
          />
        )}
        <VStack alignItems="flex-start" overflow="auto">
          {data.children.map((filterGroup, index) => {
            const logicGate = filterGroup.connector && index !== 0 && (
              <DynamicListLogicGate
                operator={filterGroup.connector}
                handleChange={(connector) => setConnector(connector, index)}
                isReadOnly={isReadOnly}
              />
            );
            return (
              <ContainerWithLogicGate index={index} logicGate={logicGate}>
                {renderFilterGroup(filterGroup, index)}
              </ContainerWithLogicGate>
            );
          })}
        </VStack>
      </Box>
    );
  } else {
    return (
      <Box
        p="3"
        rounded="lg"
        bg="grayV2.100"
        width="100%"
        alignItems="flex-start"
        id={id}
      >
        <Flex
          mb="3"
          roundedTop="lg"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          bg="grayV2.100"
        >
          <EditableGroupName
            placeholder={label}
            groupName={data.name}
            onGroupNameChange={changeOuterGroupName}
          >
            <Badge fontSize="10px" colorScheme="blue" variant="solid">
              {label}
            </Badge>
          </EditableGroupName>

          <RemoveRowCloseButton onClick={onRemove} />
        </Flex>
        {isAggregateSupported && (
          <AggregateSupport
            data={data}
            onChangeOuterGroupFilter={onChangeOuterGroupFilter}
            operators={operators}
            activeErrorCheck={activeErrorCheck}
            campaignContext={campaignContext!}
          />
        )}
        <VStack alignItems="flex-start" w="100%">
          {data.children.map((filterGroup, index) => {
            const logicGate = filterGroup.connector && index !== 0 && (
              <DynamicListLogicGate
                operator={filterGroup.connector}
                handleChange={(connector) => setConnector(connector, index)}
              />
            );
            return (
              <ContainerWithLogicGate index={index} logicGate={logicGate}>
                {renderFilterGroup(filterGroup, index)}
              </ContainerWithLogicGate>
            );
          })}
        </VStack>
        <Box pt="5">
          <AddOuterGroupButton onClick={onAddFilterGroup} />
        </Box>
      </Box>
    );
  }
}
