import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function LayoutWithTable({
  height = "calc(100vh - 55px)",
  reduceHeightBy = "0px",
  children,
}: {
  height?: string;
  reduceHeightBy?: string;
  children: ReactNode;
}) {
  return (
    <Box px="20px" height={`calc(${height} - ${reduceHeightBy})`}>
      {children}
    </Box>
  );
}
