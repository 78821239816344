import IconWithTooltip from "../../../../../../components/IconWithTooltip";
import { FaExclamationTriangle } from "react-icons/fa";
import { HStack, Text } from "@chakra-ui/react";

export default function ValidationStateIndicator({
  errorText,
}: {
  errorText: string;
}) {
  if (errorText) {
    return (
      <HStack color="red.500" spacing={2}>
        <IconWithTooltip
          label={errorText}
          tooltipProps={{
            hasArrow: true,
            bg: "red.100",
            color: "red.500",
            placement: "bottom-end",
            height: "100%",
          }}
          iconContainerProps={{ pt: 0 }}
          h="3"
          w="3"
          color="red.400"
          icon={FaExclamationTriangle}
        />
        <Text fontSize="12px" fontWeight="600">
          {errorText}
        </Text>
      </HStack>
    );
  } else {
    return <></>;
  }
}
