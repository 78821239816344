import { Center, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loginSso } from "./accountSlice";

export default function LoginRedirect() {
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      dispatch(loginSso({ code }));
    }
  }, [dispatch, code]);

  return (
    <Center h="100vh">
      <Spinner />
    </Center>
  );
}
