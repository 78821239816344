import { TextProps, Text } from "@chakra-ui/react";

export enum TEXT_TYPE {
  HEADER = "header",
  DESCRIPTION = "description",
}

export default function HeaderDescText({
  text,
  type = TEXT_TYPE.DESCRIPTION,
  ...props
}: { text: string; type?: TEXT_TYPE } & TextProps) {
  const isTypeDescription = type === TEXT_TYPE.DESCRIPTION;
  return (
    <Text
      fontSize={isTypeDescription ? "12px" : "14px"}
      color={isTypeDescription ? "brandBlue.500" : "blackAlpha.700"}
      {...props}
    >
      {text}
    </Text>
  );
}
