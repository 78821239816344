import { Grid, GridItem, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { FaArrowLeft, FaArrowRight, FaLink } from "react-icons/fa";
import { GroupBase, OptionProps } from "react-select";
import { LOADING_STATES } from "../../../../../../../common/constants/common";
import { isLoading } from "../../../../../../../common/helper/commonHelper";
import { useChakraColors } from "../../../../../../../common/hooks/commonHooks";
import { AccountContactAutoMappingElement } from "../../../../../../../common/types/AccountContactMapping";
import { PersonDestination } from "../../../../../../../common/types/person";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";
import { iconFinder } from "../../../../../../../components/unified-mapping/MappingTableColumnTitle";

export default function AccountAutoDropdown({
  readonlyMode,
  accountList,
  contactList,
  accountAutoMapping,
  fetchingMapping,
  accountField,
  setAccountField,
  contactField,
  setContactField,
}: {
  readonlyMode: boolean;
  accountList: PersonDestination[];
  contactList: PersonDestination[];
  accountAutoMapping: AccountContactAutoMappingElement;
  fetchingMapping: LOADING_STATES;
  accountField: PersonDestination;
  setAccountField: (accountField: PersonDestination) => void;
  contactField: PersonDestination;
  setContactField: (contactField: PersonDestination) => void;
}) {
  const accountValue = accountAutoMapping?.account_field;
  const contactValue = accountAutoMapping?.person_field;
  const { gray100, gray300, gray800 } = useChakraColors();

  const getAccountField = useCallback(() => {
    const accountField =
      accountList &&
      accountList.find((account) => account.name === accountValue);
    return accountField;
  }, [accountList, accountValue]);

  const getContactField = useCallback(() => {
    const contactField =
      contactList &&
      contactList.find((contact) => contact.name === contactValue);
    return contactField;
  }, [contactList, contactValue]);

  const CustomOption = ({
    children,
    data,
    ...props
  }: OptionProps<PersonDestination, false, GroupBase<PersonDestination>>) => {
    return (
      <HStack
        cursor="pointer"
        pt="2"
        pl="2"
        pb="2"
        bg={props.isSelected ? gray300 : "white"}
        color={props.isSelected ? gray800 : ""}
        _hover={props.isSelected ? {} : { bg: gray100 }}
        onClick={() => props.setValue(data, "select-option")}
      >
        <Icon as={iconFinder(data.type)} />
        <Text pt="1">{data.display}</Text>
      </HStack>
    );
  };

  function changeAccountField(option: PersonDestination) {
    setAccountField(option);
  }

  function changePersonField(option: PersonDestination) {
    setContactField(option);
  }

  useEffect(() => {
    if (accountAutoMapping && readonlyMode) {
      const accountInfo = getAccountField();
      const contactInfo = getContactField();
      accountInfo && setAccountField(accountInfo);
      contactInfo && setContactField(contactInfo);
    }
  }, [
    accountAutoMapping,
    getAccountField,
    getContactField,
    setAccountField,
    setContactField,
    readonlyMode,
  ]);

  return (
    <VStack alignItems="flex-start">
      <Text fontSize={14} fontWeight="semibold">
        Auto Match Criteria
      </Text>
      <Text fontSize={14}>
        Select the fields from the account and person fields to match
      </Text>
      {readonlyMode ? (
        <Grid templateColumns="repeat(3,1fr)" gap={20} pt="5">
          <GridItem>
            <VStack alignItems="flex-start">
              <Text fontSize={12} color="gray.500">
                Account Field
              </Text>
              <HStack>
                <Icon as={iconFinder(accountField?.type)} />
                <Text>{accountField?.display}</Text>
              </HStack>
            </VStack>
          </GridItem>
          <GridItem pt="7">
            <HStack>
              <Icon as={FaArrowLeft} />
              <Icon as={FaLink} />
              <Icon as={FaArrowRight} />
            </HStack>
          </GridItem>
          <GridItem>
            <VStack alignItems="flex-start">
              <Text fontSize={12} color="gray.500">
                Person Field
              </Text>
              <HStack>
                <Icon as={iconFinder(contactField?.type)} />
                <Text>{contactField?.display}</Text>
              </HStack>
            </VStack>
          </GridItem>
        </Grid>
      ) : (
        <Grid templateColumns="repeat(3,1fr)" gap={12} pt="5">
          <GridItem>
            <VStack alignItems="flex-start">
              <Text fontSize={12} color="gray.500">
                Account Field
              </Text>
              <DropdownWithSearch<PersonDestination>
                isLoading={isLoading(fetchingMapping)}
                options={accountList}
                isSearchable={true}
                value={
                  accountList &&
                  accountList.find((x) => x.name === accountField?.name)
                }
                getOptionLabel={(x) => x.display}
                getOptionValue={(x) => x.name}
                onChange={(option) =>
                  changeAccountField(option as PersonDestination)
                }
                components={{ Option: CustomOption }}
                controlStyle={{
                  width: "250px",
                }}
              />
            </VStack>
          </GridItem>
          <GridItem pt="9" pl="20">
            <HStack>
              <Icon as={FaArrowLeft} />
              <Icon as={FaLink} />
              <Icon as={FaArrowRight} />
            </HStack>
          </GridItem>
          <GridItem>
            <VStack alignItems="flex-start">
              <Text fontSize={12} color="gray.500">
                Person Field
              </Text>
              <DropdownWithSearch<PersonDestination>
                isLoading={isLoading(fetchingMapping)}
                options={contactList}
                isSearchable={true}
                value={
                  contactList &&
                  contactList.find((x) => x.name === contactField?.name)
                }
                getOptionLabel={(x) => x.display}
                getOptionValue={(x) => x.name}
                onChange={(option) =>
                  changePersonField(option as PersonDestination)
                }
                components={{ Option: CustomOption }}
                controlStyle={{
                  width: "250px",
                }}
              />
            </VStack>
          </GridItem>
        </Grid>
      )}
    </VStack>
  );
}
