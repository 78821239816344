import { Center, CenterProps, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

export default function WidgetIcon({
  color,
  icon,
  ...props
}: { icon: IconType } & CenterProps) {
  return (
    <Center
      bg={`${color}25`}
      h="24px"
      w="24px"
      opacity="0.8"
      rounded="full"
      {...props}
    >
      <Icon fontSize="xs" color={color} as={icon} />
    </Center>
  );
}
