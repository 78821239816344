import { Box, Flex, Heading, Switch, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  SalesforceConnectionV2,
  SF_OBJECTS_TO_SYNC,
  SyncedObjSummary,
  SyncObjectsSettings,
} from "../../../../../../common/types/salesforce";
import MemoizedCommonDrawer from "../../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../../connectionDetails/components/ConnectionButtons";
import SalesforceApiAllocationField from "../SalesforceApiAllocationField";
import { INIT_SF_SYNC_OBJ_PREFERENCE } from "../../../../../../common/constants/salesforce";
import { cloneDeep, set } from "lodash";
import {
  getSyncedObjectSummary,
  isSfSyncInitPreference,
} from "../../../../../../common/helper/salesforceHelper";

const PERMITTED_SYNC_OBJECTS = [
  SF_OBJECTS_TO_SYNC.CONTACT,
  SF_OBJECTS_TO_SYNC.LEAD,
  SF_OBJECTS_TO_SYNC.ACCOUNT,
];

function transformSyncObjects(connection: SalesforceConnectionV2 | null) {
  if (isSfSyncInitPreference(connection?.currentState)) {
    return getSyncedObjectSummary(connection);
  }
  return INIT_SF_SYNC_OBJ_PREFERENCE;
}

function transformSyncSummary(
  summary: SyncedObjSummary,
  objSettings: SyncObjectsSettings | null
) {
  let settings = cloneDeep(objSettings ?? {});

  PERMITTED_SYNC_OBJECTS.forEach((syncField) => {
    set(settings, `${syncField}.syncObject`, summary[syncField]);
  });

  return settings;
}

function ObjectRow({
  title,
  permission,
  changePermission,
}: {
  permission: boolean;
  title: string;
  changePermission: (val: boolean) => void;
}) {
  return (
    <Flex justifyContent="space-between" width="100%">
      <Text color="gray.500" fontSize="sm">
        {title}
      </Text>

      <Switch
        size="sm"
        mr="2"
        isChecked={permission}
        onChange={(e) => changePermission(e.target.checked)}
      />
    </Flex>
  );
}

export default function SfSettingsDrawer({
  connectionDetails,
  isOpen,
  loading,
  onSaveSettings,
  onClose,
  goToNextStep,
  goToPrevStep,
}: {
  connectionDetails: SalesforceConnectionV2;
  isOpen: boolean;
  loading: boolean;
  onSaveSettings: (connection: SalesforceConnectionV2) => void;
  onClose: VoidFunction;
  goToPrevStep: VoidFunction;
  goToNextStep: VoidFunction;
}) {
  const [allocated, setAllocated] = useState<number | null>(
    connectionDetails?.salesforceLimits.dailyApiRequests.max ?? null
  );

  const [objSettingSummary, setObjectSettings] = useState(
    transformSyncObjects(connectionDetails)
  );

  useEffect(() => {
    setObjectSettings(transformSyncObjects(connectionDetails));
  }, [connectionDetails]);

  function onSavePreference() {
    const updatedConnection = cloneDeep(connectionDetails);
    set(updatedConnection, "inflectionLimits.dailyApiRequests", {
      max: allocated as number,
    });
    set(
      updatedConnection,
      "syncSettings.objectSettings",
      transformSyncSummary(
        objSettingSummary,
        updatedConnection.syncSettings?.objectSettings ?? null
      )
    );

    onSaveSettings(updatedConnection);
  }

  return (
    <MemoizedCommonDrawer
      title="Salesforce"
      onClose={onClose}
      isOpen={isOpen}
      goBack={goToPrevStep}
      size="md"
      placement="right"
    >
      <VStack alignItems="flex-start" spacing="0.5">
        <Heading fontSize="md">
          Ready to connect Salesforce and Inflection?
        </Heading>
        <Text color="gray.500" fontSize="sm">
          Choose the options to sync
        </Text>
      </VStack>

      <VStack my="3" alignItems="flex-start">
        <ObjectRow
          title="Lead"
          permission={objSettingSummary?.lead}
          changePermission={(val: boolean) => {
            setObjectSettings({
              ...objSettingSummary,
              lead: val,
            });
          }}
        />
        <ObjectRow
          title="Contacts"
          permission={objSettingSummary?.contact}
          changePermission={(val: boolean) =>
            setObjectSettings({ ...objSettingSummary, contact: val })
          }
        />
        <ObjectRow
          title="Accounts"
          permission={objSettingSummary?.account}
          changePermission={(val: boolean) =>
            setObjectSettings({ ...objSettingSummary, account: val })
          }
        />
      </VStack>

      <Box
        mt="4"
        py="4"
        px="2"
        alignItems="flex-start"
        letterSpacing="0.5"
        bg="grayV2.100"
        borderRadius="md"
      >
        <Heading color="gray.800" fontSize="sm" pl="4">
          Set API allocation
        </Heading>
        <Box>
          <SalesforceApiAllocationField
            width="100%"
            justifyContent="space-between"
            color="black"
            allocated={allocated}
            setAllocated={setAllocated}
            connectionDetails={connectionDetails}
          />
        </Box>
      </Box>
      <ConnectionButtons
        name="continue-button"
        isLoading={loading}
        isDisabled={!allocated}
        onClick={onSavePreference}
        closeModal={onClose}
      />
    </MemoizedCommonDrawer>
  );
}
