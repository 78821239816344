import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  Box,
  DrawerBody,
  VStack,
  Text,
  HStack,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdExpandMore } from "react-icons/md";
import { useSelector } from "react-redux";
import { EMPTY_CONTEXT } from "../../../../common/constants/common";
import { EMAIL_FIELD_KEY } from "../../../../common/constants/form";
import { isLoading } from "../../../../common/helper/commonHelper";
import { ReactSelectDefaultOptionsType } from "../../../../common/types/common";
import {
  FormSubmissionDetails,
  FormWebSessionDetails,
} from "../../../../common/types/form";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import IButton from "../../../../components/IButton";
import LabelValuePair from "../../../../components/LabelValuePair";
import { SearchField } from "../../../../components/SearchField";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import { getFormDetails, selectForm } from "../formSlice";
import FormProviderWithLogo from "./FormProviderWithLogo";

function convertToLabelValuePair(jsonString: string) {
  const data = JSON.parse(jsonString ?? "{}");
  return Object.entries(data).map(([key, value]) => {
    return {
      label: key,
      value: (value as any)?.toString(),
    };
  });
}

const SESSION_DETAILS_LIST: {
  display: string;
  key: keyof FormWebSessionDetails;
}[] = [
  { display: "UTM Source", key: "utm_source" },
  { display: "UTM Campaign", key: "utm_campaign" },
  { display: "UTM Content", key: "utm_content" },
  { display: "UTM Medium", key: "utm_medium" },
  { display: "UTM Term", key: "utm_term" },
  { display: "Referral URL", key: "referrer_domain_path" },
  { display: "Form URL", key: "page_domain_path" },
];

function WebSessionDetails({
  sessionDetails,
}: {
  sessionDetails: FormWebSessionDetails;
}) {
  return (
    <Accordion defaultIndex={[0]} allowToggle w="100%" px={2}>
      <AccordionItem
        borderRadius="md"
        backgroundColor="oneOffs.sidebarBackground2"
        border="none"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton>
              <HStack justifyContent="left" fontSize="14px">
                <Icon
                  as={MdExpandMore}
                  fontSize={18}
                  transform={isExpanded ? "rotate(0deg)" : "rotate(-90deg)"}
                  transition="0.2s transform linear"
                />
                <Text>Web-session Details</Text>
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <VStack alignItems="flex-start" pl={2}>
                {SESSION_DETAILS_LIST.map(({ display, key }) => (
                  <LabelValuePair
                    label={display}
                    value={sessionDetails[key] ?? "--"}
                  />
                ))}
              </VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

export function SubmissionSidebar({
  formId,
  selectedSubmission,
  hideHyperLink,
  onClose,
}: {
  formId: string;
  hideHyperLink?: boolean;
  selectedSubmission: FormSubmissionDetails | null;
  onClose: () => void;
}) {
  const [filteredMappedData, setFilteredMappedData] = useState<
    ReactSelectDefaultOptionsType[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { formDetails } = useSelector(selectForm);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (formDetails.data.form_id !== formId && selectedSubmission) {
      dispatch(getFormDetails(formId));
    }
  }, [dispatch, formDetails.data, formId, selectedSubmission]);

  const mappedData = useMemo(
    () => convertToLabelValuePair(selectedSubmission?.mapped_data ?? "{}"),
    [selectedSubmission]
  );

  const submissionData = useMemo(
    () => convertToLabelValuePair(selectedSubmission?.submission_data ?? "{}"),
    [selectedSubmission]
  );

  useEffect(() => {
    if (searchKeyword) {
      const filteredData = submissionData.filter(
        (x) =>
          x.value.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          x.label.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredMappedData(filteredData);
    } else {
      setFilteredMappedData(submissionData);
    }
  }, [selectedSubmission, searchKeyword, submissionData]);

  const mappedEmailAndId = useMemo(() => {
    if (mappedData.length) {
      const email = mappedData.find(
        (data) => data.label === EMAIL_FIELD_KEY
      )?.value;
      const personId = mappedData.find(
        (data) => data.label === "person_id"
      )?.value;
      return {
        email,
        personId,
      };
    } else {
      return null;
    }
  }, [mappedData]);

  function onSearch(searchQuery: string) {
    setSearchKeyword(searchQuery);
  }

  return (
    <Drawer
      isOpen={!!selectedSubmission}
      placement="right"
      onClose={onClose}
      size="sm"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="16px">
          {mappedEmailAndId &&
          (mappedEmailAndId.email || mappedEmailAndId.personId) ? (
            <Box>
              <Text>{mappedEmailAndId.email ?? mappedEmailAndId.personId}</Text>
              {mappedEmailAndId.personId && !hideHyperLink && (
                <IButton
                  maxWidth="100%"
                  wordBreak="break-all"
                  onClick={() =>
                    window.open(
                      `${urls.person}/${mappedEmailAndId.personId}`,
                      "_blank"
                    )
                  }
                  rightIcon={<FaExternalLinkAlt />}
                  variant="link"
                  fontSize={12}
                  colorScheme="blue"
                >
                  Click to view details
                </IButton>
              )}
            </Box>
          ) : (
            "Submission details"
          )}
        </DrawerHeader>
        <DrawerBody p="0">
          <VStack w="100%">
            <VStack alignItems="flex-start" py="3" px="6" spacing={4} w="100%">
              <LabelValuePair
                label="Form Provider"
                value={
                  <FormProviderWithLogo vendor={formDetails.data.vendor} />
                }
                isLoadingData={isLoading(formDetails.loading)}
                maxWidth="400px"
              />

              <LabelValuePair
                label="Form Name"
                value={formDetails.data.name}
                isLoadingData={isLoading(formDetails.loading)}
                maxWidth="400px"
              />

              <LabelValuePair
                label="Submission ID"
                value={selectedSubmission?.submission_id}
                maxWidth="400px"
              />

              <LabelValuePair
                label="Submission On"
                value={
                  <FormatDate
                    date={selectedSubmission?.submission_date}
                    showTime
                  />
                }
                maxWidth="400px"
              />
            </VStack>
            {selectedSubmission?.web_session_details?.session_id && (
              <WebSessionDetails
                sessionDetails={selectedSubmission.web_session_details}
              />
            )}
            <HStack
              w="100%"
              bg="oneOffs.sidebarBackground2"
              px="6"
              py="2"
              justifyContent="space-between"
            >
              <Text fontSize={14} fontWeight={600}>
                Submission data
              </Text>

              <SearchField
                name="search-mapped-fields"
                value={searchKeyword}
                onSearch={onSearch}
                w="250px"
                placeholder="Search fields"
              />
            </HStack>

            {!!filteredMappedData.length ? (
              <VStack
                alignItems="flex-start"
                py="3"
                px="6"
                spacing={2}
                w="100%"
              >
                <VStack alignItems="flex-start" spacing="18px">
                  {filteredMappedData.map(({ label, value }, index) => {
                    return (
                      <LabelValuePair
                        maxWidth="400px"
                        label={label}
                        value={value || EMPTY_CONTEXT}
                        key={"mapped_data_" + index}
                      />
                    );
                  })}
                </VStack>
              </VStack>
            ) : (
              <Center h="200px">
                {searchKeyword ? (
                  <Text>No results found</Text>
                ) : (
                  <Text>No mapped fields</Text>
                )}
              </Center>
            )}
          </VStack>
          <VStack></VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
