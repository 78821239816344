import {
  BoxProps,
  Center,
  Icon,
  IconProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaInfoCircle } from "react-icons/fa";

export default function IconWithTooltip({
  icon,
  label,
  tooltipProps,
  iconContainerProps,
  ...props
}: {
  icon?: IconType;
  label: string;
  iconContainerProps?: BoxProps;
  tooltipProps?: Omit<TooltipProps, "children" | "label">;
} & IconProps) {
  return (
    <Tooltip label={label} {...tooltipProps}>
      <Center pt="2px" {...iconContainerProps}>
        <Icon as={icon ?? FaInfoCircle} {...props} />
      </Center>
    </Tooltip>
  );
}
