import { BoxProps, HStack, Icon, Box, Flex, Badge } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { IconType } from "react-icons";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";

const NodeGroupBox = React.memo(
  ({
    nodeName,
    type = "filter",
    onRemove,
    isReadOnly,
    icon,
    children,
    ...sx
  }: {
    nodeName: string;
    children: ReactNode;
    type?: "filter" | "property";
    onRemove: () => void;
    isReadOnly?: boolean;
    icon?: IconType;
  } & BoxProps) => {
    const isProperty = type === "property";

    if (isReadOnly) {
      return (
        <HStack
          width="100%"
          fontSize="14px"
          alignItems="flext-start"
          rounded="md"
          borderColor="gray.200"
          borderWidth="1px"
          p={1}
          bgColor="gray.200"
          fontWeight="500"
          mt={2}
        >
          {icon && <Icon as={icon} color="brand.blue" mt={1} />}
          {children}
        </HStack>
      );
    } else {
      return (
        <Box
          rounded="lg"
          borderColor="gray.200"
          borderWidth="1px"
          width="100%"
          bgColor="gray.200"
          pb={2}
          {...sx}
        >
          <Flex
            roundedTop="lg"
            alignItems="center"
            p="2"
            width="100%"
            justifyContent="space-between"
            bg={type === "property" ? "grayV2.300" : "grayV2.200"}
          >
            <Badge
              fontSize="10px"
              colorScheme={isProperty ? undefined : "teal"}
              style={
                isProperty ? { color: "white", backgroundColor: "black" } : {}
              }
              variant="solid"
            >
              {nodeName}
            </Badge>

            <RemoveRowCloseButton onClick={onRemove} />
          </Flex>
          <Box width="100%">{children}</Box>
        </Box>
      );
    }
  }
);

export default NodeGroupBox;
