import { debounce, isEmpty } from "lodash";
import { useEffect, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { CAMPAIGN_STATUS } from "../../../../common/constants/campaign";
import { useUpdateWithInitValue } from "../../../../common/hooks/commonHooks";
import {
  CampaignDetailsForBuilder,
  CAMPAIGN_CONTEXT,
} from "../../../../common/types/campaign";
import {
  NodeGroup,
  Query,
  TriggerCampaignValidationType,
} from "../../../../common/types/dynamicList";
import { TriggerCampaignUpdatableData } from "../../../../common/types/trigger";
import {
  QualificationFrequency,
  TimeFrame,
} from "../../../../common/types/dynamicList";
import { useAppDispatch } from "../../../../store";
import CampaignBuilder from "../CampaignBuilder";
import TriggerAudienceFilterSection from "../sidebar/sections/TriggerAudienceFilterSection";
import TriggerCampaignScheduleSection from "../sidebar/sections/TriggerCampaignScheduleSection";
import TriggerCriteriaSection from "../sidebar/sections/TriggerCriteriaSection";
import TriggerExitSection from "../sidebar/sections/TriggerExitSection";
import {
  pauseTriggerCampaign,
  publishTriggerCampaign,
  resumeTriggerCampaign,
  setTriggerCampaignTags,
  archiveTriggerCampaign,
} from "./triggerCampaignSlice";
import {
  INIT_TIME_FRAME,
  INIT_QUALIFICATION_FREQ,
  TRIGGER_CAMPAIGN_STATES,
} from "../../../../common/constants/trigger";
import { setActiveErrorCheck } from "./triggerCampaignSlice";
import {
  validateAudienceCriteria,
  validateTriggerCriteria,
  validateExitCriteria,
} from "../Audience-criteria/validationHelper";
import { CampaignBuilderContext, selectFlow } from "../flow/flowSlice";
import { useSelector } from "react-redux";

type InitCampaignDataType = {
  audienceCriteria: Query | null;
  schedule: TimeFrame | null;
  rule: QualificationFrequency | null;
  triggerCriteria: Query | null;
  exitCriteria: Query | null;
  validity: TriggerCampaignValidationType;
};

export default function TriggerCampaignBuilder({
  campaignDetails,
  initData: {
    audienceCriteria: initAudienceCriteria,
    schedule: initSchedule,
    rule: initRule,
    triggerCriteria: initTriggerCriteria,
    exitCriteria: initExitCriteria,
    validity: initValidity,
  },
  saveDraft,
}: {
  campaignDetails: CampaignDetailsForBuilder;
  initData: InitCampaignDataType;
  saveDraft: (data: TriggerCampaignUpdatableData) => void;
}) {
  const dispatch = useAppDispatch();

  // const { operatorsArgCount } = useSelector(selectDynamicList);

  const [audienceCriteria, setAudienceCriteria] =
    useUpdateWithInitValue(initAudienceCriteria);
  const [campaignSchedule, setCampaignSchedule] =
    useUpdateWithInitValue(initSchedule);
  const [campaignRule, setCampaignRule] = useUpdateWithInitValue(initRule);
  const [exitCriteria, setExitCriteria] =
    useUpdateWithInitValue(initExitCriteria);
  const [triggerCriteria, setTriggerCriteria] =
    useUpdateWithInitValue(initTriggerCriteria);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ruleValid, setRuleValid] = useUpdateWithInitValue(true);
  const [validity, setValidity] = useUpdateWithInitValue(initValidity);

  const { draftFlow: flow, flowValidity } = useSelector(selectFlow);

  const validateScheduleOnSave = useCallback(() => {
    const isScheduleValid = !isEmpty(campaignSchedule?.type);
    setValidity((prevState) => {
      return {
        ...prevState,
        schedule: isScheduleValid ? "" : "Schedule is not configured",
      };
    });
  }, [campaignSchedule, setValidity]);

  useEffect(() => {
    if (campaignDetails.activeErrorCheck) {
      validateScheduleOnSave();
    }
  }, [
    campaignDetails.activeErrorCheck,
    campaignSchedule,
    validateScheduleOnSave,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setActiveErrorCheck(false));
    };
  }, [dispatch]);

  function withOtherTabData(
    newData: Partial<TriggerCampaignUpdatableData>
  ): TriggerCampaignUpdatableData {
    return {
      campaignName: campaignDetails.campaignName,
      audienceCriteria: audienceCriteria,
      journeySettings:
        campaignSchedule && campaignRule
          ? {
              timeframe: campaignSchedule,
              qualificationFrequency: campaignRule,
            }
          : null,
      exitCriteria: exitCriteria,
      triggerCriteria: triggerCriteria,
      campaignTags: campaignDetails.campaignTags,
      ...newData,
    };
  }

  const debouncedSave = useMemo(() => {
    return debounce(saveDraft, 2000);
  }, [saveDraft]);

  function onExitCriteriaChange(nodeGroups: NodeGroup[]) {
    setExitCriteria({ nodeGroups });
    let data = withOtherTabData({
      exitCriteria: { nodeGroups },
    });
    if (!data.exitCriteria?.nodeGroups[0].nodes.length) {
      data.exitCriteria = null;
    }
    debouncedSave(data);
  }

  function onTriggerCriteriaChange(nodeGroups: NodeGroup[]) {
    setTriggerCriteria({ nodeGroups });
    const data = withOtherTabData({
      triggerCriteria: { nodeGroups },
    });
    debouncedSave(data);
  }

  function onAudienceCriteriaChange(nodeGroups: NodeGroup[]) {
    setAudienceCriteria({ nodeGroups });
    const data = withOtherTabData({
      audienceCriteria: { nodeGroups },
    });
    debouncedSave(data);
  }

  function onTriggerCampaignSchedule(
    campaignSchedule: TimeFrame | null,
    campaignRule: QualificationFrequency | null
  ) {
    setCampaignSchedule(campaignSchedule);
    setCampaignRule(campaignRule);
    const data = withOtherTabData({
      journeySettings: {
        qualificationFrequency: campaignRule,
        timeframe: campaignSchedule,
      },
    });
    debouncedSave(data);
  }

  function validateAudienceCriteriaOnClose() {
    const isAudienceCriteriaValid = validateAudienceCriteria(audienceCriteria);
    setValidity((prevState) => {
      return {
        ...prevState,
        audienceCriteria: isAudienceCriteriaValid
          ? ""
          : isEmpty(audienceCriteria?.nodeGroups)
          ? ""
          : "Audience criteria is incomplete",
      };
    });
  }

  function validateTriggerCriteriaOnClose() {
    const isTriggerCriteriaValid = validateTriggerCriteria(triggerCriteria);
    setValidity((prevState) => {
      return {
        ...prevState,
        triggerCriteria: isTriggerCriteriaValid
          ? ""
          : isEmpty(triggerCriteria?.nodeGroups[0].nodes)
          ? "Trigger criteria is empty"
          : "Trigger criteria is incomplete",
      };
    });
  }

  function validateExitCriteriaOnClose() {
    const isExitCriteriaValid = validateExitCriteria(exitCriteria);
    setValidity((prevState) => {
      return {
        ...prevState,
        exitCriteria: isExitCriteriaValid
          ? ""
          : isEmpty(exitCriteria?.nodeGroups[0].nodes)
          ? ""
          : "Exit criteria is incomplete",
      };
    });
  }

  function validateData(callback: () => void, ...args: any[]) {
    dispatch(setActiveErrorCheck(true));
    const isAudienceCriteriaValid = validateAudienceCriteria(audienceCriteria);
    const isTriggerCriteriaValid = validateTriggerCriteria(triggerCriteria);
    const isExitCriteriaValid = validateExitCriteria(exitCriteria);
    const isFlowValid =
      Object.values(flowValidity).every((value) => value) &&
      !isEmpty(flow.nodes);
    const isScheduleValid = !isEmpty(campaignSchedule?.type);
    const isRuleValid = true;
    const validityMessage = {
      audienceCriteria: isAudienceCriteriaValid
        ? ""
        : isEmpty(audienceCriteria?.nodeGroups)
        ? ""
        : "Audience criteria is incomplete",
      flow: isFlowValid
        ? ""
        : isEmpty(flow.nodes)
        ? "Flow is empty"
        : "Flow is is incomplete",
      schedule: isScheduleValid ? "" : "Schedule is not configured",
      rule: isRuleValid ? "" : "Journey rule is invalid",
      exitCriteria: isExitCriteriaValid
        ? ""
        : isEmpty(exitCriteria?.nodeGroups[0].nodes)
        ? ""
        : "Exit criteria is incomplete",
      triggerCriteria: isTriggerCriteriaValid
        ? ""
        : isEmpty(triggerCriteria?.nodeGroups[0].nodes)
        ? "Trigger criteria is empty"
        : "Trigger criteria is incomplete",
    };
    setValidity(validityMessage);
    if (
      isAudienceCriteriaValid &&
      isTriggerCriteriaValid &&
      isExitCriteriaValid &&
      isFlowValid &&
      isScheduleValid &&
      isRuleValid
    ) {
      callback.apply(null, []);
    } else {
      toast.error(
        Object.values(validityMessage)
          .filter((msg) => !!msg)
          .join(", ")
      );
    }
  }

  function publishData() {
    const data = withOtherTabData({});
    dispatch(
      publishTriggerCampaign({
        campaignId: campaignDetails.campaignId,
        data,
      })
    );
  }

  function onPublish(active: boolean) {
    if (active) {
      switch (campaignDetails.status) {
        case CAMPAIGN_STATUS.DRAFT:
          validateData(publishData);
          break;
        case CAMPAIGN_STATUS.INACTIVE:
          if (
            campaignDetails.currentState === TRIGGER_CAMPAIGN_STATES.ARCHIVED
          ) {
            validateData(publishData);
          } else if (
            campaignDetails.currentState === TRIGGER_CAMPAIGN_STATES.PAUSED
          ) {
            dispatch(resumeTriggerCampaign(campaignDetails.campaignId));
          }
          break;
      }
    } else {
      switch (campaignDetails.status) {
        case CAMPAIGN_STATUS.ACTIVE:
          dispatch(pauseTriggerCampaign(campaignDetails.campaignId));
          break;
        default:
          validateData(() =>
            dispatch(archiveTriggerCampaign(campaignDetails.campaignId))
          );
          break;
      }
    }
  }

  function updateTags(tags: string[]) {
    dispatch(setTriggerCampaignTags(tags));
    saveDraft(withOtherTabData({ campaignTags: tags }));
  }

  const isReadOnly = campaignDetails.status === CAMPAIGN_STATUS.ACTIVE;

  return (
    <CampaignBuilderContext.Provider value={campaignDetails}>
      <CampaignBuilder
        onPublish={onPublish}
        updateTags={updateTags}
        updateName={(name: string) =>
          saveDraft(withOtherTabData({ campaignName: name }))
        }
      >
        <TriggerAudienceFilterSection
          data={audienceCriteria?.nodeGroups ?? []}
          readonly={isReadOnly}
          errorText={validity.audienceCriteria}
          saveDraft={() => {}}
          onChange={onAudienceCriteriaChange}
          isLoading={false}
          campaignContext={campaignDetails.campaignContext}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          validateOnClose={validateAudienceCriteriaOnClose}
        />

        <TriggerCriteriaSection
          data={triggerCriteria?.nodeGroups ?? []}
          onChange={onTriggerCriteriaChange}
          readonly={isReadOnly}
          errorText={validity.triggerCriteria}
          campaignContext={CAMPAIGN_CONTEXT.PERSON}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          validateOnClose={validateTriggerCriteriaOnClose}
        />
        <TriggerExitSection
          data={exitCriteria?.nodeGroups ?? []}
          onChange={onExitCriteriaChange}
          readonly={isReadOnly}
          errorText={validity.exitCriteria}
          campaignContext={CAMPAIGN_CONTEXT.PERSON}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          validateOnClose={validateExitCriteriaOnClose}
        />
        <TriggerCampaignScheduleSection
          rule={campaignRule ?? INIT_QUALIFICATION_FREQ}
          schedule={campaignSchedule ?? INIT_TIME_FRAME}
          onChange={onTriggerCampaignSchedule}
          isReadOnly={isReadOnly}
          activeErrorCheck={campaignDetails.activeErrorCheck}
          errorText={validity.schedule || validity.rule}
          isLoading={false}
        />
      </CampaignBuilder>
    </CampaignBuilderContext.Provider>
  );
}
