import { VStack, Box, Flex, HStack, Text, Icon } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { formatISO } from "date-fns";
import { useEffect, useMemo, useState, useCallback, useContext } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import {
  isLoading,
  redirectToContact,
} from "../../../../../../common/helper/commonHelper";
import { useDebouncedSearch } from "../../../../../../common/hooks/commonHooks";
import {
  CanvasDateFilter,
  WebhookFlowStepReportMemberDetails,
  SummaryStatsWebhook,
  CAMPAIGN_CONTEXT,
} from "../../../../../../common/types/campaign";
import { CommonListHeader } from "../../../../../../components/CommonListHeader";
import CustomDateRangePicker from "../../../../../../components/CustomDateRangePicker";
import { DataTable } from "../../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../../components/DateTimeRangeFilter";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import { useAppDispatch } from "../../../../../../store";
import {
  exportFlowStepDetailedReport,
  selectExport,
} from "../../../../export/exportSlice";
import {
  CampaignBuilderContext,
  getFlowStepDetailedReport,
  selectFlow,
  setFlowStepDetailMembersPagination,
} from "../../flowSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import FlowStepReport from "../FlowStepReport";

const WEBHOOK_PROPERTIES = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.WEBHOOK];
const COLUMNS_TO_SEARCH_IN = [
  "product_user_id",
  "person_id",
  "email",
  "response",
];

export default function WebhookFlowStepReport({
  title,
  actionId,
  campaignId,
  data,
  isOpen,
  onClose,
  dateFilter,
}: {
  title: string;
  actionId: string;
  campaignId: string;
  data: SummaryStatsWebhook | undefined;
  isOpen: boolean;
  onClose: () => void;
  dateFilter: CanvasDateFilter;
}) {
  const dispatch = useAppDispatch();
  const { campaignContext } = useContext(CampaignBuilderContext);

  const {
    reports: {
      data: { flowStepDetailReport },
    },
  } = useSelector(selectFlow);

  const { assetsToExport } = useSelector(selectExport);

  const [searchQuery, setSearchQuery] = useState("");

  const [dateFilterLocal, setDateFilterLocal] =
    useState<CanvasDateFilter>(dateFilter);

  function exportReport() {
    dispatch(
      exportFlowStepDetailedReport({
        campaignId,
        actionId,
        startDate: dateFilterLocal.startDate,
        endDate: dateFilterLocal.endDate,
        query: searchQuery,
        columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
      })
    );
  }
  const columnHelper = createColumnHelper<WebhookFlowStepReportMemberDetails>();

  const columns = useMemo(() => {
    let columns: ColumnDef<WebhookFlowStepReportMemberDetails, any>[] = [];

    if (campaignContext === CAMPAIGN_CONTEXT.ORG) {
      columns.push(
        columnHelper.accessor("product_user_id", {
          header: "User",
          size: 400,
        })
      );
    }
    columns.push(
      columnHelper.accessor("email", {
        header: "Email",
        size: 400,
        cell: (info) => (
          <HStack>
            <Text>{info.getValue()}</Text>
            <Box hidden={!info.getValue()}>
              <Icon
                as={FaExternalLinkAlt}
                fontSize="12"
                onClick={() =>
                  redirectToContact(info.cell.row.original.person_id)
                }
                cursor="pointer"
              />
            </Box>
          </HStack>
        ),
      }),
      columnHelper.accessor("timestamp", {
        header: "Timestamp",
        size: 350,
        cell: (info) => <FormatDate date={info.getValue()} />,
      }),
      columnHelper.accessor("response", {
        header: "Response",
        size: 400,
        cell: (info) => (
          <Flex>
            <Text>{info.row.original.response?.code}</Text> -{" "}
            <Text>{info.row.original.response?.message}</Text>
          </Flex>
        ),
      })
    );
    return columns;
  }, [campaignContext, columnHelper]);

  const searchIfValid = useCallback(
    (query: string) => {
      dispatch(
        getFlowStepDetailedReport({
          campaignId,
          actionId,
          startDate: dateFilterLocal.startDate,
          endDate: dateFilterLocal.endDate,
          query,
          pageNumber: 1,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    },
    [
      actionId,
      campaignId,
      dateFilterLocal.endDate,
      dateFilterLocal.startDate,
      dispatch,
    ]
  );

  useEffect(() => {
    setSearchQuery("");
    dispatch(
      getFlowStepDetailedReport({
        campaignId,
        actionId,
        startDate: dateFilterLocal.startDate,
        endDate: dateFilterLocal.endDate,
        query: "",
        pageNumber: 1,
        columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
      })
    );
  }, [actionId, campaignId, dateFilterLocal, dispatch]);

  function changePage(pageNumber: number) {
    dispatch(
      getFlowStepDetailedReport({
        campaignId,
        actionId,
        startDate: dateFilterLocal.startDate,
        endDate: dateFilterLocal.endDate,
        query: searchQuery,
        pageNumber,
        columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
      })
    );
  }

  useEffect(() => {
    setDateFilterLocal(dateFilter);
  }, [dateFilter]);

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  return (
    <FlowStepReport
      isOpen={isOpen}
      onClose={onClose}
      title={WEBHOOK_PROPERTIES.label}
      icon={WEBHOOK_PROPERTIES.icon}
      color={WEBHOOK_PROPERTIES.color}
    >
      <VStack w="100%" spacing="0">
        <Box w="100%" h="92px" bg="gray.100" justifyContent="space-between">
          Empty Spacer
        </Box>

        <Flex
          w="100%"
          py="4"
          px="6"
          h="100px"
          bg="gray.100"
          top="0px"
          position="absolute"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <VStack alignItems="flex-start">
            <Text>{title}</Text>
          </VStack>
          <HStack>
            <Text fontSize="sm">Date Range</Text>
            <CustomDateRangePicker
              startDate={new Date(dateFilterLocal.startDate)}
              endDate={new Date(dateFilterLocal.endDate)}
              onDateRangeChange={(startDate, endDate) =>
                setDateFilterLocal({
                  startDate: formatISO(startDate),
                  endDate: formatISO(endDate),
                })
              }
              inputProps={{ border: 0 }}
            />
          </HStack>
        </Flex>

        <Flex w="100%" flexDir="column" pt="2">
          <CommonListHeader
            isLoading={isLoading(flowStepDetailReport.loading)}
            heading={`Showing ${
              flowStepDetailReport.data.list?.length ?? 0
            } of ${flowStepDetailReport.data.count ?? 0} results`}
            titleProps={{
              fontSize: "12px",
              fontWeight: 400,
              color: "grayV2.600",
            }}
            searchInputProps={{
              placeholder: "Search results  ",
              name: "search-input",
              onSearch: (query) => {
                setSearchQuery(query);
                debouncedSearch(query);
              },
              value: searchQuery,
            }}
            headerStackProps={{ px: "0px" }}
          >
            <IButton
              variant={BUTTON.SECONDARY}
              leftIcon={<MdOutlineFileDownload fontSize="14px" />}
              isLoading={isLoading(
                assetsToExport[campaignId + actionId]?.isLoading
              )}
              isDisabled={!flowStepDetailReport.data?.totalPageCount}
              onClick={exportReport}
            >
              Export
            </IButton>
          </CommonListHeader>
          <DataTable
            fetchingList={isLoading(flowStepDetailReport.loading)}
            changingPage={flowStepDetailReport.data?.changingPage}
            list={flowStepDetailReport.data?.list ?? []}
            totalPageCount={flowStepDetailReport.data?.totalPageCount ?? 0}
            currentPage={flowStepDetailReport.data?.currentPageNo ?? 0}
            totalPageSize={flowStepDetailReport.data?.pageSize ?? 10}
            setPage={(pageNo) => {
              changePage(pageNo);
              dispatch(setFlowStepDetailMembersPagination(pageNo));
            }}
            columns={columns}
            emptyMsg="No results found"
          />
        </Flex>
      </VStack>
    </FlowStepReport>
  );
}
