import { Box, Flex, VStack, Badge } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import {
  FILTER_TYPE,
  IS_EQUAL_OPERATOR,
} from "../../../../../common/constants/campaign";
import { createGroupId } from "../../../../../common/helper/commonHelper";
import {
  DynamicListType,
  DynamicListChild,
  CONNECTOR,
  TYPE,
} from "../../../../../common/types/campaign";
import AddConditionButton from "./AddConditionButton";
import MarketingEventFilterGroup from "./MarketingEventFilterGroup";
import ContactUpdateFilterGroup from "./ContactUpdateFilterGroup";
import ProductEventFilterGroup from "./ProductEventFilterGroup";

const NEW_OUTER_GROUP: Readonly<DynamicListType> = {
  type: TYPE.GROUP,
  name: "",
  filter_type: null,
  filter: null,
  property: null,
  operator: null,
  value: null,
  connector: CONNECTOR.OR,
  children: [],
};

const NEW_FILTER_GROUP: { [key: string]: Readonly<DynamicListChild> } = {
  [FILTER_TYPE.PERSON]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.PERSON,
        filter: "",
        property: null,
        operator: null,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.PRODUCT_ACTIVITY]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
        filter: "event_name",
        property: null,
        operator: IS_EQUAL_OPERATOR,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.MARKETING_ACTIVITY]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.MARKETING_ACTIVITY,
        filter: "event_name",
        property: null,
        operator: IS_EQUAL_OPERATOR,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
  [FILTER_TYPE.LIST_MEMBERSHIP]: {
    ...NEW_OUTER_GROUP,
    children: [
      {
        type: TYPE.EXPRESSION,
        filter_type: FILTER_TYPE.LIST_MEMBERSHIP,
        filter: null,
        property: null,
        operator: null,
        value: [""],
        connector: CONNECTOR.EMPTY,
      },
    ],
  },
};

const OUTER_GROUP_ID = "exit-criteria-outer-group";

export default function OuterGroup({
  data,
  onChange,
  isReadOnly,
}: {
  data: DynamicListType;
  onChange: (data: DynamicListType) => void;
  isReadOnly?: boolean;
}) {
  function onAddFilterGroup(type: FILTER_TYPE) {
    const dataCopy = cloneDeep(data);
    dataCopy.children.push(NEW_FILTER_GROUP[type]);
    onChange(dataCopy);
  }

  function onRemoveFilterGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function updateFilterGroup(index: number, newData: DynamicListChild) {
    const dataCopy = cloneDeep(data);
    dataCopy.children[index] = newData;
    onChange(dataCopy);
  }

  function renderFilterGroup(filterGroup: DynamicListChild, index: number) {
    if (!filterGroup.children?.[0]?.filter_type) {
      return <></>;
    }
    switch (filterGroup.children[0].filter_type) {
      case FILTER_TYPE.PRODUCT_ACTIVITY:
        return (
          <ProductEventFilterGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.PERSON:
        return (
          <ContactUpdateFilterGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            label=""
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case FILTER_TYPE.MARKETING_ACTIVITY:
        return (
          <MarketingEventFilterGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      default:
        throw new Error("Invalid Filter Type");
    }
  }

  const wrapperStyle = !isReadOnly
    ? {
        p: "3",
        rounded: "lg",
        bg: "grayV2.100",
        borderWidth: "1px",
      }
    : {};

  return (
    <Box
      width="100%"
      alignItems="flex-start"
      id={OUTER_GROUP_ID}
      {...wrapperStyle}
    >
      <VStack alignItems="flex-start" spacing="2">
        {data.children.map((filterGroup, index) => {
          return (
            <>
              <Flex key={index} w="100%">
                {renderFilterGroup(filterGroup, index)}
              </Flex>
              {data.children.length > 1 &&
                index !== data.children.length - 1 && (
                  <Badge
                    fontSize="10px"
                    px={3}
                    my={1}
                    mx={3}
                    borderRadius="4px"
                    bg="brandBlue.900"
                    variant="solid"
                  >
                    {CONNECTOR.OR}
                    {/* Setting or as default because old exit criteria data might have and as connector and it might confuse user.
                    Backend handles it as OR irresepective what frontend sends */}
                  </Badge>
                )}
            </>
          );
        })}
      </VStack>
      {!isReadOnly && (
        <Box mt={data.children.length ? "5" : "0"}>
          <AddConditionButton onClick={onAddFilterGroup} />
        </Box>
      )}
    </Box>
  );
}
