import { Box, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { SingleValue } from "react-select";
import { INCLUSION_LIST_TYPES } from "../../../../../../../common/types/campaign";
import { SalesforceConnection } from "../../../../../../../common/types/salesforceLegacyService";
import { AssetField } from "../../../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../../../store";
import { listAllDynamicLists } from "../../../../../../../components/dynamic-list/dynamicListSlice";
import { listStaticListSummaryList } from "../../../../../staticlist/staticlistSlice";
import {
  removeInclusionList,
  updateInclusionList,
} from "../../../salesforceLegacySlice";
import { Header } from "./Header";
import { NoteBox } from "./NoteBox";
import ListSelectionDropdownWithPreview, {
  LIST_SELECTION_DROPDOWN_VARIANT,
} from "../../../../../../../components/ListSelectionDropdown";

export default function InflectionToSalesforceLegacyInclusionList({
  data,
}: {
  data: SalesforceConnection;
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAllDynamicLists());
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  const connectionId = data.connection_id;

  const onValueChange = (val: SingleValue<AssetField>) => {
    if (val && val.id) {
      const inclusionListData = {
        connectionId,
        inclusionList: {
          list_id: val.id,
          list_type: val.type as INCLUSION_LIST_TYPES,
        },
      };
      dispatch(updateInclusionList(inclusionListData));
    } else {
      dispatch(removeInclusionList(connectionId));
    }
  };

  return (
    <Box>
      <Header
        title="Limiting what syncs"
        desc="An Inflection contact must be on the inclusion list to sync to Salesforce"
      />

      <Box mt="2">
        <NoteBox text="If no list is selected, all Inflection contacts will be synced to Salesforce" />
      </Box>

      <Text mt="3">Select an inclusion list</Text>

      <Box pt="3" width="300px">
        <ListSelectionDropdownWithPreview
          variant={LIST_SELECTION_DROPDOWN_VARIANT.DYNAMIC_LIST}
          value={data.inclusion_list?.list_id ?? null}
          onChange={onValueChange}
        />
      </Box>
    </Box>
  );
}
