import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
} from "@chakra-ui/react";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IButton from "../../components/IButton";
import { Logo } from "../../components/Logo";
import { PasswordField } from "../../components/PasswordField";
import urls from "../../urls";
import { login, resetLoginCreds, selectAccount } from "./accountSlice";

export default function LoginPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginLoading, userEmail, invalidCredsErrorMsg } =
    useSelector(selectAccount);

  function loginUser(e: React.SyntheticEvent) {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      password: { value: string };
    };
    const data = {
      email: userEmail,
      password: target.password.value,
    };
    dispatch(login(data));
  }

  const goToEmailPage = useCallback(() => {
    dispatch(resetLoginCreds());
    navigate(urls.login);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!userEmail) {
      goToEmailPage();
    }
  }, [userEmail, goToEmailPage]);

  return (
    <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Logo mx="auto" h="8" mb={{ base: "10", md: "20" }} />
        <Box mb="12" textAlign="center">
          <Heading size="md" fontWeight="extrabold">
            Hello, {userEmail} !
          </Heading>
          <IButton
            onClick={goToEmailPage}
            name="go-back-button"
            colorScheme="blue"
            variant="link"
          >
            (This is not me)
          </IButton>
        </Box>
        <Box
          bg="white"
          py="12"
          px={{ base: "4", md: "10" }}
          shadow="base"
          w="sm"
          rounded={{ sm: "lg" }}
        >
          <form onSubmit={loginUser}>
            <Stack spacing="6">
              <FormControl
                id="password"
                isInvalid={invalidCredsErrorMsg !== ""}
              >
                <Flex justify="space-between">
                  <FormLabel>Password</FormLabel>
                </Flex>
                <PasswordField isRequired={true} autoFocus />
                <FormErrorMessage>{invalidCredsErrorMsg}</FormErrorMessage>
              </FormControl>
              <IButton
                type="submit"
                isLoading={loginLoading}
                isDisabled={loginLoading}
                colorScheme="blue"
                size="lg"
                fontSize="md"
                name="sign-in-button"
              >
                Sign in
              </IButton>
            </Stack>
          </form>
        </Box>
        <IButton
          onClick={() => navigate(urls.emailForgot)}
          fontSize="sm"
          mt="3"
          colorScheme="blue"
          variant="link"
          data-cy="forgot-password-link"
          name="forgot-pw-button"
        >
          Forgot password?
        </IButton>
      </Box>
    </Box>
  );
}
