import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import urls from "../../urls";
import { lookup, selectAccount, setEmail } from "./accountSlice";
import EmailOnlyPage from "./components/EmailOnlyPage";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [run, setRun] = useState(0);

  const { loadingLookup, authType } = useSelector(selectAccount);

  function lookupEmail(data: { email: string }) {
    setRun(1);
    dispatch(setEmail(data.email));
    dispatch(lookup(data));
  }

  useEffect(() => {
    if (!loadingLookup) {
      if (authType === "sso" && run) {
        navigate(urls.loginSso);
      } else if (authType === "inflection" && run) {
        navigate(urls.loginPassword);
      }
    }
  }, [navigate, authType, run, loadingLookup]);

  return (
    <EmailOnlyPage
      heading="Sign in to your account"
      submitFn={lookupEmail}
      loading={loadingLookup}
      buttonText="Continue"
      hyperlinks={[
        {
          onClick: () => navigate(urls.emailForgot),
          label: "Forgot Password?",
        },
      ]}
    />
  );
}
