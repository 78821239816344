import {
  Box,
  Heading,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  isFulfilled,
  isLoading,
  sortAlphabetically,
} from "../../../common/helper/commonHelper";
import { isSalesforcePreference } from "../../../common/helper/unifiedMappingHelper";
import { PersonDestination } from "../../../common/types/person";
import {
  Destination,
  DESTINATION_TYPES,
} from "../../../common/types/unifiedMapping";
import { DataTable } from "../../../components/data-table/DataTable";
import DropdownWithSearch from "../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../components/IButton";
import IModal from "../../../components/IModal";
import { CustomOptionWithRequired } from "../../../components/OptionHelper";
import { useAppDispatch } from "../../../store";
import {
  getPersonMappingDetails,
  selectPerson,
} from "../persondb/personDbSlice";
import { getFormDetails, selectForm, updateFormMapping } from "./formSlice";
import TextWithWarning from "../../../components/TextWithWarning";
import { EMPTY_CONTEXT } from "../../../common/constants/common";
import { EMAIL_FIELD_KEY, MAPPING_RULES } from "../../../common/constants/form";
import { FIELD_TYPE_OPTIONS } from "../../../common/constants/unifiedMapping";

type FormMappingListItem = Destination & {
  destination_id?: string | null;
  mapping_rule?: MAPPING_RULES | null;
};

const MAPPING_RULES_OPTIONS = [
  {
    label: "Always overwrite",
    value: MAPPING_RULES.ALWAYS_OVERWRITE,
  },
  {
    label: "Update if empty",
    value: MAPPING_RULES.UPDATE_IF_EMPTY,
  },
];

function MappingHeader({
  isEditMode,
  setIsEditMode,
  onCancel,
  onSave,
  isMappingLoading,
  mapStat,
}: {
  isEditMode: boolean;
  setIsEditMode: (toggle: boolean) => void;
  onCancel: () => void;
  onSave: () => void;
  isMappingLoading: boolean;
  mapStat: {
    count: number;
    totalCount: number;
  };
}) {
  return (
    <HStack w="100%" justifyContent="space-between">
      <HStack>
        <Heading fontSize="16px">Mapping</Heading>
        <HStack
          backgroundColor={"grayV2.200"}
          px="8px"
          py={1}
          spacing={1}
          fontSize={12}
          borderRadius={4}
        >
          <Text fontWeight={600}>{mapStat.count}</Text>
          <Text> out of </Text>
          <Text fontWeight={600}>{mapStat.totalCount}</Text>
          <Text>mapped</Text>
        </HStack>
      </HStack>
      {isEditMode ? (
        <HStack>
          <IButton
            variant="ghost"
            onClick={onCancel}
            color={"text.50"}
            fontSize="12px"
            isDisabled={isMappingLoading}
          >
            Cancel
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={onSave}
            isLoading={isMappingLoading}
            px={4}
          >
            Save
          </IButton>
        </HStack>
      ) : (
        <IButton
          variant={BUTTON.PRIMARY}
          px={4}
          onClick={() => setIsEditMode(true)}
        >
          Edit
        </IButton>
      )}
    </HStack>
  );
}

function SourceDataType({
  value,
  onValueChange,
  isEditMode,
}: {
  value: DESTINATION_TYPES;
  isEditMode: boolean;
  onValueChange: (value: DESTINATION_TYPES) => void;
}) {
  if (isEditMode)
    return (
      <Box w="150px">
        <DropdownWithSearch<(typeof FIELD_TYPE_OPTIONS)[0]>
          options={FIELD_TYPE_OPTIONS}
          value={FIELD_TYPE_OPTIONS.find((x) => x.value === value)}
          onChange={(option) => option && onValueChange(option.value)}
          isSearchable
        />
      </Box>
    );
  else return <Text color="text.50">{capitalize(value)}</Text>;
}

function FormFieldDestination({
  allColumns,
  field,
  isEditMode,
  onDestinationChange,
  allFields,
}: {
  allColumns: PersonDestination[];
  field: FormMappingListItem;
  isEditMode: boolean;
  allFields: FormMappingListItem[];
  onDestinationChange: (
    source_id: string,
    selected: PersonDestination | null
  ) => void;
}) {
  const filteredColumns = useMemo(
    () =>
      allColumns.filter((column) => {
        if (
          field.type !== DESTINATION_TYPES.STRING &&
          column.type !== field.type
        ) {
          return false;
        }
        if (
          column.name !== field.destination_id &&
          allFields.some((field) => field.destination_id === column.name)
        ) {
          return false;
        }
        return true;
      }),
    [allColumns, allFields, field.destination_id, field.type]
  );

  const destination = filteredColumns.find(
    (column) => column.name === field.destination_id
  );

  if (isEditMode)
    return (
      <Box w="300px">
        <DropdownWithSearch<PersonDestination>
          options={filteredColumns}
          getOptionLabel={(option) => option.display}
          getOptionValue={(option) => option.name}
          value={destination ?? null}
          isOptionDisabled={(option) =>
            isSalesforcePreference(option.sync_preference)
          }
          onChange={(option) => onDestinationChange(field.name, option)}
          components={{
            Option: CustomOptionWithRequired,
          }}
          placeholder={"None (Unmapped)"}
          isClearable
          isSearchable
        />
      </Box>
    );
  else
    return <Text color="text.50">{destination?.display ?? "(Unmapped)"}</Text>;
}

export default function FormMapping() {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    formDetails: { data: formDetailsData, loading: fetchingMapping },
  } = useSelector(selectForm);
  const dispatch = useAppDispatch();
  const dataTypeUpdatedRows = useRef<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [typeChangeWarning, setTypeChangeWarning] = useState<{
    dl_warning: boolean;
    un_mapped_warning: boolean;
  }>({ dl_warning: false, un_mapped_warning: false });

  const {
    personMappingDetails: { data: personMappingDetails },
  } = useSelector(selectPerson);

  const [allFields, setAllFields] = useState<FormMappingListItem[]>([]);
  const [updatingFormMapping, setUpdatingFormMapping] = useState(false);

  const mapAllFields = useCallback(() => {
    const allFieldData = Object.entries(formDetailsData.all_fields ?? {}).map(
      ([key, details]) => {
        return {
          ...details.source,
          destination_id: formDetailsData.mapped_fields
            ? formDetailsData.mapped_fields[key]?.destination?.name ?? null
            : null,
          mapping_rule: formDetailsData.mapped_fields
            ? formDetailsData.mapped_fields[key]?.destination.mapping_rule
            : null,
        };
      }
    );
    allFieldData.forEach((x, index) => {
      if (x.destination_id === EMAIL_FIELD_KEY) {
        const temp = allFieldData[0];
        allFieldData[0] = allFieldData[index];
        allFieldData[index] = temp;
      }
    });
    setAllFields(allFieldData);
  }, [formDetailsData.all_fields, formDetailsData.mapped_fields]);

  useEffect(() => {
    mapAllFields();
  }, [mapAllFields]);

  const allColumns = useMemo(() => {
    return Object.entries(personMappingDetails ?? {})
      .map(([key, details]) => {
        return {
          ...details,
          required: key === EMAIL_FIELD_KEY,
        };
      })
      .filter((column) => !column.hidden)
      .sort((a, b) => sortAlphabetically(a.display, b.display));
  }, [personMappingDetails]);

  useEffect(() => {
    dispatch(getPersonMappingDetails());
  }, [dispatch]);

  function onMappingRuleChange(
    mappingRule: MAPPING_RULES | null,
    sourceId: string
  ) {
    setAllFields((prevData) => {
      const prev = [...prevData];
      const index = prev.findIndex((field) => field.name === sourceId);
      if (index > -1) {
        prev[index].mapping_rule = mappingRule;
      }
      return prev;
    });
  }

  function onSourceDataTypeChange(sourceId: string, value: DESTINATION_TYPES) {
    setAllFields((prevData) => {
      const prev = [...prevData];
      const index = prev.findIndex((field) => field.name === sourceId);
      if (index > -1) {
        prev[index].type = value;
        dataTypeUpdatedRows.current.push(index);
        prev[index].destination_id = null;
      }
      return prev;
    });
  }

  function onDestinationChange(
    sourceId: string,
    selected: PersonDestination | null
  ) {
    setAllFields((prevData) => {
      const prev = [...prevData];
      const index = prev.findIndex((field) => field.name === sourceId);
      if (index > -1) {
        prev[index].destination_id = selected?.name;
      }
      return prev;
    });
  }

  const columnHelper = createColumnHelper<FormMappingListItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => {
          const name = info.row.original.display ?? info.row.original.name;
          return name;
        },
        header: "Field name",
        minSize: 700,
      }),

      columnHelper.accessor("type", {
        cell: (info) => {
          return (
            <SourceDataType
              value={info.getValue()}
              onValueChange={(value) =>
                onSourceDataTypeChange(info.row.original.name, value)
              }
              isEditMode={isEditMode}
            />
          );
        },
        header: "Data type",
        minSize: 200,
      }),

      columnHelper.display({
        cell: (info) => (
          <FormFieldDestination
            allColumns={allColumns}
            onDestinationChange={onDestinationChange}
            allFields={allFields}
            field={info.row.original}
            isEditMode={isEditMode}
          />
        ),
        header: () => {
          const showWarning = allFields.every(
            (field) => field.destination_id !== EMAIL_FIELD_KEY
          );
          return (
            <TextWithWarning
              text="Contact Property"
              warningText={
                showWarning
                  ? "It is required to map the Email contact property to a form field to save the mapping schema"
                  : ""
              }
            />
          );
        },
        id: "actions",
        minSize: 400,
      }),
      columnHelper.display({
        cell: (info) =>
          isEditMode ? (
            <DropdownWithSearch
              options={MAPPING_RULES_OPTIONS}
              value={MAPPING_RULES_OPTIONS.find(
                (x) => x.value === info.row.original.mapping_rule
              )}
              getOptionValue={(x) => x.value}
              getOptionLabel={(x) => x.label}
              isSearchable={true}
              onChange={(option) =>
                onMappingRuleChange(
                  option?.value ?? null,
                  info.row.original.name
                )
              }
            />
          ) : (
            <Text>
              {MAPPING_RULES_OPTIONS.find(
                (x) => x.value === info.row.original.mapping_rule
              )?.label || EMPTY_CONTEXT}
            </Text>
          ),
        header: "Mapping Rule",
        id: "mapping_rule",
        minSize: 400,
      }),
    ],
    [columnHelper, allColumns, isEditMode, allFields]
  );

  function clearDraft() {
    dataTypeUpdatedRows.current = [];
    setIsEditMode(false);
    mapAllFields();
  }

  async function saveMapping(bypassOptionalWarnings: boolean = false) {
    const emailSource = allFields.find(
      (field) => field.destination_id === EMAIL_FIELD_KEY
    )?.name;
    if (!emailSource) {
      onOpen();
      return;
    }

    if (!bypassOptionalWarnings) {
      if (dataTypeUpdatedRows.current.length) {
        const unmappedFieldsWithDataTypeChanges = allFields.some(
          (field, index) =>
            !field.destination_id && dataTypeUpdatedRows.current.includes(index)
        );
        setTypeChangeWarning({
          dl_warning: true,
          un_mapped_warning: unmappedFieldsWithDataTypeChanges,
        });
        return;
      }
    }

    setUpdatingFormMapping(true);
    const mapping = allFields
      .filter((field) => field.destination_id)
      .map((field) => {
        const mappingField = { ...field };
        const destination = allColumns.find(
          (column) => column.name === mappingField.destination_id
        ) as Destination;
        delete mappingField.destination_id;
        delete mappingField.mapping_rule;
        return {
          source: mappingField,
          destination: {
            ...destination,
            mapping_rule: field.mapping_rule,
          },
        };
      }, []);
    const response = await dispatch(
      updateFormMapping({
        formId: formDetailsData.form_id,
        emailColumn: emailSource ?? "",
        mapping: mapping,
      })
    );
    setUpdatingFormMapping(false);
    if (isFulfilled(response.meta.requestStatus)) {
      toast.success("Form mapping updated successfully!");
      setIsEditMode(false);
      dispatch(getFormDetails(formDetailsData.form_id));
      dataTypeUpdatedRows.current = [];
    }
  }

  function onCloseTypeChangeWarning() {
    setTypeChangeWarning({ dl_warning: false, un_mapped_warning: false });
  }

  return (
    <VStack spacing={4} h="100%">
      <MappingHeader
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        onCancel={clearDraft}
        onSave={() => saveMapping()}
        isMappingLoading={updatingFormMapping}
        mapStat={{
          count: allFields.filter((field) => field.destination_id).length,
          totalCount: allFields.length,
        }}
      />
      <DataTable
        list={allFields}
        fetchingList={isLoading(fetchingMapping)}
        changingPage={isLoading(fetchingMapping)}
        totalPageSize={5}
        setPage={() => {}}
        totalPageCount={1}
        currentPage={1}
        columns={columns}
        emptyMsg="No fields to map"
        tableHeight="calc(100% - 140px)"
      />
      <IModal
        isOpen={isOpen}
        onClose={onClose}
        header={{ title: "The mapping cannot be saved" }}
        primaryButton={{
          label: "Continue mapping",
          props: { onClick: onClose },
        }}
      >
        <Text fontSize={14}>
          It is required to map the{" "}
          <Text as="span" fontWeight={600}>
            'Email'{" "}
          </Text>
          contact property to a form field to save the mapping schema.
        </Text>
      </IModal>
      <IModal
        isOpen={Object.values(typeChangeWarning).some((x) => x)}
        onClose={onCloseTypeChangeWarning}
        header={{ title: "Warning" }}
        primaryButton={{
          label: "Continue saving",
          props: {
            onClick: () => {
              onCloseTypeChangeWarning();
              saveMapping(true);
            },
          },
        }}
        secondaryButton={{
          label: "Cancel",
          props: { onClick: onCloseTypeChangeWarning },
        }}
      >
        <Text fontSize={14}>
          {typeChangeWarning.dl_warning &&
            "Changing the data type for any field may impact it's usage in journeys and affect audience filtering"}
          {typeChangeWarning.un_mapped_warning &&
            " & changes to unmapped fields will be ignored."}
        </Text>
        <Text fontSize={14} mt={2}>
          Do you want to continue saving?
        </Text>
      </IModal>
    </VStack>
  );
}
