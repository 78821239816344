import { Box, Skeleton, Text, VStack } from "@chakra-ui/react";
import { capitalize } from "lodash";
import { useSelector } from "react-redux";
import {
  getFormattedTenantTimezone,
  isLoading,
} from "../../../common/helper/commonHelper";
import { CommonListHeader } from "../../../components/CommonListHeader";
import ITitle from "../../../components/ITitle";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import { selectAccount } from "../../account/accountSlice";
import { selectSettings } from "./settingsSlice";

function WorkSpaceItem({
  title,
  subText,
}: {
  title: string;
  subText: string | null;
}) {
  const {
    tenantDetails: { loading: tenantDetailsLoading },
  } = useSelector(selectSettings);
  return (
    <Skeleton isLoaded={!isLoading(tenantDetailsLoading)}>
      <Box maxW="100%">
        <Text fontSize="14px" color="blackAlpha.700" fontWeight="600" mb={2}>
          {title}
        </Text>
        <Text fontSize="14px" color="blackAlpha.600">
          {subText}
        </Text>
      </Box>
    </Skeleton>
  );
}
export default function WorkSpaceSettings() {
  const {
    tenantDetails: { data: tenantDetails },
  } = useSelector(selectSettings);
  const {
    userOrg: { name: workspaceName },
  } = useSelector(selectAccount);

  return (
    <ContentContainerWithHeader mr="20px">
      <CommonListHeader heading="" />
      <ContentContainer flexDir="row" h="90vh">
        <Box w="100%" h="100%">
          <ITitle
            title="Workspace Details"
            fontWeight="600"
            color="blackAlpha.700"
            pb={8}
          />
          <VStack
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={12}
          >
            <WorkSpaceItem
              title="Workspace name"
              subText={capitalize(workspaceName)}
            />
            <WorkSpaceItem
              title="Workspace timezone"
              subText={getFormattedTenantTimezone(tenantDetails)}
            />
          </VStack>
        </Box>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
