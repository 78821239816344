import { Box, Code, Heading, VStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { EmailTokenFunction } from "../../../../common/types/campaign";

function FunctionCodeSample({
  title,
  code,
}: {
  title: string;
  code: string[];
}) {
  return (
    <Box>
      <Heading px="10" size="md" py="3">
        {title}
      </Heading>
      <VStack
        px="10"
        py="3"
        alignItems={"flex-start"}
        spacing={3}
        bg="white"
        width="100%"
      >
        {code.map((sample, index) => {
          return (
            <Code bg="white" color="blue.500" key={index}>
              {sample}
            </Code>
          );
        })}
      </VStack>
    </Box>
  );
}

export default function FunctionDescription({
  functionDetails,
}: {
  functionDetails: EmailTokenFunction;
}) {
  return (
    <Box
      bg="grayV2.100"
      h="100%"
      overflow="auto"
      borderColor="grayV2.100"
      borderWidth="1px"
    >
      <Heading pt="5" px="10" size="lg">
        {functionDetails.name}
      </Heading>

      <Text px="10" fontSize="sm" mt="2">
        {functionDetails.description}
      </Text>

      <VStack alignItems="left" mt="6">
        <FunctionCodeSample
          title="Sample Usage"
          code={functionDetails.sample.split("\n")}
        />
        <FunctionCodeSample title="Syntax" code={[functionDetails.syntax]} />
      </VStack>
    </Box>
  );
}
