import { Box, BoxProps, HStack, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons/lib";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";

export function FilterGroupBox({
  children,
  onRemove,
  isReadOnly,
  icon,
  hideRemove,
  ...sx
}: {
  children: ReactNode;
  onRemove: () => void;
  isReadOnly?: boolean;
  icon?: IconType;
  hideRemove?: boolean;
} & BoxProps) {
  if (isReadOnly) {
    return (
      <HStack
        width="100%"
        fontSize="14px"
        alignItems="flex-start"
        rounded="md"
        borderColor="grayV2.100"
        borderWidth="1px"
        py="1"
        px="2"
        bgColor="grayV2.100"
        fontWeight="500"
      >
        {icon && <Icon as={icon} color="brand.blue" mt={1} />}
        {children}
      </HStack>
    );
  } else {
    return (
      <HStack width="100%" {...sx}>
        <Box>{children}</Box>
        {!hideRemove && <RemoveRowCloseButton onClick={onRemove} />}
      </HStack>
    );
  }
}
