import { Box } from "@chakra-ui/react";
import { isNull } from "lodash";
import { useSelector } from "react-redux";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from "reactflow";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { EdgeDataProps } from "../../../../../common/types/flow";
import { useAppDispatch } from "../../../../../store";
import { openFlowMemberReport, selectFlow } from "../flowSlice";
import { AddButton } from "./AddButton";
import { INIT_FLOW_NODE_ID } from "./constants";
import MemberCount from "./MemberCount";

export default function CustomStepEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps<EdgeDataProps>) {
  const dispatch = useAppDispatch();

  const {
    reports: {
      data: { nodeEdgeCount },
    },
  } = useSelector(selectFlow);

  function getEdgeCount(prevActionId?: string, branchId?: string) {
    if (prevActionId) {
      const nodeData = nodeEdgeCount?.data?.nodes[prevActionId];
      if (nodeData?.count) {
        return nodeData.count;
      } else if (branchId && nodeData?.branches) {
        const branchData = nodeData.branches.find(
          (x) => x.branch_id === branchId
        );
        if (branchData && branchData) {
          return branchData.count;
        }
      }
    }
    return null;
  }

  const edgeCount: number | null = getEdgeCount(
    data?.prevActionId,
    data?.branchId
  );

  const showCount = !!(
    data?.prevActionId &&
    data?.prevActionId !== INIT_FLOW_NODE_ID &&
    !isNull(edgeCount)
  );

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  function onClick() {
    if (data?.prevActionId) {
      dispatch(
        openFlowMemberReport({
          actionId: data.prevActionId,
          branchId: data.branchId,
        })
      );
    }
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />

      <EdgeLabelRenderer>
        {data?.isStepAddable && (
          <Box
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${
                labelY - (showCount ? 20 : 0)
              }px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <AddButton
              parentActionId={data.prevActionId ?? ""}
              branchId={data?.branchId ?? ""}
              groupId={data?.groupId}
              isFlowEmpty={false}
              isBetweenFlowSteps={true}
              props={{ readonly: data?.readonly }}
            />
          </Box>
        )}
        {(data?.isStepAddable || data?.isLastStep) && showCount && (
          <Box
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${
                labelY + 20
              }px)`,
              fontSize: 12,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: "all",
            }}
            className="nodrag nopan"
            onClick={onClick}
          >
            <MemberCount
              count={edgeCount}
              loading={isLoading(nodeEdgeCount.loading)}
            />
          </Box>
        )}
      </EdgeLabelRenderer>
    </>
  );
}
