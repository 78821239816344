import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import { PreviewPerson } from "../../../../common/types/token";
import { WebhookPreviewRequest } from "../../../../common/types/webhook";
import { WEBHOOK_REQUEST_METHODS } from "../../../../common/constants/webhook";
import {
  PersonLookupInput,
  PERSON_ORG_EMAIL_INIT,
} from "../../../../components/PersonLookupInput";
import { useAppDispatch } from "../../../../store";
import {
  selectWebhook,
  lookupPersonForWebhooks,
  previewWebhook,
} from "../webhookSlice";
import { isLoading } from "../../../../common/helper/commonHelper";

export function WebhookPersonLookup() {
  const dispatch = useAppDispatch();
  const [personOrgEmail, setPersonOrgEmail] = useState(PERSON_ORG_EMAIL_INIT);
  const {
    personLookup,
    webhookDetails: { data: webhookDetails, loading: fetchingWebhookDetails },
    webhookPreview,
    isWebhookDirty,
  } = useSelector(selectWebhook);

  function showPreview() {
    if (!webhookDetails.url || isWebhookDirty) {
      toast.warning("Webhook should be updated and saved before preview");
      return;
    }

    let personData: PreviewPerson[] | undefined =
      personLookup.data[personOrgEmail.personEmail];

    let webhookData: WebhookPreviewRequest = {
      url: webhookDetails.url ?? "",
      method: webhookDetails.method ?? WEBHOOK_REQUEST_METHODS.POST,
      headers: webhookDetails.headers ?? {},
      person_ids: [{ id: personLookup.data[personOrgEmail.personEmail][0].id }],
      campaign_context: CAMPAIGN_CONTEXT.PERSON,
      payload: webhookDetails.payload ?? null,
      timeout: webhookDetails.timeout ?? 0,
    };

    if (personOrgEmail.orgUserCheck && personOrgEmail.selectedOrg) {
      const [id, org] = personOrgEmail.selectedOrg;
      const data = personData.find(
        (person) => person.org_id === org && person.product_user_id === id
      );
      if (data) {
        webhookData = {
          ...webhookData,
          person_ids: [
            {
              id: data.id,
              org_id: data.org_id,
              product_user_id: data.product_user_id,
            },
          ],
          campaign_context: CAMPAIGN_CONTEXT.ORG,
        };
      }
    }

    dispatch(previewWebhook(webhookData));
  }

  return (
    <PersonLookupInput
      personLookup={personLookup}
      lookUpPersonThunk={lookupPersonForWebhooks}
      fetchingPreview={webhookPreview.loading}
      personOrgEmail={personOrgEmail}
      setPersonOrgEmail={setPersonOrgEmail}
      onPreview={showPreview}
      previewButtonText="send request"
      stackProps={{
        justifyContent: "flex-start",
        pt: "0",
      }}
      isButtonDisabled={false}
      isFetchingDetails={isLoading(fetchingWebhookDetails)}
    />
  );
}
