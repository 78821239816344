import { Box, BoxProps, HStack, Icon, IconProps } from "@chakra-ui/react";
import { ReactNode } from "react";
import { MdInfo } from "react-icons/md";

export default function InfoBanner({
  children,
  iconProps,
  customContent,
  ...props
}: {
  children: ReactNode;
  customContent?: ReactNode;
  iconProps?: IconProps;
} & BoxProps) {
  return (
    <Box w="85%" bg="grayV2.100" fontSize="12px" borderRadius={4} {...props}>
      <HStack align="start">
        <Icon
          children={<MdInfo size="sm" />}
          color="brandBlue.500"
          mt={1}
          {...iconProps}
        />
        {children}
      </HStack>
      {customContent}
    </Box>
  );
}
