import { FORM_VENDORS, FORM_STATUS } from "../../../common/types/form";
import formstackLogo from "../../../common/img/logo/formstack.png";
import webflowLogo from "../../../common/img/logo/webflow.png";

//all form providers details
export const FORM_PROVIDER_DETAILS: {
  [key in FORM_VENDORS]: {
    imgSrc: string;
    name: string;
  };
} = {
  [FORM_VENDORS.WEBFLOW]: {
    imgSrc: webflowLogo,
    name: "Webflow",
  },

  [FORM_VENDORS.FORMSTACK]: {
    imgSrc: formstackLogo,
    name: "Formstack",
  },
};

export const FORM_STATUS_DETAILS = {
  [FORM_STATUS.READY]: {
    text: "Ready",
    color: "green.500",
  },
  [FORM_STATUS.NEW_FIELDS_IDENTIFIED]: {
    text: "New fields found",
    color: "gray.500",
  },
  [FORM_STATUS.MAPPING_PENDING]: {
    text: "Mapping pending",
    color: "orange.500",
  },
  [FORM_STATUS.DISCONNECTED]: {
    text: "Disconnected",
    color: "red.500",
  },
};

export function isFormProvider(vendor: string | null | undefined) {
  return vendor
    ? Object.values(FORM_VENDORS).includes(vendor as FORM_VENDORS)
    : false;
}
