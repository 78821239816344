import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  useDisclosure,
  VStack,
  Text,
  Flex,
  DrawerFooter,
  Box,
  HStack,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import { debounce, isEmpty } from "lodash";
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  memo,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { FaEnvelope, FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import { isValidTokenOrColumnAccessor } from "../../../../../../common/helper/codemirrorHelper";
import {
  isLoading,
  openAssetInNewTab,
  validateEmail,
} from "../../../../../../common/helper/commonHelper";
import {
  GlobalEmailConfigType,
  SendInternalEmailActionOptions,
} from "../../../../../../common/types/campaign";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import MultiEmailInput from "../../../../../../components/MultiEmailInput";
import { selectSettings } from "../../../../settings/settingsSlice";
import {
  listAllTemplates,
  selectTemplate,
} from "../../../../templates/templateSlice";
import {
  listAllEmailTokens,
  selectEmailToken,
} from "../../../../emailtoken/emailTokenSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";
import { useAppDispatch } from "../../../../../../store";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { AssetPreviewComponent } from "../../../../../../components/OptionHelper";
import { ReactSelectDefaultOptionsWithClick } from "../../../../../../common/types/common";
import {
  ASSET_TYPES,
  EMPTY_CONTEXT,
  LOADING_STATES,
} from "../../../../../../common/constants/common";
import WidgetIconButton from "../WidgetIconButton";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";
import CommonDrawer from "../../../components/CommonDrawer";
import {
  SenderMeta,
  TemplateBase,
} from "../../../../../../common/types/template";
import WidgetRedirectIconButton from "../WidgetRedirectIconButton";

const optionDetails = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.SEND_INTERNAL_EMAIL];

export type TemplateListOptions = (ReactSelectDefaultOptionsWithClick &
  Partial<TemplateBase>)[];

function LabelAndValue({
  label,
  value,
}: {
  label: string;
  value: string | null | undefined;
}) {
  return (
    <HStack color="brandBlue.400" fontSize="14px">
      <Text>{label}: </Text>
      <Tooltip label={value} fontSize="xs">
        <Text
          maxW="300px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {value ?? EMPTY_CONTEXT}
        </Text>
      </Tooltip>
    </HStack>
  );
}

function EmailAlertWidgetBeforeEmailSelection({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onClick}
      emptyStateDetails={{
        icon: FaEnvelope,
        header: "Select an email template for the alert",
        subHeader: "Click here to set up the email and recipients",
      }}
    />
  );
}

function EmailAlertAfterEmailSelection({
  internalEmails,
  templateListOptions,
  templateId,
  onEdit,
  readonly,
}: {
  internalEmails: string[];
  templateListOptions: TemplateListOptions;
  templateId: string | null;
  onEdit: () => void;
  readonly?: boolean;
}) {
  const template = templateListOptions.find(
    (option) => templateId && option.value === templateId
  );

  return (
    <VStack alignItems="flex-start" w="100%" spacing={1}>
      <Flex justifyContent={"space-between"} w="100%">
        <HStack>
          <Text>{template?.label}</Text>
          <WidgetRedirectIconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              template?.onPreview?.(template?.value);
            }}
          />
        </HStack>
      </Flex>

      <Divider />

      <HStack
        w="100%"
        justifyContent="space-between"
        alignItems="flex-start"
        py="2"
      >
        <VStack alignItems="flex-start">
          <LabelAndValue label="Subject" value={template?.subject} />
          <LabelAndValue label="From" value={template?.from_email?.email} />
          <LabelAndValue label="To" value={internalEmails.join(", ")} />
        </VStack>

        <Box>
          <WidgetIconButton
            icon={FaPencilAlt}
            onClick={onEdit}
            hidden={readonly}
          />
        </Box>
      </HStack>
    </VStack>
  );
}

export function EmailAlertSliderContent({
  activeErrorCheck,
  templateListOptions,
  templateId,
  setTemplateId,
  readonly,
  fetchingTemplateList,
  errors,
  internalEmails,
  setInternalEmails,
  setValue,
}: {
  activeErrorCheck: boolean;
  templateListOptions: TemplateListOptions;
  templateId: string | null;
  setTemplateId: Dispatch<SetStateAction<string | null>>;
  readonly: boolean | undefined;
  fetchingTemplateList: LOADING_STATES;
  errors: { template_id: string; internal_emails: string };
  internalEmails: string[];
  setInternalEmails: Dispatch<SetStateAction<string[]>>;
  setValue: Dispatch<SetStateAction<string>>;
}) {
  return (
    <VStack width="100%" spacing="4">
      <FormControl isInvalid={activeErrorCheck && templateId === ""}>
        <FormLabel color={"gray.500"} fontSize={"xs"}>
          Email template
        </FormLabel>
        <DropdownWithSearch
          options={templateListOptions}
          value={templateListOptions.find(
            (option) => option.value === templateId
          )}
          onChange={(option) => setTemplateId(option?.value ?? null)}
          isInvalid={activeErrorCheck && !templateId}
          maxMenuHeight={300}
          isSearchable
          isDisabled={readonly}
          menuPortalTarget={null}
          isLoading={isLoading(fetchingTemplateList)}
          components={{
            Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
          }}
        />
        <FormErrorMessage>Select an email template</FormErrorMessage>
      </FormControl>

      {templateId && (
        <>
          <FormControl isInvalid={activeErrorCheck && !!errors.internal_emails}>
            <FormLabel color={"gray.500"} fontSize={"xs"}>
              Send to
            </FormLabel>
            <MultiEmailInput
              emailList={internalEmails}
              setEmailList={setInternalEmails}
              setValue={setValue}
              errorMsg={
                activeErrorCheck && errors.internal_emails
                  ? errors.internal_emails
                  : undefined
              }
              allowTokens={true}
              isDisabled={readonly}
            />
          </FormControl>
        </>
      )}
    </VStack>
  );
}

export function transformTemplatesList(
  templateList: (TemplateBase & SenderMeta)[] | null,
  globalDefault: GlobalEmailConfigType | null
): TemplateListOptions {
  return templateList
    ? templateList
        .filter(
          (template) =>
            !template.is_empty &&
            (globalDefault ||
              (template.subject && template.from_email && template.reply_to))
        )
        .map((template) => {
          return {
            label: template.name ?? "",
            value: template.template_id,
            onPreview: () =>
              openAssetInNewTab(ASSET_TYPES.TEMPLATE, template.template_id),
            ...template,
          };
        })
    : [];
}

function EmailAlertWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as SendInternalEmailActionOptions,
    [action]
  );
  const {
    globalDefault: { data: globalDefault },
  } = useSelector(selectSettings);
  const {
    fullTemplateList: { data: templateList, loading: fetchingTemplateList },
  } = useSelector(selectTemplate);

  const {
    allColumnsList: { data: columnsList },
    emailTokenList: {
      listAll: { data: tokensList },
    },
  } = useSelector(selectEmailToken);

  const { flowValidity } = useSelector(selectFlow);

  const dispatch = useAppDispatch();
  const { activeErrorCheck } = useContext(CampaignBuilderContext);

  const [templateId, setTemplateId] = useState<string | null>(
    actionOptions.template_id
  );
  const [internalEmails, setInternalEmails] = useState<string[]>(
    actionOptions.internal_emails ?? []
  );
  const [value, setValue] = useState("");
  const [errors, setErrors] = useState({
    template_id: "",
    internal_emails: "",
  });

  const {
    isOpen: isOpenEditDrawer,
    onOpen: onOpenEditDrawer,
    onClose: onCloseEditDrawer,
  } = useDisclosure();

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  useEffect(() => {
    const validity = !!(
      actionOptions.template_id && actionOptions.internal_emails.length
    );
    setValidityCallback(validity);
  }, [actionOptions, setValidityCallback]);

  useEffect(() => {
    dispatch(listAllTemplates());
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  function setOptions(options: SendInternalEmailActionOptions) {
    setActions(options, identities.actionId, groupId);
    debouncedSave(options, { actionId: identities.actionId, groupId });
  }

  function handleSelectChange(id: string | null) {
    setOptions({ ...actionOptions, template_id: id });
    if (!id) {
      setErrors({ ...errors, template_id: "Select an email asset" });
    } else {
      setErrors({ ...errors, template_id: "" });
    }
    setValidityCallback(!!id);
  }

  const handleEmailChangeInternal = useCallback(
    (
      emails: string[],
      updateAction: (
        options: SendInternalEmailActionOptions,
        validity: boolean
      ) => void
    ) => {
      if (!isEmpty(emails)) {
        updateAction({ ...actionOptions, internal_emails: [...emails] }, true);
        setErrors({ ...errors, internal_emails: "" });
        setValidityCallback(true);
      } else {
        updateAction({ ...actionOptions, internal_emails: [] }, false);
        setErrors({ ...errors, internal_emails: "Email should not be empty" });
        setValidityCallback(false);
      }
    },
    [actionOptions, errors, setValidityCallback]
  );

  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        internal_emails: isEmpty(internalEmails)
          ? "Email should not be empty"
          : "",
      };
    });
  }, [internalEmails]);

  useEffect(() => {
    handleEmailChangeInternal(internalEmails, (value) =>
      setActions(value, identities.actionId, identities.groupId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTemplateId(actionOptions.template_id);
  }, [actionOptions.template_id]);

  useEffect(() => {
    setInternalEmails(actionOptions.internal_emails);
  }, [actionOptions.internal_emails]);

  const templateListOptions: TemplateListOptions = useMemo(() => {
    return transformTemplatesList(templateList, globalDefault);
  }, [globalDefault, templateList]);

  function onTemplateSave() {
    handleSelectChange(templateId);
  }

  function onEmailsSave() {
    let emails =
      value &&
      (isValidTokenOrColumnAccessor(value, tokensList, columnsList)
        .isValidEmailVariable ||
        validateEmail(value))
        ? [...internalEmails, value]
        : internalEmails;

    handleEmailChangeInternal(emails, (value) =>
      setActions(value, identities.actionId, identities.groupId)
    );
    setOptions({ ...actionOptions, internal_emails: emails });
  }

  function onSave() {
    onTemplateSave();
    onEmailsSave();
    onCloseEditDrawer();
  }

  function addEmailAlertDrawer() {
    return (
      <CommonDrawer
        isOpen={isOpenEditDrawer}
        onClose={onSave}
        size="md"
        placement="right"
        title={optionDetails.label}
        footer={
          <DrawerFooter p={0}>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={onSave}
              children="Close"
            />
          </DrawerFooter>
        }
        drawerContentProps={{ transform: "none !important" }} //codemirror options won't be displayed if this is not set to none
      >
        <EmailAlertSliderContent
          activeErrorCheck={activeErrorCheck}
          templateListOptions={templateListOptions}
          templateId={templateId}
          setTemplateId={setTemplateId}
          readonly={readonly}
          fetchingTemplateList={fetchingTemplateList}
          errors={errors}
          internalEmails={internalEmails}
          setInternalEmails={setInternalEmails}
          setValue={setValue}
        />
      </CommonDrawer>
    );
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Fields are invalid"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Box w="100%" p="5">
        {templateId ? (
          <EmailAlertAfterEmailSelection
            internalEmails={internalEmails}
            templateListOptions={templateListOptions}
            templateId={templateId}
            onEdit={onOpenEditDrawer}
            readonly={readonly}
          />
        ) : (
          <EmailAlertWidgetBeforeEmailSelection onClick={onOpenEditDrawer} />
        )}
      </Box>
      {addEmailAlertDrawer()}
    </WidgetContainer>
  );
}

export default memo(EmailAlertWidget, isActionDataSame);
