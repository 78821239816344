import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Spacer,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toggleSegmentEventApi } from "../../../../../../../common/api/integrations/connection";
import {
  getEventTypeAndName,
  isFulfilled,
  isLoading,
  isTrackEvent,
} from "../../../../../../../common/helper/commonHelper";
import { SegmentEventType } from "../../../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../../../store";
import {
  selectConnection,
  toggleSegmentEvent,
  updateSegmentDescription,
} from "../../../../connectionSlice";
import AccordionDatePanel from "./AccordionPanel/AccordionDatePanel";
import AccordionPropertyPanel from "./AccordionPanel/AccordionPropertyPanel";
import DescriptionEdit from "./DescriptionEdit";

export default function SegmentEventAccordion({
  segmentEventName,
  segmentEventDetails,
  isTrackEnabled,
  isByPassAll,
}: {
  segmentEventName: string;
  segmentEventDetails: SegmentEventType;
  isTrackEnabled?: boolean;
  isByPassAll: boolean;
}) {
  const dispatch = useAppDispatch();
  const [isEventEnabled, setIsEventEnabled] = useState(
    segmentEventDetails?.enabled
  );
  const [description, setDescription] = useState(
    segmentEventDetails?.description ?? ""
  );

  const {
    productDiscovery: { updatingDescription, togglingEvent },
  } = useSelector(selectConnection);

  const segmentPropertyKeyList: string[] = useMemo(
    () =>
      segmentEventDetails?.properties
        ? Object.keys(segmentEventDetails?.properties)
        : [],
    [segmentEventDetails?.properties]
  );

  useEffect(() => {
    segmentEventDetails && setIsEventEnabled(segmentEventDetails.enabled);
  }, [segmentEventDetails, segmentEventDetails?.enabled]);

  useEffect(() => {
    segmentEventDetails && setDescription(segmentEventDetails.description);
  }, [segmentEventDetails, segmentEventDetails?.description]);

  async function handleEventEnableChange() {
    const eventData = {
      ...getEventTypeAndName(segmentEventName),
      status: !isEventEnabled,
    };
    const result = await dispatch(toggleSegmentEvent(eventData));
    if (isFulfilled(result.meta.requestStatus)) {
      const toggleSegmentEventStatus = result.payload as Awaited<
        ReturnType<typeof toggleSegmentEventApi>
      >;
      if (toggleSegmentEventStatus.status) {
        setIsEventEnabled(!isEventEnabled);
      }
    }
  }

  function handleDescriptionChange(value: string) {
    const descriptionData = {
      ...getEventTypeAndName(segmentEventName),
      description: value,
    };
    setDescription(value);
    dispatch(updateSegmentDescription(descriptionData));
  }

  return (
    <AccordionItem>
      <AccordionButton w="100%">
        <AccordionIcon />
        <HStack width="100%" alignItems="baseline">
          <Text fontSize="14px">{segmentEventName}</Text>
          <DescriptionEdit
            value={description}
            onSave={handleDescriptionChange}
            isUpdating={isLoading(updatingDescription)}
          />
          <Spacer />
          {!isTrackEvent(segmentEventName) && (
            <HStack>
              <Box bg="blackAlpha.50" py={1} px={4}>
                <Text fontSize="12px" color="gray.500">
                  {segmentPropertyKeyList.length}{" "}
                  {segmentPropertyKeyList.length === 1
                    ? "Property"
                    : "Properties"}
                </Text>
              </Box>
              {!isByPassAll && (
                <Switch
                  isChecked={isEventEnabled}
                  colorScheme="teal"
                  onChange={handleEventEnableChange}
                  isDisabled={
                    (!isTrackEvent(segmentEventName) && !isTrackEnabled) ||
                    isLoading(togglingEvent)
                  }
                />
              )}
            </HStack>
          )}
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        <VStack>
          <AccordionDatePanel
            segmentEvent={segmentEventDetails}
            segmentEventPropertyKeys={segmentPropertyKeyList}
          />
          <AccordionPropertyPanel
            propertyKeys={segmentPropertyKeyList}
            propertyDetails={segmentEventDetails?.properties}
            eventName={segmentEventName}
            isByPassAll={isByPassAll}
            isEventEnabled={isEventEnabled}
          />
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}
