import { IconButton } from "@chakra-ui/react";
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function WidgetRedirectIconButton({
  onClick,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <IconButton
      aria-label="preview"
      variant="ghost"
      color="brand.blue"
      icon={<FaExternalLinkAlt />}
      onClick={(e) => onClick(e)}
    />
  );
}
