import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import urls from "../../urls";
import {
  forgotPasswordEmail,
  selectAccount,
  resetForgotPasswordEmailSuccess,
} from "./accountSlice";
import EmailOnlyPage from "./components/EmailOnlyPage";
import MessageDisplayPage from "../../components/MessageDisplayPage";

export default function ForgotEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forgotPassword } = useSelector(selectAccount);

  function sendEmail(data: { email: string }) {
    dispatch(forgotPasswordEmail(data.email));
  }

  useEffect(() => {
    return () => {
      dispatch(resetForgotPasswordEmailSuccess());
    };
  }, [dispatch]);

  return (
    <>
      {forgotPassword.emailSent ? (
        <MessageDisplayPage
          heading="Password Reset Email Sent"
          displayMessage="An email with a link to reset your password has just been sent to your email"
          redirectFn={() => navigate(urls.login, { replace: true })}
          redirectMsg="Go to Login page"
          type="success"
        />
      ) : (
        <EmailOnlyPage
          heading="Forgot Password"
          submitFn={sendEmail}
          loading={forgotPassword.emailSending}
          buttonText="Send Reset Link"
          hyperlinks={[
            {
              onClick: () => navigate(urls.login),
              label: "Go to Login",
            },
          ]}
        />
      )}
    </>
  );
}
