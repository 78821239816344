import { Box, HStack, Icon, Text, Image } from "@chakra-ui/react";
import { isString } from "lodash";
import { IconType } from "react-icons";

export function WrapperForEmptyFlowState({
  setUpFlowStep,
  emptyStateDetails,
}: {
  setUpFlowStep: () => void;
  emptyStateDetails: {
    icon: IconType | string;
    header: string;
    subHeader?: string;
  };
}) {
  return (
    <Box
      bg="grayV2.100"
      borderRadius={6}
      p={6}
      onClick={setUpFlowStep}
      style={{
        cursor: "pointer",
      }}
      w="100%"
    >
      <HStack spacing={4}>
        {isString(emptyStateDetails.icon) ? (
          <Image h="20px" src={emptyStateDetails.icon} />
        ) : (
          <Icon as={emptyStateDetails.icon} fontSize={50} color="grayV2.300" />
        )}
        <Box fontSize="sm" color="grayV2.500">
          <Text fontWeight={600}>{emptyStateDetails.header}</Text>
          <Text>{emptyStateDetails.subHeader ?? ""}</Text>
        </Box>
      </HStack>
    </Box>
  );
}
