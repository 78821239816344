import { HStack, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

const COMMON_PROPERTIES_FOR_RADIO_ITEM = {
  p: 3,
  pb: 4,
  w: "100%",
  h: "100%",
  boxShadow: "sm",
  borderRadius: "4px",
  alignItems: "flex-start",
};

function RadioItemCheckedState({
  isError,
  children,
}: {
  isError: boolean;
  children: ReactNode;
}) {
  return (
    <HStack
      {...COMMON_PROPERTIES_FOR_RADIO_ITEM}
      bg={isError ? "brandRed.50" : "brandBlue.100"}
    >
      <Icon as={FaCheckCircle} color="brandBlue.450" fontSize="16px" mt={1} />
      {children}
    </HStack>
  );
}

function RadioItemUnCheckedState({
  onRadioItemSelect,
  children,
}: {
  onRadioItemSelect: () => void;
  children: ReactNode;
}) {
  return (
    <HStack
      {...COMMON_PROPERTIES_FOR_RADIO_ITEM}
      _hover={{
        bg: "gray.50",
        cursor: "pointer",
        transform: "scale(1.01)",
      }}
      border="1px solid"
      borderColor="grayV2.100"
      onClick={onRadioItemSelect}
    >
      <Icon as={FaRegCircle} fontSize="16px" mt={1} />
      {children}
    </HStack>
  );
}

export default function RadioItemLayoutBox({
  isSelected,
  onRadioItemSelect,
  isError,
  children,
}: {
  isSelected: boolean;
  onRadioItemSelect: () => void;
  isError: boolean;
  children: ReactNode;
}) {
  return isSelected ? (
    <RadioItemCheckedState isError={isError} children={children} />
  ) : (
    <RadioItemUnCheckedState
      onRadioItemSelect={onRadioItemSelect}
      children={children}
    />
  );
}
