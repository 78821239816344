import { Box, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select, {
  GroupBase,
  Options,
  OptionsOrGroups,
  SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { isFulfilled, isInit } from "../../../common/helper/commonHelper";
import { ReactSelectDefaultOptionsType } from "../../../common/types/common";
import { TagDetails } from "../../../common/types/tag";
import { useAppDispatch } from "../../../store";
import { createTag, listTagSummary, selectTag } from "./tagSlice";
import { useChakraColors } from "../../../common/hooks/commonHooks";

export default function SearchTagInput({
  onSelectTag,
  isCreatable = false,
  autoFocus = false,
  hideTags = [],
}: {
  onSelectTag: (id: string) => void;
  isCreatable?: boolean;
  autoFocus?: boolean;
  hideTags?: string[];
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { tagSummaryList } = useSelector(selectTag);
  const options: ReactSelectDefaultOptionsType[] = useMemo(
    () =>
      Object.entries(tagSummaryList.data).map(([id, name]) => {
        return { label: `#${name}`, value: id };
      }),
    [tagSummaryList.data]
  );

  const dispatch = useAppDispatch();
  const { gray100, gray200, gray300, gray400, gray800 } = useChakraColors();

  useEffect(() => {
    if (isInit(tagSummaryList.loading)) dispatch(listTagSummary());
  }, [tagSummaryList.loading, dispatch]);

  const handleCreate = async (inputValue: string) => {
    setIsLoading(true);
    const response = await dispatch(createTag(inputValue));
    setIsLoading(false);
    if (isFulfilled(response.meta.requestStatus)) {
      const newTag = (response.payload as { tag: TagDetails }).tag;
      onSelectTag(newTag.tag_id);
    }
  };

  function onSelectionchange(
    value: SingleValue<ReactSelectDefaultOptionsType>
  ) {
    if (value) {
      onSelectTag(value.value);
    }
  }

  const dropdownParam = {
    components: { DropdownIndicator: null },
    isDisabled: isLoading,
    isLoading: isLoading,
    onChange: (newValue: SingleValue<ReactSelectDefaultOptionsType>) =>
      onSelectionchange(newValue),
    inputValue: searchValue,
    onInputChange: (newValue: string) => {
      const regexForTag = new RegExp(`^[a-zA-Z0-9_.-]*$`);
      if (regexForTag.test(newValue) && newValue.length < 50)
        setSearchValue(newValue);
    },
    options: options.filter((option) => !hideTags.includes(option.value)),
    value: null,
    styles: {
      control: (base: any, state: any) => ({
        ...base,
        borderColor: gray200,
        fontSize: "13px",
        cursor: "text",
        opacity: 1,
        backgroundColor: "white",
        borderRadius: "6px",
        fontColor: gray800,
      }),
      option: (base: any, state: any) => ({
        ...base,
        fontSize: "13px",
        color: state.isDisabled ? gray400 : gray800,
        backgroundColor: state.isSelected ? gray300 : "white",
        cursor: "pointer",
        "&:hover": {
          backgroundColor:
            state.isSelected || state.isDisabled ? undefined : gray100,
        },
      }),
      menu: (base: any) => ({
        ...base,
        minWidth: "max-content",
      }),
      menuList: (base: any) => ({
        ...base,
        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          background: "white",
        },
        "::-webkit-scrollbar-thumb": {
          background: gray200,
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: gray300,
        },
      }),
    },
    noOptionsMessage: ({ inputValue }: { inputValue: string }) => (
      <Box color="text.50" fontSize="12px">
        {inputValue ? (
          <Text>No tags with {inputValue}</Text>
        ) : (
          <Text>No tags</Text>
        )}
      </Box>
    ),
    autoFocus: autoFocus,
  };

  function isValidNewOption(
    inputValue: string,
    selectValue: Options<ReactSelectDefaultOptionsType | null>,
    selectOptions: OptionsOrGroups<
      ReactSelectDefaultOptionsType | null,
      GroupBase<ReactSelectDefaultOptionsType | null>
    >
  ) {
    return (
      !!inputValue &&
      !selectOptions.some(
        (option) =>
          (option as ReactSelectDefaultOptionsType).label.substring(1) ===
          inputValue
        //substring to avoid # from the start
      )
    );
  }

  if (isCreatable)
    return (
      <CreatableSelect
        onCreateOption={handleCreate}
        formatCreateLabel={(value) => `Create #${value}`}
        placeholder="Search or create tags"
        isValidNewOption={isValidNewOption}
        {...dropdownParam}
      />
    );
  else return <Select placeholder="Search tags" {...dropdownParam} />;
}
