import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import {
  AddFlow,
  ActionOptions,
  SalesforceCampaignBasic,
  SalesforceCampaignStatus,
  SalesforceCampaign,
  CampaignDetailsForBuilder,
  CAMPAIGN_CONTEXT,
  FlowGroupActionOptions,
  CountData,
  JourneyMemberReportResp,
  FlowStepSummary,
  CanvasDateFilter,
  NodeDetails,
  FlowStepMember,
  WebhookFlowStepReportMemberDetails,
} from "../../../../common/types/campaign";
import { RootState } from "../../../../store";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../../common/constants/common";
import { initializeLoadingData } from "../../../../common/helper/commonHelper";
import {
  LoadingWithData,
  PaginationV2,
  PaginationType,
} from "../../../../common/types/common";
import {
  getCampaignApi,
  addFlowActionApi,
  removeFlowActionApi,
  updateFlowActionApi,
  addFlowEdgeApi,
  removeFlowEdgeApi,
} from "../../../../common/api/campaign/campaign";
import { getMappingDestinationApi } from "../../../../common/api/integrations/connection";
import {
  MAPPING_FIELD,
  PersonDestination,
} from "../../../../common/types/person";
import {
  getSalesforceCampaignsApi,
  getSalesforceCampaignDetailsApi,
  getSalesforceCampaignStatusApi,
} from "../../../../common/api/salesforceIntegrations/campaign";
import {
  CanvasReportBaseRequestParams,
  FlowGraph,
  FlowMemberReportReq,
  FlowNodeAction,
  JourneyMemberReportReq,
} from "../../../../common/types/flow";
import { CAMPAIGN_STATUS } from "../../../../common/constants/campaign";
import { createContext } from "react";
import {
  filterDeleteFlowSteps,
  initialiseValidationForActions,
} from "./components/helpers";
import { JOURNEY_TYPES } from "../../../../common/constants/trigger";
import { setCampaignStatusToDraft } from "../campaignSlice";
import { setTriggerCampaignStatusToDraft } from "../trigger-campaign/triggerCampaignSlice";
import { cloneDeep } from "lodash";
import {
  getFlowEdgeCountApi,
  getFlowStepReportApi,
  getFlowStepsMembersListApi,
  getFlowStepsSummaryReportApi,
  getJourneyMemberReportApi,
} from "../../../../common/api/campaign/canvasReport";
import { formatISO } from "date-fns";

export const CampaignBuilderContext = createContext<
  CampaignDetailsForBuilder & { activeErrorCheck: boolean }
>({
  campaignName: "",
  campaignId: "",
  campaignContext: CAMPAIGN_CONTEXT.PERSON,
  status: CAMPAIGN_STATUS.DRAFT,
  isActivatedOnce: false,
  updatedBy: "",
  isSavingDraft: false,
  isPublishingCampaign: false,
  campaignTags: [],
  updatedAt: 0,
  fetchingDetails: false,
  activeErrorCheck: false,
  campaignType: JOURNEY_TYPES.BATCH,
});

interface FlowState {
  isEditingFlow: boolean;
  flow: LoadingWithData<FlowGraph>;
  deletedFlowActions: FlowNodeAction[];
  draftFlow: FlowGraph;
  flowValidity: { [actionId: string]: boolean };
  salesforceCampaigns: {
    campaigns: LoadingWithData<SalesforceCampaignBasic[]>;
    campaignStatus: LoadingWithData<SalesforceCampaignStatus[]>;
    campaignDetails: LoadingWithData<{ [key: string]: SalesforceCampaign }>;
    personMappingColumns: LoadingWithData<PersonDestination[]>;
  };

  reports: {
    campaignId: string | null;
    filter: CanvasDateFilter;

    data: {
      journeyMembers: {
        filter: {
          showUniqueEntries: boolean;
        };
        data: JourneyMemberReportResp | null;
        loading: LOADING_STATES;
        pagination: PaginationV2;
      };

      nodeEdgeCount: LoadingWithData<CountData<{
        [nodeId: string]: NodeDetails;
      }> | null>;

      flowStepDetailReport: LoadingWithData<
        PaginationType<WebhookFlowStepReportMemberDetails>
      >;

      flowStepsSummaryReport: LoadingWithData<{
        [actionId: string]: FlowStepSummary;
      }>;

      flowMemberReport: LoadingWithData<PaginationType<FlowStepMember>>;
    };
    modals: {
      flowMemberReport: {
        selectedNode: {
          nodeId: string;
          branchId?: string;
        } | null;
        isOpen: boolean;
      };
      journeyMemberReport: {
        isOpen: boolean;
      };
    };
  };
}

function initializeDateFilter() {
  return {
    startDate: formatISO(getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_ONE_MONTH)),
    endDate: formatISO(new Date()),
  };
}

const initialState: FlowState = {
  isEditingFlow: false,
  flow: initializeLoadingData({ nodes: [], links: [] }),
  deletedFlowActions: [],
  draftFlow: { nodes: [], links: [] },
  flowValidity: {},
  salesforceCampaigns: {
    campaigns: initializeLoadingData([]),
    campaignStatus: initializeLoadingData([]),
    campaignDetails: initializeLoadingData({}),
    personMappingColumns: initializeLoadingData([]),
  },
  reports: {
    campaignId: null,
    filter: initializeDateFilter(),
    data: {
      journeyMembers: {
        filter: {
          showUniqueEntries: false,
        },
        data: null,
        loading: LOADING_STATES.INIT,
        pagination: {
          currentPage: 1,
          pageSize: 20,
          totalPages: 0,
          totalRecords: 0,
        },
      },

      nodeEdgeCount: initializeLoadingData(null),
      flowStepDetailReport: initializeLoadingData(INITIAL_PAGINATION),
      flowStepsSummaryReport: initializeLoadingData([]),
      flowMemberReport: initializeLoadingData(INITIAL_PAGINATION),
    },
    modals: {
      flowMemberReport: {
        selectedNode: null,
        isOpen: false,
      },
      journeyMemberReport: {
        isOpen: false,
      },
    },
  },
};

function setCampaignUpdateMeta({
  dispatch,
  updatedAt,
  updatedBy,
}: {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  updatedAt: string;
  updatedBy: string;
}) {
  dispatch(
    setCampaignStatusToDraft({
      updatedAt: updatedAt,
      updatedBy: updatedBy,
    })
  );
  dispatch(
    setTriggerCampaignStatusToDraft({
      updatedAt: updatedAt,
      updatedBy: updatedBy,
    })
  );
}

export const getCampaignFlow = createAsyncThunk(
  "campaign/get-flow",
  async (id: string) => {
    return await getCampaignApi(id);
  }
);

export const addFlowAction = createAsyncThunk(
  "campaign/add-flow-actions",
  async (flow: AddFlow, { dispatch }) => {
    const response = await addFlowActionApi(flow);
    setCampaignUpdateMeta({
      dispatch: dispatch,
      updatedAt: response.updated_at,
      updatedBy: String(response.updated_by.id),
    });
    return response;
  }
);

export const removeFlowAction = createAsyncThunk(
  "campaign/remove-flow-actions",
  async (
    {
      actionId,
      groupId,
      campaignId,
    }: {
      actionId: string;
      groupId?: string;
      campaignId: string;
    },
    { dispatch }
  ) => {
    const response = await removeFlowActionApi(campaignId, actionId, groupId);
    setCampaignUpdateMeta({
      dispatch: dispatch,
      updatedAt: response.updated_at,
      updatedBy: String(response.updated_by.id),
    });
    return response;
  }
);

export const updateFlowAction = createAsyncThunk(
  "campaign/update-flow-actions",
  async (
    {
      campaignId,
      actionId,
      actionOptions,
      groupId,
      shouldRender = false, // used for reducer
    }: {
      campaignId: string;
      actionId: string;
      actionOptions: ActionOptions;
      groupId?: string;
      shouldRender?: boolean;
    },
    { dispatch }
  ) => {
    const response = await updateFlowActionApi({
      campaign_id: campaignId,
      action_id: actionId,
      action_options: actionOptions,
      parent_action_id: groupId,
    });
    setCampaignUpdateMeta({
      dispatch: dispatch,
      updatedAt: response.updated_at,
      updatedBy: String(response.updated_by.id),
    });
    return response;
  }
);

export const addFlowEdge = createAsyncThunk(
  "campaign/flow/add-edge",
  async (
    {
      campaignId,
      source,
      target,
      groupId,
      conditionId,
      branchId,
    }: {
      campaignId: string;
      source: string;
      target: string;
      groupId?: string;
      conditionId?: string;
      branchId?: string;
    },
    { dispatch }
  ) => {
    const response = await addFlowEdgeApi({
      campaign_id: campaignId,
      source_action_id: source,
      target_action_id: target,
      parent_action_id: groupId,
      link_attributes: conditionId
        ? {
            edge_type: "group_edge",
            condition_id: conditionId,
          }
        : undefined,
    });
    setCampaignUpdateMeta({
      dispatch: dispatch,
      updatedAt: response.updated_at,
      updatedBy: String(response.updated_by.id),
    });
    return response;
  }
);

export const removeFlowEdge = createAsyncThunk(
  "campaign/flow/remove-edge",
  async (
    {
      campaignId,
      source,
      groupId,
      conditionId,
    }: {
      campaignId: string;
      source: string;
      groupId?: string;
      conditionId?: string;
    },
    { dispatch }
  ) => {
    const response = await removeFlowEdgeApi({
      campaign_id: campaignId,
      action_id: source,
      parent_action_id: groupId,
      condition_id: conditionId,
    });
    setCampaignUpdateMeta({
      dispatch: dispatch,
      updatedAt: response.updated_at,
      updatedBy: String(response.updated_by.id),
    });
    return response;
  }
);
export const getSalesforceCampaigns = createAsyncThunk(
  "campaign/get-salesforce-campaigns",
  async (forceRefresh: boolean = false) => {
    return await getSalesforceCampaignsApi(forceRefresh);
  }
);

export const getSalesforceCampaignDetails = createAsyncThunk(
  "campaign/get-salesforce-campaign-detail",
  async ({
    campaignId,
    forceRefresh = false,
  }: {
    forceRefresh: boolean;
    campaignId: string;
  }) => {
    return await getSalesforceCampaignDetailsApi(campaignId, forceRefresh);
  }
);

export const getSalesforceCampaignStatus = createAsyncThunk(
  "campaign/get-salesforce-campaign-status",
  async (forceRefresh: boolean = false) => {
    return await getSalesforceCampaignStatusApi(forceRefresh);
  }
);

export const getPersonMappingColumns = createAsyncThunk(
  "campaign/person-mapping",
  async () => {
    return await getMappingDestinationApi();
  }
);

export const getFlowStepsSummaryReport = createAsyncThunk(
  "campaign/flow/reports-summary",
  async (params: CanvasReportBaseRequestParams) => {
    return await getFlowStepsSummaryReportApi(params);
  }
);

export const getJourneyMemberReport = createAsyncThunk(
  "campaign/get-journey-member-report",
  async (params: JourneyMemberReportReq) => {
    return await getJourneyMemberReportApi(params);
  }
);

export const getFlowEdgeCount = createAsyncThunk(
  "campaign/flow/get-flow-edge-count",
  async (params: CanvasReportBaseRequestParams) => {
    return await getFlowEdgeCountApi(params);
  }
);

export const getFlowStepDetailedReport = createAsyncThunk(
  "campaign/flow/get-report",
  async (params: Omit<FlowMemberReportReq, "pageSize">, thunkApi) => {
    const { flow } = thunkApi.getState() as RootState;
    return await getFlowStepReportApi({
      ...params,
      pageSize: flow.reports.data.flowStepDetailReport.data.pageSize,
    });
  }
);

export const getFlowStepsMembersList = createAsyncThunk(
  "campaign/flow/get-flow-member-list",
  async (params: FlowMemberReportReq) => {
    return await getFlowStepsMembersListApi(params);
  }
);

const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    setFlow(state, action: PayloadAction<FlowGraph | null>) {
      const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
        action.payload
      );
      state.deletedFlowActions = deletedActions;
      state.flow.data = filteredFlow;
      state.draftFlow = filteredFlow;
      state.flowValidity = initialiseValidationForActions(filteredFlow);
    },
    setFlowValidity(
      state,
      action: PayloadAction<{ [actionId: string]: boolean }>
    ) {
      state.flowValidity = {
        ...state.flowValidity,
        ...action.payload,
      };
    },
    setDraftFlow(state, action: PayloadAction<FlowGraph>) {
      state.draftFlow = action.payload;
    },
    setDraftFlowActionOptions(
      state,
      {
        payload: { actionOptions, id, groupId },
      }: PayloadAction<{
        actionOptions: ActionOptions;
        id: string;
        groupId?: string;
      }>
    ) {
      let newActions = cloneDeep(state.draftFlow);
      if (groupId) {
        const parentIndex = newActions.nodes.findIndex(
          (action) => action.action_id === groupId
        );
        const groupNode = newActions.nodes[parentIndex]
          .action_options as FlowGroupActionOptions;
        const childIndex = groupNode.children!.nodes.findIndex(
          (action) => action.action_id === id
        );
        groupNode.children!.nodes[childIndex].action_options = actionOptions;
        newActions.nodes[parentIndex].action_options = groupNode;
      } else {
        const index = newActions.nodes.findIndex(
          (action) => action.action_id === id
        );
        if (index > -1) {
          newActions.nodes[index].action_options = actionOptions;
        }
      }
      state.draftFlow = newActions;
    },
    clearFlow(state) {
      state.reports = initialState.reports;
      state.flow = initialState.flow;
      state.salesforceCampaigns = initialState.salesforceCampaigns;
      state.isEditingFlow = initialState.isEditingFlow;
      state.draftFlow = initialState.draftFlow;
      state.flowValidity = {};
    },
    clearSalesforceCampaignData(state) {
      state.salesforceCampaigns.campaigns = initializeLoadingData([]);
      state.salesforceCampaigns.campaignStatus = initializeLoadingData([]);
    },
    setFilterDate(
      state,
      {
        payload: { startDate, endDate },
      }: PayloadAction<{
        startDate: string;
        endDate: string;
      }>
    ) {
      state.reports.filter.endDate = endDate;
      state.reports.filter.startDate = startDate;
    },
    resetFilterDate(state) {
      state.reports.filter = initializeDateFilter();
    },
    openFlowMemberReport(
      state,
      action: PayloadAction<{ actionId: string; branchId?: string }>
    ) {
      state.reports.modals.flowMemberReport.selectedNode = {
        nodeId: action.payload.actionId,
        branchId: action.payload.branchId,
      };
      state.reports.modals.flowMemberReport.isOpen = true;
    },
    closeFlowMemberReport(state) {
      state.reports.modals.flowMemberReport.selectedNode = null;
      state.reports.modals.flowMemberReport.isOpen = false;
    },
    setFlowReportsCampaignId(state, action: PayloadAction<string>) {
      state.reports.campaignId = action.payload;
    },
    openJourneyMemberReport(state) {
      state.reports.modals.journeyMemberReport.isOpen = true;
    },
    closeJourneyMemberReport(state) {
      state.reports.modals.journeyMemberReport.isOpen = false;
    },
    setJourneyMembersPagination(state, action: PayloadAction<number>) {
      state.reports.data.journeyMembers.pagination.currentPage = action.payload;
    },
    setFlowStepDetailMembersPagination(state, action: PayloadAction<number>) {
      state.reports.data.flowMemberReport.data.currentPageNo = action.payload;
      state.reports.data.flowMemberReport.data.changingPage = true;
    },
  },
  extraReducers: (builder) => {
    builder

      // Get campaign details
      .addCase(getCampaignFlow.pending, (state) => {
        state.flow.loading = LOADING_STATES.LOADING;
      })
      .addCase(getCampaignFlow.fulfilled, (state, action) => {
        state.flow.loading = LOADING_STATES.SUCCESS;
        const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
          action.payload.campaign.draft_data.flow ??
            action.payload.campaign.flow
        );
        state.deletedFlowActions = deletedActions;
        state.flow.data = filteredFlow;
        state.draftFlow = filteredFlow;
        state.flowValidity = initialiseValidationForActions(filteredFlow);
      })
      .addCase(getCampaignFlow.rejected, (state) => {
        state.flow.data = { ...initialState.flow.data };
        state.flow.loading = LOADING_STATES.FAILED;
      })

      // Create and update flow
      .addCase(addFlowAction.pending, (state) => {
        state.isEditingFlow = true;
      })
      .addCase(addFlowAction.fulfilled, (state, action) => {
        state.isEditingFlow = false;
        const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
          action.payload.flow
        );
        state.deletedFlowActions = deletedActions;
        state.flow.data = filteredFlow;
        state.draftFlow = filteredFlow;
        state.flowValidity = initialiseValidationForActions(filteredFlow);
      })
      .addCase(addFlowAction.rejected, (state) => {
        state.isEditingFlow = false;
      })
      // delete flow
      .addCase(removeFlowAction.pending, (state) => {
        state.isEditingFlow = true;
      })
      .addCase(removeFlowAction.fulfilled, (state, action) => {
        state.isEditingFlow = false;
        const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
          action.payload.flow
        );
        state.deletedFlowActions = deletedActions;
        state.flow.data = filteredFlow;
        state.draftFlow = filteredFlow;
        state.flowValidity = initialiseValidationForActions(filteredFlow);
      })
      .addCase(removeFlowAction.rejected, (state) => {
        state.isEditingFlow = false;
      })
      // update flow
      .addCase(updateFlowAction.pending, (state) => {
        state.isEditingFlow = true;
      })
      .addCase(updateFlowAction.fulfilled, (state, action) => {
        state.isEditingFlow = false;
        if (action.meta.arg.shouldRender) {
          const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
            action.payload.flow
          );
          state.deletedFlowActions = deletedActions;
          state.flow.data = filteredFlow;
          state.draftFlow = filteredFlow;
          state.flowValidity = initialiseValidationForActions(filteredFlow);
        }
      })
      .addCase(updateFlowAction.rejected, (state) => {
        state.isEditingFlow = false;
      })

      //Add flow edge
      .addCase(addFlowEdge.pending, (state) => {
        state.isEditingFlow = true;
      })
      .addCase(addFlowEdge.fulfilled, (state, action) => {
        state.isEditingFlow = false;
        const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
          action.payload.flow
        );
        state.deletedFlowActions = deletedActions;
        state.flow.data = filteredFlow;
        state.draftFlow = filteredFlow;
      })
      .addCase(addFlowEdge.rejected, (state) => {
        state.isEditingFlow = false;
      })

      //Remove flow edge
      .addCase(removeFlowEdge.pending, (state) => {
        state.isEditingFlow = true;
      })
      .addCase(removeFlowEdge.fulfilled, (state, action) => {
        state.isEditingFlow = false;
        const { filteredFlow, deletedActions } = filterDeleteFlowSteps(
          action.payload.flow
        );
        state.deletedFlowActions = deletedActions;
        state.flow.data = filteredFlow;
        state.draftFlow = filteredFlow;
      })
      .addCase(removeFlowEdge.rejected, (state) => {
        state.isEditingFlow = false;
      })

      // salesforce campaigns
      .addCase(getSalesforceCampaigns.pending, (state) => {
        state.salesforceCampaigns.campaigns.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceCampaigns.fulfilled, (state, { payload }) => {
        state.salesforceCampaigns.campaigns.loading = LOADING_STATES.SUCCESS;
        state.salesforceCampaigns.campaigns.data = payload.data;
      })
      .addCase(getSalesforceCampaigns.rejected, (state) => {
        state.salesforceCampaigns.campaigns.loading = LOADING_STATES.FAILED;
      })

      // salesforce campaign status
      .addCase(getSalesforceCampaignStatus.pending, (state) => {
        state.salesforceCampaigns.campaignStatus.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceCampaignStatus.fulfilled, (state, { payload }) => {
        state.salesforceCampaigns.campaignStatus.loading =
          LOADING_STATES.SUCCESS;
        state.salesforceCampaigns.campaignStatus.data = payload.data;
      })
      .addCase(getSalesforceCampaignStatus.rejected, (state) => {
        state.salesforceCampaigns.campaignStatus.loading =
          LOADING_STATES.FAILED;
      })

      // salesforce campaign details
      .addCase(getSalesforceCampaignDetails.pending, (state) => {
        state.salesforceCampaigns.campaignDetails.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceCampaignDetails.fulfilled, (state, action) => {
        state.salesforceCampaigns.campaignDetails.loading =
          LOADING_STATES.SUCCESS;
        state.salesforceCampaigns.campaignDetails.data = {
          ...state.salesforceCampaigns.campaignDetails.data,
          [action.meta.arg.campaignId]: action.payload.data,
        };
      })
      .addCase(getSalesforceCampaignDetails.rejected, (state) => {
        state.salesforceCampaigns.campaignDetails.loading =
          LOADING_STATES.FAILED;
      })

      // salesforce campaign details
      .addCase(getPersonMappingColumns.pending, (state) => {
        state.salesforceCampaigns.personMappingColumns.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getPersonMappingColumns.fulfilled, (state, action) => {
        state.salesforceCampaigns.personMappingColumns.loading =
          LOADING_STATES.SUCCESS;
        state.salesforceCampaigns.personMappingColumns.data =
          action.payload.destination.find(
            (x) => x.mapping_field === MAPPING_FIELD.PERSON_MAPPING
          )?.columns ?? [];
      })
      .addCase(getPersonMappingColumns.rejected, (state) => {
        state.salesforceCampaigns.personMappingColumns.loading =
          LOADING_STATES.FAILED;
      })

      // 1. Journey Member Report
      .addCase(getJourneyMemberReport.pending, (state) => {
        state.reports.data.journeyMembers.loading = LOADING_STATES.LOADING;
      })
      .addCase(getJourneyMemberReport.fulfilled, (state, action) => {
        state.reports.data.journeyMembers.loading = LOADING_STATES.SUCCESS;

        state.reports.data.journeyMembers.data = action.payload.data;
        state.reports.data.journeyMembers.pagination.currentPage =
          action.payload.pagination.current_page;
        state.reports.data.journeyMembers.pagination.totalPages =
          action.payload.pagination.total_pages;
        state.reports.data.journeyMembers.pagination.pageSize =
          action.payload.pagination.page_size;
        state.reports.data.journeyMembers.pagination.totalRecords =
          action.payload.pagination.total_records;
      })
      .addCase(getJourneyMemberReport.rejected, (state) => {
        state.reports.data.journeyMembers.loading = LOADING_STATES.FAILED;
      })

      // 2. Count of node edges
      .addCase(getFlowEdgeCount.pending, (state) => {
        state.reports.data.nodeEdgeCount.loading = LOADING_STATES.LOADING;
      })
      .addCase(getFlowEdgeCount.fulfilled, (state, action) => {
        state.reports.data.nodeEdgeCount.loading = LOADING_STATES.SUCCESS;

        const nodes = action.payload.data.nodes.reduce((acc, cur) => {
          acc[cur.action_id] = cur;
          return acc;
        }, {} as { [actionId: string]: NodeDetails });

        state.reports.data.nodeEdgeCount.data = {
          ...action.payload.data,
          nodes,
        };
      })
      .addCase(getFlowEdgeCount.rejected, (state) => {
        state.reports.data.nodeEdgeCount.loading = LOADING_STATES.FAILED;
      })

      //  3. Node Level Summary Reports
      .addCase(getFlowStepsSummaryReport.pending, (state) => {
        state.reports.data.flowStepsSummaryReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getFlowStepsSummaryReport.fulfilled, (state, action) => {
        state.reports.data.flowStepsSummaryReport.loading =
          LOADING_STATES.SUCCESS;
        state.reports.data.flowStepsSummaryReport.data =
          action.payload.data.nodes.reduce((acc, cur) => {
            acc[cur.action_id] = cur;
            return acc;
          }, {} as { [actionId: string]: FlowStepSummary });
      })
      .addCase(getFlowStepsSummaryReport.rejected, (state) => {
        state.reports.data.flowStepsSummaryReport.loading =
          LOADING_STATES.FAILED;
      })

      // 4. Flow step detailed report
      .addCase(getFlowStepDetailedReport.pending, (state) => {
        state.reports.data.flowStepDetailReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getFlowStepDetailedReport.fulfilled, (state, action) => {
        state.reports.data.flowStepDetailReport.loading =
          LOADING_STATES.SUCCESS;

        state.reports.data.flowStepDetailReport.data.list =
          action.payload.records;

        state.reports.data.flowStepDetailReport.data.changingPage = false;

        state.reports.data.flowStepDetailReport.data.count =
          action.payload.record_count;

        state.reports.data.flowStepDetailReport.data.totalPageCount =
          action.payload.page_count;
      })
      .addCase(getFlowStepDetailedReport.rejected, (state) => {
        state.reports.data.flowStepDetailReport.loading = LOADING_STATES.FAILED;
      })

      // 5. Flow Step Members Report
      .addCase(getFlowStepsMembersList.pending, (state) => {
        state.reports.data.flowMemberReport.loading = LOADING_STATES.LOADING;
      })
      .addCase(getFlowStepsMembersList.fulfilled, (state, action) => {
        state.reports.data.flowMemberReport.loading = LOADING_STATES.SUCCESS;

        state.reports.data.flowMemberReport.data.list = action.payload.records;
        state.reports.data.flowMemberReport.data.totalPageCount =
          action.payload.page_count;
        state.reports.data.flowMemberReport.data.count =
          action.payload.record_count;
      })
      .addCase(getFlowStepsMembersList.rejected, (state, action) => {
        state.reports.data.flowMemberReport.loading = LOADING_STATES.FAILED;
      });
  },
});

export const {
  setFlow,
  clearFlow,
  clearSalesforceCampaignData,
  setFlowValidity,
  setDraftFlow,
  setDraftFlowActionOptions,
  setFilterDate,
  resetFilterDate,
  openFlowMemberReport,
  closeFlowMemberReport,
  setFlowReportsCampaignId,
  openJourneyMemberReport,
  closeJourneyMemberReport,
  setJourneyMembersPagination,
  setFlowStepDetailMembersPagination,
} = flowSlice.actions;

export const selectFlow = (state: RootState) => state.flow;

export default flowSlice.reducer;
