import { Box, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TriggerExitCriteria from "../../trigger-exit-criteria/TriggerExitCriteria";
import CommonDrawer from "../../components/CommonDrawer";
import { useSelector } from "react-redux";
import { selectTrigger } from "../../trigger-campaign/triggerCampaignSlice";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { FaUserMinus } from "react-icons/fa";
import SectionWrapper from "./components/SectionWrapper";
import { NodeGroupWithComparison } from "../../../../../common/types/dynamicList";
import { SectionLink } from "./components/SectionWrapper";
import { NodeGroup } from "../../../../../common/types/dynamicList";
import { NEW_EXIT_GROUP } from "../../../../../common/constants/dynamicList";
import { CAMPAIGN_CONTEXT } from "../../../../../common/types/campaign";

export default function TriggerExitSection({
  data,
  errorText,
  readonly,
  onChange,
  campaignContext,
  activeErrorCheck,
  validateOnClose,
}: {
  data: NodeGroup[];
  errorText: string;
  readonly: boolean;
  onChange: (data: NodeGroup[]) => void;
  campaignContext?: CAMPAIGN_CONTEXT;
  activeErrorCheck: boolean;
  validateOnClose: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [localData, setLocalData] = useState<NodeGroup[]>([]);
  const onHandleClose = () => {
    if (activeErrorCheck) {
      validateOnClose();
    }
    onClose();
  };

  const {
    triggerCampaignDetails: { loading: isCampaignDetailsLoading },
  } = useSelector(selectTrigger);

  const onChangeNodeGroup = (data: NodeGroup) => {
    onChange([data]);
  };

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  return (
    <>
      <SectionWrapper
        icon={FaUserMinus}
        title="Exit criteria"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading(isCampaignDetailsLoading)}
        isEmpty={!(data?.length && data[0].nodes.length)}
      >
        {!(data?.length && data[0].nodes.length) ? (
          <SectionLink
            onOpen={onOpen}
            title="Click to set up exit criteria"
            LinkIcon={FaUserMinus}
            errorText={errorText}
          />
        ) : (
          <TriggerExitCriteria
            data={(localData?.[0] as NodeGroupWithComparison) ?? NEW_EXIT_GROUP}
            onChange={onChangeNodeGroup}
            isReadOnly={true}
            activeErrorCheck={activeErrorCheck}
            showCheckOptions
            onOpen={onOpen}
            errorText={errorText}
            campaignContext={campaignContext}
          />
        )}
      </SectionWrapper>
      <CommonDrawer
        size="xl"
        title="Exit criteria"
        isOpen={isOpen}
        onClose={onHandleClose}
      >
        <Box className={readonly ? DISABLED_EDIT_CLASSNAME : ""} w="100%">
          <TriggerExitCriteria
            data={(localData?.[0] as NodeGroupWithComparison) ?? NEW_EXIT_GROUP}
            onChange={onChangeNodeGroup}
            isReadOnly={false}
            activeErrorCheck={activeErrorCheck}
            showCheckOptions
            onOpen={onOpen}
            errorText={errorText}
            campaignContext={campaignContext}
          />
        </Box>
      </CommonDrawer>
    </>
  );
}
