import { Flex, Box, HStack, Center, Tooltip } from "@chakra-ui/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../../urls";
import { updateCampaign } from "./campaignSlice";
import CampaignSmartflow from "./flow/CampaignSmartflow";
import {
  CAMPAIGN_STATUS,
  DL_LIST_ALL_ACTION,
} from "../../../common/constants/campaign";
import { CAMPAIGN_CONTEXT } from "../../../common/types/campaign";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import EditableHeading from "../../../components/EditableHeading";
import CampaignSidebar from "./sidebar/CampaignSidebar";
import LinkedTags from "../tag/LinkedTags";
import { ASSET_TYPES } from "../../../common/constants/common";
import TopBar from "./components/TopBar";
import ISkeleton, { SKELETON_VARIANT } from "../../../components/ISkeleton";
import { useAppDispatch } from "../../../store";
import {
  resetReports,
  setReportsCampaignId,
} from "./campaignreports/campaignReportsSlice";
import { resetTemplateCollection } from "../templates/templateSlice";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import { TEMPLATE_Of_CAMPAIGN_ACTION } from "../../../components/dynamic-list/dynamicListSlice";
import {
  CampaignBuilderContext,
  clearFlow,
  clearSalesforceCampaignData,
  selectFlow,
  setFlowReportsCampaignId,
} from "./flow/flowSlice";
import { isFinished } from "../../../common/helper/commonHelper";
import { JOURNEY_TYPES } from "../../../common/constants/trigger";
import {
  CampaignMemberTypeIcon,
  JOURNEY_TYPE_DISPLAY_DETAILS,
} from "./components/CampaignListTableItems";
import { usePreviousLocation } from "../../../common/hooks/commonHooks";

function CampaignTitle({
  campaignName,
  campaignId,
  campaignContext,
  campaignTags,
  isFetchingDetails,
  updateName,
  updateTags,
  campaignType,
}: {
  campaignTags: string[];
  campaignName: string;
  campaignId: string;
  campaignContext: CAMPAIGN_CONTEXT;
  isFetchingDetails: boolean;
  updateName?: (name: string) => void;
  updateTags: (tags: string[]) => void;
  campaignType: JOURNEY_TYPES;
}) {
  const dispatch = useDispatch();
  const [isOpenRenameModal, setIsOpenRenameModal] = useState<string | null>(
    null
  );

  function renameCampaignHandler(updatedName: string) {
    setIsOpenRenameModal(null);
    if (updateName) {
      updateName(updatedName);
    } else {
      dispatch(
        updateCampaign({
          id: campaignId,
          name: updatedName,
        })
      );
    }
  }

  return (
    <Box w="100%">
      <Box w="100%">
        <HStack alignItems="center" w="100%">
          {!isFetchingDetails && (
            <CampaignMemberTypeIcon
              fontSize="16px"
              campaignContext={campaignContext}
            />
          )}
          <Tooltip label={JOURNEY_TYPE_DISPLAY_DETAILS[campaignType].display}>
            <Center>{JOURNEY_TYPE_DISPLAY_DETAILS[campaignType].icon}</Center>
          </Tooltip>
          <EditableHeading
            name={campaignName}
            openModal={() => setIsOpenRenameModal(campaignName)}
            isLoading={isFetchingDetails}
          />
        </HStack>
        <LinkedTags
          tags={campaignTags}
          assetId={campaignId}
          assetType={ASSET_TYPES.CAMPAIGN}
          onTagsChange={(ids: string[]) => updateTags(ids)}
        />
      </Box>
      <EditNameModal
        value={isOpenRenameModal ?? ""}
        action={ASSET_NAME_ACTIONS.RENAME}
        isOpen={isOpenRenameModal !== null}
        onClose={() => setIsOpenRenameModal(null)}
        onSubmit={renameCampaignHandler}
        asset="journey"
      />
    </Box>
  );
}

export default function CampaignBuilder({
  onPublish,
  updateName,
  updateTags,
  children,
}: {
  onPublish: (active: boolean) => void;
  children?: ReactNode;
  updateName?: (name: string) => void;
  updateTags: (tags: string[]) => void;
}) {
  const dispatch = useAppDispatch();
  const { handleGoBackWithParams } = usePreviousLocation();

  function goToCampaignList() {
    handleGoBackWithParams(urls.journey);
  }

  useEffect(() => {
    return () => {
      dispatch(resetReports());
      dispatch(resetTemplateCollection());
      dispatch(clearSalesforceCampaignData());
      dispatch(clearFlow());
      dispatch(
        expireApiStatus([
          { actionName: DL_LIST_ALL_ACTION },
          { actionName: TEMPLATE_Of_CAMPAIGN_ACTION, expireAll: true },
        ])
      );
    };
  }, [dispatch]);

  const { flow } = useSelector(selectFlow);

  const {
    status,
    isActivatedOnce,
    campaignName,
    campaignId,
    campaignContext,
    campaignTags,
    fetchingDetails,
    updatedBy,
    updatedAt,
    isSavingDraft,
    isPublishingCampaign,
    campaignType,
  } = useContext(CampaignBuilderContext);

  useEffect(() => {
    dispatch(setFlowReportsCampaignId(campaignId));
    dispatch(setReportsCampaignId(campaignId));
  }, [dispatch, campaignId]);

  const isReadOnly = status === CAMPAIGN_STATUS.ACTIVE;

  return (
    <Flex w="100%" h="100vh" position="relative">
      <TopBar
        status={status}
        updatedBy={updatedBy}
        savingDraft={isSavingDraft}
        updatedAt={updatedAt}
        isCampaignEditable={!isReadOnly}
        publishingCampaign={isPublishingCampaign}
        onPublish={onPublish}
        isReportsHidden={!isActivatedOnce}
        isFetchingDetails={fetchingDetails}
      >
        <CampaignSidebar
          backButton={{
            label: "Back to journeys list",
            handler: goToCampaignList,
          }}
          titleComponent={
            <CampaignTitle
              campaignName={campaignName}
              campaignId={campaignId}
              campaignContext={campaignContext}
              campaignTags={campaignTags}
              isFetchingDetails={fetchingDetails}
              updateName={updateName}
              updateTags={updateTags}
              campaignType={campaignType}
            />
          }
          campaignName={campaignName}
          isFetchingDetails={fetchingDetails}
        >
          {children}
        </CampaignSidebar>
      </TopBar>
      <ISkeleton
        variant={SKELETON_VARIANT.FLOW}
        isLoaded={!fetchingDetails && isFinished(flow.loading)}
      >
        <CampaignSmartflow readonly={isReadOnly} />
      </ISkeleton>
    </Flex>
  );
}
