import { Icon, VStack, Text, Box } from "@chakra-ui/react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus, FaUser } from "react-icons/fa";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import {
  FlowUpdateValueActionOptions,
  UpdateValueAction,
  UpdateValueOperation,
} from "../../../../../../common/types/campaign";
import IButton from "../../../../../../components/IButton";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import WidgetContainer from "../WidgetContainer";
import { cloneDeep, debounce, isEmpty } from "lodash";
import UpdateActionDrawer from "../UpdateActionDrawer";
import { useSelector } from "react-redux";
import {
  getPersonMappingDetails,
  selectPerson,
} from "../../../../persondb/personDbSlice";
import { DESTINATION_TYPES } from "../../../../../../common/types/unifiedMapping";
import { SET_VALUE } from "../constants";
import {
  getComputeOperators,
  selectDynamicList,
} from "../../../../../../components/dynamic-list/dynamicListSlice";
import RowWrapperWithButtons from "../RowWrapperWithButtons";
import WrapperWithScrollBar from "../WrapperWithScrollbar";
import {
  ActionNodeArgs,
  IconOptionsType,
} from "../../../../../../common/types/flow";
import { NodeProps } from "reactflow";
import { isActionDataSame } from "../helpers";
import { useAppDispatch } from "../../../../../../store";
import { listAllEmailTokens } from "../../../../emailtoken/emailTokenSlice";
import { INIT_UPDATE_VALUE } from "../../../../../../common/constants/campaign";
import { getUpdateValueFields } from "../../../helper/formatHelper";
import { MdEditNote } from "react-icons/md";
import { selectFlow, setFlowValidity } from "../../flowSlice";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";

function EmptyState({ onClick }: { onClick: () => void }) {
  return (
    <Box w="95%">
      <WrapperForEmptyFlowState
        setUpFlowStep={onClick}
        emptyStateDetails={{
          icon: MdEditNote,
          header: "Update the value of contact field",
          subHeader: "Click here to select the field",
        }}
      />
    </Box>
  );
}

function UpdateActionText({ operation }: { operation: UpdateValueOperation }) {
  const { operator, filter, token, value } = operation;
  const {
    personMappingDetails: { data: personMappingDetails },
  } = useSelector(selectPerson);
  const {
    computeOperators: { data: computeOperators },
  } = useSelector(selectDynamicList);

  function styledText(text: string) {
    return (
      <Text as="span" fontWeight="semibold">
        {text}
      </Text>
    );
  }

  function getColumnDetails(filter: string) {
    return personMappingDetails[filter];
  }

  function formatDestionationTypeData(type: DESTINATION_TYPES, value: any) {
    switch (type) {
      case DESTINATION_TYPES.BOOLEAN:
        return value ? "True" : "False";
      case DESTINATION_TYPES.STRING:
        return `"${value}"`;
      default:
        return value;
    }
  }

  function getOperatorDisplayText() {
    return Object.values(
      computeOperators ? computeOperators[DESTINATION_TYPES.INTEGER] : []
    )
      .find((action) => action.id === operator)
      ?.display_name.split(" ")[0];
  }

  return (
    <Text color="gray.600" fontSize="14px">
      {`${
        token || operator === SET_VALUE ? "Update" : getOperatorDisplayText()
      } Person's`}{" "}
      {styledText(`${filter ? getColumnDetails(filter)?.display : "......."}`)}
      {token && (
        <>
          {" with"} {styledText(token)}
        </>
      )}
      {operator === SET_VALUE && (
        <>
          {" with the value "}
          {styledText(
            formatDestionationTypeData(getColumnDetails(filter)?.type, value[0])
          )}
        </>
      )}
      {operator && operator !== SET_VALUE && (
        <>
          {" by "}
          {styledText(value[0])}
        </>
      )}
    </Text>
  );
}

function UpdateActionList({
  inputs,
  removeAction,
  editAction,
  actionId,
  readonly,
}: {
  inputs: UpdateValueOperation[];
  removeAction: (removeIndex: number) => void;
  editAction: (editIndex: number) => void;
  actionId: string;
  readonly?: boolean;
}) {
  return (
    <WrapperWithScrollBar maxHeight="170px" actionId={actionId}>
      {inputs?.map((operation, index) => {
        return (
          <>
            <RowWrapperWithButtons
              onEdit={() => editAction(index)}
              onRemove={() => removeAction(index)}
              key={index}
              mb={1}
              readonly={readonly}
            >
              <Icon as={FaUser} color="gray.500" />
              <UpdateActionText operation={operation} />
            </RowWrapperWithButtons>
          </>
        );
      })}
    </WrapperWithScrollBar>
  );
}

const { label, icon, color } =
  WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.UPDATE_VALUE];

function UpdateValueWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { flowValidity } = useSelector(selectFlow);

  const dispatch = useAppDispatch();
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowUpdateValueActionOptions,
    [action]
  );

  const [inputs, setInputs] = useState<UpdateValueOperation[]>(
    actionOptions.operations?.map((operation) => {
      return operation.operation;
    }) ?? []
  );

  const [index, setIndex] = useState(
    actionOptions.operations ? actionOptions.operations.length - 1 : 0
  );
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [actionOption, setActionOption] = useState(INIT_UPDATE_VALUE);
  const [updateFieldOptions, setUpdateFieldOptions] = useState<
    IconOptionsType[]
  >([]);

  const {
    personMappingDetails: { data: personMappingDetails },
  } = useSelector(selectPerson);

  useEffect(() => {
    dispatch(getPersonMappingDetails());
    dispatch(getComputeOperators());
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions() {
    //get the prev input state before saving
    setInputs((prev) => {
      let actionOption: UpdateValueAction[] = prev.map((option, index) => {
        return { sequence_number: index + 1, operation: { ...option } };
      });
      const option = { operations: [...actionOption] };
      setActions(option, identities.actionId, groupId);
      debouncedSave(option, { actionId: identities.actionId, groupId });

      return [...prev];
    });
  }

  const debouncedSave = useMemo(() => debounce(saveDraft, 2000), [saveDraft]);

  function setUpdateFieldIsDisbled(filter: string, isDisabled: boolean) {
    setUpdateFieldOptions((prev) => {
      let prevData = cloneDeep(prev);
      let index = prevData.findIndex((field) => field.value === filter);
      if (index >= 0) {
        prevData[index].isDisabled = isDisabled;
      }
      return [...prevData];
    });
  }

  function addUpdateAction() {
    setIsUpdateModalOpen(true);
    setActionOption(INIT_UPDATE_VALUE);
    setIndex(inputs?.length);
  }

  useEffect(() => {
    setActionOption({ ...inputs[index] });
    setValidityCallback(!!inputs.length);
  }, [index, inputs, setValidityCallback]);

  function removeUpdateAction(removeIndex: number) {
    setInputs((prev) => {
      let prevData = cloneDeep(prev);
      setUpdateFieldIsDisbled(prevData[removeIndex].filter, false);
      prevData.splice(removeIndex, 1);
      return [...prevData];
    });
    setOptions();
  }

  //filter from contacts
  useEffect(() => {
    const options = getUpdateValueFields(personMappingDetails);
    setUpdateFieldOptions(options);
  }, [personMappingDetails]);

  useEffect(() => {
    for (let i = 0; i < inputs.length; i++) {
      setUpdateFieldIsDisbled(inputs[i].filter, true);
    }
  }, [inputs]);

  function saveUpdateAction(operation: UpdateValueOperation) {
    setInputs((prev) => {
      let prevData = cloneDeep(prev);
      if (index === inputs?.length) {
        prevData.push({ ...operation });
      } else {
        if (actionOption.filter !== operation.filter) {
          setUpdateFieldIsDisbled(actionOption.filter, false);
        }
        prevData[index] = {
          ...prevData[index],
          ...operation,
        };
      }
      return [...prevData];
    });
    setOptions();
    setIsUpdateModalOpen(false);
  }

  function editUpdateAction(editIndex: number) {
    setIndex(editIndex);
    setIsUpdateModalOpen(true);
  }

  function NonEmptyState() {
    return (
      <>
        <UpdateActionList
          inputs={inputs}
          removeAction={removeUpdateAction}
          editAction={editUpdateAction}
          actionId={identities.actionId}
          readonly={readonly}
        />
        {!readonly && (
          <IButton
            size="sm"
            variant="link"
            leftIcon={<Icon as={FaPlus} fontSize="14px" color="brand.blue" />}
            alignSelf="flex-start"
            name="add-operation"
            onClick={addUpdateAction}
            color="brand.blue"
            pl={2}
          >
            Add update operation
          </IButton>
        )}
      </>
    );
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId]
          ? ""
          : "Updation operations are required"
      }
      identities={identities}
      title={label}
      icon={icon}
      color={color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <VStack width="100%" px={3} py={4} spacing={4}>
        {isEmpty(inputs) ? (
          <EmptyState onClick={addUpdateAction} />
        ) : (
          <NonEmptyState />
        )}
      </VStack>
      <UpdateActionDrawer
        isOpen={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        onSave={saveUpdateAction}
        actionOption={actionOption}
        updateFieldOptions={updateFieldOptions}
        readonly={readonly}
      />
    </WidgetContainer>
  );
}

export default memo(UpdateValueWidget, isActionDataSame);
