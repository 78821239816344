import { FlexProps, Flex, Center, Text, TextProps } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";

export function PaginationLabel({
  pageNo,
  totalNoOfRecords,
  pageSize,
  ...props
}: TextProps & {
  pageNo: number;
  totalNoOfRecords: number;
  pageSize: number;
}) {
  const startCountNumber = (pageNo - 1) * pageSize + 1;

  const endCountNumber = Math.min(totalNoOfRecords, pageNo * pageSize);

  return (
    <>
      {!!totalNoOfRecords && (
        <Text
          as="span"
          fontWeight="light"
          fontSize="12px"
          pl="12px"
          color="brand.blue"
          {...props}
        >
          {startCountNumber} - {endCountNumber} of {totalNoOfRecords} items
        </Text>
      )}
    </>
  );
}

export default function Pagination({
  onPageChange,
  pageCount,
  currentPage,
  ...sx
}: {
  onPageChange: (selectedPageNumber: number) => void;
  pageCount?: number | null;
  currentPage: number;
} & FlexProps) {
  function handlePageClick({ selected }: { selected: number }) {
    onPageChange(selected + 1);
  }

  return (
    <>
      {pageCount && pageCount > 1 ? (
        <Flex justifyContent="center" mt={4} {...sx}>
          <Center p="2" rounded="md">
            <ReactPaginate
              forcePage={currentPage - 1}
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount ?? 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              disabledClassName={"disabled"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </Center>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
}
