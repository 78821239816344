import { format, formatISO } from "date-fns";
import { cloneDeep, get, merge, pick } from "lodash";
import {
  CAMPAIGN_STATUS,
  RECURRING_FREQUENCIES,
} from "../../../../common/constants/campaign";
import {
  CampaignListItem,
  CampaignListStats,
  CampaignRuleBase,
  CampaignSchedule,
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL1,
  DynamicListChildL2,
  DynamicListType,
  Flow,
  FlowAction,
  RUN_TYPE,
} from "../../../../common/types/campaign";
import { PersonDestinationFields } from "../../../../common/types/person";
import { getUpdatablePersonFields } from "../../../../common/helper/commonHelper";
import { DESTINATION_TYPE_SPECIFIC_ICONS } from "../../../../common/constants/unifiedMapping";
import { isSalesforcePreference } from "../../../../common/helper/unifiedMappingHelper";
import { IconOptionsType } from "../../../../common/types/flow";
import {
  JourneyListRow,
  JourneyListStats,
} from "../../../../common/types/trigger";
import { LOADING_STATES } from "../../../../common/constants/common";
import { INIT_JOURNEY_ROW } from "../../../../common/constants/trigger";

export function formatFlowActions(actions: Flow[]): FlowAction[] {
  return actions.map((action, index) => {
    return {
      action_type: action.action_type,
      action_options: action.action_options,
      sequence_number: index,
    };
  });
}

export function formatRule(rule: CampaignRuleBase) {
  const expectedKeys: (keyof CampaignRuleBase)[] = [
    "rule",
    "recurring_period",
    "recurring_period_unit",
  ];
  let res = {};
  expectedKeys.forEach((fieldName) => {
    const value = rule[fieldName];
    if (value != null) {
      res = { [fieldName]: rule[fieldName], ...res };
    }
  });
  return res as CampaignRuleBase;
}

export function formatRunTime(runTime: string) {
  const time = new Date();
  let [hours, minutes] = runTime.split(":");
  time.setHours(+hours, +minutes, 0);
  return formatISO(time);
}

export function formatSchedule(formValue: CampaignSchedule) {
  const data = cloneDeep(formValue);

  if (data.run_config.start_date) {
    data.run_config.start_date = `${data.run_config.start_date}`;
  }

  if (data.run_config.end_date) {
    data.run_config.end_date = `${data.run_config.end_date}`;
  }

  if (data.run_config.freq_options && data.run_config.freq_options.date) {
    data.run_config.freq_options.date = `${data.run_config.freq_options.date}T00:00`;
  }

  let req: Partial<CampaignSchedule> = {};

  if (data.run_type === RUN_TYPE.AT_ONCE) {
    req = pick(data, ["run_type"]) as Partial<CampaignSchedule>;
  } else if (data.run_type === RUN_TYPE.ONCE) {
    req = pick(data, [
      "run_type",
      "run_config.start_date",
    ]) as Partial<CampaignSchedule>;
  } else if (data.run_type === RUN_TYPE.PERIOD) {
    req = pick(data, [
      "run_type",
      "run_config.start_date",
      "run_config.end_date",
      "run_config.frequency",
      "run_config.unit",
    ]) as Partial<CampaignSchedule>;
  } else if (data.run_type === RUN_TYPE.RECURRING) {
    const properties = [
      "run_type",
      "run_config.start_date",
      "run_config.frequency",
    ];

    if (data.run_config) {
      if (get(data, "run_config.end_date") !== undefined) {
        properties.push("run_config.end_date");
      }

      switch (get(data, "run_config.frequency")) {
        case RECURRING_FREQUENCIES.WEEK:
          properties.push(
            ...["run_config.freq_options.unit", "run_config.freq_options.day"]
          );
          break;
        case RECURRING_FREQUENCIES.MONTH:
          properties.push("run_config.freq_options.date_string");
          if (get(data, "run_config.freq_options.date_string") === "custom")
            properties.push("run_config.freq_options.day");
          break;
        case RECURRING_FREQUENCIES.YEAR:
          properties.push("run_config.freq_options.date");
          break;
        case RECURRING_FREQUENCIES.DAY:
          properties.push(...["run_config.freq_options.unit"]);
          break;
      }
    }

    req = pick(data, properties) as Partial<CampaignSchedule>;
  }
  return req;
}

type ChildDynamicListTypes =
  | DynamicListChild
  | DynamicListType
  | DynamicListChildL1;

function removeConnectorAtZeroIndex(
  data: Array<ChildDynamicListTypes | DynamicListChildL2>
): Array<ChildDynamicListTypes | DynamicListChildL2> | undefined {
  if (!data || !data.length) return;

  data[0].connector = CONNECTOR.EMPTY;

  data.forEach((child) => {
    return removeConnectorAtZeroIndex(
      (child as ChildDynamicListTypes).children!
    );
  });
}

// SC-6308
export function removeConnector(dynamicList: DynamicListType[]) {
  let dynamicListCopy = cloneDeep(dynamicList);
  removeConnectorAtZeroIndex(dynamicListCopy);
  return dynamicListCopy;
}

export const INIT_SCHEDULE: CampaignSchedule = {
  campaign_id: "",
  run_type: undefined,
  run_config: {
    start_date: "",
    end_date: undefined,
    frequency: "",
    freq_options: { day: "", date: "", date_string: "", unit: undefined },
    unit: undefined,
  },
};

/**
 * Function to format api data to form data
 * @param data
 * @param campaignId
 * @returns
 */
export const formatScheduleData = (
  data: CampaignSchedule | null,
  campaignId: string | null
): CampaignSchedule => {
  const defaultSchedule = { ...INIT_SCHEDULE, campaign_id: campaignId };
  let initValues = data
    ? cloneDeep(merge(cloneDeep(defaultSchedule), data))
    : defaultSchedule;

  initValues.campaign_id = campaignId;

  if (!initValues.run_config) {
    initValues.run_config = defaultSchedule.run_config;
  } else if (!initValues.run_config.freq_options) {
    initValues.run_config.freq_options =
      defaultSchedule.run_config.freq_options;
  }
  if (
    initValues.run_config.freq_options &&
    initValues.run_config.freq_options.date
  ) {
    initValues.run_config.freq_options.date = format(
      new Date(initValues.run_config.freq_options.date),
      "yyyy-MM-dd"
    );
  }
  return initValues;
};

export function getUpdateValueFields(
  personMapping: PersonDestinationFields
): IconOptionsType[] {
  return getUpdatablePersonFields(personMapping).map(([name, details]) => {
    const { display, type, sync_preference } = details;
    return {
      label: display,
      value: name,
      icon: DESTINATION_TYPE_SPECIFIC_ICONS[type],
      isDisabled: isSalesforcePreference(sync_preference),
      salesforcePreference: sync_preference,
    };
  });
}

export function transformCampaignToJourneyRow(
  data: CampaignListItem[]
): JourneyListRow[] {
  const journeyList: JourneyListRow[] = data.map((campaign) => {
    return {
      ...INIT_JOURNEY_ROW,
      campaignId: campaign.campaign_id,
      campaignContext: campaign.campaign_context,
      campaignName: campaign.name,
      nextSchedule: campaign.schedule?.next_run_time ?? "",
      updatedAt: new Date(campaign.updated_at),
      status: transformBatchStatusToJourneyStatus(
        campaign.active,
        campaign.draft
      ),
      tags: campaign.tags,
      countProcessed: null,
      countDelivered: null,
      openCount: null,
      totalCount: null,
      statsLoading: LOADING_STATES.INIT,
      campaignType: campaign.campaign_type,
    };
  });
  return journeyList;
}

export function transformStatsToJourneyRowStats(
  data: CampaignListStats[]
): JourneyListStats[] {
  return data.map((stats) => {
    return {
      campaignId: stats.campaign_id,
      countProcessed: stats.count_processed,
      countDelivered: stats.count_delivered,
      openCount: stats.open_count,
      totalCount: stats.total_count,
    };
  });
}

export function transformBatchStatusToJourneyStatus(
  isActive: boolean,
  isDraft: boolean
): CAMPAIGN_STATUS {
  if (isActive) {
    return CAMPAIGN_STATUS.ACTIVE;
  } else if (isDraft) {
    return CAMPAIGN_STATUS.DRAFT;
  } else {
    return CAMPAIGN_STATUS.INACTIVE;
  }
}
