import { Box, Divider, Heading } from "@chakra-ui/react";
import {
  convertToProductPersonType,
  sortDetails,
} from "../../../../common/helper/commonHelper";
import {
  PersonDestinationFields,
  ProductPersonType,
} from "../../../../common/types/person";
import DisplayGrid from "../../persondb/components/DisplayGrid";

export default function AccountInfo({
  details,
}: {
  details: PersonDestinationFields;
}) {
  function displayFields(isDefault: boolean) {
    const filteredEntries = Object.entries(details)
      .filter((item) => !item[1].hidden && item[1].custom === !isDefault)
      .sort(sortDetails);
    let newEntries: ProductPersonType[];
    if (filteredEntries.length) {
      newEntries = convertToProductPersonType(filteredEntries);
      return (
        <Box px={8} py={5}>
          <Heading fontSize="md" color="brand.blue">
            {isDefault ? "Default fields" : "Custom fields"}
          </Heading>
          <DisplayGrid entries={newEntries as ProductPersonType[]} />
        </Box>
      );
    }
  }

  return (
    <Box w="100%">
      {displayFields(true)}
      <Divider />
      {displayFields(false)}
    </Box>
  );
}
