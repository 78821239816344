import { Box, Text, HStack, VStack } from "@chakra-ui/react";
import { SingleValue } from "react-select";
import { ReactSelectDefaultOptionsType } from "../../../../../../../common/types/common";
import {
  SalesforceConnection,
  SALESFORCE_OBJECT_TYPE,
  SALESFORCE_RECORD_CREATE,
  SALESFORCE_RECORD_DELETE,
} from "../../../../../../../common/types/salesforceLegacyService";
import ComingSoonText from "../../../../../../../components/ComingSoonText";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";
import { useAppDispatch } from "../../../../../../../store";
import {
  updateSalesforceRecordCreate,
  updateSalesforceRecordDelete,
} from "../../../salesforceLegacySlice";
import { Header } from "./Header";
import { NoteBox } from "./NoteBox";

export default function InflectionToSalesforceLegacyRecord({
  data,
}: {
  data: SalesforceConnection;
}) {
  const dispatch = useAppDispatch();
  const connectionId = data.connection_id;

  const personCreationMenuItems = [
    {
      label: "Create a lead in Salesforce",
      value: SALESFORCE_RECORD_CREATE.LEAD,
    },
    {
      label: "Create a contact in Salesforce",
      value: SALESFORCE_RECORD_CREATE.CONTACT,
    },
    {
      label: "Do nothing",
      value: SALESFORCE_RECORD_CREATE.DO_NOTHING,
    },
  ];
  const personDeletionMenuItems = [
    {
      label: "Delete the associated lead/contact from Salesforce",
      value: SALESFORCE_RECORD_DELETE.DELETE_RECORD,
    },
    { label: "Do nothing", value: SALESFORCE_RECORD_DELETE.DO_NOTHING },
  ];

  const onPersonCreateChange = (
    val: SingleValue<ReactSelectDefaultOptionsType>
  ) => {
    const personCreateData = {
      connectionId,
      salesforceRecordCreateUpdate: val?.value as SALESFORCE_RECORD_CREATE,
    };

    dispatch(updateSalesforceRecordCreate(personCreateData));
  };

  const onPersonDeleteChange = (
    val: SingleValue<ReactSelectDefaultOptionsType>
  ) => {
    const personDeleteData = {
      connectionId,
      salesforceRecordDelete: val?.value as SALESFORCE_RECORD_DELETE,
    };

    dispatch(updateSalesforceRecordDelete(personDeleteData));
  };

  const sfCreateOptionDisabled = (
    option: SingleValue<ReactSelectDefaultOptionsType>
  ) => {
    switch (option?.value) {
      case SALESFORCE_OBJECT_TYPE.LEAD:
        return !data.object_settings?.lead;
      case SALESFORCE_OBJECT_TYPE.CONTACT:
        return !data.object_settings?.contact;
      default:
        return false;
    }
  };

  return (
    <Box mt="3">
      <Header
        title="Creating, updating and deleting Leads/Contacts in Salesforce"
        desc=""
      />
      <Box pt="3">
        <NoteBox
          text="The action of creation, deletion or do nothing will only take effect
            from the time this setting is updated."
          tooltipText="Example:- If setting for deletion was set to do nothing on day 1 and the setting is updated to delete on day 2,
           the action of deleting associated leads/contacts will only take place for people who are deleted in Inflection from day 2."
        />
      </Box>

      <VStack alignItems="flex-start" spacing="5" mt="2" pt="3">
        <HStack justifyContent="space-between" width="100%">
          <Text mr="1">When Inflection contact is created or updated</Text>
          <DropdownWithSearch
            options={personCreationMenuItems}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={personCreationMenuItems.find(
              (option) =>
                option.value === (data.salesforce_record_create ?? null)
            )}
            isOptionDisabled={(
              option: SingleValue<ReactSelectDefaultOptionsType>
            ) => sfCreateOptionDisabled(option)}
            onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
              onPersonCreateChange(val)
            }
            controlStyle={{
              width: "300px",
            }}
          />
        </HStack>
        <HStack justifyContent="space-between" width="100%" position="relative">
          <Text mr="1">When Inflection contact is deleted</Text>
          <DropdownWithSearch
            isDisabled={true}
            options={personDeletionMenuItems}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            value={personDeletionMenuItems.find(
              (option) =>
                option.value === (data.salesforce_record_delete ?? null)
            )}
            onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
              onPersonDeleteChange(val)
            }
            controlStyle={{
              width: "300px",
            }}
          />
          <Box position="absolute" right="-6">
            <ComingSoonText />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
}
