import {
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  StackProps,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { IconType } from "react-icons";
import {
  FaAddressBook,
  FaCheckCircle,
  FaRegCircle,
  FaUserFriends,
} from "react-icons/fa";
import { RiTimerFill } from "react-icons/ri";
import { CAMPAIGN_CONTEXT } from "../../../common/types/campaign";
import IButton, { BUTTON } from "../../../components/IButton";
import InputFieldWithError from "../../../components/InputFieldWithError";
import { PERSON_ORG_MAPPING } from "../../../common/types/person";
import { selectSettings } from "../settings/settingsSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  createTriggerCampaign,
  selectTrigger,
} from "./trigger-campaign/triggerCampaignSlice";
import { createCampaign, selectCampaign } from "./campaignSlice";
import {
  JOURNEY_TYPES,
  TARGET_FROM_CONTEXT,
} from "../../../common/constants/trigger";
import { selectAccount } from "../../account/accountSlice";
import { selectFeatureFlag } from "../../../common/slices/featureFlagSlice";
import { BsFillLightningChargeFill } from "react-icons/bs";
import {
  isLoading,
  onEnterKeySubmit,
} from "../../../common/helper/commonHelper";

type TypeDetails = {
  type: CAMPAIGN_CONTEXT | JOURNEY_TYPES;
  display: string;
  info: string;
  icon: IconType;
};

const CAMPAIGN_TYPE_DETAILS = [
  {
    type: CAMPAIGN_CONTEXT.PERSON,
    display: "Contact journey",
    info: "Create a journey that enrolls Contacts and will send a single email per Contact.",
    icon: FaAddressBook,
  },
  {
    type: CAMPAIGN_CONTEXT.ORG,
    display: "User journey",
    info: "Create a journey that enrolls Product Users. Each unique User will be enrolled in the journey, so multiple Users will be enrolled per email address, which will send multiple emails to the same email address in the context of each User.",
    icon: FaUserFriends,
  },
];

function CampaignTypeOption({
  data,
  isSelected,
  onClick,
}: {
  data: TypeDetails;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      mt={0}
      p={4}
      borderRadius="md"
      cursor="pointer"
      bgColor={isSelected ? "gray.100" : ""}
      _hover={{ bgColor: isSelected ? "gray.100" : "gray.50" }}
      onClick={onClick}
    >
      <HStack alignItems="flex-start">
        <Icon
          color="brand.blue"
          marginTop={1}
          as={isSelected ? FaCheckCircle : FaRegCircle}
        />
        <Box>
          <HStack color="brand.blue" fontSize="14px">
            <Text>{data.display}</Text>
          </HStack>
          <Text fontSize="12px">{data.info}</Text>
        </Box>
      </HStack>
    </VStack>
  );
}

function StepWrapper({
  stepNo,
  isDisabled,
  isLast = false,
  children,
  hideWrapper,
  ...props
}: {
  stepNo: number;
  isDisabled?: boolean;
  isLast?: boolean;
  hideWrapper?: boolean;
  children: ReactNode;
} & StackProps) {
  const disabledStyle: StackProps = isDisabled
    ? {
        filter: "grayscale(1)",
        opacity: "60%",
        pointerEvents: "none",
      }
    : {};

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      pl={hideWrapper ? "" : 8}
      borderLeft={!isLast ? "1px solid black" : ""}
      position="relative"
      spacing={4}
      {...props}
      {...disabledStyle}
    >
      {children}
      <Circle
        size="24px"
        bgColor="gray.100"
        color="brand.blue"
        position="absolute"
        left="-12px"
        mt="0 !important"
        top={0}
        hidden={hideWrapper}
      >
        <Text fontSize="12px">{stepNo}</Text>
      </Circle>
    </VStack>
  );
}

export default function CreateCampaignDrawer({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [campaignType, setCampaignType] = useState<CAMPAIGN_CONTEXT>(
    CAMPAIGN_CONTEXT.PERSON
  );
  const [enrollType, setEnrollType] = useState<JOURNEY_TYPES>(
    JOURNEY_TYPES.BATCH
  );
  const [campaignName, setCampaignName] = useState("");

  const dispatch = useAppDispatch();
  const { personOrgMapping } = useSelector(selectSettings);
  const { user } = useSelector(selectAccount);
  const {
    triggerCampaignEnabledOrgs: { tenants },
  } = useSelector(selectFeatureFlag);
  const { creatingCampaign } = useSelector(selectCampaign);
  const {
    createdTriggerCampaign: { loading: creatingTriggerCampaign },
  } = useSelector(selectTrigger);

  const isOneToNone = personOrgMapping === PERSON_ORG_MAPPING.ONE_TO_NONE;

  const ENROLL_TYPE_DETAILS = [
    {
      type: JOURNEY_TYPES.BATCH,
      display: "Scheduled journey",
      info: "Create a journey that is a either a recurring journey or a one time batch.",
      icon: RiTimerFill,
      show: true,
    },
    {
      type: JOURNEY_TYPES.TRIGGER,
      display: "Trigger based journey",
      info: "Create a journey that will enroll the Contact or User as soon as the trigger criteria is met, like performing a product activity or a value update on the Contact.",
      icon: BsFillLightningChargeFill,
      show: tenants.includes(user.organisation_id),
    },
  ];

  useEffect(() => {
    setCampaignType(CAMPAIGN_CONTEXT.PERSON);
    setEnrollType(JOURNEY_TYPES.BATCH);
  }, [isOpen]);

  function createNewCampaign() {
    switch (enrollType) {
      case JOURNEY_TYPES.BATCH:
        dispatch(
          createCampaign({ name: campaignName, campaignContext: campaignType })
        );
        break;
      case JOURNEY_TYPES.TRIGGER:
        dispatch(
          createTriggerCampaign({
            name: campaignName,
            campaignContext: TARGET_FROM_CONTEXT[campaignType],
          })
        );
        break;
    }
  }

  const showEnrollType =
    ENROLL_TYPE_DETAILS.filter(({ show }) => show).length > 1;

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"md"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="16px">Create a new journey</DrawerHeader>
        <DrawerBody>
          {!isOneToNone && (
            <StepWrapper stepNo={1} pb={8}>
              <Text fontSize="14px">
                Which type of Journey would you like to create?
              </Text>
              <VStack spacing={2}>
                {CAMPAIGN_TYPE_DETAILS.map((data) => (
                  <CampaignTypeOption
                    data={data}
                    isSelected={data.type === campaignType}
                    onClick={() => setCampaignType(data.type)}
                  />
                ))}
              </VStack>
            </StepWrapper>
          )}
          {showEnrollType && (
            <StepWrapper
              stepNo={isOneToNone ? 1 : 2}
              pb={8}
              isDisabled={
                !campaignType &&
                personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE
              }
            >
              <Text fontSize="14px">
                How should the journey enroll contacts?
              </Text>
              <VStack spacing={2}>
                {ENROLL_TYPE_DETAILS.filter((data) => data.show).map((data) => (
                  <CampaignTypeOption
                    data={data}
                    isSelected={data.type === enrollType}
                    onClick={() => setEnrollType(data.type)}
                  />
                ))}
              </VStack>
            </StepWrapper>
          )}

          <StepWrapper
            isLast={true}
            stepNo={1 + Number(!isOneToNone) + Number(showEnrollType)}
            isDisabled={!enrollType}
            hideWrapper={!showEnrollType && isOneToNone}
          >
            <>
              <Text fontSize="14px" mt="0 !important">
                What would you like to name the journey?
              </Text>
              <InputFieldWithError
                labelText="Journey name"
                formLabelProps={{
                  color: "brand.blue",
                  fontSize: "12px",
                }}
                placeholder="Enter your journey name"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                onKeyDown={(e) => onEnterKeySubmit(e, createNewCampaign)}
              />
            </>
          </StepWrapper>

          <HStack w="100%" justifyContent="flex-end" mt={4}>
            <IButton variant={BUTTON.SECONDARY} onClick={onClose}>
              Cancel
            </IButton>
            <IButton
              variant={BUTTON.PRIMARY}
              isDisabled={!(enrollType && campaignName)}
              onClick={createNewCampaign}
              isLoading={
                isLoading(creatingCampaign) ||
                isLoading(creatingTriggerCampaign)
              }
            >
              Create journey
            </IButton>
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
