import { HStack, Text } from "@chakra-ui/react";
import IconWithTooltip from "../../../../../../../components/IconWithTooltip";

export function NoteBox({
  title = "Note:",
  text,
  tooltipText,
}: {
  title?: string;
  text: string;
  tooltipText?: string;
}) {
  return (
    <HStack bg="blackAlpha.50" py={3} px={5} fontSize="sm">
      <Text fontWeight="semibold">{title}</Text>
      <Text>{text}</Text>
      {tooltipText ? (
        <IconWithTooltip
          label={tooltipText}
          iconContainerProps={{ as: "span" }}
          tooltipProps={{ fontSize: "xs", position: "initial" }}
        />
      ) : null}
    </HStack>
  );
}
