import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
import IModal from "../../../../../components/IModal";

// Warning modal when trying to change elements in a campaign flow after it has been published
// not highly abstract
export default function ChangeInFlowAlertModal({
  isOpen,
  onClose,
  onConfirm,
  infoText,
  title,
  content,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  infoText?: string;
  title: string;
  content: string;
}) {
  // on clicking confirm, the preview for the chosen template will be selected
  function handleConfirmClick() {
    onClose();
    onConfirm();
  }
  return (
    <>
      <IModal
        header={{ title: title }}
        isOpen={isOpen}
        onClose={onClose}
        primaryButton={{
          label: "Confirm",
          props: {
            onClick: handleConfirmClick,
          },
        }}
        secondaryButton={{
          label: "Cancel",
          props: {
            onClick: onClose,
          },
        }}
      >
        <Text fontSize="xs" fontWeight={600} color="brandBlue.600">
          {content}
        </Text>
        <Box
          borderRadius={6}
          p={4}
          mt={2}
          fontSize="xs"
          bg="grayV2.100"
          color="brandBlue.500"
        >
          <HStack spacing={2}>
            <Icon as={MdInfo} fontSize="md" />
            <Text>{infoText}</Text>
          </HStack>
        </Box>
      </IModal>
    </>
  );
}
