import { SingleValue } from "react-select";
import { LOADING_STATES } from "../constants/common";
import {
  ACCOUNT_SYNC_OPTIONS,
  SF_SYNC_PREFERENCES,
} from "../constants/unifiedMapping";
import { LoadingWithData, UserSummary } from "./common";
import { SalesforceConnection } from "./salesforceLegacyService";

export interface ColumnRowsI {
  type: string;
  name: string;
  default: boolean;
  id: number;
}

export interface SalesforceObject {
  object: string;
  fields: Destination[];
}

export enum DATA_TYPES {
  CRM = "crm",
  PRODUCT = "product",
  BOTH = "both",
}

export enum SOURCES {
  SALESFORCE = "salesforce",
  SNOWFLAKE = "snowflake",
  REDSHIFT = "redshift",
  SEGMENT = "segment",
  HUBSPOT = "hubspot",
  BIGQUERY = "bigquery",
  WEBFLOW = "webflow",
  FORMSTACK = "formstack",
}

export enum START_SETTINGS {
  RECOMMENDED = "recommended",
  SYNC_LATER = "sync_later",
}

export enum STATUS {
  ACTIVE = "active",
  DISABLED = "disabled",
  PAUSED = "paused",
}

export const ACCOUNT_TYPE_ID = "id";

export interface ConnectionListItem {
  source: SOURCES;
  connection_id: string;
  synced_contact: number;
  status: STATUS;
  connected_tables: string[];
  pause_time: null | string;
  synced_events: number;
  last_sync_time: null | string;
  data_type: DATA_TYPES[];
  sync_interval: null | string;
  synced_lead: number;
  name: string;
}

export interface Mapping {
  id: string;
  product_user_mapping: MappingClass | null;
  created_by: UserSummary;
  created_at: string;
  organisation_mapping: null;
  person_mapping: MappingClass | null;
  account_mapping: AccountMappingClass | null;
  product_activity_mapping: MappingClass | null;
  updated_by: UserSummary;
  updated_at: string;
  organisation_id: number;
}

export interface MappingClass {
  mapping: MappingElement[];
  updated_by: UserSummary;
  updated_at: string;
  version: number;
}

export interface AccountMappingClass {
  mapping: AccountMappingElement[];
  updated_by: UserSummary;
  updated_at: string;
  version: number;
}

export interface MappingElement {
  source: Source[] | null;
  destination: Destination;
  // For editing and removing mapping element in UI
  tempId?: number;
}

export interface AccountMappingElement {
  destination: Destination;
  tempId?: number;
  source: AccountSource[] | null;
}

export interface AccountSource {
  type: SOURCES;
  sync_preference: ACCOUNT_SYNC_PREFERENCE | null;
  connection_id: string;
  field: Destination | null;
}

export interface AccountSource {
  type: SOURCES;
  sync_preference: ACCOUNT_SYNC_PREFERENCE | null;
  connection_id: string;
  field: Destination | null;
}

export type Destination = {
  read_only: boolean;
  updatable: boolean;
  primary_key: boolean;
  sub_type?: SUB_TYPES;
} & DwDestination;

export interface DwDestination {
  type: DESTINATION_TYPES;
  custom: boolean;
  hidden: boolean;
  length: number | null;
  required: boolean;
  name: string;
  display: string;
}

export enum SUB_TYPES {
  DATETIME = "datetime",
  EMAIL = "email",
  INT = "int",
  PHONE = "phone",
  PICKLIST = "picklist",
  STRING = "string",
  URL = "url",
  ID = "id",
}

export enum DESTINATION_TYPES {
  DATETIME = "datetime",
  INTEGER = "integer",
  STRING = "string",
  BOOLEAN = "boolean",
  FLOAT = "float",
  LONG = "long",
  JSON = "json",
}

export enum INFERRED_TYPES {
  TIMEZONE = "inferred_timezone",
}

export interface Source {
  type: SOURCES;
  sync_preference: SYNC_PREFERENCE | null;
  connection_id: string;
  field: SalesforceField;
}

export interface SalesforceField {
  contact: DwDestination | Destination | null;
  lead: Destination | null;
}

export enum SYNC_PREFERENCE {
  TWO_WAY = "two_way",
  ALWAYS_USE_SALESFORCE = "always_use_connection",
  PREFER_CONNECTION = "prefer_connection",
}

export enum ACCOUNT_SYNC_PREFERENCE {
  ALWAYS_USE_SALESFORCE = "always_use_connection",
}

export interface DefaultCrmMapping {
  connection_type: SOURCES;
  connection_id: string;
  auto_map: boolean;
}

export interface DefaultAccountMapping {
  connection_type: SOURCES;
  connection_id: string;
  auto_map: boolean;
}

export type SyncPreference = (typeof SF_SYNC_PREFERENCES)[number];
export type AccountSyncPreference = (typeof ACCOUNT_SYNC_OPTIONS)[number];

export type AccountFormFieldDataTypes =
  | SingleValue<ConnectionListItem>
  | SingleValue<Destination>
  | SingleValue<AccountSyncPreference>;

export type FormFieldDataTypes =
  | SingleValue<ConnectionListItem>
  | SingleValue<Destination>
  | SingleValue<SyncPreference>
  | SingleValue<DwDestination>;

export interface SelectiveSync {
  lead: SelectiveSyncField;
  contact: SelectiveSyncField;
  account: SelectiveSyncField;
  opportunity: SelectiveSyncField;
}

export interface SelectiveSyncField {
  active: boolean;
  value: boolean;
  field: Destination | null;
}

export interface SalesforceConnectionDetailsDict {
  [key: string]: {
    objects?: SalesforceObject[];
    details?: SalesforceConnection | null;
    loading: LOADING_STATES;
    needsReauthentication?: boolean;
  };
}

export interface ColumnDetailsDict {
  [key: string]: LoadingWithData<DwDestination[]>;
}

export interface MappedFieldsDict {
  contact: {
    [connection: string]: string[];
  };
  lead: {
    [connection: string]: string[];
  };
}
