import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  ButtonProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import IButton, { BUTTON } from "../../../../components/IButton";
import InstallTrackingCode from "./InstallTrackingCode";
import InfoBanner from "../../../../components/InfoBanner";
import { useAppDispatch } from "../../../../store";
import { debounce } from "lodash";
import { MdInfo } from "react-icons/md";
import {
  getBaseDomain,
  getSubdomain,
  isVariantDomain,
  subdomainWildcardTest,
} from "../../../../common/helper/settingsHelper";
import { createDomain, validateDomain } from "../settingsSlice";
import {
  DOMAIN_VARIANTS,
  TRACK_ACCESS_STATES,
  TRACK_SUBDOMAIN_STATES,
} from "../../../../common/constants/settings";
import ValidationMessage from "../../../../components/ValidationMessage";
import { isFulfilled } from "../../../../common/helper/commonHelper";
import { validateDomainApi } from "../../../../common/api/websiteTracking/domains";

enum ADD_DOMAIN_STEPS {
  ADD_DOMAIN,
  INSTALL_TRACKING_CODE,
}

function CancelAndActionButtonPair({
  cancelButtonProps,
  actionButtonProps,
}: {
  cancelButtonProps?: ButtonProps;
  actionButtonProps?: ButtonProps;
}) {
  return (
    <HStack justifyContent="flex-end" mt={5} spacing={2}>
      <IButton
        variant={BUTTON.GHOST}
        children="Cancel"
        {...cancelButtonProps}
      />
      <IButton variant={BUTTON.PRIMARY} {...actionButtonProps} />
    </HStack>
  );
}

function InstallTrackingCodeContent({
  onClose,
  onSave,
}: {
  onClose: () => void;
  onSave: () => void;
}) {
  return (
    <>
      <InstallTrackingCode mr={0} />
      <InfoBanner
        color="brandBlue.500"
        w="100%"
        marginY={2}
        p={2}
        children={
          <Text fontWeight={600} pb={1}>
            {" "}
            Domain activation:{" "}
          </Text>
        }
        customContent={
          <Text>
            After the domain is added, It can take about 24 hours for the domain
            to get active on inflection. The system will only start showing the
            tracked data after activation
          </Text>
        }
      />
      <CancelAndActionButtonPair
        actionButtonProps={{ children: "Save", onClick: onSave }}
        cancelButtonProps={{ onClick: onClose }}
      />
    </>
  );
}
function AddSubdomainContent({
  domain,
  setDomain,
  currDomain,
  checkIsSubdomainPresent,
  goToNextStep,
  onClose,
}: {
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  currDomain?: string;
  checkIsSubdomainPresent?: (subDomain: string) => boolean;
  goToNextStep: () => void;
  onClose: () => void;
}) {
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const checkAndSetErrors = useCallback(
    (domainInput: string) => {
      if (!domainInput) {
        setError("Invalid subdomain");
      } else if (checkIsSubdomainPresent?.(`${domainInput}.${currDomain}`)) {
        setError("Subdomain already present");
      } else {
        setError("");
      }
    },
    [checkIsSubdomainPresent, setError, currDomain]
  );

  const trimDomain = useCallback(
    (url: string) => {
      //support for wildcard domain
      if (subdomainWildcardTest(url)) {
        setDomain(url);
        return url;
      } else {
        let splitUrl;
        try {
          // Attempt to extract the hostname from a properly formatted URL
          splitUrl = new URL(url).hostname;
        } catch {
          // Fallback for when the input is not a proper URL
          // ab.cd.com/p1/p2 => cd.com
          splitUrl = url.split("/")[0];
        }
        const subdomain = getSubdomain(splitUrl, currDomain!);
        subdomain && setDomain(subdomain);
        checkAndSetErrors(subdomain);
        return subdomain;
      }
    },
    [setDomain, checkAndSetErrors, currDomain]
  );

  const debouncedChange = useMemo(
    () => debounce(trimDomain, 1000),
    [trimDomain]
  );

  function setAndValidateDomain(domainInput: string) {
    setDomain(domainInput);
    checkAndSetErrors(domainInput);
    debouncedChange(domainInput);
  }

  const errorCheck = !!error && showError;

  function addDomain() {
    setShowError(true);
    trimDomain(domain);
    if (!error && !!domain) {
      goToNextStep();
    }
  }
  return (
    <>
      <FormControl isInvalid={errorCheck}>
        <FormLabel fontSize="14px" color="text.50">
          Add the {currDomain} subdomain you wish to track here
        </FormLabel>
        <InputGroup>
          <Input
            value={domain}
            placeholder="Enter your domain"
            onChange={(e) => {
              setAndValidateDomain(e.target.value);
            }}
            isInvalid={errorCheck}
          />
          <InputRightElement w="250px" justifyContent="flex-end" mr={2}>
            <Text fontSize="14px" color="text.50">
              .{currDomain}
            </Text>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          <ValidationMessage error={error} />
        </FormErrorMessage>
      </FormControl>
      <Text fontSize="14px" color="text.50" mt={2}>
        Inflection will track all the pages under the domain added, if the
        tracking code is installed.
      </Text>
      <CancelAndActionButtonPair
        actionButtonProps={{
          children: "Next",
          onClick: addDomain,
          isDisabled: errorCheck,
        }}
        cancelButtonProps={{ onClick: onClose }}
      />
    </>
  );
}
function AddDomainContent({
  domain,
  setDomain,
  goToNextStep,
  onClose,
}: {
  domain: string;
  setDomain: React.Dispatch<React.SetStateAction<string>>;
  goToNextStep: () => void;
  onClose: () => void;
}) {
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const checkDomainValidity = useMemo(
    () => (domain: string) => {
      setError(domain ? "" : "Invalid domain");
    },
    []
  );

  async function validateDomainName(domain: string) {
    setIsLoading(true);
    const { meta, payload } = await dispatch(validateDomain(domain));
    if (isFulfilled(meta.requestStatus)) {
      const { data } = payload as Awaited<ReturnType<typeof validateDomainApi>>;
      if (!data?.valid) {
        setError(data?.reason!);
      } else {
        goToNextStep();
      }
    }
    setIsLoading(false);
  }

  const trimDomain = useCallback(
    (url: string) => {
      let splitUrl;
      try {
        // Attempt to extract the hostname from a properly formatted URL
        splitUrl = new URL(url).hostname;
      } catch {
        // Fallback for when the input is not a proper URL
        // ab.cd.com/p1/p2 => cd.com
        splitUrl = url.split("/")[0];
      }
      const mainDomain = getBaseDomain(splitUrl);
      mainDomain && setDomain(mainDomain);
      checkDomainValidity(mainDomain!);
      return mainDomain;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showError]
  );

  const debouncedChange = useMemo(
    () => debounce(trimDomain, 1000),
    [trimDomain]
  );

  function setAndValidateDomain(domainInput: string) {
    setDomain(domainInput);
    checkDomainValidity(domainInput);
    debouncedChange(domainInput);
  }

  const errorCheck = !!error && showError;

  function addDomain() {
    setShowError(true);
    trimDomain(domain);
    !error && !!domain && validateDomainName(domain);
  }

  return (
    <>
      <FormControl isInvalid={errorCheck}>
        <FormLabel fontSize="14px" color="text.50">
          Add the link of the domain you wish to track here
        </FormLabel>
        <InputGroup>
          <InputRightElement>
            <Tooltip
              label="You only need the domain name to register on inflection"
              mr={1}
            >
              <Box pt="3px">
                <Icon as={MdInfo} color="brandBlue.500" />
              </Box>
            </Tooltip>
          </InputRightElement>
          <Input
            value={domain}
            placeholder="Enter your domain"
            onChange={(e) => {
              setAndValidateDomain(e.target.value);
            }}
            isInvalid={errorCheck}
          />
        </InputGroup>
        <FormErrorMessage>
          <ValidationMessage error={error} />
        </FormErrorMessage>
      </FormControl>
      <Text fontSize="14px" color="text.50" mt={2}>
        Inflection will track all the subdomains under the domain added, if the
        tracking code is installed.
      </Text>
      <InfoBanner
        color="brandBlue.500"
        w="100%"
        marginY={2}
        p={2}
        children={
          <Text fontWeight={600} pb={1}>
            {" "}
            Subdomains:{" "}
          </Text>
        }
        customContent={
          <Text>
            Adding the top-level domain automatically tracks all subdomains. You
            can also customise subdomains individually in the settings.
          </Text>
        }
      />
      <CancelAndActionButtonPair
        actionButtonProps={{
          children: "Next",
          onClick: addDomain,
          isDisabled: errorCheck,
          isLoading: isLoading,
          loadingText: "Validating",
        }}
        cancelButtonProps={{ onClick: onClose }}
      />
    </>
  );
}

export default function AddDomainFlow({
  onClose,
  isOpen,
  variant = DOMAIN_VARIANTS.DOMAIN,
  currDomain,
  addSubdomain,
  checkIsSubdomainPresent,
}: {
  onClose: () => void;
  isOpen: boolean;
  variant?: DOMAIN_VARIANTS;
  currDomain?: string;
  addSubdomain?: (subdomain: { [key: string]: TRACK_ACCESS_STATES }) => void;
  checkIsSubdomainPresent?: (subDomain: string) => boolean;
}) {
  const [currentStep, setCurrentStep] = useState<ADD_DOMAIN_STEPS>(
    ADD_DOMAIN_STEPS.ADD_DOMAIN
  );
  const dispatch = useAppDispatch();

  const [domain, setDomain] = useState("");

  let isTypeDomain = isVariantDomain(variant);

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(ADD_DOMAIN_STEPS.ADD_DOMAIN);
      setDomain("");
    }
  }, [isOpen]);

  function onClickSaveSubdomain() {
    const hostname = `${domain}.${currDomain}`;
    addSubdomain?.({ [hostname]: TRACK_ACCESS_STATES.ALLOWED });
    onClose();
  }

  function onClickSaveDomain() {
    dispatch(
      createDomain({
        domain: domain,
        trackingStatus: TRACK_SUBDOMAIN_STATES.DEFAULT,
        trackingConfiguration: {
          [domain]: TRACK_ACCESS_STATES.ALLOWED,
        },
      })
    );

    onClose();
  }

  function goToPrevStep() {
    setCurrentStep((prev) => prev - 1);
  }

  function goToNextStep() {
    setCurrentStep((prev) => prev + 1);
  }

  function showCurrentStepContent() {
    switch (currentStep) {
      case ADD_DOMAIN_STEPS.ADD_DOMAIN:
        return isTypeDomain ? (
          <AddDomainContent
            domain={domain}
            setDomain={setDomain}
            goToNextStep={goToNextStep}
            onClose={onClose}
          />
        ) : (
          <AddSubdomainContent
            domain={domain}
            setDomain={setDomain}
            currDomain={currDomain}
            checkIsSubdomainPresent={checkIsSubdomainPresent}
            goToNextStep={goToNextStep}
            onClose={onClose}
          />
        );
      case ADD_DOMAIN_STEPS.INSTALL_TRACKING_CODE:
        return (
          <InstallTrackingCodeContent
            onClose={onClose}
            onSave={isTypeDomain ? onClickSaveDomain : onClickSaveSubdomain}
          />
        );
    }
  }

  return (
    <MemoizedCommonDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={`Add ${isTypeDomain ? "Domain" : "Subdomain"}`}
      size="md"
      placement="right"
      goBack={
        currentStep === ADD_DOMAIN_STEPS.INSTALL_TRACKING_CODE
          ? goToPrevStep
          : undefined
      }
    >
      {showCurrentStepContent()}
    </MemoizedCommonDrawer>
  );
}
