import { Center, Icon, HStack, Spinner } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { formatLargeNumber } from "../../../../../common/helper/commonHelper";
import { DEFAULT_EDGE_COLOR, WIDGET_CONTAINER_CLASS } from "./constants";

export default function MemberCount({
  count,
  loading,
}: {
  count?: number;
  loading?: boolean;
}) {
  return (
    <Center w="fit-content" className={WIDGET_CONTAINER_CLASS}>
      <HStack
        bg={DEFAULT_EDGE_COLOR}
        px={2}
        py={1}
        rounded="md"
        fontSize="12px"
        color="white"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        cursor="pointer"
      >
        <Icon as={FaUsers} fontSize="md" color="white" />
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <NumberFormat
            value={formatLargeNumber(count ?? 0, 2)}
            isNumericString={true}
            thousandSeparator={true}
            displayType="text"
          />
        )}
      </HStack>
    </Center>
  );
}
