import { MdLock } from "react-icons/md";
import IconWithTooltip from "./IconWithTooltip";

export default function LockIconWithTooltip({ message }: { message: string }) {
  return (
    <IconWithTooltip
      icon={MdLock}
      h="14px"
      ml="2px"
      color="gray.600"
      label={message}
      tooltipProps={{ fontSize: "xs" }}
    />
  );
}
