import { HStack, Icon, Text } from "@chakra-ui/react";
import { IoWarning } from "react-icons/io5";

export default function ValidationMessage({ error }: { error: string }) {
  return (
    <HStack hidden={!error} mt={1}>
      <Icon as={IoWarning} color="red.500" />
      <Text fontSize="12px" color="red.500">
        {error}
      </Text>
    </HStack>
  );
}
