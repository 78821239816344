import { VStack, Text, Box, HStack } from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import {
  SyncActionsAllowed,
  SyncObjectPermissions,
} from "../../../../../../common/types/salesforce";
import MemoizedCommonDrawer from "../../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../../connectionDetails/components/ConnectionButtons";
import IButton from "../../../../../../components/IButton";

function ShowPermissions({
  val,
  displayString,
}: {
  val: SyncActionsAllowed | undefined;
  displayString: string;
}) {
  if (val?.read && val?.write) {
    return (
      <HStack>
        <FaCheckCircle color="green" />
        <Text>Read and write {displayString}</Text>
      </HStack>
    );
  }
  if (val?.read) {
    return (
      <HStack>
        <FaCheckCircle color="yellow" />
        <Text>Read only {displayString}</Text>
      </HStack>
    );
  }
  if (val?.write) {
    return (
      <HStack>
        <FaCheckCircle color="yellow" />
        <Text>Write only {displayString}</Text>
      </HStack>
    );
  }
  return (
    <HStack>
      <FaExclamationCircle color="red" />
      <Text>No access to {displayString}</Text>
    </HStack>
  );
}

export default function SfPermissionsDrawer({
  permissions,
  isOpen,
  onClose,
  onRecheckPermission,
  goToNextStep,
  fetchingConnection,
}: {
  permissions: SyncObjectPermissions;
  onClose: VoidFunction;
  isOpen: boolean;
  onRecheckPermission: VoidFunction;
  goToNextStep: VoidFunction;
  fetchingConnection?: boolean;
}) {
  return (
    <MemoizedCommonDrawer
      title="Permissions and access"
      onClose={onClose}
      isOpen={isOpen}
      size="md"
      placement="right"
    >
      <VStack width="100%" alignItems="flex-start">
        <Box pr="12">
          <Text pb="2">The following permission details were obtained</Text>
          <IButton
            color="blue.600"
            fontSize="sm"
            variant={"link"}
            onClick={onRecheckPermission}
            isLoading={fetchingConnection}
            loadingText="Click here to re-check permissions"
          >
            Click here to re-check permissions
          </IButton>
        </Box>
        <Box width="100%" pl="4" py={4} bg="grayV2.100" borderRadius="md">
          <VStack alignItems="flex-start">
            <ShowPermissions val={permissions.lead} displayString={"Leads"} />
            <ShowPermissions
              val={permissions.contact}
              displayString={"Contacts"}
            />
            <ShowPermissions
              val={permissions.account}
              displayString={"Accounts"}
            />
          </VStack>
        </Box>
      </VStack>
      <ConnectionButtons
        name="continue-button"
        isLoading={fetchingConnection}
        onClick={goToNextStep}
        closeModal={onClose}
      />
    </MemoizedCommonDrawer>
  );
}
