import IconWithTooltip from "./IconWithTooltip";
import { IconProps, TextProps, HStack, Text } from "@chakra-ui/react";

export default function TextWithWarning({
  text,
  warningText,
  iconProps,
  ...props
}: {
  text: string;
  warningText?: string;
  iconProps?: IconProps;
} & TextProps) {
  return (
    <HStack alignItems="flex-start">
      <Text {...props}>{text}</Text>
      {warningText && (
        <IconWithTooltip
          label={warningText}
          color="orange.500"
          {...iconProps}
        />
      )}
    </HStack>
  );
}
