import {
  Box,
  ButtonProps,
  Divider,
  HStack,
  SlideFade,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { addDays, endOfToday, isAfter, startOfDay, subDays } from "date-fns";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { FaRedo, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  getEpochTimeUtc,
  isLoading,
} from "../../../../../../common/helper/commonHelper";
import {
  SalesforceConnectionV2,
  SfSyncErrorRecord,
  SF_SYNC_ERROR_TYPE,
  SfCampaignSyncError,
} from "../../../../../../common/types/salesforce";
import { DataTable } from "../../../../../../components/data-table/DataTable";
import DateTimeRangeFilter from "../../../../../../components/DateTimeRangeFilter";
import IButton from "../../../../../../components/IButton";
import IconWithTooltip from "../../../../../../components/IconWithTooltip";
import { useAppDispatch } from "../../../../../../store";
import SubHeader from "../../../components/SubHeader";
import {
  getSalesforceCampaignSyncErrors,
  listSalesforceSyncErrors,
  setSalesforceSyncErrorsPage,
  selectSalesforce,
  resyncSalesforceSyncErrors,
  archiveSalesforceSyncErrors,
} from "../../salesforceSlice";
import { SF_ERROR_TYPE_DETAILS } from "../../../../../../common/constants/salesforce";
import AffectedErrorRecordsModal from "./AffectedErrorRecordsModal";
import {
  sfCampaignSyncErrorColumns,
  sfGeneralSyncErrorColumns,
} from "./SyncErrorsTableDefinitions";

const START_DATE = startOfDay(subDays(new Date(), 7));
const END_DATE = endOfToday();

const SF_INFLECTION_SYNC_ERRORS = [
  SF_SYNC_ERROR_TYPE.OTHERS,
  SF_SYNC_ERROR_TYPE.PERMISSION,
];

function ResyncArchiveTab({
  isOpen,
  numOfSelectedRows,
  resyncButtonProps,
  archiveButtonProps,
}: {
  isOpen: boolean;
  numOfSelectedRows: number;
  resyncButtonProps: ButtonProps;
  archiveButtonProps: ButtonProps;
}) {
  return (
    <SlideFade in={isOpen}>
      <HStack spacing="20px" bgColor="gray.100" py="2">
        <Box pl="20px">
          <Text fontSize="sm">{numOfSelectedRows} selected</Text>
        </Box>
        <IButton
          size="sm"
          aria-label="Resync"
          leftIcon={<FaRedo fontSize="sm" />}
          {...resyncButtonProps}
        >
          Resync
        </IButton>
        <IButton
          size="sm"
          aria-label="Archive"
          leftIcon={<FaTrash fontSize="sm" />}
          {...archiveButtonProps}
        >
          Archive
        </IButton>
      </HStack>
    </SlideFade>
  );
}

function SalesforceSyncErrorsTable({
  errorType,
  handleResyncErrors,
  handleArchiveErrors,
  filterDates,
  changePage,
}: {
  errorType: SF_SYNC_ERROR_TYPE;
  handleResyncErrors: (errorClasses: string[]) => void;
  handleArchiveErrors: (errorClasses: string[]) => void;
  filterDates: [Date, Date];
  changePage: (pageNo: number, errorType: SF_SYNC_ERROR_TYPE) => void;
}) {
  const { sfSyncErrors, sfCampaignSyncErrors } = useSelector(selectSalesforce);
  const [selectedRows, setSelectedRows] = useState<SfSyncErrorRecord[]>([]);
  const [selectedRow, setSelectedRow] = useState<SfSyncErrorRecord | null>(
    null
  );

  const {
    isOpen: isOpenAffectedRecordsModal,
    onOpen: onOpenAffectedRecordsModal,
    onClose: onCloseAffectedRecordsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenArchiveResyncTab,
    onOpen: onOpenArchiveResyncTab,
    onClose: onCloseArchiveResyncTab,
  } = useDisclosure();

  useEffect(() => {
    if (selectedRows.length) {
      onOpenArchiveResyncTab();
    } else {
      onCloseArchiveResyncTab();
    }
  }, [selectedRows, onCloseArchiveResyncTab, onOpenArchiveResyncTab]);

  const onClickAffectedRecords = useCallback(
    (row: SfSyncErrorRecord) => {
      setSelectedRow(row);
      onOpenAffectedRecordsModal();
    },
    [onOpenAffectedRecordsModal]
  );

  function closeAffectedRecordsModal() {
    setSelectedRow(null);
    onCloseAffectedRecordsModal();
  }

  const errorClasses = selectedRows.map(({ errorClass }) => errorClass);

  return (
    <>
      {errorType === SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC ? (
        <>
          <Box h="46px" visibility="hidden">
            ReSync Archive Tab Dummy
          </Box>
          <DataTable<SfCampaignSyncError>
            fetchingList={isLoading(sfCampaignSyncErrors.loadingList)}
            changingPage={sfCampaignSyncErrors.changingPage}
            list={sfCampaignSyncErrors.list ?? []}
            totalPageSize={sfCampaignSyncErrors.pageSize}
            setPage={(pageNo) => {}}
            totalPageCount={sfCampaignSyncErrors.totalPageCount}
            currentPage={sfCampaignSyncErrors.currentPageNo ?? 1}
            columns={sfCampaignSyncErrorColumns}
            emptyMsg="No sync errors found"
          />
        </>
      ) : (
        <>
          <ResyncArchiveTab
            isOpen={isOpenArchiveResyncTab}
            numOfSelectedRows={selectedRows.length}
            resyncButtonProps={{
              onClick: () => handleResyncErrors(errorClasses),
              isLoading: isLoading(sfSyncErrors[errorType].resyncingRecords),
            }}
            archiveButtonProps={{
              onClick: () => handleArchiveErrors(errorClasses),
              isLoading: isLoading(sfSyncErrors[errorType].archivingRecords),
            }}
          />
          <DataTable
            fetchingList={isLoading(sfSyncErrors[errorType].loadingList)}
            changingPage={sfSyncErrors[errorType].changingPage}
            list={sfSyncErrors[errorType].list ?? []}
            totalPageSize={sfSyncErrors[errorType].pageSize}
            setPage={(pageNo: number) => changePage(pageNo, errorType)}
            totalPageCount={sfSyncErrors[errorType].totalPageCount}
            currentPage={sfSyncErrors[errorType].currentPageNo ?? 1}
            columns={sfGeneralSyncErrorColumns(onClickAffectedRecords)}
            emptyMsg="No sync errors found"
            onSelectionChange={setSelectedRows}
          />
        </>
      )}
      <AffectedErrorRecordsModal
        isOpen={isOpenAffectedRecordsModal}
        onClose={closeAffectedRecordsModal}
        errorType={errorType}
        data={selectedRow}
        filterDates={filterDates}
      />
    </>
  );
}

const SyncErrorsTab = forwardRef(
  (
    {
      connection,
    }: {
      connection: SalesforceConnectionV2 | null;
    },
    ref
  ) => {
    const dispatch = useAppDispatch();
    const { sfSyncErrors, sfCampaignSyncErrors } =
      useSelector(selectSalesforce);
    const [[startTimeSfCampaign, endTimeSfCampaign], setDateRangeSfCampaign] =
      useState([START_DATE, END_DATE]);
    const [[startTime, endTime], setDateRange] = useState([
      START_DATE,
      END_DATE,
    ]);
    const lastSearchedDate = useRef({ startTime, endTime });

    const [errorType, setErrorType] = useState(SF_SYNC_ERROR_TYPE.OTHERS);
    const othersPageNo = sfSyncErrors[SF_SYNC_ERROR_TYPE.OTHERS].currentPageNo;
    const permissionsPageNo =
      sfSyncErrors[SF_SYNC_ERROR_TYPE.PERMISSION].currentPageNo;

    const dateFilterHandler = useCallback(
      (startTime: Date, endTime: Date, errorType?: SF_SYNC_ERROR_TYPE) => {
        const startTimestamp = getEpochTimeUtc(startTime);
        const endTimestamp = getEpochTimeUtc(endTime);
        switch (errorType) {
          case SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC:
            dispatch(
              getSalesforceCampaignSyncErrors({
                startTimestamp,
                endTimestamp,
              })
            );
            break;
          case SF_SYNC_ERROR_TYPE.OTHERS:
          case SF_SYNC_ERROR_TYPE.PERMISSION:
            if (connection?.connectionId) {
              dispatch(
                listSalesforceSyncErrors({
                  connectionId: connection.connectionId,
                  errorType,
                  startTime: startTimestamp,
                  endTime: endTimestamp,
                })
              );
              lastSearchedDate.current = { startTime, endTime };
            }
            break;
          default:
            SF_INFLECTION_SYNC_ERRORS.forEach((errorType) => {
              if (connection?.connectionId) {
                dispatch(
                  listSalesforceSyncErrors({
                    connectionId: connection.connectionId,
                    errorType,
                    startTime: startTimestamp,
                    endTime: endTimestamp,
                  })
                );
                lastSearchedDate.current = { startTime, endTime };
              }
            });
        }
      },
      [dispatch, connection?.connectionId]
    );

    function onDateChangeSfCampaign(start: Date, end: Date) {
      const thirtyDaysFromStartDate = addDays(start, 30);
      if (isAfter(end, thirtyDaysFromStartDate)) {
        setDateRangeSfCampaign([start, thirtyDaysFromStartDate]);
      } else {
        setDateRangeSfCampaign([start, end]);
      }
    }

    useEffect(() => {
      dateFilterHandler(
        startTimeSfCampaign,
        endTimeSfCampaign,
        SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC
      );

      //only when the user clicks on search this should run, not when start , end time changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
      dateFilterHandler(startTime, endTime, SF_SYNC_ERROR_TYPE.OTHERS);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilterHandler, othersPageNo]);

    useEffect(() => {
      dateFilterHandler(startTime, endTime, SF_SYNC_ERROR_TYPE.PERMISSION);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilterHandler, permissionsPageNo]);

    useImperativeHandle(ref, () => ({
      filterWithDate(date: Date) {
        const startDate = date;
        const endDate = new Date();
        dateFilterHandler(startDate, endDate);
        setDateRange([startDate, endDate]);
      },
    }));

    function switchTabs(value: SF_SYNC_ERROR_TYPE) {
      setErrorType(value);
    }

    function changePage(pageNo: number, errorType: SF_SYNC_ERROR_TYPE) {
      dispatch(setSalesforceSyncErrorsPage({ pageNo, errorType }));
    }

    function handleResyncErrors(errorClasses: string[]) {
      if (connection?.connectionId) {
        const resyncData = {
          connectionId: connection.connectionId,
          errorClasses,
          startTime: getEpochTimeUtc(startTime),
          endTime: getEpochTimeUtc(endTime),
        };
        dispatch(resyncSalesforceSyncErrors({ data: resyncData, errorType }));
      }
    }

    function handleArchiveErrors(errorClasses: string[]) {
      if (connection?.connectionId) {
        const resyncData = {
          connectionId: connection.connectionId,
          errorClasses,
          startTime: getEpochTimeUtc(startTime),
          endTime: getEpochTimeUtc(endTime),
        };
        dispatch(archiveSalesforceSyncErrors({ data: resyncData, errorType }));
      }
    }

    const isCampaignSyncError = errorType === SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC;

    return (
      <Box p="5" bg="white" rounded="md">
        <SubHeader title="Sync errors">
          {isCampaignSyncError ? (
            <HStack>
              <IconWithTooltip label="View all errors in a 30 day window" />
              <DateTimeRangeFilter
                startDate={startTimeSfCampaign}
                endDate={endTimeSfCampaign}
                onDateChange={onDateChangeSfCampaign}
                maxEndDate={addDays(startTimeSfCampaign, 30)}
                dateFilterHandler={() =>
                  dateFilterHandler(
                    startTimeSfCampaign,
                    endTimeSfCampaign,
                    SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC
                  )
                }
              />
            </HStack>
          ) : (
            <DateTimeRangeFilter
              startDate={startTime}
              endDate={endTime}
              onDateChange={(startTime, endTime) =>
                setDateRange([startTime, endTime])
              }
              dateFilterHandler={() => {
                dateFilterHandler(startTime, endTime);
              }}
            />
          )}
        </SubHeader>
        <Tabs p={0} isLazy>
          <TabList borderBottom={1}>
            {SF_ERROR_TYPE_DETAILS.map(({ name, display }) => {
              return (
                <Tab fontSize="sm" onClick={() => switchTabs(name)} key={name}>
                  {display} (
                  {name === SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC
                    ? sfCampaignSyncErrors.count
                    : sfSyncErrors[name]?.count ?? 0}
                  )
                </Tab>
              );
            })}
          </TabList>
          <Divider pt="3px" />
          <TabPanels>
            {SF_ERROR_TYPE_DETAILS.map(({ name }) => {
              return (
                <TabPanel key={name}>
                  <SalesforceSyncErrorsTable
                    errorType={errorType}
                    changePage={changePage}
                    handleResyncErrors={handleResyncErrors}
                    handleArchiveErrors={handleArchiveErrors}
                    filterDates={
                      name === SF_SYNC_ERROR_TYPE.CAMPAIGN_SYNC
                        ? [startTimeSfCampaign, endTimeSfCampaign]
                        : [
                            lastSearchedDate.current.startTime,
                            lastSearchedDate.current.endTime,
                          ]
                    }
                  />
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
);

export default SyncErrorsTab;
