import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmailToken,
  createEmailToken,
  resetFlags,
  deleteEmailToken,
  resetDeleteFlag,
  listEmailTokens,
  resetTokenList,
} from "./emailTokenSlice";
import { EmailTokenDetails } from "../../../common/types/campaign";
import { FaEllipsisV } from "react-icons/fa";
import {
  getTableRowLinkProps,
  getUserStatusFromMapping,
  isLoading,
  isSuccess,
  keepClickEventRestricted,
} from "../../../common/helper/commonHelper";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  LOADING_STATES,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import { useNavigate } from "react-router-dom";
import urls from "../../../urls";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import UserNameWithAvatar from "../../../components/UserNameWithAvatar";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { getUserList, selectAccount } from "../../account/accountSlice";
import { DataTable } from "../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import {
  HeaderSearchProps,
  ListFilterProps,
  PaginationFilterParams,
} from "../../../common/types/common";
import InitialEmptyState from "../../../components/InitialEmptyState";
import TokenEmptyState from "../../../common/img/emptyStateLogos/token.svg";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

function TokenHeader({
  clickHandler,
  loading,
  searchProps: { searchKeyword, onSearch, hidden },
  filterProps,
}: {
  clickHandler: () => void;
  loading: LOADING_STATES;
  searchProps: HeaderSearchProps;
  filterProps?: ListFilterProps;
}) {
  return (
    <CommonListHeader
      heading="Tokens"
      searchInputProps={{
        placeholder: "Search tokens",
        name: "search-input",
        defaultValue: searchKeyword,
        onSearch: onSearch,
        hidden: hidden,
      }}
      createButtonProps={{
        onClick: clickHandler,
        isLoading: isLoading(loading),
        name: "new-token",
        text: "New Token",
      }}
      filterProps={filterProps}
    />
  );
}

const COLUMNS_TO_SEARCH_IN = ["name"];

export default function EmailTokenList() {
  const dispatch = useDispatch();
  const { usersList } = useSelector(selectAccount);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<EmailTokenDetails>();

  const [isOpenAddModal, setIsOpenAddModal] = useState<string | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] =
    useState<EmailTokenDetails | null>(null);
  const [selectedRow, setSelectedRow] = useState<EmailTokenDetails | null>(
    null
  );

  const {
    emailTokenList: {
      list: emailTokenList,
      deletingToken,
      creatingEmailToken,
      createdEmailTokenId,
    },
  } = useSelector(selectEmailToken);

  const fetchTokenList = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listEmailTokens({
          pageNo,
          searchKeyword,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchTokenList,
    fetchingList: emailTokenList.fetchingList,
  });

  const onClickDeleteToken = useCallback((row: EmailTokenDetails) => {
    setSelectedRow(row);
    setIsOpenDeleteModal(row);
  }, []);

  const goToTokenDetails = useCallback(
    (token: EmailTokenDetails) => {
      navigate(`${urls.token}/${token.token_id}`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        size: 600,
      }),
      columnHelper.accessor("updated_at", {
        header: "Last Updated On",
        size: 350,
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
      }),
      columnHelper.accessor("created_at", {
        header: "Created on",
        size: 350,
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
      }),
      columnHelper.accessor("created_by.name", {
        header: "Created by",
        size: 200,
        cell: (info) =>
          info ? (
            <UserNameWithAvatar
              username={info.getValue()}
              accountStatus={getUserStatusFromMapping(
                info.row.original.created_by.id.toString(),
                usersList
              )}
            />
          ) : (
            ""
          ),
      }),
      columnHelper.display({
        header: "",
        id: "actions",
        size: 100,
        cell: (info) => (
          <Flex alignItems="center" justifyContent="flex-end">
            <Box onClick={(e) => keepClickEventRestricted(e)}>
              <Menu>
                <MenuButton
                  size="sm"
                  as={IconButton}
                  aria-label="Options"
                  variant="ghost"
                  name="options"
                  icon={<FaEllipsisV />}
                />
                <MenuList rootProps={{ style: { right: 0 } }}>
                  <MenuItem
                    onClick={() => goToTokenDetails(info.row.original)}
                    name="edit-item"
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => onClickDeleteToken(info.row.original)}
                    name="remove-item"
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        ),
      }),
    ],
    [columnHelper, goToTokenDetails, onClickDeleteToken, usersList]
  );

  useEffect(() => {
    return () => {
      dispatch(resetTokenList());
      dispatch(resetFlags());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(deletingToken)) {
      handlePageChange(emailTokenList.currentPageNo);
      dispatch(resetDeleteFlag());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deletingToken]);

  useEffect(() => {
    if (isSuccess(creatingEmailToken)) {
      navigate(`${urls.token}/${createdEmailTokenId}`);
    }
  }, [navigate, creatingEmailToken, createdEmailTokenId]);

  function createEmailTokenHandler(newName: string) {
    setIsOpenAddModal(null);
    dispatch(createEmailToken(newName));
  }

  function deleteTokenHandler() {
    if (isOpenDeleteModal) {
      dispatch(deleteEmailToken(isOpenDeleteModal.token_id));
      setIsOpenDeleteModal(null);
      setSelectedRow(null);
    }
  }

  function validateTokenName(tokenName: string) {
    const tokenNameRegex = new RegExp(/^[a-zA-Z_][a-zA-Z_0-9]*$/);
    return tokenNameRegex.test(tokenName)
      ? ""
      : "Token name must contain only letters, numbers or underscores (_)";
  }

  function createTokenHandler() {
    setIsOpenAddModal("");
  }

  const isInitEmpty = !(emailTokenList.totalPageCount || isFiltersApplied);

  return (
    <>
      <TokenHeader
        clickHandler={createTokenHandler}
        loading={creatingEmailToken}
        searchProps={{
          searchKeyword: searchKeyword,
          onSearch: handleSearchChange,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.TOKEN,
          hidden: isInitEmpty,
        }}
      />
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
          <DataTable
            fetchingList={fetchingList}
            changingPage={emailTokenList.changingPage}
            list={emailTokenList.list as EmailTokenDetails[]}
            totalPageSize={emailTokenList.pageSize}
            totalPageCount={emailTokenList.totalPageCount}
            currentPage={emailTokenList.currentPageNo}
            onRowClick={goToTokenDetails}
            setPage={handlePageChange}
            columns={columns}
            emptyMsg={`No tokens found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps(
                { to: urls.token, editParam: "token_id" },
                data
              )
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Elevate your campaigns by defining custom tokens"
          message="No tokens created"
          ctaProps={{
            children: "Create token",
            name: "new-token",
            onClick: createTokenHandler,
            isLoading: isLoading(creatingEmailToken),
          }}
        >
          <Image src={TokenEmptyState} alt="Token" />
        </InitialEmptyState>
      )}

      <EditNameModal
        value={isOpenAddModal ?? ""}
        action={ASSET_NAME_ACTIONS.CREATE}
        isOpen={isOpenAddModal !== null}
        onClose={() => setIsOpenAddModal(null)}
        onSubmit={createEmailTokenHandler}
        asset="token"
        validateName={validateTokenName}
        helperText="Token name must contain only letters, numbers or underscores (_)"
      />

      <DeleteConfirmationModal
        isOpen={!!isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(null)}
        submitHandler={deleteTokenHandler}
        itemSpecificName={selectedRow?.name ?? ""}
        deleteItemType={DELETION_MODAL_TYPES_INFO[ASSET_TYPES.TOKEN].display}
        isLoading={isLoading(deletingToken)}
      />
    </>
  );
}
