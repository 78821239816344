import { FaUserPlus } from "react-icons/fa";
import {
  CAMPAIGN_CONTEXT,
  DynamicListType,
} from "../../../../../common/types/campaign";
import DynamicList from "../../../../../components/dynamic-list/DynamicList";
import CommonDrawer from "../../components/CommonDrawer";
import CampaignDynamicList from "../../CampaignDynamicList";
import SectionWrapper, {
  SectionItem,
  SectionLink,
} from "./components/SectionWrapper";
import { useDisclosure } from "@chakra-ui/react";
import { getPersonLabel } from "../../../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import { selectCampaign } from "../../campaignSlice";

export default function AudienceFilterSection({
  readonly,
  errorText,
  data,
  onChange,
  saveDraft,
  campaignContext,
  isLoading,
  activeErrorCheck,
  validateOnClose,
}: {
  readonly: boolean;
  errorText: string;
  data: DynamicListType[];
  onChange: (dynamicList: DynamicListType[]) => void;
  saveDraft: (dynamicList: DynamicListType[], skipDebounce?: boolean) => void;
  campaignContext: CAMPAIGN_CONTEXT;
  isLoading: boolean;
  activeErrorCheck: boolean;
  validateOnClose: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onHandleClose = () => {
    if (activeErrorCheck) {
      validateOnClose();
    }
    onClose();
  };

  const {
    campaignDetails: {
      data: { dynamic_list_id: listId },
    },
  } = useSelector(selectCampaign);

  return (
    <>
      <SectionWrapper
        icon={FaUserPlus}
        title="Audience"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading}
        isEmpty={!data.length}
      >
        {data.length ? (
          <SectionItem
            infoText={`Consider a ${getPersonLabel(
              campaignContext
            )} for the workflow only if they meet the following criteria`}
            errorText={activeErrorCheck ? errorText : ""}
          >
            <DynamicList
              dynamicListData={data ?? []}
              onChange={() => {}}
              sidebar={false}
              activeErrorCheck={activeErrorCheck}
              campaignContext={campaignContext}
              readOnlyMode
            />
          </SectionItem>
        ) : (
          <SectionLink
            onOpen={onOpen}
            title="Click to set up audience filter"
            LinkIcon={FaUserPlus}
            errorText={errorText}
          />
        )}
      </SectionWrapper>
      <CommonDrawer
        title="Audience"
        isOpen={isOpen}
        onClose={onHandleClose}
        size="3xl"
      >
        <CampaignDynamicList
          dynamicListId={listId}
          dynamicList={data ?? []}
          setDynamicList={(data) => onChange(data)}
          saveDraft={saveDraft}
          isReadOnly={readonly}
          campaignContext={campaignContext}
          activeErrorCheck={activeErrorCheck}
        />
      </CommonDrawer>
    </>
  );
}
