import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export default function ContentContainerWithHeader({
  children,
  ...props
}: {
  children: ReactNode;
} & FlexProps) {
  return (
    <Flex direction="column" w="100%" px="15px" bg="grayV2.100" {...props}>
      {children}
    </Flex>
  );
}
