import {
  Flex,
  Box,
  ListIcon,
  Alert,
  AlertIcon,
  AlertDescription,
  VStack,
  Heading,
  UnorderedList,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaMinusCircle } from "react-icons/fa";
import IModal from "../../../../../components/IModal";

function ListItemCustom({
  children,
  iconSelector,
}: {
  children: ReactNode;
  iconSelector?: boolean;
}) {
  return (
    <Flex>
      <Box>
        <ListIcon
          as={FaMinusCircle}
          color={iconSelector ? "black" : "red.400"}
        />
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
}

export default function SfDisconnectModal({
  isOpen,
  onClose,
  onDeleteSfConnection,
  deletingSfConnection,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDeleteSfConnection: () => void;
  deletingSfConnection: boolean;
}) {
  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      header={{
        title: `Disconnect Salesforce`,
      }}
      deleteButton={{
        label: "Disconnect",
        props: {
          onClick: onDeleteSfConnection,
          isLoading: deletingSfConnection,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onClose,
          isDisabled: deletingSfConnection,
        },
      }}
      modalBodyProps={{ p: 0 }}
    >
      <Alert status="error" fontSize="sm" mb="4">
        <AlertIcon />
        <AlertDescription>
          Do you want to disconnect Salesforce from Inflection?
        </AlertDescription>
      </Alert>
      <VStack alignItems="flex-start" spacing="8" px="5" pb="5">
        <Box>
          <Heading fontSize="sm" mb="2">
            The following will be removed:
          </Heading>
          <UnorderedList alignItems="flex-start" fontSize="sm">
            <ListItemCustom>Salesforce integration settings</ListItemCustom>
            <ListItemCustom>Contact field mappings</ListItemCustom>
            <ListItemCustom>Account field mappings</ListItemCustom>
            <ListItemCustom>Opportunity field mappings</ListItemCustom>
            <ListItemCustom>
              Custom object syncs, including settings and fields mappings
            </ListItemCustom>
          </UnorderedList>
        </Box>

        <Box>
          <Heading fontSize="sm" mb="2">
            The following will not be removed:
          </Heading>
          <UnorderedList fontSize="sm">
            <ListItemCustom iconSelector={true}>
              Inflection contacts created from Salesforce leads or contacts
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection companies created by Salesforce accounts
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection deals created by Salesforce opportunities
            </ListItemCustom>
            <ListItemCustom iconSelector={true}>
              Inflection property values added from Salesforce field mapping
            </ListItemCustom>
          </UnorderedList>
        </Box>
      </VStack>
    </IModal>
  );
}
