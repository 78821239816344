import MessageDisplay from "./MessageDisplay";
import { Box } from "@chakra-ui/react";
import { Logo } from "./Logo";

export default function MessageDisplayPage({
  ...props
}: {
  heading: string;
  displayMessage: string;
  redirectFn: () => void;
  redirectMsg: string;
  type: "success" | "error";
}) {
  return (
    <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Logo mx="auto" h="7" mb={{ base: "10", md: "20" }} />
        <MessageDisplay {...props} />
      </Box>
    </Box>
  );
}
