import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { removePersonLegacyApi } from "../../../../common/api/campaign/person";
import {
  handleTransactionStatus,
  isFulfilled,
  isIngestionTypeFlink,
} from "../../../../common/helper/commonHelper";
import IModal from "../../../../components/IModal";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  removePersonLegacy,
  removePerson,
  resetTransactionId,
  getPersonTransactionIdStatus,
} from "../personDbSlice";
import {
  usePreventRefresh,
  useExponentialPolling,
} from "../../../../common/hooks/commonHooks";
import { Box, Text, HStack, Icon } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";
import {
  removePersonApi,
  getPersonTransactionIdStatusApi,
} from "../../../../common/api/core-integrations/contact";
import { PreventNavigationModal } from "../../../../components/PreventNavigationModal";
import { toast } from "react-toastify";

function DeleteConfirmationModalContent({ email }: { email: string }) {
  return (
    <>
      <Text fontSize="14px" color="blackAlpha.700">
        Are you sure you want to delete this contact?
      </Text>
      <HStack my={3} color="brand.black" wordBreak="break-word">
        <Icon as={FaUser} />
        <Text>{email}</Text>
      </HStack>
      <Box
        mt={3}
        p={2}
        bgColor="gray.100"
        fontSize="14px"
        color="blackAlpha.900"
      >
        <Text fontWeight="600" mr={1}>
          Note:{" "}
          <Text as="span" fontWeight="400">
            Deleting the contact will only remove the contact's details. This
            will not remove the product data assoicated with the email address.
          </Text>
        </Text>
      </Box>
    </>
  );
}

export default function DeletePersonModal({
  personId,
  email,
  isOpen,
  onClose,
}: {
  personId: string;
  email: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deletingPerson, setDeletingPerson] = useState(false);
  const [polling, setPolling] = useState(false);

  const {
    contactIngestionEtl: { data: contactIngestionType },
  } = useSelector(selectFeatureFlag);

  usePreventRefresh({ preventRefresh: deletingPerson });

  async function deletePerson() {
    setDeletingPerson(true);
    if (isIngestionTypeFlink(contactIngestionType)) {
      const removePersonResponse = await dispatch(removePerson(personId));
      const successResponse = removePersonResponse.payload as Awaited<
        ReturnType<typeof removePersonApi>
      >;
      handleTransactionStatus(successResponse?.data?.status)
        .onDone(() => {
          closeWithResetData();
          navigate(urls.person);
        })
        .onPending(() => {
          setPolling(true);
        })
        .onDefault(() => {
          setDeletingPerson(false);
        });
    } else {
      const removePersonResponse = await dispatch(
        removePersonLegacy({ id: personId, email })
      );
      if (isFulfilled(removePersonResponse.meta.requestStatus)) {
        const { success } = removePersonResponse.payload as Awaited<
          ReturnType<typeof removePersonLegacyApi>
        >;
        if (success) {
          closeWithResetData();
          navigate(urls.person);
        }
      }
      setDeletingPerson(false);
    }
  }

  const closeWithResetData = useCallback(() => {
    setDeletingPerson(false);
    onClose();
    dispatch(resetTransactionId());
  }, [onClose, dispatch]);

  function closeIfNotLoading() {
    if (!deletingPerson) {
      closeWithResetData();
    }
  }

  const handlePollingFinish = useCallback(() => {
    setPolling(false);
    setDeletingPerson(false);
  }, []);

  const pollContactDeleteStatus = useCallback(async () => {
    const {
      meta: { requestStatus },
      payload,
    } = await dispatch(getPersonTransactionIdStatus());

    function handleDeletionFailure() {
      handlePollingFinish();
      toast.error("Contact deletion failed");
    }

    if (!isFulfilled(requestStatus)) {
      handleDeletionFailure();
      return;
    }

    const { data, errors } = payload as Awaited<
      ReturnType<typeof getPersonTransactionIdStatusApi>
    >;
    if (!!errors) {
      handleDeletionFailure();
      return;
    }

    handleTransactionStatus(data?.status)
      .onError(() => {
        handleDeletionFailure();
        return;
      })
      .onDone(async () => {
        handlePollingFinish();
        closeWithResetData();
        navigate(urls.person);
        toast.success("Contact deleted successfully");
      });
  }, [dispatch, handlePollingFinish, navigate, closeWithResetData]);

  useExponentialPolling({
    onPolling: pollContactDeleteStatus,
    shouldPoll: polling,
    initialWaitTime: 1,
  });

  return (
    <IModal
      isOpen={isOpen}
      onClose={closeIfNotLoading}
      header={{
        title: "Delete contact",
      }}
      deleteButton={{
        label: "Delete contact",
        props: {
          onClick: deletePerson,
          isLoading: deletingPerson,
          loadingText: "Deleting...",
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: closeIfNotLoading,
          hidden: deletingPerson,
        },
      }}
      hideCloseButton={deletingPerson}
    >
      <DeleteConfirmationModalContent email={email} />
      <PreventNavigationModal
        isActive={deletingPerson}
        cancelText="Cancel"
        confirmText="Confirm"
        children={
          <Text>
            If you navigate to another tab, contact deletion may fail.
          </Text>
        }
      />
    </IModal>
  );
}
