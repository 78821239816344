import { HStack, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaPencilAlt } from "react-icons/fa";
import PrimaryHeaderTitle from "./PrimaryHeaderTitle";

export default function EditableHeading({
  name,
  openModal,
  children,
  disableEditTooltip,
  isLoading,
}: {
  name: string;
  openModal: () => void;
  children?: ReactNode;
  disableEditTooltip?: string;
  isLoading?: boolean;
}) {
  return (
    <HStack
      role="editable-header"
      _hover={{
        "&>button": { visibility: "visible" },
      }}
      alignItems="center"
      wordBreak="break-all"
      onClick={disableEditTooltip || isLoading ? undefined : openModal}
      spacing={0}
      w="100%"
    >
      {children}
      <PrimaryHeaderTitle isLoading={isLoading}>{name}</PrimaryHeaderTitle>
      <Tooltip label={disableEditTooltip}>
        <IconButton
          variant="link"
          aria-label="Edit name"
          size="xs"
          name="edit-header-button"
          icon={<Icon fontSize="12px" as={FaPencilAlt} />}
          width="25px"
          visibility="hidden"
          onClick={openModal}
          alignSelf="center"
          isDisabled={!!disableEditTooltip}
          hidden={isLoading}
        />
      </Tooltip>
    </HStack>
  );
}
