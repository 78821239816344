import { Box, BoxProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export default function ContentContainer({
  children,
  ...props
}: {
  children: ReactNode;
} & BoxProps) {
  return (
    <Box rounded="md" p={{ base: "3", md: "5" }} bg="white" w="100%" {...props}>
      {children}
    </Box>
  );
}
