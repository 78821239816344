import { CAMPAIGN_CONTEXT } from "../../../../../common/types/campaign";
import CommonDrawer from "../../components/CommonDrawer";
import { useSelector } from "react-redux";
import { selectTrigger } from "../../trigger-campaign/triggerCampaignSlice";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { BsFillLightningChargeFill } from "react-icons/bs";
import TriggerCriteriaList from "../../TriggerCriteriaList";
import SectionWrapper, {
  SectionItem,
  SectionLink,
} from "./components/SectionWrapper";
import { useDisclosure } from "@chakra-ui/react";
import { NodeGroup } from "../../../../../common/types/dynamicList";
import { NEW_TRIGGER_GROUP } from "../../../../../common/constants/dynamicList";
import { Box } from "@chakra-ui/react";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";

export default function TriggerCriteriaSection({
  readonly,
  errorText,
  data,
  onChange,
  campaignContext,
  activeErrorCheck,
  validateOnClose,
}: {
  readonly: boolean;
  errorText: string;
  data: NodeGroup[];
  onChange: (data: NodeGroup[]) => void;
  campaignContext?: CAMPAIGN_CONTEXT;
  activeErrorCheck: boolean;
  validateOnClose: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onHandleClose = () => {
    if (activeErrorCheck) {
      validateOnClose();
    }
    onClose();
  };

  const onChangeNodeGroup = (data: NodeGroup) => {
    onChange([data]);
  };

  const {
    triggerCampaignDetails: { loading: isCampaignDetailsLoading },
  } = useSelector(selectTrigger);

  return (
    <>
      <SectionWrapper
        icon={BsFillLightningChargeFill}
        title="Trigger criteria"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading(isCampaignDetailsLoading)}
        isEmpty={!(data?.length && data[0].nodes.length)}
      >
        {data?.length && data[0].nodes.length > 0 ? (
          <SectionItem
            infoText="Qualify a contact if they perform any of the following events"
            errorText={activeErrorCheck ? errorText : ""}
          >
            <TriggerCriteriaList
              isReadOnly={true}
              onChange={onChangeNodeGroup}
              data={data?.[0] ?? NEW_TRIGGER_GROUP}
              campaignContext={campaignContext}
              activeErrorCheck={activeErrorCheck}
            ></TriggerCriteriaList>
          </SectionItem>
        ) : (
          <SectionLink
            onOpen={onOpen}
            title="Click to set up trigger criteria"
            LinkIcon={BsFillLightningChargeFill}
            errorText={errorText}
          />
        )}
      </SectionWrapper>
      <CommonDrawer
        title="Trigger criteria"
        isOpen={isOpen}
        onClose={onHandleClose}
        size="3xl"
      >
        <Box className={readonly ? DISABLED_EDIT_CLASSNAME : ""} w="100%">
          <TriggerCriteriaList
            onChange={onChangeNodeGroup}
            data={data?.[0] ?? NEW_TRIGGER_GROUP}
            campaignContext={campaignContext}
            activeErrorCheck={activeErrorCheck}
          ></TriggerCriteriaList>
        </Box>
      </CommonDrawer>
    </>
  );
}
