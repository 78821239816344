import ExportListAccordion from "../pages/dashboard/export/ExportListAccordion";
import LayoutWithSideNavBar from "../layout/LayoutWithSideNavBar";

export default function LayoutWithSharedComponents() {
  return (
    <>
      <LayoutWithSideNavBar />
      <ExportListAccordion />
    </>
  );
}
