import { integrationsRequest } from "./api";

export async function addFavouriteTagApi(
  tagId: string
): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/user_preference/tags/favourites.add",
    data: {
      tag_id: tagId,
    },
  });
}

export async function removeFavouriteTagApi(
  tagId: string
): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/user_preference/tags/favourites.remove",
    data: {
      tag_id: tagId,
    },
  });
}

export async function clearFavouriteTagApi(): Promise<{ status: boolean }> {
  return integrationsRequest({
    url: "/user_preference/tags/favourites.clear",
  });
}

export async function listFavouriteTagApi(): Promise<{
  tags: { favourites: string[] } | null;
}> {
  return integrationsRequest({
    url: "/user_preference/tags/favourites.get",
    shouldRetryApiCall: true,
  });
}
