import {
  Button,
  CenterProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { IconType } from "react-icons";
import {
  FLOW_ACTIONS,
  ADDITIONAL_STEP_ACTIONS,
} from "../../../../../common/constants/campaign";
import { AddFlow } from "../../../../../common/types/campaign";
import ITitle from "../../../../../components/ITitle";
import {
  SearchField,
  SEARCH_VARIANT,
} from "../../../../../components/SearchField";
import { useAppDispatch } from "../../../../../store";
import { addFlowAction, CampaignBuilderContext } from "../flowSlice";
import AdditionalActionOptionSteps from "./AdditionalActionOptionSteps";
import { WIDGET_OPTIONS, INIT_FLOW_NODE_ID } from "./constants";
import WidgetIcon from "./WidgetIcon";

const HIDDEN_FLOW_STEPS_IN_GROUP = [FLOW_ACTIONS.GROUP];
const HIDDEN_FLOW_STEPS_IN_MIDDLE = [FLOW_ACTIONS.GOTO];

function AddWidgetRow({
  onClick,
  title,
  iconDetails,
  color,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  title: string;
  iconDetails: {
    icon: IconType;
    iconProperties?: CenterProps;
  };
  color: string;
  isLoading: boolean;
  isDisabled: boolean;
}) {
  return (
    <Flex
      cursor="pointer"
      w="full"
      py={2}
      px={2}
      alignItems="center"
      onClick={onClick}
      _hover={{
        backgroundColor: "gray.200",
      }}
      borderRadius="sm"
      justifyContent="space-between"
    >
      <Button
        variant="ghost"
        isDisabled={isDisabled}
        p={0}
        h="fit-content"
        _hover={{
          bg: "transparent",
        }}
        leftIcon={
          <WidgetIcon
            color={color}
            icon={iconDetails.icon}
            {...iconDetails.iconProperties}
          />
        }
      >
        <Heading fontSize="sm">
          <ITitle title={title} capitalize={false} />
        </Heading>
      </Button>
      {isLoading && <Spinner height={4} width={4} />}
    </Flex>
  );
}

export default function AddWidgetMenu({
  isOpen,
  onClose,
  addAfterActionId,
  addInBranchId,
  addInGroup,
  isBetweenFlowSteps,
}: {
  isOpen: boolean;
  onClose: () => void;
  addAfterActionId: string;
  addInBranchId: string;
  addInGroup?: string;
  isBetweenFlowSteps?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { campaignId } = useContext(CampaignBuilderContext);

  const [searchText, setSearchText] = useState("");
  const [selectedActionType, setSelectedActionType] = useState<AddFlow | null>(
    null
  );

  const allowedWidgetOptions = useMemo(() => {
    return WIDGET_OPTIONS.reduce((filteredOptions, { label, actions }) => {
      const filteredActions = actions.filter((action) => {
        if (addInGroup && HIDDEN_FLOW_STEPS_IN_GROUP.includes(action.type))
          return false;
        if (
          isBetweenFlowSteps &&
          HIDDEN_FLOW_STEPS_IN_MIDDLE.includes(action.type)
        )
          return false;
        return true;
      });
      if (filteredActions.length) {
        return [...filteredOptions, { label, actions: filteredActions }];
      }
      return [...filteredOptions];
    }, [] as typeof WIDGET_OPTIONS);
  }, [addInGroup, isBetweenFlowSteps]);

  const filteredWidgetOptions = allowedWidgetOptions.reduce(
    (filteredOptions, { label, actions }) => {
      const query = searchText.toLowerCase();
      const filteredActions = actions.filter((action) =>
        action.label.toLowerCase().includes(query)
      );
      if (label.toLowerCase().includes(query)) {
        return [...filteredOptions, { label, actions }];
      } else if (filteredActions.length) {
        return [...filteredOptions, { label, actions: filteredActions }];
      }
      return [...filteredOptions];
    },
    [] as typeof WIDGET_OPTIONS
  );

  async function addWidget(actionType: FLOW_ACTIONS, addAfterActionId: string) {
    const data: AddFlow = {
      action_type: actionType,
      campaign_id: campaignId,
    };
    if (addAfterActionId !== INIT_FLOW_NODE_ID)
      data.add_after_action_id = addAfterActionId;
    data.parent_action_id = addInGroup;
    if (addInBranchId !== INIT_FLOW_NODE_ID) {
      data.branch_id = addInBranchId;
    }
    setSelectedActionType(data);
    if (!ADDITIONAL_STEP_ACTIONS.includes(actionType)) {
      await dispatch(addFlowAction(data));
      setSelectedActionType(null);
    }
    onCloseReset();
  }

  function onCloseReset() {
    setSearchText("");
    onClose();
  }

  return (
    <>
      <Drawer onClose={onCloseReset} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize="md">Add flow step</DrawerHeader>
          <DrawerBody pt={4} h="100%">
            <SearchField
              variant={SEARCH_VARIANT.SECONDARY}
              placeholder="Search actions"
              name="search-actions-input"
              value={searchText}
              onSearch={setSearchText}
            />
            <VStack
              alignItems="left"
              py={6}
              pl={1}
              spacing={4}
              maxH="calc(100% - 35px)"
              overflowY="auto"
            >
              {filteredWidgetOptions.map(({ label, actions }, index) => {
                return (
                  <VStack alignItems="left" key={index}>
                    <Heading
                      fontSize="md"
                      color="gray.500"
                      fontWeight="600"
                      ml={2}
                    >
                      {label}
                    </Heading>
                    {actions.map(
                      ({ type, label, icon, iconProperties, color }, index) => {
                        return (
                          <AddWidgetRow
                            onClick={() =>
                              addWidget(type as FLOW_ACTIONS, addAfterActionId)
                            }
                            title={label}
                            iconDetails={{
                              icon,
                              iconProperties,
                            }}
                            color={color}
                            key={index}
                            isLoading={type === selectedActionType?.action_type}
                            isDisabled={
                              !!selectedActionType &&
                              selectedActionType.action_type !== type
                            }
                          />
                        );
                      }
                    )}
                  </VStack>
                );
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <AdditionalActionOptionSteps
        onClose={() => setSelectedActionType(null)}
        flowStepDataForAdd={selectedActionType}
        isBetweenFlowSteps={isBetweenFlowSteps}
      />
    </>
  );
}
