import { Box } from "@chakra-ui/react";
import { ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function IDropzone({
  children,
  onSelectFile = () => {},
  disabled = false,
}: {
  children: ReactNode;
  onSelectFile?: (file: File[]) => void;
  disabled?: boolean;
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      !disabled && onSelectFile(acceptedFiles);
    },
    [disabled, onSelectFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { "text/csv": [".csv"] },
    disabled: disabled,
  });

  return (
    <Box
      {...getRootProps()}
      w="100%"
      mt="0 !important"
      cursor={disabled ? "not-allowed" : "pointer"}
    >
      <input {...getInputProps()} />
      {children}
    </Box>
  );
}
