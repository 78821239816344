import {
  Flex,
  Heading,
  VStack,
  Icon,
  Box,
  Badge,
  IconButton,
  Center,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  FaExclamationCircle,
  FaChevronRight,
  FaMinusCircle,
  FaArrowUp,
} from "react-icons/fa";
import { CUSTOM_TABLE } from "../../../../../common/constants/campaign";
import { TableLink } from "../../../../../common/types/connection";
import { formatErrorMsg } from "../helper";

export default function SelectedTableSidebar({
  selectedTables,
  activeTableId,
  fetchColumns,
  unlinkTable,
  invalidList,
  primaryKeyValid,
}: {
  selectedTables: TableLink[];
  activeTableId: number | null;
  fetchColumns: (selectedTable: TableLink) => void;
  unlinkTable: (tempId: number) => void;
  invalidList: number[];
  primaryKeyValid: boolean;
}) {
  return (
    <Flex w="400px" flexDir="column">
      <Heading p={4} fontSize={"sm"}>
        Linked tables
      </Heading>
      <VStack spacing={0} minH="500px" maxH="100%" overflow="auto">
        {selectedTables && selectedTables.length ? (
          selectedTables.map((table, index) => {
            const errorMsg = formatErrorMsg(
              table,
              invalidList,
              primaryKeyValid
            );
            return (
              <Flex
                key={index}
                minWidth="100%"
                maxWidth="100%"
                bg={table.tempId === activeTableId ? "blue.50" : ""}
                justifyContent="space-between"
                alignItems="center"
              >
                {errorMsg ? (
                  <Tooltip
                    hasArrow
                    label={errorMsg}
                    bg="red.500"
                    color="white"
                    placement="bottom-end"
                    height="100%"
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <Icon
                        fontSize="0.9rem"
                        ml="2"
                        as={FaExclamationCircle}
                        color="red.500"
                      />
                    </Flex>
                  </Tooltip>
                ) : (
                  <Box w="0.9rem" ml="2"></Box>
                )}
                <Flex
                  cursor="pointer"
                  alignItems="center"
                  py={2}
                  pr={3}
                  pl={2}
                  onClick={() => fetchColumns(table)}
                  height="100%"
                  color={errorMsg && "red.500"}
                >
                  <Text isTruncated minW="105px" maxW="70px">
                    {table.table_name}
                  </Text>
                  <Icon mx="1" fontSize="xs" as={FaChevronRight} />
                  <Text isTruncated minW="105px" maxW="70px">
                    {table.destination_table_name}
                  </Text>
                </Flex>

                <Flex py={2} px={4} alignItems="center">
                  {table.destination_table_name === CUSTOM_TABLE ? (
                    <Badge fontSize="10px" mr="5" colorScheme="blue">
                      Custom
                    </Badge>
                  ) : (
                    <Badge fontSize="10px" mr="5" bg="brand.blue" color="white">
                      Default
                    </Badge>
                  )}

                  {table.disabled ? (
                    <Box w="15px"></Box>
                  ) : (
                    <IconButton
                      variant="ghost"
                      aria-label="Remove table"
                      cursor="pointer"
                      name="remove-table"
                      onClick={() => unlinkTable(table.tempId)}
                      title="Remove table"
                      color="red.300"
                      size="0.9rem"
                      icon={<FaMinusCircle />}
                    />
                  )}
                </Flex>
              </Flex>
            );
          })
        ) : (
          <Center h="200px">
            <VStack color="gray.400">
              <Icon mr="3" as={FaArrowUp}></Icon>
              <Text fontSize="sm"> Add tables to sync from above </Text>
            </VStack>
          </Center>
        )}
      </VStack>
    </Flex>
  );
}
