import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { WIDGET_CONTAINER_CLASS } from "./constants";
import { FlowStepSourceHandle } from "./FlowHandle";
import {
  addSuffixForPlural,
  getPersonLabel,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import { useContext, useMemo } from "react";
import { CampaignBuilderContext, selectFlow } from "../flowSlice";
import ISkeleton, {
  SKELETON_VARIANT,
} from "../../../../../components/ISkeleton";

export default function DynamicListCount() {
  const {
    reports: {
      data: { nodeEdgeCount },
    },
  } = useSelector(selectFlow);

  const { campaignContext } = useContext(CampaignBuilderContext);

  const contactsEnteredJourneyText = useMemo(() => {
    const contactsEnteredJourney = nodeEdgeCount.data?.qualified_users ?? 0;
    return `${contactsEnteredJourney} ${addSuffixForPlural(
      getPersonLabel(campaignContext),
      contactsEnteredJourney
    )} ${contactsEnteredJourney > 1 ? "have" : "has"} entered the journey`;
  }, [nodeEdgeCount, campaignContext]);

  return (
    <Flex
      w="sm"
      alignItems="center"
      flexDir="column"
      className={WIDGET_CONTAINER_CLASS}
    >
      <Flex
        alignItems="center"
        backgroundColor="white"
        borderRadius="md"
        boxShadow="md"
        p={2}
      >
        <Icon as={FaUserCircle} fontSize="md" mr="2" />
        <ISkeleton
          mt="0"
          isLoaded={!isLoading(nodeEdgeCount.loading)}
          noOfLines={1}
          skeletonHeight={5}
          variant={SKELETON_VARIANT.TEXT}
        >
          <Text fontSize="sm" color="gray.500" fontWeight="bold">
            {contactsEnteredJourneyText}
          </Text>
        </ISkeleton>
      </Flex>
      <FlowStepSourceHandle />
    </Flex>
  );
}
