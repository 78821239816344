import { Flex, Box } from "@chakra-ui/react";
import EmailConfigureInput from "../../../components/EmailConfigureInput";
import { gutter } from "../../../components/codemirror/codemirrorModules";
import { EditorView } from "@codemirror/view";
import CodemirrorInput from "../../../components/codemirror/CodemirrorInput";
import { EmailConfigurationType } from "../../../common/types/campaign";
import {
  CcBccListType,
  EmailConfigErrors,
  EmailConfigOnChange,
  SenderMeta,
} from "../../../common/types/template";

export default function EmailHtmlEditor({
  inputs,
  template,
  errors,
  handleChange,
  setTemplate,
  globalBccEmailData,
}: {
  inputs: { subject: string } & EmailConfigurationType & SenderMeta;
  errors: EmailConfigErrors;
  handleChange: EmailConfigOnChange;
  template: string;
  setTemplate: (isDirty: boolean, code: string) => void;
  globalBccEmailData?: CcBccListType;
}) {
  return (
    <Flex flexDir="row" h="calc(100vh - 77px)">
      <EmailConfigureInput
        inputs={inputs}
        onChange={handleChange}
        errors={errors}
        readOnlyGlobalBccData={globalBccEmailData}
        width="30%"
        inputPaddingX="2"
        px={1}
        py={2}
      />
      <Box width="70%" px="5px">
        <CodemirrorInput
          value={template}
          onChange={(code: string) => setTemplate(true, code)}
          extensions={[gutter.lineNumbers(), EditorView.lineWrapping]}
          defaultVariables={true}
        />
      </Box>
    </Flex>
  );
}
