import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LOADING_STATES,
  INITIAL_PAGINATION,
} from "../../../../common/constants/common";
import { initializeLoadingData } from "../../../../common/helper/commonHelper";
import {
  DateTimeFilterPagintation,
  LoadingWithData,
  PaginationType,
} from "../../../../common/types/common";
import {
  SalesforceConnection,
  SalesforceSyncRun,
  SalesforceSyncError,
  SalesforceSyncHistoryDetails,
  ErrorType,
  SalesforceSyncErrorQuery,
  InclusionList,
  ObjectCreateRule,
  ObjectDeleteRule,
  ObjectSettings,
  SALESFORCE_RECORD_CREATE,
  SALESFORCE_RECORD_DELETE,
  SetupCompleteData,
  SfResyncArchiveDataType,
  SyncSettings,
} from "../../../../common/types/salesforceLegacyService";
import { RootState } from "../../../../store";
import {
  connectSalesforceApi,
  createSalesforceConnectionApi,
  getSalesforceConnectionDetailsApi,
  listSalesforceSyncRunsApi,
  getSalesforceLoginUrlApi,
  updateSalesforceSettingsApi,
  recheckSalesforcePermissionApi,
  getSyncHistoryDetailsApi,
  pauseSalesforceSyncApi,
  resumeSalesforceSyncApi,
  deleteSalesforceConnectionApi,
  updateSalesforceObjectsApi,
  getApiUsageCountApi,
  setupCompleteApi,
  updateInclusionListApi,
  updateSelectiveSyncApi,
  updateSalesforceRecordsCreateUpdateSettingApi,
  updateSalesforceRecordsDeleteSettingApi,
  updateInflectionRecordsDeleteSettingApi,
  updateInflectionRecordsCreateUpdateSettingApi,
  startSalesforceSyncApi,
  listSalesforceSyncErrorsTypesApi,
  listSalesforceSyncErrorsApi,
  getSalesforceErrorQueryApi,
  archiveSalesforceSyncErrorApi,
  resyncSalesforceSyncErrorApi,
  removeInclusionListApi,
  getSalesforceConnectionListApi,
  getSalesforceObjectsApi,
} from "../../../../common/api/integrations/salesforceLegacyService";
import { expireApiStatus } from "../../../../common/slices/apiStatusSlice";
import {
  SelectiveSync,
  SalesforceConnectionDetailsDict,
} from "../../../../common/types/unifiedMapping";
import { CONNECTION_LIST_ALL_ACTION } from "../connectionSlice";
import { getSalesforceCampaignSyncErrorsApi } from "../../../../common/api/salesforceIntegrations/campaign";
import { toast } from "react-toastify";
import { SfCampaignSyncError } from "../../../../common/types/salesforce";

interface ErrorPayload {
  type: string;
  data: { code: number; description: string; exception: string };
}

interface SalesforceInitState {
  connectionList: LoadingWithData<SalesforceConnection[]>;
  creatingSalesforceConnection: LOADING_STATES;
  fetchingConnection: LOADING_STATES;
  connection: SalesforceConnection | null;
  loginUrl: LoadingWithData<string>;
  connecting: LOADING_STATES;
  savingSyncSettings: LOADING_STATES;
  savingObjectSettings: LOADING_STATES;
  fetchingErrorList: LOADING_STATES;
  syncRuns: PaginationType<SalesforceSyncRun>;
  campaignSyncErrors: SfCampaignSyncError[] | null;
  syncErrors: { [key: string]: PaginationType<SalesforceSyncError> };
  recheckingPermissions: LOADING_STATES;
  syncHistoryDetails: LoadingWithData<SalesforceSyncHistoryDetails | null>;
  pausingSync: LOADING_STATES;
  resumingSync: LOADING_STATES;
  startedSync: LOADING_STATES;
  deletingConnection: LOADING_STATES;
  apiUsageCount: LoadingWithData<number | null>;
  savingSetup: LOADING_STATES;
  savingSelectiveSync: LOADING_STATES;
  savingInclusionList: LOADING_STATES;
  savingRecordCreateSettings: LOADING_STATES;
  savingRecordDeleteSettings: LOADING_STATES;
  savingInflectionRecordCreateSettings: LOADING_STATES;
  savingInflectionRecordDeleteSettings: LOADING_STATES;
  sfErrorTypes: LoadingWithData<ErrorType[]>;
  sfAffectedRecords: PaginationType<SalesforceSyncErrorQuery>;
  archivedRecordCount: number;
  archivingSalesforceSyncError: LOADING_STATES;
  isResyncSuccess: boolean;
  resyncingSalesforceSyncError: LOADING_STATES;
  salesforceConnections: SalesforceConnectionDetailsDict;
}

const initialState: SalesforceInitState = {
  connectionList: initializeLoadingData([]),
  creatingSalesforceConnection: LOADING_STATES.INIT,
  fetchingConnection: LOADING_STATES.INIT,
  connection: null,
  loginUrl: initializeLoadingData(""),
  connecting: LOADING_STATES.INIT,

  savingSyncSettings: LOADING_STATES.INIT,
  savingObjectSettings: LOADING_STATES.INIT,
  fetchingErrorList: LOADING_STATES.INIT,
  syncRuns: INITIAL_PAGINATION,
  campaignSyncErrors: [],
  syncErrors: {},
  recheckingPermissions: LOADING_STATES.INIT,
  syncHistoryDetails: initializeLoadingData(null),
  pausingSync: LOADING_STATES.INIT,
  resumingSync: LOADING_STATES.INIT,
  startedSync: LOADING_STATES.INIT,
  deletingConnection: LOADING_STATES.INIT,
  apiUsageCount: initializeLoadingData(null),
  savingSetup: LOADING_STATES.INIT,
  savingSelectiveSync: LOADING_STATES.INIT,
  savingInclusionList: LOADING_STATES.INIT,
  savingRecordCreateSettings: LOADING_STATES.INIT,
  savingRecordDeleteSettings: LOADING_STATES.INIT,
  savingInflectionRecordCreateSettings: LOADING_STATES.INIT,
  savingInflectionRecordDeleteSettings: LOADING_STATES.INIT,
  sfErrorTypes: initializeLoadingData([]),
  sfAffectedRecords: INITIAL_PAGINATION,
  archivedRecordCount: 0,
  archivingSalesforceSyncError: LOADING_STATES.INIT,
  isResyncSuccess: false,
  resyncingSalesforceSyncError: LOADING_STATES.INIT,
  salesforceConnections: {},
};

export const createSalesforceConnection = createAsyncThunk(
  "connection/create-salesforce-connection",
  async (name: string, { dispatch }) => {
    const response = createSalesforceConnectionApi(name);
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return response;
  }
);

export const getSalesforceLoginUrl = createAsyncThunk(
  "connection/get-salesforce-login-url",
  async () => {
    return await getSalesforceLoginUrlApi();
  }
);

export const connectSalesforce = createAsyncThunk(
  "connection/connect-salesforce",
  async ({ code, connectionId }: { code: string; connectionId: string }) => {
    return await connectSalesforceApi(code, connectionId);
  }
);

export const getSalesforceConnectionList = createAsyncThunk(
  "connection/get-salesforce-connection-list",
  async () => {
    return await getSalesforceConnectionListApi();
  }
);

export const getSalesforceConnectionDetails = createAsyncThunk(
  "connection/get-salesforce-connection-details",
  async (connectionId: string) => {
    return await getSalesforceConnectionDetailsApi(connectionId);
  }
);

export const getSyncHistoryDetails = createAsyncThunk(
  "connection/get-salesforce-sync-history-details",
  async (connectionId: string) => {
    return await getSyncHistoryDetailsApi(connectionId);
  }
);

export const updateSalesforceSettings = createAsyncThunk(
  "connection/update-salesforce-settings",
  async (data: SyncSettings, thunkApi) => {
    const { salesforceLegacy } = thunkApi.getState() as RootState;
    return await updateSalesforceSettingsApi(
      data,
      salesforceLegacy.connection?.connection_id || ""
    );
  }
);

export const updateSalesforceObjects = createAsyncThunk(
  "connection/update-salesforce-objects",
  async (
    { data, toast }: { data: ObjectSettings; toast?: boolean },
    { getState }
  ) => {
    const { salesforceLegacy } = getState() as RootState;
    return await updateSalesforceObjectsApi(
      data,
      salesforceLegacy.connection?.connection_id || ""
    );
  },
  {
    condition: (_, { getState }) => {
      const { salesforceLegacy } = getState() as RootState;

      if (!salesforceLegacy.connection?.connection_id) {
        return false;
      }
    },
  }
);

export const listSalesforceSyncRuns = createAsyncThunk(
  "connection/get-salesforce-connection-run-list",
  async (
    data: {
      connectionId: string;
      shouldIncludeEmptyRuns: boolean;
    } & DateTimeFilterPagintation
  ) => {
    return await listSalesforceSyncRunsApi(data);
  }
);

export const recheckSalesforcePermission = createAsyncThunk(
  "connection/recheck-salesforce-permission",
  async (_, thunkApi) => {
    const {
      salesforceLegacy: { connection },
    } = thunkApi.getState() as RootState;
    return await recheckSalesforcePermissionApi(
      connection?.connection_id ?? ""
    );
  }
);

export const pauseSalesforceSync = createAsyncThunk(
  "connection/pause-salesforce-sync",
  async (_, thunkApi) => {
    const {
      salesforceLegacy: { connection },
    } = thunkApi.getState() as RootState;
    return await pauseSalesforceSyncApi(connection?.connection_id ?? "");
  }
);

export const resumeSalesforceSync = createAsyncThunk(
  "connection/resume-salesforce-sync",
  async (_, thunkApi) => {
    const {
      salesforceLegacy: { connection },
    } = thunkApi.getState() as RootState;
    return await resumeSalesforceSyncApi(connection?.connection_id ?? "");
  }
);

export const deleteSalesforceConnection = createAsyncThunk(
  "connection/delete-salesforce-connection",
  async (_, { getState, dispatch }) => {
    const {
      salesforceLegacy: { connection },
    } = getState() as RootState;
    const response = await deleteSalesforceConnectionApi(
      connection?.connection_id ?? ""
    );
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return response;
  }
);

export const getSalesforceApiUsageCount = createAsyncThunk(
  "connection/get-salesforce-api-usage-count",
  async (connectionId: string) => {
    return await getApiUsageCountApi(connectionId);
  }
);

export const updateInclusionList = createAsyncThunk(
  "connection/inclusion-list-update",
  async (data: { connectionId: string; inclusionList: InclusionList }) => {
    return await updateInclusionListApi(data);
  }
);

export const removeInclusionList = createAsyncThunk(
  "connection/inclusion-list-remove",
  async (connectionId: string) => {
    return await removeInclusionListApi(connectionId);
  }
);

export const updateSelectiveSync = createAsyncThunk(
  "connection/selective-sync-update",
  async (data: {
    connectionId: string;
    selectiveSync: Partial<SelectiveSync>;
  }) => {
    return await updateSelectiveSyncApi(data);
  }
);

export const updateSalesforceRecordCreate = createAsyncThunk(
  "connection/salesforce-records-create-update",
  async (data: {
    connectionId: string;
    salesforceRecordCreateUpdate: SALESFORCE_RECORD_CREATE;
  }) => {
    return await updateSalesforceRecordsCreateUpdateSettingApi(data);
  }
);

export const updateSalesforceRecordDelete = createAsyncThunk(
  "connection/salesforce-records-delete",
  async (data: {
    connectionId: string;
    salesforceRecordDelete: SALESFORCE_RECORD_DELETE;
  }) => {
    return await updateSalesforceRecordsDeleteSettingApi(data);
  }
);

export const updateInflectionRecordCreate = createAsyncThunk(
  "connection/inflection-records-create-update",
  async (data: {
    connectionId: string;
    objectCreate: Partial<ObjectCreateRule>;
  }) => {
    return await updateInflectionRecordsCreateUpdateSettingApi(data);
  }
);

export const updateInflectionRecordDelete = createAsyncThunk(
  "connection/inflection-records-delete",
  async (data: {
    connectionId: string;
    objectDelete: Partial<ObjectDeleteRule>;
  }) => {
    return await updateInflectionRecordsDeleteSettingApi(data);
  }
);

export const listSalesforceSyncErrors = createAsyncThunk(
  "connection/inflection-salesforce-sync-errors",
  async (
    data: {
      connectionId: string;
      errorType: string;
    } & DateTimeFilterPagintation
  ) => {
    return await listSalesforceSyncErrorsApi(data);
  }
);

export const listSalesforceSyncErrorsTypes = createAsyncThunk(
  "connection/inflection-salesforce-sync-errors-types",
  async () => {
    return await listSalesforceSyncErrorsTypesApi();
  }
);

export const startSalesforceSync = createAsyncThunk(
  "connection/salesforce-sync-start",
  async (connectionId: string) => {
    return await startSalesforceSyncApi(connectionId);
  }
);

export const getSalesforceCampaignSyncErrors = createAsyncThunk(
  "connection/get-salesforce-campaign-sync-errors",
  async ({
    startTimestamp,
    endTimestamp,
  }: {
    startTimestamp: number;
    endTimestamp: number;
  }) => {
    return await getSalesforceCampaignSyncErrorsApi(
      startTimestamp,
      endTimestamp
    );
  }
);

export const getSalesforceErrorQuery = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-query",
  async (data: {
    errorClass: string;
    objectEmail?: string;
    pageNo?: number;
    pageSize?: number;
    keyword?: string;
  }) => {
    return await getSalesforceErrorQueryApi(data);
  }
);

export const archiveSalesforceSyncError = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-archive",
  async (data: SfResyncArchiveDataType) => {
    return await archiveSalesforceSyncErrorApi(data);
  }
);

export const resyncSalesforceSyncError = createAsyncThunk(
  "connection/inflection-salesforce-sync-error-resync",
  async (data: SfResyncArchiveDataType) => {
    return await resyncSalesforceSyncErrorApi(data);
  }
);

export const completeSetup = createAsyncThunk(
  "connection/setup-complete",
  async (data: SetupCompleteData) => {
    return await setupCompleteApi(data);
  }
);

export const getSalesforceObjects = createAsyncThunk(
  "connection/get-all-sf-objects",
  async (connectionId: string, thunkApi) => {
    try {
      return await getSalesforceObjectsApi(connectionId);
    } catch (err: any) {
      // TODO - make type 'unknown' here and fix type error
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue({ data: err.response.data });
    }
  }
);

const salesforceLegacySlice = createSlice({
  name: "salesforceLegacy",
  initialState,
  reducers: {
    setSalesforceErrorModalPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.sfAffectedRecords.totalPageCount ?? 1)) {
        state.sfAffectedRecords.currentPageNo = pageNo;
        state.sfAffectedRecords.changingPage = true;
      } else {
        state.sfAffectedRecords.currentPageNo = 1;
      }
    },
    setSalesforceSyncHistoryPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.syncRuns.totalPageCount ?? 1)) {
        state.syncRuns.currentPageNo = pageNo;
        state.syncRuns.changingPage = true;
      }
    },
    resetSalesforceData(state) {
      Object.assign(state, initialState);
    },
    resetSalesforceAffectedRecords(state) {
      state.sfAffectedRecords = INITIAL_PAGINATION;
    },
  },
  extraReducers: (builder) => {
    builder
      // create salesforce connection
      .addCase(createSalesforceConnection.pending, (state) => {
        state.creatingSalesforceConnection = LOADING_STATES.LOADING;
      })
      .addCase(createSalesforceConnection.fulfilled, (state, action) => {
        state.creatingSalesforceConnection = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
      })
      .addCase(createSalesforceConnection.rejected, (state) => {
        state.creatingSalesforceConnection = LOADING_STATES.FAILED;
      })

      // get salesforce login url
      .addCase(getSalesforceLoginUrl.pending, (state) => {
        state.loginUrl = {
          data: "",
          loading: LOADING_STATES.LOADING,
        };
      })
      .addCase(getSalesforceLoginUrl.fulfilled, (state, action) => {
        state.loginUrl = {
          data: action.payload.url,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getSalesforceLoginUrl.rejected, (state) => {
        state.loginUrl = {
          data: "",
          loading: LOADING_STATES.FAILED,
        };
      })

      // salesforce ouath login
      .addCase(connectSalesforce.pending, (state) => {
        state.connecting = LOADING_STATES.LOADING;
      })
      .addCase(connectSalesforce.fulfilled, (state, action) => {
        state.connecting = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
      })
      .addCase(connectSalesforce.rejected, (state) => {
        state.connecting = LOADING_STATES.FAILED;
      })

      // salesforce get details
      .addCase(getSalesforceConnectionDetails.pending, (state, { meta }) => {
        state.fetchingConnection = LOADING_STATES.LOADING;
        state.salesforceConnections[meta.arg] = {
          ...state.salesforceConnections[meta.arg],
          loading: LOADING_STATES.LOADING,
        };
      })
      .addCase(
        getSalesforceConnectionDetails.fulfilled,
        (state, { payload, meta }) => {
          state.fetchingConnection = LOADING_STATES.SUCCESS;
          state.connection = payload.connection;
          state.salesforceConnections[meta.arg] = {
            ...state.salesforceConnections[meta.arg],
            loading: LOADING_STATES.SUCCESS,
            details: payload.connection,
          };
        }
      )
      .addCase(getSalesforceConnectionDetails.rejected, (state, { meta }) => {
        state.fetchingConnection = LOADING_STATES.FAILED;
        state.salesforceConnections[meta.arg] = {
          ...state.salesforceConnections[meta.arg],
          loading: LOADING_STATES.FAILED,
        };
      })

      // update settings
      .addCase(updateSalesforceSettings.pending, (state) => {
        state.savingSyncSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceSettings.fulfilled, (state, action) => {
        state.savingSyncSettings = LOADING_STATES.SUCCESS;
        if (state.connection) {
          state.connection = action.payload.connection;
          toast.success("Sync updated successfully");
        }
      })
      .addCase(updateSalesforceSettings.rejected, (state) => {
        state.savingSyncSettings = LOADING_STATES.FAILED;
      })

      // update objects
      .addCase(updateSalesforceObjects.pending, (state) => {
        state.savingObjectSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceObjects.fulfilled, (state, action) => {
        state.savingObjectSettings = LOADING_STATES.SUCCESS;
        if (state.connection) {
          state.connection = action.payload.connection;
        }
        if (action.meta.arg.toast) {
          toast.success("Settings updated successfully");
        }
      })
      .addCase(updateSalesforceObjects.rejected, (state) => {
        state.savingObjectSettings = LOADING_STATES.FAILED;
      })

      // error list
      .addCase(listSalesforceSyncRuns.pending, (state) => {
        state.syncRuns.fetchingList = true;
      })
      .addCase(listSalesforceSyncRuns.fulfilled, (state, action) => {
        state.syncRuns.fetchingList = false;
        state.syncRuns.changingPage = false;
        state.syncRuns.list = action.payload.records;
        state.syncRuns.totalPageCount = action.payload.page_count;
        state.syncRuns.count = action.payload.record_count;
      })
      .addCase(listSalesforceSyncRuns.rejected, (state) => {
        state.syncRuns.fetchingList = false;
        state.syncRuns.changingPage = false;
      })

      // Recheck permissions
      .addCase(recheckSalesforcePermission.pending, (state) => {
        state.recheckingPermissions = LOADING_STATES.LOADING;
      })
      .addCase(recheckSalesforcePermission.fulfilled, (state, action) => {
        state.recheckingPermissions = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
      })
      .addCase(recheckSalesforcePermission.rejected, (state) => {
        state.recheckingPermissions = LOADING_STATES.FAILED;
      })

      // Get sync history details
      .addCase(getSyncHistoryDetails.pending, (state) => {
        state.syncHistoryDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSyncHistoryDetails.fulfilled, (state, action) => {
        state.syncHistoryDetails.loading = LOADING_STATES.SUCCESS;
        state.syncHistoryDetails.data = action.payload;
      })
      .addCase(getSyncHistoryDetails.rejected, (state) => {
        state.syncHistoryDetails.loading = LOADING_STATES.FAILED;
      })

      // Pause sync
      .addCase(pauseSalesforceSync.pending, (state) => {
        state.pausingSync = LOADING_STATES.LOADING;
      })
      .addCase(pauseSalesforceSync.fulfilled, (state, action) => {
        state.pausingSync = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
      })
      .addCase(pauseSalesforceSync.rejected, (state) => {
        state.pausingSync = LOADING_STATES.FAILED;
      })

      // Resume sync
      .addCase(resumeSalesforceSync.pending, (state) => {
        state.resumingSync = LOADING_STATES.LOADING;
      })
      .addCase(resumeSalesforceSync.fulfilled, (state, action) => {
        state.resumingSync = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Sync resumed successfully");
      })
      .addCase(resumeSalesforceSync.rejected, (state) => {
        state.resumingSync = LOADING_STATES.FAILED;
      })

      // Delete connection
      .addCase(deleteSalesforceConnection.pending, (state) => {
        state.deletingConnection = LOADING_STATES.LOADING;
      })
      .addCase(deleteSalesforceConnection.fulfilled, (state, action) => {
        if (action.payload.connection) {
          state.deletingConnection = LOADING_STATES.SUCCESS;
          toast.success("Connection deleted successfully");
        } else {
          state.deletingConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteSalesforceConnection.rejected, (state) => {
        state.deletingConnection = LOADING_STATES.FAILED;
      })

      //Get Salesforce Api Usage Count
      .addCase(getSalesforceApiUsageCount.pending, (state) => {
        state.apiUsageCount.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceApiUsageCount.fulfilled, (state, action) => {
        state.apiUsageCount.loading = LOADING_STATES.SUCCESS;
        state.apiUsageCount.data = action.payload.api_usage_count;
      })
      .addCase(getSalesforceApiUsageCount.rejected, (state) => {
        state.apiUsageCount.loading = LOADING_STATES.FAILED;
      })
      .addCase(completeSetup.pending, (state) => {
        state.savingSetup = LOADING_STATES.LOADING;
      })
      .addCase(completeSetup.fulfilled, (state, action) => {
        state.savingSetup = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Setup completed successfully");
      })
      .addCase(completeSetup.rejected, (state) => {
        state.savingSetup = LOADING_STATES.FAILED;
      })

      .addCase(updateInclusionList.pending, (state) => {
        state.savingInclusionList = LOADING_STATES.LOADING;
      })
      .addCase(updateInclusionList.fulfilled, (state, action) => {
        state.savingInclusionList = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Updated inclusion list successfully");
      })
      .addCase(updateInclusionList.rejected, (state) => {
        state.savingInclusionList = LOADING_STATES.FAILED;
      })

      .addCase(removeInclusionList.pending, (state) => {
        state.savingInclusionList = LOADING_STATES.LOADING;
      })
      .addCase(removeInclusionList.fulfilled, (state, action) => {
        state.savingInclusionList = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Removed inclusion list successfully");
      })
      .addCase(removeInclusionList.rejected, (state) => {
        state.savingInclusionList = LOADING_STATES.FAILED;
      })

      .addCase(updateSalesforceRecordCreate.pending, (state) => {
        state.savingRecordCreateSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceRecordCreate.fulfilled, (state, action) => {
        state.savingRecordCreateSettings = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateSalesforceRecordCreate.rejected, (state) => {
        state.savingRecordCreateSettings = LOADING_STATES.FAILED;
      })

      .addCase(updateSalesforceRecordDelete.pending, (state) => {
        state.savingRecordDeleteSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceRecordDelete.fulfilled, (state, action) => {
        state.savingRecordDeleteSettings = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateSalesforceRecordDelete.rejected, (state) => {
        state.savingRecordDeleteSettings = LOADING_STATES.FAILED;
      })

      .addCase(updateInflectionRecordCreate.pending, (state) => {
        state.savingInflectionRecordCreateSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateInflectionRecordCreate.fulfilled, (state, action) => {
        state.savingInflectionRecordCreateSettings = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateInflectionRecordCreate.rejected, (state) => {
        state.savingInflectionRecordCreateSettings = LOADING_STATES.FAILED;
      })

      .addCase(updateInflectionRecordDelete.pending, (state) => {
        state.savingInflectionRecordDeleteSettings = LOADING_STATES.LOADING;
      })
      .addCase(updateInflectionRecordDelete.fulfilled, (state, action) => {
        state.savingInflectionRecordDeleteSettings = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Updated settings successfully");
      })
      .addCase(updateInflectionRecordDelete.rejected, (state) => {
        state.savingInflectionRecordDeleteSettings = LOADING_STATES.FAILED;
      })

      //Salesforce Sync errors
      .addCase(listSalesforceSyncErrors.pending, (state) => {
        state.fetchingErrorList = LOADING_STATES.LOADING;
      })
      .addCase(listSalesforceSyncErrors.fulfilled, (state, action) => {
        state.fetchingErrorList = LOADING_STATES.SUCCESS;
        state.syncErrors[action.meta.arg.errorType] = {
          ...state.syncErrors[action.meta.arg.errorType],
          ...{
            list: action.payload.records,
            totalPageCount: action.payload.page_count,
            count: action.payload.record_count,
            currentPageNo: action.meta.arg.pageNumber,
            pageSize: action.meta.arg.pageSize,
          },
        };
      })
      .addCase(listSalesforceSyncErrors.rejected, (state) => {
        state.fetchingErrorList = LOADING_STATES.FAILED;
      })

      //Salesforce Sync error types
      .addCase(listSalesforceSyncErrorsTypes.pending, (state) => {
        state.sfErrorTypes.loading = LOADING_STATES.LOADING;
      })
      .addCase(listSalesforceSyncErrorsTypes.fulfilled, (state, action) => {
        state.sfErrorTypes.loading = LOADING_STATES.SUCCESS;
        state.sfErrorTypes.data = action.payload.error_types;
      })
      .addCase(listSalesforceSyncErrorsTypes.rejected, (state) => {
        state.sfErrorTypes.loading = LOADING_STATES.FAILED;
      })

      //Salesforce Start
      .addCase(startSalesforceSync.pending, (state) => {
        state.startedSync = LOADING_STATES.LOADING;
      })
      .addCase(startSalesforceSync.fulfilled, (state, action) => {
        state.startedSync = LOADING_STATES.SUCCESS;
        state.connection = action.payload.connection;
        toast.success("Sync started successfully");
      })
      .addCase(startSalesforceSync.rejected, (state) => {
        state.startedSync = LOADING_STATES.FAILED;
      })

      .addCase(getSalesforceErrorQuery.pending, (state) => {
        state.sfAffectedRecords.fetchingList = true;
      })
      .addCase(getSalesforceErrorQuery.fulfilled, (state, action) => {
        state.sfAffectedRecords.list = action.payload.errors.records;
        state.sfAffectedRecords.totalPageCount =
          action.payload.errors.page_count;
        state.sfAffectedRecords.count = action.payload.errors.record_count;
        state.sfAffectedRecords.fetchingList = false;
        state.sfAffectedRecords.changingPage = false;
      })
      .addCase(getSalesforceErrorQuery.rejected, (state) => {
        state.sfAffectedRecords.fetchingList = false;
        state.sfAffectedRecords.totalPageCount = 0;
        state.sfAffectedRecords.changingPage = false;
      })

      .addCase(archiveSalesforceSyncError.pending, (state) => {
        state.archivingSalesforceSyncError = LOADING_STATES.LOADING;
      })
      .addCase(archiveSalesforceSyncError.fulfilled, (state, action) => {
        state.archivingSalesforceSyncError = LOADING_STATES.SUCCESS;
        state.archivedRecordCount = action.payload.updated_count;
        toast.success("Errors successfully archived");
      })
      .addCase(archiveSalesforceSyncError.rejected, (state) => {
        state.archivingSalesforceSyncError = LOADING_STATES.FAILED;
      })

      .addCase(resyncSalesforceSyncError.pending, (state) => {
        state.resyncingSalesforceSyncError = LOADING_STATES.LOADING;
      })
      .addCase(resyncSalesforceSyncError.fulfilled, (state, action) => {
        state.resyncingSalesforceSyncError = LOADING_STATES.SUCCESS;
        state.isResyncSuccess = action.payload.success;
        toast.success("Contacts successfully resynced");
      })
      .addCase(resyncSalesforceSyncError.rejected, (state) => {
        state.resyncingSalesforceSyncError = LOADING_STATES.FAILED;
      })
      .addCase(updateSelectiveSync.pending, (state) => {
        state.savingSelectiveSync = LOADING_STATES.LOADING;
      })
      .addCase(updateSelectiveSync.fulfilled, (state, action) => {
        state.savingSelectiveSync = LOADING_STATES.SUCCESS;
        if (state.connection) {
          state.connection.selective_sync =
            action.payload.connection.selective_sync;
        }
        toast.success("Updated sync successfully");
      })
      .addCase(updateSelectiveSync.rejected, (state) => {
        state.savingSelectiveSync = LOADING_STATES.FAILED;
      })

      // List Salesforce connections
      .addCase(getSalesforceConnectionList.pending, (state) => {
        state.connectionList = {
          loading: LOADING_STATES.LOADING,
          data: [],
        };
      })
      .addCase(getSalesforceConnectionList.fulfilled, (state, action) => {
        state.connectionList = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.records,
        };
      })
      .addCase(getSalesforceConnectionList.rejected, (state) => {
        state.connectionList = {
          loading: LOADING_STATES.FAILED,
          data: [],
        };
      })
      // Get campaign sync errors
      .addCase(getSalesforceCampaignSyncErrors.pending, (state) => {
        state.fetchingErrorList = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceCampaignSyncErrors.fulfilled, (state, action) => {
        state.fetchingErrorList = LOADING_STATES.SUCCESS;
        if (action.payload.data?.length) {
          state.campaignSyncErrors = action.payload.data;
        } else {
          state.campaignSyncErrors = null;
        }
      })
      .addCase(getSalesforceCampaignSyncErrors.rejected, (state, action) => {
        state.fetchingErrorList = LOADING_STATES.FAILED;
        state.campaignSyncErrors = null;
      })
      // Salesforce objects
      .addCase(getSalesforceObjects.pending, (state, action) => {
        state.salesforceConnections[action.meta.arg] = {
          ...state.salesforceConnections[action.meta.arg],
          ...{ loading: LOADING_STATES.LOADING },
        };
      })
      .addCase(getSalesforceObjects.fulfilled, (state, action) => {
        state.salesforceConnections[action.meta.arg] = {
          ...state.salesforceConnections[action.meta.arg],
          ...{
            objects: action.payload.salesforce_objects,
            loading: LOADING_STATES.SUCCESS,
          },
        };
      })
      .addCase(getSalesforceObjects.rejected, (state, action) => {
        if (
          action.payload &&
          (action.payload as ErrorPayload).data?.exception ===
            "SalesforceAuthException"
        ) {
          state.salesforceConnections[action.meta.arg] = {
            ...state.salesforceConnections[action.meta.arg],
            needsReauthentication: true,
          };
        }
        state.salesforceConnections[action.meta.arg] = {
          ...state.salesforceConnections[action.meta.arg],
          ...{ loading: LOADING_STATES.FAILED },
        };
      });
  },
});

export const selectSalesforceLegacy = (state: RootState) =>
  state.salesforceLegacy;
export const {
  setSalesforceSyncHistoryPage,
  resetSalesforceData,
  setSalesforceErrorModalPage,
} = salesforceLegacySlice.actions;

export default salesforceLegacySlice.reducer;
