import { VStack, Text, Box } from "@chakra-ui/react";

export default function SubPropertyItemList({
  subPropertyKeyList,
  subPropertyList,
  leftPadding,
}: {
  subPropertyKeyList: string[];
  subPropertyList: any;
  leftPadding: number;
}) {
  if (subPropertyKeyList.length === 0) {
    return <></>;
  } else if (typeof subPropertyList !== "object") {
    return <Text pl={leftPadding + 5}>{subPropertyList}</Text>;
  } else {
    return (
      <VStack alignItems="flex-start">
        {subPropertyKeyList.map((key) => {
          return (
            <Box>
              <Text pl={leftPadding}>{key} : </Text>
              <SubPropertyItemList
                subPropertyKeyList={Object.keys(subPropertyList[key])}
                subPropertyList={subPropertyList[key]}
                leftPadding={leftPadding + 5}
              />
            </Box>
          );
        })}
      </VStack>
    );
  }
}
