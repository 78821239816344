import { LOADING_STATES } from "../constants/common";
import {
  TimestampRangePaginationFilter,
  PaginationTypeWithLoadingState,
} from "./common";
import {
  DESTINATION_TYPES,
  SalesforceObject,
  SUB_TYPES,
} from "./unifiedMapping";

export type SalesforceConnectionV2 = {
  connectionId: string;
  connectionName: string;
  tenantId: number;
  instanceUrl: string | null;
  salesforceLimits: SfApiLimitType;
  inflectionLimits: InflectionApiLimitType;
  permissions: SyncObjectPermissions;
  requireReAuthentication: boolean;
  syncSettings: {
    objectSettings: SyncObjectsSettings;
  };
  currentState: SALESFORCE_CONNECTION_STATE;
  syncIntervalInMinutes: null | number;
  lastSyncCompletionTimestamp: null | number;
  lastDataCaptureCutOffTimestamp: null | number;
  nextSyncStartTimestamp: null | number;
};

export interface SfCampaignSyncError {
  journeyId: string;
  error: string;
  salesforceCampaignId: string;
  firstDetectedTimestamp: number;
  lastDetectedTimestamp: number;
  count: number;
}

export type SfResponse<T> = {
  meta: {
    status: string;
    timestamp: string;
  };
  errors: {
    errorCode: string;
    message: string;
    detail: string;
  }[];
  data: T | null;
};

export type OAuthUrl = { oauthUrl: string };

export type SfVerificationParams = {
  code: string;
  state: string;
};

export type SfUpdateSyncObjectSettings =
  | {
      [key in SF_OBJECTS_TO_SYNC]?: {
        syncObject: boolean;
        readSettings: {
          selectiveSyncSettings: { fieldName: string } | null;
          onSalesforceEvent: SalesforceEvents | null;
        };
        writeSettings: SyncObjWriteSettings;
      };
    }
  | null;

export type SfConnectionUpdateType = {
  connectionName: string;
  syncSettings: {
    objectSettings: SfUpdateSyncObjectSettings;
  } | null;
  inflectionLimits: InflectionApiLimitType | null;
};

export type InitSalesforceConReqType = {
  connectionName: string;
  isSandbox: boolean;
};

//TODO:SF  change after merge
export type SfPagination<T> = {
  data: {
    records: T;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
  };
  errors: {};
  meta: {
    status: string;
    timestamp: string;
  };
};

export type SfSyncRunsGet = TimestampRangePaginationFilter & {
  connectionId: string;
  includeEmptyRuns: boolean;
};

export type SfSyncErrorsGet = TimestampRangePaginationFilter & {
  errorType: string;
  connectionId: string;
};

export type SfSyncAffectedRecordsGet = TimestampRangePaginationFilter & {
  searchQuery: string;
  connectionId: string;
  errorClass: string;
};

export type SfSyncRunsSummaryGet = {
  connectionId: string;
  startTime: number;
  endTime: number;
};

export type SfSyncRunDetails = {
  readCount: number;
  writeCount: number;
  readErrorCount: number;
  writeErrorCount: number;
};

export type SyncObjectSyncRunDetails = {
  [key in SF_OBJECTS_TO_SYNC]?: SfSyncRunDetails;
};

export type SfSyncRunRecord = {
  id: string;
  startTime: number;
  endTime: number;
  skippedReason: string;
  syncRunObjects: SyncObjectSyncRunDetails;
  status: SF_SYNC_RUN_STATE;
};

export type SfSyncErrorRecord = {
  syncRunId: string;
  syncJobType: string;
  count: number;
  lastDetectedTimestamp: number;
  firstDetectedTimestamp: number;
  errorMessage: string;
  errorClass: string;
  errorType: SF_SYNC_ERROR_TYPE;
  salesforceObject: SALESFORCE_FLINK_SYNC_OBJECTS;
};

export type SfSyncAffectedRecord = {
  inflectionId: string;
  salesforceId: string;
  objectEmail: string;
  salesforceObject: SALESFORCE_FLINK_SYNC_OBJECTS;
  errorMessage: string;
  name: string;
  createdAt: number;
};

export type SfSyncRunSummary = SfSyncRunDetails & {
  newReadRecordsCount: number;
  newWriteRecordsCount: number;
};

export type SfSyncObjRunsSummary = {
  [key in SF_OBJECTS_TO_SYNC]?: SfSyncRunSummary;
};

export type SfSyncRunsSummaryType = {
  startTime: number;
  endTime: number;
  syncRunObjects: SfSyncObjRunsSummary;
};

export type SfSyncErrorsResyncArchiveReq = {
  connectionId: string;
  startTime: number;
  endTime: number;
  errorClasses: string[];
};

export type SfApiLimitDetails = {
  max: number;
  remaining: number;
};

export type InflectionApiLimitDetails = {
  max: number;
  used: number;
};

export type SfApiLimitType = {
  dailyApiRequests: SfApiLimitDetails;
  dailyBulkV2QueryFileStorageMB: SfApiLimitDetails;
  dailyBulkV2QueryJobs: SfApiLimitDetails;
};

export type InflectionApiLimitType = {
  dailyApiRequests: InflectionApiLimitDetails;
};

export type SyncObjectPermissions = {
  [key in SF_OBJECTS_TO_SYNC]?: SyncActionsAllowed;
};

export type SyncActionsAllowed = {
  read: boolean;
  write: boolean;
};

//salesforce write settings
export type SfInclusionListType = {
  listIdentifier: string;
  listType: SF_INCLUSION_LIST_TYPES;
};

export type InflectionEvents = {
  onCreate: SALESFORCE_CREATE_ACTION;
  onDelete: SALESFORCE_DELETE_ACTION;
};

export type SyncObjWriteSettings = {
  inclusionListSettings: SfInclusionListType | null;
  onInflectionEvent: InflectionEvents | null;
};

//salesforce read settings

export type SfSelectiveSyncField = {
  display: string;
  name: string | null;
  type: string;
  subType: string;
};

export type SalesforceEvents = {
  onCreate: INFLECTION_CREATE_ACTION;
  onDelete: INFLECTION_DELETE_ACTION;
};

export type SfSelectiveSyncSettings = {
  value: string | boolean;
  field: SfSelectiveSyncField | null;
};

export type SyncObjReadSettings = {
  selectiveSyncSettings: SfSelectiveSyncSettings | null;
  onSalesforceEvent: SalesforceEvents | null;
};

//salesforce sync object settings
export type SyncObj = {
  syncObject: boolean;
  readSettings: SyncObjReadSettings;
  writeSettings: SyncObjWriteSettings;
};

export type SyncObjectsSettings = { [key in SF_OBJECTS_TO_SYNC]?: SyncObj };

//using api response
export type SyncedObjSummary = { [key in SF_OBJECTS_TO_SYNC]: boolean };

export type SfColumnDetails = {
  caseSensitive: boolean;
  createable: boolean;
  filterable: boolean;
  label: string;
  length: number;
  name: string;
  nullable: boolean;
  type?: SUB_TYPES; //salesforce type
  inflectionMappedType: DESTINATION_TYPES;
  unique: boolean;
  updateable: boolean;
};

export type SfColumnsOfAllSyncObjects = {
  [key in SF_OBJECTS_TO_SYNC]?: {
    fields: SfColumnDetails[];
    createable?: boolean;
    custom?: boolean;
    deletable?: boolean;
    label?: string;
    labelPlural?: string;
    name?: string;
    queryable?: boolean;
    retrieveable?: boolean;
    searchable?: boolean;
    updateable?: boolean;
  };
};

export type SyncReadSettingsOfAllSyncObjects = {
  [key in SF_OBJECTS_TO_SYNC]?: SyncObjReadSettings | null;
};

export type SalesforceColumnsGet = {
  connectionId: string;
  forceRefresh: boolean;
  lenientStaleCheck: boolean;
};

export type SalesforceUnifiedMappingColumns = {
  sfConnectionDetails: SalesforceConnectionV2 | null;
  loading: LOADING_STATES;
  syncObjectColumns: SfColumnsOfAllSyncObjects | null;
};

export type SalesforceConnectionsSummary = {
  [id: string]: {
    sfConnectionDetails: SalesforceConnectionV2 | null;
    loading: LOADING_STATES;
    syncObjectColumns: SalesforceObject[] | null;
  };
};

export type SfSyncErrorResyncArchiveWithPagination<T> = {
  archivingRecords: LOADING_STATES;
  resyncingRecords: LOADING_STATES;
} & PaginationTypeWithLoadingState<T>;

export type SalesforcePersonDetails = {
  connectionId: string;
  salesforceId: string;
  salesforceObject: null | SALESFORCE_FLINK_SYNC_OBJECTS;
};

export type SalesforcePersonRemapReq = SalesforcePersonDetails & {
  email: string;
};

export type SalesforcePersonSyncDetails = {
  syncStatusMeta: {
    status: SF_PERSON_SYNC_STATUS;
  };
  linkedRecord: SalesforcePersonDetails & {
    firstName: string;
    lastName: string;
  };
  associatedRecords: SalesforcePersonDetails &
    { firstName: string; lastName: string }[];
  failureReason?: string;
};

export type SalesforceSyncScheduleDetails = {
  tenantId: number;
  connectionId: string;
  nextScheduledSyncAt: number | null;
  lastSyncCompletionTime: number | null;
  isCurrentlyProcessing: boolean;
};

//enums

export enum SF_INIT_STEPS {
  CHECK_PERMISSIONS,
  SYNC_SETTINGS,
  SYNC_SUMMARY,
  CONFIRM_SYNC,
}

export enum SALESFORCE_CONNECTION_STATE {
  VERIFIED = "VERIFIED",
  PREFERENCES_INITIALIZED = "PREFERENCES_INITIALIZED",
  YET_TO_START = "YET_TO_START",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
}

export enum SF_OBJECTS_TO_SYNC {
  CONTACT = "contact",
  LEAD = "lead",
  ACCOUNT = "account",
  OPPORTUNITY = "opportunity",
  CAMPAIGN = "campaign",
  CAMPAIGN_MEMBER = "campaignMember",
}

export enum SF_SYNC_RUN_STATE {
  SCHEDULED = "SCHEDULED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  SKIPPED = "SKIPPED",
  QUEUED = "QUEUED",
}

export enum SF_SYNC_ERROR_TYPE {
  OTHERS = "Others",
  PERMISSION = "Permission",
  CAMPAIGN_SYNC = "CampaignSync",
}

export enum SF_INCLUSION_LIST_TYPES {
  STATIC = "STATIC_LIST",
  DYNAMIC = "DYNAMIC_LIST",
}

export enum SALESFORCE_CREATE_ACTION {
  DO_NOTHING = "DO_NOTHING",
  CONTACT = "CREATE_CONTACT",
  LEAD = "CREATE_LEAD",
}

export enum SALESFORCE_DELETE_ACTION {
  DO_NOTHING = "DO_NOTHING",
}

export enum INFLECTION_CREATE_ACTION {
  DO_NOTHING = "DO_NOTHING",
  PERSON = "CREATE_PERSON",
  ACCOUNT = "CREATE_ACCOUNT", //not implemented yet
}

export enum INFLECTION_DELETE_ACTION {
  DO_NOTHING = "DO_NOTHING",
  DELETE_PERSON_KEEP_PRODUCT_DATA = "DELETE_PERSON_KEEP_PRODUCT_DATA",
}

export enum SALESFORCE_FLINK_SYNC_OBJECTS {
  ACCOUNT = "ACCOUNT",
  CONTACT = "CONTACT",
  LEAD = "LEAD",
  CAMPAIGN_SYNC = "CAMPAIGN_SYNC",
  CAMPAIGN_MEMBER = "CAMPAIGN_MEMBER",
}

export enum SF_PERSON_SYNC_STATUS {
  FAILED = "FAILED",
  SYNCED = "SYNCED",
}
