import { HStack, Icon, Text, useDisclosure, Image } from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";
import { useState, useCallback, useMemo, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCampaignCreatedId,
  deleteCampaign,
  selectCampaign,
  updateCampaign,
  cloneCampaign,
  clearCampaignList,
  clearCampaignChangedFlag,
} from "./campaignSlice";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import {
  getTableRowLinkProps,
  getUtcOffset,
  isInit,
  isLoading,
  isSuccess,
  percentageOf,
} from "../../../common/helper/commonHelper";
import urls from "../../../urls";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import {
  selectSettings,
  resetLoadingStateEmailDefault,
} from "../settings/settingsSlice";
import GlobalDefaultsModal from "../../../components/GlobalDefaultsPopup";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "../../../components/data-table/DataTable";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import WrapperWithSkeleton from "../../../components/WrapperWithSkeleton";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import CreateCampaignDrawer from "./CreateCampaignDrawer";
import {
  cloneTriggerCampaign,
  deleteTriggerCampaign,
  getCampaignListStats,
  listAllTypeJourney,
  renameTriggerCampaign,
  resetCampaignCreatedId,
  selectTrigger,
} from "./trigger-campaign/triggerCampaignSlice";
import {
  CampaignNameWithIcons,
  CellWithPercentage,
  ExtraOptions,
  JourneyTypeWithIcon,
} from "./components/CampaignListTableItems";
import { JourneyListRow } from "../../../common/types/trigger";
import {
  INIT_JOURNEY_ROW,
  JOURNEY_TYPES,
} from "../../../common/constants/trigger";
import { CAMPAIGN_STATUS } from "../../../common/constants/campaign";
import {
  HeaderSearchProps,
  ListFilterProps,
  PaginationFilterParams,
} from "../../../common/types/common";
import InitialEmptyState from "../../../components/InitialEmptyState";
import CampaignEmptyState from "../../../common/img/emptyStateLogos/campaign.svg";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

function CampaignHeader({
  openEditableModal,
  loading,
  isButtonDisabled,
  searchProps: { searchKeyword, onSearch, hidden },
  filterProps,
}: {
  openEditableModal: () => void;
  loading?: boolean;
  isButtonDisabled?: boolean;
  searchProps: HeaderSearchProps;
  filterProps?: ListFilterProps;
}) {
  return (
    <CommonListHeader
      heading="Journeys"
      createButtonProps={{
        onClick: openEditableModal,
        isLoading: loading,
        isDisabled: isButtonDisabled,
        name: "new-campaign",
        text: "New Journey",
      }}
      searchInputProps={{
        placeholder: "Search journeys",
        defaultValue: searchKeyword,
        name: "search-input",
        onSearch: onSearch,
        hidden: hidden,
      }}
      filterProps={filterProps}
    />
  );
}

function EmptyPageCallToAction({
  openEditableModal,
  loading,
  isDisabled,
}: {
  openEditableModal: () => void;
  loading?: boolean;
  isDisabled?: boolean;
}) {
  return (
    <InitialEmptyState
      mainText="Add a journey to begin"
      message="Lets get started!"
      ctaProps={{
        children: "Create journey",
        name: "create-new-journey",
        onClick: openEditableModal,
        isLoading: loading,
        isDisabled,
      }}
    >
      <Image src={CampaignEmptyState} alt="Journey" />
    </InitialEmptyState>
  );
}

// helper function to get UTC offseted timezone
const FormatDateTimeForNextSchedule = memo(
  ({ date }: { date: string | null }) => {
    const {
      tenantDetails: {
        data: { timezone: tenantTimezone },
      },
    } = useSelector(selectSettings);

    // needed format: 2012-01-26T13:51:00.000-07:00
    // current format: 2012-01-26T13:51:00
    // this function formats date likewise

    function getUtcOffsetedFormattedDate() {
      if (tenantTimezone) {
        const utcOffsetString = getUtcOffset(tenantTimezone);
        const formattedDate = date ? `${date}.000${utcOffsetString}` : null;
        return formattedDate;
      }
      return null;
    }
    return (
      <Text>
        <FormatDate date={getUtcOffsetedFormattedDate()} splitLines showTime />
      </Text>
    );
  }
);

function CampaignListTable({
  fetchingList,
  changingPage,
  campaignList,
  pageSize,
  editCampaignHandler,
  openCloneModal,
  openRenameModal,
  openDeleteModal,
  totalPageCount,
  currentPage,
  emptyMsg,
  handlePageChange,
}: {
  fetchingList: boolean;
  changingPage: boolean;
  campaignList: JourneyListRow[] | null;
  pageSize: number;
  editCampaignHandler: (row: JourneyListRow) => void;
  openCloneModal: (row: JourneyListRow) => void;
  openRenameModal: (row: JourneyListRow) => void;
  openDeleteModal: (row: JourneyListRow) => void;
  totalPageCount: number | null;
  currentPage: number;
  emptyMsg: string;
  handlePageChange: (page: number) => void;
}) {
  const columnHelper = createColumnHelper<JourneyListRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("campaignName", {
        cell: (info) => {
          return (
            <CampaignNameWithIcons
              campaignName={info.getValue()}
              campaignContext={info.row.original.campaignContext}
              tags={info.row.original.tags}
              status={info.row.original.status}
            />
          );
        },
        header: "Journey",
        minSize: 700,
      }),
      columnHelper.accessor("campaignType", {
        cell: (info) => {
          return <JourneyTypeWithIcon type={info.getValue()} />;
        },
        header: "Journey type",
        meta: {
          width: "150px",
        },
      }),
      columnHelper.accessor("totalCount", {
        cell: (info) => {
          return (
            <WrapperWithSkeleton
              loading={isLoading(info.row.original.statsLoading)}
            >
              {info.getValue()?.toLocaleString() ?? "-"}
            </WrapperWithSkeleton>
          );
        },
        header: "Emails processed",
        meta: {
          isNumeric: true,
          width: "140px",
        },
      }),
      columnHelper.accessor("countDelivered", {
        cell: (info) => {
          return (
            <WrapperWithSkeleton
              loading={isLoading(info.row.original.statsLoading)}
            >
              <CellWithPercentage
                data={info.getValue() ?? 0}
                percentage={
                  percentageOf(
                    info.getValue() ?? 0,
                    info.row.original.countProcessed ?? 0,
                    true,
                    1
                  ) as string
                }
                color="blue.600"
              />
            </WrapperWithSkeleton>
          );
        },
        header: "Delivered",
        meta: {
          isNumeric: true,
        },
        size: 150,
      }),
      columnHelper.accessor("openCount", {
        cell: (info) => {
          return (
            <WrapperWithSkeleton
              loading={isLoading(info.row.original.statsLoading)}
            >
              <CellWithPercentage
                data={info.getValue() ?? 0}
                percentage={
                  percentageOf(
                    info.getValue() ?? 0,
                    info.row.original.countDelivered ?? 0,
                    true,
                    1
                  ) as string
                }
                color="cyan.600"
              />
            </WrapperWithSkeleton>
          );
        },
        header: "Opened",
        meta: {
          isNumeric: true,
        },
        size: 150,
      }),
      columnHelper.accessor("nextSchedule", {
        cell: (info) => {
          const isCampaignActive =
            info.row.original.status === CAMPAIGN_STATUS.ACTIVE;
          return (
            <FormatDateTimeForNextSchedule
              date={isCampaignActive ? info.getValue() : null}
            />
          );
        },
        header: "Next schedule",
        minSize: 200,
      }),

      columnHelper.display({
        cell: (info) => {
          return (
            <ExtraOptions
              onCloneClick={() => openCloneModal(info.row.original)}
              onRenameClick={() => openRenameModal(info.row.original)}
              onDeleteClick={() => openDeleteModal(info.row.original)}
            />
          );
        },
        header: "",
        id: "actions",
        size: 50,
      }),
    ],
    [columnHelper, openCloneModal, openDeleteModal, openRenameModal]
  );

  function getTableRowLinkPropsForCampaign(data: JourneyListRow) {
    const basicUrl =
      data.campaignType === JOURNEY_TYPES.BATCH
        ? urls.batchJourneyEdit
        : urls.triggerJourneyEdit;
    return getTableRowLinkProps(
      { to: basicUrl.replace("/:id", ""), editParam: "campaignId" },
      data
    );
  }

  return (
    <DataTable
      list={campaignList}
      fetchingList={fetchingList}
      changingPage={changingPage}
      totalPageSize={pageSize}
      onRowClick={editCampaignHandler}
      setPage={handlePageChange}
      totalPageCount={totalPageCount}
      currentPage={currentPage}
      columns={columns}
      emptyMsg={emptyMsg}
      getTableRowLinkProps={getTableRowLinkPropsForCampaign}
    />
  );
}

const COLUMNS_TO_SEARCH_IN = ["name"];

export default function Campaigns() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { campaignsChanged, campaignCreatedId, creatingCampaign } =
    useSelector(selectCampaign);

  const { createdTriggerCampaign, allJourneyList, triggerCampaignChanged } =
    useSelector(selectTrigger);

  const {
    isOpen: isOpenGlobalDefaultsModal,
    onOpen: onOpenGlobalDefaultsModal,
    onClose: onCloseGlobalDefaultsModal,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateDrawer,
    onOpen: onOpenCreateDrawer,
    onClose: onCloseCreateDrawer,
  } = useDisclosure();

  const { globalDefault } = useSelector(selectSettings);

  const [editableName, setEditableName] = useState<string | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenCloneModal, setIsOpenCloneModal] = useState(false);
  const [selectedRow, setSelectedRow] =
    useState<JourneyListRow>(INIT_JOURNEY_ROW);

  const checkGlobalDefaults = useCallback(() => {
    if (
      isSuccess(globalDefault.fetching) &&
      (!globalDefault.data?.from_email.email ||
        !globalDefault.data?.from_email.name ||
        !globalDefault.data.reply_to)
    ) {
      onOpenGlobalDefaultsModal();
    } else {
      onOpenCreateDrawer();
    }
  }, [globalDefault, onOpenGlobalDefaultsModal, onOpenCreateDrawer]);

  useEffect(() => {
    return () => {
      dispatch(clearCampaignList());
    };
  }, [dispatch]);

  const editBatchCampaign = useCallback(
    (id: string) => {
      navigate(`${urls.batchJourneyEdit.replace(":id", id)}`);
    },
    [navigate]
  );

  const editTriggerCampaign = useCallback(
    (id: string) => {
      navigate(`${urls.triggerJourneyEdit.replace(":id", id)}`);
    },
    [navigate]
  );

  const fetchJourneys = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listAllTypeJourney({
          pageNo,
          searchKeyword,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchJourneys,
    fetchingList: allJourneyList.fetchingList,
  });

  useEffect(() => {
    if (campaignsChanged || triggerCampaignChanged) {
      handlePageChange(allJourneyList.currentPageNo);
      dispatch(clearCampaignChangedFlag());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsChanged, triggerCampaignChanged]);

  useEffect(() => {
    if (
      allJourneyList.list?.length &&
      allJourneyList.list.every((campaign) => isInit(campaign.statsLoading))
    ) {
      dispatch(
        getCampaignListStats(
          allJourneyList.list
            .filter((campaign) => {
              return campaign.campaignType === JOURNEY_TYPES.BATCH;
            })
            .map((campaign) => campaign.campaignId)
        )
      );
    }
  }, [allJourneyList.list, dispatch]);

  useEffect(() => {
    if (campaignCreatedId !== "") {
      editBatchCampaign(campaignCreatedId);
      dispatch(clearCampaignCreatedId());
    }
  }, [campaignCreatedId, editBatchCampaign, dispatch]);

  useEffect(() => {
    if (createdTriggerCampaign.data !== "") {
      editTriggerCampaign(createdTriggerCampaign.data);
      dispatch(resetCampaignCreatedId());
    }
  }, [editTriggerCampaign, createdTriggerCampaign.data, dispatch]);

  function cloneCampaignHandler(name: string) {
    switch (selectedRow?.campaignType) {
      case JOURNEY_TYPES.TRIGGER:
        dispatch(
          cloneTriggerCampaign({ campaignId: selectedRow.campaignId, name })
        );
        break;
      case JOURNEY_TYPES.BATCH:
        dispatch(cloneCampaign({ campaignId: selectedRow.campaignId, name }));
        break;
    }
    setIsOpenCloneModal(false);
  }

  function deleteCampaignHandler() {
    setIsOpenDeleteModal(false);
    switch (selectedRow?.campaignType) {
      case JOURNEY_TYPES.TRIGGER:
        dispatch(deleteTriggerCampaign(selectedRow.campaignId));
        break;
      case JOURNEY_TYPES.BATCH:
        dispatch(deleteCampaign(selectedRow.campaignId));
        break;
    }
  }

  function renameCampaignHandler(updatedName: string) {
    setEditableName(null);
    switch (selectedRow?.campaignType) {
      case JOURNEY_TYPES.TRIGGER:
        dispatch(
          renameTriggerCampaign({
            campaignId: selectedRow.campaignId,
            name: updatedName,
          })
        );
        break;
      case JOURNEY_TYPES.BATCH:
        dispatch(
          updateCampaign({ id: selectedRow.campaignId, name: updatedName })
        );
        break;
    }
  }

  function openCloneModal(row: JourneyListRow) {
    setSelectedRow(row);
    setIsOpenCloneModal(true);
  }

  function openDeleteModal(row: JourneyListRow) {
    setIsOpenDeleteModal(true);
    setSelectedRow(row);
  }

  function openRenameModal(row: JourneyListRow) {
    setEditableName(row.campaignName);
    setSelectedRow(row);
  }

  function editCampaignHandler(row: JourneyListRow) {
    setSelectedRow(row);
    switch (row.campaignType) {
      case JOURNEY_TYPES.BATCH:
        editBatchCampaign(row.campaignId);
        break;
      case JOURNEY_TYPES.TRIGGER:
        editTriggerCampaign(row.campaignId);
        break;
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  const { search } = useLocation();

  // Support for old urls | Replacing old urls to new
  useEffect(() => {
    navigate(`${urls.journey}${search}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const isInitEmpty = !(
    allJourneyList.totalPageCount !== 0 || isFiltersApplied
  );

  return (
    <>
      <CampaignHeader
        loading={isLoading(creatingCampaign)}
        isButtonDisabled={isLoading(globalDefault.fetching)}
        openEditableModal={checkGlobalDefaults}
        searchProps={{
          searchKeyword: searchKeyword,
          onSearch: handleSearchChange,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.CAMPAIGN,
          hidden: isInitEmpty,
        }}
      />
      <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
        {!isInitEmpty || fetchingList ? (
          <CampaignListTable
            fetchingList={fetchingList}
            changingPage={allJourneyList.changingPage}
            campaignList={allJourneyList.list}
            editCampaignHandler={editCampaignHandler}
            pageSize={allJourneyList.pageSize}
            openCloneModal={openCloneModal}
            openRenameModal={openRenameModal}
            openDeleteModal={openDeleteModal}
            totalPageCount={allJourneyList.totalPageCount}
            currentPage={allJourneyList.currentPageNo}
            emptyMsg={`No journeys found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            handlePageChange={handlePageChange}
          />
        ) : (
          <EmptyPageCallToAction
            loading={isLoading(creatingCampaign)}
            isDisabled={isLoading(globalDefault.fetching)}
            openEditableModal={checkGlobalDefaults}
          />
        )}
      </LayoutWithTable>
      {/* Rename the campaign */}
      {editableName !== "" && (
        <EditNameModal
          value={editableName ?? ""}
          action={ASSET_NAME_ACTIONS.RENAME}
          isOpen={editableName !== null}
          onClose={() => setEditableName(null)}
          onSubmit={renameCampaignHandler}
          asset="journey"
        ></EditNameModal>
      )}

      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        deleteItemType={DELETION_MODAL_TYPES_INFO[ASSET_TYPES.CAMPAIGN].display}
        itemSpecificName={selectedRow.campaignName}
        onClose={() => setIsOpenDeleteModal(false)}
        submitHandler={deleteCampaignHandler}
      />

      <GlobalDefaultsModal
        isOpen={isOpenGlobalDefaultsModal}
        onClose={onCloseGlobalDefaultsModal}
      />
      {/* cloning the campaign */}
      <EditNameModal
        value={selectedRow.campaignName + "-clone"}
        action={ASSET_NAME_ACTIONS.CLONE}
        isOpen={isOpenCloneModal}
        onClose={() => setIsOpenCloneModal(false)}
        onSubmit={cloneCampaignHandler}
        asset="Journey"
      >
        <HStack
          alignItems="flex-start"
          fontSize="sm"
          color="gray.700"
          mt={2}
          p={2}
          background="grayV2.100"
        >
          <Icon
            color="brand.black"
            fontSize="14px"
            as={FaExclamationCircle}
            mt={1}
          ></Icon>
          <Text>
            The dynamic list, flow steps and exit criteria will be cloned from
            <Text
              as="span"
              color="brand.black"
              fontWeight="bold"
              overflowWrap={"anywhere"}
            >
              {` "${selectedRow.campaignName}" `}
            </Text>
            journey.
          </Text>
        </HStack>
      </EditNameModal>
      {/* New create journey slideout */}
      <CreateCampaignDrawer
        isOpen={isOpenCreateDrawer}
        onClose={onCloseCreateDrawer}
      />
    </>
  );
}
