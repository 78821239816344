import { VStack, Flex, Icon, Text, Box, HStack } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineFileDownload, MdPeopleAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  isLoading,
  redirectToContact,
} from "../../../../../common/helper/commonHelper";
import { useDebouncedSearch } from "../../../../../common/hooks/commonHooks";
import {
  CAMPAIGN_CONTEXT,
  FlowStepMember,
} from "../../../../../common/types/campaign";
import { CommonListHeader } from "../../../../../components/CommonListHeader";
import { DataTable } from "../../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../../components/DateTimeRangeFilter";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import {
  exportFlowStepMemberReport,
  selectExport,
} from "../../../export/exportSlice";
import CommonDrawer from "../../components/CommonDrawer";
import {
  CampaignBuilderContext,
  closeFlowMemberReport,
  getFlowStepsMembersList,
  selectFlow,
} from "../flowSlice";

const COLUMNS_TO_SEARCH_IN = [
  "person_id",
  "product_user_id",
  "user_name",
  "email",
];
const PAGE_SIZE = 30;

export default function FlowMemberReport() {
  const { campaignContext } = useContext(CampaignBuilderContext);
  const {
    reports: {
      filter,
      campaignId,
      data: { flowMemberReport },
      modals: {
        flowMemberReport: { isOpen, selectedNode },
      },
    },
  } = useSelector(selectFlow);

  const { assetsToExport } = useSelector(selectExport);

  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen && campaignId && selectedNode) {
      // fetch data on load with action and branch ids
      dispatch(
        getFlowStepsMembersList({
          campaignId,
          actionId: selectedNode.nodeId,
          branchId: selectedNode.branchId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          query: "",
          pageNumber: 1,
          pageSize: PAGE_SIZE,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }, [
    dispatch,
    campaignId,
    filter.endDate,
    filter.startDate,
    isOpen,
    selectedNode,
  ]);

  function onPageChange(pageNo: number) {
    if (pageNo && isOpen && campaignId && selectedNode) {
      dispatch(
        getFlowStepsMembersList({
          campaignId,
          actionId: selectedNode.nodeId,
          branchId: selectedNode.branchId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          query: searchQuery,
          pageNumber: pageNo,
          pageSize: PAGE_SIZE,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }

  function onClose() {
    dispatch(closeFlowMemberReport());
  }

  function exportReport() {
    if (selectedNode && campaignId) {
      dispatch(
        exportFlowStepMemberReport({
          campaignId,
          actionId: selectedNode.nodeId,
          branchId: selectedNode.branchId,
          startDate: filter.startDate,
          endDate: filter.endDate,
          query: searchQuery,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }

  const searchIfValid = useCallback(
    (query: string) => {
      if (selectedNode && campaignId) {
        dispatch(
          getFlowStepsMembersList({
            campaignId,
            actionId: selectedNode.nodeId,
            branchId: selectedNode.branchId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            query: query,
            pageNumber: 1,
            pageSize: 10,
            columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          })
        );
      }
    },
    [campaignId, dispatch, filter.endDate, filter.startDate, selectedNode]
  );

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  const columnHelper = createColumnHelper<FlowStepMember>();

  const columns = useMemo(() => {
    let columns: ColumnDef<FlowStepMember, any>[] = [];

    if (campaignContext === CAMPAIGN_CONTEXT.ORG) {
      columns.push(
        columnHelper.accessor("user.product_user_id", {
          header: "User",
          size: 200,
        })
      );
    }

    columns.push(
      columnHelper.accessor("email", {
        header: "Email",
        size: 400,
        cell: (info) => (
          <HStack>
            <Text>{info.getValue()}</Text>
            <Box hidden={!info.getValue()}>
              <Icon
                as={FaExternalLinkAlt}
                fontSize="12"
                onClick={() =>
                  redirectToContact(info.cell.row.original.user.person_id)
                }
                cursor="pointer"
              />
            </Box>
          </HStack>
        ),
      }),
      columnHelper.accessor("transition_date", {
        header: "Transition Date",
        size: 350,
        cell: (info) => <FormatDate date={info.getValue()} showTime />,
      })
    );

    return columns;
  }, [campaignContext, columnHelper]);

  return (
    <CommonDrawer
      title={
        <Flex alignItems="center">
          <Icon as={MdPeopleAlt} color="brandBlue.500" fontSize="20px" mr="2" />
          <Text>Members in Workflow</Text>
        </Flex>
      }
      size="xl"
      placement="left"
      isOpen={isOpen}
      onClose={onClose}
      btnRef={{ current: null }}
      variant="primary"
      drawerBodyProps={{ position: "relative" }}
    >
      <VStack w="100%" spacing="0">
        <Flex w="100%" flexDir="column">
          <CommonListHeader
            isLoading={isLoading(flowMemberReport.loading)}
            heading={`Showing ${flowMemberReport.data?.list?.length ?? 0} of ${
              flowMemberReport.data.count ?? 0
            } results`}
            titleProps={{
              fontSize: "12px",
              fontWeight: 400,
              color: "grayV2.600",
            }}
            searchInputProps={{
              placeholder: "Search results  ",
              name: "search-input",
              onSearch: (query) => {
                setSearchQuery(query);
                debouncedSearch(query);
              },
              value: searchQuery,
            }}
            headerStackProps={{ px: "0px" }}
          >
            <IButton
              variant={BUTTON.SECONDARY}
              leftIcon={<MdOutlineFileDownload fontSize="14px" />}
              isLoading={
                campaignId && selectedNode?.nodeId
                  ? isLoading(
                      assetsToExport[campaignId + selectedNode?.nodeId]
                        ?.isLoading
                    )
                  : false
              }
              isDisabled={!flowMemberReport.data?.totalPageCount}
              onClick={exportReport}
            >
              Export
            </IButton>
          </CommonListHeader>
          <DataTable
            fetchingList={isLoading(flowMemberReport.loading)}
            changingPage={flowMemberReport.data?.changingPage}
            list={flowMemberReport.data?.list || []}
            totalPageCount={flowMemberReport.data?.totalPageCount}
            currentPage={flowMemberReport.data?.currentPageNo}
            totalPageSize={flowMemberReport.data?.pageSize}
            setPage={onPageChange}
            columns={columns}
            emptyMsg="No results found"
          />
        </Flex>
      </VStack>
    </CommonDrawer>
  );
}
