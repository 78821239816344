import { Box, HStack, VStack, Text, StackProps } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { FaRegListAlt } from "react-icons/fa";

import { AudienceCriteriaContext } from "../AudienceCriteria";
import React, { useContext, useMemo } from "react";
import { SingleValue } from "react-select";
import { getOperatorDetails } from "../../../../../common/helper/commonHelper";
import { OperatorType } from "../../../../../common/types/campaign";
import { AssetField } from "../../../../../common/types/connection";
import {
  ListMembershipCondition,
  ListMembershipNode,
} from "../../../../../common/types/dynamicList";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import NodeGroupBox from "../components/NodeGroupBox";
import {
  LIST_TYPE_FROM_MEMBERSHIP_TYPE,
  MEMBERSHIP_OPERATOR,
} from "../../../../../common/constants/dynamicList";
import ListSelectionDropdownWithPreview, {
  LIST_SELECTION_DROPDOWN_VARIANT,
} from "../../../../../components/ListSelectionDropdown";

function ActivitySelector({
  nodeData,
  onChange,
  isReadOnly,
}: {
  nodeData: ListMembershipCondition;
  onChange: (nodeData: ListMembershipCondition) => void;
  isReadOnly?: boolean;
}) {
  const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        nodeData.membershipOperator,
        operators,
        OperatorType.LIST_SPECIFIC
      ),
    [nodeData.membershipOperator, operators]
  );

  function onMembershipOperatorChange(operator: MEMBERSHIP_OPERATOR) {
    const nodeDataCopy = cloneDeep(nodeData);
    if (nodeDataCopy.membershipOperator !== operator) {
      nodeDataCopy.listId = null;
      nodeDataCopy.listType = null;
    }
    nodeDataCopy.membershipOperator = operator;
    onChange(nodeDataCopy);
  }

  function onListChange(val: SingleValue<AssetField>) {
    const nodeDataCopy = cloneDeep(nodeData);
    if (val && val.id) {
      nodeDataCopy.listId = val.id;
      nodeDataCopy.listType = LIST_TYPE_FROM_MEMBERSHIP_TYPE[val.type];
    } else {
      nodeDataCopy.listId = null;
      nodeDataCopy.listType = null;
    }
    onChange(nodeDataCopy);
  }

  const isInvalidOperator = activeErrorCheck && !nodeData.membershipOperator;
  const isInvalidList =
    activeErrorCheck && (!nodeData.listId || !nodeData.listType);

  const wrapperStyle: StackProps = isReadOnly
    ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        spacing: "1",
      }
    : {
        spacing: "3",
      };

  return (
    <HStack alignItems="center" {...wrapperStyle} w="100%">
      {isReadOnly ? (
        <Text>Membership</Text>
      ) : (
        <Text fontSize="sm">...membership property of...</Text>
      )}
      {operators && (
        <Box id="list-membership-activity-selector">
          <ValueSelectFields
            options={Object.values(operators[OperatorType.LIST_SPECIFIC]).map(
              (op) => ({
                label: op.display,
                value: op.id,
              })
            )}
            value={(nodeData.membershipOperator as string) || ""}
            onChange={(value) =>
              onMembershipOperatorChange(value as MEMBERSHIP_OPERATOR)
            }
            validationError={isInvalidOperator ? "Invalid operator" : ""}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}
      {nodeData.membershipOperator && operators && operatorDetails && (
        <Box overflow="hidden">
          <ListSelectionDropdownWithPreview
            variant={LIST_SELECTION_DROPDOWN_VARIANT.CAMPAIGN}
            value={nodeData.listId ?? null}
            onChange={onListChange}
            isReadOnly={isReadOnly}
            isInvalid={isInvalidList}
          />
        </Box>
      )}
    </HStack>
  );
}

const ListMembershipNodeCriteria = React.memo(
  ({
    nodeData,
    label,
    id,
    onChange,
    onRemove,
    isReadOnly,
  }: {
    nodeData: ListMembershipNode;
    label: string;
    id: string;
    onChange: (nodeData: any) => void;
    onRemove: () => void;
    isReadOnly?: boolean;
  }) => {
    function onConditionChange(condition: ListMembershipCondition) {
      const nodeDataCopy = cloneDeep(nodeData);
      nodeDataCopy.listMembershipCondition = condition;
      onChange(nodeDataCopy);
    }

    const wrapperStyle = isReadOnly
      ? {
          py: "0",
          px: "0",
          spacing: "1",
        }
      : {
          py: "2",
          px: "3",
          spacing: "4",
          bg: "grayV2.200",
        };

    return (
      <NodeGroupBox
        nodeName={label}
        id={id}
        onRemove={onRemove}
        icon={FaRegListAlt}
        isReadOnly={isReadOnly}
        bg="grayV2.200"
      >
        <VStack alignItems="flex-start" {...wrapperStyle} w="100%">
          {nodeData.listMembershipCondition && (
            <HStack width="100%" wrap="wrap" gridGap="2">
              <ActivitySelector
                nodeData={nodeData.listMembershipCondition}
                onChange={onConditionChange}
                isReadOnly={isReadOnly}
              />
            </HStack>
          )}
        </VStack>
      </NodeGroupBox>
    );
  }
);

export default ListMembershipNodeCriteria;
