import { HStack, Text, VStack, Flex, Heading } from "@chakra-ui/react";
import { cloneDeep, set, isEqual } from "lodash";
import { useEffect, useState } from "react";
import {
  SalesforceConnectionV2,
  SF_OBJECTS_TO_SYNC,
  SyncObjectsSettings,
} from "../../../../../../common/types/salesforce";
import DropdownWithSearch from "../../../../../../components/DropdownWithSearch";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { NoteBox } from "../salesforceSyncSettings/components/NoteBox";
import { isAccountSyncEnabled } from "../../../../../../common/helper/salesforceHelper";
import ActionBasedButtons, {
  ACTIONS,
} from "../../../../../../components/ActionBasedButtons";
import SwitchBadgeInput from "../../../../../../components/SwitchBadgeInput";

const LEAD_AND_CONTACT_ITEM = {
  label: "Leads & Contacts",
  value: "lead+contact",
};

const CONTACT_ITEM = {
  label: "Contacts",
  value: SF_OBJECTS_TO_SYNC.CONTACT,
};
const LEAD_ITEM = { label: "Leads", value: SF_OBJECTS_TO_SYNC.LEAD };

const OPTIONS = [LEAD_ITEM, CONTACT_ITEM, LEAD_AND_CONTACT_ITEM];

function mapToDropdown(settings: SyncObjectsSettings | null) {
  if (settings) {
    const contactSync = settings.contact?.syncObject;
    const leadSync = settings.lead?.syncObject;

    if (contactSync && leadSync) return LEAD_AND_CONTACT_ITEM;
    if (leadSync) return LEAD_ITEM;
    if (contactSync) return CONTACT_ITEM;

    return null;
  }

  return null;
}

function mapDropdownToRequestParams(
  option: { label: string; value: string } | null,
  syncObjectSettings: SyncObjectsSettings | null
) {
  if (option && syncObjectSettings) {
    let newObjectSettings = cloneDeep(syncObjectSettings) ?? {};
    const contactSyncKey = "contact.syncObject";
    const leadSyncKey = "lead.syncObject";

    switch (option.value) {
      case CONTACT_ITEM.value:
        set(newObjectSettings, contactSyncKey, true);
        set(newObjectSettings, leadSyncKey, false);
        break;
      case LEAD_ITEM.value:
        set(newObjectSettings, contactSyncKey, false);
        set(newObjectSettings, leadSyncKey, true);
        break;
      case LEAD_AND_CONTACT_ITEM.value:
        set(newObjectSettings, contactSyncKey, true);
        set(newObjectSettings, leadSyncKey, true);
        break;
      default:
        set(newObjectSettings, contactSyncKey, false);
        set(newObjectSettings, leadSyncKey, false);
        break;
    }
    return newObjectSettings;
  }
  return syncObjectSettings;
}

export default function SalesforceGeneralSettings({
  connection,
  onUpdateConnection,
  isLoading = false,
  isUpdatingConnection,
}: {
  connection: SalesforceConnectionV2 | null;
  onUpdateConnection: (connection: SalesforceConnectionV2 | null) => void;
  isLoading: boolean;
  isUpdatingConnection?: boolean;
}) {
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [contactLeadSync, setContactLeadSync] = useState(
    mapToDropdown(connection?.syncSettings?.objectSettings ?? null)
  );

  const [isAccountSync, setIsAccountSync] = useState(
    isAccountSyncEnabled(connection)
  );

  useEffect(() => {
    setContactLeadSync(
      mapToDropdown(connection?.syncSettings?.objectSettings ?? null)
    );
    setIsAccountSync(isAccountSyncEnabled(connection));
  }, [connection]);

  function onCancelEdit() {
    setIsAccountSync(isAccountSyncEnabled(connection));
    setContactLeadSync(
      mapToDropdown(connection?.syncSettings?.objectSettings ?? null)
    );
    setIsReadOnly(true);
  }

  function onUpdateSyncObject() {
    const updatedConnection = cloneDeep(connection);
    set(
      updatedConnection ?? {},
      "syncSettings.objectSettings.account.syncObject",
      isAccountSync
    );

    const newSyncObjectSettings = mapDropdownToRequestParams(
      contactLeadSync,
      updatedConnection?.syncSettings?.objectSettings ?? null
    );

    set(
      updatedConnection ?? {},
      "syncSettings.objectSettings",
      newSyncObjectSettings
    );

    if (!isEqual(connection, updatedConnection)) {
      onUpdateConnection(updatedConnection);
    }

    setIsReadOnly(true);
  }

  return (
    <ContentContainerWithHeader>
      <HStack py={1} justifyContent="flex-end">
        <ActionBasedButtons
          action={isReadOnly ? ACTIONS.VIEW : ACTIONS.EDIT}
          editButtonProps={{ onClick: () => setIsReadOnly(false) }}
          saveButtonProps={{
            onClick: onUpdateSyncObject,
            isLoading: isUpdatingConnection,
          }}
          cancelButtonProps={{
            onClick: onCancelEdit,
            isLoading: isUpdatingConnection,
          }}
        />
      </HStack>

      <VStack alignItems="flex-start" spacing="3" p="5" bg="white" rounded="md">
        <VStack alignItems="flex-start" spacing="2">
          <Heading fontSize="md">Contact/Lead Sync</Heading>
          <HStack justifyContent="space-between">
            <Text fontSize="sm">
              Sync Salesforce person object from sources{" "}
            </Text>
            <Flex justifyContent="flex-start" width="100px">
              <DropdownWithSearch
                options={OPTIONS}
                value={contactLeadSync}
                onChange={(option) => option && setContactLeadSync(option)}
                isDisabled={isReadOnly}
                name="sync-lead-contact"
                controlStyle={{
                  width: "200px",
                }}
              />
            </Flex>
          </HStack>
        </VStack>
        <VStack alignItems="flex-start" pt={2}>
          <Heading fontSize="md">Account Sync</Heading>
          <HStack
            justifyContent="space-between"
            w="300px"
            alignItems="flex-start"
          >
            <Text fontSize="sm" w="500px">
              Enable account sync
            </Text>

            <SwitchBadgeInput
              isEnabled={isAccountSync}
              onChange={(e) => setIsAccountSync(e.target.checked)}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          </HStack>
          <NoteBox
            title=""
            text="Account sync will automatically create and update the accounts in
            Inflection and Salesforce."
            pr={4}
            pl={1}
          />
        </VStack>
      </VStack>
    </ContentContainerWithHeader>
  );
}
