import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputGroupProps,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

export enum SEARCH_VARIANT {
  PRIMARY = "primary", // primary search input with border
  SECONDARY = "secondary", // secondary search input with only bottom border
}

export type SearchFieldType = InputGroupProps & {
  placeholder?: string;
  name: string;
  value?: string;
  variant?: SEARCH_VARIANT;
  onSearch: (keyword: string) => void;
  defaultValue?: string;
};

export function SearchField({
  placeholder = "Search",
  variant = SEARCH_VARIANT.PRIMARY,
  name,
  value,
  defaultValue,
  onSearch,
  ...inputGroupProps
}: SearchFieldType) {
  switch (variant) {
    case SEARCH_VARIANT.PRIMARY:
      return (
        <InputGroup
          size="sm"
          {...inputGroupProps}
          bg="white"
          borderRadius="4px"
        >
          <InputRightElement
            children={<Icon as={FaSearch} color="gray.300" />}
          />
          <Input
            borderRadius="4px"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={(e) => onSearch(e.target.value)}
            defaultValue={defaultValue}
          />
        </InputGroup>
      );
    case SEARCH_VARIANT.SECONDARY:
      return (
        <InputGroup
          size="sm"
          borderBottom="2px solid"
          borderColor="gray.200"
          {...inputGroupProps}
        >
          <InputRightElement
            pointerEvents="none"
            children={<Icon as={FaSearch} color="gray.400" />}
          />
          <Input
            onChange={(e) => onSearch(e.target.value)}
            border="none"
            placeholder={placeholder}
            name={name}
            value={value}
            defaultValue={defaultValue}
            className="focus-visible" // hack to remove the default focus style
            autoFocus
          />
        </InputGroup>
      );
    default:
      return <></>;
  }
}
