import { Center, Icon, useDisclosure } from "@chakra-ui/react";
import { MdFilterAlt } from "react-icons/md";
import IButton, { BUTTON } from "../IButton";
import { ListFilterProps } from "../../common/types/common";
import { FilterSidebar } from "./FilterSidebar";
import { FaPlus } from "react-icons/fa";

export default function FilterButton({
  variant,
  handleFilterChange,
  filters,
}: ListFilterProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <IButton
        variant={BUTTON.SECONDARY}
        leftIcon={
          <Center position="relative" h="100%">
            <Icon as={MdFilterAlt} fontSize="16px" mr={0.5} />
            <Icon
              as={FaPlus}
              fontSize="8px"
              position="absolute"
              bottom={0}
              right={0}
            />
          </Center>
        }
        onClick={onOpen}
      >
        Add Filter
      </IButton>
      <FilterSidebar
        isOpen={isOpen}
        onClose={onClose}
        variant={variant}
        filters={filters}
        handleFilterChange={handleFilterChange}
      />
    </>
  );
}
