import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";
import { DynamicListType } from "../../../../../common/types/campaign";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";
import IButton, { BUTTON } from "../../../../../components/IButton";
import CampaignExitCriteria from "../../exit-criteria/CampaignExitCriteria";
import { validateDynamicList } from "../../helper/validationHelper";

export function GroupExitCriteria({
  groupName,
  data,
  onChange,
  isOpen,
  onClose,
  removeEdge,
  removeExitCriteria,
  readonly,
}: {
  groupName: string;
  data: DynamicListType | null;
  onChange: (data: DynamicListType | null) => void;
  isOpen: boolean;
  onClose: () => void;
  removeEdge: () => void;
  removeExitCriteria: () => void;
  readonly?: boolean;
}) {
  const [localData, setLocalData] = useState<DynamicListType | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const { operatorsArgCount } = useSelector(selectDynamicList);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  function onSave() {
    if (localData) {
      const {
        dynamicListValid: exitCriteriaValid,
        dynamicListValidated: exitCriteriaValidated,
      } = validateDynamicList([localData], operatorsArgCount);
      setLocalData(exitCriteriaValidated[0]);
      setIsInvalid(!exitCriteriaValid);
      if (exitCriteriaValid) {
        onChange(localData);
      }
    } else {
      setIsInvalid(true);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Exit criteria for {groupName}</DrawerHeader>

        <DrawerBody>
          <Box w="100%" className={readonly ? DISABLED_EDIT_CLASSNAME : ""}>
            <CampaignExitCriteria
              data={localData}
              onChange={setLocalData}
              activeErrorCheck={isInvalid}
              isReadOnly={false}
            />
          </Box>
          <VStack alignItems="flex-start" spacing={4} mt={4} pl={2}>
            {data && !readonly && (
              <>
                <IButton
                  leftIcon={<Icon as={FaPencilAlt} fontSize="12px" />}
                  variant="link"
                  size="sm"
                  mt={4}
                  onClick={removeEdge}
                >
                  Edit exit criteria target
                </IButton>
                <IButton
                  leftIcon={<Icon as={FaTrash} fontSize="12px" />}
                  variant="link"
                  size="sm"
                  colorScheme="red"
                  mt={4}
                  onClick={removeExitCriteria}
                >
                  Remove exit criteria
                </IButton>
              </>
            )}
          </VStack>
        </DrawerBody>

        {!readonly && (
          <DrawerFooter>
            <IButton mr={3} onClick={onClose} variant={BUTTON.SECONDARY}>
              Cancel
            </IButton>
            <IButton
              variant={BUTTON.PRIMARY}
              onClick={onSave}
              isDisabled={!localData}
            >
              {data ? "Update" : "Save"}
            </IButton>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}
