import {
  Input,
  InputGroup,
  InputProps,
  useDisclosure,
  Icon,
  useMergeRefs,
  Box,
} from "@chakra-ui/react";
import * as React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { FaCheck } from "react-icons/fa";
export const PasswordField = React.forwardRef<
  HTMLInputElement,
  InputProps & { check?: any }
>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <InputGroup rounded="md">
      <Input
        ref={mergeRef}
        name={props.name || "password"}
        type={isOpen ? "text" : "password"}
        autoComplete="current-password"
        borderRight={props.check ? "0px" : "default"}
        maxLength={100}
        borderRightRadius={0}
        {...props}
      />
      {props.check && (
        <Box border="1px" borderColor="gray.100" borderLeft={0}>
          <Icon
            as={FaCheck}
            boxSize={6}
            color="green.600"
            alignContent="center"
            pt="10px"
          />
        </Box>
      )}
      <Box
        w="36px"
        border="1px"
        borderColor="gray.100"
        borderLeft={0}
        borderRadius="md"
        borderLeftRadius={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        _hover={{
          cursor: "pointer",
          bg: "gray.100",
        }}
        onClick={onClickReveal}
      >
        <Icon
          as={isOpen ? HiEyeOff : HiEye}
          aria-label={isOpen ? "Mask password" : "Reveal password"}
          fontSize="14px"
        />
      </Box>
    </InputGroup>
  );
});

PasswordField.displayName = "PasswordField";
