import { Box, Heading, Stack } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IButton from "../../components/IButton";
import { Logo } from "../../components/Logo";
import urls from "../../urls";
import { resetLoginCreds, selectAccount } from "./accountSlice";

export default function LoginSso() {
  const { samlRedirectUrl, userEmail } = useSelector(selectAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function goToSsoPage() {
    window.location.href = samlRedirectUrl;
  }

  const goToEmailPage = useCallback(() => {
    dispatch(resetLoginCreds());
    navigate(urls.login);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!userEmail) {
      goToEmailPage();
    }
  }, [userEmail, goToEmailPage]);

  return (
    <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Logo mx="auto" h="8" mb={{ base: "10", md: "20" }} />
        <Box mb="12" textAlign="center">
          <Heading size="md" fontWeight="extrabold">
            Hello, {userEmail} !
          </Heading>
          <IButton
            onClick={goToEmailPage}
            colorScheme="blue"
            variant="link"
            id="back-to-login"
          >
            (This is not me)
          </IButton>
        </Box>
        <Box
          bg="white"
          py="12"
          px={{ base: "4", md: "10" }}
          shadow="base"
          w="sm"
          rounded={{ sm: "lg" }}
        >
          <form onSubmit={goToSsoPage}>
            <Stack spacing="6">
              <Heading textAlign="center" as="h6" size="xs">
                Your organization has OKTA SSO enabled
              </Heading>
              <IButton
                width="100%"
                colorScheme="blue"
                size="lg"
                fontSize="md"
                onClick={goToSsoPage}
                name="go-to-okta"
              >
                Continue with OKTA
              </IButton>
            </Stack>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
