import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import {
  UpdateWebhookType,
  WebhookDetails,
  WebhookSummary,
  PreviewWebhookType,
  WebhookPreviewRequest,
  WebhookListItem,
} from "../../types/webhook";
import { campaignRequest } from "./api";

export async function listAllWebhooksApi(): Promise<{
  records: WebhookSummary[];
}> {
  return campaignRequest({
    method: "POST",
    url: "/assets/webhooks/webhook.list.all",
  });
}

export async function listWebhooksApi({
  pageNo,
  pageSize,
  searchKeyword,
  columnsToSearchIn,
  filters,
}: SearchAssetsRequestType): Promise<PaginatedList<WebhookListItem>> {
  return campaignRequest({
    method: "POST",
    url: "/assets/webhooks/webhook.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: { search: { keyword: searchKeyword, fields: columnsToSearchIn } },
      filters,
    },
    shouldRetryApiCall: true,
  });
}

export async function createWebhookApi(
  name: string
): Promise<{ webhook: WebhookDetails }> {
  return campaignRequest({
    url: "/assets/webhooks/webhook.create",
    data: { name },
  });
}

export async function deleteWebhookApi(
  webhookId: string
): Promise<{ webhook: boolean }> {
  return campaignRequest({
    url: "/assets/webhooks/webhook.delete",
    data: { webhook_id: webhookId },
  });
}

export async function updateWebhookNameApi(
  name: string,
  webhookId: string
): Promise<{ webhook: boolean }> {
  return campaignRequest({
    url: "/assets/webhooks/name.update",
    data: { webhook_id: webhookId, name },
  });
}

export async function getWebhookApi(
  webhookId: string
): Promise<{ webhook: WebhookDetails }> {
  return campaignRequest({
    url: "/assets/webhooks/webhook.get",
    data: { webhook_id: webhookId },
    shouldRetryApiCall: true,
  });
}

export async function updateWebhookApi(
  webhookData: UpdateWebhookType
): Promise<{ webhook: WebhookDetails }> {
  return campaignRequest({
    url: "/assets/webhooks/webhook.update",
    data: webhookData,
  });
}

export async function previewWebhookApi(
  data: WebhookPreviewRequest
): Promise<PreviewWebhookType> {
  return campaignRequest({
    url: "/assets/webhooks/preview.debug",
    data,
  });
}
