import { useDisclosure, VStack, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";
import {
  CampaignRuleResp,
  CampaignSchedule,
} from "../../../../../common/types/campaign";
import IButton, { BUTTON } from "../../../../../components/IButton";
import CampaignQualification from "../../CampaignQualification";
import CampaignScheduleForms from "../../CampaignSchedule";
import CommonDrawer from "../../components/CommonDrawer";
import { validateSchedule } from "../../helper/validationHelper";
import CampaignScheduleReadonly from "./components/CampaignScheduleReadonly";
import { FaRoute } from "react-icons/fa";
import SectionWrapper from "./components/SectionWrapper";

export default function CampaignScheduleSection({
  schedule,
  rule,
  readonly,
  scheduleErrorText,
  ruleErrorText,
  onChange,
  onRuleValidityChange,
  isLoading,
  activeErrorCheck,
}: {
  schedule: CampaignSchedule;
  rule: CampaignRuleResp;
  readonly: boolean;
  scheduleErrorText: string;
  ruleErrorText: string;
  onChange: (schedule: CampaignSchedule, rule: CampaignRuleResp) => void;
  onRuleValidityChange: (valid: boolean) => void;
  isLoading: boolean;
  activeErrorCheck: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updatedSchedule, setUpdatedSchedule] =
    useState<CampaignSchedule>(schedule);

  const [updatedRule, setUpdatedRule] = useState<CampaignRuleResp>(rule);

  const [isRuleValid, setIsRuleValid] = useState(true);

  const [showErrors, setShowErrors] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setShowErrors(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setUpdatedRule(rule);
  }, [rule]);

  function onClickSave() {
    setShowErrors(true);
    const validationErrors = validateSchedule(updatedSchedule);
    if (Object.keys(validationErrors).length === 0 && isRuleValid) {
      onChange(updatedSchedule, updatedRule);
      onClose();
    }
  }

  const isSaveDisabled =
    (Object.keys(validateSchedule(updatedSchedule)).length > 0 ||
      !isRuleValid) &&
    showErrors;

  return (
    <>
      <SectionWrapper
        icon={FaRoute}
        title="Journey settings"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={scheduleErrorText || ruleErrorText}
        isLoading={isLoading}
        isEmpty={!schedule.run_type}
        isLastRow={true}
      >
        <CampaignScheduleReadonly
          onOpen={onOpen}
          data={schedule}
          rule={rule}
          scheduleErrorText={activeErrorCheck ? scheduleErrorText : ""}
          ruleErrorText={activeErrorCheck ? ruleErrorText : ""}
        />
      </SectionWrapper>
      <CommonDrawer
        title="Journey settings"
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <VStack
          alignItems="flex-start"
          spacing={26}
          className={readonly ? DISABLED_EDIT_CLASSNAME : ""}
          mb={20}
        >
          <CampaignScheduleForms
            data={schedule}
            onChange={(schedule) => setUpdatedSchedule(schedule)}
            showErrors={showErrors}
          />
          <CampaignQualification
            onChange={(rule) => setUpdatedRule(rule)}
            data={updatedRule}
            setRuleValid={(ruleValidity) => {
              setIsRuleValid(ruleValidity);
              onRuleValidityChange(ruleValidity);
            }}
            showErrors={showErrors}
          />
          <HStack justifyContent="flex-end" width="100%" hidden={readonly}>
            <IButton variant={BUTTON.SECONDARY} onClick={onClose}>
              Cancel
            </IButton>
            <IButton onClick={onClickSave} isDisabled={isSaveDisabled}>
              Done
            </IButton>
          </HStack>
        </VStack>
      </CommonDrawer>
    </>
  );
}
