import {
  Flex,
  Icon,
  Link,
  VStack,
  Text,
  Center,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Spacer,
  useDisclosure,
  PopoverArrow,
  HStack,
  Box,
  Image,
  Circle,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import urls from "../urls";
import { useMemo } from "react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRoute,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { MdSettings } from "react-icons/md";
import { RiDatabase2Fill } from "react-icons/ri";
import { HiSquare3Stack3D } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { logoutUser } from "../pages/account/accountSlice";
import SmallLogo from "../common/img/logo/logo.png";
import FullLogo from "../common/img/logo/inflection_white.png";
import IconWithTooltip from "./IconWithTooltip";

interface NavItemType {
  label: string;
  children?: Array<NavItemType>;
  path: string;
  icon?: IconType;
}

const NAV_ITEMS: Array<NavItemType> = [
  {
    label: "Journeys",
    path: urls.journey,
    icon: FaRoute,
  },
  {
    label: "Assets",
    path: urls.assets,
    icon: HiSquare3Stack3D,
    children: [
      {
        label: "Emails",
        path: urls.email,
      },
      {
        label: "Tokens",
        path: urls.token,
      },
      {
        label: "Static lists",
        path: urls.staticList,
      },
      {
        label: "Webhooks",
        path: urls.webhook,
      },
      { label: "Forms", path: urls.form },
    ],
  },
  {
    label: "Database",
    path: urls.database,
    icon: FaUsers,
    children: [
      {
        label: "Contacts",
        path: urls.person,
      },
      {
        label: "Accounts",
        path: urls.account,
      },
    ],
  },
  {
    label: "Connections",
    path: urls.unifiedMapping,
    icon: RiDatabase2Fill,
  },
  {
    label: "Settings",
    path: urls.settings,
    icon: MdSettings,
  },
];

function NavSubMenu({
  label,
  path,
  icon,
  onClick,
  isSelected,
}: NavItemType & { onClick?: () => void; isSelected?: boolean }) {
  return (
    <Link
      as={RouterLink}
      to={path}
      fontSize="sm"
      _hover={{
        textDecoration: "none",
        bg: "brandBlue.400",
      }}
      onClick={onClick}
      w="100%"
      h="30px"
    >
      <Flex alignItems="center" h="100%" px="3">
        {icon && <Icon mr="2" w="2" h="2" as={icon} />}
        <Text
          // Delay in applying boldness
          // transition="all 5s ease"
          fontWeight={isSelected ? "semibold" : 500}
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
}

function Logout({ collapsed }: { collapsed: boolean }) {
  const dispatch = useDispatch();
  return (
    <Link
      py={3}
      px={2}
      w="100%"
      _hover={{
        textDecoration: "none",
        bg: "brandBlue.400",
      }}
      onClick={() => dispatch(logoutUser())}
      cursor="pointer"
      position="relative"
    >
      <Box
        w="5px"
        position="absolute"
        left="0px"
        top="0px"
        bg="brandAqua.500"
        h="48px"
        visibility={"hidden"}
      ></Box>
      <HStack w="100%" justifyContent="flex-start" px="5" spacing="5">
        <IconWithTooltip
          label="Logout"
          icon={RiLogoutBoxRFill}
          fontSize="20px"
          tooltipProps={{ visibility: collapsed ? "visible" : "hidden" }}
          iconContainerProps={{ pt: "0" }}
        />
        <Text visibility={collapsed ? "hidden" : "visible"} fontWeight={500}>
          Logout
        </Text>
      </HStack>
    </Link>
  );
}

function NavbarItem({
  selected,
  label,
  path,
  icon,
  hideText,
  collapsed,
}: {
  selected: boolean;
  label: string;
  path: string;
  icon?: IconType;
  hideText?: boolean;
  collapsed: boolean;
}) {
  return (
    <Link
      as={RouterLink}
      py={3}
      px={2}
      to={path ?? "#"}
      w="100%"
      sx={
        selected
          ? {
              bg: "brandBlue.600",
              textDecoration: "none",
              fontWeight: 800,
            }
          : undefined
      }
      _hover={{
        textDecoration: "none",
        bg: "brandBlue.400",
      }}
      cursor="pointer"
      position="relative"
    >
      <Box
        w="5px"
        position="absolute"
        left="0px"
        top="0px"
        bg="brandAqua.500"
        h="48px"
        visibility={selected ? "visible" : "hidden"}
      ></Box>
      <HStack w="100%" justifyContent="flex-start" px="5" spacing="5">
        <IconWithTooltip
          label={label}
          icon={icon ?? FaUser}
          fontSize="20px"
          iconContainerProps={{ pt: "0" }}
          tooltipProps={{ visibility: collapsed ? "visible" : "hidden" }}
        />

        {!hideText && (
          <Text visibility={collapsed ? "hidden" : "visible"} fontWeight={500}>
            {label}
          </Text>
        )}
      </HStack>
    </Link>
  );
}

function PopoverSidebarMenu({
  currentNav,
  navItem,
}: {
  currentNav: string;
  navItem: NavItemType;
}) {
  const { onClose } = useDisclosure();
  const isSelected = useMemo(
    () =>
      !!navItem.children?.find(
        (x) => x.path === currentNav || currentNav.includes(x.path)
      ),
    [currentNav, navItem]
  );

  return (
    <Popover trigger="hover" placement="right-start" onClose={onClose}>
      <PopoverTrigger>
        <Link
          as={RouterLink}
          to={navItem.children?.[0]?.path ?? "#"}
          py={3}
          px={2}
          w="100%"
          sx={
            isSelected
              ? {
                  bg: "brandBlue.600",
                  textDecoration: "none",
                  fontWeight: 800,
                }
              : undefined
          }
          _hover={{
            textDecoration: "none",
            bg: "brandBlue.400",

            "&>svg": { display: "block" },
          }}
          position="relative"
          cursor="pointer"
          h="48px"
        >
          <Box
            w="5px"
            position="absolute"
            left="0px"
            top="0px"
            bg="teal.500"
            h="48px"
            visibility={isSelected ? "visible" : "hidden"}
          ></Box>

          <HStack w="100%" justifyContent="flex-start" pl="5" spacing="5">
            <IconWithTooltip
              label={navItem.label}
              icon={navItem.icon ?? FaUser}
              fontSize="20px"
              iconContainerProps={{ pt: "0" }}
              tooltipProps={{ visibility: "visible" }}
            />
          </HStack>

          <Icon
            position="absolute"
            right="18px"
            top="18px"
            opacity={0.5}
            display="none"
            fontSize="10px"
            as={FaChevronRight}
          />
        </Link>
      </PopoverTrigger>

      {navItem.children && (
        <PopoverContent
          w="max"
          minW="200px"
          border={0}
          boxShadow="xl"
          p={2}
          rounded="md"
          bg="brandBlue.500"
        >
          <PopoverArrow bg="brandBlue.500" />
          <Stack>
            {navItem.children.map((child) => {
              const selected =
                currentNav === child.path || currentNav.includes(child.path);
              return (
                <NavSubMenu
                  isSelected={selected}
                  key={child.label}
                  onClick={onClose}
                  {...child}
                />
              );
            })}
          </Stack>
        </PopoverContent>
      )}
    </Popover>
  );
}

function NavigationMenu({
  currentNav,
  collapsed,
}: {
  currentNav: string;
  collapsed: boolean;
}) {
  return (
    <VStack py={6} w="100%" spacing="0">
      {NAV_ITEMS.map((navItem) => {
        // Has sub menu
        if (navItem.children) {
          if (collapsed) {
            return (
              <Flex key={navItem.label} w="100%">
                <PopoverSidebarMenu currentNav={currentNav} navItem={navItem} />
              </Flex>
            );
          } else {
            return (
              <Flex key={navItem.label} w="100%">
                <AccordionNavMenu currentNav={currentNav} navItem={navItem} />
              </Flex>
            );
          }
        } else {
          // Is Single Menu
          return (
            <NavbarItem
              path={navItem.path}
              selected={
                currentNav === navItem.path || currentNav.includes(navItem.path)
              }
              label={navItem.label}
              icon={navItem.icon}
              collapsed={collapsed}
            />
          );
        }
      })}
    </VStack>
  );
}

function AccordionNavMenu({
  currentNav,
  navItem,
}: {
  currentNav: string;
  navItem: NavItemType;
}) {
  const isSelected = useMemo(
    () =>
      !!navItem.children?.find(
        (x) => x.path === currentNav || currentNav.includes(x.path)
      ),
    [currentNav, navItem]
  );

  return (
    <Accordion
      w="100%"
      allowToggle
      cursor="pointer"
      sx={
        isSelected
          ? {
              bg: "brandBlue.600",
              textDecoration: "none",
              fontWeight: 800,
            }
          : undefined
      }
    >
      <AccordionItem border="unset" boxSizing="unset">
        <AccordionButton
          _hover={{
            bg: "brandBlue.400",
          }}
          py={1}
          px={2}
          h="48px"
          sx={
            isSelected
              ? {
                  bg: "brandBlue.600",
                  textDecoration: "none",
                  fontWeight: 800,
                }
              : undefined
          }
        >
          <HStack w="100%" justifyContent="flex-start" px="5" spacing="5">
            <Center>
              <Icon fontSize="20px" as={navItem.icon ?? FaUser} />
            </Center>
            <Text fontWeight={500}>{navItem.label}</Text>
          </HStack>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pl="7">
          <Stack spacing="0" borderLeftColor="white" borderLeftWidth="1px">
            {navItem.children!.map((child) => {
              const selected =
                currentNav === child.path || currentNav.includes(child.path);
              return (
                <HStack w="100%" position="relative" spacing="0">
                  <Box
                    w="4px"
                    position="absolute"
                    left="0px"
                    top="0px"
                    bg="brandAqua.500"
                    h="30px"
                    visibility={selected ? "visible" : "hidden"}
                  ></Box>
                  <NavSubMenu
                    isSelected={selected}
                    key={child.label}
                    {...child}
                  />
                </HStack>
              );
            })}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function LogoSection({
  collapsed,
  goToHomepage,
  setCollapsed,
}: {
  collapsed: boolean;
  goToHomepage: () => void;
  setCollapsed: { on: () => void; off: () => void; toggle: () => void };
}) {
  return (
    <Center cursor="pointer" h="64px">
      {collapsed ? (
        <Image src={SmallLogo} alt="Inflection" h="7" onClick={goToHomepage} />
      ) : (
        <Image
          src={FullLogo}
          alt="Inflection"
          display="block"
          flexShrink={0}
          mr="37px"
          h="7"
          onClick={goToHomepage}
        />
      )}
      <Circle
        borderColor="gray.100"
        borderWidth="1px"
        bg="white"
        size="22px"
        position="absolute"
        right={-3}
        onClick={() => setCollapsed.toggle()}
        pr={collapsed ? "0px" : "2px"}
      >
        <Icon
          fontSize="14"
          color="teal.500"
          as={collapsed ? FaChevronRight : FaChevronLeft}
        />
      </Circle>
    </Center>
  );
}

export default function SideNavbar({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
}) {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  //only when the path name changes this useMemo runs.
  //if the component re-renders and still the path is same , the useMemo won't run
  const currentNav = useMemo(() => path, [path]);

  function goToHomepage() {
    navigate(urls.home);
  }

  return (
    <Flex
      w={collapsed ? "80px" : "250px"}
      bg="brandBlue.500"
      color="white"
      h="100vh"
      zIndex="2"
      transition="width 0.1s ease-in"
    >
      <VStack w="100%" spacing="0" position="relative">
        <LogoSection
          collapsed={collapsed}
          goToHomepage={goToHomepage}
          setCollapsed={setCollapsed}
        />
        <NavigationMenu currentNav={currentNav} collapsed={collapsed} />
        <Spacer />
        <VStack w="100%">
          <Logout collapsed={collapsed} />
        </VStack>
      </VStack>
    </Flex>
  );
}
