import { IconButton, Icon, IconButtonProps } from "@chakra-ui/react";
import { FaMinusCircle } from "react-icons/fa";

export default function RemoveRowButton({
  onClick,
  ...props
}: { onClick: () => void } & Partial<IconButtonProps>) {
  return (
    <IconButton
      rounded="full"
      name="remove-button"
      variant="ghost"
      size="sm"
      icon={<Icon fontSize="14px" as={FaMinusCircle} />}
      onClick={onClick}
      color="red.300"
      {...props}
      aria-label="Remove"
    />
  );
}
