import React from "react";
import LayoutWithSideNavBar from "../layout/LayoutWithSideNavBar";
import SpinnerContainer from "./SpinnerContainer";

export default function Fallback() {
  return (
    <LayoutWithSideNavBar>
      <SpinnerContainer loading height="100vh" width="100%">
        ...
      </SpinnerContainer>
    </LayoutWithSideNavBar>
  );
}
