import { Flex, Avatar, Text, Tooltip } from "@chakra-ui/react";
import { checkIsAccountInactive } from "../common/helper/commonHelper";
import { USER_ACCOUNT_STATUS } from "../common/types/auth";

export default function UserNameWithAvatar({
  username,
  accountStatus,
}: {
  username: string;
  accountStatus?: USER_ACCOUNT_STATUS;
}) {
  const isAccountInactive = checkIsAccountInactive(accountStatus as string);
  if (!username) return <></>;
  return (
    <Tooltip
      label="The user is inactive"
      fontSize="xs"
      position="initial"
      size="small"
      isDisabled={!isAccountInactive}
    >
      <Flex alignItems="center">
        <Avatar
          mr={2}
          size="xs"
          name={username}
          opacity={isAccountInactive ? "0.5" : ""}
        />
        <Text color={isAccountInactive ? "gray.300" : ""} fontSize={14}>
          {username}
        </Text>
      </Flex>
    </Tooltip>
  );
}
