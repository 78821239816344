import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectWebhook } from "../webhookSlice";
import { isFinished, isLoading } from "../../../../common/helper/commonHelper";
import { toJson } from "../helper";
import CodemirrorInput from "../../../../components/codemirror/CodemirrorInput";
import { gutter } from "../../../../components/codemirror/codemirrorModules";
import { EditorView } from "@codemirror/view";
import { WebhookPersonLookup } from "./WebhookPersonLookup";
import { useEffect, useRef, useState } from "react";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import SubHeader from "../../settings/components/SubHeader";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";

enum WEBHOOK_PREVIEW {
  RESPONSE = "response",
  REQUEST = "request",
}

function WebhookPreview() {
  return (
    <Box width="calc(100vw - 450px)" height="calc(100vh - 140px)" py={2}>
      <SubHeader
        boxProps={{ px: "3" }}
        title="Response preview"
        titleProps={{ color: "gray.700", fontSize: "md" }}
        desc="Send request as"
        descriptionProps={{ color: "gray.500", fontSize: "xs" }}
      />
      <WebhookPersonLookup />
      <Tabs height="calc(100vh - 250px)">
        <TabList>
          <Tab fontSize="sm">Response</Tab>
          <Tab fontSize="sm">Request</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <WebhookPreviewTabs action={WEBHOOK_PREVIEW.RESPONSE} />
          </TabPanel>
          <TabPanel p={0}>
            <WebhookPreviewTabs action={WEBHOOK_PREVIEW.REQUEST} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

function WebhookPreviewTabs({ action }: { action: WEBHOOK_PREVIEW }) {
  const {
    webhookPreview: { data: previewWebhookData, loading: fetchingPreview },
    webhookDetails: { loading: fetchingWebhookDetails },
  } = useSelector(selectWebhook);
  const [data, setData] = useState(
    JSON.stringify(previewWebhookData, undefined, 2)
  );
  const reinitCodemirror = useRef(false);

  useEffect(() => {
    reinitCodemirror.current = true;

    const webhook =
      action === WEBHOOK_PREVIEW.RESPONSE
        ? {
            status_code: previewWebhookData.status_code,
            webhook_result: toJson(previewWebhookData.webhook_result),
          }
        : {
            url: previewWebhookData.url,
            headers: previewWebhookData.headers,
            body: toJson(previewWebhookData.body),
          };
    setData(JSON.stringify(webhook, undefined, 3));

    setTimeout(() => {
      reinitCodemirror.current = false;
    }, 3000);
  }, [action, previewWebhookData]);

  return (
    <Box
      height="calc(100vh - 193px)"
      fontSize="sm"
      className="codemirror-wrapper"
    >
      <ISkeleton
        variant={SKELETON_VARIANT.TEXT_AREA}
        isLoaded={isFinished(fetchingWebhookDetails)}
      >
        <SpinnerContainer
          loading={isLoading(fetchingPreview)}
          height="100%"
          width="100%"
        >
          <CodemirrorInput
            reinitialize={reinitCodemirror.current}
            value={data}
            lang="json"
            onChange={(code: string) => {}}
            extensions={[gutter.lineNumbers(), EditorView.lineWrapping]}
            defaultVariables={true}
            isDisabled
          />
        </SpinnerContainer>
      </ISkeleton>
    </Box>
  );
}

export default WebhookPreview;
