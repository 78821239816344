import {
  NodeGroup,
  Pattern,
  PatternOperator,
  SequencePatternNode,
} from "../../../../../common/types/dynamicList";
import TriggerGroupWrapper from "./TriggerGroupWrapper";
import ProductEventGroup from "./ProductEventGroup";
import MarketingEventGroup from "./MarketingEventGroup";
import {
  COMPARISON_OPERATORS,
  EVENT_CATEGORY,
  FLINK_CEP_OPERARTORS,
} from "../../../../../common/constants/dynamicList";
import { createGroupId } from "../../../../../common/helper/commonHelper";
import { cloneDeep } from "lodash";
import IButton from "../../../../../components/IButton";
import { RxPlus } from "react-icons/rx";
import {
  Divider,
  VStack,
  Text,
  HStack,
  Icon,
  Box,
  Badge,
  StackProps,
} from "@chakra-ui/react";
import IModal from "../../../../../components/IModal";
import { useCallback, useEffect, useState } from "react";
import NumberField from "../../../../../components/NumberField";
import { NUMBER_FIELD_TYPES } from "../../../../../components/NumberField";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import {
  TIME_FRAME_TYPE,
  TIME_FRAME_UNIT,
} from "../../../../../common/constants/trigger";
import { IoWarning } from "react-icons/io5";
import { addSuffixForPlural } from "../../../../../common/helper/commonHelper";
import { MdEdit } from "react-icons/md";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { BsLightningChargeFill } from "react-icons/bs";

const OUTER_GROUP_ID = "trigger-criteria-sequence-group";
const INIT_PATTERN_DATA: Pattern = {
  eventName: null,
  conditionGroups: [],
  patternOperators: [],
};
const INIT_PATTERN_FOLLOWED: PatternOperator = {
  patternOperator: FLINK_CEP_OPERARTORS.FOLLOWED_BY,
  timeframe: null,
};

const INIT_PATTERN_WITHIN: PatternOperator = {
  patternOperator: FLINK_CEP_OPERARTORS.WITHIN,
  timeframe: {
    type: TIME_FRAME_TYPE.RELATIVE,
    operator: COMPARISON_OPERATORS.EQUALS,
    unit: TIME_FRAME_UNIT.DAY,
    start: 0,
    end: 0,
  },
};

const frequencies = {
  Hour: "HOUR",
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
  Year: "YEAR",
};
function TimeIntervalModal({
  data,
  node,
  nodeIndex,
  patternIndex,
  isOpen,
  onClose,
  onChange,
}: {
  data: NodeGroup;
  node: SequencePatternNode;
  nodeIndex: number;
  patternIndex: number;
  isOpen: boolean;
  onClose: () => void;
  onChange: (data: NodeGroup) => void;
}) {
  const patternOperator =
    node.patternGroup[patternIndex + 1]?.patternOperators.filter(
      (p) => p.patternOperator === FLINK_CEP_OPERARTORS.WITHIN
    )[0] || INIT_PATTERN_WITHIN;
  const [patternOperatorData, setPatternOperatorData] =
    useState<PatternOperator>(patternOperator);
  const [invalidUnit, setInvalidUnit] = useState<boolean>(false);
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const onHandleClose = () => {
    setPatternOperatorData(patternOperator);
    onClose();
  };
  const validate = useCallback(() => {
    let isValid = true;
    if (
      patternOperatorData.timeframe &&
      patternOperatorData.timeframe.start <= 0
    ) {
      setInvalidUnit(true);
      isValid = false;
    } else {
      setInvalidUnit(false);
      isValid = true;
    }
    return isValid;
  }, [patternOperatorData.timeframe]);

  useEffect(() => {
    if (isValidate) validate();
  }, [patternOperatorData?.timeframe?.start, validate, isValidate]);

  function submitHandler() {
    setIsValidate(true);
    if (!validate()) return;
    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    const withInDataIndex = newNode.patternGroup[
      patternIndex + 1
    ].patternOperators.findIndex(
      (p) => p.patternOperator === FLINK_CEP_OPERARTORS.WITHIN
    );
    if (withInDataIndex >= 0) {
      newNode.patternGroup[patternIndex + 1].patternOperators[withInDataIndex] =
        patternOperatorData;
    } else {
      newNode.patternGroup[patternIndex + 1].patternOperators.push(
        patternOperatorData
      );
    }
    dataCopy.nodes[nodeIndex] = newNode;
    onChange(dataCopy);
    onClose();
  }
  return (
    <IModal
      isOpen={isOpen}
      onClose={onHandleClose}
      header={{
        title: "Time interval",
      }}
      primaryButton={{
        label: "Save",
        props: {
          onClick: submitHandler,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onHandleClose,
        },
      }}
    >
      <VStack alignItems="flex-start" spacing="1">
        <Text fontSize="sm">
          Specify a time interval for the next trigger sequence
        </Text>
        <HStack alignItems="initial" mt={3} spacing={6}>
          <VStack alignItems="flex-start">
            <Text fontSize="12px">Time</Text>
            <NumberField
              type={NUMBER_FIELD_TYPES.INTEGER}
              value={patternOperatorData?.timeframe?.start || 0}
              min={1}
              size="sm"
              width="90px"
              fieldProps={{
                borderRadius: "6px",
              }}
              onValueChange={(value) => {
                setPatternOperatorData((prevState: PatternOperator) => {
                  if (!prevState) {
                    return prevState;
                  }
                  const unitValue: TIME_FRAME_UNIT =
                    prevState.timeframe?.unit || TIME_FRAME_UNIT.DAY;
                  return {
                    ...prevState,
                    timeframe: {
                      type: TIME_FRAME_TYPE.RELATIVE,
                      start: (value as number) || 0,
                      end: 0,
                      operator: COMPARISON_OPERATORS.EQUALS,
                      unit: unitValue.toUpperCase() as TIME_FRAME_UNIT,
                    },
                  };
                });
              }}
              isInvalid={invalidUnit}
            />
            {invalidUnit && (
              <HStack>
                <Icon as={IoWarning} color="red.500"></Icon>
                <Text fontSize="12px" color="red.500">
                  Invalid unit
                </Text>
              </HStack>
            )}
          </VStack>
          <VStack alignItems="flex-start">
            <Text fontSize="12px">Unit</Text>
            <DropdownWithSearch
              options={Object.entries(frequencies).map(([label, frequency]) => {
                return { label: label, value: frequency };
              })}
              value={
                patternOperatorData?.timeframe?.unit
                  ? Object.entries(frequencies)
                      .filter(
                        ([_, frequency]) =>
                          frequency === patternOperatorData?.timeframe?.unit
                      )
                      .map(([label, frequency]) => {
                        return { label: label, value: frequency };
                      })
                  : undefined
              }
              onChange={(option) => {
                setPatternOperatorData((prevState: PatternOperator) => {
                  if (!prevState) {
                    return prevState;
                  }
                  const unitValue: string = option?.value
                    ? option.value
                    : prevState.timeframe?.unit || TIME_FRAME_UNIT.DAY;
                  return {
                    ...prevState,
                    timeframe: {
                      type: TIME_FRAME_TYPE.RELATIVE,
                      start: prevState.timeframe?.start || 0,
                      end: 0,
                      operator: COMPARISON_OPERATORS.EQUALS,
                      unit: unitValue.toUpperCase() as TIME_FRAME_UNIT,
                    },
                  };
                });
              }}
              isSearchable={false}
              isInvalid={false}
              controlStyle={{ width: "110px" }}
            />
          </VStack>
        </HStack>
      </VStack>
    </IModal>
  );
}
function buildTimeInterval(
  node: SequencePatternNode,
  patternIndex: number,
  isReadOnly: boolean | undefined
) {
  let text = "";
  const patternOperators =
    node.patternGroup[patternIndex + 1]?.patternOperators || [];
  if (!patternOperators.length) text += "";
  const withInData = patternOperators.filter(
    (p) => p.patternOperator === FLINK_CEP_OPERARTORS.WITHIN
  );
  if (withInData.length) {
    text += `Within ${withInData[0].timeframe?.start} ${addSuffixForPlural(
      (withInData[0].timeframe?.unit || "").toLowerCase(),
      withInData[0].timeframe?.start
    )}`;
  } else {
    text += !isReadOnly ? "Add time interval" : "Followed by";
  }
  return text;
}

function checkForWithinOperator(
  node: SequencePatternNode,
  patternIndex: number
) {
  const patternOperators =
    node.patternGroup[patternIndex + 1]?.patternOperators || [];
  const withInData = patternOperators.filter(
    (p) => p.patternOperator === FLINK_CEP_OPERARTORS.WITHIN
  );
  return withInData.length === 1;
}

function PatternGroupContainer({
  data,
  node,
  index,
  patternIndex,
  pattern,
  onChange,
  isReadOnly,
}: {
  data: NodeGroup;
  node: SequencePatternNode;
  index: number;
  patternIndex: number;
  pattern: Pattern;
  onChange: (data: NodeGroup) => void;
  isReadOnly?: boolean;
}) {
  const stackStyle: StackProps = !isReadOnly
    ? {
        pl: 6,
      }
    : {
        py: 0,
      };
  function closeTimeIntervalModal() {
    setOpenTimeIntervalModal(false);
  }
  const [dragIndex, setDragIndex] = useState<number>(-1);
  const [openTimeIntervalModal, setOpenTimeIntervalModal] =
    useState<boolean>(false);
  const onRemoveTimeInterval = () => {
    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    const withInDataIndex = newNode.patternGroup[
      patternIndex + 1
    ].patternOperators.findIndex(
      (p) => p.patternOperator === FLINK_CEP_OPERARTORS.WITHIN
    );
    if (withInDataIndex >= 0) {
      newNode.patternGroup[patternIndex + 1].patternOperators.splice(
        withInDataIndex,
        1
      );
      dataCopy.nodes[index] = newNode;
      onChange(dataCopy);
    }
  };
  return (
    <>
      <PatternRowBox
        data={data}
        node={node}
        pattern={pattern}
        patternIndex={patternIndex}
        nodeIndex={index}
        isReadOnly={isReadOnly}
        onChange={onChange}
        dragIndex={dragIndex}
        setDragIndex={setDragIndex}
      />
      {pattern.patternOperators.filter(
        (p) => p.patternOperator === FLINK_CEP_OPERARTORS.FOLLOWED_BY
      ).length === 1 && (
        <HStack my="0 !important" {...stackStyle}>
          <Divider
            orientation="vertical"
            borderColor="brandBlue.500"
            height={
              !isReadOnly
                ? checkForWithinOperator(node, patternIndex)
                  ? "64px"
                  : "52px"
                : ""
            }
          ></Divider>
          {!isReadOnly ? (
            <>
              <IButton
                size="sm"
                variant="link"
                leftIcon={
                  !checkForWithinOperator(node, patternIndex) ? (
                    <RxPlus fontSize="12px" />
                  ) : (
                    <></>
                  )
                }
                rightIcon={
                  checkForWithinOperator(node, patternIndex) ? (
                    <MdEdit fontSize="12px" />
                  ) : (
                    <></>
                  )
                }
                onClick={() => {
                  setOpenTimeIntervalModal(true);
                }}
                name="add-interval-time"
                color="brand.blue"
                ml={4}
                fontWeight="normal"
              >
                {buildTimeInterval(node, patternIndex, isReadOnly)}
              </IButton>
              {checkForWithinOperator(node, patternIndex) && (
                <>
                  <Divider h="20px" mt="6px" orientation="vertical" />
                  <RemoveRowCloseButton onClick={onRemoveTimeInterval} />
                </>
              )}
            </>
          ) : (
            <VStack alignItems="flex-start" spacing={0}>
              <Badge
                py="2px"
                px="6px"
                bg="grayV2.200"
                textTransform="none"
                width="150px"
                borderRadius="4px"
                borderWidth={0}
                borderColor="brand.blue"
                zIndex={1}
                color="brand.blue"
                fontSize="10px"
                mt="10px"
                mb="8px"
              >
                {buildTimeInterval(node, patternIndex, isReadOnly)}
              </Badge>
            </VStack>
          )}
        </HStack>
      )}
      {openTimeIntervalModal && (
        <TimeIntervalModal
          data={data}
          node={node}
          patternIndex={patternIndex}
          nodeIndex={index}
          isOpen={openTimeIntervalModal}
          onClose={closeTimeIntervalModal}
          onChange={onChange}
        />
      )}
    </>
  );
}

function PatternRowBox({
  data,
  pattern,
  node,
  patternIndex,
  nodeIndex,
  isReadOnly,
  onChange,
  dragIndex,
  setDragIndex,
}: {
  data: NodeGroup;
  pattern: Pattern;
  node: SequencePatternNode;
  patternIndex: number;
  nodeIndex: number;
  isReadOnly?: boolean;
  onChange: (data: NodeGroup) => void;
  dragIndex: number;
  setDragIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  function updateFilterGroup(patternIndex: number, newData: Pattern) {
    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    newNode.patternGroup[patternIndex] = newData;
    dataCopy.nodes[nodeIndex] = newNode;
    onChange(dataCopy);
  }
  function onRemoveFilterGroup(patternIndex: number) {
    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    const isAfterNode = newNode.patternGroup[patternIndex + 1];
    newNode.patternGroup = newNode.patternGroup.filter(
      (_, i) => i !== patternIndex
    );
    const patternGroupLength = newNode.patternGroup.length;
    if (!isAfterNode) {
      if (newNode.patternGroup[patternIndex - 1]) {
        const prevPatternOperators =
          newNode.patternGroup[patternIndex - 1].patternOperators;
        const withInOperatorIndex = prevPatternOperators.findIndex(
          (p) => p.patternOperator === FLINK_CEP_OPERARTORS.FOLLOWED_BY
        );
        newNode.patternGroup[patternIndex - 1]?.patternOperators.splice(
          withInOperatorIndex,
          1
        );
      }
    }
    dataCopy.nodes[nodeIndex] = newNode;
    if (!patternGroupLength) dataCopy.nodes.splice(nodeIndex, 1);
    onChange(dataCopy);
  }

  useEffect(() => {
    setDragIndex(-1);
  }, [data, setDragIndex]);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    dragIndex: number
  ) => {
    event.dataTransfer.setData("text/plain", dragIndex.toString());
    setDragIndex(dragIndex);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    const parentContainer = (event.target as HTMLElement)?.parentNode;
    if (parentContainer instanceof HTMLElement) {
      const parentRect = parentContainer.getBoundingClientRect();
      const x = event.clientX;
      const y = event.clientY;

      // Check if the dragged element is outside the parent container
      if (
        x < parentRect.left ||
        x > parentRect.right ||
        y < parentRect.top ||
        y > parentRect.bottom
      ) {
        event.preventDefault();
        setDragIndex(-1);
      }
    }
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ) => {
    event.preventDefault();

    const dragIndex = Number(event.dataTransfer.getData("text/plain"));
    if (dragIndex === targetIndex) {
      setDragIndex(-1);
      return;
    }

    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    const dragItem = cloneDeep(newNode.patternGroup[dragIndex]);
    const targetItem = cloneDeep(newNode.patternGroup[targetIndex]);

    if (!dragItem) {
      setDragIndex(-1);
      return;
    }
    const tempEventName = dragItem.eventName;
    const tempConditionGroups = dragItem.conditionGroups;
    dragItem.eventName = targetItem.eventName;
    dragItem.conditionGroups = targetItem.conditionGroups;
    targetItem.eventName = tempEventName;
    targetItem.conditionGroups = tempConditionGroups;

    newNode.patternGroup[dragIndex] = dragItem;
    newNode.patternGroup[targetIndex] = targetItem;

    dataCopy.nodes[nodeIndex] = newNode;
    onChange(dataCopy);
  };
  return (
    <Box
      draggable={!isReadOnly}
      onDragStart={(event) => handleDragStart(event, patternIndex)}
      onDragOver={handleDragOver}
      onDrop={(event) => handleDrop(event, patternIndex)}
      onDrag={handleDrag}
      width="100%"
      rounded="lg"
      borderColor="grayV2.200"
      borderWidth={!isReadOnly ? "1px" : ""}
      bg="grayV2.200"
      borderStyle={dragIndex === patternIndex ? "ridge" : ""}
      opacity={dragIndex === patternIndex ? "0.5" : "1"}
      mt="0 !important"
    >
      {node.eventCategory === EVENT_CATEGORY.PRODUCT_ACTIVITY ? (
        <ProductEventGroup
          data={pattern}
          id={createGroupId(OUTER_GROUP_ID, "filter", patternIndex + 1)}
          onChange={(data) => updateFilterGroup(patternIndex, data)}
          onRemove={() => onRemoveFilterGroup(patternIndex)}
          isReadOnly={isReadOnly}
          isDraggable={node.patternGroup.length > 1}
        />
      ) : (
        <MarketingEventGroup
          data={pattern}
          id={createGroupId(OUTER_GROUP_ID, "filter", patternIndex + 1)}
          onChange={(data) => updateFilterGroup(patternIndex, data)}
          onRemove={() => onRemoveFilterGroup(patternIndex)}
          isReadOnly={isReadOnly}
          isDraggable={node.patternGroup.length > 1}
        />
      )}
    </Box>
  );
}

export function PatternGroupBox({
  data,
  node,
  index,
  onChange,
  isReadOnly,
}: {
  data: NodeGroup;
  node: SequencePatternNode;
  index: number;
  onChange: (data: NodeGroup) => void;
  isReadOnly?: boolean;
}) {
  function onAddEvent() {
    const dataCopy = cloneDeep(data);
    const newNode = cloneDeep(node);
    newNode.patternGroup = [...newNode.patternGroup, INIT_PATTERN_DATA];
    const patternGroupLength = newNode.patternGroup.length;
    if (patternGroupLength > 1) {
      newNode.patternGroup[patternGroupLength - 2].patternOperators.push(
        INIT_PATTERN_FOLLOWED
      );
    }
    dataCopy.nodes[index] = newNode;
    onChange(dataCopy);
  }

  return (
    <>
      {node?.patternGroup?.length > 0 && (
        <TriggerGroupWrapper
          isReadOnly={isReadOnly}
          type={node.eventCategory || EVENT_CATEGORY.PRODUCT_ACTIVITY}
          nodeType={node.nodeType}
          index={index}
        >
          <VStack
            w="100%"
            alignItems="flex-start"
            spacing={0}
            id={`group-container-${index}`}
            rounded={isReadOnly ? "md" : ""}
            bg={isReadOnly ? "grayV2.100" : ""}
          >
            {node.patternGroup.map((pattern, patternIndex) => {
              return (
                <>
                  <PatternGroupContainer
                    data={data}
                    pattern={pattern}
                    node={node}
                    patternIndex={patternIndex}
                    index={index}
                    isReadOnly={isReadOnly}
                    onChange={onChange}
                  />
                </>
              );
            })}
            {!isReadOnly && (
              <Box pt={4}>
                <IButton
                  size="sm"
                  variant="button"
                  bg="grayV2.100"
                  color="brand.blue"
                  borderColor="brand.blue"
                  borderWidth="1px"
                  _hover={{ bg: "grayV2.200" }}
                  _active={{ bg: "grayV2.200" }}
                  leftIcon={
                    <BsLightningChargeFill color="brand.blue" fontSize="12px" />
                  }
                  onClick={onAddEvent}
                  name="add-sequence-event-group"
                  fontWeight="normal"
                >
                  Add another event
                </IButton>
              </Box>
            )}
          </VStack>
        </TriggerGroupWrapper>
      )}
    </>
  );
}
