import {
  Box,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Image,
  VStack,
  Grid,
  Icon,
  Link,
  Center,
} from "@chakra-ui/react";
import {
  FaArrowDown,
  FaArrowRight,
  FaArrowUp,
  FaExclamationCircle,
} from "react-icons/fa";
import salesforceLogo from "../common/img/logo/salesforce.png";
import inflectionLogo from "../common/img/logo/Inflection.jpg";

function InflectionImageComponent() {
  return <Image src={inflectionLogo} alt="Inflection" boxSize="15px" />;
}

function SalesforceImageContent() {
  return <Image src={salesforceLogo} alt="Salesforce" boxSize="15px" />;
}
interface PopoverSendData {
  name: string;
  updated: number;
  imported: number;
  error: { readError: number; writeError: number };
  permissions: boolean | undefined;
}

export default function SyncHistoryTableEntry({
  readData,
  writeData,
  type,
  sendData,
  showErrorsDuringDuration,
  currentDate,
}: {
  readData: number;
  writeData: number;
  type: string;
  sendData: PopoverSendData[];
  showErrorsDuringDuration: (date: Date) => void;
  currentDate: string;
}) {
  let readError = sendData[0].error.readError;
  let writeError = sendData[0].error.writeError;
  let objectPermission = sendData[0].permissions;

  if (sendData.length === 2) {
    readError = sendData[0].error.readError + sendData[1].error.readError;
    writeError = sendData[0].error.writeError + sendData[1].error.writeError;
    objectPermission = sendData[0].permissions || sendData[1].permissions;
  }

  function SummaryValues() {
    return (
      <Box pt="12px">
        <HStack bg="blackAlpha.50" py={2} px={5}>
          <Icon as={FaArrowDown} color="blue.600" />
          <Text>{readData}</Text>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="25px"
          />
          <Icon as={FaArrowUp} color="green.400" />
          <Text>{writeData}</Text>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="25px"
          />
          <FaExclamationCircle
            color={readError + writeError === 0 ? "gray" : "red"}
          />
          <Text>{readError + writeError}</Text>
        </HStack>
      </Box>
    );
  }

  function SyncData({
    childrenLeft,
    childrenRight,
  }: {
    childrenLeft: JSX.Element;
    childrenRight: JSX.Element;
  }) {
    return (
      <Box px="20px" pt="15px" pb="10px">
        <Grid templateColumns="6fr 1fr 6fr" gap={4}>
          <VStack alignItems="flex-start" py="2">
            <HStack>
              <Box>
                <SalesforceImageContent />
              </Box>
              <FaArrowRight />
              <Box>
                <InflectionImageComponent />
              </Box>
            </HStack>
            <VStack
              fontSize="smaller"
              pt="5px"
              spacing={0}
              alignItems="flex-start"
            >
              {childrenLeft}
            </VStack>
          </VStack>
          <Center>
            <Divider
              orientation="vertical"
              borderColor="blackAlpha.400"
              height="100%"
            />
          </Center>
          <VStack alignItems="flex-start" py="2">
            <HStack>
              <Box>
                <InflectionImageComponent />
              </Box>
              <FaArrowRight />
              <Box>
                <SalesforceImageContent />
              </Box>
            </HStack>
            <VStack
              fontSize="smaller"
              pt="5px"
              spacing={1}
              alignItems="flex-start"
            >
              {childrenRight}
            </VStack>
          </VStack>
        </Grid>
      </Box>
    );
  }

  function navigateToSyncHistoryTab() {
    showErrorsDuringDuration(new Date(currentDate));
  }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack pt="15px" pb="15px" pl="20px">
          <Icon
            as={FaArrowDown}
            color={objectPermission ? "blue.600" : "gray"}
          />
          <Text>{readData}</Text>
          {objectPermission && (
            <Link onClick={navigateToSyncHistoryTab}>
              <Text color="red" as="u">
                {" "}
                ({readError})
              </Text>
            </Link>
          )}
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="30px"
          />
          <Icon
            as={FaArrowUp}
            color={objectPermission ? "green.400" : "gray"}
          />
          <Text>{writeData}</Text>
          {objectPermission && (
            <Link onClick={navigateToSyncHistoryTab}>
              <Text color="red" as="u">
                {" "}
                ({writeError})
              </Text>
            </Link>
          )}
        </HStack>
      </PopoverTrigger>
      {objectPermission && (
        <PopoverContent width="400px">
          <PopoverBody>
            <Box pt="5px">
              <Box pt="10px" pl="25px">
                <Text>{type} Sync History</Text>
              </Box>
              <SummaryValues />
              <>
                <SyncData
                  childrenLeft={
                    <>
                      <Text>
                        {sendData[0].name} Imported : {sendData[0].imported}
                      </Text>
                      {sendData.length === 2 ? (
                        <Text>
                          {sendData[1].name} Imported : {sendData[1].imported}
                        </Text>
                      ) : null}
                      <Text>
                        Errors :{" "}
                        {sendData[0].error.readError +
                          (sendData.length === 2
                            ? sendData[1].error.readError
                            : 0)}
                      </Text>
                    </>
                  }
                  childrenRight={
                    <>
                      <Text>
                        {sendData[0].name} Updated : {sendData[0].updated}
                      </Text>
                      {sendData.length === 2 ? (
                        <Text>
                          {sendData[1].name} Updated : {sendData[1].updated}
                        </Text>
                      ) : null}
                      <Text>
                        Errors :{" "}
                        {sendData[0].error.writeError +
                          (sendData.length === 2
                            ? sendData[1].error.writeError
                            : 0)}
                      </Text>
                    </>
                  }
                />
              </>
            </Box>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
}
