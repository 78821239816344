import { Text } from "@chakra-ui/react";
import IModal from "../../../../../components/IModal";
import { SegmentAsSource } from "./SegmentSyncs";

export function ApiKeyConfirmationModal({
  isOpen,
  onCancel,
  onProceed,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onProceed: () => void;
}) {
  return (
    <IModal
      isOpen={isOpen}
      onClose={onCancel}
      header={{
        title: "Refresh key",
      }}
      primaryButton={{
        label: "Proceed",
        props: {
          onClick: onProceed,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onCancel,
        },
      }}
    >
      <Text fontSize="14px">
        Do you want to refresh the segment api keys? It will deactivate your
        existing key and create a new one. You will not be able to send any
        segment events with the deactivated key.
      </Text>
    </IModal>
  );
}

export function SegmentApiKeyModal({
  isOpen,
  onCancel,
  onConfirm,
  isLoading,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}) {
  return (
    <IModal
      isOpen={isOpen}
      onClose={onCancel}
      header={{
        title: "Segment",
      }}
      primaryButton={{
        label: "Done",
        props: {
          onClick: onConfirm,
          isLoading,
        },
      }}
      hideCloseButton={true}
    >
      <SegmentAsSource />
    </IModal>
  );
}
