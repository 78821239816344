import {
  VStack,
  Flex,
  Box,
  IconButton,
  useBoolean,
  Icon,
  HStack,
  Text,
  Collapse,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { BiCollapseAlt, BiExpandAlt } from "react-icons/bi";
import { FaArrowLeft } from "react-icons/fa";
import IButton from "../../../../components/IButton";

export default function CampaignSidebar({
  backButton,
  titleComponent,
  campaignName,
  isFetchingDetails,
  children,
}: {
  backButton: { label: string; handler: () => void };
  titleComponent: ReactNode;
  campaignName: string;
  isFetchingDetails: boolean;
  children: ReactNode;
}) {
  const [collapsed, setCollapsed] = useBoolean(false);

  return (
    <VStack
      width="410px"
      spacing="0"
      alignItems="flex-start"
      bg="white"
      position="absolute"
      rounded="md"
      zIndex={1}
    >
      <Flex
        h="46px"
        w="100%"
        py="12px"
        px="20px"
        justifyContent="space-between"
        alignItems="center"
        shadow="sm"
      >
        {collapsed ? (
          <HStack alignItems="center" w="calc(100% - 30px)">
            <IconButton
              aria-label="Go back"
              minW="0"
              color="grayV2.600"
              variant="link"
              onClick={backButton.handler}
              bg="basic.title"
              py={1}
              px={2}
              icon={<Icon as={FaArrowLeft} fontSize="14px" />}
            />
            <Box h="20px" w="calc(100% - 35px)">
              <Text
                fontWeight="bold"
                fontSize="sm"
                lineHeight="20px"
                w="100%"
                isTruncated
              >
                {campaignName}
              </Text>
            </Box>
          </HStack>
        ) : (
          <IButton
            aria-label="Go back"
            size="md"
            variant="link"
            name="return-button"
            onClick={backButton.handler}
            fontSize="xs"
            bg="basic.title"
            py={1}
            px={2}
            leftIcon={<Icon as={FaArrowLeft} fontSize="14px" />}
          >
            {backButton.label}
          </IButton>
        )}

        <IconButton
          aria-label="sidebar collapse toggle"
          onClick={setCollapsed.toggle}
          variant="link"
          minH="0"
          maxH="12px"
          size="14px"
          icon={collapsed ? <BiExpandAlt /> : <BiCollapseAlt />}
          minW="0"
          borderRadius="4px"
          bg="basic.title"
          py="12px"
          px="6px"
          hidden={isFetchingDetails}
        />
      </Flex>
      <Box w="100%">
        <Collapse in={!collapsed} animateOpacity>
          <VStack w="100%" spacing="0" py="2" alignItems="unset">
            <Box px="5" w="100%" shadow="md" zIndex={1}>
              {titleComponent}
            </Box>
            <VStack
              alignItems="unset"
              px="5"
              py="3"
              spacing={0}
              overflow="auto"
              height="calc(100vh - 145px)"
            >
              {children}
            </VStack>
          </VStack>
        </Collapse>
      </Box>
    </VStack>
  );
}
