import { Flex, Box, Heading, VStack, Text } from "@chakra-ui/react";

function GettingStartedItem({
  index,
  title,
  examples,
  color,
}: {
  index: number;
  title: string;
  examples: string[];
  color: string;
}) {
  return (
    <Flex alignItems="center">
      <Text fontSize="48px" fontWeight="thin" color={color} px="2">
        {index}
      </Text>
      <Flex flexDir="column">
        <Text fontSize="12" fontWeight="semibold" color={color}>
          {title}
        </Text>
        <Text>
          Example functions like
          <Text as="span" ml="1" fontWeight="semibold" color={color}>
            {examples.join(", ")}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
}

export default function GettingStarted() {
  return (
    <Box
      px="10"
      py="5"
      bg="grayV2.100"
      h="100%"
      overflow="auto"
      borderColor="grayV2.100"
      borderWidth="1px"
    >
      <Heading size="md">Getting started with tokens</Heading>

      <Text fontSize="sm" mt="2">
        Here’s a quick start guide for Inflection tokens, <br />
        Start typing and token creator will show you the available
      </Text>

      <VStack alignItems="left" mt="3">
        <GettingStartedItem
          index={1}
          title="Functions"
          examples={["IF"]}
          color="black"
        />
        <GettingStartedItem
          index={2}
          title="Variables from product and person dates"
          examples={["sticker_created"]}
          color="teal"
        />
        <GettingStartedItem
          index={3}
          title="Variables from product and person datas"
          examples={["greeting_with_gender"]}
          color="violet"
        />
      </VStack>
    </Box>
  );
}
