import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { toast } from "react-toastify";
import {
  createApiKeyApi,
  deactivateApiKeyApi,
} from "../../../common/api/auth/auth";
import {
  createConnectionApi,
  listConnectionsApi,
  listSourceTableColumnsApi,
  listSourceSchemaApi,
  listSourceTablesApi,
  setSchemaApi,
  listDestinationTablesApi,
  saveSyncApi,
  listSyncRunsApi,
  toggleSyncApi,
  updateConnectionApi,
  getSyncSummaryApi,
  getSyncDetailsApi,
  getActivityLogs,
  getWhiteListIpsApi,
  listSegmentErrorsApi,
  getSegmentEventReportApi,
  getSegmentErrorReportApi,
  listConnectionsUnifiedApi,
  getUnifiedMappingApi,
  updateCrmUnifiedMappingApi,
  setDefaultCrmMappingApi,
  updateAccountUnifiedMappingApi,
  setDefaultAccountUnifiedMappingApi,
  getSegmentEventsStatusApi,
  updateDescriptionApi,
  toggleSegmentEventApi,
  toggleSegmentEventPropertyApi,
  toggleBypassPropertiesApi,
  getSegmentDetailsApi,
  setSegmentSyncApi,
  getSegmentNonTrackEventsStatusApi,
  getSegmentTrackEventsStatusApi,
  listDestinationColumnsApi,
  addSourceTablesApi,
  validateConnectionApi,
  getMappedColumnsApi,
} from "../../../common/api/integrations/connection";
import {
  deleteAccountContactMappingApi,
  getAccountContactMappingApi,
  updateAccountContactMappingApi,
} from "../../../common/api/setting/setting";
import { CUSTOM_TABLE } from "../../../common/constants/campaign";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
  initializePaginationWithLoadingState,
} from "../../../common/helper/commonHelper";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import { AccountContactAutoMappingElement } from "../../../common/types/AccountContactMapping";
import { STATUS } from "../../../common/constants/AccountContactMapping";
import { ApiKeyData } from "../../../common/types/auth";
import {
  LoadingWithData,
  PaginationType,
  PaginationTypeWithLoadingState,
} from "../../../common/types/common";
import {
  ActivityLog,
  Connection,
  ConnectionCreateConfig,
  ConnectionUpdateConfig,
  DateRange,
  DestinationTable,
  ReportRange,
  SegmentError,
  SegmentReportRow,
  SourceColumn,
  SyncGetResp,
  SyncSummary,
  SyncTablesData,
  SyncRun,
  SegmentEventStatus,
  SegmentDescription,
  SegmentEventToggle,
  SegmentEventPropertyToggle,
  SegmentAsDestinationConfig,
  SegmentSyncConfig,
  SEGMENT_SYNC_TYPES,
  SegmentEventStatusTrack,
  SegmentEventStatusNonTrack,
  ConnectionReadAndWriteValidateConfig,
  ConnectionReadOnlyValidateConfig,
  ColumnToAssetMapping,
} from "../../../common/types/connection";
import {
  DefaultCrmMapping,
  ConnectionListItem,
  Mapping,
  MappingElement,
  AccountMappingElement,
  DefaultAccountMapping,
  SOURCES,
  STATUS as STATUS_CONNECTIONS_LIST,
  ColumnDetailsDict,
} from "../../../common/types/unifiedMapping";
import { RootState } from "../../../store";
import { FORM_VENDORS } from "../../../common/types/form";

//TODO: If any data with it's loading variable, merge them to one using LoadingWithData type

export const CONNECTION_LIST_ALL_ACTION = "connection/list-all-connections";
const FILTERS_LIST_ACTION = "campaign/get-filter-list";
const PERSON_MAPPING = "person/mapping-destination";

const FORM_VENDORS_LIST = Object.values(FORM_VENDORS);

interface ConnectionState {
  selectedService: string;
  connectionDetails: Connection | null;
  destinationTables: DestinationTable[] | null;
  fetchingDestinationTables: boolean;
  createCredsWizardStep: number;

  connectionList: Connection[] | null;
  fetchingConnectionList: boolean;
  connectionPageSize: number;
  connectionTotalPageCount: number | null;
  connectionCurrentPage: number;

  hostName: string;

  schemaList: string[] | null;
  selectedSchema: string;
  tableList: string[] | null;
  columnList: { [table_name: string]: SourceColumn[] };
  fetchingSourceTables: boolean;
  fetchingColumns: boolean;

  creatingNewConnection: boolean;
  createdNewConnection: boolean;
  updatingConnection: boolean;
  updatedConnection: boolean;
  validatingReadOnlyConnection: LOADING_STATES;
  validatingReadAndWriteConnection: LOADING_STATES;
  addedSchema: boolean;

  creatingTableConnection: boolean;
  createdTableConnection: boolean;

  savingSync: LOADING_STATES;
  togglingSync: boolean;

  fetchingSyncSummary: boolean;
  syncSummary: SyncSummary | null;

  fetchingSyncRuns: boolean;

  // TODO - change to pagination type
  syncRuns: {
    records: SyncRun[] | null;
    currentPage: number;
    pageSize: number;
    totalPageCount: number | null;
    recordCount: number | null;
  };

  fetchingSyncDetails: boolean;
  syncDetails: SyncGetResp | null;

  // TODO - change to pagination type
  activityLogs: {
    records: ActivityLog[] | null;
    pageSize: number;
    currentPageNumber: number;
    totalPageCount: number | null;
    recordCount: number | null;
  };
  fetchingActivityLog: boolean;

  fetchingWhiteListIps: boolean;
  whiteListIps: string[] | null;

  segment: {
    createSync: {
      type: SEGMENT_SYNC_TYPES | "";
      readApiKey: LoadingWithData<ApiKeyData>;
      writeApiKey: string;
    };
    syncDetails: LoadingWithData<SegmentSyncConfig & { read_api_key: string }>;
    sourceSyncDetails: {
      errors: PaginationType<SegmentError>;
      eventReport: LoadingWithData<SegmentReportRow[]>;
      errorReport: LoadingWithData<SegmentReportRow[]>;
    };
    destinationSyncDetails: {
      errors: PaginationType<SegmentError>;
      eventReport: LoadingWithData<SegmentReportRow[]>;
      errorReport: LoadingWithData<SegmentReportRow[]>;
    };
  };

  bigQuery: {
    isReadOnlyPermission: boolean;
  };

  unifiedConnection: {
    connectionList: PaginationTypeWithLoadingState<ConnectionListItem>;
    mapping: LoadingWithData<Mapping | null>;
    updatingMapping: LOADING_STATES;
    automapping: LOADING_STATES;
    dwColumnList: ColumnDetailsDict;
    mappedColumns: LoadingWithData<ColumnToAssetMapping>;
  };

  productDiscovery: {
    segmentEventStatus: LoadingWithData<SegmentEventStatus | null>;
    segmentEventStatusNonTrack: LoadingWithData<SegmentEventStatusNonTrack | null>;
    segmentEventStatusTrack: LoadingWithData<SegmentEventStatusTrack | null>;
    updatingDescription: LOADING_STATES;
    togglingEvent: LOADING_STATES;
    togglingProperty: LOADING_STATES;
    togglingBypass: LOADING_STATES;
  };

  accountContactAutoMapping: {
    mapping: AccountContactAutoMappingElement | null;
    fetchingMapping: LOADING_STATES;
    deletingMapping: LOADING_STATES;
    updatingMapping: LOADING_STATES;
  };
}

const SYNC_HISTORY_PAGE_SIZE = 10;
const CONNECTION_LIST_PAGE_SIZE = 30;
const ACTIVITY_LOG_PAGE_SIZE = 10;

const initialState: ConnectionState = {
  selectedService: "",
  connectionDetails: null,
  destinationTables: null,
  fetchingDestinationTables: false,
  createCredsWizardStep: 1,

  connectionList: null,
  fetchingConnectionList: false,
  connectionPageSize: CONNECTION_LIST_PAGE_SIZE,
  connectionTotalPageCount: null,
  connectionCurrentPage: 1,

  hostName: "",

  schemaList: [],
  selectedSchema: "",
  tableList: null,
  columnList: {},
  fetchingSourceTables: false,
  fetchingColumns: false,

  creatingNewConnection: false,
  createdNewConnection: false,
  updatingConnection: false,
  updatedConnection: false,
  validatingReadOnlyConnection: LOADING_STATES.INIT,
  validatingReadAndWriteConnection: LOADING_STATES.INIT,
  addedSchema: false,

  creatingTableConnection: false,
  createdTableConnection: false,

  savingSync: LOADING_STATES.INIT,
  togglingSync: false,

  fetchingSyncSummary: false,
  syncSummary: null,

  syncRuns: {
    records: null,
    pageSize: SYNC_HISTORY_PAGE_SIZE,
    currentPage: 1,
    totalPageCount: null,
    recordCount: null,
  },
  fetchingSyncRuns: false,

  fetchingSyncDetails: false,
  syncDetails: null,

  activityLogs: {
    records: null,
    pageSize: ACTIVITY_LOG_PAGE_SIZE,
    currentPageNumber: 1,
    totalPageCount: null,
    recordCount: null,
  },
  fetchingActivityLog: false,

  fetchingWhiteListIps: false,
  whiteListIps: null,

  segment: {
    createSync: {
      type: "",
      readApiKey: initializeLoadingData({}),
      writeApiKey: "",
    },
    syncDetails: initializeLoadingData({}),
    sourceSyncDetails: {
      errors: INITIAL_PAGINATION,
      eventReport: initializeLoadingData([]),
      errorReport: initializeLoadingData([]),
    },
    destinationSyncDetails: {
      errors: INITIAL_PAGINATION,
      eventReport: initializeLoadingData([]),
      errorReport: initializeLoadingData([]),
    },
  },

  unifiedConnection: {
    connectionList: initializePaginationWithLoadingState({}),

    mapping: initializeLoadingData(null),
    updatingMapping: LOADING_STATES.INIT,
    automapping: LOADING_STATES.INIT,
    dwColumnList: {},
    mappedColumns: initializeLoadingData({}),
  },

  productDiscovery: {
    segmentEventStatus: initializeLoadingData(null),
    segmentEventStatusNonTrack: initializeLoadingData(null),
    segmentEventStatusTrack: initializeLoadingData(null),
    updatingDescription: LOADING_STATES.INIT,
    togglingEvent: LOADING_STATES.INIT,
    togglingProperty: LOADING_STATES.INIT,
    togglingBypass: LOADING_STATES.INIT,
  },

  accountContactAutoMapping: {
    mapping: null,
    updatingMapping: LOADING_STATES.INIT,
    deletingMapping: LOADING_STATES.INIT,
    fetchingMapping: LOADING_STATES.INIT,
  },
  bigQuery: {
    isReadOnlyPermission: false,
  },
};

export const createConnection = createAsyncThunk<
  { connectionInfo: { connection: Connection }; schemas?: string[] },
  ConnectionCreateConfig,
  {}
>(
  "connection/create-connection",
  async (data: ConnectionCreateConfig, { dispatch }) => {
    const connectionInfo = await createConnectionApi(data);
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );

    // TODO - move this api call out of this async thunk
    if (data.typ !== SOURCES.BIGQUERY) {
      const { schemas } = await listSourceSchemaApi(
        connectionInfo.connection.id
      );
      return { connectionInfo, schemas };
    }

    return { connectionInfo };
  }
);

export const updateConnection = createAsyncThunk(
  "connection/update-connection",
  async (data: ConnectionUpdateConfig, { dispatch }) => {
    const response = await updateConnectionApi(data);

    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    // TODO - move this api call out of this async thunk
    if (response.connection.typ !== SOURCES.BIGQUERY) {
      const { schemas } = await listSourceSchemaApi(response.connection.id);
      return { response, schemas };
    }

    return { response };
  }
);

export const validateReadOnlyConnection = createAsyncThunk(
  "connection/validate-readonly-connection",
  async ({
    data,
    connId,
    sourceTables,
  }: {
    data: ConnectionReadOnlyValidateConfig;
    connId: string;
    sourceTables: string[];
  }) => {
    const response = await validateConnectionApi(data);
    if (response.success) {
      await addSourceTablesApi(connId, sourceTables);
    }
    return { response };
  }
);

export const validateReadAndWriteConnection = createAsyncThunk(
  "connection/validate-readandwrite-connection",
  async ({
    data,
    connId,
  }: {
    data: ConnectionReadAndWriteValidateConfig;
    connId: string;
  }) => {
    const response = await validateConnectionApi(data);
    if (response.success) {
      const { schemas } = await listSourceSchemaApi(connId);
      return { response, schemas };
    }
    return { response };
  }
);

export const schemaSet = createAsyncThunk(
  "connection/set-schema",
  async ({ id, schemaName }: { id: string; schemaName: string }) => {
    return await setSchemaApi(id, schemaName);
  }
);

export const listConnection = createAsyncThunk(
  "connection/list-connection",
  async (_, thunkApi) => {
    const { connection } = thunkApi.getState() as RootState;
    return await listConnectionsApi(
      connection.connectionPageSize,
      connection.connectionCurrentPage
    );
  }
);

export const listSourceTables = createAsyncThunk(
  "connection/list-source-tables",
  async ({ connectionId }: { connectionId: string }) => {
    return await listSourceTablesApi(connectionId);
  }
);

export const listDestinationTables = createAsyncThunk(
  "connection/list-destination-tables",
  async () => {
    return await listDestinationTablesApi();
  }
);

export const listSourceTableColumns = createAsyncThunk(
  "connection/list-columns",
  async ({ id, tableName }: { id: string; tableName: string }) => {
    const data = await listSourceTableColumnsApi(id, tableName);

    return { data, tableName };
  }
);

export const getSyncSummary = createAsyncThunk(
  "connection/get-sync-summary",
  async (connectionId: string) => {
    return await getSyncSummaryApi(connectionId);
  }
);

export const getSyncDetails = createAsyncThunk(
  "connection/get-sync-details",
  async (connectionId: string) => {
    return await getSyncDetailsApi(connectionId);
  }
);

export const saveSync = createAsyncThunk(
  "connection/save-sync",
  async (data: SyncTablesData) => {
    return await saveSyncApi(data);
  }
);

export const listSyncRuns = createAsyncThunk(
  "connection/list-sync-runs",
  async ({
    connectionId,
    startDate,
    endDate,
    pageNumber,
    pageSize,
  }: {
    connectionId: string;
  } & DateRange) => {
    return await listSyncRunsApi({
      connectionId,
      startTime: startDate,
      endTime: endDate,
      pageNumber,
      pageSize,
    });
  }
);

export const toggleSync = createAsyncThunk(
  "connection/toggle-sync",
  async (connectionId: string) => {
    return await toggleSyncApi(connectionId);
  }
);

export const activityLog = createAsyncThunk(
  "connection/get-activity-log",
  async ({
    connectionId,
    pageNumber,
    pageSize,
  }: {
    connectionId: string;
    pageNumber: number;
    pageSize: number;
  }) => {
    return await getActivityLogs({ connectionId, pageNumber, pageSize });
  }
);

export const whiteListedIps = createAsyncThunk(
  "connection/get-white-list-ips",
  async () => {
    return await getWhiteListIpsApi();
  }
);

// Segment
export const getSegmentDetails = createAsyncThunk(
  "connection/get-segment-details",
  async () => {
    return await getSegmentDetailsApi();
  }
);

export const listSegmentSourceErrors = createAsyncThunk(
  "connection/list-segment-source-errors",
  async ({ startDate, endDate, pageNumber, pageSize }: DateRange) => {
    return await listSegmentErrorsApi({
      startTime: startDate,
      endTime: endDate,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
      pageNumber,
      pageSize,
    });
  }
);

export const listSegmentDestinationErrors = createAsyncThunk(
  "connection/list-segment-destination-errors",
  async ({ startDate, endDate, pageNumber, pageSize }: DateRange) => {
    return await listSegmentErrorsApi({
      startTime: startDate,
      endTime: endDate,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
      pageNumber,
      pageSize,
    });
  }
);

export const getSegmentSourceEventReport = createAsyncThunk(
  "connection/get-segment-source-event-report",
  async (data: ReportRange) => {
    return await getSegmentEventReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
    });
  }
);

export const getSegmentDestinationEventReport = createAsyncThunk(
  "connection/get-segment-destination-event-report",
  async (data: ReportRange) => {
    return await getSegmentEventReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
    });
  }
);

export const getSegmentSourceErrorReport = createAsyncThunk(
  "connection/get-segment-source-error-report",
  async (data: ReportRange) => {
    return await getSegmentErrorReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.SOURCE,
    });
  }
);

export const getSegmentDestinationErrorReport = createAsyncThunk(
  "connection/get-segment-destination-error-report",
  async (data: ReportRange) => {
    return await getSegmentErrorReportApi({
      ...data,
      syncType: SEGMENT_SYNC_TYPES.DESTINATION,
    });
  }
);

export const createSegmentReadApiKey = createAsyncThunk(
  "connection/segment/create-read-api-key",
  async () => {
    return await createApiKeyApi("read_key", SOURCES.SEGMENT);
  }
);

export const deactivateSegmentReadApiKey = createAsyncThunk(
  "connection/segment/deactivate-read-api-key",
  async (keyId: string) => {
    return await deactivateApiKeyApi(keyId);
  }
);

export const setSegmentSync = createAsyncThunk(
  "connection/segment/sync",
  async (destinationSettings: SegmentAsDestinationConfig) => {
    const { write_key } = destinationSettings;
    const syncDetails = {
      connection_type: write_key
        ? SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
        : SEGMENT_SYNC_TYPES.SOURCE,
      source_sync_settings: {
        is_sync_contacts_enabled: true,
        is_sync_organisations_enabled: true,
      },
      destination_sync_settings: write_key ? destinationSettings : undefined,
    };
    return await setSegmentSyncApi(syncDetails);
  }
);

export const getAllConnections = createAsyncThunkWrapper<
  {
    connections: ConnectionListItem[];
  },
  { isForced?: boolean } | undefined
>({
  actionName: CONNECTION_LIST_ALL_ACTION,
  dispatchFn: async (_, { getState }) => {
    const { connection } = getState() as RootState;
    return await listConnectionsUnifiedApi(
      connection.unifiedConnection.connectionList.pageSize,
      connection.unifiedConnection.connectionList.currentPageNo
    );
  },
  isCachable: true,
});

export const getUnifiedMapping = createAsyncThunk(
  "connection/get-unified-mapping",
  async () => {
    return await getUnifiedMappingApi();
  }
);

export const updateUnifiedMappingCrm = createAsyncThunk(
  "connection/update-unified-mapping-crm",
  async (mapping: MappingElement[], { dispatch }) => {
    const response = await updateCrmUnifiedMappingApi(mapping);
    dispatch(
      expireApiStatus([
        {
          actionName: FILTERS_LIST_ACTION,
          expireAll: true,
        },
        {
          actionName: PERSON_MAPPING,
          expireAll: true,
        },
      ])
    );
    return response;
  }
);

export const setDefaultMappingCrm = createAsyncThunk(
  "connection/set-default-mapping-crm",
  async (data: DefaultCrmMapping) => {
    return await setDefaultCrmMappingApi(data);
  }
);

export const updateUnifiedMappingAccount = createAsyncThunk(
  "connection/update-unified-mapping-account",
  async (mapping: AccountMappingElement[]) => {
    return await updateAccountUnifiedMappingApi(mapping);
  }
);

export const setDefaultMappingAccount = createAsyncThunk(
  "connection/set-default-mapping-account",
  async (data: DefaultAccountMapping) => {
    return await setDefaultAccountUnifiedMappingApi(data);
  }
);

export const getSegmentEventStatus = createAsyncThunk(
  "connection/segment-event-status",
  async () => {
    return await getSegmentEventsStatusApi();
  }
);

export const getSegmentTrackEventStatus = createAsyncThunk(
  "connection/segment-track-event-status",
  async ({
    pageSize,
    pageNumber,
    searchQuery,
  }: {
    pageSize: number;
    pageNumber: number;
    searchQuery: string;
  }) => {
    return await getSegmentTrackEventsStatusApi(
      pageSize,
      pageNumber,
      searchQuery
    );
  }
);

export const getSegmentNonTrackEventStatus = createAsyncThunk(
  "connection/segment-non-track-event-status",
  async () => {
    return await getSegmentNonTrackEventsStatusApi();
  }
);

export const updateSegmentDescription = createAsyncThunk(
  "connection/segment-event-description",
  async (data: SegmentDescription) => {
    return await updateDescriptionApi(data);
  }
);

export const toggleSegmentEvent = createAsyncThunk(
  "connection/segment-event-toggle",
  async (data: SegmentEventToggle) => {
    return await toggleSegmentEventApi(data);
  }
);

export const toggleSegmentEventProperty = createAsyncThunk(
  "connection/segment-event-property-toggle",
  async (data: SegmentEventPropertyToggle) => {
    return await toggleSegmentEventPropertyApi(data);
  }
);

export const toggleBypassProperties = createAsyncThunk(
  "connection/segment-bypass-properties",
  async (data: SegmentEventPropertyToggle) => {
    return await toggleBypassPropertiesApi(data);
  }
);

export const getAccountContactAutoMapping = createAsyncThunk(
  "connection/get-account-contact-auto-mapping",
  async (settingName: string) => {
    return await getAccountContactMappingApi(settingName);
  }
);

export const deleteAccountContactAutoMapping = createAsyncThunk(
  "connection/delete-account-contact-auto-mapping",
  async (settingName: string) => {
    return await deleteAccountContactMappingApi(settingName);
  }
);

export const updateAccountContactAutoMapping = createAsyncThunk(
  "connection/update-account-contact-auto-mapping",
  async (settingValue: AccountContactAutoMappingElement) => {
    return await updateAccountContactMappingApi(settingValue);
  }
);

export const listDwDestinationColumns = createAsyncThunk(
  "connection/list-dw-destination-columns",
  async ({
    connectionId,
    destinationTableName,
  }: {
    connectionId: string;
    destinationTableName: string;
  }) => {
    return await listDestinationColumnsApi(connectionId, destinationTableName);
  }
);

export const getMappedColumns = createAsyncThunk(
  "connection/get-mapped-columns",
  async () => {
    return await getMappedColumnsApi();
  }
);

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnectionsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.connectionTotalPageCount ?? 1)) {
        state.connectionCurrentPage = pageNo;
      }
    },

    setAllConnectionsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <= (state.unifiedConnection.connectionList.totalPageCount ?? 1)
      ) {
        state.unifiedConnection.connectionList.currentPageNo = pageNo;
        state.unifiedConnection.connectionList.changingPage = true;
      }
    },

    wizardNextStep: (state) => {
      state.createCredsWizardStep++;
    },
    wizardPrevStep: (state) => {
      if (state.createCredsWizardStep > 1) {
        state.createCredsWizardStep--;
      }
    },
    wizardSetStep: (state, action: PayloadAction<number>) => {
      state.createCredsWizardStep = action.payload;
    },
    setSelectedSchema: (state, action: PayloadAction<string>) => {
      state.selectedSchema = action.payload;
    },
    setSelectedService: (state, action: PayloadAction<string>) => {
      state.selectedService = action.payload;
    },
    resetFlags: (state) => {
      state.addedSchema = false;
      state.createdNewConnection = false;
      state.creatingNewConnection = false;
      state.updatedConnection = false;
      state.updatingConnection = false;
      state.validatingReadOnlyConnection = LOADING_STATES.INIT;
      state.validatingReadAndWriteConnection = LOADING_STATES.INIT;
      state.bigQuery.isReadOnlyPermission = false;
      state.createCredsWizardStep = 1;
      state.createdTableConnection = false;
      state.selectedService = "";
      state.whiteListIps = null;
    },
    removeFromTableList: (state, action: PayloadAction<string>) => {
      if (state.tableList) {
        state.tableList = state.tableList.filter(
          (table) => table !== action.payload
        );
      }
    },
    addToTableList: (state, action: PayloadAction<string>) => {
      if (state.tableList) {
        state.tableList.push(action.payload);
      }
    },
    toggleTableColumnSelection: (
      state,
      action: PayloadAction<{
        columnName: string;
        tableName: string;
        selected: boolean;
      }>
    ) => {
      state.columnList[action.payload.tableName].forEach((col) => {
        if (col.name === action.payload.columnName) {
          col.selected = action.payload.selected;
        }
      });
    },
    removeFromColumnList: (state, action: PayloadAction<string>) => {
      delete state.columnList[action.payload];
    },
    selectAllColumnsInTableToggle: (
      state,
      action: PayloadAction<{ tableName: string; selected: boolean }>
    ) => {
      state.columnList[action.payload.tableName].forEach((col) => {
        col.selected = action.payload.selected;
      });
    },
    setSyncRunsPageNo(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.syncRuns.totalPageCount ?? 1)) {
        state.syncRuns.currentPage = pageNo;
      }
    },
    clearConnectionDetailsData(state) {
      state.syncRuns = initialState.syncRuns;
      state.syncSummary = null;
      state.connectionDetails = null;
      state.syncDetails = null;
      state.activityLogs = initialState.activityLogs;
      state.tableList = null;
      state.savingSync = LOADING_STATES.INIT;
    },
    setConnectionDetails(state, action: PayloadAction<Connection | null>) {
      state.connectionDetails = action.payload;
    },
    setActivityLogPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.activityLogs.totalPageCount ?? 1)) {
        state.activityLogs.currentPageNumber = pageNo;
      }
    },
    setSegmentSourceErrorPage(state, { payload: pageNo }: { payload: number }) {
      if (
        pageNo &&
        pageNo <= (state.segment.sourceSyncDetails.errors.totalPageCount ?? 1)
      ) {
        state.segment.sourceSyncDetails.errors.currentPageNo = pageNo;
        state.segment.sourceSyncDetails.errors.changingPage = true;
      }
    },
    setSegmentDestErrorPage(state, { payload: pageNo }: { payload: number }) {
      if (
        pageNo &&
        pageNo <=
          (state.segment.destinationSyncDetails.errors.totalPageCount ?? 1)
      ) {
        state.segment.destinationSyncDetails.errors.currentPageNo = pageNo;
        state.segment.destinationSyncDetails.errors.changingPage = true;
      }
    },
    setSegmentConnectionType(
      state,
      { payload }: { payload: SEGMENT_SYNC_TYPES }
    ) {
      state.segment.createSync.type = payload;
    },
    setSegmentDestinationKey(state, { payload }: { payload: string }) {
      state.segment.createSync.writeApiKey = payload;
    },
    resetSegmentSyncData(state) {
      state.segment.createSync.type = "";
      state.segment.createSync.writeApiKey = "";
    },
    resetUnifiedMappingConnectionList(state) {
      state.unifiedConnection.connectionList =
        initializePaginationWithLoadingState({});
    },
    setIsReadOnly(state, { payload }: { payload: boolean }) {
      state.bigQuery.isReadOnlyPermission = payload;
    },
    setHostName(state, { payload }: { payload: string }) {
      state.hostName = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create connection
      .addCase(createConnection.pending, (state) => {
        state.creatingNewConnection = true;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.creatingNewConnection = false;
        state.createdNewConnection = true;
        state.connectionDetails = action.payload.connectionInfo.connection;
        state.hostName = action.payload.connectionInfo.connection.host_name;
        state.schemaList = action.payload.schemas ?? [];
        toast.success("Connection created successfully");
      })
      .addCase(createConnection.rejected, (state) => {
        state.creatingNewConnection = false;
      })

      // update connection
      .addCase(updateConnection.pending, (state) => {
        state.updatingConnection = true;
      })
      .addCase(updateConnection.fulfilled, (state, action) => {
        state.updatingConnection = false;
        state.updatedConnection = true;
        state.hostName = action.payload.response.connection.host_name;
        state.connectionList =
          state.connectionList?.map((conn) => {
            if (conn.id === action.payload.response.connection.id) {
              conn = action.payload.response.connection;
            }
            return conn;
          }) ?? null;
        state.connectionDetails = action.payload.response.connection;
        state.schemaList = action.payload.schemas ?? [];
        toast.success("Connection updated successfully");
      })
      .addCase(updateConnection.rejected, (state) => {
        state.updatingConnection = false;
      })

      //validate connection
      .addCase(validateReadOnlyConnection.pending, (state) => {
        state.validatingReadOnlyConnection = LOADING_STATES.LOADING;
      })
      .addCase(validateReadOnlyConnection.fulfilled, (state, action) => {
        if (action.payload.response.success) {
          toast.success("Connection validated successfully");
          state.validatingReadOnlyConnection = LOADING_STATES.SUCCESS;
        } else {
          toast.error(
            "Access Denied: Cannot access the project datasets. Check if the project id is correct and inflection has required permissions",
            {
              style: {
                width: "500px",
              },
            }
          );
          state.validatingReadOnlyConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(validateReadOnlyConnection.rejected, (state) => {
        state.validatingReadOnlyConnection = LOADING_STATES.FAILED;
      })

      .addCase(validateReadAndWriteConnection.pending, (state) => {
        state.validatingReadAndWriteConnection = LOADING_STATES.LOADING;
      })
      .addCase(validateReadAndWriteConnection.fulfilled, (state, action) => {
        state.schemaList = action.payload.schemas ?? [];

        if (action.payload.response.success) {
          toast.success("Connection validated successfully");
          state.validatingReadAndWriteConnection = LOADING_STATES.SUCCESS;
        } else {
          toast.error(
            "Access Denied: Cannot access the project datasets. Check if the project id is correct and the inflection dataset has been created",
            {
              style: {
                width: "500px",
              },
            }
          );
          state.validatingReadAndWriteConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(validateReadAndWriteConnection.rejected, (state) => {
        state.validatingReadAndWriteConnection = LOADING_STATES.FAILED;
      })

      // List connection
      .addCase(listConnection.pending, (state) => {
        state.fetchingConnectionList = true;
      })
      .addCase(listConnection.fulfilled, (state, action) => {
        state.connectionList = action.payload.records;
        state.fetchingConnectionList = false;
      })
      .addCase(listConnection.rejected, (state) => {
        state.fetchingConnectionList = false;
      })

      // Set Schema
      .addCase(schemaSet.pending, (state) => {
        state.updatingConnection = true;
      })
      .addCase(schemaSet.fulfilled, (state, action) => {
        state.updatingConnection = false;
        state.addedSchema = true;
        state.connectionDetails = action.payload.connection;
      })
      .addCase(schemaSet.rejected, (state) => {
        state.updatingConnection = false;
      })

      // List source tables
      .addCase(listSourceTables.pending, (state) => {
        state.fetchingSourceTables = true;
      })
      .addCase(listSourceTables.fulfilled, (state, action) => {
        state.fetchingSourceTables = false;
        state.tableList = action.payload.tables;
      })
      .addCase(listSourceTables.rejected, (state) => {
        state.fetchingSourceTables = false;
      })

      // List destination tables
      .addCase(listDestinationTables.pending, (state) => {
        state.fetchingDestinationTables = true;
      })
      .addCase(listDestinationTables.fulfilled, (state, action) => {
        state.fetchingDestinationTables = false;
        // add custom table option to show in destination table
        state.destinationTables = [
          ...action.payload.tables,
          {
            table_name: CUSTOM_TABLE,
            default: false,
            used_in_connection: "",
            columns: [],
            multi_source: true,
          },
        ];
      })
      .addCase(listDestinationTables.rejected, (state) => {
        state.fetchingSourceTables = false;
      })

      // List columns
      .addCase(listSourceTableColumns.pending, (state) => {
        state.fetchingColumns = true;
      })
      .addCase(listSourceTableColumns.fulfilled, (state, action) => {
        const cols: SourceColumn[] = action.payload.data.columns.map(
          (col: SourceColumn) => {
            return { ...col, selected: false };
          }
        );
        state.columnList[action.payload.tableName] = cols;
        state.fetchingColumns = false;
      })
      .addCase(listSourceTableColumns.rejected, (state) => {
        state.fetchingColumns = false;
      })

      // Get Sync Summary
      .addCase(getSyncSummary.pending, (state) => {
        state.fetchingSyncSummary = true;
      })
      .addCase(getSyncSummary.fulfilled, (state, action) => {
        state.fetchingSyncSummary = false;
        state.syncSummary = action.payload.sync_summary;
      })
      .addCase(getSyncSummary.rejected, (state) => {
        state.fetchingSyncSummary = false;
      })

      // Get Sync
      .addCase(getSyncDetails.pending, (state) => {
        state.fetchingSyncDetails = true;
      })
      .addCase(getSyncDetails.fulfilled, (state, action) => {
        state.fetchingSyncDetails = false;
        state.syncDetails = action.payload.sync;
      })
      .addCase(getSyncDetails.rejected, (state) => {
        state.fetchingSyncDetails = false;
      })

      // Save Sync
      .addCase(saveSync.pending, (state) => {
        state.savingSync = LOADING_STATES.LOADING;
      })
      .addCase(saveSync.fulfilled, (state, action) => {
        state.savingSync = LOADING_STATES.SUCCESS;
        state.connectionDetails = action.payload.connection;
        state.destinationTables = null;
        toast.success("Sync successfully saved");
      })
      .addCase(saveSync.rejected, (state) => {
        state.savingSync = LOADING_STATES.FAILED;
      })

      // Toggle Sync
      .addCase(toggleSync.pending, (state) => {
        state.togglingSync = true;
      })
      .addCase(toggleSync.fulfilled, (state, action) => {
        state.togglingSync = false;
        if (state.syncSummary) {
          state.syncSummary.sync_enabled = action.payload.sync.sync_enabled;
        }
        if (action.payload.sync.sync_enabled) {
          toast.success("Sync successfully resumed");
        } else {
          toast.success("Sync successfully paused");
        }
      })
      .addCase(toggleSync.rejected, (state) => {
        state.togglingSync = false;
      })

      // List Sync Runs
      .addCase(listSyncRuns.pending, (state) => {
        state.fetchingSyncRuns = true;
      })
      .addCase(listSyncRuns.fulfilled, (state, action) => {
        state.fetchingSyncRuns = false;
        state.syncRuns.records = action.payload.records;
        state.syncRuns.recordCount = action.payload.record_count;
        state.syncRuns.totalPageCount = action.payload.page_count;
      })
      .addCase(listSyncRuns.rejected, (state) => {
        state.fetchingSyncRuns = false;
      })

      .addCase(activityLog.pending, (state) => {
        state.fetchingActivityLog = true;
      })
      .addCase(activityLog.fulfilled, (state, action) => {
        state.fetchingActivityLog = false;
        state.activityLogs.records = action.payload.records;
        state.activityLogs.recordCount = action.payload.record_count;
        state.activityLogs.totalPageCount = action.payload.page_count;
      })
      .addCase(activityLog.rejected, (state) => {
        state.fetchingActivityLog = false;
      })

      .addCase(whiteListedIps.pending, (state) => {
        state.fetchingWhiteListIps = true;
      })
      .addCase(whiteListedIps.fulfilled, (state, action) => {
        state.fetchingWhiteListIps = false;
        state.whiteListIps = action.payload.whitelist_ips;
      })
      .addCase(whiteListedIps.rejected, (state) => {
        state.whiteListIps = [];
        state.fetchingWhiteListIps = false;
      })

      //segment
      //segment as source list errors
      .addCase(listSegmentSourceErrors.pending, (state) => {
        state.segment.sourceSyncDetails.errors.fetchingList = true;
      })
      .addCase(listSegmentSourceErrors.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errors.list = action.payload.records;
        state.segment.sourceSyncDetails.errors.totalPageCount =
          action.payload.page_count;
        state.segment.sourceSyncDetails.errors.count =
          action.payload.record_count;
        state.segment.sourceSyncDetails.errors.fetchingList = false;
        state.segment.sourceSyncDetails.errors.changingPage = false;
      })
      .addCase(listSegmentSourceErrors.rejected, (state) => {
        state.segment.sourceSyncDetails.errors.totalPageCount = 0;
        state.segment.sourceSyncDetails.errors.fetchingList = false;
        state.segment.sourceSyncDetails.errors.changingPage = false;
      })

      //segment as source get event report
      .addCase(getSegmentSourceEventReport.pending, (state) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentSourceEventReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.eventReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentSourceEventReport.rejected, (state) => {
        state.segment.sourceSyncDetails.eventReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as source get error report
      .addCase(getSegmentSourceErrorReport.pending, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentSourceErrorReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.errorReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentSourceErrorReport.rejected, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as destination, list errors
      .addCase(listSegmentDestinationErrors.pending, (state) => {
        state.segment.destinationSyncDetails.errors.fetchingList = true;
      })
      .addCase(listSegmentDestinationErrors.fulfilled, (state, action) => {
        state.segment.destinationSyncDetails.errors.list =
          action.payload.records;
        state.segment.destinationSyncDetails.errors.totalPageCount =
          action.payload.page_count;
        state.segment.destinationSyncDetails.errors.count =
          action.payload.record_count;
        state.segment.destinationSyncDetails.errors.fetchingList = false;
        state.segment.destinationSyncDetails.errors.changingPage = false;
      })
      .addCase(listSegmentDestinationErrors.rejected, (state) => {
        state.segment.destinationSyncDetails.errors.totalPageCount = 0;
        state.segment.destinationSyncDetails.errors.fetchingList = false;
        state.segment.destinationSyncDetails.errors.changingPage = false;
      })

      //segment as destination event report
      .addCase(getSegmentDestinationEventReport.pending, (state) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDestinationEventReport.fulfilled, (state, action) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.destinationSyncDetails.eventReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentDestinationEventReport.rejected, (state) => {
        state.segment.destinationSyncDetails.eventReport.loading =
          LOADING_STATES.FAILED;
      })

      //segment as destination error report
      .addCase(getSegmentDestinationErrorReport.pending, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDestinationErrorReport.fulfilled, (state, action) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.SUCCESS;
        state.segment.sourceSyncDetails.errorReport.data = sortBy(
          action.payload.rows,
          ["date", "hour"]
        );
      })
      .addCase(getSegmentDestinationErrorReport.rejected, (state) => {
        state.segment.sourceSyncDetails.errorReport.loading =
          LOADING_STATES.FAILED;
      })

      // create and update segment sync
      .addCase(setSegmentSync.pending, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(setSegmentSync.fulfilled, (state, { payload }) => {
        state.segment.syncDetails.loading = LOADING_STATES.SUCCESS;
        const {
          source_sync_settings,
          destination_sync_settings,
          connection_type,
        } = payload;
        const { data } = state.segment.syncDetails;

        data.source_sync_settings = source_sync_settings;
        data.destination_sync_settings = destination_sync_settings;
        data.connection_type = connection_type;
      })
      .addCase(setSegmentSync.rejected, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.FAILED;
      })

      //get segment sync details
      .addCase(getSegmentDetails.pending, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSegmentDetails.fulfilled, (state, { payload }) => {
        state.segment.syncDetails.loading = LOADING_STATES.SUCCESS;
        state.segment.syncDetails.data = payload;
      })
      .addCase(getSegmentDetails.rejected, (state) => {
        state.segment.syncDetails.loading = LOADING_STATES.FAILED;
      })

      // create/refresh segment as source, read api key
      .addCase(createSegmentReadApiKey.pending, (state) => {
        state.segment.createSync.readApiKey = initializeLoadingData({});
        state.segment.createSync.readApiKey.loading = LOADING_STATES.LOADING;
      })
      .addCase(createSegmentReadApiKey.fulfilled, (state, action) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.SUCCESS;
        state.segment.createSync.readApiKey.data = action.payload.api_key;
      })
      .addCase(createSegmentReadApiKey.rejected, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.FAILED;
      })

      // deactivate the segment read api  key
      .addCase(deactivateSegmentReadApiKey.pending, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        deactivateSegmentReadApiKey.fulfilled,
        (state, { payload, meta }) => {
          state.segment.createSync.readApiKey.loading = LOADING_STATES.SUCCESS;

          if (payload.api_key.state === STATUS.INACTIVE) {
            state.segment.createSync.readApiKey = initializeLoadingData({});
          }
        }
      )
      .addCase(deactivateSegmentReadApiKey.rejected, (state) => {
        state.segment.createSync.readApiKey.loading = LOADING_STATES.FAILED;
      })

      // List all connection (unified)
      .addCase(getAllConnections.pending, (state) => {
        state.unifiedConnection.connectionList.loadingList =
          LOADING_STATES.LOADING;
      })
      .addCase(getAllConnections.fulfilled, (state, action) => {
        state.unifiedConnection.connectionList.list =
          action.payload.connections.filter(
            ({ source, status }) =>
              !(
                (
                  status === STATUS_CONNECTIONS_LIST.DISABLED &&
                  FORM_VENDORS_LIST.includes(source as unknown as FORM_VENDORS)
                )
                // TODO: Hotfix for Forms. Change the unknown type casting in V2.
              )
          );
        state.unifiedConnection.connectionList.totalPageCount = 1;
        state.unifiedConnection.connectionList.count =
          action.payload.connections.length;
        state.unifiedConnection.connectionList.loadingList =
          LOADING_STATES.SUCCESS;
        state.unifiedConnection.connectionList.changingPage = false;
      })
      .addCase(getAllConnections.rejected, (state) => {
        state.unifiedConnection.connectionList.loadingList =
          LOADING_STATES.FAILED;
        state.unifiedConnection.connectionList.changingPage = false;
      })

      //Salesforce get unified mapping
      .addCase(getUnifiedMapping.pending, (state) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.LOADING;
      })
      .addCase(getUnifiedMapping.fulfilled, (state, action) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
      })
      .addCase(getUnifiedMapping.rejected, (state) => {
        state.unifiedConnection.mapping.loading = LOADING_STATES.FAILED;
      })

      // Salesforce update unified mapping
      .addCase(updateUnifiedMappingCrm.pending, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.LOADING;
      })
      .addCase(updateUnifiedMappingCrm.fulfilled, (state, action) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateUnifiedMappingCrm.rejected, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(updateUnifiedMappingAccount.pending, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.LOADING;
      })
      .addCase(updateUnifiedMappingAccount.fulfilled, (state, action) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateUnifiedMappingAccount.rejected, (state) => {
        state.unifiedConnection.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(setDefaultMappingCrm.pending, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.LOADING;
      })
      .addCase(setDefaultMappingCrm.fulfilled, (state, action) => {
        state.unifiedConnection.automapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(setDefaultMappingCrm.rejected, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.FAILED;
      })

      .addCase(setDefaultMappingAccount.pending, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.LOADING;
      })
      .addCase(setDefaultMappingAccount.fulfilled, (state, action) => {
        state.unifiedConnection.automapping = LOADING_STATES.SUCCESS;
        state.unifiedConnection.mapping.data = action.payload.mapping;
        toast.success("Updated mapping successfully");
      })
      .addCase(setDefaultMappingAccount.rejected, (state) => {
        state.unifiedConnection.automapping = LOADING_STATES.FAILED;
      })

      .addCase(getSegmentEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatus.data =
          action.payload.segment_event_status;
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatus.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(getSegmentTrackEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentTrackEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatusTrack.data = action.payload;
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentTrackEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatusTrack.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(getSegmentNonTrackEventStatus.pending, (state) => {
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getSegmentNonTrackEventStatus.fulfilled, (state, action) => {
        state.productDiscovery.segmentEventStatusNonTrack.data = action.payload;
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getSegmentNonTrackEventStatus.rejected, (state) => {
        state.productDiscovery.segmentEventStatusNonTrack.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(updateSegmentDescription.pending, (state) => {
        state.productDiscovery.updatingDescription = LOADING_STATES.LOADING;
      })
      .addCase(updateSegmentDescription.fulfilled, (state, action) => {
        state.productDiscovery.updatingDescription = action.payload.success
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(updateSegmentDescription.rejected, (state) => {
        state.productDiscovery.updatingDescription = LOADING_STATES.FAILED;
      })

      .addCase(toggleSegmentEvent.pending, (state) => {
        state.productDiscovery.togglingEvent = LOADING_STATES.LOADING;
      })
      .addCase(toggleSegmentEvent.fulfilled, (state, action) => {
        state.productDiscovery.togglingEvent = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleSegmentEvent.rejected, (state) => {
        state.productDiscovery.togglingEvent = LOADING_STATES.FAILED;
      })

      .addCase(toggleSegmentEventProperty.pending, (state) => {
        state.productDiscovery.togglingProperty = LOADING_STATES.LOADING;
      })
      .addCase(toggleSegmentEventProperty.fulfilled, (state, action) => {
        state.productDiscovery.togglingProperty = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleSegmentEventProperty.rejected, (state) => {
        state.productDiscovery.togglingProperty = LOADING_STATES.FAILED;
      })

      .addCase(toggleBypassProperties.pending, (state) => {
        state.productDiscovery.togglingBypass = LOADING_STATES.LOADING;
      })
      .addCase(toggleBypassProperties.fulfilled, (state, action) => {
        state.productDiscovery.togglingBypass = action.payload.status
          ? LOADING_STATES.SUCCESS
          : LOADING_STATES.FAILED;
      })
      .addCase(toggleBypassProperties.rejected, (state) => {
        state.productDiscovery.togglingBypass = LOADING_STATES.FAILED;
      })

      .addCase(getAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.fetchingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(getAccountContactAutoMapping.fulfilled, (state, action) => {
        state.accountContactAutoMapping.mapping = action.payload.setting_value;
        state.accountContactAutoMapping.fetchingMapping =
          LOADING_STATES.SUCCESS;
      })
      .addCase(getAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.fetchingMapping = LOADING_STATES.FAILED;
      })

      .addCase(updateAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.updatingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(updateAccountContactAutoMapping.fulfilled, (state, action) => {
        state.accountContactAutoMapping.updatingMapping =
          LOADING_STATES.SUCCESS;
        state.accountContactAutoMapping.mapping = action.payload;
        toast.success("Updated mapping successfully");
      })
      .addCase(updateAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.updatingMapping = LOADING_STATES.FAILED;
      })

      .addCase(deleteAccountContactAutoMapping.pending, (state) => {
        state.accountContactAutoMapping.deletingMapping =
          LOADING_STATES.LOADING;
      })
      .addCase(deleteAccountContactAutoMapping.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.accountContactAutoMapping.deletingMapping =
            LOADING_STATES.SUCCESS;
          toast.success("Deleted mapping successfully");
        } else {
          state.accountContactAutoMapping.deletingMapping =
            LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteAccountContactAutoMapping.rejected, (state) => {
        state.accountContactAutoMapping.deletingMapping = LOADING_STATES.FAILED;
      })

      // List DW destination columns
      .addCase(listDwDestinationColumns.pending, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.LOADING,
          data: [],
        };
      })
      .addCase(listDwDestinationColumns.fulfilled, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.columns,
        };
      })
      .addCase(listDwDestinationColumns.rejected, (state, action) => {
        state.unifiedConnection.dwColumnList[action.meta.arg.connectionId] = {
          loading: LOADING_STATES.FAILED,
          data: [],
        };
      })
      // Get already mapped columns
      .addCase(getMappedColumns.pending, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.LOADING,
          data: {},
        };
      })
      .addCase(getMappedColumns.fulfilled, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.SUCCESS,
          data: action.payload.column_to_assets_mapping,
        };
      })
      .addCase(getMappedColumns.rejected, (state, action) => {
        state.unifiedConnection.mappedColumns = {
          loading: LOADING_STATES.FAILED,
          data: {},
        };
      });
  },
});

export const {
  wizardNextStep,
  wizardPrevStep,
  wizardSetStep,
  resetFlags,
  setSelectedSchema,
  setSelectedService,
  removeFromTableList,
  addToTableList,
  toggleTableColumnSelection,
  removeFromColumnList,
  selectAllColumnsInTableToggle,
  setSyncRunsPageNo,
  setAllConnectionsPageNo,
  clearConnectionDetailsData,
  setIsReadOnly,
  setHostName,
  setConnectionDetails,
  setActivityLogPage,
  setConnectionsPageNo,
  setSegmentConnectionType,
  setSegmentDestinationKey,
  setSegmentSourceErrorPage,
  setSegmentDestErrorPage,
  resetSegmentSyncData,
  resetUnifiedMappingConnectionList,
} = connectionSlice.actions;

export const selectConnection = (state: RootState) => state.connection;

export default connectionSlice.reducer;
