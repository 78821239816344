import { IconType } from "react-icons";
import { FLOW_ACTIONS } from "../constants/campaign";
import { ActionOptions, ValueTypes } from "./campaign";
import { DESTINATION_TYPES, SYNC_PREFERENCE } from "./unifiedMapping";

export type FlowGraph = {
  links: FlowEdge[];
  nodes: FlowNodeAction[];
};

export type FlowEdge = {
  source: string;
  target: string;
  attributes?: {
    branch_id?: string;
    condition_id?: string;
    edge_type?: string;
  };
};

export type FlowNodeAction = {
  action_id: string;
  action_options: ActionOptions;
  action_type: FLOW_ACTIONS;
  branch_id: string;
  branch_start_action: boolean;
  flow_start_action: boolean;
  is_deleted?: boolean;
};

export type UpdateFlowActionReq = {
  campaign_id: string;
  action_id: string;
  action_options: ActionOptions;
  parent_action_id?: string;
  branch_id?: string;
};

export type FlowValidity = { [actionId: string]: boolean };

export type FlowApiResType = {
  flow: FlowGraph;
  updated_by: {
    id: number;
    name: string;
  };
  updated_at: string;
};

// Flow step operators (for branching update contacts)

export interface ComputeOperatorDetails {
  id: string;
  display_name: string;
  operands: number;
  operand_data_type: DESTINATION_TYPES[];
}

export type ComputeOperatorList = {
  [key in DESTINATION_TYPES]: { [operator: string]: ComputeOperatorDetails };
};

export type IconOptionsType = {
  label: string;
  value: any;
  icon?: IconType;
  isDisabled?: boolean;
  salesforcePreference?: SYNC_PREFERENCE;
  required?: boolean;
};

export type FlowEdgeAddReq = {
  campaign_id: string;
  source_action_id: string;
  target_action_id: string;
  parent_action_id?: string;
  link_attributes?: {
    [key: string]: string | undefined;
  };
};

export type ActionNodeIdentifiers = {
  actionId: string;
  branchId?: string;
  groupId?: string;
};

export type ChildIdentifiers = {
  nodeId: string;
  branchId: string;
  edgeConditionId: string;
};

export type NodeHelperProps = {
  saveDraft: (
    flowAction: ActionOptions,
    identities: ActionNodeIdentifiers,
    shouldRender?: boolean
  ) => void;
  setActions: (
    actionOptions: ActionOptions,
    id: string,
    groupId?: string
  ) => void;
  selectGoto: (id: string | null) => void;
  selectExit: (conditionId: string | null) => void;
  FlowContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  readonly?: boolean;
};

export type ActionNodeArgs = {
  action: FlowNodeAction;
  props: NodeHelperProps;
  groupId?: string;
  isCandidate?: boolean;
  selectedGoto?: string;
  selectedExit?: { actionId: string; conditionId: string };
  childLessExit?: string;
  gotoChild: string;
  exitLinkData?: ExitCriteriaLinkData;
  lock?: boolean;
  height?: string;
  width?: string;
};

export type AddNodeArgs = {
  parentActionId: string;
  branchId: string;
  isFlowEmpty: boolean;
  isCandidate?: boolean;
  groupId?: string;
  isBetweenFlowSteps?: boolean;
  props: { readonly?: boolean };
};

export type EdgeDataProps = {
  prevActionId?: string;
  branchId?: string;
  groupId?: string;
  conditionId?: string;
  isStepAddable?: boolean;
  isLastStep?: boolean;
  readonly?: boolean;
};

export enum EDGE_LABEL_VARIANTS {
  BRANCH_BY_FILTER = "branch_by_filter",
  BRANCH_BY_DATA = "branch_by_data",
}

export type EdgeLabelPropsForFilter = {
  variant: EDGE_LABEL_VARIANTS.BRANCH_BY_FILTER;
  label: string;
};

export type EdgeLabelPropsForData = {
  variant: EDGE_LABEL_VARIANTS.BRANCH_BY_DATA;
  type: FLOW_ACTIONS.BRANCH_BY_TOKEN | FLOW_ACTIONS.BRANCH_BY_VALUE;
  value: ValueTypes;
  operator: string;
  isDefault: boolean;
};

export type EdgeLabelNodeArgs = EdgeLabelPropsForFilter | EdgeLabelPropsForData;

export type ExitCriteriaLinkData = {
  [conditionId: string]: ExitCriteriaEndData;
};

export type ExitCriteriaEndData = {
  sourceHandle: string;
  target: {
    actionId: string;
    branchId: string;
  };
};

export interface CanvasReportBaseRequestParams {
  campaignId: string;
  startDate: string;
  endDate: string;
}

export interface CanvasReportRequestParams
  extends CanvasReportBaseRequestParams {
  query: string;
  columnsToSearchIn: string[];
  pageSize: number;
  pageNumber: number;
}

export type JourneyMemberReportReq = CanvasReportRequestParams & {
  showUniqueEntries: boolean;
};
export type FlowMemberReportReq = CanvasReportRequestParams & {
  actionId: string;
  branchId?: string;
};
