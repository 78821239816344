import { Box, BoxProps, useTheme } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function StyledScrollBar({
  maxHeight,
  children,
  ...props
}: BoxProps & {
  children: ReactNode;
}) {
  const theme = useTheme();

  const colors = {
    gray200: theme.__cssVars["--chakra-colors-gray-200"],
    gray300: theme.__cssVars["--chakra-colors-gray-300"],
  };

  return (
    <Box
      maxHeight={maxHeight}
      overflowY="auto"
      css={{
        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          background: "white",
        },
        "::-webkit-scrollbar-thumb": {
          background: colors.gray200,
          borderRadius: "6px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: colors.gray300,
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
