import { VStack } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import {
  AccountMappingElement,
  AccountSource,
  ConnectionListItem,
} from "../../../../../../../../../../../common/types/unifiedMapping";
import AccountMappingColumnRow from "./AccountMappingColumnRow";
import { SalesforceConnectionsSummary } from "../../../../../../../../../../../common/types/salesforce";

export default function AccountIdColumnRows({
  rows,
  sources,
  data,
  onChange,
  readonly,
  salesforceConnections,
}: {
  rows: { [connectionId: string]: string[] };
  sources: ConnectionListItem[];
  data: AccountMappingElement;
  onChange: (data: AccountMappingElement) => void;
  readonly: boolean;
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  const props = {
    sources: sources,
    remove: () => {},
  };

  function onChangeLocal(updatedData: AccountMappingElement) {
    const clonedData = cloneDeep(data);
    let updatedSources: AccountSource[] | null = null;
    if (clonedData.source) {
      updatedSources = clonedData.source.map((source) => {
        if (
          updatedData.source &&
          source.connection_id === updatedData.source[0].connection_id
        ) {
          source = { ...updatedData.source[0] };
        }
        return source;
      });
    } else {
      updatedSources = cloneDeep(updatedData.source);
    }
    clonedData.source = updatedSources ? [...updatedSources] : null;
    onChange(clonedData);
  }

  return (
    <VStack width="100%">
      {data.source ? (
        data.source.map((source, index) => {
          const dataWithSingleSource = { ...data, source: [source] };
          return (
            <AccountMappingColumnRow
              rows={rows}
              readonly={readonly}
              data={dataWithSingleSource}
              onChange={(data) => onChangeLocal(data)}
              disableSource={true}
              hideTitle={index > 0}
              {...props}
              selectedSource={source}
              syncPreferences={source?.sync_preference || null}
              account={source.field}
              salesforceConnections={salesforceConnections}
            />
          );
        })
      ) : (
        <AccountMappingColumnRow
          rows={rows}
          readonly={readonly}
          data={data}
          onChange={(data) => onChangeLocal(data)}
          sources={sources}
          selectedSource={null}
          syncPreferences={null}
          account={null}
          remove={() => {}}
        />
      )}
    </VStack>
  );
}
