import { VStack, Text, HStack, Divider } from "@chakra-ui/react";
import { SegmentProperty } from "../../../../../../../../common/types/connection";
import SegmentPropertyItem from "./components/SegmentPropertyItem";
import { isEmpty } from "lodash";

export default function AccordionPropertyPanel({
  propertyDetails,
  propertyKeys,
  eventName,
  isByPassAll,
  isEventEnabled,
}: {
  propertyDetails: SegmentProperty;
  propertyKeys: string[];
  eventName: string;
  isByPassAll: boolean;
  isEventEnabled: boolean;
}) {
  return (
    <VStack
      bg="blackAlpha.50"
      py={6}
      px={4}
      fontSize="sm"
      width="100%"
      alignItems="flex-start"
      borderRadius="md"
    >
      <HStack width="100%">
        <Text fontSize="14px" fontWeight="600">
          Event properties
        </Text>
      </HStack>

      {isEmpty(propertyKeys) ? (
        <Text fontSize="12px" color="text.50" fontWeight="500" px="40%" py="4">
          No event properties found.
        </Text>
      ) : (
        <VStack width="100%" alignItems="flex-start" divider={<Divider />}>
          {propertyKeys.map((property) => {
            return (
              <SegmentPropertyItem
                propertyName={property}
                propertyDetails={propertyDetails[property]}
                eventName={eventName}
                isByPassAll={isByPassAll}
                isEventEnabled={isEventEnabled}
              />
            );
          })}
        </VStack>
      )}
    </VStack>
  );
}
