import { Flex } from "@chakra-ui/layout";
import { get } from "lodash";
import { AccountMember } from "../../../../common/types/account";
import { PaginationType } from "../../../../common/types/common";
import { PersonDestinationFields } from "../../../../common/types/person";
import ContentContainer from "../../../../components/v2/ContentContainer";
import AccountInfo from "./AccountInfo";
import AccountMembersPage from "./AccountMembersPage";

import AccountSalesforceDetails from "./AccountSalesforceDetails";

export default function Details({
  details,
  accountMembersList,
  accountName,
  accountId,
  instanceUrl,
  selectedTab,
}: {
  details: PersonDestinationFields;
  accountMembersList: PaginationType<AccountMember>;
  accountName: string | null;
  accountId: string | null;
  instanceUrl: string;
  selectedTab: number;
}) {
  const tabs = {
    0: (
      <ContentContainer>
        <AccountInfo details={details} />
      </ContentContainer>
    ),
    1: <AccountMembersPage accountMembersList={accountMembersList} />,
    2: (
      <ContentContainer>
        <AccountSalesforceDetails
          accountName={accountName as string}
          accountId={accountId}
          instanceUrl={instanceUrl}
        />
      </ContentContainer>
    ),
  };

  return (
    <Flex mt={12} w="calc(100% - 300px)">
      {get(tabs, selectedTab)}
    </Flex>
  );
}
