import {
  Box,
  Flex,
  Heading,
  VStack,
  Center,
  Text,
  Divider,
} from "@chakra-ui/react";
import { formatISO, format } from "date-fns";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../../components/Pagination";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import {
  selectConnection,
  listSyncRuns,
  setSyncRunsPageNo,
} from "../../connectionSlice";
import { SyncRun } from "../../../../../common/types/connection";
import CustomDateRangePicker from "../../../../../components/CustomDateRangePicker";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
} from "../../../../../common/constants/common";

function SyncHistoryItem({ item }: { item: SyncRun }) {
  return (
    <Flex
      width="100%"
      border="1px"
      borderColor="gray.200"
      p="3"
      bg={item.error_time ? "red.50" : "white"}
      rounded="sm"
      justifyContent="space-between"
    >
      <Text fontSize="sm" fontWeight="semibold">
        {format(new Date(item.time_sync_started), "PPpp")}
      </Text>
      <Text fontSize="md" fontWeight="semibold" color="brand.blue">
        {item.error_time ? (
          "Encountered error"
        ) : (
          <>
            +{item.records_synced_count}
            <Text as="span" ml="1" color="gray.400">
              rows
            </Text>
          </>
        )}
        <Divider height="2px" />
      </Text>
    </Flex>
  );
}

export function SyncHistory({
  connectionId,
  refresh,
}: {
  connectionId: string | null;
  refresh: boolean;
}) {
  const dispatch = useDispatch();

  const [[startDate, endDate], setDateRange] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_WEEK),
    new Date(),
  ]);

  const { syncRuns, fetchingSyncRuns } = useSelector(selectConnection);

  function onDateChange(startDate: Date, endDate: Date) {
    setDateRange([startDate, endDate]);
  }

  useEffect(() => {
    if (connectionId) {
      dispatch(
        listSyncRuns({
          connectionId,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
          pageSize: syncRuns.pageSize,
          pageNumber: syncRuns.currentPage,
        })
      );
    }
  }, [
    dispatch,
    connectionId,
    syncRuns.currentPage,
    syncRuns.pageSize,
    refresh,
    startDate,
    endDate,
  ]);

  return (
    <SpinnerContainer width="100%" height="300px" loading={fetchingSyncRuns}>
      <Box mt="5">
        <Flex mb="3" justifyContent="space-between" alignItems="center">
          <Heading as="h6" fontSize="lg" fontWeight="medium">
            Sync history
          </Heading>
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateChange}
          />
        </Flex>
        {!isEmpty(syncRuns.records) ? (
          <>
            <VStack spacing="3">
              {syncRuns.records?.map((syncRun, index) => {
                return <SyncHistoryItem item={syncRun} key={index} />;
              })}
            </VStack>
            <Pagination
              mt="5"
              currentPage={syncRuns.currentPage}
              pageCount={syncRuns.totalPageCount}
              onPageChange={(selectPage) =>
                dispatch(setSyncRunsPageNo(selectPage))
              }
            />
          </>
        ) : (
          <Center
            h="200px"
            fontSize="sm"
            color="gray.400"
            borderTop="1px"
            borderColor="gray.100"
          >
            No sync runs yet
          </Center>
        )}
      </Box>
    </SpinnerContainer>
  );
}
