import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Divider,
  Stack,
  Icon,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FilterGroupBox } from "./FilterGroupBox";
import { RiMailCheckLine } from "react-icons/ri";
import {
  CAMPAIGN_LIST_INIT,
  PROPERTY_FILTER_ID,
  TEMPLATE_LIST_INIT,
  VALUE_ALL,
} from "../../../../../common/constants/campaign";
import { getOperatorDetails } from "../../../../../common/helper/commonHelper";
import {
  MarketingActivityArgument,
  MarketingActivity,
} from "../../../../../common/types/person";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import IButton from "../../../../../components/IButton";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { useAppDispatch } from "../../../../../store";
import { selectCampaign } from "../../campaignSlice";
import {
  selectDynamicList,
  getMarketingActivities,
  getTemplatesOfCampaign,
  listAllCampaigns,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import { TriggerCriteriaContext } from "../../TriggerCriteriaList";
import AudienceCriteriaValueFields from "../../Audience-criteria/components/AudienceCriteriaValueFields";

import {
  ComparisonNode,
  ConditionGroup,
  Condition,
  TargetValueTypes,
  Pattern,
} from "../../../../../common/types/dynamicList";
import {
  COMPARISON_OPERATORS,
  DL_TARGET_VALUE_TYPES,
} from "../../../../../common/constants/dynamicList";
import { getLogicalOperatorForTrigger } from "../../Audience-criteria/helper";
import { RxDragHandleDots2, RxPlus } from "react-icons/rx";

enum CONDITION_FIELD_NAME {
  TEMPLATE = "template_id",
  CAMPAIGN = "campaign_id",
  REASON = "reason",
}

const templateListInit = [{ label: "All templates", value: VALUE_ALL }];

function PropertyGroupRow({
  data,
  onChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
}: {
  data: Condition;
  onChange: (data: Condition) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
}) {
  const { operators, activeErrorCheck } = useContext(TriggerCriteriaContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        data.comparisonOperator,
        operators,
        argumentData?.data_type
      ),
    [data.comparisonOperator, operators, argumentData]
  );

  function setOperator(operator: COMPARISON_OPERATORS) {
    const dataCopy = cloneDeep(data);
    dataCopy.comparisonOperator = operator;
    dataCopy.targetValueType = DL_TARGET_VALUE_TYPES.STRING;
    dataCopy.targetValue = [];
    onChange(dataCopy);
  }

  function handleValueChange(val: TargetValueTypes) {
    const dataCopy = cloneDeep(data);
    dataCopy.targetValue = val;
    onChange(dataCopy);
  }

  const isInvalidOperator = activeErrorCheck && !data.comparisonOperator;

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
        color: "text.50",
        fontWeight: "600",
        fontSize: "12px",
      }
    : {
        spacing: "3",
        py: "2",
        px: "3",
        gridGap: "2",
      };

  if (argumentData) {
    return (
      <HStack alignItems="center" wrap="wrap" {...wrapperStyle}>
        <Text fontSize={isReadOnly ? "12px" : "sm"} textAlign="right">
          {argumentData.display}
        </Text>

        {operators && (
          <Box>
            <ValueSelectFields
              options={Object.values(operators[argumentData.data_type]).map(
                (op) => ({
                  label: op.display,
                  value: op.id,
                })
              )}
              value={(data.comparisonOperator as COMPARISON_OPERATORS) || ""}
              onChange={(data: string) =>
                setOperator(data as COMPARISON_OPERATORS)
              }
              validationError={isInvalidOperator ? "Invalid operator" : ""}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}
        {data.comparisonOperator && operatorDetails && (
          <HStack>
            <AudienceCriteriaValueFields
              value={data.targetValue}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              argumentSelectList={
                data.comparisonOperator === COMPARISON_OPERATORS.EQUALS
                  ? argumentSelectList
                  : undefined
              }
              filter={data.propertyName as PROPERTY_FILTER_ID}
              noOfArguments={operatorDetails.arguments}
              helperText={operatorDetails.display_2}
              activeErrorCheck={activeErrorCheck}
              isReadOnly={isReadOnly}
            />
          </HStack>
        )}
      </HStack>
    );
  }

  return <></>;
}

function PropertyGroup({
  mainFilter,
  data,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: string | null;
  data: ConditionGroup;
  onRemove: () => void;
  onChange: (data: ConditionGroup) => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { campaignDetails } = useSelector(selectCampaign);

  const {
    marketingEventNamesList: { data: marketingEventNamesList },
    templateListOfCampaign,
    totalCampaignList,
  } = useSelector(selectDynamicList);

  const [templateList, setTemplateList] =
    useState<{ label: string; value: string }[]>(TEMPLATE_LIST_INIT);

  const campaignFilter = useMemo(
    () =>
      data.conditions?.find(
        ({ propertyName: fieldName }) =>
          fieldName === PROPERTY_FILTER_ID.CAMPAIGN
      ),
    [data.conditions]
  );
  const templateFilter = useMemo(
    () =>
      data.conditions?.find(
        ({ propertyName: fieldName }) =>
          fieldName === PROPERTY_FILTER_ID.TEMPLATE
      ),
    [data.conditions]
  );
  const reasonFilter = useMemo(
    () =>
      data.conditions?.find(
        ({ propertyName: fieldName }) => fieldName === PROPERTY_FILTER_ID.REASON
      ),
    [data.conditions]
  );

  useEffect(() => {
    const campaignId = campaignFilter?.targetValue?.[0];
    if (
      campaignFilter?.comparisonOperator === COMPARISON_OPERATORS.EQUALS &&
      campaignId &&
      campaignId !== VALUE_ALL
    ) {
      const templates =
        templateListOfCampaign[campaignId]?.data.map((template) => {
          return {
            value: template.template_id,
            label: template.name,
          };
        }) ?? [];
      setTemplateList([...templateListInit, ...templates]);
    }
  }, [
    templateListOfCampaign,
    campaignFilter?.targetValue,
    campaignFilter?.comparisonOperator,
  ]);

  const campaignList: { label: string; value: string }[] = useMemo(() => {
    const data = totalCampaignList.data
      .filter(
        (item) =>
          campaignDetails.data.campaign_id !== item.campaign_id &&
          (item.activated_at || item.deactivated_at)
      )
      .map((item) => {
        return {
          label: item.name,
          value: item.campaign_id,
        };
      });
    return [...CAMPAIGN_LIST_INIT, ...data];
  }, [campaignDetails.data.campaign_id, totalCampaignList]);

  useEffect(() => {
    dispatch(listAllCampaigns());
  }, [dispatch]);

  useEffect(() => {
    const campaignId = campaignFilter?.targetValue?.[0];
    if (
      campaignFilter?.comparisonOperator === COMPARISON_OPERATORS.EQUALS &&
      campaignId &&
      campaignId !== VALUE_ALL
    )
      dispatch(getTemplatesOfCampaign(campaignId as string));
  }, [
    dispatch,
    campaignFilter?.targetValue,
    campaignFilter?.comparisonOperator,
  ]);

  function onChangeHandler(newData: Condition, type: PROPERTY_FILTER_ID) {
    const templateRow: Condition = {
      logicalOperator: getLogicalOperatorForTrigger(data.conditions.length),
      propertyName: CONDITION_FIELD_NAME.TEMPLATE,
      comparisonOperator: null,
      targetValueType: null,
      targetValue: [],
    };
    if (data.conditions) {
      const dataCopy = cloneDeep(data);
      const index = dataCopy.conditions?.findIndex(
        ({ propertyName: fieldName }) => fieldName === type
      );
      if (index !== undefined) {
        (dataCopy.conditions as Condition[])[index] = newData;
        if (type === PROPERTY_FILTER_ID.CAMPAIGN) {
          const templateFilterIndex = dataCopy.conditions?.findIndex(
            ({ propertyName: fieldName }) =>
              fieldName === PROPERTY_FILTER_ID.TEMPLATE
          );
          if (templateFilterIndex !== undefined) {
            dataCopy.conditions[templateFilterIndex] = templateRow;
            // (dataCopy.children as DynamicListChildL2[])[templateFilterIndex] =
            //   templateRow;
          }
        }
      }
      onChange(dataCopy);
    }
  }

  function getArgumentDetails(type: string) {
    return mainFilter
      ? marketingEventNamesList
          ?.find((x) => x.id === mainFilter)
          ?.arguments?.find((x) => x.id === type)
      : undefined;
  }

  return (
    <Flex w="100%" flexWrap="nowrap">
      <VStack spacing="0" alignItems="flex-start">
        {campaignFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.CAMPAIGN)
            }
            data={campaignFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.CAMPAIGN)}
            argumentSelectList={campaignList}
            isReadOnly={isReadOnly}
          />
        )}
        {templateFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.TEMPLATE)
            }
            data={templateFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.TEMPLATE)}
            argumentSelectList={templateList}
            isReadOnly={isReadOnly}
          />
        )}
        {reasonFilter && (
          <PropertyGroupRow
            onChange={(data) =>
              onChangeHandler(data, PROPERTY_FILTER_ID.REASON)
            }
            data={reasonFilter}
            argumentData={getArgumentDetails(PROPERTY_FILTER_ID.REASON)}
            isReadOnly={isReadOnly}
          />
        )}
      </VStack>
      {!isReadOnly && <RemoveRowCloseButton mt={2} onClick={onRemove} />}
    </Flex>
  );
}

function ActivitySelector({
  marketingActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  marketingActivityList: MarketingActivity[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && <Text fontSize="sm">Marketing activity</Text>}
      <Box id="marketing-activity-selector">
        <ValueSelectFields
          options={marketingActivityList?.map((x) => ({
            label: x.display,
            value: x.id,
          }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </Box>
    </HStack>
  );
}

function PropertyGroupContainer({
  filter,
  data,
  onChange,
  isReadOnly,
}: {
  filter: string | null;
  data: ConditionGroup[];
  onChange: (data: ConditionGroup[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(updateData: ConditionGroup, index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
        ml: "0px !important",
      }
    : {
        px: "12px",
        ml: "12px !important",
        borderLeftWidth: "1px",
        borderLeftColor: "grayV2.500",
      };

  return (
    <VStack
      alignItems="left"
      {...wrapperStyle}
      mt={!isReadOnly && data.length ? "12px !important" : "0px !important"}
    >
      {data.map((item, index) => (
        <>
          {index === 0 && !isReadOnly && (
            <Text fontSize="12px" color="brand.blue">
              where all the properties apply
            </Text>
          )}
          <PropertyGroup
            mainFilter={filter}
            data={item}
            onRemove={() => onRemovePropertyGroup(index)}
            onChange={(data) => onChangePropertyGroup(data, index)}
            isReadOnly={isReadOnly}
          />
        </>
      ))}
    </VStack>
  );
}

export default function MarketingEventGroup({
  data,
  id,
  onChange,
  onRemove,
  isReadOnly,
  isDraggable,
}: {
  data: ComparisonNode | Pattern;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
  isDraggable?: boolean;
}) {
  const { marketingEventNamesList } = useSelector(selectDynamicList);
  const dispatch = useAppDispatch();

  const { activeErrorCheck } = useContext(TriggerCriteriaContext);

  useEffect(() => {
    dispatch(getMarketingActivities());
  }, [dispatch]);

  function onActivityChange(value: string) {
    let dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.eventName !== value) {
      dataCopy.conditionGroups = [];
    }
    dataCopy.eventName = value;
    onChange(dataCopy);
  }

  // function onRemoveRow(index: number) {
  //   const dataCopy = cloneDeep(data);
  //   dataCopy.conditionGroups = dataCopy.conditionGroups.filter(
  //     (_, i) => i !== index
  //   );
  //   onChange(dataCopy);
  // }

  function onPropertyGroupChange(newData: ConditionGroup[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.conditionGroups = newData;
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: ConditionGroup = {
      conditionGroupId: "",
      logicalOperator: getLogicalOperatorForTrigger(
        data.conditionGroups.length
      ),
      conditions: [
        {
          logicalOperator: getLogicalOperatorForTrigger(
            data.conditionGroups.length
          ),
          propertyName: PROPERTY_FILTER_ID.CAMPAIGN,
          comparisonOperator: null,
          targetValueType: null,
          targetValue: [],
        },
      ],
    };
    const templatePropertyGroupChild: Condition = {
      logicalOperator: getLogicalOperatorForTrigger(
        data.conditionGroups.length
      ),
      propertyName: PROPERTY_FILTER_ID.TEMPLATE,
      comparisonOperator: null,
      targetValueType: null,
      targetValue: [],
    };
    const reasonPropertyGroupChild: Condition = {
      logicalOperator: getLogicalOperatorForTrigger(
        data.conditionGroups.length
      ),
      propertyName: PROPERTY_FILTER_ID.REASON,
      comparisonOperator: null,
      targetValueType: null,
      targetValue: [],
    };
    if (getEventList()?.includes(PROPERTY_FILTER_ID.TEMPLATE)) {
      newPropertyGroup?.conditions?.push(templatePropertyGroupChild);
    }
    if (getEventList()?.includes(PROPERTY_FILTER_ID.REASON)) {
      newPropertyGroup?.conditions?.push(reasonPropertyGroupChild);
    }

    dataCopy.conditionGroups.push(newPropertyGroup);
    onChange(dataCopy);
  }

  function getEventList() {
    return data.eventName
      ? marketingEventNamesList.data
          ?.find((x) => x.id === data.eventName)
          ?.arguments?.map((x) => x.id)
      : [];
  }

  const isInvalidEventName = activeErrorCheck && !data.eventName;

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
      }
    : {
        px: "3",
        py: "3",
        spacing: "0",
      };

  return (
    <FilterGroupBox
      id={id}
      onRemove={onRemove}
      icon={RiMailCheckLine}
      isReadOnly={isReadOnly}
      rounded="lg"
      borderColor="grayV2.200"
      borderWidth="1px"
      bg="grayV2.200"
      hideRemove={true}
    >
      <HStack>
        {!isReadOnly && isDraggable && (
          <Icon cursor="grab" ml={4} as={RxDragHandleDots2} />
        )}
        <VStack alignItems="flex-start" {...wrapperStyle}>
          {data.conditionGroups && (
            <>
              <HStack
                width="100%"
                wrap="wrap"
                gridGap={isReadOnly ? undefined : "2"}
              >
                <ActivitySelector
                  marketingActivityList={marketingEventNamesList.data || []}
                  value={data.eventName as string}
                  onChange={(value) => onActivityChange(value)}
                  validationError={isInvalidEventName ? "Invalid event" : ""}
                  isReadOnly={isReadOnly}
                />
                {!isReadOnly && !data.conditionGroups[0]?.conditions.length && (
                  <IButton
                    size="sm"
                    variant="link"
                    leftIcon={<RxPlus fontSize="12px" />}
                    onClick={onAddPropertyGroup}
                    isDisabled={!data.eventName}
                    color="brand.blue"
                    fontWeight="normal"
                  >
                    Add property
                  </IButton>
                )}
                {!isReadOnly && (
                  <RemoveRowCloseButton
                    onClick={onRemove}
                  ></RemoveRowCloseButton>
                )}
              </HStack>
              {isReadOnly ? (
                <Stack direction="row" position="relative">
                  <Divider
                    orientation="vertical"
                    h="100%"
                    ml="-16px"
                    position="absolute"
                    borderColor="brandBlue.500"
                  ></Divider>
                  <PropertyGroupContainer
                    filter={data.eventName}
                    data={data.conditionGroups}
                    onChange={onPropertyGroupChange}
                    isReadOnly={isReadOnly}
                  />
                </Stack>
              ) : (
                <PropertyGroupContainer
                  filter={data.eventName}
                  data={data.conditionGroups}
                  onChange={onPropertyGroupChange}
                  isReadOnly={isReadOnly}
                />
              )}
              {!isReadOnly && data.conditionGroups[0]?.conditions.length && (
                <IButton
                  size="sm"
                  variant="link"
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={onAddPropertyGroup}
                  isDisabled={!data.eventName}
                  color="brand.blue"
                  ml="24px !important"
                  mt="8px !important"
                  fontWeight="normal"
                >
                  Add additional property
                </IButton>
              )}
            </>
          )}
        </VStack>
      </HStack>
    </FilterGroupBox>
  );
}
