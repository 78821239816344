import {
  Text,
  VStack,
  HStack,
  Image,
  Box,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import IButton from "../../../../components/IButton";
import { FORM_VENDORS } from "../../../../common/types/form";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import { useAppDispatch } from "../../../../store";
import {
  integrateFormsProvider,
  listAllForms,
  listFormProviders,
  selectForm,
} from "../formSlice";
import { FORM_PROVIDER_DETAILS } from "../helper";
import AddFormProvider from "./AddFormProvider";
import urls from "../../../../urls";
import { useSelector } from "react-redux";
import { isFulfilled, isLoading } from "../../../../common/helper/commonHelper";
import IDuplicateComponent from "../../../../components/IDuplicateComponent";

function LoadingState() {
  return (
    <Box py={2}>
      <Text fontWeight="semibold" fontSize="sm" color="gray.700">
        Connected form providers
      </Text>
      <IDuplicateComponent duplicateCount={2}>
        <HStack py={4}>
          <SkeletonCircle size="10" />
          <SkeletonText w="150px" />
        </HStack>
      </IDuplicateComponent>
      <Text fontWeight="semibold" fontSize="sm" color="gray.700" my={2}>
        Available form providers
      </Text>
      <IDuplicateComponent duplicateCount={2}>
        <Box py={3}>
          <SkeletonText w="200px" noOfLines={1} skeletonHeight={3} />
        </Box>
      </IDuplicateComponent>
    </Box>
  );
}

function ListConnectedProviders({
  connectedProviders,
}: {
  connectedProviders: FORM_VENDORS[];
}) {
  return (
    <Box py={2}>
      <Text fontWeight="semibold" fontSize="sm" color="gray.700">
        Connected form providers
      </Text>

      <VStack alignItems="flex-start" spacing={4} my={3}>
        {connectedProviders.map((provider) => {
          const { imgSrc, name } = FORM_PROVIDER_DETAILS[provider];
          return (
            <HStack spacing={3} my={2} key={"connected_" + provider}>
              <Image
                src={imgSrc}
                alt={name}
                boxSize="35px"
                borderRadius={provider === FORM_VENDORS.WEBFLOW ? "17px" : ""}
              />
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontSize="sm">{name}</Text>
                <IButton
                  variant="link"
                  fontSize="xs"
                  onClick={() =>
                    window.open(`${urls.formProvider}/${provider}`, "_blank")
                  }
                >
                  view details
                </IButton>
              </VStack>
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
}

function ListAvailableProviders({
  availableProviders,
  onAddProvider,
}: {
  availableProviders: FORM_VENDORS[];
  onAddProvider: (vendor: FORM_VENDORS) => void;
}) {
  return (
    <Box py={2}>
      <Text fontWeight="semibold" fontSize="sm" color="gray.700">
        Available form providers
      </Text>
      <Text fontSize="xs" color="text.50">
        Connect to form providers of your choice
      </Text>
      <VStack alignItems="flex-start" mt={4}>
        {availableProviders.map((vendor) => {
          const { name, imgSrc } = FORM_PROVIDER_DETAILS[vendor];

          return (
            <HStack
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
              w="100%"
              h="35px"
              borderRadius="5px"
              onClick={() => onAddProvider(vendor)}
              p={2}
              key={"available_" + vendor}
            >
              <Image
                src={imgSrc}
                alt={name}
                boxSize="18px"
                borderRadius={vendor === FORM_VENDORS.WEBFLOW ? "10px" : ""}
              />
              <Text fontSize="sm">{name}</Text>
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
}

export default function SelectFormProvider({
  isOpen,
  onClose,
  connectedProviders,
  refreshFormsList,
}: {
  isOpen: boolean;
  onClose: () => void;
  connectedProviders: FORM_VENDORS[];
  refreshFormsList: () => void;
}) {
  const dispatch = useAppDispatch();
  const {
    providersList: { loading },
  } = useSelector(selectForm);
  const [provider, setProvider] = useState<FORM_VENDORS | null>(null);

  const availableProviders = useMemo(
    () =>
      (Object.keys(FORM_PROVIDER_DETAILS) as FORM_VENDORS[]).filter(
        (vendor) => !connectedProviders.includes(vendor)
      ),
    [connectedProviders]
  );

  function onSideoutClose() {
    onClose();
    setProvider(null);
  }

  async function onAddProvider(provider: FORM_VENDORS) {
    setProvider(provider);
    const {
      meta: { requestStatus },
    } = await dispatch(integrateFormsProvider(provider));
    isFulfilled(requestStatus) && dispatch(listFormProviders());
  }

  return (
    <>
      <MemoizedCommonDrawer
        isOpen={isOpen && !provider}
        placement="right"
        onClose={onSideoutClose}
        size="xs"
        title="Form providers"
      >
        {isLoading(loading) ? (
          <LoadingState />
        ) : (
          <>
            {!!connectedProviders.length && (
              <ListConnectedProviders connectedProviders={connectedProviders} />
            )}

            {!!availableProviders.length && (
              <ListAvailableProviders
                availableProviders={availableProviders}
                onAddProvider={onAddProvider}
              />
            )}
          </>
        )}
      </MemoizedCommonDrawer>

      {provider && (
        <AddFormProvider
          isOpen
          vendor={provider}
          onClose={onSideoutClose}
          onProceed={() => {
            dispatch(listAllForms());
            refreshFormsList();
          }}
        />
      )}
    </>
  );
}
