import { HStack, Text, useDisclosure } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { TABLE_FILTER_VARIANTS } from "../../common/constants/common";
import { ListFilterItem } from "../../common/types/common";
import IButton from "../IButton";
import FilterSelection, { FilterDisplayItemContainer } from "./FilterSelection";
import { FilterSidebar } from "./FilterSidebar";
import { TABLE_FILTERS } from "./TableFilters";

export default function FilterDisplay({
  appliedFilters,
  variant,
  handleFilterChange,
}: {
  appliedFilters?: ListFilterItem;
  variant: TABLE_FILTER_VARIANTS;
  handleFilterChange: (filters: ListFilterItem) => void;
}) {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const defaultFilters = useMemo(() => {
    return TABLE_FILTERS[variant].filters.filter(
      ({ value }) => value in (appliedFilters ?? {})
    );
  }, [appliedFilters, variant]);

  function clearAllUnderMainFilter(filter: string) {
    const filters = cloneDeep(appliedFilters ?? {});
    if (filter in filters) {
      delete filters[filter];
    }
    handleFilterChange(filters);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  function openFilterOnEditMode(filter: string | null) {
    setSelectedFilter(filter);
    onOpen();
  }

  function onCloseFilterSlide() {
    setSelectedFilter(null);
    onClose();
  }

  function clearAllFilters() {
    handleFilterChange({});
  }

  return (
    <HStack px={5} pb={2}>
      {defaultFilters.slice(0, 3).map((mainFilter) => {
        return (
          <FilterSelection
            isReadOnly={true}
            filter={mainFilter}
            selectedFilters={appliedFilters?.[mainFilter.value]}
            clearSelectedFilter={() =>
              clearAllUnderMainFilter(mainFilter.value)
            }
            openFilterOnEditMode={() => openFilterOnEditMode(mainFilter.value)}
          />
        );
      })}
      {defaultFilters.length > 3 && (
        <FilterDisplayItemContainer
          onClick={() => openFilterOnEditMode(null)}
          title=""
        >
          <Text fontWeight="600">+{defaultFilters.length - 3}</Text>
          <Text isTruncated>more</Text>
        </FilterDisplayItemContainer>
      )}
      <IButton
        variant="link"
        onClick={clearAllFilters}
        children="Clear"
        color="brandBlue.400"
        fontWeight="400"
        pl={2}
        hidden={!defaultFilters.length}
      />
      <FilterSidebar
        isOpen={isOpen}
        onClose={onCloseFilterSlide}
        variant={variant}
        handleFilterChange={handleFilterChange}
        filters={appliedFilters}
        openByFilter={selectedFilter}
      />
    </HStack>
  );
}
