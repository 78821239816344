import {
  Heading,
  VStack,
  Text,
  HStack,
  Divider,
  Box,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useState } from "react";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import { START_SETTINGS } from "../../../../../../common/types/unifiedMapping";
import MemoizedCommonDrawer from "../../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../../connectionDetails/components/ConnectionButtons";

function AdditionalSettings({
  options,
  setOptions,
}: {
  options: string;
  setOptions: (value: string) => void;
}) {
  return (
    <Box>
      <Heading fontSize="14px">Additional Settings</Heading>
      <RadioGroup
        pt="3"
        onChange={(value: string) => setOptions(value)}
        value={options}
      >
        <VStack alignItems="flex-start">
          <Radio
            style={{ cursor: "pointer" }}
            value={START_SETTINGS.RECOMMENDED}
          >
            <Text fontSize="sm">Recommended Settings</Text>
          </Radio>
          <Text fontSize="small" textColor="gray.400" px="6">
            By selecting this option, Salesforce default fields will be
            automatically mapped to Inflection fields. Custom fields will not be
            mapped at this point. You can map them later in the unified mapping.
            If you have segment or any other system configured in the unified
            mapping, checking this box will override any previous mapping and
            immediately start the sync. If you wish to manually map standard or
            custom fields and start the sync later, you can choose the below
            option.
          </Text>
          <Radio
            style={{ cursor: "pointer" }}
            value={START_SETTINGS.SYNC_LATER}
          >
            <Text fontSize="sm">Custom Settings (Start Sync later)</Text>
          </Radio>
          <Text fontSize="small" textColor="gray.400" px="6">
            Choosing this option will not start the sync immediately. You can
            use the unified mapping to map your default and custom fields and
            then start the sync with Salesforce when you are ready.
          </Text>
        </VStack>
      </RadioGroup>
    </Box>
  );
}

function SettingsSummary({
  connectionDetails,
}: {
  connectionDetails: SalesforceConnectionV2;
}) {
  function concatSyncedObjects(
    syncedObjects: string,
    newString: string,
    val?: boolean
  ) {
    if (val) {
      if (!syncedObjects) {
        return newString;
      } else {
        return syncedObjects.concat(", " + newString);
      }
    } else {
      return syncedObjects;
    }
  }

  function getSyncedObjects() {
    const { syncSettings } = connectionDetails;

    let syncedObjects = "";
    syncedObjects = concatSyncedObjects(
      syncedObjects,
      "Accounts",
      syncSettings.objectSettings.account?.syncObject
    );
    syncedObjects = concatSyncedObjects(
      syncedObjects,
      "Contacts",
      syncSettings.objectSettings.contact?.syncObject
    );
    syncedObjects = concatSyncedObjects(
      syncedObjects,
      "Leads",
      syncSettings.objectSettings.lead?.syncObject
    );
    syncedObjects = concatSyncedObjects(
      syncedObjects,
      "Opportunities",
      syncSettings.objectSettings.opportunity?.syncObject
    );

    return syncedObjects;
  }

  return (
    <Box>
      <Heading fontSize="14px" alignSelf="left">
        Summary
      </Heading>
      <HStack pt="2">
        <VStack alignItems="flex-start">
          <Text fontSize="sm">Connection Name</Text>
          <Text fontSize="sm">APIs Allocated to Inflection</Text>
          <Text fontSize="sm">Approximate Sync Interval</Text>
          <Text fontSize="sm">Objects Synced</Text>
        </VStack>
        <VStack px="10" alignItems="flex-start">
          <Text fontSize="sm">{connectionDetails.connectionName}</Text>
          <Text fontSize="sm">
            {connectionDetails.salesforceLimits.dailyApiRequests.max} items
          </Text>
          <Text fontSize="sm">
            {connectionDetails?.syncIntervalInMinutes ?? 0} minutes
          </Text>
          <Text fontSize="sm">{getSyncedObjects()}</Text>
        </VStack>
      </HStack>
    </Box>
  );
}

export default function SfSummaryDrawer({
  connectionDetails,
  isOpen,
  onClose,
  completeSfSync,
  goToPrevStep,
  goToNextStep,
  completingSetup,
}: {
  connectionDetails: SalesforceConnectionV2 | null;
  isOpen: boolean;
  completeSfSync: (autoMap: boolean, connectionId: string) => void;
  onClose: () => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  completingSetup?: boolean;
}) {
  const [option, setOption] = useState<string>(START_SETTINGS.RECOMMENDED);

  const isRecommendedSettings = option === START_SETTINGS.RECOMMENDED;

  function finishSetup() {
    if (isRecommendedSettings) {
      goToNextStep();
    } else {
      connectionDetails?.connectionId &&
        completeSfSync(false, connectionDetails.connectionId);
    }
  }

  return (
    <MemoizedCommonDrawer
      title="Salesforce"
      onClose={onClose}
      isOpen={isOpen}
      goBack={goToPrevStep}
      size="md"
      placement="right"
    >
      <VStack alignItems="flex-start" pb="3" spacing={4} pl={1}>
        {connectionDetails && (
          <SettingsSummary connectionDetails={connectionDetails} />
        )}
        <Divider />
        <AdditionalSettings options={option} setOptions={setOption} />
      </VStack>
      <ConnectionButtons
        name="continue-button"
        isLoading={completingSetup}
        onClick={finishSetup}
        label={isRecommendedSettings ? "Start Sync" : "Finish Setup Later"}
        closeModal={onClose}
      />
    </MemoizedCommonDrawer>
  );
}
