export const CONTACT_ACCOUNT_MAPPING = "contact_to_account_mapping";

export enum STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const StatusSpecificBadge = {
  [STATUS.ACTIVE]: {
    color: "green",
  },
  [STATUS.INACTIVE]: {
    color: "grey",
  },
};

export enum DELIMITER {
  WHITESPACE = "whitespace",
  COMMA = "comma",
  NONE = "none",
}

export const ApiToDelimiterMapping = {
  [DELIMITER.WHITESPACE]: {
    value: "Multiple Values whitespace separated",
    apiValue: "whitespace",
  },
  [DELIMITER.COMMA]: {
    value: "Multiple Values Comma separated",
    apiValue: "comma",
  },
  [DELIMITER.NONE]: {
    value: "Single Value",
    apiValue: "none",
  },
};
