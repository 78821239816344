import {
  ApiResponse,
  ApiResponseWithPagination,
  ListFilterParams,
} from "../../types/common";
import {
  DomainDetails,
  DomainReq,
  ValidateDomainResp,
} from "../../types/settings";
import { websiteTrackingRequest } from "./api";

const DOMAIN_BASE_URL = "/config/domains";

export async function getDomainListApi(
  params: ListFilterParams
): Promise<ApiResponseWithPagination<DomainDetails[]>> {
  return websiteTrackingRequest({
    url: DOMAIN_BASE_URL,
    params: params,
    method: "GET",
  });
}

export async function createDomainApi(
  domainInfo: DomainReq
): Promise<ApiResponse<DomainDetails>> {
  return websiteTrackingRequest({
    url: DOMAIN_BASE_URL,
    method: "POST",
    data: domainInfo,
  });
}

export async function updateDomainApi(
  domainInfo: DomainReq,
  domainId: string
): Promise<ApiResponse<DomainDetails>> {
  return websiteTrackingRequest({
    url: `${DOMAIN_BASE_URL}/${domainId}`,
    method: "PUT",
    data: domainInfo,
  });
}

export async function deleteDomainApi(
  domainId: string
): Promise<ApiResponse<{}>> {
  return websiteTrackingRequest({
    url: `${DOMAIN_BASE_URL}/${domainId}`,
    method: "DELETE",
  });
}

export async function validateDomainApi(
  domain: string
): Promise<ApiResponse<ValidateDomainResp>> {
  return websiteTrackingRequest({
    url: `${DOMAIN_BASE_URL}/validate`,
    method: "GET",
    params: {
      domain,
    },
  });
}

export async function sendEmailToDevApi(
  email: string
): Promise<ApiResponse<{}>> {
  return websiteTrackingRequest({
    url: "email/developer/send",
    method: "POST",
    data: {
      developerEmail: email,
    },
  });
}

export async function activateDomainApi(
  domainId: string
): Promise<ApiResponse<{}>> {
  return websiteTrackingRequest({
    url: `${DOMAIN_BASE_URL}/${domainId}/activate`,
    method: "POST",
  });
}

export async function deactivateDomainApi(
  domainId: string
): Promise<ApiResponse<{}>> {
  return websiteTrackingRequest({
    url: `${DOMAIN_BASE_URL}/${domainId}/deactivate`,
    method: "POST",
  });
}
