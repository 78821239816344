import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Link,
  Heading,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../urls";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  registerUser,
  selectAccount,
  resetRegisterSuccess,
} from "./accountSlice";
import { Formik, Form } from "formik";
import { object } from "yup";
import {
  emailValidation,
  passwordValidation,
  domainValidation,
  requiredString,
} from "../../common/helper/validationHelper";
import InputFieldWithError from "../../components/InputFieldWithError";
import { PasswordField } from "../../components/PasswordField";
import MessageDisplayPage from "../../components/MessageDisplayPage";
import IButton, { BUTTON } from "../../components/IButton";
import PasswordValidationPopover from "../../components/PasswordValidationPopover";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<null | HTMLInputElement>(null);
  const initialFocusRef = useRef(null);
  const [isFocussed, setIsFocussed] = useState(false);
  const initValues = {
    name: "",
    email: "",
    password: "",
    organisation: "",
    domain: "",
  };
  const registerSchema = object().shape({
    name: requiredString("Name"),
    email: emailValidation,
    password: passwordValidation,
    organisation: requiredString("Organization"),
    domain: domainValidation,
  });

  const { register } = useSelector(selectAccount);

  useEffect(() => {
    return () => {
      dispatch(resetRegisterSuccess());
    };
  }, [dispatch]);

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsFocussed(false);
    },
  });

  function registerSubmit(values: any) {
    dispatch(registerUser(values));
  }

  return (
    <>
      {!register.success ? (
        <Flex
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          bg={"grayV2.100"}
        >
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Heading fontSize={"4xl"}>Get started with Inflection</Heading>
            </Stack>
            <Box w={"lg"} rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
              <Formik
                enableReinitialize={true}
                initialValues={initValues}
                onSubmit={registerSubmit}
                validationSchema={registerSchema}
              >
                {(formik) => {
                  const {
                    errors,
                    touched,
                    isValid,
                    dirty,
                    handleBlur,
                    handleChange,
                    values,
                  } = formik;
                  return (
                    <Form>
                      <Stack spacing={4}>
                        <InputFieldWithError
                          labelText="Name"
                          name="name"
                          id="name"
                          value={values.name}
                          errorMsg={errors.name}
                          touched={!!touched.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={14}
                        />
                        <InputFieldWithError
                          labelText="Email"
                          name="email"
                          id="email"
                          value={values.email}
                          errorMsg={errors.email}
                          touched={!!touched.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={14}
                        />
                        <PasswordValidationPopover
                          password={values.password}
                          isFocussed={isFocussed}
                          initialFocusRef={initialFocusRef}
                        >
                          <FormControl
                            ref={ref}
                            id="password"
                            isInvalid={!!errors.password && touched.password}
                          >
                            <FormLabel>Password</FormLabel>
                            <PasswordField
                              ref={initialFocusRef}
                              name="password"
                              id="password"
                              value={values.password}
                              onFocus={() => {
                                setIsFocussed(true);
                              }}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fontSize={14}
                              check={passwordValidation.isValidSync(
                                values.password
                              )}
                            />
                            <FormErrorMessage fontSize={12}>
                              {errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        </PasswordValidationPopover>
                        <InputFieldWithError
                          labelText="Organization"
                          name="organisation"
                          id="organisation"
                          value={values.organisation}
                          errorMsg={errors.organisation}
                          touched={!!touched.organisation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={14}
                        />

                        <InputFieldWithError
                          labelText="Domain"
                          name="domain"
                          id="domain"
                          value={values.domain}
                          errorMsg={errors.domain}
                          touched={!!touched.domain}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={14}
                        />
                        <IButton
                          mt={5}
                          w={"100%"}
                          type="submit"
                          variant={BUTTON.PRIMARY}
                          color={"white"}
                          name="signup-button"
                          disabled={!(isValid && dirty)}
                          _hover={{
                            bg: "blue.500",
                          }}
                          isLoading={register.loading}
                        >
                          Sign Up
                        </IButton>
                        <Text fontSize={"sm"} textAlign="center" mt={2}>
                          Already have an account?{" "}
                          <Link
                            color={"blue.400"}
                            as={RouterLink}
                            to={urls.login}
                            id="goto-login"
                          >
                            Login
                          </Link>
                        </Text>
                      </Stack>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Stack>
        </Flex>
      ) : (
        <MessageDisplayPage
          heading="Registration Successful"
          displayMessage="Thanks for signing up! We have sent you a confirmation email, please click on the link to activate your account"
          redirectFn={() => navigate(urls.login, { replace: true })}
          redirectMsg="Go to Login page"
          type="success"
        />
      )}
    </>
  );
}
