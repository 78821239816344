import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { LOADING_STATES } from "../../../common/constants/common";
import {
  uploadCsvFileToS3Url,
  initializeLoadingData,
  downloadFileWithoutPageReload,
  isIngestionTypeFlink,
} from "../../../common/helper/commonHelper";
import {
  CsvRecords,
  MappedFieldsWithDestId,
  DestIdType,
  CsvMappingWithDestId,
  CsvMappingWithPersonDest,
  UploadSummaryType,
  ContactImportPlan,
  IMPORT_ACTIONS,
  GetUploadStatusResp,
} from "../../../common/types/contactUpload";
import {
  getErrorReportDownloadUrlApi,
  getSmartMappingApi,
  getUploadStatusApi,
  getUploadSummaryApi,
  getUploadUrlApi,
  importContactsFromCsvApi,
  importContactsFromCsvAsyncApi,
  updateCsvMappingApi,
  validateFileApi,
} from "../../../common/api/campaign/contactUpload";
import { LoadingWithData } from "../../../common/types/common";

const INIT_OVERWRITE_CONFIG = {
  updateOnlyEmptyFieldsInDb: false,
  updateDbWithBlankValueInCsv: false,
};

type ContactUploadInitState = {
  fileUpload: {
    uploadId: string;
    fileName: string;
    isLoading: LOADING_STATES;
    cancel: boolean;
    errorMsg: string;
    sampleCsvRecords: CsvRecords;
    csvColumnHeaders: string[];
  };

  csvSmartMapping: LoadingWithData<CsvMappingWithPersonDest> & {
    isUpdating: LOADING_STATES;
    mappedFields: MappedFieldsWithDestId[];
  };
  uploadSummary: LoadingWithData<UploadSummaryType>;
  downloadingErrorReport: LOADING_STATES;
  contactImportCsv: LoadingWithData<ContactImportPlan>;
  contactImportConfig: {
    staticListId: null | string;
    overwriteConfig: typeof INIT_OVERWRITE_CONFIG;
    importAction: IMPORT_ACTIONS;
  };
  isContactsUploaded: LoadingWithData<GetUploadStatusResp>;
  abortUploadPoll: boolean;
};

const initialState: ContactUploadInitState = {
  fileUpload: {
    uploadId: "",
    fileName: "",
    isLoading: LOADING_STATES.INIT,
    errorMsg: "",
    cancel: false,
    sampleCsvRecords: [],
    csvColumnHeaders: [],
  },

  csvSmartMapping: {
    ...initializeLoadingData({}),
    mappedFields: [],
    isUpdating: LOADING_STATES.INIT,
  },
  uploadSummary: initializeLoadingData({}),
  downloadingErrorReport: LOADING_STATES.INIT,
  contactImportConfig: {
    staticListId: null,
    overwriteConfig: INIT_OVERWRITE_CONFIG,
    importAction: IMPORT_ACTIONS.CREATE_AND_UPDATE,
  },
  contactImportCsv: initializeLoadingData({}),
  isContactsUploaded: initializeLoadingData({}),
  abortUploadPoll: false,
};

// csv Header mapped to person destination name (id)
function getMappedFieldsData(
  mapping: CsvMappingWithPersonDest
): MappedFieldsWithDestId[] {
  return Object.entries(mapping).map(([csvColumn, destDetails]) => {
    return {
      csvColumn,
      destId: destDetails?.name ?? null,
    };
  });
}

export const uploadAndValidateFile = createAsyncThunk<
  { uploadId: string; sampleRecords: CsvRecords; csvHeaders: string[] },
  File,
  { rejectValue: string }
>(
  "upload-contact/upload.validate.file",
  async (file: File, { rejectWithValue, fulfillWithValue }) => {
    try {
      // get s3 url to upload the user file
      const uploadConfig: Awaited<ReturnType<typeof getUploadUrlApi>> =
        await getUploadUrlApi();
      const { data: uploadData, error: uploadError } = uploadConfig;

      if (uploadError) return rejectWithValue(uploadError.message);
      if (!uploadData) throw new Error();

      const { upload_id, s3_url } = uploadData;

      //upload the file to the url if no error msg was returned
      const fileUploadResp = await uploadCsvFileToS3Url(file, s3_url);

      if (fileUploadResp.ok) {
        // validate the uploaded file.
        const validationResp: Awaited<ReturnType<typeof validateFileApi>> =
          await validateFileApi(upload_id);

        const { data: validationData, error: validationError } = validationResp;

        if (validationError) return rejectWithValue(validationError.message);
        if (!validationData) throw new Error();
        const {
          file: { records, headers },
        } = validationData;

        return fulfillWithValue({
          uploadId: upload_id,
          sampleRecords: records,
          csvHeaders: headers,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      return rejectWithValue("File could not be uploaded");
    }
  }
);

export const getSmartMapping = createAsyncThunk(
  "upload-contacts/get.smartMapping",
  async (uploadId: string, { getState }) => {
    const {
      contactUpload: {
        fileUpload: { csvColumnHeaders },
      },
    } = getState() as RootState;
    return await getSmartMappingApi({
      uploadId,
      fieldNamesToMap: csvColumnHeaders,
    });
  }
);

export const updateCsvMapping = createAsyncThunk(
  "upload-contacts/update.mapping",
  async (data: { uploadId: string; mappedFields: CsvMappingWithDestId }) => {
    return await updateCsvMappingApi(data);
  }
);

export const getUploadSummary = createAsyncThunk(
  "upload-contacts/upload.summary",
  async (uploadId: string) => {
    return await getUploadSummaryApi(uploadId);
  }
);

export const downloadErrorReport = createAsyncThunk(
  "upload-contacts/download-report",
  async (uploadId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const downloadUrlData = (await getErrorReportDownloadUrlApi(uploadId))
        .data;
      if (downloadUrlData) {
        downloadFileWithoutPageReload(
          downloadUrlData.s3_url,
          "error_report.csv"
        );
        return fulfillWithValue("Downloaded successfully");
      }
      throw new Error();
    } catch (error) {
      return rejectWithValue("Download failed");
    }
  }
);

export const importContactsFromCsv = createAsyncThunk(
  "upload-contacts/import.contacts",
  async (
    {
      uploadId,
      testRun,
      listId,
    }: { uploadId: string; testRun: boolean; listId?: string },
    { getState }
  ) => {
    const {
      contactUpload: {
        contactImportConfig: { importAction, overwriteConfig, staticListId },
      },
      featureFlag: {
        contactIngestionEtl: { data: etl },
      },
    } = getState() as RootState;
    const data = {
      testRun,
      uploadId,
      importAction,
      staticListId: listId ?? staticListId ?? "",
      ...overwriteConfig,
    };

    if (isIngestionTypeFlink(etl)) {
      return importContactsFromCsvAsyncApi(data);
    }
    return importContactsFromCsvApi(data);
  }
);

export const getUploadStatus = createAsyncThunk(
  "upload-contacts/status",
  async ({ uploadId }: { uploadId: string }) => {
    return getUploadStatusApi(uploadId);
  },
  {
    condition: (_, { getState }) => {
      const {
        contactUpload: { abortUploadPoll },
      } = getState() as RootState;

      return !abortUploadPoll;
    },
  }
);

//to avoid race condition ,from previous upload endpoints
function isCorrectUploadResp(
  state: ContactUploadInitState,
  argUploadId: string
) {
  const { uploadId } = state.fileUpload;
  return uploadId && uploadId === argUploadId;
}

const contactUploadSlice = createSlice({
  name: "contactUploader",
  initialState,
  reducers: {
    resetFileUploadDetails(state) {
      state.fileUpload = initialState.fileUpload;
    },
    abortUploadPoll(state) {
      state.abortUploadPoll = true;
    },
    setFileUploadError(
      state,
      { payload }: PayloadAction<{ fileName: string; errorMsg: string }>
    ) {
      state.fileUpload.errorMsg = payload.errorMsg;
      state.fileUpload.fileName = payload.fileName;
      state.fileUpload.isLoading = LOADING_STATES.FAILED;
    },
    cancelUploadFile(state) {
      state.fileUpload.cancel = true;
    },
    resetMappingData(state) {
      state.csvSmartMapping = initialState.csvSmartMapping;
    },
    updateMappedFields(
      state,
      {
        payload,
        payload: { csvColumn, destId },
      }: PayloadAction<{ csvColumn: string; destId: DestIdType }>
    ) {
      const data = state.csvSmartMapping.mappedFields;
      const index = data.findIndex(
        ({ csvColumn }) => csvColumn === payload.csvColumn
      );
      if (index < 0) {
        state.csvSmartMapping.mappedFields = [...data, { csvColumn, destId }];
      } else {
        state.csvSmartMapping.mappedFields[index] = { csvColumn, destId };
      }
    },
    setImportAction(
      state,
      { payload: importAction }: PayloadAction<IMPORT_ACTIONS>
    ) {
      state.contactImportConfig.importAction = importAction;
    },
    addStaticList(state, { payload: listId }: PayloadAction<string | null>) {
      state.contactImportConfig.staticListId = listId;
    },
    updateOverwriteConfig(
      state,
      {
        payload: config,
      }: PayloadAction<{
        name: keyof typeof INIT_OVERWRITE_CONFIG;
        isChecked: boolean;
      }>
    ) {
      state.contactImportConfig.overwriteConfig = {
        ...state.contactImportConfig.overwriteConfig,
        [config.name]: config.isChecked,
      };
    },
    resetAllUploadDetails(state) {
      state.fileUpload = initialState.fileUpload;
      state.contactImportConfig = initialState.contactImportConfig;
      state.csvSmartMapping = initialState.csvSmartMapping;
      state.uploadSummary = initialState.uploadSummary;
      state.downloadingErrorReport = initialState.downloadingErrorReport;
      state.contactImportCsv = initialState.contactImportCsv;
    },
  },
  extraReducers: (builder) => {
    builder

      //upload file to s3 and validate the file after uploading
      .addCase(uploadAndValidateFile.pending, (state, { meta: { arg } }) => {
        state.fileUpload.isLoading = LOADING_STATES.LOADING;
        state.fileUpload.cancel = false;
        state.fileUpload.fileName = arg?.name ?? "";
        state.fileUpload.errorMsg = "";
      })
      .addCase(uploadAndValidateFile.fulfilled, (state, { payload }) => {
        const { uploadId, sampleRecords, csvHeaders } = payload;
        state.fileUpload.uploadId = uploadId;
        state.fileUpload.sampleCsvRecords = sampleRecords;
        state.fileUpload.csvColumnHeaders = csvHeaders;
        state.fileUpload.isLoading = LOADING_STATES.SUCCESS;
      })
      .addCase(
        uploadAndValidateFile.rejected,
        (state, { payload: errorMsg }) => {
          if (!state.fileUpload.cancel) {
            state.fileUpload.errorMsg = errorMsg ?? "";
            state.fileUpload.isLoading = LOADING_STATES.FAILED;
          } else {
            state.fileUpload = initialState.fileUpload;
          }
        }
      )

      // get smart mapping options to fill the data
      .addCase(getSmartMapping.pending, (state) => {
        state.csvSmartMapping.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getSmartMapping.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          if (isCorrectUploadResp(state, arg)) {
            if (data) {
              const smartMapping = data.file_to_datastore_mapping;
              state.csvSmartMapping.data = smartMapping;
              state.csvSmartMapping.mappedFields =
                getMappedFieldsData(smartMapping);
              state.csvSmartMapping.loading = LOADING_STATES.SUCCESS;
            } else {
              state.csvSmartMapping.loading = LOADING_STATES.FAILED;
            }
          }
        }
      )
      .addCase(getSmartMapping.rejected, (state, { meta: { arg } }) => {
        if (isCorrectUploadResp(state, arg)) {
          state.csvSmartMapping.loading = LOADING_STATES.FAILED;
        }
      })

      // update the same mapping after save
      .addCase(updateCsvMapping.pending, (state) => {
        state.csvSmartMapping.isUpdating = LOADING_STATES.LOADING;
      })
      .addCase(
        updateCsvMapping.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          if (isCorrectUploadResp(state, arg.uploadId)) {
            if (data) {
              state.csvSmartMapping.isUpdating = LOADING_STATES.SUCCESS;
            } else {
              state.csvSmartMapping.isUpdating = LOADING_STATES.FAILED;
            }
          }
        }
      )
      .addCase(updateCsvMapping.rejected, (state, { meta: { arg } }) => {
        if (isCorrectUploadResp(state, arg.uploadId)) {
          state.csvSmartMapping.isUpdating = LOADING_STATES.FAILED;
        }
      })

      //get the upload summary for a single upload
      .addCase(getUploadSummary.pending, (state) => {
        state.uploadSummary.loading = LOADING_STATES.LOADING;
        state.uploadSummary.data = initializeLoadingData({}).data;
      })
      .addCase(
        getUploadSummary.fulfilled,
        (state, { payload: { data, error }, meta: { arg } }) => {
          if (isCorrectUploadResp(state, arg)) {
            if (data) {
              state.uploadSummary.data = {
                totalContacts: data.total_contacts,
                newContacts: data.new_contacts,
                errors: data.errors,
                reportAvailable: data.report_available,
              };
              state.uploadSummary.loading = LOADING_STATES.SUCCESS;
            }

            if (error) {
              state.uploadSummary.loading = LOADING_STATES.FAILED;
              state.uploadSummary.data = initializeLoadingData({}).data;
            }
          }
        }
      )
      .addCase(getUploadSummary.rejected, (state, { meta: { arg } }) => {
        if (isCorrectUploadResp(state, arg)) {
          state.uploadSummary.loading = LOADING_STATES.FAILED;
        }
      })

      // download the error report
      .addCase(downloadErrorReport.pending, (state) => {
        state.downloadingErrorReport = LOADING_STATES.LOADING;
      })
      .addCase(downloadErrorReport.fulfilled, (state) => {
        state.downloadingErrorReport = LOADING_STATES.SUCCESS;
      })
      .addCase(downloadErrorReport.rejected, (state) => {
        state.downloadingErrorReport = LOADING_STATES.FAILED;
      })

      //upload contacts to contact Db
      .addCase(importContactsFromCsv.pending, (state) => {
        state.contactImportCsv.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        importContactsFromCsv.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          if (isCorrectUploadResp(state, arg.uploadId)) {
            if (data) {
              state.contactImportCsv.data = {
                newContacts: data.plan.insert_count,
                updatableContacts: data.plan.update_count,
                staticList: {
                  name: data.plan.static_list?.name ?? "",
                  insertCount: data.plan.static_list?.insert_count ?? 0,
                },
              };
              state.contactImportCsv.loading = LOADING_STATES.SUCCESS;
            } else {
              state.contactImportCsv.loading = LOADING_STATES.FAILED;
            }
          }
        }
      )
      .addCase(importContactsFromCsv.rejected, (state, { meta: { arg } }) => {
        if (isCorrectUploadResp(state, arg.uploadId)) {
          state.contactImportCsv.loading = LOADING_STATES.FAILED;
        }
      })

      // get upload status
      .addCase(getUploadStatus.pending, (state) => {
        state.isContactsUploaded.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getUploadStatus.fulfilled,
        (state, { payload: { data }, meta: { arg } }) => {
          if (isCorrectUploadResp(state, arg.uploadId)) {
            if (data) {
              state.isContactsUploaded = {
                loading: LOADING_STATES.SUCCESS,
                data,
              };
            } else {
              state.isContactsUploaded.loading = LOADING_STATES.FAILED;
            }
          }
        }
      )
      .addCase(getUploadStatus.rejected, (state, { meta: { arg } }) => {
        if (isCorrectUploadResp(state, arg.uploadId)) {
          state.isContactsUploaded.loading = LOADING_STATES.FAILED;
        }
      });
  },
});

export const {
  resetAllUploadDetails,
  resetFileUploadDetails,
  cancelUploadFile,
  resetMappingData,
  updateMappedFields,
  setImportAction,
  updateOverwriteConfig,
  addStaticList,
  setFileUploadError,
  abortUploadPoll,
} = contactUploadSlice.actions;
export const selectContactUpload = (state: RootState) => state.contactUpload;
export default contactUploadSlice.reducer;
