import { SOURCES } from "../types/unifiedMapping";
import snowflakeLogo from "../../common/img/logo/snowflake.png";
import redshiftLogo from "../../common/img/logo/redshift.png";
import salesforceLogo from "../../common/img/logo/salesforce.png";
import segmentLogo from "../../common/img/logo/segment.png";
import { DwServices } from "../types/connection";
import bigqueryLogo from "../../common/img/logo/bigquery.png";
import webflowLogo from "../../common/img/logo/webflow.png";
import formstackLogo from "../../common/img/logo/formstack.png";

export enum ACTIVITY_TYPE {
  SYNC_PAUSED = "sync_paused",
  SYNC_RESUMED = "sync_resumed",
  SYNC_EDITED = "sync_edited",
  SYNC_CREATED = "sync_created",
}

export const SEGMENT_SUMMARY_RANGE_HOURS = 24;

export const CONNECTION_ID = "connection_id";
export const SF_REAUTHENTICATION = "sf_reauthenticating";

export const DATA_CONNECTION_SERVICES_LIST: DwServices[] = [
  {
    id: SOURCES.SNOWFLAKE,
    label: "Snowflake",
    imgSrc: snowflakeLogo,
    hidden: false,
  },
  {
    id: SOURCES.REDSHIFT,
    label: "Redshift",
    imgSrc: redshiftLogo,
    hidden: false,
  },
  {
    id: SOURCES.SALESFORCE,
    label: "Salesforce",
    imgSrc: salesforceLogo,
    hidden: false,
  },
  {
    id: SOURCES.SEGMENT,
    label: "Segment",
    imgSrc: segmentLogo,
    hidden: false,
  },
  {
    id: SOURCES.BIGQUERY,
    label: "BigQuery",
    imgSrc: bigqueryLogo,
    hidden: false,
  },
  {
    id: SOURCES.WEBFLOW,
    label: "Webflow",
    imgSrc: webflowLogo,
    hidden: false,
  },
  {
    id: SOURCES.FORMSTACK,
    label: "Formstack",
    imgSrc: formstackLogo,
    hidden: false,
  },
];

export const DW_SOURCES = [
  SOURCES.BIGQUERY,
  SOURCES.REDSHIFT,
  SOURCES.SNOWFLAKE,
];

export const FORMS_SOURCES = [SOURCES.WEBFLOW, SOURCES.FORMSTACK];

export const READONLY_SF_SYNC_MESSAGE =
  "Salesforce preferred fields are read-only";
