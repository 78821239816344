import { Box, HStack, VStack } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CONTACT_ACCOUNT_MAPPING } from "../../../../../../common/constants/AccountContactMapping";
import {
  isInit,
  isLoading,
} from "../../../../../../common/helper/commonHelper";
import { AccountContactAutoMappingElement } from "../../../../../../common/types/AccountContactMapping";
import { PersonDestination } from "../../../../../../common/types/person";
import { DESTINATION_TYPES } from "../../../../../../common/types/unifiedMapping";
import SpinnerContainer from "../../../../../../components/SpinnerContainer";
import ContentContainer from "../../../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../../../../store";
import {
  getAccountMappingDetails,
  selectDirectoryAccount,
} from "../../../../accountdb/accountDbSlice";
import {
  getPersonMappingDetails,
  selectPerson,
} from "../../../../persondb/personDbSlice";
import {
  getAccountContactAutoMapping,
  getUnifiedMapping,
  selectConnection,
} from "../../../connectionSlice";
import AccountAutoDelimiter from "./components/AccountAutoDelimiter";
import AccountAutoDropdown from "./components/AccountAutoDropdown";
import AccountAutoSettingNotes from "./components/AccountAutoSettingNotes";
import AccountAutoSettingsEnable from "./components/AccountAutoSettingsEnable";
import SaveAndCancelBar from "./components/SaveAndCancelBar";
import { isAccountSyncEnabled } from "../../../../../../common/helper/salesforceHelper";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import { LOADING_STATES } from "../../../../../../common/constants/common";

const initPersonDestinationValues = {
  name: "",
  type: DESTINATION_TYPES.STRING,
  display: "",
  custom: false,
  inferred: false,
  required: false,
  length: null,
  hidden: false,
  sub_type: null,
  primary_key: false,
  read_only: false,
  updatable: false,
  value: "",
};

export default function AccountAutoSettings({
  sfConnection,
  loadingConnection,
}: {
  sfConnection: SalesforceConnectionV2 | null;
  loadingConnection: LOADING_STATES;
}) {
  const dispatch = useAppDispatch();
  const {
    accountContactAutoMapping: { mapping, updatingMapping, fetchingMapping },
    unifiedConnection: {
      connectionList: { loadingList: fetchingConnectionList },
      mapping: connectionMapping,
    },
  } = useSelector(selectConnection);
  const {
    personMappingDetails: { data: personMappingDetails },
  } = useSelector(selectPerson);
  const {
    accountMappingDetails: { data: accountMappingDetails },
  } = useSelector(selectDirectoryAccount);

  const [isEnabled, setIsEnabled] = useState(mapping?.is_enabled ?? false);
  const [isReadonly, setIsReadonly] = useState(true);
  const [delimiterValue, setDelimiterValue] = useState<{
    label: string;
    value: string | null;
  }>({ label: "", value: "" });
  const [accountField, setAccountField] = useState<PersonDestination>(
    initPersonDestinationValues
  );
  const [contactField, setContactField] = useState<PersonDestination>(
    initPersonDestinationValues
  );

  useEffect(() => {
    dispatch(getPersonMappingDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountMappingDetails());
  }, [dispatch]);

  useEffect(() => {
    if (isInit(fetchingMapping)) {
      dispatch(getAccountContactAutoMapping(CONTACT_ACCOUNT_MAPPING));
    }
  }, [dispatch, fetchingMapping]);

  useEffect(() => {
    if (isInit(connectionMapping.loading)) {
      dispatch(getUnifiedMapping());
    }
  }, [dispatch, connectionMapping.loading]);

  const isSalesforceObjectEnabled = useMemo(
    () => isAccountSyncEnabled(sfConnection),
    [sfConnection]
  );

  useEffect(() => {
    setIsEnabled((prev) => prev && isSalesforceObjectEnabled);
    setIsReadonly((prev) => prev || !isSalesforceObjectEnabled);
  }, [isSalesforceObjectEnabled]);

  const contactList: PersonDestination[] = useMemo(
    () =>
      personMappingDetails
        ? Object.keys(personMappingDetails).map(
            (key) => personMappingDetails[key]
          )
        : [],
    [personMappingDetails]
  );

  const accountList: PersonDestination[] = useMemo(
    () =>
      accountMappingDetails
        ? Object.keys(accountMappingDetails).map(
            (key) => accountMappingDetails[key]
          )
        : [],
    [accountMappingDetails]
  );

  return (
    <ContentContainerWithHeader>
      <SpinnerContainer
        loading={
          isLoading(fetchingMapping) ||
          isLoading(loadingConnection) ||
          isLoading(fetchingConnectionList)
        }
      >
        <HStack minH="45px" maxH="45px" flex={1} justifyContent="flex-end">
          <SaveAndCancelBar
            readonlyMode={isReadonly}
            setIsReadonly={setIsReadonly}
            isEnabled={isEnabled}
            accountField={accountField}
            contactField={contactField}
            delimiterValue={delimiterValue}
            updatingMapping={updatingMapping}
            isHidden={!isSalesforceObjectEnabled}
          />
        </HStack>
        <ContentContainer>
          <Box>
            <HStack
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
              pr="5"
            >
              <VStack alignItems="flex-start">
                <AccountAutoSettingsEnable
                  isEnabled={isEnabled}
                  readonly={isReadonly}
                  setIsEnabled={setIsEnabled}
                  accountAutoMapping={
                    mapping as AccountContactAutoMappingElement
                  }
                />
                {isEnabled && (
                  <>
                    <Box pt="5" />
                    <AccountAutoDropdown
                      readonlyMode={isReadonly}
                      accountList={accountList}
                      contactList={contactList}
                      accountAutoMapping={
                        mapping as AccountContactAutoMappingElement
                      }
                      fetchingMapping={fetchingMapping}
                      accountField={accountField}
                      setAccountField={setAccountField}
                      contactField={contactField}
                      setContactField={setContactField}
                    />
                    <Box pt="3" />
                    <AccountAutoDelimiter
                      readonlyMode={isReadonly}
                      accountAutoMapping={
                        mapping as AccountContactAutoMappingElement
                      }
                      fetchingMapping={fetchingMapping}
                      delimiterValue={delimiterValue}
                      setDelimiterValue={setDelimiterValue}
                    />
                    <AccountAutoSettingNotes />
                  </>
                )}
              </VStack>
            </HStack>
          </Box>
        </ContentContainer>
      </SpinnerContainer>
    </ContentContainerWithHeader>
  );
}
