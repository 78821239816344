import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Text,
  useDisclosure,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInviteList,
  selectUser,
  setUserInviteListPage,
  deleteInvite,
  resetUserInviteListPage,
} from "./userSlice";
import { selectAccount } from "../../account/accountSlice";
import { FaEllipsisV } from "react-icons/fa";
import { Auth, USER_ACCOUNT_STATUS } from "../../../common/types/auth";
import {
  ROLE_DISPLAY_VALUE,
  USER_MODAL_TYPES,
} from "../../../common/constants/auth";
import UserManagementModal from "./UserManagementModals";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import UserNameWithAvatar from "../../../components/UserNameWithAvatar";
import IButton, { BUTTON } from "../../../components/IButton";
import { CommonListHeader } from "../../../components/CommonListHeader";
import DeactivateUserModal from "./DeactivateUserModal";
import { DataTable } from "../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import SubHeader from "../settings/components/SubHeader";
import ContentContainer from "../../../components/v2/ContentContainer";
import { DELETION_MODAL_TYPES_INFO } from "../../../common/constants/common";

const USER_STATUS_COLORS = {
  [USER_ACCOUNT_STATUS.ACTIVE]: "green",
  [USER_ACCOUNT_STATUS.INACTIVE]: "gray",
  [USER_ACCOUNT_STATUS.PENDING]: "blue",
  [USER_ACCOUNT_STATUS.ERROR]: "red",
};

function UserListHeader({
  sendInvite,
  loading,
  showInvite,
}: {
  sendInvite: () => void;
  loading: boolean;
  showInvite: boolean;
}) {
  return (
    <CommonListHeader heading="" headerStackProps={{ px: "0px" }}>
      {showInvite && (
        <Box>
          <IButton
            variant={BUTTON.PRIMARY}
            name="invite-users"
            onClick={sendInvite}
            isLoading={loading}
          >
            Invite users
          </IButton>
        </Box>
      )}
    </CommonListHeader>
  );
}

export default function UserList() {
  const dispatch = useDispatch();
  const {
    isOpen: isOpenInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();
  const {
    isOpen: isOpenDeactivateModal,
    onOpen: onOpenDeactivateModal,
    onClose: onCloseDeactivateModal,
  } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<Auth | null>(null);
  const [modalType, setModalType] = useState<USER_MODAL_TYPES>(
    USER_MODAL_TYPES.INVITE
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<Auth | null>(null);
  const { user } = useSelector(selectAccount);
  const isAdmin = user.roles.some(
    (role) => role.role_name === ROLE_DISPLAY_VALUE.Admin
  );
  const {
    userInviteList,
    refreshList,
    invitingUser,
    deletingInvite,
    resendingInvite,
    updatingUser,
  } = useSelector(selectUser);

  useEffect(() => {
    if (refreshList) {
      dispatch(getUserInviteList());
    }
  }, [refreshList, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetUserInviteListPage());
    };
  }, [dispatch]);

  function deleteInviteCall() {
    if (isOpenDeleteModal) {
      dispatch(deleteInvite(isOpenDeleteModal.id));
      setSelectedRow(null);
    }
    setIsOpenDeleteModal(null);
  }

  function sendInvite() {
    onOpenInviteModal();
    setModalType(USER_MODAL_TYPES.INVITE);
  }

  const resendInviteCall = useCallback(
    (row: Auth) => {
      onOpenInviteModal();
      setSelectedRow(row);
      setModalType(USER_MODAL_TYPES.RESEND);
    },
    [onOpenInviteModal]
  );

  const editUser = useCallback(
    (row: Auth) => {
      onOpenInviteModal();
      setModalType(USER_MODAL_TYPES.EDIT);
      setSelectedRow(row);
    },
    [onOpenInviteModal]
  );

  const onDeleteInviteClick = useCallback((row: Auth) => {
    setSelectedRow(row);
    setIsOpenDeleteModal(row);
  }, []);

  const deactivateUser = useCallback(
    (row: Auth) => {
      onOpenDeactivateModal();
      setSelectedRow(row);
    },
    [onOpenDeactivateModal]
  );

  const columnHelper = createColumnHelper<Auth>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Full name",
        cell: (info) => <UserNameWithAvatar username={info.getValue()} />,
      }),
      columnHelper.accessor("email", {
        header: "Email",
      }),
      columnHelper.accessor("roles", {
        header: "Role",
        cell: (info) => {
          if (info.getValue().length > 0) {
            const role = Object.entries(ROLE_DISPLAY_VALUE).find(
              ([_, value]) => value === info.getValue()[0].role_name
            );
            if (role) {
              return role[0];
            }
          }
          return "";
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <Badge
            fontSize="xs"
            minWidth="70"
            textAlign="center"
            colorScheme={USER_STATUS_COLORS[info.getValue()]}
            variant="solid"
          >
            {info.getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor("is_email_verified", {
        header: "",
        cell: (info) => (
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Options"
              variant="ghost"
              name="options"
              icon={<FaEllipsisV />}
              isDisabled={
                !isAdmin || deletingInvite || resendingInvite || updatingUser
              }
            ></MenuButton>
            <MenuList rootProps={{ style: { right: 0 } }}>
              <MenuItem onClick={() => editUser(info.row.original)} name="edit">
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => deactivateUser(info.row.original)}
                name="deactivate"
                hidden={
                  info.row.original.status !== USER_ACCOUNT_STATUS.ACTIVE ||
                  user.id === info.row.original.id ||
                  !isAdmin
                }
              >
                Deactivate
              </MenuItem>
              <MenuItem
                onClick={() => resendInviteCall(info.row.original)}
                name="resend-invite"
                hidden={info.row.original.is_email_verified}
              >
                Resend invite
              </MenuItem>
              <MenuItem
                onClick={() => onDeleteInviteClick(info.row.original)}
                name="delete-invite"
                hidden={info.row.original.is_email_verified}
              >
                Delete invite
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      }),
    ],
    [
      columnHelper,
      deactivateUser,
      deletingInvite,
      editUser,
      isAdmin,
      resendInviteCall,
      onDeleteInviteClick,
      resendingInvite,
      updatingUser,
      user.id,
    ]
  );

  return (
    <ContentContainerWithHeader>
      <UserListHeader
        sendInvite={sendInvite}
        loading={invitingUser}
        showInvite={isAdmin}
      />
      <ContentContainer>
        <SubHeader
          title={
            <HStack fontSize="md">
              <Text>Users</Text>
              <Text>
                {userInviteList.count ? `(${userInviteList.count})` : ""}
              </Text>
            </HStack>
          }
          desc=""
          stackProps={{
            width: "100%",
            height: "calc(100vh - 130px)",
          }}
        >
          <DataTable
            fetchingList={userInviteList.fetchingList}
            changingPage={userInviteList.changingPage}
            list={userInviteList.list}
            totalPageCount={userInviteList.totalPageCount}
            currentPage={userInviteList.currentPageNo}
            totalPageSize={userInviteList.pageSize}
            setPage={(pageNo) => dispatch(setUserInviteListPage(pageNo))}
            columns={columns}
            emptyMsg="No user found"
          />
        </SubHeader>
      </ContentContainer>
      <UserManagementModal
        isOpen={isOpenInviteModal}
        onClose={onCloseInviteModal}
        data={selectedRow}
        type={modalType}
      />
      <DeleteConfirmationModal
        isOpen={!!isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(null)}
        submitHandler={deleteInviteCall}
        isLoading={deletingInvite}
        customHeader={`Delete invite`}
        deleteItemType={DELETION_MODAL_TYPES_INFO.invite.display}
      />

      <DeactivateUserModal
        onClose={onCloseDeactivateModal}
        isOpen={isOpenDeactivateModal}
        data={selectedRow}
      />
    </ContentContainerWithHeader>
  );
}
