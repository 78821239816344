import { Box, Heading, Stack } from "@chakra-ui/react";
import { Logo } from "../../../components/Logo";
import { Form, Formik } from "formik";
import { emailValidation } from "../../../common/helper/validationHelper";
import { object } from "yup";
import IButton from "../../../components/IButton";
import InputFieldWithError from "../../../components/InputFieldWithError";

type Link = {
  onClick: () => void;
  label: string;
};

export default function EmailOnlyPage({
  heading,
  loading,
  buttonText,
  hyperlinks,
  submitFn,
}: {
  heading: string;
  loading: boolean;
  buttonText: string;
  hyperlinks: Link[];
  submitFn: (values: { email: string }) => void;
}) {
  const emailSchema = object().shape({
    email: emailValidation,
  });

  return (
    <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Logo mx="auto" h="8" mb={{ base: "10", md: "20" }} />
        <Heading textAlign="center" size="lg" fontWeight="extrabold" mb="16">
          {heading}
        </Heading>
        <Box
          bg="white"
          pt="10"
          pb="12"
          px={{ base: "4", md: "10" }}
          shadow="base"
          w="sm"
          rounded={{ sm: "lg" }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{ email: "" }}
            onSubmit={submitFn}
            validationSchema={emailSchema}
          >
            {(formik) => (
              <Form>
                <Stack spacing="4">
                  <InputFieldWithError
                    labelText="Email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    placeholder="Enter your email"
                    onChange={formik.handleChange}
                    autoFocus
                    errorMsg={
                      !!formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : undefined
                    }
                  />
                  <IButton
                    type="submit"
                    isLoading={loading}
                    isDisabled={loading}
                    colorScheme="blue"
                    size="lg"
                    name="submit-button"
                    fontSize="md"
                  >
                    {buttonText}
                  </IButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
        {hyperlinks.map((item, index) => (
          <IButton
            onClick={item.onClick}
            fontSize="sm"
            mt="3"
            colorScheme="blue"
            variant="link"
            key={index}
            data-cy={`redirection-link-${index + 1}`}
            name="redirect-button"
          >
            {item.label}
          </IButton>
        ))}
      </Box>
    </Box>
  );
}
