import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  BoxProps,
  Flex,
  HStack,
  StackProps,
  Text,
  useClipboard,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaCheckCircle, FaCopy } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { CODEMIRROR_SIZE } from "../../../../common/constants/common";
import { CODE_BOX_VARIANTS } from "../../../../common/constants/settings";
import CodemirrorInput from "../../../../components/codemirror/CodemirrorInput";
import IButton, { BUTTON } from "../../../../components/IButton";
import ITitle from "../../../../components/ITitle";
import SendToDevCta from "./SendToDevCta";

const TRACKING_CODE = `<!-- Start of Inflection Embed Code -->
<script async src="https://wt.inflection.io/scripts/wt-script.js"></script>
<!-- End of Inflection Embed Code -->`;

const BUTTON_STYLE = {
  variant: BUTTON.PRIMARY,
  size: "xs",
  background: "grayV2.100",
  color: "brandBlue.500",
  customContent: true,
  _hover: {
    bg: "grayV2.300",
  },
};

function CodeBox({ additionalContent }: { additionalContent?: ReactNode }) {
  return (
    <CodemirrorInput
      type={CODEMIRROR_SIZE.READ_ONLY_CODE_EDITOR}
      value={TRACKING_CODE}
      onChange={() => {}}
      isDisabled={true}
      additionalContent={additionalContent}
    />
  );
}

function CtaButtons({
  onOpenSendToDevCta,
  onCopy,
  hasCopied,
  toggle = true,
  ...props
}: {
  onOpenSendToDevCta: () => void;
  onCopy: () => void;
  hasCopied: boolean;
  toggle?: boolean;
} & BoxProps) {
  const copiedText = hasCopied ? "Copied!" : "Copy code";
  const copyIcon = hasCopied ? <FaCheckCircle /> : <FaCopy />;

  return (
    <HStack spacing={2} justifyContent="flex-end" {...props}>
      <Tooltip label="Send code" hidden={toggle}>
        <IButton
          name="send-to-dev"
          onClick={onOpenSendToDevCta}
          leftIcon={toggle ? <IoMdSend /> : undefined}
          children={toggle ? "Send code" : <IoMdSend />}
          {...BUTTON_STYLE}
        />
      </Tooltip>
      <Tooltip label="Copy code" hidden={toggle}>
        <IButton
          name="copy-clipboard"
          onClick={onCopy}
          leftIcon={toggle ? copyIcon : undefined}
          children={toggle ? copiedText : copyIcon}
          {...BUTTON_STYLE}
        />
      </Tooltip>
    </HStack>
  );
}

function AccordionCodeBox({
  onOpenSendToDevCta,
  hasCopied,
  onCopy,
}: {
  onOpenSendToDevCta: () => void;
  hasCopied: boolean;
  onCopy: () => void;
}) {
  return (
    <Accordion allowToggle index={undefined} bg="grayV2.200" borderRadius="md">
      <AccordionItem borderRadius="md">
        <Flex
          justifyContent="space-between"
          w="100%"
          bg="grayV2.200"
          borderRadius="md"
          h="35px"
          pr={3}
        >
          <AccordionButton
            _hover={{
              bg: "grayV2.200",
            }}
            borderRadius="md"
          >
            <AccordionIcon mr={1} />
            <ITitle title="View code" fontWeight={600} fontSize="14px" />
          </AccordionButton>
          <CtaButtons
            onOpenSendToDevCta={onOpenSendToDevCta}
            onCopy={onCopy}
            hasCopied={hasCopied}
          />
        </Flex>
        <AccordionPanel p={0}>
          <CodeBox />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function CodeBoxBasedOnVariant({
  variant,
  onCopy,
  onOpenSendToDevCta,
  hasCopied,
}: {
  variant: CODE_BOX_VARIANTS;
  onCopy: () => void;
  onOpenSendToDevCta: () => void;
  hasCopied: boolean;
}) {
  switch (variant) {
    case CODE_BOX_VARIANTS.TOGGLE:
      return (
        <AccordionCodeBox
          onCopy={onCopy}
          onOpenSendToDevCta={onOpenSendToDevCta}
          hasCopied={hasCopied}
        />
      );
    case CODE_BOX_VARIANTS.DEFAULT:
      return (
        <CodeBox
          additionalContent={
            <CtaButtons
              onOpenSendToDevCta={onOpenSendToDevCta}
              onCopy={onCopy}
              hasCopied={hasCopied}
              toggle={false}
              position="absolute"
              top="5px"
              right="5px"
            />
          }
        />
      );
  }
}

export default function InstallTrackingCode({
  variant = CODE_BOX_VARIANTS.DEFAULT,
  ...props
}: { variant?: CODE_BOX_VARIANTS } & StackProps) {
  const { onCopy, hasCopied } = useClipboard(TRACKING_CODE, 5000);

  const {
    onOpen: onOpenSendToDevCta,
    onClose: onCloseSendToDevCta,
    isOpen: isOpenSendToDevCta,
  } = useDisclosure();

  return (
    <>
      <Flex justifyContent="space-between" mr={5} mt={1} {...props}>
        <ITitle title="Tracking code" fontWeight={600} fontSize="16px" />
      </Flex>
      <Text fontSize="14px" my={2} color="text.50">
        Copy and paste this tracking code into each page you wish to track, just
        before the &lt;/head&gt; tag.
      </Text>

      <CodeBoxBasedOnVariant
        variant={variant}
        onCopy={onCopy}
        onOpenSendToDevCta={onOpenSendToDevCta}
        hasCopied={hasCopied}
      />
      <SendToDevCta isOpen={isOpenSendToDevCta} onClose={onCloseSendToDevCta} />
    </>
  );
}
