import Icon from "@chakra-ui/icon";
import { Box, Heading, HStack, Link, Text, VStack } from "@chakra-ui/layout";
import { useCallback } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { openSalesforceInstance } from "../../../../common/helper/commonHelper";

export default function AccountSalesforceDetails({
  accountName,
  accountId,
  instanceUrl,
}: {
  accountName: string;
  accountId: string | null;
  instanceUrl: string;
}) {
  const redirectSalesforceWindow = useCallback(() => {
    if (instanceUrl && accountId) {
      openSalesforceInstance({
        url: instanceUrl,
        crmObject: "users",
        salesforceId: accountId,
      });
    }
  }, [accountId, instanceUrl]);

  return (
    <VStack alignItems="flex-start" fontSize="14px">
      <Heading as="h1" pb="2" fontSize="15px">
        Salesforce Sync
      </Heading>
      <HStack>
        <Text>The account {accountName} is currently linked to </Text>
        <Link onClick={() => redirectSalesforceWindow()} color="blue.600">
          {accountName}
          <Icon fontSize="14px" pt="2px" pl="2px">
            <FaExternalLinkAlt />
          </Icon>
        </Link>
      </HStack>
      <Box pt="2" />
      <Text fontSize={15} color="gray.500">
        Salesforce Account ID
      </Text>
      <Text>{accountId}</Text>
    </VStack>
  );
}
