import { Box, Divider, Flex, Grid, Text, VStack } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import {
  AccountMappingElement,
  ACCOUNT_TYPE_ID,
  ConnectionListItem,
  SUB_TYPES,
} from "../../../../../../../../../../common/types/unifiedMapping";
import { MappingTableColumnTitle } from "../../../../../../../../../../components/unified-mapping/MappingTableColumnTitle";
import AccountIdColumnRows from "./components/AccountIdColumnRows";
import AccountMappingColumnRow from "./components/AccountMappingColumnRow";
import ITitle from "../../../../../../../../../../components/ITitle";
import {
  findSource,
  getObjectSettingsSalesforce,
} from "../../../../../../../../../../common/helper/unifiedMappingHelper";
import { SalesforceConnectionsSummary } from "../../../../../../../../../../common/types/salesforce";

function TableTitle({
  title,
  capitalize,
}: {
  title: string;
  capitalize?: boolean;
}) {
  return (
    <ITitle
      fontWeight="md"
      fontSize="sm"
      color="text.100"
      title={title}
      capitalize={capitalize}
      px="2"
    />
  );
}

function ColumnHeaders({
  accountSources,
  data,
  salesforceConnections,
}: {
  accountSources: ConnectionListItem[];
  data: AccountMappingElement[];
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  const sourceField: ConnectionListItem | null = useMemo(
    () => findSource(data[0]?.source?.[0] || null, accountSources),
    [data, accountSources]
  );

  const objectSettings = useMemo(
    () => getObjectSettingsSalesforce(salesforceConnections, sourceField),
    [salesforceConnections, sourceField]
  );

  return (
    <Flex
      width="100%"
      alignItems="center"
      bg="oneOffs.tableHeader"
      roundedTop="md"
      sx={{ top: "0", margin: "0", zIndex: "1", position: "sticky" }}
    >
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={12} p="3">
        <TableTitle title="Inflection Property" />
        {accountSources.length !== 0 && (
          <>
            <TableTitle title="Data Source" />

            {objectSettings && objectSettings.account ? (
              <TableTitle title="Source Property" />
            ) : (
              <Text></Text>
            )}

            <TableTitle title="CRM Sync Setting" capitalize={false} />
          </>
        )}
      </Grid>
      <Box w="32px"></Box>
    </Flex>
  );
}

function sourceColumnRows(
  rows: AccountMappingElement[],
  query: string,
  removeRow: (name: string) => void,
  accountSources: ConnectionListItem[],
  onChange: (data: AccountMappingElement[]) => void,
  readonly: boolean,
  salesforceConnections?: SalesforceConnectionsSummary | null
) {
  function onChangeRow(updatedData: AccountMappingElement) {
    let clonedRows = cloneDeep(rows);
    clonedRows = clonedRows.map((row) => {
      if (
        row.destination.name === ACCOUNT_TYPE_ID &&
        updatedData.destination.name !== ACCOUNT_TYPE_ID
      ) {
        // when a non email row is updated
        row = { ...updatedIdRows(row, updatedData) };
      }

      if (row.tempId === updatedData.tempId) {
        row = { ...updatedData };
      }
      return row;
    });
    onChange(clonedRows);
  }

  const mappedAccountFields: { [con: string]: string[] } = {};

  rows.forEach((x) => {
    if (x.source?.[0]?.field) {
      const accountSourceName: string | null =
        x.source?.[0].field?.name ?? null;
      if (accountSourceName) {
        const connectionId = x.source?.[0].connection_id;
        if (mappedAccountFields[connectionId]) {
          mappedAccountFields[connectionId].push(accountSourceName);
        } else {
          mappedAccountFields[connectionId] = [accountSourceName];
        }
      }
    }
  });

  return (
    rows
      .filter((x) => x.destination.name.toLocaleLowerCase().includes(query))
      // Bring email row at the top
      .sort((x, y) => (x.destination.name === SUB_TYPES.ID ? -1 : 0))
      .map((row) => {
        const props = {
          remove: () => removeRow(row.destination.name),
        };
        if (accountSources.length) {
          if (row.destination.name === SUB_TYPES.ID) {
            return (
              <AccountIdColumnRows
                rows={mappedAccountFields}
                readonly={readonly}
                key={row.tempId}
                data={row}
                onChange={(row) => onChangeRow(row)}
                sources={accountSources}
                salesforceConnections={salesforceConnections}
              />
            );
          } else {
            return (
              <AccountMappingColumnRow
                rows={mappedAccountFields}
                key={row.tempId}
                data={row}
                onChange={(row) => onChangeRow(row)}
                sources={accountSources}
                selectedSource={row.source?.[0] || null}
                syncPreferences={row.source?.[0]?.sync_preference || null}
                account={row.source?.[0]?.field || null}
                readonly={readonly}
                salesforceConnections={salesforceConnections}
                {...props}
              />
            );
          }
        } else {
          return (
            <MappingTableColumnTitle
              type={
                row.destination.sub_type === SUB_TYPES.ID
                  ? SUB_TYPES.ID
                  : row.destination.type
              }
              name={row.destination.display}
              removable={row.destination.custom}
            />
          );
        }
      })
  );
}

function updatedIdRows(
  row: AccountMappingElement,
  updatedData: AccountMappingElement
) {
  // add newly added source in email field mapping

  const clonedRow = cloneDeep(row);
  const sourceIds = clonedRow.source?.map((x) => x.connection_id);

  let newSources = updatedData?.source ? [...updatedData.source] : [];
  if (sourceIds) {
    newSources =
      updatedData.source?.filter(
        (x) => !sourceIds?.includes(x.connection_id)
      ) ?? [];
  }

  const existingSources = clonedRow.source ? [...clonedRow.source] : [];

  newSources.forEach((newSource) => {
    existingSources.push({
      ...newSource,
      ...{
        sync_preference: null,
        field: null,
      },
    });
  });

  clonedRow.source = [...existingSources];

  return clonedRow;
}

export default function AccountMappingTable({
  accountSources,
  data,
  onChange,
  query,
  removeRow,
  readonly,
  salesforceConnections,
}: {
  accountSources: ConnectionListItem[];
  data: AccountMappingElement[];
  onChange: (data: AccountMappingElement[]) => void;
  query: string;
  removeRow: (name: string) => void;
  readonly: boolean;
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  return (
    <Box bg="white" w="100%" rounded="md">
      <ColumnHeaders
        accountSources={accountSources}
        data={data}
        salesforceConnections={salesforceConnections}
      />
      <VStack
        width="100%"
        divider={<Divider />}
        maxH="calc(100vh - 100px)"
        overflow="auto"
        spacing={0}
      >
        {sourceColumnRows(
          data,
          query,
          removeRow,
          accountSources,
          onChange,
          readonly,
          salesforceConnections
        )}
      </VStack>
    </Box>
  );
}
