import { toast } from "react-toastify";
import { LOADING_STATES } from "../../../../../../../common/constants/common";
import {
  isFulfilled,
  isLoading,
} from "../../../../../../../common/helper/commonHelper";
import { AccountContactAutoMappingElement } from "../../../../../../../common/types/AccountContactMapping";
import { PersonDestination } from "../../../../../../../common/types/person";
import { useAppDispatch } from "../../../../../../../store";
import { updateAccountContactAutoMapping } from "../../../../connectionSlice";
import ActionBasedButtons, {
  ACTIONS,
} from "../../../../../../../components/ActionBasedButtons";

export default function SaveAndCancelBar({
  readonlyMode,
  setIsReadonly,
  isEnabled,
  accountField,
  contactField,
  delimiterValue,
  updatingMapping,
  isHidden,
}: {
  readonlyMode: boolean;
  setIsReadonly: (readonlyMode: boolean) => void;
  isEnabled: boolean;
  accountField: PersonDestination;
  contactField: PersonDestination;
  delimiterValue: { label: string; value: string | null };
  updatingMapping: LOADING_STATES;
  isHidden: boolean;
}) {
  const dispatch = useAppDispatch();

  function handleCancel() {
    setIsReadonly(true);
  }

  function validateValues(
    accountField: PersonDestination,
    contactField: PersonDestination,
    delimiterValue: { label: string; value: string | null }
  ) {
    if (accountField && contactField && delimiterValue) {
      return !(
        accountField?.name === "" ||
        contactField.name === "" ||
        delimiterValue.value === ""
      );
    } else {
      return false;
    }
  }

  async function handleSave() {
    const isValid = validateValues(accountField, contactField, delimiterValue);
    if (isValid) {
      const data: AccountContactAutoMappingElement = {
        is_enabled: isEnabled,
        account_field: accountField?.name,
        person_field: contactField?.name,
        delimiter:
          delimiterValue.value === "none" ? null : delimiterValue.value,
      };
      const updateResp = await dispatch(updateAccountContactAutoMapping(data));
      if (isFulfilled(updateResp.meta.requestStatus)) {
        setIsReadonly(true);
      }
    } else {
      toast.warning("Incomplete fields please fill all the fields");
    }
  }

  function handleEdit() {
    setIsReadonly(false);
  }

  return (
    <ActionBasedButtons
      action={readonlyMode ? ACTIONS.VIEW : ACTIONS.EDIT}
      editButtonProps={{ hidden: isHidden, onClick: handleEdit }}
      cancelButtonProps={{
        onClick: handleCancel,
        isDisabled: isLoading(updatingMapping),
        hidden: isHidden,
      }}
      saveButtonProps={{
        onClick: handleSave,
        isLoading: isLoading(updatingMapping),
        hidden: isHidden,
      }}
    />
  );
}
