import { useNodes, BezierEdge, EdgeProps } from "reactflow";
import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
  svgDrawSmoothLinePath,
} from "@tisoap/react-flow-smart-edge";
import { EdgeDataProps } from "../../../../../common/types/flow";

export default function SmoothSmartEdge(props: EdgeProps<EdgeDataProps>) {
  const {
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd,
    data,
  } = props;

  const nodes = useNodes();

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes: nodes.filter((node) => node.parentNode === data?.groupId),
    options: {
      nodePadding: 30,
      gridRatio: 10,
      drawEdge: svgDrawSmoothLinePath,
      generatePath: pathfindingJumpPointNoDiagonal,
    },
  });

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <BezierEdge {...props} />;
  }

  const { svgPathString } = getSmartEdgeResponse;

  return (
    <path
      className="react-flow__edge-path"
      d={svgPathString}
      markerEnd={markerEnd}
      markerStart={markerStart}
      style={style}
    />
  );
}
