import {
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../../urls";
import {
  getPersonLegacy,
  getPerson,
  getProductPerson,
  listPersonActivity,
  resetMarketingActivityPage,
  resetPersonDetailsPage,
  selectPerson,
  updatePersonLegacy,
  updatePerson,
  getPersonMappingDetails,
  setActivityPage,
  listPersonMarketingActivity,
  getPersonTransactionIdStatus,
} from "./personDbSlice";
import SpinnerContainer from "../../../components/SpinnerContainer";
import { PreventNavigationModal } from "../../../components/PreventNavigationModal";
import SideBar from "../../../components/SideBar";
import PersonActivities from "./components/PersonActivities";
import {
  PersonUpdateFields,
  PersonDestinationFields,
} from "../../../common/types/person";
import { toast } from "react-toastify";
import {
  isLoading,
  isFinished,
  isFulfilled,
  isIngestionTypeFlink,
  handleTransactionStatus,
} from "../../../common/helper/commonHelper";
import PersonMarketingActivities from "./components/PersonMarketingActivities";
import IButton, { BUTTON } from "../../../components/IButton";
import { cloneDeep, debounce, get } from "lodash";
import { useAppDispatch } from "../../../store";
import { TableList } from "../../../common/types/campaign";
import PersonInfo from "./components/PersonInfo";
import ProductPerson from "./components/ProductPerson";
import PersonSalesforceSyncTab from "./components/PersonSalesforceSyncTab";
import { SearchField } from "../../../components/SearchField";
import SecondarySidebar from "../../../components/v2/SecondarySidebar";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import SidebarBackButton from "../../../components/v2/SidebarBackButton";
import {
  getStartDate,
  CUSTOM_DATE_RANGE_LABELS,
} from "../../../common/constants/common";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import { formatISO } from "date-fns";
import { updatePersonLegacyApi } from "../../../common/api/campaign/person";
import {
  updatePersonApi,
  getPersonTransactionIdStatusApi,
} from "../../../common/api/core-integrations/contact";
import {
  selectDynamicList,
  getTableDescription,
} from "../../../components/dynamic-list/dynamicListSlice";
import {
  usePreviousLocation,
  usePreventRefresh,
  useExponentialPolling,
} from "../../../common/hooks/commonHooks";
import DeletePersonModal from "./components/DeletePersonModal";
import { resetSalesforceEntireState } from "../connection/salesforce/salesforceSlice";
import { selectFeatureFlag } from "../../../common/slices/featureFlagSlice";

const SIDEBAR_ITEMS = [
  "Contact Fields",
  "Product User",
  "Marketing Activity",
  "Product Activity",
  "Salesforce",
];

function ActionsMenu({
  onEdit,
  onDelete,
}: {
  onEdit: () => void;
  onDelete: () => void;
}) {
  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        size="sm"
        as={Button}
        name="actions"
        rightIcon={<FaChevronDown />}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onEdit} name="edit-contact">
          Edit
        </MenuItem>
        <MenuItem onClick={onDelete} name="delete-contact">
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function SaveCancel({
  onSave,
  onCancel,
  onLoadingSaveAndCancel,
}: {
  onSave: () => void;
  onCancel: () => void;
  onLoadingSaveAndCancel: boolean;
}) {
  return (
    <>
      <IButton
        variant={BUTTON.SECONDARY}
        onClick={onCancel}
        name="cancel"
        isDisabled={onLoadingSaveAndCancel}
      >
        Cancel
      </IButton>
      <IButton
        variant={BUTTON.PRIMARY}
        onClick={onSave}
        isLoading={onLoadingSaveAndCancel}
        name="save"
      >
        Save
      </IButton>
    </>
  );
}

enum LOADING_TYPES {
  SAVE = "save",
  DELETE = "delete",
}

const ButtonProps = {
  [LOADING_TYPES.SAVE]: {
    variant: BUTTON.PRIMARY,
    loadingText: "Saving...",
  },
  [LOADING_TYPES.DELETE]: {
    variant: "solid",
    colorScheme: "red",
    loadingText: "Deleting...",
  },
};

function LoadingButton({ type }: { type: LOADING_TYPES }) {
  return (
    <IButton name="loading-buttom" isLoading {...ButtonProps[type]}>
      Cancel
    </IButton>
  );
}

const FILTER_COLUMNS = ["event_name"];

export default function PersonDashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    personDetails: { data: personDetails, loading: fetchingPersonDetails },
    personMarketingActivity,
    personActivity,
    personMappingDetails: {
      data: personMappingDetails,
      loading: fetchingPersonMappingDetails,
    },
  } = useSelector(selectPerson);
  const {
    contactIngestionEtl: {
      data: contactIngestionType,
      loading: fetchingContactIngestionType,
    },
  } = useSelector(selectFeatureFlag);

  const [isUpdating, setIsUpdating] = useState(false);
  const [polling, setPolling] = useState(false);

  usePreventRefresh({ preventRefresh: isUpdating });

  const { tableList } = useSelector(selectDynamicList);

  const [details, setDetails] = useState(personMappingDetails);
  const [backup, setBackup] = useState(personMappingDetails);
  const [enableEdit, setEnableEdit] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [[startDate, endDate], setDateRange] = useState([
    getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_WEEK),
    new Date(),
  ]);

  let { id } = useParams<{ id: string }>();

  const goToPersonList = useCallback(() => {
    navigate(urls.person);
  }, [navigate]);

  useEffect(() => {
    dispatch(getPersonMappingDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTableDescription());
  }, [dispatch]);

  const getPersonDetails = useCallback(() => {
    if (id) {
      if (isIngestionTypeFlink(contactIngestionType)) {
        dispatch(getPerson(id));
      } else {
        dispatch(getPersonLegacy(id));
      }
    }
  }, [dispatch, contactIngestionType, id]);

  useEffect(() => {
    if (
      isFinished(fetchingPersonMappingDetails) &&
      isFinished(fetchingContactIngestionType)
    ) {
      if (!personMappingDetails) {
        toast.error("Something went wrong");
        goToPersonList();
      } else {
        if (id) {
          dispatch(getProductPerson(id));
        }
        getPersonDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    dispatch,
    goToPersonList,
    personMappingDetails,
    fetchingPersonMappingDetails,
    fetchingContactIngestionType,
    getPersonDetails,
  ]);

  useEffect(() => {
    if (id)
      dispatch(
        listPersonActivity({
          personId: id,
          searchKeyword,
          columnsToSearchIn: FILTER_COLUMNS,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, personActivity.currentPageNo]);

  useEffect(() => {
    return () => {
      dispatch(resetMarketingActivityPage());
      dispatch(resetPersonDetailsPage());
    };
  }, [dispatch]);

  useEffect(() => {
    let detailsPerson: PersonDestinationFields = { ...personMappingDetails };
    if (personDetails && personMappingDetails) {
      Object.entries(personDetails).forEach(([key, details]) => {
        if (key in detailsPerson) {
          detailsPerson[key] = {
            ...detailsPerson[key],
            value: details,
          };
        }
      });
      setDetails(detailsPerson);
      setBackup(detailsPerson);
    }
  }, [personDetails, personMappingDetails]);

  useEffect(() => {
    if (id) {
      dispatch(
        listPersonMarketingActivity({
          personId: id,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
        })
      );
    }
  }, [dispatch, endDate, id, startDate, personMarketingActivity.currentPageNo]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceEntireState());
    };
  }, [dispatch]);

  function onDateChange(startDate: Date, endDate: Date) {
    setDateRange([startDate, endDate]);
  }

  async function onSave() {
    let data: PersonUpdateFields = {
      default_fields: {},
      custom_fields: {},
      email: "",
    };
    let flinkData: { [key: string]: any } = {};
    Object.keys(details).forEach((key) => {
      if (
        details[key].updatable &&
        !details[key].hidden &&
        details[key].value !== backup[key].value
      ) {
        if (details[key].custom) {
          data.custom_fields[key] = details[key].value;
        } else {
          data.default_fields[key] = details[key].value;
        }
        flinkData[key] = details[key].value;
      }
    });
    data.email = details.email?.value ?? "";
    setIsUpdating(true);

    if (isIngestionTypeFlink(contactIngestionType)) {
      resetEditData();
      const updatedPersonResponse = await dispatch(
        updatePerson({ id: id!, data: flinkData })
      );
      const successResponse = updatedPersonResponse.payload as Awaited<
        ReturnType<typeof updatePersonApi>
      >;
      handleTransactionStatus(successResponse?.data?.status)
        .onDone(() => {
          setIsUpdating(false);
        })
        .onPending(() => {
          setPolling(true);
        })
        .onDefault(() => {
          setIsUpdating(false);
        });
    } else {
      const updatedPersonResponse = await dispatch(updatePersonLegacy(data));
      if (isFulfilled(updatedPersonResponse.meta.requestStatus)) {
        const { status } = updatedPersonResponse.payload as Awaited<
          ReturnType<typeof updatePersonLegacyApi>
        >;
        if (status && id) {
          dispatch(getPersonLegacy(id));
          resetEditData();
        }
      }
      setIsUpdating(false);
    }
  }

  const pollContactCreateStatus = useCallback(async () => {
    const {
      meta: { requestStatus },
      payload,
    } = await dispatch(getPersonTransactionIdStatus());

    function handleSaveFailure() {
      setIsUpdating(false);
      setPolling(false);
      toast.error("Contact save failed");
    }

    if (!isFulfilled(requestStatus)) {
      handleSaveFailure();
      return;
    }

    const { data, errors } = payload as Awaited<
      ReturnType<typeof getPersonTransactionIdStatusApi>
    >;

    if (!!errors) {
      handleSaveFailure();
      return;
    }

    handleTransactionStatus(data?.status)
      .onError(() => {
        handleSaveFailure();
        return;
      })
      .onDone(() => {
        setIsUpdating(false);
        setPolling(false);
        getPersonDetails();
        toast.success("Contact saved successfully");
      });
  }, [dispatch, getPersonDetails]);

  useExponentialPolling({
    onPolling: pollContactCreateStatus,
    shouldPoll: polling,
    initialWaitTime: 1,
  });

  function resetEditData() {
    setDetails(backup);
    setEnableEdit(false);
  }

  const editDetails = useCallback(
    (key: string, value: any) => {
      let newDetails = cloneDeep(details);
      newDetails[key].value = value;
      setDetails(newDetails);
    },
    [details]
  );

  const searchIfValid = useCallback(
    (keyword: string) => {
      if (personActivity.currentPageNo !== 1) {
        dispatch(setActivityPage(1));
      } else {
        if (id)
          dispatch(
            listPersonActivity({
              personId: id,
              searchKeyword: keyword,
              columnsToSearchIn: FILTER_COLUMNS,
            })
          );
      }
    },
    [dispatch, id, personActivity.currentPageNo]
  );

  const debounceSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function PersonDetailsTabButton() {
    if (isUpdating) {
      return <LoadingButton type={LOADING_TYPES.SAVE} />;
    } else if (enableEdit) {
      return (
        <SaveCancel
          onSave={onSave}
          onCancel={resetEditData}
          onLoadingSaveAndCancel={isUpdating}
        />
      );
    } else {
      return (
        <ActionsMenu
          onEdit={() => setEnableEdit(true)}
          onDelete={() => setDeleteModalOpen(true)}
        />
      );
    }
  }

  function currentTabMenu(tab: number) {
    //displays side menu based on the current tab, add more cases if needed

    switch (tab) {
      case 0:
        // action, save and cancel menu for person_details tab
        return PersonDetailsTabButton();
      case 2:
        return (
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={onDateChange}
          />
        );
      case 3:
        return (
          <SearchField
            name="search-product-activities"
            placeholder="Search product events"
            value={searchKeyword}
            onSearch={(keyword) => {
              setSearchKeyword(keyword);
              debounceSearch(keyword);
            }}
            width="260px"
          />
        );
    }
  }

  const tabs = useMemo(() => {
    return {
      [SIDEBAR_ITEMS[0]]: (
        <ContentContainer flexDir="column">
          <PersonInfo
            details={details}
            editDetails={editDetails}
            enableEdit={enableEdit}
          />
        </ContentContainer>
      ),
      [SIDEBAR_ITEMS[1]]: (
        <ProductPerson allTableList={tableList.data as TableList} />
      ),
      [SIDEBAR_ITEMS[2]]: <PersonMarketingActivities personId={id ? id : ""} />,
      [SIDEBAR_ITEMS[3]]: <PersonActivities />,
      [SIDEBAR_ITEMS[4]]: (
        <PersonSalesforceSyncTab
          internalPersonEmail={details?.email?.value ?? ""}
        />
      ),
    };
  }, [details, editDetails, enableEdit, id, tableList.data]);

  const { handleGoBackWithParams } = usePreviousLocation();

  return (
    <>
      <Flex>
        <SecondarySidebar
          heading={personDetails?.email ?? ""}
          backButton={
            <SidebarBackButton
              onClick={() => handleGoBackWithParams(urls.person)}
              text="Back to contact list"
            />
          }
        >
          <SideBar
            options={SIDEBAR_ITEMS}
            selected={selectedOption}
            select={setSelectedOption}
          />
        </SecondarySidebar>

        <ContentContainerWithHeader>
          <HStack minH="45px" maxH="45px" flex={1} justifyContent="flex-end">
            {currentTabMenu(selectedOption)}
          </HStack>
          <SpinnerContainer
            loading={
              isLoading(fetchingPersonDetails) ||
              isLoading(fetchingPersonMappingDetails) ||
              isLoading(fetchingContactIngestionType)
            }
            height="80vh"
          >
            {get(tabs, SIDEBAR_ITEMS[selectedOption])}
          </SpinnerContainer>
        </ContentContainerWithHeader>
        <DeletePersonModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          personId={id ?? ""}
          email={details?.email?.value ?? ""}
        />
      </Flex>
      <PreventNavigationModal
        isActive={isUpdating}
        cancelText="Cancel"
        confirmText="Confirm"
        children={
          <Text>
            If you navigate to another tab, the changes made to the contact may
            fail to save.
          </Text>
        }
      />
    </>
  );
}
