import {
  HStack,
  Divider,
  Alert,
  AlertDescription,
  Icon,
  AlertProps,
  VStack,
  Box,
  Text,
  Heading,
  useBoolean,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IconType } from "react-icons";
import {
  FaExclamationCircle,
  FaPlayCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { EMPTY_CONTEXT } from "../../../common/constants/common";
import {
  isFulfilled,
  isLoading,
  isSuccess,
} from "../../../common/helper/commonHelper";
import { FORM_STATUS } from "../../../common/types/form";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import IButton, { BUTTON } from "../../../components/IButton";
import IFormLabel from "../../../components/IFormLabel";
import InputFieldWithError from "../../../components/InputFieldWithError";
import LabelValuePair from "../../../components/LabelValuePair";
import { useAppDispatch } from "../../../store";
import FormProviderWithLogo from "./components/FormProviderWithLogo";
import {
  selectForm,
  getSubmissionStatsForSingleForm,
  getFormErrors30days,
  updateFormDescription,
} from "./formSlice";

function SubmissionDetails() {
  const dispatch = useAppDispatch();

  const { formDetails, formSubmissionStat, formErrors30days } =
    useSelector(selectForm);

  useEffect(() => {
    if (formDetails.data?.form_id) {
      dispatch(
        getSubmissionStatsForSingleForm({
          source: formDetails.data.vendor,
          formId: formDetails.data.form_id,
        })
      );
      dispatch(
        getFormErrors30days({
          source: formDetails.data.vendor,
          formId: formDetails.data.form_id,
        })
      );
    }
  }, [formDetails.data, dispatch]);

  const stat = useMemo(() => {
    if (formSubmissionStat.data && formDetails.data?.form_id) {
      return {
        count:
          formSubmissionStat.data[formDetails.data.form_id]?.count.toString(),
        lastSyncTime:
          formSubmissionStat.data[formDetails.data?.form_id]?.last_sync_time,
      };
    } else {
      return {
        count: "",
        lastSyncTime: undefined,
      };
    }
  }, [formSubmissionStat.data, formDetails.data]);

  const { mappedCount, allFieldsCount } = useMemo(() => {
    if (isSuccess(formDetails.loading)) {
      return {
        allFieldsCount: formDetails.data.all_fields
          ? Object.keys(formDetails.data.all_fields).length
          : null,
        mappedCount: formDetails.data.mapped_fields
          ? Object.keys(formDetails.data.mapped_fields).length
          : null,
      };
    }

    return { allFieldsCount: null, mappedCount: null };
  }, [formDetails]);

  const items = [
    {
      label: "Number of submissions",
      value: stat.count,
      isLoading:
        isLoading(formDetails.loading) || isLoading(formSubmissionStat.loading),
    },
    {
      label: "Last submission",
      value: <FormatDate date={stat.lastSyncTime} showTime />,
      isLoading:
        isLoading(formDetails.loading) || isLoading(formSubmissionStat.loading),
    },
    {
      label: "Form Errors (Last 30 days)",
      value: formErrors30days?.data?.length ?? "NA",
      isLoading:
        isLoading(formDetails.loading) || isLoading(formSubmissionStat.loading),
    },
    {
      label: "Fields Mapped",
      value:
        allFieldsCount !== null
          ? `${mappedCount ?? 0} out of ${allFieldsCount ?? 0}`
          : "NA",
      isLoading:
        isLoading(formDetails.loading) || isLoading(formSubmissionStat.loading),
    },
  ];

  return (
    <HStack
      alignItems="flex-start"
      rounded="md"
      bgColor="white"
      w="100%"
      py={2}
      px={8}
      spacing={8}
    >
      {items.map((item, index) => {
        return (
          <>
            <LabelValuePair
              label={item.label}
              value={item.value}
              isLoadingData={item.isLoading}
            />
            {index !== items.length - 1 && <Divider orientation="vertical" />}
          </>
        );
      })}
    </HStack>
  );
}

function FormActiveBanner() {
  const { formDetails } = useSelector(selectForm);
  interface AlertType {
    icon: IconType;
    text: string;
    status: AlertProps["status"];
  }

  const alerts: { [key in FORM_STATUS]: AlertType } = {
    [FORM_STATUS.READY]: {
      icon: FaPlayCircle,
      text: "Form is active",
      status: "success",
    },
    [FORM_STATUS.DISCONNECTED]: {
      icon: FaExclamationCircle,
      text: "Form is inactive",
      status: "error",
    },
    [FORM_STATUS.MAPPING_PENDING]: {
      icon: FaTimesCircle,
      text: "Form is unmapped",
      status: "warning",
    },
    [FORM_STATUS.NEW_FIELDS_IDENTIFIED]: {
      icon: FaTimesCircle,
      text: "Form has unmapped fields",
      status: "warning",
    },
  };

  function AlertBanner({ data }: { data: AlertType }) {
    return (
      <Alert status={data.status} fontSize="14px" rounded="md">
        <Icon as={data.icon} mr="2" />
        <AlertDescription>{data.text}</AlertDescription>
      </Alert>
    );
  }

  return (
    formDetails.data.form_status && (
      <AlertBanner data={alerts[formDetails.data.form_status]} />
    )
  );
}

function FormDetailSection() {
  const { formDetails } = useSelector(selectForm);
  const [editMode, setEditMode] = useBoolean(false);
  const dispatch = useAppDispatch();

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (formDetails.data) {
      setDescription(formDetails.data.description ?? "");
    }
  }, [formDetails.data]);

  async function save() {
    const response = await dispatch(
      updateFormDescription({ formId: formDetails.data.form_id, description })
    );
    if (isFulfilled(response.meta.requestStatus)) {
      setEditMode.off();
    }
  }

  function cancel() {
    setDescription(formDetails.data.description);
    setEditMode.off();
  }

  return (
    <Box rounded="md" bg="white" w="100%" py={3} px={4}>
      <VStack alignItems="flex-start" spacing={4}>
        <HStack justifyContent="space-between" w="100%">
          <Heading as="h5" fontSize="sm" fontWeight={600} color="text.50">
            Form Details
          </Heading>

          {!editMode && (
            <IButton variant="link" onClick={setEditMode.on}>
              Edit
            </IButton>
          )}
        </HStack>
        <VStack alignItems="flex-start" spacing={0.5}>
          <IFormLabel m="0">Form Description</IFormLabel>
          {editMode ? (
            <InputFieldWithError
              textArea
              minW="360px"
              noOfLines={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          ) : (
            <Text fontSize="sm">
              {formDetails.data.description || EMPTY_CONTEXT}
            </Text>
          )}
        </VStack>

        <VStack alignItems="flex-start" spacing={0.5}>
          <IFormLabel m="0">Form Provider</IFormLabel>
          <FormProviderWithLogo vendor={formDetails.data.vendor} />
        </VStack>

        <VStack alignItems="flex-start" spacing={0.5}>
          <IFormLabel m="0">Creation Date</IFormLabel>
          <Text fontSize="sm">
            <FormatDate date={formDetails.data.created_at} showTime />
          </Text>
        </VStack>

        {editMode && (
          <HStack alignSelf="flex-end">
            <IButton
              variant={BUTTON.PRIMARY}
              onClick={save}
              isLoading={isLoading(formDetails.loading)}
            >
              Save
            </IButton>
            <IButton variant={BUTTON.SECONDARY} onClick={cancel}>
              Cancel
            </IButton>
          </HStack>
        )}
      </VStack>
    </Box>
  );
}

export default function FormDetails() {
  return (
    <VStack>
      <FormActiveBanner />
      <SubmissionDetails />
      <FormDetailSection />
    </VStack>
  );
}
