import { Flex, Heading, IconButton, Icon, HStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaSyncAlt } from "react-icons/fa";

export default function SubHeader({
  title,
  height,
  children,
  refreshAction,
}: {
  title: string;
  height?: string;
  children?: ReactNode;
  refreshAction?: () => void;
}) {
  return (
    <Flex
      justifyContent="space-between"
      fontSize="sm"
      alignItems="center"
      height={height}
    >
      <Flex alignItems="center">
        <Heading fontSize="md" fontWeight="bold">
          {title}
        </Heading>
        {refreshAction && (
          <IconButton
            variant="ghost"
            ml="2"
            size="xs"
            name="refresh-details"
            aria-label="Refresh Details"
            onClick={refreshAction}
            icon={<Icon fontSize="16px" as={FaSyncAlt} />}
          />
        )}
      </Flex>
      <HStack spacing={3}>{children}</HStack>
    </Flex>
  );
}
