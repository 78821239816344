import { ApiKeyData } from "../../types/auth";
import { AuthenticatedConstants } from "../../types/common";
import { ContactIngestionEtlType } from "../../types/featureFlag";
import { integrationsRequest as request } from "./api";

export async function getAuthedConstantsApi(): Promise<AuthenticatedConstants> {
  return await request({
    url: "/generic/constants.authenticated",
    data: null,
    shouldRetryApiCall: true,
  });
}

export async function deactivateIntegrationsApiKeyApi(
  apiKey: string
): Promise<{ api_key: ApiKeyData }> {
  return request({
    url: "/api-key/key.deactivate",
    data: {
      api_key: apiKey,
    },
  });
}

export async function getContactIngestionEtlServiceApi(): Promise<ContactIngestionEtlType> {
  return await request({
    url: "/generic/feature.flags",
    shouldRetryApiCall: true,
  });
}
