import { RouteItem } from "../../common/types/common";
import urls from "../../urls";
import CreatePassword from "./CreatePassword";
import Login from "./Login";
import LoginPassword from "./LoginPassword";
import LoginRedirect from "./LoginRedirect";
import LoginSso from "./LoginSso";
import ValidateEmail from "./ValidateEmail";
import Unsubscribed from "./Unsubscribed";
import ForgotPassword from "./ForgotPassword";
import ForgotEmail from "./ForgotEmail";
import LoginWithToken from "./LoginWithToken";
import Register from "./Register";

export const routes: RouteItem[] = [
  {
    path: urls.login,
    name: "Login",
    component: Login,
    protected: false,
  },
  {
    path: urls.loginPassword,
    name: "Login with Password",
    component: LoginPassword,
    protected: false,
  },
  {
    path: urls.loginSso,
    name: "Login SSO",
    component: LoginSso,
    protected: false,
  },
  {
    path: urls.loginSsoRedirect,
    name: "Redirecting",
    component: LoginRedirect,
    protected: false,
  },
  {
    path: urls.passwordCreate,
    name: "Password Create",
    component: CreatePassword,
    protected: false,
  },
  {
    path: urls.verifyToken,
    name: "Validate Email Token",
    component: ValidateEmail,
    protected: false,
  },
  {
    path: urls.unsubscribed,
    name: "Unsubscribed",
    component: Unsubscribed,
    protected: false,
  },
  {
    path: urls.passwordForgot,
    name: "Forgot password",
    component: ForgotPassword,
    protected: false,
  },
  {
    path: urls.emailForgot,
    name: "Forgot Password Email",
    component: ForgotEmail,
    protected: false,
  },
  {
    path: urls.loginWithToken,
    name: "Set Token from URL",
    component: LoginWithToken,
    protected: false,
  },
  {
    path: urls.register,
    name: "Register",
    component: Register,
    protected: false,
  },
];
