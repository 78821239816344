import {
  BeePluginError,
  BeePluginMessageEditDetail,
  IBeeConfig,
  IPluginSessionInfo,
  ISpecialLink,
} from "@mailupinc/bee-plugin/dist/types/bee";
import { UNSUBSCRIBED_URL } from "../../../../common/constants/template";

export const BEEJS_URL = "https://app-rsrc.getbee.io/plugin/BeePlugin.js";
export const API_AUTH_URL = "https://auth.getbee.io/apiauth";
export const BEE_PLUGIN_CONTAINER_ID = "bee-plugin-container";

export const specialLinks: ISpecialLink[] = [
  {
    type: "unsubscribe",
    label: `{{${UNSUBSCRIBED_URL}}}`,
    link: `{{${UNSUBSCRIBED_URL}}}`,
  },
];

const customFonts = [
  {
    name: "Poppins",
    fontFamily: "'Poppins', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Poppins",
  },
  {
    name: "DM Sans",
    fontFamily: "'DM Sans', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=DM+Sans",
  },
  {
    name: "Inter",
    fontFamily: "'Inter', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Inter",
  },
  {
    name: "PT Sans",
    fontFamily: "'PT Sans', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=PT+Sans",
  },
  {
    name: "Lora",
    fontFamily: "'Lora', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Lora",
  },
  {
    name: "Slabo 13px",
    fontFamily: "'Slabo 13px', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Slabo+13px",
  },
  {
    name: "Work Sans",
    fontFamily: "'Work Sans', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Work+Sans",
  },
  {
    name: "Prompt",
    fontFamily: "'Prompt', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Prompt",
  },
  {
    name: "Concert One",
    fontFamily: "'Concert One', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Concert+One",
  },
  {
    name: "Nunito Sans",
    fontFamily: "'Nunito Sans', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Nunito+Sans",
  },
  {
    name: "Noto Sans",
    fontFamily: "'Noto Sans', sans-serif",
    url: "https://fonts.googleapis.com/css2?family=Noto+Sans",
  },
  {
    name: "Geist Sans",
    fontFamily: "'Geist Sans', sans-serif",
    url: "https://cdn.jsdelivr.net/npm/@fontsource/geist-sans/latin.css",
  },
  {
    name: "Geist Mono",
    fontFamily: "'Geist Mono', sans-serif",
    url: "https://cdn.jsdelivr.net/npm/@fontsource/geist-mono/latin.css",
  },
];

// Using any because uid isn't optional in IBeeConfig. Backend is not using uid anymore and it also poses
// security risks, so FE doesn't send it anymore. But the FE libraries haven't been updated to reflect the new change
// so until fe npm library for bee is updated we have to override this type here to suppress type errors

export const beeConfig: Omit<IBeeConfig, "uid"> = {
  container: BEE_PLUGIN_CONTAINER_ID,
  commenting: false,
  language: "en-US",
  specialLinks,
  loadingSpinnerDisableOnSave: true,
  saveRows: true,
  onSave: (_: string, htmlFile: string) => {},
  onLoad: () => {},
  onSaveAsTemplate: (pageJson: string, templateVersion: number) => {},
  onAutoSave: (jsonFile: string) => {},
  onSend: (htmlFile: string) => {},
  onError: (errorMessage: BeePluginError) => {},
  onChange: (
    json: string,
    detail: BeePluginMessageEditDetail,
    version: number
  ) => {},
  rowsConfiguration: {
    emptyRows: true,
    defaultRows: true,
    externalContentURLs: [
      {
        name: "My saved rows",
        handle: "saved-rows",
        isLocal: true,
        behaviour: {
          canEdit: true,
          canDelete: true,
          canEditSyncedRows: true,
          canDeleteSyncedRows: false,
        },
      },
    ],
  },
  editorFonts: {
    customFonts: [...customFonts],
  },
  onWarning: (e: BeePluginError) => {},
  onPreview: () => {},
  onTogglePreview: () => {},
  onSessionStarted: (sessionInfo: IPluginSessionInfo) => {},
  onSessionChange: (sessionInfo: IPluginSessionInfo) => {},
};
