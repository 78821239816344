import {
  VStack,
  Stack,
  Box,
  HStack,
  Text,
  Divider,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import {
  createGroupId,
  getOperatorDetails,
  isArgumentMany,
  isBlank,
} from "../../common/helper/commonHelper";
import {
  filterMarketingActivityByLevel,
  initMarketingActivity,
  isTypeGroup,
  addEllipsisToText,
} from "../../common/helper/dynamicListHelper";
import {
  DynamicListChildL2,
  CONNECTOR,
  TYPE,
  OperatorType,
  ValueTypes,
} from "../../common/types/campaign";
import AddPropertyButton from "./AddPropertyButton";
import { ReadOnlyDlLogicGate } from "./DynamicListLogicGate";
import { FilterGroupBox } from "./FilterGroupBox";
import { Fragment, useContext, useMemo } from "react";
import { LuAppWindow, LuFileCode } from "react-icons/lu";
import { useSelector } from "react-redux";
import {
  FILTER_TYPE,
  PROPERTY_FILTER_ID,
  WEBSITE_ACTIVITY_META,
} from "../../common/constants/campaign";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import ActivitySelector from "./ActivitySelector";
import { DynamicListContext } from "./DynamicList";
import { selectDynamicList } from "./dynamicListSlice";
import {
  ValueSelectFields,
  DynamicListValueFields,
  AsyncWebActivityMetaField,
} from "./DynamicListValueFields";
import { MarketingActivityArgument } from "../../common/types/person";
import AdditionalParametersMenu from "./AdditionalParametersMenu";
import PropertyGroupsHeader from "./PropertyGroupsHeader";
import {
  DL_ACTION,
  DL_FIELD,
  UTM_PARAM_FILTER,
  CUSTOM_PARAM_FILTER,
  STR_OPERATOR,
  CUSTOM_PARAM_STRING_COMPARE,
  UTM_PARAM_STRING_COMPARE,
} from "../../common/constants/dynamicList";

export default function FormSessionPropertyGroupsContainer({
  propertyGroups,
  parentId,
  mainFormFilter,
  onChangePropertyGroups,
  isReadOnly,
}: {
  propertyGroups: DynamicListChildL2[];
  parentId: string;
  mainFormFilter: string;
  onChangePropertyGroups: (data: DynamicListChildL2[]) => void;
  isReadOnly?: boolean;
}) {
  // taking second one as first one is always empty
  const propertyGroupsConnector = propertyGroups[1]?.connector ?? CONNECTOR.AND;

  function onChangePropertyGroup(
    action: DL_ACTION,
    index?: number,
    data?: DynamicListChildL2
  ) {
    const dataCopy = cloneDeep(propertyGroups);
    switch (action) {
      case DL_ACTION.ADD:
        const newPropertyGroup = {
          ...initMarketingActivity({
            type: TYPE.GROUP,
            connector: propertyGroupsConnector,
          }),
          filter_type: null,
          children: [initMarketingActivity({ connector: CONNECTOR.EMPTY })],
        };
        dataCopy.push(newPropertyGroup);
        break;

      case DL_ACTION.CHANGE:
        dataCopy[index!] = data!;
        break;

      case DL_ACTION.REMOVE:
        dataCopy.splice(index!, 1);
    }
    onChangePropertyGroups(dataCopy);
  }

  function onPropertyGroupsConnectorChange(connector: CONNECTOR) {
    if (propertyGroups.length > 1 && propertyGroupsConnector !== connector) {
      const dataCopy = cloneDeep(propertyGroups);
      dataCopy.forEach((propertyGroup, index) => {
        if (isTypeGroup(propertyGroup.type) && index > 0) {
          propertyGroup.connector = connector;
        }
      });
      onChangePropertyGroups(dataCopy);
    }
  }

  const totalPropertyGroups = propertyGroups.length;

  const wrapperStyle = isReadOnly ? { spacing: 2 } : { spacing: 3 };

  return (
    <VStack
      alignItems="flex-start"
      w="100%"
      fontSize="14px"
      bg="grayV2.300"
      rounded="md"
      hidden={totalPropertyGroups < 1}
      px={2}
      pb={2}
    >
      <PropertyGroupsHeader
        title="Web session parameters"
        subTitle="Group parameter by"
        connector={propertyGroupsConnector}
        onConnectorChange={onPropertyGroupsConnectorChange}
        displayConnector={totalPropertyGroups > 1}
        isReadOnly={isReadOnly}
      />

      <VStack alignItems="flex-start" w="100%" {...wrapperStyle}>
        {propertyGroups.map((propertyGroup, index) => {
          const propertyGroupId = createGroupId(
            parentId,
            "property",
            index + 1
          );
          return (
            <Stack
              key={propertyGroupId}
              alignItems="flex-start"
              w="100%"
              direction={isReadOnly ? "column" : "row"}
            >
              <Box w={totalPropertyGroups > 1 ? "25px" : undefined}>
                {index > 0 && (
                  <ReadOnlyDlLogicGate connector={propertyGroup.connector} />
                )}
              </Box>

              <PropertyGroup
                propertyGroup={propertyGroup}
                onChangePropertyGroup={(data) =>
                  onChangePropertyGroup(DL_ACTION.CHANGE, index, data)
                }
                onRemovePropertyGroup={() =>
                  onChangePropertyGroup(DL_ACTION.REMOVE, index)
                }
                label={propertyGroup.name || `Property Group ${index + 1}`}
                id={propertyGroupId}
                mainFilter={mainFormFilter}
                isReadOnly={isReadOnly}
              />
            </Stack>
          );
        })}

        <HStack px={1} alignItems="center" hidden={isReadOnly}>
          <ReadOnlyDlLogicGate connector={propertyGroupsConnector} mt={2} />
          <AddPropertyButton
            onClick={() => onChangePropertyGroup(DL_ACTION.ADD)}
            children="Add property group"
          />
        </HStack>
      </VStack>
    </VStack>
  );
}

//Single propertyGroup
function PropertyGroup({
  propertyGroup,
  onChangePropertyGroup,
  onRemovePropertyGroup,
  mainFilter,
  id,
  label,
  isReadOnly,
}: {
  propertyGroup: DynamicListChildL2;
  onChangePropertyGroup: (data: DynamicListChildL2) => void;
  onRemovePropertyGroup: () => void;
  mainFilter: string;
  id: string;
  label: string;
  isReadOnly?: boolean;
}) {
  const propertyGroupRowsConnector =
    propertyGroup.children?.[1]?.connector ?? CONNECTOR.AND; //first connector [0].connector is always empty;

  function onPropertyGroupNameChange(name: string) {
    if (propertyGroup.name !== name) {
      const dataCopy = cloneDeep(propertyGroup);
      dataCopy.name = name;
      onChangePropertyGroup(dataCopy);
    }
  }

  function onAddRow() {
    const newPropertyGroupRow = initMarketingActivity({
      connector: propertyGroupRowsConnector,
    });
    const dataCopy = cloneDeep(propertyGroup);
    dataCopy.children?.push(newPropertyGroupRow);
    onChangePropertyGroup(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(propertyGroup);
    dataCopy.children?.splice(index, 1);
    onChangePropertyGroup(dataCopy);
  }

  function onChangeRow(row: DynamicListChildL2, index: number) {
    if (propertyGroup.children) {
      const dataCopy = cloneDeep(propertyGroup);
      (dataCopy.children as DynamicListChildL2[])[index] = row;
      onChangePropertyGroup(dataCopy);
    }
  }

  const totalProperties = propertyGroup.children?.length ?? 0;

  function onRowsConnectorChange(connector: CONNECTOR) {
    //if only one property then need not change the connector
    if (totalProperties > 1 && connector !== propertyGroupRowsConnector) {
      const dataCopy = cloneDeep(propertyGroup);
      dataCopy.children?.forEach((property, index) => {
        if (index > 0) property.connector = connector;
      });
      onChangePropertyGroup(dataCopy);
    }
  }

  return (
    <FilterGroupBox
      id={id}
      type="property"
      groupName={propertyGroup.name ?? ""}
      onGroupNameChange={onPropertyGroupNameChange}
      onRemove={onRemovePropertyGroup}
      label={label}
      isReadOnly={isReadOnly}
      additionalComponent={
        <PropertyGroupsHeader
          subTitle="Group parameters by"
          connector={propertyGroupRowsConnector}
          onConnectorChange={onRowsConnectorChange}
          isReadOnly={isReadOnly}
          displayConnector={totalProperties > 1}
        />
      }
      bg="grayV2.400"
      borderColor="grayV2.400"
      readOnlyProps={{ bg: "grayV2.400", px: 0 }}
    >
      <VStack
        alignItems="baseline"
        w="100%"
        px={2}
        divider={
          isReadOnly ? (
            <Divider orientation="horizontal" borderColor="grayV2.200" />
          ) : undefined
        }
      >
        {propertyGroup.children?.map((property, index) => {
          return (
            <HStack
              key={index}
              width="100%"
              alignItems="baseline"
              spacing={0}
              gap={1}
            >
              <Box w={totalProperties > 1 && !isReadOnly ? "25px" : undefined}>
                {index > 0 && (
                  <ReadOnlyDlLogicGate connector={property.connector} />
                )}
              </Box>

              <PropertyGroupRow
                propertyRow={property}
                onChangePropertyRow={(data) => onChangeRow(data, index)}
                onRemovePropertyRow={() => onRemoveRow(index)}
                mainFilter={mainFilter}
                isReadOnly={isReadOnly}
                showRemoveButton={totalProperties > 1}
              />
            </HStack>
          );
        })}
      </VStack>

      <AddPropertyButton
        children={
          totalProperties > 1
            ? "Add another property"
            : "Add multiple properties in the group"
        }
        onClick={onAddRow}
        hidden={isReadOnly || totalProperties <= 0}
        py={2}
        px={4}
        m="2"
      />
    </FilterGroupBox>
  );
}

function PropertyGroupRow({
  propertyRow,
  onChangePropertyRow,
  onRemovePropertyRow,
  mainFilter,
  showRemoveButton = false,
  isReadOnly,
}: {
  propertyRow: DynamicListChildL2;
  onChangePropertyRow: (data: DynamicListChildL2) => void;
  onRemovePropertyRow: () => void;
  mainFilter: string;
  showRemoveButton?: boolean;
  isReadOnly?: boolean;
}) {
  const {
    marketingEventNamesList: { data: marketingEventList },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const level3FiltersList = useMemo(
    () =>
      filterMarketingActivityByLevel(marketingEventList ?? [], mainFilter, 3) ??
      [],
    [marketingEventList, mainFilter]
  );

  const filter = useMemo(
    () =>
      (isTypeGroup(propertyRow.type)
        ? propertyRow.children?.[0].filter
        : propertyRow.filter) ?? null,
    [propertyRow]
  );

  const filterDetails = useMemo(
    () =>
      filter ? level3FiltersList.find(({ id }) => id === filter) ?? null : null,
    [level3FiltersList, filter]
  );

  function checkIfArgPresent(filter: string) {
    return !!level3FiltersList.find(({ id }) => id === filter)?.arguments;
  }

  function onPropertyFilterChange(value: string) {
    if (filter !== value) {
      const dataCopy = cloneDeep(propertyRow);

      if (checkIfArgPresent(value)) {
        dataCopy.type = TYPE.GROUP;
        dataCopy.children = [{ ...initMarketingActivity({ filter: value }) }];
        dataCopy.filter_type = null;
        dataCopy.filter = "";
        dataCopy.operator = null;
      } else {
        dataCopy.filter = value;
        dataCopy.type = TYPE.EXPRESSION;
        dataCopy.filter_type = FILTER_TYPE.WEBSITE_ACTIVITY;
        dataCopy.children = [];

        switch (value) {
          case UTM_PARAM_FILTER:
            dataCopy.operator = UTM_PARAM_STRING_COMPARE;
            break;
          case CUSTOM_PARAM_FILTER:
            dataCopy.operator = CUSTOM_PARAM_STRING_COMPARE;
            break;
          default:
            dataCopy.operator = null;
        }
      }

      dataCopy.value = [];
      dataCopy.validation_error = "";

      onChangePropertyRow(dataCopy);
    }
  }

  function onChangePropertyRowGroup(data: DynamicListChildL2[]) {
    const dataCopy = cloneDeep(propertyRow);
    dataCopy.children = data;
    onChangePropertyRow(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: 0,
        spacing: 0,
        gap: 1,
        gridRowGap: 1,
      }
    : {
        spacing: 2,
        gridRowGap: 2,
        p: 1,
      };

  const validationError = activeErrorCheck
    ? (propertyRow.validation_error ||
        propertyRow.children?.[0]?.validation_error) ??
      ""
    : "";

  return (
    <Flex justifyContent="space-between" w="100%">
      <HStack alignItems="flex-start" w="100%" {...wrapperStyle}>
        <HStack maxW="40px" hidden={isReadOnly} pl={1}>
          <Text pt={3}>where...</Text>
        </HStack>

        <HStack
          alignItems="flex-start"
          w="100%"
          flexWrap="wrap"
          {...wrapperStyle}
        >
          <ActivitySelector
            activityList={level3FiltersList?.map(({ display, id }) => ({
              label: display,
              value: id,
            }))}
            value={filter ?? ""}
            onChange={onPropertyFilterChange}
            isReadOnly={isReadOnly}
            validationError={validationError}
            icon={LuAppWindow}
          />
          {filter && (
            <>
              {isTypeGroup(propertyRow.type) ? (
                <PropertyGroupRowGroup
                  mainFilter={mainFilter}
                  propertyRowGroup={propertyRow.children ?? []}
                  rowFilterDetails={filterDetails}
                  onChangePropertyRowGroup={onChangePropertyRowGroup}
                  isReadOnly={isReadOnly}
                />
              ) : (
                <PropertyGroupRowExp
                  propertyRowExp={propertyRow}
                  rowFilterDetails={filterDetails}
                  onChangePropertyRowExp={onChangePropertyRow}
                  isReadOnly={isReadOnly}
                />
              )}
            </>
          )}
        </HStack>
      </HStack>
      <Flex h="42px" alignItems="center">
        <RemoveRowCloseButton
          onClick={onRemovePropertyRow}
          hidden={isReadOnly || !showRemoveButton}
        />
      </Flex>
    </Flex>
  );
}

function PropertyGroupRowExp({
  propertyRowExp,
  onChangePropertyRowExp,
  rowFilterDetails,
  isReadOnly,
}: {
  propertyRowExp: DynamicListChildL2;
  onChangePropertyRowExp: (data: DynamicListChildL2) => void;
  rowFilterDetails: MarketingActivityArgument | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  //custom and utm operator details
  const operatorDetails = useMemo(
    () =>
      propertyRowExp.operator && rowFilterDetails?.data_type && operators
        ? getOperatorDetails(
            propertyRowExp.operator,
            operators,
            rowFilterDetails.data_type
          )
        : null,
    [rowFilterDetails?.data_type, operators, propertyRowExp.operator]
  );

  function onChangeValue(
    value: string | number | string[],
    index: number,
    emptyNextValue?: boolean
  ) {
    const dataCopy = cloneDeep(propertyRowExp);

    dataCopy.value[index] = value;
    dataCopy.validation_error = "";
    if (emptyNextValue) {
      dataCopy.value.splice(index + 1, 1);
    }
    onChangePropertyRowExp(dataCopy);
  }

  //filter operator and value together put in the value field
  const [filter, stringOperator] = useMemo(
    () => propertyRowExp.value ?? [null, null, null],
    [propertyRowExp.value]
  );

  const validationError = activeErrorCheck
    ? propertyRowExp.validation_error
    : "";

  const stringOperatorDetails =
    stringOperator && operators
      ? getOperatorDetails(
          (stringOperator as string) ?? null,
          operators,
          OperatorType.STRING
        )
      : null;

  function OperatorValueFields(
    type: string | { display: string; id: string }[] | null,
    index: number
  ) {
    const valueFromValueArr = propertyRowExp.value?.[index];
    const commonProps = {
      isReadOnly,
      validationError,
    };

    if (type && typeof type === "object") {
      return (
        <ValueSelectFields
          options={
            type?.map(({ display, id }) => ({
              label: display,
              value: id,
            })) ?? []
          }
          value={valueFromValueArr as string}
          onChange={(valueFromValueArr) =>
            onChangeValue(valueFromValueArr, index)
          }
          {...commonProps}
        />
      );
    } else if (type === STR_OPERATOR) {
      return (
        <>
          {filter && (
            <ValueSelectFields
              options={
                operators
                  ? Object.values(operators[OperatorType.STRING]).map(
                      ({ display, id }) => ({
                        label: display,
                        value: id,
                      })
                    )
                  : []
              }
              onChange={(valueFromValueArr) =>
                onChangeValue(valueFromValueArr, index, true)
              }
              value={valueFromValueArr as string}
              {...commonProps}
            />
          )}
        </>
      );
    } else if (type === null) {
      // type inferred from previous value in the array
      const isManyArg = isArgumentMany(stringOperatorDetails?.arguments);
      const valueList = isManyArg
        ? valueFromValueArr || []
        : [valueFromValueArr];
      return (
        <>
          {stringOperator && (
            <DynamicListValueFields
              value={valueList as string[]}
              onChange={(value) =>
                onChangeValue(
                  (isManyArg ? (value as string[]) : (value[0] as string)) ??
                    "",
                  index
                )
              }
              argumentTypes={stringOperatorDetails?.arguments_types ?? null}
              helperText={stringOperatorDetails?.display_2}
              noOfArguments={stringOperatorDetails?.arguments}
              {...commonProps}
            />
          )}
        </>
      );
    }
    return (
      <AsyncWebActivityMetaField
        metaField={propertyRowExp.filter as WEBSITE_ACTIVITY_META}
        value={!isBlank(valueFromValueArr) ? (valueFromValueArr as string) : ""}
        onChange={(valueFromValueArr) =>
          onChangeValue(valueFromValueArr, index, !valueFromValueArr)
        }
        {...commonProps}
      />
    );
  }

  return (
    <>
      {propertyRowExp.filter &&
        propertyRowExp.operator &&
        operators &&
        operatorDetails?.arguments_types?.map((type, index) => {
          return (
            <Fragment key={index}>{OperatorValueFields(type, index)}</Fragment>
          );
        })}
    </>
  );
}

function PropertyGroupRowGroup({
  mainFilter,
  propertyRowGroup,
  onChangePropertyRowGroup,
  rowFilterDetails,
  isReadOnly,
}: {
  mainFilter: string | null;
  propertyRowGroup: DynamicListChildL2[];
  onChangePropertyRowGroup: (data: DynamicListChildL2[]) => void;
  rowFilterDetails: MarketingActivityArgument | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);
  const { activeErrorCheck } = useContext(DynamicListContext);

  //split the first row and the rest of the rows
  //first row has the main filter
  const firstPropertyRow = useMemo(
    () => propertyRowGroup[0],
    [propertyRowGroup]
  );

  const operatorDetails = useMemo(
    () =>
      rowFilterDetails && firstPropertyRow.operator
        ? getOperatorDetails(
            firstPropertyRow.operator,
            operators,
            rowFilterDetails.data_type
          )
        : null,
    [firstPropertyRow.operator, operators, rowFilterDetails]
  );

  function onChangeFirstPropertyRow(
    field: DL_FIELD,
    value: string | ValueTypes
  ) {
    const dataCopy = cloneDeep(propertyRowGroup);
    switch (field) {
      case DL_FIELD.OPERATOR:
        if (dataCopy[0].operator !== value) {
          dataCopy[0].operator = value as string;
          dataCopy[0].value = [];
        }
        break;
      case DL_FIELD.VALUE:
        dataCopy[0].value = value as ValueTypes;
    }
    dataCopy[0].validation_error = "";
    onChangePropertyRowGroup(dataCopy);
  }

  function onChangeRowFilter(
    action: DL_ACTION,
    index?: number,
    data?: DynamicListChildL2 | PROPERTY_FILTER_ID
  ) {
    const dataCopy = cloneDeep(propertyRowGroup);

    switch (action) {
      case DL_ACTION.ADD:
        const newRowGroupFilter = initMarketingActivity({
          filter: data as PROPERTY_FILTER_ID,
        });
        dataCopy.push(newRowGroupFilter);
        break;
      case DL_ACTION.CHANGE:
        dataCopy[index!] = data! as DynamicListChildL2;
        break;
      case DL_ACTION.REMOVE:
        dataCopy.splice(index!, 1);
    }
    onChangePropertyRowGroup(dataCopy);
  }

  const validationError = activeErrorCheck
    ? firstPropertyRow.validation_error
    : "";

  return (
    <>
      {firstPropertyRow.filter && operators && (
        <ValueSelectFields
          options={
            operators && rowFilterDetails
              ? Object.values(operators[rowFilterDetails.data_type] ?? {})?.map(
                  (op) => ({
                    label: op.display,
                    value: op.id,
                  })
                )
              : []
          }
          value={firstPropertyRow.operator ?? ""}
          onChange={(op) => onChangeFirstPropertyRow(DL_FIELD.OPERATOR, op)}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      )}
      {firstPropertyRow.operator && operators && (
        <DynamicListValueFields
          mainFilter={mainFilter}
          value={firstPropertyRow.value ?? []}
          onChange={(value) => onChangeFirstPropertyRow(DL_FIELD.VALUE, value)}
          argumentTypes={operatorDetails?.arguments_types ?? null}
          helperText={operatorDetails?.display_2}
          noOfArguments={operatorDetails?.arguments}
          validationError={validationError}
          isReadOnly={isReadOnly}
          filter={firstPropertyRow.filter ?? ""}
          operator={firstPropertyRow.operator}
        />
      )}

      <Stack
        direction="column"
        alignItems="flex-start"
        position="relative"
        spacing={1}
        w="100%"
      >
        <Divider
          orientation="vertical"
          position="absolute"
          borderColor="brandBlue.300"
          h="80%"
          ml="-30px"
          mt="16px"
          hidden={isReadOnly}
        />
        {/* remove first filter  */}
        {propertyRowGroup.slice(1).map((propertyRowGroupFilter, index) => {
          return (
            <PropertyGroupRowGroupFilter
              key={index}
              rowFilter={propertyRowGroupFilter}
              onChangeRowFilter={(data) =>
                onChangeRowFilter(DL_ACTION.CHANGE, index + 1, data)
              }
              onRemoveRowFilter={() =>
                onChangeRowFilter(DL_ACTION.REMOVE, index + 1)
              }
              rowFilterDetails={
                rowFilterDetails?.arguments?.find(
                  ({ id }) => id === propertyRowGroupFilter.filter
                ) ?? null
              }
              isReadOnly={isReadOnly}
            />
          );
        })}
      </Stack>
      {firstPropertyRow.operator && (
        <AdditionalParametersMenu
          options={
            rowFilterDetails?.arguments?.filter(
              ({ id }) => !propertyRowGroup.find(({ filter }) => filter === id)
            ) ?? null
          }
          label="Add additional URL parameters"
          onSelect={(valueFromValueArr) =>
            onChangeRowFilter(DL_ACTION.ADD, undefined, valueFromValueArr)
          }
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
}

function PropertyGroupRowGroupFilter({
  rowFilter,
  rowFilterDetails,
  onChangeRowFilter,
  onRemoveRowFilter,
  isReadOnly,
}: {
  rowFilter: DynamicListChildL2;
  onChangeRowFilter: (data: DynamicListChildL2) => void;
  onRemoveRowFilter: () => void;
  rowFilterDetails: MarketingActivityArgument | null;
  isReadOnly?: boolean;
}) {
  const {
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        rowFilter.operator,
        operators,
        rowFilterDetails?.data_type
      ),
    [rowFilter.operator, operators, rowFilterDetails?.data_type]
  );

  function onChangeFilter(field: DL_FIELD, value: ValueTypes | string) {
    const dataCopy = cloneDeep(rowFilter);
    switch (field) {
      case DL_FIELD.OPERATOR:
        if (dataCopy.operator !== value) {
          dataCopy.operator = value as string;
          dataCopy.value = [];
        }
        break;
      case DL_FIELD.VALUE:
        dataCopy.value = value as ValueTypes;
    }
    dataCopy.validation_error = "";
    onChangeRowFilter(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? { spacing: 1, gridRowGap: 1 }
    : { spacing: 2, py: 1, gridRowGap: 2 };

  const validationError = activeErrorCheck ? rowFilter.validation_error : "";
  return (
    <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle} w="100%">
      {isReadOnly ? (
        <>
          <Icon as={LuFileCode} color="brand.blue" fontSize="14px" mt={1} />
          <Text>in which {rowFilterDetails?.display}</Text>
        </>
      ) : (
        <Text pt={2}>
          {addEllipsisToText(`in which ${rowFilterDetails?.display}`)}
        </Text>
      )}
      <HStack flex="1" alignItems="flex-start" wrap="wrap" {...wrapperStyle}>
        {rowFilter.filter && rowFilterDetails && operators && (
          <ValueSelectFields
            options={Object.values(
              operators[rowFilterDetails.data_type] ?? {}
            )?.map((op) => ({
              label: op.display,
              value: op.id,
            }))}
            value={rowFilter.operator}
            onChange={(op) => onChangeFilter(DL_FIELD.OPERATOR, op)}
            validationError={validationError}
            isReadOnly={isReadOnly}
          />
        )}

        {rowFilter.operator && operators && (
          <DynamicListValueFields
            value={rowFilter.value ?? []}
            onChange={(valueFromValueArr) =>
              onChangeFilter(DL_FIELD.VALUE, valueFromValueArr)
            }
            argumentTypes={operatorDetails?.arguments_types ?? null}
            helperText={operatorDetails?.display_2}
            noOfArguments={operatorDetails?.arguments}
            isReadOnly={isReadOnly}
            validationError={validationError}
          />
        )}
      </HStack>
      <RemoveRowCloseButton onClick={onRemoveRowFilter} hidden={isReadOnly} />
    </HStack>
  );
}
