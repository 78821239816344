import {
  FaAdjust,
  FaAlignLeft,
  FaCalendarAlt,
  FaHashtag,
  FaRegClock,
  FaUser,
} from "react-icons/fa";
import { BiCodeCurly } from "react-icons/bi";
import {
  ACCOUNT_SYNC_PREFERENCE,
  Destination,
  DESTINATION_TYPES,
  INFERRED_TYPES,
  SYNC_PREFERENCE,
} from "../types/unifiedMapping";

const TWO_WAY_OPTION = { label: "Two Way", value: SYNC_PREFERENCE.TWO_WAY };

export const ALWAYS_USE_SALESFORCE_OPTION = {
  label: "Always use Salesforce",
  value: SYNC_PREFERENCE.ALWAYS_USE_SALESFORCE,
};

const PREFER_CONNECTION_OPTION = {
  label: "Prefer Salesforce unless blank",
  value: SYNC_PREFERENCE.PREFER_CONNECTION,
};

export const SF_SYNC_PREFERENCES = [
  TWO_WAY_OPTION,
  ALWAYS_USE_SALESFORCE_OPTION,
  PREFER_CONNECTION_OPTION,
];

export const DW_SYNC_PREFERENCES = [
  {
    label: "Prefer Connection",
    value: SYNC_PREFERENCE.PREFER_CONNECTION,
  },
];

export const ACCOUNT_SYNC_OPTIONS = [
  {
    label: "Always use Salesforce",
    value: ACCOUNT_SYNC_PREFERENCE.ALWAYS_USE_SALESFORCE,
  },
];

// name none option in crm source dropdown
// random keys appeneded to avoid collision with existing client columns
export const SYNC_NONE = "sync-none-237adas";

export const NONE_DESTINATION: Destination = {
  type: DESTINATION_TYPES.STRING,
  custom: false,
  hidden: false,
  read_only: false,
  length: null,
  updatable: false,
  required: false,
  primary_key: false,
  name: SYNC_NONE,
  display: "None",
};

export const DESTINATION_TYPE_SPECIFIC_ICONS = {
  [DESTINATION_TYPES.BOOLEAN]: FaAdjust,
  [DESTINATION_TYPES.STRING]: FaAlignLeft,
  [DESTINATION_TYPES.INTEGER]: FaHashtag,
  [DESTINATION_TYPES.LONG]: FaHashtag,
  [DESTINATION_TYPES.FLOAT]: FaHashtag,
  [DESTINATION_TYPES.DATETIME]: FaCalendarAlt,
  [DESTINATION_TYPES.JSON]: BiCodeCurly,
};

export const DESTINATION_TYPE_SPECIFIC_ICONS_FOR_INFERRED = {
  [INFERRED_TYPES.TIMEZONE]: FaRegClock,
};

export const TAB = "tab";

export const FIELD_TYPE_OPTIONS = [
  { label: "String", value: DESTINATION_TYPES.STRING, icon: FaUser },
  { label: "Boolean", value: DESTINATION_TYPES.BOOLEAN, icon: FaUser },
  { label: "Integer", value: DESTINATION_TYPES.INTEGER, icon: FaUser },
  { label: "Float", value: DESTINATION_TYPES.FLOAT, icon: FaUser },
  { label: "Date Time", value: DESTINATION_TYPES.DATETIME, icon: FaUser },
];
