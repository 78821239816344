import { useDisclosure } from "@chakra-ui/react";
import { cloneDeep, snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  NONE_DESTINATION,
  SYNC_NONE,
} from "../../../../../../common/constants/unifiedMapping";
import { isLoading } from "../../../../../../common/helper/commonHelper";
import {
  AccountMappingElement,
  ConnectionListItem,
  DESTINATION_TYPES,
} from "../../../../../../common/types/unifiedMapping";
import { useAppDispatch } from "../../../../../../store";
import {
  selectConnection,
  updateUnifiedMappingAccount,
} from "../../../connectionSlice";
import { AddNewFieldModal } from "../../../../../../components/unified-mapping/AddNewFieldModal";
import AccountTab from "./components/AccountTab/AccountTab";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import ContentContainer from "../../../../../../components/v2/ContentContainer";
import SpinnerContainer from "../../../../../../components/SpinnerContainer";
import CenteredTextBox from "../../../../../../components/CenteredTextBox";
import { SalesforceConnectionsSummary } from "../../../../../../common/types/salesforce";

export default function AccountMappingTab({
  accountSources,
  isDataLoading,
  isAccountObjectEnabled,
  syncObjSettingsEnabled,
  salesforceConnections,
}: {
  accountSources: ConnectionListItem[];
  isDataLoading: boolean;
  isAccountObjectEnabled: boolean; // check if account sync is enabled
  syncObjSettingsEnabled?: boolean; // check if settings is present
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  return (
    <SpinnerContainer loading={isDataLoading} minH="500px">
      {syncObjSettingsEnabled ? (
        <AccountMapping
          accountSources={accountSources}
          isAccountObjectPresent={isAccountObjectEnabled}
          salesforceConnections={salesforceConnections}
        />
      ) : (
        <CenteredTextBox
          color="gray.400"
          message="No accounts mapped to inflection"
        />
      )}
    </SpinnerContainer>
  );
}

function AccountMapping({
  accountSources,
  isAccountObjectPresent,
  salesforceConnections,
}: {
  accountSources: ConnectionListItem[];
  isAccountObjectPresent: boolean;
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  const {
    unifiedConnection: { mapping, updatingMapping, automapping },
  } = useSelector(selectConnection);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useAppDispatch();

  function addField(data: { field: string; type: DESTINATION_TYPES }) {
    const rowsCopy = cloneDeep(rows);
    rowsCopy.push({
      tempId: Math.random(),
      source: null,
      destination: {
        type: data.type,
        custom: true,
        hidden: false,
        read_only: false,
        length: null,
        updatable: true,
        required: false,
        primary_key: false,
        name: snakeCase(data.field),
        display: data.field,
      },
    });
    setRows(rowsCopy);
    onClose();
  }

  function addTempIds(mapping?: AccountMappingElement[]) {
    const mappingCopy = cloneDeep(mapping) || [];
    let mappingsWithId = null;
    if (mappingCopy) {
      mappingsWithId = mappingCopy.map((x) => {
        x.tempId = Math.random();

        x.source = x.source
          ? x.source.map((y) => {
              if (!y.field) {
                y.field = NONE_DESTINATION;
              }
              return y;
            })
          : null;
        return x;
      });
    }
    return mappingsWithId;
  }

  const [rows, setRows] = useState(
    addTempIds(mapping?.data?.account_mapping?.mapping) ?? []
  );

  useEffect(() => {
    setRows(addTempIds(mapping?.data?.account_mapping?.mapping) ?? []);
  }, [mapping]);

  function onChangeData(data: AccountMappingElement[]) {
    setRows([...data]);
  }

  function removeRow(name: string) {
    const newRows = cloneDeep(rows);
    const index = rows.findIndex((row) => row.destination.name === name);
    newRows.splice(index, 1);
    setRows(newRows);
  }

  function removeTempIds(rows: AccountMappingElement[]) {
    const clonedRows = cloneDeep(rows);
    return clonedRows.map((x) => {
      // set None sources as null
      x.source =
        x.source?.map((y) => {
          if (y.field?.name === SYNC_NONE) {
            y.field = null;
          }
          return y;
        }) ?? null;

      // set source as null if leads and contacts are null
      x.source = x.source ? x.source.filter((y) => y.field) : null;

      delete x.tempId;
      return x;
    });
  }

  function onCancel() {
    setRows(addTempIds(mapping?.data?.account_mapping?.mapping) ?? []);
  }

  function savePersonTabData() {
    const formattedData = removeTempIds(rows);
    dispatch(updateUnifiedMappingAccount(formattedData));
  }

  return (
    <ContentContainerWithHeader>
      <ContentContainer my="15px" bg="unset" p="unset">
        <AccountTab
          accountSources={accountSources}
          data={rows}
          onChange={(data: AccountMappingElement[]) => onChangeData(data)}
          removeRow={(name: string) => removeRow(name)}
          onSave={() => savePersonTabData()}
          isLoading={isLoading(mapping.loading)}
          automapping={isLoading(automapping)}
          updatingMapping={isLoading(updatingMapping)}
          onCancel={onCancel}
          openAddModal={onOpen}
          isAccountObjectPresent={isAccountObjectPresent}
          salesforceConnections={salesforceConnections}
        />
        <AddNewFieldModal
          save={(data) => addField(data)}
          isOpen={isOpen}
          onCancel={onClose}
        />
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
