import { HStack, StackProps, Text } from "@chakra-ui/react";
import IconWithTooltip from "../../../../../../../components/IconWithTooltip";

export function NoteBox({
  title = "Note:",
  text,
  tooltipText,
  ...props
}: {
  title?: string;
  text: string;
  tooltipText?: string;
} & StackProps) {
  return (
    <HStack
      bg="blackAlpha.50"
      py={3}
      px={3}
      fontSize="sm"
      rounded="md"
      {...props}
    >
      <Text fontWeight="semibold">{title}</Text>
      <Text>{text}</Text>
      {tooltipText ? (
        <IconWithTooltip
          label={tooltipText}
          iconContainerProps={{ as: "span" }}
          tooltipProps={{ fontSize: "xs", position: "initial" }}
        />
      ) : null}
    </HStack>
  );
}
