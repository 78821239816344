import { ButtonGroup, HStack, Input, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import {
  keepClickEventRestricted,
  onEnterKeySubmit,
} from "../../../../../../../common/helper/commonHelper";
import IButton, { BUTTON } from "../../../../../../../components/IButton";

export default function DescriptionEdit({
  value,
  onSave,
  isUpdating = false,
}: {
  value: string;
  onSave: (newval: string) => void;
  isUpdating?: boolean;
}) {
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [newDescription, setNewDescription] = useState(value);

  function onSubmit() {
    onSave(newDescription);
    setIsEditClicked(false);
  }

  if (!isEditClicked)
    return (
      <>
        <Tooltip label={value || "No Description"}>
          <Text
            fontSize="12px"
            color="text.50"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            maxW="600px"
          >
            {value || "No Description"}
          </Text>
        </Tooltip>
        <IButton
          fontSize={12}
          onClick={(e) =>
            keepClickEventRestricted(e, () => setIsEditClicked(true))
          }
          colorScheme="blue"
          variant="link"
          pl="3"
        >
          Edit
        </IButton>
      </>
    );
  else
    return (
      <HStack>
        <Input
          placeholder="Enter description"
          width={500}
          fontSize={12}
          defaultValue={value}
          onChange={(e) => setNewDescription(e.target.value)}
          onKeyDown={(e) => onEnterKeySubmit(e, onSubmit)}
          onClick={(e) => keepClickEventRestricted(e)}
        />
        <ButtonGroup>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={(e) =>
              keepClickEventRestricted(e, () => setIsEditClicked(false))
            }
            isDisabled={isUpdating}
          >
            Cancel
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={(e) => keepClickEventRestricted(e, onSubmit)}
            isLoading={isUpdating}
          >
            Save
          </IButton>
        </ButtonGroup>
      </HStack>
    );
}
