import {
  VStack,
  HStack,
  Icon,
  Text,
  Flex,
  Image,
  Input,
  Box,
  ModalHeader,
  IconButton,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Link,
} from "@chakra-ui/react";
import { useMemo } from "react";
import {
  Bs1CircleFill,
  Bs2CircleFill,
  BsArrowLeft,
  BsArrowLeftRight,
  BsArrowRight,
} from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useSelector } from "react-redux";
import {
  isLoading,
  isFulfilled,
  isFailed,
} from "../../../../../common/helper/commonHelper";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { SEGMENT_SYNC_TYPES } from "../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../store";
import { CopyTextToClipboard } from "../../connectionList/components/CopyDatabaseName";
import segmentLogo from "../../../../../common/img/logo/segment.png";
import inflectionLogo from "../../../../../common/img/logo/logo.png";
import {
  selectConnection,
  setSegmentDestinationKey,
  setSegmentSync,
} from "../../connectionSlice";
import { toast } from "react-toastify";

const SEGMENT_SYNC_ITEMS = {
  [SEGMENT_SYNC_TYPES.SOURCE]: {
    heading: "Source: Segment",
    body: <SegmentAsSource />,
  },
  [SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION]: {
    heading: "Source & Destination: Segment",
    body: <SegmentAsSourceAndDestination />,
  },
  [SEGMENT_SYNC_TYPES.DESTINATION]: {
    heading: "Destination: Segment",
    body: <SegmentAsDestination />,
  },
};

const SEGMENT_SYNC_ICONS = {
  [SEGMENT_SYNC_TYPES.SOURCE]: BsArrowRight,
  [SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION]: BsArrowLeftRight,
  [SEGMENT_SYNC_TYPES.DESTINATION]: BsArrowLeft,
};

export function SegmentSyncIcon({ type }: { type: SEGMENT_SYNC_TYPES }) {
  return (
    <HStack width="60px" height="20px" alignItems="center" mr={1}>
      <Image src={segmentLogo} alt="segment" boxSize="17px" />
      <Icon as={SEGMENT_SYNC_ICONS[type]} fontSize="14px" color="blue.800" />
      <Image src={inflectionLogo} alt="Inflection" boxSize="14px" />
    </HStack>
  );
}

export function SegmentAsSource() {
  const {
    segment: {
      createSync: { readApiKey },
    },
  } = useSelector(selectConnection);
  return (
    <>
      <VStack alignItems="flex-start">
        <Text fontSize="14px" fontWeight="600" mb={3}>
          Almost done, copy your API key
        </Text>
        <HStack alignItems="flex-start" fontSize="12px">
          <Icon as={Bs1CircleFill} color="gray.400" fontSize="16px" />
          <Flex direction="column">
            <Text>
              At the last step of connecting your Segment source with
              Inflection, you would be required to input the below generated API
              key in Segment.
            </Text>
            <Text mt={2}>
              This would help us identify and authenticate your segment data.
            </Text>
          </Flex>
        </HStack>
        <CopyTextToClipboard
          text={readApiKey.data.api_key}
          w="100%"
          py={2}
          px={1}
          buttonProps={{
            isDisabled: isFailed(readApiKey.loading),
            isLoading: isLoading(readApiKey.loading),
          }}
        />
      </VStack>
      <HStack fontSize="12px" mt={4} alignItems="flex-start">
        <Icon as={Bs2CircleFill} color="gray.400" fontSize="16px" />
        <Flex direction="column">
          <Text>Copy the API key into Segment Destination.</Text>
          <Link
            mt={2}
            isExternal
            color="blue.700"
            href="https://segment.com/catalog/integrations/inflection/"
          >
            Click Here to go to Segment
            <Icon as={HiOutlineExternalLink} mx={1} />
          </Link>

          <Text mt={2}>
            <Text as="span" fontWeight="bold" mr={1}>
              Note:
            </Text>
            Once you paste your API keys in Segment, you can send us a test
            event with it. Your API cannot be viewed again once this window is
            closed.
          </Text>
        </Flex>
      </HStack>
    </>
  );
}

function SegmentAsDestination() {
  const {
    segment: {
      createSync: { writeApiKey: destinationKey },
    },
  } = useSelector(selectConnection);
  const dispatch = useAppDispatch();
  return (
    <>
      <Text fontSize="sm" fontWeight="bold">
        Enter your Segment API Key
      </Text>
      <Text fontSize="12px" mt={3}>
        Inflection requires API to start sending data. You can add Inflection as
        a source and generate an API key in Segment.
      </Text>
      <Input
        mt={3}
        placeholder="api-key AAAAwu2nnn3k-11292jasq1-11100aoala-66589"
        value={destinationKey}
        onChange={(e) => dispatch(setSegmentDestinationKey(e.target.value))}
      />
    </>
  );
}

function SegmentAsSourceAndDestination() {
  return (
    <>
      <Box borderBottom="1px solid" borderColor="gray.100" pb={4}>
        <Text fontSize="14px" fontWeight="bold" mb={3} color="blue.800">
          Source
        </Text>
        <SegmentAsSource />
      </Box>
      <Box mt={3}>
        <Text fontSize="14px" fontWeight="bold" mb={3} color="blue.800">
          Destination
        </Text>
        <SegmentAsDestination />
      </Box>
    </>
  );
}

export default function SegmentSyncs({
  onSave,
  onCancel,
  goToPrevStep,
}: {
  onSave: () => void;
  onCancel: () => void;
  goToPrevStep: () => void;
}) {
  const {
    segment: {
      createSync: {
        type: segmentConnectionType,
        writeApiKey: destinationKey,
        readApiKey: sourceKey,
      },
    },
  } = useSelector(selectConnection);
  const dispatch = useAppDispatch();

  const isDisabledSave = useMemo(() => {
    switch (segmentConnectionType) {
      case SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION:
        return !destinationKey || !sourceKey.data.api_key;
      case SEGMENT_SYNC_TYPES.SOURCE:
        return !sourceKey.data.api_key;
      case SEGMENT_SYNC_TYPES.DESTINATION:
        return !destinationKey;
    }
  }, [destinationKey, segmentConnectionType, sourceKey.data.api_key]);

  async function onSaveSegmentSync() {
    if (segmentConnectionType) {
      const isDestConnection =
        segmentConnectionType === SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION &&
        destinationKey;
      const write_key = isDestConnection ? destinationKey : "";
      const result = await dispatch(setSegmentSync({ write_key }));
      if (isFulfilled(result.meta.requestStatus)) {
        toast.success("Connection created successfully");
      } else {
        toast.error("Connection creation failed");
      }
      onSave();
    }
  }

  return (
    <>
      {segmentConnectionType && (
        <>
          <ModalHeader borderBottom="1px solid" borderColor="gray.100" px={3}>
            <IconButton
              variant="ghost"
              icon={<FaArrowLeft />}
              mr={1}
              mb={1}
              aria-label="back"
              onClick={goToPrevStep}
            />
            {SEGMENT_SYNC_ITEMS[segmentConnectionType].heading}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={8} py={5}>
            {SEGMENT_SYNC_ITEMS[segmentConnectionType].body}
          </ModalBody>
          <ModalFooter>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={onCancel}
              isDisabled={isLoading(sourceKey.loading)}
              mr={3}
            >
              Cancel
            </IButton>
            <IButton
              variant={BUTTON.PRIMARY}
              isDisabled={isDisabledSave}
              onClick={onSaveSegmentSync}
              isLoading={isLoading(sourceKey.loading)}
            >
              Save
            </IButton>
          </ModalFooter>
        </>
      )}
    </>
  );
}
