import { Box, Text, HStack, VStack } from "@chakra-ui/react";
import {
  InflectionEvents,
  SALESFORCE_CREATE_ACTION,
  SALESFORCE_DELETE_ACTION,
  SyncedObjSummary,
} from "../../../../../../../common/types/salesforce";
import ComingSoonText from "../../../../../../../components/ComingSoonText";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";
import { Header } from "./Header";
import { NoteBox } from "./NoteBox";

const INFLECTION_PERSON_CREATE_MENU_OPTIONS = [
  {
    label: "Create a lead in Salesforce",
    value: SALESFORCE_CREATE_ACTION.LEAD,
  },
  {
    label: "Create a contact in Salesforce",
    value: SALESFORCE_CREATE_ACTION.CONTACT,
  },
  {
    label: "Do nothing",
    value: SALESFORCE_CREATE_ACTION.DO_NOTHING,
  },
];

const INFLECTION_PERSON_DELETE_MENU_OPTIONS = [
  { label: "Do nothing", value: SALESFORCE_DELETE_ACTION.DO_NOTHING },
];

export default function InflectionToSalesforceRecord({
  inflectionEvents,
  onChangeEvents,
  syncObjectEnabled,
  isLoading = false,
  isReadOnly = false,
}: {
  inflectionEvents: InflectionEvents | null;
  onChangeEvents: (data: InflectionEvents | null) => void;
  syncObjectEnabled: SyncedObjSummary;
  isLoading?: boolean;
  isReadOnly?: boolean;
}) {
  const sfCreateOptionDisabled = (option: SALESFORCE_CREATE_ACTION) => {
    switch (option) {
      case SALESFORCE_CREATE_ACTION.LEAD:
        return !syncObjectEnabled.lead;

      case SALESFORCE_CREATE_ACTION.CONTACT:
        return !syncObjectEnabled.contact;
      default:
        return false;
    }
  };

  function onCreateEventChange(val: SALESFORCE_CREATE_ACTION) {
    if (inflectionEvents) {
      onChangeEvents({ ...inflectionEvents, onCreate: val });
    }
  }

  return (
    <Box mt="3">
      <Header
        title="Creating, updating and deleting Leads/Contacts in Salesforce"
        desc=""
      />
      <Box pt="3">
        <NoteBox
          text="The action of creation, deletion or do nothing will only take effect
            from the time this setting is updated."
          tooltipText="Example:- If setting for deletion was set to do nothing on day 1 and the setting is updated to delete on day 2,
           the action of deleting associated leads/contacts will only take place for people who are deleted in Inflection from day 2."
        />
      </Box>

      <VStack alignItems="flex-start" spacing="5" mt="2" pt="3">
        <HStack justifyContent="space-between" width="100%">
          <Text mr="1">When Inflection contact is created or updated</Text>
          <DropdownWithSearch
            options={INFLECTION_PERSON_CREATE_MENU_OPTIONS}
            value={INFLECTION_PERSON_CREATE_MENU_OPTIONS.find(
              (option) => option.value === (inflectionEvents?.onCreate ?? null)
            )}
            isOptionDisabled={(option) =>
              option && sfCreateOptionDisabled(option.value)
            }
            onChange={(option) => option && onCreateEventChange(option.value)}
            isLoading={isLoading}
            isDisabled={isReadOnly}
            controlStyle={{
              width: "300px",
            }}
          />
        </HStack>
        <HStack justifyContent="space-between" width="100%" position="relative">
          <Text mr="1">When Inflection contact is deleted</Text>
          {/* coming soon section */}
          <DropdownWithSearch
            isLoading={isLoading}
            isDisabled={true}
            options={INFLECTION_PERSON_DELETE_MENU_OPTIONS}
            value={INFLECTION_PERSON_DELETE_MENU_OPTIONS.find(
              (option) => option.value === (inflectionEvents?.onDelete ?? null)
            )}
            controlStyle={{
              width: "300px",
            }}
          />
          <Box position="absolute" right="-6">
            <ComingSoonText />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
}
