import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  VStack,
  Text,
  ButtonProps,
} from "@chakra-ui/react";
import { useState, useEffect, ReactNode, useCallback } from "react";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { isLoading, isBlank } from "../../../../../common/helper/commonHelper";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import { selectPerson } from "../../../persondb/personDbSlice";
import { IconOptionsType } from "../../../../../common/types/flow";
import { UPDATE_BY_OPTIONS, UPDATE_VALUE_LABEL } from "./constants";
import {
  EmailTokenDetails,
  UpdateValueOperation,
} from "../../../../../common/types/campaign";
import { isEmpty, isNumber } from "lodash";
import {
  FILTER_TYPE,
  INIT_UPDATE_VALUE,
  UPDATE_VALUE_OBJECT_TYPE,
  UPDATE_VALUE_TYPE,
} from "../../../../../common/constants/campaign";
import { selectEmailToken } from "../../../emailtoken/emailTokenSlice";
import { DESTINATION_TYPES } from "../../../../../common/types/unifiedMapping";
import { EditableFieldByType } from "../../../../../components/EditableFieldByType";
import { CustomIconOption, CustomIconSingleValue } from "./OptionHandler";
import { SET_VALUE } from "./constants";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";
import MemoizedCommonDrawer from "../../components/CommonDrawer";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { TokenDropdownOption } from "../../../../../components/OptionHelper";

type UpdateValueType = {
  updateField: string;
  updateBy: UPDATE_VALUE_LABEL | "";
  token: string;
  operator: string | null;
  value: string[];
};

const INIT_STATE: UpdateValueType = {
  updateField: "",
  updateBy: "",
  token: "",
  operator: null,
  value: [],
};

const INIT_ERROR_STATE = {
  updateField: "",
  updateBy: "",
  value: "",
};
const PERSON_OBJECT: IconOptionsType[] = [
  {
    label: "Person Object",
    value: "Person Object",
    icon: FaUser,
  },
];

//three labels -> two values
const MAP_LABEL_TO_VALUE = {
  [UPDATE_VALUE_LABEL.TOKEN]: UPDATE_VALUE_TYPE.TOKEN,
  [UPDATE_VALUE_LABEL.FORMULA]: UPDATE_VALUE_TYPE.FORMULA,
  [UPDATE_VALUE_LABEL.CUSTOM_VALUE]: UPDATE_VALUE_TYPE.FORMULA,
};

function FormControlLabel({
  label,
  children,
  errorMsg,
}: {
  label: string;
  children: ReactNode;
  errorMsg?: string;
}) {
  return (
    <FormControl isInvalid={!!errorMsg} px={1}>
      <FormLabel color="gray.500" fontSize="xs">
        {label}
      </FormLabel>
      {children}
      <FormErrorMessage fontSize="xs">{errorMsg}</FormErrorMessage>
    </FormControl>
  );
}

//update action
export default function UpdateActionDrawer({
  isOpen,
  onClose,
  onSave,
  actionOption = INIT_UPDATE_VALUE,
  updateFieldOptions,
  readonly,
  primaryButtonProps,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (operation: UpdateValueOperation) => void;
  actionOption?: UpdateValueOperation;
  updateFieldOptions: IconOptionsType[];
  readonly?: boolean;
  primaryButtonProps?: ButtonProps;
}) {
  const {
    personMappingDetails: {
      data: personMappingDetails,
      loading: fetchingPersonMappingDetails,
    },
  } = useSelector(selectPerson);
  const {
    emailTokenList: {
      listAll: { data: tokenList, loading: tokenListLoading },
    },
  } = useSelector(selectEmailToken);
  const {
    computeOperators: {
      data: computeOperators,
      loading: computeOperatorsLoading,
    },
  } = useSelector(selectDynamicList);

  const [errors, setErrors] = useState(INIT_ERROR_STATE);
  const [updateParams, setUpdateParams] = useState(INIT_STATE);

  const tokenOptions: EmailTokenDetails[] = tokenList.filter((token) => {
    return (
      token.return_type ===
      matchTypes(getColumnDetails(updateParams.updateField)?.type)
    );
  });

  const setLabel = useCallback(() => {
    if (actionOption.token) {
      return UPDATE_VALUE_LABEL.TOKEN;
    }
    if (actionOption.operator === SET_VALUE) {
      return UPDATE_VALUE_LABEL.CUSTOM_VALUE;
    }
    if (isEmpty(actionOption.value)) {
      return "";
    }

    return UPDATE_VALUE_LABEL.FORMULA;
  }, [actionOption.token, actionOption.operator, actionOption.value]);

  const removeErrors = useCallback(() => {
    setErrors((prev) => {
      return { ...INIT_ERROR_STATE };
    });
  }, []);

  //set inputs from data
  useEffect(() => {
    if (isOpen) {
      removeErrors();
      setUpdateParams((prev) => {
        return {
          ...prev,
          updateField: actionOption.filter,
          updateBy: setLabel(),
          token: actionOption.token ?? "",
          operator: actionOption.operator,
          value: actionOption.value,
        };
      });
    }
  }, [actionOption, setLabel, removeErrors, isOpen]);

  function validateUpdateAction() {
    if (!updateParams.updateField) {
      setErrors({ ...INIT_ERROR_STATE, updateField: "Field is required" });
      return false;
    }
    if (!updateParams.updateBy) {
      setErrors({ ...INIT_ERROR_STATE, updateBy: "Action is required" });
      return false;
    }
    if (
      updateParams.updateBy === UPDATE_VALUE_LABEL.TOKEN &&
      !updateParams.token
    ) {
      setErrors({
        ...INIT_ERROR_STATE,
        value: "Valid token is required",
      });
      return false;
    }
    if (
      updateParams.updateBy === UPDATE_VALUE_LABEL.CUSTOM_VALUE &&
      isEmpty(updateParams.value)
    ) {
      setErrors({
        ...INIT_ERROR_STATE,
        value: "Value is not valid",
      });
      return false;
    }
    if (updateParams.updateBy === UPDATE_VALUE_LABEL.FORMULA) {
      let errorMsg = "";
      const val = updateParams.value;
      if (isEmpty(val)) {
        errorMsg = "Enter a valid number";
      } else if (isNumber(val[0]) && val[0] < 1)
        errorMsg = "Minimum value is 1";

      setErrors({
        ...INIT_ERROR_STATE,
        value: errorMsg,
      });

      return !errorMsg;
    }
    removeErrors();
    return true;
  }

  function onActionSave() {
    const isValid = validateUpdateAction();
    if (isValid) {
      onSave({
        filter: updateParams.updateField,
        type: MAP_LABEL_TO_VALUE[updateParams.updateBy as UPDATE_VALUE_LABEL],
        token: updateParams.token ?? "",
        operator: updateParams.operator,
        value: updateParams.value,
        object_type: UPDATE_VALUE_OBJECT_TYPE.PERSON,
        filter_type: FILTER_TYPE.PERSON,
      });
    }
  }

  function getColumnDetails(filter: string) {
    return personMappingDetails[filter];
  }

  //get only mathematical operations
  function getOperators() {
    let operators = computeOperators
      ? Object.values(computeOperators[DESTINATION_TYPES.INTEGER]).filter(
          (operator) => operator.id !== SET_VALUE
        )
      : [];

    return operators;
  }

  function filterTypeOptions() {
    const fieldType = getColumnDetails(updateParams.updateField)?.type;
    if (
      [DESTINATION_TYPES.INTEGER, DESTINATION_TYPES.FLOAT].includes(fieldType)
    ) {
      return UPDATE_BY_OPTIONS;
    }
    return UPDATE_BY_OPTIONS.filter(
      (option) => option.label !== UPDATE_VALUE_LABEL.FORMULA
    );
  }

  //token return type and field type to filter token
  function matchTypes(type: DESTINATION_TYPES) {
    switch (type) {
      case DESTINATION_TYPES.INTEGER:
      case DESTINATION_TYPES.FLOAT:
      case DESTINATION_TYPES.LONG:
        return "number";
      default:
        return type;
    }
  }

  function handleFilterChange(filter: string) {
    removeErrors();
    setUpdateParams((prev) => {
      const filterType = getColumnDetails(filter)?.type;
      const previousFilterType = getColumnDetails(prev.updateField)?.type;
      if (filterType !== previousFilterType) {
        return {
          ...prev,
          updateField: filter,
          updateBy: "",
          token: "",
          operator: null,
          value: [],
        };
      }
      return { ...prev, updateField: filter };
    });
  }

  function handleTypeChange(label: UPDATE_VALUE_LABEL) {
    removeErrors();
    setUpdateParams((prev) => {
      return { ...prev, updateBy: label, token: "", value: [], operator: null };
    });
  }

  function handleChange(name: keyof UpdateValueType, value: any) {
    removeErrors();
    setUpdateParams((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function updateValueInputField(type: UPDATE_VALUE_LABEL) {
    const fieldType = getColumnDetails(updateParams.updateField)?.type;

    switch (type) {
      case UPDATE_VALUE_LABEL.TOKEN:
        return (
          <FormControlLabel label="Update by token" errorMsg={errors.value}>
            <DropdownWithSearch
              placeholder="Select token"
              value={
                tokenOptions.find(
                  (option) => option.display === updateParams.token
                ) ?? null
              }
              options={tokenOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.display}
              onChange={(option) =>
                handleChange("token", option?.display ?? "")
              }
              isLoading={isLoading(tokenListLoading)}
              isInvalid={!!errors.value}
              maxMenuHeight={300}
              isSearchable
              isDisabled={readonly}
              components={{ Option: TokenDropdownOption }}
            />
          </FormControlLabel>
        );

      case UPDATE_VALUE_LABEL.CUSTOM_VALUE:
        return (
          <FormControlLabel label="New value" errorMsg={errors.value}>
            <EditableFieldByType
              type={fieldType}
              onChange={(value: any) => {
                removeErrors();
                setUpdateParams((prev) => {
                  const empty = isBlank(value);
                  return {
                    ...prev,
                    value: empty ? [] : [value],
                    operator: empty ? null : SET_VALUE,
                  };
                });
              }}
              value={updateParams.value[0] ?? ""}
              isInValid={!!errors.value}
              isDisabled={readonly}
            />
          </FormControlLabel>
        );

      case UPDATE_VALUE_LABEL.FORMULA:
        return (
          <>
            <FormControlLabel
              label="Formula"
              errorMsg={
                !updateParams.operator && errors.value
                  ? "Formula is required"
                  : ""
              }
            >
              <DropdownWithSearch
                options={getOperators()}
                value={
                  getOperators().find(
                    (option) => option.id === updateParams.operator
                  ) ?? null
                }
                getOptionLabel={(option) => option.display_name}
                getOptionValue={(option) => option.id}
                onChange={(option) =>
                  handleChange("operator", option?.id ?? null)
                }
                isInvalid={!updateParams.operator && !!errors.value}
                isDisabled={readonly}
                isLoading={isLoading(computeOperatorsLoading)}
              />
            </FormControlLabel>
            {!!updateParams.operator && (
              <FormControlLabel label="Value" errorMsg={errors.value}>
                <EditableFieldByType
                  type={fieldType}
                  onChange={(value: any) =>
                    handleChange("value", isBlank(value) ? [] : [value])
                  }
                  value={updateParams.value[0] ?? ""}
                  isInValid={!!errors.value}
                  //minValue is 1 for all operators
                  minValue={1}
                  allowNegativeNumbers={false}
                  isDisabled={readonly}
                  height="32px"
                />
              </FormControlLabel>
            )}
          </>
        );
    }
  }

  function displayAction() {
    if (readonly) {
      return "View";
    } else if (actionOption.token || actionOption.operator) {
      return "Edit";
    } else {
      return "Add";
    }
  }

  function filterUpdateFieldOptions() {
    return updateFieldOptions.map((option) => {
      return {
        ...option,
        isDisabled:
          actionOption.filter === option.value ? false : option.isDisabled,
      };
    });
  }

  return (
    <MemoizedCommonDrawer
      title={`${displayAction()} update action`}
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      drawerBodyProps={{ p: 2, w: "100%" }}
    >
      <VStack
        px={2}
        h="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box w="100%">
          <Text fontSize="14px" mx={1}>
            Update values of a contact field
          </Text>

          <VStack w="100%" my={3} spacing={3}>
            <FormControlLabel label="Update values for">
              <DropdownWithSearch
                defaultValue={PERSON_OBJECT[0]}
                options={PERSON_OBJECT}
                components={{
                  Option: CustomIconOption,
                  SingleValue: CustomIconSingleValue,
                }}
                isDisabled={readonly}
              />
            </FormControlLabel>

            <FormControlLabel
              label="Update field"
              errorMsg={errors.updateField}
            >
              <DropdownWithSearch
                placeholder="Select field"
                value={updateFieldOptions.find(
                  (option) => option.value === updateParams.updateField
                )}
                options={filterUpdateFieldOptions()}
                onChange={(option) => handleFilterChange(option?.value)}
                getOptionValue={(option) => option.value}
                isOptionDisabled={(option) => !!option.isDisabled}
                isLoading={isLoading(fetchingPersonMappingDetails)}
                isSearchable={true}
                isInvalid={!!errors.updateField}
                maxMenuHeight={300}
                components={{
                  Option: CustomIconOption,
                  SingleValue: CustomIconSingleValue,
                }}
                isDisabled={readonly}
              />
            </FormControlLabel>

            {updateParams.updateField && (
              <FormControlLabel label="Update by" errorMsg={errors.updateBy}>
                <DropdownWithSearch
                  placeholder="Select action"
                  value={
                    filterTypeOptions().find(
                      (option) => option.label === updateParams.updateBy
                    ) ?? null
                  }
                  options={filterTypeOptions()}
                  getOptionValue={(option) => option.label}
                  onChange={(option) =>
                    handleTypeChange(option?.label as UPDATE_VALUE_LABEL)
                  }
                  isInvalid={!!errors.updateBy}
                  components={{
                    Option: CustomIconOption,
                    SingleValue: CustomIconSingleValue,
                  }}
                  isDisabled={readonly}
                />
              </FormControlLabel>
            )}

            {updateValueInputField(updateParams.updateBy as UPDATE_VALUE_LABEL)}
          </VStack>
        </Box>
        <HStack py={4} justifyContent="flex-end" w="100%">
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={onClose}
            hidden={readonly}
          >
            Cancel
          </IButton>

          <IButton
            children="save"
            {...primaryButtonProps}
            onClick={onActionSave}
            type="submit"
            hidden={readonly}
          />
        </HStack>
      </VStack>
    </MemoizedCommonDrawer>
  );
}
