import {
  VStack,
  HStack,
  Box,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import IButton from "../../../../components/IButton";
import { MdOutlineFileDownload } from "react-icons/md";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useAppDispatch } from "../../../../store";
import {
  listStaticListSummaryList,
  selectStaticList,
} from "../../staticlist/staticlistSlice";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { useSelector } from "react-redux";
import {
  isFulfilled,
  isIngestionTypeFlink,
  isInit,
  isLoading,
  isSuccess,
  isUploadContactsToSl,
} from "../../../../common/helper/commonHelper";
import { StatsRow, CheckboxWithLabel } from "./HelperComponents";
import {
  addStaticList,
  downloadErrorReport,
  getUploadStatus,
  importContactsFromCsv,
  selectContactUpload,
  setImportAction,
  updateOverwriteConfig,
} from "../contactUploadSlice";
import {
  UploadSummaryType,
  UPLOAD_CONTACTS_TO,
  IMPORT_ACTIONS,
} from "../../../../common/types/contactUpload";
import ConfirmImportModal from "./ConfirmImportModal";
import { isEmpty } from "lodash";
import { CreatableStaticListDropdown } from "./CreatableStaticListDropdown";
import ContactUploadStatusModal from "./ContactUploadStatusModal";
import { LOADING_STATES } from "../../../../common/constants/common";
import {
  useExponentialPolling,
  usePreventRefresh,
} from "../../../../common/hooks/commonHooks";
import {
  getUploadStatusApi,
  importContactsFromCsvApi,
} from "../../../../common/api/campaign/contactUpload";
import { PreventNavigationModal } from "../../../../components/PreventNavigationModal";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";

const IMPORT_ACTION_OPTIONS = [
  {
    label: "Create and Update Existing Contacts",
    value: IMPORT_ACTIONS.CREATE_AND_UPDATE,
  },
  { label: "Only Create New Contacts", value: IMPORT_ACTIONS.CREATE },
  { label: "Only Update Existing Contacts", value: IMPORT_ACTIONS.UPDATE },
];

function UploadStats({
  newContacts,
  totalContacts,
  errors,
  loading,
}: UploadSummaryType & { loading: boolean }) {
  return (
    <VStack
      alignItems="flex-start"
      width="100%"
      my="2"
      bg="gray.100"
      px={5}
      py={3}
      borderRadius="3px"
    >
      <Text fontSize="sm" fontWeight="bold">
        Summary
      </Text>
      <StatsRow label="Total entries" data={totalContacts} loading={loading} />
      <StatsRow label="New entries" data={newContacts} loading={loading} />
      <StatsRow
        label="Errors"
        tooltip="Faulty entries due to duplicates, missing/invalid emails, invalid list values, or data type mismatches are automatically excluded during import."
        data={errors}
        loading={loading}
        color="red.600"
      />
    </VStack>
  );
}

function DownloadReport({
  onDownload,
  isLoading,
}: {
  onDownload: () => void;
  isLoading: boolean;
}) {
  return (
    <HStack
      px={5}
      py={3}
      w="100%"
      borderRadius="3px"
      justifyContent="space-between"
      bg="brandRed.50"
    >
      <Box w="450px">
        <Text fontWeight="bold" color="red.600" mb={2} fontSize="sm">
          Download Error Report
        </Text>
        <Text fontSize="12px">
          You can find the details of the error for each row in the 'error'
          column inside file. Once errors are corrected, you can upload the same
          file.
        </Text>
      </Box>
      <IButton
        variant="outline"
        bg="white"
        minW="170px"
        color="brand.blue"
        spinnerPlacement="start"
        loadingText="Downloading"
        leftIcon={<Icon as={MdOutlineFileDownload} fontSize="18px" />}
        onClick={onDownload}
        isLoading={isLoading}
      >
        Download Report
      </IButton>
    </HStack>
  );
}
function ImportActionUpdate() {
  const {
    contactImportConfig: { overwriteConfig, importAction },
  } = useSelector(selectContactUpload);
  const dispatch = useAppDispatch();

  return (
    <>
      <Text fontSize="sm" fontWeight="semibold">
        Upload Options
      </Text>
      <Box w="300px">
        <Text fontSize="xs" color="gray.500">
          Select how to import your Contacts
        </Text>
        <DropdownWithSearch
          options={IMPORT_ACTION_OPTIONS}
          value={IMPORT_ACTION_OPTIONS.find(
            (option) => option.value === importAction
          )}
          onChange={(option) =>
            option && dispatch(setImportAction(option.value))
          }
        />
      </Box>
      {importAction !== IMPORT_ACTIONS.CREATE && (
        <VStack alignItems={"flex-start"} spacing={2}>
          <CheckboxWithLabel
            label="Update empty fields only"
            tooltip="Only update blank Contact fields in the Contact DB"
            isChecked={overwriteConfig.updateOnlyEmptyFieldsInDb}
            onChange={(e) =>
              dispatch(
                updateOverwriteConfig({
                  name: "updateOnlyEmptyFieldsInDb",
                  isChecked: e.target.checked,
                })
              )
            }
          />
          <CheckboxWithLabel
            label="Overwrite with blank values"
            tooltip="Write blank values to Contact DB for all blank values in the CSV"
            isChecked={overwriteConfig.updateDbWithBlankValueInCsv}
            onChange={(e) =>
              dispatch(
                updateOverwriteConfig({
                  name: "updateDbWithBlankValueInCsv",
                  isChecked: e.target.checked,
                })
              )
            }
          />
        </VStack>
      )}
    </>
  );
}

function AddToStaticList({
  isDisabled,
  isInvalid,
}: {
  isDisabled: boolean;
  isInvalid: boolean;
}) {
  const {
    contactImportConfig: { staticListId },
  } = useSelector(selectContactUpload);

  const {
    staticListSummaryList: { data: staticListSummary, loading: summaryLoading },
    updatingStaticList,
  } = useSelector(selectStaticList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      (isInit(summaryLoading) && staticListId !== null) ||
      isSuccess(updatingStaticList)
    ) {
      dispatch(listStaticListSummaryList());
    }
  }, [dispatch, summaryLoading, staticListId, updatingStaticList]);

  const staticListOptions = useMemo(
    () =>
      !isEmpty(staticListSummary)
        ? staticListSummary.map(({ name, static_list_id }) => {
            return { label: name, value: static_list_id };
          })
        : [],
    [staticListSummary]
  );

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      fontSize="14px"
      spacing={3}
      opacity={isDisabled ? 0.3 : 1}
      py={1}
      px={1}
    >
      <Text fontWeight="semibold">Static List Support</Text>
      <CheckboxWithLabel
        label="Add Imported contacts to a static list"
        isChecked={staticListId !== null}
        onChange={(e) => dispatch(addStaticList(e.target.checked ? "" : null))}
        isDisabled={isDisabled}
      />

      {staticListId !== null && (
        <Box my={2} w="300px">
          <Text fontSize="12px" color="gray.500" my={1}>
            Static List Name
          </Text>
          <CreatableStaticListDropdown
            staticListOptions={staticListOptions}
            listLoading={summaryLoading}
            invalidMsg={
              isInvalid && staticListId === "" ? "Empty static list" : ""
            }
          />
        </Box>
      )}
    </VStack>
  );
}

export default function ContactUploadReport({
  uploadTo,
  onCompleteImport,
  goBack,
}: {
  uploadTo: UPLOAD_CONTACTS_TO;
  onCompleteImport?: () => void;
  goBack: () => void;
}) {
  const {
    fileUpload: { uploadId },
    uploadSummary: { data: uploadSummary, loading: summaryLoading },
    downloadingErrorReport,
    contactImportCsv: { loading: loadingImport },
    contactImportConfig: { staticListId },
  } = useSelector(selectContactUpload);

  const {
    staticListDetails: { data: staticListDetails },
  } = useSelector(selectStaticList);

  const {
    contactIngestionEtl: { data: etl },
  } = useSelector(selectFeatureFlag);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenStatusModal,
    onClose: onCloseStatusModal,
    onOpen: onOpenStatusModal,
  } = useDisclosure();

  const dispatch = useAppDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isUploading, setIsUploading] = useState(LOADING_STATES.INIT);
  const [polling, setPolling] = useState(false);

  const uploadToSl = isUploadContactsToSl(uploadTo);
  const listId = uploadToSl ? staticListDetails?.static_list_id : undefined;

  const pollContactUploadStatus = useCallback(async () => {
    const {
      meta: { requestStatus },
      payload,
    } = await dispatch(getUploadStatus({ uploadId: uploadId }));

    if (!isFulfilled(requestStatus)) {
      setPolling(false);
      setIsUploading(LOADING_STATES.FAILED);
      return;
    }

    const { data, error } = payload as Awaited<
      ReturnType<typeof getUploadStatusApi>
    >;

    if (!!error) {
      setPolling(false);
      setIsUploading(LOADING_STATES.FAILED);
      return;
    }

    if (data?.status) {
      setPolling(false);
      setIsUploading(LOADING_STATES.SUCCESS);
    }
  }, [dispatch, uploadId]);

  useExponentialPolling({
    onPolling: pollContactUploadStatus,
    shouldPoll: polling,
    initialWaitTime: 0,
  });

  async function importContactsDryRun() {
    if (staticListId !== "") {
      setIsInvalid(false);
      const dryRunImport = await dispatch(
        importContactsFromCsv({ uploadId, testRun: true, listId })
      );
      if (isFulfilled(dryRunImport.meta.requestStatus)) {
        onOpen();
      }
    } else {
      setIsInvalid(true);
    }
  }

  function onDownloadReport() {
    dispatch(downloadErrorReport(uploadId));
  }

  function onCloseAfterImport() {
    onCloseStatusModal();
    setIsUploading(LOADING_STATES.INIT);
  }

  async function onConfirmImport() {
    setIsUploading(LOADING_STATES.LOADING);
    onOpenStatusModal();
    onClose();
    const runImport = await dispatch(
      importContactsFromCsv({
        uploadId,
        testRun: false,
        listId,
      })
    );
    if (!isFulfilled(runImport.meta.requestStatus)) {
      setIsUploading(LOADING_STATES.FAILED);
      return;
    }
    const { data } = runImport.payload as Awaited<
      ReturnType<typeof importContactsFromCsvApi>
    >;
    if (data) {
      isIngestionTypeFlink(etl)
        ? setPolling(true)
        : setIsUploading(LOADING_STATES.SUCCESS);
    } else {
      setIsUploading(LOADING_STATES.FAILED);
    }
  }

  // to handle reloads
  usePreventRefresh({ preventRefresh: isLoading(isUploading) });

  const showErrorReport =
    uploadSummary.errors > 0 && uploadSummary.reportAvailable;

  return (
    <VStack alignItems="flex-start" justifyContent="space-between" h="100%">
      <VStack spacing={4} alignItems="flex-start" w="100%">
        <UploadStats {...uploadSummary} loading={isLoading(summaryLoading)} />

        {showErrorReport && (
          <DownloadReport
            onDownload={onDownloadReport}
            isLoading={isLoading(downloadingErrorReport)}
          />
        )}
        <ImportActionUpdate />
        <AddToStaticList isDisabled={uploadToSl} isInvalid={isInvalid} />
      </VStack>
      <HStack w="100%" justifyContent="flex-end">
        <IButton
          variant="ghost"
          onClick={goBack}
          loadingText="loading"
          isLoading={isLoading(loadingImport)}
        >
          Back
        </IButton>

        <IButton
          onClick={importContactsDryRun}
          isLoading={isLoading(summaryLoading) || isLoading(loadingImport)}
        >
          Import
        </IButton>
      </HStack>
      <ConfirmImportModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirmImport}
        uploadTo={uploadTo}
      />
      <ContactUploadStatusModal
        onCompleteImport={onCompleteImport}
        isOpen={isOpenStatusModal}
        onClose={onCloseAfterImport}
        isUploading={isUploading}
      />
      <PreventNavigationModal
        isActive={isLoading(isUploading)}
        cancelText="Cancel"
        confirmText="Confirm"
        children={
          <Text>
            Your upload is in progress, leaving this page will discard any
            changes made.
          </Text>
        }
      />
    </VStack>
  );
}
