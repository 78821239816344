import {
  Center,
  Text,
  VStack,
  Box,
  ButtonProps,
  TextProps,
  BoxProps,
} from "@chakra-ui/react";
import IButton from "./IButton";
import { ReactNode } from "react";

export default function InitialEmptyState({
  mainText,
  message,
  ctaProps,
  additionalActions,
  children,
  mainTextProps,
  messageProps,
  containerProps,
}: {
  mainText: string;
  message?: string;
  ctaProps?: ButtonProps;
  additionalActions?: ReactNode;
  children?: ReactNode;
  mainTextProps?: TextProps;
  messageProps?: TextProps;
  containerProps?: BoxProps;
}) {
  return (
    <Center height="70vh" w="100%" {...containerProps}>
      <VStack spacing={3} alignItems="center" w="100%">
        {children}
        {message && (
          <Text
            fontSize="sm"
            color="gray.400"
            fontWeight="600"
            {...messageProps}
          >
            {message}
          </Text>
        )}
        <Text
          fontWeight="700"
          fontSize="18px"
          wordBreak="normal"
          textAlign="center"
          {...mainTextProps}
        >
          {mainText}
        </Text>

        {ctaProps && (
          <Box pt="14px">
            <IButton px="14px" h="32px" {...ctaProps} />
          </Box>
        )}

        {additionalActions}
      </VStack>
    </Center>
  );
}
