import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormSubmissionDetails,
  FORM_VENDORS,
} from "../../../../common/types/form";
import { DataTable } from "../../../../components/data-table/DataTable";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import { useAppDispatch } from "../../../../store";
import {
  getFormSubmissions,
  selectForm,
  setFormSubmissionsPage,
} from "../formSlice";
import { isLoading } from "../../../../common/helper/commonHelper";
import { SubmissionSidebar } from "./SubmissionSidebar";

export default function FormSubmissionTable({
  formId,
  vendor,
}: {
  formId: string;
  vendor: FORM_VENDORS;
}) {
  const dispatch = useAppDispatch();

  const { formSubmissionList, formDetails } = useSelector(selectForm);

  useEffect(() => {
    return function resetPage() {
      dispatch(setFormSubmissionsPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    if (formId)
      dispatch(
        getFormSubmissions({
          formId: formId,
          formType: vendor,
        })
      );
  }, [formId, vendor, dispatch, formSubmissionList.currentPageNo]);

  const columnHelper = createColumnHelper<FormSubmissionDetails>();
  const [selectedSubmission, setSelectedSubmission] =
    useState<FormSubmissionDetails | null>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor("submission_id", {
        header: "Submission id",
        minSize: 400,
      }),
      columnHelper.accessor("mapped_data", {
        cell: (info) => {
          if (info.getValue()) {
            return JSON.parse(info.getValue())?.email ?? "";
          } else {
            return "";
          }
        },
        header: "Email",
        minSize: 700,
      }),
      columnHelper.accessor("submission_date", {
        header: "Submission date",
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
        minSize: 200,
      }),
    ],
    [columnHelper]
  );

  return (
    <>
      <DataTable
        list={formSubmissionList.list}
        fetchingList={
          formSubmissionList.fetchingList || isLoading(formDetails.loading)
        }
        changingPage={formSubmissionList.changingPage}
        setPage={(pageNo: number) => dispatch(setFormSubmissionsPage(pageNo))}
        onRowClick={(data: FormSubmissionDetails) =>
          setSelectedSubmission(data)
        }
        totalPageCount={formSubmissionList.totalPageCount}
        totalPageSize={formSubmissionList.pageSize}
        currentPage={formSubmissionList.currentPageNo}
        columns={columns}
        emptyMsg="No submissions"
      />
      <SubmissionSidebar
        formId={formId}
        selectedSubmission={selectedSubmission}
        onClose={() => setSelectedSubmission(null)}
      />
    </>
  );
}
