import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { MdPostAdd } from "react-icons/md";
import { FILTER_TYPE } from "../../common/constants/campaign";
import { CAMPAIGN_CONTEXT } from "../../common/types/campaign";

const OUTER_GROUP_MENUS = [
  {
    label: "Contact properties",
    options: [
      {
        label: "A person property",
        type: FILTER_TYPE.PERSON,
      },
      {
        label: "Performed a product event",
        type: FILTER_TYPE.PRODUCT_ACTIVITY,
      },
      {
        label: "Performed a marketing activity",
        type: FILTER_TYPE.MARKETING_ACTIVITY,
      },
      {
        label: "Performed a web activity",
        type: FILTER_TYPE.WEBSITE_ACTIVITY,
      },
      {
        label: "List membership",
        type: FILTER_TYPE.LIST_MEMBERSHIP,
      },
    ],
  },
  {
    label: "Grouped properties",
    options: [
      {
        label: "Organization members",
        type: FILTER_TYPE.ORG_MEMBERSHIP,
      },
      {
        label: "Account members",
        type: FILTER_TYPE.ACCOUNT_MEMBERSHIP,
      },
    ],
  },
];

export default function AddOuterGroupButton({
  onClick,
  campaignContext,
}: {
  onClick: (type: FILTER_TYPE) => void;
  campaignContext?: CAMPAIGN_CONTEXT;
}) {
  const filteredOuterGroupMenus = useMemo(
    () =>
      cloneDeep(OUTER_GROUP_MENUS).map((menu) => {
        menu.options = menu.options.filter((option) => {
          if (option.type === FILTER_TYPE.ORG_MEMBERSHIP) {
            return campaignContext === CAMPAIGN_CONTEXT.ORG;
          } else if (option.type === FILTER_TYPE.ACCOUNT_MEMBERSHIP) {
            return campaignContext === CAMPAIGN_CONTEXT.PERSON;
          } else {
            return true;
          }
        });
        return menu;
      }),
    [campaignContext]
  );

  return (
    <Menu>
      <MenuButton
        size={"sm"}
        as={Button}
        bg="white"
        color="brand.blue"
        borderColor="grayV2.400"
        borderWidth="1px"
        _hover={{ bg: "grayV2.200" }}
        _active={{ bg: "grayV2.200" }}
        leftIcon={<MdPostAdd color="brand.blue" fontSize="14px" />}
      >
        Add condition
      </MenuButton>
      <MenuList>
        {Object.values(filteredOuterGroupMenus).map((menu, index) => {
          return (
            !!menu.options.length && (
              <MenuOptionGroup title={menu.label} key={index}>
                {menu.options.map((option, key) => {
                  return (
                    <MenuItem
                      fontSize="14px"
                      onClick={() => onClick(option.type)}
                      name={`add-${option.type}`}
                      key={key}
                    >
                      {option.label}
                    </MenuItem>
                  );
                })}
              </MenuOptionGroup>
            )
          );
        })}
      </MenuList>
    </Menu>
  );
}
