import { VStack, Box, Flex } from "@chakra-ui/react";
import { isEmpty, cloneDeep } from "lodash";
import { createContext, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { FILTER_TYPE } from "../../../../common/constants/campaign";
import {
  EVENT_CATEGORY,
  LOGICAL_OPERATORS,
  NODE_GROUP_TYPES,
  TRIGGER_CAMPAIGN_DL_OPERATORS,
  TRIGGER_DL_NODE_TYPES,
} from "../../../../common/constants/dynamicList";
import { isInit, createGroupId } from "../../../../common/helper/commonHelper";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import {
  ComparisonNode,
  NodeGroup,
  OperatorListV2,
} from "../../../../common/types/dynamicList";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import {
  selectDynamicList,
  getEventKeys,
  getEventNames,
  getFilterList,
  getMarketingActivities,
} from "../../../../components/dynamic-list/dynamicListSlice";
import { useAppDispatch } from "../../../../store";
import { filterOperatorsArgCount } from "../helper/validationHelper";
import LogicGate from "./components/LogicGate";
import NodeGroupCriteria from "./nodes/NodeGroupCriteria";
import { getLogicalOperator } from "./helper";
import {
  fillFirstNodeData,
  isGroupNode,
} from "../../../../common/helper/trigger";
import AudienceCriteriaSideBar from "./components/AudienceCriteriaSidebar";
import AddNodeGroupButton from "./components/AddNodeGroupButton";

export const AudienceCriteriaContext = createContext({
  activeErrorCheck: false,
  operators: TRIGGER_CAMPAIGN_DL_OPERATORS,
  operatorArgCount: filterOperatorsArgCount(TRIGGER_CAMPAIGN_DL_OPERATORS),
  campaignContext: CAMPAIGN_CONTEXT.PERSON,
});

export default function AudienceCriteria({
  audienceCriteriaData,
  onChange,
  operators = TRIGGER_CAMPAIGN_DL_OPERATORS,
  sidebar = true,
  activeErrorCheck = false,
  readOnlyMode = false,
  campaignContext = CAMPAIGN_CONTEXT.PERSON,
}: {
  audienceCriteriaData: NodeGroup[];
  onChange: (data: NodeGroup[]) => void;
  operators?: OperatorListV2;
  sidebar?: boolean;
  activeErrorCheck?: boolean;
  readOnlyMode?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
}) {
  const dispatch = useAppDispatch();

  const hasOnceRun = useRef(false);

  const {
    productEventKeysList,
    productEventNamesList,
    filterList,
    filterListLoading,
  } = useSelector(selectDynamicList);

  useEffect(() => {
    if (audienceCriteriaData && !hasOnceRun.current) {
      // Fetch product_event_properties list for all product_event_names in the campaign dynamiclist get
      // create an array of event names in the product activity filter nodeGroup
      const eventNamesArray: string[][] = audienceCriteriaData.map(
        (nodeGroup) => {
          const eventNames: string[] = nodeGroup.nodes.map((nodeData) => {
            if (nodeData.nodeType === TRIGGER_DL_NODE_TYPES.COMPARISON) {
              return (nodeData as ComparisonNode).eventName ?? "";
            }
            return "";
          });
          return eventNames.filter((x) => x);
        }
      );

      const eventKeysAlreadyFetched = Object.keys(
        productEventKeysList.data || {}
      );
      const eventKeysToBeFetched = eventNamesArray
        .flat()
        .filter((event) => !eventKeysAlreadyFetched.includes(event));

      if (!isEmpty(eventKeysToBeFetched)) {
        dispatch(
          getEventKeys({
            max_result: 1000,
            entity: FILTER_TYPE.PERSON,
            event_names: eventKeysToBeFetched,
          })
        );
      }
      hasOnceRun.current = true;
    }
  }, [audienceCriteriaData, dispatch, productEventKeysList.data]);

  useEffect(() => {
    if (!productEventNamesList.data && isInit(productEventNamesList.loading)) {
      const data = {
        max_result: 10000,
        entity: FILTER_TYPE.PERSON,
      };
      dispatch(getEventNames(data));
    }
  }, [dispatch, productEventNamesList]);

  useEffect(() => {
    const personFilterList = filterList[campaignContext]?.[FILTER_TYPE.PERSON];
    const productActivitiesFilterList =
      filterList[campaignContext]?.[FILTER_TYPE.PRODUCT_ACTIVITY];
    if (!personFilterList || isInit(personFilterList.loading)) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PERSON,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
    if (
      !productActivitiesFilterList ||
      isInit(productActivitiesFilterList.loading)
    ) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PRODUCT_ACTIVITY,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
  }, [dispatch, filterList, campaignContext]);

  useEffect(() => {
    dispatch(getMarketingActivities());
  }, [dispatch]);

  function onAddNodeGroup(
    nodeGroupType: NODE_GROUP_TYPES,
    nodeType?: TRIGGER_DL_NODE_TYPES,
    eventCategory?: EVENT_CATEGORY
  ) {
    const dataCopy = cloneDeep(audienceCriteriaData);
    const NEW_NODES = nodeType
      ? [fillFirstNodeData(nodeType, eventCategory)]
      : [];

    const INIT_NODE_GROUP = {
      nodes: NEW_NODES,
      nodeGroupType,
      logicalOperator: getLogicalOperator(audienceCriteriaData.length),
    };

    const newNodeGroup = isGroupNode(nodeGroupType)
      ? {
          ...INIT_NODE_GROUP,
          groupOperator: null,
        }
      : INIT_NODE_GROUP;

    dataCopy.push(newNodeGroup);

    onChange(dataCopy);
  }

  function onRemoveNodeGroup(index: number) {
    let dataCopy = cloneDeep(audienceCriteriaData);
    dataCopy = dataCopy.filter((_, i) => i !== index);
    if (dataCopy.length) {
      dataCopy[0].logicalOperator = LOGICAL_OPERATORS.FIRST;
    }
    onChange(dataCopy);
  }

  function setLogicalOperator(op: LOGICAL_OPERATORS, index: number) {
    const dataCopy = cloneDeep(audienceCriteriaData);
    dataCopy[index].logicalOperator = op;
    onChange(dataCopy);
  }

  function onNodeGroupChange(index: number, newData: NodeGroup) {
    const dataCopy = cloneDeep(audienceCriteriaData);
    dataCopy[index] = newData;
    onChange(dataCopy);
  }
  const operatorArgCount = useMemo(
    () => filterOperatorsArgCount(operators),
    [operators]
  );

  return (
    <>
      {readOnlyMode ? (
        <VStack alignItems="flex-start" width="100%">
          <ISkeleton
            variant={SKELETON_VARIANT.TEXT}
            isLoaded={!filterListLoading}
          >
            {audienceCriteriaData.length ? (
              <>
                {audienceCriteriaData.map((nodeGroup, index) => {
                  return (
                    <Box key={index} w="100%" mt="0px !important">
                      <LogicGate
                        operator={nodeGroup.logicalOperator}
                        handleChange={(op) => {
                          setLogicalOperator(op, index);
                        }}
                        isReadOnly={readOnlyMode}
                      />

                      <AudienceCriteriaContext.Provider
                        value={{
                          operators,
                          operatorArgCount,
                          activeErrorCheck: activeErrorCheck,
                          campaignContext,
                        }}
                      >
                        <NodeGroupCriteria
                          label={`Outer Group ${index + 1}`}
                          id={createGroupId("", "outer", index + 1)}
                          nodeGroupData={nodeGroup}
                          onRemove={() => onRemoveNodeGroup(index)}
                          onChange={(data) => onNodeGroupChange(index, data)}
                          isReadOnly={readOnlyMode}
                          campaignContext={campaignContext}
                        />
                      </AudienceCriteriaContext.Provider>
                    </Box>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </ISkeleton>
        </VStack>
      ) : (
        <SpinnerContainer loading={!!filterListLoading}>
          <Flex
            width="100%"
            flexDir={{ base: "column", md: "row" }}
            height="100%"
          >
            {sidebar && (
              <AudienceCriteriaSideBar
                nodeGroups={audienceCriteriaData}
                campaignContext={campaignContext}
              />
            )}
            <VStack
              spacing="3"
              alignItems="flex-start"
              width="100%"
              overflowY="auto"
              pr={0}
              pl={sidebar ? 3 : 0}
              fontSize="16px"
              css={{ scrollBehavior: "smooth" }}
            >
              {audienceCriteriaData.length ? (
                <>
                  {audienceCriteriaData.map((nodeGroup, index) => {
                    return (
                      <Box key={index} w="100%">
                        <LogicGate
                          operator={nodeGroup.logicalOperator}
                          handleChange={(op) => {
                            setLogicalOperator(op, index);
                          }}
                        />

                        <AudienceCriteriaContext.Provider
                          value={{
                            operators,
                            operatorArgCount,
                            activeErrorCheck,
                            campaignContext,
                          }}
                        >
                          <NodeGroupCriteria
                            label={`Outer Group ${index + 1}`}
                            id={createGroupId("", "outer", index + 1)}
                            nodeGroupData={nodeGroup}
                            onRemove={() => onRemoveNodeGroup(index)}
                            onChange={(data) => onNodeGroupChange(index, data)}
                            campaignContext={campaignContext}
                          />
                        </AudienceCriteriaContext.Provider>
                      </Box>
                    );
                  })}
                  <Box pt="5">
                    <AddNodeGroupButton
                      onAddNodeGroup={onAddNodeGroup}
                      campaignContext={campaignContext}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <AddNodeGroupButton
                    onAddNodeGroup={onAddNodeGroup}
                    campaignContext={campaignContext}
                  />
                </>
              )}
            </VStack>
          </Flex>
        </SpinnerContainer>
      )}
    </>
  );
}
