import { cloneDeep, isEmpty } from "lodash";
import { CURRENT_PATH, PREV_PATH } from "../constants";
import { CAMPAIGN_STATUS } from "../constants/campaign";
import { FILTER_DISPLAY_HEIGHT } from "../constants/common";
import { ListFilterItem, ListFilterValueType } from "../types/common";
import { FORM_STATUS } from "../types/form";
import { STATUS } from "../types/unifiedMapping";

export function transformJourneyStatusFilter(filter?: ListFilterItem) {
  const statusField = "campaign_statuses";
  const statusFieldMapping = {
    [CAMPAIGN_STATUS.ACTIVE]: {
      active: true,
      draft: false,
    },
    [CAMPAIGN_STATUS.DRAFT]: {
      active: false,
      draft: true,
    },
    [CAMPAIGN_STATUS.INACTIVE]: {
      active: false,
      draft: false,
    },
  };
  if (filter && statusField in filter) {
    const statusFilters = filter[statusField] as CAMPAIGN_STATUS[];
    const mappedFilters = statusFilters.map(
      (status) => statusFieldMapping[status]
    );
    return {
      ...filter,
      [statusField]: mappedFilters,
    };
  }
  return filter;
}

export function transformFormStatusFilter(filter?: ListFilterItem) {
  const statusField = "mapping_statuses";
  const vendorStatusField = "vendor_statuses";
  const mappedFilters = cloneDeep(filter);
  if (mappedFilters && statusField in mappedFilters) {
    const statuses = mappedFilters[statusField] as ListFilterValueType[];
    const index = statuses.indexOf(FORM_STATUS.DISCONNECTED);
    const vendorStatuses: ListFilterValueType[] = [];
    if (index > -1) {
      statuses.splice(index, 1);
      vendorStatuses.push(STATUS.DISABLED);
    }
    if (!statuses.length) {
      delete mappedFilters[statusField];
    } else {
      vendorStatuses.push(STATUS.ACTIVE);
    }
    mappedFilters[vendorStatusField] = vendorStatuses;
  }
  return mappedFilters;
}

export function getHeightOfFilter(filters?: ListFilterItem) {
  return !isEmpty(filters) ? FILTER_DISPLAY_HEIGHT : undefined;
}

export function setNewPathInStorage(newPath: string) {
  const currentUrl = localStorage.getItem(CURRENT_PATH);
  localStorage.setItem(PREV_PATH, currentUrl ?? "");
  localStorage.setItem(CURRENT_PATH, newPath);
}
