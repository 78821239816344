import { useEffect, ReactNode, RefObject } from "react";
import {
  Box,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import PasswordValidationContent from "./PasswordValidationContent";

export default function PasswordValidationPopover({
  password,
  children,
  isFocussed,
  initialFocusRef,
}: {
  password: string;
  children: ReactNode;
  isFocussed: boolean;
  initialFocusRef: RefObject<HTMLElement>;
}) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  useEffect(() => {
    if (isFocussed) {
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocussed]);

  return (
    <Popover
      placement="right"
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        width="270px"
        marginLeft={7}
        rounded="6px"
        boxShadow="0px 4px 24px 0px rgba(159, 159, 159, 0.15)"
      >
        <PopoverBody>
          <Box p={3}>
            <PasswordValidationContent
              value={password}
              height="140px"
              heading="Your Password must contain:"
              isFocussed={isFocussed}
              onClose={onClose}
              onOpen={onOpen}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
