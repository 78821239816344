import { useDisclosure } from "@chakra-ui/react";
import { cloneDeep, snakeCase } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SYNC_NONE } from "../../../../../../common/constants/unifiedMapping";
import { isLoading } from "../../../../../../common/helper/commonHelper";
import { addTempIds } from "../../../../../../common/helper/unifiedMappingHelper";
import {
  ConnectionListItem,
  DESTINATION_TYPES,
  MappingElement,
  Source,
  SOURCES,
} from "../../../../../../common/types/unifiedMapping";
import { useAppDispatch } from "../../../../../../store";
import {
  selectConnection,
  updateUnifiedMappingCrm,
} from "../../../connectionSlice";
import { AddNewFieldModal } from "../../../../../../components/unified-mapping/AddNewFieldModal";
import { PersonTab } from "./components/PersonTab/PersonTab";
import ContentContainer from "../../../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { SalesforceConnectionsSummary } from "../../../../../../common/types/salesforce";
import { EMAIL_FIELD_KEY } from "../../../../../../common/constants/form";

export default function CrmDataTab({
  crmSources,
  salesforceConnections,
}: {
  crmSources: ConnectionListItem[];
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    unifiedConnection: { mapping, updatingMapping, automapping },
  } = useSelector(selectConnection);

  const dispatch = useAppDispatch();

  const [rows, setRows] = useState(
    addTempIds(mapping?.data?.person_mapping?.mapping) ?? []
  );

  useEffect(() => {
    setRows(addTempIds(mapping?.data?.person_mapping?.mapping) ?? []);
  }, [mapping]);

  function addField(data: { field: string; type: DESTINATION_TYPES }) {
    const rowsCopy = cloneDeep(rows);
    rowsCopy.push({
      tempId: Math.random(),
      source: null,
      destination: {
        type: data.type,
        custom: true,
        hidden: false,
        read_only: false,
        length: null,
        updatable: true,
        required: false,
        primary_key: false,
        name: snakeCase(data.field),
        display: data.field,
      },
    });
    setRows(rowsCopy);
    onClose();
  }

  function removeRow(name: string) {
    const newRows = cloneDeep(rows);
    const index = rows.findIndex((row) => row.destination.name === name);
    newRows.splice(index, 1);
    setRows(newRows);
  }

  function onChangeData(data: MappingElement[]) {
    setRows([...data]);
  }

  function onCancel() {
    setRows(addTempIds(mapping?.data?.person_mapping?.mapping) ?? []);
  }

  function makeSfConnectionAsFirstSource(sources: Source[] | null) {
    //if we have more than one source mapping , make sure salesforce is first in the array
    //only for email field
    if (sources?.length || 0 > 1) {
      const salesforceIndex =
        sources?.findIndex((src) => src.type === SOURCES.SALESFORCE) ?? -1;
      if (salesforceIndex > 0) {
        const salesforceSource = sources?.splice(salesforceIndex, 1);
        if (salesforceSource?.[0]) sources?.splice(0, 0, salesforceSource[0]);
      }
    }
  }

  function removeTempIds(rows: MappingElement[]) {
    const clonedRows = cloneDeep(rows);
    return clonedRows.map((x) => {
      // set None sources as null
      x.source =
        x.source?.map((y) => {
          if (y.field.contact?.name === SYNC_NONE) {
            y.field.contact = null;
          }
          if (y.field.lead?.name === SYNC_NONE) {
            y.field.lead = null;
          }
          return y;
        }) ?? null;

      // set source as null if leads and contacts are null
      x.source = x.source
        ? x.source.filter((y) => y.field.contact || y.field.lead)
        : null;
      if (x.destination.name === EMAIL_FIELD_KEY) {
        makeSfConnectionAsFirstSource(x.source);
      }
      delete x.tempId;
      return x;
    });
  }

  function savePersonTabData() {
    const formattedData = removeTempIds(rows);
    dispatch(updateUnifiedMappingCrm(formattedData));
  }

  return (
    <ContentContainerWithHeader>
      <ContentContainer my="15px" bg="unset" p="unset">
        <PersonTab
          automapping={isLoading(automapping)}
          updating={isLoading(updatingMapping)}
          loading={isLoading(mapping.loading)}
          removeRow={(name: string) => removeRow(name)}
          data={rows}
          openAddModal={onOpen}
          onChange={(data) => onChangeData(data)}
          onSave={() => savePersonTabData()}
          onCancel={() => onCancel()}
          crmSources={crmSources}
          salesforceConnections={salesforceConnections}
        ></PersonTab>

        <AddNewFieldModal
          save={(data) => addField(data)}
          isOpen={isOpen}
          onCancel={onClose}
        />
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
