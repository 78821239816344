import {
  Flex,
  IconButton,
  Icon,
  Heading,
  HStack,
  Container,
} from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/tabs";
import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Connection } from "../../../../common/types/connection";
import urls from "../../../../urls";
import {
  clearConnectionDetailsData,
  listConnection,
  selectConnection,
} from "../connectionSlice";
import { selectConstants } from "../../../../common/slices/constantsSlice";
import SyncDetails from "./components/SyncDetails";
import { SyncHistory } from "./components/SyncHistory";
import ActivityLog from "./components/ActivityLog";
import ConnectionDetailsTab from "./components/ConnectionDetailsTab";

function Header({ title }: { title: string }) {
  const navigate = useNavigate();
  return (
    <Flex height="56px">
      <HStack mr="10">
        <IconButton
          size="md"
          aria-label="Go back"
          variant="ghost"
          name="return-button"
          onClick={() => navigate(urls.unifiedMapping)}
          icon={<Icon fontSize="20px" as={FaArrowLeft} />}
          mx={4}
        />
        <Heading fontSize="xl" mr={10}>
          {title}
        </Heading>
      </HStack>
      <HStack alignItems="flex-end">
        <TabList>
          <Tab pb="10px">Sync history</Tab>
          <Tab pb="10px">Connection details</Tab>
          <Tab pb="10px">Activity log</Tab>
        </TabList>
      </HStack>
    </Flex>
  );
}

export default function ConnectionDetails() {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();
  const [tabIndex, setTabIndex] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState<Connection | null>(
    null
  );
  const { connectionList, updatingConnection } = useSelector(selectConnection);
  const {
    authedConstants: { warehouse_types },
  } = useSelector(selectConstants);

  useEffect(() => {
    if (!connectionList) {
      dispatch(listConnection());
    } else if (connectionList && connectionList.length) {
      const currentConn = connectionList.find((conn) => conn.id === id);
      setConnectionDetails(currentConn || null);
    }
  }, [dispatch, connectionList, id]);

  useEffect(() => {
    return () => {
      dispatch(clearConnectionDetailsData());
    };
  }, [dispatch]);

  function refreshAction() {
    setRefresh(!refresh);
  }

  return (
    <Tabs
      size="md"
      variant="line"
      index={tabIndex}
      onChange={(index) => setTabIndex(index)}
    >
      <Header title={connectionDetails?.label || ""} />
      <TabPanels>
        <TabPanel>
          <Container maxW="container.lg" py={5}>
            <SyncDetails
              connectionId={id ?? null}
              refresh={refresh}
              refreshAction={refreshAction}
            />
            <SyncHistory connectionId={id ?? null} refresh={refresh} />
          </Container>
        </TabPanel>
        <TabPanel>
          {connectionDetails && warehouse_types && (
            <ConnectionDetailsTab
              updatingConnection={updatingConnection}
              connectionDetails={connectionDetails}
              serviceDetails={warehouse_types[connectionDetails.typ]}
            />
          )}
        </TabPanel>
        <TabPanel>
          {connectionDetails && (
            <ActivityLog
              connectionId={connectionDetails.id}
              refresh={refresh}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
