import {
  ApiKey,
  ApiKeyData,
  Auth,
  AuthResp,
  InvitedUser,
  Organisation,
  RegisterResp,
  Roles,
  SamlConfigReq,
  SamlConfigResp,
  UserInviteType,
} from "../../types/auth";
import {
  CreatePasswordReq,
  LoginParams,
  Token,
  TokenWithType,
  RegisterParams,
} from "../../types/common";
import { authRequest } from "./api";
import { PaginatedList } from "../../types/common";

export async function loginApi(
  data: LoginParams,
  isErrorHandled: boolean = false
): Promise<AuthResp> {
  return await authRequest({
    url: "/accounts/login",
    data,
    isErrorHandled,
    isAuthenticated: false,
  });
}

export async function loginSsoApi(code: string) {
  return await authRequest({
    url: "/saml/sso2.login",
    data: { code },
    isAuthenticated: false,
  });
}

export async function validateTokenApi(): Promise<AuthResp> {
  return await authRequest({
    url: "/accounts/token.verify",
    data: {
      role_names: [],
    },
    isErrorHandled: true,
    shouldRetryApiCall: true,
  });
}

export async function registerApi(data: RegisterParams): Promise<RegisterResp> {
  return await authRequest({
    url: "/accounts/register",
    data,
    isAuthenticated: false,
  });
}

export async function checkEmailTokenIsValidApi(
  data: Token
): Promise<{ success: boolean }> {
  return await authRequest({
    url: "/accounts/verify",
    data,
    isAuthenticated: false,
  });
}

export async function listUserAccountsApi(
  pageSize: number,
  pageNo: number
): Promise<PaginatedList<Auth>> {
  return await authRequest({
    url: "/accounts/list",
    data: { page_size: pageSize, page_number: pageNo },
    shouldRetryApiCall: true,
  });
}

export async function lookupApi(
  email: string
): Promise<{ auth_type: string; redirect_url?: string }> {
  return await authRequest({
    url: "/accounts/lookup",
    data: { email },
    isAuthenticated: false,
  });
}

export async function getSamlConfigApi(): Promise<{ config: SamlConfigResp }> {
  return await authRequest({
    url: "/saml/config.get",
    isErrorHandled: true,
    shouldRetryApiCall: true,
  });
}

export async function updateSamlConfigApi(
  config: SamlConfigReq
): Promise<{ config: SamlConfigResp }> {
  return await authRequest({
    url: "/saml/config.update",
    data: config,
  });
}

export async function createSamlConfigApi(
  config: SamlConfigReq
): Promise<{ config: SamlConfigResp }> {
  return await authRequest({
    url: "/saml/config.create",
    data: config,
  });
}

export async function checkPasswordTokenIsValidApi(
  data: TokenWithType
): Promise<{ password: boolean }> {
  return await authRequest({
    url: "/accounts/password/token.valid",
    data,
    isAuthenticated: false,
  });
}

export async function createPasswordApi(
  data: CreatePasswordReq
): Promise<{ password: boolean }> {
  return await authRequest({
    url: "/accounts/password.set",
    data,
    isAuthenticated: false,
  });
}

export async function resetPasswordApi(
  data: CreatePasswordReq
): Promise<{ password: boolean }> {
  return await authRequest({
    url: "/accounts/password/email.reset",
    data,
    isAuthenticated: false,
  });
}

export async function forgotPasswordEmailApi(
  email: string,
  isErrorHandled: boolean = false
): Promise<{ success: boolean }> {
  return await authRequest({
    url: "/accounts/password/send.reset",
    data: { email },
    isAuthenticated: false,
    isErrorHandled,
  });
}

export async function getOrgListApi(): Promise<{
  organisations: Organisation[];
}> {
  return await authRequest({
    url: "/internal/organisation.list",
  });
}

export async function getAccountListApi(
  organisation_id: number
): Promise<PaginatedList<Auth>> {
  return await authRequest({
    url: "/internal/account.list",
    data: { organisation_id, page_size: 1000, page_number: 1 },
  });
}

export async function impersonateApi(data: {
  organisation_id: number;
  account_id: number;
}): Promise<AuthResp> {
  return await authRequest({
    url: "/internal/account.impersonate",
    data,
  });
}

//User Management apis

export async function inviteUserApi(users: UserInviteType[]): Promise<{
  requests: InvitedUser[];
  unsuccessful: UserInviteType[];
}> {
  return await authRequest({
    url: "/accounts/create.users",
    data: { users: users },
  });
}

export async function deleteInviteApi(
  account_id: number
): Promise<{ success: boolean }> {
  return await authRequest({
    url: "/accounts/delete.invite",
    data: { account_id },
  });
}

export async function resendInviteApi(data: {
  account_id: number;
  name: string;
  email: string;
  role_names: Roles[];
}): Promise<{ request: InvitedUser }> {
  return await authRequest({
    url: "/accounts/resend.invite",
    data,
  });
}

export async function updateUserApi(data: {
  account_id: number;
  name?: string;
  role_names?: Roles[];
}): Promise<{ user: Auth }> {
  return await authRequest({
    url: "/accounts/update",
    data,
  });
}

export async function listApiKeysApi(): Promise<{
  api_keys: ApiKey[];
}> {
  return await authRequest({
    url: "/api-key/list",
    data: null,
  });
}

export async function createApiKeyApi(
  label: string,
  keyType: string
): Promise<{ api_key: ApiKeyData }> {
  return await authRequest({
    url: "/api-key/create",
    data: { label, key_type: keyType },
  });
}

export async function deactivateApiKeyApi(
  keyId: string
): Promise<{ api_key: ApiKey }> {
  return await authRequest({
    url: "/api-key/deactivate",
    data: {
      key_id: keyId,
    },
  });
}

export async function logoutUserApi(): Promise<{ success: boolean }> {
  return await authRequest({
    url: "/accounts/logout",
    data: null,
  });
}

export async function deactivateUserApi(
  accountId: number
): Promise<{ success: Auth }> {
  return await authRequest({
    url: "/accounts/deactivate.user",
    data: { account_id: accountId },
  });
}
