import { Box, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function SideBar({
  options,
  selected,
  select,
}: {
  options: (ReactNode | string)[];
  selected: number;
  select: (i: number) => void;
}) {
  return (
    <VStack justifyContent="left" w="100%">
      {options.map((label, index) => {
        const isSelected = selected === index;
        return (
          <Box
            key={index}
            fontSize="sm"
            _hover={!isSelected ? { bg: "blackAlpha.100" } : {}}
            p={2}
            w="100%"
            cursor="pointer"
            backgroundColor={isSelected ? "brandV2.gray" : ""}
            color={isSelected ? "brandBlue.500" : "text.200"}
            rounded="md"
            onClick={() => select(index)}
          >
            {label}
          </Box>
        );
      })}
    </VStack>
  );
}
