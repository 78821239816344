import { Flex, Heading, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { SEGMENT_SYNC_TYPES } from "../../../../../common/types/connection";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import IButton from "../../../../../components/IButton";
import InputFieldWithError from "../../../../../components/InputFieldWithError";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import {
  ApiKeyConfirmationModal,
  SegmentApiKeyModal,
} from "./SegmentRefreshApiKeyModals";
import { useAppDispatch } from "../../../../../store";
import {
  createSegmentReadApiKey,
  resetSegmentSyncData,
  selectConnection,
  setSegmentConnectionType,
  setSegmentDestinationKey,
} from "../../connectionSlice";
import { FaSyncAlt } from "react-icons/fa";

const SYNC_OPTIONS = [
  {
    label: "Source",
    value: SEGMENT_SYNC_TYPES.SOURCE,
  },
  {
    label: "Source and Destination",
    value: SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION,
  },
];

function LabelWithChildren({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) {
  return (
    <Stack spacing={1} my={3}>
      <Text color="blue.800" fontSize="12px">
        {label}
      </Text>
      {children}
    </Stack>
  );
}

export default function SegmentDetails({
  isReadOnly = true,
}: {
  isReadOnly: boolean;
}) {
  const {
    segment: {
      syncDetails: {
        data: {
          connection_type: connectionType,
          destination_sync_settings: srcDestSync,
        },
        loading: segmentDetailsLoading,
      },
      createSync: { readApiKey, type: syncType, writeApiKey },
    },
  } = useSelector(selectConnection);

  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenConfirmationModal,
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isOpenApiKeyModal,
    onOpen: onOpenApiKeyModal,
    onClose: onCloseApiKeyModal,
  } = useDisclosure();

  useEffect(() => {
    if (!isReadOnly) {
      dispatch(setSegmentConnectionType(connectionType));
      dispatch(setSegmentDestinationKey(srcDestSync?.write_key ?? ""));
    } else {
      dispatch(resetSegmentSyncData());
    }
  }, [isReadOnly, connectionType, srcDestSync?.write_key, dispatch]);

  function onRefreshApiKey() {
    onCloseConfirmationModal();
    onOpenApiKeyModal();
    dispatch(createSegmentReadApiKey());
  }

  return (
    <SpinnerContainer loading={isLoading(segmentDetailsLoading)}>
      <Heading fontSize="16px">General Details</Heading>
      <Flex direction="column" my={4} width="200px">
        <LabelWithChildren label="Connection Name">
          {connectionType && <Text fontSize="14px">Segment</Text>}
        </LabelWithChildren>
        <LabelWithChildren label="Segment Sync Type">
          {isReadOnly ? (
            <Text fontSize="14px">
              {
                SYNC_OPTIONS.find((option) => option.value === connectionType)
                  ?.label
              }
            </Text>
          ) : (
            <DropdownWithSearch
              options={SYNC_OPTIONS}
              value={SYNC_OPTIONS.find((option) => option.value === syncType)}
              onChange={(option) =>
                dispatch(
                  setSegmentConnectionType(
                    option?.value ?? SEGMENT_SYNC_TYPES.SOURCE
                  )
                )
              }
              controlStyle={{ width: "300px" }}
            />
          )}
        </LabelWithChildren>
      </Flex>
      <Heading fontSize="16px">API Keys</Heading>
      <Flex direction="column" my={4} width="250px">
        <LabelWithChildren label="Segment as Source Key">
          <IButton
            w="120px"
            variant="link"
            onClick={onOpenConfirmationModal}
            color="brand.blue"
            fontWeight="normal"
            fontSize="14px"
            isLoading={isLoading(readApiKey.loading)}
            leftIcon={<FaSyncAlt size="12px" />}
          >
            Refresh Api Key
          </IButton>
        </LabelWithChildren>
        {[syncType, connectionType].includes(
          SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
        ) &&
          syncType !== SEGMENT_SYNC_TYPES.SOURCE && (
            <LabelWithChildren label="Segment as Destination Key">
              {isReadOnly && srcDestSync?.write_key ? (
                <Text fontSize="14px">
                  {srcDestSync?.write_key.slice(0, 15) + "-xxx-xx-xxx"}
                </Text>
              ) : (
                <>
                  <Text fontSize="12px" py={2}>
                    <Text as="span" fontWeight="bold" pr={1}>
                      Note:
                    </Text>
                    Inflection requires API to start sending data. You can add
                    Inflection as a source and generate an API key in Segment.
                  </Text>
                  <InputFieldWithError
                    as="textarea"
                    width="300px"
                    height="75px"
                    maxH="200px"
                    p={1}
                    value={writeApiKey}
                    onChange={(e) => {
                      dispatch(setSegmentDestinationKey(e.target.value));
                    }}
                  />
                </>
              )}
            </LabelWithChildren>
          )}
      </Flex>
      <ApiKeyConfirmationModal
        isOpen={isOpenConfirmationModal}
        onCancel={onCloseConfirmationModal}
        onProceed={onRefreshApiKey}
      />
      <SegmentApiKeyModal
        isOpen={isOpenApiKeyModal}
        onCancel={onCloseApiKeyModal}
        onConfirm={onCloseApiKeyModal}
        isLoading={isLoading(readApiKey.loading)}
      />
    </SpinnerContainer>
  );
}
