import {
  Heading,
  VStack,
  Text,
  TextProps,
  Box,
  BoxProps,
  StackProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

export default function SubHeader({
  title,
  desc,
  boxProps,
  titleProps,
  descriptionProps,
  children,
  stackProps,
}: {
  title: string | ReactNode;
  desc: string;
  boxProps?: BoxProps;
  titleProps?: TextProps;
  descriptionProps?: TextProps;
  children?: ReactNode;
  stackProps?: StackProps;
}) {
  return (
    <Box {...boxProps}>
      <Heading as="h1" pb="2" fontSize="16px" {...titleProps}>
        {title}
      </Heading>
      <Text fontSize="14px" {...descriptionProps}>
        {desc}
      </Text>
      <VStack width="full" spacing="4" mt={4} {...stackProps}>
        {children}
      </VStack>
    </Box>
  );
}
