import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  MenuOptionGroup,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DynamicListType,
  StaticListContactsData,
} from "../../../common/types/campaign";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import IButton from "../../../components/IButton";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import { DisplayCount } from "../../../components/NumberFormatHeader";
import { staticListContactsTableHeader } from "./components/tableDefinitions";
import ContactUploaderCsv from "../contactUpload/ContactUploaderCsv";
import { UPLOAD_CONTACTS_TO } from "../../../common/types/contactUpload";
import CommonDynamicListDrawer from "../../../components/dynamic-list/CommonDynamicListDrawer";
import {
  getStaticList,
  insertStaticListContactsDl,
  listStaticListContacts,
  removeAllStaticListContacts,
  removeStaticListContacts,
  resetDlUploadState,
  resetStaticDetailsPage,
  selectStaticList,
} from "./staticlistSlice";
import { DataTable } from "../../../components/data-table/DataTable";
import {
  usePaginatedData,
  usePreviousLocation,
} from "../../../common/hooks/commonHooks";
import { exportStaticList, selectExport } from "../export/exportSlice";
import { isLoading, isSuccess } from "../../../common/helper/commonHelper";
import {
  ALERT_TYPE,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import SlContactsEmptyState from "../../../common/img/emptyStateLogos/staticListContacts.svg";
import InitialEmptyState from "../../../components/InitialEmptyState";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { PaginationFilterParams } from "../../../common/types/common";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

enum DELETION_TYPE {
  ALL = "all",
  SOME = "some",
  SINGLE = "single",
}

function DeleteConfirmationModalContent({
  isOnlyOneContactSelected,
  textToshow,
  staticListName,
}: {
  isOnlyOneContactSelected: boolean;
  textToshow: string;
  staticListName: string;
}) {
  return (
    <Text fontSize="14px" color="blackAlpha.700" lineHeight={2}>
      Are you sure about removing{" "}
      {isOnlyOneContactSelected ? (
        "the"
      ) : (
        <Text as="span" fontWeight="bold" color="blackAlpha.900">
          {textToshow}{" "}
        </Text>
      )}{" "}
      selected contact
      {isOnlyOneContactSelected ? "" : "s"} from{" "}
      <Text as="span" fontWeight="bold" color="blackAlpha.900">
        {staticListName ?? ""}
      </Text>
      ? Once deleted, cannot be undone
    </Text>
  );
}

function AddContactsButton({
  onCSVModalOpen,
  onDLModalOpen,
  onExportList,
  isHidden,
  isExportLoading,
}: {
  onCSVModalOpen: () => void;
  onDLModalOpen: () => void;
  onExportList?: () => void;
  isHidden?: boolean;
  isExportLoading?: boolean;
}) {
  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        size="sm"
        as={Button}
        rightIcon={<FaChevronDown />}
        name="add-contacts-button"
        hidden={isHidden}
        px="16px"
        py="4px"
      >
        Actions
      </MenuButton>
      <MenuList zIndex={3} fontSize="14px" minW="180px">
        <MenuOptionGroup title="Add contacts">
          <MenuItem onClick={onCSVModalOpen} name="insert-from-csv">
            From a CSV file
          </MenuItem>
          <MenuItem onClick={onDLModalOpen} name="insert-from-dl">
            From a dynamic list
          </MenuItem>
        </MenuOptionGroup>
        {onExportList && (
          <MenuOptionGroup title="Export contacts">
            <MenuItem
              onClick={onExportList}
              name="export-contacts"
              isDisabled={isExportLoading}
            >
              Export entire List
              {isExportLoading && <Spinner size="sm" color="gray.500" ml={6} />}
            </MenuItem>
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
}

function ClearButtons({
  count,
  removeAll,
  removeSome,
}: {
  count: number;
  removeAll: () => void;
  removeSome: () => void;
}) {
  return (
    <HStack>
      <IButton
        colorScheme="red"
        bg="red.50"
        color="brand.red"
        borderColor="red.100"
        variant="outline"
        name="delete-selected"
        onClick={removeSome}
      >
        {`Remove selected ${count} contact${
          count > 1 ? "s" : ""
        } from the list`}
      </IButton>
      <IButton variant="outline" onClick={removeAll} name="clear-all-button">
        Clear the list
      </IButton>
    </HStack>
  );
}

export default function StaticListContacts() {
  const dispatch = useAppDispatch();

  const {
    staticListContacts,
    staticListDetails,
    staticListDl,
    staticListDeleting,
  } = useSelector(selectStaticList);

  const [selectedRows, setSelectedRows] = useState<StaticListContactsData[]>(
    []
  );
  const [rowForDeletion, setRowForDeletion] =
    useState<StaticListContactsData | null>(null);

  const [removeOption, setDeleteOption] = useState<DELETION_TYPE | null>(null);

  useEffect(() => {
    switch (removeOption) {
      case DELETION_TYPE.ALL:
        setTextToShow("all");
        break;
      case DELETION_TYPE.SOME:
        setTextToShow(`${selectedRows.length}`);
        break;
      default:
        setTextToShow("");
    }
  }, [selectedRows, removeOption]);

  const [textToshow, setTextToShow] = useState("");

  const {
    isOpen: isCSVModalOpen,
    onClose: onCSVModalClose,
    onOpen: onCSVModalOpen,
  } = useDisclosure();

  const {
    isOpen: isDLModalOpen,
    onClose: onDLModalClose,
    onOpen: onDLModalOpen,
  } = useDisclosure();

  const { id: staticListId } = useParams<{ id: string }>();
  const isOnlyOneContactSelected =
    removeOption === DELETION_TYPE.SINGLE ||
    (selectedRows.length === 1 && removeOption !== DELETION_TYPE.ALL);

  const { assetsToExport } = useSelector(selectExport);

  useEffect(() => {
    if (staticListId) dispatch(getStaticList(staticListId));
  }, [dispatch, staticListId]);

  useEffect(() => {
    return () => {
      dispatch(resetStaticDetailsPage());
    };
  }, [dispatch]);

  const fetchStaticListContacts = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      staticListId &&
        dispatch(
          listStaticListContacts({
            id: staticListId,
            pageNo,
            searchKeyword,
            filters,
          })
        );
    },
    [dispatch, staticListId]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchStaticListContacts,
    fetchingList:
      isLoading(staticListDetails.loading) || staticListContacts.fetchingList,
  });

  useEffect(() => {
    if (isSuccess(staticListDl.inserting) && staticListId) {
      handlePageChange(1);
      dispatch(getStaticList(staticListId));
      dispatch(resetDlUploadState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticListDl.inserting, staticListId]);

  useEffect(() => {
    if (isSuccess(staticListDeleting) && staticListId) {
      handlePageChange(1);
      dispatch(getStaticList(staticListId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, staticListDeleting, staticListId]);

  const { handleGoBackWithParams } = usePreviousLocation();

  function gotoStaticLists() {
    handleGoBackWithParams(urls.staticList);
  }

  function removeContacts(rows?: StaticListContactsData[]) {
    if (rows && staticListId) {
      dispatch(
        removeStaticListContacts({
          staticListId: staticListId,
          emails: rows.map((data: StaticListContactsData) => data.email),
        })
      );
    } else {
      if (staticListId) dispatch(removeAllStaticListContacts(staticListId));
    }
    setSelectedRows([]);
    setDeleteOption(null);
    setRowForDeletion(null);
  }

  function onExportList() {
    if (staticListId) {
      dispatch(exportStaticList(staticListId));
    }
  }

  const isExportLoading =
    (!!staticListId && isLoading(assetsToExport[staticListId]?.isLoading)) ||
    staticListContacts.fetchingList;

  function onImportContactsViaCsv() {
    if (staticListId) {
      setTimeout(() => {
        dispatch(getStaticList(staticListId));
        handlePageChange(1);
      }, 500);
    }
  }

  const isInitEmpty = !(
    staticListDetails.data?.num_records || isFiltersApplied
  );

  return (
    <>
      <CommonListHeader
        heading={staticListDetails.data?.name ?? ""}
        isLoading={isLoading(staticListDetails.loading)}
        goBack={gotoStaticLists}
        searchInputProps={{
          placeholder: "Search contacts",
          name: "search-input",
          onSearch: handleSearchChange,
          defaultValue: searchKeyword,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.SL_CONTACTS,
          hidden: isInitEmpty,
        }}
        componentAfterHeader={
          staticListDetails.data?.num_records ? (
            <DisplayCount
              count={staticListDetails.data.num_records}
              countLabel={"contacts"}
            />
          ) : undefined
        }
        ctaMenu={
          <AddContactsButton
            onCSVModalOpen={onCSVModalOpen}
            onDLModalOpen={onDLModalOpen}
            onExportList={
              staticListDetails.data?.num_records ? onExportList : undefined
            }
            isHidden={isLoading(staticListDetails.loading)}
            isExportLoading={isExportLoading}
          />
        }
      />
      {!!selectedRows.length && (
        <Box pb={3} px={5}>
          <ClearButtons
            count={selectedRows.length}
            removeSome={() => setDeleteOption(DELETION_TYPE.SOME)}
            removeAll={() => setDeleteOption(DELETION_TYPE.ALL)}
          />
        </Box>
      )}
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable
          reduceHeightBy={`calc(${getHeightOfFilter(filters)} + ${
            selectedRows.length ? "42px" : "0px"
          })`}
        >
          <DataTable
            fetchingList={fetchingList}
            changingPage={staticListContacts.changingPage}
            list={staticListContacts.list}
            totalPageSize={staticListContacts.pageSize}
            totalPageCount={staticListContacts.totalPageCount}
            currentPage={staticListContacts.currentPageNo}
            setPage={handlePageChange}
            columns={staticListContactsTableHeader((row) => {
              setDeleteOption(DELETION_TYPE.SINGLE);
              setRowForDeletion(row);
            })}
            emptyMsg={`No contacts found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            onSelectionChange={setSelectedRows}
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Add new contacts using the button below from a file or by querying using a dynamic list from contact database"
          message="No contacts in this list"
          mainTextProps={{
            fontSize: "12px",
            fontWeight: 600,
            color: "gray.400",
            maxW: "400px",
          }}
          messageProps={{
            fontWeight: "600",
            fontSize: "18px",
            color: "unset",
          }}
          containerProps={{ bg: "white", height: "80vh" }}
          additionalActions={
            <AddContactsButton
              onCSVModalOpen={onCSVModalOpen}
              onDLModalOpen={onDLModalOpen}
            />
          }
        >
          <Image src={SlContactsEmptyState} alt="Contacts" />
        </InitialEmptyState>
      )}
      <ContactUploaderCsv
        isOpen={isCSVModalOpen}
        onClose={onCSVModalClose}
        onImport={onImportContactsViaCsv}
        uploadTo={UPLOAD_CONTACTS_TO.STATIC_LIST}
      />

      <CommonDynamicListDrawer
        isOpen={isDLModalOpen}
        onClose={onDLModalClose}
        submitButtonProps={{
          label: "Add to list",
          onSubmit: (dynamicList: DynamicListType[]) =>
            dispatch(
              insertStaticListContactsDl({
                id: staticListDetails.data?.static_list_id ?? "",
                dynamicList: dynamicList,
              })
            ),
          props: {
            isLoading: isLoading(staticListDl.inserting),
          },
        }}
        showDLCount={true}
      />
      <DeleteConfirmationModal
        isOpen={!!removeOption}
        onClose={() => setDeleteOption(null)}
        type={ALERT_TYPE.REMOVE}
        submitHandler={() => {
          switch (removeOption) {
            case DELETION_TYPE.ALL:
              removeContacts();
              break;
            case DELETION_TYPE.SOME:
              removeContacts(selectedRows);
              break;
            case DELETION_TYPE.SINGLE:
              rowForDeletion && removeContacts([rowForDeletion]);
              break;
          }
        }}
        customHeader={`Remove contact${isOnlyOneContactSelected ? "" : "s"}`}
        customContent={
          <DeleteConfirmationModalContent
            isOnlyOneContactSelected={isOnlyOneContactSelected}
            textToshow={textToshow}
            staticListName={staticListDetails.data?.name ?? ""}
          />
        }
      />
    </>
  );
}
