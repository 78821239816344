import {
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MemoizedCommonDrawer from "../../pages/dashboard/campaign/components/CommonDrawer";
import IButton, { BUTTON } from "../IButton";
import { SearchField, SEARCH_VARIANT } from "../SearchField";
import { cloneDeep, isArray, isEmpty } from "lodash";
import {
  SUBFILTER_TYPE,
  TABLE_FILTER_VARIANTS,
} from "../../common/constants/common";
import {
  ListDateFilterType,
  ListDlFilterType,
  ListFilterItem,
  ListFilterType,
  ListFilterValueType,
  ListTagFilterType,
  MainFilter,
} from "../../common/types/common";
import { TABLE_FILTERS } from "./TableFilters";
import SelectionRow from "./SelectionRow";
import FilterSelection from "./FilterSelection";
import { addOrRemoveArrayValue } from "../../common/helper/commonHelper";
import { MdOutlineClose } from "react-icons/md";

const FILTER_DRAWER_WIDTH = {
  [SUBFILTER_TYPE.LIST]: "filter-sm",
  [SUBFILTER_TYPE.DATE]: "filter-lg",
  [SUBFILTER_TYPE.USER]: "filter-sm",
  [SUBFILTER_TYPE.TAGS]: "filter-sm",
  [SUBFILTER_TYPE.DL]: "filter-md",
};

const MULTI_SELECT_FILTERS = [
  SUBFILTER_TYPE.LIST,
  SUBFILTER_TYPE.USER,
  SUBFILTER_TYPE.TAGS,
];

function getFilterOptionDisplayText(
  displayText: string,
  type: SUBFILTER_TYPE,
  subFilters?: ListFilterType
) {
  function getDisplayCount(filterList: any) {
    return isArray(filterList) ? "(" + filterList.length + ")" : "";
  }

  switch (type) {
    case SUBFILTER_TYPE.LIST:
    case SUBFILTER_TYPE.USER:
      return `${displayText} ${getDisplayCount(subFilters)}`;
    case SUBFILTER_TYPE.TAGS:
      const tagFilter = subFilters as ListTagFilterType;
      return `${displayText} ${getDisplayCount(tagFilter?.filter_list)}`;
    default:
      return displayText;
  }
}

function FilterList({
  setFilter,
  selectedFilters,
  mainFilter,
  filters,
}: {
  setFilter: (data: MainFilter) => void;
  selectedFilters: ListFilterItem;
  mainFilter?: string;
  filters: MainFilter[];
}) {
  return (
    <VStack w="100%">
      {filters.map((filter) => {
        const subFilters = selectedFilters[filter.value];
        const isSelected = mainFilter === filter.value;
        return (
          <SelectionRow
            isSelected={isSelected}
            onClick={() => setFilter(filter)}
            displayText={getFilterOptionDisplayText(
              filter.display,
              filter.subFilterType,
              subFilters
            )}
          />
        );
      })}
    </VStack>
  );
}

export function FilterSidebar({
  isOpen,
  onClose,
  variant,
  handleFilterChange,
  filters,
  openByFilter = null,
}: {
  isOpen: boolean;
  onClose: () => void;
  variant: TABLE_FILTER_VARIANTS;
  handleFilterChange: (filters: ListFilterItem) => void;
  filters?: ListFilterItem;
  openByFilter?: string | null;
}) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [mainFilter, setMainFilter] = useState<MainFilter | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<ListFilterItem>({});

  useEffect(() => {
    if (!isOpen) {
      setMainFilter(null);
      setSearchKeyword("");
      setSelectedFilters({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && filters) {
      const subFilterOptions = TABLE_FILTERS[variant].filters;
      const selectedMainFilter =
        subFilterOptions.find((filter) => filter.value === openByFilter) ??
        subFilterOptions[0];
      setMainFilter(selectedMainFilter);
      setSelectedFilters(filters);
    }
  }, [isOpen, filters, openByFilter, variant]);

  function selectMainFilter(filter: MainFilter) {
    if (filter.value !== mainFilter?.value) {
      setMainFilter(filter);
      setSearchKeyword("");
    }
  }

  function updateSubFilter(filter: string, subFilter: ListFilterValueType) {
    const filters = cloneDeep(selectedFilters);
    const updatedFilterList = addOrRemoveArrayValue(
      filters[filter] as ListFilterValueType[],
      subFilter
    );
    if (filter in filters && updatedFilterList.length === 0) {
      delete filters[filter];
    } else {
      filters[filter] = updatedFilterList;
    }
    setSelectedFilters(filters);
  }

  function setSubFilter(
    filter: string,
    subFilter: ListDateFilterType | ListTagFilterType | ListDlFilterType
  ) {
    const filters = cloneDeep(selectedFilters);
    filters[filter] = subFilter;
    setSelectedFilters(filters);
  }

  function clearAllUnderMainFilter(filter: string) {
    const filters = cloneDeep(selectedFilters);
    if (filter in filters) {
      delete filters[filter];
    }
    setSelectedFilters(filters);
  }

  function handleApplyFilter() {
    handleFilterChange(selectedFilters);
    onClose();
  }

  const style: StackProps = mainFilter
    ? {
        width: "100%",
        visibility: "visible",
      }
    : {
        width: 0,
        visibility: "hidden",
      };

  return (
    <MemoizedCommonDrawer
      title={
        <Text fontSize={18} fontWeight="600">
          Filter {TABLE_FILTERS[variant].display}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
      size={mainFilter ? FILTER_DRAWER_WIDTH[mainFilter.subFilterType] : "xs"}
      placement="right"
      drawerBodyProps={{ px: 4 }}
      drawerHeaderProps={{ pb: 1 }}
    >
      <VStack justifyContent="space-between" h="100%" w="100%">
        <Grid
          w="100%"
          templateColumns={`repeat(${mainFilter ? 5 : 1}, 1fr)`}
          gap={3}
        >
          <GridItem colSpan={2} minW="100%">
            <VStack pt={1}>
              <FilterList
                mainFilter={mainFilter?.value}
                setFilter={selectMainFilter}
                selectedFilters={selectedFilters}
                filters={TABLE_FILTERS[variant].filters}
              />
            </VStack>
          </GridItem>
          {mainFilter && (
            <GridItem maxW="100%" colSpan={3}>
              <VStack
                {...style}
                transition="1s linear"
                overflow="hidden"
                spacing={1}
              >
                <HStack w="100%" h="32px" justifyContent="space-between">
                  <Heading fontSize="sm" fontWeight="semibold" pl={1}>
                    {getFilterOptionDisplayText(
                      mainFilter.display,
                      mainFilter.subFilterType,
                      selectedFilters[mainFilter.value]
                    )}
                  </Heading>
                  <Button
                    leftIcon={<MdOutlineClose />}
                    fontSize={12}
                    backgroundColor="gray.100"
                    variant={BUTTON.SECONDARY}
                    height="24px"
                    fontWeight="400"
                    border="none"
                    onClick={() => clearAllUnderMainFilter(mainFilter.value)}
                    hidden={
                      !MULTI_SELECT_FILTERS.includes(
                        mainFilter.subFilterType
                      ) || isEmpty(selectedFilters[mainFilter.value])
                    }
                  >
                    Clear
                  </Button>
                </HStack>
                <SearchField
                  variant={SEARCH_VARIANT.SECONDARY}
                  placeholder="Search filters"
                  name="search-filters-input"
                  value={searchKeyword}
                  onSearch={setSearchKeyword}
                  hidden={
                    !MULTI_SELECT_FILTERS.includes(mainFilter.subFilterType)
                  }
                />
                <FilterSelection
                  isReadOnly={false}
                  filter={mainFilter}
                  updateSubFilter={updateSubFilter}
                  setSubFilter={setSubFilter}
                  selectedFilters={selectedFilters[mainFilter.value]}
                  clearSelectedFilter={clearAllUnderMainFilter}
                  searchText={searchKeyword}
                />
              </VStack>
            </GridItem>
          )}
        </Grid>
        <HStack justifyContent="flex-end" w="100%">
          <IButton
            onClick={onClose}
            name="cancel-button"
            mr={3}
            fontSize="12px"
            variant={BUTTON.SECONDARY}
          >
            Cancel
          </IButton>
          <IButton
            fontSize="12px"
            p="10px"
            variant={BUTTON.PRIMARY}
            onClick={handleApplyFilter}
          >
            Apply filter
          </IButton>
        </HStack>
      </VStack>
    </MemoizedCommonDrawer>
  );
}
