import { Box, Icon, IconProps } from "@chakra-ui/react";
import { IconType } from "react-icons";

export default function WidgetIconButton({
  hidden,
  onClick,
  icon,
  customIcon,
  ...props
}: {
  hidden?: boolean;
  onClick: () => void;
  icon?: IconType;
  customIcon?: React.ReactNode;
} & IconProps) {
  return (
    <Box
      bg="grayV2.100"
      borderRadius={6}
      h={6}
      w={6}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ cursor: "pointer" }}
      _hover={{
        bg: "grayV2.300",
      }}
      hidden={hidden}
      onClick={onClick}
    >
      {customIcon}
      {!customIcon && icon && (
        <Icon as={icon} fontSize="12px" color="brandBlue.500" {...props} />
      )}
    </Box>
  );
}
