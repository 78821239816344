import {
  Text,
  Badge,
  Box,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBoolean,
  Table,
  Thead,
} from "@chakra-ui/react";
import { formatISO } from "date-fns";
import { FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  STATUS_BADGE_DETAILS,
  TRACKED_DOMAINS_STATUS,
  TRACK_ACCESS_STATES,
  TRACK_SUBDOMAIN_STATES,
} from "../../../../common/constants/settings";
import {
  addSuffixForPlural,
  convertCase,
  isInit,
  isLoading,
  keepClickEventRestricted,
} from "../../../../common/helper/commonHelper";
import { DomainDetails } from "../../../../common/types/settings";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import {
  deleteDomain,
  getDomainList,
  selectSettings,
  setDomainListPageNo,
} from "../settingsSlice";
import { useState, useMemo, useCallback } from "react";
import { IVirtualTable } from "../../../../components/IVirtual";
import { useAppDispatch } from "../../../../store";
import {
  isDomainActive,
  isAllowedSubdomain,
  isTrackAllSubdomains,
} from "../../../../common/helper/settingsHelper";
import { EditDomainDrawer } from "./EditDomainDrawer";
import { DeleteConfirmationModal } from "../../../../components/DeleteConfirmationModal";
import {
  ALERT_TYPE,
  DELETION_MODAL_TYPES_INFO,
} from "../../../../common/constants/common";
import { DomainDeleteModalContent } from "./DomainDeleteModalContent";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";
import { LinkWithIcon } from "./LinkWithIcon";

const EMPTY_CONTEXT = "-";

const COLUMN_HEADERS = [
  "Websites connected",
  "Date connected",
  "Tracking setup",
  "Tracking status",
  "",
];
const INIT_STATE_FOR_DOMAIN: DomainDetails = {
  id: "",
  currentState: TRACKED_DOMAINS_STATUS.INACTIVE,
  activatedAt: null,
  createdAt: null,
  updatedAt: null,
  createdBy: "",
  updatedBy: "",
  domain: "",
  trackingStatus: TRACK_SUBDOMAIN_STATES.DEFAULT,
  trackingConfiguration: {},
};

function getTrackingSetup(
  trackSubdomains: TRACK_SUBDOMAIN_STATES,
  subdomains: {
    [key: string]: TRACK_ACCESS_STATES;
  },
  domain: string
) {
  if (isTrackAllSubdomains(trackSubdomains)) {
    return "Auto-track all";
  } else {
    const allowedCount = Object.entries(subdomains).filter(
      ([subdomain, status]) =>
        isAllowedSubdomain(status) && subdomain !== domain
    ).length;

    return `Track ${allowedCount} ${addSuffixForPlural(
      "subdomain",
      allowedCount
    )}`;
  }
}

function ColumnHeader() {
  return (
    <Tr height="40px">
      {COLUMN_HEADERS.map((colName, index) => {
        return (
          <Th
            key={index}
            style={{ textTransform: "none" }}
            borderTopLeftRadius={index === 0 ? "md" : undefined}
            borderTopRightRadius={
              index === COLUMN_HEADERS.length - 1 ? "md" : undefined
            }
          >
            <Text>{colName}</Text>
          </Th>
        );
      })}
    </Tr>
  );
}

export function ExtraOptions({
  onEditClick,
  onDeleteClick,
}: {
  onEditClick: (() => void) | null;
  onDeleteClick: () => void;
}) {
  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <Box onClick={(e) => keepClickEventRestricted(e)}>
        <Menu>
          <MenuButton
            size="xs"
            as={IconButton}
            aria-label="Options"
            variant="ghost"
            name="options"
            icon={<FaEllipsisV />}
          />
          <MenuList zIndex={3} rootProps={{ style: { right: 0 } }}>
            {onEditClick && (
              <MenuItem onClick={onEditClick} name="clone-item">
                Edit tracking
              </MenuItem>
            )}
            <MenuItem onClick={onDeleteClick} name="remove-item">
              Remove
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}
// to override menu getting cut off for a single row
function SingleRowTable({
  domainOne,
  tableRowContent,
  fetchingDomain,
}: {
  domainOne: DomainDetails;
  tableRowContent: (index: number, domainDetails: DomainDetails) => JSX.Element;
  fetchingDomain: boolean;
}) {
  return (
    <Table fontSize="sm" width="100%" borderRadius="md" mb={6}>
      <Thead bg="oneOffs.tableHeader" roundedTop="md" h="40px">
        <ColumnHeader />
      </Thead>
      <Tr bg="white">{tableRowContent(0, domainOne)}</Tr>
    </Table>
  );
}

function DomainListLoader() {
  return (
    <ISkeleton
      variant={SKELETON_VARIANT.TABLE_BODY}
      columnCount={5}
      rowCount={10}
      isLoaded={false}
      children={<></>}
    />
  );
}

export function DomainListTable() {
  const {
    domainList: {
      list: domainList,
      loadingList,
      currentPageNo,
      totalPageCount,
    },
  } = useSelector(selectSettings);

  const dispatch = useAppDispatch();

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useBoolean(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useBoolean(false);
  const [selectedRow, setSelectedRow] = useState<DomainDetails>(
    INIT_STATE_FOR_DOMAIN
  );

  const filteredDomainList = useMemo(
    () =>
      domainList?.filter(
        (domain) => domain.currentState !== TRACKED_DOMAINS_STATUS.REJECTED
      ),
    [domainList]
  );

  const onOpenEditDrawer = useCallback(
    (row: DomainDetails) => {
      setSelectedRow(row);
      setIsEditDrawerOpen.on();
    },
    [setIsEditDrawerOpen]
  );

  const onOpenDeleteModal = useCallback(
    (row: DomainDetails) => {
      setSelectedRow(row);
      setIsOpenDeleteModal.on();
    },
    [setIsOpenDeleteModal]
  );

  function onCloseEditDrawer() {
    setIsEditDrawerOpen.off();
    setSelectedRow(INIT_STATE_FOR_DOMAIN);
  }

  function deleteDomainHandler() {
    setIsOpenDeleteModal.off();
    dispatch(deleteDomain(selectedRow.id));
  }

  function loadMoreDomains() {
    const fetchNextPage = currentPageNo + 1 < (totalPageCount ?? 1);
    if (fetchNextPage && !isLoading(loadingList)) {
      dispatch(setDomainListPageNo(currentPageNo + 1));
      dispatch(getDomainList());
    }
  }

  const TableRowContent = useMemo(
    () => (index: number, domainDetails: DomainDetails) => {
      const {
        domain,
        trackingStatus: trackSubdomains,
        trackingConfiguration: subDomains,
        currentState,
        activatedAt: epoch,
      } = domainDetails;

      const status = currentState as keyof typeof STATUS_BADGE_DETAILS;
      const isStatusPending = currentState === TRACKED_DOMAINS_STATUS.PENDING;
      return (
        <>
          <Td w="350px">
            <LinkWithIcon urlToDisplay={domain!} />
          </Td>
          <Td w="250px">
            <FormatDate
              date={epoch ? formatISO(new Date(epoch)) : null}
              showTime
            />
          </Td>
          <Td w="250px">
            {!isStatusPending && isDomainActive(currentState) ? (
              getTrackingSetup(trackSubdomains, subDomains, domain!)
            ) : (
              <Text pl={7}>{EMPTY_CONTEXT}</Text>
            )}
          </Td>
          <Td w="250px">
            {!isStatusPending ? (
              <Badge
                children={convertCase(status)}
                px={2}
                textTransform="none"
                {...STATUS_BADGE_DETAILS[status]}
              />
            ) : (
              <Text pl={5}>{EMPTY_CONTEXT}</Text>
            )}
          </Td>
          <Td w="150px">
            <ExtraOptions
              onEditClick={
                !isStatusPending ? () => onOpenEditDrawer(domainDetails) : null
              }
              onDeleteClick={() => {
                onOpenDeleteModal(domainDetails);
              }}
            />
          </Td>
        </>
      );
    },
    [onOpenDeleteModal, onOpenEditDrawer]
  );

  const domainCount = filteredDomainList ? filteredDomainList.length : 0;
  const isListLoading = isLoading(loadingList) || isInit(loadingList);
  const tableHeight =
    isListLoading && !domainCount
      ? "500px"
      : `calc(${domainCount} * 58px + 40px)`;
  return (
    <>
      {domainCount === 1 ? (
        <SingleRowTable
          domainOne={filteredDomainList?.[0]!}
          tableRowContent={TableRowContent}
          fetchingDomain={isListLoading}
        />
      ) : (
        <Box maxHeight="55vh">
          <IVirtualTable
            list={filteredDomainList ?? null}
            containerProps={{
              maxHeight: "100%",
              height: tableHeight,
              borderRadius: "md",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            tableProps={{
              maxHeight: "100%",
              background: "white",
            }}
            TableHeaderContent={ColumnHeader}
            TableRowContent={TableRowContent}
            LoaderAtEnd={DomainListLoader}
            loadMoreData={loadMoreDomains}
            fetchingList={isListLoading}
            overscan={70}
          />
        </Box>
      )}
      <EditDomainDrawer
        isOpen={isEditDrawerOpen}
        onClose={onCloseEditDrawer}
        domainDetails={selectedRow}
      />
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal.off()}
        submitHandler={deleteDomainHandler}
        type={ALERT_TYPE.REMOVE}
        deleteItemType={DELETION_MODAL_TYPES_INFO.domain.display}
        customContent={
          <DomainDeleteModalContent domainName={selectedRow.domain!} />
        }
      />
    </>
  );
}
