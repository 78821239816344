import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { RxChevronDown, RxPlus } from "react-icons/rx";
import { PROPERTY_FILTER_ID } from "../../common/constants/campaign";
import { WebsiteActivityFiltersArg } from "../../common/types/campaign";
import { MarketingActivityArgument } from "../../common/types/person";

export default function AdditionalParametersMenu({
  label,
  options,
  onSelect,
  isReadOnly,
}: {
  label: string;
  options: (WebsiteActivityFiltersArg | MarketingActivityArgument)[] | null;
  onSelect: (id: PROPERTY_FILTER_ID) => void;
  isReadOnly?: boolean;
}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        bg="white"
        color="brand.blue"
        fontWeight="400"
        fontSize="12px"
        name="additional-parameters"
        rightIcon={<RxChevronDown fontSize="12px" />}
        leftIcon={<RxPlus fontSize="12px" />}
        hidden={isReadOnly || !options?.length}
      >
        {label}
      </MenuButton>
      <MenuList>
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              onClick={() => onSelect(option.id as PROPERTY_FILTER_ID)}
            >
              {option.display}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
