import { CONNECTION_ID } from "../constants/connection";
import { SF_CAMPAIGN_SELECTION_TYPE } from "../types/campaign";

export function redirectToSalesforceLoginPage(
  loginUrl: string,
  connectionId: string
) {
  localStorage.setItem(CONNECTION_ID, connectionId);
  window.location.href = loginUrl;
}

export function isResolveContextType(type: SF_CAMPAIGN_SELECTION_TYPE | null) {
  return type === SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_CONTEXT;
}

export function isResolveDirectType(type: SF_CAMPAIGN_SELECTION_TYPE | null) {
  return type === SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_DIRECT;
}
