import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import { TagAssetType, TagDetails, TagSummary } from "../../types/tag";
import { campaignRequest } from "./api";

export async function listTagApi({
  pageSize,
  pageNo,
  searchKeyword,
  columnsToSearchIn,
}: SearchAssetsRequestType): Promise<PaginatedList<TagDetails>> {
  return campaignRequest({
    url: "/tags/tags.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: { search: { keyword: searchKeyword, fields: columnsToSearchIn } },
    },
  });
}

export async function listTagSummaryApi(): Promise<{ tags: TagSummary[] }> {
  return campaignRequest({
    url: "/tags/tags.list.all",
    shouldRetryApiCall: true,
  });
}

export async function createTagApi(
  tagName: string
): Promise<{ tag: TagDetails }> {
  return campaignRequest({
    url: "/tags/tags.create",
    data: { tag_name: tagName },
  });
}

export async function updateTagApi({
  tagId,
  tagName,
}: {
  tagId: string;
  tagName: string;
}): Promise<{ status: boolean }> {
  return campaignRequest({
    url: "tags/tags.update",
    data: {
      tag_id: tagId,
      tag_name: tagName,
    },
  });
}

export async function getTagApi(tagId: string): Promise<{ tag: TagDetails }> {
  return campaignRequest({
    url: "/tags/tags.get",
    data: {
      tag_id: tagId,
    },
  });
}

export async function deleteTagApi(
  tagId: string
): Promise<{ status: boolean }> {
  return campaignRequest({
    url: "/tags/tags.delete",
    data: {
      tag_id: tagId,
    },
  });
}

export async function tagAssetApi(
  data: TagAssetType
): Promise<{ status: boolean }> {
  return campaignRequest({
    url: "/tags/tags/tag.asset",
    data,
  });
}

export async function untagAssetApi(
  data: TagAssetType
): Promise<{ status: boolean }> {
  return campaignRequest({
    url: "/tags/tags/untag.asset",
    data,
  });
}
