import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useOutsideClick,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { PasswordField } from "../../components/PasswordField";
import { Form, Formik } from "formik";
import { passwordValidation } from "../../common/helper/validationHelper";
import { object, string } from "yup";
import IButton from "../../components/IButton";
import PasswordValidationContent from "../../components/PasswordValidationContent";

export default function PasswordInputs({
  createPassword,
  loading = false,
  submitButtonText,
}: {
  createPassword: (values: {
    password: string;
    confirmPassword: string;
  }) => void;
  loading?: boolean;
  submitButtonText: string;
}) {
  const initValues = {
    password: "",
    confirmPassword: "",
  };
  const ref = useRef<null | HTMLInputElement>(null);
  const [isFocussed, setIsFocussed] = useState(true);
  const passwordSchema = object().shape({
    password: passwordValidation,
    confirmPassword: string().test(
      "password-match",
      "Passwords do not match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsFocussed(false);
    },
  });

  return (
    <Box
      bg="white"
      py="12"
      px={{ base: "4", md: "10" }}
      shadow="base"
      w="md"
      rounded={{ sm: "lg" }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={createPassword}
        validationSchema={passwordSchema}
      >
        {(formik) => (
          <Form>
            <Stack spacing="6">
              <FormControl
                id="password"
                isInvalid={!!formik.errors.password && formik.touched.password}
                ref={ref}
              >
                <Flex justify="space-between">
                  <FormLabel>Password</FormLabel>
                </Flex>
                <PasswordField
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onFocus={() => {
                    setIsFocussed(true);
                  }}
                  check={passwordValidation.isValidSync(formik.values.password)}
                />
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="confirm-password"
                isInvalid={
                  !!formik.errors.confirmPassword &&
                  formik.touched.confirmPassword
                }
              >
                <Flex justify="space-between">
                  <FormLabel>Confirm Password</FormLabel>
                </Flex>
                <PasswordField
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>
                  {formik.errors.confirmPassword}
                </FormErrorMessage>
              </FormControl>
              <Box>
                <PasswordValidationContent
                  height="85px"
                  value={formik.values.password}
                  isFocussed={isFocussed}
                />
              </Box>
              <IButton
                type="submit"
                isLoading={loading}
                isDisabled={!formik.isValid}
                colorScheme="blue"
                size="lg"
                fontSize="md"
                name="submit-button"
              >
                {submitButtonText}
              </IButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
