import { FORM_VENDORS } from "../../../common/types/form";
import FormSubmissionTable from "./components/FormSubmissionTable";

export default function FormSubmission({
  formId,
  vendor,
}: {
  formId: string;
  vendor: FORM_VENDORS;
}) {
  return <FormSubmissionTable formId={formId} vendor={vendor} />;
}
