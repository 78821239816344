import { ModalProps, VStack, Text, Box, HStack } from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOADING_STATES } from "../../../../../common/constants/common";
import { isLoading } from "../../../../../common/helper/commonHelper";
import {
  Permission,
  Permissions,
} from "../../../../../common/types/salesforceLegacyService";
import urls from "../../../../../urls";
import MemoizedCommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";
import { recheckSalesforcePermission } from "../salesforceLegacySlice";

function ShowPermissions({
  val,
  displayString,
}: {
  val: Permission;
  displayString: string;
}) {
  return val.read && val.write ? (
    <HStack>
      <FaCheckCircle color="green" />
      <Text>Read and write {displayString}</Text>
    </HStack>
  ) : val.read ? (
    <HStack>
      <FaCheckCircle color="yellow" />
      <Text>Read only {displayString}</Text>
    </HStack>
  ) : val.write ? (
    <HStack>
      <FaCheckCircle color="yellow" />
      <Text>Write only {displayString}</Text>
    </HStack>
  ) : (
    <HStack>
      <FaExclamationCircle color="red" />
      <Text>No access to {displayString}</Text>
    </HStack>
  );
}

export default function SalesforceLegacyPermissionsModal({
  permissions,
  isOpen,
  onClose,
  loading,
  ...props
}: {
  permissions: Permissions;
  loading: LOADING_STATES;
  isOpen: boolean;
  onClose: () => void;
} & Partial<ModalProps>) {
  const dispatch = useDispatch();

  function recheckPermissions() {
    dispatch(recheckSalesforcePermission());
  }
  const navigate = useNavigate();
  const redirectOnClose = () => {
    navigate(urls.unifiedMapping);
  };

  return (
    <MemoizedCommonDrawer
      title="Permissions and access"
      onClose={redirectOnClose}
      isOpen={isOpen}
      size="md"
      placement="right"
    >
      <VStack width="100%" alignItems="flex-start">
        <Box pr="12">
          <Text pb="2">The following permission details were obtained</Text>
          <Link to="#" onClick={recheckPermissions}>
            <Text color="blue.600" fontSize="sm">
              Click here to re-check permissions
            </Text>
          </Link>
        </Box>
        <Box width="100%" pl="4" py={4} bg="grayV2.100" borderRadius="md">
          <VStack alignItems="flex-start">
            <ShowPermissions
              val={permissions.lead as Permission}
              displayString={"Leads"}
            />
            <ShowPermissions
              val={permissions.contact as Permission}
              displayString={"Contacts"}
            />
            <ShowPermissions
              val={permissions.account as Permission}
              displayString={"Accounts"}
            />
          </VStack>
        </Box>
      </VStack>
      <ConnectionButtons
        name="continue-button"
        isLoading={isLoading(loading)}
        onClick={onClose}
        closeModal={redirectOnClose}
      />
    </MemoizedCommonDrawer>
  );
}
