import {
  Flex,
  FlexProps,
  ButtonProps,
  Badge,
  BadgeProps,
} from "@chakra-ui/react";
import { CONNECTOR } from "../../common/types/campaign";
import IButton from "../IButton";

interface PropTypes extends FlexProps {
  hideLines?: boolean;
  operator: CONNECTOR;
  handleChange?: (op: CONNECTOR) => void;
  isReadOnly?: boolean;
  badgeProps?: BadgeProps;
  connectorProps?: FlexProps;
}

function ButtonAnd({
  onClick,
  active,
  ...sx
}: { onClick: () => void; active?: boolean } & ButtonProps) {
  return (
    <IButton
      size="xs"
      rounded="2xl"
      width="40px"
      name="logic-gate-and-button"
      bg={active ? "blue.500" : undefined}
      colorScheme={active ? "blue" : undefined}
      variant={!active ? "ghost" : undefined}
      onClick={onClick}
      capitalize={false}
      {...sx}
    >
      {active ? "and.." : "and"}
    </IButton>
  );
}

function ButtonOr({
  onClick,
  active,
  ...sx
}: { onClick: () => void; active?: boolean } & ButtonProps) {
  return (
    <IButton
      size="xs"
      rounded="2xl"
      width="30px"
      name="logic-gate-or-button"
      bg={active ? "green.300" : undefined}
      colorScheme={active ? "green" : undefined}
      variant={!active ? "ghost" : undefined}
      onClick={onClick}
      capitalize={false}
      {...sx}
    >
      {active ? "or.." : "or"}
    </IButton>
  );
}

export default function DynamicListLogicGate(props: PropTypes) {
  const {
    hideLines,
    operator,
    handleChange,
    isReadOnly,
    badgeProps,
    connectorProps,
    ...rest
  } = props;
  const isAnd = operator === CONNECTOR.AND;
  if (isReadOnly) {
    return (
      <Badge
        variant="solid"
        fontSize="10px"
        mb={1}
        height="fit-content"
        colorScheme={isAnd ? "blue" : "green"}
        {...badgeProps}
      >
        {isAnd ? "And" : "Or"}
      </Badge>
    );
  } else {
    return (
      <Flex h="35px" alignItems="center" justifyContent="flex-start" {...rest}>
        <Flex
          rounded="2xl"
          border="1px"
          borderColor="gray.300"
          bg="grayV2.100"
          {...connectorProps}
        >
          <>
            <ButtonAnd
              //override onclick and active
              active={isAnd}
              onClick={() => handleChange?.(CONNECTOR.AND)}
            />
            <ButtonOr
              onClick={() => handleChange?.(CONNECTOR.OR)}
              active={operator === CONNECTOR.OR}
            />
          </>
        </Flex>
      </Flex>
    );
  }
}

export function ReadOnlyDlLogicGate({
  connector,
  ...props
}: { connector: CONNECTOR } & BadgeProps) {
  return (
    <DynamicListLogicGate isReadOnly operator={connector} badgeProps={props} />
  );
}
