import { Text, Center, HStack, Box, Icon, Tooltip } from "@chakra-ui/react";
import { DynamicListType } from "../../common/types/campaign";
import {
  convertCase,
  isLoading,
  redirectToContact,
} from "../../common/helper/commonHelper";
import { debounce } from "lodash";
import { useState, useCallback, useMemo, useEffect } from "react";
import { getDynamicListPreview, selectDynamicList } from "./dynamicListSlice";
import { useAppDispatch } from "../../store";
import { FILTER_COLUMNS_DL_PREVIEW } from "../../common/constants/campaign";
import { useSelector } from "react-redux";
import IModal from "../IModal";
import { SearchFieldWithCount } from "../ListSelectionDropdown";
import SpinnerContainer from "../SpinnerContainer";
import { createColumnHelper } from "@tanstack/react-table";
import { FaExternalLinkAlt } from "react-icons/fa";
import { DataTable } from "../data-table/DataTable";
import {
  exportDynamicList,
  selectExport,
} from "../../pages/dashboard/export/exportSlice";
import IButton from "../IButton";
import { selectCampaign } from "../../pages/dashboard/campaign/campaignSlice";

const EMAIL_KEY = "email";

export default function DynamicListPreview({
  dynamicListQuery,
  dynamicListId,
  campaignId,
  isOpen,
  onClose,
}: {
  dynamicListQuery?: DynamicListType[];
  dynamicListId?: string;
  campaignId?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const {
    audienceList: { data, loading },
  } = useSelector(selectDynamicList);

  const { savingDraft } = useSelector(selectCampaign);

  const columnKeys = useMemo(
    () => (data && data.rows.length ? Object.keys(data.rows[0]).sort() : []),
    [data]
  );

  const [searchKeyword, setSearchKeyword] = useState("");

  const dispatch = useAppDispatch();
  const { assetsToExport } = useSelector(selectExport);

  const searchIfValid = useCallback(
    (keyword: string) => {
      dispatch(
        getDynamicListPreview({
          query: dynamicListQuery,
          dynamicListId: dynamicListId,
          campaignId: campaignId,
          columnsToSearchIn: FILTER_COLUMNS_DL_PREVIEW,
          searchKeyword: keyword,
        })
      );
    },
    [dispatch, dynamicListQuery, dynamicListId, campaignId]
  );

  useEffect(() => {
    isOpen && searchIfValid("");
  }, [searchIfValid, isOpen]);

  const debounceSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  const onSearch = useCallback(
    (keyword: string) => {
      setSearchKeyword(keyword);
      debounceSearch(keyword.trim());
    },
    [debounceSearch]
  );

  function onClosePreviewModal() {
    setSearchKeyword("");
    onClose();
  }

  const columnHelper = createColumnHelper<{ [key: string]: string }>();
  const columns =
    columnKeys.map(
      (fieldKey) => {
        return columnHelper.accessor(EMAIL_KEY, {
          header: convertCase(fieldKey),
          cell: (info) => {
            if (fieldKey === EMAIL_KEY) {
              const id =
                info.cell.row.original.id ?? info.cell.row.original.person_id;
              return (
                <HStack>
                  <Text>{info.getValue()}</Text>
                  <Box hidden={!id}>
                    <Icon
                      as={FaExternalLinkAlt}
                      fontSize="12"
                      onClick={() => redirectToContact(id)}
                      cursor="pointer"
                    />
                  </Box>
                </HStack>
              );
            }
            return info.row.original[fieldKey];
          },
        });
      },
      [data]
    ) ?? [];

  function onExportDynamicList() {
    if (dynamicListId) {
      dispatch(exportDynamicList(dynamicListId));
    }
  }

  const isExportLoading =
    isLoading(loading) ||
    (!!dynamicListId && isLoading(assetsToExport[dynamicListId]?.isLoading));

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClosePreviewModal}
      header={{ title: "Dynamic list preview" }}
      size="6xl"
      headerChildren={
        <SearchFieldWithCount
          isLoading={isLoading(loading)}
          count={data?.rows?.length ?? 0}
          total={data?.total_count?.count ?? 0}
          searchKeyword={searchKeyword}
          onSearch={onSearch}
          searchBarIsHidden={!dynamicListQuery}
        >
          {dynamicListId && (
            <Tooltip
              label="Can't export empty list"
              isDisabled={!!data?.rows.length || isExportLoading}
            >
              <IButton
                onClick={onExportDynamicList}
                isDisabled={!data?.rows.length}
                isLoading={isExportLoading || savingDraft}
                w="90px"
              >
                Export list
              </IButton>
            </Tooltip>
          )}
        </SearchFieldWithCount>
      }
    >
      <SpinnerContainer loading={isLoading(loading)}>
        {data && !!data.rows.length ? (
          <DataTable
            fetchingList={isLoading(loading)}
            changingPage={isLoading(loading)}
            list={data.rows}
            totalPageSize={data.rows.length}
            totalPageCount={data.rows.length ? 1 : 0}
            currentPage={1}
            setPage={(pageNo) => {}}
            columns={columns}
            emptyMsg="No contacts found"
            isPagination={false}
            tableHeight="70vh"
          />
        ) : (
          <Center h="100px" w="100%">
            <Text>No Results</Text>
          </Center>
        )}
      </SpinnerContainer>
    </IModal>
  );
}
