import {
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatISO, isEqual } from "date-fns";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoading, isSuccess } from "../../../../common/helper/commonHelper";
import CustomDateRangePicker from "../../../../components/CustomDateRangePicker";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../store";
import CampaignDetails from "./CampaignDetails";
import CampaignHistory from "./CampaignHistory";
import {
  getAggregateData,
  getOverAllBounceData,
  getEmailClientClickData,
  getEmailClientOpenData,
  getTopnLinks,
  selectCampaignReports,
  setCampaignReportsRange,
  getBounceClassificationInfo,
} from "./campaignReportsSlice";
import { DeliveryInfo, EmailClient, TopLinks, EventMatrix } from "./Matrix";
import RecipientsReports from "./RecipientsReports";

function ReportFilter({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}) {
  const dispatch = useAppDispatch();

  function onDateChange(start: Date, end: Date) {
    if (!(isEqual(startDate, start) && isEqual(endDate, end))) {
      dispatch(
        setCampaignReportsRange({
          start: formatISO(start),
          end: formatISO(end),
        })
      );
    }
  }

  return (
    <HStack alignItems="center" height="100%">
      <Text>Sent in</Text>
      <CustomDateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateChange}
      />
    </HStack>
  );
}

function Overview() {
  const {
    aggregateData: { loading: aggregateDataLoading },
  } = useSelector(selectCampaignReports);

  return (
    <VStack pr={4} width="100%">
      <SpinnerContainer loading={isLoading(aggregateDataLoading)}>
        {isSuccess(aggregateDataLoading) && (
          <>
            <DeliveryInfo />
            <EventMatrix />
            <TopLinks />
            <EmailClient />
          </>
        )}
      </SpinnerContainer>
      <CampaignHistory />
    </VStack>
  );
}

export default function CampaignReports() {
  const dispatch = useAppDispatch();

  const {
    filterData: { timeRange },
  } = useSelector(selectCampaignReports);

  useEffect(() => {
    if (timeRange.startDate && timeRange.endDate) {
      dispatch(getAggregateData());
      dispatch(getOverAllBounceData());
      dispatch(getEmailClientClickData());
      dispatch(getEmailClientOpenData());
      dispatch(getTopnLinks());
    }
  }, [dispatch, timeRange]);

  useEffect(() => {
    dispatch(getBounceClassificationInfo());
  }, [dispatch]);

  return (
    <Tabs size="md" variant="line">
      <Flex
        height="65px"
        marginBottom="0"
        justifyContent="center"
        borderBottom="2px solid"
        borderColor="gray.200"
      >
        <Flex justifyContent="space-between" alignItems="flex-end" w="100%">
          <TabList borderBottom={1}>
            <Tab>Overview</Tab>
            <Tab>Recipients</Tab>
          </TabList>
          <ReportFilter
            startDate={new Date(timeRange.startDate)}
            endDate={new Date(timeRange.endDate)}
          />
        </Flex>
      </Flex>
      <TabPanels w="100%">
        <TabPanel bg="white" w="100%">
          <VStack width="100%" spacing={10}>
            <Overview />
            <CampaignDetails />
          </VStack>
        </TabPanel>
        <TabPanel bg="white">
          <Flex flexDirection="column" alignItems="center" width="100%">
            <HStack w="100%" alignItems="flex-start">
              <RecipientsReports />
            </HStack>
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
