import { ButtonProps, Flex, HStack } from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isFulfilled, isLoading } from "../../../../common/helper/commonHelper";
import {
  SegmentAsDestinationConfig,
  SegmentDestinationSettings,
  SEGMENT_SYNC_TYPES,
} from "../../../../common/types/connection";
import IButton, { BUTTON } from "../../../../components/IButton";
import SideBar from "../../../../components/SideBar";
import ContentContainer from "../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../components/v2/ContentContainerWithHeader";
import SecondarySidebar from "../../../../components/v2/SecondarySidebar";
import SidebarBackButton from "../../../../components/v2/SidebarBackButton";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  getSegmentDetails,
  selectConnection,
  setSegmentSync,
} from "../connectionSlice";
import SegmentActivityLog from "./components/SegmentActivityLog";
import SegmentDestinationDetails from "./components/SegmentDestinationDetails";
import SegmentDetails from "./components/SegmentDetails";

const INIT_SEGMENT_DEST_SETTINGS: SegmentDestinationSettings = {
  sync_email_delivered: false,
  sync_email_opened: {
    is_enabled: false,
    unique_opens_only: false,
  },
  sync_email_bounced: false,
  sync_email_spam: false,
  sync_email_link_click: {
    is_enabled: false,
    unique_clicks_only: false,
  },
  sync_email_unsubscribed: false,
};

function DisplayEditOption({
  isReadOnly,
  onEdit,
  cancelButtonProps,
  saveButtonProps,
}: {
  isReadOnly: boolean;
  onEdit: () => void;
  cancelButtonProps: ButtonProps;
  saveButtonProps: ButtonProps;
}) {
  return isReadOnly ? (
    <IButton
      variant={BUTTON.PRIMARY}
      leftIcon={<FaPencilAlt size="10px" />}
      onClick={onEdit}
    >
      Edit
    </IButton>
  ) : (
    <HStack>
      <IButton variant={BUTTON.SECONDARY} {...cancelButtonProps}>
        Cancel
      </IButton>
      <IButton variant={BUTTON.PRIMARY} {...saveButtonProps}>
        Save
      </IButton>
    </HStack>
  );
}

export default function SegmentConnection() {
  const {
    segment: {
      syncDetails: {
        data: segmentSyncDetails,
        loading: segmentSyncDetailsLoading,
      },
      createSync: { type: syncType, writeApiKey },
    },
  } = useSelector(selectConnection);
  const [currentTab, setCurrentTab] = useState(0);
  const [editDetails, setEditDetails] = useState(false);
  const [editDestination, setEditDestination] = useState(false);
  const [destSettings, setDestSettings] = useState({
    ...INIT_SEGMENT_DEST_SETTINGS,
    ...segmentSyncDetails.destination_sync_settings,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const sideBarItems = useMemo(() => {
    let items = ["Connection Details", "Sync Activity"];
    return segmentSyncDetails.connection_type ===
      SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
      ? [...items, "Destination Settings"]
      : items;
  }, [segmentSyncDetails.connection_type]);

  function getCurrentTabContent() {
    switch (currentTab) {
      case 0:
        return <SegmentDetails isReadOnly={!editDetails} />;
      case 1:
        return <SegmentActivityLog />;
      case 2:
        return (
          <SegmentDestinationDetails
            isReadOnly={!editDestination}
            destinationSettings={destSettings}
            setDestinationSettings={setDestSettings}
          />
        );
    }
  }

  function displaySideMenu() {
    switch (currentTab) {
      case 0:
        return (
          <DisplayEditOption
            isReadOnly={!editDetails}
            onEdit={() => setEditDetails(true)}
            saveButtonProps={{
              onClick: onSaveSegmentDetails,
              isLoading: isLoading(segmentSyncDetailsLoading),
              isDisabled:
                syncType === SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION &&
                !writeApiKey,
            }}
            cancelButtonProps={{
              onClick: () => setEditDetails(false),
              isDisabled: isLoading(segmentSyncDetailsLoading),
            }}
          />
        );
      case 2:
        return (
          <DisplayEditOption
            isReadOnly={!editDestination}
            onEdit={() => setEditDestination(true)}
            saveButtonProps={{
              onClick: onSaveSegmentDestSettings,
              isLoading: isLoading(segmentSyncDetailsLoading),
            }}
            cancelButtonProps={{
              onClick: () => setEditDestination(false),
              isDisabled: isLoading(segmentSyncDetailsLoading),
            }}
          />
        );
    }
  }

  useEffect(() => {
    setDestSettings({
      ...INIT_SEGMENT_DEST_SETTINGS,
      ...segmentSyncDetails.destination_sync_settings,
    });
  }, [segmentSyncDetails.destination_sync_settings, editDestination]);

  async function updateSegmentSyncDetails(
    syncDetails: SegmentAsDestinationConfig
  ) {
    const result = await dispatch(setSegmentSync(syncDetails));
    if (isFulfilled(result.meta.requestStatus)) {
      toast.success("Updated settings successfully");
    } else {
      toast.error("Settings updation failed");
    }
  }

  function onSaveSegmentDetails() {
    const syncDetails =
      syncType === SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
        ? {
            ...destSettings,
            write_key: writeApiKey.trim(),
          }
        : {
            write_key: "",
          };

    updateSegmentSyncDetails(syncDetails);
    setEditDetails(false);
  }

  useEffect(() => {
    dispatch(getSegmentDetails());
  }, [dispatch]);

  function onSaveSegmentDestSettings() {
    let write_key = segmentSyncDetails.destination_sync_settings?.write_key;
    if (write_key) {
      updateSegmentSyncDetails({ ...destSettings, write_key });
    }
    setEditDestination(false);
  }

  return (
    <Flex>
      <SecondarySidebar
        heading="Segment Connection"
        backButton={
          <SidebarBackButton
            onClick={() => navigate(urls.unifiedMapping)}
            text="Back to Connections"
          />
        }
      >
        <SideBar
          options={sideBarItems}
          selected={currentTab}
          select={setCurrentTab}
        />
      </SecondarySidebar>

      <ContentContainerWithHeader>
        <HStack minH="45px" maxH="45px" flex={1} justifyContent="flex-end">
          {displaySideMenu()}
        </HStack>
        <ContentContainer
          flexDir="column"
          bg="white"
          maxH="calc(100vh - 60px)"
          mb="15px"
        >
          {getCurrentTabContent()}
        </ContentContainer>
      </ContentContainerWithHeader>
    </Flex>
  );
}
