import {
  ButtonProps,
  HStack,
  Text,
  Input,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import IButton, { BUTTON } from "../../../../components/IButton";

export default function CopyText({
  textType,
  text,
  buttonProps,
}: {
  textType: string;
  text: string;
  buttonProps?: ButtonProps;
}) {
  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  useEffect(() => {
    setValue(text);
  }, [setValue, text]);

  return (
    <VStack alignItems="flex-start" spacing={2} py={3}>
      <Text fontSize="xs" color="gray.600">
        Copy the below {textType} and paste it in your form settings
      </Text>
      <HStack spacing={1} w="100%" p={0}>
        <Input
          size="sm"
          value={value}
          borderRadius={4}
          isReadOnly
          fontSize="12px"
        ></Input>
        <IButton
          variant={BUTTON.PRIMARY}
          fontSize="12px"
          width="75px"
          onClick={onCopy}
          {...buttonProps}
        >
          {hasCopied ? "Copied" : "Copy"}
        </IButton>
      </HStack>
    </VStack>
  );
}
