import { defineStyleConfig, ComponentStyleConfig } from "@chakra-ui/react";

const commonTitleStyles = {
  fontWeight: "semi-bold",
  textTransform: "capitalize",
  color: "text.100",
};

const headingStyle: ComponentStyleConfig = defineStyleConfig({
  variants: {
    "main-page-title": {
      fontSize: "18px",
      ...commonTitleStyles,
      fontWeight: "bold",
    },
    "section-title": {
      fontSize: "16px",
      ...commonTitleStyles,
    },
    "paragraph-title": {
      fontSize: "14px",
      ...commonTitleStyles,
    },
  },
});

export default headingStyle;
