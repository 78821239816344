import { HStack, Text, Switch, Heading, Badge } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  STATUS,
  StatusSpecificBadge,
} from "../../../../../../../common/constants/AccountContactMapping";
import { AccountContactAutoMappingElement } from "../../../../../../../common/types/AccountContactMapping";

export default function AccountAutoSettingsEnable({
  isEnabled,
  readonly,
  setIsEnabled,
  accountAutoMapping,
}: {
  isEnabled: boolean;
  readonly: boolean;
  setIsEnabled: (isEnabled: boolean) => void;
  accountAutoMapping: AccountContactAutoMappingElement;
}) {
  const mappingStatus = isEnabled ? STATUS.ACTIVE : STATUS.INACTIVE;

  useEffect(() => {
    if (accountAutoMapping && readonly) {
      setIsEnabled(accountAutoMapping?.is_enabled);
    }
  }, [accountAutoMapping, readonly, setIsEnabled]);

  return (
    <>
      <Heading as="h1" pb="2" fontSize="md">
        Contact to Account Matching
      </Heading>
      <Text fontSize={14}>
        Automatically match contacts to an account with a matching criteria in
        Inflection
      </Text>
      <Text fontSize={12} color="gray.500" pt="4">
        Service Status
      </Text>
      {!readonly ? (
        <HStack>
          <Text fontSize={14}>Enable Auto Account Matching</Text>
          <Switch
            colorScheme="green"
            isChecked={isEnabled}
            onChange={() => setIsEnabled(!isEnabled)}
          />
        </HStack>
      ) : (
        <HStack>
          <Text fontSize={14}>Auto Account Matching is</Text>
          <Badge
            fontSize="10px"
            variant="solid"
            backgroundColor={StatusSpecificBadge[mappingStatus].color}
          >
            {mappingStatus}
          </Badge>
        </HStack>
      )}
    </>
  );
}
