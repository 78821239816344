import { Flex } from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TAB } from "../../../../common/constants/unifiedMapping";
import {
  isLoading,
  isSuccess,
  useQuery,
} from "../../../../common/helper/commonHelper";
import { ConnectionListItem } from "../../../../common/types/unifiedMapping";
import SideBar from "../../../../components/SideBar";
import ContentContainerWithHeader from "../../../../components/v2/ContentContainerWithHeader";
import SecondarySidebar from "../../../../components/v2/SecondarySidebar";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import { resetSalesforceData } from "../salesforceLegacyService/salesforceLegacySlice";
import { resetSalesforceEntireState } from "../salesforce/salesforceSlice";
import {
  selectConnection,
  getAllConnections,
  getUnifiedMapping,
  listDwDestinationColumns,
  resetUnifiedMappingConnectionList,
} from "../connectionSlice";
import AccountMappingTab from "./components/accountMapping/AccountMappingTab";
import CrmDataTab from "./components/crmTab/CrmDataTab";
import AccountAutoSettings from "./components/dataObjects/AccountAutoSettings";
import OverviewTab from "./components/overviewTab/OverviewTab";
import ProductDataExplorer from "./components/ProductDataExplorer/ProductDataExplorer";
import {
  getCrmAndSalesforceSource,
  getSalesforceId,
} from "../../../../common/helper/unifiedMappingHelper";
import { DW_SOURCES } from "../../../../common/constants/connection";
import {
  useSalesforceConnection,
  useSalesforceSyncObjectColumnsList,
} from "../../../../common/hooks/salesforce";
import { isAccountSyncEnabled } from "../../../../common/helper/salesforceHelper";

const SIDEBAR_ITEMS = [
  "Data Connections",
  "Contact Fields",
  "Account Fields",
  "Product Data Explorer",
  "Contact to Account Matching",
];

const MARKETING_PERSON = "marketing_person";

function EmptyContainerFallback() {
  return (
    <ContentContainerWithHeader>
      <></>
    </ContentContainerWithHeader>
  );
}

export default function DataManagement() {
  const dispatch = useAppDispatch();
  const {
    unifiedConnection: {
      connectionList: { list: genericConnectionList, loadingList },
      dwColumnList,
    },
  } = useSelector(selectConnection);
  const query = useQuery();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    const tabNumber = query.get(TAB);
    if (tabNumber && isFinite(+tabNumber) && +tabNumber > 0) {
      setSelectedOption(+tabNumber - 1);
      navigate(urls.unifiedMapping, { replace: true });
    }
  }, [navigate, query]);

  useEffect(() => {
    dispatch(getAllConnections({ isForced: true }));
  }, [dispatch]);

  const salesforceConnectionId = useMemo(
    () =>
      isSuccess(loadingList) ? getSalesforceId(genericConnectionList) : null,
    [genericConnectionList, loadingList]
  );

  // hide non salesforce sources in CRM
  const crmAndSalesforceSource: ConnectionListItem[] = useMemo(
    () => getCrmAndSalesforceSource(genericConnectionList),
    [genericConnectionList]
  );

  const { data: sfConnection, loading: fetchingSfConnection } =
    useSalesforceConnection({ connectionId: salesforceConnectionId });

  const salesforceConnections = useSalesforceSyncObjectColumnsList({
    connectionId: salesforceConnectionId,
  });

  useEffect(() => {
    crmAndSalesforceSource.forEach(async (source) => {
      if (
        DW_SOURCES.includes(source.source) &&
        !dwColumnList[source.connection_id]
      ) {
        dispatch(
          listDwDestinationColumns({
            connectionId: source.connection_id,
            destinationTableName: MARKETING_PERSON,
          })
        );
      }
    });
  }, [dispatch, crmAndSalesforceSource, dwColumnList]);

  useEffect(() => {
    dispatch(getUnifiedMapping());
  }, [dispatch]);

  const settingTabs: Record<number, ReactNode> = {
    0: <OverviewTab />,
    1: (
      <CrmDataTab
        crmSources={crmAndSalesforceSource}
        salesforceConnections={salesforceConnections}
      />
    ),
    2: (
      <AccountMappingTab
        accountSources={crmAndSalesforceSource}
        isDataLoading={
          isLoading(fetchingSfConnection) || isLoading(loadingList)
        }
        isAccountObjectEnabled={isAccountSyncEnabled(sfConnection)}
        syncObjSettingsEnabled={!!sfConnection?.syncSettings?.objectSettings}
        salesforceConnections={salesforceConnections}
      />
    ),
    3: <ProductDataExplorer />,
    4: (
      <AccountAutoSettings
        sfConnection={sfConnection}
        loadingConnection={fetchingSfConnection}
      />
    ),
  };

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceEntireState());
      dispatch(resetSalesforceData());
      dispatch(resetUnifiedMappingConnectionList());
    };
  }, [dispatch]);

  return (
    <Flex maxH="100vh">
      <SecondarySidebar heading="Connections">
        <SideBar
          options={SIDEBAR_ITEMS}
          selected={selectedOption}
          select={setSelectedOption}
        />
      </SecondarySidebar>
      {settingTabs[selectedOption] ?? <EmptyContainerFallback />}
    </Flex>
  );
}
