import { Skeleton } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function WrapperWithSkeleton({
  loading,
  children,
}: {
  loading: boolean;
  children: ReactNode;
}) {
  return <Skeleton isLoaded={!loading}>{children}</Skeleton>;
}
