import {
  Box,
  ButtonGroup,
  Icon,
  Flex,
  HStack,
  VStack,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { formatDuration, format } from "date-fns";
import { useRef, useEffect } from "react";
import {
  FaCopy,
  FaInfoCircle,
  FaPauseCircle,
  FaPlayCircle,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SyncDuration } from "../../../../../common/types/connection";
import IButton from "../../../../../components/IButton";
import ITitle from "../../../../../components/ITitle";
import urls from "../../../../../urls";
import BolderText from "../../components/BolderText";
import { SyncStatsHeader } from "../../components/SyncStatsHeader";
import { SyncStats } from "../../components/SyncStats";
import {
  selectConnection,
  getSyncSummary,
  toggleSync,
} from "../../connectionSlice";
import SubHeader from "../../components/SubHeader";

function SyncError({ error }: { error: Record<string, string> }) {
  const { hasCopied, onCopy } = useClipboard(
    error ? Object.values(error)[0] : ""
  );
  return (
    <Box border="1px" borderColor="red.100" mb="5">
      <Flex
        bg="red.50"
        borderBottom="1px"
        borderColor="red.100"
        justifyContent="space-between"
      >
        <HStack alignItems="center" spacing="3" p="3">
          <Icon color="orange.400" as={FaInfoCircle}></Icon>
          <ITitle
            fontSize="xs"
            fontWeight="medium"
            color="gray.600"
            title="Sync Paused"
          />
          <Text fontSize="xs" fontWeight="bold" color="gray.800">
            {error && Object.keys(error)[0]}
          </Text>
        </HStack>
        <Box>
          <IButton
            h="100%"
            onClick={onCopy}
            rounded="none"
            bg="white"
            name="copy-button"
            variant="ghost"
            leftIcon={<FaCopy />}
          >
            {hasCopied ? "Copied" : "Copy error"}
          </IButton>
        </Box>
      </Flex>
      <Box p="3" bg="white">
        <Text fontSize="xs">{error && Object.values(error)[0]}</Text>
      </Box>
    </Box>
  );
}

export default function SyncDetails({
  connectionId,
  refreshAction,
  refresh,
}: {
  connectionId: string | null;
  refreshAction: () => void;
  refresh: boolean;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { syncSummary, togglingSync } = useSelector(selectConnection);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!syncSummary && connectionId) {
      dispatch(getSyncSummary(connectionId));
    }
  }, [dispatch, syncSummary, connectionId]);

  useEffect(() => {
    if (connectionId) {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      } else {
        dispatch(getSyncSummary(connectionId));
      }
    }
  }, [dispatch, refresh, connectionId]);

  function toggleSyncHandler() {
    if (connectionId) {
      dispatch(toggleSync(connectionId));
    }
  }

  function addDurationIfExits(duration: SyncDuration | null) {
    return duration ? "in " + formatDuration(duration) : "";
  }

  function navigateToEditSync() {
    navigate(`${urls.connectionConfiguration}/${connectionId}`);
  }

  return (
    <>
      {syncSummary && (
        <Box>
          <SubHeader refreshAction={refreshAction} title="Sync Details">
            <ButtonGroup>
              <IButton
                isLoading={togglingSync}
                width="140px"
                variant="outline"
                name="sync-resume-pause-button"
                color={syncSummary.error_time ? "orange.500" : "brand.blue"}
                leftIcon={
                  <Icon
                    color={syncSummary.error_time ? "orange.500" : "brand.blue"}
                    as={syncSummary.sync_enabled ? FaPauseCircle : FaPlayCircle}
                  />
                }
                onClick={() => toggleSyncHandler()}
              >
                {syncSummary.sync_enabled ? "Pause Sync" : "Resume Sync"}
              </IButton>
              <IButton
                onClick={navigateToEditSync}
                colorScheme="blue"
                name="edit-sync"
              >
                Edit Sync
              </IButton>
            </ButtonGroup>
          </SubHeader>
          {syncSummary.error && (
            <SyncError error={syncSummary.error as Record<string, string>} />
          )}
          <Box border="1px" borderColor="gray.100" rounded="lg">
            <Flex
              justifyContent="space-between"
              px={5}
              py={2}
              borderBottomWidth="1px"
              borderColor="gray.100"
              bg="grayV2.100"
              color="gray.500"
              fontWeight="medium"
              fontSize="xs"
            >
              {syncSummary.next_run_time ? (
                <Text>
                  Next sync on{" "}
                  <BolderText>
                    {format(new Date(syncSummary.next_run_time), "PPpp")}
                  </BolderText>
                </Text>
              ) : (
                <Text>
                  Next sync is <BolderText>Paused</BolderText>
                </Text>
              )}

              {syncSummary.time_last_synced && (
                <HStack spacing="1">
                  <Text>Last sync completed</Text>
                  <BolderText>
                    {addDurationIfExits(syncSummary.last_sync_time_taken)}
                  </BolderText>
                  <Text>on</Text>
                  <BolderText>
                    {format(new Date(syncSummary.time_last_synced), "PPpp")}
                  </BolderText>
                </HStack>
              )}
            </Flex>
            <Flex>
              <VStack
                width="300px"
                alignItems="left"
                p={5}
                spacing="5"
                borderRightWidth="1px"
                borderColor="gray.200"
              >
                <SyncStatsHeader title="Last Sync Updates" />
                <SyncStats
                  label="New Records"
                  value={syncSummary.records_synced_count}
                />
              </VStack>
              <VStack alignItems="left" flex={1} p={5} spacing="5">
                <SyncStatsHeader title="Total Sync Details" />
                <HStack spacing="10">
                  <SyncStats
                    label="Total Records"
                    value={syncSummary.total_records_synced_count}
                  />
                  <SyncStats
                    label="Number of tables"
                    value={syncSummary.table_count}
                  />
                </HStack>
              </VStack>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
}
