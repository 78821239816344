export enum TOKEN_REQUEST_TYPE {
  PASSWORD_CREATE = "password_create",
  PASSWORD_RESET = "password_reset",
  VERIFY_EMAIL = "verify_email",
}

export enum PASSWORD_MOD_TYPE {
  CREATE = "create",
  FORGOT = "forgot",
}

export enum TOKEN_RETURN_TYPE {
  NUMBER = "number",
  STRING = "string",
  BOOLEAN = "boolean",
  ARRAY = "array",
  HASHMAP = "hashmap",
  NONE = "null",
  DATETIME = "datetime",
  ANY = "any",
}
