import { Box, GridItem, Grid, Heading, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectConstants } from "../../../../../common/slices/constantsSlice";
import { useAppDispatch } from "../../../../../store";
import CommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";
import {
  wizardNextStep,
  wizardPrevStep,
  wizardSetStep,
  setIsReadOnly,
  selectConnection,
  validateReadAndWriteConnection,
} from "../../connectionSlice";
import { CopyTextToClipboard } from "./CopyDatabaseName";
import { ConnectionReadAndWriteValidateConfig } from "../../../../../common/types/connection";
import {
  isFulfilled,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import {
  listSourceSchemaApi,
  validateConnectionApi,
} from "../../../../../common/api/integrations/connection";

function BigqueryAccessWriteAndReadRequestDetails() {
  const {
    authedConstants: { gcp_service_account_email },
  } = useSelector(selectConstants);

  return (
    <>
      <Text fontSize="12px" pb={6} color="gray.600">
        On your Google Cloud console, ensure that you've selected the project
        containing your BigQuery Instance.
        <br />
        From the navigation menu, go to IAM & Admin Panel. Click on "Grant
        Access". Then, add the following service account as principle and add
        the specified role.
      </Text>
      <Box px={1} fontSize="12px">
        <Grid gridTemplateColumns={"90px 1fr"} gap={2}>
          <GridItem colSpan={2} color="gray.600">
            Give BigQuery.DataViewer permissions to your project in which your
            dataset resides. This will help us read the data and sync the data
            to inflection.
          </GridItem>
          <GridItem mt={4} color="gray.600">
            Principal:
          </GridItem>
          <GridItem mt={4}>
            <CopyTextToClipboard
              text={gcp_service_account_email}
              inputTextProps={{ fontSize: "14px" }}
            />
          </GridItem>
          <GridItem color="gray.600">Role:</GridItem>
          <GridItem>bigquery.dataviewer</GridItem>
        </Grid>
      </Box>
    </>
  );
}

function BigqueryAccessReadOnlyRequestDetails() {
  const {
    authedConstants: { gcp_service_account_email },
  } = useSelector(selectConstants);

  return (
    <>
      <Text fontSize="12px" pb={6} color="gray.600">
        On your BigQuery instance, create a custom role with the permissions
        shown below.Navigate to the tables you want to sync and click on Share.
        <br />
        Add the following service account as Principal and add the custom role
        created earlier with the specified permissions.
      </Text>
      <Box fontSize="12px">
        <Grid gridTemplateColumns={"90px 1fr"} gap={2}>
          <GridItem colSpan={2} color="gray.600">
            Grant the service account the permissions BigQuery.Tables.get and
            BigQuery.tables.getData for the tables you intend to sync with
            Inflection from your project.
          </GridItem>
          <GridItem mt={4} color="gray.600">
            Principal:
          </GridItem>
          <GridItem mt={4}>
            <CopyTextToClipboard
              text={gcp_service_account_email}
              inputTextProps={{ fontSize: "14px" }}
            />
          </GridItem>
          <GridItem color="gray.600">Role:</GridItem>
          <GridItem>bigquery.tables.get, bigquery.tables.getData</GridItem>
        </Grid>
      </Box>
    </>
  );
}

export default function BigqueryAccessRequestDetails({
  onClose,
  isReadOnlyPermission,
}: {
  onClose: () => void;
  isReadOnlyPermission: boolean;
}) {
  const {
    hostName,
    selectedService,
    connectionDetails,
    validatingReadAndWriteConnection,
  } = useSelector(selectConnection);
  const dispatch = useAppDispatch();

  const readAndWriteReq: ConnectionReadAndWriteValidateConfig = {
    host_name: hostName,
    typ: selectedService,
  };

  const handleOnclick = async () => {
    if (!isReadOnlyPermission && connectionDetails) {
      const response = await dispatch(
        validateReadAndWriteConnection({
          data: readAndWriteReq,
          connId: connectionDetails.id,
        })
      );
      const resPayload = response.payload as {
        response: Awaited<ReturnType<typeof validateConnectionApi>>;
        schemas?: Awaited<ReturnType<typeof listSourceSchemaApi>>;
      };
      if (
        isFulfilled(response.meta.requestStatus) &&
        resPayload.response.success
      ) {
        dispatch(wizardNextStep());
      }
    } else dispatch(wizardSetStep(5));
  };

  return (
    <CommonDrawer
      title="Required permissions"
      onClose={onClose}
      isOpen={true}
      goBack={() => {
        isReadOnlyPermission
          ? dispatch(setIsReadOnly(false))
          : dispatch(wizardPrevStep());
      }}
      size="md"
      placement="right"
    >
      <Box height="calc(100vh - 200px)" pl={1} pr={5}>
        <Heading fontSize="14px" pb={2} color="gray.600">
          Verifying access to the
          {isReadOnlyPermission ? (
            <Text as="span"> tables</Text>
          ) : (
            <Text as="span"> datasets</Text>
          )}
        </Heading>
        <Text fontSize="12px" pt={2} color="gray.600">
          Inflection requires specific permissions to sync data.
        </Text>
        {isReadOnlyPermission ? (
          <BigqueryAccessReadOnlyRequestDetails />
        ) : (
          <BigqueryAccessWriteAndReadRequestDetails />
        )}
      </Box>
      <Grid gridTemplateColumns={"280px 1fr"}>
        <GridItem pt={7} color="gray.600">
          {!isReadOnlyPermission && (
            <Text fontSize="12px">
              Unable to give project-level permission?{" "}
              <Text
                as="span"
                fontWeight="bold"
                fontSize={12}
                color={"blue.800"}
                cursor="pointer"
                onClick={() => dispatch(setIsReadOnly(true))}
                _hover={{ textDecoration: "underline" }}
              >
                Click here
              </Text>
            </Text>
          )}
        </GridItem>
        <GridItem pb={4}>
          <ConnectionButtons
            name="continue-button"
            isLoading={
              !isReadOnlyPermission &&
              isLoading(validatingReadAndWriteConnection)
            }
            loadingText="Validating"
            onClick={handleOnclick}
            closeModal={onClose}
          />
        </GridItem>
      </Grid>
    </CommonDrawer>
  );
}
