import {
  Box,
  FormControl,
  HStack,
  Heading,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { CAMPAIGN_QUALIFICATION_RULES } from "../../../common/constants/campaign";
import { CampaignRuleResp } from "../../../common/types/campaign";
import DropdownWithSearch from "../../../components/DropdownWithSearch";
import NumberField, {
  NUMBER_FIELD_TYPES,
} from "../../../components/NumberField";

const CAMPAIGN_RULE_OPTIONS = [
  { label: "Qualify only once", value: CAMPAIGN_QUALIFICATION_RULES.ONCE },
  {
    label: "Qualify once every",
    value: CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY,
  },
  { label: "Qualify always", value: CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME },
];

const FREQUENCIES = {
  days: "Days",
  weeks: "Weeks",
  months: "Months",
};

export default function CampaignQualification({
  onChange,
  data,
  setRuleValid,
  showErrors,
}: {
  onChange: (formValue: CampaignRuleResp) => void;
  data: CampaignRuleResp;
  setRuleValid: (valid: boolean) => void;
  showErrors: boolean;
}) {
  const [errors, setErrors] = useState({
    rule: "",
    recurring_period: "",
  });

  function handleRuleChange(rule: CAMPAIGN_QUALIFICATION_RULES) {
    if (rule !== CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY) {
      onChange({
        ...data,
        rule,
        recurring_period: null,
        recurring_period_unit: null,
      });
    } else {
      onChange({ ...data, rule });
    }
  }

  function handlePeriodChange(newPeriod: number | null) {
    onChange({ ...data, recurring_period: newPeriod });
  }

  function handlePeriodUnitChange(periodUnit: string) {
    onChange({ ...data, recurring_period_unit: periodUnit });
  }

  const setErrorForOnceEveryRule = useMemo(() => {
    if (data.recurring_period && data.recurring_period_unit) {
      return "";
    } else if (!data.recurring_period) {
      return "Invalid period";
    } else {
      return "Invalid period unit";
    }
  }, [data]);

  useEffect(() => {
    let rule = data.rule ?? "";

    if (rule !== CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY) {
      if (
        !Object.values(CAMPAIGN_QUALIFICATION_RULES).includes(
          rule as CAMPAIGN_QUALIFICATION_RULES
        )
      ) {
        setErrors({ rule: "Select an option", recurring_period: "" });
        setRuleValid(false);
      } else {
        setErrors({ rule: "", recurring_period: "" });
        setRuleValid(true);
      }
    } else {
      setErrors(() => ({
        rule: "",
        recurring_period: setErrorForOnceEveryRule,
      }));
      setRuleValid(!!(data.recurring_period && data.recurring_period_unit));
    }
  }, [data, setRuleValid, setErrorForOnceEveryRule]);

  return (
    <Box>
      <Heading as="h3" fontSize="16px" fontWeight={700}>
        Journey Qualification
      </Heading>

      <Text fontSize="14px" color="blackAlpha.700" my={4}>
        Journey Qualification determines how many times a person will be allowed
        to enter the journey
      </Text>

      <form id="qualification-form">
        <Text fontSize="12px" color="brandBlue.500" mb={2} mt={6}>
          Qualification rule
        </Text>
        <HStack width="80%">
          <FormControl isInvalid={showErrors && !!errors.rule}>
            <DropdownWithSearch
              options={CAMPAIGN_RULE_OPTIONS}
              value={
                data.rule
                  ? CAMPAIGN_RULE_OPTIONS.find(
                      (option) => option.value === data.rule
                    )
                  : undefined
              }
              placeholder="rule"
              onChange={(option) => {
                handleRuleChange(option?.value as CAMPAIGN_QUALIFICATION_RULES);
              }}
              isInvalid={showErrors && !!errors.rule}
              controlStyle={{ width: "180px" }}
            />
          </FormControl>

          {data.rule === CAMPAIGN_QUALIFICATION_RULES.ONCE_EVERY && (
            <FormControl isInvalid={showErrors && !!errors.recurring_period}>
              <HStack>
                <NumberField
                  type={NUMBER_FIELD_TYPES.INTEGER}
                  value={data.recurring_period ?? ""}
                  min={1}
                  size="sm"
                  width="90px"
                  fieldProps={{
                    borderRadius: "6px",
                  }}
                  onValueChange={(value) => {
                    handlePeriodChange(value as number);
                  }}
                  isInvalid={showErrors && !!errors.recurring_period}
                />
                <DropdownWithSearch
                  options={Object.entries(FREQUENCIES).map(
                    ([frequency, label]) => {
                      return { label: label, value: frequency };
                    }
                  )}
                  value={
                    data.recurring_period_unit
                      ? Object.entries(FREQUENCIES)
                          .filter(
                            ([frequency, _]) =>
                              frequency === data.recurring_period_unit
                          )
                          .map(([frequency, label]) => {
                            return { label: label, value: frequency };
                          })
                      : undefined
                  }
                  onChange={(option) => {
                    handlePeriodUnitChange(option?.value ?? "");
                  }}
                  isSearchable={false}
                  isInvalid={showErrors && !!errors.recurring_period}
                  controlStyle={{ width: "110px" }}
                />
              </HStack>
            </FormControl>
          )}
        </HStack>
      </form>
      <HStack
        fontSize="12px"
        color="red.500"
        mt={2}
        spacing={1}
        hidden={!showErrors || !(errors.rule || errors.recurring_period)}
      >
        <Icon as={IoWarning} color="red.500" />
        <Text>{errors.rule ?? ""}</Text>
        <Text>{errors.recurring_period ?? ""}</Text>
      </HStack>
    </Box>
  );
}
