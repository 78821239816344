import { formatDataForDisplay } from "../../../common/helper/commonHelper";
import { DESTINATION_TYPES } from "../../../common/types/unifiedMapping";

export function createNewProperties(properties: any) {
  return Object.entries(properties ?? {}).map(([key, value]) =>
    formatDataForDisplay(key, value as string, DESTINATION_TYPES.STRING)
  );
}

export function getUnmappedFieldKeys(data: { [key: string]: any }[]) {
  const allKeys: string[][] = data.map(({ properties }) => {
    return Object.keys(properties ?? {});
  });

  return Array.from(new Set(allKeys.flat()));
}
