import {
  Button,
  Center,
  Circle,
  Grid,
  Heading,
  HStack,
  Icon,
  Text,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useContext, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { NodeProps } from "reactflow";
import {
  ADDITIONAL_STEP_ACTIONS,
  FLOW_ACTIONS,
} from "../../../../../common/constants/campaign";
import { AddNodeArgs } from "../../../../../common/types/flow";
import IButton from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import AddWidgetMenu from "./AddWidgetMenu";
import {
  DEFAULT_EDGE_COLOR,
  WIDGET_OPTIONS_DETAILS,
  WIDGET_CONTAINER_CLASS,
} from "./constants";
import { FlowStepTargetHandle } from "./FlowHandle";
import AdditionalActionOptionSteps from "./AdditionalActionOptionSteps";
import { addFlowAction, CampaignBuilderContext } from "../flowSlice";

const priorityActionList = [
  FLOW_ACTIONS.SEND_EMAIL,
  FLOW_ACTIONS.WEBHOOK,
  FLOW_ACTIONS.DELAY,
  FLOW_ACTIONS.BRANCH_BY_FILTER,
  FLOW_ACTIONS.SEND_INTERNAL_EMAIL,
  FLOW_ACTIONS.BRANCH_BY_VALUE,
  FLOW_ACTIONS.UPDATE_VALUE,
  FLOW_ACTIONS.GROUP,
  FLOW_ACTIONS.ADD_TO_STATIC_LIST,
];

function EmptyFlowCTA({
  groupId,
  openSideBar,
  activeErrorCheck,
}: {
  groupId?: string;
  openSideBar: () => void;
  activeErrorCheck: boolean;
}) {
  const dispatch = useAppDispatch();
  const { campaignId } = useContext(CampaignBuilderContext);

  const [selectedActionType, setSelectedActionType] =
    useState<FLOW_ACTIONS | null>(null);

  async function addWidget(actionType: FLOW_ACTIONS) {
    const data = {
      action_type: actionType,
      campaign_id: campaignId,
    };
    setSelectedActionType(actionType);
    if (!ADDITIONAL_STEP_ACTIONS.includes(actionType)) {
      await dispatch(addFlowAction(data));
      setSelectedActionType(null);
    }
  }

  const theme = useTheme();
  const colors = useMemo(() => {
    return {
      brandBlue: theme.__cssVars["--chakra-colors-brand-blue"],
      red400: theme.__cssVars["--chakra-colors-red-400"],
    };
  }, [theme]);
  const invalidBorderStyle = useMemo(
    () => (activeErrorCheck ? `1px solid ${colors.red400}` : "1px solid white"),
    [colors, activeErrorCheck]
  );

  if (groupId) {
    return (
      <HStack
        backgroundColor="white"
        borderRadius="md"
        boxShadow="md"
        py={5}
        cursor="pointer"
        onClick={openSideBar}
        justifyContent="center"
        w="md"
      >
        <Circle bg="brandBlue.500" p={1}>
          <Icon as={FaPlus} color="white" fontSize="14px" />
        </Circle>
        <IButton variant="link" color="brandBlue.500">
          Add flow step in the group
        </IButton>
      </HStack>
    );
  } else {
    return (
      <>
        <VStack
          backgroundColor="white"
          borderRadius="md"
          boxShadow="md"
          alignItems="center"
          w="md"
          py={5}
          cursor="pointer"
          spacing={7}
          className={WIDGET_CONTAINER_CLASS}
          border={invalidBorderStyle}
        >
          <FlowStepTargetHandle />
          <VStack spacing={0}>
            <Heading fontSize="18px">Let's build the journey</Heading>
            <Text fontSize="14px" color="gray.500">
              Start the journey by adding a flow step
            </Text>
          </VStack>
          <Grid templateColumns="repeat(3,1fr)" gap={5}>
            {priorityActionList.map((actionType) => {
              return (
                <Button
                  size="sm"
                  height="fit-content"
                  variant="ghost"
                  w="100%"
                  py={2}
                  px={2}
                  isLoading={
                    selectedActionType === actionType &&
                    !ADDITIONAL_STEP_ACTIONS.includes(actionType)
                  }
                  isDisabled={
                    !!selectedActionType && selectedActionType !== actionType
                  }
                  onClick={() => addWidget(actionType)}
                  key={actionType}
                >
                  <VStack key={actionType} w="100%">
                    <Icon
                      fontSize="xl"
                      as={WIDGET_OPTIONS_DETAILS[actionType].icon}
                      color={WIDGET_OPTIONS_DETAILS[actionType].color}
                    />
                    <Text
                      fontSize="14px"
                      textAlign="center"
                      w="110px"
                      whiteSpace="break-spaces"
                    >
                      {WIDGET_OPTIONS_DETAILS[actionType].label}
                    </Text>
                  </VStack>
                </Button>
              );
            })}
          </Grid>
          <HStack justifyContent="center" onClick={openSideBar}>
            <IButton variant="link" color="blue.500">
              Show more flow steps
            </IButton>
          </HStack>
        </VStack>
        {selectedActionType && (
          <AdditionalActionOptionSteps
            onClose={() => setSelectedActionType(null)}
            flowStepDataForAdd={{
              action_type: selectedActionType,
              campaign_id: campaignId,
            }}
            isBetweenFlowSteps={false}
          />
        )}
      </>
    );
  }
}

export function AddButton({
  parentActionId,
  branchId,
  isFlowEmpty,
  isCandidate,
  groupId,
  props: { readonly },
  isBetweenFlowSteps,
}: AddNodeArgs) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  return (
    <Center
      className={`${WIDGET_CONTAINER_CLASS} ${
        isCandidate !== undefined || readonly ? "disabled-edit" : ""
      }`}
      hidden={readonly && isBetweenFlowSteps}
    >
      {isFlowEmpty ? (
        <EmptyFlowCTA
          groupId={groupId}
          openSideBar={onOpen}
          activeErrorCheck={activeErrorCheck}
        />
      ) : (
        <Circle
          bg={DEFAULT_EDGE_COLOR}
          p={3}
          onClick={onOpen}
          cursor="pointer"
          size="28px"
        >
          <FlowStepTargetHandle />
          <Icon as={FaPlus} color="white" fontSize="18px" />
        </Circle>
      )}
      <AddWidgetMenu
        isOpen={isOpen}
        onClose={onClose}
        addAfterActionId={parentActionId}
        addInBranchId={branchId}
        addInGroup={groupId}
        isBetweenFlowSteps={isBetweenFlowSteps}
      />
    </Center>
  );
}

export default function AddButtonNode({ data }: NodeProps<AddNodeArgs>) {
  return <AddButton {...data} />;
}
