import {
  DrawerFooter,
  Flex,
  useDisclosure,
  Text,
  Box,
  HStack,
} from "@chakra-ui/react";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { FaList, FaPencilAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NodeProps } from "reactflow";
import { ASSET_TYPES } from "../../../../../../common/constants/common";
import { openAssetInNewTab } from "../../../../../../common/helper/commonHelper";
import { FlowStaticListActionOptions } from "../../../../../../common/types/campaign";
import { ReactSelectDefaultOptionsWithClick } from "../../../../../../common/types/common";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import IButton, { BUTTON } from "../../../../../../components/IButton";
import { useAppDispatch } from "../../../../../../store";
import {
  listStaticListSummaryList,
  selectStaticList,
} from "../../../../staticlist/staticlistSlice";
import CommonDrawer from "../../../components/CommonDrawer";
import {
  CampaignBuilderContext,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import { isActionDataSame } from "../helpers";
import WidgetContainer from "../WidgetContainer";
import WidgetIconButton from "../WidgetIconButton";
import WidgetRedirectIconButton from "../WidgetRedirectIconButton";
import { WrapperForEmptyFlowState } from "../WrapperForEmptyFlowState";
import { StaticListsDropdown } from "../../../../../../components/dynamic-list/CommonDropdowns";

function StaticListWidgetBeforeStaticListSelection({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <WrapperForEmptyFlowState
      setUpFlowStep={onClick}
      emptyStateDetails={{
        icon: FaList,
        header: "Select a static list",
        subHeader: "Click here to set up the static list",
      }}
    />
  );
}

function StaticListAfterStaticListSelection({
  staticListListOptions,
  value,
  onEdit,
  readonly,
}: {
  staticListListOptions: ReactSelectDefaultOptionsWithClick[];
  value: string;
  onEdit: () => void;
  readonly?: boolean;
}) {
  const staticList = staticListListOptions.find(
    (option) => option.value === value
  );

  return (
    <Flex justifyContent={"space-between"} w="100%">
      <HStack>
        <Text>{staticList?.label}</Text>
        <WidgetRedirectIconButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            staticList?.onPreview?.(staticList?.value);
          }}
        />
      </HStack>
      <WidgetIconButton icon={FaPencilAlt} onClick={onEdit} hidden={readonly} />
    </Flex>
  );
}

function StaticListWidget({
  data: { action, groupId, isCandidate, props, selectedExit, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const { activeErrorCheck } = useContext(CampaignBuilderContext);
  const { staticListSummaryList } = useSelector(selectStaticList);
  const { flowValidity } = useSelector(selectFlow);

  const dispatch = useAppDispatch();
  const { saveDraft, setActions, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);
  const actionOptions = useMemo(
    () => action.action_options as FlowStaticListActionOptions,
    [action]
  );

  const setValidityCallback = useCallback(
    (valid: boolean) => {
      dispatch(setFlowValidity({ [identities.actionId]: valid }));
    },
    [dispatch, identities.actionId]
  );

  function setOptions(options: FlowStaticListActionOptions) {
    setActions(options, identities.actionId, groupId);
    saveDraft(options, { actionId: identities.actionId, groupId });
  }

  const {
    isOpen: isOpenEditDrawer,
    onOpen: onOpenEditDrawer,
    onClose: onCloseEditDrawer,
  } = useDisclosure();

  useEffect(() => {
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  useEffect(() => {
    setValidityCallback(!!actionOptions.static_list_id);
  }, [actionOptions, setValidityCallback]);

  function handleOptionChange(value: string | null) {
    setOptions({
      static_list_id: value,
    });
    setValidityCallback(!!value);
  }

  const staticLists: ReactSelectDefaultOptionsWithClick[] = useMemo(
    () =>
      staticListSummaryList.data.map((staticList) => {
        return {
          label: staticList.name,
          value: staticList.static_list_id,
          onPreview: () =>
            openAssetInNewTab(
              ASSET_TYPES.STATIC_LIST,
              staticList.static_list_id
            ),
        };
      }),
    [staticListSummaryList.data]
  );

  const optionDetails = useMemo(
    () => WIDGET_OPTIONS_DETAILS[action.action_type],
    [action.action_type]
  );

  function editStaticListWidgetDrawer() {
    return (
      <CommonDrawer
        isOpen={isOpenEditDrawer}
        onClose={onCloseEditDrawer}
        size="md"
        placement="right"
        title={optionDetails.label}
        footer={
          <DrawerFooter p={0}>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={onCloseEditDrawer}
              children="Close"
            />
          </DrawerFooter>
        }
      >
        <StaticListsDropdown
          staticListId={actionOptions.static_list_id}
          onSelect={handleOptionChange}
          isInvalid={activeErrorCheck && !flowValidity[identities.actionId]}
          isReadOnly={readonly}
        />
      </CommonDrawer>
    );
  }

  return (
    <WidgetContainer
      invalidMessage={
        flowValidity[identities.actionId] ? "" : "Static list is required"
      }
      identities={identities}
      title={optionDetails.label}
      icon={optionDetails.icon}
      color={optionDetails.color}
      isCandidate={isCandidate}
      selectedExit={selectedExit}
      selectedGoto={selectedGoto}
      isDisabled={readonly}
    >
      <Box w="100%" p="5">
        {actionOptions.static_list_id ? (
          <StaticListAfterStaticListSelection
            staticListListOptions={staticLists}
            onEdit={onOpenEditDrawer}
            value={actionOptions.static_list_id}
            readonly={readonly}
          />
        ) : (
          <StaticListWidgetBeforeStaticListSelection
            onClick={onOpenEditDrawer}
          />
        )}
      </Box>

      {editStaticListWidgetDrawer()}
    </WidgetContainer>
  );
}

export default memo(StaticListWidget, isActionDataSame);
