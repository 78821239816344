import { ReactNode } from "react";

export default function IDuplicateComponent({
  duplicateCount = 1,
  children,
}: {
  duplicateCount?: number;
  children: ReactNode;
}) {
  return <>{Array(duplicateCount).fill(children)}</>;
}
