import {
  Box,
  ButtonProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaArrowLeft } from "react-icons/fa";
import IButton, { BUTTON } from "./IButton";
import ITitle from "./ITitle";

export type ModalButtonContent = {
  label: string;
  props: ButtonProps;
};

function DisplayArrowLeft({ goPreviousBox }: { goPreviousBox: () => void }) {
  return (
    <IconButton
      size="md"
      aria-label="Go back"
      name="return-button"
      variant="ghost"
      onClick={goPreviousBox}
      icon={<Icon fontSize="20px" as={FaArrowLeft} />}
      mr={4}
    />
  );
}
export default function IModal({
  header = {
    title: "",
    capitalize: true,
  },
  primaryButton,
  deleteButton,
  secondaryButton,
  modalContentProps,
  modalBodyProps,
  children,
  footer,
  hideBackArrow = false,
  hideCloseButton = false,
  goPreviousBox,
  headerChildren,
  ...props
}: {
  header: {
    title: string;
    capitalize?: boolean;
  };
  primaryButton?: ModalButtonContent;
  deleteButton?: ModalButtonContent;
  secondaryButton?: ModalButtonContent;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  children: ReactNode;
  footer?: ReactNode;
  hideBackArrow?: boolean;
  hideCloseButton?: boolean;
  goPreviousBox?: () => void;
  headerChildren?: ReactNode;
} & ModalProps) {
  return (
    <Modal {...props} isCentered>
      <ModalOverlay />
      <ModalContent {...modalContentProps}>
        <ModalHeader>
          <HStack>
            {hideBackArrow ? (
              <DisplayArrowLeft goPreviousBox={goPreviousBox as () => void} />
            ) : null}
            <ITitle
              title={header.title}
              capitalize={header.capitalize}
              fontSize="16px"
              fontWeight="bold"
            />
          </HStack>
          {headerChildren}
          <ModalCloseButton hidden={hideCloseButton} />
        </ModalHeader>

        <ModalBody {...modalBodyProps}>{children}</ModalBody>
        <ModalFooter>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Box>{footer}</Box>
            <HStack>
              {secondaryButton && (
                <IButton
                  variant={BUTTON.SECONDARY}
                  {...secondaryButton.props}
                  name="modal-secondary-button"
                >
                  {secondaryButton.label}
                </IButton>
              )}
              {deleteButton && (
                <IButton
                  variant="solid"
                  colorScheme="red"
                  {...deleteButton.props}
                  name="modal-delete-button"
                >
                  {deleteButton.label}
                </IButton>
              )}
              {primaryButton && (
                <IButton
                  variant={BUTTON.PRIMARY}
                  name="modal-primary-button"
                  {...primaryButton.props}
                >
                  {primaryButton.label}
                </IButton>
              )}
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
