import { Account, AccountDetails, AccountMember } from "../../types/account";
import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import { campaignRequest } from "./api";

export async function listAccountsApi({
  pageSize,
  pageNo,
  searchKeyword,
  columnsToSearchIn,
}: SearchAssetsRequestType): Promise<PaginatedList<Account>> {
  return campaignRequest({
    url: "/accounts/account.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: {
        search: {
          keyword: searchKeyword,
          fields: columnsToSearchIn,
        },
      },
    },
    shouldRetryApiCall: true,
  });
}

export async function getAccountDetailsApi(
  accountId: string
): Promise<{ rows: AccountDetails[] }> {
  return campaignRequest({
    url: "/accounts/account.get",
    data: { account_id: accountId },
  });
}

export async function getAccountMembersApi(
  accountId: string,
  pageNo: number,
  pageSize: number
): Promise<PaginatedList<AccountMember>> {
  return campaignRequest({
    url: "/accounts/account.person-list",
    data: {
      account_id: accountId,
      page_number: pageNo,
      page_size: pageSize,
    },
  });
}
