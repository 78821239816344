import {
  Box,
  BoxProps,
  Center,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonProps,
  SkeletonText,
  SkeletonTextProps,
  Stack,
  StackProps,
  Td,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import IDuplicateComponent from "./IDuplicateComponent";

export enum SKELETON_VARIANT {
  INPUT = "input",
  TEXT_AREA = "text_area",
  FLOW = "flow",
  TEXT = "text",
  TABLE_BODY = "table_body",
  LIST = "list",
  TEMPLATE = "template",
  TIMELINE = "timeline",
}

type SkeletonForInputType = {
  variant: SKELETON_VARIANT.INPUT | SKELETON_VARIANT.TEXT_AREA;
  isLoaded: boolean;
  skeletonProps?: SkeletonTextProps;
  children?: ReactNode;
} & BoxProps;

type SkeletonTextType = {
  variant: SKELETON_VARIANT.TEXT;
  isLoaded: boolean;
  children: ReactNode;
} & SkeletonTextProps;

type SkeletonFlowType = {
  variant: SKELETON_VARIANT.FLOW;
  isLoaded: boolean;
  children: ReactNode;
};

type SkeletonTableBodyType = {
  variant: SKELETON_VARIANT.TABLE_BODY;
  isLoaded: boolean;
  columnCount: number;
  rowCount: number;
  children: ReactNode;
};

type SkeletonListType = {
  variant: SKELETON_VARIANT.LIST;
  isLoaded: boolean;
  rowCount: number;
  children: ReactNode;
} & SkeletonProps;

type SkeletonTemplateType = {
  variant: SKELETON_VARIANT.TEMPLATE;
} & BoxProps;

type SkeletonTimelineType = {
  variant: SKELETON_VARIANT.TIMELINE;
  isLoaded: boolean;
  children: ReactNode;
} & StackProps;

type ISkeletonProps =
  | SkeletonFlowType
  | SkeletonForInputType
  | SkeletonTextType
  | SkeletonTableBodyType
  | SkeletonListType
  | SkeletonTemplateType
  | SkeletonTimelineType;

export function SkeletonForInput({
  isLoaded,
  isTextArea,
  children,
  skeletonProps,
  ...props
}: {
  isLoaded: boolean;
  children?: ReactNode;
  isTextArea?: boolean;
  skeletonProps?: SkeletonTextProps;
} & BoxProps) {
  if (!isLoaded)
    return (
      <Box
        w="100%"
        h="100%"
        minH="32px"
        bg="white"
        rounded="md"
        py="4"
        px="2"
        {...props}
      >
        <SkeletonText
          noOfLines={isTextArea ? 3 : 1}
          spacing="4"
          skeletonHeight="2"
          isLoaded={isLoaded}
          {...skeletonProps}
        />
      </Box>
    );
  else {
    return <>{children}</>;
  }
}

export function TextSkeleton({
  isLoaded,
  children,
  ...props
}: {
  isLoaded: boolean;
  children: ReactNode;
} & SkeletonTextProps) {
  return (
    <SkeletonText
      isLoaded={isLoaded}
      noOfLines={2}
      spacing="4"
      skeletonHeight="2"
      w="100%"
      mt={2}
      {...props}
    >
      {children}
    </SkeletonText>
  );
}

function TreeLayer({ children }: { children: ReactNode | ReactNode[] }) {
  return <Box className="tree-layer">{children}</Box>;
}

function TreeColumn({ children }: { children: ReactNode | ReactNode[] }) {
  return <Box className="tree-node-wrapper">{children}</Box>;
}

function TreeNode() {
  return (
    <Center>
      <Box w="200px" h="100px" bg="grayV2.200"></Box>
    </Center>
  );
}

export function FlowSkeleton({
  isLoaded,
  children,
}: {
  isLoaded?: boolean;
  children?: ReactNode;
}) {
  if (!isLoaded)
    return (
      <Center w="100%" h="100vh" bg="oneOffs.reactFlow">
        <Box className="tree" pl="400px">
          <TreeLayer>
            <TreeColumn>
              <TreeNode />
              <TreeLayer>
                <TreeColumn>
                  <TreeNode />
                  <TreeLayer>
                    <TreeColumn>
                      <TreeNode />
                    </TreeColumn>
                  </TreeLayer>
                </TreeColumn>
                <TreeColumn>
                  <TreeNode />
                  <TreeLayer>
                    <TreeColumn>
                      <TreeNode />
                    </TreeColumn>
                    <TreeColumn>
                      <TreeNode />
                    </TreeColumn>
                  </TreeLayer>
                </TreeColumn>
              </TreeLayer>
            </TreeColumn>
          </TreeLayer>
        </Box>
      </Center>
    );
  else {
    return <>{children}</>;
  }
}

export function TableDataSkeleton({
  columnCount,
  rowCount,
  isLoaded,
  children,
}: Omit<SkeletonTableBodyType, "variant">) {
  if (isLoaded) {
    return <>{children}</>;
  } else {
    return (
      <>
        {Array(rowCount)
          .fill(true)
          .map((_, index) => (
            <Tr
              h="50px"
              opacity={`${100 - (index * rowCount) / 2}%`}
              key={index}
            >
              {Array(columnCount)
                .fill(true)
                .map((_, index) => {
                  return (
                    <Td verticalAlign="middle" px={3} py={2} key={index}>
                      <Skeleton isLoaded={false} h="20px" w="100%" />
                    </Td>
                  );
                })}
            </Tr>
          ))}
      </>
    );
  }
}

export function ListSkeleton({
  rowCount,
  isLoaded,
  children,
  ...props
}: Omit<SkeletonListType, "variant">) {
  if (isLoaded) {
    return <>{children}</>;
  } else {
    return (
      <>
        {Array(rowCount)
          .fill(true)
          .map((_, index) => {
            return (
              <Skeleton
                isLoaded={false}
                opacity={`${100 - (100 / rowCount) * (index + 1)}%`}
                {...props}
              />
            );
          })}
      </>
    );
  }
}

export function TemplateListLoaderSkeleton({
  ...props
}: Omit<SkeletonTemplateType, "variant">) {
  return (
    <Box width="430px" height="100px" borderRadius="10px" {...props}>
      <HStack alignItems="flex-start">
        <Skeleton h="100px" w="93px" borderRadius="10%" />
        <Stack py={4} px={3}>
          <Skeleton h="14px" w="300px" />
          <Skeleton h="12px" w="220px" />
        </Stack>
      </HStack>
    </Box>
  );
}

export function TimelineSkeleton({
  isLoaded,
  children,
  ...props
}: Omit<SkeletonTimelineType, "variant">) {
  if (isLoaded) {
    return (
      <VStack {...props}>
        <IDuplicateComponent duplicateCount={3}>
          <HStack>
            <SkeletonCircle size="20px" />
            <Skeleton width="300px" height="10px" />
          </HStack>
        </IDuplicateComponent>
      </VStack>
    );
  } else {
    return <>{children}</>;
  }
}

export default function ISkeleton(props: ISkeletonProps) {
  switch (props.variant) {
    case SKELETON_VARIANT.INPUT:
    case SKELETON_VARIANT.TEXT_AREA:
      return (
        <SkeletonForInput
          isTextArea={props.variant === SKELETON_VARIANT.TEXT_AREA}
          {...props}
        >
          {props.children}
        </SkeletonForInput>
      );
    case SKELETON_VARIANT.FLOW:
      return <FlowSkeleton {...props} />;
    case SKELETON_VARIANT.TEXT:
      return <TextSkeleton {...props} />;
    case SKELETON_VARIANT.TABLE_BODY:
      return <TableDataSkeleton {...props} />;
    case SKELETON_VARIANT.TEMPLATE:
      return <TemplateListLoaderSkeleton {...props} />;
    case SKELETON_VARIANT.LIST:
      return <ListSkeleton {...props} />;
    case SKELETON_VARIANT.TIMELINE:
      return <TimelineSkeleton {...props} />;
    default:
      return <></>;
  }
}
