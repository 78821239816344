import { Box } from "@chakra-ui/react";
import { NodeGroup } from "../../../../common/types/dynamicList";
import AudienceCriteria from "../Audience-criteria/AudienceCriteria";
import { DISABLED_EDIT_CLASSNAME } from "../../../../common/constants/campaign";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";

// function AudienceCriteriaHeader({
//   showAudiencePreview,
// }: {
//   showAudiencePreview: () => void;
// }) {
//   return (
//     <Flex justifyContent="flex-end" alignItems="center" mb="2">
//       <IButton
//         size="sm"
//         leftIcon={<FaEye />}
//         onClick={showAudiencePreview}
//         name="preview-dl"
//         variant={BUTTON.SECONDARY}
//       >
//         Preview Audience
//       </IButton>
//     </Flex>
//   );
// }

export default function TriggerCampaignAudienceCriteria({
  audienceCriteria,
  setAudienceCriteria,
  isReadOnly = false,
  campaignContext,
  activeErrorCheck,
}: {
  audienceCriteria: NodeGroup[];
  setAudienceCriteria: (data: NodeGroup[]) => void;
  isReadOnly?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
  activeErrorCheck: boolean;
}) {
  return (
    <>
      {/* <AudienceCriteriaHeader showAudiencePreview={() => {}} /> */}
      <Box className={isReadOnly ? DISABLED_EDIT_CLASSNAME : ""} w="100%">
        <AudienceCriteria
          audienceCriteriaData={audienceCriteria}
          onChange={(data) => {
            setAudienceCriteria(data);
          }}
          activeErrorCheck={activeErrorCheck}
          campaignContext={campaignContext}
        />
      </Box>
    </>
  );
}
