import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getRollbarData, rollbar } from "../common/helper/rollbarHelper";
import { selectAccount } from "../pages/account/accountSlice";
import { INIT_ROLLBAR_DATA } from "../common/constants/common";
import { RollbarDataType } from "../common/types/common";

export const RollBar = ({ children }: { children: any }) => {
  //RollBar component to log errors
  const {
    isAuthenticated,
    user: { id, email, organisation_id, name: username },
    userOrg: { name: organisation_name },
  } = useSelector(selectAccount);

  const rollbarDataLocalStr: RollbarDataType = useMemo(() => {
    return isAuthenticated
      ? //users who are already logged in , will have no local storage data.
        getRollbarData() ?? {
          id,
          email,
          username,
          organisation_id,
          organisation_name,
        }
      : INIT_ROLLBAR_DATA;
  }, [
    email,
    id,
    isAuthenticated,
    organisation_id,
    organisation_name,
    username,
  ]);

  useEffect(() => {
    //configure as the user data changes
    rollbar.configure({
      payload: {
        person: rollbarDataLocalStr,
      },
    });
  }, [rollbarDataLocalStr]);

  return (
    <RollbarProvider instance={rollbar}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  );
};
