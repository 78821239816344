import {
  VStack,
  FormControl,
  Input,
  FormLabel,
  Icon,
  InputGroup,
  InputRightElement,
  Spinner,
  FormErrorMessage,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { debounce, isEmpty } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  isLoading,
  onEnterKeySubmit,
  validateEmail,
} from "../../../../common/helper/commonHelper";
import {
  lookupPerson,
  resetPersonLookup,
  selectTemplate,
  sendPreviewEmail,
} from "../templateSlice";
import { selectAccount } from "../../../account/accountSlice";
import {
  CAMPAIGN_CONTEXT,
  EmailDraftParams,
} from "../../../../common/types/campaign";
import { PERSON_ORG_MAPPING } from "../../../../common/types/person";
import IModal from "../../../../components/IModal";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import { selectSettings } from "../../settings/settingsSlice";
import MultiEmailInput from "../../../../components/MultiEmailInput";

export default function SendPreviewEmailPopover({
  data,
  isValid,
  isOpen,
  onClose,
}: {
  data: EmailDraftParams;
  isValid: () => boolean;
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useDispatch();

  const {
    user: { email: userEmail },
  } = useSelector(selectAccount);
  const { personLookup, sendingPreviewEmail } = useSelector(selectTemplate);
  const { personOrgMapping } = useSelector(selectSettings);

  const [personEmail, setPersonEmail] = useState("");
  const [formDirty, setFormDirty] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [personAvailable, setPersonAvailable] = useState(false);
  const [orgUserCheck, setOrgUserCheck] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<[string, string] | null>(null);
  const [toEmails, setToEmails] = useState<string[]>([userEmail]);

  useEffect(() => {
    if (
      personLookup.data[personEmail] &&
      personLookup.data[personEmail].length
    ) {
      setPersonAvailable(true);
      setOrgUserCheck(false);
      setSelectedOrg(null);
    } else {
      setPersonAvailable(false);
    }
  }, [personLookup, personEmail]);

  useEffect(() => {
    setFormDirty(false);
  }, [personLookup]);

  function sendPreview() {
    if (isValid()) {
      let filteredPersons = personLookup.data[personEmail];
      if (orgUserCheck && selectedOrg) {
        const [id, org] = selectedOrg;
        filteredPersons = filteredPersons.filter(
          ({ org_id, product_user_id }) =>
            org_id === org && product_user_id === id
        );
      }
      let persons = filteredPersons.map(({ id, org_id, product_user_id }) => ({
        id,
        org_id,
        product_user_id,
      }));
      dispatch(
        sendPreviewEmail({
          data: { ...data, to_emails: toEmails },
          persons: [persons[0]],
          campaignContext: orgUserCheck
            ? CAMPAIGN_CONTEXT.ORG
            : CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
  }

  useEffect(() => {
    if (userEmail) {
      setToEmails([userEmail]);
    }
  }, [userEmail]);

  const searchIfValid = useCallback(
    (email: string) => {
      dispatch(lookupPerson(email));
    },
    [dispatch]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    const email = event.target.value;
    setPersonEmail(email);
    setFormDirty(true);
    if (email && validateEmail(email)) {
      setInvalidEmail(false);
      if (personLookup.data[email]) {
        setFormDirty(false);
      } else {
        debouncedSearch(email);
      }
    } else {
      setInvalidEmail(true);
    }
  }

  function closeWithResetData() {
    setPersonEmail("");
    setInvalidEmail(true);
    setPersonAvailable(false);
    setFormDirty(false);
    setToEmails([userEmail]);
    dispatch(resetPersonLookup());
    onClose();
  }

  const invalidToPreview =
    invalidEmail ||
    !personEmail ||
    !personAvailable ||
    isLoading(personLookup.loading) ||
    !(
      !orgUserCheck ||
      (personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE && !!selectedOrg)
    ) ||
    isEmpty(toEmails);

  const showSelectOrg =
    personAvailable &&
    personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE &&
    personLookup.data[personEmail]?.some((person) => person.org_id) &&
    personLookup.data[personEmail]?.some((person) => person.product_user_id);

  const orgProductUserOptions: { label: string; value: [string, string] }[] =
    personLookup.data[personEmail] && personLookup.data[personEmail].length
      ? personLookup.data[personEmail].map((person) => {
          return {
            label: `${person.product_user_id}(${person.org_id})`,
            value: [person.product_user_id as string, person.org_id as string],
          };
        })
      : [];

  return (
    <IModal
      header={{ title: "Send test email" }}
      primaryButton={{
        label: "Send preview",
        props: {
          isDisabled: invalidToPreview,
          onClick: sendPreview,
          isLoading: sendingPreviewEmail,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: closeWithResetData,
        },
      }}
      isOpen={isOpen}
      onClose={closeWithResetData}
    >
      <VStack spacing={4}>
        <FormControl>
          <FormLabel color="gray.500" fontSize="14px" fontWeight="500">
            Send to
          </FormLabel>
          <MultiEmailInput
            emailList={toEmails}
            setEmailList={setToEmails}
            errorMsg={!toEmails.length ? "Send email should not be empty" : ""}
          />
        </FormControl>
        <FormControl id="email" isInvalid={!!(personEmail && invalidEmail)}>
          <FormLabel color="gray.500" fontSize="14px" fontWeight="500">
            Receive as
          </FormLabel>
          <InputGroup>
            <InputRightElement
              children={<Spinner />}
              hidden={!isLoading(personLookup.loading)}
            />
            <Input
              type="email"
              value={personEmail}
              onChange={handleEmailChange}
              placeholder="Enter email"
              onKeyDown={(e) => {
                !invalidToPreview && onEnterKeySubmit(e, sendPreview);
              }}
            />
            <InputRightElement
              hidden={
                isLoading(personLookup.loading) || invalidEmail || formDirty
              }
              children={
                <Icon
                  h="4"
                  w="4"
                  as={personAvailable ? FaCheck : FaTimes}
                  color={personAvailable ? "brand.green" : "brand.red"}
                  title={`Person data ${
                    personAvailable ? "available" : "unavailable"
                  }`}
                />
              }
            />
          </InputGroup>
          <FormErrorMessage>Valid email is required</FormErrorMessage>
          {showSelectOrg && (
            <Box>
              <Checkbox
                checked={orgUserCheck}
                defaultChecked={orgUserCheck}
                onChange={() => setOrgUserCheck(!orgUserCheck)}
                fontSize="sm"
                my={3}
              >
                Receive as a org-user
              </Checkbox>
              {orgUserCheck && (
                <FormControl id="receiver">
                  <FormLabel color="gray.500" fontSize="14px" fontWeight="500">
                    Select org user
                  </FormLabel>
                  <DropdownWithSearch
                    options={orgProductUserOptions}
                    value={
                      selectedOrg
                        ? {
                            label: `${selectedOrg[0]}(${selectedOrg[1]})`,
                            value: selectedOrg,
                          }
                        : null
                    }
                    onChange={(option) => setSelectedOrg(option?.value ?? null)}
                    isSearchable={true}
                  />
                </FormControl>
              )}
            </Box>
          )}
        </FormControl>
      </VStack>
    </IModal>
  );
}
