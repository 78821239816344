import {
  Box,
  Circle,
  FocusLock,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import IButton from "../../../components/IButton";
import { FaPen, FaPlus, FaTimes } from "react-icons/fa";
import SearchTagInput from "./SearchTagInput";
import { useAppDispatch } from "../../../store";
import { listFavouriteTags, selectTag, tagAsset, untagAsset } from "./tagSlice";
import { ASSET_TYPES } from "../../../common/constants/common";
import { isFulfilled, isInit } from "../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const TAG_VISIBILITY_LIMIT = 4;
const FAV_SUGGESTIONS_LIMIT = 3;

function FavTagsWithSearch({
  onTagSelection,
  tags,
}: {
  onTagSelection: (id: string) => void;
  tags: string[];
}) {
  const {
    favouriteTags,
    tagSummaryList: { data: tagSummaryList },
  } = useSelector(selectTag);

  return (
    <>
      <SearchTagInput
        onSelectTag={onTagSelection}
        hideTags={tags}
        isCreatable
        autoFocus
      />
      <VStack alignItems="flex-start" w="100%" mt={2}>
        {favouriteTags.data
          .filter((id) => !tags.includes(id))
          .slice(-FAV_SUGGESTIONS_LIMIT)
          .map((id) => (
            <HStack
              cursor="pointer"
              fontSize="14px"
              color="gray.800"
              w="100%"
              onClick={() => onTagSelection(id)}
            >
              <Text
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                #{tagSummaryList[id]}
              </Text>
            </HStack>
          ))}
      </VStack>
    </>
  );
}

function TagReadOnlyList({ tags }: { tags: string[] }) {
  const {
    tagSummaryList: { data: tagSummaryList },
  } = useSelector(selectTag);
  return (
    <HStack>
      {tags.slice(0, TAG_VISIBILITY_LIMIT).map((tagId) => {
        return (
          <HStack maxW="80px" fontSize="12px" spacing="3px">
            <Text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              maxW="100%"
              color="text.50"
            >
              #{tagSummaryList[tagId]}
            </Text>
          </HStack>
        );
      })}
      {tags.length > TAG_VISIBILITY_LIMIT ? (
        <HStack maxW="150px" fontSize="12px" spacing="3px">
          <Tooltip
            label={`#${tags
              .slice(TAG_VISIBILITY_LIMIT, tags.length)
              .map((tagId) => tagSummaryList[tagId])
              .join(", #")}`}
            fontSize="12px"
          >
            <Circle bg="gray.100" py="2px" px="4px">
              +{tags.length - TAG_VISIBILITY_LIMIT}
            </Circle>
          </Tooltip>
        </HStack>
      ) : null}
    </HStack>
  );
}

function TagCell({ label, onRemove }: { label: string; onRemove: () => void }) {
  return (
    <HStack
      maxW="120px"
      fontSize="12px"
      spacing="3px"
      background="grayV2.100"
      px={1}
      borderRadius="lg"
    >
      <Tooltip label={`#${label}`} fontSize="12px">
        <Text
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          maxW="100%"
        >
          #{label}
        </Text>
      </Tooltip>
      <IconButton
        rounded="full"
        name="remove-button"
        variant="ghost"
        size="xxs"
        icon={<Icon fontSize="10px" as={FaTimes} />}
        onClick={onRemove}
        aria-label="Remove tag"
      />
    </HStack>
  );
}

const addTagButtonStyle = {
  size: "xxs",
  height: "16px",
  width: "fit-content",
  borderRadius: "1px",
  px: 1,
  variant: "ghost",
  fontSize: "10px",
};

export default function LinkedTags({
  tags,
  assetId,
  assetType,
  onTagsChange,
}: {
  tags: string[];
  assetId: string;
  assetType: ASSET_TYPES;
  onTagsChange: (tags: string[]) => void;
}) {
  const {
    onOpen: onAddTagOpen,
    onClose: onAddTagClose,
    isOpen: isAddTagOpen,
  } = useDisclosure();
  const {
    onOpen: onTagListOpen,
    onClose: onTagListClose,
    isOpen: isTagListOpen,
  } = useDisclosure();
  const dispatch = useAppDispatch();
  const {
    favouriteTags,
    tagSummaryList: { data: tagSummaryList },
  } = useSelector(selectTag);

  useEffect(() => {
    if (isInit(favouriteTags.loading)) dispatch(listFavouriteTags());
  }, [favouriteTags.loading, dispatch]);

  async function onTagSelection(id: string) {
    onAddTagClose();
    if (!tags.includes(id)) {
      const response = await dispatch(
        tagAsset({
          tagId: id,
          assetId,
          assetType,
        })
      );
      if (
        isFulfilled(response.meta.requestStatus) &&
        (response.payload as { status: boolean }).status
      ) {
        onTagsChange([id, ...tags]);
      }
    }
  }

  async function onUntagSelection(id: string) {
    const response = await dispatch(
      untagAsset({
        tagId: id,
        assetId,
        assetType,
      })
    );
    if (
      isFulfilled(response.meta.requestStatus) &&
      (response.payload as { status: boolean }).status
    ) {
      onTagsChange(tags.filter((tagId) => tagId !== id));
      tags.length === 1 && onTagListClose();
    }
  }

  return (
    <HStack
      my="8px"
      _hover={{
        "&>button": {
          visibility: "visible",
          opacity: 1,
          transition: "opacity 1s",
        },
      }}
      spacing={0}
    >
      <Popover
        isOpen={isTagListOpen}
        onOpen={onTagListOpen}
        onClose={onTagListClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Box>
            <TagReadOnlyList tags={tags} />
          </Box>
        </PopoverTrigger>

        <IconButton
          size="xxs"
          height="16px"
          icon={<Icon as={FaPen} fontSize="8px" />}
          aria-label="add-tag"
          width="fit-content"
          borderRadius="1px"
          px={1}
          onClick={onTagListOpen}
          visibility="hidden"
          opacity={0}
          variant="ghost"
          ml={1}
          display={tags.length ? undefined : "none"}
        />
        <PopoverContent p={3} maxW="300px" width="fit-content">
          <PopoverArrow />
          <Wrap onBlur={onTagListClose}>
            {tags.map((tagId) => (
              <TagCell
                label={tagSummaryList[tagId]}
                onRemove={() => onUntagSelection(tagId)}
              />
            ))}
          </Wrap>
        </PopoverContent>
      </Popover>
      <Popover
        isOpen={isAddTagOpen}
        onClose={onAddTagClose}
        placement="right-start"
      >
        <PopoverTrigger>
          {tags.length ? (
            <IconButton
              {...addTagButtonStyle}
              icon={<Icon as={FaPlus} fontSize="8px" />}
              aria-label="add-tag"
              onClick={onAddTagOpen}
              visibility={"hidden"}
              opacity={0}
            />
          ) : (
            <IButton
              {...addTagButtonStyle}
              leftIcon={<Icon as={FaPlus} fontSize="8px" />}
              onClick={onAddTagOpen}
            >
              Add tag
            </IButton>
          )}
        </PopoverTrigger>
        <FocusLock persistentFocus>
          <PopoverContent p={3} display={!isAddTagOpen ? "none" : undefined}>
            <PopoverArrow />
            <FavTagsWithSearch
              onTagSelection={(id: string) => {
                onTagSelection(id);
                onAddTagClose();
              }}
              tags={tags}
            />
          </PopoverContent>
        </FocusLock>
      </Popover>
    </HStack>
  );
}
