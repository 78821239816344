import {
  CountData,
  FlowStepMember,
  WebhookFlowStepReportMemberDetails,
  FlowStepSummaryResp,
  JourneyMemberReportResp,
  NodeDetails,
} from "../../types/campaign";
import {
  ApiResponseWithMeta,
  ApiResponseWithPaginationMeta,
  PaginatedList,
} from "../../types/common";
import {
  CanvasReportBaseRequestParams,
  FlowMemberReportReq,
  JourneyMemberReportReq,
} from "../../types/flow";
import { campaignRequest } from "./api";

// 1. Journey Member Report
export async function getJourneyMemberReportApi(
  params: JourneyMemberReportReq
): Promise<ApiResponseWithPaginationMeta<JourneyMemberReportResp>> {
  return campaignRequest({
    method: "GET",
    url: `/campaign-reports/campaign/${params.campaignId}/journey`,
    params: {
      query: {
        search: { keyword: params.query, fields: params.columnsToSearchIn },
      },
      start_date: params.startDate,
      end_date: params.endDate,
      show_unique_entries: params.showUniqueEntries,
      page_size: params.pageSize,
      page_number: params.pageNumber,
    },
  });
}

// 2. User/Contact count on node edges
export async function getFlowEdgeCountApi(
  param: CanvasReportBaseRequestParams
): Promise<ApiResponseWithMeta<CountData<NodeDetails[]>>> {
  return campaignRequest({
    method: "GET",
    url: `/campaign-reports/campaign/${param.campaignId}/node-edge-counts`,
    params: {
      start_date: param.startDate,
      end_date: param.endDate,
    },
  });
}

// 3. Node Level Summary Reports
export async function getFlowStepsSummaryReportApi(
  param: CanvasReportBaseRequestParams
): Promise<ApiResponseWithMeta<FlowStepSummaryResp>> {
  return campaignRequest({
    method: "GET",
    url: `/campaign-reports/campaign/${param.campaignId}/actions`,
    params: {
      start_date: param.startDate,
      end_date: param.endDate,
    },
  });
}

// 4. Flow step report
export async function getFlowStepReportApi(
  param: FlowMemberReportReq
): Promise<PaginatedList<WebhookFlowStepReportMemberDetails>> {
  return campaignRequest({
    method: "GET",
    url: `/campaign-reports/campaign/${param.campaignId}/action/${param.actionId}/action-detail`,
    params: {
      start_date: param.startDate,
      end_date: param.endDate,
      query: {
        search: { keyword: param.query, fields: param.columnsToSearchIn },
      },
      page_size: param.pageSize,
      page_number: param.pageNumber,
    },
  });
}

// 5. Members in between flow steps
export async function getFlowStepsMembersListApi(
  param: FlowMemberReportReq
): Promise<PaginatedList<FlowStepMember>> {
  return campaignRequest({
    method: "GET",
    url: `/campaign-reports/campaign/${param.campaignId}/action/${param.actionId}/members`,
    params: {
      ui_branch_id: param.branchId,
      start_date: param.startDate,
      end_date: param.endDate,
      query: {
        search: { keyword: param.query, fields: param.columnsToSearchIn },
      },
      page_size: param.pageSize,
      page_number: param.pageNumber,
    },
  });
}
