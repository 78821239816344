import {
  Center,
  Flex,
  Heading,
  Icon,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setSelectedService } from "../../connectionSlice";
import IButton from "../../../../../components/IButton";
import { DATA_CONNECTION_SERVICES_LIST } from "../../../../../common/constants/connection";
import { DwServices } from "../../../../../common/types/connection";
import { SOURCES } from "../../../../../common/types/unifiedMapping";
import CommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";

function AvailableDwServices({
  dwServicesList,
  selectService,
  selectedService,
  disabledServices,
}: {
  dwServicesList: DwServices[];
  selectService: (id: string) => void;
  selectedService: string;
  disabledServices: SOURCES[];
}) {
  return (
    <SimpleGrid columns={2} gridGap="3px" background="grayV2.100">
      {dwServicesList
        .filter((x) => !x.hidden)
        .map((service) => (
          <IButton
            isDisabled={disabledServices.includes(service.id)}
            key={service.id}
            size="full"
            bg="white"
            variant="ghost"
            rounded="none"
            name="return-button"
            onClick={() => selectService(service.id)}
            position="relative"
            customContent={true}
          >
            {selectedService === service.id && (
              <Icon
                as={FaCheckCircle}
                position="absolute"
                top={"5px"}
                right={"5px"}
                color="green"
              />
            )}
            <Center height="80px">
              <Flex alignItems="center">
                <img width="25" src={service.imgSrc} alt="logo" />
                <Heading fontSize={"md"} ml={2}>
                  {service.label}
                </Heading>
              </Flex>
            </Center>
          </IButton>
        ))}
    </SimpleGrid>
  );
}

export default function AddConnectionModal({
  isOpen,
  onClose,
  goToNext,
  disabledServices,
}: {
  isOpen: boolean;
  onClose: () => void;
  goToNext: (service: string) => void;
  disabledServices: SOURCES[];
}) {
  const [selectedService, selectService] = useState("");
  const dispatch = useDispatch();

  const enterConnectionDetails = () => {
    dispatch(setSelectedService(selectedService));
    closeModal();
    goToNext(selectedService);
  };

  const closeModal = () => {
    selectService("");
    onClose();
  };

  return (
    <CommonDrawer
      isOpen={isOpen}
      onClose={closeModal}
      title="Add connections"
      size="md"
      placement="right"
    >
      <Text mb="10" fontSize="16px" fontWeight="semibold" color="gray.700">
        Select a platform to connect
      </Text>
      <AvailableDwServices
        disabledServices={disabledServices}
        dwServicesList={DATA_CONNECTION_SERVICES_LIST}
        selectService={selectService}
        selectedService={selectedService}
      />
      <ConnectionButtons
        closeModal={closeModal}
        label="Next: Enter connection details"
        name="next-button"
        isDisabled={!selectedService}
        onClick={enterConnectionDetails}
      />
    </CommonDrawer>
  );
}
