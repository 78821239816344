import {
  Box,
  Button,
  ButtonProps,
  forwardRef,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
} from "@chakra-ui/react";
import ITitle from "./ITitle";

const IPopoverButton = forwardRef<
  ButtonProps & {
    capitalize?: boolean;
    customContent?: boolean;
    disablePopover?: boolean;
    popoverBodyComponent?: JSX.Element;
  },
  "button"
>(
  (
    {
      capitalize = true,
      customContent = false,
      disablePopover = false,
      popoverBodyComponent = "",
      ...props
    },
    ref
  ) => {
    function TitleButton() {
      return (
        <Button {...props} ref={ref}>
          {customContent || !capitalize ? (
            props.children
          ) : (
            <ITitle title={props.children as string}></ITitle>
          )}
        </Button>
      );
    }

    return !disablePopover ? (
      <Popover trigger="hover">
        <PopoverTrigger
          {...({
            children: (
              <Box as="span">
                <TitleButton />
              </Box>
            ),
          } as any)}
        />
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{popoverBodyComponent}</PopoverBody>
        </PopoverContent>
      </Popover>
    ) : (
      <TitleButton />
    );
  }
);

export default IPopoverButton;
