import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Account } from "../../../common/types/account";
import { SearchInputAndCreateButton } from "../../../components/CommonListHeader";
import { DataTable } from "../../../components/data-table/DataTable";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import NumberFormatHeader from "../../../components/NumberFormatHeader";
import { listAccounts, selectDirectoryAccount } from "./accountDbSlice";
import { getTableRowLinkProps } from "../../../common/helper/commonHelper";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import { PaginationFilterParams } from "../../../common/types/common";

const COLUMNS_TO_SEARCH_IN = ["id", "name"];

export default function Accounts() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountList } = useSelector(selectDirectoryAccount);

  const fetchAccountsList = useCallback(
    ({ pageNo, searchKeyword = "" }: PaginationFilterParams) => {
      dispatch(
        listAccounts({
          pageNo,
          searchKeyword,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    },
    [dispatch]
  );

  const { fetchingList, searchKeyword, handleSearchChange, handlePageChange } =
    usePaginatedData({
      fetchList: fetchAccountsList,
      fetchingList: accountList.fetchingList,
    });

  const columnHelper = createColumnHelper<Account>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "Account Id",
        size: 400,
      }),
      columnHelper.accessor("name", {
        header: "Account Name",
        size: 400,
      }),
      columnHelper.accessor("account_owner", {
        header: "Account Owner",
        size: 250,
      }),
      columnHelper.accessor("website", {
        header: "Website",
        size: 250,
      }),
    ],
    [columnHelper]
  );

  const openAccount = useCallback(
    (account: Account) => {
      navigate(`${urls.account}/${account.id}`);
    },
    [navigate]
  );

  return (
    <>
      <NumberFormatHeader
        count={accountList.count}
        heading="Accounts"
        countLabel="accounts"
      >
        <SearchInputAndCreateButton
          searchInputProps={{
            placeholder: "Search accounts",
            name: "search-input",
            defaultValue: searchKeyword,
            onSearch: handleSearchChange,
          }}
        />
      </NumberFormatHeader>
      <LayoutWithTable>
        <DataTable
          fetchingList={fetchingList}
          changingPage={accountList.changingPage}
          list={accountList.list}
          totalPageCount={accountList.totalPageCount}
          currentPage={accountList.currentPageNo}
          totalPageSize={accountList.pageSize}
          onRowClick={openAccount}
          setPage={handlePageChange}
          columns={columns}
          emptyMsg={`No accounts found.${
            searchKeyword ? " Please change the search / filter values" : ""
          }`}
          getTableRowLinkProps={(data) =>
            getTableRowLinkProps({ to: urls.account, editParam: "id" }, data)
          }
        />
      </LayoutWithTable>
    </>
  );
}
