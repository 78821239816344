import {
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuButtonProps,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";

export enum SORT_BY_OPTIONS {
  NAME = "name",
  CREATED_AT = "created_at",
  LAST_MODIFIED_AT = "updated_at",
}

export enum SORT_ORDER_OPTIONS {
  ASC = 1,
  DESC = -1,
}

export type SortAssetsRequestsType = {
  sortByOption?: SORT_BY_OPTIONS;
  sortOrder?: SORT_ORDER_OPTIONS;
};

export default function SortFilter({
  sortMenuOptions,
  handleSortChange,
  defaultSortByOptionLabel,
  menuButtonProps,
}: {
  menuButtonProps?: MenuButtonProps;
  sortMenuOptions: {
    label: string;
    sortByOption: SORT_BY_OPTIONS;
    sortOrder: SORT_ORDER_OPTIONS;
    sortOrderIcon: JSX.Element;
  }[];
  handleSortChange: (
    sortByOption: SORT_BY_OPTIONS,
    sortOrder: SORT_ORDER_OPTIONS
  ) => void;
  defaultSortByOptionLabel: string;
}) {
  const [sortOption, setSortOption] = useState(defaultSortByOptionLabel);

  return (
    <Menu>
      <MenuButton
        height="32px"
        borderRadius="4px"
        border="1px solid"
        borderColor="gray.200"
        p={2}
        {...menuButtonProps}
      >
        <HStack>
          <Box fontSize="14px" color="brandBlue.400">
            {
              sortMenuOptions.find((option) => option.label === sortOption)
                ?.sortOrderIcon
            }
          </Box>
          <Text fontSize="14px" color="brandBlue.400" ml={0}>
            {sortOption}
          </Text>
        </HStack>
      </MenuButton>
      <MenuList fontSize="sm">
        {sortMenuOptions.map((option) => (
          <MenuItem
            onClick={() => {
              handleSortChange(option.sortByOption, option.sortOrder);
              setSortOption(option.label);
            }}
            bg={option.label === sortOption ? "gray.300" : "white"}
            _hover={{ bg: "gray.100" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
