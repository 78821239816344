import { Text, UnorderedList, VStack } from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import IModal from "../../../../../../components/IModal";
import { ListItemComponent } from "../../../../../../components/ListItemComponent";

export default function SfOverrideModal({
  completeSfSync,
  isOpen,
  goToPrevStep,
  completingSetup,
}: {
  completeSfSync: (autoMap: boolean) => void;
  isOpen: boolean;
  goToPrevStep: VoidFunction;
  completingSetup?: boolean;
}) {
  function startSync() {
    completeSfSync(true);
  }

  return (
    <IModal
      isOpen={isOpen}
      onClose={goToPrevStep}
      hideCloseButton={true}
      size="xl"
      header={{
        title: `Start Sync confirmation`,
      }}
      primaryButton={{
        label: "Start Sync Now",
        props: {
          onClick: startSync,
          isLoading: completingSetup,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: goToPrevStep,
          isLoading: completingSetup,
        },
      }}
    >
      <VStack alignItems="flex-start">
        <Text fontSize="sm">
          Are you sure you want to start the Salesforce sync? Starting the sync
          now will:
        </Text>
        <UnorderedList pl="20px">
          <ListItemComponent IconType={FaCircle}>
            <Text as="b">
              <Text as="u">Override any previous field mappings</Text>
            </Text>{" "}
            including mappings of any other connections like segment etc, and
            replace it with the default Salesforce field mapping.
          </ListItemComponent>
          <ListItemComponent IconType={FaCircle}>
            Start the sync immediately
          </ListItemComponent>
        </UnorderedList>
        <Text fontSize="sm">
          If you want to map the fields manually and start the sync later, click
          on Custom Settings and finish setup later
        </Text>
      </VStack>
    </IModal>
  );
}
