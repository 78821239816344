import { Tooltip, Text } from "@chakra-ui/react";

export default function TableEntryText({ children }: { children: string }) {
  return (
    <Tooltip label={children} fontSize="xs">
      <Text
        maxWidth="500px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {children}
      </Text>
    </Tooltip>
  );
}
