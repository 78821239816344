import { Box, Flex } from "@chakra-ui/layout";
import { Completion } from "@codemirror/autocomplete";
import { EditorView, ViewPlugin } from "@codemirror/view";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  createCompletion,
  createCompletionResult,
  createHighlightViewPlugin,
} from "../../../common/helper/codemirrorHelper";
import {
  isBlank,
  isFinished,
  isFulfilled,
  isLoading,
  isSuccess,
} from "../../../common/helper/commonHelper";
import {
  EmailTokenFunction,
  EmailTokenFunctions,
  EmailTokenUpdate,
} from "../../../common/types/campaign";
import Codemirror from "../../../components/codemirror/Codemirror";
import {
  getAllColumns,
  getAllFunctions,
  getEmailToken,
  getEmailTokenPreview,
  listAllEmailTokens,
  setTokenDefinitionDirty,
  resetTokenData,
  selectEmailToken,
  setTokenHeaderDirty,
  updateEmailToken,
  verifyToken,
} from "./emailTokenSlice";
import { toast } from "react-toastify";
import TokenPreview from "./components/EmailTokenPreview";
import FunctionDescription from "./components/FunctionDescription";
import TokenHeader from "./components/TokenHeader";
import { InvalidFields } from "../../../common/types/token";
import GettingStarted from "./components/GettingStarted";
import { Diagnostic, linter } from "@codemirror/lint";
import { useAppDispatch } from "../../../store";
import { PreventNavigationModal } from "../../../components/PreventNavigationModal";
import TokenForm from "./components/TokenForm";
import { Divider, HStack } from "@chakra-ui/react";
import { TOKEN_RETURN_TYPE } from "../../../common/constants/token";
import { selectSettings } from "../settings/settingsSlice";
import ISkeleton, { SKELETON_VARIANT } from "../../../components/ISkeleton";
import { verifyTokenApi } from "../../../common/api/campaign/tokens";

export default function EmailToken() {
  const dispatch = useAppDispatch();
  let { id } = useParams<{ id: string }>();

  const tokenInit: EmailTokenUpdate = {
    token: "",
    token_id: "",
    default_value: "",
    description: "",
  };

  const fields: InvalidFields = {
    token: "",
    default_value: "",
    description: "",
  };

  const [token, setToken] = useState<EmailTokenUpdate>(tokenInit);
  const [invalidFields, setInvalidFields] = useState<InvalidFields>(fields);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedFunction, setSelectedFunction] =
    useState<EmailTokenFunction>();

  const [autoCompleteItems, setAutoCompleteItems] = useState<Completion[]>([]);
  const [defaultValueReset, setDefaultValueReset] = useState(false);

  // eslint-disable-next-line
  const autoCompleteOptions = useCallback(
    createCompletionResult(autoCompleteItems),
    [autoCompleteItems]
  );

  const localReinitCodemirror = useRef(false);

  useEffect(() => {
    return () => {
      dispatch(resetTokenData());
    };
  }, [dispatch]);

  const {
    emailTokenList: {
      listAll: { data: allTokensList, loading: fetchingTokenList },
    },
    emailTokenEdit: {
      tokenDetails,
      fetchingTokenDetails,
      updatingTokenDetails,
      isTokenDefinitionDirty,
      isTokenHeaderDirty,
    },
    allColumnsList,
    allFunctionsList,
  } = useSelector(selectEmailToken);

  const { fetchingPersonOrgMapping } = useSelector(selectSettings);

  useEffect(() => {
    dispatch(listAllEmailTokens());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(updatingTokenDetails)) {
      dispatch(getEmailTokenPreview([]));
    }
  }, [dispatch, updatingTokenDetails]);

  useEffect(() => {
    dispatch(getAllFunctions());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(fetchingPersonOrgMapping)) {
      dispatch(getAllColumns());
    }
  }, [dispatch, fetchingPersonOrgMapping]);

  useEffect(() => {
    if (
      isSuccess(fetchingTokenList) &&
      isSuccess(allFunctionsList.loading) &&
      isSuccess(allColumnsList.loading)
    )
      setAutoCompleteItems(
        createCompletion(
          allTokensList,
          allColumnsList.data,
          Object.values<EmailTokenFunction>(allFunctionsList.data),
          false
        )
      );

    const viewPlugins = createHighlightViewPlugin(
      allColumnsList.data,
      allTokensList,
      false
    );
    setViewPlugins([viewPlugins]);

    localReinitCodemirror.current = true;
    setTimeout(() => {
      localReinitCodemirror.current = false;
    });
  }, [
    dispatch,
    allTokensList,
    fetchingTokenList,
    allFunctionsList,
    allColumnsList,
  ]);

  const [viewPlugins, setViewPlugins] = useState<ViewPlugin<any>[]>([]);

  useEffect(() => {
    if (id) dispatch(getEmailToken(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (tokenDetails) {
      const { token_id, token, default_value, description } = tokenDetails;
      setToken({ token_id, token, default_value, description });
    }
  }, [tokenDetails]);

  function headerOnChange(val: EmailTokenUpdate) {
    setToken((prev) => ({ ...prev, ...val }));
    dispatch(setTokenHeaderDirty(true));
    setDefaultValueReset(false);
  }

  const [validatingToken, setValidatingToken] = useState<boolean>(false);
  const [returnType, setReturnType] = useState("");

  async function validateToken(code: string) {
    if (code) {
      const result = await dispatch(verifyToken(code));
      if (isFulfilled(result.meta.requestStatus)) {
        const tokenValidity = result.payload as Awaited<
          ReturnType<typeof verifyTokenApi>
        >;

        if (tokenValidity.error) {
          if (tokenValidity.error.position) {
            setInvalidFields((prev) => {
              return { ...prev, token: "Invalid syntax" };
            });
            return {
              valid: false,
              start: tokenValidity.error.position,
              end: tokenValidity.error.position + 3,
              message: tokenValidity.error.reason,
            };
          }
          setInvalidFields((prev) => {
            return { ...prev, token: "Invalid token" };
          });
          return {
            valid: false,
            end: 0,
            message: tokenValidity.error.reason,
          };
        } else {
          setReturnType(tokenValidity?.return_type + "");
          setInvalidFields((prev) => {
            return { ...prev, token: "" };
          });
          return { valid: true };
        }
      }
    }
    return { valid: false, message: "Syntax error" };
  }

  async function updateEditorError(view: EditorView) {
    const doc = view.state.doc;
    const text = doc.toString();
    let diagnostics: Diagnostic[] = [];
    setValidatingToken(true);
    const validity = await validateToken(text);
    if (validity && !validity.valid) {
      let start = validity.start || 0;
      let end =
        validity.end && validity.end <= doc.length ? validity.end : doc.length;
      diagnostics.push({
        severity: "error",
        message: validity.message ?? `Invalid syntax`,
        from: start,
        to: end,
      } as Diagnostic);
    }

    setValidatingToken(false);
    return diagnostics;
  }

  function onCodeChange(code: string) {
    setToken((prev) => ({ ...prev, ...{ token: code } }));
    dispatch(setTokenDefinitionDirty(true));
  }

  function validateFields(token: EmailTokenUpdate): InvalidFields {
    const newInvalidFields = cloneDeep(invalidFields);
    if (
      [
        TOKEN_RETURN_TYPE.STRING,
        TOKEN_RETURN_TYPE.HASHMAP,
        TOKEN_RETURN_TYPE.ARRAY,
      ].includes(returnType as TOKEN_RETURN_TYPE)
    ) {
      newInvalidFields.default_value = "";
    } else {
      newInvalidFields.default_value = isBlank(token.default_value)
        ? "Fallback value cannot be empty"
        : "";
    }
    newInvalidFields.description = token.description
      ? ""
      : "Description is required";
    newInvalidFields.token = token.token
      ? newInvalidFields.token
      : "Token code is required";

    setInvalidFields(newInvalidFields);

    return newInvalidFields;
  }

  function setDefaultFallbackValue(
    token: EmailTokenUpdate,
    returnType: string
  ) {
    const newToken = cloneDeep(token);

    if (returnType === TOKEN_RETURN_TYPE.HASHMAP) {
      newToken.default_value = {};
    } else if (returnType === TOKEN_RETURN_TYPE.ARRAY) {
      newToken.default_value = [];
    }
    return newToken;
  }

  function saveToken() {
    const errorMsgs = Object.values(validateFields(token)).filter((val) => val);
    if (!errorMsgs.length) {
      const newToken = setDefaultFallbackValue(token, returnType);
      dispatch(updateEmailToken(newToken));
    } else {
      toast.error(errorMsgs.join(", "));
    }
  }

  const setFunctionDesc = useCallback(
    (functionName: string) => {
      if (
        allFunctionsList.data &&
        allFunctionsList.data.length &&
        functionName
      ) {
        Object.entries(allFunctionsList.data as EmailTokenFunctions).forEach(
          ([key, value]) => {
            if (value.name.toLowerCase() === functionName.toLowerCase()) {
              setSelectedFunction(value);
            }
          }
        );
      }
    },
    [allFunctionsList.data]
  );

  useEffect(() => {
    if (selectedCode) {
      setFunctionDesc(selectedCode);
    }
  }, [setFunctionDesc, selectedCode]);

  const prevParserReturnType = useRef<string>("");

  useEffect(() => {
    // Tokens just added will not have tokenType
    if (
      !tokenDetails?.return_type &&
      returnType &&
      returnType !== prevParserReturnType.current
    ) {
      prevParserReturnType.current = returnType;

      headerOnChange({ ...token, default_value: "" });
      if (token.default_value) {
        setDefaultValueReset(true);
      }

      /**
       * fallback value is reset to avoid auto conversion of value when parser return type changes
       * auto conversion causes unexpected values to show up in input field
       * Don't need to reset value for tokens when editing because return type of token can't be changed
       */
      // onChangeHandler("", DEFAULT_VALUE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnType]);

  function isTokenSaveDisabled(): boolean {
    return (
      !!(
        (tokenDetails?.return_type &&
          returnType !== "undefined" &&
          tokenDetails.return_type !== returnType) ||
        invalidFields.token
      ) || !(isTokenDefinitionDirty || isTokenHeaderDirty)
    );
  }

  return (
    <>
      <TokenHeader
        isLoading={isLoading(fetchingTokenDetails)}
        isValidating={validatingToken}
        isSaveDisabled={isTokenSaveDisabled()}
        tokenType={tokenDetails?.return_type}
        tokenName={tokenDetails?.name || token.token_id}
        saveHandler={saveToken}
      />
      <Divider />
      <HStack alignItems="flex-start" h="400px" m="4" spacing="5">
        <Box width="300px" minW="200px">
          <TokenForm
            value={token}
            tokenType={tokenDetails?.return_type}
            parserReturnType={returnType}
            onChange={headerOnChange}
            invalidFields={invalidFields}
            fallbackValueReset={defaultValueReset}
            isLoading={isLoading(fetchingTokenDetails)}
          />
        </Box>
        <Flex
          flex="1"
          height="400px"
          borderColor="gray.200"
          borderWidth="1px"
          rounded="lg"
        >
          <ISkeleton
            variant={SKELETON_VARIANT.TEXT_AREA}
            isLoaded={isFinished(fetchingTokenDetails)}
          >
            <Codemirror
              reinitialize={localReinitCodemirror.current}
              style={{ height: "100%" }}
              value={token.token}
              onChange={onCodeChange}
              onFunctionSelection={setSelectedCode}
              theme={"light"}
              lang="jinja2"
              autoCompleteOptions={autoCompleteOptions}
              extensions={[linter(updateEditorError), EditorView.lineWrapping]}
              viewPlugins={viewPlugins}
              className="codemirror-rounded-left"
            />
          </ISkeleton>
          <Box
            w="30%"
            minW="350px"
            maxHeight="400px"
            overflow="auto"
            roundedRight="lg"
            borderColor="gray.200"
          >
            {selectedFunction ? (
              <FunctionDescription functionDetails={selectedFunction} />
            ) : (
              <GettingStarted />
            )}
          </Box>
        </Flex>
      </HStack>
      <TokenPreview />
      <PreventNavigationModal
        isActive={isTokenDefinitionDirty || isTokenHeaderDirty}
        onConfirm={() => {
          dispatch(setTokenDefinitionDirty(false));
          dispatch(setTokenHeaderDirty(false));
        }}
      />
    </>
  );
}
