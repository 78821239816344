import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const GREEN_RED = definePartsStyle({
  track: {
    bg: "red.600",
    _checked: {
      bg: "green",
    },
  },
  thumb: {
    height: "12px",
    width: "12px",
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: { GREEN_RED },
});
