import { Input, InputProps, NumberInputProps } from "@chakra-ui/react";
import { DESTINATION_TYPES } from "../common/types/unifiedMapping";
import DropdownWithSearch from "./DropdownWithSearch";
import NumberField, { NUMBER_FIELD_TYPES } from "./NumberField";

//TODO: enable inputs to show error messages

function booleanField(
  value: 0 | 1,
  onChange: (value: any) => void,
  isInvalid = false,
  isDisabled?: boolean
) {
  const options = [
    { label: "True", value: 1 },
    { label: "False", value: 0 },
  ];
  return (
    <DropdownWithSearch
      options={options}
      value={options.find((item) => item.value === value)}
      onChange={(option) => onChange(option?.value ?? true)}
      controlStyle={{
        width: "100%",
        height: "32px",
      }}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
    />
  );
}

function inputField(
  type: "text" | "date",
  value: any,
  onChange: (value: any) => void,
  isInvalid = false,
  maxlength?: number | null,
  inputProps?: InputProps
) {
  return (
    <Input
      placeholder=""
      value={value ?? ""}
      height="32px"
      onChange={(e) => onChange(e.target.value)}
      type={type}
      maxLength={maxlength!}
      isInvalid={isInvalid}
      bg="white"
      {...inputProps}
    />
  );
}

export function EditableFieldByType({
  type,
  value,
  maxlength,
  onChange,
  isInValid = false,
  allowNegativeNumbers,
  minValue,
  ...props
}: {
  type: DESTINATION_TYPES;
  value: any;
  maxlength?: number;
  onChange: (value: any) => void;
  isInValid?: boolean;
  allowNegativeNumbers?: boolean;
  minValue?: number;
} & InputProps) {
  const NUMBER_FIELD_PROPS = {
    value: value as number,
    onValueChange: onChange,
    isInvalid: isInValid,
    allowNegativeNumbers,
    min: minValue,
    ...(props as NumberInputProps),
  };

  switch (type) {
    case DESTINATION_TYPES.STRING:
      return inputField(
        "text",
        value as string,
        onChange,
        isInValid,
        maxlength,
        props
      );

    case DESTINATION_TYPES.DATETIME:
      return inputField(
        "date",
        value ? new Date(value as string).toISOString().substring(0, 10) : "",
        onChange,
        isInValid,
        undefined,
        props
      );

    case DESTINATION_TYPES.LONG:
      return inputField(
        "text",
        value as string,
        onChange,
        isInValid,
        maxlength,
        props
      );

    case DESTINATION_TYPES.INTEGER:
      return (
        <NumberField
          type={NUMBER_FIELD_TYPES.INTEGER}
          {...NUMBER_FIELD_PROPS}
        />
      );

    case DESTINATION_TYPES.FLOAT:
      return (
        <NumberField type={NUMBER_FIELD_TYPES.FLOAT} {...NUMBER_FIELD_PROPS} />
      );

    case DESTINATION_TYPES.BOOLEAN:
      const { isDisabled } = props;
      return booleanField(value, onChange, isInValid, isDisabled);

    default:
      return value;
  }
}
