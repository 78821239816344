import {
  Checkbox,
  Heading,
  VStack,
  Text,
  Divider,
  HStack,
  TextProps,
  Icon,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import InputFieldWithError from "../../../../../components/InputFieldWithError";
import MemoizedCommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";
import { InitSalesforceConReqType } from "../../../../../common/types/salesforce";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { TbApi } from "react-icons/tb";

function SalesforceInstruction({
  text,
  icon,
  ...props
}: {
  text: string;
  icon?: ReactNode;
} & TextProps) {
  return (
    <HStack color="text.50" alignItems="flex-start">
      {icon}
      <Text fontSize="xs" {...props}>
        {text}
      </Text>
    </HStack>
  );
}

export default function SalesforceFlinkConnectionModal({
  isOpen,
  onClose,
  submit,
  isInitializingConnection,
}: {
  isOpen: boolean;
  onClose: () => void;
  submit: (data: InitSalesforceConReqType) => void;
  isInitializingConnection?: boolean;
}) {
  const [isSandboxInstance, setIsSandboxInstance] = useState(false);
  const [connectionName, setConnectionName] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setIsSandboxInstance(false);
      setConnectionName("");
    }
  }, [isOpen]);

  function submitHandler() {
    submit({ connectionName, isSandbox: isSandboxInstance });
  }

  return (
    <MemoizedCommonDrawer
      size="sm"
      placement="right"
      title="Salesforce"
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack alignItems="flex-start" spacing="4">
        <Heading as="h4" fontSize="md">
          Ready to connect Salesforce and Inflection?
        </Heading>

        <Text fontSize="sm">
          There are a few steps to follow, but the process should only take
          about 10-15 minutes.
        </Text>

        <VStack
          alignItems="flex-start"
          bg="oneOffs.sidebarBackground2"
          w="100%"
          p={2}
          rounded="md"
        >
          <SalesforceInstruction
            text="Before you start, make sure:"
            fontWeight="bold"
            icon={<Icon as={FaInfoCircle} fontSize="xs" mt={1} />}
          />

          <SalesforceInstruction
            text="To have administrator access to your Salesforce account"
            icon={<Icon as={MdOutlineAdminPanelSettings} fontSize="sm" />}
          />
          <SalesforceInstruction
            text="Your Salesforce edition has API access"
            icon={<Icon as={TbApi} fontSize="md" />}
          />
        </VStack>

        <Divider />
        <HStack alignItems="flex-start">
          <Checkbox
            isChecked={isSandboxInstance}
            onChange={(e) => setIsSandboxInstance(e.target.checked)}
            my={1}
          />
          <Text fontSize="sm">
            Select if your Salesforce is a Sandbox instance
            <Text as="span" color="orange.300">
              {" "}
              (only if applicable)
            </Text>
          </Text>
        </HStack>

        <InputFieldWithError
          labelText="Connection name"
          value={connectionName}
          onChange={(e) => setConnectionName(e.target.value)}
          isRequired={true}
          autoFocus
        />
      </VStack>
      <ConnectionButtons
        closeModal={onClose}
        label="Save and login to Salesforce"
        name="save-button"
        isLoading={isInitializingConnection}
        onClick={submitHandler}
        isDisabled={!connectionName}
      />
    </MemoizedCommonDrawer>
  );
}
