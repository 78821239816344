import {
  Box,
  Flex,
  Badge,
  BoxProps,
  HStack,
  StackProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import { EditableGroupName } from "./EditableGroupName";

export function FilterGroupBox({
  label,
  children,
  groupName,
  onRemove,
  type,
  onGroupNameChange,
  isReadOnly,
  additionalComponent,
  readOnlyProps,
  ...sx
}: {
  label: string;
  children: ReactNode;
  groupName: string;
  type?: "filter" | "property";
  onRemove: () => void;
  onGroupNameChange: (value: string) => void;
  isReadOnly?: boolean;
  additionalComponent?: ReactNode;
  readOnlyProps?: StackProps;
} & BoxProps) {
  const isProperty = type === "property";

  if (isReadOnly) {
    return (
      <HStack
        width="100%"
        fontSize="14px"
        alignItems="flex-start"
        rounded="md"
        p={2}
        bgColor={isProperty ? "grayV2.300" : "grayV2.200"}
        fontWeight="500"
        {...readOnlyProps}
      >
        {children}
      </HStack>
    );
  } else {
    return (
      <Box
        rounded="lg"
        borderColor="gray.200"
        borderWidth="1px"
        bgColor="grayV2.200"
        width="100%"
        mt={2}
        {...sx}
      >
        <Flex
          roundedTop="lg"
          alignItems="center"
          p="2"
          width="100%"
          justifyContent="space-between"
          borderColor="gray.200"
        >
          <HStack maxW="95%" wrap="wrap" gridRowGap={2} spacing={0}>
            <EditableGroupName
              placeholder={label}
              groupName={groupName}
              onGroupNameChange={onGroupNameChange}
            >
              <Badge
                fontSize="10px"
                colorScheme={isProperty ? undefined : "teal"}
                style={
                  isProperty ? { color: "white", backgroundColor: "black" } : {}
                }
                variant="solid"
              >
                {label}
              </Badge>
            </EditableGroupName>
            {additionalComponent}
          </HStack>
          <RemoveRowCloseButton onClick={onRemove} />
        </Flex>
        {children}
      </Box>
    );
  }
}
