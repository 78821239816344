import { Box, Divider, Heading, VStack } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  convertToProductPersonType,
  sortDetails,
} from "../../../../common/helper/commonHelper";
import {
  PersonDestinationFields,
  ProductPersonType,
} from "../../../../common/types/person";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  getAccount,
  selectDirectoryAccount,
} from "../../accountdb/accountDbSlice";
import DisplayGrid from "./DisplayGrid";

enum CONTACT_FIELD_TYPES {
  DEFAULT = "default",
  CUSTOM = "custom",
  INFERRED = "inferred",
}

export default function PersonInfo({
  details,
  editDetails,
  enableEdit,
}: {
  details: PersonDestinationFields;
  editDetails: (key: string, value: string) => void;
  enableEdit: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountId = useMemo(() => details?.account_id?.value, [details]);
  const {
    accountDetails: { data: accountDetails },
  } = useSelector(selectDirectoryAccount);

  useEffect(() => {
    if (accountId) {
      dispatch(getAccount(accountId));
    }
  }, [accountId, dispatch]);

  const redirectToAccount = useCallback(
    (accountId: string) => navigate(`${urls.account}/${accountId}`),
    [navigate]
  );

  const getNewAccountDetails = useCallback(
    (newEntries: ProductPersonType[]) => {
      const newAccountDetails = newEntries.filter(({ key, details }) => {
        return key === "account_id" && details;
      });
      return newAccountDetails;
    },
    []
  );

  const createNewAccountData = useCallback(
    (newAccountDetails: ProductPersonType[]) => {
      if (newAccountDetails[0]) {
        const newAccountData: ProductPersonType = {
          key: cloneDeep(newAccountDetails[0]?.key),
          details: cloneDeep(newAccountDetails[0]?.details),
          isHyperLink: false,
          redirectPage: () => {},
        };
        return newAccountData;
      }
    },
    []
  );

  const createNewAccountIdData = useCallback(
    (newAccountDetails: ProductPersonType[]) => {
      if (newAccountDetails[0]) {
        const newAccountData: ProductPersonType = {
          key: cloneDeep(newAccountDetails[0]?.key),
          details: cloneDeep(newAccountDetails[0]?.details),
        };
        return newAccountData;
      }
    },
    []
  );

  const addAccountField = useCallback(
    (newEntries: ProductPersonType[]) => {
      const newAccountDetails = getNewAccountDetails(newEntries);
      const newAccountData = createNewAccountData(newAccountDetails);
      const newAccountIdData = createNewAccountIdData(newAccountDetails);
      if (newAccountData && newAccountIdData) {
        newAccountData.details.name = "account";
        newAccountData.details.display = "Account";
        newAccountData.details.read_only = newAccountIdData.details.read_only =
          true;
        newAccountData.details.updatable = newAccountIdData.details.updatable =
          false;
        newAccountData.details.value = null;
        if (accountId === accountDetails[0]?.id) {
          newAccountData.isHyperLink = !!accountId;
          newAccountData.redirectPage = () => redirectToAccount(accountId);
          newAccountData.details.value = accountDetails[0]?.name;
        }
        const accountIdIndex = newEntries.findIndex(
          (val) => val.key === "account_id"
        );
        newEntries.splice(accountIdIndex, 1);
        newEntries.splice(accountIdIndex, 0, newAccountIdData);
        newEntries.splice(accountIdIndex, 0, newAccountData);
      }
    },
    [
      accountDetails,
      accountId,
      createNewAccountData,
      createNewAccountIdData,
      getNewAccountDetails,
      redirectToAccount,
    ]
  );

  function displayFields(contactFieldType: CONTACT_FIELD_TYPES) {
    let filteredEntries;
    let sectionTitle;

    switch (contactFieldType) {
      case CONTACT_FIELD_TYPES.DEFAULT:
        filteredEntries = Object.entries(details)
          .filter(
            (item) => !item[1].hidden && !item[1].custom && !item[1].inferred
          )
          .sort(sortDetails);
        sectionTitle = "Default fields";
        break;

      case CONTACT_FIELD_TYPES.CUSTOM:
        filteredEntries = Object.entries(details)
          .filter((item) => !item[1].hidden && item[1].custom)
          .sort(sortDetails);
        sectionTitle = "Custom fields";
        break;

      case CONTACT_FIELD_TYPES.INFERRED:
        filteredEntries = Object.entries(details)
          .filter((item) => !item[1].hidden && item[1].inferred)
          .sort(sortDetails);
        sectionTitle = "Inferred fields";
        break;
    }
    let newEntries: ProductPersonType[];
    if (filteredEntries.length) {
      newEntries = convertToProductPersonType(filteredEntries);
      addAccountField(newEntries);

      return (
        <>
          <Box px={8} py={5}>
            <Heading fontSize="md" color="brand.blue">
              {sectionTitle}
            </Heading>
            <DisplayGrid
              entries={newEntries as ProductPersonType[]}
              enableEdit={enableEdit}
              editDetails={editDetails}
            />
          </Box>
          <Divider />
        </>
      );
    }
  }

  return (
    <VStack w="100%" alignItems="flex-start" spacing={5}>
      {Object.values(CONTACT_FIELD_TYPES).map((fieldType) =>
        displayFields(fieldType)
      )}
    </VStack>
  );
}
