import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import urls from "../urls";

function redirectToContact(contactId: string) {
  if (contactId) {
    window.open(`${urls.person}/${contactId}`, "_blank");
  }
}

export function RedirectToContact({
  email,
  personId,
}: {
  email: ReactNode;
  personId: string;
}) {
  return (
    <RedirectToPage
      label={email}
      redirectTo={personId ? () => redirectToContact(personId) : undefined}
    />
  );
}

export default function RedirectToPage({
  label,
  redirectTo,
}: {
  label: ReactNode;
  redirectTo?: () => void;
}) {
  return (
    <HStack>
      <Text>{label}</Text>
      <Box hidden={!label || !redirectTo}>
        <Icon
          as={FaExternalLinkAlt}
          fontSize="12"
          onClick={() => redirectTo?.()}
          cursor="pointer"
        />
      </Box>
    </HStack>
  );
}
