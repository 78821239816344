import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import urls from "../../urls";
import {
  selectAccount,
  getOrganisationList,
  getAccountList,
  impersonate,
  resetImpersonationStatus,
} from "./accountSlice";
import { Form, Formik } from "formik";
import { object, number } from "yup";
import { capitalize } from "lodash";
import SpinnerContainer from "../../components/SpinnerContainer";
import { isSuccess, isLoading } from "../../common/helper/commonHelper";
import DropdownWithSearch from "../../components/DropdownWithSearch";
import IButton from "../../components/IButton";

interface ImpersonateData {
  organisation_id: number;
  account_id: number;
}

export default function Impersonate() {
  const { organisationList, personList, impersonateStatus } =
    useSelector(selectAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const impersonateSchema = object().shape({
    organisation_id: number().required("Organization is required"),
    account_id: number().required("Person is required"),
  });

  useEffect(() => {
    dispatch(getOrganisationList());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess(impersonateStatus)) {
      navigate(urls.unifiedMapping, { replace: true });
    }
    return () => {
      dispatch(resetImpersonationStatus());
    };
  }, [dispatch, navigate, impersonateStatus]);

  function selectOrg(value: number | undefined) {
    if (value) {
      dispatch(getAccountList(Number(value)));
    }
  }

  function impersonateSubmit(values: ImpersonateData) {
    dispatch(impersonate(values));
  }

  return (
    <SpinnerContainer height="90vh" loading={organisationList.pending}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Logo mx="auto" h="8" mb={{ base: "10", md: "20" }} />
        <Formik
          enableReinitialize={true}
          initialValues={{ organisation_id: 0, account_id: 0 }}
          onSubmit={impersonateSubmit}
          validationSchema={impersonateSchema}
        >
          {(formik) => (
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormControl my={4}>
                <FormLabel color="gray.500" fontSize="xs">
                  Organization
                </FormLabel>
                <DropdownWithSearch
                  options={organisationList.list}
                  getOptionLabel={(option) => capitalize(option.name)}
                  getOptionValue={(option) => `${option.id}`}
                  onChange={(option) => {
                    selectOrg(option?.id);
                    formik.setFieldValue("organisation_id", option?.id ?? "");
                  }}
                  value={organisationList.list.find(
                    (org) => org.id === formik.values.organisation_id
                  )}
                />
              </FormControl>
              {!personList.pending &&
                personList.list &&
                !!personList.list.length && (
                  <FormControl my={4}>
                    <FormLabel color="gray.500" fontSize="xs">
                      Person
                    </FormLabel>
                    <DropdownWithSearch
                      options={personList.list}
                      getOptionLabel={(option) => option.email}
                      getOptionValue={(option) => `${option.id}`}
                      onChange={(option) =>
                        formik.setFieldValue("account_id", option?.id ?? "")
                      }
                      value={personList.list.find(
                        (person) => person.id === formik.values.account_id
                      )}
                    />
                  </FormControl>
                )}
              <IButton
                type="submit"
                width={300}
                mt={4}
                isLoading={isLoading(impersonateStatus)}
                isDisabled={
                  !formik.values.account_id || !formik.values.organisation_id
                }
                colorScheme="blue"
                size="lg"
                fontSize="md"
              >
                Impersonate
              </IButton>
            </Form>
          )}
        </Formik>
      </Box>
    </SpinnerContainer>
  );
}
