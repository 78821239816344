import {
  Center,
  Divider,
  Flex,
  VStack,
  Text,
  HStack,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { addDays, addHours, format, formatISO, set, subDays } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  LineChart,
  Line,
  Legend,
} from "recharts";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { PaginationType } from "../../../../../common/types/common";
import {
  ReportRange,
  SegmentError,
  SegmentReportRow,
  SegmentSourceDestination,
  SEGMENT_SYNC_TYPES,
} from "../../../../../common/types/connection";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import Pagination from "../../../../../components/Pagination";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import {
  getSegmentDestinationErrorReport,
  getSegmentDestinationEventReport,
  getSegmentSourceErrorReport,
  getSegmentSourceEventReport,
  listSegmentDestinationErrors,
  listSegmentSourceErrors,
  selectConnection,
  setSegmentDestErrorPage,
  setSegmentSourceErrorPage,
} from "../../connectionSlice";
import SubHeader from "../../components/SubHeader";

const options = [
  {
    name: "Last 24 hours",
    unit: 1,
  },
  {
    name: "Last 30 days",
    unit: 30,
  },
  {
    name: "Last 60 days",
    unit: 60,
  },
  {
    name: "Last 90 days",
    unit: 90,
  },
];

function generateChartData(
  dateRange: number,
  events: SegmentReportRow[],
  errors: SegmentReportRow[]
) {
  const endDate = set(new Date(), { minutes: 0, seconds: 0 });
  const startDate = subDays(endDate, dateRange);

  let j = 0,
    k = 0;

  let data: {
    date: string;
    events: number;
    errors: number;
  }[] = [];

  if (dateRange === 1) {
    for (let i = 0; i < 24; i++) {
      const date = format(addHours(startDate, i), "yyyy-MM-dd");
      const hour = +format(addHours(startDate, i), "H");
      const xAxis = format(addHours(startDate, i), "MM-dd-yyyy - p");
      let errorCount = 0;
      let eventCount = 0;

      if (
        events.length &&
        j < events.length &&
        events[j].date === date &&
        events[j].hour === hour
      ) {
        eventCount = events[j].count;
        j++;
      }

      if (
        errors.length &&
        k < errors.length &&
        errors[k].date === date &&
        errors[k].hour === hour
      ) {
        errorCount = errors[k].count;
        k++;
      }

      data.push({
        date: xAxis,
        events: eventCount,
        errors: errorCount,
      });
    }
  } else {
    for (let i = 0; i < dateRange; i++) {
      const date = format(addDays(startDate, i), "yyyy-MM-dd");
      const xAxis = format(addDays(startDate, i), "MM-dd-yyyy");

      let errorCount = 0;
      let eventCount = 0;

      if (events.length && j < events.length && events[j].date === date) {
        eventCount = events[j].count;
        j++;
      }

      if (errors.length && k < errors.length && errors[k].date === date) {
        errorCount = errors[k].count;
        k++;
      }

      data.push({
        date: xAxis,
        errors: errorCount,
        events: eventCount,
      });
    }
  }
  return data;
}

function ActivityChart({
  loading,
  dateRange,
  events,
  errors,
}: {
  loading: boolean;
  dateRange: number;
  events: SegmentReportRow[];
  errors: SegmentReportRow[];
}) {
  const data = useMemo(
    () => generateChartData(dateRange, events, errors),
    [dateRange, errors, events]
  );

  return (
    <Box width={"100%"} height="300px">
      {loading ? (
        <SpinnerContainer width="100%" height={"300px"} loading>
          ...
        </SpinnerContainer>
      ) : (
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <Legend verticalAlign="top" align="right" height={36} />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis fontSize={12} dataKey="date" />
            <YAxis
              fontSize={"10"}
              yAxisId="left"
              dataKey="events"
              orientation="left"
              width={80}
              label={{ value: "Events", angle: -90 }}
            />
            <YAxis
              fontSize={"10"}
              yAxisId="right"
              dataKey="errors"
              width={80}
              label={{ value: "Errors", angle: 90 }}
              orientation="right"
            />
            <ChartTooltip />
            <Line
              dot={false}
              strokeWidth={2}
              type="monotone"
              yAxisId="left"
              dataKey="events"
              stroke="#0CCFCF"
              legendType="rect"
            />
            <Line
              dot={false}
              strokeWidth={2}
              type="monotone"
              yAxisId="right"
              dataKey="errors"
              stroke="#FF4D42"
              legendType="rect"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}

function SyncErrors({
  errorList,
  changePage,
}: {
  errorList: PaginationType<SegmentError>;
  changePage: (num: number) => void;
}) {
  return (
    <>
      <SubHeader title="Sync Errors" height="45px" />
      <SpinnerContainer loading={errorList.fetchingList} height="350px">
        {!isEmpty(errorList.list) ? (
          <>
            <VStack
              spacing="3"
              maxH="calc(100vh - 545px)"
              overflow="auto"
              pr="15px"
            >
              {errorList.list?.map((segment, index) => {
                return <ErrorHistoryItem item={segment} key={index} />;
              })}
            </VStack>
            <Pagination
              mt="2"
              currentPage={errorList.currentPageNo}
              pageCount={errorList.totalPageCount}
              onPageChange={changePage}
            />
          </>
        ) : (
          <Center
            h="200px"
            fontSize="sm"
            color="gray.400"
            borderTop="1px"
            borderColor="gray.100"
          >
            No errors found
          </Center>
        )}
      </SpinnerContainer>
    </>
  );
}

function ErrorHistoryItem({ item }: { item: SegmentError }) {
  return (
    <Flex
      width="100%"
      border="1px"
      borderColor="gray.200"
      p="2"
      rounded="md"
      justifyContent="space-between"
    >
      <HStack
        fontSize="sm"
        fontWeight="semibold"
        color="brand.blue"
        width={"80%"}
      >
        <Text as="span" color="red.500">
          {item.exception}
        </Text>
        <Tooltip label={item.description} openDelay={500}>
          <Text as="span" isTruncated>
            {item.description}
          </Text>
        </Tooltip>
      </HStack>
      <Text fontSize="sm" textAlign="right" fontWeight="semibold" width={"25%"}>
        {format(new Date(item.time_created), "PPpp")}
      </Text>
    </Flex>
  );
}

export default function SegmentActivityLog() {
  const [dateRange, setDateRange] = useState(options[0].unit);
  const [syncType, setSyncType] = useState<SegmentSourceDestination>(
    SEGMENT_SYNC_TYPES.SOURCE
  );

  const dispatch = useDispatch();

  const {
    segment: {
      syncDetails: {
        data: { connection_type: connectionType },
      },
      sourceSyncDetails: {
        errors: srcErrors,
        errors: { currentPageNo: srcErrorsPgNo, pageSize: srcErrorsPgSize },
        eventReport: { data: srcEventReport, loading: srcEventsLoading },
        errorReport: { data: srcErrorReport, loading: srcErrorsLoding },
      },
      destinationSyncDetails: {
        errors: destErrors,
        errors: { currentPageNo: destErrorsPgNo, pageSize: destErrorsPgSize },
        eventReport: { data: destEventReport, loading: destEventsLoading },
        errorReport: { data: destErrorReport, loading: destErrorsLoding },
      },
    },
  } = useSelector(selectConnection);

  const getGraphData =
    syncType === SEGMENT_SYNC_TYPES.SOURCE
      ? {
          isLoading: isLoading(srcEventsLoading) || isLoading(srcErrorsLoding),
          errorList: srcErrors,
          eventReport: srcEventReport,
          errorReport: srcErrorReport,
        }
      : {
          isLoading:
            isLoading(destEventsLoading) || isLoading(destErrorsLoding),
          errorList: destErrors,
          eventReport: destEventReport,
          errorReport: destErrorReport,
        };

  const filterSyncItems = useMemo(() => {
    let syncOptions = [
      { label: "Segment to Inflection", value: SEGMENT_SYNC_TYPES.SOURCE },
    ];

    return connectionType === SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION
      ? [
          ...syncOptions,
          {
            label: "Inflection to Segment",
            value: SEGMENT_SYNC_TYPES.DESTINATION,
          },
        ]
      : syncOptions;
  }, [connectionType]);

  useEffect(() => {
    const endDate = new Date();
    const startDate = subDays(endDate, dateRange);
    if (syncType === SEGMENT_SYNC_TYPES.SOURCE) {
      dispatch(
        listSegmentSourceErrors({
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
          pageNumber: srcErrorsPgNo,
          pageSize: srcErrorsPgSize,
        })
      );
    } else {
      dispatch(
        listSegmentDestinationErrors({
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
          pageNumber: destErrorsPgNo,
          pageSize: destErrorsPgSize,
        })
      );
    }
  }, [
    dispatch,
    syncType,
    dateRange,
    srcErrorsPgNo,
    srcErrorsPgSize,
    destErrorsPgNo,
    destErrorsPgSize,
  ]);

  useEffect(() => {
    const timeUnit = dateRange === 1 ? "hour" : "day";
    const timeRange = String(timeUnit === "hour" ? 24 : dateRange);
    const tzOffset = new Date().getTimezoneOffset();

    let data: ReportRange = {
      time_unit: timeUnit,
      time_range: timeRange,
      tz_offset: tzOffset,
    };

    if (syncType === SEGMENT_SYNC_TYPES.SOURCE) {
      dispatch(getSegmentSourceEventReport(data));
      dispatch(getSegmentSourceErrorReport(data));
    } else {
      dispatch(getSegmentDestinationEventReport(data));
      dispatch(getSegmentDestinationErrorReport(data));
    }
  }, [dispatch, dateRange, syncType]);

  function changingErrorPage(pgNo: number) {
    if (syncType === SEGMENT_SYNC_TYPES.SOURCE) {
      dispatch(setSegmentSourceErrorPage(pgNo));
    } else {
      dispatch(setSegmentDestErrorPage(pgNo));
    }
  }

  return (
    <>
      <HStack pos="absolute" top="7px" right="15px">
        <DropdownWithSearch
          options={filterSyncItems}
          value={filterSyncItems.find((option) => option.value === syncType)}
          onChange={(option) => {
            setSyncType(option?.value ?? "");
          }}
        />
        <DropdownWithSearch
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => `${option.unit}`}
          onChange={(option) => {
            setDateRange(option?.unit || options[0].unit);
          }}
          isClearable={false}
          value={options.find((opt) => opt.unit === dateRange)}
          controlStyle={{ width: "150px" }}
        ></DropdownWithSearch>
      </HStack>
      <SubHeader title="Sync Activity" height="45px"></SubHeader>
      <Divider />

      <ActivityChart
        dateRange={dateRange}
        loading={getGraphData.isLoading}
        errors={getGraphData.errorReport}
        events={getGraphData.eventReport}
      />

      <SyncErrors
        errorList={getGraphData.errorList}
        changePage={changingErrorPage}
      />
    </>
  );
}
