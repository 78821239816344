import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../urls";
import {
  checkEmailTokenIsValid,
  setRegisterValidationToken,
  selectAccount,
} from "./accountSlice";
import SpinnerContainer from "../../components/SpinnerContainer";
import MessageDisplay from "../../components/MessageDisplay";
import { Box } from "@chakra-ui/react";
import { Logo } from "../../components/Logo";

export default function ValidateEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      dispatch(setRegisterValidationToken(token));
    }
  }, [token, dispatch]);

  const { registerValidation } = useSelector(selectAccount);

  useEffect(() => {
    if (registerValidation.token) {
      dispatch(
        checkEmailTokenIsValid({
          token: registerValidation.token,
        })
      );
    }
  }, [registerValidation.token, dispatch]);

  useEffect(() => {
    if (registerValidation.tokenValid) navigate(urls.login);
  }, [registerValidation.tokenValid, navigate]);

  return (
    <SpinnerContainer height="100vh" loading={registerValidation.tokenChecking}>
      <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box
          maxW="md"
          mx="auto"
          display="flex"
          flexDir="column"
          alignItems="center"
          marginTop={20}
        >
          <Logo mx="auto" h="8" mb={{ base: "10", md: "20" }} />
          <MessageDisplay
            heading={"Link Expired"}
            displayMessage="Your account activation link is no longer valid"
            redirectFn={() => navigate(urls.login)}
            redirectMsg="Go to Login page"
            type="error"
          ></MessageDisplay>
        </Box>
      </Box>
    </SpinnerContainer>
  );
}
