import { Box, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  SF_INCLUSION_LIST_TYPES,
  SfInclusionListType,
} from "../../../../../../../common/types/salesforce";
import { useAppDispatch } from "../../../../../../../store";
import { listAllDynamicLists } from "../../../../../../../components/dynamic-list/dynamicListSlice";
import { listStaticListSummaryList } from "../../../../../staticlist/staticlistSlice";
import { Header } from "./Header";
import { NoteBox } from "./NoteBox";
import ListSelectionDropdownWithPreview, {
  LIST_SELECTION_DROPDOWN_VARIANT,
} from "../../../../../../../components/ListSelectionDropdown";
import { SingleValue } from "react-select";
import { AssetField } from "../../../../../../../common/types/connection";
import { INCLUSION_LIST_TYPES } from "../../../../../../../common/types/campaign";
import { EMPTY_CONTEXT } from "../../../../../../../common/constants/common";

const MAP_INCLUSION_LIST_TYPES = {
  [INCLUSION_LIST_TYPES.DYNAMIC]: SF_INCLUSION_LIST_TYPES.DYNAMIC,
  [INCLUSION_LIST_TYPES.STATIC]: SF_INCLUSION_LIST_TYPES.STATIC,
};

export default function InflectionToSalesforceInclusionList({
  inclusionList,
  onChangeList,
  isLoading = false,
  isReadOnly = false,
  isInvalid = false,
}: {
  inclusionList: SfInclusionListType | null;
  onChangeList: (data: SfInclusionListType | null) => void;
  isLoading?: boolean;
  isReadOnly?: boolean;
  isInvalid?: boolean;
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAllDynamicLists());
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  function onInclusionListChange(list: SingleValue<AssetField>) {
    const updatedInclusionList =
      list && list.id
        ? {
            listIdentifier: list.id,
            listType:
              MAP_INCLUSION_LIST_TYPES[list.type as INCLUSION_LIST_TYPES],
          }
        : null;
    onChangeList(updatedInclusionList);
  }

  return (
    <Box>
      <Header
        title="Limiting what syncs"
        desc="An Inflection contact must be on the inclusion list to sync to Salesforce"
      />

      <Box mt="2">
        <NoteBox text="If no list is selected, all Inflection contacts will be synced to Salesforce" />
      </Box>

      <Text mt="5">Select an inclusion list</Text>

      <Box pt="3" width="400px">
        {isReadOnly && !inclusionList?.listIdentifier ? (
          <Text color="gray.400">{EMPTY_CONTEXT}</Text>
        ) : (
          <ListSelectionDropdownWithPreview
            variant={LIST_SELECTION_DROPDOWN_VARIANT.DYNAMIC_LIST}
            value={inclusionList?.listIdentifier ?? null}
            onChange={onInclusionListChange}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
          />
        )}
      </Box>
    </Box>
  );
}
