import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { FaEllipsisV, FaExternalLinkAlt } from "react-icons/fa";
import NumberFormat from "react-number-format";
import {
  formatLargeNumber,
  keepClickEventRestricted,
  redirectToContact,
} from "../../../../common/helper/commonHelper";
import {
  UserListMap,
  USER_ACCOUNT_STATUS,
} from "../../../../common/types/auth";
import {
  StaticListContactsData,
  StaticListDetails,
} from "../../../../common/types/campaign";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import { RedirectToContact } from "../../../../components/RedirectToPage";
import UserNameWithAvatar from "../../../../components/UserNameWithAvatar";

const staticListsColumnHelper = createColumnHelper<StaticListDetails>();
const staticListContactsColumnHelper =
  createColumnHelper<StaticListContactsData>();

export const staticListColumnDefs = (
  usersListMap: UserListMap,
  onDelete: (row: StaticListDetails) => void,
  onRename: (row: StaticListDetails) => void,
  findUser: (id: string, usersListMap: UserListMap) => string,
  getUserStatus: (id: string, usersListMap: UserListMap) => USER_ACCOUNT_STATUS
): ColumnDef<StaticListDetails, any>[] => {
  return [
    staticListsColumnHelper.accessor("name", {
      header: "Name",
      size: 400,
    }),
    staticListsColumnHelper.accessor("num_records", {
      header: "Number of contacts",
      cell: (info) => (
        <NumberFormat
          value={formatLargeNumber(info.getValue(), 2)}
          isNumericString={true}
          thousandSeparator={true}
          displayType="text"
        />
      ),
      size: 200,
    }),
    staticListsColumnHelper.accessor("updated_at", {
      header: "Last Updated On",
      cell: (info) => <FormatDate date={info.getValue()} splitLines />,
      size: 250,
    }),
    staticListsColumnHelper.accessor("updated_by", {
      header: "Last updated by",
      cell: (info) => (
        <UserNameWithAvatar
          username={findUser(info.getValue().toString(), usersListMap)}
          accountStatus={getUserStatus(
            info.getValue().toString(),
            usersListMap
          )}
        />
      ),
      size: 200,
    }),
    staticListsColumnHelper.accessor("created_at", {
      header: "Created on",
      cell: (info) => <FormatDate date={info.getValue()} splitLines />,
      size: 200,
    }),
    staticListsColumnHelper.accessor("created_by", {
      header: "Created by",
      cell: (info) => (
        <UserNameWithAvatar
          username={findUser(info.getValue().toString(), usersListMap)}
          accountStatus={getUserStatus(
            info.getValue().toString(),
            usersListMap
          )}
        />
      ),
      size: 250,
    }),
    staticListsColumnHelper.display({
      header: "",
      id: "actions",
      cell: ({ row }) => (
        <Flex alignItems="center" justifyContent="flex-end">
          <Box onClick={(e) => keepClickEventRestricted(e)}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                name="options"
                variant="ghost"
                icon={<FaEllipsisV />}
              />
              <MenuList rootProps={{ style: { right: 0 } }}>
                <MenuItem
                  onClick={() => onRename(row.original)}
                  name="rename-item"
                >
                  Rename
                </MenuItem>
                <MenuItem
                  onClick={() => onDelete(row.original)}
                  name="delete-item"
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      ),
    }),
  ];
};

export const staticListContactsPreviewTableHeaderColumnDef = [
  staticListContactsColumnHelper.accessor("first_name", {
    header: "First Name",
  }),
  staticListContactsColumnHelper.accessor("last_name", {
    header: "Last Name",
  }),
  staticListContactsColumnHelper.accessor("email", {
    header: "Email Address",
    cell: (info) => (
      <HStack>
        <Text>{info.getValue()}</Text>
        <Box hidden={!info.getValue()}>
          <Icon
            as={FaExternalLinkAlt}
            fontSize="12"
            onClick={() => redirectToContact(info.cell.row.original.id)}
            cursor="pointer"
          />
        </Box>
      </HStack>
    ),
  }),
  staticListContactsColumnHelper.accessor("_created_time", {
    header: "Date Added",
    cell: (info) => <FormatDate date={info.getValue()} splitLines />,
  }),
];

export const staticListContactsTableHeader = (
  removeItem: (row: StaticListContactsData) => void
): ColumnDef<StaticListContactsData, any>[] => {
  return [
    staticListContactsColumnHelper.accessor("first_name", {
      header: "First Name",
      meta: {
        width: "140px",
      },
    }),
    staticListContactsColumnHelper.accessor("last_name", {
      header: "Last Name",
      meta: {
        width: "140px",
      },
    }),
    staticListContactsColumnHelper.accessor("title", {
      header: "Title",
      meta: {
        width: "140px",
      },
    }),
    staticListContactsColumnHelper.accessor("company_name", {
      header: "Company",
      meta: {
        width: "140px",
      },
    }),
    staticListContactsColumnHelper.accessor("email", {
      header: "Email",
      cell: (info) => (
        <RedirectToContact
          email={info.getValue()}
          personId={info.cell.row.original.id}
        />
      ),
    }),
    staticListContactsColumnHelper.accessor("phone_number", {
      header: "Phone",
      meta: {
        width: "150px",
      },
    }),
    staticListContactsColumnHelper.accessor("_created_time", {
      header: "Date Added",
      cell: (info) => <FormatDate date={info.getValue()} splitLines />,
      meta: {
        width: "150px",
      },
    }),
    staticListContactsColumnHelper.display({
      header: "",
      id: "actions",
      cell: (info) => (
        <Flex alignItems="center" justifyContent="flex-end">
          <Box onClick={(e) => e.stopPropagation()}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                variant="ghost"
                name="options"
                icon={<FaEllipsisV />}
                onClick={(e) => e.stopPropagation()}
              />
              <MenuList rootProps={{ style: { right: 0 } }}>
                <MenuItem
                  onClick={() => removeItem(info.row.original)}
                  name="remove-item"
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      ),
      meta: {
        width: "50px",
      },
    }),
  ];
};
