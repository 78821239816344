import {
  SF_CAMPAIGN_SELECTION_TYPE,
  INCLUSION_LIST_TYPES,
} from "../types/campaign";
import {
  INFLECTION_CREATE_ACTION,
  INFLECTION_DELETE_ACTION,
  SALESFORCE_CONNECTION_STATE,
  SALESFORCE_CREATE_ACTION,
  SALESFORCE_DELETE_ACTION,
  SALESFORCE_FLINK_SYNC_OBJECTS,
  SalesforceConnectionV2,
  SF_INCLUSION_LIST_TYPES,
  SF_OBJECTS_TO_SYNC,
  SfColumnsOfAllSyncObjects,
  SyncActionsAllowed,
  SyncedObjSummary,
} from "../types/salesforce";
import {
  INFLECTION_RECORD_CREATION,
  INFLECTION_RECORD_DELETION,
  Permissions,
  SALESFORCE_OBJECT_TYPE,
  SALESFORCE_RECORD_CREATE,
  SalesforceConnection,
} from "../types/salesforceLegacyService";
import { Destination, SalesforceObject } from "../types/unifiedMapping";
import { getEpochTimeUtc } from "./commonHelper";

export function redirectToSfLoginPageWithoutLocalStorage(loginUrl: string) {
  if (loginUrl) {
    window.location.href = loginUrl;
  }
}

export function isResolveContextType(type: SF_CAMPAIGN_SELECTION_TYPE | null) {
  return type === SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_CONTEXT;
}

export function isResolveDirectType(type: SF_CAMPAIGN_SELECTION_TYPE | null) {
  return type === SF_CAMPAIGN_SELECTION_TYPE.RESOLVE_DIRECT;
}

export function isSfSyncActive(state?: SALESFORCE_CONNECTION_STATE) {
  return state && state === SALESFORCE_CONNECTION_STATE.ACTIVE;
}

export function isSfSyncPaused(state?: SALESFORCE_CONNECTION_STATE) {
  return state && state === SALESFORCE_CONNECTION_STATE.PAUSED;
}

export function isSfSyncVerified(state?: SALESFORCE_CONNECTION_STATE) {
  return state && state === SALESFORCE_CONNECTION_STATE.VERIFIED;
}

export function isSfSyncYetToStart(state?: SALESFORCE_CONNECTION_STATE) {
  return state && state === SALESFORCE_CONNECTION_STATE.YET_TO_START;
}

export function isSfSyncInitPreference(state?: SALESFORCE_CONNECTION_STATE) {
  return state && state === SALESFORCE_CONNECTION_STATE.PREFERENCES_INITIALIZED;
}

export function isAccountSyncEnabled(
  sfConnection: SalesforceConnectionV2 | null
) {
  return (
    sfConnection?.syncSettings?.objectSettings?.account?.syncObject ?? false
  );
}

export function isContactSyncEnabled(
  sfConnection: SalesforceConnectionV2 | null
) {
  return (
    sfConnection?.syncSettings?.objectSettings?.contact?.syncObject ?? false
  );
}

export function isLeadSyncEnabled(sfConnection: SalesforceConnectionV2 | null) {
  return sfConnection?.syncSettings?.objectSettings?.lead?.syncObject ?? false;
}

export function isOpportunitySyncEnabled(
  sfConnection: SalesforceConnectionV2 | null
) {
  return (
    sfConnection?.syncSettings?.objectSettings?.opportunity?.syncObject ?? false
  );
}

export function isCampaignSyncEnabled(
  sfConnection: SalesforceConnectionV2 | null
) {
  return (
    sfConnection?.syncSettings?.objectSettings?.campaign?.syncObject ?? false
  );
}

export function isCampaignMemberSyncEnabled(
  sfConnection: SalesforceConnectionV2 | null
) {
  return (
    sfConnection?.syncSettings?.objectSettings?.campaignMember?.syncObject ??
    false
  );
}

export function getSyncedObjectSummary(
  sfConnection: SalesforceConnectionV2 | null
): SyncedObjSummary {
  return {
    lead: isLeadSyncEnabled(sfConnection),
    contact: isContactSyncEnabled(sfConnection),
    account: isAccountSyncEnabled(sfConnection),
    opportunity: isOpportunitySyncEnabled(sfConnection),
    campaign: isCampaignSyncEnabled(sfConnection),
    campaignMember: isCampaignMemberSyncEnabled(sfConnection),
  };
}

export function getContactInclusionList(
  connection: SalesforceConnectionV2 | null
) {
  return (
    connection?.syncSettings?.objectSettings?.contact?.writeSettings
      ?.inclusionListSettings ?? null
  );
}

function transformPermissions(
  permissions: Permissions | null,
  sfSyncobj: SALESFORCE_OBJECT_TYPE
): SyncActionsAllowed {
  return {
    read: permissions?.[sfSyncobj]?.read ?? false,
    write: permissions?.[sfSyncobj]?.write ?? false,
  };
}

export const INCLUSION_LIST_MAPPING = {
  [INCLUSION_LIST_TYPES.STATIC]: SF_INCLUSION_LIST_TYPES.STATIC,
  [INCLUSION_LIST_TYPES.DYNAMIC]: SF_INCLUSION_LIST_TYPES.DYNAMIC,
};

export const INFLECTION_CONTACT_CREATED_MAPPING = {
  [SALESFORCE_RECORD_CREATE.LEAD]: SALESFORCE_CREATE_ACTION.LEAD,
  [SALESFORCE_RECORD_CREATE.CONTACT]: SALESFORCE_CREATE_ACTION.CONTACT,
  [SALESFORCE_RECORD_CREATE.DO_NOTHING]: SALESFORCE_CREATE_ACTION.DO_NOTHING,
};

export const CREATED_IN_SALESFORCE_MAPPING = {
  [INFLECTION_RECORD_CREATION.CREATE_RECORD]: INFLECTION_CREATE_ACTION.PERSON,
  [INFLECTION_RECORD_CREATION.DO_NOTHING]: INFLECTION_CREATE_ACTION.DO_NOTHING,
};

export const DELETED_IN_SALESFORCE_MAPPING = {
  [INFLECTION_RECORD_DELETION.DELETE_RECORD]:
    INFLECTION_DELETE_ACTION.DELETE_PERSON_KEEP_PRODUCT_DATA,
  [INFLECTION_RECORD_DELETION.DO_NOTHING]: INFLECTION_DELETE_ACTION.DO_NOTHING,
};

//NOTE : MAPPING MIGHT NOT BE ACCURATE TESTED FOR DATA REQUIRED ONLY
export function transformSalesforceConnectionFromLegacyToNew(
  connection: SalesforceConnection
): SalesforceConnectionV2 {
  const {
    connection_id,
    name,
    instance_url,
    permissions,
    object_settings,
    inclusion_list,
    org_daily_max,
    org_jobs_max,
    last_synced_time,
    sync_settings,
    salesforce_record_create,
    records_create_settings,
    records_delete_settings,
    next_schedule_time,
  } = connection;
  return {
    connectionId: connection_id,
    connectionName: name,
    instanceUrl: instance_url,
    permissions: {
      lead: transformPermissions(permissions, SALESFORCE_OBJECT_TYPE.LEAD),
      contact: transformPermissions(
        permissions,
        SALESFORCE_OBJECT_TYPE.CONTACT
      ),
      account: transformPermissions(
        permissions,
        SALESFORCE_OBJECT_TYPE.ACCOUNT
      ),
      opportunity: transformPermissions(
        permissions,
        SALESFORCE_OBJECT_TYPE.OPPORTUNITY
      ),
    },
    salesforceLimits: {
      dailyApiRequests: {
        max: org_daily_max || 0,
        remaining: org_jobs_max || 0,
      },
      dailyBulkV2QueryFileStorageMB: {
        max: 0,
        remaining: 0,
      },
      dailyBulkV2QueryJobs: {
        max: 0,
        remaining: 0,
      },
    },
    inflectionLimits: {
      dailyApiRequests: {
        max: sync_settings?.max_allocated || 0,
        used: 0,
      },
    },
    syncSettings: {
      objectSettings: {
        lead: {
          syncObject: !!object_settings?.lead,
          readSettings: {
            selectiveSyncSettings: null,
            onSalesforceEvent: {
              onCreate:
                CREATED_IN_SALESFORCE_MAPPING[
                  records_create_settings?.lead ??
                    INFLECTION_RECORD_CREATION.DO_NOTHING
                ],
              onDelete:
                DELETED_IN_SALESFORCE_MAPPING[
                  records_delete_settings?.lead ??
                    INFLECTION_RECORD_DELETION.DO_NOTHING
                ],
            },
          },
          writeSettings: {
            inclusionListSettings: null,
            onInflectionEvent: null,
          },
        },
        contact: {
          syncObject: !!object_settings?.contact,
          readSettings: {
            selectiveSyncSettings: null,
            onSalesforceEvent: {
              onCreate:
                CREATED_IN_SALESFORCE_MAPPING[
                  records_create_settings?.contact ??
                    INFLECTION_RECORD_CREATION.DO_NOTHING
                ],
              onDelete:
                DELETED_IN_SALESFORCE_MAPPING[
                  records_delete_settings?.contact ??
                    INFLECTION_RECORD_DELETION.DO_NOTHING
                ],
            },
          },
          writeSettings: {
            inclusionListSettings: inclusion_list
              ? {
                  listIdentifier: inclusion_list.list_id,
                  listType: INCLUSION_LIST_MAPPING[inclusion_list.list_type],
                }
              : null,
            onInflectionEvent: {
              onCreate: salesforce_record_create
                ? INFLECTION_CONTACT_CREATED_MAPPING[salesforce_record_create]
                : SALESFORCE_CREATE_ACTION.DO_NOTHING,
              onDelete: SALESFORCE_DELETE_ACTION.DO_NOTHING, //Coming soon feature
            },
          },
        },
        account: {
          syncObject: !!object_settings?.account,
          readSettings: {
            selectiveSyncSettings: null,
            onSalesforceEvent: null,
          },
          writeSettings: {
            inclusionListSettings: null,
            onInflectionEvent: null,
          },
        },
        opportunity: {
          syncObject: !!object_settings?.opportunity,
          readSettings: {
            selectiveSyncSettings: null,
            onSalesforceEvent: null,
          },
          writeSettings: {
            inclusionListSettings: null,
            onInflectionEvent: null,
          },
        },
      },
    },
    lastSyncCompletionTimestamp: last_synced_time
      ? getEpochTimeUtc(last_synced_time)
      : null,
    lastDataCaptureCutOffTimestamp: null,
    tenantId: -1,
    syncIntervalInMinutes: 0,
    currentState: SALESFORCE_CONNECTION_STATE.ACTIVE,
    requireReAuthentication: false,
    nextSyncStartTimestamp: next_schedule_time
      ? getEpochTimeUtc(next_schedule_time)
      : null,
  };
}

export function transformSfSyncObjColumnsFromNewToLegacyForUnifiedMapping(
  sfObjectColumns: SfColumnsOfAllSyncObjects | null
): SalesforceObject[] | null {
  if (!sfObjectColumns) return null;
  return Object.keys(sfObjectColumns).reduce((prev, key) => {
    const sfSyncObject = sfObjectColumns[key as SF_OBJECTS_TO_SYNC];

    if (sfSyncObject) {
      const fields: Destination[] = sfSyncObject.fields.map((field) => ({
        type: field.inflectionMappedType,
        sub_type: field.type,
        updatable: field.updateable,
        name: field.name,
        display: field.label,
        length: field.length,
        read_only: !field.updateable,
        primary_key: field.unique,
        custom: false,
        hidden: false,
        required: false,
      }));

      prev.push({
        object: key,
        fields,
      });
    }

    return prev;
  }, [] as SalesforceObject[]);
}

export function transformCrmObjectToSalesforceObject(crmObject: string) {
  switch (crmObject) {
    case "contact":
      return SALESFORCE_FLINK_SYNC_OBJECTS.CONTACT;
    case "lead":
      return SALESFORCE_FLINK_SYNC_OBJECTS.LEAD;
    case "account":
      return SALESFORCE_FLINK_SYNC_OBJECTS.ACCOUNT;
    default:
      return null;
  }
}
