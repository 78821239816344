import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAggregateStatsApi,
  getReportsTopLinkApi,
  getReportsEmailClientClickApi,
  getReportsEmailClientOpenApi,
  getRecipientEngagementApi,
  getRecipientOpenApi,
  getRecipientClickApi,
  getRecipientBounceApi,
  getRecipientUnsubApi,
  getRecipientSpamReportApi,
  getRecipientNotSentApi,
  getReportRunsListApi,
  getReportRunStatsApi,
  getEmailStatsWithFilters,
  getBounceClassificationInfoApi,
} from "../../../../common/api/campaign/reports";
import {
  AggregateStats,
  ClickData,
  EmailClientClickData,
  EmailClientOpenData,
  LinkClickData,
  OpenData,
  RecipientBounce,
  RecipientNotSent,
  RecipientUnsub,
  RecipientWithUrls,
  ReportsFilterType,
  RunStats,
  BounceClassificationStats,
  BounceReasonPerContact,
  BounceClassificationInfo,
} from "../../../../common/types/campaign";
import { RootState } from "../../../../store";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
  INITIAL_PAGINATION_15_ITEMS,
  LOADING_STATES,
} from "../../../../common/constants/common";
import {
  LoadingWithData,
  PaginationType,
  PaginationDataV2,
  SearchAssetsType,
} from "../../../../common/types/common";
import {
  BOUNCE_EVENT,
  BOUNCE_GROUP_BY,
  BOUNCE_STATS_VIEW_TYPE,
  RECIPIENT_EVENT_TYPE,
} from "../../../../common/constants/campaign";
import {
  filterActionIdIfExist,
  transformSearchFilterToApiQuery,
  removeActionIdIfEmpty,
} from "./constantHelper";
import { initializeLoadingData } from "../../../../common/helper/commonHelper";
import { formatISO } from "date-fns";

const AGGREGATE_STATS_INIT = {
  processed_count: 0,
  bounce_count: 0,
  delivered_count: 0,
  dropped_count: 0,
  spamreport_count: 0,
  total_count: 0,
  not_sent_count: 0,
  total_open_count: 0,
  unique_open_count: 0,
  total_click_count: 0,
  unique_click_count_by_email: 0,
  unique_click_count_by_url: 0,
  unsub_count: 0,
};
interface CampaignReportsState {
  isReportsDrawerOpen: boolean;
  campaignId: string;
  campaignHistory: PaginationType<RunStats>;
  filterData: ReportsFilterType;
  showNonEmptyRuns: boolean;
  aggregateData: { data: AggregateStats; loading: LOADING_STATES };
  bounceStats: {
    bounceOverAllStats: LoadingWithData<BounceClassificationStats[]>;
    bounceStatsByClassification: PaginationType<BounceReasonPerContact>;
    bounceClassificationInfo: LoadingWithData<{
      [classification: string]: BounceClassificationInfo;
    }>;
  };
  topLinks: {
    top: LoadingWithData<LinkClickData[]>;
    list: PaginationType<LinkClickData>;
  };
  emailClient: {
    open: LoadingWithData<EmailClientOpenData[]>;
    click: LoadingWithData<EmailClientClickData[]>;
  };
  recipientEventType: RECIPIENT_EVENT_TYPE;

  recipientData: {
    [RECIPIENT_EVENT_TYPE.ALL]: PaginationType<
      RecipientWithUrls & ClickData & OpenData
    >;
    [RECIPIENT_EVENT_TYPE.NOT_SENT]: PaginationType<RecipientNotSent>;
    [RECIPIENT_EVENT_TYPE.OPEN]: PaginationType<
      RecipientWithUrls & ClickData & OpenData
    >;
    [RECIPIENT_EVENT_TYPE.CLICK]: PaginationType<RecipientWithUrls & ClickData>;
    [RECIPIENT_EVENT_TYPE.BOUNCE]: PaginationType<RecipientBounce>;
    [RECIPIENT_EVENT_TYPE.UNSUB]: PaginationType<RecipientUnsub>;
    [RECIPIENT_EVENT_TYPE.SPAM]: PaginationType<RecipientBounce>;
  };
}

const initialState: CampaignReportsState = {
  isReportsDrawerOpen: false,
  campaignId: "",
  campaignHistory: INITIAL_PAGINATION_15_ITEMS,
  showNonEmptyRuns: false,
  filterData: {
    campaignId: "",
    timeRange: {
      startDate: formatISO(
        getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_ONE_MONTH)
      ),
      endDate: formatISO(new Date()),
    },
  },
  aggregateData: {
    data: AGGREGATE_STATS_INIT,
    loading: LOADING_STATES.INIT,
  },
  bounceStats: {
    bounceOverAllStats: initializeLoadingData([]),
    bounceStatsByClassification: INITIAL_PAGINATION_15_ITEMS,
    bounceClassificationInfo: initializeLoadingData({}),
  },
  topLinks: {
    top: initializeLoadingData([]),
    list: INITIAL_PAGINATION_15_ITEMS,
  },
  emailClient: {
    open: initializeLoadingData([]),
    click: initializeLoadingData([]),
  },
  recipientEventType: RECIPIENT_EVENT_TYPE.ALL,

  recipientData: {
    [RECIPIENT_EVENT_TYPE.ALL]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.NOT_SENT]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.OPEN]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.CLICK]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.BOUNCE]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.UNSUB]: INITIAL_PAGINATION_15_ITEMS,
    [RECIPIENT_EVENT_TYPE.SPAM]: INITIAL_PAGINATION_15_ITEMS,
  },
};

export const getCampaignHistory = createAsyncThunk(
  "campaign/reports/history",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportRunsListApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
      page_number: campaignReports.campaignHistory.currentPageNo,
      page_size: campaignReports.campaignHistory.pageSize,
      show_non_empty_runs: campaignReports.showNonEmptyRuns,
    });
  }
);

export const getCampaignHistoryStats = createAsyncThunk(
  "campaign/reports/history-stats",
  async (runIds: string[], thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportRunStatsApi({
      campaign_id: campaignReports.campaignId,
      run_ids: runIds,
    });
  }
);

export const getAggregateData = createAsyncThunk(
  "campaign/reports/aggregate",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getAggregateStatsApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
    });
  }
);

export const getOverAllBounceData = createAsyncThunk(
  "campaign/reports/bounce-data",
  async (_, thunkApi) => {
    const {
      campaignReports: { filterData, campaignId },
    } = thunkApi.getState() as RootState;
    return await getEmailStatsWithFilters({
      campaignId: campaignId,
      filters: {
        view: BOUNCE_STATS_VIEW_TYPE.AGGREGATE,
        groupBy: BOUNCE_GROUP_BY,
        event: BOUNCE_EVENT,
        ...removeActionIdIfEmpty(filterData),
      },
    });
  }
);

export const getBounceDataByClassification = createAsyncThunk(
  "campaign/reports/bounce-data/classification/stats",
  async (
    {
      searchKeyword,
      timeRange,
      classification,
    }: {
      searchKeyword: string;
      timeRange: { startDate: string; endDate: string };
      classification: string;
    },
    { getState }
  ) => {
    const {
      campaignReports: {
        filterData,
        bounceStats: { bounceStatsByClassification },
        campaignId,
      },
    } = getState() as RootState;
    return await getEmailStatsWithFilters({
      campaignId: campaignId,
      filters: {
        view: BOUNCE_STATS_VIEW_TYPE.LIST,
        event: BOUNCE_EVENT,
        pageNumber: bounceStatsByClassification.currentPageNo,
        pageSize: bounceStatsByClassification.pageSize,
        search: searchKeyword,
        bounceClassification: classification,
        ...removeActionIdIfEmpty(filterData),
        startDate: timeRange.startDate,
        endDate: timeRange.endDate,
      },
    });
  }
);

export const getBounceClassificationInfo = createAsyncThunk(
  "campaign/reports/bounce-data/classification",
  async (classification?: string) => {
    return await getBounceClassificationInfoApi(classification);
  }
);

export const getTopnLinks = createAsyncThunk(
  "campaign/reports/top-n-links",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportsTopLinkApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
      page_number: 1,
      page_size: 5,
    });
  }
);

export const getPaginatedTopLinks = createAsyncThunk(
  "campaign/reports/top-links",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportsTopLinkApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
      page_number: campaignReports.topLinks.list.currentPageNo,
      page_size: campaignReports.topLinks.list.pageSize,
    });
  }
);

export const getEmailClientClickData = createAsyncThunk(
  "campaign/reports/emailclient.click",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportsEmailClientClickApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
      page_number: 1,
      page_size: 1000,
    });
  }
);

export const getEmailClientOpenData = createAsyncThunk(
  "campaign/reports/emailclient.open",
  async (_, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    return await getReportsEmailClientOpenApi({
      ...filterActionIdIfExist(campaignReports.filterData),
      campaign_id: campaignReports.campaignId,
      page_number: 1,
      page_size: 1000,
    });
  }
);

export const getRecipientAllData = createAsyncThunk(
  "campaign/reports/recipient_data.all",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.ALL].currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.ALL].pageSize,
    };
    return await getRecipientEngagementApi(data);
  },
  {
    condition: (_, { getState }) => {
      const { campaignReports } = getState() as RootState;
      if (
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.ALL].fetchingList
      ) {
        return false;
      }
    },
  }
);

export const getRecipientNotSentData = createAsyncThunk(
  "campaign/reports/recipient_data.not-sent",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT]
          .currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT].pageSize,
    };
    return await getRecipientNotSentApi(data);
  }
);

export const getRecipientOpenData = createAsyncThunk(
  "campaign/reports/recipient_data.open",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.OPEN].currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.OPEN].pageSize,
    };
    return await getRecipientOpenApi(data);
  }
);

export const getRecipientClickData = createAsyncThunk(
  "campaign/reports/recipient_data.click",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.CLICK].currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.CLICK].pageSize,
    };
    return await getRecipientClickApi(data);
  }
);

export const getRecipientBounceData = createAsyncThunk(
  "campaign/reports/recipient_data.bounce",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.BOUNCE]
          .currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.BOUNCE].pageSize,
    };
    return await getRecipientBounceApi(data);
  }
);

export const getRecipientUnsubData = createAsyncThunk(
  "campaign/reports/recipient_data.unsub",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.UNSUB].currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.UNSUB].pageSize,
    };
    return await getRecipientUnsubApi(data);
  }
);

export const getRecipientSpamData = createAsyncThunk(
  "campaign/reports/recipient_data.spam",
  async ({ searchKeyword, columnsToSearchIn }: SearchAssetsType, thunkApi) => {
    const { campaignReports } = thunkApi.getState() as RootState;
    const data = {
      ...filterActionIdIfExist(campaignReports.filterData),
      ...transformSearchFilterToApiQuery({
        searchKeyword,
        columnsToSearchIn,
      }),
      campaign_id: campaignReports.campaignId,
      page_number:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.SPAM].currentPageNo,
      page_size:
        campaignReports.recipientData[RECIPIENT_EVENT_TYPE.SPAM].pageSize,
    };
    return await getRecipientSpamReportApi(data);
  }
);

const campaignReportsSlice = createSlice({
  name: "campaign-reports",
  initialState,
  reducers: {
    setReportsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.campaignHistory.totalPageCount ?? 1)) {
        state.campaignHistory.currentPageNo = pageNo;
        state.campaignHistory.changingPage = true;
      }
    },
    setCampaignReportsRange(state, action) {
      state.filterData.timeRange = {
        startDate: action.payload.start,
        endDate: action.payload.end,
      };
      state.campaignHistory.currentPageNo = 1;
      state.campaignHistory.totalPageCount = null;
      Object.values(RECIPIENT_EVENT_TYPE).forEach((type) => {
        state.recipientData[type].currentPageNo = 1;
      });
      state.topLinks.list.currentPageNo = 1;
      state.topLinks.list.totalPageCount = null;
    },
    setShowNonEmptyRunsFilter(state, action) {
      state.showNonEmptyRuns = action.payload;
      state.campaignHistory.currentPageNo = 1;
      state.campaignHistory.totalPageCount = null;
    },
    setRecipientEventType(state, action: PayloadAction<RECIPIENT_EVENT_TYPE>) {
      state.recipientEventType = action.payload;
    },
    setRecipientsReportsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <=
          (state.recipientData[state.recipientEventType].totalPageCount ?? 1)
      ) {
        state.recipientData[state.recipientEventType].currentPageNo = pageNo;
        state.recipientData[state.recipientEventType].changingPage = true;
      }
    },
    setTopLinksPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.topLinks.list.totalPageCount ?? 1)) {
        state.topLinks.list.currentPageNo = pageNo;
        state.topLinks.list.changingPage = true;
      }
    },
    setBounceReasonsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <=
          (state.bounceStats.bounceStatsByClassification.totalPageCount ?? 1)
      ) {
        state.bounceStats.bounceStatsByClassification.currentPageNo = pageNo;
        state.bounceStats.bounceStatsByClassification.changingPage = true;
      }
    },
    resetReports(state) {
      state = initialState;
    },
    setReportsCampaignId(state, action: PayloadAction<string>) {
      state.campaignId = action.payload;
    },
    openReportsSlider(state) {
      state.isReportsDrawerOpen = true;
    },
    closeReportsSlider(state) {
      state.isReportsDrawerOpen = false;
    },
    setActionIdFilter(state, action) {
      state.filterData.actionId = action.payload;
      state.campaignHistory.currentPageNo = 1;
      state.campaignHistory.totalPageCount = null;
      Object.values(RECIPIENT_EVENT_TYPE).forEach((type) => {
        state.recipientData[type].currentPageNo = 1;
      });
      state.topLinks.list.currentPageNo = 1;
      state.topLinks.list.totalPageCount = null;
    },
    resetCampaignReportsRange(state) {
      state.filterData.timeRange.startDate = formatISO(
        getStartDate(CUSTOM_DATE_RANGE_LABELS.LAST_ONE_MONTH)
      );
      state.filterData.timeRange.endDate = formatISO(new Date());
    },
    resetActionIdFilter(state) {
      state.filterData.actionId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignHistory.pending, (state) => {
        state.campaignHistory.fetchingList = true;
      })
      .addCase(getCampaignHistory.fulfilled, (state, action) => {
        state.campaignHistory = {
          ...state.campaignHistory,
          list: action.payload.records.map((run) => {
            return {
              ...run,
              ...AGGREGATE_STATS_INIT,
              stats_loading: LOADING_STATES.INIT,
            };
          }),
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getCampaignHistory.rejected, (state) => {
        state.campaignHistory.fetchingList = false;
        state.campaignHistory.changingPage = false;
      })

      //campaign history stats
      .addCase(getCampaignHistoryStats.pending, (state) => {
        state.campaignHistory.list =
          state.campaignHistory.list?.map((run) => {
            run.stats_loading = LOADING_STATES.LOADING;
            return run;
          }) ?? null;
      })
      .addCase(getCampaignHistoryStats.fulfilled, (state, action) => {
        state.campaignHistory.list =
          state.campaignHistory.list?.map((run) => {
            const stats =
              action.payload.records.find(
                (runStats) => runStats.run_id === run.run_id
              ) ?? {};
            return {
              ...run,
              ...stats,
              stats_loading: LOADING_STATES.SUCCESS,
            };
          }) ?? null;
      })
      .addCase(getCampaignHistoryStats.rejected, (state) => {
        state.campaignHistory.list =
          state.campaignHistory.list?.map((run) => {
            run.stats_loading = LOADING_STATES.FAILED;
            return run;
          }) ?? null;
      })

      .addCase(getAggregateData.pending, (state) => {
        state.aggregateData.loading = LOADING_STATES.LOADING;
      })
      .addCase(getAggregateData.fulfilled, (state, action) => {
        state.aggregateData = {
          data: action.payload.stats,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getAggregateData.rejected, (state) => {
        state.aggregateData.loading = LOADING_STATES.FAILED;
      })

      //bounce reasons
      .addCase(getOverAllBounceData.pending, (state) => {
        state.bounceStats.bounceOverAllStats.loading = LOADING_STATES.LOADING;
      })
      .addCase(getOverAllBounceData.fulfilled, (state, action) => {
        state.bounceStats.bounceOverAllStats = {
          data: action.payload.data as BounceClassificationStats[],
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getOverAllBounceData.rejected, (state) => {
        state.bounceStats.bounceOverAllStats.loading = LOADING_STATES.FAILED;
      })

      .addCase(getBounceDataByClassification.pending, (state) => {
        state.bounceStats.bounceStatsByClassification.fetchingList = true;
      })
      .addCase(getBounceDataByClassification.fulfilled, (state, action) => {
        const { data, pagination } =
          action.payload as PaginationDataV2<BounceReasonPerContact>;
        state.bounceStats.bounceStatsByClassification = {
          ...state.bounceStats.bounceStatsByClassification,
          list: data,
          count: pagination.total_records,
          totalPageCount: pagination.total_pages,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getBounceDataByClassification.rejected, (state) => {
        state.bounceStats.bounceStatsByClassification.fetchingList = false;
      })

      .addCase(getBounceClassificationInfo.pending, (state) => {
        state.bounceStats.bounceClassificationInfo.loading =
          LOADING_STATES.LOADING;
      })
      .addCase(getBounceClassificationInfo.fulfilled, (state, action) => {
        state.bounceStats.bounceClassificationInfo = {
          data: action.payload.data,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getBounceClassificationInfo.rejected, (state) => {
        state.bounceStats.bounceClassificationInfo.loading =
          LOADING_STATES.FAILED;
      })

      .addCase(getTopnLinks.pending, (state) => {
        state.topLinks.top.loading = LOADING_STATES.LOADING;
      })
      .addCase(getTopnLinks.fulfilled, (state, action) => {
        state.topLinks.top = {
          data: action.payload.records,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getTopnLinks.rejected, (state) => {
        state.topLinks.top.loading = LOADING_STATES.FAILED;
      })

      .addCase(getPaginatedTopLinks.pending, (state) => {
        state.topLinks.list.fetchingList = true;
      })
      .addCase(getPaginatedTopLinks.fulfilled, (state, action) => {
        state.topLinks.list = {
          ...state.topLinks.list,
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getPaginatedTopLinks.rejected, (state) => {
        state.topLinks.list.fetchingList = false;
        state.topLinks.list.changingPage = false;
      })

      .addCase(getEmailClientOpenData.pending, (state) => {
        state.emailClient.open.loading = LOADING_STATES.LOADING;
      })
      .addCase(getEmailClientOpenData.fulfilled, (state, action) => {
        state.emailClient.open = {
          data: action.payload.records,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getEmailClientOpenData.rejected, (state) => {
        state.emailClient.open.loading = LOADING_STATES.FAILED;
      })

      .addCase(getEmailClientClickData.pending, (state) => {
        state.emailClient.click.loading = LOADING_STATES.LOADING;
      })
      .addCase(getEmailClientClickData.fulfilled, (state, action) => {
        state.emailClient.click = {
          data: action.payload.records,
          loading: LOADING_STATES.SUCCESS,
        };
      })
      .addCase(getEmailClientClickData.rejected, (state) => {
        state.emailClient.click.loading = LOADING_STATES.FAILED;
      })

      .addCase(getRecipientAllData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.ALL].fetchingList = true;
      })
      .addCase(getRecipientAllData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.ALL] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.ALL],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientAllData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.ALL].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.ALL].changingPage = false;
      })

      .addCase(getRecipientNotSentData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT].fetchingList = true;
      })
      .addCase(getRecipientNotSentData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientNotSentData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.NOT_SENT].changingPage = false;
      })

      .addCase(getRecipientOpenData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.OPEN].fetchingList = true;
      })
      .addCase(getRecipientOpenData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.OPEN] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.OPEN],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientOpenData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.OPEN].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.OPEN].changingPage = false;
      })

      .addCase(getRecipientClickData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK].fetchingList = true;
      })
      .addCase(getRecipientClickData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.CLICK],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientClickData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK].changingPage = false;
      })

      .addCase(getRecipientBounceData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.BOUNCE].fetchingList = true;
      })
      .addCase(getRecipientBounceData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.BOUNCE] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.BOUNCE],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientBounceData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.CLICK].changingPage = false;
      })

      .addCase(getRecipientUnsubData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.UNSUB].fetchingList = true;
      })
      .addCase(getRecipientUnsubData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.UNSUB] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.UNSUB],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientUnsubData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.UNSUB].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.UNSUB].changingPage = false;
      })

      .addCase(getRecipientSpamData.pending, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.SPAM].fetchingList = true;
      })
      .addCase(getRecipientSpamData.fulfilled, (state, action) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.SPAM] = {
          ...state.recipientData[RECIPIENT_EVENT_TYPE.SPAM],
          list: action.payload.records,
          count: action.payload.record_count,
          totalPageCount: action.payload.page_count,
          fetchingList: false,
          changingPage: false,
        };
      })
      .addCase(getRecipientSpamData.rejected, (state) => {
        state.recipientData[RECIPIENT_EVENT_TYPE.SPAM].fetchingList = false;
        state.recipientData[RECIPIENT_EVENT_TYPE.SPAM].changingPage = false;
      });
  },
});

export const {
  openReportsSlider,
  closeReportsSlider,
  setActionIdFilter,
  resetActionIdFilter,
  setCampaignReportsRange,
  resetCampaignReportsRange,
  setReportsPage,
  setRecipientsReportsPage,
  setTopLinksPage,
  setRecipientEventType,
  setShowNonEmptyRunsFilter,
  resetReports,
  setBounceReasonsPage,
  setReportsCampaignId,
} = campaignReportsSlice.actions;

export const selectCampaignReports = (state: RootState) =>
  state.campaignReports;

export default campaignReportsSlice.reducer;
