import { Box, Flex, VStack, Badge } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { createGroupId } from "../../../../../common/helper/commonHelper";
import AddEventButton from "./AddEventButton";
import MarketingEventFilterGroup from "./MarketingEventFilterGroup";
import ProductEventFilterGroup from "./ProductEventFilterGroup";
import {
  NodeGroupWithComparison,
  ComparisonNode,
} from "../../../../../common/types/dynamicList";
import {
  TRIGGER_DL_NODE_TYPES,
  EVENT_CATEGORY,
} from "../../../../../common/constants/dynamicList";
import { getLogicalOperatorForTrigger } from "../../Audience-criteria/helper";
import { fillFirstNodeData } from "../../../../../common/helper/trigger";
import PersonEventGroup from "./PersonEventGroup";

const OUTER_GROUP_ID = "exit-criteria-outer-group";

export default function TriggerOuterGroup({
  data,
  onChange,
  isReadOnly,
}: {
  data: NodeGroupWithComparison;
  onChange: (data: NodeGroupWithComparison) => void;
  isReadOnly?: boolean;
}) {
  function onAddFilterGroup(
    nodeType: TRIGGER_DL_NODE_TYPES,
    event?: EVENT_CATEGORY
  ) {
    const dataCopy = cloneDeep(data);
    let newFilterGroup = fillFirstNodeData(nodeType, event) as ComparisonNode;
    newFilterGroup = {
      ...newFilterGroup,
      logicalOperator: getLogicalOperatorForTrigger(data.nodes.length),
    };
    dataCopy.nodes.push(newFilterGroup);
    onChange(dataCopy);
  }

  function onRemoveFilterGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.nodes = dataCopy.nodes.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function updateFilterGroup(index: number, newData: ComparisonNode) {
    const dataCopy = cloneDeep(data);
    dataCopy.nodes[index] = newData;
    onChange(dataCopy);
  }

  function renderFilterGroup(filterGroup: ComparisonNode, index: number) {
    switch (filterGroup.eventCategory) {
      case EVENT_CATEGORY.PRODUCT_ACTIVITY:
        return (
          <ProductEventFilterGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      case EVENT_CATEGORY.MARKETING_ACTIVITY:
        return (
          <MarketingEventFilterGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
      default:
        return (
          <PersonEventGroup
            data={filterGroup}
            id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
            onChange={(data) => updateFilterGroup(index, data)}
            onRemove={() => onRemoveFilterGroup(index)}
            isReadOnly={isReadOnly}
          />
        );
    }
  }

  const wrapperStyle = !isReadOnly
    ? {
        p: "3",
        rounded: "lg",
        bg: "grayV2.100",
        borderWidth: "1px",
      }
    : {};

  return (
    <Box
      width="100%"
      alignItems="flex-start"
      id={OUTER_GROUP_ID}
      {...wrapperStyle}
    >
      <VStack alignItems="flex-start" spacing="2">
        {data.nodes.map((filterGroup, index) => {
          return (
            <>
              <Flex key={index} w="100%">
                {renderFilterGroup(filterGroup, index)}
              </Flex>
              {data.nodes.length > 1 && index !== data.nodes.length - 1 && (
                <Badge
                  fontSize="10px"
                  px={3}
                  my={2}
                  mx={3}
                  borderRadius="4px"
                  colorScheme="blue"
                  variant="solid"
                >
                  Or
                </Badge>
              )}
            </>
          );
        })}
      </VStack>
      {!isReadOnly && (
        <Box mt={data.nodes.length ? "5" : "0"}>
          <AddEventButton label="Add event" onClick={onAddFilterGroup} />
        </Box>
      )}
    </Box>
  );
}
