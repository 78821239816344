import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  HStack,
  IconButton,
  Switch,
  Text,
  useBoolean,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { DomainDetails, DomainReq } from "../../../../common/types/settings";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import {
  getSortedSubdomainList,
  isDomainActive,
  isAllowedSubdomain,
  isTrackAllSubdomains,
  toggleDomainControlStatus,
  toggleSubdomainStatus,
} from "../../../../common/helper/settingsHelper";
import ITitle from "../../../../components/ITitle";
import {
  DOMAIN_VARIANTS,
  TRACKED_DOMAINS_STATUS,
  TRACK_ACCESS_STATES,
} from "../../../../common/constants/settings";
import IButton, { BUTTON } from "../../../../components/IButton";
import { FaSave, FaTimesCircle } from "react-icons/fa";
import { useAppDispatch } from "../../../../store";
import { controlDomainStatus, updateDomain } from "../settingsSlice";
import { FaPlus } from "react-icons/fa6";
import AddDomainFlow from "./AddDomainFlow";
import { IoTrash } from "react-icons/io5";
import { DeleteConfirmationModal } from "../../../../components/DeleteConfirmationModal";
import {
  ALERT_TYPE,
  DELETION_MODAL_TYPES_INFO,
} from "../../../../common/constants/common";
import { DomainDeleteModalContent } from "./DomainDeleteModalContent";
import { LinkWithIcon } from "./LinkWithIcon";
import { isFulfilled } from "../../../../common/helper/commonHelper";

type SUBDOMAIN_ROW = { [key: string]: TRACK_ACCESS_STATES };

function SaveAndCancel({
  onSave,
  onCancel,
  loading,
}: {
  onSave: () => void;
  onCancel: () => void;
  loading: boolean;
}) {
  return (
    <HStack justifyContent="flex-end">
      <IButton
        variant={BUTTON.PRIMARY}
        bg="gray.50"
        color="brandBlue.500"
        leftIcon={<FaTimesCircle />}
        _hover={{ bg: "gray.100" }}
        onClick={onCancel}
      >
        Cancel
      </IButton>
      <IButton
        variant={BUTTON.PRIMARY}
        leftIcon={<FaSave />}
        onClick={onSave}
        isLoading={loading}
        loadingText="Saving"
      >
        Save
      </IButton>
    </HStack>
  );
}

function SubdomainHeader() {
  return (
    <HStack
      bg="oneOffs.tableHeader"
      rounded="md"
      p={2}
      fontSize="12px"
      fontWeight={600}
    >
      <Text w="60%">Domain/ Subdomain</Text>
      <Text>Enable/ Disable</Text>
    </HStack>
  );
}

function SubdomainContent({
  subdomain,
  domain,
  status,
  updateSubdomain,
  openDeleteModal,
}: {
  subdomain: string;
  domain?: string;
  status: TRACK_ACCESS_STATES;
  updateSubdomain: (subdomain: SUBDOMAIN_ROW) => void;
  openDeleteModal: (domain: string) => void;
}) {
  return (
    <>
      <HStack pl={2}>
        <Box w="65%">
          <LinkWithIcon urlToDisplay={subdomain} />
        </Box>
        <Box w="20%">
          <Switch
            variant="GREEN_RED"
            size="sm"
            isChecked={isAllowedSubdomain(status)}
            onChange={() =>
              updateSubdomain({
                [subdomain]: isAllowedSubdomain(status)
                  ? TRACK_ACCESS_STATES.BLOCKED
                  : TRACK_ACCESS_STATES.ALLOWED,
              })
            }
          />
        </Box>
        <IconButton
          aria-label="remove-subdomain"
          icon={<IoTrash />}
          color="brandBlue.500"
          bg="gray.200"
          size="xs"
          _hover={{
            bg: "gray.100",
            cursor: "pointer",
          }}
          onClick={() => {
            openDeleteModal(subdomain);
          }}
          hidden={!domain}
        />
      </HStack>
      <Divider />
    </>
  );
}
export function EditDomainDrawer({
  isOpen,
  onClose,
  domainDetails,
}: {
  isOpen: boolean;
  onClose: () => void;
  domainDetails: DomainDetails;
}) {
  const transformedDomainObj: DomainReq = useMemo(
    () => ({
      trackingStatus: domainDetails.trackingStatus,
      trackingConfiguration: domainDetails.trackingConfiguration,
    }),
    [domainDetails]
  );

  const { domain, currentState, id } = domainDetails;

  const [domainValues, setDomainValues] =
    useState<DomainReq>(transformedDomainObj);
  const [subdomainList, setSubdomainList] = useState<
    [string, TRACK_ACCESS_STATES][]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [domainState, setDomainState] =
    useState<TRACKED_DOMAINS_STATUS>(currentState);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useBoolean(false);
  const [selectedRow, setSelectedRow] = useState("");

  useEffect(() => {
    const { trackingConfiguration } = transformedDomainObj;
    setDomainValues(transformedDomainObj);
    setDomainState(domainDetails.currentState);
    setSubdomainList(getSortedSubdomainList(trackingConfiguration, domain!));
  }, [transformedDomainObj, domain, domainDetails]);

  const { trackingStatus: trackSubdomains, trackingConfiguration: subdomains } =
    domainValues;

  const dispatch = useAppDispatch();

  const {
    onOpen: onOpenAddDomainFlow,
    onClose: onCloseAddDomainFlow,
    isOpen: isOpenAddDomainFlow,
  } = useDisclosure();

  function checkIsSubdomainPresent(subDomain: string) {
    return !!subdomainList.find(([value, _]) => value === subDomain);
  }

  function updateDomainValues(options: Partial<DomainReq>) {
    setDomainValues((prevState) => {
      return {
        ...prevState,
        ...options,
      };
    });
  }

  function updateSubdomain(subdomain: SUBDOMAIN_ROW) {
    const [inputKey, inputValue] = Object.entries(subdomain)[0];

    if (checkIsSubdomainPresent(inputKey)) {
      setSubdomainList((prev) =>
        prev.map(([key, status]) =>
          key === inputKey ? [key, inputValue] : [key, status]
        )
      );
    } else {
      setSubdomainList((prev) => [...prev, [inputKey, inputValue]]);
    }
    updateDomainValues({
      trackingConfiguration: {
        ...subdomains,
        ...subdomain,
      },
    });
  }

  async function onSave() {
    setIsLoading(true);
    const stateChanged = currentState !== domainState;
    let isControlFulfilled = true;

    if (stateChanged) {
      const domainActivity = isDomainActive(domainState)
        ? TRACKED_DOMAINS_STATUS.ACTIVE
        : TRACKED_DOMAINS_STATUS.INACTIVE;

      const domainStatusResponse = await dispatch(
        controlDomainStatus({ domainId: id, domainActivity })
      );

      isControlFulfilled = isFulfilled(
        domainStatusResponse?.meta.requestStatus
      );
    }

    let isUpdateFulfilled = true;
    if (isDomainActive(domainState)) {
      const { meta: updateMeta } = await dispatch(
        updateDomain({ domainInfo: domainValues, domainId: id })
      );
      isUpdateFulfilled = isFulfilled(updateMeta.requestStatus);
    }

    if (isControlFulfilled && isUpdateFulfilled) {
      onClose();
    }
    setIsLoading(false);
  }

  function onOpenDeleteModal(row: string) {
    setSelectedRow(row);
    setIsOpenDeleteModal.on();
  }

  function removeSubdomain() {
    setSubdomainList((prev) => prev.filter(([key, _]) => key !== selectedRow));

    updateDomainValues({
      trackingConfiguration: {
        ...Object.fromEntries(
          subdomainList.filter(([curr, _]) => curr !== selectedRow)
        ),
      },
    });
    setIsOpenDeleteModal.off();
  }

  const isEnabledDomain = isDomainActive(domainState);
  const isEnabledTrackAll = isTrackAllSubdomains(trackSubdomains);

  return (
    <>
      <MemoizedCommonDrawer
        title={domain}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size="md"
      >
        <Text color="text.50" fontSize="12px">
          Tracking status
        </Text>
        <HStack>
          <Text color="text.50" fontSize="14px" pr={1}>
            Track {domain}{" "}
          </Text>
          <Switch
            variant="GREEN_RED"
            size="sm"
            isChecked={isEnabledDomain}
            onChange={() =>
              setDomainState((prev) => toggleDomainControlStatus(prev))
            }
          />
        </HStack>
        <Box hidden={!isEnabledDomain} pt={5} mb={8}>
          <Divider />
          <VStack align="left" pt={5}>
            <Checkbox
              isChecked={isEnabledTrackAll}
              onChange={() =>
                updateDomainValues({
                  trackingStatus: toggleSubdomainStatus(trackSubdomains),
                })
              }
              pb={3}
            >
              <Text fontWeight={600} fontSize="12px" color="text.50">
                Automatically track all subdomains?
              </Text>
            </Checkbox>
            <Text fontSize="12px" color="text.50">
              If enabled, tracking would be enabled for the top level domain and
              all its subdomains. All the pages with the tracking code would be
              enabled for webtracking. When this option is disabled, Users can
              define the subdomain tracking behaviour. Only the enabled
              subdomains would be tracked
            </Text>
          </VStack>
          <VStack hidden={isEnabledTrackAll} align="left" pt={8}>
            <Text fontSize="12px" color="text.50" mb={8}>
              <Text as="span" fontWeight={600}>
                Note:{" "}
              </Text>
              The top-level subdomain can be added for tracking in the table
            </Text>
            <VStack bg="gray.100" align="left" p={2} rounded="md" pb={2}>
              <ITitle
                fontSize="14px"
                fontWeight={600}
                title="Custom configuration:"
              />
              <SubdomainHeader />
              <VStack align="left" maxHeight="250px" overflowY="auto">
                {subdomainList.map(([subdomain, status], index) => (
                  <SubdomainContent
                    subdomain={subdomain}
                    domain={!index ? "" : domain}
                    status={status}
                    updateSubdomain={updateSubdomain}
                    openDeleteModal={onOpenDeleteModal}
                  />
                ))}
              </VStack>
              <IButton
                name="add-subdomain"
                variant="link"
                leftIcon={<FaPlus />}
                color="brandBlue.500"
                fontSize="12px"
                w="40%"
                customContent
                onClick={onOpenAddDomainFlow}
              >
                Add {subdomainList.length - 1 > 0 && "another"} subdomain
              </IButton>
            </VStack>
          </VStack>
        </Box>
        <SaveAndCancel loading={isLoading} onSave={onSave} onCancel={onClose} />
      </MemoizedCommonDrawer>
      <AddDomainFlow
        isOpen={isOpenAddDomainFlow}
        onClose={onCloseAddDomainFlow}
        variant={DOMAIN_VARIANTS.SUBDOMAIN}
        currDomain={domain}
        addSubdomain={updateSubdomain}
        checkIsSubdomainPresent={checkIsSubdomainPresent}
      />
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal.off()}
        submitHandler={removeSubdomain}
        type={ALERT_TYPE.REMOVE}
        deleteItemType={DELETION_MODAL_TYPES_INFO.subdomain.display}
        customContent={
          <DomainDeleteModalContent domainName={selectedRow} isSubdomain />
        }
      />
    </>
  );
}
