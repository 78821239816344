import { Box, Flex } from "@chakra-ui/layout";
import { Heading, Text } from "@chakra-ui/react";

export default function Unsubscribed() {
  return (
    <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
      <Box
        maxW="md"
        mx="auto"
        display="flex"
        flexDir="column"
        alignItems="center"
        marginTop={20}
      >
        <Box
          bg="white"
          py="12"
          px={{ base: "4", md: "10" }}
          shadow="base"
          w={{ md: "lg" }}
          rounded={{ sm: "lg" }}
        >
          <Flex justify="center" alignItems="center" flexDirection="column">
            <Heading as="h6" mb={6}>
              Unsubscribed
            </Heading>

            <Text mb="6">
              You have successfully unsubscribed from email list.
            </Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
