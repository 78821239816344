import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function IFormLabel({
  children,
  ...props
}: { children: ReactNode } & FormLabelProps) {
  return (
    <FormLabel color="text.50" fontSize="xs" fontWeight={400} {...props}>
      {children}
    </FormLabel>
  );
}
