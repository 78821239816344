import { Box, Heading, Skeleton, Text, Tooltip } from "@chakra-ui/react";

export default function PrimaryHeaderTitle({
  isLoading,
  children,
}: {
  isLoading?: boolean;
  children: string;
}) {
  return (
    <Heading fontSize="16px" maxWidth="340px" width="100%">
      <Tooltip label={children} fontSize="xs">
        <Skeleton isLoaded={!isLoading} h="22px" minW="100px">
          <Box w="100%" lineHeight="1.3">
            <Text textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {children}
            </Text>
          </Box>
        </Skeleton>
      </Tooltip>
    </Heading>
  );
}
