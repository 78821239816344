import { Badge, Switch, SwitchProps } from "@chakra-ui/react";
import ISkeleton, { SKELETON_VARIANT } from "./ISkeleton";

export function SwitchBadge({ enabled }: { enabled: boolean }) {
  const props = enabled
    ? { bgColor: "green.500", children: "ENABLED" }
    : { opacity: 0.7, bgColor: "gray.400", children: "DISABLED" };

  return (
    <Badge
      color="white"
      variant="solid"
      fontSize="10px"
      borderRadius="3px"
      fontWeight="extrabold"
      textAlign="center"
      px={2}
      py={1}
      mb={2}
      w="70px"
      {...props}
    />
  );
}

export default function SwitchBadgeInput({
  isEnabled,
  isLoading = false,
  isReadOnly = false,
  ...props
}: {
  isEnabled: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
} & SwitchProps) {
  return (
    <ISkeleton
      isLoaded={!isLoading}
      noOfLines={1}
      skeletonHeight={6}
      variant={SKELETON_VARIANT.TEXT}
    >
      {isReadOnly ? (
        <SwitchBadge enabled={isEnabled} />
      ) : (
        <Switch isChecked={isEnabled} colorScheme="green" mb={2} {...props} />
      )}
    </ISkeleton>
  );
}
