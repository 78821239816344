import {
  Box,
  ButtonProps,
  Checkbox,
  DrawerFooter,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  DAY_OF_WEEK,
  DELAY_OPTION_VALUES,
  DELAY_TYPE,
  DELAY_UNIT,
} from "../../../../../common/constants/campaign";
import { DelayActionOptions } from "../../../../../common/types/campaign";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import NumberField, {
  NUMBER_FIELD_TYPES,
} from "../../../../../components/NumberField";
import { selectCampaign } from "../../campaignSlice";
import CommonDrawer from "../../components/CommonDrawer";
import InfoBanner from "../../../../../components/InfoBanner";
import { useCallback, useEffect, useMemo, useState } from "react";
import RadioItemLayoutBox from "../../../../../components/RadioItemLayoutBox";
import { IoWarning } from "react-icons/io5";
import { selectSettings } from "../../../settings/settingsSlice";
import { getFormattedTenantTimezone } from "../../../../../common/helper/commonHelper";
import IButton, { BUTTON } from "../../../../../components/IButton";

type DELAY_UNTIL_OPTIONS_TYPE = {
  option?: DELAY_OPTION_VALUES;
  run_time?: string;
  run_date?: string;
  week_days?: DAY_OF_WEEK[];
  is_recipient_timezone?: boolean;
};

type DELAY_BY_OPTIONS_TYPE = {
  delay_value?: number;
  delay_unit?: DELAY_UNIT;
};

const DELAY_UNTIL_OPTIONS = [
  { label: "Time of day", value: DELAY_OPTION_VALUES.TIME_OF_DAY },
  {
    label: "Specific date and time",
    value: DELAY_OPTION_VALUES.SPECIFIC_DATE_TIME,
  },
  { label: "Time in week", value: DELAY_OPTION_VALUES.TIME_IN_WEEK },
  {
    label: "First day of month",
    value: DELAY_OPTION_VALUES.FIRST_DAY_OF_MONTH,
  },
  { label: "Last day of month", value: DELAY_OPTION_VALUES.LAST_DAY_OF_MONTH },
];

const UNIT_OPTIONS = [
  { label: "Minute", value: DELAY_UNIT.MINUTES },
  { label: "Hour", value: DELAY_UNIT.HOURS },
  { label: "Day", value: DELAY_UNIT.DAYS },
];

const DELAY_TYPE_DETAILS = [
  {
    label: "Delay by a fixed interval",
    description: "Specify a time window for delay",
    value: DELAY_TYPE.DELAY_BY,
  },
  {
    label: "Delay until a specific time",
    description: "Delay your journey till the specified time",
    value: DELAY_TYPE.DELAY_UNTIL,
  },
];

function ShowValidationText({ error }: { error: string }) {
  return (
    <HStack hidden={!error} spacing={1}>
      <Icon as={IoWarning} color="red.500" />
      <Text fontSize="12px" color="red.500" pt={1}>
        {error}
      </Text>
    </HStack>
  );
}

function TimeInput({
  delayTime,
  setRunTime,
  activeErrorCheck,
}: {
  delayTime: string | undefined;
  setRunTime: (option: DELAY_UNTIL_OPTIONS_TYPE) => void;
  activeErrorCheck: boolean;
}) {
  return (
    <FormControl isInvalid={!delayTime && activeErrorCheck}>
      <FormLabel fontSize="12px" color="brandBlue.500">
        Select time
      </FormLabel>
      <Input
        // slicing the :00 from the end of the time since the input field does not accept seconds but is required to be passed to BE
        value={delayTime?.slice(0, -3) ?? ""}
        onChange={(e) => {
          if (e.target.value)
            setRunTime({
              run_time: e.target.value + ":00",
            });
        }}
        isInvalid={!delayTime && activeErrorCheck}
        type="time"
        width="120px"
      />
    </FormControl>
  );
}

function DateTimeInput({
  delayRunTime,
  delayDate,
  setDateAndRunTime,
  activeErrorCheck,
}: {
  delayRunTime: string | undefined;
  delayDate: string | undefined;
  setDateAndRunTime: (options: DELAY_UNTIL_OPTIONS_TYPE) => void;
  activeErrorCheck: boolean;
}) {
  function showDateAndRunTime(
    delayDate: string | undefined,
    delayRunTime: string | undefined
  ) {
    return delayDate && delayRunTime ? delayDate + "T" + delayRunTime : ":00";
  }
  function onChangeDateAndRunTime(value: string) {
    const date_slice = value.slice(0, 10);
    const time_slice = value.slice(11) + ":00";

    setDateAndRunTime({
      run_date: date_slice,
      run_time: time_slice,
    });
  }

  return (
    <FormControl isInvalid={!delayRunTime && activeErrorCheck}>
      <FormLabel fontSize="12px" color="brandBlue.500">
        Date and time
      </FormLabel>
      <Input
        type="datetime-local"
        value={showDateAndRunTime(delayDate, delayRunTime)}
        onChange={(e) => {
          if (e.target.value) {
            onChangeDateAndRunTime(e.target.value);
          }
        }}
        isInvalid={!delayRunTime && activeErrorCheck}
        min={new Date().toISOString().slice(0, 16)}
        width="200px"
      />
    </FormControl>
  );
}

function IncDecNumberInput({
  delayIntervalValue,
  setDelayIntervalValue,
  activeErrorCheck,
}: {
  delayIntervalValue: number | undefined;
  setDelayIntervalValue: (options: DELAY_BY_OPTIONS_TYPE) => void;
  activeErrorCheck: boolean;
}) {
  function onValueChangeWrapperForDelayBy(value: number | string | null) {
    setDelayIntervalValue({
      delay_value: value as number,
    });
  }
  return (
    <FormControl isInvalid={!delayIntervalValue && activeErrorCheck}>
      <FormLabel fontSize="12px" color="brandBlue.500">
        Value
      </FormLabel>
      <NumberField
        type={NUMBER_FIELD_TYPES.INTEGER}
        value={delayIntervalValue ?? ""}
        onValueChange={onValueChangeWrapperForDelayBy}
        min={1}
        allowNegativeNumbers={false}
        fieldProps={{
          height: "32px",
          placeholder: "0",
        }}
        width="120px"
      />
    </FormControl>
  );
}

function UnitSelectDropDown({
  delayIntervalUnit,
  setDelayIntervalUnit,
  activeErrorCheck,
}: {
  delayIntervalUnit: string | undefined;
  setDelayIntervalUnit: (options: DELAY_BY_OPTIONS_TYPE) => void;
  activeErrorCheck: boolean;
}) {
  return (
    <FormControl isInvalid={!delayIntervalUnit && activeErrorCheck}>
      <FormLabel fontSize="12px" color="brandBlue.500">
        Unit
      </FormLabel>
      <DropdownWithSearch
        options={UNIT_OPTIONS}
        value={
          UNIT_OPTIONS?.find((option) => option.value === delayIntervalUnit) ??
          null
        }
        placeholder="Unit"
        onChange={(option) => {
          setDelayIntervalUnit({
            delay_unit: option?.value ?? undefined,
          });
        }}
        isInvalid={!delayIntervalUnit && activeErrorCheck}
        controlStyle={{
          width: "120px",
          height: "32px",
          minHeight: "32px",
        }}
      />
    </FormControl>
  );
}

function WeekPicker({
  actionOptions,
  setDelayDayValue,
  activeErrorCheck,
}: {
  actionOptions: DelayActionOptions;
  setDelayDayValue: (options: DELAY_UNTIL_OPTIONS_TYPE) => void;
  activeErrorCheck: boolean;
}) {
  const isTypeDelayUntil = actionOptions.type === DELAY_TYPE.DELAY_UNTIL;

  const weekDayOption = useMemo(() => {
    if (isTypeDelayUntil) {
      return actionOptions.delay_until?.week_days;
    }
  }, [isTypeDelayUntil, actionOptions]);

  function onClickHandler(day: DAY_OF_WEEK) {
    if (isTypeDelayUntil) {
      let updatedWeekDayArray = [] as DAY_OF_WEEK[];

      updatedWeekDayArray = weekDayOption ? [...weekDayOption] : [];
      const dayIndex = updatedWeekDayArray.indexOf(day);

      if (dayIndex === -1) {
        updatedWeekDayArray.push(day);
      } else {
        updatedWeekDayArray.splice(dayIndex, 1);
      }

      setDelayDayValue({
        week_days: updatedWeekDayArray,
      });
    }
  }

  const showWeekdayError =
    isTypeDelayUntil && !weekDayOption?.length && activeErrorCheck;

  const showBorderStyle = useCallback(
    (day: DAY_OF_WEEK) => {
      if (showWeekdayError) {
        return { borderStyle: "1px solid", borderColor: "red.500" };
      } else if (weekDayOption?.includes(day)) {
        return { borderStyle: "2px solid", borderColor: "brandBlue.500" };
      } else {
        return { borderStyle: "", borderColor: "" };
      }
    },
    [weekDayOption, showWeekdayError]
  );

  const borderOnHover = useCallback(
    (day: DAY_OF_WEEK) => {
      if (!weekDayOption?.includes(day)) {
        return "1px solid";
      } else {
        return "";
      }
    },
    [weekDayOption]
  );

  return (
    <FormControl isInvalid={showWeekdayError} mt={4}>
      <FormLabel fontSize="12px" color="brandBlue.500">
        Select day(s)
      </FormLabel>
      <HStack pb={1}>
        {Object.entries(DAY_OF_WEEK).map(([_, day]) => {
          return (
            <Box
              key={day}
              w="45px"
              h="40px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="white"
              borderRadius="6px"
              fontSize="14px"
              _hover={{
                cursor: "pointer",
                border: borderOnHover(day),
              }}
              onClick={() => onClickHandler(day)}
              border={showBorderStyle(day).borderStyle}
              borderColor={showBorderStyle(day).borderColor}
            >
              {day[0]}
            </Box>
          );
        })}
      </HStack>
      {showWeekdayError && <ShowValidationText error={"Select day"} />}
    </FormControl>
  );
}

export default function AddDelayDrawer({
  isInvalid,
  isOpen,
  onClose,
  actionOptions,
  setOptions,
  primaryButtonProps,
}: {
  isInvalid: boolean;
  isOpen: boolean;
  onClose: () => void;
  actionOptions: DelayActionOptions;
  setOptions: (options: DelayActionOptions) => void;
  primaryButtonProps?: ButtonProps;
}) {
  const { activeErrorCheck } = useSelector(selectCampaign);
  const {
    tenantDetails: { data: tenantDetails },
  } = useSelector(selectSettings);

  const [delayOptions, setDelayOptions] = useState(actionOptions);
  const [isErrorCheck, setIsErrorCheck] = useState(false);

  const isTypeDelayUntil = delayOptions.type === DELAY_TYPE.DELAY_UNTIL;
  const isTypeDelayBy = delayOptions.type === DELAY_TYPE.DELAY_BY;
  const isSelected = (value: DELAY_TYPE) => delayOptions.type === value;

  const shouldDisplayErrors = activeErrorCheck || isErrorCheck;

  useEffect(() => {
    if (!isOpen) {
      setDelayOptions(actionOptions);
    }
  }, [actionOptions, isOpen]);

  useEffect(() => {
    setIsErrorCheck(false);
  }, [delayOptions]);

  const showErrorText = useMemo(() => {
    if (delayOptions.type === DELAY_TYPE.DELAY_BY) {
      const delayBy = delayOptions.delay_by;
      if (!delayBy?.delay_unit && !delayBy?.delay_value) {
        return "Enter delay value and unit";
      } else if (!delayBy?.delay_unit) {
        return "Enter delay unit";
      } else if (!delayBy.delay_value) {
        return "Enter delay value";
      }
    }
    if (delayOptions.type === DELAY_TYPE.DELAY_UNTIL) {
      const delayUntil = delayOptions.delay_until;

      if (!delayUntil?.option) return "Select an option";
      else if (
        delayUntil.option === DELAY_OPTION_VALUES.FIRST_DAY_OF_MONTH ||
        delayUntil.option === DELAY_OPTION_VALUES.LAST_DAY_OF_MONTH ||
        delayUntil.option === DELAY_OPTION_VALUES.TIME_OF_DAY ||
        delayUntil.option === DELAY_OPTION_VALUES.TIME_IN_WEEK
      ) {
        if (!delayUntil.run_time) {
          return "Scheduled time is incomplete";
        }
      } else if (delayUntil.option === DELAY_OPTION_VALUES.SPECIFIC_DATE_TIME) {
        if (!delayUntil.run_date || !delayUntil.run_time) {
          return "Scheduled date and time is incomplete";
        }
      }
    }
  }, [delayOptions]);

  function setDelayTypeOption(value: DELAY_TYPE) {
    setDelayOptions({ type: value });
  }

  function setDelayUntilOptions(options: DELAY_UNTIL_OPTIONS_TYPE) {
    if (isTypeDelayUntil) {
      setDelayOptions((prev) => {
        const delayUntil =
          prev.type === DELAY_TYPE.DELAY_UNTIL ? prev.delay_until : {};
        return {
          ...prev,
          delay_until: { ...delayUntil, ...options },
        };
      });
    }
  }

  function setDelayByOptions(options: DELAY_BY_OPTIONS_TYPE) {
    if (isTypeDelayBy) {
      setDelayOptions((prev) => {
        const delayBy = prev.type === DELAY_TYPE.DELAY_BY ? prev.delay_by : {};
        return {
          ...prev,
          delay_by: {
            ...delayBy,
            ...options,
          },
        };
      });
    }
  }

  function showFormOptionsBasedOnDelayOption({
    delayType,
  }: {
    delayType: DELAY_TYPE;
  }) {
    switch (delayType) {
      case DELAY_TYPE.DELAY_BY:
        if (delayOptions.type === DELAY_TYPE.DELAY_BY) {
          return (
            <>
              <HStack width="50%" alignItems="flex-start" pt={3}>
                <IncDecNumberInput
                  delayIntervalValue={
                    delayOptions.delay_by?.delay_value ?? undefined
                  }
                  setDelayIntervalValue={setDelayByOptions}
                  activeErrorCheck={shouldDisplayErrors}
                />
                <UnitSelectDropDown
                  delayIntervalUnit={
                    delayOptions.delay_by?.delay_unit ?? undefined
                  }
                  setDelayIntervalUnit={setDelayByOptions}
                  activeErrorCheck={shouldDisplayErrors}
                />
              </HStack>
              {shouldDisplayErrors && (
                <ShowValidationText error={showErrorText ?? ""} />
              )}
            </>
          );
        }
        break;
      case DELAY_TYPE.DELAY_UNTIL:
        if (delayOptions.type === DELAY_TYPE.DELAY_UNTIL) {
          const delayUntil = delayOptions.delay_until;
          return (
            <>
              <HStack alignItems="flex-start" pt={3}>
                <FormControl
                  isInvalid={!delayUntil?.option && shouldDisplayErrors}
                >
                  <FormLabel fontSize="12px" color="brandBlue.500">
                    Delay until
                  </FormLabel>
                  <DropdownWithSearch
                    options={DELAY_UNTIL_OPTIONS}
                    value={DELAY_UNTIL_OPTIONS?.find(
                      (option) => option.value === delayUntil?.option
                    )}
                    // resets all the fields in delay until when a new option is selected
                    onChange={(option) => {
                      setDelayOptions((prev) => {
                        return {
                          ...prev,
                          delay_until: {
                            is_recipient_timezone:
                              delayUntil?.is_recipient_timezone,
                            option: option?.value,
                          },
                        };
                      });
                    }}
                    isInvalid={!delayUntil?.option && shouldDisplayErrors}
                    isSearchable
                    controlStyle={{
                      width: "210px",
                      height: "32px",
                      minHeight: "32px",
                    }}
                  />
                </FormControl>
                {showFormOptionsBasedOnDelayUntilOption()}
              </HStack>
              <Box mt={0}>
                {shouldDisplayErrors && (
                  <ShowValidationText error={showErrorText ?? ""} />
                )}
              </Box>
              {delayUntil?.option === DELAY_OPTION_VALUES.TIME_IN_WEEK && (
                <WeekPicker
                  actionOptions={delayOptions}
                  setDelayDayValue={setDelayUntilOptions}
                  activeErrorCheck={shouldDisplayErrors}
                />
              )}
            </>
          );
        }
    }
  }

  function showFormOptionsBasedOnDelayUntilOption() {
    // components that should come in the HStack to the right of dropdown
    if (delayOptions.type === DELAY_TYPE.DELAY_UNTIL) {
      const delayUntil = delayOptions.delay_until;
      if (
        DELAY_OPTION_VALUES.TIME_OF_DAY === delayUntil?.option ||
        DELAY_OPTION_VALUES.TIME_IN_WEEK === delayUntil?.option ||
        DELAY_OPTION_VALUES.FIRST_DAY_OF_MONTH === delayUntil?.option ||
        DELAY_OPTION_VALUES.LAST_DAY_OF_MONTH === delayUntil?.option
      ) {
        return (
          <TimeInput
            delayTime={delayUntil?.run_time}
            setRunTime={setDelayUntilOptions}
            activeErrorCheck={shouldDisplayErrors}
          />
        );
        // the weekpicker for time in week is handled separately as it should appear in the vstack
      }
      if (delayUntil?.option === DELAY_OPTION_VALUES.SPECIFIC_DATE_TIME) {
        return (
          <DateTimeInput
            delayRunTime={delayUntil.run_time}
            delayDate={delayUntil.run_date}
            setDateAndRunTime={setDelayUntilOptions}
            activeErrorCheck={shouldDisplayErrors}
          />
        );
      }
    }
  }

  function onSaveDelayOptions() {
    setIsErrorCheck(true);
    if (!showErrorText && !!delayOptions.type) {
      setOptions(delayOptions);
    }
  }

  return (
    <CommonDrawer
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      placement="right"
      title="Add Delay"
      footer={
        <DrawerFooter p={0}>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={onClose}
            children="Cancel"
            mr={3}
          />

          <IButton
            onClick={onSaveDelayOptions}
            children="save"
            {...primaryButtonProps}
          />
        </DrawerFooter>
      }
    >
      <Text fontSize="14px" color="blackAlpha.700" mb={4}>
        Delay Type
      </Text>
      <VStack spacing="8px" w="100%">
        {DELAY_TYPE_DETAILS.map((delay) => (
          <RadioItemLayoutBox
            isSelected={isSelected(delay.value)}
            onRadioItemSelect={() => setDelayTypeOption(delay.value)}
            isError={isInvalid}
          >
            <VStack align="flex-start">
              <Text fontSize="14px" fontWeight="600">
                {delay.label}
              </Text>
              <Text fontSize="14px">{delay.description}</Text>
              {showFormOptionsBasedOnDelayOption({
                delayType: delay.value,
              })}
            </VStack>
          </RadioItemLayoutBox>
        ))}
      </VStack>
      <Text
        ml={4}
        fontSize="12px"
        color="red"
        mt={4}
        hidden={!(shouldDisplayErrors && !delayOptions.type)}
      >
        Please select an option
      </Text>
      <Box mt={8}>
        <Checkbox
          color="blackAlpha.700"
          isChecked={
            isTypeDelayUntil && delayOptions.delay_until?.is_recipient_timezone
          }
          onChange={() => {
            isTypeDelayUntil &&
              setDelayUntilOptions({
                is_recipient_timezone:
                  !delayOptions.delay_until?.is_recipient_timezone,
              });
          }}
          hidden={!isTypeDelayUntil}
        >
          <Text fontSize="14px" color="blackAlpha.700">
            Schedule according to recipient timezone
          </Text>
        </Checkbox>
      </Box>
      <InfoBanner
        width="100%"
        hidden={!isTypeDelayUntil}
        p={3}
        mt={2}
        iconProps={{
          fontSize: "20px",
        }}
      >
        <Text color="brandBlue.500" fontSize="12px" lineHeight="20px">
          {" "}
          The fallback timezone is:
          <br />
          <Text as="span" fontWeight="600">
            Workspace timezone:{" "}
          </Text>
          ({getFormattedTenantTimezone(tenantDetails)})
        </Text>
      </InfoBanner>
    </CommonDrawer>
  );
}
