import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { BsLightningChargeFill } from "react-icons/bs";
import {
  EVENT_CATEGORY,
  TRIGGER_DL_NODE_TYPES,
} from "../../../../../common/constants/dynamicList";

const OUTER_GROUP_MENUS = [
  {
    name: "Product Event",
    event: EVENT_CATEGORY.PRODUCT_ACTIVITY,
    nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
  },
  {
    name: "Marketing Event",
    event: EVENT_CATEGORY.MARKETING_ACTIVITY,
    nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
  },
  {
    name: "Property Change",
    event: undefined,
    nodeType: TRIGGER_DL_NODE_TYPES.PERSON,
  },
];

export default function AddEventButton({
  onClick,
  label,
}: {
  onClick: (
    nodeType: TRIGGER_DL_NODE_TYPES,
    eventType?: EVENT_CATEGORY
  ) => void;
  label: string;
}) {
  return (
    <Menu>
      <MenuButton
        size={"sm"}
        as={Button}
        bg="grayV2.100"
        color="brand.blue"
        borderColor="brand.blue"
        borderWidth="1px"
        _hover={{ bg: "grayV2.200" }}
        _active={{ bg: "grayV2.200" }}
        leftIcon={<BsLightningChargeFill color="brand.blue" fontSize="12px" />}
      >
        {label}
      </MenuButton>
      <MenuList>
        {OUTER_GROUP_MENUS.map((option, index) => {
          return (
            <MenuItem
              fontSize="14px"
              onClick={() => onClick(option.nodeType, option.event)}
              name={`add-${option.event}`}
              key={index}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
