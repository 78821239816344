import { ModalProps } from "@chakra-ui/react";
import { capitalize } from "lodash";
import {
  ChangeEvent,
  useEffect,
  useState,
  KeyboardEvent,
  ReactNode,
} from "react";
import { onEnterKeySubmit } from "../common/helper/commonHelper";
import IModal from "./IModal";
import InputFieldWithError from "./InputFieldWithError";

export enum ASSET_NAME_ACTIONS {
  RENAME = "Rename",
  CREATE = "Create",
  CLONE = "Clone",
}

export default function EditNameModal({
  value,
  onSubmit,
  action,
  asset,
  validateName = (name: string) => "",
  helperText,
  children,
  validateOnClick = false,
  ...props
}: {
  value: string;
  action: ASSET_NAME_ACTIONS;
  onSubmit: (value: string) => void;
  asset: string;
  helperText?: string;
  validateName?: (name: string) => string;
  children?: ReactNode;
  validateOnClick?: boolean;
} & Omit<ModalProps, "children">) {
  const [newName, setNewName] = useState(value);
  const [activeClick, setActiveClick] = useState(false);

  useEffect(() => {
    setNewName(value ?? "");
  }, [value]);

  const title = `${
    action === ASSET_NAME_ACTIONS.CREATE ? "New" : action
  } ${asset}`;

  function onCloseModal() {
    setActiveClick(false);
    props.onClose();
    setNewName(value);
  }

  function onPrimaryClick() {
    if (newName && !validateName(newName)) {
      onSubmit(newName);
      setNewName("");
      setActiveClick(false);
    } else {
      setActiveClick(true);
    }
  }

  function onNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNewName(event.target.value);
  }

  function onKeyDownSubmit(e: KeyboardEvent<HTMLInputElement>) {
    newName &&
      !validateName(newName) &&
      onEnterKeySubmit(e, () => onSubmit(newName));
  }

  return (
    <IModal
      header={{ title }}
      primaryButton={{
        label: action,
        props: {
          onClick: onPrimaryClick,
          isDisabled: !newName || (!validateOnClick && !!validateName(newName)),
        },
      }}
      secondaryButton={{ label: "Cancel", props: { onClick: onCloseModal } }}
      {...props}
      onClose={onCloseModal}
    >
      <InputFieldWithError
        labelText={`${asset} name`}
        value={newName}
        onChange={onNameChange}
        name="name-input"
        placeholder={capitalize(`${asset} name`)}
        onKeyDown={onKeyDownSubmit}
        helperText={helperText}
        errorMsg={activeClick ? validateName(newName) : ""}
        autoFocus
      />
      {children}
    </IModal>
  );
}
