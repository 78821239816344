import { CAMPAIGN_CONTEXT } from "../types/campaign";
import { Query } from "../types/dynamicList";
import { QualificationFrequency, TimeFrame } from "../types/dynamicList";
import {
  FullTriggerCampaign,
  JourneyListRow,
  JourneySettings,
} from "../types/trigger";
import { CAMPAIGN_STATUS } from "./campaign";
import { LOADING_STATES } from "./common";
// import { TIME_FRAME_UNIT } from "./dynamicList";

export enum TIME_FRAME_UNIT {
  YEAR = "YEAR",
  MONTH = "MONTH",
  WEEK = "WEEK",
  DAY = "DAY",
  HOUR = "HOUR",
  SECOND = "SECOND",
  EPOCH = "EPOCH",
}

export enum JOURNEY_TYPES {
  BATCH = "BATCH_CAMPAIGN",
  TRIGGER = "TRIGGER_CAMPAIGN",
}

export enum TRIGGER_CAMPAIGN_STATES {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  ARCHIVED = "ARCHIVED",
  PAUSED = "PAUSED",
}

export enum TIME_FRAME_TYPE {
  RELATIVE = "RELATIVE",
  ABSOLUTE = "ABSOLUTE",
}

export enum CAMPAIGN_TARGET {
  PERSON = "PERSON",
  PRODUCT_USER = "PRODUCT_USER",
}

export enum TRIGGER_CAMPAIGN_QUALIFICATION_RULES {
  ONLY_ONCE = "ONLY_ONCE",
  EVERY_TIME = "EVERY_TIME",
  ONCE_PER_INTERVAL = "ONCE_PER_INTERVAL",
}

export const TRIGGER_QUALIFICATION_RULES_OPTIONS = {
  [TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONLY_ONCE]: "Only once",
  [TRIGGER_CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME]: "Every time",
  [TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL]: "Once every",
};

export const INIT_QUERY: Query = { nodeGroups: [] };

export const INIT_TIME_FRAME: TimeFrame = {
  type: null,
  start: 0,
  end: 0,
  operator: null,
  unit: null,
};

export const INIT_QUALIFICATION_FREQ: QualificationFrequency = {
  type: TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONLY_ONCE,
  interval: {
    unit: TIME_FRAME_UNIT.DAY,
    value: 0,
  },
};

export const INIT_JOURNEY_SETTINGS: JourneySettings = {
  qualificationFrequency: INIT_QUALIFICATION_FREQ,
  timeframe: INIT_TIME_FRAME,
};

export const INIT_TRIGGER_CAMPAIGN: FullTriggerCampaign = {
  campaignId: "",
  campaignName: "",
  currentState: TRIGGER_CAMPAIGN_STATES.DRAFT,
  campaignType: JOURNEY_TYPES.TRIGGER,
  campaignTarget: CAMPAIGN_TARGET.PERSON,
  tenantId: 0,
  audienceCriteria: null,
  triggerCriteria: null,
  exitCriteria: null,
  flow: null,
  journeySettings: null,
  createdAt: 0,
  updatedAt: 0,
  createdBy: "",
  updatedBy: "",
  version: 0,
  campaignTags: null,
};

function reverseRecord<T extends PropertyKey, U extends PropertyKey>(
  input: Record<T, U>
) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [value, key])
  ) as Record<U, T>;
}

export const TARGET_FROM_CONTEXT = {
  [CAMPAIGN_CONTEXT.PERSON]: CAMPAIGN_TARGET.PERSON,
  [CAMPAIGN_CONTEXT.ORG]: CAMPAIGN_TARGET.PRODUCT_USER,
};

export const CONTEXT_FROM_TARGET = reverseRecord(TARGET_FROM_CONTEXT);

export const CAMPAIGN_STATUS_FROM_TRIGGER = {
  [TRIGGER_CAMPAIGN_STATES.DRAFT]: CAMPAIGN_STATUS.DRAFT,
  [TRIGGER_CAMPAIGN_STATES.PUBLISHED]: CAMPAIGN_STATUS.ACTIVE,
  [TRIGGER_CAMPAIGN_STATES.PAUSED]: CAMPAIGN_STATUS.INACTIVE,
  [TRIGGER_CAMPAIGN_STATES.ARCHIVED]: CAMPAIGN_STATUS.INACTIVE, // We don't have a status for this in draft campaign
};

export const INIT_JOURNEY_ROW: JourneyListRow = {
  campaignId: "",
  campaignType: JOURNEY_TYPES.BATCH,
  campaignContext: CAMPAIGN_CONTEXT.PERSON,
  campaignName: "",
  nextSchedule: "",
  updatedAt: new Date(),
  status: CAMPAIGN_STATUS.DRAFT,
  tags: [],
  countProcessed: null,
  countDelivered: null,
  openCount: null,
  totalCount: null,
  statsLoading: LOADING_STATES.INIT,
};
