import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isBefore } from "date-fns";
import { useCallback, useMemo } from "react";
import {
  ConnectionListItem,
  DATA_TYPES,
  STATUS,
} from "../../../../../../../common/types/unifiedMapping";
import { formatDistanceToNow } from "date-fns";
import { FaDotCircle } from "react-icons/fa";
import SpinnerContainer from "../../../../../../../components/SpinnerContainer";

interface CrmSummary {
  status: STATUS;
  syncedContactsIn24Hrs: number;
  syncedLeadsIn24Hrs: number;
  lastSynced: string;
}

export default function Overview({
  connectionList,
  loading,
}: {
  connectionList: ConnectionListItem[];
  loading: boolean;
}) {
  const calculateCRMSummary = useCallback((list: ConnectionListItem[]) => {
    const summary: CrmSummary = {
      status: STATUS.DISABLED,
      syncedContactsIn24Hrs: 0,
      syncedLeadsIn24Hrs: 0,
      lastSynced: "",
    };
    list.forEach((x) => {
      if (x.data_type.includes(DATA_TYPES.CRM)) {
        summary.status = x.status;
        if (!summary.lastSynced && x.last_sync_time) {
          summary.lastSynced = x.last_sync_time;
        } else if (summary.lastSynced && x.last_sync_time) {
          if (
            isBefore(new Date(summary.lastSynced), new Date(x.last_sync_time))
          ) {
            summary.lastSynced = x.last_sync_time;
          }
        }

        summary.syncedContactsIn24Hrs += x.synced_contact;
        summary.syncedLeadsIn24Hrs += x.synced_lead;
      }
    });
    return summary;
  }, []);

  const crmSummary: CrmSummary = useMemo(
    () => calculateCRMSummary(connectionList),
    [calculateCRMSummary, connectionList]
  );

  return (
    <Box
      bg="white"
      p="5"
      width="100%"
      rounded="md"
      borderColor="grayV2.200"
      borderWidth="1px"
    >
      <Heading fontSize="lg">Overview</Heading>
      <SpinnerContainer height="115px" loading={loading}>
        <Grid templateColumns="1fr" gap={6} py="5" px="8">
          <GridItem>
            <HStack mb="3">
              <Icon
                h="3"
                w="3"
                color={
                  crmSummary?.status === STATUS.ACTIVE
                    ? "green.500"
                    : "yellow.500"
                }
                as={FaDotCircle}
              />
              <Text fontWeight="600" fontSize="15">
                CRM data sync is {crmSummary?.status}
              </Text>
            </HStack>
            <VStack fontSize="sm" alignItems="flex-start" pl="6">
              <Text>
                {crmSummary?.syncedContactsIn24Hrs ?? 0} contacts synced in the
                past 24 hours
              </Text>
              <Text>
                {crmSummary?.syncedLeadsIn24Hrs ?? 0} leads synced in the past
                24 hours
              </Text>
              {crmSummary?.lastSynced && (
                <Text>
                  {`Last synced
                ${formatDistanceToNow(new Date(crmSummary?.lastSynced), {
                  addSuffix: true,
                })}`}
                </Text>
              )}
            </VStack>
          </GridItem>
        </Grid>
      </SpinnerContainer>
    </Box>
  );
}
