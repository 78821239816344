import { FlexProps, TextProps } from "@chakra-ui/react";
import { EditorView } from "@codemirror/view";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { isValidTokenOrColumnAccessor } from "../common/helper/codemirrorHelper";
import {
  validateEmail,
  isStringColumnAccessor,
  isStringToken,
} from "../common/helper/commonHelper";
import { selectEmailToken } from "../pages/dashboard/emailtoken/emailTokenSlice";
import ChipsInput, { ChipsStyleProps } from "./ChipsInput";

export default function MultiEmailInput({
  emailList,
  setEmailList,
  setValue,
  allowTokens = false,
  maxEmails = 50,
  errorMsg,
  chipsStyleProps,
  ...props
}: {
  emailList: string[];
  setEmailList: Dispatch<SetStateAction<string[]>>; // we need previous state when using codemirror
  setValue?: Dispatch<SetStateAction<string>>;
  allowTokens?: boolean;
  maxEmails?: number;
  errorMsg?: string;
  chipsContainerProps?: FlexProps;
  chipsStyleProps?: ChipsStyleProps;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isClearable?: boolean;
  readOnlyLabels?: string[];
}) {
  const {
    allColumnsList: { data: allColumnsList },
    emailTokenList: {
      listAll: { data: allTokensList },
    },
  } = useSelector(selectEmailToken);

  const [inputData, setInputData] = useState({
    value: "",
    error: "",
  });
  const reinitialiseCodemirror = useRef(false);

  const CONSTANT_HELPER = allowTokens
    ? {
        initHelperText: "Enter or paste emails separated by comma or space",
        separateLabelKeys: [",", " "],
        props: {
          extensions: [
            EditorView.domEventHandlers({
              paste: handlePaste,
              keydown: handleKeyEvent,
            }),
          ],
          reinitialize: reinitialiseCodemirror.current,
        },
      }
    : {
        initHelperText:
          "Enter or paste emails separated by comma, space or newline",
        separateLabelKeys: [",", " ", "Enter"],
        props: {
          inputProps: {
            onKeyDown: handleKeyEvent,
            onPaste: handlePaste,
          },
          reinitialize: false,
        },
      };

  const [helperText, setHelperText] = useState<{
    text: string;
    style: TextProps;
  }>({
    text: CONSTANT_HELPER.initHelperText,
    style: {},
  });

  useEffect(() => {
    setValue?.(inputData.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData.value]);

  function isDuplicateLabel(label: string) {
    let isDuplicate = false;
    //always use prev list , as codemirror doesn't update state
    setEmailList((prev) => {
      isDuplicate = prev.includes(label);
      return prev;
    });
    return isDuplicate;
  }

  function duplicateLabelCheck(label: string) {
    const duplicateLabel = isDuplicateLabel(label);
    setInputData((prev) => {
      return {
        ...prev,
        error: duplicateLabel
          ? `${label} has already been added to the list`
          : "",
      };
    });
    return !duplicateLabel;
  }

  const validEmailCheck = useCallback(
    (email: string) => {
      const isValid = email && validateEmail(email);
      setInputData((prev) => {
        return {
          ...prev,
          error: !isValid
            ? `Enter a valid email ${allowTokens ? " or token" : ""}`
            : "",
        };
      });
      return isValid;
    },
    [allowTokens]
  );

  function handleChange(code: string) {
    setInputData((prev) => {
      return { ...prev, value: code, error: "" };
    });
  }

  function handleDelete(index: number) {
    setEmailList((prev) => {
      const filteredEmails = [...prev.filter((_, ind) => ind !== index)];
      setHelperText({
        style: {},
        text:
          filteredEmails.length === maxEmails
            ? ""
            : CONSTANT_HELPER.initHelperText,
      });

      return filteredEmails;
    });
  }

  function validateAndRemoveDuplicateEmails(emails: string[]) {
    return emails.filter(
      (email, index) =>
        validateEmail(email) &&
        !isDuplicateLabel(email) &&
        emails.indexOf(email) === index
    );
  }

  function handlePaste(e: ClipboardEvent | React.ClipboardEvent) {
    e.preventDefault();
    const textToBePasted = e.clipboardData?.getData("text") ?? "";
    const pastedEmails = textToBePasted.match(
      new RegExp(/[a-zA-Z0-9.!#$%&'*+/=?^_‘{|}~-]+@[\w\d\\.-]+\.[\w\d\\.-]+/g)
    );

    //validate all the emails and check for duplicates within the pastedEmails and the Emails in the list
    const emailsToAdd = pastedEmails
      ? validateAndRemoveDuplicateEmails(pastedEmails)
      : [];

    setEmailList((prev) => {
      //pasted emails + emails > max number of emails
      const totalEmails = prev.length + emailsToAdd.length;

      if (totalEmails > maxEmails) {
        const emailsToDelete = totalEmails - maxEmails;
        const emailsToPaste = emailsToAdd.length - emailsToDelete;
        emailsToAdd.splice(emailsToAdd.length - emailsToDelete);

        setHelperText({
          text: `${emailsToPaste} email${
            emailsToPaste > 1 ? "s" : ""
          } added to the list`,
          style: {
            color: "green.500",
            fontWeight: "semibold",
          },
        });
      } else {
        setHelperText({
          text: `${emailsToAdd.length} email${
            emailsToAdd.length > 1 ? "s" : ""
          } added to the list`,
          style: {
            color: "green.500",
            fontWeight: "semibold",
          },
        });
      }
      //remove helper text when max emails have been entered
      setTimeout(() => {
        setHelperText({
          style: {},
          text:
            prev.length + emailsToAdd.length === maxEmails
              ? ""
              : CONSTANT_HELPER.initHelperText,
        });
      }, 4000);
      setInputData((prev) => {
        return { ...prev, value: "" };
      });

      return [...prev, ...emailsToAdd];
    });
  }

  function handleKeyEvent(e: KeyboardEvent | React.KeyboardEvent) {
    if (CONSTANT_HELPER.separateLabelKeys.includes(e.key)) {
      e.preventDefault();

      //code mirror doesn't update state
      let email = "";
      setInputData((prev) => {
        email = prev.value.trim();
        return prev;
      });
      if (!email) {
        email = inputData.value.trim();
      }
      if (
        duplicateLabelCheck(email) &&
        ((allowTokens &&
          isValidTokenOrColumnAccessor(email, allTokensList, allColumnsList)
            .isValidEmailVariable) ||
          validEmailCheck(email))
      ) {
        setInputData({ value: "", error: "" });
        setEmailList((prev) => {
          setHelperText({
            style: {},
            text:
              prev.length + 1 === maxEmails
                ? ""
                : CONSTANT_HELPER.initHelperText,
          });
          return [...prev, email];
        });
      }
    }
  }

  useEffect(() => {
    if (allowTokens && inputData.value === "") {
      reinitialiseCodemirror.current = true;
      return () => {
        setTimeout(() => {
          reinitialiseCodemirror.current = false;
        }, 500);
      };
    }
  }, [allowTokens, inputData.value]);

  function clearInputs() {
    const numEmails = emailList.length;

    setHelperText({
      style: {},
      text: `${numEmails} inputs cleared`,
    });

    setEmailList((prev) => []);
  }

  return (
    <ChipsInput
      isCodeMirror={allowTokens}
      labels={emailList}
      value={inputData.value}
      maxInputLength={maxEmails}
      errorMsg={inputData.error || errorMsg}
      onChange={handleChange}
      onChipDelete={handleDelete}
      clearInputs={clearInputs}
      helperText={helperText.text}
      helperTextProps={helperText.style}
      chipsProps={chipsStyleProps}
      filterTokens={isStringToken}
      filterColumnAccessors={isStringColumnAccessor}
      {...CONSTANT_HELPER.props}
      {...props}
    />
  );
}
