import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaCircle } from "react-icons/fa";

export function ListItemComponent({
  children,
  IconType = FaCircle,
}: {
  children: ReactNode;
  IconType: any;
}) {
  return (
    <Flex>
      <Box>
        <Icon fontSize="9px" as={IconType} pb="4px" />
      </Box>
      <Text pl="5px" fontSize="sm">
        {children}
      </Text>
    </Flex>
  );
}
