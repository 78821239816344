import { Box, Icon, Flex, Badge, Tooltip, Text } from "@chakra-ui/react";
import { FaPlusCircle, FaInfoCircle } from "react-icons/fa";
import { OptionProps, GroupBase } from "react-select";
import { CUSTOM_TABLE } from "../../../../../common/constants/campaign";
import { DestinationTable } from "../../../../../common/types/connection";
import IButton from "../../../../../components/IButton";

export const TableImportCustomOption = ({
  children,
  data,
  ...props
}: OptionProps<DestinationTable, false, GroupBase<DestinationTable>>) => {
  return (
    <>
      {data.table_name === CUSTOM_TABLE && !props.isSelected ? (
        <Box py="2" px="3">
          <IButton
            size="sm"
            variant="link"
            leftIcon={<Icon color="gray.700" as={FaPlusCircle}></Icon>}
            onClick={() => props.setValue(data, "select-option")}
            color="gray.600"
            name="add-custom"
          >
            Sync as a custom table
          </IButton>
        </Box>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          py="2"
          px="3"
          bg={props.isSelected ? "#2684FF" : ""}
          color={props.isSelected ? "white" : ""}
          _hover={props.isSelected ? {} : { bg: "#DEEBFF" }}
          onClick={() => props.setValue(data, "select-option")}
        >
          <Text fontSize="sm" isTruncated maxW="200px">
            {data.table_name}
          </Text>
          <Flex alignItems="center">
            <Badge
              fontSize="10px"
              mr="10"
              fontWeight="semibold"
              colorScheme="blue"
            >
              {data.default && "DEFAULT FIELD"}
            </Badge>

            {data.used_in_connection && (
              <Tooltip
                label={`Table in use with ${data.used_in_connection}`}
                fontSize="xs"
              >
                <Box>
                  <Icon
                    color={props.isSelected ? "white" : "brand.blue"}
                    as={FaInfoCircle}
                  ></Icon>
                </Box>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};
