import { RadioGroup, Radio, Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { TEMPLATE_EDITOR_MODE } from "../../../common/types/template";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";

export default function CreateNewTemplateModal({
  templateName,
  isOpen,
  onClose,
  handleSubmit,
}: {
  templateName?: string | null;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (
    value: string | null,
    editorType: TEMPLATE_EDITOR_MODE
  ) => void;
}) {
  const [editorType, setEditorType] = useState<TEMPLATE_EDITOR_MODE>(
    TEMPLATE_EDITOR_MODE.DRAG_DROP
  );
  return (
    <EditNameModal
      value={templateName ?? ""}
      action={ASSET_NAME_ACTIONS.CREATE}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={(value) => handleSubmit(value, editorType)}
      asset="email"
      validateName={(name: string) => {
        return name.length < 3 ? "Name must be at least 3 characters long" : "";
      }}
      validateOnClick
    >
      <RadioGroup
        onChange={(value: string) =>
          setEditorType(value as TEMPLATE_EDITOR_MODE)
        }
        value={editorType}
        mt={2}
      >
        <Radio value={TEMPLATE_EDITOR_MODE.DRAG_DROP}>
          <Box p={2} mb={2}>
            <Text fontSize="16px" fontWeight="600">
              Visual editor
            </Text>
            <Text>Drag and drop email editor</Text>
          </Box>
        </Radio>
        <Radio value={TEMPLATE_EDITOR_MODE.CODE}>
          <Box p={2}>
            <Text fontSize="16px" fontWeight="600">
              HTML editor (Advanced)
            </Text>
            <Text>Code editor to manually create email using HTML</Text>
          </Box>
        </Radio>
      </RadioGroup>
    </EditNameModal>
  );
}
