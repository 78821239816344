import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createTagApi,
  listTagApi,
  listTagSummaryApi,
  tagAssetApi,
  untagAssetApi,
} from "../../../common/api/campaign/tag";
import {
  addFavouriteTagApi,
  clearFavouriteTagApi,
  listFavouriteTagApi,
  removeFavouriteTagApi,
} from "../../../common/api/integrations/tag";
import {
  ASSET_TYPES,
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  initializeLoadingData,
  isLoading,
} from "../../../common/helper/commonHelper";
import { LoadingWithData, PaginationType } from "../../../common/types/common";
import { TagDetails } from "../../../common/types/tag";
import { RootState } from "../../../store";

interface TagState {
  tagList: PaginationType<TagDetails>;
  tagSummaryList: LoadingWithData<{ [id: string]: string }>;
  creatingTag: LOADING_STATES;

  favouriteTags: LoadingWithData<string[]>;
}

const initialState: TagState = {
  tagList: INITIAL_PAGINATION,
  tagSummaryList: initializeLoadingData({}),
  creatingTag: LOADING_STATES.INIT,

  favouriteTags: initializeLoadingData([]),
};

export const listTag = createAsyncThunk(
  "tag/list",
  async (searchKeyword: string, thunkApi) => {
    const {
      tag: {
        tagList: { pageSize, currentPageNo: pageNo },
      },
    } = thunkApi.getState() as RootState;
    return await listTagApi({
      pageSize,
      pageNo,
      searchKeyword,
      columnsToSearchIn: ["name"],
    });
  }
);

export const listTagSummary = createAsyncThunk(
  "tag/list.all",
  async () => {
    return await listTagSummaryApi();
  },
  {
    condition: (_, { getState }) => {
      const {
        tag: {
          tagSummaryList: { loading },
        },
      } = getState() as RootState;

      if (isLoading(loading)) {
        return false;
      }
    },
  }
);

export const createTag = createAsyncThunk(
  "tag/create",
  async (name: string) => {
    return await createTagApi(name);
  }
);

export const listFavouriteTags = createAsyncThunk(
  "tag/favourites.list.all",
  async () => {
    return await listFavouriteTagApi();
  }
);

export const addFavouriteTag = createAsyncThunk(
  "tag/favourites.add",
  async (id: string) => {
    return await addFavouriteTagApi(id);
  }
);

export const clearFavouriteTag = createAsyncThunk(
  "tag/favourites.remove.all",
  async () => {
    return await clearFavouriteTagApi();
  }
);

export const removeFavouriteTag = createAsyncThunk(
  "tag/favourites.remove",
  async (id: string) => {
    return await removeFavouriteTagApi(id);
  }
);

export const tagAsset = createAsyncThunk(
  "tag/tag-asset",
  async ({
    tagId,
    assetId,
    assetType,
  }: {
    tagId: string;
    assetId: string;
    assetType: ASSET_TYPES;
  }) => {
    return await tagAssetApi({
      tag_id: tagId,
      asset_id: assetId,
      asset_type: assetType,
    });
  }
);

export const untagAsset = createAsyncThunk(
  "tag/untag-asset",
  async ({
    tagId,
    assetId,
    assetType,
  }: {
    tagId: string;
    assetId: string;
    assetType: ASSET_TYPES;
  }) => {
    return await untagAssetApi({
      tag_id: tagId,
      asset_id: assetId,
      asset_type: assetType,
    });
  }
);

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    setTagPage(state, { payload: pageNo }) {
      if (pageNo && pageNo <= (state.tagList.totalPageCount ?? 1)) {
        state.tagList.changingPage = true;
        state.tagList.currentPageNo = pageNo;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      //create tag
      .addCase(createTag.pending, (state) => {
        state.creatingTag = LOADING_STATES.LOADING;
      })
      .addCase(createTag.fulfilled, (state, action) => {
        state.creatingTag = LOADING_STATES.SUCCESS;
        const { name, tag_id } = action.payload.tag;
        state.tagSummaryList.data = {
          ...state.tagSummaryList.data,
          [tag_id]: name,
        };
      })
      .addCase(createTag.rejected, (state) => {
        state.creatingTag = LOADING_STATES.FAILED;
      })

      //list tags
      .addCase(listTag.pending, (state) => {
        state.tagList.fetchingList = true;
      })
      .addCase(listTag.fulfilled, (state, { payload }) => {
        state.tagList.fetchingList = false;
        state.tagList.list = payload.records;
        state.tagList.count = payload.record_count;
        state.tagList.totalPageCount = payload.page_count;
      })
      .addCase(listTag.rejected, (state) => {
        state.tagList.fetchingList = false;
      })

      //list tag summary
      .addCase(listTagSummary.pending, (state) => {
        state.tagSummaryList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listTagSummary.fulfilled, (state, { payload }) => {
        const tagsDict: { [id: string]: string } = {};
        payload.tags.forEach((tag) => {
          tagsDict[tag.tag_id] = tag.name;
        });
        state.tagSummaryList.data = tagsDict;
        state.tagSummaryList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listTagSummary.rejected, (state) => {
        state.tagSummaryList.loading = LOADING_STATES.FAILED;
      })

      //list favourite tags
      .addCase(listFavouriteTags.pending, (state) => {
        state.favouriteTags.loading = LOADING_STATES.LOADING;
      })
      .addCase(listFavouriteTags.fulfilled, (state, { payload }) => {
        state.favouriteTags.data = payload.tags?.favourites ?? [];
        state.favouriteTags.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listFavouriteTags.rejected, (state) => {
        state.favouriteTags.loading = LOADING_STATES.FAILED;
      })

      //remove favourite tag
      .addCase(addFavouriteTag.fulfilled, (state, { meta, payload }) => {
        if (payload.status) {
          state.favouriteTags.data = [meta.arg, ...state.favouriteTags.data];
        }
      })

      //remove favourite tag
      .addCase(removeFavouriteTag.fulfilled, (state, { meta, payload }) => {
        if (payload.status) {
          state.favouriteTags.data = state.favouriteTags.data.filter(
            (tag) => tag !== meta.arg
          );
        }
      });
  },
});

export const { setTagPage } = tagSlice.actions;

export const selectTag = (state: RootState) => state.tag;

export default tagSlice.reducer;
