import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { TAB } from "../../../../../../common/constants/unifiedMapping";
import IButton from "../../../../../../components/IButton";
import IPopoverButton from "../../../../../../components/IPopoverButton";
import urls from "../../../../../../urls";
import { ButtonProps, HStack, Text, useDisclosure } from "@chakra-ui/react";
import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  isInit,
  isLoading,
  isFulfilled,
} from "../../../../../../common/helper/commonHelper";
import {
  isSfSyncActive,
  isSfSyncPaused,
  isSfSyncYetToStart,
} from "../../../../../../common/helper/salesforceHelper";
import { SALESFORCE_CONNECTION_STATE } from "../../../../../../common/types/salesforce";
import { useAppDispatch } from "../../../../../../store";
import { selectConnection, getUnifiedMapping } from "../../../connectionSlice";
import {
  selectSalesforce,
  pauseSalesforceSync,
  resumeSalesforceSync,
  startSalesforceSync,
  deleteSalesforceConnection,
} from "../../salesforceSlice";
import SfDisconnectModal from "../SfDisconnectModal";
import { SOURCES } from "../../../../../../common/types/unifiedMapping";

export function PauseSyncButton(props: ButtonProps) {
  return (
    <IButton leftIcon={<FaPauseCircle />} {...props}>
      Pause sync
    </IButton>
  );
}

export function StartSyncButton({
  disablePopover = false,
  ...props
}: { disablePopover?: boolean } & ButtonProps) {
  const popoverBodyComponent = (
    <Text fontSize="sm">
      You can start the sync only after you have mapped at least 1 Salesforce
      and Inflection field in the unified mapping{" "}
      <Link to={`${urls.unifiedMapping}?${TAB}=2`}>
        <Text
          as="span"
          textDecoration="underline"
          fontWeight="semibold"
          fontSize="sm"
        >
          Click Here
        </Text>
      </Link>
    </Text>
  );

  return (
    <IPopoverButton
      colorScheme="blue"
      bg="brand.blue"
      leftIcon={<FaPlayCircle />}
      popoverBodyComponent={popoverBodyComponent}
      disablePopover={disablePopover}
      {...props}
    >
      Start sync
    </IPopoverButton>
  );
}

export function ResumeSyncButton(props: ButtonProps) {
  return (
    <IButton leftIcon={<FaPlayCircle />} {...props}>
      Resume Sync
    </IButton>
  );
}

export function PauseDisconnectHeader() {
  const {
    unifiedConnection: { mapping },
  } = useSelector(selectConnection);
  const {
    isOpen: isOpenDisconnectModal,
    onOpen: onOpenDisconnectModal,
    onClose: onCloseDisconnectModal,
  } = useDisclosure();

  const {
    sfConnection,
    deletingSfConnection,
    pausingSfSync,
    resumingSfSync,
    startingSync,
  } = useSelector(selectSalesforce);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSalesforceMappedInUnifiedMapping = useMemo(() => {
    return mapping.data?.person_mapping?.mapping?.some((value) =>
      value.source?.some(({ type }) => type === SOURCES.SALESFORCE)
    );
  }, [mapping.data?.person_mapping?.mapping]);

  useEffect(() => {
    if (isInit(mapping.loading)) {
      dispatch(getUnifiedMapping());
    }
  });

  function pauseSync() {
    if (
      sfConnection?.connectionId &&
      isSfSyncActive(sfConnection?.currentState)
    ) {
      dispatch(pauseSalesforceSync(sfConnection?.connectionId));
    }
  }

  function resumeSync() {
    if (
      sfConnection?.connectionId &&
      isSfSyncPaused(sfConnection?.currentState)
    ) {
      dispatch(resumeSalesforceSync(sfConnection?.connectionId));
    }
  }

  function startSync() {
    if (
      sfConnection?.connectionId &&
      isSfSyncYetToStart(sfConnection?.currentState)
    ) {
      dispatch(startSalesforceSync(sfConnection?.connectionId));
    }
  }

  async function onDeleteSfConnection() {
    if (sfConnection?.connectionId) {
      const deleteResp = await dispatch(
        deleteSalesforceConnection(sfConnection.connectionId)
      );
      if (isFulfilled(deleteResp.meta.requestStatus)) {
        navigate(urls.unifiedMapping);
      }
    }
  }

  function getCurrentStateButton() {
    switch (sfConnection?.currentState) {
      case SALESFORCE_CONNECTION_STATE.ACTIVE:
        return (
          <PauseSyncButton
            onClick={pauseSync}
            isLoading={isLoading(pausingSfSync)}
          />
        );
      case SALESFORCE_CONNECTION_STATE.PAUSED:
        return (
          <ResumeSyncButton
            onClick={resumeSync}
            isLoading={isLoading(resumingSfSync)}
          />
        );
      case SALESFORCE_CONNECTION_STATE.YET_TO_START:
        return (
          <StartSyncButton
            onClick={startSync}
            isLoading={isLoading(startingSync)}
            isDisabled={!isSalesforceMappedInUnifiedMapping}
            disablePopover={isSalesforceMappedInUnifiedMapping}
          />
        );
      default:
        <></>;
    }
  }

  return (
    <HStack py={2} justifyContent="flex-end">
      {getCurrentStateButton()}
      <IButton
        bg="brand.red"
        colorScheme="red"
        _hover={{
          colorScheme: "red",
          opacity: 0.8,
        }}
        onClick={onOpenDisconnectModal}
      >
        Disconnect
      </IButton>
      <SfDisconnectModal
        isOpen={isOpenDisconnectModal}
        onClose={onCloseDisconnectModal}
        onDeleteSfConnection={onDeleteSfConnection}
        deletingSfConnection={isLoading(deletingSfConnection)}
      />
    </HStack>
  );
}
