import { HStack, Box } from "@chakra-ui/react";
import EditableHeading from "../../../../components/EditableHeading";
import IButton, { BUTTON } from "../../../../components/IButton";
import SubHeader from "../../../../components/SubHeader";

export default function WebhookHeader({
  heading,
  openRenameModal,
  lastSavedStatus,
  loading,
  onSave,
  returnUrl,
  isFetchingData,
}: {
  heading: string;
  openRenameModal: () => void;
  lastSavedStatus: string;
  loading: boolean;
  onSave: () => void;
  returnUrl: string;
  isFetchingData?: boolean;
}) {
  return (
    <SubHeader
      heading={<EditableHeading name={heading} openModal={openRenameModal} />}
      returnUrl={returnUrl}
      isLoading={isFetchingData}
    >
      <HStack px={2}>
        <Box fontSize="xs" style={{ gap: "6px" }} color="gray.400" mr={3}>
          {lastSavedStatus}
        </Box>
        <IButton
          variant={BUTTON.PRIMARY}
          name="save-asset"
          onClick={onSave}
          isLoading={loading}
          hidden={isFetchingData}
        >
          Save
        </IButton>
      </HStack>
    </SubHeader>
  );
}
