import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import {
  DestinationTable,
  TableLink,
} from "../../../../../common/types/connection";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { getUnSelectedTables } from "../helper";
import { TableImportCustomOption } from "./TableImportCustomOption";

export default function TableSelectForm({
  selectedTables,
  sourceTables,
  destinationTables,
  addNewTable,
}: {
  selectedTables: TableLink[];
  sourceTables: string[];
  destinationTables: DestinationTable[];
  addNewTable: (table: TableLink) => void;
}) {
  const [sourceTable, setSourceTable] = useState("");
  const [destinationTable, setDestinationTable] =
    useState<DestinationTable | null>(null);

  const { unSelectedSourceTables, unSelectedDestinationTables } =
    getUnSelectedTables(selectedTables, sourceTables, destinationTables);

  function addTable() {
    addNewTable({
      tempId: Math.random(),
      table_name: sourceTable,
      destination_table_name: destinationTable?.table_name || "",
      columns: [],
    });

    setSourceTable("");
    setDestinationTable(null);
  }

  const unSelectedSourceTableOptions = unSelectedSourceTables.map((table) => ({
    label: table,
    value: table,
  }));

  return (
    <Box bg="grayV2.100" px="4" py="5">
      <Flex alignItems="flex-end">
        <FormControl id="select-source-table" mr="5" flex="1">
          <FormLabel fontSize="sm">Source Table</FormLabel>
          <DropdownWithSearch
            options={unSelectedSourceTableOptions}
            onChange={(option) => setSourceTable(option?.value ?? "")}
            value={
              unSelectedSourceTableOptions.find(
                (table) => table.value === sourceTable
              ) ?? null
            }
          />
        </FormControl>
        <FormControl id="select-destination-table" mr="5" flex="1">
          <FormLabel fontSize="sm">Destination Table</FormLabel>
          <DropdownWithSearch
            options={unSelectedDestinationTables.filter(
              (x) => !x.used_in_connection || x.multi_source
            )}
            getOptionLabel={(option) => option.table_name}
            getOptionValue={(option) => option.table_name}
            onChange={(option) => setDestinationTable(option || null)}
            value={destinationTable}
            components={{ Option: TableImportCustomOption }}
          />
        </FormControl>
        <Box flex="2">
          <IButton
            variant={BUTTON.PRIMARY}
            isDisabled={!destinationTable || !sourceTable}
            name="add-table-to-sync"
            onClick={addTable}
          >
            Add table to sync
          </IButton>
        </Box>
      </Flex>
    </Box>
  );
}
