import {
  HStack,
  Text,
  VStack,
  Flex,
  Box,
  Switch,
  Heading,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import {
  ObjectSettings,
  SALESFORCE_OBJECT_TYPE,
} from "../../../../../../../common/types/salesforceLegacyService";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";
import { updateSalesforceObjects } from "../../../salesforceLegacySlice";
import { useAppDispatch } from "../../../../../../../store";
import { isRejected } from "../../../../../../../common/helper/commonHelper";

const LEAD_AND_CONTACT_ITEM = {
  label: "Leads & Contacts",
  value: "lead+contact",
};
const CONTACT_ITEM = {
  label: "Contacts",
  value: SALESFORCE_OBJECT_TYPE.CONTACT,
};
const LEAD_ITEM = { label: "Leads", value: SALESFORCE_OBJECT_TYPE.LEAD };

const OPTIONS = [LEAD_ITEM, CONTACT_ITEM, LEAD_AND_CONTACT_ITEM];

const DEFAULT_OBJECT_SETTINGS: ObjectSettings = {
  lead: false,
  contact: false,
  account: false,
  opportunity: false,
};

export default function SalesforceLegacyGeneralSettings({
  objectSettings,
  saving,
}: {
  objectSettings: ObjectSettings | null;
  saving: boolean;
}) {
  const dispatch = useAppDispatch();

  const [val, setVal] = useState(mapToDropdown(objectSettings));
  const [localObjectSettings, setLocalObjectSettings] = useState(
    objectSettings ?? DEFAULT_OBJECT_SETTINGS
  );
  const [isAccountSync, setIsAccountSync] = useState(objectSettings?.account);

  useEffect(() => {
    if (objectSettings) {
      setLocalObjectSettings(objectSettings);
      setVal(mapToDropdown(objectSettings));
    }
  }, [objectSettings]);

  function mapToDropdown(settings: ObjectSettings | null) {
    if (settings) {
      if (settings.lead && settings.contact) {
        return LEAD_AND_CONTACT_ITEM;
      } else if (settings.lead && !settings.contact) {
        return LEAD_ITEM;
      } else if (!settings.lead && settings.contact) {
        return CONTACT_ITEM;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function mapDropdownToRequestParams(
    option: { label: string; value: string } | null
  ) {
    let newObjectSettings = cloneDeep(
      localObjectSettings ?? DEFAULT_OBJECT_SETTINGS
    );
    if (option) {
      switch (option.value) {
        case CONTACT_ITEM.value:
          newObjectSettings.contact = true;
          newObjectSettings.lead = false;
          break;
        case LEAD_ITEM.value:
          newObjectSettings.contact = false;
          newObjectSettings.lead = true;
          break;
        case LEAD_AND_CONTACT_ITEM.value:
          newObjectSettings.contact = true;
          newObjectSettings.lead = true;
          break;
        default:
          newObjectSettings.contact = false;
          newObjectSettings.lead = false;
          break;
      }
    }
    return newObjectSettings;
  }

  async function onChangePerson(
    option: { label: string; value: string } | null
  ) {
    option ? setVal(option) : setVal(null);
    if (option) {
      const newObjectSettings = mapDropdownToRequestParams(option);
      setLocalObjectSettings(newObjectSettings);
      const result = await dispatch(
        updateSalesforceObjects({ data: newObjectSettings, toast: true })
      );
      if (isRejected(result.meta.requestStatus)) {
        setLocalObjectSettings(objectSettings ?? DEFAULT_OBJECT_SETTINGS);
        setVal(mapToDropdown(objectSettings));
      }
    }
  }

  function enableAccountSync() {
    setIsAccountSync(!isAccountSync);
    const data: ObjectSettings = {
      lead: objectSettings?.lead as boolean,
      contact: objectSettings?.contact as boolean,
      account: !isAccountSync as boolean,
      opportunity: objectSettings?.opportunity as boolean,
    };
    dispatch(updateSalesforceObjects({ data }));
  }

  return (
    <VStack alignItems="flex-start" spacing="3" p="5" bg="white" rounded="md">
      <VStack alignItems="flex-start" spacing="1">
        <Heading fontSize="md">Contact/Lead Sync</Heading>
        <HStack justifyContent="space-between">
          <Text fontSize="sm">Sync Salesforce person object from sources </Text>
          <Flex justifyContent="flex-start" width="100px">
            <DropdownWithSearch
              name="sync-lead-contact"
              options={OPTIONS}
              placeholder="Select"
              value={val}
              onChange={(option) => onChangePerson(option)}
              isInvalid={!val}
              controlStyle={{
                width: "200px",
                borderBottomLeftRadius: "0",
                borderTopLeftRadius: "0",
              }}
            />
          </Flex>
        </HStack>
      </VStack>
      <VStack alignItems="flex-start" spacing="1">
        <Heading fontSize="md">Account Sync</Heading>
        <HStack spacing={3}>
          <Text fontSize="sm">Enable account sync</Text>
          <Switch
            colorScheme="green"
            isChecked={isAccountSync}
            onChange={enableAccountSync}
          />
        </HStack>
        <Box bg="blackAlpha.50" px={3} py={3}>
          <Text fontSize="sm">
            Account sync will automatically create and update the accounts in
            Inflection and Salesforce
          </Text>
        </Box>
      </VStack>
    </VStack>
  );
}
