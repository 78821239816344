import {
  StaticListDetails,
  StaticListContactsFilterType,
  StaticListContactsData,
  DynamicListType,
  StaticListSummary,
} from "../../types/campaign";
import { ListFilterItem, PaginatedList } from "../../types/common";
import { campaignRequest } from "./api";

export async function listStaticListsApi(
  searchKeyword: string,
  pageSize: number,
  pageNumber: number,
  filters?: ListFilterItem
): Promise<PaginatedList<StaticListDetails>> {
  return campaignRequest({
    url: "/assets/static-list/lists.list",
    data: {
      page_size: pageSize,
      page_number: pageNumber,
      search_keyword: searchKeyword,
      filters,
    },
    shouldRetryApiCall: true,
  });
}

export async function createStaticListApi(
  name: string
): Promise<StaticListDetails> {
  return campaignRequest({
    url: "/assets/static-list/list.create",
    data: { name },
  });
}

export async function renameStaticListApi(
  name: string,
  staticListId: string
): Promise<{ staticlist_id: string; success: boolean }> {
  return campaignRequest({
    url: "/assets/static-list/list.rename",
    data: { static_list_id: staticListId, name },
  });
}

export async function deleteStaticListApi(
  staticListId: string
): Promise<{ staticlist_id: string; success: boolean }> {
  return campaignRequest({
    url: "/assets/static-list/list.delete",
    data: { static_list_id: staticListId },
  });
}

export async function getStaticListApi(
  staticListId: string
): Promise<StaticListDetails> {
  return campaignRequest({
    url: "/assets/static-list/list.get",
    data: { static_list_id: staticListId },
    shouldRetryApiCall: true,
  });
}

export async function listStaticListContactsApi(
  data: StaticListContactsFilterType
): Promise<PaginatedList<StaticListContactsData>> {
  return campaignRequest({
    url: "/assets/static-list/contacts.list",
    data,
    shouldRetryApiCall: true,
  });
}

export async function removeStaticListContactsApi(
  staticListId: string,
  emails: string[]
): Promise<{ deleted_rowcount: number }> {
  return campaignRequest({
    url: "/assets/static-list/contacts.remove",
    data: { static_list_id: staticListId, emails },
  });
}

export async function removeAllStaticListContactsApi(
  staticListId: string
): Promise<{ deleted_rowcount: number }> {
  return campaignRequest({
    url: "/assets/static-list/contacts.remove-all",
    data: { static_list_id: staticListId },
  });
}

export async function getStaticListDlInsertPreviewApi(
  dynamicList: DynamicListType[]
): Promise<{ count: number }> {
  return campaignRequest({
    url: "/assets/static-list/contacts/query.preview",
    data: { query: { conditions: dynamicList } },
  });
}

export async function getStaticListDlInsertApi(
  staticListId: string,
  dynamicList: DynamicListType[]
): Promise<{ copied_rowcount: number }> {
  return campaignRequest({
    url: "/assets/static-list/contacts/query.insert",
    data: { static_list_id: staticListId, query: { conditions: dynamicList } },
  });
}

export async function listAllStaticListsApi(): Promise<{
  records: StaticListSummary[];
}> {
  return campaignRequest({
    url: "/assets/static-list/lists.all",
    data: {},
  });
}
