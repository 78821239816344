import { useSelector } from "react-redux";
import { isLoading } from "../../../common/helper/commonHelper";
import { Auth } from "../../../common/types/auth";
import { useAppDispatch } from "../../../store";
import { deactivateUser, selectUser } from "./userSlice";
import { Text } from "@chakra-ui/react";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { ALERT_TYPE } from "../../../common/constants/common";

export default function DeactivateUserModal({
  onClose,
  isOpen,
  data,
}: {
  onClose: () => void;
  isOpen: boolean;
  data: Auth | null;
}) {
  const dispatch = useAppDispatch();
  const { deactivatedAccount } = useSelector(selectUser);

  async function confirmDeactivation() {
    if (data) {
      await dispatch(deactivateUser(data.id));
    }
    onClose();
  }

  return (
    <DeleteConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={"Deactivate user"}
      submitHandler={confirmDeactivation}
      isLoading={isLoading(deactivatedAccount.loading)}
      type={ALERT_TYPE.DEACTIVATE}
      customContent={
        <Text fontSize="14px" color="blackAlpha.700">
          Are you sure you want to deactivate{" "}
          <Text as="span" fontWeight="bold" color="blackAlpha.900">
            {data?.name ?? "this user"}
          </Text>
          ?
        </Text>
      }
    />
  );
}
