import { Flex, Icon, IconProps, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import CommonDrawer, { CommonDrawerSize } from "../../components/CommonDrawer";

export default function FlowStepReport({
  isOpen,
  onClose,
  icon,
  color,
  title,
  children,
  size,
}: {
  isOpen: boolean;
  onClose: () => void;
  icon: IconType;
  color: IconProps["color"];
  title?: string;
  children: React.ReactNode;
  size?: CommonDrawerSize;
}) {
  return (
    <CommonDrawer
      title={
        <Flex alignItems="center">
          <Icon as={icon} color={color} fontSize="20px" mr="2" />
          <Text>{title} </Text>
        </Flex>
      }
      size={size ?? "xl"}
      placement="left"
      isOpen={isOpen}
      onClose={onClose}
      btnRef={{ current: null }}
      variant="primary"
      drawerBodyProps={{ position: "relative" }}
    >
      {children}
    </CommonDrawer>
  );
}
