import React, { useMemo } from "react";
import useCodemirror, { getTheme } from "./useCodemirror";
import { EditorState, SelectionRange } from "@codemirror/state";
import { CodemirrorType } from "../../common/types/common";
import { EditorView } from "@codemirror/view";
import { closeCompletion } from "@codemirror/autocomplete";
import { debounce } from "lodash";

const findParentFunction = (
  doc: string,
  pos: number,
  wordAt: (pos: number) => SelectionRange | null
) => {
  function findFunctionNameAt(i: number): string {
    const range = wordAt(i);
    if (range) {
      const word = doc.slice(range.from, range.to);
      if (word !== "column" && word !== "token") {
        return word;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const range = wordAt(pos);
  if (range && doc[range.to] === "(") {
    const functionName = findFunctionNameAt(pos);
    if (functionName) {
      return functionName;
    }
  }

  let counter = 0;
  counter = 0;
  for (let i = pos; i > 0; i--) {
    if (doc[i] === ")") {
      counter++;
    }
    if (doc[i] === "(") {
      if (counter === 0) {
        const functionName = findFunctionNameAt(i);
        if (functionName) {
          return functionName;
        }
      } else {
        counter--;
      }
    }
  }
  return "";
};

export default function Codemirror({
  value,
  onChange,
  onSelection,
  onFunctionSelection,
  lang = "jinja2",
  theme = "light",
  autoCompleteOptions,
  viewPlugins,
  extensions: extraExtensions,
  style = {},
  className,
  reinitialize = false,
}: CodemirrorType) {
  const themeRef = React.useRef(theme);

  React.useEffect(() => {
    themeRef.current = theme;
  }, [theme]);

  const [editor, editorRef] = useCodemirror();

  const editorStateRef = React.useRef<EditorState | null>(null);

  React.useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setTheme(getTheme(editor.codemirror, theme));
  }, [editor, theme]);

  const debouncedSave = useMemo(() => debounce(findParentFunction, 1000), []);

  React.useEffect(() => {
    const updateCodemirror = async () => {
      if (!editor) {
        return;
      }

      const state = editorStateRef.current;
      const closeAutoCompleteExtension = EditorView.domEventHandlers({
        blur: (event, view) => closeCompletion(view),
      });
      const theme = getTheme(editor.codemirror, themeRef.current);

      if (state && !reinitialize) {
        // Do not reassign editor state on next render unless specifically made to reinitialzie
        // Reinitialize needs to be handled manually because once codemirror is rendered the state cannot -
        // be updated on every render as it's an expensive operation and practically makes the editor unusable
        // So in cases where we need codemirror state to be updated with the new params, we can set the -
        // reinitialize flag to true and let the component create a new codemirror state and set it.
        // But we recommend to set reinitialize to false as soon as codemirror is rerenderd with the new state -
        // due to preformance issues I mentioned before

        // Why do we need to update codemirror state when just a document update would suffice in most cases
        // Answered by author marijn himself - https://discuss.codemirror.net/t/document-changes-in-cm6/3284
        return;
      }

      // Create the editor state object for this file

      let didCancel = false;

      const extensions = await editor.loadExentions({
        lang,
        theme,
        autoCompleteOptions,
        viewPlugins,
        extraExtensions,
      });
      if (didCancel) {
        return;
      }

      const { codemirror } = editor;

      // Keep our state in sync with the editor's state. This listener is called
      // after view.setState and on any future updates
      const updateListener = codemirror.view.EditorView.updateListener.of(
        (update) => {
          if (onFunctionSelection) {
            const functionName = debouncedSave(
              update.state.doc.toString(),
              update.state.selection.main.head,
              (pos: number) => update.state.wordAt(pos)
            );

            if (functionName) {
              onFunctionSelection(functionName);
            }
          }

          if (onSelection) {
            onSelection(update.state.selection.main);
          }

          if (update.docChanged) {
            onChange(update.state.doc.toString());
          }
          editorStateRef.current = update.state;
        }
      );

      const newState = codemirror.state.EditorState.create({
        doc: value,
        extensions: [extensions, updateListener, closeAutoCompleteExtension],
      });

      editor.view.setState(newState);

      return () => {
        didCancel = true;
      };
    };
    updateCodemirror();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editor,
    value,
    onChange,
    lang,
    autoCompleteOptions,
    viewPlugins,
    extraExtensions,
    reinitialize,
  ]);

  return (
    <div
      className={`codemirror-container ${className ?? ""}`}
      style={style}
      ref={editorRef}
    />
  );
}
