import { Box, Divider, Flex, HStack, VStack } from "@chakra-ui/layout";
import { Icon, Text } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { FaRegIdCard } from "react-icons/fa";
import {
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL1,
  OperatorType,
  TableList,
  ValueTypes,
} from "../../../../../common/types/campaign";
import {
  FILTER_TABLE_NAME,
  FILTER_TYPE,
} from "../../../../../common/constants/campaign";
import { DynamicListContext } from "../../../../../components/dynamic-list/DynamicList";
import {
  ValueSelectFields,
  DynamicListValueFields,
} from "../../../../../components/dynamic-list/DynamicListValueFields";
import RemoveRowButton from "../../../../../components/RemoveRowButton";
import { FilterGroupBox } from "./FilterGroupBox";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";

enum Fields {
  FILTER = "filter",
  OPERATOR = "operator",
  VALUE = "value",
  CONNECTOR = "connector",
  VALIDATION_ERROR = "validation_error",
}

function PersonFilterGroupRow({
  onFilterGroupChange,
  filterGroup,
  showRemoveButton,
  onRemoveRow,
  isReadOnly,
}: {
  onFilterGroupChange: (
    field: Fields,
    value: string | number | CONNECTOR | ValueTypes
  ) => void;
  filterGroup: DynamicListChildL1;
  showRemoveButton: boolean;
  onRemoveRow: () => void;
  isReadOnly?: boolean;
}) {
  const { operators, filterList } = useSelector(selectDynamicList);
  const { campaignContext } = useContext(DynamicListContext);
  const tableList = useMemo(
    () => filterList[campaignContext]?.[FILTER_TYPE.PERSON],
    [campaignContext, filterList]
  );
  const {
    filter,
    operator,
    value,
    validation_error: validationError,
  } = filterGroup;

  const formatTableList = useCallback((list: TableList) => {
    return Object.entries(list[FILTER_TABLE_NAME.PERSON] ?? {})
      ?.filter(([, value]) => !value.ui_hidden)
      ?.map(([key, value]) => {
        return { label: value.display_name || key, value: key };
      });
  }, []);

  const tableArray: { label: string; value: string }[] = useMemo(
    () => formatTableList(tableList?.data ?? {}),
    [formatTableList, tableList?.data]
  );

  const getOperatorList = useCallback(
    (colType: string) => {
      return operators.data?.[colType as OperatorType];
    },
    [operators]
  );

  const findColType = useCallback(
    (filter: string, filterList?: TableList): string => {
      return (
        (filterList as TableList)?.[FILTER_TYPE.PERSON]?.[filter]?.type ?? null
      );
    },
    []
  );

  const colType = useMemo(
    () => (filter ? findColType(filter, tableList?.data) : null),
    [filter, tableList, findColType]
  );

  const operatorList = useMemo(
    () => (colType ? getOperatorList(colType) : null),
    [colType, getOperatorList]
  );

  const operatorDetails = useMemo(
    () => (operator && operatorList ? operatorList[operator] : null),
    [operatorList, operator]
  );

  function setFilter(value: string) {
    onFilterGroupChange(Fields.FILTER, value);
  }

  function setOperator(value: string) {
    onFilterGroupChange(Fields.OPERATOR, value);
  }

  function setValue(value: ValueTypes) {
    onFilterGroupChange(Fields.VALUE, value);
  }

  function setValidationError(msg: string) {
    onFilterGroupChange(Fields.VALIDATION_ERROR, msg);
  }

  function setValueHandler(values: ValueTypes) {
    const invalid =
      operatorDetails?.arguments_types?.length &&
      values.length &&
      values.every((val) => !val);

    setValidationError(invalid ? "Invalid values" : "");
    setValue(values);
  }

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
        pl: "6",
      }
    : {
        spacing: "3",
        gridGap: "3",
        pl: "2",
      };

  return (
    <HStack alignItems="flex-start" width="100%" wrap="wrap" {...wrapperStyle}>
      <Box id="person-activity-selector">
        <ValueSelectFields
          options={tableArray}
          value={filter || ""}
          onChange={setFilter}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </Box>

      {filter && colType && operatorList && (
        <Box>
          <ValueSelectFields
            options={Object.values(operatorList).map((op) => ({
              label: op.display,
              value: op.id,
            }))}
            value={operator || ""}
            onChange={setOperator}
            validationError={validationError}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}

      {operator && colType && operatorDetails && (
        <HStack>
          <DynamicListValueFields
            value={value as ValueTypes}
            validationError={validationError}
            onChange={(val) => setValueHandler(val)}
            noOfArguments={operatorDetails.arguments}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            isReadOnly={isReadOnly}
          />
        </HStack>
      )}

      {!isReadOnly && showRemoveButton && (
        <Flex flex={"1"} justifyContent="flex-end">
          <RemoveRowButton onClick={onRemoveRow} />
        </Flex>
      )}
    </HStack>
  );
}

export default function ContactUpdateFilterGroup({
  data,
  label,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  label: string;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const { filterList } = useSelector(selectDynamicList);
  const { activeErrorCheck, campaignContext } = useContext(DynamicListContext);

  const tableList = useMemo(
    () => filterList[campaignContext]?.[FILTER_TYPE.PERSON],
    [campaignContext, filterList]
  );

  function onDataChange(
    field: string,
    index: number,
    value: string | number | CONNECTOR | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case Fields.FILTER:
        dataCopy.children[index].filter = value as string;
        dataCopy.children[index].operator = null;
        dataCopy.children[index].value = [];
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.OPERATOR:
        dataCopy.children[index].operator = value as string;
        dataCopy.children[index].value = [];
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.VALUE:
        dataCopy.children[index].value = value as ValueTypes;
        dataCopy.children[index].validation_error = "";
        break;
      case Fields.CONNECTOR:
        dataCopy.children[index].connector = value as CONNECTOR;
        break;
      case Fields.VALIDATION_ERROR:
        dataCopy.children[index].validation_error = value as string;
    }
    onChange(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        py: "0",
        px: "0",
        spacing: "1",
      }
    : {
        py: "5",
        px: "3",
        spacing: "4",
        divider: <Divider />,
      };

  return (
    <FilterGroupBox
      id={id}
      onRemove={onRemove}
      isReadOnly={isReadOnly}
      rounded="lg"
      borderColor="gray.200"
      borderWidth="1px"
      bg="white"
    >
      {tableList && (tableList.data as TableList)[FILTER_TABLE_NAME.PERSON] && (
        <VStack alignItems="flex-start" {...wrapperStyle}>
          <HStack>
            {isReadOnly && <Icon as={FaRegIdCard} color="brand.blue" />}
            <Text fontSize="sm">Contact field updated</Text>
          </HStack>
          {data.children &&
            data.children.map((child, index) => {
              return (
                <Box key={index} width="100%">
                  <PersonFilterGroupRow
                    onFilterGroupChange={(field, value) =>
                      onDataChange(field, index, value)
                    }
                    filterGroup={{
                      ...child,
                      validation_error: activeErrorCheck
                        ? child.validation_error
                        : "",
                      connector:
                        index !== 0
                          ? child.connector || CONNECTOR.EMPTY
                          : CONNECTOR.EMPTY,
                    }}
                    showRemoveButton={!!(data.children.length > 1)}
                    onRemoveRow={() => onRemoveRow(index)}
                    isReadOnly={isReadOnly}
                  />
                </Box>
              );
            })}
        </VStack>
      )}
    </FilterGroupBox>
  );
}
