import { HStack, Input, IconButton, Icon } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { FaPencilAlt, FaCheck } from "react-icons/fa";
import { onEnterKeySubmit } from "../../common/helper/commonHelper";

export function EditableGroupName({
  placeholder,
  children,
  groupName,
  onGroupNameChange,
}: {
  placeholder: string;
  children: ReactNode;
  groupName: string;
  onGroupNameChange: (value: string) => void;
}) {
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(groupName);

  function changeName() {
    if (editName) {
      onGroupNameChange(name);
    }
    setEditName(!editName);
  }

  return (
    <HStack
      _hover={{
        "&>button": { visibility: "visible" },
      }}
    >
      {editName ? (
        <Input
          type="text"
          value={name}
          placeholder={placeholder}
          height="28px"
          fontSize="12px"
          borderColor="gray.200"
          bg="white"
          name="item-name-input"
          maxLength={100}
          onChange={(e) => setName(e.target.value)}
          width="100%"
          onKeyDown={(e) => onEnterKeySubmit(e, changeName)}
        />
      ) : (
        children
      )}
      <IconButton
        aria-label="Edit name"
        name="edit-save-name-button"
        size="xs"
        icon={<Icon fontSize="12px" as={editName ? FaCheck : FaPencilAlt} />}
        width="25px"
        bg="gray.200"
        visibility={editName ? "visible" : "hidden"}
        onClick={changeName}
      />
    </HStack>
  );
}
