import {
  Center,
  Flex,
  Spinner,
  Icon,
  HStack,
  Checkbox,
  Heading,
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Badge,
  Text,
  Switch,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import {
  FaArrowLeft,
  FaSearch,
  FaChevronRight,
  FaExclamationCircle,
} from "react-icons/fa";
import { CUSTOM_TABLE } from "../../../../../common/constants/campaign";
import {
  TableLink,
  ColSave,
  DestinationTable,
  TableColumn,
} from "../../../../../common/types/connection";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import { getSortedColumnsList, formatErrorMsg } from "../helper";

function NoTablesSelectedMessage({ loading }: { loading: boolean }) {
  return (
    <Center h="100%" bg="grayV2.100">
      <Flex alignItems="center" color="gray.400">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Icon mr="3" as={FaArrowLeft}></Icon>
            <Text fontSize="sm">Choose a table to map fields</Text>
          </>
        )}
      </Flex>
    </Center>
  );
}

function CustomColumnsSelector({
  activeTable,
  toggleCheckbox,
  selectAll,
}: {
  activeTable: TableLink;
  selectAll: (checked: boolean) => void;
  toggleCheckbox: (
    event: ChangeEvent<HTMLInputElement>,
    column: ColSave
  ) => void;
}) {
  const [query, setQuery] = useState("");

  function CustomColumnRow({
    index,
    col,
  }: {
    index: number;
    col: ColSave;
  }): JSX.Element {
    const isMapped = col.checked && col.disabled;

    return (
      <HStack spacing="5" p="3">
        <Text minW="30px">{index + 1}</Text>
        <Checkbox
          isDisabled={col.disabled}
          isChecked={col.checked}
          onChange={(e) => toggleCheckbox(e, col)}
        />
        <Text color={isMapped ? "gray.300" : "default"}>{col.column_name}</Text>
        <Badge
          mr="3"
          fontWeight="medium"
          fontSize="10px"
          bgColor="gray.200"
          color="gray.500"
          hidden={!isMapped}
        >
          Mapped
        </Badge>
      </HStack>
    );
  }

  return (
    <Flex flexDir="column" minH="300px">
      <Flex>
        <Heading bg="gray.100" p={4} fontSize="sm">
          Map as custom columns
        </Heading>
        <Flex alignItems="center" px="3" bg="blue.50" flex="1">
          <InputGroup size="sm" width="50%">
            <InputLeftElement
              pointerEvents="none"
              children={<Icon as={FaSearch} color="gray.400" />}
            />
            <Input
              onChange={(e) => setQuery(e.target.value)}
              border="none"
              placeholder="Search columns"
              name="search-columns-input"
            />
          </InputGroup>
        </Flex>
      </Flex>
      {activeTable.destination_table_name === CUSTOM_TABLE && !query && (
        <HStack spacing="5" p="3">
          <Checkbox
            isChecked={activeTable.columns.every((col) => col.checked)}
            onChange={(e) => selectAll(e.target.checked)}
          />
          <Text>Select All</Text>
        </HStack>
      )}
      {activeTable.columns
        .filter(
          (col) =>
            !col.isDestinationPresent &&
            col.column_name.toLowerCase().includes(query.toLowerCase())
        )
        .map((col, index) => (
          <CustomColumnRow key={col.column_name} index={index} col={col} />
        ))}
    </Flex>
  );
}

function DefaultColumnSelector({
  destinationTables,
  activeTable,
  setDestinationColumnName,
  editMode,
  saveActive,
  togglePrimaryKey,
}: {
  destinationTables: DestinationTable[];
  activeTable: TableLink;
  setDestinationColumnName: (
    sourceCol: string,
    destinationCol: TableColumn
  ) => void;
  editMode: React.MutableRefObject<boolean>;
  saveActive: boolean;
  togglePrimaryKey: (
    table: TableColumn,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}) {
  const destinationTableColumnsSorted = getSortedColumnsList(
    destinationTables,
    activeTable.destination_table_name
  );

  const pkDisabled = activeTable.columns.some(
    (column) => column.disabled && column.primary_key
  );

  return (
    <>
      {destinationTableColumnsSorted?.map((destinationCol, index) => {
        const sourceCol = activeTable.columns.find(
          (x) =>
            x.destination_column_name &&
            x.destination_column_name === destinationCol.name
        );

        const isColDisabled = editMode.current && sourceCol?.disabled;

        return (
          <Flex
            px="5"
            py="3"
            alignItems="center"
            border="gray.200"
            borderTopWidth="1px"
            key={index}
          >
            <Box flex="2" width="90%">
              <DropdownWithSearch
                placeholder="Select source column"
                options={activeTable.columns}
                value={sourceCol || null}
                getOptionLabel={(option) => option.column_name}
                getOptionValue={(option) => option.column_name}
                isOptionDisabled={(option) =>
                  !!option.destination_column_name || !!option.disabled
                }
                isDisabled={isColDisabled}
                isInvalid={saveActive && destinationCol.required && !sourceCol}
                onChange={(option) =>
                  setDestinationColumnName(
                    option?.column_name ?? "",
                    destinationCol
                  )
                }
                isSearchable
                isClearable
              />
            </Box>
            <Icon mx="5" fontSize="xs" as={FaChevronRight} />
            <Flex flex="3" alignItems="center" justifyContent="space-between">
              <Text maxW="400px" isTruncated display="flex" alignItems="center">
                {destinationCol.name}
                <Text
                  fontSize="xs"
                  as="span"
                  fontWeight="bold"
                  ml="1"
                  color="brand.blue"
                >
                  {destinationCol.required ? "(Required)" : ""}
                </Text>
              </Text>

              <Flex alignItems="center" justifyContent="space-between">
                <Flex alignItems="center">
                  <Switch
                    size="sm"
                    mr="2"
                    isDisabled={
                      !sourceCol ||
                      sourceCol?.destination_column_name === "" ||
                      pkDisabled
                    }
                    isChecked={sourceCol?.primary_key}
                    onChange={(e) => togglePrimaryKey(destinationCol, e)}
                  />
                  <Text fontSize="sm">PK</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </>
  );
}

export default function TableImportColumnsList({
  editMode,
  loading,
  activeTable,
  destinationTables,
  saveActive,
  pkValid,
  invalidList,
  toggleCheckbox,
  setDestinationColumnName,
  selectAllCustomColumns,
  togglePrimaryKey,
}: {
  editMode: React.MutableRefObject<boolean>;
  loading: boolean;
  activeTable: TableLink | undefined;
  destinationTables: DestinationTable[] | null;
  saveActive: boolean;
  pkValid: boolean;
  invalidList: number[];
  toggleCheckbox: (
    event: ChangeEvent<HTMLInputElement>,
    column: ColSave
  ) => void;
  setDestinationColumnName: (
    sourceCol: string,
    destinationColumn: TableColumn
  ) => void;
  selectAllCustomColumns: (checked: boolean) => void;
  togglePrimaryKey: (
    table: TableColumn,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}) {
  const errorMsg =
    activeTable && destinationTables && !loading
      ? formatErrorMsg(activeTable, invalidList, pkValid)
      : "";
  return (
    <Flex
      flexDir="column"
      flex="1"
      borderColor="gray.200"
      borderWidth="1px"
      bg="white"
    >
      {activeTable && destinationTables && !loading ? (
        <>
          <HStack bg="grayV2.100">
            <Heading p={4} fontSize="sm">
              {`${
                activeTable.columns.filter(
                  (col) => col.checked || col.destination_column_name
                ).length
              } Columns selected`}
            </Heading>
            {errorMsg && (
              <Flex alignItems="center" fontSize="12px" color="red.500">
                <Icon mx="2" as={FaExclamationCircle} />
                {errorMsg}
              </Flex>
            )}
          </HStack>

          <DefaultColumnSelector
            togglePrimaryKey={togglePrimaryKey}
            editMode={editMode}
            destinationTables={destinationTables}
            activeTable={activeTable}
            setDestinationColumnName={setDestinationColumnName}
            saveActive={saveActive}
          />

          <CustomColumnsSelector
            selectAll={selectAllCustomColumns}
            activeTable={activeTable}
            toggleCheckbox={toggleCheckbox}
          />
        </>
      ) : (
        <NoTablesSelectedMessage loading={loading} />
      )}
    </Flex>
  );
}
