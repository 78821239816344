import {
  Input,
  InputProps,
  Text,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { CODEMIRROR_SIZE } from "../../common/constants/common";
import { CodeMirrorTypeWithAutoCompleteFilter } from "../../common/types/common";
import CodemirrorInput from "./CodemirrorInput";
import { addEmptyContextIfBlank } from "../../common/helper/commonHelper";

export default function OptionalCodemirrorInput({
  isInvalid = false,
  reinitCodemirror,
  onChange,
  type,
  isCodemirror = true,
  inputProps,
  isDisabled,
  additionalContent,
  isReadOnly = false,
  filterTokens,
  filterColumnAccessors,
  ...props
}: {
  isInvalid?: boolean;
  reinitCodemirror?: boolean;
  onChange: (code: string) => void;
  type: CODEMIRROR_SIZE;
  isCodemirror?: boolean;
  inputProps?: InputProps | TextareaProps;
  isDisabled?: boolean;
  additionalContent?: ReactNode;
  isReadOnly?: boolean;
} & CodeMirrorTypeWithAutoCompleteFilter) {
  function renderNormalInput() {
    switch (type) {
      case CODEMIRROR_SIZE.SINGLE_LINE:
        return (
          <Input
            value={props.value || ""}
            onChange={(e) => onChange(e.target.value)}
            fontSize="14px"
            {...(inputProps as InputProps)}
            isDisabled={isDisabled}
          />
        );
      default:
        return (
          <Textarea
            value={props.value || ""}
            onChange={(e) => onChange(e.target.value)}
            fontSize="14px"
            {...(inputProps as TextareaProps)}
            isDisabled={isDisabled}
          />
        );
    }
  }
  if (isReadOnly) {
    return <Text fontSize="14px">{addEmptyContextIfBlank(props.value)}</Text>;
  } else if (isCodemirror)
    return (
      <CodemirrorInput
        type={type}
        reinitialize={reinitCodemirror}
        onChange={onChange}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
        additionalContent={additionalContent}
        filterColumnAccessors={filterColumnAccessors}
        filterTokens={filterTokens}
        {...props}
      />
    );
  return renderNormalInput();
}
