import {
  Text,
  Icon,
  Heading,
  Box,
  Flex,
  Checkbox,
  FormControl,
  Spinner,
  Center,
  Grid,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaCopy, FaInfoCircle, FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { WarehouseTypeDetails } from "../../../../../common/types/common";
import ConnectionDetailsForm from "../../components/ConnectionDetailsForm";
import {
  resetFlags,
  selectConnection,
  clearConnectionDetailsData,
  setIsReadOnly,
  setSelectedSchema,
  schemaSet,
  wizardNextStep,
  wizardPrevStep,
  whiteListedIps,
  wizardSetStep,
  validateReadOnlyConnection,
} from "../../connectionSlice";
import {
  copyToClipboard,
  isFulfilled,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import IButton from "../../../../../components/IButton";
import ITitle from "../../../../../components/ITitle";
import { SOURCES } from "../../../../../common/types/unifiedMapping";
import BigqueryAccessRequestDetails from "./BigqueryAccessRequestDetails";
import CommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";
import InputFieldWithError from "../../../../../components/InputFieldWithError";
import { ConnectionReadOnlyValidateConfig } from "../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../store";
import { validateConnectionApi } from "../../../../../common/api/integrations/connection";

function IpAddressRow({ ip }: { ip: string }) {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text fontWeight="bold" color="orange.400" fontSize="14px">
        {ip}
      </Text>
      <IButton
        onClick={() => copyToClipboard(ip)}
        variant="ghost"
        color="gray.500"
        size={"xs"}
        name="copy-button"
        leftIcon={<Icon as={FaCopy} />}
      >
        Copy
      </IButton>
    </Flex>
  );
}

function SelectSchemaForm({
  serviceDetails,
  setSchema,
  schema,
  setupConnection,
  closeModal,
}: {
  serviceDetails: WarehouseTypeDetails;
  setSchema: React.Dispatch<React.SetStateAction<string>>;
  schema: string;
  setupConnection: () => void;
  closeModal: () => void;
}) {
  const { schemaList, updatingConnection } = useSelector(selectConnection);

  const dispatch = useDispatch();

  const goToPrevStep = useCallback(() => {
    if (serviceDetails?.client_id === SOURCES.BIGQUERY) {
      dispatch(setIsReadOnly(false));
      dispatch(wizardSetStep(3));
    } else {
      dispatch(wizardSetStep(2));
    }
  }, [dispatch, serviceDetails?.client_id]);

  return (
    <CommonDrawer
      title={serviceDetails?.name + " details"}
      onClose={() => {
        dispatch(clearConnectionDetailsData());
        closeModal();
      }}
      isOpen={true}
      goBack={goToPrevStep}
      size="md"
      placement="right"
    >
      <FormControl id="schema">
        <Text fontSize="14px" pb="10px">
          {`Enter a ${
            serviceDetails.client_id === SOURCES.BIGQUERY ? "dataset" : "schema"
          } to sync`}
        </Text>
        <DropdownWithSearch
          options={schemaList?.map((schema) => {
            return {
              label: schema,
              value: schema,
            };
          })}
          onChange={(option) => setSchema(option?.value ?? "")}
          controlStyle={{
            fontSize: "14px",
          }}
        />
      </FormControl>
      <ConnectionButtons
        closeModal={closeModal}
        isDisabled={!schema || updatingConnection}
        isLoading={updatingConnection}
        loadingText="Setting up connection"
        onClick={setupConnection}
      />
    </CommonDrawer>
  );
}

export function useTableItems() {
  const tableRequiredError = "Table name is required";

  const [tableNames, setTableNames] = useState([""]);

  const addTable = () => {
    setTableNames([...tableNames, ""]);
  };

  const removeTable = (index: number) => {
    setTableNames(tableNames.filter((_, i) => i !== index));
  };

  const handleTableValuesInputChange = (index: number, value: string) => {
    const newTableNames = [...tableNames];
    newTableNames[index] = value;
    setTableNames(newTableNames);
  };

  return {
    tableNames,
    addTable,
    removeTable,
    handleTableValuesInputChange,
    tableRequiredError,
  };
}

export function TableItemsForm({
  tableNames,
  activeErrorCheck,
  addTable,
  removeTable,
  handleTableValuesInputChange,
  tableRequiredError,
}: {
  tableNames: string[];
  activeErrorCheck: boolean;
  addTable: () => void;
  removeTable: (index: number) => void;
  handleTableValuesInputChange: (index: number, value: string) => void;
  tableRequiredError: string;
}) {
  return (
    <>
      {tableNames.map((item, index) => (
        <Grid key={index} gridTemplateColumns={"340px 1fr 1fr"}>
          <GridItem>
            <InputFieldWithError
              labelText=""
              placeholder={"table name"}
              fontSize="12px"
              type="string"
              errorMsg={activeErrorCheck && !item ? tableRequiredError : ""}
              onChange={(e) => {
                handleTableValuesInputChange(index, e.target.value);
              }}
              value={item}
            />
          </GridItem>
          <GridItem pl={5}>
            <IButton
              variant="secondary"
              isDisabled={index === 0 && tableNames.length === 1}
              name="remove-button"
              height="40px"
              width="40px"
              borderRadius="40px"
              customContent={true}
              children={<Icon as={FaMinus} />}
              onClick={() => removeTable(index)}
            />
          </GridItem>
          {index === tableNames.length - 1 && (
            <GridItem pl={2}>
              <IButton
                variant="primary"
                name="add-button"
                height="40px"
                width="40px"
                borderRadius="40px"
                customContent={true}
                children={<Icon as={FaPlus} />}
                onClick={addTable}
              />
            </GridItem>
          )}
        </Grid>
      ))}
    </>
  );
}

// currently read only is a requirement only for bigQuery service
function SelectReadOnlySchemaForm({
  serviceDetails,
  setDataset,
  dataset,
  setupConnection,
  closeModal,
}: {
  serviceDetails: WarehouseTypeDetails;
  setDataset: React.Dispatch<React.SetStateAction<string>>;
  dataset: string;
  setupConnection: () => void;
  closeModal: () => void;
}) {
  const dispatch = useAppDispatch();

  const {
    hostName,
    selectedService,
    validatingReadOnlyConnection,
    connectionDetails,
  } = useSelector(selectConnection);

  const goToPrevStep = useCallback(() => {
    if (serviceDetails?.client_id === SOURCES.BIGQUERY) {
      setIsReadOnly(true);
      setDataset("");
      dispatch(wizardSetStep(3));
    } else {
      dispatch(wizardSetStep(2));
    }
  }, [dispatch, setDataset, serviceDetails?.client_id]);

  const [activeErrorCheck, setActiveErrorCheck] = useState(false);

  const {
    tableNames,
    addTable,
    removeTable,
    handleTableValuesInputChange,
    tableRequiredError,
  } = useTableItems();

  const hasErrorCheck = useMemo(() => {
    return (
      !dataset || tableNames.length === 0 || tableNames.some((item) => !item)
    );
  }, [dataset, tableNames]);

  const readOnlyReq: ConnectionReadOnlyValidateConfig = {
    host_name: hostName,
    schema_name: dataset,
    source_tables: tableNames.map((item) => item),
    typ: selectedService,
  };

  async function handleSubmit() {
    if (!hasErrorCheck) {
      setActiveErrorCheck(false);
      if (connectionDetails) {
        const response = await dispatch(
          validateReadOnlyConnection({
            data: readOnlyReq,
            connId: connectionDetails.id,
            sourceTables: tableNames.map((item) => item),
          })
        );
        const resPayload = response.payload as {
          response: Awaited<ReturnType<typeof validateConnectionApi>>;
        };
        if (
          isFulfilled(response.meta.requestStatus) &&
          resPayload.response.success
        ) {
          setupConnection();
        }
      }
    } else {
      setActiveErrorCheck(true);
    }
  }

  return (
    <CommonDrawer
      title={serviceDetails?.name + " Details"}
      onClose={() => {
        dispatch(clearConnectionDetailsData());
        closeModal();
      }}
      isOpen={true}
      goBack={goToPrevStep}
      size="md"
      placement="right"
    >
      <FormControl>
        <Text fontSize="14px" pb="6px">
          {`Enter a ${
            serviceDetails.client_id === SOURCES.BIGQUERY ? "dataset" : "schema"
          } to sync`}
        </Text>
        <InputFieldWithError
          labelText=""
          placeholder={"dataset name"}
          fontSize="12px"
          type="string"
          errorMsg={activeErrorCheck && !dataset ? "Dataset is required" : ""}
          onChange={(e) => {
            setDataset(e.target.value);
          }}
          value={dataset}
        />
        <Text fontSize="14px" pb="10px" mt="10px">
          Enter table names from the dataset to sync
        </Text>
        <TableItemsForm
          activeErrorCheck={activeErrorCheck}
          tableNames={tableNames}
          addTable={addTable}
          removeTable={removeTable}
          handleTableValuesInputChange={handleTableValuesInputChange}
          tableRequiredError={tableRequiredError}
        />
      </FormControl>
      <ConnectionButtons
        closeModal={closeModal}
        loadingText="Setting Up Connection"
        onClick={handleSubmit}
        isLoading={isLoading(validatingReadOnlyConnection)}
      />
    </CommonDrawer>
  );
}

function StaticIpForm({
  whiteListedIP,
  toggleWhitelistCheckbox,
  closeModal,
  goToPrevStep,
}: {
  whiteListedIP: boolean;
  toggleWhitelistCheckbox: () => void;
  closeModal: () => void;
  goToPrevStep: () => void;
}) {
  const dispatch = useDispatch();

  const { whiteListIps, fetchingWhiteListIps } = useSelector(selectConnection);

  useEffect(() => {
    if (!fetchingWhiteListIps && !whiteListIps) {
      dispatch(whiteListedIps());
    }
  }, [dispatch, fetchingWhiteListIps, whiteListIps]);

  return (
    <CommonDrawer
      title={"Redshift"}
      onClose={closeModal}
      goBack={goToPrevStep}
      isOpen={true}
      size="md"
      placement="right"
    >
      <HStack color="blue.700" fontSize="14px" mb={4}>
        <Icon as={FaInfoCircle} />
        <ITitle title="Important prerequisite" />
      </HStack>
      <Heading size={"xs"}>Required Permissions</Heading>
      <Text fontSize={"xs"} mt={2}>
        Inflection requires specific permissions to sync data. You can find
        detailed Redshift instructions
      </Text>

      <Heading size={"xs"} mt={5}>
        Allow these static IPs to establish connection
      </Heading>

      <Text fontSize={"xs"} mt={2}>
        Allow the following IPs in your AWS dashboard to connect
      </Text>
      <Box mt={2}>
        {whiteListIps && whiteListIps.length
          ? whiteListIps.map((ip) => {
              return <IpAddressRow ip={ip} />;
            })
          : fetchingWhiteListIps && (
              <Center h="100px">
                <Spinner />
              </Center>
            )}
      </Box>

      <Checkbox
        isChecked={whiteListedIP}
        onChange={toggleWhitelistCheckbox}
        mt={6}
      >
        <Text fontWeight="semibold" fontSize="sm">
          I've Whitelisted the IPs
        </Text>
      </Checkbox>
      <ConnectionButtons
        closeModal={closeModal}
        onClick={() => dispatch(wizardNextStep())}
        isLoading={fetchingWhiteListIps}
        isDisabled={!whiteListedIP && !fetchingWhiteListIps}
      />
    </CommonDrawer>
  );
}

export default function ConnectionDetailsModal({
  isOpen,
  onClose,
  goToNext,
  goToStart,
  serviceDetails,
}: {
  isOpen: boolean;
  onClose: () => void;
  goToNext: () => void;
  goToStart: () => void;
  serviceDetails: WarehouseTypeDetails;
}) {
  const dispatch = useDispatch();

  const [schema, setSchema] = useState("");

  const [whiteListedIP, setWhiteListedIP] = useState(false);
  const toggleWhitelistCheckbox = () => setWhiteListedIP(!whiteListedIP);

  const {
    createCredsWizardStep,
    creatingNewConnection,
    connectionDetails,
    addedSchema,
    bigQuery: { isReadOnlyPermission },
  } = useSelector(selectConnection);

  useEffect(() => {
    return () => {
      dispatch(resetFlags());
    };
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(resetFlags());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (addedSchema) {
      dispatch(setSelectedSchema(schema));
      closeModal();
      goToNext();
    }
  }, [addedSchema, goToNext, dispatch, schema, closeModal]);

  const setupConnection = () => {
    if (connectionDetails && connectionDetails.id) {
      dispatch(schemaSet({ id: connectionDetails.id, schemaName: schema }));
    }
  };

  function stepSwitch(step: number) {
    function goBackToStepOne() {
      dispatch(clearConnectionDetailsData());
      if (serviceDetails.client_id !== SOURCES.REDSHIFT) {
        goToStart();
      } else {
        dispatch(wizardPrevStep());
      }
    }

    switch (step) {
      case 1:
        if (serviceDetails && serviceDetails.client_id === SOURCES.REDSHIFT) {
          return (
            <StaticIpForm
              whiteListedIP={whiteListedIP}
              toggleWhitelistCheckbox={toggleWhitelistCheckbox}
              closeModal={closeModal}
              goToPrevStep={() => {
                dispatch(clearConnectionDetailsData());
                goToStart();
              }}
            />
          );
        } else {
          dispatch(wizardNextStep());
        }
        return <></>;

      case 2:
        return (
          <ConnectionDetailsForm
            connectionDetails={connectionDetails}
            serviceDetails={serviceDetails}
            loading={creatingNewConnection}
            closeModal={closeModal}
            goToPrevStep={goBackToStepOne}
          />
        );

      case 3:
        if (serviceDetails && serviceDetails.client_id === SOURCES.BIGQUERY) {
          return (
            <BigqueryAccessRequestDetails
              onClose={() => {
                dispatch(clearConnectionDetailsData());
                closeModal();
              }}
              isReadOnlyPermission={isReadOnlyPermission}
            />
          );
        }
        dispatch(wizardNextStep());
        return <></>;

      case 4:
        return (
          <SelectSchemaForm
            serviceDetails={serviceDetails}
            setSchema={setSchema}
            schema={schema}
            setupConnection={setupConnection}
            closeModal={closeModal}
          />
        );
      case 5: // implementation only for bigquery
        return (
          <SelectReadOnlySchemaForm
            serviceDetails={serviceDetails}
            setDataset={setSchema}
            dataset={schema}
            setupConnection={setupConnection}
            closeModal={closeModal}
          />
        );
      default:
        return <></>;
    }
  }

  return <>{stepSwitch(createCredsWizardStep)}</>;
}
