import { Box, Flex, Heading, Switch, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isFulfilled } from "../../../../../common/helper/commonHelper";
import { SalesforceConnection } from "../../../../../common/types/salesforceLegacyService";
import { useAppDispatch } from "../../../../../store";
import urls from "../../../../../urls";
import MemoizedCommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";
import {
  updateSalesforceObjects,
  updateSalesforceSettings,
} from "../salesforceLegacySlice";
import { ApiAllocationForm } from "./AllocationForm";

function ObjectRow({
  title,
  permission,
  changePermission,
}: {
  title: string;
  permission: boolean;
  changePermission: (val: boolean) => void;
}) {
  return (
    <Flex justifyContent="space-between" width="100%">
      <Text color="gray.500" fontSize="sm">
        {title}
      </Text>

      <Switch
        size="sm"
        mr="2"
        isChecked={permission}
        onChange={(e) => changePermission(e.target.checked)}
      />
    </Flex>
  );
}

export default function SalesforceLegacySettingsModal({
  connectionDetails,
  isOpen,
  loading,
  onClose,
  goPreviousBox,
}: {
  connectionDetails: SalesforceConnection;
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  goPreviousBox: () => void;
}) {
  const dispatch = useAppDispatch();

  const [allocated, setAllocated] = useState<number | undefined>(
    connectionDetails?.sync_settings?.max_allocated || undefined
  );

  const [objectSettings, setObjectSettings] = useState({
    lead: true,
    contact: true,
    opportunity: false,
    account: true,
  });

  useEffect(() => {
    if (connectionDetails.object_settings) {
      setObjectSettings(connectionDetails.object_settings);
    }
  }, [connectionDetails]);

  async function save() {
    const result1 = await dispatch(
      updateSalesforceObjects({ data: objectSettings })
    );
    const result2 = await dispatch(
      updateSalesforceSettings({
        max_allocated: allocated,
      })
    );
    if (
      isFulfilled(result1.meta.requestStatus) &&
      isFulfilled(result2.meta.requestStatus)
    ) {
      onClose();
    }
  }

  const navigate = useNavigate();

  const redirectUnifiedMapping = () => {
    navigate(urls.unifiedMapping);
  };

  return (
    <MemoizedCommonDrawer
      title="Salesforce"
      onClose={redirectUnifiedMapping}
      isOpen={isOpen}
      goBack={goPreviousBox}
      size="md"
      placement="right"
    >
      <VStack alignItems="flex-start" spacing="0.5">
        <Heading fontSize="md">
          Ready to connect Salesforce and Inflection?
        </Heading>
        <Text color="gray.500" fontSize="sm">
          Choose the options to sync
        </Text>
      </VStack>

      <VStack my="3" alignItems="flex-start">
        <ObjectRow
          title="Lead"
          permission={objectSettings.lead}
          changePermission={(val: boolean) => {
            setObjectSettings({ ...objectSettings, lead: val });
          }}
        />
        <ObjectRow
          title="Contacts"
          permission={objectSettings.contact}
          changePermission={(val: boolean) =>
            setObjectSettings({ ...objectSettings, contact: val })
          }
        />
        <ObjectRow
          title="Accounts"
          permission={objectSettings.account}
          changePermission={(val: boolean) =>
            setObjectSettings({ ...objectSettings, account: val })
          }
        />
      </VStack>

      <Box
        mt="4"
        py="4"
        px="2"
        alignItems="flex-start"
        letterSpacing="0.5"
        bg="grayV2.100"
        borderRadius="md"
      >
        <Heading color="gray.800" fontSize="sm" pl="4">
          Set API allocation
        </Heading>
        <Box>
          <ApiAllocationForm
            width="100%"
            justifyContent="space-between"
            color="black"
            allocated={allocated}
            setAllocated={setAllocated}
            connectionDetails={connectionDetails}
          />
        </Box>
      </Box>
      <ConnectionButtons
        name="continue-button"
        isLoading={loading}
        onClick={save}
        closeModal={redirectUnifiedMapping}
      />
    </MemoizedCommonDrawer>
  );
}
