import { Flex } from "@chakra-ui/react";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { PauseDisconnectHeader } from "../salesforceSyncRun/SyncStatusEditButtons";
import { SalesforceConnectionV2 } from "../../../../../../common/types/salesforce";
import SyncRunHistory from "../salesforceSyncRun/SyncRunHistory";
import SyncRunsSummary from "../salesforceSyncRun/SyncRunsSummary";

export default function SfSyncSummaryAndHistory({
  connection,
  showErrorsDuringDuration,
  isLoading,
}: {
  connection: SalesforceConnectionV2 | null;
  showErrorsDuringDuration: (data: Date) => void;
  isLoading?: boolean;
}) {
  return (
    <ContentContainerWithHeader>
      <PauseDisconnectHeader />
      <Flex flexDir="column" gap={3}>
        <SyncRunsSummary connection={connection} isLoading={isLoading} />
        <SyncRunHistory
          connection={connection}
          showErrorsDuringDuration={showErrorsDuringDuration}
        />
      </Flex>
    </ContentContainerWithHeader>
  );
}
