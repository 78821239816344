import { Box, BoxProps, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function Banner({
  children,
  ...props
}: { children: ReactNode } & BoxProps) {
  return (
    <Box
      as="section"
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      {...props}
      zIndex="2"
    >
      <Box>
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="center"
          spacing={{ base: "0.5", md: "1.5" }}
          pe={{ base: "4", sm: "0" }}
        >
          {children}
        </Stack>
      </Box>
    </Box>
  );
}
