import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  forwardRef,
  IconButton,
  Text,
  BoxProps,
  Box,
} from "@chakra-ui/react";
import { memo, useMemo, useState } from "react";
import { FaArrowRight, FaCalendarAlt, FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { compareDesc, format, isValid } from "date-fns";

export function DateTimeRangePicker({
  start,
  end,
  onChange,
  isInvalid,
  maxEndDate,
  minStartDate,
}: {
  start: Date;
  end: Date;
  onChange: (startDate: Date, endDate: Date) => void;
  isInvalid: boolean;
  maxEndDate?: Date;
  minStartDate?: Date;
}) {
  //Have tested and confirmed that it doesnt cause any problems in other areas

  function handleStartDateChange(date: Date) {
    onChange(date, end);
  }

  function handleEndDateChange(date: Date) {
    onChange(start, date);
  }

  const InputField = forwardRef(({ value, onClick }, ref) => (
    <InputGroup size="sm" onClick={onClick}>
      <InputRightElement
        pointerEvents="none"
        children={<Icon as={FaCalendarAlt} color="gray.400" />}
      />
      <Input ref={ref} isInvalid={isInvalid} defaultValue={value} size="sm" />
    </InputGroup>
  ));

  return (
    <Flex width="420px" alignItems="center">
      <DatePicker
        name="start-date-picker"
        showTimeSelect
        selected={start}
        onChange={handleStartDateChange}
        selectsStart
        startDate={start}
        endDate={end}
        minDate={minStartDate}
        customInput={<InputField />}
        dateFormat="MMM dd, yyyy h:mm aa"
      />
      <Icon as={FaArrowRight} mx="2" color="black" />
      <DatePicker
        name="end-date-picker"
        showTimeSelect
        selected={end}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={start}
        endDate={end}
        minDate={start}
        maxDate={maxEndDate}
        customInput={<InputField />}
        dateFormat="MMM dd, yyyy h:mm aa"
      />
    </Flex>
  );
}

export default function DateTimeRangeFilter({
  startDate,
  endDate,
  onDateChange,
  dateFilterHandler,
  maxEndDate,
  minStartDate,
}: {
  startDate: Date;
  endDate: Date;
  onDateChange: (start: Date, end: Date) => void;
  dateFilterHandler: () => void;
  maxEndDate?: Date;
  minStartDate?: Date;
}) {
  const [isInvalid, setIsInvalid] = useState(false);

  function validateAndChange(startDate: Date, endDate: Date) {
    if (
      !isValid(startDate) ||
      !isValid(endDate) ||
      compareDesc(startDate, endDate) === -1
    ) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
    onDateChange(startDate, endDate);
  }

  return (
    <Flex>
      <DateTimeRangePicker
        isInvalid={isInvalid}
        start={startDate}
        end={endDate}
        onChange={validateAndChange}
        maxEndDate={maxEndDate}
      />
      <IconButton
        isDisabled={isInvalid}
        onClick={dateFilterHandler}
        aria-label="Search"
        variant="outline"
        size="sm"
        ml="2"
        bg="white"
        icon={<FaSearch />}
        borderRadius="4px"
      />
    </Flex>
  );
}

export const getOrdinalSuffix = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function formatDateString({
  date,
  showTime,
  tzIndependent,
}: {
  date: string | number | null | undefined;
  showTime?: boolean;
  tzIndependent?: boolean;
}) {
  const dateObject = date ? new Date(date) : null;
  if (dateObject) {
    // NOTE: if tzIndependent is true, then only date will be returned, have to
    // further use the formatTime function from commonHelper to acheive the same
    if (tzIndependent) {
      // new Date() adds browser timezone to date string, remove that
      dateObject.setMinutes(
        dateObject.getMinutes() + dateObject.getTimezoneOffset()
      );
      const dateString = format(dateObject, "MMM dd, yyyy ");
      return dateString;
    }
    const dateString = dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: showTime ? "2-digit" : undefined,
      minute: showTime ? "2-digit" : undefined,
    });
    return dateString;
  } else {
    return "-";
  }
}

export const FormatDate = memo(
  ({
    date,
    showTime,
    splitLines,
    tzIndependent = false,
    ...props
  }: {
    date: string | number | null | undefined;
    showTime?: boolean;
    splitLines?: boolean;
    tzIndependent?: boolean;
  } & BoxProps) => {
    const dateString = useMemo(
      () =>
        formatDateString({
          date,
          showTime: showTime || splitLines,
          tzIndependent,
        }),
      [date, showTime, splitLines, tzIndependent]
    );
    if (splitLines) {
      const dateArray = dateString.split(" at ");
      return (
        <Box {...props}>
          <Text>{dateArray[0]}</Text>
          <Text>{dateArray[1]}</Text>
        </Box>
      );
    } else {
      return <Text fontSize="14px">{dateString}</Text>;
    }
  }
);
