import {
  Flex,
  Icon,
  VStack,
  Text,
  useBoolean,
  Box,
  HStack,
  Checkbox,
  Badge,
} from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { MdOutlineFileDownload } from "react-icons/md";
import {
  CAMPAIGN_CONTEXT,
  CanvasDateFilter,
  JourneyMemberReportResp,
  JOURNEY_MEMBER_STATUS,
} from "../../../../../common/types/campaign";
import { CommonListHeader } from "../../../../../components/CommonListHeader";
import { DataTable } from "../../../../../components/data-table/DataTable";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import {
  CampaignBuilderContext,
  closeJourneyMemberReport,
  getJourneyMemberReport,
  selectFlow,
} from "../flowSlice";
import { formatISO } from "date-fns";
import CustomDateRangePicker from "../../../../../components/CustomDateRangePicker";
import FlowStepReport from "./FlowStepReport";
import { FaExternalLinkAlt, FaUser } from "react-icons/fa";
import { FiRadio } from "react-icons/fi";
import { FaArrowRightToBracket } from "react-icons/fa6";
import {
  isLoading,
  redirectToContact,
} from "../../../../../common/helper/commonHelper";
import { FormatDate } from "../../../../../components/DateTimeRangeFilter";
import {
  exportJourneyMemberReport,
  selectExport,
} from "../../../export/exportSlice";
import { useDebouncedSearch } from "../../../../../common/hooks/commonHooks";

const PAGE_SIZE = 20;
const FIRST_PAGE = 1;
const SHOW_UNIQUE_ENTRIES_DEFAULT = false;
const COLUMNS_TO_SEARCH_IN = [
  "email",
  "status",
  "person_id",
  "product_user_id",
];
const STATUS_COLORS: Record<JOURNEY_MEMBER_STATUS, string> = {
  [JOURNEY_MEMBER_STATUS.PROGRESS]: "yellow",
  [JOURNEY_MEMBER_STATUS.COMPLETED]: "green",
};

export default function JourneyMemberReport() {
  const { campaignContext } = useContext(CampaignBuilderContext);
  const {
    reports: {
      filter,
      campaignId,
      modals: {
        journeyMemberReport: { isOpen },
      },
      data: {
        journeyMembers: {
          data: journeyMembers,
          loading: loadingJourneyMembers,
          pagination: journeyMembersPagination,
        },
      },
    },
  } = useSelector(selectFlow);

  const { assetsToExport } = useSelector(selectExport);

  const [searchQuery, setSearchQuery] = useState("");
  const [showUniqueEntries, setShowUniqueEntries] = useBoolean(
    SHOW_UNIQUE_ENTRIES_DEFAULT
  );

  const [dateFilterLocal, setDateFilterLocal] =
    useState<CanvasDateFilter>(filter);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen && campaignId) {
      dispatch(
        getJourneyMemberReport({
          campaignId,
          startDate: dateFilterLocal.startDate,
          endDate: dateFilterLocal.endDate,
          query: "",
          showUniqueEntries: SHOW_UNIQUE_ENTRIES_DEFAULT,
          pageSize: PAGE_SIZE,
          pageNumber: FIRST_PAGE,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }, [
    dispatch,
    campaignId,
    dateFilterLocal.endDate,
    dateFilterLocal.startDate,
    isOpen,
  ]);

  useEffect(() => {
    if (isOpen && campaignId) {
      dispatch(
        getJourneyMemberReport({
          campaignId,
          startDate: dateFilterLocal.startDate,
          endDate: dateFilterLocal.endDate,
          query: searchQuery,
          showUniqueEntries: showUniqueEntries,
          pageSize: PAGE_SIZE,
          pageNumber: FIRST_PAGE,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showUniqueEntries]);

  function onPageChange(pageNo: number) {
    if (isOpen && campaignId) {
      dispatch(
        getJourneyMemberReport({
          campaignId,
          startDate: dateFilterLocal.startDate,
          endDate: dateFilterLocal.endDate,
          query: searchQuery,
          showUniqueEntries: showUniqueEntries,
          pageSize: PAGE_SIZE,
          pageNumber: pageNo,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }

  function onClose() {
    dispatch(closeJourneyMemberReport());
  }

  function exportReport() {
    if (campaignId) {
      dispatch(
        exportJourneyMemberReport({
          campaignId,
          startDate: dateFilterLocal.startDate,
          endDate: dateFilterLocal.endDate,
          query: searchQuery,
          showUniqueEntries: showUniqueEntries,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }

  const searchIfValid = useCallback(
    (query: string) => {
      if (campaignId) {
        dispatch(
          getJourneyMemberReport({
            campaignId,
            startDate: dateFilterLocal.startDate,
            endDate: dateFilterLocal.endDate,
            query: query,
            showUniqueEntries: showUniqueEntries,
            pageSize: PAGE_SIZE,
            pageNumber: FIRST_PAGE,
            columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          })
        );
      }
    },
    [
      campaignId,
      dispatch,
      dateFilterLocal.endDate,
      dateFilterLocal.startDate,
      showUniqueEntries,
    ]
  );

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  const columnHelper = createColumnHelper<JourneyMemberReportResp["list"][0]>();

  const columns = useMemo(() => {
    let columns: ColumnDef<JourneyMemberReportResp["list"][0], any>[] = [];

    if (campaignContext === CAMPAIGN_CONTEXT.ORG) {
      columns.push(
        columnHelper.accessor("product_user_id", {
          header: "User",
          size: 400,
        })
      );
    }

    columns.push(
      columnHelper.accessor("email", {
        header: "Email",
        size: 400,
        cell: (info) => (
          <HStack>
            <Text>{info.getValue()}</Text>
            <Box hidden={!info.getValue()}>
              <Icon
                as={FaExternalLinkAlt}
                fontSize="12"
                onClick={() =>
                  redirectToContact(info.cell.row.original.person_id)
                }
                cursor="pointer"
              />
            </Box>
          </HStack>
        ),
      }),
      columnHelper.accessor("entry_date", {
        header: "Entry Date",
        size: 200,
        cell: (info) => <FormatDate date={info.getValue() as string} />,
      }),
      columnHelper.accessor("exit_date", {
        header: "Exit Date",
        size: 200,
        cell: (info) => <FormatDate date={info.getValue() as string} />,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        size: 200,
        cell: (info) => {
          return (
            <Badge
              fontSize="xs"
              colorScheme={
                STATUS_COLORS[info.getValue() as JOURNEY_MEMBER_STATUS]
              }
              variant="solid"
            >
              {info.getValue()}
            </Badge>
          );
        },
      })
    );

    if (showUniqueEntries) {
      columns.splice(
        2,
        0,
        columnHelper.accessor("count", {
          header: "Unique Entries",
          size: 250,
        })
      );
    }

    return columns;
  }, [campaignContext, columnHelper, showUniqueEntries]);

  return (
    <FlowStepReport
      isOpen={isOpen}
      onClose={onClose}
      title="Journey Members"
      icon={FaUser}
      color="brandBlue.500"
      size="4xl"
    >
      <VStack w="100%" spacing="0">
        <Box w="100%" h="92px" bg="gray.100" justifyContent="space-between">
          Empty Spacer
        </Box>

        <Flex
          w="100%"
          py="4"
          px="6"
          h="100px"
          bg="gray.100"
          top="0px"
          position="absolute"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <VStack justifyContent="center" height="32px">
            <HStack fontSize="sm" spacing="3">
              <HStack spacing="1">
                <Icon as={FaArrowRightToBracket} />
                <Text>Total Enrolled: </Text>
                <Text>{journeyMembers?.summary.total_qualified ?? "-"}</Text>
              </HStack>
              <HStack spacing="1">
                <Icon as={FiRadio} />
                <Text>Currently in Workflow:</Text>
                <Text>
                  {journeyMembers?.summary.currently_in_workflow ?? "-"}
                </Text>
              </HStack>
            </HStack>
          </VStack>
          <HStack>
            <Checkbox
              size="lg"
              colorScheme="twitter"
              isChecked={showUniqueEntries}
              onChange={setShowUniqueEntries.toggle}
            >
              <Text fontSize="sm">Show Unique Entries</Text>
            </Checkbox>
            <CustomDateRangePicker
              startDate={new Date(dateFilterLocal.startDate)}
              endDate={new Date(dateFilterLocal.endDate)}
              onDateRangeChange={(startDate, endDate) =>
                setDateFilterLocal({
                  startDate: formatISO(startDate),
                  endDate: formatISO(endDate),
                })
              }
              inputProps={{ border: 0 }}
            />
          </HStack>
        </Flex>

        <Flex w="100%" flexDir="column" pt="2">
          <CommonListHeader
            isLoading={isLoading(loadingJourneyMembers)}
            heading={`Showing ${journeyMembers?.list?.length ?? 0} of ${
              journeyMembersPagination.totalRecords ?? 0
            } results`}
            titleProps={{
              fontSize: "12px",
              fontWeight: 400,
              color: "grayV2.600",
            }}
            searchInputProps={{
              placeholder: "Search results  ",
              name: "search-input",
              onSearch: (query) => {
                setSearchQuery(query);
                debouncedSearch(query);
              },
              value: searchQuery,
            }}
            headerStackProps={{ px: "0px" }}
          >
            <IButton
              variant={BUTTON.SECONDARY}
              leftIcon={<MdOutlineFileDownload fontSize="14px" />}
              isLoading={
                campaignId
                  ? isLoading(assetsToExport[campaignId]?.isLoading)
                  : false
              }
              isDisabled={!journeyMembersPagination.totalPages}
              onClick={exportReport}
            >
              Export
            </IButton>
          </CommonListHeader>
          <DataTable
            fetchingList={isLoading(loadingJourneyMembers)}
            changingPage={isLoading(loadingJourneyMembers)}
            list={journeyMembers?.list || null}
            totalPageCount={journeyMembersPagination.totalPages}
            currentPage={journeyMembersPagination.currentPage}
            totalPageSize={journeyMembersPagination.pageSize}
            setPage={onPageChange}
            columns={columns}
            emptyMsg="No results found"
          />
        </Flex>
      </VStack>
    </FlowStepReport>
  );
}
