import {
  HStack,
  Flex,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Box,
  Divider,
  ButtonGroup,
} from "@chakra-ui/react";
import { FaSearch, FaPlus, FaMagic, FaPencilAlt } from "react-icons/fa";
import IButton, { BUTTON } from "../IButton";

export function SearchAndActionsBar({
  setQuery,
  addAction,
  openAutomapModal,
  save,
  cancel,
  saving,
  automapping,
  readonlyMode,
  toggleReadonly,
}: {
  setQuery: (e: string) => void;
  addAction?: () => void;
  openAutomapModal?: () => void;
  save: () => void;
  cancel: () => void;
  saving: boolean;
  automapping: boolean;
  readonlyMode: boolean;
  toggleReadonly: () => void;
}) {
  return (
    <HStack alignItems="center" justifyContent="space-between" width="100%">
      <Flex flex="1">
        <InputGroup size="sm">
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FaSearch} color="gray.400" />}
          />
          <Input
            onChange={(e) => setQuery(e.target.value)}
            border="none"
            placeholder="Search columns"
            name="search-columns-input"
          />
        </InputGroup>
      </Flex>

      <Box>
        <Divider orientation="vertical" height="30px" />
      </Box>

      {openAutomapModal && (
        <IButton
          variant={BUTTON.SECONDARY}
          isLoading={automapping}
          leftIcon={<FaMagic />}
          size="sm"
          onClick={openAutomapModal}
          isDisabled={saving}
        >
          Auto-map
        </IButton>
      )}

      <Box>
        <Divider orientation="vertical" height="30px" />
      </Box>

      {readonlyMode ? (
        <>
          <IButton
            variant={BUTTON.SECONDARY}
            leftIcon={<FaPencilAlt />}
            size="sm"
            onClick={toggleReadonly}
            isLoading={saving}
            isDisabled={automapping}
          >
            Edit
          </IButton>
        </>
      ) : (
        <>
          <IButton
            variant="ghost"
            leftIcon={<FaPlus />}
            size="sm"
            onClick={addAction}
            isDisabled={saving}
          >
            Add new field
          </IButton>

          <Box>
            <Divider orientation="vertical" height="30px" />
          </Box>
          <ButtonGroup>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={cancel}
              isDisabled={saving}
            >
              Cancel
            </IButton>
            <IButton variant={BUTTON.PRIMARY} onClick={save} isLoading={saving}>
              Save
            </IButton>
          </ButtonGroup>
        </>
      )}
    </HStack>
  );
}
