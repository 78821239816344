import { Box, Flex, Badge, VStack, Text, HStack, Wrap } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { ReactNode, useContext, useMemo } from "react";
import {
  ComparisonNode,
  ListMembershipNode,
  NodeCriteriaTypes,
  NodeGroup,
  OperatorListV2,
} from "../../../../../common/types/dynamicList";
import { createGroupId } from "../../../../../common/helper/commonHelper";
import {
  OperatorType,
  CAMPAIGN_CONTEXT,
} from "../../../../../common/types/campaign";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import {
  EVENT_CATEGORY,
  GROUP_OPERATORS,
  LOGICAL_OPERATORS,
  TRIGGER_DL_NODE_TYPES,
} from "../../../../../common/constants/dynamicList";
import { getLogicalOperator } from "../helper";
import LogicGate from "../components/LogicGate";
import { AudienceCriteriaContext } from "../AudienceCriteria";
import AddNodeGroupButton from "../components/AddNodeGroupButton";
import PersonFilterNodeCriteria from "./PersonFilterNodeCriteria";
import ListMembershipNodeCriteria from "./ListMembershipNodeCriteria";
import MarketingActivityNodeCriteria from "./MarketingActivityNodeCriteria";
import ProductActivityNodeCriteria from "./ProductActivityNodesCriteria";
import {
  fillFirstNodeData,
  isGroupNode,
} from "../../../../../common/helper/trigger";

function ContainerWithLogicGate({
  index,
  logicGate,
  children,
}: {
  index: number;
  logicGate: ReactNode;
  children: ReactNode;
}) {
  return (
    <Box key={index} w="100%">
      {logicGate}
      {children}
    </Box>
  );
}

function filterOperators(
  operators: OperatorListV2 | null,
  operatorType: OperatorType
) {
  return operators
    ? Object.values(operators[operatorType] ?? {}).map((op) => ({
        label: op.display,
        value: op.id,
      }))
    : [];
}

function WrapIfReadOnly({
  children,
  isReadOnly,
}: {
  children: ReactNode;
  isReadOnly: boolean;
}) {
  if (isReadOnly) {
    return <Wrap>{children}</Wrap>;
  } else {
    return <HStack>{children}</HStack>;
  }
}

function AggregateSupport({
  operators,
  groupData,
  onChangeGroupOperator,
  isReadOnly,
  activeErrorCheck,
  campaignContext,
}: {
  operators: OperatorListV2;
  groupData: NodeGroup;

  onChangeGroupOperator: (operator: GROUP_OPERATORS | null) => void;
  isReadOnly?: boolean;
  activeErrorCheck: boolean;
  campaignContext: CAMPAIGN_CONTEXT;
}) {
  const options = useMemo(() => {
    switch (campaignContext) {
      case CAMPAIGN_CONTEXT.ORG:
        return filterOperators(operators, OperatorType.ORG_AGGREGATE_SPECIFIC);
      case CAMPAIGN_CONTEXT.PERSON:
        return filterOperators(
          operators,
          OperatorType.ACCOUNT_AGGREGATE_SPECIFIC
        );
      default:
        return [];
    }
  }, [operators, campaignContext]);

  const aggregateItem = useMemo(() => {
    switch (campaignContext) {
      case CAMPAIGN_CONTEXT.ORG:
        return "org";
      case CAMPAIGN_CONTEXT.PERSON:
        return "account";
      default:
        return [];
    }
  }, [campaignContext]);

  return (
    <Box fontSize="sm" mb="3">
      <WrapIfReadOnly isReadOnly={!!isReadOnly}>
        <>
          <Text>...associated with an {aggregateItem}</Text>
          <ValueSelectFields
            options={options}
            value={groupData.groupOperator || ""}
            onChange={(op) => onChangeGroupOperator(op as GROUP_OPERATORS)}
            validationError={
              activeErrorCheck && !groupData.groupOperator
                ? "Invalid operator"
                : ""
            }
            isReadOnly={isReadOnly}
          />
          <Text>at least one member</Text>
        </>
      </WrapIfReadOnly>
    </Box>
  );
}

const NodeGroupCriteria = React.memo(
  ({
    label,
    id,
    nodeGroupData,
    onChange,
    onRemove,
    isReadOnly,
    campaignContext,
  }: {
    label: string;
    id: string;
    nodeGroupData: NodeGroup;
    onRemove: () => void;
    onChange: (data: NodeGroup) => void;
    isReadOnly?: boolean;
    campaignContext?: CAMPAIGN_CONTEXT;
  }) => {
    const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

    function onChangeGroupOperator(operator: GROUP_OPERATORS | null) {
      const dataCopy = cloneDeep(nodeGroupData);
      dataCopy.groupOperator = operator;
      onChange(dataCopy);
    }

    function onAddNewNode(
      nodeType: TRIGGER_DL_NODE_TYPES,
      eventCategory?: EVENT_CATEGORY
    ) {
      const dataCopy = cloneDeep(nodeGroupData);

      dataCopy.nodes.push({
        ...fillFirstNodeData(nodeType, eventCategory),
        logicalOperator: getLogicalOperator(dataCopy.nodes.length),
      });
      onChange(dataCopy);
    }

    function onRemoveNode(index: number) {
      const dataCopy = cloneDeep(nodeGroupData);
      if (dataCopy.nodes.length <= 1) {
        onRemove();
      } else {
        dataCopy.nodes = dataCopy.nodes.filter((_, i) => i !== index);
        if (dataCopy.nodes.length) {
          dataCopy.nodes[0].logicalOperator = LOGICAL_OPERATORS.FIRST;
        }
        onChange(dataCopy);
      }
    }

    function onUpdateNode(index: number, newData: NodeCriteriaTypes) {
      const dataCopy = cloneDeep(nodeGroupData);
      dataCopy.nodes[index] = newData;
      onChange(dataCopy);
    }

    function setNodeLogicalOperator(op: LOGICAL_OPERATORS, index: number) {
      const dataCopy = cloneDeep(nodeGroupData);
      dataCopy.nodes[index].logicalOperator = op;
      onChange(dataCopy);
    }

    function renderNode(nodeData: NodeCriteriaTypes, index: number) {
      const SHARED_NODE_DATA = {
        label: `Filter group ${index + 1}`,
        id: createGroupId(id, "filter", index + 1),
        isReadOnly,
        onChange: (newNodeData: NodeCriteriaTypes) =>
          onUpdateNode(index, newNodeData),
        onRemove: () => onRemoveNode(index),
      };

      switch (nodeData.nodeType) {
        case TRIGGER_DL_NODE_TYPES.COMPARISON:
          if (
            "eventCategory" in nodeData &&
            nodeData.eventCategory === EVENT_CATEGORY.MARKETING_ACTIVITY
          ) {
            return (
              <MarketingActivityNodeCriteria
                nodeData={nodeData as ComparisonNode}
                {...SHARED_NODE_DATA}
              />
            );
          }
          return (
            <ProductActivityNodeCriteria
              nodeData={nodeData as ComparisonNode}
              {...SHARED_NODE_DATA}
            />
          );
        case TRIGGER_DL_NODE_TYPES.PERSON:
          return (
            <PersonFilterNodeCriteria
              nodeData={nodeData as ComparisonNode}
              {...SHARED_NODE_DATA}
            />
          );
        case TRIGGER_DL_NODE_TYPES.LIST_MEMBERSHIP:
          return (
            <ListMembershipNodeCriteria
              nodeData={nodeData as ListMembershipNode}
              {...SHARED_NODE_DATA}
            />
          );
        default:
          throw new Error("Invalid Group Type");
      }
    }

    const isAggregateSupported =
      campaignContext && isGroupNode(nodeGroupData.nodeGroupType);

    if (isReadOnly) {
      return (
        <Box
          p="3"
          bg="grayV2.100"
          borderWidth="1px"
          borderColor="grayV2.100"
          width="100%"
          alignItems="flex-start"
          mt={1}
          id={id}
          rounded="md"
        >
          {isAggregateSupported && (
            <AggregateSupport
              groupData={nodeGroupData}
              onChangeGroupOperator={onChangeGroupOperator}
              isReadOnly={isReadOnly}
              operators={operators}
              activeErrorCheck={activeErrorCheck}
              campaignContext={campaignContext}
            />
          )}
          <VStack alignItems="flex-start">
            {nodeGroupData.nodes.map((nodeData, index) => {
              const logicGate = (
                <LogicGate
                  operator={nodeData.logicalOperator}
                  handleChange={(connector) =>
                    setNodeLogicalOperator(connector, index)
                  }
                  isReadOnly={isReadOnly}
                />
              );

              return (
                <ContainerWithLogicGate index={index} logicGate={logicGate}>
                  {renderNode(nodeData, index)}
                </ContainerWithLogicGate>
              );
            })}
          </VStack>
        </Box>
      );
    } else {
      return (
        <Box
          p="3"
          rounded="lg"
          bg="grayV2.100"
          borderWidth="1px"
          borderColor="grayV2.100"
          width="100%"
          alignItems="flex-start"
          id={id}
        >
          <Flex
            mb="3"
            roundedTop="lg"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            bg="grayV2.100"
          >
            <Badge fontSize="10px" colorScheme="blue" variant="solid">
              {label}
            </Badge>

            <RemoveRowCloseButton onClick={onRemove} />
          </Flex>
          {isAggregateSupported && (
            <AggregateSupport
              groupData={nodeGroupData}
              onChangeGroupOperator={onChangeGroupOperator}
              operators={operators}
              activeErrorCheck={activeErrorCheck}
              campaignContext={campaignContext!}
            />
          )}
          <VStack alignItems="flex-start">
            {nodeGroupData.nodes.map((nodeData, index) => {
              const logicGate = (
                <LogicGate
                  operator={nodeData.logicalOperator}
                  handleChange={(op) => setNodeLogicalOperator(op, index)}
                />
              );
              return (
                <ContainerWithLogicGate index={index} logicGate={logicGate}>
                  {renderNode(nodeData, index)}
                </ContainerWithLogicGate>
              );
            })}
          </VStack>
          <Box pt="5">
            <AddNodeGroupButton onAddNode={onAddNewNode} />
          </Box>
        </Box>
      );
    }
  }
);

export default NodeGroupCriteria;
