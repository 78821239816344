import { Box, useDisclosure } from "@chakra-ui/react";
import {
  CAMPAIGN_CONTEXT,
  DynamicListType,
} from "../../../../../common/types/campaign";
import CampaignExitCriteria from "../../exit-criteria/CampaignExitCriteria";
import CommonDrawer from "../../components/CommonDrawer";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";
import { FaUserMinus } from "react-icons/fa";
import SectionWrapper, {
  SectionItem,
  SectionLink,
} from "./components/SectionWrapper";
import { getPersonLabel } from "../../../../../common/helper/commonHelper";

export default function CampaignExitSection({
  data,
  errorText,
  readonly,
  onChange,
  campaignContext,
  isLoading,
  activeErrorCheck,
  validateOnClose,
}: {
  data: DynamicListType | null;
  errorText: string;
  readonly: boolean;
  onChange: (data: DynamicListType | null) => void;
  campaignContext: CAMPAIGN_CONTEXT;
  isLoading: boolean;
  activeErrorCheck: boolean;
  validateOnClose: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onHandleClose = () => {
    if (activeErrorCheck) {
      validateOnClose();
    }
    onClose();
  };

  const isEmptyWhileReadOnly = !data?.children?.length && readonly;
  const infoText = isEmptyWhileReadOnly
    ? `Remove a ${getPersonLabel(
        campaignContext
      )} from the workflow when the ${getPersonLabel(
        campaignContext
      )} completes the workflow`
    : `Remove a ${getPersonLabel(
        campaignContext
      )} from the workflow when completes the workflow if any of the following events occur`;

  return (
    <>
      <SectionWrapper
        icon={FaUserMinus}
        title="Exit criteria"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading}
        isEmpty={!data?.children?.length}
      >
        {data?.children?.length || readonly ? (
          <SectionItem
            infoText={infoText}
            errorText={activeErrorCheck ? errorText : ""}
          >
            <CampaignExitCriteria
              showCheckOptions
              data={data}
              onChange={() => {}}
              isReadOnly={true}
            />
          </SectionItem>
        ) : (
          <SectionLink
            onOpen={onOpen}
            title="Click to set up exit criteria"
            LinkIcon={FaUserMinus}
            errorText={errorText}
          />
        )}
      </SectionWrapper>
      <CommonDrawer
        size="xl"
        title="Exit criteria"
        isOpen={isOpen}
        onClose={onHandleClose}
      >
        <Box className={readonly ? DISABLED_EDIT_CLASSNAME : ""} w="100%">
          <CampaignExitCriteria
            data={data}
            onChange={onChange}
            isReadOnly={false}
            activeErrorCheck={activeErrorCheck}
            showCheckOptions
          />
        </Box>
      </CommonDrawer>
    </>
  );
}
