import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";
import { withErrorBoundary } from "@sentry/react";
import ErrorFallback from "./components/ErrorFallback";
import { RollBar } from "./components/RollBar";
import App from "./App";
import { store } from "./store";
import "focus-visible/dist/focus-visible";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import theme from "./theme";

const AppWithBoundary = withErrorBoundary(App, { fallback: ErrorFallback });

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={theme}>
      <Provider store={store}>
        <RollBar>
          <ToastContainer
            autoClose={3000}
            theme="colored"
            position="bottom-center"
            limit={2}
          />
          <AppWithBoundary />
        </RollBar>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
