import { ComponentStyleConfig } from "@chakra-ui/theme";

const textareaStyle: ComponentStyleConfig = {
  baseStyle: {
    border: "1px solid grayV2.200",
  },
  variants: {
    outline: {
      background: "white",
      borderRadius: "md",
    },
  },
  defaultProps: {
    variant: "outline",
  },
};

export default textareaStyle;
