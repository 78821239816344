import { HStack, Icon, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { getEventTypeAndName } from "../../../../../../../../../common/helper/commonHelper";
import { SegmentPropertyDetail } from "../../../../../../../../../common/types/connection";
import { useAppDispatch } from "../../../../../../../../../store";
import { updateSegmentDescription } from "../../../../../../connectionSlice";
import DescriptionEdit from "../../DescriptionEdit";
import SubPropertyItem from "./SubPropertyItem/SubPropertyItem";

export default function SegmentPropertyItem({
  propertyName,
  propertyDetails,
  eventName,
  isByPassAll,
  isEventEnabled,
}: {
  propertyName: string;
  propertyDetails: SegmentPropertyDetail;
  eventName: string;
  isByPassAll: boolean;
  isEventEnabled: boolean;
}) {
  const dispatch = useAppDispatch();
  const [description, setDescription] = useState(
    propertyDetails?.description ?? ""
  );

  function handleDescriptionChange(value: string) {
    const descriptionData = {
      ...getEventTypeAndName(eventName),
      description: value,
      event_property: propertyName,
    };
    setDescription(value);
    dispatch(updateSegmentDescription(descriptionData));
  }

  return (
    <>
      <HStack width="100%" minH="35px">
        <Icon
          as={FaCircle}
          color={isByPassAll || isEventEnabled ? "green" : "red.400"}
        />
        <HStack width="100%" alignItems="baseline">
          <Text fontSize="14px">{propertyName}</Text>
          <DescriptionEdit
            value={description}
            onSave={handleDescriptionChange}
          />
        </HStack>
      </HStack>
      {Object.keys(propertyDetails.sub_properties).length ? (
        <SubPropertyItem subProperties={propertyDetails.sub_properties} />
      ) : null}
    </>
  );
}
