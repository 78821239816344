import {
  QualificationFrequency,
  TimeFrame,
} from "../../../../../common/types/dynamicList";
import { TIME_FRAME_TYPE } from "../../../../../common/constants/trigger";
import RadioItemLayoutBox from "../../../../../components/RadioItemLayoutBox";
import { VStack, Text, HStack, Icon, Box, Heading } from "@chakra-ui/react";
import { MdInfo } from "react-icons/md";
import { useEffect, useState, useCallback } from "react";
import SectionWrapper from "./components/SectionWrapper";
import { FaRoute, FaRegClock, FaClock } from "react-icons/fa";
import { useDisclosure } from "@chakra-ui/react";
import CommonDrawer from "../../components/CommonDrawer";
import IButton, { BUTTON } from "../../../../../components/IButton";
import InputFieldWithError from "../../../../../components/InputFieldWithError";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import {
  TRIGGER_CAMPAIGN_QUALIFICATION_RULES,
  TRIGGER_QUALIFICATION_RULES_OPTIONS,
  TIME_FRAME_UNIT,
} from "../../../../../common/constants/trigger";
import { COMPARISON_OPERATORS } from "../../../../../common/constants/dynamicList";
import NumberField from "../../../../../components/NumberField";
import { NUMBER_FIELD_TYPES } from "../../../../../components/NumberField";
import { SectionItem } from "./components/SectionWrapper";
import { SectionLink } from "./components/SectionWrapper";
import { IconType } from "react-icons";
import { toLower } from "lodash";
import {
  addSuffixForPlural,
  getFormattedTenantTimezone,
} from "../../../../../common/helper/commonHelper";
import { DISABLED_EDIT_CLASSNAME } from "../../../../../common/constants/campaign";
import { IoWarning } from "react-icons/io5";
import { PiUserFocusFill } from "react-icons/pi";
import { selectSettings } from "../../../settings/settingsSlice";
import { useSelector } from "react-redux";
import { getOrdinalSuffix } from "../../../../../components/DateTimeRangeFilter";

const CAMPAIGN_RULE_OPTIONS = [
  {
    label: "Qualify only once",
    value: TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONLY_ONCE,
  },
  {
    label: "Qualify once every",
    value: TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL,
  },
  {
    label: "Qualify always",
    value: TRIGGER_CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME,
  },
];

const TRIGGER_JOURNEY_OPTION_DETAILS = [
  {
    key: TIME_FRAME_TYPE.RELATIVE,
    optionHeader: "Start immediately",
    optionDescription: "Start the journey immediately",
  },
  {
    key: TIME_FRAME_TYPE.ABSOLUTE,
    optionHeader: "Schedule to run once",
    optionDescription: "Schedule the journey to run only once",
  },
];

const frequencies = {
  Day: "DAY",
  Week: "WEEK",
  Month: "MONTH",
};

function formFinder(
  type: TIME_FRAME_TYPE,
  schedule: TimeFrame | null,
  timeFrameStartDate: string,
  setTimeFrameStartDate: React.Dispatch<React.SetStateAction<string>>,
  scheduleErrorType: number
) {
  switch (type) {
    case TIME_FRAME_TYPE.ABSOLUTE:
      return (
        <ScheduleOnce
          startDate={timeFrameStartDate || ""}
          endDate={schedule?.end || 0}
          setValue={setTimeFrameStartDate}
          scheduleErrorType={scheduleErrorType}
        />
      );
    case TIME_FRAME_TYPE.RELATIVE:
      return <></>;
  }
}

function ScheduleOptionItem({
  timeFrameStartDate,
  setTimeFrameStartDate,
  schedule,
  timeFrameType,
  optionKey,
  optionHeader,
  optionDescription,
  onClick,
  scheduleErrorType,
}: {
  timeFrameStartDate: string;
  setTimeFrameStartDate: React.Dispatch<React.SetStateAction<string>>;
  schedule: TimeFrame | null;
  timeFrameType: TIME_FRAME_TYPE | null;
  optionKey: TIME_FRAME_TYPE;
  optionHeader: string;
  optionDescription: string;
  onClick: () => void;
  scheduleErrorType: number;
}) {
  const isSelected = optionKey === timeFrameType;
  const {
    tenantDetails: { data: tenantDetails },
  } = useSelector(selectSettings);

  return (
    <RadioItemLayoutBox
      isSelected={isSelected}
      onRadioItemSelect={onClick}
      isError={scheduleErrorType > 0}
    >
      <VStack align="flex-start" width="100%">
        <Text fontSize="14px" color="blackAlpha.800" fontWeight={600}>
          {optionHeader}
        </Text>
        <Text
          fontSize="12px"
          color="blackAlpha.700"
          hidden={isSelected && timeFrameType !== TIME_FRAME_TYPE.RELATIVE}
        >
          {optionDescription}
        </Text>
        {isSelected &&
          formFinder(
            timeFrameType,
            schedule,
            timeFrameStartDate,
            setTimeFrameStartDate,
            scheduleErrorType
          )}
        {isSelected && (
          <HStack
            align="flex-start"
            bg="white"
            p={2}
            mt={3}
            borderRadius="6px"
            border="1px solid"
            borderColor="gray.200"
            width="400px"
          >
            <Icon as={MdInfo} color="blue.700" mt={1} />
            <Text color="brandBlue.500" fontSize="12px" lineHeight="20px">
              {" "}
              Journey is scheduled in Workspace Timezone
              <br />
              <Text as="span" fontWeight="600">
                Workspace timezone:{" "}
              </Text>
              ({getFormattedTenantTimezone(tenantDetails)})
            </Text>
          </HStack>
        )}
      </VStack>
    </RadioItemLayoutBox>
  );
}

function ScheduleOptionList({
  schedule,
  setScheduleData,
  scheduleErrorType,
}: {
  schedule: TimeFrame | null;
  setScheduleData: React.Dispatch<React.SetStateAction<TimeFrame | null>>;
  scheduleErrorType: number;
}) {
  const [timeFrameType, setTimeFrameType] = useState<TIME_FRAME_TYPE | null>(
    schedule?.type ?? null
  );

  const [timeFrameStartDate, setTimeFrameStartDate] = useState<string>(
    schedule?.start === 0 ? "" : convertEpochToDate(schedule?.start || 0)
  );

  useEffect(() => {
    setScheduleData((prevSchedule: TimeFrame | null) => {
      if (!prevSchedule) {
        return prevSchedule;
      }
      const startDateValue: number | null = timeFrameStartDate
        ? convertDateTimeToEpoch(timeFrameStartDate)
        : null;
      return { ...prevSchedule, start: startDateValue ?? prevSchedule.start };
    });
  }, [timeFrameStartDate, setScheduleData]);

  useEffect(() => {
    setScheduleData((prevSchedule: TimeFrame | null) => {
      if (!prevSchedule) {
        return prevSchedule;
      }
      return {
        ...prevSchedule,
        type: timeFrameType || prevSchedule.type,
        start:
          timeFrameType === TIME_FRAME_TYPE.RELATIVE ? 0 : prevSchedule.start,
        operator:
          timeFrameType === TIME_FRAME_TYPE.RELATIVE
            ? COMPARISON_OPERATORS.NOW
            : COMPARISON_OPERATORS.EQUALS,
        unit:
          timeFrameType === TIME_FRAME_TYPE.RELATIVE
            ? TIME_FRAME_UNIT.DAY
            : TIME_FRAME_UNIT.EPOCH,
      };
    });
  }, [timeFrameType, setScheduleData]);

  function onClickHandler(chosenRunType: TIME_FRAME_TYPE) {
    if (timeFrameType === TIME_FRAME_TYPE.RELATIVE) {
      setTimeFrameStartDate("");
    }
    setTimeFrameType(chosenRunType);
  }

  return (
    <VStack spacing="8px">
      {TRIGGER_JOURNEY_OPTION_DETAILS.map((option) => (
        <ScheduleOptionItem
          timeFrameStartDate={timeFrameStartDate}
          setTimeFrameStartDate={setTimeFrameStartDate}
          schedule={schedule}
          timeFrameType={timeFrameType}
          optionKey={option.key}
          optionHeader={option.optionHeader}
          optionDescription={option.optionDescription}
          onClick={() => {
            onClickHandler(option.key);
          }}
          key={option.key}
          scheduleErrorType={scheduleErrorType}
        />
      ))}
      {scheduleErrorType === 1 && (
        <HStack>
          <Icon as={IoWarning} color="red.500"></Icon>
          <Text fontSize="12px" color="red.500">
            Select a schedule type
          </Text>
        </HStack>
      )}
    </VStack>
  );
}

function convertEpochToDate(epoch: number): string {
  if (epoch === 0) return "";
  const date = new Date(epoch);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
}

function convertDateTimeToEpoch(datetime: string): number | null {
  const epoch = Date.parse(datetime);

  if (!isNaN(epoch)) {
    return epoch;
  } else {
    return null; // Return null if the datetime string is invalid
  }
}

export function ScheduleOnce({
  startDate,
  endDate,
  setValue,
  scheduleErrorType,
}: {
  startDate: string;
  endDate: number;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  scheduleErrorType: number;
}) {
  return (
    <VStack align="flex-start" spacing="0px" width="430px">
      <Text fontSize="12px" color="blackAlpha.700" mt={4}>
        You can schedule the journey to run only once
      </Text>
      <InputFieldWithError
        labelText=""
        value={startDate}
        formLabelProps={{ color: "gray.500", fontSize: "xs" }}
        name="timeframe.start_date"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        width="90%"
        type="datetime-local"
        isInvalid={scheduleErrorType === 2}
        min={new Date().toISOString().slice(0, 16)}
        max={endDate ? new Date(endDate).toISOString().slice(0, 16) : undefined}
      />
      {scheduleErrorType === 2 && (
        <HStack>
          <Icon as={IoWarning} color="red.500"></Icon>
          <Text fontSize="12px" color="red.500">
            Enter a schedule start date and time
          </Text>
        </HStack>
      )}
    </VStack>
  );
}

function QualificationRule({
  rule,
  setRuleData,
  ruleErrorType,
}: {
  rule: QualificationFrequency | null;
  setRuleData: React.Dispatch<
    React.SetStateAction<QualificationFrequency | null>
  >;
  ruleErrorType: number;
}) {
  return (
    <Box>
      <Heading as="h3" fontSize="16px" fontWeight={700} mt={26}>
        Journey Qualification
      </Heading>

      <Text fontSize="14px" color="blackAlpha.700" my={4}>
        Journey Qualification determines how many times a person will be allowed
        to enter the journey
      </Text>
      <Text fontSize="12px" color="brandBlue.500" mb={2} mt={6}>
        Qualification rule
      </Text>
      <HStack alignItems="initial">
        <DropdownWithSearch
          options={CAMPAIGN_RULE_OPTIONS}
          value={
            rule?.type
              ? CAMPAIGN_RULE_OPTIONS.find(
                  (option) => option.value === rule.type
                )
              : undefined
          }
          placeholder="rule"
          onChange={(option) => {
            setRuleData((prevRule: QualificationFrequency | null) => {
              if (!prevRule) {
                return prevRule;
              }
              return {
                ...prevRule,
                interval: TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL
                  ? {
                      unit: TIME_FRAME_UNIT.DAY,
                      value: 0,
                    }
                  : null,
                type: option?.value as TRIGGER_CAMPAIGN_QUALIFICATION_RULES,
              };
            });
          }}
          isInvalid={false}
          controlStyle={{ width: "180px" }}
        />
        {rule?.type ===
          TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL && (
          <HStack alignItems="initial">
            <VStack>
              <NumberField
                type={NUMBER_FIELD_TYPES.INTEGER}
                value={rule.interval?.value ?? ""}
                min={1}
                size="sm"
                width="90px"
                fieldProps={{
                  borderRadius: "6px",
                }}
                onValueChange={(value) => {
                  setRuleData((prevRule: QualificationFrequency | null) => {
                    if (!prevRule) {
                      return prevRule;
                    }
                    const unitValue: TIME_FRAME_UNIT =
                      prevRule.interval?.unit || TIME_FRAME_UNIT.DAY;
                    return {
                      ...prevRule,
                      interval: {
                        unit: unitValue.toUpperCase() as TIME_FRAME_UNIT,
                        value: value as number,
                      },
                    };
                  });
                }}
                isInvalid={ruleErrorType > 0}
              />
              {ruleErrorType > 0 && (
                <HStack>
                  <Icon as={IoWarning} color="red.500"></Icon>
                  <Text fontSize="12px" color="red.500">
                    Invalid unit
                  </Text>
                </HStack>
              )}
            </VStack>
            <DropdownWithSearch
              options={Object.entries(frequencies).map(([label, frequency]) => {
                return { label: label, value: frequency };
              })}
              value={
                rule.interval?.unit
                  ? Object.entries(frequencies)
                      .filter(
                        ([_, frequency]) => frequency === rule.interval?.unit
                      )
                      .map(([label, frequency]) => {
                        return { label: label, value: frequency };
                      })
                  : undefined
              }
              onChange={(option) => {
                setRuleData((prevRule: QualificationFrequency | null) => {
                  if (!prevRule) {
                    return prevRule;
                  }
                  const unitValue: string = option?.value
                    ? option.value
                    : prevRule.interval?.unit || TIME_FRAME_UNIT.DAY;
                  const numericValue: number = prevRule.interval?.value || 0;
                  return {
                    ...prevRule,
                    interval: {
                      unit: unitValue.toUpperCase() as TIME_FRAME_UNIT,
                      value: numericValue,
                    },
                  };
                });
              }}
              isSearchable={false}
              isInvalid={false}
              controlStyle={{ width: "110px" }}
            />
          </HStack>
        )}
      </HStack>
    </Box>
  );
}

function IconWithTextAndDesc({
  icon,
  label,
  description,
}: {
  icon: IconType;
  label: string;
  description?: string;
}) {
  return (
    <HStack w="100%" p={1} mt="0px !important">
      <VStack alignItems="flex-start" spacing="0">
        <HStack spacing="6px" alignItems="flex-start">
          <Icon color="blue.700" fontSize="13px" as={icon} mt="3px" />
          <Text color="gray.800" fontSize="13px">
            {label}
          </Text>
        </HStack>
        <Box>
          <Text
            ml="22px"
            fontWeight="bold"
            fontSize="xs"
            color="oneOffs.subtitle"
          >
            {description}
          </Text>
        </Box>
      </VStack>
    </HStack>
  );
}

function buildQualifyRulesText(rule: QualificationFrequency | null) {
  let text = "";
  if (
    rule?.type === TRIGGER_CAMPAIGN_QUALIFICATION_RULES.EVERY_TIME ||
    rule?.type === TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONLY_ONCE
  ) {
    text += `Qualify contacts ${toLower(
      TRIGGER_QUALIFICATION_RULES_OPTIONS[rule.type]
    )}`;
  } else if (
    rule?.type === TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL
  ) {
    if (rule?.interval?.unit && rule?.interval.value)
      text += `Qualify contacts ${toLower(
        TRIGGER_QUALIFICATION_RULES_OPTIONS[rule.type]
      )} ${rule.interval.value} ${addSuffixForPlural(
        rule.interval.unit.toLowerCase(),
        rule.interval.value
      )}`;
  }
  return text;
}

function buildFrequencyRange(schedule: TimeFrame | null) {
  let text = "";
  if (schedule?.type === TIME_FRAME_TYPE.RELATIVE) {
    text += "Run immediately";
  } else {
    text += `Schedule run once on ${convertEpochToFormattedDateTime(
      schedule?.start
    )}`;
  }
  return text;
}

function convertEpochToFormattedDateTime(epoch: number | undefined): string {
  if (epoch === undefined) {
    return "Invalid timestamp";
  }
  const date = new Date(epoch);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return `${formattedDate} at ${formattedTime}`;
}

function TriggerCampaignScheduleReadonly({
  rule,
  schedule,
  onOpen,
  errorText,
}: {
  rule: QualificationFrequency | null;
  schedule: TimeFrame | null;
  onOpen?: any;
  errorText: string;
}) {
  return (
    <>
      {schedule?.type ? (
        <VStack alignItems="flex-start" w="100%">
          <SectionItem
            infoText="Schedule the journey based on the following timings"
            errorText=""
          >
            <IconWithTextAndDesc
              label={buildFrequencyRange(schedule)}
              description=""
              icon={FaClock}
            />
          </SectionItem>
          <SectionItem
            infoText="Define multiple entry qualification"
            errorText=""
          >
            <IconWithTextAndDesc
              label={buildQualifyRulesText(rule)}
              description=""
              icon={PiUserFocusFill}
            />
          </SectionItem>
        </VStack>
      ) : (
        <SectionLink
          onOpen={onOpen}
          title="Set journey schedule and qualification rules"
          LinkIcon={FaRegClock}
          errorText={errorText}
        />
      )}
    </>
  );
}

export default function TriggerCampaignScheduleSection({
  rule,
  schedule,
  isReadOnly,
  activeErrorCheck,
  isLoading,
  onChange,
  errorText,
}: {
  rule: QualificationFrequency | null;
  schedule: TimeFrame | null;
  isReadOnly: boolean;
  activeErrorCheck: boolean;
  isLoading: boolean;
  onChange: (
    campaignSchedule: TimeFrame | null,
    campaignRule: QualificationFrequency | null
  ) => void;
  errorText: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scheduleData, setScheduleData] = useState<TimeFrame | null>(schedule);
  const [ruleData, setRuleData] = useState<QualificationFrequency | null>(rule);
  const [scheduleErrorType, setScheduleErrorType] = useState<number>(0);
  const [ruleErrorType, setRuleErrorType] = useState<number>(0);
  const [isValidation, setIsValidation] = useState<boolean>(false);

  useEffect(() => {
    setScheduleData(schedule);
  }, [schedule]);

  useEffect(() => {
    setRuleData(rule);
  }, [rule]);

  const validate = useCallback(() => {
    let isValid = true;
    if (!scheduleData?.type) {
      setScheduleErrorType(1);
      isValid = false;
    } else if (
      scheduleData?.type === TIME_FRAME_TYPE.ABSOLUTE &&
      !scheduleData.start
    ) {
      setScheduleErrorType(2);
      isValid = false;
    } else {
      setScheduleErrorType(0);
      isValid = true;
    }

    if (
      ruleData?.type === TRIGGER_CAMPAIGN_QUALIFICATION_RULES.ONCE_PER_INTERVAL
    ) {
      if (!ruleData.interval) {
        setRuleErrorType(1);
        isValid = false;
      } else if (!ruleData.interval.unit) {
        setRuleErrorType(2);
        isValid = false;
      } else if (ruleData.interval.value <= 0) {
        setRuleErrorType(3);
        isValid = false;
      } else {
        setRuleErrorType(0);
        isValid = true;
      }
    }

    return isValid;
  }, [scheduleData, ruleData]);

  const onHandleSubmit = () => {
    setIsValidation(true);
    if (validate()) {
      onChange(scheduleData, ruleData);
      onClose();
    }
  };

  const onHandleOpen = () => {
    onOpen();
  };

  const onHandleClose = () => {
    setScheduleErrorType(0);
    setRuleErrorType(0);
    setScheduleData(schedule);
    setRuleData(rule);
    onClose();
  };

  useEffect(() => {
    if (isValidation) validate();
  }, [
    scheduleData?.type,
    scheduleData?.start,
    ruleData?.type,
    ruleData?.interval?.value,
    validate,
    isValidation,
  ]);

  return (
    <>
      <SectionWrapper
        icon={FaRoute}
        title="Journey settings"
        isReadOnly={isReadOnly}
        onRightButtonClick={onHandleOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading}
        isEmpty={!scheduleData?.type}
        isLastRow={true}
      >
        <TriggerCampaignScheduleReadonly
          schedule={schedule}
          rule={rule}
          onOpen={onHandleOpen}
          errorText={errorText}
        />
      </SectionWrapper>
      <CommonDrawer
        title="Journey settings"
        isOpen={isOpen}
        onClose={onHandleClose}
        size="md"
      >
        <VStack
          alignItems="flex-start"
          spacing={26}
          mb={16}
          className={isReadOnly ? DISABLED_EDIT_CLASSNAME : ""}
        >
          <ScheduleOptionList
            setScheduleData={setScheduleData}
            schedule={scheduleData}
            scheduleErrorType={scheduleErrorType}
          ></ScheduleOptionList>
          <QualificationRule
            ruleErrorType={ruleErrorType}
            rule={ruleData}
            setRuleData={setRuleData}
          />
          <HStack justifyContent="flex-end" width="100%">
            <IButton variant={BUTTON.SECONDARY} onClick={onHandleClose}>
              Cancel
            </IButton>
            <IButton
              className={
                scheduleErrorType > 0 || ruleErrorType > 0
                  ? DISABLED_EDIT_CLASSNAME
                  : ""
              }
              variant={BUTTON.PRIMARY}
              onClick={onHandleSubmit}
            >
              Done
            </IButton>
          </HStack>
        </VStack>
      </CommonDrawer>
    </>
  );
}
