import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  List,
  ListItem,
  HStack,
  Heading,
  Icon,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { FaCheck, FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { SEGMENT_SYNC_TYPES } from "../../../../../common/types/connection";
import IButton, { BUTTON } from "../../../../../components/IButton";
import { useAppDispatch } from "../../../../../store";
import {
  selectConnection,
  setSegmentConnectionType,
} from "../../connectionSlice";
import { SegmentSyncIcon } from "./SegmentSyncs";

const SEGMENT_SYNC_OPTIONS = [
  {
    type: SEGMENT_SYNC_TYPES.SOURCE,
    option: "Segment as a Source",
    info: "This setting would allow Inflection to receive events from your segment and can be viewed in the product data explorer",
  },
  {
    type: SEGMENT_SYNC_TYPES.SOURCE_AND_DESTINATION,
    option: "Segment as Source and Destination",
    info: "This setting would not only receive  data from segment but also send Inflection events (Marketing activity) back to segment",
  },
];

export default function SelectSegmentConnectionType({
  onCancel,
  goToNextStep,
}: {
  onCancel: () => void;
  goToNextStep: () => void;
}) {
  const {
    segment: {
      createSync: { type: segmentConnectionType },
    },
  } = useSelector(selectConnection);

  const dispatch = useAppDispatch();

  return (
    <>
      <ModalHeader fontSize="sm">Connect Segment to Inflection</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text fontSize="14px">
          Which type of sync would you like to set up?
        </Text>
        <List mt={3}>
          {SEGMENT_SYNC_OPTIONS.map((options) => {
            return (
              <ListItem
                cursor="pointer"
                position="relative"
                _hover={{ bg: "gray.100" }}
                bg={segmentConnectionType === options.type ? "gray.100" : ""}
                as="div"
                width="100%"
                p={3}
                borderRadius="6px"
                mt={1}
                onClick={() => dispatch(setSegmentConnectionType(options.type))}
              >
                <HStack>
                  <SegmentSyncIcon type={options.type} />
                  <Heading as="span" color="blue.800" fontSize="12px">
                    {options.option}
                  </Heading>
                  {segmentConnectionType === options.type && (
                    <Icon
                      as={FaCheck}
                      fontSize="12px"
                      color="gray.500"
                      position="absolute"
                      right={7}
                    />
                  )}
                </HStack>
                <Text fontSize="12px" p={2} lineHeight={1.7}>
                  {options.info}
                </Text>
              </ListItem>
            );
          })}
        </List>
        <HStack borderRadius="6px" my={2} bg="orange.100" p={3}>
          <Icon as={FaInfoCircle} fontSize="14px" color="orange.400" />
          <Text fontSize="10px">
            These options can be changed later from the segment settings screen
          </Text>
        </HStack>
      </ModalBody>
      <ModalFooter>
        <IButton variant={BUTTON.SECONDARY} mr={3} onClick={onCancel}>
          Cancel
        </IButton>
        <IButton
          variant={BUTTON.PRIMARY}
          isDisabled={!segmentConnectionType}
          onClick={goToNextStep}
        >
          Continue
        </IButton>
      </ModalFooter>
    </>
  );
}
