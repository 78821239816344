import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";

export default function PublishButton({
  loading,
  disabled,
  publish,
  staticButton,
  isInactiveState,
}: {
  loading?: boolean;
  disabled?: boolean;
  publish: (active: boolean) => void;
  staticButton?: boolean;
  isInactiveState: boolean;
}) {
  return (
    <Menu>
      <MenuButton
        colorScheme="blue"
        size={"sm"}
        as={Button}
        name="publish"
        rightIcon={<FaChevronDown />}
        isDisabled={loading || disabled}
        isLoading={loading}
      >
        Publish
      </MenuButton>
      <MenuList zIndex={6}>
        <MenuItem onClick={() => publish(true)} name="publish-as-active">
          as active journey
        </MenuItem>
        <MenuItem
          isDisabled={isInactiveState}
          onClick={() => publish(false)}
          name="publish-as-inactive"
        >
          as inactive journey
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
