import { ConfirmationModal } from "../../../../../../components/ConfirmationModal";
import { Text } from "@chakra-ui/react";

export default function SfReauthenticateModal({
  isOpen,
  onCancel,
  onSubmit,
  isLoadingOnSubmit = false,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isLoadingOnSubmit?: boolean;
}) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      cancelButtonText="Cancel"
      onCancel={onCancel}
      confirmButtonText="Re-authenticate now"
      confirmButtonLoading={isLoadingOnSubmit}
      submitHandler={onSubmit}
      title="Re-authenticate Salesforce"
    >
      <Text>
        Salesforce connection needs re-authentication. Sync is paused until the
        Salesforce account is re-authenticated.
      </Text>
    </ConfirmationModal>
  );
}
