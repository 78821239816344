import { memo, ReactNode, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function DndWrapper({ id, children }: { id: string; children: ReactNode }) {
  const [context, setContext] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setContext(document.getElementById(id));
  }, [id]);

  return context ? (
    <DndProvider backend={HTML5Backend} options={{ rootElement: context }}>
      {children}
    </DndProvider>
  ) : null;
}

export default memo(DndWrapper);
