import {
  HStack,
  Input,
  Text,
  useClipboard,
  StackProps,
  ButtonProps,
  TextProps,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { SOURCES } from "../../../../../common/types/unifiedMapping";
import IButton, { BUTTON } from "../../../../../components/IButton";
import CommonDrawer from "../../../campaign/components/CommonDrawer";
import SubHeader from "../../../settings/components/SubHeader";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";

const DATABASE_NAME_TO_COPY = {
  [SOURCES.BIGQUERY]: "inflection",
};

const GETTING_STARTED_DOC = {
  [SOURCES.BIGQUERY]: {
    title: "Creating a dataset",
    description:
      "Inflection creates tables and transfers data from your dataset. So it requires read & write permissions to a dataset to create & transfer data from the tables. Create a dataset by copying the below dataset name.",
    additonalInfo:
      "Once you create the dataset, you can proceed to add the permissions for Inflection to start syncing data in the next step",
  },
};

export function CopyTextToClipboard({
  text,
  buttonProps,
  inputTextProps,
  ...props
}: {
  text: string;
  buttonProps?: ButtonProps;
  inputTextProps?: TextProps;
} & StackProps) {
  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  useEffect(() => {
    setValue(text);
  }, [setValue, text]);

  return (
    <HStack {...props}>
      <Input
        size="sm"
        value={value}
        borderRadius={4}
        isReadOnly
        fontSize={"12px"}
        {...inputTextProps}
      ></Input>
      <IButton
        variant={BUTTON.PRIMARY}
        fontSize="12px"
        width="70px"
        onClick={onCopy}
        {...buttonProps}
      >
        {hasCopied ? "Copied" : "Copy"}
      </IButton>
    </HStack>
  );
}

export default function CopyDatabaseName({
  service = SOURCES.BIGQUERY,
  onClose,
  goToNext,
  goToPrev,
}: {
  service?: SOURCES.BIGQUERY;
  onClose: () => void;
  goToNext: () => void;
  goToPrev: () => void;
}) {
  const { title, description, additonalInfo } = GETTING_STARTED_DOC[service];
  return (
    <CommonDrawer
      title="Getting started"
      onClose={onClose}
      isOpen={true}
      goBack={goToPrev}
      size="md"
      placement="right"
    >
      <SubHeader
        title={title}
        desc={description}
        titleProps={{ fontSize: "16px" }}
        descriptionProps={{ fontSize: "14px", mt: "20px", mb: "20px" }}
        boxProps={{ mt: "10px" }}
      />
      <CopyTextToClipboard text={DATABASE_NAME_TO_COPY[service]} mb="20px" />
      <Text fontSize="14px" py={2} mt={2} mb="20px">
        {additonalInfo}
      </Text>
      <ConnectionButtons closeModal={onClose} onClick={goToNext} />
    </CommonDrawer>
  );
}
