import axios, { AxiosRequestConfig, Method } from "axios";
import urls from "../../urls";
import { DEFAULT_RETRY_COUNT } from "../constants/common";
import {
  CLIENT_APP_VERSION,
  INTEGRATIONS_BASE_URL,
  VERSION_HEADER_NAME,
  VERSION_MISMATCH_BANNER_ID,
} from "../constants";
import { getAuthToken, removeAuthToken } from "../helper/authHelper";
import { removeRollbarData, rollbar } from "../helper/rollbarHelper";
import { checkErrorStatusCode, consolidateApiErrors, timeout } from "./helper";

const SALESFORCE_SESSION_EXPIRED_ERROR_CODE = "SIS-E-003";

function hasSalesforceSessionExpiryCode(errors?: any[]) {
  return errors?.[0]?.errorCode === SALESFORCE_SESSION_EXPIRED_ERROR_CODE;
}

const client = axios.create({
  baseURL: INTEGRATIONS_BASE_URL,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
  timeout: 300000,
});

/**
 * Request Wrapper with default success/error actions
 * @param options Axios request configuration
 * @param isAuthenticated Does this API require valid auth token
 * @param isErrorHandled Is the API error/validation toaster handled separately
 */

export const request = function (
  options: AxiosRequestConfig,
  isAuthenticated = true,
  isErrorHandled = false,
  shouldRetryApiCall?: boolean,
  apiRetryInterval?: number,
  retryCount?: number
) {
  let numberOfRetries = 1;
  if (isAuthenticated) {
    const token = getAuthToken();
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    } else {
      throw new Error("Auth token not found");
    }
  }

  const onSuccess = function (response: any) {
    if (response.headers?.[VERSION_HEADER_NAME]) {
      if (response.headers?.[VERSION_HEADER_NAME] > CLIENT_APP_VERSION) {
        var banner = window?.document?.getElementById(
          VERSION_MISMATCH_BANNER_ID
        );
        if (banner) {
          banner.style.display = "block";
        }
      }
    } else {
      rollbar.error("Missing client version number", response);
    }
    return response.data;
  };

  const onError: any = async function (error: any) {
    if (
      shouldRetryApiCall &&
      retryCount &&
      numberOfRetries <= retryCount &&
      checkErrorStatusCode(error)
    ) {
      numberOfRetries++;
      if (apiRetryInterval) {
        await timeout(apiRetryInterval);
      }
      return client(options).then(onSuccess).catch(onError);
    }
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.error("Status:", error.response.status);
        // eslint-disable-next-line no-console
        console.error("Data:", error.response.data);
        // eslint-disable-next-line no-console
        console.error("Headers:", error.response.headers);
      }

      if (!isErrorHandled) {
        if (error.response.status < 500) {
          if (
            error.response.status === 400 &&
            !error.response.data.description
          ) {
            consolidateApiErrors(
              "Some request parameters are invalid or missing",
              "error"
            );
          } else if (
            (error.response.status === 403 || error.response.status === 401) &&
            !hasSalesforceSessionExpiryCode(error.response.data.errors)
          ) {
            if (getAuthToken()) {
              removeAuthToken();
              removeRollbarData();
              consolidateApiErrors(
                "Session expired! Please login again.",
                "warning"
              );

              setTimeout(() => {
                window.location.href = urls.login;
              }, 2000);
            }
          } else {
            consolidateApiErrors(error.response.data.description, "error");
          }
        } else {
          //log errors with status code >= 500
          rollbar.error(
            error.response.data.exception ?? error.response.data.description,
            error.response.data
          );
          consolidateApiErrors("Something went wrong!", "error");
        }
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.error("Error Message:", error.message);
      }
      if (!isErrorHandled) {
        consolidateApiErrors(error.message, "error");
      }
      //log errors with no error response
      rollbar.error(error.message, error);
    }

    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};

export async function inflectionRequest<T>({
  method = "POST",
  url,
  baseUrl,
  data,
  isErrorHandled = false,
  isAuthenticated = true,
  shouldRetryApiCall = false,
  apiRetryInterval = 5000,
  retryCount = DEFAULT_RETRY_COUNT,
  ...options
}: {
  method?: Method;
  baseUrl: string;
  url: string;
  data?: any;
  isAuthenticated?: boolean;
  isErrorHandled?: boolean;
  shouldRetryApiCall?: boolean;
  apiRetryInterval?: number;
  retryCount?: number;
} & AxiosRequestConfig): Promise<T> {
  return await request(
    {
      method,
      baseURL: baseUrl,
      url,
      data,
      ...options,
    },
    isAuthenticated,
    isErrorHandled,
    shouldRetryApiCall,
    apiRetryInterval,
    retryCount
  );
}
