import { Box, Text, Heading, Flex, Icon } from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import IButton from "./IButton";

export default function MessageDisplay({
  displayMessage,
  heading,
  redirectFn,
  redirectMsg,
  type,
}: {
  displayMessage: string;
  heading: string;
  redirectFn: () => void;
  redirectMsg: string;
  type: "success" | "error";
}) {
  return (
    <Box
      bg="white"
      py="12"
      px={{ base: "4", md: "10" }}
      shadow="base"
      w="md"
      rounded={{ sm: "lg" }}
    >
      <Flex justify="center" alignItems="center" flexDirection="column">
        <Icon
          h="12"
          w="12"
          as={type === "success" ? FaCheckCircle : FaExclamationCircle}
          m={4}
          mb={6}
          color={type === "success" ? "brand.green" : "brand.red"}
        />
        <Heading as="h6" mb={6} fontSize={20}>
          {heading}
        </Heading>

        <Text mb="6" fontSize={16} textAlign="center">
          {displayMessage}
        </Text>

        <IButton
          onClick={() => redirectFn()}
          fontSize={14}
          name="redirect-button"
          backgroundColor={type === "success" ? "brand.green" : "brand.red"}
          color="white"
          _hover={{ opacity: "0.7" }}
        >
          {redirectMsg}
        </IButton>
      </Flex>
    </Box>
  );
}
