import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import ITitle from "./ITitle";

export enum BUTTON {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  GHOST = "ghost",
}

const IButton = forwardRef<
  ButtonProps & {
    capitalize?: boolean;
    customContent?: boolean;
  },
  "button"
>(({ capitalize = true, customContent = false, ...props }, ref) => {
  return (
    <Button {...props} ref={ref}>
      {customContent || !capitalize ? (
        props.children
      ) : (
        <ITitle title={props.children as string}></ITitle>
      )}
    </Button>
  );
});

export default IButton;
