import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { LOADING_STATES } from "../constants/common";
import hash from "object-hash";

const DEFAULT_EXPIRY_TIME = 3600000;

interface ApiStatusState {
  apiStatus: {
    [actionName: string]: {
      [propsHash: string]: {
        state: LOADING_STATES;
        expiry?: number;
      };
    };
  };
}

const initialState: ApiStatusState = {
  apiStatus: {},
};

const apiStatusSlice = createSlice({
  name: "api-status",
  initialState,
  reducers: {
    setApiStatus(
      state,
      {
        payload,
      }: {
        payload: {
          actionName: string;
          state?: LOADING_STATES;
          props?: any;
          validPeriod?: number;
        };
      }
    ) {
      state.apiStatus[payload.actionName] = {
        ...state.apiStatus[payload.actionName],
        [hash(payload.props ?? {})]: {
          state: payload.state || LOADING_STATES.LOADING,
          expiry: Date.now() + (payload.validPeriod ?? DEFAULT_EXPIRY_TIME),
        },
      };
    },
    expireApiStatus(
      state,
      {
        payload,
      }: { payload: { actionName: string; props?: any; expireAll?: boolean }[] }
    ) {
      payload.forEach(({ actionName, props, expireAll }) => {
        if (actionName in state.apiStatus) {
          if (expireAll) {
            const propHashes = Object.keys(state.apiStatus[actionName]);
            propHashes.forEach((propHash) => {
              state.apiStatus[actionName][propHash].expiry = Date.now();
            });
          } else {
            state.apiStatus[actionName][hash(props ?? {})].expiry = Date.now();
          }
        }
      });
    },
  },
});

export const { setApiStatus, expireApiStatus } = apiStatusSlice.actions;

export const selectApiStatus = (state: RootState) => state.apiStatus;

export default apiStatusSlice.reducer;
