import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { formatDistanceToNow, subHours } from "date-fns";
import { ReactNode, useContext, useEffect } from "react";
import { IconType } from "react-icons";
import {
  FaLock,
  FaDotCircle,
  FaEnvelope,
  FaRoute,
  FaChevronDown,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_BASIC,
  RECIPIENT_EVENT_TYPE,
} from "../../../../common/constants/campaign";
import { useLastSavedStatus } from "../../../../common/hooks/commonHooks";
import { UserSummary } from "../../../../common/types/common";
import IButton, { BUTTON } from "../../../../components/IButton";
import { LayoutContext } from "../../../../layout/LayoutWithSideNavBar";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import { getUserList, selectAccount } from "../../../account/accountSlice";
import CampaignReports from "../campaignreports/CampaignReports";
import {
  closeReportsSlider,
  openReportsSlider,
  resetActionIdFilter,
  resetCampaignReportsRange,
  selectCampaignReports,
  setRecipientEventType,
} from "../campaignreports/campaignReportsSlice";
import JourneyMemberReport from "../flow/components/JourneyMemberReport";
import { openJourneyMemberReport } from "../flow/flowSlice";
import CampaignModal from "./CampaignModal";
import MemoizedCommonDrawer from "./CommonDrawer";
import PublishButton from "./PublishButton";

function RoundedCardWithIcon({
  icon,
  color,
  label,
}: {
  icon: IconType;
  color: string;
  label: string;
}) {
  return (
    <Flex alignItems="center">
      <HStack alignItems="center">
        <Icon as={icon} color={color} />
        <Text fontSize="sm" fontWeight="400" color="gray.700">
          {label}
        </Text>
      </HStack>
    </Flex>
  );
}

const STATUS_DETAILS_LABEL = {
  [CAMPAIGN_STATUS.ACTIVE]: "Journey is active",
  [CAMPAIGN_STATUS.INACTIVE]: "Journey is inactive",
  [CAMPAIGN_STATUS.DRAFT]: "Draft journey",
};

function CampaignStatusBox({ status }: { status: CAMPAIGN_STATUS }) {
  return (
    <RoundedCardWithIcon
      icon={CAMPAIGN_STATUS_BASIC[status].icon}
      color={CAMPAIGN_STATUS_BASIC[status].color}
      label={STATUS_DETAILS_LABEL[status]}
    />
  );
}

function LastSavedStatus({
  updatedAt,
  isDraft,
  currentUserId,
  updatedBy,
}: {
  updatedAt: string | number;
  isDraft: boolean;
  currentUserId: number;
  updatedBy: UserSummary | null;
}) {
  const [lastSaved] = useLastSavedStatus(updatedAt);

  return (
    <Flex style={{ gap: "3px" }} alignItems="center">
      <Text>{isDraft ? "Saved" : `Published`}</Text>
      <Text fontWeight={600} whiteSpace="nowrap">
        {lastSaved}
      </Text>
      {isDraft && updatedBy && currentUserId !== updatedBy?.id && (
        <Flex fontSize="12px" style={{ gap: "4px" }} alignItems="center">
          <Text>by </Text>
          <Text
            fontWeight={600}
            maxWidth="100px"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            title={`${updatedBy?.name}`}
          >
            {updatedBy?.name}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

function SaveStatus({
  updatedAt,
  savingDraft,
  isDraft,
  currentUserId,
  updatedBy,
}: {
  updatedAt: string | number;
  savingDraft: boolean;
  isDraft: boolean;
  currentUserId: number;
  updatedBy: UserSummary | null;
}) {
  return (
    <Flex fontSize="12px" mr="10px" style={{ gap: "6px" }} alignItems="center">
      <Icon
        h="3"
        w="3"
        color={
          isDraft && currentUserId !== updatedBy?.id ? "red.500" : "green.500"
        }
        as={FaDotCircle}
      />
      {savingDraft ? (
        <Flex alignItems="center" fontWeight={600} mr="10px">
          Saving...
        </Flex>
      ) : (
        <LastSavedStatus
          updatedAt={updatedAt}
          isDraft={isDraft}
          currentUserId={currentUserId}
          updatedBy={updatedBy}
        />
      )}
    </Flex>
  );
}

export default function TopBar({
  updatedBy,
  status,
  savingDraft,
  updatedAt,
  publishingCampaign,
  isCampaignEditable,
  onPublish,
  isReportsHidden,
  isFetchingDetails,
  children,
}: {
  updatedBy: string;
  status: CAMPAIGN_STATUS;
  savingDraft: boolean;
  updatedAt: string | number;
  publishingCampaign: boolean;
  isCampaignEditable: boolean;
  onPublish: (active: boolean) => void;
  isReportsHidden: boolean;
  isFetchingDetails: boolean;
  children: ReactNode;
}) {
  const { user, usersList } = useSelector(selectAccount);
  const { isSidebarCollapsed } = useContext(LayoutContext);
  const { isReportsDrawerOpen } = useSelector(selectCampaignReports);
  const {
    isOpen: isEditWarningOpen,
    onOpen: onEditWarningOpen,
    onClose: onEditWarningClose,
  } = useDisclosure();

  const {
    isOpen: isOverrideWarningOpen,
    onOpen: onOverrideWarningOpen,
    onClose: onOverrideWarningClose,
  } = useDisclosure();

  const dispatch = useAppDispatch();

  function onCloseEmailReport() {
    dispatch(resetActionIdFilter());
    dispatch(closeReportsSlider());
  }

  function openEmailReport() {
    // TODO check whether campaigns date range reset is needed
    dispatch(resetCampaignReportsRange());
    dispatch(openReportsSlider());
    dispatch(setRecipientEventType(RECIPIENT_EVENT_TYPE.ALL));
  }

  function openMemberReport() {
    dispatch(openJourneyMemberReport());
  }

  useEffect(() => {
    if (
      updatedBy &&
      user.id &&
      new Date(updatedAt ?? "") > subHours(new Date(), 1) &&
      Number(updatedBy) !== user.id &&
      !isFetchingDetails &&
      status === CAMPAIGN_STATUS.DRAFT
    ) {
      onOverrideWarningOpen();
    } else {
      onOverrideWarningClose();
    }
  }, [
    updatedBy,
    updatedAt,
    onOverrideWarningOpen,
    onOverrideWarningClose,
    user.id,
    isFetchingDetails,
    status,
  ]);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const navigate = useNavigate();

  function goToCampaignList() {
    navigate(urls.journey);
  }

  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      width={`calc(100vw - ${isSidebarCollapsed ? "80px" : "245px"})`}
      transition="width 0.1s ease-in"
      zIndex={1}
    >
      <Box minW="425px" py="2" px="15px">
        {children}
      </Box>
      <Box m="2" w="100%">
        <Flex
          flexGrow="1"
          justifyContent="space-between"
          py="2"
          px="5"
          bg="white"
          shadow="sm"
          rounded="md"
          w="100%"
        >
          {!isFetchingDetails ? <CampaignStatusBox status={status} /> : <Box />}
          <HStack justifyContent="flex-end">
            {!isFetchingDetails &&
              (status === CAMPAIGN_STATUS.DRAFT) === !!updatedBy && (
                <SaveStatus
                  updatedAt={updatedAt}
                  savingDraft={savingDraft}
                  isDraft={status === CAMPAIGN_STATUS.DRAFT}
                  currentUserId={user.id}
                  updatedBy={{
                    id: Number(updatedBy),
                    name: usersList[updatedBy]?.name,
                  }}
                />
              )}

            {!isReportsHidden && (
              <>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={BUTTON.SECONDARY}
                    rightIcon={<FaChevronDown />}
                  >
                    Reports
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={openEmailReport}>
                      <HStack>
                        <Icon as={FaEnvelope} fontSize="12px" />
                        <Text>Email Report</Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem>
                      <HStack onClick={openMemberReport}>
                        <Icon as={FaRoute} fontSize="12px" />
                        <Text>Journey Member Report</Text>
                      </HStack>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </>
            )}
            <Skeleton isLoaded={!isFetchingDetails}>
              {!isCampaignEditable ? (
                <IButton
                  variant={BUTTON.PRIMARY}
                  leftIcon={<FaLock />}
                  onClick={onEditWarningOpen}
                  isLoading={publishingCampaign}
                  name="edit-campaign"
                >
                  Edit journey
                </IButton>
              ) : (
                <PublishButton
                  loading={publishingCampaign}
                  publish={(active) => onPublish(active)}
                  isInactiveState={status === CAMPAIGN_STATUS.INACTIVE}
                />
              )}
            </Skeleton>
          </HStack>
          <CampaignModal
            isOpenModal={isEditWarningOpen}
            onCancel={onEditWarningClose}
            submitHandler={() => {
              onPublish(false);
              onEditWarningClose();
            }}
            header="Edit active journey"
            text="Editing an Active journey would pause the current run. Do you want to continue?"
            primaryButton="Edit journey"
          />
          <CampaignModal
            isOpenModal={isOverrideWarningOpen}
            onCancel={() => {
              onOverrideWarningClose();
              goToCampaignList();
            }}
            submitHandler={() => {
              onOverrideWarningClose();
            }}
            header="Data override warning"
            text={`${usersList[updatedBy]?.name} updated this journey ${
              updatedAt
                ? formatDistanceToNow(new Date(updatedAt), {
                    addSuffix: true,
                  })
                : ""
            }. Before continuing make sure that they are done with editing. 
                Editing a journey at the same time could lead to overwrites.`}
            primaryButton="Ok"
          />
          <MemoizedCommonDrawer
            title="Email Report"
            isOpen={isReportsDrawerOpen}
            onClose={onCloseEmailReport}
            size="4xl"
          >
            <CampaignReports />
          </MemoizedCommonDrawer>

          <JourneyMemberReport />
        </Flex>
      </Box>
    </Flex>
  );
}
