import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  TableProps,
  Flex,
  Checkbox,
  CheckboxProps,
  Center,
  Tbody,
  TableRowProps,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  PaginationState,
} from "@tanstack/react-table";
import CenteredTextBox from "../CenteredTextBox";
import Pagination from "../Pagination";
import { isEmpty } from "lodash";
import ISkeleton, { SKELETON_VARIANT } from "../ISkeleton";
import { LinkProps } from "react-router-dom";

export type DataTableProps<Data extends object> = {
  list: Data[] | null;
  columns: ColumnDef<Data, any>[];
  emptyMsg: string;
  fetchingList: boolean;
  changingPage: boolean;
  totalPageCount: number | null;
  onRowClick?: (row: Data) => void;
  onRowRightClick?: (row: Data) => void;
  currentPage: number;
  totalPageSize: number;
  setPage: (pageNo: number) => void;
  onSelectionChange?: (rows: Data[], all: boolean) => void;
  tablePadding?: {
    customCellPadding?: string;
    headerPadding?: string;
  };
  popoverPresent?: boolean;
  scrollProps?: {
    overflowY: string;
    height: string;
  };
  getTableRowLinkProps?: (
    data: Data
  ) => (TableRowProps & LinkProps) | undefined;
  tableHeight?: string;
  isPagination?: boolean;
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Checkbox
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}

function TableLoader({
  loading,
  children,
  emptyMsg,
  recordsPresent,
  columnCount,
  rowCount,
}: {
  loading: boolean;
  children: ReactNode;
  emptyMsg: string;
  recordsPresent: boolean;
  columnCount: number;
  rowCount: number;
}) {
  function displayAtCenter(children: ReactNode): ReactNode {
    return (
      <Tr>
        <Td>
          <Center width="100%" height="50px" color="grayV2.600">
            {children}
          </Center>
        </Td>
      </Tr>
    );
  }

  function displayRecords(): ReactNode {
    return recordsPresent ? children : displayAtCenter(<>{emptyMsg}</>);
  }

  return (
    <Tbody w="100%">
      <ISkeleton
        variant={SKELETON_VARIANT.TABLE_BODY}
        isLoaded={!loading}
        columnCount={columnCount}
        rowCount={rowCount}
      >
        {displayRecords()}
      </ISkeleton>
    </Tbody>
  );
}

export function DataTable<Data extends object>({
  list,
  columns,
  onRowClick,
  onRowRightClick,
  currentPage,
  setPage,
  totalPageCount,
  totalPageSize,
  fetchingList,
  changingPage,
  emptyMsg,
  onSelectionChange,
  tablePadding,
  popoverPresent = false,
  scrollProps,
  getTableRowLinkProps,
  tableHeight,
  isPagination = true,
  ...props
}: DataTableProps<Data> & TableProps) {
  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({});

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: currentPage - 1,
      pageSize: totalPageSize, // no use with pagesize here,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (fetchingList || changingPage) {
      setRowSelection({});
    }
  }, [fetchingList, changingPage]);

  useEffect(() => {
    setPagination({ pageIndex: currentPage - 1, pageSize: totalPageSize });
  }, [currentPage, totalPageSize]);

  const table = useReactTable({
    columns,
    data: list ?? [],
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      pagination,
    },
    pageCount: totalPageCount ?? -1,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableRowSelection: !!onSelectionChange,
    onRowSelectionChange: setRowSelection,
  });

  const selectedList: Data[] = useMemo(
    () =>
      rowSelection && !isEmpty(rowSelection)
        ? list?.filter((x, i) => rowSelection[i]) ?? []
        : [],
    [rowSelection, list]
  );

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedList, selectedList?.length === list?.length);
    }
  }, [list?.length, onSelectionChange, selectedList]);

  const columnCount = useMemo(() => {
    return table.getHeaderGroups()[0].headers.length;
  }, [table]);

  if (!totalPageCount && !(fetchingList || changingPage)) {
    return (
      <CenteredTextBox
        color="grayV2.600"
        bg="white"
        rounded="md"
        message={emptyMsg}
      />
    );
  } else {
    return (
      <>
        <Box
          borderRadius="md"
          height={tableHeight}
          sx={{
            maxHeight: isPagination ? "calc(100% - 60px)" : "100%",
            overflow: fetchingList || changingPage ? "hidden" : "auto",
          }}
          w="100%"
        >
          <Table
            fontSize="sm"
            width="100%"
            bg="white"
            borderRadius="md"
            {...props}
            style={scrollProps as React.CSSProperties}
          >
            <Thead
              bg="oneOffs.tableHeader"
              roundedTop="md"
              h="40px"
              sx={{ top: "0", margin: "0", zIndex: "1", position: "sticky" }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} roundedTop="md">
                  {!!onSelectionChange && (
                    <Th
                      w="65px"
                      roundedTopLeft="md"
                      px={tablePadding?.headerPadding ?? 3}
                      py={tablePadding?.headerPadding ?? 2}
                    >
                      <IndeterminateCheckbox
                        {...{
                          isChecked: table.getIsAllRowsSelected(),
                          indeterminate: table.getIsSomeRowsSelected(),
                          onChange: table.getToggleAllRowsSelectedHandler(),
                          isDisabled: fetchingList || changingPage,
                        }}
                      />
                    </Th>
                  )}
                  {headerGroup.headers.map((header, index) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta: any = header.column.columnDef.meta;

                    const roundedRight =
                      index === headerGroup.headers.length - 1;
                    const roundedLeft = !onSelectionChange && index === 0;
                    return (
                      <Th
                        key={header.id}
                        isNumeric={meta?.isNumeric}
                        colSpan={header.colSpan}
                        width={
                          header.getSize() !== 150
                            ? header.getSize()
                            : meta?.width
                        }
                        fontWeight="semibold"
                        color="text.200"
                        roundedTopLeft={roundedLeft ? "md" : "none"}
                        roundedTopRight={roundedRight ? "md" : "none"}
                        textTransform="none"
                        px={tablePadding?.headerPadding ?? 3}
                        py={tablePadding?.headerPadding ?? 2}
                      >
                        <Flex
                          justifyContent={
                            meta?.isNumeric ? "flex-end" : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <TableLoader
              loading={fetchingList || changingPage}
              recordsPresent={!!(list && list.length)}
              emptyMsg={emptyMsg}
              columnCount={columnCount}
              rowCount={pageSize}
            >
              {table.getRowModel().rows.map((row) => (
                <Tr
                  {...(getTableRowLinkProps?.(row.original) ?? {})}
                  key={row.id}
                  _hover={popoverPresent ? undefined : { bg: "grayV2.100" }}
                  cursor={onRowClick ? "pointer" : "default"}
                  onClick={
                    onRowClick
                      ? (e) => {
                          if (e.ctrlKey || e.metaKey) {
                            //right clicks
                            onRowRightClick?.(row.original);
                            return;
                          }
                          e.preventDefault();
                          onRowClick(row.original);
                        }
                      : undefined
                  }
                  onContextMenu={() => onRowRightClick?.(row.original)}
                  h="50px"
                >
                  {!!onSelectionChange && (
                    <Td
                      verticalAlign="middle"
                      w="65px"
                      px={tablePadding?.customCellPadding ?? 3}
                      py={tablePadding?.customCellPadding ?? 2}
                    >
                      <IndeterminateCheckbox
                        {...{
                          isChecked: row.getIsSelected(),
                          isDisabled: !row.getCanSelect(),
                          indeterminate: row.getIsSomeSelected(),
                          onChange: row.getToggleSelectedHandler(),
                        }}
                      />
                    </Td>
                  )}
                  {row.getVisibleCells().map((cell) => {
                    // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                    const meta: any = cell.column.columnDef.meta;
                    return (
                      <Td
                        verticalAlign="middle"
                        key={cell.id}
                        wordBreak="break-all"
                        isNumeric={meta?.isNumeric}
                        px={tablePadding?.customCellPadding ?? 3}
                        py={tablePadding?.customCellPadding ?? 2}
                        _hover={popoverPresent ? { bg: "gray.100" } : undefined}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </TableLoader>
          </Table>
        </Box>
        {!(fetchingList && !changingPage) && isPagination && (
          <Pagination
            onPageChange={(pageNo) => {
              table.setPageIndex(pageNo - 1);
              setPage(pageNo);
            }}
            pageCount={table.getPageCount()}
            currentPage={pageIndex + 1}
          />
        )}
      </>
    );
  }
}
