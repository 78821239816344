import {
  Box,
  Heading,
  StackProps,
  VStack,
  Text,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export default function SecondarySidebar({
  heading,
  backButton,
  children,
  ...stackProps
}: {
  heading: string;
  backButton?: ReactNode;
  children?: ReactNode;
} & StackProps) {
  return (
    <VStack
      width="280px"
      spacing="0"
      alignItems="flex-start"
      px="20px"
      bg="oneOffs.sidebarBackground2"
    >
      <VStack
        alignItems="flex-start"
        py="12px"
        {...stackProps}
        minH="45px"
        justifyContent="flex-start"
        w="100%"
        maxW="240px"
      >
        {backButton}
        <Skeleton isLoaded={!!heading} h="22px" width="150px">
          <Tooltip label={heading} fontSize="12px">
            <Heading
              maxW="100%"
              variant="main-page-title"
              textTransform="inherit"
            >
              <Text
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {heading}
              </Text>
            </Heading>
          </Tooltip>
        </Skeleton>
      </VStack>

      <Box w="100%">{children}</Box>
    </VStack>
  );
}
