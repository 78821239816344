import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { DATA_CONNECTION_SERVICES_LIST } from "../../common/constants/connection";
import { SOURCES } from "../../common/types/unifiedMapping";

export function ConnectionNameWithSourceLogo({
  label,
  source,
  ...style
}: {
  label: string;
  source: SOURCES;
} & FlexProps) {
  const src = DATA_CONNECTION_SERVICES_LIST.find(
    (x) => x.id === source
  )?.imgSrc;
  return (
    <Flex alignItems={"center"} {...style}>
      {src && <img width="25" src={src} alt="logo" />}
      {label && (
        <Text fontSize="sm" ml="3">
          {label}
        </Text>
      )}
    </Flex>
  );
}
