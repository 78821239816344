import { Box, HStack, VStack, Text, Flex } from "@chakra-ui/react";
import { cloneDeep, isArray } from "lodash";
import { useContext, useMemo } from "react";
import { FaDesktop } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  CONNECTOR,
  DynamicListChild,
  DynamicListChildL2,
  OperatorType,
  DynamicListChildL1,
  TYPE,
  ValueTypes,
} from "../../common/types/campaign";
import AddPropertyButton from "./AddPropertyButton";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import { FilterGroupBox } from "./FilterGroupBox";
import DynamicListLogicGate from "./DynamicListLogicGate";
import {
  DynamicListValueFields,
  ValueSelectFields,
} from "./DynamicListValueFields";
import { FILTER_TYPE } from "../../common/constants/campaign";
import {
  createGroupId,
  getOperatorDetails,
  isLoading,
} from "../../common/helper/commonHelper";
import { useAppDispatch } from "../../store";
import { FrequencySelector, TimeFrameSelector } from "./CommonFilterRows";
import { validateFilterGroup } from "../../pages/dashboard/campaign/helper/validationHelper";
import { DynamicListContext } from "./DynamicList";
import { selectDynamicList, getEventKeys } from "./dynamicListSlice";
import ActivitySelector from "./ActivitySelector";
import { LuMousePointerClick } from "react-icons/lu";
import {
  addEllipsisToText,
  findFirstGroupIndex,
  getOperatorsListForDisplay,
  isTypeGroup,
} from "../../common/helper/dynamicListHelper";
import AddFrequencyButton from "./AddFrequencyButton";
import AddTimeFrameButton from "./AddTimeFrameButton";

function PropertyGroupContainer({
  data,
  parentId,
  onChange,
  isReadOnly,
}: {
  data: DynamicListChildL1[];
  parentId: string;
  onChange: (data: DynamicListChildL1[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(
    updateData: DynamicListChildL1,
    index: number
  ) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  function setConnector(newConnector: CONNECTOR, index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy[index].connector = newConnector;
    onChange(dataCopy);
  }

  function notFirstConnector(index: number) {
    return data.findIndex((i) => i.type === TYPE.GROUP) !== index;
  }

  function findFirstGroupIndex(data: DynamicListChildL1[]) {
    return data.findIndex((x) => isTypeGroup(x.type)) || 0;
  }

  const wrapperStyle = isReadOnly ? { pl: 3, pr: 1 } : { p: 0 };

  return (
    <>
      {data.map(
        (item, index) =>
          item.type === TYPE.GROUP && (
            <VStack
              w="100%"
              alignItems="flex-start"
              spacing={1}
              {...wrapperStyle}
            >
              {notFirstConnector(index) && (
                <DynamicListLogicGate
                  operator={item.connector}
                  handleChange={(conn) => setConnector(conn, index)}
                  isReadOnly={isReadOnly}
                />
              )}
              <PropertyGroup
                mainFilter={data[0].value}
                data={item}
                id={createGroupId(
                  parentId,
                  "property",
                  index - findFirstGroupIndex(data) + 1
                )}
                label={
                  item.name
                    ? item.name
                    : `Property group ${index - findFirstGroupIndex(data) + 1}`
                }
                onRemove={() => onRemovePropertyGroup(index)}
                onChange={(data) => onChangePropertyGroup(data, index)}
                isReadOnly={isReadOnly}
              />
            </VStack>
          )
      )}
    </>
  );
}

function PropertyGroup({
  mainFilter,
  data,
  label,
  id,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: ValueTypes | null;
  data: DynamicListChildL1;
  label: string;
  id: string;
  onRemove: () => void;
  onChange: (data: DynamicListChildL1) => void;
  isReadOnly?: boolean;
}) {
  function addRow() {
    const newRow: DynamicListChildL2 = {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
      filter: "event_properties",
      property: "",
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    };
    const dataCopy = cloneDeep(data);
    if (dataCopy.children) {
      dataCopy.children.push(newRow);
    } else {
      dataCopy.children = [newRow];
    }
    onChange(dataCopy);
  }

  function removeRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children?.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangeHandler(newData: DynamicListChildL2, index: number) {
    if (data.children) {
      const dataCopy = cloneDeep(data);
      (dataCopy.children as DynamicListChildL2[])[index] = newData;
      onChange(dataCopy);
    }
  }

  function onChangePropertyGroupName(value: string) {
    let dataCopy = cloneDeep(data);
    dataCopy = { ...dataCopy, name: value };
    onChange(dataCopy);
  }

  return (
    <FilterGroupBox
      label={label}
      onRemove={onRemove}
      onGroupNameChange={onChangePropertyGroupName}
      groupName={data.name ?? ""}
      id={id}
      type="property"
      borderColor="grayV2.300"
      bg="grayV2.300"
      isReadOnly={isReadOnly}
    >
      <VStack spacing={0} w="100%">
        {data.children?.map((child: DynamicListChildL2, index: number) => {
          return (
            <PropertyGroupRow
              mainFilter={mainFilter}
              showConnector={index !== 0}
              onRemove={() => removeRow(index)}
              onChange={(data) => onChangeHandler(data, index)}
              data={child}
              onAddRow={addRow}
              showAddButton={index === (data.children?.length || -1) - 1}
              showRemoveButton={data.children?.length !== 1}
              isReadOnly={isReadOnly}
            />
          );
        })}
      </VStack>
    </FilterGroupBox>
  );
}

function PropertyGroupRow({
  data,
  mainFilter,
  showConnector,
  onChange,
  showRemoveButton,
  onRemove,
  showAddButton,
  onAddRow,
  isReadOnly,
}: {
  data: DynamicListChildL2;
  mainFilter: ValueTypes | null;
  showConnector: boolean;
  onChange: (data: DynamicListChildL2) => void;
  showRemoveButton: boolean;
  onRemove: () => void;
  showAddButton: boolean;
  onAddRow: () => void;
  isReadOnly?: boolean;
}) {
  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
    operators: { data: operators },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  const operatorDetails = useMemo(
    () => getOperatorDetails(data.operator, operators, OperatorType.STRING),
    [operators, data.operator]
  );

  function setProperty(newProperty: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.property = newProperty;
    dataCopy.operator = null;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function setConnector(newConnector: CONNECTOR) {
    const dataCopy = cloneDeep(data);
    dataCopy.connector = newConnector;
    onChange(dataCopy);
  }

  function setOperator(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function handleValueChange(val: ValueTypes) {
    const dataCopy = cloneDeep(data);
    if (
      operatorDetails?.arguments_types?.length &&
      val.length &&
      val.some((x) => x === "")
    ) {
      dataCopy.validation_error = "Invalid values";
    } else {
      dataCopy.validation_error = "";
    }
    dataCopy.value = val;
    onChange(dataCopy);
  }

  function getEventList() {
    return mainFilter
      ? productEventKeysList[mainFilter[0] as string]?.map((x) => ({
          label: x,
          value: x,
        }))
      : [];
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "1",
        spacing: "1",
      }
    : {
        spacing: "2",
        py: "1",
        px: "3",
      };

  return (
    <VStack w="100%" justifyContent="flex-start" {...wrapperStyle}>
      <Flex w="100%" alignItems="flex-start">
        <HStack alignItems="flex-start" width="100%" wrap="wrap" flex="1">
          {showConnector ? (
            <DynamicListLogicGate
              id="logic-gate"
              operator={data.connector}
              handleChange={setConnector}
              isReadOnly={isReadOnly}
            />
          ) : isReadOnly ? (
            <Text fontSize="sm">where</Text>
          ) : (
            <HStack h="33px">
              <Text fontSize="sm">where...</Text>
            </HStack>
          )}

          <ValueSelectFields
            options={getEventList() ?? []}
            value={data.property || ""}
            onChange={setProperty}
            validationError={activeErrorCheck ? data.validation_error : ""}
            loading={isLoading(fetchingProductEventKeysList)}
            isReadOnly={isReadOnly}
          />

          {data.property && operators && (
            <ValueSelectFields
              options={getOperatorsListForDisplay(
                OperatorType.STRING,
                operators
              )}
              value={(data.operator as string) || ""}
              onChange={setOperator}
              validationError={activeErrorCheck ? data.validation_error : ""}
              isReadOnly={isReadOnly}
            />
          )}

          {data.property && data.operator && operatorDetails && (
            <DynamicListValueFields
              value={data.value}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              helperText={operatorDetails.display_2}
              noOfArguments={operatorDetails.arguments}
              validationError={activeErrorCheck ? data.validation_error : ""}
              isReadOnly={isReadOnly}
            />
          )}
        </HStack>

        <Box p="1">
          {!isReadOnly && showRemoveButton && (
            <RemoveRowCloseButton onClick={onRemove} />
          )}
        </Box>
      </Flex>

      {showAddButton && data.operator && !isReadOnly && (
        <Flex w="100%" justifyContent="flex-start" pb="2">
          <AddPropertyButton
            hidden={isReadOnly}
            onClick={onAddRow}
            leftIcon={<LuMousePointerClick fontSize="12px" />}
          />
        </Flex>
      )}
    </VStack>
  );
}

export default function ProductActivityFilterGroup({
  data,
  label,
  id,
  onChange,
  onRemove,
  isReadOnly,
}: {
  data: DynamicListChild;
  label: string;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
    productEventNamesList: { data: productEventNamesList },
    operators: { data: operators },
    operatorsArgCount,
  } = useSelector(selectDynamicList);

  const { activeErrorCheck } = useContext(DynamicListContext);

  function fetchEventKeys(event: string) {
    if (
      event &&
      !productEventKeysList[event] &&
      !isLoading(fetchingProductEventKeysList)
    ) {
      const data = {
        entity: FILTER_TYPE.PERSON,
        event_names: [event],
        max_result: 10000,
      };
      dispatch(getEventKeys(data));
    }
  }

  function onTimeFrameChange(
    field: string,
    index: number,
    value: string | number | CONNECTOR | ValueTypes
  ) {
    const dataCopy = cloneDeep(data);
    switch (field) {
      case "operator":
        dataCopy.children[index].operator = value as string;
        dataCopy.children[index].value = [];
        break;
      case "value":
        dataCopy.children[index].value = value as ValueTypes;
        break;
    }
    const { filterGroup } = validateFilterGroup(
      dataCopy,
      false,
      operatorsArgCount
    );
    onChange(filterGroup);
  }

  function onActivityChange(value: string) {
    fetchEventKeys(value);
    const dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.children[0].value?.[0] !== value) {
      dataCopy.children.forEach((child: DynamicListChildL1) => {
        if (child.type === TYPE.GROUP) {
          child.children?.forEach((prop) => {
            prop.property = "";
            prop.value = [""];
            prop.operator = null;
          });
        }
      });
    }
    dataCopy.children[0].value = [value];
    if (value) {
      dataCopy.children[0].validation_error = "";
    }
    onChange(dataCopy);
  }

  function onFrequencyOperatorChange(operator: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = operator;
    dataCopy.value = [];
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function onFrequencyChange(value: ValueTypes) {
    const dataCopy = cloneDeep(data);
    dataCopy.value = value;
    dataCopy.validation_error = "";
    onChange(dataCopy);
  }

  function onRemoveFrequency() {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = null;
    dataCopy.value = null;
    onChange(dataCopy);
  }

  function onRemoveRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = dataCopy.children.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function onAddTimeFrame() {
    const dataCopy = cloneDeep(data);
    dataCopy.children.splice(1, 0, {
      type: TYPE.EXPRESSION,
      filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
      filter: "event_date",
      property: null,
      operator: null,
      value: [""],
      connector: CONNECTOR.AND,
    });
    onChange(dataCopy);
  }

  function onAddFrequency() {
    const dataCopy = cloneDeep(data);
    dataCopy.operator = "exactly";
    dataCopy.value = [0];
    onChange(dataCopy);
  }

  const timeFrameRowIndex: number | null = useMemo(() => {
    const hasTimeFrame = isArray(data.children)
      ? data.children.findIndex((child) => child.filter === "event_date")
      : null;
    return hasTimeFrame && hasTimeFrame >= 0 ? hasTimeFrame : null;
  }, [data.children]);

  function onPropertyGroupChange(newData: DynamicListChildL1[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.children = newData;
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: DynamicListChildL1 = {
      type: TYPE.GROUP,
      name: "",
      filter_type: null,
      filter: null,
      property: null,
      operator: null,
      value: null,
      children: [
        {
          // Fluffy Child
          type: TYPE.EXPRESSION,
          filter_type: FILTER_TYPE.PRODUCT_ACTIVITY,
          filter: "event_properties",
          property: "",
          operator: null,
          value: [""],
          connector: CONNECTOR.AND,
        },
      ],
      connector: CONNECTOR.AND,
    };
    dataCopy.children.push(newPropertyGroup);
    onChange(dataCopy);
  }

  function onChangeFilterGroupName(value: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.name = value;
    onChange(dataCopy);
  }

  function AddPropertyGroup() {
    return (
      <AddPropertyButton
        hidden={isReadOnly}
        leftIcon={<LuMousePointerClick fontSize="12px" />}
        onClick={onAddPropertyGroup}
        isDisabled={!data.children?.[0]?.value?.[0]}
        children="Add property group"
      />
    );
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        gridGap: "1",
      }
    : {
        px: "3",
        pt: "2",
        pb: "3",
        gridGap: "2",
        bg: "grayV2.200",
      };

  const noGroupTypeChild = useMemo(() => {
    return findFirstGroupIndex(data.children) === -1;
  }, [data.children]);

  return (
    <FilterGroupBox
      id={id}
      label={label}
      onRemove={onRemove}
      groupName={data.name}
      onGroupNameChange={onChangeFilterGroupName}
      isReadOnly={isReadOnly}
    >
      <Flex
        direction="column"
        alignItems="flex-start"
        w="100%"
        {...wrapperStyle}
      >
        {data.children && (
          <>
            <HStack
              width="100%"
              wrap="wrap"
              alignItems="flex-start"
              gridGap={isReadOnly ? undefined : "2"}
              spacing="1"
            >
              <ActivitySelector
                displayText={addEllipsisToText("performed an activity of")}
                activityList={
                  productEventNamesList?.map((x) => ({
                    label: x,
                    value: x,
                  })) || []
                }
                value={data.children[0]?.value?.[0] as string}
                onChange={(value) => onActivityChange(value)}
                validationError={
                  activeErrorCheck ? data.children[0]?.validation_error : ""
                }
                isReadOnly={isReadOnly}
                icon={FaDesktop}
              />

              {data.operator && operators && (
                <FrequencySelector
                  numberOperators={operators[OperatorType.FREQUENCY_COUNT]}
                  operator={data.operator}
                  onOperatorChange={onFrequencyOperatorChange}
                  value={data.value as ValueTypes}
                  onValueChange={onFrequencyChange}
                  isReadOnly={isReadOnly}
                  validationError={
                    activeErrorCheck ? data.validation_error : ""
                  }
                  showRemoveButton={!!(!isReadOnly && data.operator)}
                  onRemoveRow={onRemoveFrequency}
                />
              )}

              {timeFrameRowIndex && operators && (
                <>
                  <TimeFrameSelector
                    dateOperators={operators[OperatorType.AGGREGATE_TIMEFRAME]}
                    setOperator={(value) =>
                      onTimeFrameChange("operator", timeFrameRowIndex, value)
                    }
                    operator={data.children[timeFrameRowIndex]?.operator}
                    value={
                      data.children[timeFrameRowIndex]?.value as ValueTypes
                    }
                    setValue={(value) =>
                      onTimeFrameChange("value", timeFrameRowIndex, value)
                    }
                    showRemoveButton={true}
                    onRemoveRow={() => onRemoveRow(timeFrameRowIndex)}
                    validationError={
                      activeErrorCheck
                        ? data.children[timeFrameRowIndex]?.validation_error
                        : ""
                    }
                    isReadOnly={isReadOnly}
                  />
                </>
              )}
            </HStack>
            <HStack>
              {!data.operator && (
                <AddFrequencyButton
                  hidden={isReadOnly || !data.children?.[0]?.value?.[0]}
                  name={`add-frequency-${id}`}
                  onClick={onAddFrequency}
                />
              )}

              {!timeFrameRowIndex && (
                <AddTimeFrameButton
                  hidden={isReadOnly || !data.children?.[0]?.value?.[0]}
                  name={`add-timeframe-${id}`}
                  onClick={onAddTimeFrame}
                />
              )}

              {noGroupTypeChild && <AddPropertyGroup />}
            </HStack>

            <PropertyGroupContainer
              data={data.children}
              parentId={id}
              onChange={onPropertyGroupChange}
              isReadOnly={isReadOnly}
            />

            <Box hidden={noGroupTypeChild}>
              <AddPropertyGroup />
            </Box>
          </>
        )}
      </Flex>
    </FilterGroupBox>
  );
}
