import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../urls";
import {
  checkPasswordTokenIsValid,
  createPasswordRequest,
  resetPasswordRequest,
  resetLoginCreds,
  selectAccount,
  setPasswordToken,
} from "./accountSlice";
import PasswordInputs from "./PasswordInputs";
import SpinnerContainer from "../../components/SpinnerContainer";
import MessageDisplay from "../../components/MessageDisplay";
import { Box } from "@chakra-ui/react";
import { Logo } from "../../components/Logo";
import {
  TOKEN_REQUEST_TYPE,
  PASSWORD_MOD_TYPE,
} from "../../common/constants/token";

export default function CreatePassword({
  type = PASSWORD_MOD_TYPE.CREATE,
}: {
  type?: PASSWORD_MOD_TYPE;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      dispatch(setPasswordToken(token));
    }
  }, [dispatch, token]);

  const { password } = useSelector(selectAccount);

  useEffect(() => {
    if (password.token) {
      dispatch(
        checkPasswordTokenIsValid({
          token: password.token,
          request_type:
            type === PASSWORD_MOD_TYPE.CREATE
              ? TOKEN_REQUEST_TYPE.PASSWORD_CREATE
              : TOKEN_REQUEST_TYPE.PASSWORD_RESET,
        })
      );
    }
  }, [dispatch, password.token, type]);

  const goToLoginPage = useCallback(() => {
    dispatch(resetLoginCreds());
    navigate(urls.login);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (password.set) {
      goToLoginPage();
    }
  }, [password.set, goToLoginPage]);

  function createPassword(value: {
    password: string;
    confirmPassword: string;
  }) {
    const data = {
      token: password.token,
      password: value.password,
    };
    dispatch(
      type === PASSWORD_MOD_TYPE.CREATE
        ? createPasswordRequest(data)
        : resetPasswordRequest(data)
    );
  }

  return (
    <SpinnerContainer height="100vh" loading={password.tokenChecking}>
      <Box bg="grayV2.100" minH="100vh" py="12" px={{ base: "4", lg: "8" }}>
        <Box
          maxW="md"
          mx="auto"
          display="flex"
          flexDir="column"
          alignItems="center"
          marginTop={20}
        >
          <Logo mx="auto" h="7" mb={{ base: "10", md: "20" }} />
          {password.tokenValid ? (
            <PasswordInputs
              createPassword={createPassword}
              loading={password.pending}
              submitButtonText={
                type === PASSWORD_MOD_TYPE.CREATE
                  ? "Activate account"
                  : "Reset password"
              }
            />
          ) : (
            <MessageDisplay
              heading={"Link Expired"}
              displayMessage={
                type === PASSWORD_MOD_TYPE.CREATE
                  ? "Your account activation link is no longer valid"
                  : "Your password reset link is no longer valid"
              }
              redirectFn={goToLoginPage}
              redirectMsg="Go to Login page"
              type="error"
            ></MessageDisplay>
          )}
        </Box>
      </Box>
    </SpinnerContainer>
  );
}
