import { isArray } from "lodash";
import { LOGICAL_OPERATORS } from "../../../../common/constants/dynamicList";
import { ValueTypes } from "../../../../common/types/campaign";
import { isBlank } from "../../../../common/helper/commonHelper";

export function isFirstNode(logicalOperator: LOGICAL_OPERATORS) {
  return logicalOperator === LOGICAL_OPERATORS.FIRST;
}

export function getLogicalOperator(arrayLength: number): LOGICAL_OPERATORS {
  return arrayLength > 0 ? LOGICAL_OPERATORS.AND : LOGICAL_OPERATORS.FIRST;
}

export function getLogicalOperatorForTrigger(
  arrayLength: number
): LOGICAL_OPERATORS {
  return arrayLength > 0 ? LOGICAL_OPERATORS.OR : LOGICAL_OPERATORS.FIRST;
}

export function getArrayFromTargetValue(
  targetValue: string | number | ValueTypes
): ValueTypes {
  return isArray(targetValue)
    ? targetValue
    : !isBlank(targetValue)
    ? [targetValue]
    : [];
}
