import {
  Box,
  Collapse,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

export function AlertMessage({
  isOpen,
  onClose,
  alertText,
}: {
  isOpen: boolean;
  onClose: () => void;
  alertText: string;
}) {
  return (
    <Box width="100%" my="2">
      <Collapse in={isOpen}>
        <Alert status="warning" fontSize="14px">
          <AlertIcon />
          <AlertDescription>{alertText}</AlertDescription>
          <CloseButton
            position="absolute"
            right={2}
            top={2}
            onClick={onClose}
          />
        </Alert>
      </Collapse>
    </Box>
  );
}
