import { Box, Flex, HStack, VStack, Text, Divider } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  ComparisonNode,
  Condition,
  ConditionGroup,
  TargetValueTypes,
} from "../../../../../common/types/dynamicList";
import { RiMailCheckLine } from "react-icons/ri";
import {
  isLoading,
  getOperatorDetails,
} from "../../../../../common/helper/commonHelper";
import { OperatorType } from "../../../../../common/types/campaign";
import {
  MarketingActivityArgument,
  MarketingActivity,
} from "../../../../../common/types/person";
import IButton from "../../../../../components/IButton";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import {
  getTemplatesOfCampaign,
  listAllCampaigns,
  selectDynamicList,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import { useAppDispatch } from "../../../../../store";
import { selectCampaign } from "../../campaignSlice";
import NodesLogicGate from "../components/LogicGate";
import NodeGroupBox from "../components/NodeGroupBox";
import {
  COMPARISON_OPERATORS,
  DL_TARGET_VALUE_TYPES,
  LOGICAL_OPERATORS,
} from "../../../../../common/constants/dynamicList";
import { getLogicalOperator, isFirstNode } from "../helper";
import { AudienceCriteriaContext } from "../AudienceCriteria";
import AudienceCriteriaValueFields from "../components/AudienceCriteriaValueFields";
import {
  FrequencyCondition,
  TimeframeCondition,
} from "../../../../../common/types/dynamicList";
import {
  FrequencySelectorV2,
  TimeFrameSelectorV2,
} from "../components/CommonNodeCriteriaFilters";
import {
  PROPERTY_FILTER_ID,
  CHILD_FILTER_TYPE,
} from "../../../../../common/constants/campaign";
import { RxPlus } from "react-icons/rx";

enum CONDITION_FIELD_NAME {
  TEMPLATE = "template_id",
  CAMPAIGN = "campaign_id",
  REASON = "reason",
}

const IS_OPERATOR = COMPARISON_OPERATORS.EQUALS;

const VALUE_ALL = "all";

const templateListInit = [{ label: "All templates", value: VALUE_ALL }];
const campaignListInit = [{ label: "All journeys", value: VALUE_ALL }];
const templateRow: Condition = {
  logicalOperator: LOGICAL_OPERATORS.AND,
  propertyName: CONDITION_FIELD_NAME.TEMPLATE,
  comparisonOperator: null,
  targetValueType: null,
  targetValue: [],
};

function ConditionGroupsContainer({
  conditionGroups,
  eventName,
  onChange,
  isReadOnly,
}: {
  conditionGroups: ConditionGroup[];
  eventName: string;
  onChange: (data: ConditionGroup[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemoveConditionGroup(index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy.splice(index, 1);
    if (dataCopy.length) {
      dataCopy[0].logicalOperator = LOGICAL_OPERATORS.FIRST;
    }
    onChange(dataCopy);
  }

  function onChangeConditionGroup(updateData: ConditionGroup, index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  function setLogicalOperator(op: LOGICAL_OPERATORS, index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy[index].logicalOperator = op;
    onChange(dataCopy);
  }

  return (
    <>
      {conditionGroups.map((conditionGroup, index) => (
        <>
          {/* {!isReadOnly && <Divider />} */}
          <Flex w="100%" gap={isReadOnly ? "4px" : "0px"} alignItems="baseline">
            {!isFirstNode(conditionGroup.logicalOperator) && (
              <NodesLogicGate
                operator={conditionGroup.logicalOperator}
                handleChange={(op) => setLogicalOperator(op, index)}
                isReadOnly={isReadOnly}
              />
            )}
            <SingleConditionGroup
              eventName={eventName}
              conditionGroup={conditionGroup}
              onRemove={() => onRemoveConditionGroup(index)}
              onChange={(data) => onChangeConditionGroup(data, index)}
              isReadOnly={isReadOnly}
            />
          </Flex>
        </>
      ))}
    </>
  );
}

function SingleConditionGroup({
  eventName,
  conditionGroup,
  onRemove,
  onChange,
  isReadOnly,
}: {
  eventName: string;
  conditionGroup: ConditionGroup;
  onRemove: () => void;
  onChange: (data: ConditionGroup) => void;
  isReadOnly?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { campaignDetails } = useSelector(selectCampaign);

  const {
    marketingEventNamesList: { data: marketingEventNamesList },
    templateListOfCampaign,
    totalCampaignList,
  } = useSelector(selectDynamicList);

  const [templateList, setTemplateList] =
    useState<{ label: string; value: string }[]>(templateListInit);

  const campaignFilter = useMemo(
    () =>
      conditionGroup.conditions?.find(
        ({ propertyName: fieldName }) =>
          fieldName === CONDITION_FIELD_NAME.CAMPAIGN
      ),
    [conditionGroup.conditions]
  );

  const templateFilter = useMemo(
    () =>
      conditionGroup.conditions?.find(
        ({ propertyName: fieldName }) =>
          fieldName === CONDITION_FIELD_NAME.TEMPLATE
      ),
    [conditionGroup.conditions]
  );
  const reasonFilter = useMemo(
    () =>
      conditionGroup.conditions?.find(
        ({ propertyName: fieldName }) =>
          fieldName === CONDITION_FIELD_NAME.REASON
      ),
    [conditionGroup.conditions]
  );

  const templateListLoading = useMemo(() => {
    const campaignId = campaignFilter?.targetValue?.[0];
    if (campaignId) {
      const templates = templateListOfCampaign[campaignId];
      return isLoading(templates?.loading);
    }
    return false;
  }, [campaignFilter, templateListOfCampaign]);

  useEffect(() => {
    const campaignId = campaignFilter?.targetValue?.[0];
    if (
      campaignFilter?.comparisonOperator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    ) {
      const templates =
        templateListOfCampaign[campaignId]?.data.map((template) => {
          return {
            value: template.template_id,
            label: template.name,
          };
        }) ?? [];
      setTemplateList([...templateListInit, ...templates]);
    }
  }, [
    templateListOfCampaign,
    campaignFilter?.targetValue,
    campaignFilter?.comparisonOperator,
  ]);

  const campaignList: { label: string; value: string }[] = useMemo(() => {
    const data = totalCampaignList.data
      .filter(
        (item) =>
          campaignDetails.data.campaign_id !== item.campaign_id &&
          (item.activated_at || item.deactivated_at)
      )
      .map((item) => {
        return {
          label: item.name,
          value: item.campaign_id,
        };
      });
    return [...campaignListInit, ...data];
  }, [campaignDetails.data.campaign_id, totalCampaignList]);

  useEffect(() => {
    dispatch(listAllCampaigns());
  }, [dispatch]);

  useEffect(() => {
    const campaignId = campaignFilter?.targetValue?.[0] ?? "";
    if (
      campaignFilter?.comparisonOperator === IS_OPERATOR &&
      campaignId &&
      campaignId !== VALUE_ALL
    )
      dispatch(getTemplatesOfCampaign(campaignId));
  }, [
    dispatch,
    campaignFilter?.targetValue,
    campaignFilter?.comparisonOperator,
  ]);

  function onChangeHandler(newData: Condition, type: CONDITION_FIELD_NAME) {
    const dataCopy = cloneDeep(conditionGroup);

    const index = dataCopy.conditions?.findIndex(
      ({ propertyName: fieldName }) => fieldName === type
    );
    if (index !== undefined) {
      dataCopy.conditions[index] = newData;
      if (type === CONDITION_FIELD_NAME.CAMPAIGN) {
        const templateFilterIndex = dataCopy.conditions?.findIndex(
          ({ propertyName: fieldName }) =>
            fieldName === CONDITION_FIELD_NAME.TEMPLATE
        );
        if (templateFilterIndex !== undefined) {
          dataCopy.conditions[templateFilterIndex] = templateRow;
        }
      }
    }
    onChange(dataCopy);
  }

  function getArgumentDetails(type: string) {
    return eventName
      ? marketingEventNamesList
          ?.find((x) => x.id === eventName)
          ?.arguments?.find((x) => x.id === type)
      : undefined;
  }

  return (
    <Flex w="100%" flexWrap="nowrap" alignItems="center">
      <VStack spacing="1" alignItems="flex-start">
        {campaignFilter && (
          <ConditionRow
            onConditionChange={(data) =>
              onChangeHandler(data, CONDITION_FIELD_NAME.CAMPAIGN)
            }
            condition={campaignFilter}
            argumentData={getArgumentDetails(CONDITION_FIELD_NAME.CAMPAIGN)}
            argumentSelectList={campaignList}
            isReadOnly={isReadOnly}
            isListLoading={isLoading(totalCampaignList.loading)}
          />
        )}
        {templateFilter && (
          <ConditionRow
            onConditionChange={(data) =>
              onChangeHandler(data, CONDITION_FIELD_NAME.TEMPLATE)
            }
            condition={templateFilter}
            argumentData={getArgumentDetails(CONDITION_FIELD_NAME.TEMPLATE)}
            argumentSelectList={templateList}
            isReadOnly={isReadOnly}
            isListLoading={templateListLoading}
          />
        )}
        {reasonFilter && (
          <ConditionRow
            onConditionChange={(data) =>
              onChangeHandler(data, CONDITION_FIELD_NAME.REASON)
            }
            condition={reasonFilter}
            argumentData={getArgumentDetails(CONDITION_FIELD_NAME.REASON)}
            isReadOnly={isReadOnly}
          />
        )}
      </VStack>
      {!isReadOnly && <RemoveRowCloseButton onClick={onRemove} />}
    </Flex>
  );
}

function ConditionRow({
  condition,
  onConditionChange,
  argumentData,
  argumentSelectList,
  isReadOnly,
  isListLoading = false,
}: {
  condition: Condition;
  onConditionChange: (data: Condition) => void;
  argumentData?: MarketingActivityArgument;
  argumentSelectList?: { label: string; value: string }[];
  isReadOnly?: boolean;
  isListLoading?: boolean;
}) {
  const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        condition.comparisonOperator,
        operators,
        argumentData?.data_type
      ),
    [condition.comparisonOperator, operators, argumentData]
  );

  function setOperator(operator: COMPARISON_OPERATORS | null) {
    const dataCopy = cloneDeep(condition);
    dataCopy.comparisonOperator = operator;
    dataCopy.targetValueType = DL_TARGET_VALUE_TYPES.STRING;
    dataCopy.targetValue = [];
    onConditionChange(dataCopy);
  }

  function handleValueChange(val: TargetValueTypes) {
    const dataCopy = cloneDeep(condition);
    dataCopy.targetValue = val;
    onConditionChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
      }
    : {
        py: "2",
        px: "3",
      };

  const isInvalidOperator = activeErrorCheck && !condition.comparisonOperator;

  if (argumentData) {
    return (
      <HStack alignItems="center" wrap="wrap" {...wrapperStyle}>
        {isReadOnly ? (
          <Text fontSize="sm" textAlign="right">
            {argumentData.display}
          </Text>
        ) : (
          <HStack h="33px">
            <Text fontSize="sm" textAlign="right">
              {`...${argumentData.display}...`}
            </Text>
          </HStack>
        )}
        {operators && (
          <Box>
            <ValueSelectFields
              options={Object.values(operators[argumentData.data_type]).map(
                (op) => ({
                  label: op.display,
                  value: op.id,
                })
              )}
              value={condition.comparisonOperator || ""}
              onChange={(option) => setOperator(option as COMPARISON_OPERATORS)}
              validationError={isInvalidOperator ? "Invalid operator" : ""}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}
        {condition.comparisonOperator && operatorDetails && (
          <HStack>
            <AudienceCriteriaValueFields
              value={condition.targetValue}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              argumentSelectList={
                condition.comparisonOperator === IS_OPERATOR
                  ? argumentSelectList
                  : undefined
              }
              noOfArguments={operatorDetails.arguments}
              helperText={operatorDetails.display_2}
              isReadOnly={isReadOnly}
              isLoading={isListLoading}
              activeErrorCheck={activeErrorCheck}
              filter={condition.propertyName as PROPERTY_FILTER_ID}
            />
          </HStack>
        )}
      </HStack>
    );
  }

  return <></>;
}

function ActivitySelector({
  marketingActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  marketingActivityList: MarketingActivity[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && (
        <Text fontSize="sm">...performed a marketing activity of...</Text>
      )}
      <HStack id="marketing-activity-selector">
        <ValueSelectFields
          options={marketingActivityList?.map((x) => ({
            label: x.display,
            value: x.id,
          }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </HStack>
    </HStack>
  );
}

const MarketingActivityNodeCriteria = React.memo(
  ({
    nodeData,
    label,
    id,
    onChange,
    onRemove,
    isReadOnly,
  }: {
    nodeData: ComparisonNode;
    label: string;
    id: string;
    onChange: (data: ComparisonNode) => void;
    onRemove: () => void;
    isReadOnly?: boolean;
  }) => {
    const { marketingEventNamesList } = useSelector(selectDynamicList);

    const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

    function onActivityChange(value: string) {
      let dataCopy = cloneDeep(nodeData);
      // reset data in condition groups when node group activity changes
      if (dataCopy.eventName !== value) {
        dataCopy.conditionGroups = [];
        dataCopy.frequencyCondition = null;
        dataCopy.timeframe = null;
      }
      dataCopy.eventName = value;

      onChange(dataCopy);
    }

    function onFrequencyChange(frequency: FrequencyCondition | null) {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.frequencyCondition = frequency;
      onChange(dataCopy);
    }

    function onConditionGroupChange(conditionGroups: ConditionGroup[]) {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.conditionGroups = conditionGroups;
      onChange(dataCopy);
    }

    function onAddConditionGroup() {
      const dataCopy = cloneDeep(nodeData);
      const newConditionGroup: ConditionGroup = {
        logicalOperator: getLogicalOperator(dataCopy.conditionGroups.length),
        conditions: [
          {
            logicalOperator: LOGICAL_OPERATORS.FIRST,
            propertyName: CONDITION_FIELD_NAME.CAMPAIGN,
            comparisonOperator: null,
            targetValueType: null,
            targetValue: null,
          },
        ],
      };

      const templateCondition: Condition = {
        logicalOperator: LOGICAL_OPERATORS.AND,
        propertyName: CONDITION_FIELD_NAME.TEMPLATE,
        comparisonOperator: null,
        targetValueType: null,
        targetValue: null,
      };

      const reasonCondition: Condition = {
        logicalOperator: LOGICAL_OPERATORS.AND,
        propertyName: CONDITION_FIELD_NAME.REASON,
        comparisonOperator: null,
        targetValueType: null,
        targetValue: null,
      };

      if (getEventList()?.includes(CONDITION_FIELD_NAME.TEMPLATE)) {
        newConditionGroup?.conditions?.push(templateCondition);
      }
      if (getEventList()?.includes(CONDITION_FIELD_NAME.REASON)) {
        newConditionGroup?.conditions?.push(reasonCondition);
      }

      dataCopy.conditionGroups.push(newConditionGroup);
      onChange(dataCopy);
    }

    //time frame changes

    const hasTimeFrame = !!nodeData.timeframe;

    function onAddTimeFrame() {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.timeframe = {
        type: null,
        operator: null,
        unit: null,
        start: null,
        end: null,
      };
      onChange(dataCopy);
    }

    function onTimeFrameChange(timeFrame: TimeframeCondition | null) {
      const dataCopy = cloneDeep(nodeData);

      dataCopy.timeframe = timeFrame;

      onChange(dataCopy);
    }

    function getEventList() {
      return nodeData.eventName
        ? marketingEventNamesList.data
            ?.find((x) => x.id === nodeData.eventName)
            ?.arguments?.map((x) => x.id)
        : [];
    }

    const wrapperStyle = isReadOnly
      ? {
          px: "0",
          py: "0",
          gridGap: "1",
        }
      : {
          px: "3",
          py: "2",
          gridGap: "3",
          bg: "grayV2.200",
        };
    const isInvalidEventName = activeErrorCheck && !nodeData.eventName;

    return (
      <NodeGroupBox
        id={id}
        nodeName={label}
        onRemove={onRemove}
        icon={RiMailCheckLine}
        isReadOnly={isReadOnly}
      >
        <VStack alignItems="flex-start" {...wrapperStyle}>
          <>
            <HStack
              width="100%"
              wrap="wrap"
              alignItems="flex-start"
              gridGap={isReadOnly ? undefined : "2"}
            >
              <ActivitySelector
                marketingActivityList={marketingEventNamesList.data || []}
                value={nodeData.eventName || ""}
                onChange={(value) => onActivityChange(value)}
                validationError={isInvalidEventName ? "Invalid event" : ""}
                isReadOnly={isReadOnly}
              />

              {getEventList()?.includes(CHILD_FILTER_TYPE.FREQUENCY) && (
                <FrequencySelectorV2
                  frequencyCondition={nodeData.frequencyCondition ?? null}
                  onFrequencyChange={onFrequencyChange}
                  numberOperators={operators[OperatorType.INTEGER]}
                  isReadOnly={isReadOnly}
                  activeErrorCheck={activeErrorCheck}
                  buttonProps={{ hidden: !nodeData.eventName }}
                />
              )}
            </HStack>
            {!isReadOnly &&
              getEventList()?.includes(CHILD_FILTER_TYPE.TIME_FRAME) &&
              !hasTimeFrame && (
                <IButton
                  hidden={!nodeData.eventName}
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={onAddTimeFrame}
                  variant="link"
                  color="brand.blue"
                  mt="5px !important"
                  fontWeight="normal"
                >
                  Add time frame
                </IButton>
              )}

            {getEventList()?.includes(CHILD_FILTER_TYPE.TIME_FRAME) &&
              hasTimeFrame && (
                <>
                  {!isReadOnly && <Divider />}
                  <TimeFrameSelectorV2
                    validationError={activeErrorCheck ? "" : ""}
                    isReadOnly={isReadOnly}
                    timeFrame={
                      nodeData.timeframe ?? {
                        type: null,
                        operator: null,
                        unit: null,
                        start: null,
                        end: null,
                      }
                    }
                    onChangeTimeFrame={onTimeFrameChange}
                    activeErrorCheck={activeErrorCheck}
                    showRemoveButton={true}
                  />
                </>
              )}
            {!isReadOnly && nodeData.conditionGroups.length ? (
              <HStack position="relative" ml="24px !important">
                <Divider
                  orientation="vertical"
                  h="100%"
                  mt="4px"
                  position="absolute"
                  borderColor="brandBlue.500"
                />
                <Box ml={3}>
                  <ConditionGroupsContainer
                    eventName={nodeData.eventName ?? ""}
                    conditionGroups={nodeData.conditionGroups}
                    onChange={onConditionGroupChange}
                    isReadOnly={isReadOnly}
                  />
                </Box>
              </HStack>
            ) : (
              <HStack position="relative">
                <Divider
                  orientation="vertical"
                  h="100%"
                  position="absolute"
                  ml="-16px"
                  mb="4px"
                  borderColor="brandBlue.500"
                />
                <Box ml="0px !important">
                  <ConditionGroupsContainer
                    eventName={nodeData.eventName ?? ""}
                    conditionGroups={nodeData.conditionGroups}
                    onChange={onConditionGroupChange}
                    isReadOnly={isReadOnly}
                  />
                </Box>
              </HStack>
            )}

            {!isReadOnly && (
              <IButton
                size="sm"
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddConditionGroup}
                isDisabled={!nodeData.eventName}
                variant="link"
                color="brand.blue"
                mt="5px"
                fontWeight="400"
              >
                Add new property
              </IButton>
            )}
          </>
        </VStack>
      </NodeGroupBox>
    );
  }
);

export default MarketingActivityNodeCriteria;
