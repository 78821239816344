import {
  Box,
  HStack,
  VStack,
  Text,
  Divider,
  useMediaQuery,
  Stack,
  Icon,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { FaDesktop } from "react-icons/fa";
import { useSelector } from "react-redux";
import { FILTER_TYPE } from "../../../../../common/constants/campaign";
import {
  createGroupId,
  getOperatorDetails,
  isInit,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import { OperatorType } from "../../../../../common/types/campaign";
import AddPropertyButton from "../../../../../components/dynamic-list/AddPropertyButton";
import {
  selectDynamicList,
  getEventKeys,
  getEventNames,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import AudienceCriteriaValueFields from "../../Audience-criteria/components/AudienceCriteriaValueFields";
import IButton from "../../../../../components/IButton";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import { useAppDispatch } from "../../../../../store";
import { FilterGroupBox } from "./FilterGroupBox";
import {
  ComparisonNode,
  ConditionGroup,
  Condition,
  TargetValueTypes,
  Pattern,
} from "../../../../../common/types/dynamicList";
import {
  COMPARISON_OPERATORS,
  DL_TARGET_VALUE_TYPES,
} from "../../../../../common/constants/dynamicList";
import { TriggerCriteriaContext } from "../../TriggerCriteriaList";
import { getLogicalOperatorForTrigger } from "../../Audience-criteria/helper";
import { RxDragHandleDots2 } from "react-icons/rx";
import { RxPlus } from "react-icons/rx";

function PropertyGroupRow({
  data,
  mainFilter,
  onChange,
  showRemoveButton,
  onRemove,
  showAddButton,
  onAddRow,
  isReadOnly,
}: {
  data: Condition;
  mainFilter: string | null;
  onChange: (data: Condition) => void;
  showRemoveButton: boolean;
  onRemove: () => void;
  showAddButton: boolean;
  onAddRow: () => void;
  isReadOnly?: boolean;
}) {
  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
  } = useSelector(selectDynamicList);

  const { operators, activeErrorCheck } = useContext(TriggerCriteriaContext);

  const isInvalidPropertyName = activeErrorCheck && !data.propertyName;
  const isInvalidOperator = activeErrorCheck && !data.comparisonOperator;

  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        data.comparisonOperator,
        operators,
        OperatorType.STRING
      ),
    [data.comparisonOperator, operators]
  );

  function setProperty(newProperty: string) {
    const dataCopy = cloneDeep(data);
    dataCopy.propertyName = newProperty;
    dataCopy.comparisonOperator = null;
    dataCopy.targetValueType = null;
    dataCopy.targetValue = [];
    onChange(dataCopy);
  }

  function setOperator(operator: COMPARISON_OPERATORS) {
    const dataCopy = cloneDeep(data);
    dataCopy.comparisonOperator = operator;
    dataCopy.targetValueType = DL_TARGET_VALUE_TYPES.STRING;
    dataCopy.targetValue = [];
    onChange(dataCopy);
  }

  function handleValueChange(val: TargetValueTypes) {
    const dataCopy = cloneDeep(data);
    dataCopy.targetValue = val;
    onChange(dataCopy);
  }

  function getEventList() {
    return mainFilter
      ? productEventKeysList[mainFilter]?.map((x) => ({
          label: x,
          value: x,
        }))
      : [];
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        spacing: "1",
      }
    : {
        spacing: "3",
        py: "2",
        px: "3",
        gridGap: "2",
      };
  return (
    <HStack alignItems="flex-start" width="100%" wrap="wrap" {...wrapperStyle}>
      <Box>
        <ValueSelectFields
          options={getEventList() ?? []}
          value={data.propertyName || null}
          onChange={setProperty}
          validationError={isInvalidPropertyName ? "Invalid property" : ""}
          loading={isLoading(fetchingProductEventKeysList)}
          isReadOnly={isReadOnly}
        />
      </Box>
      {data.propertyName && operators && (
        <Box>
          <ValueSelectFields
            options={Object.values(operators[OperatorType.STRING]).map(
              (op) => ({
                label: op.display,
                value: op.id,
              })
            )}
            value={(data.comparisonOperator as COMPARISON_OPERATORS) || ""}
            onChange={(data: string) =>
              setOperator(data as COMPARISON_OPERATORS)
            }
            validationError={isInvalidOperator ? "Invalid operator" : ""}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}
      {data.propertyName && data.comparisonOperator && operatorDetails && (
        <HStack>
          <AudienceCriteriaValueFields
            value={data.targetValue}
            onChange={handleValueChange}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            noOfArguments={operatorDetails.arguments}
            activeErrorCheck={activeErrorCheck}
            isReadOnly={isReadOnly}
          />
        </HStack>
      )}

      {!isReadOnly && showAddButton && data.comparisonOperator && (
        <AddPropertyButton iconOnly={isSmallerThan1024} onClick={onAddRow} />
      )}

      {!isReadOnly && showRemoveButton && (
        <RemoveRowCloseButton onClick={onRemove} />
      )}
    </HStack>
  );
}

function PropertyGroup({
  mainFilter,
  data,
  id,
  onRemove,
  onChange,
  isReadOnly,
}: {
  mainFilter: string | null;
  data: ConditionGroup;
  label: string;
  id: string;
  onRemove: () => void;
  onChange: (data: ConditionGroup) => void;
  isReadOnly?: boolean;
}) {
  function addRow() {
    const newRow: Condition = {
      logicalOperator: getLogicalOperatorForTrigger(data.conditions.length),
      propertyName: null,
      comparisonOperator: null,
      targetValueType: null,
      targetValue: [],
    };
    const dataCopy = cloneDeep(data);
    if (dataCopy.conditions) {
      dataCopy.conditions.push(newRow);
    } else {
      dataCopy.conditions = [newRow];
    }
    onChange(dataCopy);
  }

  function removeRow(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.conditions?.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangeHandler(newData: Condition, index: number) {
    if (data.conditions) {
      const dataCopy = cloneDeep(data);
      (dataCopy.conditions as Condition[])[index] = newData;
      onChange(dataCopy);
    }
  }

  if (isReadOnly) {
    return (
      <VStack color="text.50" fontWeight="600" fontSize="12px" spacing="0">
        {data.conditions?.map((child: Condition, index: number) => {
          return (
            <PropertyGroupRow
              mainFilter={mainFilter}
              onRemove={() => removeRow(index)}
              onChange={(child) => onChangeHandler(child, index)}
              data={child}
              onAddRow={addRow}
              showAddButton={false}
              showRemoveButton={false}
              isReadOnly={isReadOnly}
            />
          );
        })}
      </VStack>
    );
  } else {
    return (
      <FilterGroupBox onRemove={onRemove} id={id} icon={FaDesktop}>
        <VStack divider={<Divider />} spacing="0">
          {data.conditions?.map((child: Condition, index: number) => {
            return (
              <PropertyGroupRow
                mainFilter={mainFilter}
                onRemove={() => removeRow(index)}
                onChange={(data) => onChangeHandler(data, index)}
                data={child}
                onAddRow={addRow}
                showAddButton={false}
                showRemoveButton={false}
              />
            );
          })}
        </VStack>
      </FilterGroupBox>
    );
  }
}

function PropertyGroupContainer({
  eventName,
  data,
  parentId,
  onChange,
  isReadOnly,
}: {
  eventName: string | null;
  data: ConditionGroup[];
  parentId: string;
  onChange: (data: ConditionGroup[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemovePropertyGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.splice(index, 1);
    onChange(dataCopy);
  }

  function onChangePropertyGroup(updateData: ConditionGroup, index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
        ml: "0px !important",
      }
    : {
        px: "12px",
        ml: "12px !important",
        borderLeftWidth: "1px",
        borderLeftColor: "grayV2.500",
      };

  return (
    <VStack
      alignItems="left"
      {...wrapperStyle}
      mt={!isReadOnly && data.length ? "12px !important" : "0px !important"}
    >
      {data.map((item, index) => (
        <>
          {index === 0 && !isReadOnly && (
            <Text fontSize="12px" color="brand.blue">
              where all the properties apply
            </Text>
          )}
          <PropertyGroup
            mainFilter={eventName}
            data={item}
            id={createGroupId(parentId, "filter", index - 0 + 1)}
            label={`Property group ${index - 0 + 1}`}
            onRemove={() => onRemovePropertyGroup(index)}
            onChange={(data) => onChangePropertyGroup(data, index)}
            isReadOnly={isReadOnly}
          />
        </>
      ))}
    </VStack>
  );
}

function ActivitySelector({
  productActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  productActivityList: string[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && <Text fontSize="sm">Product event</Text>}
      <Box id="product-activity-selector">
        <ValueSelectFields
          options={productActivityList?.map((x) => ({ label: x, value: x }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </Box>
    </HStack>
  );
}

export default function ProductEventGroup({
  data,
  id,
  onChange,
  onRemove,
  isReadOnly,
  isDraggable,
}: {
  data: ComparisonNode | Pattern;
  id: string;
  onChange: (data: any) => void;
  onRemove: () => void;
  isReadOnly?: boolean;
  isDraggable?: boolean;
}) {
  const dispatch = useAppDispatch();

  const { activeErrorCheck } = useContext(TriggerCriteriaContext);

  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
    productEventNamesList: {
      data: productEventNamesList,
      loading: fetchingProductEventNamesList,
    },
  } = useSelector(selectDynamicList);

  useEffect(() => {
    if (!productEventNamesList && isInit(fetchingProductEventNamesList)) {
      const data = {
        max_result: 10000,
        entity: FILTER_TYPE.PERSON,
      };
      dispatch(getEventNames(data));
    }
  }, [dispatch, productEventNamesList, fetchingProductEventNamesList]);

  function fetchEventKeys(event: string) {
    if (
      event &&
      !productEventKeysList[event] &&
      !isLoading(fetchingProductEventKeysList)
    ) {
      const data = {
        entity: FILTER_TYPE.PERSON,
        event_names: [event],
        max_result: 10000,
      };
      dispatch(getEventKeys(data));
    }
  }

  function onActivityChange(value: string) {
    fetchEventKeys(value);
    const dataCopy = cloneDeep(data);
    // reset data in property groups when filter group activity changes
    if (dataCopy.eventName !== value) {
      dataCopy.conditionGroups = [];
    }
    dataCopy.eventName = value;
    onChange(dataCopy);
  }

  function onPropertyGroupChange(newData: ConditionGroup[]) {
    const dataCopy = cloneDeep(data);
    dataCopy.conditionGroups = newData;
    onChange(dataCopy);
  }

  function onAddPropertyGroup() {
    const dataCopy = cloneDeep(data);
    const newPropertyGroup: ConditionGroup = {
      conditionGroupId: "",
      logicalOperator: getLogicalOperatorForTrigger(
        data.conditionGroups.length
      ),
      conditions: [
        {
          logicalOperator: getLogicalOperatorForTrigger(
            data.conditionGroups.length
          ),
          propertyName: null,
          comparisonOperator: null,
          targetValueType: null,
          targetValue: [],
        },
      ],
    };
    dataCopy.conditionGroups.push(newPropertyGroup);
    onChange(dataCopy);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
      }
    : {
        px: "3",
        py: "3",
        spacing: "0",
      };

  const isInvalidEventName = activeErrorCheck && !data.eventName;

  return (
    <FilterGroupBox
      id={id}
      onRemove={onRemove}
      icon={FaDesktop}
      isReadOnly={isReadOnly}
      rounded="lg"
      borderColor="grayV2.200"
      borderWidth="1px"
      bg="grayV2.200"
      hideRemove={true}
    >
      <HStack>
        {!isReadOnly && isDraggable && (
          <Icon cursor="grab" ml={4} as={RxDragHandleDots2} />
        )}
        <VStack alignItems="flex-start" {...wrapperStyle}>
          <>
            <HStack
              width="100%"
              wrap="wrap"
              gridGap={isReadOnly ? undefined : "2"}
              spacing="1"
            >
              <ActivitySelector
                productActivityList={productEventNamesList || []}
                value={data.eventName as string}
                onChange={(value) => onActivityChange(value)}
                validationError={isInvalidEventName ? "Invalid event" : ""}
                isReadOnly={isReadOnly}
              />
              {!isReadOnly && !data.conditionGroups[0]?.conditions.length && (
                <IButton
                  size="sm"
                  variant="link"
                  leftIcon={<RxPlus fontSize="12px" />}
                  onClick={onAddPropertyGroup}
                  isDisabled={!data.eventName}
                  name="add-property-group"
                  color="brand.blue"
                  fontWeight="normal"
                >
                  Add property
                </IButton>
              )}
              {!isReadOnly && (
                <RemoveRowCloseButton onClick={onRemove}></RemoveRowCloseButton>
              )}
            </HStack>
            {isReadOnly ? (
              <Stack direction="row" position="relative">
                <Divider
                  orientation="vertical"
                  h="100%"
                  ml="-16px"
                  position="absolute"
                  borderColor="brandBlue.500"
                ></Divider>
                <PropertyGroupContainer
                  eventName={data.eventName}
                  data={data.conditionGroups}
                  parentId={id}
                  onChange={onPropertyGroupChange}
                  isReadOnly={isReadOnly}
                />
              </Stack>
            ) : (
              <PropertyGroupContainer
                eventName={data.eventName}
                data={data.conditionGroups}
                parentId={id}
                onChange={onPropertyGroupChange}
                isReadOnly={isReadOnly}
              />
            )}
            {!isReadOnly && data.conditionGroups[0]?.conditions.length && (
              <IButton
                size="sm"
                variant="link"
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddPropertyGroup}
                isDisabled={!data.eventName}
                name="add-property-group"
                color="brand.blue"
                ml="24px !important"
                mt="8px !important"
                fontWeight="normal"
              >
                Add additional property
              </IButton>
            )}
          </>
        </VStack>
      </HStack>
    </FilterGroupBox>
  );
}
