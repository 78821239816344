import {
  TRIGGER_DL_NODE_TYPES,
  EVENT_CATEGORY,
  LOGICAL_OPERATORS,
} from "../constants/dynamicList";
import { NodeCriteriaTypes } from "../types/dynamicList";
import { NODE_GROUP_TYPES } from "../constants/dynamicList";

export function fillFirstNodeData(
  nodeType: TRIGGER_DL_NODE_TYPES,
  eventCategory?: EVENT_CATEGORY
): NodeCriteriaTypes {
  const INIT_NODE_DATA = {
    logicalOperator: LOGICAL_OPERATORS.FIRST,
  };

  switch (nodeType) {
    case TRIGGER_DL_NODE_TYPES.PERSON:
      return {
        ...INIT_NODE_DATA,
        eventName: null,
        nodeType,
        conditionGroups: [
          {
            logicalOperator: LOGICAL_OPERATORS.FIRST,
            conditions: [
              {
                logicalOperator: LOGICAL_OPERATORS.FIRST,
                comparisonOperator: null,
                propertyName: null,
                targetValueType: null,
                targetValue: null,
              },
            ],
          },
        ],
      };
    case TRIGGER_DL_NODE_TYPES.LIST_MEMBERSHIP:
      return {
        ...INIT_NODE_DATA,
        nodeType,
        listMembershipCondition: {
          membershipOperator: null,
          listType: null,
          listId: null,
        },
      };
    case TRIGGER_DL_NODE_TYPES.COMPARISON:
      return {
        ...INIT_NODE_DATA,
        nodeType,
        eventName: null,
        timeframe: null,
        frequencyCondition: null,
        conditionGroups: [],
        eventCategory,
      };
    case TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN:
      return {
        ...INIT_NODE_DATA,
        nodeType,
        eventCategory,
        patternGroup: [
          {
            eventName: null,
            conditionGroups: [],
            patternOperators: [],
          },
        ],
      };
  }
}

export function isGroupNode(nodeGroupType: NODE_GROUP_TYPES) {
  return [NODE_GROUP_TYPES.ORGANISATION, NODE_GROUP_TYPES.ACCOUNT].includes(
    nodeGroupType
  );
}
