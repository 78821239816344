import { Checkbox, VStack, Text, Box } from "@chakra-ui/react";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FILTER_TYPE } from "../../../../common/constants/campaign";
import {
  NEW_EXIT_GROUP,
  NODE_GROUP_TYPES,
} from "../../../../common/constants/dynamicList";
import { CAMPAIGN_CONTEXT } from "../../../../common/types/campaign";
import { DynamicListContext } from "../../../../components/dynamic-list/DynamicList";
import {
  selectDynamicList,
  getEventKeys,
} from "../../../../components/dynamic-list/dynamicListSlice";
import { useAppDispatch } from "../../../../store";
import { FaUserMinus } from "react-icons/fa";
import {
  SectionItem,
  SectionLink,
} from "../sidebar/sections/components/SectionWrapper";
import { NodeGroupWithComparison } from "../../../../common/types/dynamicList";
import TriggerOuterGroup from "./components/TriggerOuterGroup";
import { TRIGGER_CAMPAIGN_DL_OPERATORS } from "../../../../common/constants/dynamicList";
import { createContext } from "react";
import { OperatorListV2 } from "../../../../common/types/dynamicList";

function CheckboxItem({
  checked,
  onChange,
  label,
  disabled,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  disabled?: boolean;
}) {
  return (
    <>
      <Checkbox
        isChecked={checked}
        onChange={(e) => onChange(e.target.checked)}
        isDisabled={disabled}
      >
        <Text fontSize="sm">{label}</Text>
      </Checkbox>
    </>
  );
}

function ExitCriteriaOptions({
  isReadOnly,
  isChecked,
  checkOption,
  showExtraOption,
  onOpen,
}: {
  isReadOnly: boolean;
  isChecked: boolean;
  showExtraOption: boolean;
  checkOption: (checked: boolean) => void;
  onOpen: () => void;
}) {
  if (isReadOnly) {
    return <Box w="100%">{showExtraOption ? <></> : <></>}</Box>;
  } else {
    return (
      <>
        <CheckboxItem
          checked={true}
          onChange={() => {}}
          disabled={true}
          label="They complete the journey"
        />
        <CheckboxItem
          checked={isChecked}
          onChange={(checked) => checkOption(checked)}
          label="When any of the following events occur"
        />
      </>
    );
  }
}

export const TriggerExitContext = createContext({
  operators: TRIGGER_CAMPAIGN_DL_OPERATORS,
  activeErrorCheck: false,
});

export default function TriggerExitCriteria({
  data,
  onChange,
  isReadOnly,
  showCheckOptions,
  activeErrorCheck = false,
  onOpen,
  errorText,
  campaignContext = CAMPAIGN_CONTEXT.PERSON,
  operators = TRIGGER_CAMPAIGN_DL_OPERATORS,
}: {
  data: NodeGroupWithComparison;
  onChange: (data: NodeGroupWithComparison) => void;
  isReadOnly: boolean;
  showCheckOptions?: boolean;
  activeErrorCheck?: boolean;
  onOpen?: any;
  errorText?: string;
  campaignContext?: CAMPAIGN_CONTEXT;
  operators?: OperatorListV2;
}) {
  const dispatch = useAppDispatch();
  const [whenEventsOccur, setWhenEventsOccur] = useState(false);

  useEffect(() => {
    setWhenEventsOccur(!!data || !showCheckOptions);
  }, [data, showCheckOptions]);

  function handleCheckboxChange(val: boolean) {
    if (!val && data) {
      setWhenEventsOccur(false);
      onChange(NEW_EXIT_GROUP);
    } else if (val && !data) {
      const outerGroup = cloneDeep(NEW_EXIT_GROUP);
      onChange(outerGroup);
    }
  }
  const hasOnceRun = useRef(false);

  const { productEventKeysList } = useSelector(selectDynamicList);

  useEffect(() => {
    if (data && !hasOnceRun.current) {
      // Fetch product_event_properties list for all product_event_names in the campaign dynamiclist get
      // create an array of event names in the product activity filter group
      const eventNamesArray: string[][] = [];
      if (data.nodeGroupType === NODE_GROUP_TYPES.INDIVIDUAL) {
        const eventNames: (string | null)[] = data.nodes
          ?.filter((node) => node.eventCategory === "PRODUCT_ACTIVITY_EVENT")
          .map((node) => node.eventName);
        eventNamesArray.push(eventNames.filter((x) => x) as string[]);
      }
      const eventKeysAlreadyFetched = Object.keys(
        productEventKeysList.data || {}
      );
      const eventKeysToBeFetched = eventNamesArray
        .flat()
        .filter((event) => !eventKeysAlreadyFetched.includes(event));

      if (!isEmpty(eventKeysToBeFetched)) {
        dispatch(
          getEventKeys({
            max_result: 1000,
            entity: FILTER_TYPE.PERSON,
            event_names: eventKeysToBeFetched,
          })
        );
      }
      hasOnceRun.current = true;
    }
  }, [data, dispatch, productEventKeysList.data]);

  return (
    <VStack spacing={isReadOnly ? 0 : 2} alignItems="flex-start" w="100%">
      {showCheckOptions && (
        <ExitCriteriaOptions
          isReadOnly={isReadOnly}
          isChecked={whenEventsOccur}
          checkOption={(checked) => handleCheckboxChange(checked)}
          showExtraOption={!!data}
          onOpen={onOpen}
        />
      )}
      {whenEventsOccur && (!isReadOnly || (data && data.nodes?.length)) && (
        <DynamicListContext.Provider
          value={{
            activeErrorCheck,
            campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
          }}
        >
          <SectionItem
            infoText={
              "Remove a contact from the workflow when completes the workflow if any of the following events occur"
            }
            hideInfo={!isReadOnly}
            errorText={activeErrorCheck ? errorText || "" : ""}
          >
            <Box w="100%">
              <TriggerExitContext.Provider
                value={{ operators, activeErrorCheck: activeErrorCheck }}
              >
                <TriggerOuterGroup
                  data={data ?? NEW_EXIT_GROUP}
                  onChange={onChange}
                  isReadOnly={isReadOnly}
                />
              </TriggerExitContext.Provider>
            </Box>
          </SectionItem>
        </DynamicListContext.Provider>
      )}
      {isReadOnly && !data?.nodes?.length && (
        <SectionLink
          onOpen={onOpen}
          title="Click to set up exit criteria"
          LinkIcon={FaUserMinus}
          errorText={errorText}
        />
      )}
    </VStack>
  );
}
