import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
  useClipboard,
} from "@chakra-ui/react";
import { selectAccount } from "../../account/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Formik } from "formik";
import InputFieldWithError from "../../../components/InputFieldWithError";
import {
  requiredString,
  urlValidation,
} from "../../../common/helper/validationHelper";
import { object } from "yup";
import {
  createSamlConfig,
  getSamlConfig,
  selectSettings,
  updateSamlConfig,
} from "./settingsSlice";
import IButton, { BUTTON } from "../../../components/IButton";
import { CommonListHeader } from "../../../components/CommonListHeader";
import SubHeader from "./components/SubHeader";
import ContentContainer from "../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";

export default function SsoConfiguration() {
  const dispatch = useDispatch();

  const { userOrg } = useSelector(selectAccount);
  const { samlConfig } = useSelector(selectSettings);

  const orgSlug = useMemo(() => userOrg.slug, [userOrg.slug]);
  const { hasCopied, onCopy } = useClipboard(orgSlug);

  useEffect(() => {
    dispatch(getSamlConfig());
  }, [dispatch]);

  const oktaFormSchema = object().shape({
    sso_url: urlValidation("Single Sign on URL"),
    entity_id: requiredString("IDP Issuer"),
    certificate: requiredString("Certificate"),
  });

  return (
    <ContentContainerWithHeader>
      <CommonListHeader heading="" />
      <ContentContainer>
        <Formik
          enableReinitialize={true}
          initialValues={{
            slug: orgSlug,
            id: samlConfig.id,
            sso_url: samlConfig.sso_url,
            entity_id: samlConfig.entity_id,
            certificate: samlConfig.certificate,
          }}
          onSubmit={({ id, sso_url, entity_id, certificate }) => {
            const config = { id, sso_url, entity_id, certificate };
            if (id) {
              dispatch(updateSamlConfig(config));
            } else {
              dispatch(createSamlConfig(config));
            }
          }}
          validationSchema={oktaFormSchema}
        >
          {(settingsForm) => (
            <form id="settings-form" onSubmit={settingsForm.handleSubmit}>
              <SubHeader
                title="Single sign-on"
                desc=""
                stackProps={{ width: "50%", mb: "3" }}
              >
                <FormControl id="slug">
                  <FormLabel fontSize={14}>Organization slug</FormLabel>
                  <Flex>
                    <Input
                      type="text"
                      isReadOnly
                      disabled
                      defaultValue={settingsForm.values.slug}
                      fontSize={14}
                    />
                    <IButton onClick={onCopy} ml={2} name="copy">
                      {hasCopied ? "Copied" : "Copy"}
                    </IButton>
                  </Flex>
                </FormControl>

                <InputFieldWithError
                  labelText="Single sign-on URL"
                  onChange={settingsForm.handleChange}
                  name="sso_url"
                  value={settingsForm.values.sso_url}
                  errorMsg={settingsForm.errors.sso_url}
                  touched={!!settingsForm.touched.sso_url}
                  onBlur={settingsForm.handleBlur}
                  py={0}
                  formLabelProps={{ fontSize: "14" }}
                />

                <InputFieldWithError
                  labelText="IDP issuer"
                  onChange={settingsForm.handleChange}
                  name="entity_id"
                  value={settingsForm.values.entity_id}
                  errorMsg={settingsForm.errors.entity_id}
                  touched={!!settingsForm.touched.entity_id}
                  onBlur={settingsForm.handleBlur}
                  py={0}
                  formLabelProps={{ fontSize: "14" }}
                />

                <FormControl
                  id="certificate"
                  isInvalid={
                    !!(
                      settingsForm.errors.certificate &&
                      settingsForm.touched.certificate
                    )
                  }
                >
                  <FormLabel fontSize={14}>Certificate</FormLabel>
                  <Textarea
                    rows={8}
                    name="certificate"
                    onChange={settingsForm.handleChange}
                    onBlur={settingsForm.handleBlur}
                    fontSize={14}
                    value={settingsForm.values.certificate}
                  />
                  <FormErrorMessage fontSize={12}>
                    {settingsForm.errors.certificate}
                  </FormErrorMessage>
                </FormControl>
              </SubHeader>
              <HStack width="50%" justifyContent="flex-end">
                <IButton
                  variant={BUTTON.PRIMARY}
                  type="submit"
                  isDisabled={!settingsForm.isValid}
                  fontSize={14}
                  name="save-changes"
                >
                  Save
                </IButton>
              </HStack>
            </form>
          )}
        </Formik>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
