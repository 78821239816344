import { forwardRef, useEffect, useState } from "react";
import BeePlugin from "@mailupinc/bee-plugin";
import {
  IBeeConfig,
  IEntityContentJson,
  IMergeTag,
  IPluginRow,
  IToken,
} from "@mailupinc/bee-plugin/dist/types/bee";
import { Box } from "@chakra-ui/react";
import {
  API_AUTH_URL,
  beeConfig,
  BEEJS_URL,
  BEE_PLUGIN_CONTAINER_ID,
} from "./constants";
import { SearchToken } from "./SearchTokenDialog";
import { useSelector } from "react-redux";
import { selectEmailToken } from "../../emailtoken/emailTokenSlice";
import {
  isFinished,
  isFulfilled,
  isSuccess,
} from "../../../../common/helper/commonHelper";
import {
  authenticateBee,
  deleteCustomRow,
  listSavedRows,
  selectTemplate,
  updateCustomRow,
} from "../templateSlice";
import { toast } from "react-toastify";
import { VARIABLE_TYPE } from "../../../../common/constants/campaign";
import { useAppDispatch } from "../../../../store";
import { RowMetaDataModal } from "./RowMetaDataModal";
import { SavedRow } from "../../../../common/types/template";

const BeePluginStarter = forwardRef(
  ({
    templateJson,
    variables,
    onChange,
    onSave,
    onTogglePreview,
    editorRef,
  }: {
    templateJson: string;
    variables: (IMergeTag & { type: VARIABLE_TYPE })[];
    onChange: (jsonFile: string) => void;
    onSave: (jsonFile: unknown, htmlFile: unknown) => void;
    onTogglePreview: () => void;
    editorRef: React.MutableRefObject<any>;
  }) => {
    const [tokenModal, setTokenModal] = useState(<></>);
    const [rowModal, setRowModal] = useState(<></>);

    const {
      allColumnsList,
      emailTokenList: {
        listAll: { loading: fetchingTokenList },
      },
    } = useSelector(selectEmailToken);

    const {
      templateDetails: { loading: fetchingTemplateDetails },
      beeAuth,
    } = useSelector(selectTemplate);

    const dispatch = useAppDispatch();

    function onSaveRow(rowJson: string, rowHtml: string, pageJson: string) {
      const id = JSON.parse(rowJson).metadata.id;
      dispatch(
        updateCustomRow({
          bee_row_id: id,
          row_json: rowJson,
          row_html: rowHtml,
          page_json: pageJson,
        })
      );
    }

    function openTokenModal() {
      return new Promise<IMergeTag>((resolve, reject) => {
        setTokenModal(
          <SearchToken
            resolve={resolve}
            reject={reject}
            variables={variables}
            onClose={() => setTokenModal(<></>)}
          />
        );
      });
    }

    function openRowModal(row: IPluginRow) {
      return new Promise<{ name: string; id: string }>((resolve, reject) => {
        setRowModal(
          <RowMetaDataModal
            resolve={resolve}
            reject={reject}
            onClose={() => setRowModal(<></>)}
            rowJson={row}
          />
        );
      });
    }

    function initiateBEE(template: IEntityContentJson, beeInstance: BeePlugin) {
      beeInstance
        .start(
          {
            ...(beeConfig as IBeeConfig),
            onChange,
            onSave,
            onLoad: () => {
              if (editorRef && editorRef.current.bee)
                editorRef.current.bee.save();
            },
            onSaveRow,
            onTogglePreview,
            contentDialog: {
              mergeTags: {
                label: "Tokens",
                handler: function (resolve, reject) {
                  return openTokenModal()
                    .then((token) => resolve(token))
                    .catch(() => reject());
                },
              },
              saveRow: {
                label: "Rows",
                handler: function (resolve, reject, args) {
                  return openRowModal(args)
                    .then((metadata) => {
                      resolve(metadata);
                    })
                    .catch(() => reject());
                },
              },
              onDeleteRow: {
                handler: async (resolve, reject, args) => {
                  return dispatch(
                    deleteCustomRow((args as any).row.metadata.id)
                  )
                    .then((res) => {
                      if (isFulfilled(res.meta.requestStatus)) {
                        const isDeleted = (
                          res.payload as { bee_row_delete: boolean }
                        ).bee_row_delete;
                        resolve(isDeleted);
                      } else {
                        reject();
                      }
                    })
                    .catch(() => reject());
                },
              },
            },
            hooks: {
              getRows: {
                handler: async (resolve, reject, args) => {
                  return dispatch(listSavedRows())
                    .then((res) => {
                      if (isFulfilled(res.meta.requestStatus)) {
                        const rows = (res.payload as { records: SavedRow[] })
                          .records;
                        resolve(
                          rows.map((row) => {
                            const rowJson = JSON.parse(row.row_json);
                            if (!rowJson.metadata.id)
                              rowJson.metadata.id = row.bee_row_id;
                            return rowJson;
                          })
                        );
                      } else {
                        reject();
                      }
                    })
                    .catch(() => reject());
                },
              },
            },
          },
          template,
          "",
          { shared: false }
        )
        .catch((error) => {
          toast.error(`Error during iniziatialization`);
        })
        .finally(() => {
          editorRef.current = { bee: beeInstance };
        });
    }

    useEffect(() => {
      const conf = { authUrl: API_AUTH_URL, beePluginUrl: BEEJS_URL };
      if (!isFinished(beeAuth.loading)) dispatch(authenticateBee());
      if (
        isFinished(fetchingTokenList) &&
        isFinished(allColumnsList.loading) &&
        isFinished(fetchingTemplateDetails) &&
        isSuccess(beeAuth.loading)
      ) {
        //BeePlugin expect token as string, but we have to pass all the auth data together.
        const beeInstance = new BeePlugin(
          beeAuth.data as unknown as IToken,
          conf
        );
        if (templateJson) {
          initiateBEE(JSON.parse(templateJson), beeInstance);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      editorRef,
      fetchingTokenList,
      allColumnsList.loading,
      variables,
      fetchingTemplateDetails,
      beeAuth.loading,
    ]);

    return (
      <>
        <Box
          id={BEE_PLUGIN_CONTAINER_ID}
          w="100%"
          height="calc(100vh - 70px)"
        ></Box>
        {tokenModal}
        {rowModal}
      </>
    );
  }
);

export default BeePluginStarter;
