import { FormikProps } from "formik";
import {
  BOUNCE_STATS_VIEW_TYPE,
  CAMPAIGN_QUALIFICATION_RULES,
  CAMPAIGN_RUN_STATE,
  CAMPAIGN_STATUS,
  DAY_OF_WEEK,
  DELAY_OPTION_VALUES,
  DELAY_TYPE,
  DELAY_UNIT,
  FILTER_TYPE,
  FLOW_ACTIONS,
  PERIODIC_FREQUENCIES,
  RECURRING_FREQUENCIES,
  SF_CAMPAIGN_SYNC_DRAWER_VALIDITY,
  UPDATE_VALUE_OBJECT_TYPE,
  UPDATE_VALUE_TYPE,
  BOUNCE_EVENT,
  BOUNCE_GROUP_BY,
} from "../constants/campaign";
import { UserSummary, PageData, ListFilterItem } from "./common";
import { FlowGraph } from "./flow";
import { COLUMN_VALUE_TYPES } from "./person";
import { CcBccListType, SenderMeta, TemplateCcBccResponse } from "./template";
import { SOURCES } from "./unifiedMapping";
import {
  FILTER_TYPE_TEXT,
  FILTER_TEXT,
} from "../../pages/dashboard/campaign/flow/components/constants";
import { PreviewPerson } from "./token";
import { USER_ACCOUNT_STATUS } from "./auth";
import { LOADING_STATES } from "../constants/common";
import { ReactNode } from "react";
import { JOURNEY_TYPES } from "../constants/trigger";
import { TRIGGER_CAMPAIGN_STATES } from "../constants/trigger";

export interface CampaignListItem {
  campaign_id: string;
  campaign_context?: CAMPAIGN_CONTEXT;
  created_at: string;
  draft: boolean;
  name: string;
  schedule: CampaignSchedule | null;
  created_by: UserSummary;
  updated_at: string;
  active: boolean;
  tags?: string[];
  campaign_type: JOURNEY_TYPES;
}

export interface CampaignListStats {
  campaign_id: string;
  count_processed: number | null;
  count_delivered: number | null;
  count_bounced: number | null;
  count_dropped: number | null;
  open_count: number | null;
  total_count: number | null;
}

export type Campaign = CampaignListItem &
  CampaignListStats & { stats_loading?: LOADING_STATES };

export type FormType = FormikProps<CampaignSchedule>;

export enum RUN_TYPE {
  PERIOD = "period",
  AT_ONCE = "at_once",
  ONCE = "once",
  RECURRING = "recurring",
}

export interface CampaignSchedule {
  campaign_id: string | null;
  next_run_time?: string;
  run_type: RUN_TYPE | undefined;
  run_config: RunConfig;
}

export interface RunConfig {
  start_date: string;
  end_date?: string;
  frequency: RECURRING_FREQUENCIES | PERIODIC_FREQUENCIES | "";
  freq_options: {
    day?: string;
    unit?: number;
    date_string?: string;
    date?: string;
  };
  unit: number | undefined;
}

export interface AddFlow {
  campaign_id: string;
  parent_action_id?: string;
  action_type: FLOW_ACTIONS;
  action_options?: ActionOptions;
  add_after_action_id?: string;
  branch_id?: string;
  link_attributes?: {
    branch_id?: string;
  };
}

export interface UpdateValueOperation {
  type: UPDATE_VALUE_TYPE | "";
  object_type: UPDATE_VALUE_OBJECT_TYPE;
  filter_type: FILTER_TYPE.PERSON;
  filter: string;
  token?: string;
  operator: string | null;
  value: string[];
}

export interface UpdateValueAction {
  sequence_number: number;
  operation: UpdateValueOperation;
}

export interface FlowUpdateValueActionOptions {
  operations: UpdateValueAction[];
}

export interface SendInternalEmailActionOptions {
  template_id: string | null;
  internal_emails: string[];
}

export type DelayActionOptions = DelayUntil | DelayBy;

export interface DelayBy {
  type?: DELAY_TYPE.DELAY_BY;
  delay_by?: {
    delay_value?: number;
    delay_unit?: DELAY_UNIT;
  };
}
export interface DelayUntil {
  type?: DELAY_TYPE.DELAY_UNTIL;
  delay_until?: {
    option?: DELAY_OPTION_VALUES;
    run_time?: string;
    run_date?: string;
    week_days?: DAY_OF_WEEK[];
    is_recipient_timezone?: boolean;
  };
}

export interface SendEmailActionOptions extends Partial<SenderMeta> {
  template_id: string | null;
  subject?: string | null;
  preheader?: string | null;
  reply_to?: string | null;
  from_email?: { name: string; email: string } | null;
  bcc_email_data_set?: CcBccListType;
  cc_email_data_set?: CcBccListType;
}

export interface FlowStaticListActionOptions {
  static_list_id: string | null;
}

export interface FlowWebhookActionOptions {
  webhook_id: string | null;
}

export interface BranchByFilterActionOptions {
  primary_branch_id?: string;
  default_branch_id?: string;
  condition: { conditions: DynamicListType[] };
}

export interface FlowSalesforceActionOptions {
  connection_id: string | null;
}

export interface BranchByConditionsActionOptions {
  default_branch_id?: string;
  conditions?: BranchingCondition[];
}

export interface BranchByValueActionOptions
  extends BranchByConditionsActionOptions {
  filter_type?: string;
  filter?: string;
}

export interface BranchByTokenActionOptions
  extends BranchByConditionsActionOptions {
  token?: string;
}

export type BranchByDataActionOptions =
  | BranchByTokenActionOptions
  | BranchByValueActionOptions;

export interface BranchingCondition {
  sequence_number: number;
  branch_id?: string;
  condition: BranchingConditionDef;
}

export interface FlowGroupActionOptions {
  children: FlowGraph | null;
  exit_criteria: GroupExitCriteriaType[] | null;
  name?: string;
}

export interface GroupExitCriteriaType {
  condition_id?: string;
  conditions: DynamicListType[] | null;
}

export interface BranchingConditionDef {
  type: TYPE;
  filter_type: string | typeof FILTER_TYPE_TEXT;
  filter: string | typeof FILTER_TEXT;
  operator: string | null;
  value: ValueTypes;
}

export interface EmailConfigurationType extends TemplateCcBccResponse {
  from_email: {
    name: string;
    email: string;
  };
  reply_to: string;
  preheader?: string | null;
}

export interface GlobalEmailConfigType
  extends Omit<EmailConfigurationType, "cc_email_data_set"> {}

export interface EmailDraftParams extends EmailConfigurationType, SenderMeta {
  content: string;
  subject: string;
}

export interface EmailPreviewParams extends EmailDraftParams {
  to_emails: string[];
}

export type ActionOptions =
  | DelayActionOptions
  | SendEmailActionOptions
  | SendInternalEmailActionOptions
  | FlowStaticListActionOptions
  | FlowWebhookActionOptions
  | BranchByFilterActionOptions
  | FlowSalesforceActionOptions
  | FlowUpdateValueActionOptions
  | BranchByDataActionOptions
  | FlowGroupActionOptions;

export interface Flow {
  action_id: string;
  previous_action_id: null | string;
  action_options: ActionOptions;
  action_type: FLOW_ACTIONS;
  next_action_id: null | string;
  sequence_number?: number;
}

export interface FullCampaign {
  campaign_id: string;
  campaign_context?: CAMPAIGN_CONTEXT;
  dynamic_list: DynamicList | null;
  flow: FlowGraph | null;
  name: string;
  draft: boolean;
  draft_data: DraftData;
  schedule: CampaignSchedule | null;
  exit_criteria: { conditions: [DynamicListType] } | null;
  created_by: UserSummary;
  created_at: string;
  version: number;
  active: boolean;
  updated_at: string;
  rule: CampaignRuleResp;
  tags?: string[];
  account_status: USER_ACCOUNT_STATUS;
  activated_at?: string; //Not present for old campaigns
  deactivated_at?: string; //Not present for old campaigns
  dynamic_list_id?: string; //Not present for old campaigns
  is_deleted: boolean;
  is_dry_run_enabled?: boolean;
}

export interface FlowSet {
  campaign_id: string;
  flow_actions: FlowAction[];
}

export interface FlowAction {
  action_type: string;
  action_options: ActionOptions;
  sequence_number: number;
}

export interface AudienceList {
  rows: { [key: string]: string }[];
  total_count: {
    count: number;
  };
}

export interface DynamicList {
  created_at: string;
  version: number;
  generated_query: GeneratedQuery;
  updated_at: string;
  query: DynamicListQuery;
  created_by: UserSummary;
}

export interface GeneratedQuery {
  columns: string[];
  conditions: string;
  tables: string[];
}
export interface DynamicListQuery {
  conditions: DynamicListType[];
}

export interface CampaignRuleBase {
  rule: CAMPAIGN_QUALIFICATION_RULES;
  recurring_period: number | null;
  recurring_period_unit: string | null;
}

export type CampaignRuleResp = CampaignRuleBase & CampaignRuleMeta;

export interface CampaignRuleMeta {
  version: number;
  created_time: string;
  updated_time: string;
  allow_re_entry: boolean;
  recurring: boolean;
  recurring_period_days: number | null;
}

export interface EmailSentDetails {
  subject: string;
  address_from: string;
  address_reply_to: string;
  content: string;
}

export interface EventNamesReq {
  max_result: number;
  entity: string;
}

export interface EventKeysReq extends EventNamesReq {
  event_names: string[];
}

export interface EventNamesResp {
  rows: string[];
}

export interface EventKeysResp {
  [key: string]: string[];
}

//Dynamic list operators

export type OperatorArgType =
  | null
  | (string | { display: string; id: string }[] | null)[];

export interface OperatorDetails {
  id: string;
  display: string;
  display_2: string | string[];
  arguments: string;
  arguments_types: OperatorArgType;
}

export type PropertyType = Extract<
  OperatorType,
  | OperatorType.BOOLEAN
  | OperatorType.DATE
  | OperatorType.FLOAT
  | OperatorType.INTEGER
  | OperatorType.JSON
  | OperatorType.STRING
>;

export enum OperatorType {
  BOOLEAN = "bool",
  DATE = "date",
  FLOAT = "float",
  INTEGER = "int",
  JSON = "json",
  STRING = "string",
  ASSET_SPECIFIC = "asset_specific",
  LIST_SPECIFIC = "list_specific",
  ORG_AGGREGATE_SPECIFIC = "org_aggregate_specific",
  ACCOUNT_AGGREGATE_SPECIFIC = "account_aggregate_specific",
  CONTACT_UPDATE_SPECIFIC = "contact_update_specific",
  FORM_FIELD_SPECIFIC = "form_field_specific",
  FORM_SPECIFIC = "form_specific",
  WT_UTM_PARAM_SPECIFIC = "website_tracking_utm_parameter_specific",
  WT_CUSTOM_PARAM_SPECIFIC = "website_tracking_custom_parameter_specific",
  WT_PROTOCOL = "web_protocol",
  FREQUENCY_COUNT = "frequency_count",
  AGGREGATE_TIMEFRAME = "aggregate_timeframe",
  LINK_STRING = "link_string",
}

export type OperatorList = {
  [key in OperatorType]: { [operator: string]: OperatorDetails };
};

export interface TableDesc {
  type: COLUMN_VALUE_TYPES | OperatorType;
  display_name: string | null;
  is_default?: boolean;
  ui_hidden?: boolean;
}

export interface TableDescWithId extends TableDesc {
  id: string;
}

export interface TableList {
  [tableName: string]: { [columnName: string]: TableDesc };
}

export interface DynamicListType {
  type: TYPE;
  name: string;
  filter_type: null;
  filter: null;
  property: null;
  operator: string | null;
  value: null;
  connector: CONNECTOR | null;
  children: DynamicListChild[];
  validation_error?: string;
}

export type ValueTypes = (string | number | string[])[];

export interface DynamicListChild {
  type: TYPE;
  filter_type: null;
  filter: null;
  property: null;
  operator: string | null;
  value: ValueTypes | null;
  connector: CONNECTOR | null;
  name: string;
  children: DynamicListChildL1[];
  validation_error?: string;
}

export interface DynamicListChildL1 {
  type: TYPE;
  filter_type: null | FILTER_TYPE;
  filter: null | string;
  property: null | string;
  operator: null | string;
  connector: CONNECTOR;
  name?: string;
  value: ValueTypes | null;
  children?: DynamicListChildL2[];
  validation_error?: string;
}

export interface DynamicListChildL2 {
  type: TYPE;
  filter_type: FILTER_TYPE | null;
  filter: null | string;
  property: string | null;
  property_type?: PropertyType | null;
  operator: string | null;
  value: ValueTypes;
  connector: CONNECTOR;
  validation_error?: string;
  children?: DynamicListChildL2[];
  name?: string;
}

export enum CONNECTOR {
  AND = "and",
  EMPTY = "",
  OR = "or",
}

export enum TYPE {
  EXPRESSION = "expression",
  GROUP = "group",
}

export interface EmailTokenDetails {
  name: string;
  token_id: string;
  created_by: UserSummary;
  token: string;
  created_at: string;
  updated_at: string;
  is_terminal: boolean;
  return_type: string;
  description: string;
  default_value: any;
  display: string;
  account_status: USER_ACCOUNT_STATUS;
}

export interface EmailTokenUpdate {
  token: string;
  token_id: string;
  default_value: any;
  description: string;
}

type ReturnTypes = number | string | boolean;
export interface EmailTokenFunctions {
  [name: string]: EmailTokenFunction;
}

export interface EmailTokenFunction {
  id: string;
  display: string;
  syntax: string;
  name: string;
  description: string;
  sample: string;
  return_type: string;
  arguments: string;
  types: { [key: string]: ReturnTypes };
  function_type: string;
}

export interface EmailTokenColumn {
  id: string;
  name: string;
  display: string;
  return_type: string;
}

export interface EmailTokenPreviewData {
  email: string;
  id: string;
  org_id?: string;
  product_user_id?: string;
  campaign_context: CAMPAIGN_CONTEXT;
  token_value: any;
  column: { [name: string]: string | null };
  token: { [name: string]: string | null };
}

export interface EmailTokenPreviewReq {
  token_id: string;
  person_ids: PreviewPerson[];
}

export interface DraftSendDataType {
  query: DynamicListQuery | null;
  rule: CampaignRuleBase | null;
  flow: FlowGraph | null;
  schedule: Partial<CampaignSchedule> | null;
  exit_criteria: { conditions: [DynamicListType] } | null;
}

export interface DraftSaveType {
  campaign_id: string;
  draft: DraftSendDataType;
}

export interface DraftData {
  flow: FlowGraph | null;
  schedule: CampaignSchedule | null;
  rule: CampaignRuleResp | null;
  dynamic_list: DynamicList | null;
  exit_criteria: { conditions: [DynamicListType] } | null;
  warning: {
    updated_by: {
      id: number;
      name: string;
    };
    updated_at: string;
    text: string;
  } | null;
  updated_by: {
    id: number;
    name: string;
  } | null;
  updated_at: string | null;
}

export interface DraftPublishType {
  campaign: FullCampaign;
  error: boolean;
  error_details: {
    dynamic_list: string;
    flow: string;
    rule: string;
    schedule: string;
    exit_criteria: string;
  };
}

// Reports V2

export interface ReportsFilterApiType {
  campaign_id: string;
  start_date: string;
  end_date: string;
  action_id?: string | null;
}

export interface ReportsFilterType {
  campaignId: string;
  timeRange: {
    startDate: string;
    endDate: string;
  };
  actionId?: string | null;
}

export interface ClickData {
  total_click_count: number;
  unique_click_count_by_email: number;
  unique_click_count_by_url: number;
}

export interface OpenData {
  total_open_count: number;
  unique_open_count: number;
}

export interface AggregateStats extends ClickData, OpenData {
  processed_count: number;
  bounce_count: number;
  delivered_count: number;
  dropped_count: number;
  spamreport_count: number;
  total_count: number;
  not_sent_count: number;
  unsub_count: number;
}

export interface RunDetails {
  state: CAMPAIGN_RUN_STATE;
  run_id: string;
  start_time: string;
}

export type ReportPageReq = ReportsFilterApiType & PageData;

export type RunStats = RunDetails &
  AggregateStats & { stats_loading?: LOADING_STATES };

export interface LinkClickData extends ClickData {
  link: string;
}

export interface EmailClientOpenData extends OpenData {
  email_client: string;
}
export interface EmailClientClickData extends ClickData {
  email_client: string;
}

export interface RecipientData {
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  sent_date: string;
  correlation_id: string;

  // Only present if account has org support
  product_user_id?: string;
  org_id?: string;
}

export interface RecipientWithUrls extends RecipientData {
  urls: string[];
}

export interface RecipientUnsub extends RecipientData {
  unsub_time: string;
}

export interface RecipientBounce extends RecipientData {
  event_time: string;
  reason: string;
}

export interface RecipientNotSent extends RecipientData {
  error_text: string;
}

export interface AssetPrimaryDetails {
  name: string;
  is_deleted: boolean;
}

export interface TemplatePrimaryDetails extends AssetPrimaryDetails {
  template_id: string;
}

export interface CampaignPrimaryDetails extends AssetPrimaryDetails {
  campaign_id: string;
}

export interface BounceClassificationStats {
  display?: string; // frontend added field
  bounce_classification: string;
  count: number;
}

export enum RECIPIENT_TYPES {
  TO = "to",
  CC_BCC = "cc_or_bcc",
}

export interface BounceReasonPerContact {
  campaign_id: string;
  email: string;
  person_id: string;
  sent_time: string;
  reason: string;
  recipient_type: RECIPIENT_TYPES;
}

export interface DateActionIdFilter {
  startDate: string;
  endDate: string;
  actionId?: string;
}

export interface BounceReasonAggregateReqParams extends DateActionIdFilter {
  view: BOUNCE_STATS_VIEW_TYPE.AGGREGATE;
  groupBy: typeof BOUNCE_GROUP_BY;
  event: typeof BOUNCE_EVENT;
}

export interface BounceReasonListReqParams extends DateActionIdFilter {
  view: BOUNCE_STATS_VIEW_TYPE.LIST;
  bounceClassification: string;
  event: typeof BOUNCE_EVENT;
  pageNumber: number;
  pageSize: number;
  search: string;
}

export interface BounceClassificationInfo {
  classification: string;
  display: string;
  description: string;
}

// Static list

export interface StaticListDetails {
  static_list_id: string;
  created_by: number;
  created_at: string;
  name: string;
  updated_at: string;
  updated_by: number;
  num_records: number;
  flow: null;
  is_deleted: boolean;
}

export interface StaticListSummary {
  static_list_id: string;
  name: string;
  updated_at: string;
  created_at: string;
  num_records: number;
}

export interface StaticListContactsFilterType {
  static_list_id: string;
  search_keyword: string;
  filters?: ListFilterItem;
  page_size: number;
  page_number: number;
}

export interface StaticListContactsData {
  email: string;
  company_name: string;
  title: string;
  phone_number: number;
  first_name: string;
  last_name: string;
  id: string;
  _created_time: string;
  _source: "dynamic_list_query" | "csv_upload";
  _created_by: number;
}

export interface TokenValidity {
  return_type: string;
  error: {
    reason: string;
    position: number;
  };
}

export enum CAMPAIGN_CONTEXT {
  ORG = "person_org",
  PERSON = "person",
}

export enum MEMBERSHIP_ASSET_TYPES {
  DYNAMIC = "campaign",
  STATIC = "static_list",
}

export enum INCLUSION_LIST_TYPES {
  DYNAMIC = "dynamic",
  STATIC = "static",
}

export type CampaignListSummary = {
  campaign_id: string;
  active: boolean;
  draft: boolean;
  name: string;
  activated_at: string;
  deactivated_at: string;
};

export interface DynamicListSummary {
  dynamic_list_id: string;
  name: string;
  updated_at: string;
  created_at: string;
}

export interface MarketingPersonListItem {
  id: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  title: string;
  unsubscribed: boolean;
  phone_number: string;
  person_created_time: string;
  crm_object: string;
  connection_id: string;
  _source: SOURCES;
  _created_time: string;
  _modified_time: string;
  _deleted_time: string;
  _is_selected: boolean;
}

export type CampaignSummarySaveHandler = (
  rule: CampaignRuleBase,
  exitCriteria: DynamicListType | null,
  noDelay?: boolean
) => void;

export type CampaignValidationType = {
  dynamicList: string;
  flow: string;
  schedule: string;
  rule: string;
  exitCriteria: string;
};

export type CampaignDetailsForBuilder = {
  campaignName: string;
  campaignId: string;
  campaignContext: CAMPAIGN_CONTEXT;
  status: CAMPAIGN_STATUS;
  isActivatedOnce: boolean;
  updatedBy: string;
  isSavingDraft: boolean;
  isPublishingCampaign: boolean;
  campaignTags: string[];
  updatedAt: string | number;
  fetchingDetails: boolean;
  activeErrorCheck: boolean;
  campaignType: JOURNEY_TYPES;
  currentState?: TRIGGER_CAMPAIGN_STATES;
};

export enum SF_CAMPAIGN_STATUS {
  SENT = "Sent",
  RESPONDED = "Responded",
}

export enum SF_CAMPAIGN_SELECTION_TYPE {
  RESOLVE_DIRECT = "RESOLVE_DIRECT",
  RESOLVE_CONTEXT = "RESOLVE_CONTEXT",
}

export enum SF_CAMPAIGN_CONTEXT_TYPE {
  PERSON = "PERSON",
  FORMS = "FORMS",
}

export interface SF_CAMPAIGN_PERSON_CONTEXT {
  context_type: SF_CAMPAIGN_CONTEXT_TYPE.PERSON;
  person_context: {
    field: string;
  };
}

export interface SF_CAMPAIGN_FORM_CONTEXT {
  context_type: SF_CAMPAIGN_CONTEXT_TYPE.FORMS;
  forms_context: {
    form_id: string;
    form_field: string;
  };
}

export interface FlowSfCampaignSyncActionOptions {
  connection_id: string;
  campaign_member_status: string | SF_CAMPAIGN_STATUS | null;
  campaign_selection_type: SF_CAMPAIGN_SELECTION_TYPE;

  campaign_id?: string;
  context?: SF_CAMPAIGN_PERSON_CONTEXT | SF_CAMPAIGN_FORM_CONTEXT;
}

export type SalesforceCampaign = {
  startDate: string;
  endDate: string;
  description: string;
  isActive: boolean;
} & SalesforceCampaignBasic;

export interface SalesforceCampaigns {
  campaigns: SalesforceCampaignBasic[];
}

export interface SalesforceCampaignBasic {
  campaignId: string;
  campaignName: string;
  campaignStatus: SalesforceCampaignStatus["value"];
  campaignType: string;
}

export interface SalesforceCampaignStatus {
  id: string;
  campaignId: string;
  label: string;
  value: string;
}

export interface SalesforceCampaignSyncDrawerData {
  campaignIdSource: SF_CAMPAIGN_SELECTION_TYPE | null;
  salesforceCampaign: string | null;
  personField: string | null;
  salesforceCampaignStatus: string | null | SF_CAMPAIGN_STATUS;
}

export type Validity = typeof SF_CAMPAIGN_SYNC_DRAWER_VALIDITY;

export interface EventMatrixRowType {
  label?: string;
  info?: string;
  value?: number;
  cell?: ReactNode;
  expandDetails?: () => void;
}

export interface EventMatrixRowListType {
  icon: ReactNode;
  rows: EventMatrixRowType[];
}

export type DynamicListPreviewQuery = {
  query?: DynamicListType[];
  dynamicListId?: string;
  campaignId?: string;
  searchKeyword: string;
  columnsToSearchIn: string[];
};

export type WebsiteActivityFiltersArg = {
  id: string;
  display: string;
  data_type: OperatorType;
  display_level: number;
  arguments: WebsiteActivityFiltersArg[] | null;
};

export type WebsiteActivityFilter = {
  id: string;
  display: string;
  arguments: WebsiteActivityFiltersArg[];
};

// ---------- CANVAS REPORT TYPES --------------

// 1. JOURNEY MEMBER REPORT
export interface JourneyMemberReportResp {
  campaign_id: string;
  summary: Summary;
  list: JourneyMember[];
}

export enum JOURNEY_MEMBER_STATUS {
  PROGRESS = "In Progress",
  COMPLETED = "Completed",
}
interface JourneyMember {
  entry_date: Date;
  exit_date: Date;
  person_id: string;
  product_user_id: string;
  org_id: string;
  user_name: string;
  contact_name: string;
  email: string;
  count: number | null;
  status: JOURNEY_MEMBER_STATUS;
}

export interface Summary {
  total_qualified: number;
  currently_in_workflow: number;
}

// 2. COUNT
export interface CountData<T> {
  campaign_id: string;
  qualified_users: number;
  nodes: T;
}

export interface NodeDetails {
  action_id: string;
  count?: number;
  branches?: Branch[];
}

interface Branch {
  branch_id: string;
  count: number;
}

// 3. FLOW STEP REPORT
export interface WebhookFlowStepReportMemberDetails {
  product_user_id: string;
  person_id: string;
  email: string;
  timestamp: string;
  response: WebhookResponse;
}

interface WebhookResponse {
  code: number;
  message: string;
}

// 4. FLOW STEP SUMMARY REPORT
export interface FlowStepSummaryResp {
  campaign_id: string;
  nodes: FlowStepSummary[];
}

export interface FlowStepSummary {
  type: FLOW_ACTIONS;
  action_id: string;
  summary_stats: SummaryStats;
}

export type SummaryStats = SummaryStatsEmail | SummaryStatsWebhook;

export interface SummaryStatsEmail {
  total?: number;
  delivered?: number;
  opened?: number;
  clicked?: number;
  unsubscribed?: number;
  bounced?: number;
  spammed?: number;
}

export interface SummaryStatsWebhook {
  success?: number;
  failure?: number;
}

// 5. FLOW STEP MEMBERS REPORT
export interface FlowStepMember {
  user: {
    product_user_id: string;
    person_id: string;
    user_name: string;
  };
  email: string;
  transition_date: string;
}

export interface CanvasDateFilter {
  startDate: string;
  endDate: string;
}
