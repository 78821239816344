import { useNavigate, useLocation } from "react-router-dom";
import { setAuthToken } from "../../common/helper/authHelper";
import urls from "../../urls";

const TOKEN_PARAM = "token";

export default function LoginWithToken() {
  const { search } = useLocation();
  const navigate = useNavigate();
  function setTokenWithRedirect(token: string | null) {
    if (token) {
      setAuthToken(token);
      navigate(urls.journey, { replace: true });
    } else {
      navigate(urls.login, { replace: true });
    }
  }
  const token = new URLSearchParams(search).get(TOKEN_PARAM);
  setTokenWithRedirect(token);

  return <></>;
}
