import { Checkbox, VStack, Text, Box, CheckboxProps } from "@chakra-ui/react";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  FILTER_TYPE,
  NEW_OUTER_GROUP,
} from "../../../../common/constants/campaign";
import {
  CAMPAIGN_CONTEXT,
  DynamicListType,
  TYPE,
} from "../../../../common/types/campaign";
import { DynamicListContext } from "../../../../components/dynamic-list/DynamicList";
import {
  selectDynamicList,
  getEventKeys,
} from "../../../../components/dynamic-list/dynamicListSlice";
import { useAppDispatch } from "../../../../store";
import { selectCampaign } from "../campaignSlice";
import OuterGroup from "./components/OuterGroup";

function CheckboxItem({
  checked,
  onCheck,
  label,
  disabled,
  ...props
}: {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  label: string;
  disabled?: boolean;
} & CheckboxProps) {
  return (
    <>
      <Checkbox
        isChecked={checked}
        onChange={(e) => onCheck(e.target.checked)}
        isDisabled={disabled}
        {...props}
      >
        <Text fontSize="sm">{label}</Text>
      </Checkbox>
    </>
  );
}

function ExitCriteriaOptions({
  isReadOnly,
  isChecked,
  checkOption,
}: {
  isReadOnly: boolean;
  isChecked: boolean;
  checkOption: (checked: boolean) => void;
}) {
  return (
    <>
      <CheckboxItem
        checked={true}
        onCheck={() => {}}
        disabled={true}
        hidden={isReadOnly}
        label="They complete the journey"
      />
      <CheckboxItem
        checked={isChecked}
        onCheck={(checked) => checkOption(checked)}
        hidden={isReadOnly}
        label="When any of the following events occur"
      />
    </>
  );
}

export default function CampaignExitCriteria({
  data,
  onChange,
  isReadOnly,
  showCheckOptions,
  activeErrorCheck = false,
}: {
  data: DynamicListType | null;
  onChange: (data: DynamicListType | null) => void;
  isReadOnly: boolean;
  showCheckOptions?: boolean;
  activeErrorCheck?: boolean;
}) {
  const [whenEventsOccur, setWhenEventsOccur] = useState(false);

  useEffect(() => {
    setWhenEventsOccur(!!data || !showCheckOptions);
  }, [data, showCheckOptions]);

  function handleCheckboxChange(val: boolean) {
    if (!val && data) {
      setWhenEventsOccur(false);
      onChange(null);
    } else if (val && !data) {
      const outerGroup = cloneDeep(NEW_OUTER_GROUP);
      onChange(outerGroup);
    }
  }
  const hasOnceRun = useRef(false);
  const dispatch = useAppDispatch();
  const {
    campaignDetails: {
      data: { campaign_context: campaignContext },
    },
  } = useSelector(selectCampaign);

  const { productEventKeysList } = useSelector(selectDynamicList);

  useEffect(() => {
    if (data && !hasOnceRun.current) {
      // Fetch product_event_properties list for all product_event_names in the campaign dynamiclist get
      // create an array of event names in the product activity filter group
      const eventNamesArray: string[][] = [];
      if (data.type === TYPE.GROUP) {
        const eventNames: (string | undefined)[] = data.children.map(
          (child) => {
            if (child.type === TYPE.GROUP) {
              const filterGroupRow = child.children?.find(
                (ch) => ch.filter_type === FILTER_TYPE.PRODUCT_ACTIVITY
              );
              return filterGroupRow?.value?.[0] as string | undefined;
            }
            return "";
          }
        );
        eventNamesArray.push(eventNames.filter((x) => x) as string[]);
      }
      const eventKeysAlreadyFetched = Object.keys(
        productEventKeysList.data || {}
      );
      const eventKeysToBeFetched = eventNamesArray
        .flat()
        .filter((event) => !eventKeysAlreadyFetched.includes(event));

      if (!isEmpty(eventKeysToBeFetched)) {
        dispatch(
          getEventKeys({
            max_result: 1000,
            entity: FILTER_TYPE.PERSON,
            event_names: eventKeysToBeFetched,
          })
        );
      }
      hasOnceRun.current = true;
    }
  }, [data, dispatch, productEventKeysList.data]);

  return (
    <VStack alignItems="flex-start" w="100%">
      {showCheckOptions && (
        <ExitCriteriaOptions
          isReadOnly={isReadOnly}
          isChecked={whenEventsOccur}
          checkOption={(checked) => handleCheckboxChange(checked)}
        />
      )}
      {whenEventsOccur && (!isReadOnly || (data && data.children?.length)) && (
        <DynamicListContext.Provider
          value={{
            activeErrorCheck,
            campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
          }}
        >
          <Box w="100%">
            <OuterGroup
              data={data ?? NEW_OUTER_GROUP}
              onChange={onChange}
              isReadOnly={isReadOnly}
            />
          </Box>
        </DynamicListContext.Provider>
      )}
    </VStack>
  );
}
