import { toast } from "react-toastify";
import { createStaticListApi } from "../../../../common/api/campaign/staticList";
import {
  createStaticList,
  selectStaticList,
} from "../../staticlist/staticlistSlice";
import { addStaticList, selectContactUpload } from "../contactUploadSlice";
import { useAppDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import {
  isFailed,
  isLoading,
  isFulfilled,
} from "../../../../common/helper/commonHelper";
import { LOADING_STATES } from "../../../../common/constants/common";
import CreatableTextInput from "../../../../components/CreatableTextInput";

export function CreatableStaticListDropdown({
  staticListOptions,
  listLoading,
  invalidMsg,
}: {
  staticListOptions: { label: string; value: string }[];
  listLoading?: LOADING_STATES;
  invalidMsg?: string;
}) {
  const dispatch = useAppDispatch();

  const {
    contactImportConfig: { staticListId },
  } = useSelector(selectContactUpload);
  const { updatingStaticList } = useSelector(selectStaticList);

  async function handleCreateStaticList(name: string) {
    const newStaticList = await dispatch(createStaticList(name));

    if (isFulfilled(newStaticList.meta.requestStatus)) {
      const listDetails = newStaticList.payload as Awaited<
        ReturnType<typeof createStaticListApi>
      >;
      dispatch(addStaticList(listDetails.static_list_id));
    } else {
      toast.error("Failed to create static list");
    }
  }

  return (
    <CreatableTextInput
      value={
        staticListId
          ? staticListOptions.find(({ value }) => value === staticListId)
          : null
      }
      onChange={(option) => {
        dispatch(addStaticList(option?.value ?? ""));
      }}
      placeholder="Search or create static list"
      onCreateOption={handleCreateStaticList}
      isLoading={isLoading(listLoading) || isLoading(updatingStaticList)}
      options={staticListOptions}
      isDisabled={isFailed(listLoading)}
      formatCreateLabel={(value) => `Create "${value}"`}
      isValidNewOption={(inputValue) => {
        return (
          !!inputValue &&
          staticListOptions.every(({ label }) => !label.includes(inputValue))
        );
      }}
      invalidMsg={invalidMsg}
    />
  );
}
