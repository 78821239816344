import {
  Box,
  Card,
  CardBody,
  HStack,
  Icon,
  IconButton,
  Portal,
  Text,
} from "@chakra-ui/react";
import { FLOW_ACTIONS } from "../../../../../../common/constants/campaign";
import {
  memo,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { WIDGET_OPTIONS_DETAILS } from "../constants";
import {
  FaPencilAlt,
  FaRegLightbulb,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";
import { useAppDispatch } from "../../../../../../store";
import { useSelector } from "react-redux";
import { FlowStepSourceHandle, FlowStepTargetHandle } from "../FlowHandle";
import { ActionNodeArgs } from "../../../../../../common/types/flow";
import { NodeProps } from "reactflow";
import { isActionDataSame } from "../helpers";
import {
  addFlowEdge,
  CampaignBuilderContext,
  removeFlowAction,
  removeFlowEdge,
  selectFlow,
  setFlowValidity,
} from "../../flowSlice";

const { label, icon, color } = WIDGET_OPTIONS_DETAILS[FLOW_ACTIONS.GOTO];

function GotoWidget({
  data: { action, groupId, gotoChild, lock, props, selectedGoto },
}: NodeProps<ActionNodeArgs>) {
  const newRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const { campaignId } = useContext(CampaignBuilderContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isEditingFlow } = useSelector(selectFlow);
  const [lastChild, setLastChild] = useState<{
    actionId: string;
    groupId?: string;
  } | null>(null);
  const { selectGoto, FlowContainerRef, readonly } = props;
  const identities = useMemo(() => {
    return {
      actionId: action.action_id,
      groupId,
      branchId: action.branch_id,
    };
  }, [action, groupId]);

  const isSelected = useMemo(
    () => action.action_id === selectedGoto,
    [action.action_id, selectedGoto]
  );

  useEffect(() => {
    dispatch(setFlowValidity({ [identities.actionId]: !!gotoChild }));
  }, [dispatch, gotoChild, identities.actionId]);

  async function removeAction() {
    setIsDeleting(true);
    await dispatch(removeFlowAction({ ...identities, campaignId }));
    setIsDeleting(false);
  }

  const createGotoEdge = useCallback(
    (id: string, groupId?: string) => {
      if (selectedGoto) {
        dispatch(
          addFlowEdge({
            source: selectedGoto,
            target: id,
            groupId,
            campaignId,
          })
        );
      }
    },
    [dispatch, campaignId, selectedGoto]
  );

  function addEdge() {
    createGotoEdge(lastChild!.actionId, lastChild!.groupId);
    setLastChild(null);
  }

  async function removeEdgeAction() {
    setIsDeleting(true);
    await dispatch(
      removeFlowEdge({ source: identities.actionId, groupId, campaignId })
    );
    setLastChild({ actionId: gotoChild, groupId });
    setIsDeleting(false);
  }

  function resetLastAction() {
    if (lastChild?.actionId) {
      addEdge();
    } else {
      removeAction();
    }
  }

  function onClickGoto() {
    if (gotoChild && !lock) {
      selectGoto(isSelected ? null : identities.actionId);
    }
  }

  return (
    <Box w="250px">
      <HStack
        role={readonly ? undefined : "group"}
        bgColor="gray.200"
        borderRadius="25px"
        width="fit-content"
        ml="102px"
        ref={newRef}
      >
        <Box>
          <FlowStepTargetHandle className="goto-handle" />
          <FlowStepSourceHandle className="goto-handle" />
          <IconButton
            bg={isSelected ? color : "white"}
            border={"4px solid black"}
            borderColor={color}
            rounded="full"
            size="lg"
            onClick={onClickGoto}
            title={label}
            _hover={{
              color: color,
            }}
            icon={
              <Icon
                as={icon}
                fontSize="26px"
                transform={"rotate(180deg)"}
                color={isSelected ? "white" : color}
              />
            }
            aria-label="add-goto"
            colorScheme="blue"
            isLoading={(!gotoChild && isEditingFlow) || isDeleting}
          />
        </Box>
        {gotoChild && (
          <IconButton
            rounded="full"
            size="md"
            icon={<Icon as={FaPencilAlt} color="white" fontSize="18px" />}
            aria-label="remove-goto-link"
            display="none"
            _groupHover={{ display: "flex" }}
            onClick={removeEdgeAction}
            variant="primary"
            isLoading={isDeleting}
            isDisabled={readonly}
          />
        )}
        <IconButton
          rounded="full"
          size="md"
          icon={<Icon as={FaTrashAlt} color="white" fontSize="18px" />}
          aria-label="remove-goto"
          display="none"
          _groupHover={{ display: "flex" }}
          onClick={removeAction}
          variant="solid"
          colorScheme="red"
          isLoading={isDeleting}
          isDisabled={readonly}
        />
      </HStack>
      <Portal
        containerRef={FlowContainerRef as MutableRefObject<HTMLElement | null>}
      >
        {isSelected && !gotoChild && (
          <Card
            position="fixed"
            top={0}
            right={18}
            size="sm"
            fontSize="12px"
            mt={"120px"}
          >
            <CardBody>
              <HStack spacing={2}>
                <IconButton
                  icon={<Icon as={FaRegLightbulb} fontSize="16px" />}
                  aria-label="help-icon-loading"
                  background="yellow.400"
                  color="white"
                  rounded="full"
                  size="sm"
                  colorScheme="yellow"
                  cursor="initial"
                  isLoading={isDeleting || isEditingFlow}
                />
                <Text fontSize="14px" pr={2}>
                  Click on a flow step to merge.
                </Text>
                <IconButton
                  icon={<FaTimes fontSize="14px" />}
                  aria-label="close-help"
                  variant="ghost"
                  rounded="full"
                  size="xs"
                  onClick={resetLastAction}
                  isDisabled={isDeleting || isEditingFlow}
                />
              </HStack>
            </CardBody>
          </Card>
        )}
      </Portal>
    </Box>
  );
}

export default memo(GotoWidget, isActionDataSame);
