import { IPluginRow } from "@mailupinc/bee-plugin/dist/types/bee";
import { useState, KeyboardEvent } from "react";
import { saveCustomRowApi } from "../../../../common/api/campaign/template";
import {
  isFulfilled,
  onEnterKeySubmit,
} from "../../../../common/helper/commonHelper";
import IModal from "../../../../components/IModal";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { useAppDispatch } from "../../../../store";
import { saveCustomRow } from "../templateSlice";

export function RowMetaDataModal({
  resolve,
  reject,
  onClose,
  rowJson,
}: {
  resolve: (data: { name: string; id: string }) => void;
  reject: () => void;
  onClose: () => void;
  rowJson: IPluginRow;
}) {
  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);
  const dispatch = useAppDispatch();

  async function onSubmit() {
    if (resolve && name) {
      onClose();
      rowJson.metadata = { name };
      setIsSaving(true);
      const result = await dispatch(
        saveCustomRow({
          row_json: JSON.stringify(rowJson),
        })
      );
      if (isFulfilled(result.meta.requestStatus)) {
        const savedRowResponse = result.payload as Awaited<
          ReturnType<typeof saveCustomRowApi>
        >;
        setIsSaving(false);
        const rowId = savedRowResponse.bee_row.bee_row_id;
        resolve({ name, id: rowId });
      }
    }
  }

  function onKeyDownSubmit(e: KeyboardEvent<HTMLInputElement>) {
    setActiveErrorCheck(true);
    name && onEnterKeySubmit(e, () => onSubmit());
  }

  return (
    <IModal
      isOpen={true}
      onClose={() => {
        reject();
        onClose();
      }}
      header={{ title: "Save row as" }}
      size="md"
      primaryButton={{
        label: "Save row",
        props: {
          onClick: () => {
            setActiveErrorCheck(true);
            onSubmit();
          },
          isLoading: isSaving,
          isDisabled: activeErrorCheck && !name,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: () => {
            reject();
            onClose();
          },
        },
      }}
      modalBodyProps={{
        py: 0,
      }}
    >
      <InputFieldWithError
        placeholder="Row name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={onKeyDownSubmit}
        errorMsg={activeErrorCheck && !name ? "Row name required" : ""}
        autoFocus
      />
    </IModal>
  );
}
