import { Box, Circle, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { FaRegCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiLoader3Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { LOADING_STATES } from "../../../../common/constants/common";
import { isLoading, isSuccess } from "../../../../common/helper/commonHelper";
import IButton, { BUTTON } from "../../../../components/IButton";
import IModal from "../../../../components/IModal";
import { selectContactUpload } from "../contactUploadSlice";

function UploadSucessfulIcon() {
  return (
    <VStack>
      <Circle
        size="120px"
        bg="green.50"
        animation="growShrink 13s ease-in-out infinite"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Circle size="90px" borderWidth="2px" borderColor="green.600">
          <Icon as={IoIosCheckmarkCircle} color="green.600" fontSize="60px" />
        </Circle>
      </Circle>
      <Text fontSize="14px">Upload successful!</Text>
    </VStack>
  );
}

function UploadLoadingIcon() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Icon as={FaRegCircle} fontSize="80px" color="gray.200" />
      <Icon
        as={RiLoader3Line}
        fontSize="50px"
        color="blue.500"
        position="absolute"
        top="40%"
        left="50%"
        transform="translate(-50%, -50%)"
        animation="spin 2s linear infinite"
      />
      <Text fontSize="14px">Upload in progress</Text>
    </Box>
  );
}

function UploadFailedIcon({ fileName }: { fileName: string }) {
  return (
    <VStack textAlign="center">
      <Icon as={BiSolidErrorAlt} fontSize="80px" color="oneOffs.error.red" />
      <Text fontSize="14px">
        <Text as="span" fontWeight="600">
          {fileName}{" "}
        </Text>
        failed to upload.
        <br />
        Try uploading again
      </Text>
    </VStack>
  );
}

function getUploadStatusIconAndText(
  isUploading: LOADING_STATES,
  fileName: string
) {
  switch (isUploading) {
    case LOADING_STATES.SUCCESS:
      return <UploadSucessfulIcon />;
    case LOADING_STATES.LOADING:
      return <UploadLoadingIcon />;
    case LOADING_STATES.FAILED:
      return <UploadFailedIcon fileName={fileName} />;
    default:
      return <></>;
  }
}

export default function ContactUploadStatusModal({
  isOpen,
  onClose,
  onCompleteImport,
  isUploading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCompleteImport: (() => void) | undefined;
  isUploading: LOADING_STATES;
}) {
  function onCloseAfterImport() {
    onClose();
    if (isSuccess(isUploading)) {
      onCompleteImport?.();
    }
  }

  const {
    fileUpload: { fileName },
  } = useSelector(selectContactUpload);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      header={{
        title: "",
      }}
      headerChildren={
        <Heading textAlign="center" fontSize="16px" fontWeight={600}>
          Importing Contacts
        </Heading>
      }
      size="sm"
      closeOnOverlayClick={false}
    >
      <VStack justifyContent="center">
        {getUploadStatusIconAndText(isUploading, fileName)}
        <IButton
          variant={BUTTON.SECONDARY}
          isDisabled={isLoading(isUploading)}
          onClick={onCloseAfterImport}
        >
          Okay!
        </IButton>
      </VStack>
    </IModal>
  );
}
