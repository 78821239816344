import { string, number } from "yup";
import {
  PASSWORD_REQUIREMENTS,
  PASSWORD_CONSOLIDATED_REGEX,
} from "../constants/auth";

export const emailValidation = string()
  .required("Email is required")
  .email("Please enter a valid email address");

export const domainValidation = string()
  .required("Domain is required")
  .matches(
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
    "Please enter a valid domain"
  );

export const urlValidation = (inputName = "Field") => {
  return string()
    .required(`${inputName} is required`)
    .matches(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/i,
      "Please enter a valid url"
    );
};

export const passwordValidation = string()
  .required("Password is required")
  .min(
    PASSWORD_REQUIREMENTS.MIN_LENGTH,
    "Password does not fulfill the requirements"
  )
  .matches(
    PASSWORD_CONSOLIDATED_REGEX,
    "Password does not fulfill the requirements"
  );

export const requiredString = (inputName = "Field") => {
  return string().required(`${inputName} is required`);
};

export const requiredNumber = (inputName = "Field") => {
  return number().required(`${inputName} is required`);
};
