import { ButtonProps, StackProps } from "@chakra-ui/react";
import EmailConfigureInput from "../../../../components/EmailConfigureInput";
import {
  EmailConfigErrors,
  EmailConfigOnChange,
} from "../../../../common/types/template";
import { GlobalEmailConfigType } from "../../../../common/types/campaign";
import IButton from "../../../../components/IButton";
import {
  GLOBAL_EMAIL_CONFIG_IS_CODE,
  GLOBAL_EMAIL_CONFIG_INIT,
} from "../../../../common/constants/template";
import { ACTIONS } from "../../../../components/ActionBasedButtons";
import { GLOBAL_EMAIL_CONFIG_VALID_FIELDS } from "../../../../common/constants/template";
import { OmitOnChange } from "../../../../common/types/common";
import { ReactNode } from "react";

export default function GlobalEmailConfiguration({
  emailConfig,
  onChange,
  errors,
  mode = ACTIONS.EDIT,
  saveButtonProps,
  additionalCcBccContent,
  ...props
}: {
  emailConfig: GlobalEmailConfigType | null;
  onChange: EmailConfigOnChange;
  errors: EmailConfigErrors;
  mode?: ACTIONS;
  saveButtonProps?: ButtonProps;
  additionalCcBccContent?: ReactNode;
} & OmitOnChange<StackProps>) {
  const isReadOnlyMode = mode === ACTIONS.VIEW;

  return (
    <>
      <EmailConfigureInput
        inputs={emailConfig ?? GLOBAL_EMAIL_CONFIG_INIT}
        onChange={onChange}
        errors={errors}
        overflow="visible"
        w="100%"
        inputPaddingX="0"
        isCodemirror={GLOBAL_EMAIL_CONFIG_IS_CODE}
        flexDirection="column"
        isReadOnly={isReadOnlyMode}
        requiredFields={GLOBAL_EMAIL_CONFIG_VALID_FIELDS}
        ccBccInputChildren={additionalCcBccContent}
        showCcBccWarning={false}
        {...props}
      />

      {saveButtonProps && (
        <IButton
          fontSize={14}
          name="save-configuration"
          {...saveButtonProps}
          hidden={saveButtonProps.hidden || isReadOnlyMode}
        >
          Save configurations
        </IButton>
      )}
    </>
  );
}
