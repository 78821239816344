import { VStack, useDisclosure, Img } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CommonListHeader } from "../../../components/CommonListHeader";
import IButton, { BUTTON } from "../../../components/IButton";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import AddDomainFlow from "./components/AddDomainFlow";
import { DomainListTable } from "./components/DomainListTable";
import InstallTrackingCode from "./components/InstallTrackingCode";
import {
  getDomainList,
  resetDomainList,
  selectSettings,
} from "./settingsSlice";
import domainEmptyState from "../../../common/img/domainEmptyState.png";
import { CODE_BOX_VARIANTS } from "../../../common/constants/settings";
import { useAppDispatch } from "../../../store";
import { useEffect } from "react";
import { isLoading } from "../../../common/helper/commonHelper";
import { FaPlus } from "react-icons/fa6";
import InitialEmptyState from "../../../components/InitialEmptyState";

function WebsiteTrackingEmptyState({ onClick }: { onClick: () => void }) {
  return (
    <InitialEmptyState
      mainText="Let's start with adding your domain for tracking"
      message="No active domains"
      mainTextProps={{
        fontWeight: "600",
        fontSize: "18px",
        color: "unset",
      }}
      messageProps={{
        fontSize: "12px",
        fontWeight: 600,
        color: "gray.400",
        maxW: "400px",
      }}
      containerProps={{ bg: "white", height: "90vh" }}
      additionalActions={
        <IButton px="16px" py="4px" children="Add domain" onClick={onClick} />
      }
    >
      <Img src={domainEmptyState} alt="domain" />
    </InitialEmptyState>
  );
}

function WebsiteTrackingPopulatedState({ hidden }: { hidden: boolean }) {
  return (
    <>
      <DomainListTable />
      <VStack
        bg="white"
        align="left"
        mt={6}
        p={5}
        pt={3}
        px={3}
        borderRadius="md"
        hidden={hidden}
      >
        <InstallTrackingCode variant={CODE_BOX_VARIANTS.TOGGLE} />
      </VStack>
    </>
  );
}

export default function WebsiteTrackingSettings() {
  const {
    onOpen: onOpenAddDomainFlow,
    onClose: onCloseAddDomainFlow,
    isOpen: isOpenAddDomainFlow,
  } = useDisclosure();

  const {
    domainList: { list: domainList, loadingList },
    domainChanged,
  } = useSelector(selectSettings);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (domainChanged) {
      dispatch(resetDomainList());
      dispatch(getDomainList());
    }
  }, [domainChanged, dispatch]);

  return (
    <ContentContainerWithHeader mt={3}>
      <CommonListHeader
        heading="Tracking enabled domains"
        createButtonProps={{
          variant: BUTTON.PRIMARY,
          fontWeight: "400px",
          rounded: "4px",
          text: "Add new domain",
          name: "add-new-domain",
          onClick: onOpenAddDomainFlow,
          leftIcon: <FaPlus fontSize="14px" />,
        }}
        headerStackProps={{
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingBottom: "15px",
        }}
        titleProps={{
          fontSize: "16px",
        }}
      />

      {!domainList?.length && !isLoading(loadingList) ? (
        <WebsiteTrackingEmptyState onClick={onOpenAddDomainFlow} />
      ) : (
        <WebsiteTrackingPopulatedState hidden={!domainList?.length} />
      )}

      <AddDomainFlow
        onClose={onCloseAddDomainFlow}
        isOpen={isOpenAddDomainFlow}
      />
    </ContentContainerWithHeader>
  );
}
