import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormError } from "../../../common/types/form";
import { DataTable } from "../../../components/data-table/DataTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { useAppDispatch } from "../../../store";
import ErrorsSidebar from "./components/ErrorsSidebar";
import { getFormErrors, selectForm, setFormErrorsPage } from "./formSlice";

export default function FormErrors() {
  const dispatch = useAppDispatch();

  const { formErrors, formDetails } = useSelector(selectForm);

  useEffect(() => {
    return function resetPage() {
      dispatch(setFormErrorsPage(1));
    };
  }, [dispatch]);

  useEffect(() => {
    if (formDetails.data.form_id)
      dispatch(
        getFormErrors({
          formId: formDetails.data.form_id,
          source: formDetails.data.vendor,
        })
      );
  }, [
    formDetails.data.form_id,
    formDetails.data.vendor,
    dispatch,
    formErrors.currentPageNo,
  ]);

  const columnHelper = createColumnHelper<FormError>();
  const [selectedSubmission, setSelectedSubmission] =
    useState<FormError | null>(null);

  const columns = useMemo(
    () => [
      columnHelper.accessor("error", {
        header: "Errors",
        minSize: 400,
      }),
      columnHelper.accessor("received_date", {
        header: "Submission date",
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
        minSize: 200,
      }),
    ],
    [columnHelper]
  );

  return (
    <>
      <DataTable
        list={formErrors.list}
        fetchingList={formErrors.fetchingList}
        changingPage={formErrors.changingPage}
        setPage={(pageNo) => dispatch(setFormErrorsPage(pageNo))}
        onRowClick={setSelectedSubmission}
        totalPageCount={formErrors.totalPageCount}
        totalPageSize={formErrors.pageSize}
        currentPage={formErrors.count}
        columns={columns}
        emptyMsg="No errors found"
      />
      <ErrorsSidebar
        selectedError={selectedSubmission}
        onClose={() => setSelectedSubmission(null)}
      />
    </>
  );
}
