import {
  forwardRef,
  HStack,
  Icon,
  IconButton,
  StackProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaExternalLinkAlt, FaPencilAlt } from "react-icons/fa";
import RemoveRowButton from "../../../../../components/RemoveRowButton";

const RowWrapperWithButton = forwardRef(
  (
    {
      onEdit,
      onRemove,
      children,
      readonly,
      ...props
    }: {
      onEdit: () => void;
      onRemove: () => void;
      children?: ReactNode;
      readonly?: boolean;
    } & StackProps,
    ref
  ) => {
    return (
      <HStack
        justifyContent="space-between"
        w="100%"
        backgroundColor="gray.100"
        px={2}
        py={2}
        ref={ref}
        borderRadius="sm"
        {...props}
      >
        <HStack w="calc(100% - 72px)">{children}</HStack>
        <HStack>
          <IconButton
            name="edit-button"
            variant="ghost"
            size="sm"
            icon={
              <Icon
                fontSize="14px"
                as={readonly ? FaExternalLinkAlt : FaPencilAlt}
              />
            }
            onClick={onEdit}
            aria-label="Edit"
          />
          <RemoveRowButton onClick={onRemove} isDisabled={readonly} />
        </HStack>
      </HStack>
    );
  }
);

export default RowWrapperWithButton;
