import { transformJourneyStatusFilter } from "../../helper/filterHelper";
import {
  Campaign,
  CampaignSchedule,
  FullCampaign,
  FlowSet,
  Flow,
  DraftSaveType,
  DraftData,
  DraftPublishType,
  CAMPAIGN_CONTEXT,
  CampaignListSummary,
  AddFlow,
  CampaignListItem,
  CampaignListStats,
} from "../../types/campaign";
import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import {
  FlowApiResType,
  FlowEdgeAddReq,
  UpdateFlowActionReq,
} from "../../types/flow";
import { campaignRequest } from "./api";

export async function listCampaignsApi({
  pageSize,
  pageNo,
  searchKeyword,
  tagsFilter,
  columnsToSearchIn,
  filters,
}: SearchAssetsRequestType): Promise<PaginatedList<CampaignListItem>> {
  return campaignRequest({
    url: "/campaigns/campaign.list",
    data: {
      page_size: pageSize,
      page_number: pageNo,
      query: {
        search: {
          keyword: searchKeyword,
          fields: columnsToSearchIn,
        },
      },
      tags: tagsFilter,
      filters: transformJourneyStatusFilter(filters),
    },
    shouldRetryApiCall: true,
  });
}

export async function getCampaignListStatsApi(
  campaignIds: string[]
): Promise<{ records: CampaignListStats[] }> {
  return campaignRequest({
    url: "/campaigns/campaign.list.stats",
    data: {
      campaign_ids: campaignIds,
    },
  });
}

export async function listAllCampaignApi(): Promise<{
  records: CampaignListSummary[];
}> {
  return campaignRequest({
    method: "POST",
    url: "campaigns/list.all",
    data: {},
  });
}

export async function getCampaignApi(
  campaign_id: string
): Promise<{ campaign: FullCampaign }> {
  return campaignRequest({
    url: "/campaigns/get",
    data: { campaign_id },
    shouldRetryApiCall: true,
  });
}

export async function updateCampaignApi(
  campaign_id: string,
  name: string
): Promise<{ campaign: true }> {
  return campaignRequest({
    url: "/campaigns/update",
    data: { campaign_id, name },
  });
}

export async function deleteCampaignApi(
  campaign_id: string
): Promise<{ campaign: true }> {
  return campaignRequest({
    url: "/campaigns/delete",
    data: { campaign_id },
  });
}

export async function createCampaignApi(
  name: string,
  campaignContext?: CAMPAIGN_CONTEXT
): Promise<{ campaign: Campaign }> {
  return campaignRequest({
    url: "/campaigns/create",
    data: { name, campaign_context: campaignContext },
  });
}

export async function createCampaignScheduleApi(
  campaign: Partial<CampaignSchedule>
): Promise<{ schedule: CampaignSchedule }> {
  return campaignRequest({
    url: "/campaigns/schedule/create",
    data: campaign,
  });
}

export async function updateCampaignScheduleApi(
  campaign: Partial<CampaignSchedule>
): Promise<{ schedule: CampaignSchedule }> {
  return campaignRequest({
    url: "/campaigns/schedule/update",
    data: campaign,
  });
}

export async function savePublishCampaignScheduleApi(
  campaign: Partial<CampaignSchedule>,
  active: boolean
): Promise<{ schedule: CampaignSchedule; active: boolean }> {
  return campaignRequest({
    url: "/campaigns/schedule/save.publish",
    data: { ...campaign, active },
  });
}

export async function addFlowActionApi(flow: AddFlow): Promise<FlowApiResType> {
  return campaignRequest({
    url: "/campaigns/flow/action/add",
    data: flow,
  });
}

export async function removeFlowActionApi(
  campaignId: string,
  actionId: string,
  parentActionId?: string
): Promise<FlowApiResType> {
  return campaignRequest({
    url: "/campaigns/flow/action/remove",
    data: {
      campaign_id: campaignId,
      action_id: actionId,
      parent_action_id: parentActionId,
    },
  });
}

export async function updateFlowActionApi(
  data: UpdateFlowActionReq
): Promise<FlowApiResType> {
  return campaignRequest({
    url: "/campaigns/flow/action/update",
    data: data,
  });
}

export async function addFlowEdgeApi(
  data: FlowEdgeAddReq
): Promise<FlowApiResType> {
  return campaignRequest({
    url: "/campaigns/flow/link.add",
    data,
  });
}

export async function removeFlowEdgeApi(data: {
  campaign_id: string;
  action_id: string;
  parent_action_id?: string;
  condition_id?: string;
}): Promise<FlowApiResType> {
  return campaignRequest({
    url: "/campaigns/flow/link.remove",
    data,
  });
}

export async function savePublishFlowActionApi(
  flow: FlowSet,
  active: boolean
): Promise<{ flow: Flow[]; active: boolean }> {
  return campaignRequest({
    url: "/campaigns/flow/create.publish",
    data: { ...flow, active },
  });
}

export async function publishCampaignApi(
  campaign_id: string,
  active: boolean
): Promise<{ campaign: { active: boolean } }> {
  return campaignRequest({
    url: "/campaigns/publish",
    data: { campaign_id, active },
  });
}

export async function saveDraftApi(
  data: DraftSaveType
): Promise<{ draft: DraftData }> {
  return campaignRequest({
    url: "/campaigns/draft.save",
    data,
    isErrorHandled: true,
  });
}

export async function publishDraftApi(
  campaignId: string
): Promise<DraftPublishType> {
  return campaignRequest({
    url: "/campaigns/draft.publish",
    data: { campaign_id: campaignId },
  });
}

export async function cloneCampaignApi({
  campaignId,
  clonedCampaignName,
}: {
  campaignId: string;
  clonedCampaignName: string;
}): Promise<{ campaign: { campaign_id: string } }> {
  return campaignRequest({
    url: "/campaigns/campaign.clone",
    data: { campaign_id: campaignId, name: clonedCampaignName },
  });
}
