import {
  ASSET_LIST_TYPE,
  COMPARISON_OPERATORS,
  EVENT_CATEGORY,
  FLINK_CEP_OPERARTORS,
  GROUP_OPERATORS,
  LOGICAL_OPERATORS,
  MEMBERSHIP_OPERATOR,
  NODE_GROUP_TYPES,
  TRIGGER_DL_NODE_TYPES,
  DL_TARGET_VALUE_TYPES,
  TIME_FRAME_RELATIVE_OPERATOR,
} from "../constants/dynamicList";
import {
  TIME_FRAME_UNIT,
  TIME_FRAME_TYPE,
  TRIGGER_CAMPAIGN_QUALIFICATION_RULES,
} from "../constants/trigger";
import { OperatorDetails, OperatorType, PropertyType } from "./campaign";
import { DESTINATION_TYPES } from "./unifiedMapping";

// !!info: Most of the types are certain, but enums are uncertain.

export interface Query {
  nodeGroups: NodeGroup[];
}

export interface JourneySettings {
  qualificationFrequency: QualificationFrequency;
  timeframe: TimeFrame;
}

export interface QualificationFrequency {
  type: TRIGGER_CAMPAIGN_QUALIFICATION_RULES;
  interval: INTERVAL | null;
}

export interface INTERVAL {
  unit: TIME_FRAME_UNIT;
  value: number;
}

export interface TimeFrame {
  type: TIME_FRAME_TYPE | null;
  start: number;
  end: number;
  operator: COMPARISON_OPERATORS | null;
  unit: TIME_FRAME_UNIT | null;
}

export type NodeCriteriaTypes =
  | ListMembershipNode
  | ComparisonNode
  | SequencePatternNode;

export interface NodeGroup {
  nodeGroupType: NODE_GROUP_TYPES;
  nodeGroupId?: string;
  logicalOperator: LOGICAL_OPERATORS;
  nodes: NodeCriteriaTypes[];
  groupOperator?: GROUP_OPERATORS | null;
}

export interface NodeGroupWithComparison {
  nodeGroupType: NODE_GROUP_TYPES;
  nodeGroupId: string;
  logicalOperator: LOGICAL_OPERATORS;
  nodes: ComparisonNode[];
  groupOperator?: GROUP_OPERATORS;
}

export interface ListMembershipCondition {
  membershipOperator: MEMBERSHIP_OPERATOR | null;
  listType: ASSET_LIST_TYPE | null;
  listId: string | null;
}

export interface NodeBasic {
  nodeId?: string;
}

export interface ListMembershipNode extends NodeBasic {
  nodeType: TRIGGER_DL_NODE_TYPES.LIST_MEMBERSHIP;
  logicalOperator: LOGICAL_OPERATORS;
  listMembershipCondition: ListMembershipCondition;
}

export type TRIGGER_DL_COMP_NODE_TYPES =
  | TRIGGER_DL_NODE_TYPES.COMPARISON
  | TRIGGER_DL_NODE_TYPES.PERSON
  | TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN;

export interface ComparisonNode extends NodeBasic {
  nodeType: TRIGGER_DL_COMP_NODE_TYPES;
  logicalOperator: LOGICAL_OPERATORS;
  eventName: string | null;
  conditionGroups: ConditionGroup[];
  //present only for marketing or product activity
  eventCategory?: EVENT_CATEGORY;
  timeframe?: TimeframeCondition | null;
  frequencyCondition?: FrequencyCondition | null;
}

export interface SequencePatternNode extends NodeBasic {
  nodeType: TRIGGER_DL_COMP_NODE_TYPES;
  logicalOperator: LOGICAL_OPERATORS;
  eventCategory?: EVENT_CATEGORY;
  patternGroup: Pattern[];
}

export type TargetValueTypes = (string | null)[] | null;

export type DlTimeFrameOperators =
  | TIME_FRAME_RELATIVE_OPERATOR
  | COMPARISON_OPERATORS;

export interface FrequencyCondition {
  comparisonOperator: COMPARISON_OPERATORS | null;
  targetValue: null | (number | null)[];
}

export interface TimeframeCondition {
  type: null | TIME_FRAME_TYPE;
  operator: null | DlTimeFrameOperators;
  unit: null | TIME_FRAME_UNIT;
  start: number | null;
  end: number | null;
}

export interface ConditionGroup {
  conditionGroupId?: string;
  logicalOperator: LOGICAL_OPERATORS;
  conditions: Condition[];
}

export interface Condition {
  logicalOperator: LOGICAL_OPERATORS;
  propertyName: string | null;
  comparisonOperator: COMPARISON_OPERATORS | null;
  targetValueType: null | DL_TARGET_VALUE_TYPES;
  targetValue: TargetValueTypes;
}

export interface Pattern {
  eventName: string | null;
  conditionGroups: ConditionGroup[];
  patternOperators: PatternOperator[];
}

export interface PatternOperator {
  patternOperator: FLINK_CEP_OPERARTORS;
  timeframe: TimeFrame | null;
}

export type OperatorTypeV2 =
  | TIME_FRAME_RELATIVE_OPERATOR
  | COMPARISON_OPERATORS
  | MEMBERSHIP_OPERATOR
  | GROUP_OPERATORS;

export type OperatorDetailsV2 = OperatorDetails & {
  valueType?: DL_TARGET_VALUE_TYPES;
  timeFrameType?: TIME_FRAME_TYPE;
};

export type OperatorListV2 = {
  [key in OperatorType]: { [key in OperatorTypeV2]?: OperatorDetailsV2 };
};

export type TriggerCampaignValidationType = {
  audienceCriteria: string;
  flow: string;
  schedule: string;
  rule: string;
  exitCriteria: string;
  triggerCriteria: string;
};

export const PROPERTY_TYPE_FROM_DESTINATION_TYPE: {
  [key in DESTINATION_TYPES]: PropertyType;
} = {
  [DESTINATION_TYPES.STRING]: OperatorType.STRING,
  [DESTINATION_TYPES.INTEGER]: OperatorType.INTEGER,
  [DESTINATION_TYPES.DATETIME]: OperatorType.DATE,
  [DESTINATION_TYPES.FLOAT]: OperatorType.FLOAT,
  [DESTINATION_TYPES.BOOLEAN]: OperatorType.BOOLEAN,
  [DESTINATION_TYPES.JSON]: OperatorType.JSON,
  [DESTINATION_TYPES.LONG]: OperatorType.INTEGER, // There is no specific operator type for long fields.
};
