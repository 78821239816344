import { AccountContactAutoMappingElement } from "../../types/AccountContactMapping";
import { settingRequest as request } from "./api";

export async function getAccountContactMappingApi(
  settingName: string
): Promise<{ setting_value: AccountContactAutoMappingElement }> {
  return request({
    url: "/feature-setting/general-settings.get",
    data: { setting_name: settingName },
  });
}

export async function deleteAccountContactMappingApi(
  settingName: string
): Promise<{ success: boolean }> {
  return request({
    url: "/feature-setting/general-settings.delete",
    data: { setting_name: settingName },
    shouldRetryApiCall: true,
  });
}

export async function updateAccountContactMappingApi(
  data: AccountContactAutoMappingElement
): Promise<AccountContactAutoMappingElement> {
  return request({
    url: "/feature-setting/contact-account-mapping.update",
    data: data,
  });
}
