import {
  Icon,
  useDisclosure,
  VStack,
  HStack,
  Text,
  Link,
  StackProps,
} from "@chakra-ui/react";
import { useState, useEffect, useCallback, ReactNode } from "react";
import { FaExclamationTriangle, FaPlus } from "react-icons/fa";
import { ConfirmationModal } from "./ConfirmationModal";
import IButton from "./IButton";
import IconWithTooltip from "./IconWithTooltip";
import MultiEmailInput from "./MultiEmailInput";
import {
  EMAIL_RECIPIENT_FIELDS,
  CcBccListType,
  RequiredCcBcc,
} from "../common/types/template";
import {
  EMAIL_CONFIG_FIELDS,
  MAX_CC_BCC_TOTAL_RECIPIENTS,
} from "../common/constants/template";
import {
  transformRecipientDataListToStrings,
  transformStringsToRecipientDataList,
} from "../common/helper/templateHelper";
import { OmitOnChange } from "../common/types/common";
import { useSelector } from "react-redux";
import { selectEmailToken } from "../pages/dashboard/emailtoken/emailTokenSlice";
import { isValidTokenOrColumnAccessor } from "../common/helper/codemirrorHelper";
import { isEmpty } from "lodash";
import { ANTI_SPAM_POLICY_LINK } from "../common/constants/common";

type CcBccEmailInputProps = {
  emailDataList: CcBccListType;
  onChange: (list: CcBccListType) => void;
  errorMsg?: string;
  allowTokens?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showTooltip?: boolean;
  maxRecipients?: number;
} & OmitOnChange<StackProps>;

export const EMAIL_RECIPIENT_INFO = {
  [EMAIL_RECIPIENT_FIELDS.CC]: {
    heading: "Cc",
    tooltip:
      "Improper usage of CC field can harm privacy, email metrics, campaigns, and sender reputation.",
    term: "Cc",
  },
  [EMAIL_RECIPIENT_FIELDS.BCC]: {
    heading: "Bcc",
    tooltip:
      "Improper usage of BCC field can harm privacy, email metrics, campaigns, and sender reputation.",
    term: "Bcc",
  },
  [EMAIL_RECIPIENT_FIELDS.CC_BCC]: {
    heading: "Cc/Bcc",
    tooltip:
      "Improper usage of CC and BCC fields can harm privacy, email metrics, campaigns, and sender reputation.",
    term: "Cc and Bcc",
  },
};

const DEFAULT_BCC_CC_TOKEN_CONFIG = {
  bcc: true,
  cc: true,
};

function isRequiredCcField(field: EMAIL_RECIPIENT_FIELDS) {
  return [EMAIL_RECIPIENT_FIELDS.CC, EMAIL_RECIPIENT_FIELDS.CC_BCC].includes(
    field
  );
}

function isRequiredBccField(field: EMAIL_RECIPIENT_FIELDS) {
  return [EMAIL_RECIPIENT_FIELDS.BCC, EMAIL_RECIPIENT_FIELDS.CC_BCC].includes(
    field
  );
}

function CcBccWarningModal({
  fields,
  isOpen,
  onClose,
  onProceed,
}: {
  fields: EMAIL_RECIPIENT_FIELDS;
  isOpen: boolean;
  onClose: () => void;
  onProceed: () => void;
}) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      onCancel={onClose}
      submitHandler={onProceed}
      title={`${EMAIL_RECIPIENT_INFO[fields].heading} warning`}
      cancelButtonText="Cancel"
      confirmButtonText="Proceed"
      icon={
        <Icon as={FaExclamationTriangle} color="orange" mr={3} fontSize="18" />
      }
      footerContent={
        <Link
          isExternal
          href={ANTI_SPAM_POLICY_LINK}
          fontSize="14px"
          fontWeight="semibold"
          w="70%"
        >
          Privacy and anti-spam policy
        </Link>
      }
    >
      <Text fontSize="14px" fontWeight="semibold" pt={1}>
        Make sure to use {EMAIL_RECIPIENT_INFO[fields].term} fields responsibly
        to protect email privacy and comply with regulations.
      </Text>

      <Text fontSize="14px" pt={3}>
        Misuse may lead to privacy violations and negative impacts on your email
        campaigns, email metrics and sender reputation.
      </Text>
    </ConfirmationModal>
  );
}

export function CcEmailInput({
  emailDataList,
  onChange,
  showTooltip = false,
  errorMsg,
  allowTokens = true,
  isDisabled,
  isReadOnly = false,
  maxRecipients = 200,
}: CcBccEmailInputProps) {
  const [ccList, setCcList] = useState(
    transformRecipientDataListToStrings(emailDataList) ?? []
  );

  const {
    allColumnsList: { data: columnsList },
    emailTokenList: {
      listAll: { data: tokensList },
    },
  } = useSelector(selectEmailToken);

  useEffect(() => {
    setCcList(transformRecipientDataListToStrings(emailDataList) ?? []);
  }, [emailDataList]);

  const isValidToken = useCallback(
    (token: string) => {
      return isValidTokenOrColumnAccessor(token, tokensList, columnsList);
    },
    [tokensList, columnsList]
  );

  const handleSetCcList: React.Dispatch<React.SetStateAction<string[]>> = (
    newList
  ) => {
    setCcList((prevList) => {
      const updatedList =
        typeof newList === "function" ? newList(prevList) : newList;
      onChange(transformStringsToRecipientDataList(updatedList, isValidToken));
      return updatedList;
    });
  };

  return (
    <VStack alignItems="flex-start" spacing={1} w="100%">
      <Text color="text.50" fontSize="xs">
        Cc
      </Text>
      {showTooltip && (
        <IconWithTooltip
          label={EMAIL_RECIPIENT_INFO[EMAIL_RECIPIENT_FIELDS.CC].tooltip}
          color="gray.400"
          fontSize="11px"
          mb={1}
        />
      )}

      <MultiEmailInput
        maxEmails={maxRecipients}
        emailList={ccList}
        setEmailList={handleSetCcList}
        errorMsg={errorMsg}
        allowTokens={allowTokens}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isClearable
      />
    </VStack>
  );
}

export function BccEmailInput({
  emailDataList,
  globalBccEmailData,
  onChange,
  errorMsg,
  showTooltip = false,
  allowTokens = true,
  isDisabled,
  isReadOnly = false,
  maxRecipients = 200,
}: { globalBccEmailData?: CcBccListType } & CcBccEmailInputProps) {
  const [bccList, setBccList] = useState(
    transformRecipientDataListToStrings(emailDataList) ?? []
  );

  const {
    allColumnsList: { data: columnsList },
    emailTokenList: {
      listAll: { data: tokensList },
    },
  } = useSelector(selectEmailToken);

  useEffect(() => {
    setBccList(transformRecipientDataListToStrings(emailDataList) ?? []);
  }, [emailDataList]);

  const isValidToken = useCallback(
    (token: string) => {
      return isValidTokenOrColumnAccessor(token, tokensList, columnsList);
    },
    [tokensList, columnsList]
  );

  const handleSetBccList: React.Dispatch<React.SetStateAction<string[]>> = (
    newList
  ) => {
    setBccList((prevList) => {
      const updatedList =
        typeof newList === "function" ? newList(prevList) : newList;
      onChange(transformStringsToRecipientDataList(updatedList, isValidToken));
      return updatedList;
    });
  };

  return (
    <VStack alignItems="flex-start" spacing={1} w="100%">
      <HStack justifyContent="space-between">
        <Text color="text.50" fontSize="xs">
          Bcc
        </Text>
        {showTooltip && (
          <IconWithTooltip
            label={EMAIL_RECIPIENT_INFO[EMAIL_RECIPIENT_FIELDS.BCC].tooltip}
            color="gray.400"
            fontSize="11px"
            mb={1}
          />
        )}
      </HStack>
      <MultiEmailInput
        readOnlyLabels={
          globalBccEmailData
            ? transformRecipientDataListToStrings(globalBccEmailData) ??
              undefined
            : undefined
        }
        maxEmails={maxRecipients}
        emailList={bccList}
        setEmailList={handleSetBccList}
        errorMsg={errorMsg}
        allowTokens={allowTokens}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isClearable
      />
    </VStack>
  );
}

export default function CcBccEmailInputs({
  ccList,
  bccList,
  onListChange,
  errors,
  globalBccEmailData,
  allowTokenConfig = DEFAULT_BCC_CC_TOKEN_CONFIG,
  requiredField = EMAIL_RECIPIENT_FIELDS.CC_BCC,
  isDisabled,
  isReadOnly = false,
  showTooltip = false,
  showWarning = true,
  children,
}: {
  bccList: CcBccListType;
  ccList: CcBccListType;
  onListChange: (
    name: EMAIL_CONFIG_FIELDS.CC_LIST | EMAIL_CONFIG_FIELDS.BCC_LIST,
    value: CcBccListType
  ) => void;
  errors?: { cc_email_data_set: string; bcc_email_data_set: string };
  globalBccEmailData?: CcBccListType;
  requiredField?: EMAIL_RECIPIENT_FIELDS;
  allowTokenConfig?: RequiredCcBcc;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showTooltip?: boolean;
  showWarning?: boolean;
  children?: ReactNode;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [enableCcBcc, setEnableCcBcc] = useState(false);

  useEffect(() => {
    setEnableCcBcc((prev) => {
      const enabledCcBcc =
        !isEmpty(bccList) || !isEmpty(ccList) || !isEmpty(globalBccEmailData);
      return prev || enabledCcBcc;
    });
  }, [bccList, ccList, globalBccEmailData]);

  function onAddCcBcc() {
    setEnableCcBcc(true);
    onClose();
  }

  const commonListProps = { isDisabled, isReadOnly, showTooltip };
  const totalRecipients =
    (ccList?.length ?? 0) +
    (bccList?.length ?? 0) +
    (globalBccEmailData?.length ?? 0);
  const maxRecipientsAllowed = MAX_CC_BCC_TOTAL_RECIPIENTS - totalRecipients;

  return (
    <>
      <VStack
        alignItems="flex-start"
        my={2}
        w="100%"
        hidden={!enableCcBcc && showWarning}
      >
        {children}

        {isRequiredCcField(requiredField) && (
          <CcEmailInput
            emailDataList={ccList}
            onChange={(list) => {
              onListChange(EMAIL_CONFIG_FIELDS.CC_LIST, list);
            }}
            errorMsg={errors?.cc_email_data_set}
            allowTokens={allowTokenConfig.cc}
            maxRecipients={maxRecipientsAllowed}
            {...commonListProps}
          />
        )}
        {isRequiredBccField(requiredField) && (
          <BccEmailInput
            emailDataList={bccList}
            globalBccEmailData={globalBccEmailData}
            onChange={(list) => {
              onListChange(EMAIL_CONFIG_FIELDS.BCC_LIST, list);
            }}
            allowTokens={allowTokenConfig.bcc}
            errorMsg={errors?.bcc_email_data_set}
            maxRecipients={maxRecipientsAllowed}
            {...commonListProps}
          />
        )}
      </VStack>

      <IButton
        color="brandBlue.500"
        variant="link"
        capitalize={false}
        children={`Add ${EMAIL_RECIPIENT_INFO[requiredField].heading}`}
        hidden={isReadOnly || enableCcBcc || !showWarning}
        leftIcon={<FaPlus />}
        onClick={onOpen}
        my={2}
      />

      <CcBccWarningModal
        isOpen={isOpen}
        fields={requiredField}
        onClose={onClose}
        onProceed={onAddCcBcc}
      />
    </>
  );
}
