import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { personLookupApi } from "../../../common/api/campaign/person";
import {
  cloneTemplateApi,
  createTemplateApi,
  deleteCustomRowApi,
  deleteTemplateApi,
  getTemplateApi,
  getVisualTemplateJsonApi,
  listCustomRowsApi,
  listTemplatesApi,
  listVisualTemplatesApi,
  loginBeeApi,
  saveCustomRowApi,
  sendPreviewEmailApi,
  updateCustomRowApi,
  updateTemplateApi,
  updateTemplateNameApi,
} from "../../../common/api/campaign/template";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  createAsyncThunkWrapper,
  initializeInfiniteList,
  initializeLoadingData,
  isLoading,
} from "../../../common/helper/commonHelper";
import {
  CAMPAIGN_CONTEXT,
  EmailPreviewParams,
} from "../../../common/types/campaign";
import { PersonLookup, PERSON_ORG_MAPPING } from "../../../common/types/person";
import {
  FilterInfiniteListType,
  InfiniteListType,
  LoadingWithData,
  PaginationType,
  SearchAssetWithPage,
} from "../../../common/types/common";
import {
  BeeAuthType,
  SavedRow,
  SavedRowBasic,
  SenderMeta,
  TemplateAdditionalProperties,
  TemplateBase,
  TemplateExhaustive,
  TEMPLATE_EDITOR_MODE,
  VisualTemplateData,
  TemplateType,
} from "../../../common/types/template";
import { RootState } from "../../../store";
import {
  EMAIL_CONFIG_DATA_INIT,
  PREVIEW_MODES,
} from "../../../common/constants/template";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import {
  SortAssetsRequestsType,
  SORT_BY_OPTIONS,
  SORT_ORDER_OPTIONS,
} from "../../../components/SortFilter";

const COLUMNS_TO_SEARCH_TEMPLATE_PREVIEW = ["name", "subject"];

type TemplateSortAndPreview = SortAssetsRequestsType & {
  isPreview?: boolean;
};

function getPreviewMode(isPreview?: boolean) {
  return isPreview ? PREVIEW_MODES.PREVIEW : PREVIEW_MODES.LIST;
}

function transformTemplateDataForUpdateReq(template: TemplateType) {
  const { bcc_email_data_set, cc_email_data_set, ...rest } = template;
  return {
    ...rest,
    bcc_email_data_list: bcc_email_data_set,
    cc_email_data_list: cc_email_data_set,
  };
}

interface TemplateState {
  templateList: PaginationType<
    TemplateBase & SenderMeta & TemplateAdditionalProperties
  >;
  templateCollection: LoadingWithData<{ [key: string]: TemplateExhaustive }>;
  fullTemplateList: LoadingWithData<(TemplateBase & SenderMeta)[] | null>;
  templatesChanged: boolean;
  creatingTemplate: boolean;
  updatingTemplate: LOADING_STATES;
  templateCreatedId: string;
  isTemplateDirty: boolean;
  templateDetails: LoadingWithData<TemplateExhaustive>;
  templateName: string;
  personLookup: LoadingWithData<{ [key: string]: PersonLookup[] }>;
  cloningTemplateId: string;
  sendingPreviewEmail: boolean;

  visualTemplateList: LoadingWithData<VisualTemplateData[]>;
  beeAuth: LoadingWithData<BeeAuthType>;
  savedRows: {
    list: LoadingWithData<SavedRow[]>;
  };
  infiniteTemplateList: InfiniteListType<TemplateExhaustive>;
}

const TemplateDetailsInit: TemplateExhaustive = {
  content: "",
  template_id: "",
  type: "",
  ...EMAIL_CONFIG_DATA_INIT,
  created_at: "",
  created_by: {
    id: 0,
    name: "",
  },
  updated_at: "",
  content_json: "",
  updated_by: {
    id: 0,
    name: "",
  },
  template_type: TEMPLATE_EDITOR_MODE.CODE,
  previews: {
    img_thumb: "",
    img_large: "",
  },
  bcc_email_data_set: [],
  global_bcc_email_data_set: [],
  cc_email_data_set: [],
};

const initialState: TemplateState = {
  templateList: INITIAL_PAGINATION,
  templateCollection: initializeLoadingData({}),
  fullTemplateList: initializeLoadingData(null),
  templatesChanged: false,
  creatingTemplate: false,
  isTemplateDirty: false,
  updatingTemplate: LOADING_STATES.INIT,
  templateCreatedId: "",
  cloningTemplateId: "",
  templateDetails: initializeLoadingData(TemplateDetailsInit),
  templateName: "",
  personLookup: initializeLoadingData({}),
  sendingPreviewEmail: false,
  visualTemplateList: initializeLoadingData([]),
  beeAuth: initializeLoadingData({}),
  savedRows: {
    list: initializeLoadingData([]),
  },
  infiniteTemplateList: initializeInfiniteList({}),
};

const TEMPLATE_LIST_ALL_ACTION = "template/list-all";

export const listAllTemplates = createAsyncThunkWrapper({
  actionName: TEMPLATE_LIST_ALL_ACTION,
  dispatchFn: async () => {
    // TODO update pagination API to fetch all results
    // Or change the UI and API to support dropdown with server side search
    return await listTemplatesApi({
      pageSize: 10000,
      pageNo: 1,
      searchKeyword: "",
      columnsToSearchIn: [""],
      sortByOption: SORT_BY_OPTIONS.LAST_MODIFIED_AT,
      sortOrder: SORT_ORDER_OPTIONS.DESC,
    });
  },
  isCachable: true,
});

export const listTemplates = createAsyncThunk(
  "template/list",
  async (
    {
      pageNo,
      searchKeyword,
      columnsToSearchIn,
      isPreview = false,
      sortByOption = SORT_BY_OPTIONS.LAST_MODIFIED_AT,
      sortOrder = SORT_ORDER_OPTIONS.DESC,
      filters,
    }: SearchAssetWithPage & TemplateSortAndPreview,
    thunkApi
  ) => {
    const {
      template: {
        templateList: { pageSize },
      },
    } = thunkApi.getState() as RootState;

    return await listTemplatesApi({
      pageSize,
      pageNo,
      view: getPreviewMode(isPreview),
      searchKeyword,
      columnsToSearchIn,
      sortByOption,
      sortOrder,
      filters,
    });
  }
);

export const getTemplate = createAsyncThunk(
  "template/get",
  async ({ id, isPreview = false }: { id: string; isPreview?: boolean }) => {
    return await getTemplateApi({
      template_id: id,
      view: getPreviewMode(isPreview),
    });
  }
);

export const addToTemplateCollection = createAsyncThunk(
  "template/add-to-collection",
  async (id: string) => {
    const response = await getTemplateApi({
      template_id: id,
      view: PREVIEW_MODES.PREVIEW,
    });
    return response;
  },
  {
    condition: (id, { getState }) => {
      const {
        template: {
          templateCollection: { loading, data },
        },
      } = getState() as RootState;
      if (data[id] || isLoading(loading)) {
        return false;
      }
    },
  }
);

export const deleteTemplate = createAsyncThunk(
  "template/delete",
  async (id: string, { dispatch }) => {
    const response = await deleteTemplateApi(id);
    dispatch(expireApiStatus([{ actionName: TEMPLATE_LIST_ALL_ACTION }]));
    return response;
  }
);

export const updateTemplate = createAsyncThunk(
  "template/update",
  async (template: TemplateType, { dispatch }) => {
    const response = await updateTemplateApi(
      transformTemplateDataForUpdateReq(template)
    );
    dispatch(expireApiStatus([{ actionName: TEMPLATE_LIST_ALL_ACTION }]));
    return response;
  }
);

export const updateTemplateName = createAsyncThunk(
  "template/name.update",
  async (
    { templateId, name }: { templateId: string; name: string },
    { dispatch }
  ) => {
    const response = await updateTemplateNameApi(templateId, name);
    dispatch(expireApiStatus([{ actionName: TEMPLATE_LIST_ALL_ACTION }]));
    return response;
  }
);

export const createTemplate = createAsyncThunk(
  "template/create",
  async (
    {
      name,
      templateType,
    }: {
      name: string;
      templateType: TEMPLATE_EDITOR_MODE;
    },
    { dispatch }
  ) => {
    const response = await createTemplateApi(name, templateType);
    dispatch(expireApiStatus([{ actionName: TEMPLATE_LIST_ALL_ACTION }]));
    return response;
  }
);

export const lookupPerson = createAsyncThunk(
  "template/person-lookup",
  async (email: string, { getState }) => {
    const { settings } = getState() as RootState;
    const campaignContext =
      settings.personOrgMapping === PERSON_ORG_MAPPING.ONE_TO_NONE
        ? CAMPAIGN_CONTEXT.PERSON
        : CAMPAIGN_CONTEXT.ORG;
    return await personLookupApi(email, campaignContext);
  }
);

export const sendPreviewEmail = createAsyncThunk(
  "template/send-email-preview",
  async ({
    data,
    campaignContext,
    persons,
  }: {
    data: EmailPreviewParams;
    campaignContext: CAMPAIGN_CONTEXT;
    persons: Omit<PersonLookup, "email">[];
  }) => {
    return await sendPreviewEmailApi(data, campaignContext, persons);
  }
);

export const listVisualTemplates = createAsyncThunk(
  "template/visual.list",
  async (_) => {
    return await listVisualTemplatesApi();
  }
);

export const fetchVisualTemplateJson = createAsyncThunk(
  "template/visual.json",
  async (name: string) => {
    return await getVisualTemplateJsonApi(name);
  }
);

export const authenticateBee = createAsyncThunk(
  "template/bee.auth",
  async () => {
    return await loginBeeApi();
  },
  {
    condition: (_, { getState }) => {
      const {
        template: {
          beeAuth: { loading },
        },
      } = getState() as RootState;

      if (isLoading(loading)) {
        return false;
      }
    },
  }
);

export const cloneTemplate = createAsyncThunk(
  "template/clone",
  async (id: string, { dispatch }) => {
    const response = await cloneTemplateApi(id);
    dispatch(expireApiStatus([{ actionName: TEMPLATE_LIST_ALL_ACTION }]));
    return response;
  }
);

export const saveCustomRow = createAsyncThunk(
  "template/bee/save-custom-row",
  async (data: SavedRowBasic) => {
    return await saveCustomRowApi(data);
  }
);

export const updateCustomRow = createAsyncThunk(
  "template/bee/update-custom-row",
  async (data: SavedRow) => {
    return await updateCustomRowApi(data);
  }
);

export const listSavedRows = createAsyncThunk(
  "template/bee/list-saved-rows",
  async () => {
    return await listCustomRowsApi();
  }
);

export const deleteCustomRow = createAsyncThunk(
  "template/bee/delete-saved-row",
  async (id: string) => {
    return await deleteCustomRowApi(id);
  }
);

export const listInfiniteTemplates = createAsyncThunk(
  "template/list-infinite",
  async (
    {
      searchKeyword,
      columnsToSearchIn = COLUMNS_TO_SEARCH_TEMPLATE_PREVIEW,
      sortByOption = SORT_BY_OPTIONS.LAST_MODIFIED_AT,
      sortOrder = SORT_ORDER_OPTIONS.DESC,
      isPreview = false,
      isFilterUpdated = false,
    }: FilterInfiniteListType & TemplateSortAndPreview,
    { dispatch, getState }
  ) => {
    //when any new filter is applied we have to reset the list.
    if (isFilterUpdated) {
      dispatch(resetInfiniteTemplateList());
    }
    const {
      template: {
        infiniteTemplateList: { pageSize, currentPageNo: pageNo },
      },
    } = getState() as RootState;

    return await listTemplatesApi({
      pageSize,
      pageNo,
      view: getPreviewMode(isPreview),
      searchKeyword,
      columnsToSearchIn,
      sortByOption,
      sortOrder,
    });
  }
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setIsTemplateDirty(state, action: PayloadAction<boolean>) {
      state.isTemplateDirty = action.payload;
    },
    clearTemplateCreatedId(state) {
      state.templateCreatedId = "";
    },
    resetPersonLookup(state) {
      state.personLookup = initializeLoadingData({});
    },
    resetTemplateDetails(state) {
      state.templateDetails = initializeLoadingData(TemplateDetailsInit);
      state.templateName = "";
    },
    resetTemplateList(state) {
      state.templateList = initialState.templateList;
    },
    resetTemplateCollection(state) {
      state.templateCollection = initialState.templateCollection;
    },
    setTemplateContent(state, action: PayloadAction<{ json: string }>) {
      state.templateDetails.data.content_json = action.payload.json;
    },
    setCloningTemplateId(state, action: PayloadAction<string>) {
      state.cloningTemplateId = action.payload;
    },
    setUpdatingTemplate(state, action: PayloadAction<LOADING_STATES>) {
      state.updatingTemplate = action.payload;
    },
    resetInfiniteTemplateList(state) {
      state.infiniteTemplateList = initializeInfiniteList({});
    },
    setInfiniteTemplateListPageNo(
      state,
      { payload: pageNo }: PayloadAction<number>
    ) {
      if (
        pageNo &&
        pageNo <= (state.infiniteTemplateList.totalPageCount ?? 1)
      ) {
        state.infiniteTemplateList.currentPageNo = pageNo;
      } else {
        state.infiniteTemplateList.currentPageNo = 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      // Add to Template Collection
      .addCase(addToTemplateCollection.pending, (state) => {
        state.templateCollection.loading = LOADING_STATES.LOADING;
      })
      .addCase(addToTemplateCollection.fulfilled, (state, action) => {
        const { bcc_data, ...rest } = action.payload.template;
        state.templateCollection.data = {
          ...state.templateCollection.data,
          ...{
            [action.payload.template.template_id]: {
              ...rest,
              bcc_email_data_set: bcc_data.template_emails_set,
            },
          },
        };
        state.templateCollection.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(addToTemplateCollection.rejected, (state) => {
        state.templateCollection.loading = LOADING_STATES.FAILED;
      })
      // List Templates
      .addCase(listTemplates.pending, (state, action) => {
        state.templateList.fetchingList = true;
        state.templateList.currentPageNo = action.meta.arg.pageNo;
      })
      .addCase(listTemplates.fulfilled, (state, action) => {
        state.templateList.list = action.payload.records;
        state.templateList.totalPageCount = action.payload.page_count;
        state.templateList.fetchingList = false;
        state.templateList.changingPage = false;
        state.templatesChanged = false;
      })
      .addCase(listTemplates.rejected, (state) => {
        state.templateList.changingPage = false;
        state.templateList.fetchingList = false;
      })
      // List All Templates
      .addCase(listAllTemplates.pending, (state) => {
        state.fullTemplateList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllTemplates.fulfilled, (state, action) => {
        state.fullTemplateList.data = action.payload.records;
        state.fullTemplateList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listAllTemplates.rejected, (state) => {
        state.fullTemplateList.loading = LOADING_STATES.FAILED;
      })
      // Update Template
      .addCase(updateTemplate.pending, (state) => {
        state.updatingTemplate = LOADING_STATES.LOADING;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.updatingTemplate = LOADING_STATES.SUCCESS;
        state.templatesChanged = true;
        const { name, ...rest } = action.payload.template;
        state.templateDetails.data = { ...state.templateDetails.data, ...rest };
        state.templateName = name!;
        state.isTemplateDirty = false;
        toast.success("Email updated successfully");
      })
      .addCase(updateTemplate.rejected, (state) => {
        state.updatingTemplate = LOADING_STATES.FAILED;
      })
      // Create Template
      .addCase(createTemplate.pending, (state) => {
        state.creatingTemplate = true;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.creatingTemplate = false;
        state.templateCreatedId = action.payload.template.template_id;
        toast.success("New email added successfully");
      })
      .addCase(createTemplate.rejected, (state) => {
        state.creatingTemplate = false;
      })
      // Update template name
      .addCase(updateTemplateName.pending, (state) => {
        state.updatingTemplate = LOADING_STATES.LOADING;
      })
      .addCase(updateTemplateName.fulfilled, (state, action) => {
        state.updatingTemplate = LOADING_STATES.SUCCESS;
        state.templateName = action.meta.arg.name;
        state.templatesChanged = true;
        toast.success("Email name updated successfully");
      })
      .addCase(updateTemplateName.rejected, (state) => {
        state.updatingTemplate = LOADING_STATES.FAILED;
      })
      // Get Template
      .addCase(getTemplate.pending, (state) => {
        state.templateDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        const { name, bcc_data, ...rest } = action.payload.template;
        state.templateDetails.data = {
          ...rest,
          bcc_email_data_set: bcc_data.template_emails_set,
          global_bcc_email_data_set: bcc_data.global_emails_set,
        };
        state.templateName = name!;
        state.templateDetails.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getTemplate.rejected, (state) => {
        state.templateDetails.loading = LOADING_STATES.FAILED;
      })
      // Delete Template
      .addCase(deleteTemplate.fulfilled, (state) => {
        state.templatesChanged = true;
      })

      // Person Lookup
      .addCase(lookupPerson.pending, (state) => {
        state.personLookup.loading = LOADING_STATES.LOADING;
      })
      .addCase(lookupPerson.fulfilled, (state, action) => {
        state.personLookup.data = {
          ...state.personLookup.data,
          ...{ [action.meta.arg]: action.payload.rows },
        };
        state.personLookup.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(lookupPerson.rejected, (state) => {
        state.personLookup.loading = LOADING_STATES.FAILED;
      })

      // Send email preview
      .addCase(sendPreviewEmail.pending, (state) => {
        state.sendingPreviewEmail = true;
      })
      .addCase(sendPreviewEmail.fulfilled, (state) => {
        state.sendingPreviewEmail = false;
        toast.success("Email preview sent successfully");
      })
      .addCase(sendPreviewEmail.rejected, (state) => {
        state.sendingPreviewEmail = false;
      })

      // List Visual Templates
      .addCase(listVisualTemplates.pending, (state) => {
        state.visualTemplateList.loading = LOADING_STATES.LOADING;
      })
      .addCase(listVisualTemplates.fulfilled, (state, action) => {
        state.visualTemplateList.data = action.payload.response;
        state.visualTemplateList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listVisualTemplates.rejected, (state) => {
        state.visualTemplateList.loading = LOADING_STATES.FAILED;
      })

      // BEE auth
      .addCase(authenticateBee.pending, (state) => {
        state.beeAuth.loading = LOADING_STATES.LOADING;
      })
      .addCase(authenticateBee.fulfilled, (state, action) => {
        state.beeAuth.data = action.payload;
        state.beeAuth.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(authenticateBee.rejected, (state) => {
        state.beeAuth.loading = LOADING_STATES.FAILED;
      })

      // clone template
      .addCase(cloneTemplate.fulfilled, (state) => {
        state.cloningTemplateId = "";
      })
      .addCase(cloneTemplate.rejected, (state) => {
        state.cloningTemplateId = "";
      })

      // save custom row
      .addCase(saveCustomRow.fulfilled, (state) => {
        toast.success("Row saved successfully");
        state.savedRows.list.loading = LOADING_STATES.INIT;
      })
      .addCase(saveCustomRow.rejected, (_) => {
        toast.success("Row saving was unsuccessful");
      })

      // delete custom row
      .addCase(deleteCustomRow.fulfilled, (state) => {
        toast.success("Row deleted successfully");
        state.savedRows.list.loading = LOADING_STATES.INIT;
      })
      .addCase(deleteCustomRow.rejected, (_) => {
        toast.success("Row deletion was unsuccessful");
      })

      // list saved rows
      .addCase(listSavedRows.pending, (state) => {
        state.savedRows.list.loading = LOADING_STATES.LOADING;
      })
      .addCase(listSavedRows.fulfilled, (state, action) => {
        state.savedRows.list.data = action.payload.records;
        state.savedRows.list.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listSavedRows.rejected, (state) => {
        state.savedRows.list.loading = LOADING_STATES.FAILED;
      })
      .addCase(listInfiniteTemplates.pending, (state) => {
        state.infiniteTemplateList.loadingList = LOADING_STATES.LOADING;
      })
      .addCase(listInfiniteTemplates.fulfilled, (state, { payload }) => {
        state.infiniteTemplateList.list = state.infiniteTemplateList.list
          ? [...state.infiniteTemplateList.list, ...payload.records]
          : payload.records;
        state.infiniteTemplateList.count = payload.record_count;
        state.infiniteTemplateList.totalPageCount = payload.page_count;
        state.infiniteTemplateList.loadingList = LOADING_STATES.SUCCESS;
      })
      .addCase(listInfiniteTemplates.rejected, (state) => {
        state.infiniteTemplateList.loadingList = LOADING_STATES.FAILED;
      });
  },
});

export const {
  clearTemplateCreatedId,
  resetPersonLookup,
  resetTemplateDetails,
  resetTemplateList,
  setTemplateContent,
  setCloningTemplateId,
  setIsTemplateDirty,
  setUpdatingTemplate,
  resetTemplateCollection,
  resetInfiniteTemplateList,
  setInfiniteTemplateListPageNo,
} = templateSlice.actions;

export const selectTemplate = (state: RootState) => state.template;

export default templateSlice.reducer;
