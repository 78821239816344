import {
  Badge,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { isBefore } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  getSalesforceConnectionDetailsApi,
  remapSalesforcePersonApi,
} from "../../../../common/api/integrations/salesforceLegacyService";
import {
  isFulfilled,
  isInit,
  isLoading,
  isSuccess,
  openSalesforceInstance,
} from "../../../../common/helper/commonHelper";
import { MarketingPersonListItem } from "../../../../common/types/campaign";
import { LoadingWithData } from "../../../../common/types/common";
import { SOURCES } from "../../../../common/types/unifiedMapping";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import IButton, { BUTTON } from "../../../../components/IButton";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { useAppDispatch } from "../../../../store";
import { getSalesforceConnectionDetails } from "../../connection/salesforceLegacyService/salesforceLegacySlice";
import {
  getSalesforcePersonSyncError,
  listMarketingPerson,
  remapSalesforcePerson,
  resyncSalesforcePerson,
  selectPerson,
} from "../personDbSlice";

enum BUTTON_ACTION {
  RESYNC = "resync",
  EDIT = "edit",
  CANCEL = "cancel",
  SAVE = "save",
}

function findSelectedPerson(
  marketingPersonList: LoadingWithData<MarketingPersonListItem[]>
) {
  return marketingPersonList.data.find((x) => x._is_selected) ?? null;
}

function EditPersonMapping({
  email,
  count,
  selectedPerson,
  radioOnchangeHandler,
  marketingPersonList,
  instanceUrl,
}: {
  email: string;
  count: number;
  selectedPerson: MarketingPersonListItem | null;
  radioOnchangeHandler: (val: string) => void;
  marketingPersonList: LoadingWithData<MarketingPersonListItem[]>;
  instanceUrl: string;
}) {
  return (
    <VStack alignItems="flex-start" spacing="3" fontSize="sm">
      <Text>{`${email} has ${count} associated record(s) in Salesforce`}</Text>

      <RadioGroup
        value={selectedPerson?.id}
        onChange={(val) => radioOnchangeHandler(val)}
      >
        <VStack alignItems="flex-start" py="2">
          {marketingPersonList.data.map((x) => (
            <Radio value={x.id}>
              <LinkedRecordItem data={x} instanceUrl={instanceUrl} />
            </Radio>
          ))}
        </VStack>
      </RadioGroup>
    </VStack>
  );
}

function ReadonlyPersonMapping({
  email,
  selectedPerson,
  instanceUrl,
  count,
  marketingPersonList,
}: {
  email: string;
  selectedPerson: MarketingPersonListItem | null;
  instanceUrl: string;
  count: number;
  marketingPersonList: LoadingWithData<MarketingPersonListItem[]>;
}) {
  return (
    <VStack alignItems="flex-start" spacing="3" fontSize="sm">
      <HStack spacing="1">
        <HStack spacing="1">
          <Text color="gray.800">{email}</Text>
        </HStack>
        {selectedPerson ? (
          <HStack spacing="1">
            <Text>is currently linked to</Text>
            <LinkedRecordItem data={selectedPerson} instanceUrl={instanceUrl} />
          </HStack>
        ) : (
          <Text>has 0 associated record(s) in Salesforce</Text>
        )}
      </HStack>

      {count > 1 && (
        <VStack alignItems="flex-start" bg="gray.100" p="2" px="4">
          <Text>{`Note: There ${count - 1 > 1 ? `are ` : `is `} ${
            count - 1
          } other record(s) associated with this email`}</Text>
          {marketingPersonList.data
            .filter((x) => x.id !== selectedPerson?.id)
            .map((x) => (
              <LinkedRecordItem data={x} instanceUrl={instanceUrl} />
            ))}
        </VStack>
      )}
    </VStack>
  );
}

function ButtonBar({
  isReadMode,
  onClick,
}: {
  isReadMode: boolean;
  onClick: (action: BUTTON_ACTION) => void;
}) {
  return (
    <Flex
      w="100%"
      justifyContent="flex-end"
      position="absolute"
      top="-58px"
      right="-20px"
    >
      {isReadMode ? (
        <ButtonGroup>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={() => onClick(BUTTON_ACTION.RESYNC)}
          >
            Resync
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={() => onClick(BUTTON_ACTION.EDIT)}
          >
            Edit
          </IButton>
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          <IButton
            variant={BUTTON.SECONDARY}
            onClick={() => onClick(BUTTON_ACTION.CANCEL)}
          >
            Cancel
          </IButton>
          <IButton
            variant={BUTTON.PRIMARY}
            onClick={() => onClick(BUTTON_ACTION.SAVE)}
          >
            Save
          </IButton>
        </ButtonGroup>
      )}
    </Flex>
  );
}

function SalesforceSync({
  lastUpdated,
  email,
}: {
  lastUpdated: string;
  email: string;
}) {
  const dispatch = useAppDispatch();
  const { syncErrorList } = useSelector(selectPerson);
  const [syncUptodate, setSyncUptodate] = useState(true);

  useEffect(() => {
    dispatch(getSalesforcePersonSyncError({ email }));
  }, [dispatch, email]);

  useEffect(() => {
    if (syncErrorList.data.length) {
      if (
        isBefore(
          new Date(syncErrorList.data[0].last_occurred),
          new Date(lastUpdated)
        )
      ) {
        setSyncUptodate(true);
      } else {
        setSyncUptodate(false);
      }
    }
  }, [syncErrorList.data, lastUpdated]);

  return (
    <VStack alignItems="flex-start">
      <Heading fontSize="md">Salesforce sync</Heading>
      <HStack>
        Sync status:
        <SpinnerContainer
          loading={isLoading(syncErrorList.loading)}
          height="24px"
          width="100%"
        >
          {syncUptodate ? (
            <Text fontSize="sm"> Up-to-date </Text>
          ) : (
            <HStack>
              <Text color="red.300" fontSize="16px">
                Sync error
              </Text>
              <Tooltip label={syncErrorList.data[0]?.error_text} fontSize="sm">
                <Center ml="2">
                  <Icon color="gray.300" fontSize="sm" as={FaInfoCircle}></Icon>
                </Center>
              </Tooltip>
            </HStack>
          )}
        </SpinnerContainer>
      </HStack>
    </VStack>
  );
}

function LinkedRecordItem({
  data,
  instanceUrl,
}: {
  data: MarketingPersonListItem | null;
  instanceUrl: string;
}) {
  return (
    data && (
      <HStack bg="grayV2.100" px="2">
        <IButton
          color={"blue.600"}
          variant="link"
          rightIcon={<FaExternalLinkAlt />}
          onClick={() =>
            openSalesforceInstance({
              url: instanceUrl,
              crmObject: data.crm_object,
              salesforceId: data.id as string,
            })
          }
        >
          {data.name ?? "empty"}
        </IButton>
        <Badge>SF {data.crm_object}</Badge>
        <HStack>
          <Text fontWeight="semibold">ID:</Text> <Text>{data.id}</Text>
        </HStack>
      </HStack>
    )
  );
}

function SalesforceObjects({
  email,
  selectedPerson,
  onChange,
  marketingPersonList,
  isReadMode,
}: {
  email: string;
  selectedPerson: MarketingPersonListItem | null;
  onChange: (x: MarketingPersonListItem) => void;
  marketingPersonList: LoadingWithData<MarketingPersonListItem[]>;
  isReadMode: boolean;
}) {
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(marketingPersonList.data.length);
  const [instanceUrl, setInstanceUrl] = useState("");

  const fetchInstanceUrl = useCallback(
    async (connectionId: string) => {
      const rest = await dispatch(getSalesforceConnectionDetails(connectionId));
      if (isFulfilled(rest.meta.requestStatus)) {
        const newSfConnectionDetails = rest.payload as Awaited<
          ReturnType<typeof getSalesforceConnectionDetailsApi>
        >;
        setInstanceUrl(newSfConnectionDetails.connection?.instance_url || "");
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (marketingPersonList.data.length) {
      fetchInstanceUrl(marketingPersonList.data[0].connection_id);
    }
    setCount(marketingPersonList.data.length);
  }, [dispatch, fetchInstanceUrl, marketingPersonList.data]);

  function radioOnchangeHandler(val: string): void {
    const person = marketingPersonList.data.find((x) => x.id === val);
    if (person) {
      onChange(person);
    }
  }

  return (
    <VStack mt="5" alignItems="flex-start" w="100%">
      <Heading fontSize="md">Salesforce sync objects</Heading>
      <SpinnerContainer
        width="100%"
        loading={isLoading(marketingPersonList.loading)}
      >
        {isReadMode ? (
          <ReadonlyPersonMapping
            email={email}
            selectedPerson={selectedPerson}
            instanceUrl={instanceUrl}
            count={count}
            marketingPersonList={marketingPersonList}
          />
        ) : (
          <EditPersonMapping
            email={email}
            count={count}
            selectedPerson={selectedPerson}
            radioOnchangeHandler={radioOnchangeHandler}
            marketingPersonList={marketingPersonList}
            instanceUrl={instanceUrl}
          />
        )}
      </SpinnerContainer>
    </VStack>
  );
}

export default function SalesforceLegacySyncTab({ email }: { email: string }) {
  const [isReadonlyMode, setIsReadonlyMode] = useState(true);
  const [selectedPerson, setSelectedPerson] =
    useState<MarketingPersonListItem | null>(null);

  const dispatch = useAppDispatch();
  const { marketingPersonList } = useSelector(selectPerson);

  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const cancelRef = useRef(null);

  useEffect(() => {
    if (isInit(marketingPersonList.loading)) {
      dispatch(listMarketingPerson({ email, source: SOURCES.SALESFORCE }));
    } else if (isSuccess(marketingPersonList.loading)) {
      if (marketingPersonList.data.length) {
        setSelectedPerson(findSelectedPerson(marketingPersonList));
      }
    }
  }, [dispatch, email, marketingPersonList]);

  async function save() {
    if (selectedPerson) {
      const result = await dispatch(
        remapSalesforcePerson({ email, sfId: selectedPerson.id })
      );
      if (isFulfilled(result.meta.requestStatus)) {
        const resultPayload = result.payload as Awaited<
          ReturnType<typeof remapSalesforcePersonApi>
        >;
        if (resultPayload.success) {
          setIsReadonlyMode(true);
          setIsOpenConfirmationModal(false);
        }
      }
    }
  }

  function resync() {
    dispatch(resyncSalesforcePerson(email));
  }

  function edit() {
    setIsReadonlyMode(false);
  }

  async function cancel() {
    setSelectedPerson(findSelectedPerson(marketingPersonList));
    setIsReadonlyMode(true);
  }

  function buttonClickHandler(action: BUTTON_ACTION) {
    switch (action) {
      case BUTTON_ACTION.RESYNC:
        resync();
        break;
      case BUTTON_ACTION.EDIT:
        edit();
        break;
      case BUTTON_ACTION.SAVE:
        setIsOpenConfirmationModal(true);
        break;
      case BUTTON_ACTION.CANCEL:
        cancel();
        break;
    }
  }

  return (
    <Flex
      flexDir="column"
      w="100%"
      p="2"
      alignItems="flex-start"
      position="relative"
    >
      <ButtonBar isReadMode={isReadonlyMode} onClick={buttonClickHandler} />
      <SalesforceSync
        email={email}
        lastUpdated={selectedPerson?._modified_time ?? ""}
      />
      <SalesforceObjects
        onChange={setSelectedPerson}
        selectedPerson={selectedPerson}
        marketingPersonList={marketingPersonList}
        email={email}
        isReadMode={isReadonlyMode}
      />
      <ConfirmationModal
        title="Change mapping"
        cancelButtonText="Cancel"
        confirmButtonText="Change"
        isOpen={isOpenConfirmationModal}
        cancelRef={cancelRef}
        onCancel={() => setIsOpenConfirmationModal(false)}
        submitHandler={save}
      >
        <Text color="gray.600">
          Are you sure you want to change Inflection person mapping?
        </Text>
      </ConfirmationModal>
    </Flex>
  );
}
