import { Box } from "@chakra-ui/layout";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AccountMember } from "../../../../common/types/account";
import { PaginationType } from "../../../../common/types/common";
import { DataTable } from "../../../../components/data-table/DataTable";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import { listPerson, selectPerson } from "../../persondb/personDbSlice";
import { setAccountMembersPage } from "../accountDbSlice";

const COLUMNS_TO_SEARCH_IN = ["email", "first_name", "last_name"];

export default function AccountMembersPage({
  accountMembersList,
}: {
  accountMembersList: PaginationType<AccountMember>;
}) {
  const columnHelper = createColumnHelper<AccountMember>();
  const dispatch = useAppDispatch();
  const {
    person: { list },
  } = useSelector(selectPerson);

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        size: 500,
      }),
      columnHelper.accessor("first_name", {
        header: "First name",
        size: 250,
      }),
      columnHelper.accessor("last_name", {
        header: "Last name",
        size: 250,
      }),
      columnHelper.accessor("title", {
        header: "Title",
        size: 250,
      }),
    ],
    [columnHelper]
  );

  //TODO: Check why we need the person list api call here.
  useEffect(() => {
    if (!list) {
      dispatch(
        listPerson({
          pageNo: 1,
          searchKeyword: "",
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
        })
      );
    }
  }, [dispatch, list]);

  function redirectToContact(accountMember: AccountMember) {
    if (accountMember?.id) {
      window.open(`${urls.person}/${accountMember.id}`, "_blank");
    }
  }

  return (
    <Box w="100%">
      <DataTable
        fetchingList={accountMembersList.fetchingList}
        changingPage={accountMembersList.changingPage}
        list={accountMembersList.list}
        totalPageCount={accountMembersList.totalPageCount}
        currentPage={accountMembersList.currentPageNo}
        totalPageSize={accountMembersList.pageSize}
        setPage={(pageNo) => dispatch(setAccountMembersPage(pageNo))}
        columns={columns}
        emptyMsg={"No members found"}
        onRowClick={redirectToContact}
      />
    </Box>
  );
}
