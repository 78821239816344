import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import { BsLightningChargeFill } from "react-icons/bs";
import { FILTER_TYPE } from "../../../../../common/constants/campaign";

const OUTER_GROUP_MENUS = [
  // Remove contact updated filter - sc8865
  // {
  //   label: "Contact field updated",
  //   type: FILTER_TYPE.PERSON,
  // },
  {
    label: "Product event",
    type: FILTER_TYPE.PRODUCT_ACTIVITY,
  },
  {
    label: "Marketing event",
    type: FILTER_TYPE.MARKETING_ACTIVITY,
  },
];

export default function AddConditionButton({
  onClick,
}: {
  onClick: (type: FILTER_TYPE) => void;
}) {
  return (
    <Menu>
      <MenuButton
        size={"sm"}
        as={Button}
        bg="grayV2.100"
        color="brand.blue"
        borderColor="brand.blue"
        borderWidth="1px"
        _hover={{ bg: "grayV2.200" }}
        _active={{ bg: "grayV2.200" }}
        leftIcon={<BsLightningChargeFill color="brand.blue" fontSize="12px" />}
      >
        Add event
      </MenuButton>
      <MenuList>
        {OUTER_GROUP_MENUS.map((option, index) => {
          return (
            <MenuItem
              fontSize="14px"
              onClick={() => onClick(option.type)}
              name={`add-${option.type}`}
              key={index}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
