import { Icon } from "@chakra-ui/icon";
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Slide,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  CAMPAIGN_CONTEXT,
  EmailTokenDetails,
  EmailTokenPreviewData,
} from "../../../../common/types/campaign";
import { PERSON_ORG_MAPPING } from "../../../../common/types/person";
import { PreviewPerson } from "../../../../common/types/token";
import { useAppDispatch } from "../../../../store";
import { selectSettings } from "../../settings/settingsSlice";
import {
  getEmailTokenPreview,
  lookupPersonForToken,
  resetPersonLookup,
  selectEmailToken,
} from "../emailTokenSlice";
import TokenPreviewData from "./TokenPreviewData";
import { toast } from "react-toastify";
import {
  PersonLookupInput,
  PERSON_ORG_EMAIL_INIT,
} from "../../../../components/PersonLookupInput";
import {
  isLoading,
  isSuccess,
  isFulfilled,
} from "../../../../common/helper/commonHelper";

function UnsavedChangeWarning({ hidden }: { hidden: boolean }) {
  return (
    <Slide direction="bottom" in={!hidden} style={{ zIndex: 10 }}>
      <HStack
        p="8px"
        mt="4"
        bg="orange.100"
        rounded="sm"
        shadow="md"
        fontSize="sm"
      >
        <Icon as={FaExclamationCircle} color="orange.500" />
        <Text fontWeight="semibold">Token preview not available now!</Text>
        <Text>
          Token previews are updated while saving it. Try saving to update
          preview.
        </Text>
      </HStack>
    </Slide>
  );
}

function PreviewBody({
  row,
  returnType,
  isPersonOrg,
}: {
  row: EmailTokenPreviewData;
  returnType: string;
  isPersonOrg: boolean;
}) {
  return (
    <Tr borderBottom="1px" borderColor="gray.100" verticalAlign="top">
      <Td>{row.email}</Td>
      <Td>
        <TokenPreviewData data={row.token_value} returnType={returnType} />
      </Td>
      {Object.values(row.column).map((col) => (
        <Td>{String(col)}</Td>
      ))}
      {Object.values(row.token).map((col) => (
        <Td>{String(col)}</Td>
      ))}
      {isPersonOrg && row.product_user_id && row.org_id && (
        <Td>
          {row.product_user_id} ({row.org_id})
        </Td>
      )}
    </Tr>
  );
}

function PreviewTable({
  previewData,
  tokenDetails,
}: {
  previewData: EmailTokenPreviewData[];
  tokenDetails: EmailTokenDetails | null;
}) {
  const { personOrgMapping } = useSelector(selectSettings);

  function getKeys(
    preview: EmailTokenPreviewData[],
    field: "column" | "token"
  ) {
    return Object.keys(preview[0][field]);
  }

  if (isEmpty(previewData)) {
    return (
      <Center h="calc(100vh - 565px)" minH="200px">
        <Text color="gray.500">Add an email to see token preview </Text>
      </Center>
    );
  } else {
    return (
      <Table fontSize="sm" style={{ tableLayout: "fixed" }}>
        <Thead bg="grayV2.100">
          <Tr>
            <Th>Person</Th>
            <Th>Token</Th>
            {getKeys(previewData, "column").map((col) => (
              <Th color="green.500">{col}</Th>
            ))}
            {getKeys(previewData, "token").map((col) => (
              <Th color="teal.500">{col}</Th>
            ))}
            {personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE && (
              <Th color="teal.500">User and Org</Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {previewData.map((row, index) => (
            <PreviewBody
              row={row}
              returnType={tokenDetails?.return_type ?? ""}
              isPersonOrg={personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE}
              key={index}
            />
          ))}
        </Tbody>
      </Table>
    );
  }
}

function PreviewHeader({ isFetchingDetails }: { isFetchingDetails?: boolean }) {
  const dispatch = useAppDispatch();
  const [personOrgEmail, setPersonOrgEmail] = useState(PERSON_ORG_EMAIL_INIT);

  const {
    emailTokenPreview: { personIds: personLookup, preview, fetchingPreview },
    emailTokenEdit,
  } = useSelector(selectEmailToken);

  async function showPreview() {
    if (!emailTokenEdit.tokenDetails?.token) {
      toast.warning("Token should be updated and saved before preview");
      return;
    }
    let personData: PreviewPerson[] | undefined =
      personLookup.data[personOrgEmail.personEmail];
    if (personOrgEmail.orgUserCheck && personOrgEmail.selectedOrg) {
      const [id, org] = personOrgEmail.selectedOrg;
      const data = personData.find(
        (person) => person.org_id === org && person.product_user_id === id
      );
      if (data) {
        const previewFind = preview.find(
          (person) =>
            person.org_id === org &&
            person.product_user_id === id &&
            person.email === data.email
        );
        personData = previewFind
          ? []
          : [{ ...data, campaign_context: CAMPAIGN_CONTEXT.ORG }];
      }
    }
    const result = await dispatch(getEmailTokenPreview(personData));
    if (isFulfilled(result.meta.requestStatus)) {
      dispatch(resetPersonLookup());
    }
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" px="2">
      <Heading p="2" fontSize="md">
        Preview Table
      </Heading>
      <PersonLookupInput
        personLookup={personLookup}
        personOrgEmail={personOrgEmail}
        setPersonOrgEmail={setPersonOrgEmail}
        lookUpPersonThunk={lookupPersonForToken}
        fetchingPreview={fetchingPreview}
        onPreview={showPreview}
        isButtonDisabled={
          emailTokenEdit.isTokenDefinitionDirty ||
          emailTokenEdit.isTokenHeaderDirty
        }
        resetData={true}
        previewButtonText="Add to preview"
        stackProps={{ justifyContent: "flex-end" }}
        isFetchingDetails={isFetchingDetails}
      />
    </Flex>
  );
}

export default function TokenPreview() {
  const {
    emailTokenPreview: { preview },
    emailTokenEdit: {
      isTokenDefinitionDirty,
      tokenDetails,
      fetchingTokenDetails,
    },
  } = useSelector(selectEmailToken);

  return (
    <Box
      m="4"
      bg="white"
      h="calc(100vh - 515px)"
      minH="300px"
      rounded="md"
      borderWidth="1px"
      borderColor="gray.200"
      overflow="auto"
    >
      <PreviewHeader isFetchingDetails={isLoading(fetchingTokenDetails)} />
      <PreviewTable previewData={preview} tokenDetails={tokenDetails} />
      <UnsavedChangeWarning
        hidden={
          !(isTokenDefinitionDirty && !isEmpty(preview)) ||
          !isSuccess(fetchingTokenDetails)
        }
      />
    </Box>
  );
}
