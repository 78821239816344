import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  CAMPAIGN_QUALIFICATION_RULES,
  CAMPAIGN_LIST_STATS_INIT,
  CAMPAIGN_LIST_ALL_ACTION,
  DL_LIST_ALL_ACTION,
} from "../../../common/constants/campaign";
import {
  Campaign,
  DraftData,
  DraftSendDataType,
  FullCampaign,
  CAMPAIGN_CONTEXT,
} from "../../../common/types/campaign";
import { RootState } from "../../../store";
import { LOADING_STATES } from "../../../common/constants/common";
import {
  initialisePaginationWithPageNo,
  initializeLoadingData,
  isLoading,
} from "../../../common/helper/commonHelper";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetsType,
} from "../../../common/types/common";
import {
  createCampaignApi,
  deleteCampaignApi,
  getCampaignApi,
  publishCampaignApi,
  publishDraftApi,
  saveDraftApi,
  updateCampaignApi,
  cloneCampaignApi,
  listCampaignsApi,
  getCampaignListStatsApi,
} from "../../../common/api/campaign/campaign";
import { USER_ACCOUNT_STATUS } from "../../../common/types/auth";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";

interface CampaignState {
  campaignList: PaginationType<Campaign>;
  savingDraft: boolean;
  campaignsChanged: boolean;
  creatingCampaign: LOADING_STATES;
  campaignCreatedId: string;

  campaignDetails: LoadingWithData<FullCampaign>;
  campaignTags: string[];

  publishingCampaign: boolean;
  editingFlowEdge: boolean;

  activeErrorCheck: boolean;
}

const initDraftData: DraftData = {
  updated_by: null,
  flow: null,
  warning: null,
  schedule: null,
  rule: null,
  updated_at: null,
  dynamic_list: null,
  exit_criteria: null,
};

const initCampaignDetails: FullCampaign = {
  campaign_id: "",
  dynamic_list: null,
  flow: null,
  name: "",
  draft: false,
  schedule: null,
  created_by: { id: 0, name: "" },
  created_at: "",
  version: 0,
  active: true,
  updated_at: "",
  rule: {
    rule: CAMPAIGN_QUALIFICATION_RULES.ONCE,
    recurring_period: null,
    recurring_period_unit: null,
    version: 0,
    created_time: "",
    updated_time: "",
    allow_re_entry: false,
    recurring: false,
    recurring_period_days: null,
  },
  draft_data: initDraftData,
  account_status: USER_ACCOUNT_STATUS.INACTIVE,
  exit_criteria: null,
  is_deleted: false,
};

const initialState: CampaignState = {
  campaignList: initialisePaginationWithPageNo(150),
  savingDraft: false,
  campaignsChanged: false,
  creatingCampaign: LOADING_STATES.INIT,
  campaignCreatedId: "",

  campaignDetails: initializeLoadingData(initCampaignDetails),
  campaignTags: [],

  publishingCampaign: false,
  editingFlowEdge: false,

  activeErrorCheck: false,
};

export const listCampaigns = createAsyncThunk(
  "campaign/list",
  async (
    { searchKeyword, columnsToSearchIn, tagsFilter }: SearchAssetsType,
    thunkApi
  ) => {
    const {
      campaign: {
        campaignList: { pageSize, currentPageNo: pageNo },
      },
    } = thunkApi.getState() as RootState;
    return await listCampaignsApi({
      pageSize,
      pageNo,
      searchKeyword,
      columnsToSearchIn,
      tagsFilter,
    });
  }
);

export const getCampaignListStats = createAsyncThunk(
  "campaign/list-item-stats",
  async (campaignIds: string[]) => {
    return await getCampaignListStatsApi(campaignIds);
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/create",
  async (
    {
      name,
      campaignContext,
    }: {
      name: string;
      campaignContext?: CAMPAIGN_CONTEXT;
    },
    { dispatch }
  ) => {
    const response = await createCampaignApi(name, campaignContext);
    dispatch(expireApiStatus([{ actionName: CAMPAIGN_LIST_ALL_ACTION }]));
    return response;
  },
  {
    condition: (_, { getState }) => {
      const { campaign } = getState() as RootState;
      if (isLoading(campaign.creatingCampaign)) {
        return false;
      }
    },
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/delete",
  async (id: string, { dispatch }) => {
    const response = await deleteCampaignApi(id);
    dispatch(
      expireApiStatus([
        { actionName: CAMPAIGN_LIST_ALL_ACTION },
        { actionName: DL_LIST_ALL_ACTION },
      ])
    );
    return response;
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/update",
  async ({ id, name }: { id: string; name: string }) => {
    return await updateCampaignApi(id, name);
  }
);

export const getCampaign = createAsyncThunk(
  "campaign/get",
  async (id: string) => {
    return await getCampaignApi(id);
  }
);

export const publishCampaign = createAsyncThunk(
  "campaign/publish-campaign",
  async ({ active }: { active: boolean }, { dispatch, getState }) => {
    const { campaign } = getState() as RootState;
    const response = await publishCampaignApi(
      campaign.campaignDetails.data.campaign_id,
      active
    );
    dispatch(
      expireApiStatus([
        { actionName: CAMPAIGN_LIST_ALL_ACTION },
        { actionName: DL_LIST_ALL_ACTION },
      ])
    );
    return response;
  }
);

export const saveCampaignDraft = createAsyncThunk(
  "campaign/dynamic-list/save-draft",
  async (data: DraftSendDataType, thunkApi) => {
    const { campaign } = thunkApi.getState() as RootState;
    thunkApi.dispatch(setSavingDraft(true));
    return saveDraftApi({
      campaign_id: campaign.campaignDetails.data.campaign_id,
      draft: data,
    });
  },
  {
    condition: (_, { getState }) => {
      const { campaign } = getState() as RootState;
      if (!campaign.campaignDetails.data.campaign_id) {
        return false;
      }
    },
  }
);

export const publishCampaignDraft = createAsyncThunk(
  "campaign/dynamic-list/publish-draft",
  async (_, thunkApi) => {
    const { campaign } = thunkApi.getState() as RootState;
    return publishDraftApi(campaign.campaignDetails.data.campaign_id);
  }
);

export const cloneCampaign = createAsyncThunk(
  "campaign/clone",
  async (
    { campaignId, name }: { campaignId: string; name: string },
    { dispatch }
  ) => {
    const response = await cloneCampaignApi({
      campaignId,
      clonedCampaignName: name,
    });
    dispatch(
      expireApiStatus([
        { actionName: CAMPAIGN_LIST_ALL_ACTION },
        { actionName: DL_LIST_ALL_ACTION },
      ])
    );
    return response;
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.campaignList.totalPageCount ?? 1)) {
        state.campaignList.currentPageNo = pageNo;
        state.campaignList.changingPage = true;
      } else {
        state.campaignList.currentPageNo = 1;
      }
    },
    setCampaignDetails(state, action: PayloadAction<FullCampaign>) {
      state.campaignDetails.data = action.payload;
    },
    clearCampaignCreatedId(state) {
      state.campaignCreatedId = "";
    },
    clearCampaignDetails(state) {
      state.campaignDetails = initializeLoadingData(initCampaignDetails);
      state.activeErrorCheck = false;
    },
    clearCampaignList(state) {
      state.campaignList = initialState.campaignList;
    },
    setActiveErrorCheck(state, action) {
      state.activeErrorCheck = action.payload;
    },
    setPublishLoadState(state, action) {
      state.publishingCampaign = action.payload;
    },
    setSavingDraft(state, action: PayloadAction<boolean>) {
      state.savingDraft = action.payload;
    },
    setCampaignTags(state, action: PayloadAction<string[]>) {
      state.campaignTags = action.payload;
    },
    clearCampaignChangedFlag(state) {
      state.campaignsChanged = false;
    },
    setCampaignStatusToDraft(
      state,
      action: PayloadAction<{
        updatedAt: string;
        updatedBy: string;
      }>
    ) {
      state.campaignDetails.data.active = false;
      state.campaignDetails.data.draft = true;
      state.campaignDetails.data.draft_data.updated_at =
        action.payload.updatedAt;
      state.campaignDetails.data.draft_data.updated_by = {
        id: Number(action.payload.updatedBy),
        name: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // campaign list
      .addCase(listCampaigns.pending, (state) => {
        state.campaignList.fetchingList = true;
      })
      .addCase(listCampaigns.fulfilled, (state, action) => {
        state.campaignList.list = action.payload.records.map((campaign) => {
          return {
            ...campaign,
            ...CAMPAIGN_LIST_STATS_INIT,
          };
        });
        state.campaignList.totalPageCount = action.payload.page_count;
        state.campaignList.fetchingList = false;
        state.campaignList.changingPage = false;
      })
      .addCase(listCampaigns.rejected, (state) => {
        state.campaignList.totalPageCount = 0;
        state.campaignList.fetchingList = false;
        state.campaignList.changingPage = false;
      })

      // campaign list stats
      .addCase(getCampaignListStats.pending, (state) => {
        state.campaignList.list =
          state.campaignList.list?.map((campaign) => {
            campaign.stats_loading = LOADING_STATES.LOADING;
            return campaign;
          }) ?? null;
      })
      .addCase(getCampaignListStats.fulfilled, (state, action) => {
        state.campaignList.list =
          state.campaignList.list?.map((campaign) => {
            const stats =
              action.payload.records.find(
                (cStats) => cStats.campaign_id === campaign.campaign_id
              ) ?? {};
            return {
              ...campaign,
              ...stats,
              stats_loading: LOADING_STATES.SUCCESS,
            };
          }) ?? null;
      })
      .addCase(getCampaignListStats.rejected, (state) => {
        state.campaignList.list =
          state.campaignList.list?.map((campaign) => {
            campaign.stats_loading = LOADING_STATES.FAILED;
            return campaign;
          }) ?? null;
      })

      // Create campaign
      .addCase(createCampaign.pending, (state) => {
        state.creatingCampaign = LOADING_STATES.LOADING;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.creatingCampaign = LOADING_STATES.SUCCESS;
        state.campaignCreatedId = action.payload.campaign.campaign_id;
        toast.success("New journey added");
      })
      .addCase(createCampaign.rejected, (state) => {
        state.creatingCampaign = LOADING_STATES.FAILED;
      })
      // Delete Campaign
      .addCase(deleteCampaign.fulfilled, (state) => {
        state.campaignsChanged = true;
        toast.success("Journey removed");
      })
      .addCase(deleteCampaign.rejected, () => {})
      // Update Campaign
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.campaignsChanged = true;
        state.campaignDetails.data.name = action.meta.arg.name;
        toast.success("Journey renamed");
      })
      .addCase(updateCampaign.rejected, () => {})

      // Get campaign details
      .addCase(getCampaign.pending, (state) => {
        state.campaignDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.campaignDetails.loading = LOADING_STATES.SUCCESS;
        state.campaignDetails.data = {
          ...{ draft_data: initDraftData },
          ...action.payload.campaign,
        };
        state.campaignTags = action.payload.campaign.tags ?? [];
      })
      .addCase(getCampaign.rejected, (state) => {
        state.campaignDetails.data = initCampaignDetails;
        state.campaignDetails.loading = LOADING_STATES.FAILED;
      })

      // Publish Campaign
      .addCase(publishCampaign.pending, (state) => {
        state.publishingCampaign = true;
      })
      .addCase(publishCampaign.fulfilled, (state, action) => {
        const data = action.payload;
        state.campaignDetails.data.active = data.campaign.active;
        state.campaignDetails.data.updated_at = new Date().toString();
        state.campaignDetails.data.draft = false;
        state.campaignDetails.data.draft_data = initDraftData;
        state.publishingCampaign = false;
        if (state.campaignDetails.data.active) {
          state.campaignDetails.data.activated_at = new Date().toString();
        }
        toast.success(
          `Journey published as ${data.campaign.active ? "active" : "inactive"}`
        );
      })
      .addCase(publishCampaign.rejected, (state) => {
        state.publishingCampaign = false;
      })

      // Drafts
      .addCase(saveCampaignDraft.fulfilled, (state, action) => {
        const { updated_by, updated_at, warning } = action.payload.draft;
        state.campaignDetails.data.draft_data.updated_by = updated_by;
        state.campaignDetails.data.draft_data.updated_at = updated_at;
        state.campaignDetails.data.draft_data.warning = warning;
        state.campaignDetails.data.draft = true;
        state.savingDraft = false;
      })
      .addCase(saveCampaignDraft.pending, (state, action) => {
        state.savingDraft = true;
      })
      .addCase(saveCampaignDraft.rejected, (state, action) => {
        state.savingDraft = false;
      })

      //clone campaign
      .addCase(cloneCampaign.pending, (state) => {
        state.creatingCampaign = LOADING_STATES.LOADING;
      })
      .addCase(cloneCampaign.fulfilled, (state, { payload }) => {
        state.creatingCampaign = LOADING_STATES.SUCCESS;
        if (payload.campaign.campaign_id) {
          state.campaignCreatedId = payload.campaign.campaign_id;
          toast.success("Journey cloned successfully");
        }
      })
      .addCase(cloneCampaign.rejected, (state) => {
        state.creatingCampaign = LOADING_STATES.FAILED;
        toast.error("Journey cloning failed");
      });
  },
});

export const {
  setCampaignsPage,
  clearCampaignCreatedId,
  clearCampaignDetails,
  clearCampaignList,
  setActiveErrorCheck,
  setCampaignDetails,
  setPublishLoadState,
  setSavingDraft,
  setCampaignTags,
  setCampaignStatusToDraft,
  clearCampaignChangedFlag,
} = campaignSlice.actions;

export const selectCampaign = (state: RootState) => state.campaign;

export default campaignSlice.reducer;
