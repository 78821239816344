import {
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IMergeTag } from "@mailupinc/bee-plugin/dist/types/bee";
import { toLower } from "lodash";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { VARIABLE_TYPE } from "../../../../common/constants/campaign";
import IModal from "../../../../components/IModal";

export function SearchToken({
  resolve,
  reject,
  variables,
  onClose,
}: {
  resolve: (value: IMergeTag) => void;
  reject: () => void;
  variables: (IMergeTag & { type: VARIABLE_TYPE })[];
  onClose: () => void;
}) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const newVariables = variables.filter((variable) =>
    toLower(variable.name).includes(toLower(searchKeyword))
  );

  return (
    <IModal
      isOpen={true}
      onClose={() => {
        reject();
        onClose();
      }}
      header={{ title: "Add token" }}
      size="lg"
    >
      <VStack>
        <InputGroup size="sm" border="none" pb={4}>
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FaSearch} color="gray.400" />}
          />
          <Input
            placeholder="Search token"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            border="none"
            autoFocus
          />
        </InputGroup>
        <Text fontSize="md" color="gray.400" alignSelf="flex-start" pl={2}>
          Click to add token or column to the editor
        </Text>
        <VStack width="100%" maxH="50vh" overflowY="auto" overflowX="hidden">
          {newVariables.length ? (
            newVariables.map((tokenDetails, index) => (
              <HStack
                onClick={() => {
                  if (resolve) {
                    onClose();
                    resolve(tokenDetails);
                  }
                }}
                justifyContent="space-between"
                w="100%"
                borderBottom="1px solid"
                p={2}
                borderRadius="2px"
                borderColor="gray.200"
                margin="5px 5px !important"
                cursor="pointer"
                _hover={{
                  backgroundColor: "gray.100",
                }}
                key={index}
              >
                <Text whiteSpace="normal" wordBreak="break-all" maxW="70%">
                  {tokenDetails.name}
                </Text>
                <Tag backgroundColor="brand.black" color="white">
                  {tokenDetails.type}
                </Tag>
              </HStack>
            ))
          ) : (
            <VStack backgroundColor="grayV2.100" p={4} w="100%">
              <Text>No tokens available</Text>
            </VStack>
          )}
        </VStack>
      </VStack>
    </IModal>
  );
}
