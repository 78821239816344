import { Box, Flex, HStack, VStack, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isInit } from "../../../../../common/helper/commonHelper";
import BolderText from "../../components/BolderText";
import { SyncStatsHeader } from "../../components/SyncStatsHeader";
import { SyncStats } from "../../components/SyncStats";
import SubHeader from "../../components/SubHeader";
import {
  selectSalesforceLegacy,
  getSyncHistoryDetails,
  getSalesforceConnectionDetails,
} from "../salesforceLegacySlice";

export default function SalesforceLegacySyncDetails({
  connectionId,
}: {
  connectionId: string;
}) {
  const dispatch = useDispatch();
  const { syncHistoryDetails, connection } = useSelector(
    selectSalesforceLegacy
  );

  useEffect(() => {
    if (isInit(syncHistoryDetails.loading) && connectionId) {
      dispatch(getSyncHistoryDetails(connectionId));
    }
  }, [dispatch, syncHistoryDetails, connectionId]);

  function refreshAction() {
    if (connectionId) {
      dispatch(getSyncHistoryDetails(connectionId));
      dispatch(getSalesforceConnectionDetails(connectionId));
    }
  }

  return (
    <>
      {syncHistoryDetails.data && (
        <Box p="5" bg="white" rounded="md">
          <SubHeader refreshAction={refreshAction} title="Sync details" />
          <Box border="1px" borderColor="gray.100" rounded="lg" mt="2">
            <Flex
              justifyContent="space-between"
              px={5}
              py={2}
              borderBottomWidth="1px"
              borderColor="gray.100"
              bg="grayV2.100"
              color="gray.500"
              fontWeight="medium"
              fontSize="xs"
            >
              {connection?.next_schedule_time ? (
                <Text>
                  Next sync on{" "}
                  <BolderText>
                    {format(new Date(connection.next_schedule_time), "PPpp")}
                  </BolderText>
                </Text>
              ) : (
                <Text>
                  Next sync is <BolderText>not scheduled</BolderText>
                </Text>
              )}

              {connection?.last_synced_time && (
                <HStack spacing="1">
                  <Text>Last sync completed on</Text>
                  <BolderText>
                    {/* TODO Ask why these values need to be fetched from connection instead of syncHistoryDetails */}
                    {format(new Date(connection.last_synced_time), "PPpp")}
                  </BolderText>
                </HStack>
              )}
            </Flex>
            <Flex alignItems="flex-end" bg="white">
              <VStack
                width="300px"
                alignItems="left"
                p={5}
                spacing="5"
                borderRightWidth="1px"
                borderColor="gray.200"
              >
                <SyncStatsHeader title="Last Sync Updates (24 hours)" />
                <SyncStats
                  label="New Records"
                  value={
                    syncHistoryDetails.data.total_new_account_count +
                    syncHistoryDetails.data.total_new_contact_count +
                    syncHistoryDetails.data.total_new_lead_count +
                    syncHistoryDetails.data.total_new_opportunity_count
                  }
                />
              </VStack>
              <VStack alignItems="left" flex={1} p={5} spacing="5">
                <HStack spacing="10">
                  <SyncStats
                    label="Total Records"
                    value={
                      syncHistoryDetails.data.total_lead_count +
                      syncHistoryDetails.data.total_account_count +
                      syncHistoryDetails.data.total_contact_count +
                      syncHistoryDetails.data.total_opportunity_count
                    }
                  />
                  <SyncStats
                    label="Total person objects"
                    value={
                      syncHistoryDetails.data.total_contact_count +
                      syncHistoryDetails.data.total_lead_count
                    }
                  />
                  <SyncStats
                    label="Total account objects"
                    value={syncHistoryDetails.data.total_account_count}
                  />
                  <SyncStats
                    label="Total opportunity objects"
                    value={syncHistoryDetails.data.total_opportunity_count}
                    isComingSoon={true}
                  />
                </HStack>
              </VStack>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
}
