import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaTrashAlt } from "react-icons/fa";
import { Handle, Position } from "reactflow";
import { HANDLE_IDS } from "./constants";

export const GotoLeftHandle = memo(
  ({ className = "goto-handle" }: { className?: string }) => {
    return (
      <Handle
        type="target"
        position={Position.Left}
        id={HANDLE_IDS.LEFT_TARGET}
        isConnectable={false}
        className={className}
      />
    );
  }
);

export const GotoRightHandle = memo(
  ({ className = "goto-handle" }: { className?: string }) => {
    return (
      <Handle
        type="target"
        position={Position.Right}
        id={HANDLE_IDS.RIGHT_TARGET}
        isConnectable={false}
        className={className}
      />
    );
  }
);

export const FlowStepSourceHandle = memo(
  ({ className }: { className?: string }) => {
    return (
      <Handle
        type="source"
        position={Position.Bottom}
        id={HANDLE_IDS.BOTTOM_SOURCE}
        isConnectable={false}
        className={className}
      />
    );
  }
);

export const FlowStepTargetHandle = memo(
  ({ className }: { className?: string }) => {
    return (
      <Handle
        type="target"
        position={Position.Top}
        id={HANDLE_IDS.TOP_TARGET}
        isConnectable={false}
        className={className}
      />
    );
  }
);

export const ExitCriteriaHandle = memo(
  ({
    handleId,
    isHidden,
    index,
    onClick,
    isDisabled,
    onRemoveExitCriteria,
    isReadOnly,
  }: {
    handleId: string;
    isHidden: boolean;
    index: number;
    isDisabled: boolean;
    onClick: () => void;
    onRemoveExitCriteria: () => void;
    isReadOnly?: boolean;
  }) => {
    const isLeftSource = useMemo(
      () => handleId.includes(HANDLE_IDS.LEFT_SOURCE),
      [handleId]
    );
    if (handleId) {
      return (
        <Handle
          type="source"
          position={isLeftSource ? Position.Left : Position.Right}
          id={handleId}
          isConnectable={false}
          className="exit-criteria-handle"
          style={{
            top: `${((index * 2 + 2) * 100) / 8}%`,
            transform: `translate( ${isLeftSource ? "-12px" : "16px"}, -50%)`,
          }}
        >
          {!isHidden && (
            <Flex
              role="group"
              bgColor="gray.200"
              borderRadius="25px"
              width="fit-content"
              transform={`translate( ${isLeftSource ? "0px" : "-32px"}, -16px)`}
              flexDirection={isLeftSource ? "row-reverse" : "row"}
              justifyContent="space-between"
              alignItems="center"
              gap="8px"
              _hover={{
                transform: `translate( ${
                  isLeftSource ? "-40px" : "-32px"
                }, -16px)`,
              }}
              py="2px"
            >
              <IconButton
                icon={<Icon as={BsFillLightningChargeFill} fontSize="16px" />}
                aria-label={`exit-criteria-${handleId}`}
                color="white"
                size="sm"
                variant="solid"
                colorScheme="yellow"
                onClick={onClick}
                rounded="full"
                isDisabled={isDisabled}
              />
              <IconButton
                rounded="full"
                size="sm"
                icon={<Icon as={FaTrashAlt} color="white" fontSize="16px" />}
                aria-label="remove-exit"
                display="none"
                _groupHover={{ display: "flex" }}
                onClick={onRemoveExitCriteria}
                variant="solid"
                colorScheme="red"
                isDisabled={isDisabled || isReadOnly}
              />
            </Flex>
          )}
        </Handle>
      );
    } else return <></>;
  }
);
