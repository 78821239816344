import {
  VStack,
  useDisclosure,
  Container,
  ButtonGroup,
  HStack,
  Divider,
  Text,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import { useEffect } from "react";
import { WarehouseTypeDetails } from "../../../../../common/types/common";
import { Connection } from "../../../../../common/types/connection";
import IButton, { BUTTON } from "../../../../../components/IButton";
import ITitle from "../../../../../components/ITitle";
import ConnectionDetailsForm from "../../components/ConnectionDetailsForm";
import SubHeader from "../../components/SubHeader";

function EditConnectionModal({
  connection,
  serviceDetails,
  isOpen,
  onClose,
  updatingConnection,
}: {
  serviceDetails: WarehouseTypeDetails;
  connection: Connection;
  isOpen: boolean;
  onClose: () => void;
  updatingConnection: boolean;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ConnectionDetailsForm
          connectionDetails={connection}
          serviceDetails={serviceDetails}
          loading={updatingConnection}
          closeModal={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

function ConnectionDetailsStat({
  label,
  value,
}: {
  label: string;
  value: string;
}) {
  return (
    <VStack flex="1" alignItems="left" p="3">
      <ITitle
        fontSize="sm"
        fontWeight="semibold"
        color="gray.500"
        title={label}
      />
      <Text fontSize="md" fontWeight="semibold">
        {value}
      </Text>
    </VStack>
  );
}

export default function ConnectionDetailsTab({
  updatingConnection,
  connectionDetails,
  serviceDetails,
}: {
  updatingConnection: boolean;
  serviceDetails: WarehouseTypeDetails;
  connectionDetails: Connection;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!updatingConnection) {
      onClose();
    }
  }, [updatingConnection, onClose]);

  return (
    <Container maxW="container.lg" py={5}>
      <SubHeader title="Connection Details">
        <ButtonGroup>
          <IButton
            variant={BUTTON.PRIMARY}
            name="edit-connection"
            onClick={onOpen}
          >
            Edit Connection
          </IButton>
        </ButtonGroup>
      </SubHeader>

      <VStack
        alignItems="left"
        border="1px"
        borderColor="gray.200"
        p="5"
        spacing="4"
      >
        <HStack>
          <ConnectionDetailsStat
            label="Connection name"
            value={connectionDetails.label}
          />
          <ConnectionDetailsStat
            label="Platform"
            value={capitalize(connectionDetails.typ)}
          />
        </HStack>
        <Divider />
        <HStack>
          <ConnectionDetailsStat
            label="Host name"
            value={connectionDetails.host_name}
          />
          {connectionDetails.port && (
            <ConnectionDetailsStat
              label="Port"
              value={String(connectionDetails.port ?? "")}
            />
          )}
        </HStack>
        <HStack>
          {connectionDetails.database_name && (
            <ConnectionDetailsStat
              label="Database name"
              value={connectionDetails.database_name}
            />
          )}
          {connectionDetails.user_name && (
            <ConnectionDetailsStat
              label="Username"
              value={connectionDetails.user_name}
            />
          )}
        </HStack>
        <HStack>
          <ConnectionDetailsStat
            label="Sync frequency"
            value={connectionDetails.sync_interval}
          />
        </HStack>
      </VStack>

      <EditConnectionModal
        updatingConnection={updatingConnection}
        connection={connectionDetails}
        serviceDetails={serviceDetails}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Container>
  );
}
