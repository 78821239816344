import {
  useRadio,
  Box,
  UseRadioProps,
  Icon,
  Flex,
  Input,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaCheck } from "react-icons/fa";

export default function ListRadio(
  props: UseRadioProps & { children: ReactNode }
) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="100%">
      <Input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        rounded="xs"
        borderWidth="1px"
        borderColor="grayV2.200"
        fontSize="sm"
        _checked={{
          bg: "grayV2.200",
          "&>svg": { visibility: "visible" },
        }}
        px={2}
        py={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{props.children}</Box>

        <Icon visibility="hidden" as={FaCheck} />
      </Flex>
    </Box>
  );
}
