import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Icon,
  Box,
  Divider,
  Stack,
  FormLabelProps,
} from "@chakra-ui/react";
import { FormikProps, useFormik } from "formik";
import { isEqual, isObject, transform } from "lodash";
import { FaSave } from "react-icons/fa";
import {
  WarehouseTypeDetails,
  YupValidation,
} from "../../../../common/types/common";
import {
  Connection,
  ConnectionConfig,
  ConnectionCreateConfig,
  ConnectionUpdateConfig,
} from "../../../../common/types/connection";
import {
  createConnection,
  updateConnection,
  wizardNextStep,
  clearConnectionDetailsData,
} from "../connectionSlice";
import { object } from "yup";
import {
  requiredString,
  requiredNumber,
} from "../../../../common/helper/validationHelper";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { PasswordField } from "../../../../components/PasswordField";
import ITitle from "../../../../components/ITitle";
import CommonDrawer from "../../campaign/components/CommonDrawer";
import ConnectionButtons from "../connectionDetails/components/ConnectionButtons";
import { SOURCES } from "../../../../common/types/unifiedMapping";
import { useAppDispatch } from "../../../../store";
import { isFulfilled } from "../../../../common/helper/commonHelper";
import {
  createConnectionApi,
  listSourceSchemaApi,
  updateConnectionApi,
} from "../../../../common/api/integrations/connection";

const CONNECTION_INFO = {
  [SOURCES.BIGQUERY]: {
    label:
      "Inflection uses your Google Cloud Platform Project ID to access your BigQuery datasets and sync the data to inflection.",
    title: "Enter the Google Project ID",
  },
  // Add labels for other services as needed
};

function ConnectionFormControl({
  label,
  placeholder,
  type = "text",
  form,
  id,
  errorMsg,
  formLabelProps = {
    fontSize: "14px",
    color: "gray.600",
  },
}: {
  label?: string;
  errorMsg?: string;
  placeholder?: string;
  type?: string;
  form: FormikProps<ConnectionConfig>;
  id: keyof ConnectionConfig;
  formLabelProps?: FormLabelProps;
}) {
  return (
    <>
      {label && (
        <InputFieldWithError
          labelText={label}
          mt={2}
          fontSize="12px"
          placeholder={placeholder || ""}
          type={type}
          errorMsg={
            form.errors[id] ? errorMsg ?? `${label} ${form.errors[id]}` : ""
          }
          touched={!!form.touched[id]}
          name={id}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values[id]}
          formLabelProps={formLabelProps}
        />
      )}
    </>
  );
}

export default function ConnectionDetailsForm({
  connectionDetails,
  serviceDetails,
  loading,
  closeModal,
  goToPrevStep,
}: {
  connectionDetails?: Connection | null;
  serviceDetails: WarehouseTypeDetails;
  loading: boolean;
  closeModal: () => void;
  goToPrevStep?: () => void;
}) {
  const dispatch = useAppDispatch();

  let label = "",
    hostName = "",
    port: Connection["port"] = undefined,
    databaseName = "",
    userName = "";

  if (connectionDetails) {
    ({
      label,
      host_name: hostName,
      port,
      database_name: databaseName,
      user_name: userName,
    } = connectionDetails);
  }

  function findChangedProperties(object: any, base: any) {
    return transform(object, (result: any, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? findChangedProperties(value, base[key])
            : value;
      }
    });
  }

  function getInfoForConnectionDetails(serviceName: string) {
    return (
      CONNECTION_INFO[serviceName as keyof typeof CONNECTION_INFO] ?? {
        label: serviceDetails.fields.host_name.label,
        title: "Connection details",
        // this need not be conditional after we get all labels and titles
      }
    );
  }

  // connection name and hostname are required for all services
  let connectionDetailsShape: YupValidation = {
    label: requiredString(""),
    host_name: requiredString(""),
  };

  //rest of the fields, change according to the service selected
  if (serviceDetails.fields.database_name) {
    connectionDetailsShape.database_name = requiredString("");
  }

  if (serviceDetails.fields.user_name) {
    connectionDetailsShape.user_name = requiredString("");
  }

  if (serviceDetails?.fields.port) {
    connectionDetailsShape.port = requiredNumber("");
  }

  if (serviceDetails?.fields.password && !connectionDetails) {
    connectionDetailsShape.password = requiredString("Password");
  }

  const connectionDetailsSchema = object().shape(connectionDetailsShape);

  const connectionDetailsForm = useFormik<ConnectionConfig>({
    initialValues: {
      label: label || "",
      host_name: hostName || "",
      port: port || undefined,
      database_name: databaseName || undefined,
      user_name: userName || undefined,
      password: undefined,
    },

    onSubmit: async (values) => {
      if (connectionDetails) {
        const changedValues = findChangedProperties(values, {
          label,
          host_name: hostName,
          port,
          database_name: databaseName,
          user_name: userName,
        });
        if (!changedValues.password) {
          delete changedValues.password;
        }
        const dwCreds: ConnectionUpdateConfig = {
          ...changedValues,
          ...{ connection_id: connectionDetails.id },
        };
        const response = await dispatch(updateConnection(dwCreds));
        if (isFulfilled(response.meta.requestStatus)) {
          const resPayload = response.payload as {
            response: Awaited<ReturnType<typeof updateConnectionApi>>;
            schemas?: Awaited<ReturnType<typeof listSourceSchemaApi>>;
          };
          if (resPayload.response.connection) {
            dispatch(wizardNextStep());
          }
        }
      } else {
        const dwCreds: ConnectionCreateConfig = {
          ...values,
          ...{ typ: serviceDetails.client_id },
        };
        const response = await dispatch(createConnection(dwCreds));
        if (isFulfilled(response.meta.requestStatus)) {
          const resPayload = response.payload as {
            connectionInfo: Awaited<ReturnType<typeof createConnectionApi>>;
            schemas?: Awaited<ReturnType<typeof listSourceSchemaApi>>;
          };
          if (resPayload.connectionInfo.connection) {
            dispatch(wizardNextStep());
          }
        }
      }
    },
    validationSchema: connectionDetailsSchema,
  });

  return (
    <CommonDrawer
      title={serviceDetails?.name}
      goBack={goToPrevStep ?? undefined}
      onClose={() => {
        dispatch(clearConnectionDetailsData());
        closeModal();
      }}
      isOpen={true}
      size="md"
      placement="right"
    >
      <form onSubmit={connectionDetailsForm.handleSubmit}>
        <ConnectionFormControl
          id="label"
          label="Lets start with a connection name"
          errorMsg="Connection name is required"
          form={connectionDetailsForm}
          placeholder="connection-1"
          formLabelProps={{
            fontSize: "14px",
            fontWeight: 600,
            color: "gray.600",
          }}
        />
        <Divider my={5} />
        <Box>
          <FormLabel fontSize="14px" mb="2">
            <ITitle
              capitalize={false}
              title={
                getInfoForConnectionDetails(serviceDetails.client_id).title
              }
              fontWeight={600}
              color="gray.600"
              fontSize="14px"
            />
          </FormLabel>
          <Stack spacing={2}>
            <ConnectionFormControl
              id="host_name"
              label={
                getInfoForConnectionDetails(serviceDetails.client_id).label
              }
              errorMsg={serviceDetails.fields.host_name?.label + " is required"}
              placeholder={serviceDetails.fields.host_name.placeholder}
              form={connectionDetailsForm}
            />
            <ConnectionFormControl
              id="port"
              type="number"
              label={serviceDetails.fields.port?.label}
              placeholder={serviceDetails.fields.port?.placeholder}
              form={connectionDetailsForm}
            />
            <ConnectionFormControl
              id="database_name"
              label={serviceDetails.fields.database_name?.label}
              placeholder={serviceDetails.fields.database_name?.placeholder}
              form={connectionDetailsForm}
            />
            <ConnectionFormControl
              id="user_name"
              label={serviceDetails.fields.user_name?.label}
              placeholder={serviceDetails.fields.user_name?.placeholder}
              form={connectionDetailsForm}
            />
            {serviceDetails?.fields.password && (
              <FormControl
                id="password"
                isInvalid={
                  !!connectionDetailsForm.errors.password &&
                  connectionDetailsForm.touched.password
                }
              >
                <FormLabel fontSize="sm">Password</FormLabel>
                <PasswordField
                  name="password"
                  onChange={connectionDetailsForm.handleChange}
                  onBlur={connectionDetailsForm.handleBlur}
                  value={connectionDetailsForm.values.password}
                  fontSize={12}
                />
                <FormErrorMessage fontSize="12px">
                  {connectionDetailsForm.errors.password}
                </FormErrorMessage>
              </FormControl>
            )}
          </Stack>
        </Box>
        <ConnectionButtons
          closeModal={closeModal}
          type="submit"
          isLoading={loading}
          isDisabled={loading || !connectionDetailsForm.isValid}
          loadingText="Saving connection"
          name="save-button"
          px={5}
          leftIcon={<Icon as={FaSave} />}
        />
      </form>
    </CommonDrawer>
  );
}
