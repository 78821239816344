import React, { useRef, useState } from "react";
import Select, {
  components,
  GroupBase,
  Props,
  MenuListProps,
  CSSObjectWithLabel,
} from "react-select";
import { Box } from "@chakra-ui/react";
import { useChakraColors } from "../common/hooks/commonHooks";

type DropdownProps = {
  isInvalid?: boolean;
  controlStyle?: React.CSSProperties;
  menuStyle?: React.CSSProperties;
  menuListStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  singleValueProps?: CSSObjectWithLabel;
  valueContainerProps?: CSSObjectWithLabel;
};

export function CustomMenuList<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ children, ...props }: MenuListProps<Option, IsMulti, Group>) {
  return (
    <Box onWheelCapture={(e) => e.stopPropagation()} cursor="pointer">
      <components.MenuList {...props}>{children}</components.MenuList>
    </Box>
  );
}

export default function DropdownWithSearch<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  isInvalid,
  controlStyle,
  containerStyle,
  menuStyle,
  menuListStyle,
  isSearchable = false,
  placeholder = "Select",
  menuPortalTarget = document.body, // doesn't work well with flow, so need to send null for exception cases.
  components,
  singleValueProps,
  valueContainerProps,

  ...props
}: Props<Option, IsMulti, Group> & DropdownProps) {
  const { red500, gray100, gray200, gray300, gray400, gray800, text200 } =
    useChakraColors();

  const [alignRight, setAlignRight] = useState(false);
  const [alignTop, setAlignTop] = useState(false);
  const [menuHidden, setMenuHidden] = useState(true);
  const menuObserver = useRef<IntersectionObserver | null>(null);
  const selectRef = useRef<any>();

  const onMenuOpen = () => {
    const observeOnscreen: IntersectionObserverCallback = (entries = []) => {
      const { boundingClientRect, intersectionRect } = entries[0];
      const isRightClipping = intersectionRect.width < boundingClientRect.width;
      const isBottomClipping =
        intersectionRect.height < boundingClientRect.height;

      setAlignRight(isRightClipping);
      setAlignTop(isBottomClipping);
      setMenuHidden(false);
    };

    setTimeout(() => {
      const menuList = selectRef.current?.menuListRef;
      menuObserver.current = new IntersectionObserver(observeOnscreen);
      if (menuList) menuObserver.current.observe(menuList);
    });
  };

  const onMenuClose = () => {
    setAlignRight(false);
    setAlignTop(false);
    setMenuHidden(true);
    menuObserver.current?.disconnect();
  };

  function checkIfFocussed(isFocused: boolean) {
    return isFocused ? gray100 : "white";
  }

  return (
    <Select
      {...props}
      ref={selectRef}
      placeholder={placeholder}
      styles={{
        control: (base, state) => ({
          ...base,
          height: "32px",
          minHeight: "32px",
          borderColor: isInvalid ? red500 : gray200,
          boxShadow: isInvalid ? `0 0 0 1px ${red500}` : "",
          fontSize: "14px",
          cursor: state.isDisabled ? "not-allowed" : "pointer",
          opacity: state.isDisabled ? 0.6 : 1,
          backgroundColor: state.isDisabled
            ? "hsl(0, 10%, 100%)"
            : "rgb(255,255,255)",
          borderRadius: "6px",
          color: state.isDisabled ? text200 : gray800,
          ...controlStyle,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          color: gray800,
          width: "95%",
          paddingTop: 0,
          paddingBottom: 0,
          cursor: "pointer",
        }),
        clearIndicator: (base) => ({
          ...base,
          padding: 0,
          cursor: "pointer",
        }),
        option: (base, state) => ({
          ...base,
          fontSize: "14px",
          color: state.isDisabled ? gray400 : gray800,
          backgroundColor: state.isSelected
            ? gray300
            : checkIfFocussed(state.isFocused),
          cursor: "pointer",
          "&:hover": {
            backgroundColor:
              state.isSelected || state.isDisabled ? undefined : gray100,
          },
          whiteSpace: "nowrap",
        }),
        menu: (base) => ({
          ...base,
          minWidth: "max-content",
          position: "absolute",
          right: alignRight ? 0 : undefined,
          visibility: menuHidden ? "hidden" : "visible",
          ...menuStyle,
        }),
        menuList: (base) => ({
          ...base,
          "::-webkit-scrollbar": {
            width: "6px",
          },
          "::-webkit-scrollbar-track": {
            background: "white",
          },
          "::-webkit-scrollbar-thumb": {
            background: gray200,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: gray300,
          },
          ...menuListStyle,
        }),
        container: (base) => ({
          ...base,
          ...containerStyle,
        }),
        singleValue: (base, state) => ({
          ...base,
          color: state.isDisabled ? text200 : gray800,
          opacity: state.isDisabled ? 0.8 : 1,
          ...singleValueProps,
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 1450, // 1400 is the z-index for modals
        }),
        valueContainer: (base) => ({
          ...base,
          ...valueContainerProps,
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
        MenuList: CustomMenuList,
        ...components,
      }}
      menuPlacement={alignTop ? "top" : "auto"}
      isSearchable={isSearchable}
      captureMenuScroll
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuPortalTarget={menuPortalTarget}
    />
  );
}

export function MultiSelectDropdown<
  Option,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ ...props }: Props<Option, true, Group> & DropdownProps) {
  return <DropdownWithSearch {...props} isMulti />;
}
