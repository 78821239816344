import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Image,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  LOADING_STATES,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import {
  generateRandomString,
  getTableRowLinkProps,
  getUserStatusFromMapping,
  isLoading,
  isSuccess,
  keepClickEventRestricted,
} from "../../../common/helper/commonHelper";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import {
  HeaderSearchProps,
  ListFilterProps,
  PaginationFilterParams,
} from "../../../common/types/common";
import { WebhookListItem } from "../../../common/types/webhook";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { DataTable } from "../../../components/data-table/DataTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import InitialEmptyState from "../../../components/InitialEmptyState";
import UserNameWithAvatar from "../../../components/UserNameWithAvatar";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { useAppDispatch } from "../../../store";
import urls from "../../../urls";
import { getUserList, selectAccount } from "../../account/accountSlice";
import {
  clearCreatedWebhookId,
  createWebhook,
  deleteWebhook,
  listWebhooks,
  resetWebhookList,
  selectWebhook,
} from "./webhookSlice";
import WebhookEmptyState from "../../../common/img/emptyStateLogos/webhook.svg";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

const NEW_WEBHOOK_PREFIX = "webhook-";
const FILTER_COLUMNS = ["name"];

function WebhookHeader({
  clickHandler,
  loading,
  searchProps: { searchKeyword, onSearch, hidden },
  filterProps,
}: {
  clickHandler: () => void;
  loading: LOADING_STATES;
  searchProps: HeaderSearchProps;
  filterProps?: ListFilterProps;
}) {
  return (
    <CommonListHeader
      heading="Webhooks"
      createButtonProps={{
        name: "add-webhook",
        onClick: clickHandler,
        isLoading: isLoading(loading),
        text: "New webhook",
      }}
      searchInputProps={{
        placeholder: "Search webhooks",
        name: "search-input",
        onSearch: onSearch,
        defaultValue: searchKeyword,
        hidden: hidden,
      }}
      filterProps={filterProps}
    />
  );
}
function WebhookList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<WebhookListItem | null>(null);
  const { webhooksList, createdWebhookId, deletingWebhook, creatingWebhook } =
    useSelector(selectWebhook);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState("");

  const { usersList } = useSelector(selectAccount);

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const fetchWebhookList = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listWebhooks({
          pageNo,
          searchKeyword,
          columnsToSearchIn: FILTER_COLUMNS,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchWebhookList,
    fetchingList: webhooksList.fetchingList,
  });

  useEffect(() => {
    if (isSuccess(deletingWebhook)) {
      handlePageChange(webhooksList.currentPageNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingWebhook, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetWebhookList());
    };
  }, [dispatch]);

  const goToEditWebhook = useCallback(
    (webhookId: string) => {
      navigate(`${urls.webhook}/${webhookId}`);
    },
    [navigate]
  );

  const onClickDeleteWebhook = useCallback((row: WebhookListItem) => {
    setSelectedRow(row);
    setIsOpenDeleteModal(row.webhook_id);
  }, []);

  useEffect(() => {
    if (createdWebhookId !== "") {
      goToEditWebhook(createdWebhookId);
      dispatch(clearCreatedWebhookId());
    }
  }, [createdWebhookId, dispatch, goToEditWebhook]);

  const columnHelper = createColumnHelper<WebhookListItem>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        minSize: 300,
        header: "Name",
      }),
      columnHelper.accessor("description", {
        minSize: 350,
        cell: (info) => <Text noOfLines={1}>{info.getValue()}</Text>,
        header: "Description",
      }),
      columnHelper.accessor("method", {
        cell: (info) => info.getValue(),
        header: "Type",
        minSize: 150,
      }),
      columnHelper.accessor("created_at", {
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
        header: "Created On",
        minSize: 200,
      }),
      columnHelper.accessor("created_by.name", {
        cell: (info) =>
          info.getValue() ? (
            <UserNameWithAvatar
              username={info.getValue()}
              accountStatus={getUserStatusFromMapping(
                info.row.original.created_by.id.toString(),
                usersList
              )}
            />
          ) : (
            ""
          ),
        header: "Created By",
        minSize: 200,
      }),
      columnHelper.display({
        header: "",
        id: "actions",
        cell: (info) => (
          <Flex alignItems="center" justifyContent="flex-end">
            <Box onClick={(e) => keepClickEventRestricted(e)}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  variant="ghost"
                  name="options"
                  icon={<FaEllipsisV />}
                />
                <MenuList rootProps={{ style: { right: 0 } }}>
                  <MenuItem
                    onClick={() =>
                      goToEditWebhook(info.row.original.webhook_id)
                    }
                    name="edit-item"
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => onClickDeleteWebhook(info.row.original)}
                    name="remove-item"
                  >
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        ),
      }),
    ],
    [columnHelper, goToEditWebhook, onClickDeleteWebhook, usersList]
  );

  function deleteWebhookHandler() {
    if (isOpenDeleteModal) {
      dispatch(deleteWebhook(isOpenDeleteModal));
      setIsOpenDeleteModal("");
      setSelectedRow(null);
    }
  }

  function newWebhookHandler() {
    dispatch(createWebhook(generateRandomString(5, NEW_WEBHOOK_PREFIX)));
  }

  const isInitEmpty = !(webhooksList.totalPageCount || isFiltersApplied);

  return (
    <>
      <WebhookHeader
        clickHandler={newWebhookHandler}
        loading={creatingWebhook}
        searchProps={{
          searchKeyword: searchKeyword,
          onSearch: handleSearchChange,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.WEBHOOK,
          hidden: isInitEmpty,
        }}
      />
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
          <DataTable
            fetchingList={fetchingList}
            changingPage={webhooksList.changingPage}
            list={webhooksList.list as WebhookListItem[]}
            totalPageSize={webhooksList.pageSize}
            totalPageCount={webhooksList.totalPageCount}
            currentPage={webhooksList.currentPageNo}
            onRowClick={(row: WebhookListItem) =>
              goToEditWebhook(row.webhook_id)
            }
            setPage={handlePageChange}
            columns={columns}
            emptyMsg={`No webhooks found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps(
                { to: urls.webhook, editParam: "webhook_id" },
                data
              )
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Supercharge your workflow with powerful webhooks"
          message="No active webhooks"
          ctaProps={{
            children: "Create webhook",
            name: "new-webhook",
            isLoading: isLoading(creatingWebhook),
            onClick: newWebhookHandler,
          }}
        >
          <Image src={WebhookEmptyState} alt="Webhook" />
        </InitialEmptyState>
      )}

      <DeleteConfirmationModal
        isOpen={!!isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal("")}
        deleteItemType={DELETION_MODAL_TYPES_INFO[ASSET_TYPES.WEBHOOK].display}
        itemSpecificName={selectedRow?.name ?? ""}
        submitHandler={deleteWebhookHandler}
        isLoading={isLoading(deletingWebhook)}
      />
    </>
  );
}

export default WebhookList;
