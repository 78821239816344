import { VStack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ContentContainer from "../../../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { selectConnection } from "../../../connectionSlice";
import Connections from "./components/Connections";
import Overview from "./components/Overview";
import { isLoading } from "../../../../../../common/helper/commonHelper";

export default function OverviewTab() {
  const {
    unifiedConnection: { connectionList },
  } = useSelector(selectConnection);

  return (
    <ContentContainerWithHeader>
      <ContentContainer flexDir="column" bg="unset" p="unset" mt="15px">
        <VStack w="100%" spacing="3">
          <Overview
            connectionList={connectionList.list ?? []}
            loading={isLoading(connectionList.loadingList)}
          />
          <Connections connectionList={connectionList} />
        </VStack>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
