import { HStack, Icon, Text } from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { openInNewTab } from "../../../../common/helper/commonHelper";

export function LinkWithIcon({ urlToDisplay }: { urlToDisplay: string }) {
  return (
    <HStack
      onClick={() => openInNewTab(`https://${urlToDisplay}`)}
      _hover={{
        textDecoration: "underline",
        cursor: "pointer",
      }}
      textAlign="left"
    >
      <Text overflowWrap="anywhere" fontSize="14px">
        {urlToDisplay}
      </Text>
      <Icon as={FaExternalLinkAlt} fontSize="10px" color="brandBlue.500" />
    </HStack>
  );
}
