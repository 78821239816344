import {
  Box,
  Circle,
  Collapse,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { capitalize, cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SingleValue } from "react-select";
import { isLoading } from "../../../../../../../common/helper/commonHelper";
import { findFields } from "../../../../../../../common/helper/unifiedMappingHelper";
import { ReactSelectDefaultOptionsType } from "../../../../../../../common/types/common";
import {
  INFLECTION_RECORD_CREATION,
  INFLECTION_RECORD_DELETION,
  ObjectCreateRule,
  ObjectDeleteRule,
  ObjectSettings,
  SalesforceConnection,
  SALESFORCE_OBJECT_TYPE,
} from "../../../../../../../common/types/salesforceLegacyService";
import {
  Destination,
  DESTINATION_TYPES,
  SelectiveSyncField,
} from "../../../../../../../common/types/unifiedMapping";
import DropdownWithSearch from "../../../../../../../components/DropdownWithSearch";
import ITitle from "../../../../../../../components/ITitle";
import { useAppDispatch } from "../../../../../../../store";
import {
  updateInflectionRecordCreate,
  updateInflectionRecordDelete,
  updateSelectiveSync,
  getSalesforceObjects,
  selectSalesforceLegacy,
} from "../../../salesforceLegacySlice";
import { Header } from "./Header";
import { NoteBox } from "./NoteBox";
import IconWithTooltip from "../../../../../../../components/IconWithTooltip";

const personCreationMenuItems = [
  {
    label: `Create a contact in Inflection`,
    value: INFLECTION_RECORD_CREATION.CREATE_RECORD,
  },
  {
    label: "Do nothing",
    value: INFLECTION_RECORD_CREATION.DO_NOTHING,
  },
];

const personDeletionMenuItems = [
  {
    label: "Delete the contact in Inflection but keep product data",
    value: INFLECTION_RECORD_DELETION.DELETE_RECORD,
  },
  { label: "Do nothing", value: INFLECTION_RECORD_DELETION.DO_NOTHING },
];

function NumberedListItem({ number, text }: { number: string; text: string }) {
  return (
    <Flex fontSize="13px">
      <Circle
        bgColor="gray.400"
        size={"20px"}
        mr={2}
        color="white"
        fontWeight="600"
      >
        {number}
      </Circle>
      <Box>
        <Text mb={3}>{text}</Text>
      </Box>
    </Flex>
  );
}

function HelperTextBox() {
  return (
    <VStack w="100%" bg="gray.100" p="4" spacing="3">
      <Box w="100%">
        <Heading fontSize="md" mb="2">
          Help
        </Heading>
        <Text fontSize="sm">
          To further limit which new Salesforce leads/contact can be added to
          Inflection, follow the below steps
        </Text>
      </Box>

      <VStack alignItems="flex-start" w="100%" spacing="1">
        <NumberedListItem
          number="1"
          text={`Create a boolean field in Salesforce on the lead & contact object (Example - "Sync to Inflection")`}
        />
        <NumberedListItem
          number="2"
          text={`Mark the above field as true for all leads/contacts that you want to sync to Inflection`}
        />
        <NumberedListItem
          number="3"
          text={`Once the above steps are complete, select the boolean field from the below list & toggle on the selective sync for lead or contacts or both`}
        />
      </VStack>

      <Divider />

      <Text fontSize="sm" color="gray.600">
        Note: If Selective sync is enabled, any leads/contacts that are added to
        the Salesforce inclusion list will automatically be marked as True
      </Text>
    </VStack>
  );
}

function ToggleFormWithDropdown({
  isDisabled,
  onChange,
  field,
  type,
  options,
  isLoading,
}: {
  isDisabled?: boolean;
  onChange: (field: SelectiveSyncField) => void;
  field: SelectiveSyncField;
  type: string;
  options: Destination[];
  isLoading: boolean;
}) {
  const [syncEnabled, setSyncEnabled] = useState(field.active);

  function onDropdownChange(value: Destination) {
    const clonedField = cloneDeep(field);
    clonedField.field = value;
    onChange(clonedField);
  }

  function onToggle(value: boolean) {
    const clonedField = cloneDeep(field);
    clonedField.active = value;
    if (!value) {
      clonedField.field = null;
    }
    onChange(clonedField);
    setSyncEnabled(value);
  }

  return (
    <Box>
      <HStack my="2" spacing="5">
        <ITitle
          fontSize="sm"
          fontWeight="semibold"
          title={`Selective sync for ${type}`}
        />
        <Flex alignItems="center">
          <Switch
            isDisabled={isDisabled}
            isChecked={syncEnabled}
            onChange={(e) => onToggle(e.target.checked)}
          />
        </Flex>
        {isDisabled && (
          <InfoTooltip
            text={`${capitalize(
              type
            )} sync is currently disabled. You can enable them in general settings`}
          />
        )}
      </HStack>

      <Collapse in={syncEnabled} animateOpacity>
        <VStack
          alignItems="flex-start"
          bg="blackAlpha.50"
          p="2"
          w="300px"
          opacity={syncEnabled ? 1 : 0.7}
        >
          <Text
            fontSize="sm"
            color={syncEnabled ? "black" : "gray.400"}
          >{`Boolean field for ${type}`}</Text>
          <Box w="100%">
            <DropdownWithSearch<Destination>
              menuPortalTarget={document.body}
              isLoading={isLoading}
              isDisabled={!syncEnabled || isDisabled}
              options={options}
              isSearchable={true}
              value={options.find((x) => x.name === field?.field?.name)}
              getOptionValue={(x) => x.name}
              getOptionLabel={(x) => x.display}
              onChange={(opt) => opt && onDropdownChange(opt)}
            />
          </Box>
        </VStack>
      </Collapse>
    </Box>
  );
}

function InfoTooltip({ text }: { text: string }) {
  return (
    <IconWithTooltip
      label={text}
      iconContainerProps={{ as: "span", pt: "3px" }}
      fontSize="18px"
    />
  );
}

function SelectiveSync({ data }: { data: SalesforceConnection }) {
  const { salesforceConnections } = useSelector(selectSalesforceLegacy);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.access_token && !salesforceConnections[data.connection_id]) {
      dispatch(getSalesforceObjects(data.connection_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data.access_token, data.connection_id]);

  function findBooleanFields(type: SALESFORCE_OBJECT_TYPE): Destination[] {
    return findFields({
      objects: salesforceConnections[data.connection_id]?.objects,
      objectType: type,
      fieldDataType: DESTINATION_TYPES.BOOLEAN,
    });
  }

  function onChange(
    objectType: SALESFORCE_OBJECT_TYPE.LEAD | SALESFORCE_OBJECT_TYPE.CONTACT,
    field: SelectiveSyncField
  ) {
    let selectiveSync = cloneDeep(data.selective_sync);
    selectiveSync = { ...selectiveSync, [objectType]: field };

    if ((field.active && field.field) || !field.active) {
      dispatch(
        updateSelectiveSync({
          connectionId: data.connection_id,
          selectiveSync: selectiveSync,
        })
      );
    }
  }

  return (
    <VStack alignItems="flex-start" spacing="2" width="100%">
      <Header
        title="Selective sync"
        desc="Selective sync limits what syncs with Inflection. Only records with a
        valid email address are synced"
      />
      ;
      <HelperTextBox />
      <ToggleFormWithDropdown
        isLoading={isLoading(
          salesforceConnections[data.connection_id]?.loading
        )}
        isDisabled={!data.object_settings?.lead}
        onChange={(field) => onChange(SALESFORCE_OBJECT_TYPE.LEAD, field)}
        field={data.selective_sync.lead}
        type="leads"
        options={findBooleanFields(SALESFORCE_OBJECT_TYPE.LEAD)}
      />
      <ToggleFormWithDropdown
        isLoading={isLoading(
          salesforceConnections[data.connection_id]?.loading
        )}
        isDisabled={!data.object_settings?.contact}
        onChange={(field) => onChange(SALESFORCE_OBJECT_TYPE.CONTACT, field)}
        field={data.selective_sync.contact}
        type="contacts"
        options={findBooleanFields(SALESFORCE_OBJECT_TYPE.CONTACT)}
      />
    </VStack>
  );
}

function SyncRules({
  data,
  objectSettings,
}: {
  data: SalesforceConnection;
  objectSettings: ObjectSettings | null;
}) {
  const dispatch = useAppDispatch();

  const onPersonCreateChange = (
    val: SingleValue<ReactSelectDefaultOptionsType>,
    type: SALESFORCE_OBJECT_TYPE
  ) => {
    const personCreateData = {
      connectionId: data.connection_id,
      objectCreate: {
        [SALESFORCE_OBJECT_TYPE.CONTACT]:
          data.records_create_settings?.contact ||
          INFLECTION_RECORD_CREATION.DO_NOTHING,
        [SALESFORCE_OBJECT_TYPE.LEAD]:
          data.records_create_settings?.lead ||
          INFLECTION_RECORD_CREATION.DO_NOTHING,
        [type]: val?.value! as INFLECTION_RECORD_CREATION,
      } as Partial<ObjectCreateRule>,
    };

    dispatch(updateInflectionRecordCreate(personCreateData));
  };

  const onPersonDeleteChange = (
    val: SingleValue<ReactSelectDefaultOptionsType>,
    type: SALESFORCE_OBJECT_TYPE
  ) => {
    const personDeleteData = {
      connectionId: data.connection_id,
      objectDelete: {
        [SALESFORCE_OBJECT_TYPE.CONTACT]:
          data.records_delete_settings?.contact ||
          INFLECTION_RECORD_DELETION.DO_NOTHING,
        [SALESFORCE_OBJECT_TYPE.LEAD]:
          data.records_delete_settings?.lead ||
          INFLECTION_RECORD_DELETION.DO_NOTHING,
        [type]: val?.value! as INFLECTION_RECORD_DELETION,
      } as Partial<ObjectDeleteRule>,
    };

    dispatch(updateInflectionRecordDelete(personDeleteData));
  };

  return (
    <VStack alignItems="flex-start" spacing="3" width="100%" mt="5" pt="3">
      <NoteBox
        text="The action of creation, deletion or do nothing will only take effect from the time this setting is updated"
        tooltipText="Example:- If setting for deletion was set to do nothing on day 1 and the setting is updated to delete on day 2,
        the action of deleting associated Inflection people will only take place for leads/contacts who are deleted in Salesforce from day 2."
      />
      <Header
        title="Sync rules for creating, updating and deleting contact in Inflection"
        desc=""
      />

      <Grid
        width="100%"
        templateColumns="repeat(3, 1fr)"
        bg="grayV2.100"
        p="2"
        gap={6}
      >
        <Text>Action</Text>
        <HStack>
          <Text>Lead</Text>
          {objectSettings && !objectSettings.lead && (
            <InfoTooltip
              text={
                "Lead sync is currently disabled. You can enable them in general settings"
              }
            />
          )}
        </HStack>
        <HStack>
          <Text>Contact</Text>
          {objectSettings && !objectSettings.contact && (
            <InfoTooltip
              text={
                "Contact sync is currently disabled. You can enable them in general settings"
              }
            />
          )}
        </HStack>
      </Grid>

      <Grid
        p="2"
        width="100%"
        templateColumns="repeat(3, 1fr)"
        templateRows="1fr 2fr"
        gap={6}
      >
        <Text mr="1">Created in Salesforce</Text>

        <DropdownWithSearch
          isDisabled={!data.object_settings?.lead}
          options={personCreationMenuItems}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={personCreationMenuItems.find((option) => {
            return (
              option.value === (data.records_create_settings?.lead ?? null)
            );
          })}
          maxMenuHeight={350}
          onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
            onPersonCreateChange(val, SALESFORCE_OBJECT_TYPE.LEAD)
          }
          controlStyle={{
            width: "300px",
          }}
        />
        <DropdownWithSearch
          isDisabled={!data.object_settings?.contact}
          options={personCreationMenuItems}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={personCreationMenuItems.find(
            (option) =>
              option.value === (data.records_create_settings?.contact ?? null)
          )}
          onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
            onPersonCreateChange(val, SALESFORCE_OBJECT_TYPE.CONTACT)
          }
          controlStyle={{
            width: "300px",
          }}
        />

        <Text mr="1">Deleted in Salesforce</Text>
        <DropdownWithSearch
          isDisabled={!data.object_settings?.lead}
          options={personDeletionMenuItems}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={personDeletionMenuItems.find(
            (option) =>
              option.value === (data.records_delete_settings?.lead ?? null)
          )}
          onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
            onPersonDeleteChange(val, SALESFORCE_OBJECT_TYPE.LEAD)
          }
          controlStyle={{
            width: "300px",
          }}
        />
        <DropdownWithSearch
          isDisabled={!data.object_settings?.contact}
          options={personDeletionMenuItems}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          value={personDeletionMenuItems.find(
            (option) =>
              option.value === (data.records_delete_settings?.contact ?? null)
          )}
          onChange={(val: SingleValue<ReactSelectDefaultOptionsType>) =>
            onPersonDeleteChange(val, SALESFORCE_OBJECT_TYPE.CONTACT)
          }
          controlStyle={{
            width: "300px",
          }}
        />
      </Grid>
    </VStack>
  );
}

export default function SalesforceLegacyToInflection({
  data,
  objectSettings,
}: {
  data: SalesforceConnection;
  objectSettings: ObjectSettings | null;
}) {
  return (
    <VStack spacing={2} p="5" bg="white" rounded="md">
      <SelectiveSync data={data} />
      <SyncRules data={data} objectSettings={objectSettings} />
    </VStack>
  );
}
