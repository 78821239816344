import { Flex, Skeleton, HStack, Icon, Divider } from "@chakra-ui/react";
import { IoMdArrowRoundForward } from "react-icons/io";

export default function WidgetSummaryReport({
  loading,
  onClick,
  children,
}: {
  onClick?: () => void;
  loading?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      w="100%"
      cursor="pointer"
      onClick={onClick}
    >
      {loading ? (
        <Skeleton h="40px" width="100%" />
      ) : (
        <HStack h="40px" width="100%" bg="grayV2.100" borderRadius="md" p="2">
          <HStack
            width="100%"
            spacing={1}
            divider={
              <Divider
                h="20px"
                borderColor="brandBlue.300"
                orientation="vertical"
              />
            }
          >
            {children}
          </HStack>
          <Icon
            as={IoMdArrowRoundForward}
            fontSize="lg"
            color="brandBlue.600"
          />
        </HStack>
      )}
    </Flex>
  );
}
