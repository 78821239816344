import {
  HStack,
  FormControl,
  InputGroup,
  InputRightElement,
  Spinner,
  Input,
  Icon,
  FormErrorMessage,
  Checkbox,
  Text,
  StackProps,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { AsyncThunk } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { IoArrowRedoCircleSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { LOADING_STATES } from "../common/constants/common";
import {
  isLoading,
  onEnterKeySubmit,
  validateEmail,
} from "../common/helper/commonHelper";
import { LoadingWithData } from "../common/types/common";
import { PersonLookup, PERSON_ORG_MAPPING } from "../common/types/person";
import { PreviewPerson } from "../common/types/token";
import { selectSettings } from "../pages/dashboard/settings/settingsSlice";
import { useAppDispatch } from "../store";
import DropdownWithSearch from "./DropdownWithSearch";
import IButton, { BUTTON } from "./IButton";
import ISkeleton, { SKELETON_VARIANT } from "./ISkeleton";

export type PersonOrgEmailType = {
  personEmail: string;
  orgUserCheck: boolean;
  selectedOrg: [string, string] | null;
};

export const PERSON_ORG_EMAIL_INIT: PersonOrgEmailType = {
  personEmail: "",
  selectedOrg: null,
  orgUserCheck: false,
};

export function PersonLookupInput({
  personLookup,
  lookUpPersonThunk,
  fetchingPreview,
  personOrgEmail: { personEmail, orgUserCheck, selectedOrg },
  setPersonOrgEmail,
  onPreview,
  previewButtonText,
  resetData = false,
  stackProps,
  isButtonDisabled,
  isFetchingDetails,
}: {
  personLookup: LoadingWithData<{ [email: string]: PreviewPerson[] }>;
  lookUpPersonThunk: AsyncThunk<
    {
      rows: PersonLookup[];
    },
    string,
    {}
  >;
  fetchingPreview: LOADING_STATES;
  personOrgEmail: PersonOrgEmailType;
  setPersonOrgEmail: Dispatch<SetStateAction<PersonOrgEmailType>>;
  onPreview: () => void;
  previewButtonText: string;
  resetData?: boolean;
  stackProps?: StackProps;
  isButtonDisabled: boolean;
  isFetchingDetails?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { personOrgMapping } = useSelector(selectSettings);
  const [formDirty, setFormDirty] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(true);
  const [personAvailable, setPersonAvailable] = useState(false);

  useEffect(() => {
    if (
      personLookup.data[personEmail] &&
      personLookup.data[personEmail].length
    ) {
      setPersonAvailable(true);
    } else {
      setPersonAvailable(false);
    }
  }, [personEmail, personLookup.data]);

  const searchIfValid = useCallback(
    (email: string) => {
      dispatch(lookUpPersonThunk(email)).finally(() => {
        setFormDirty(false);
      });
    },
    [dispatch, lookUpPersonThunk]
  );

  const debouncedSearch = useMemo(
    () => debounce(searchIfValid, 1000),
    [searchIfValid]
  );

  function handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    const email = event.target.value;
    setPersonOrgEmail((prev) => {
      return { ...PERSON_ORG_EMAIL_INIT, personEmail: email };
    });
    setFormDirty(true);

    if (email && validateEmail(email)) {
      setInvalidEmail(false);
      if (personLookup.data[email]) {
        setFormDirty(false);
      } else {
        debouncedSearch(email);
      }
    } else {
      setInvalidEmail(true);
    }
  }

  const invalidToPreview =
    invalidEmail ||
    !personEmail ||
    !personAvailable ||
    isLoading(personLookup.loading) ||
    isButtonDisabled ||
    !(
      !orgUserCheck ||
      (personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE && !!selectedOrg)
    );

  const showSelectOrg =
    personAvailable &&
    personOrgMapping !== PERSON_ORG_MAPPING.ONE_TO_NONE &&
    personLookup.data[personEmail]?.some((person) => person.org_id) &&
    personLookup.data[personEmail]?.some((person) => person.product_user_id);

  const orgProductUserOptions: { label: string; value: [string, string] }[] =
    personLookup.data[personEmail] && personLookup.data[personEmail].length
      ? personLookup.data[personEmail].map((person) => {
          return {
            label: `${person.product_user_id}(${person.org_id})`,
            value: [person.product_user_id as string, person.org_id as string],
          };
        })
      : [];

  function showPreview() {
    onPreview();
    if (resetData) {
      setPersonOrgEmail(PERSON_ORG_EMAIL_INIT);
      setInvalidEmail(true);
      setPersonAvailable(false);
      setFormDirty(false);
    }
  }

  function getSelectedOrgValue() {
    return selectedOrg
      ? {
          label: `${selectedOrg[0]}(${selectedOrg[1]})`,
          value: selectedOrg,
        }
      : null;
  }

  return (
    <HStack
      alignItems="flex-start"
      justifyContent="space-between"
      p={2}
      {...stackProps}
    >
      <HStack spacing={4} alignItems="flex-start">
        <ISkeleton
          variant={SKELETON_VARIANT.INPUT}
          isLoaded={!isFetchingDetails}
          minW="200px"
          h="32px"
        >
          <FormControl id="email" isInvalid={!!(personEmail && invalidEmail)}>
            <InputGroup>
              <InputRightElement
                children={<Spinner />}
                hidden={!isLoading(personLookup.loading)}
              />
              <Input
                type="email"
                value={personEmail}
                onChange={handleEmailChange}
                placeholder="Enter email"
                name="email-input-for-preview"
                minW="20vw"
                size="sm"
                onKeyDown={(e) => {
                  !invalidToPreview && onEnterKeySubmit(e, showPreview);
                }}
              />
              <InputRightElement
                hidden={
                  isLoading(personLookup.loading) || invalidEmail || formDirty
                }
              >
                <Icon
                  h="4"
                  w="4"
                  as={personAvailable ? FaCheck : FaTimes}
                  color={personAvailable ? "brand.green" : "brand.red"}
                  title={`Person data ${
                    personAvailable ? "available" : "unavailable"
                  }`}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>Valid email is required</FormErrorMessage>
          </FormControl>
        </ISkeleton>
        {showSelectOrg && (
          <HStack alignItems={"flex-start"} pr={1}>
            <Checkbox
              checked={orgUserCheck}
              onChange={() => {
                setPersonOrgEmail((prev) => {
                  return { ...prev, orgUserCheck: !prev.orgUserCheck };
                });
              }}
              defaultChecked={orgUserCheck}
              fontSize="sm"
              name="check-org"
              my={2}
              width="200px"
            >
              <Text>Receive as a org-user</Text>
            </Checkbox>
            {orgUserCheck && (
              <FormControl id="receiver" width="200px">
                <DropdownWithSearch
                  options={orgProductUserOptions}
                  value={getSelectedOrgValue()}
                  onChange={(option) => {
                    setPersonOrgEmail((prev) => {
                      return { ...prev, selectedOrg: option?.value ?? null };
                    });
                  }}
                  controlStyle={{
                    width: "200px",
                  }}
                  isSearchable={true}
                />
              </FormControl>
            )}
          </HStack>
        )}
      </HStack>
      <Tooltip
        label={isButtonDisabled ? "Save all unsaved changes to continue" : ""}
      >
        <Box>
          <ISkeleton
            variant={SKELETON_VARIANT.INPUT}
            isLoaded={!isFetchingDetails}
            minW="100px"
            h="32px"
          >
            <IButton
              size="sm"
              variant={BUTTON.SECONDARY}
              name="modal-primary-button"
              isDisabled={invalidToPreview}
              onClick={showPreview}
              isLoading={isLoading(fetchingPreview)}
              leftIcon={<IoArrowRedoCircleSharp />}
            >
              {previewButtonText}
            </IButton>
          </ISkeleton>
        </Box>
      </Tooltip>
    </HStack>
  );
}
