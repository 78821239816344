import { Image, Text } from "@chakra-ui/react";
import { CAMPAIGN_STATUS } from "../../common/constants/campaign";
import { CAMPAIGN_CONTEXT } from "../../common/types/campaign";
import {
  CampaignMemberTypeIcon,
  CampaignStatusIcon,
} from "../../pages/dashboard/campaign/components/CampaignListTableItems";
import { TEMPLATE_EDITOR_MODE } from "../../common/types/template";
import { WEBHOOK_REQUEST_METHODS } from "../../common/constants/webhook";
import { FORM_STATUS, FORM_VENDORS } from "../../common/types/form";
import {
  FORM_PROVIDER_DETAILS,
  FORM_STATUS_DETAILS,
} from "../../pages/dashboard/form/helper";
import { MainFilter, SubFilterDetails } from "../../common/types/common";
import {
  SUBFILTER_TYPE,
  TABLE_FILTER_VARIANTS,
} from "../../common/constants/common";

const CAMPAIGN_FILTERS: MainFilter[] = [
  {
    display: "Status",
    value: "campaign_statuses",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: [
      {
        displayText: "Active",
        value: "active",
        icon: <CampaignStatusIcon status={CAMPAIGN_STATUS.ACTIVE} />,
      },
      {
        displayText: "Inactive",
        value: "inactive",
        icon: <CampaignStatusIcon status={CAMPAIGN_STATUS.INACTIVE} />,
      },
      {
        displayText: "Draft",
        value: "draft",
        icon: <CampaignStatusIcon status={CAMPAIGN_STATUS.DRAFT} />,
      },
    ],
  },
  {
    display: "Journey member type",
    value: "campaign_contexts",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: [
      {
        displayText: "Contact journeys",
        value: CAMPAIGN_CONTEXT.PERSON,
        icon: (
          <CampaignMemberTypeIcon campaignContext={CAMPAIGN_CONTEXT.PERSON} />
        ),
      },
      {
        displayText: "User journeys",
        value: CAMPAIGN_CONTEXT.ORG,
        icon: <CampaignMemberTypeIcon campaignContext={CAMPAIGN_CONTEXT.ORG} />,
      },
    ],
  },
  {
    display: "Tags",
    value: "tags",
    subFilterType: SUBFILTER_TYPE.TAGS,
  },
  {
    display: "Next schedule",
    value: "next_schedule_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const EMAIL_FILTERS: MainFilter[] = [
  {
    display: "Editor type",
    value: "template_types",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: [
      {
        displayText: "Visual",
        value: TEMPLATE_EDITOR_MODE.DRAG_DROP,
      },
      {
        displayText: "Advanced",
        value: TEMPLATE_EDITOR_MODE.CODE,
      },
    ],
  },
  {
    display: "Last updated on",
    value: "updated_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
  {
    display: "Created on",
    value: "created_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const TOKEN_FILTERS: MainFilter[] = [
  {
    display: "Created by",
    value: "created_by_list",
    subFilterType: SUBFILTER_TYPE.USER,
  },
  {
    display: "Last updated on",
    value: "updated_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
  {
    display: "Created on",
    value: "created_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const STATIC_LIST_FILTERS: MainFilter[] = [
  {
    display: "Created by",
    value: "created_by_list",
    subFilterType: SUBFILTER_TYPE.USER,
  },
  {
    display: "Last updated by",
    value: "updated_by_list",
    subFilterType: SUBFILTER_TYPE.USER,
  },
  {
    display: "Created on",
    value: "created_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
  {
    display: "Last updated on",
    value: "updated_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const WEBHOOK_FILTERS: MainFilter[] = [
  {
    display: "Type",
    value: "methods",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: Object.values(WEBHOOK_REQUEST_METHODS).map((method) => {
      return {
        displayText: method,
        value: method,
      } as SubFilterDetails;
    }),
  },
  {
    display: "Created by",
    value: "created_by_list",
    subFilterType: SUBFILTER_TYPE.USER,
  },
  {
    display: "Created at",
    value: "created_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const FORM_FILTERS: MainFilter[] = [
  {
    display: "Type",
    value: "vendors",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: Object.values(FORM_VENDORS).map((vendor) => {
      const { imgSrc, name } = FORM_PROVIDER_DETAILS[vendor];
      return {
        displayText: name,
        value: vendor,
        icon: (
          <Image
            src={imgSrc}
            alt={name}
            boxSize="18px"
            borderRadius={vendor === FORM_VENDORS.WEBFLOW ? "10px" : ""}
          />
        ),
      };
    }),
  },
  {
    display: "Form status",
    value: "mapping_statuses",
    subFilterType: SUBFILTER_TYPE.LIST,
    subFilters: Object.values(FORM_STATUS).map((status) => {
      const { text, color } = FORM_STATUS_DETAILS[status];
      return {
        displayText: text,
        value: status,
        displayCell: (
          <Text color={color} fontWeight="semibold" fontSize={14}>
            {text}
          </Text>
        ),
      };
    }),
  },
  {
    display: "Date added",
    value: "created_at_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

const ADVANCED_FILTER: MainFilter = {
  display: "Advanced filter",
  value: "dynamic_list_query",
  subFilterType: SUBFILTER_TYPE.DL,
};

const CONTACT_FILTERS: MainFilter[] = [ADVANCED_FILTER];

const SL_CONTACT_FILTERS: MainFilter[] = [
  ADVANCED_FILTER,
  {
    display: "Date added",
    value: "date_added_range",
    subFilterType: SUBFILTER_TYPE.DATE,
  },
];

export const TABLE_FILTERS = {
  [TABLE_FILTER_VARIANTS.CAMPAIGN]: {
    display: "journey",
    filters: CAMPAIGN_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.EMAIL]: {
    display: "email",
    filters: EMAIL_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.STATIC_LIST]: {
    display: "static list",
    filters: STATIC_LIST_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.TOKEN]: {
    display: "token",
    filters: TOKEN_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.WEBHOOK]: {
    display: "webhook",
    filters: WEBHOOK_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.FORMS]: {
    display: "forms",
    filters: FORM_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.CONTACTS]: {
    display: "contacts",
    filters: CONTACT_FILTERS,
  },
  [TABLE_FILTER_VARIANTS.SL_CONTACTS]: {
    display: "contacts",
    filters: SL_CONTACT_FILTERS,
  },
};
