import { Button, Flex, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function SelectionRow({
  onClick,
  isSelected,
  displayText,
  displayCell,
  icon,
  children,
}: {
  onClick: () => void;
  isSelected: boolean;
  displayText: string;
  displayCell?: JSX.Element;
  icon?: JSX.Element;
  children?: ReactNode;
}) {
  return (
    <Flex
      cursor="pointer"
      w="full"
      py={2}
      px={2}
      alignItems="center"
      onClick={onClick}
      _hover={{
        backgroundColor: isSelected ? "gray.200" : "gray.100",
      }}
      backgroundColor={isSelected ? "gray.200" : ""}
      borderRadius="md"
      justifyContent="space-between"
    >
      {displayCell ? (
        displayCell
      ) : (
        <Button
          variant="ghost"
          p={0}
          h="fit-content"
          justifyContent="flex-start"
          _hover={{
            bg: "transparent",
          }}
          leftIcon={icon}
          fontSize="14px"
          fontWeight="400"
          w="100%"
        >
          <Text isTruncated>{displayText}</Text>
        </Button>
      )}
      {children}
    </Flex>
  );
}
