// reports v2 apis

import { transformFiltersToParams } from "../../helper/commonHelper";
import {
  ReportsFilterApiType,
  AggregateStats,
  ReportPageReq,
  LinkClickData,
  EmailClientOpenData,
  EmailClientClickData,
  RecipientWithUrls,
  ClickData,
  OpenData,
  RecipientUnsub,
  RecipientBounce,
  RecipientNotSent,
  CampaignPrimaryDetails,
  TemplatePrimaryDetails,
  RunDetails,
  BounceReasonAggregateReqParams,
  BounceReasonListReqParams,
  BounceClassificationStats,
  BounceReasonPerContact,
  BounceClassificationInfo,
} from "../../types/campaign";
import { PaginatedList, PaginationDataV2 } from "../../types/common";
import { campaignRequest } from "./api";

export async function getAggregateStatsApi(
  data: ReportsFilterApiType
): Promise<{ stats: AggregateStats }> {
  return campaignRequest({
    url: "campaigns/reports/stats.aggregate",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getReportRunsListApi(
  data: ReportPageReq & { show_non_empty_runs: boolean }
): Promise<PaginatedList<RunDetails>> {
  return campaignRequest({
    url: "campaigns/reports/runs.list",
    data,
    version: 2,
  });
}

export async function getReportRunStatsApi(data: {
  campaign_id: string;
  run_ids: string[];
}): Promise<{ records: (AggregateStats & { run_id: string })[] }> {
  return campaignRequest({
    url: "campaigns/reports/runs.list.stats",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getReportsTopLinkApi(
  data: ReportPageReq
): Promise<PaginatedList<LinkClickData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.top_link",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getReportsEmailClientOpenApi(
  data: ReportPageReq
): Promise<PaginatedList<EmailClientOpenData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.top_email_client.open",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getReportsEmailClientClickApi(
  data: ReportPageReq
): Promise<PaginatedList<EmailClientClickData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.top_email_client.click",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientClickApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientWithUrls & ClickData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_click",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientOpenApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientWithUrls & OpenData & ClickData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_open",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientEngagementApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientWithUrls & OpenData & ClickData>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_engagement",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientUnsubApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientUnsub>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_unsub",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientBounceApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientBounce>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_bounce",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientNotSentApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientNotSent>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_not_sent",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getRecipientSpamReportApi(
  data: ReportPageReq
): Promise<PaginatedList<RecipientBounce>> {
  return campaignRequest({
    url: "campaigns/reports/stats.recipient_spamreport",
    data,
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getTemplatesOfCampaignApi(
  campaign_id: string
): Promise<{ templates: TemplatePrimaryDetails[] }> {
  return campaignRequest({
    url: "campaigns/reports/templates.list-all",
    data: { campaign_id },
    version: 2,
    shouldRetryApiCall: true,
  });
}

export async function getAlreadyRunCampaignsApi(): Promise<{
  campaigns: CampaignPrimaryDetails[];
}> {
  return campaignRequest({
    method: "POST",
    url: "campaigns/reports/campaigns.list",
    data: {},
    shouldRetryApiCall: true,
  });
}

export async function getEmailStatsWithFilters({
  campaignId,
  filters,
}: {
  campaignId: string;
  filters?: BounceReasonAggregateReqParams | BounceReasonListReqParams;
}): Promise<
  | { data: BounceClassificationStats[] }
  | PaginationDataV2<BounceReasonPerContact>
> {
  const params = transformFiltersToParams(filters);
  return campaignRequest({
    method: "GET",
    url: `campaigns/${campaignId}/stats`,
    version: 3,
    params,
  });
}

export async function getBounceClassificationInfoApi(
  classification?: string
): Promise<{ data: { [classification: string]: BounceClassificationInfo } }> {
  return campaignRequest({
    method: "GET",
    url: `campaigns/stats/bounce_classifications`,
    version: 3,
    params: {
      classification,
    },
  });
}
