import {
  Virtuoso as VirtualList,
  TableVirtuoso as VirtualTable,
} from "react-virtuoso";
import { Table, Thead, TableHeadProps, TableProps, Tr } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type VirtualizationProps<T> = {
  list: T[] | null;
  fetchingList?: boolean;
  loadMoreData?: (lastItemIndex?: number) => void;
  initIndexToDisplay?: number;
  overscan?: number;
  containerProps?: React.CSSProperties;
  LoaderAtEnd?: React.ComponentType;
  emptyState?: ReactNode;
};

type VirtualListProps<T> = {
  renderListItem: (index: number, data: T) => ReactNode;
  Header?: React.ComponentType;
} & VirtualizationProps<T>;

type VirtualTableProps<T> = {
  tableProps?: TableProps;
  tableHeaderProps?: TableHeadProps;
  TableRowContent: (index: number, data: T) => ReactNode;
  TableHeaderContent: () => ReactNode;
} & VirtualizationProps<T>;

function getSharedVirtualizationConfig<Data>({
  list,
  fetchingList,
  LoaderAtEnd,
  emptyState,
  initIndexToDisplay,
  overscan,
  loadMoreData,
  containerProps,
}: VirtualizationProps<Data>) {
  return {
    commonProps: {
      //set default height for virtualization to work.
      style: { height: "700px", ...containerProps },
      data: list ?? [],
      totalCount: list?.length ?? 0,
      endReached: loadMoreData,
      initialTopMostItemIndex: initIndexToDisplay ?? 0,
      overscan: overscan ?? 120, // 120px from bottom the loadMoreData function would be executed
    },
    Loader: () => <>{fetchingList && LoaderAtEnd && <LoaderAtEnd />}</>,
    EmptyStatePlaceholder: () => (
      <>{!list?.length && !fetchingList && emptyState}</>
    ),
  };
}

export function IVirtualList<Data>({
  Header,
  renderListItem,
  ...props
}: VirtualListProps<Data>) {
  const { commonProps, Loader, EmptyStatePlaceholder } =
    getSharedVirtualizationConfig({
      ...props,
    });

  return (
    <VirtualList
      {...commonProps}
      itemContent={renderListItem}
      components={{
        Footer: Loader,
        Header,
        EmptyPlaceholder: EmptyStatePlaceholder,
      }}
    />
  );
}

export function IVirtualTable<Data>({
  tableProps,
  tableHeaderProps,
  TableRowContent,
  TableHeaderContent,
  ...props
}: VirtualTableProps<Data>) {
  const { commonProps, Loader, EmptyStatePlaceholder } =
    getSharedVirtualizationConfig({
      ...props,
    });

  return (
    <VirtualTable
      {...commonProps}
      components={{
        Table: (props) => (
          <Table
            fontSize="sm"
            width="100%"
            borderRadius="md"
            {...tableProps}
            {...props}
          />
        ),
        TableHead: (props) => (
          <Thead
            bg="oneOffs.tableHeader"
            roundedTop="md"
            {...tableHeaderProps}
            {...props}
            h="40px"
          />
        ),
        TableRow: (props) => {
          return <Tr {...props} height="50px" />;
        },

        TableFoot: Loader,
        EmptyPlaceholder: EmptyStatePlaceholder,
      }}
      itemContent={TableRowContent}
      fixedFooterContent={() => <></>}
      fixedHeaderContent={TableHeaderContent}
    />
  );
}
