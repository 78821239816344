import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle((props) => {
  return {
    fontSize: "12px",
    transition: "transform 0.15s ease-out, background 0.15s ease-out",
    colorScheme: "blue",
    bg: "brandBlue.500",
    color: "white",
    paddingY: "5px",
    paddingX: "8px",
    _hover: {
      bg: `brandBlue.400`,
      _disabled: {
        bg: "brandBlue.400",
      },
    },
    _active: {
      bg: `brandBlue.600`,
    },
  };
});

const secondary = defineStyle(() => {
  return {
    borderWidth: "1px",
    fontSize: "12px",
    paddingY: "5px",
    paddingX: "8px",
    borderColor: "grayV2.400",
    transition: "transform 0.15s ease-out, background 0.15s ease-out",
    colorScheme: "gray",
    bg: "white",
    color: "brandBlue.500",

    _hover: {
      bg: `gray.100`,
    },

    _active: {
      bg: `gray.200`,
    },
  };
});

const buttonStyle = defineStyleConfig({
  baseStyle: {
    rounded: "md",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
  },
  sizes: {
    sm: {
      height: "30px",
    },
  },
  variants: {
    primary,
    secondary,
  },
  defaultProps: {
    size: "sm",
    variant: "primary",
  },
});

export default buttonStyle;
