import { Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../store";
import {
  fetchVisualTemplateJson,
  listVisualTemplates,
  selectTemplate,
  setTemplateContent,
} from "../templateSlice";
import { VisualTemplateData } from "../../../../common/types/template";
import {
  isFulfilled,
  isInit,
  isRejected,
} from "../../../../common/helper/commonHelper";
import { toast } from "react-toastify";
import SpinnerContainer from "../../../../components/SpinnerContainer";

function TemplateCard({
  name,
  imgUrl,
  onSelect,
}: {
  name: string;
  imgUrl: string;
  onSelect: () => void;
}) {
  return (
    <VStack width="200px" p={3} spacing={4}>
      <Box
        borderWidth={1}
        borderColor="gray.100"
        boxShadow="sm"
        _hover={{ boxShadow: "xl" }}
        style={{ cursor: "pointer" }}
        height="122px"
        width="122px"
        onClick={onSelect}
      >
        {imgUrl && (
          <Image
            maxWidth="120px"
            h="120px"
            margin="auto"
            src={imgUrl}
            alt="templates"
            style={{ objectFit: "contain" }}
          />
        )}
      </Box>
      <Text textTransform="capitalize" fontSize="sm" textAlign="center">
        {name}
      </Text>
    </VStack>
  );
}

export default function VisualTemplates({
  toggleNext,
}: {
  toggleNext: () => void;
}) {
  const dispatch = useAppDispatch();

  const { visualTemplateList } = useSelector(selectTemplate);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (isInit(visualTemplateList.loading)) dispatch(listVisualTemplates());
  }, [dispatch, visualTemplateList.loading]);

  async function selectVisualTemplate(template: VisualTemplateData) {
    setProcessing(true);
    const response = await dispatch(
      fetchVisualTemplateJson(template.template_name)
    );
    if (isFulfilled(response.meta.requestStatus)) {
      if (response.payload) {
        const templateData = response.payload as {
          name: string;
          json_content: string;
        };
        const jsonData = JSON.stringify(templateData.json_content);
        dispatch(
          setTemplateContent({
            json: jsonData,
          })
        );
        toggleNext();
      } else {
        throw new Error("template payload empty");
      }
    }
    if (isRejected(response.meta.requestStatus)) {
      toast.error("Template selection unsuccessful");
    }
    setProcessing(false);
  }

  return (
    <SpinnerContainer loading={processing}>
      <VStack alignItems="flex-start" w="100%" mt={6}>
        <Heading fontSize="20px" ml={8}>
          Templates
        </Heading>
        <Flex w="100%" flexWrap="wrap">
          {visualTemplateList.data.map((data, index) => {
            const { template_name: name, template_preview: templateImg } = data;
            return (
              <TemplateCard
                name={name.replaceAll("-", " ")}
                imgUrl={templateImg}
                onSelect={() => selectVisualTemplate(data)}
                key={index}
              />
            );
          })}
        </Flex>
      </VStack>
    </SpinnerContainer>
  );
}
