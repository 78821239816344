//User management
export enum USER_MODAL_TYPES {
  RESEND = "resend",
  EDIT = "edit",
  INVITE = "invite",
}

export const ROLE_DISPLAY_VALUE = {
  Admin: "CUSTOMER_ORG_ADMIN",
  User: "CUSTOMER_ORG_USER",
};

export const PASSWORD_REQUIREMENTS = {
  MIN_LENGTH: 8,
  MIN_UPPER_CASE: 1,
  MIN_NUMBER: 1,
  MIN_SPECIAL_CHAR: 1,
  MIN_LOWER_CASE: 1,
};

export const PASSWORD_REQ_REGEX = {
  REGEX_FOR_SPECIAL_CHARACTERS: /[!@#$%^&*()\-+={}[\]_:;"'`~<>,.?/|\\]/,
  REGEX_FOR_UPPER_CASE: /[A-Z]/,
  REGEX_FOR_LOWER_CASE: /[a-z]/,
  REGEX_FOR_NUMBER: /\d/,
};

const passwordRegExpString = `^${Object.values(PASSWORD_REQ_REGEX)
  .map((regExp) => `(?=.*${regExp.source})`)
  .join("")}.+$`;

export const PASSWORD_CONSOLIDATED_REGEX = new RegExp(passwordRegExpString);
