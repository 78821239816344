import { HStack, Badge, Text, Spinner } from "@chakra-ui/react";
import IButton, { BUTTON } from "../../../../components/IButton";
import SubHeader from "../../../../components/SubHeader";
import urls from "../../../../urls";

export default function TokenHeader({
  isLoading,
  isValidating,
  isSaveDisabled,
  tokenType,
  tokenName,
  saveHandler,
}: {
  isLoading: boolean;
  isValidating: boolean;
  isSaveDisabled: boolean;
  tokenType: string | undefined;
  tokenName: string;
  saveHandler: () => void;
}) {
  return (
    <SubHeader title={tokenName} returnUrl={urls.token} isLoading={isLoading}>
      <HStack alignItems="center" spacing="4">
        {tokenType && (
          <>
            {isValidating && <Spinner size="sm" />}
            <Text fontWeight="semibold" fontSize="sm">
              Token type
            </Text>
            <Badge colorScheme="teal">{tokenType}</Badge>
          </>
        )}
        <IButton
          variant={BUTTON.PRIMARY}
          name="save-token"
          onClick={saveHandler}
          isDisabled={isSaveDisabled}
          _hover={!isSaveDisabled ? undefined : { pointerEvents: "auto" }}
        >
          Save Token
        </IButton>
      </HStack>
    </SubHeader>
  );
}
