import React from "react";
import { MdLibraryAdd } from "react-icons/md";
import AddPropertyButton from "./AddPropertyButton";

type AddPropertyButtonType = Parameters<typeof AddPropertyButton>[0];

export default function AddFrequencyButton({
  onClick,
  hidden,
  children,
  ...props
}: {
  onClick: () => void;
  hidden: boolean;
  children?: React.ReactNode;
} & AddPropertyButtonType) {
  return (
    <AddPropertyButton
      onClick={onClick}
      children={children ?? "Add frequency"}
      leftIcon={<MdLibraryAdd fontSize="12px" />}
      hidden={hidden}
      {...props}
    />
  );
}
