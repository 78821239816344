import {
  EMAIL_CONFIG_TEXT_MAP,
  TEMPLATE_CC_BCC_TYPES,
  VALUE_META_TYPE,
} from "../constants/template";
import {
  CcBccListType,
  CcBccFieldType,
  EmailConfigChangeTracker,
} from "../types/template";
import {
  getTokenNameFromToken,
  transformTxtToToken,
  transformTxtToColumnAccessor,
} from "./commonHelper";

export function getDisplayTextForOverridenFields(
  emailConfigChange: EmailConfigChangeTracker | undefined
) {
  const entries = Object.entries(emailConfigChange ?? {});
  return entries
    .filter(([key, value]) => value)
    .map(
      ([key]) =>
        EMAIL_CONFIG_TEXT_MAP[key as keyof typeof EMAIL_CONFIG_TEXT_MAP]
    );
}

function transformStringTokenToRecipientData(
  tokenCode: string
): CcBccFieldType {
  //tokenCode -> {{token("")}}
  return {
    type: TEMPLATE_CC_BCC_TYPES.TOKEN,
    value: getTokenNameFromToken(tokenCode),
  };
}

function transformStringColumnToRecipientData(
  columnCode: string
): CcBccFieldType {
  //columnCode -> {{column("")}}
  return {
    type: TEMPLATE_CC_BCC_TYPES.COLUMN,
    value: getTokenNameFromToken(columnCode),
  };
}

function transformEmailToRecipientData(email: string): CcBccFieldType {
  return { type: TEMPLATE_CC_BCC_TYPES.TEXT, value: email };
}

// transform recipient data to single string based on type
export function transformRecipientDataToString(recipientData: CcBccFieldType) {
  const { type, value } = recipientData;
  switch (type) {
    case TEMPLATE_CC_BCC_TYPES.TEXT:
      return value;
    case TEMPLATE_CC_BCC_TYPES.TOKEN:
      return transformTxtToToken(value);
    case TEMPLATE_CC_BCC_TYPES.COLUMN:
      return transformTxtToColumnAccessor(value);
    default:
      return value;
  }
}

//transform single string back to the necessary recipient data, by checking validity
export function transformStringToValidRecipientData(
  value: string,
  isValidTokenOrColumnAccessor: (token: string) => {
    isToken: boolean;
    isColumnAccessor: boolean;
  }
): CcBccFieldType {
  const { isToken, isColumnAccessor } = isValidTokenOrColumnAccessor(value);

  if (isToken) return transformStringTokenToRecipientData(value);
  if (isColumnAccessor) return transformStringColumnToRecipientData(value);

  return transformEmailToRecipientData(value);
}

export function transformRecipientDataListToStrings(
  recipientsList: CcBccListType
): string[] | null {
  if (!recipientsList) {
    return null;
  }

  return recipientsList.map((recipient) =>
    transformRecipientDataToString(recipient)
  );
}

export function transformStringsToRecipientDataList(
  labels: string[] | null,
  isValidTokenOrColumnAccessor: (token: string) => {
    isToken: boolean;
    isColumnAccessor: boolean;
  }
): CcBccListType {
  if (!labels) {
    return null;
  }

  return labels.map((value) =>
    transformStringToValidRecipientData(value, isValidTokenOrColumnAccessor)
  );
}

function isRecipientFieldInRecipientList(
  field: CcBccFieldType,
  recipientsList: CcBccListType
) {
  if (!recipientsList) {
    return false;
  }

  return (
    recipientsList.findIndex(
      ({ type, value }) => field.type === type && field.value === value
    ) > -1
  );
}

export function hasDuplicateRecipientInLists(
  recipientsList1: CcBccListType,
  recipientsList2: CcBccListType
) {
  return recipientsList1?.some((field) =>
    isRecipientFieldInRecipientList(field, recipientsList2)
  );
}

// check if two list are equal -> same objects present in both list
export function isEqualRecipientLists(
  recipientsList1: CcBccListType,
  recipientsList2: CcBccListType
) {
  if (recipientsList1?.length !== recipientsList2?.length) {
    return false;
  }

  return (
    (recipientsList1?.every((field) =>
      isRecipientFieldInRecipientList(field, recipientsList2)
    ) &&
      recipientsList2?.every((field) =>
        isRecipientFieldInRecipientList(field, recipientsList1)
      )) ??
    false
  );
}

export function isTextEmailField(val: VALUE_META_TYPE | null) {
  return val === VALUE_META_TYPE.TEXT;
}

export function isTokenEmailField(val: VALUE_META_TYPE | null) {
  return val === VALUE_META_TYPE.TOKEN;
}
