import {
  Accordion,
  Box,
  VStack,
  Text,
  HStack,
  Icon,
  Input,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FaExclamationCircle, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  isLoading,
  onEnterKeySubmit,
} from "../../../../../../common/helper/commonHelper";
import { SegmentEventType } from "../../../../../../common/types/connection";
import Pagination, {
  PaginationLabel,
} from "../../../../../../components/Pagination";
import SpinnerContainer from "../../../../../../components/SpinnerContainer";
import StyledScrollBar from "../../../../../../components/StyledScrollBar";
import ContentContainer from "../../../../../../components/v2/ContentContainer";
import ContentContainerWithHeader from "../../../../../../components/v2/ContentContainerWithHeader";
import { useAppDispatch } from "../../../../../../store";
import {
  getSegmentNonTrackEventStatus,
  getSegmentTrackEventStatus,
  selectConnection,
} from "../../../connectionSlice";
import SegmentEventAccordion from "./components/SegmentEventAccordion";

const PAGE_SIZE = 50;

function EmptyDataFallback({ text }: { text: string }) {
  return (
    <Center py="3">
      <Text color="gray.400" fontSize="sm">
        {text}
      </Text>
    </Center>
  );
}

export default function ProductDataExplorer() {
  const dispatch = useAppDispatch();
  const {
    productDiscovery: { segmentEventStatusTrack, segmentEventStatusNonTrack },
  } = useSelector(selectConnection);

  const isByPassAll: boolean = useMemo(
    () =>
      !!segmentEventStatusNonTrack.data &&
      !!segmentEventStatusNonTrack.data.events?.force_all_events_processing,
    [segmentEventStatusNonTrack.data]
  );

  const segmentTrackEventKeysList = useMemo(
    () =>
      segmentEventStatusTrack.data?.records
        ? Object.keys(segmentEventStatusTrack.data.records)
        : [],
    [segmentEventStatusTrack.data?.records]
  );

  const isTrackEnabled = useMemo(
    () => !!segmentEventStatusTrack.data?.record_count,
    [segmentEventStatusTrack.data?.record_count]
  );

  const [pageNo, setPageNo] = useState(1);

  const searchQuery = useRef("");

  function queryChange(newSearchQuery: string) {
    searchQuery.current = newSearchQuery;
  }

  function resetPagination() {
    setPageNo(1);
  }

  const search = useCallback(
    (pageNumber: number, searchQuery: string) =>
      dispatch(
        getSegmentTrackEventStatus({
          pageNumber,
          pageSize: PAGE_SIZE,
          searchQuery,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    search(pageNo, searchQuery.current);
  }, [pageNo, search]);

  useEffect(() => {
    dispatch(getSegmentNonTrackEventStatus());
  }, [dispatch]);

  function searchButtonHandler() {
    if (pageNo !== 1) {
      resetPagination();
    } else {
      search(1, searchQuery.current);
    }
  }

  function searchOnEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    onEnterKeySubmit(e, searchButtonHandler);
  }

  return (
    <ContentContainerWithHeader>
      {isByPassAll && (
        <Box width="100%" mt="3">
          <HStack bg="grayV2.200" py={3} px={5} fontSize="sm" rounded="md">
            <Icon as={FaExclamationCircle} color="blue.400" />
            <Text fontSize={14}>
              All product events and properties are enabled for use
            </Text>
          </HStack>
        </Box>
      )}
      <ContentContainer my="15px">
        <Accordion allowMultiple>
          <VStack alignItems="flex-start">
            <Text fontWeight="bold" pb="5">
              User properties
            </Text>
          </VStack>
          <SpinnerContainer
            loading={isLoading(segmentEventStatusNonTrack.loading)}
          >
            {isEmpty(
              segmentEventStatusNonTrack.data?.events?.segment_events
            ) ? (
              <EmptyDataFallback text="No segment group/identify events available" />
            ) : (
              <>
                <SegmentEventAccordion
                  segmentEventName="group"
                  segmentEventDetails={
                    segmentEventStatusNonTrack.data?.events?.segment_events
                      ?.group as SegmentEventType
                  }
                  isByPassAll={isByPassAll}
                />
                <SegmentEventAccordion
                  segmentEventName="identify"
                  segmentEventDetails={
                    segmentEventStatusNonTrack.data?.events?.segment_events
                      ?.identify as SegmentEventType
                  }
                  isByPassAll={isByPassAll}
                />
              </>
            )}
          </SpinnerContainer>
          <HStack width="100%" pt="5" justifyContent="space-between" pb="5">
            <Text fontWeight="bold">
              Track
              <PaginationLabel
                pageNo={pageNo}
                totalNoOfRecords={
                  segmentEventStatusTrack.data?.record_count ?? 0
                }
                pageSize={PAGE_SIZE}
              />
            </Text>
            <HStack alignItems="center" spacing={0.5}>
              <Input
                name="search"
                onChange={(e) => queryChange(e.target.value)}
                w="300px"
                rounded="md"
                placeholder="Search"
                onKeyDown={(e) => searchOnEnter(e)}
              />
              <IconButton
                h="32px"
                onClick={searchButtonHandler}
                aria-label="Search"
                variant="outline"
                size="sm"
                bg="white"
                icon={<FaSearch />}
                rounded="md"
              />
            </HStack>
          </HStack>
          <SpinnerContainer
            loading={isLoading(segmentEventStatusTrack.loading)}
          >
            <StyledScrollBar maxHeight={"calc(100vh - 300px)"}>
              {isEmpty(segmentEventStatusTrack.data?.records) ? (
                <EmptyDataFallback text="No segment track events available" />
              ) : (
                segmentTrackEventKeysList.map((trackEvent) => {
                  return (
                    segmentEventStatusTrack.data?.records && (
                      <SegmentEventAccordion
                        segmentEventName={trackEvent}
                        segmentEventDetails={
                          segmentEventStatusTrack.data?.records[trackEvent]
                        }
                        isTrackEnabled={isTrackEnabled}
                        isByPassAll={isByPassAll}
                      />
                    )
                  );
                })
              )}
            </StyledScrollBar>
          </SpinnerContainer>
          <Pagination
            onPageChange={(pageNo) => setPageNo(pageNo)}
            pageCount={segmentEventStatusTrack.data?.page_count ?? 0}
            currentPage={pageNo}
          />
        </Accordion>
      </ContentContainer>
    </ContentContainerWithHeader>
  );
}
