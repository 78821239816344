import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { EMAIL_PREVIEW_VARIANTS } from "../pages/dashboard/campaign/flow/components/constants";
import {
  EmailTemplateDetails,
  EmailTemplatePreviewFrame,
} from "../pages/dashboard/campaign/flow/components/TemplatePreviewDrawer";
import { getTemplate } from "../pages/dashboard/templates/templateSlice";
import { useAppDispatch } from "../store";
import IModal from "./IModal";

export function TemplatePreviewModal({
  isOpen,
  onClose,
  templateId,
}: {
  isOpen: boolean;
  onClose: () => void;
  templateId: string;
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    isOpen &&
      templateId &&
      dispatch(getTemplate({ id: templateId, isPreview: true }));
  }, [dispatch, templateId, isOpen]);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      header={{
        title: "Email asset preview",
      }}
      size="4xl"
    >
      <Flex h="70vh" flexWrap="wrap" gap={4}>
        <EmailTemplatePreviewFrame />
        <Box flex={1} position="relative">
          <EmailTemplateDetails
            variant={EMAIL_PREVIEW_VARIANTS.READONLY}
            onSave={() => {}}
          />
        </Box>
      </Flex>
    </IModal>
  );
}
