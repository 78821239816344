import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isFulfilled,
  isLoading,
  isSuccess,
  keepClickEventRestricted,
  getTableRowLinkProps,
} from "../../../common/helper/commonHelper";
import {
  TemplateBase,
  TemplateType,
  TEMPLATE_EDITOR_MODE,
} from "../../../common/types/template";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import urls from "../../../urls";
import {
  clearTemplateCreatedId,
  createTemplate,
  deleteTemplate,
  selectTemplate,
  updateTemplateName,
  cloneTemplate,
  setCloningTemplateId,
  resetTemplateList,
  listTemplates,
} from "./templateSlice";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import GlobalDefaultsModal from "../../../components/GlobalDefaultsPopup";
import {
  resetLoadingStateEmailDefault,
  selectSettings,
} from "../settings/settingsSlice";
import { capitalize } from "lodash";
import { useAppDispatch } from "../../../store";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { DataTable } from "../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { GLOBAL_EMAIL_CONFIG_INIT } from "../../../common/constants/template";
import { cloneTemplateApi } from "../../../common/api/campaign/template";
import { toast } from "react-toastify";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import {
  ASSET_TYPES,
  DELETION_MODAL_TYPES_INFO,
  TABLE_FILTER_VARIANTS,
} from "../../../common/constants/common";
import CreateNewTemplateModal from "./CreateNewTemplateModal";
import {
  HeaderSearchProps,
  ListFilterProps,
  PaginationFilterParams,
} from "../../../common/types/common";
import InitEmptyState from "../../../components/InitialEmptyState";
import TemplateEmptyState from "../../../common/img/emptyStateLogos/template.svg";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

const initState = {
  template_id: "",
  name: "",
  content: "",
  content_json: "",
  template_type: TEMPLATE_EDITOR_MODE.CODE,
  type: "",
  subject: "",
  ...GLOBAL_EMAIL_CONFIG_INIT,
  updated_at: "",
  created_at: "",
  created_by: {
    id: 0,
    name: "",
  },
};

function TemplateListHeader({
  setEditableName,
  loading,
  isButtonDisabled,
  searchProps: { searchKeyword, onSearch, hidden },
  filterProps,
}: {
  setEditableName: () => void;
  loading?: boolean;
  isButtonDisabled?: boolean;
  searchProps: HeaderSearchProps;
  filterProps?: ListFilterProps;
}) {
  return (
    <CommonListHeader
      heading="Emails"
      createButtonProps={{
        name: "create-new-email",
        onClick: setEditableName,
        isLoading: loading,
        isDisabled: isButtonDisabled,
        text: "New Email",
      }}
      searchInputProps={{
        placeholder: "Search email templates",
        defaultValue: searchKeyword,
        onSearch: onSearch,
        name: "search-input",
        hidden: hidden,
      }}
      filterProps={filterProps}
    />
  );
}

const columnHelper = createColumnHelper<TemplateBase>();

function TemplateListTable({
  fetchingTemplates,
  changingPage,
  templateList,
  pageSize,
  openEditPage,
  openDeleteModal,
  openRenameModal,
  cloneTemplateHandler,
  handlePageChange,
  totalPageCount,
  currentPage,
  emptyMsg,
}: {
  fetchingTemplates: boolean;
  changingPage: boolean;
  templateList: TemplateBase[] | null;
  pageSize: number;
  openEditPage: (row: TemplateBase) => void;
  cloneTemplateHandler: (row: TemplateBase) => void;
  openDeleteModal: (row: TemplateBase) => void;
  openRenameModal: (row: TemplateBase) => void;
  handlePageChange: (pageNo: number) => void;
  totalPageCount: number | null;
  currentPage: number;
  emptyMsg: string;
}) {
  const { cloningTemplateId } = useSelector(selectTemplate);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        size: 900,
      }),
      columnHelper.accessor("template_type", {
        header: "Editor type",
        cell: (info) => {
          return (
            capitalize(info.getValue()) || capitalize(TEMPLATE_EDITOR_MODE.CODE)
          );
        },
        meta: {
          width: "110px",
        },
      }),
      columnHelper.accessor("updated_at", {
        header: "Last Updated On",
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
        size: 200,
      }),

      columnHelper.accessor("created_at", {
        header: "Created On",
        cell: (info) => <FormatDate date={info.getValue()} splitLines />,
        size: 200,
      }),
      columnHelper.display({
        header: "",
        id: "actions",
        cell: (info) => {
          return (
            <Flex alignItems="center" justifyContent="flex-end">
              {cloningTemplateId === info.row.original.template_id ? (
                <Spinner />
              ) : (
                <Box onClick={(e) => keepClickEventRestricted(e)}>
                  <Menu>
                    <MenuButton
                      size="sm"
                      as={IconButton}
                      aria-label="Options"
                      name="options"
                      variant="ghost"
                      icon={<FaEllipsisV />}
                    />
                    <MenuList rootProps={{ style: { right: 0 } }}>
                      <MenuItem
                        onClick={() => cloneTemplateHandler(info.row.original)}
                        name="clone"
                      >
                        Clone
                      </MenuItem>
                      <MenuItem
                        onClick={() => openRenameModal(info.row.original)}
                        name="rename"
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        onClick={() => openDeleteModal(info.row.original)}
                        name="remove"
                      >
                        Remove
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </Flex>
          );
        },
      }),
    ],
    [cloneTemplateHandler, cloningTemplateId, openDeleteModal, openRenameModal]
  );

  return (
    <DataTable
      fetchingList={fetchingTemplates}
      changingPage={changingPage}
      list={templateList}
      totalPageSize={pageSize}
      onRowClick={openEditPage}
      setPage={handlePageChange}
      totalPageCount={totalPageCount}
      currentPage={currentPage}
      columns={columns}
      emptyMsg={emptyMsg}
      getTableRowLinkProps={(data) =>
        getTableRowLinkProps({ to: urls.email, editParam: "template_id" }, data)
      }
    />
  );
}

const COLUMNS_TO_SEARCH_IN = ["name"];

export default function TemplateList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenGlobalDefaultsModal,
    onOpen: onOpenGlobalDefaultsModal,
    onClose: onCloseGlobalDefaultsModal,
  } = useDisclosure();

  const {
    templateList,
    templatesChanged,
    templateCreatedId,
    creatingTemplate,
  } = useSelector(selectTemplate);

  const { globalDefault } = useSelector(selectSettings);
  const [editableName, setEditableName] = useState<string | null>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenAddRenameModal, setIsOpenAddRenameModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TemplateBase>(initState);

  const checkGlobalDefaults = useCallback(() => {
    if (
      isSuccess(globalDefault.fetching) &&
      (!globalDefault.data?.from_email.email ||
        !globalDefault.data?.from_email.name ||
        !globalDefault.data.reply_to)
    ) {
      onOpenGlobalDefaultsModal();
    } else {
      setEditableName("");
    }
  }, [globalDefault, onOpenGlobalDefaultsModal]);

  useEffect(() => {
    return () => {
      dispatch(resetTemplateList());
    };
  }, [dispatch]);

  const edit = useCallback(
    (id: string) => {
      navigate(`${urls.email}/${id}`);
    },
    [navigate]
  );

  const { search } = useLocation();
  // Support for old urls | Replacing old urls to new
  useEffect(() => {
    navigate(`${urls.email}${search}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const fetchTemplateList = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listTemplates({
          pageNo,
          searchKeyword,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    filters,
    searchKeyword,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchTemplateList,
    fetchingList: templateList.fetchingList,
  });

  useEffect(() => {
    if (templatesChanged) {
      handlePageChange(templateList.currentPageNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesChanged]);

  useEffect(() => {
    if (templateCreatedId !== "") {
      edit(templateCreatedId);
      dispatch(clearTemplateCreatedId());
    }
  }, [templateCreatedId, edit, dispatch]);

  function createTemplateHandler(
    newName: string | null,
    editorType: TEMPLATE_EDITOR_MODE
  ) {
    setEditableName(null);
    dispatch(createTemplate({ name: newName ?? "", templateType: editorType }));
  }

  function renameTemplateHandler(newName: string) {
    setIsOpenAddRenameModal(false);
    if (selectedRow && selectedRow.template_id) {
      dispatch(
        updateTemplateName({
          templateId: selectedRow.template_id,
          name: newName,
        })
      );
    }
    setSelectedRow(initState);
  }

  function deleteTemplateHandler() {
    setIsOpenDeleteModal(false);
    if (selectedRow && selectedRow.template_id) {
      dispatch(deleteTemplate(selectedRow.template_id));
    }
    setSelectedRow(initState);
  }

  function openDeleteModal(row: TemplateBase) {
    setIsOpenDeleteModal(true);
    setSelectedRow(row);
  }

  function openRenameModal(row: TemplateBase) {
    setSelectedRow(row);
    setIsOpenAddRenameModal(true);
  }

  function editTemplateHanlder(row: TemplateBase) {
    setSelectedRow(row);
    edit(row.template_id);
  }

  async function cloneTemplateHandler(row: TemplateBase) {
    dispatch(setCloningTemplateId(row.template_id));
    const result = await dispatch(cloneTemplate(row.template_id));
    if (isFulfilled(result.meta.requestStatus)) {
      const cloneTemplateResponse = result.payload as Awaited<
        ReturnType<typeof cloneTemplateApi>
      >;
      if (cloneTemplateResponse.template)
        edit(cloneTemplateResponse.template.template_id);
      else toast.error("Template cloning failed");
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetLoadingStateEmailDefault());
    };
  }, [dispatch]);

  const isInitEmpty = !(templateList.totalPageCount || isFiltersApplied);

  return (
    <>
      <TemplateListHeader
        loading={creatingTemplate}
        isButtonDisabled={isLoading(globalDefault.fetching)}
        setEditableName={checkGlobalDefaults}
        searchProps={{
          searchKeyword: searchKeyword,
          onSearch: handleSearchChange,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.EMAIL,
          hidden: isInitEmpty,
        }}
      />
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
          <TemplateListTable
            fetchingTemplates={fetchingList}
            changingPage={templateList.changingPage}
            templateList={templateList.list as TemplateType[]}
            pageSize={templateList.pageSize}
            openEditPage={editTemplateHanlder}
            openDeleteModal={openDeleteModal}
            openRenameModal={openRenameModal}
            cloneTemplateHandler={cloneTemplateHandler}
            totalPageCount={templateList.totalPageCount}
            currentPage={templateList.currentPageNo}
            emptyMsg={`No templates found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            handlePageChange={handlePageChange}
          />
        </LayoutWithTable>
      ) : (
        <InitEmptyState
          mainText="Create an engaging email template for your first journey"
          message="No email templates"
          ctaProps={{
            children: "Create email",
            name: "new-email",
            isLoading: creatingTemplate,
            isDisabled: isLoading(globalDefault.fetching),
            onClick: checkGlobalDefaults,
          }}
        >
          <Image src={TemplateEmptyState} alt="Email" />
        </InitEmptyState>
      )}
      <DeleteConfirmationModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        submitHandler={deleteTemplateHandler}
        deleteItemType={DELETION_MODAL_TYPES_INFO[ASSET_TYPES.TEMPLATE].display}
        itemSpecificName={selectedRow?.name ?? ""}
      />
      <CreateNewTemplateModal
        templateName={editableName}
        isOpen={editableName !== null}
        onClose={() => setEditableName(null)}
        handleSubmit={createTemplateHandler}
      />

      <EditNameModal
        value={selectedRow?.name ?? ""}
        isOpen={isOpenAddRenameModal}
        action={ASSET_NAME_ACTIONS.RENAME}
        onClose={() => setIsOpenAddRenameModal(false)}
        onSubmit={renameTemplateHandler}
        asset="email"
        validateName={(name: string) => {
          return name.length < 3
            ? "Name must be at least 3 characters long"
            : "";
        }}
        validateOnClick
      />

      <GlobalDefaultsModal
        isOpen={isOpenGlobalDefaultsModal}
        onClose={onCloseGlobalDefaultsModal}
      />
    </>
  );
}
