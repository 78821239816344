import {
  Flex,
  IconButton,
  Icon,
  Box,
  Heading,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { FaArrowLeft, FaLink } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectConstants } from "../../../../../common/slices/constantsSlice";
import DropdownWithSearch from "../../../../../components/DropdownWithSearch";
import IButton, { BUTTON } from "../../../../../components/IButton";

export function Header({
  savingSync,
  connectionName,
  saveSync,
  syncDirty,
  navigateBack,
}: {
  savingSync: boolean;
  syncDirty: boolean;
  connectionName: string;
  saveSync: () => void;
  navigateBack: () => void;
}) {
  return (
    <Flex
      height="65px"
      marginBottom="0"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex alignItems="center">
        <IconButton
          size="md"
          aria-label="Go back"
          variant="ghost"
          name="return-button"
          onClick={navigateBack}
          icon={<Icon fontSize="20px" as={FaArrowLeft} />}
          mx={4}
        />
        <Box mr={3}>
          <Icon fontSize="20px" as={FaLink}></Icon>
        </Box>
        <Heading fontSize="xl" mr={10}>
          {connectionName}
        </Heading>
      </Flex>
      <Box px={4}>
        <IButton
          variant={BUTTON.PRIMARY}
          isLoading={savingSync}
          isDisabled={savingSync || !syncDirty}
          name="save-sync"
          onClick={saveSync}
        >
          Save Sync
        </IButton>
      </Box>
    </Flex>
  );
}

export function SubHeader({
  syncFrequeny,
  onChangeFrequency,
}: {
  syncFrequeny: string;
  onChangeFrequency: (value: string) => void;
}) {
  const {
    authedConstants: { warehouse_sync_valid_intervals },
  } = useSelector(selectConstants);

  const options = useMemo(
    () =>
      warehouse_sync_valid_intervals
        ? Object.entries(warehouse_sync_valid_intervals).map(([key, value]) => {
            return {
              label: value,
              value: key,
            };
          })
        : [],
    [warehouse_sync_valid_intervals]
  );

  return (
    <Flex
      justifyContent="space-between"
      fontSize="sm"
      alignItems="center"
      py="5"
    >
      <Heading fontSize="lg" fontWeight="bold">
        Sync Structure
      </Heading>
      <HStack spacing={3}>
        <Text minW="110px" color="gray.500" fontSize="sm" fontWeight="semibold">
          Sync Frequency
        </Text>
        <Box>
          {!!options.length && (
            <DropdownWithSearch
              placeholder="Select"
              options={options}
              isInvalid={!syncFrequeny}
              value={options.find((option) => option.value === syncFrequeny)}
              onChange={(option) => onChangeFrequency(option?.value ?? "")}
              controlStyle={{ width: "105px" }}
            />
          )}
        </Box>
      </HStack>
    </Flex>
  );
}
