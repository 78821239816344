import { Box, useTheme } from "@chakra-ui/react";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { WIDGET_CONTAINER_CLASS } from "./constants";
import { ActionNodeIdentifiers } from "../../../../../common/types/flow";
import { useAppDispatch } from "../../../../../store";
import { addFlowEdge, CampaignBuilderContext } from "../flowSlice";

function candidateClassName(isCandidate?: boolean) {
  if (isCandidate === undefined) {
    return WIDGET_CONTAINER_CLASS;
  } else {
    return `${WIDGET_CONTAINER_CLASS} ${
      isCandidate ? "goto-candidate" : "goto-non-candidate"
    }`;
  }
}

export default function WrapperForCandidate({
  isCandidate,
  identities,
  selectedGoto,
  selectedExit,
  onClick,
  isInvalid,
  children,
}: {
  isCandidate?: boolean;
  identities: ActionNodeIdentifiers;
  selectedGoto?: string;
  selectedExit?: { actionId: string; conditionId: string };
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isInvalid?: boolean;
  children: ReactNode;
}) {
  const dispatch = useAppDispatch();
  const { campaignId } = useContext(CampaignBuilderContext);

  const createGotoEdge = useCallback(
    (id: string, groupId?: string) => {
      if (selectedGoto) {
        dispatch(
          addFlowEdge({
            campaignId,
            source: selectedGoto,
            target: id,
            groupId,
          })
        );
      }
    },
    [dispatch, campaignId, selectedGoto]
  );

  const createExitEdge = useCallback(
    (id: string, branchId?: string) => {
      if (selectedExit) {
        dispatch(
          addFlowEdge({
            campaignId,
            source: selectedExit.actionId,
            target: id,
            conditionId: selectedExit.conditionId,
            branchId,
          })
        );
      }
    },
    [dispatch, campaignId, selectedExit]
  );

  function onSelect() {
    const { actionId, groupId, branchId } = identities;
    if (isCandidate) {
      if (selectedExit) {
        createExitEdge(actionId, branchId);
        return;
      }
      if (selectedGoto) {
        createGotoEdge(actionId, groupId);
      }
    }
  }

  const theme = useTheme();
  const colors = useMemo(() => {
    return {
      brandBlue: theme.__cssVars["--chakra-colors-brand-blue"],
      red400: theme.__cssVars["--chakra-colors-red-400"],
    };
  }, [theme]);
  const invalidBorderStyle = useMemo(
    () => (isInvalid ? `1px solid ${colors.red400}40` : "1px solid white"),
    [colors, isInvalid]
  );

  return (
    <Box
      rounded="xl"
      boxShadow="md"
      _hover={{
        boxShadow: isCandidate ? "lg" : "md",
        border: isCandidate
          ? `3px solid ${colors.brandBlue}`
          : invalidBorderStyle,
      }}
      border={
        isCandidate ? `1px solid ${colors.brandBlue}` : invalidBorderStyle
      }
      className={candidateClassName(isCandidate)}
      onClick={(e) => {
        onClick && onClick(e);
        onSelect();
      }}
    >
      {children}
    </Box>
  );
}
