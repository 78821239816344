import { VStack } from "@chakra-ui/react";
import { SalesforceConnection } from "../../../../../../../common/types/salesforceLegacyService";
import InflectionToSalesforceLegacyInclusionList from "./InflectionToSalesforceLegacyInclusionList";
import InflectionToSalesforceLegacyRecord from "./InflectionToSalesforceLegacyRecord";

export default function InflectionToSalesforceLegacy({
  data,
}: {
  data: SalesforceConnection;
}) {
  return (
    <VStack alignItems="flex-start" spacing={6} p="5" bg="white" rounded="md">
      <InflectionToSalesforceLegacyInclusionList data={data} />
      <InflectionToSalesforceLegacyRecord data={data} />
    </VStack>
  );
}
