import { Center, HStack, Text, Icon, Flex } from "@chakra-ui/react";
import { IconType } from "react-icons/lib";
import { LuCalendarClock, LuAppWindow } from "react-icons/lu";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { OperatorDetails, ValueTypes } from "../../common/types/campaign";
import { CHILD_FILTER_TYPE } from "../../common/constants/campaign";
import {
  DynamicListValueFields,
  ValueSelectFields,
} from "./DynamicListValueFields";
import InputFormControl from "./InputFormControl";
import RemoveRowCloseButton from "../RemoveRowCloseButton";
import { isArgumentMany, isBlank } from "../../common/helper/commonHelper";
import { addEllipsisToText } from "../../common/helper/dynamicListHelper";
import { OPERATOR_ARG_COUNT } from "../../common/constants/dynamicList";

type StringChildFilter =
  | CHILD_FILTER_TYPE.LINK_CLICKED
  | CHILD_FILTER_TYPE.VISITOR_SOURCE;

const CHILD_FILTER_DISPLAY = {
  [CHILD_FILTER_TYPE.LINK_CLICKED]: "where link clicked",
  [CHILD_FILTER_TYPE.VISITOR_SOURCE]: "where source of visit",
};

export function FrequencySelector({
  onOperatorChange,
  operator,
  value,
  onValueChange,
  numberOperators,
  isReadOnly,
  validationError,
  showRemoveButton,
  onRemoveRow,
  icon,
}: {
  onOperatorChange: (value: string) => void;
  operator: string | null;
  value: ValueTypes;
  onValueChange: (value: ValueTypes) => void;
  numberOperators: { [operator: string]: OperatorDetails };
  isReadOnly?: boolean;
  validationError?: string;
  showRemoveButton?: boolean;
  onRemoveRow?: () => void;
  icon?: IconType;
}) {
  const operatorDetails: OperatorDetails | null = useMemo(
    () => (operator ? numberOperators[operator] ?? null : null),
    [operator, numberOperators]
  );

  function valueChange(updatedValue: string | number, index: number) {
    let newValue = cloneDeep(value);

    newValue[index] = !isBlank(updatedValue)
      ? Number(updatedValue)
      : updatedValue;

    onValueChange(newValue);
  }

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        flex: "1",
        spacing: "2",
      };

  return (
    <Flex w="100%">
      <HStack alignItems="flex-start" flex="1" {...wrapperStyle}>
        {isReadOnly ? (
          <>{icon && <Icon as={icon} color="brand.blue" mt={1} />}</>
        ) : (
          <Center h="32px">
            <Text fontSize="sm">
              {addEllipsisToText("with a frequency of")}
            </Text>
          </Center>
        )}

        <ValueSelectFields
          options={Object.values(numberOperators).map((op) => ({
            value: op.id,
            label: op.display,
          }))}
          value={operator || ""}
          onChange={onOperatorChange}
          isReadOnly={isReadOnly}
          validationError={validationError}
        />
        {(operatorDetails?.arguments === OPERATOR_ARG_COUNT.ONE ||
          operatorDetails?.arguments === OPERATOR_ARG_COUNT.TWO) && (
          <InputFormControl
            width="100px"
            type="number"
            name="first-argument"
            value={(value[0] as string) ?? ""}
            onChange={(event) => valueChange(event.target.value, 0)}
            isReadOnly={isReadOnly}
            validationMessage={validationError}
          />
        )}

        {operatorDetails?.arguments === OPERATOR_ARG_COUNT.TWO && (
          <HStack>
            <Text>and</Text>
            <InputFormControl
              width="100px"
              name="second-argument"
              type="number"
              value={(value[1] as string) ?? ""}
              onChange={(event) => valueChange(event.target.value, 1)}
              isReadOnly={isReadOnly}
              validationMessage={validationError}
            />
          </HStack>
        )}
        {operatorDetails?.arguments &&
          !isArgumentMany(operatorDetails?.arguments) && (
            <Center h={isReadOnly ? undefined : "32px"}>
              <Text>times</Text>
            </Center>
          )}
        {operatorDetails && isArgumentMany(operatorDetails?.arguments) && (
          <DynamicListValueFields
            value={value}
            onChange={onValueChange}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            noOfArguments={operatorDetails.arguments}
            isReadOnly={isReadOnly}
            validationError={validationError}
          />
        )}
        {operatorDetails?.arguments &&
          isArgumentMany(operatorDetails?.arguments) && (
            <Center h={isReadOnly ? undefined : "32px"}>
              <Text>times</Text>
            </Center>
          )}
      </HStack>
      {!isReadOnly && showRemoveButton && onRemoveRow && (
        <RemoveRowCloseButton onClick={onRemoveRow} />
      )}
    </Flex>
  );
}

export function TimeFrameSelector({
  operator,
  setOperator,
  value,
  setValue,
  validationError,
  showRemoveButton,
  onRemoveRow,
  dateOperators,
  icon = LuCalendarClock,
  isReadOnly,
}: {
  operator: string | null;
  setOperator: (value: string) => void;
  value: ValueTypes;
  setValue: (value: ValueTypes) => void;
  validationError?: string;
  showRemoveButton: boolean;
  onRemoveRow: () => void;
  dateOperators: { [operator: string]: OperatorDetails };
  icon?: IconType;
  isReadOnly?: boolean;
}) {
  const operatorDetails: OperatorDetails | null = useMemo(
    () => (operator ? dateOperators[operator] ?? null : null),
    [operator, dateOperators]
  );

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "2",
        gridGap: "2",
      };

  return (
    <Flex w="100%">
      <HStack
        alignItems="flex-start"
        width="100%"
        flexFlow="row wrap"
        flex="1"
        {...wrapperStyle}
      >
        {isReadOnly ? (
          <>
            {icon && <Icon as={icon} color="brand.blue" mt={1} />}
            <Text fontSize="sm">during the time frame</Text>
          </>
        ) : (
          <Center h="32px">
            <Text fontSize="sm">...during the time frame...</Text>
          </Center>
        )}

        <ValueSelectFields
          options={Object.values(dateOperators).map((op) => ({
            value: op.id,
            label: op.display,
          }))}
          validationError={validationError}
          value={operator || ""}
          onChange={(val) => setOperator(val)}
          isReadOnly={isReadOnly}
        />

        {operatorDetails && (
          <DynamicListValueFields
            value={value}
            onChange={setValue}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            noOfArguments={operatorDetails.arguments}
            validationError={validationError}
            isReadOnly={isReadOnly}
          />
        )}
      </HStack>

      {!isReadOnly && showRemoveButton && (
        <RemoveRowCloseButton onClick={onRemoveRow} />
      )}
    </Flex>
  );
}

export function StringOperatorValueFilter({
  filter,
  operator,
  setOperator,
  value,
  setValue,
  validationError,
  showRemoveButton = true,
  onRemoveRow,
  stringOperators,
  icon = LuAppWindow,
  isReadOnly,
}: {
  operator: string | null;
  filter: StringChildFilter;
  setOperator: (value: string) => void;
  value: ValueTypes;
  setValue: (value: ValueTypes) => void;
  validationError?: string;
  showRemoveButton?: boolean;
  onRemoveRow: () => void;
  stringOperators: { [operator: string]: OperatorDetails };
  icon?: IconType;
  isReadOnly?: boolean;
}) {
  const operatorDetails: OperatorDetails | null = useMemo(
    () => (operator ? stringOperators[operator] ?? null : null),
    [operator, stringOperators]
  );

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "2",
        gridGap: "2",
      };

  const DISPLAY_TXT = CHILD_FILTER_DISPLAY[filter];

  return (
    <HStack width="100%">
      <HStack
        alignItems="flex-start"
        width="100%"
        wrap="wrap"
        flex="1"
        {...wrapperStyle}
      >
        {isReadOnly ? (
          <>
            {icon && <Icon as={icon} color="brand.blue" mt={1} />}
            <Text>{DISPLAY_TXT}</Text>
          </>
        ) : (
          <HStack h="33px">
            <Text fontSize="sm">{addEllipsisToText(DISPLAY_TXT)}</Text>
          </HStack>
        )}
        <ValueSelectFields
          options={Object.values(stringOperators).map((op) => ({
            value: op.id,
            label: op.display,
          }))}
          validationError={validationError}
          value={operator || ""}
          onChange={(val) => setOperator(val)}
          isReadOnly={isReadOnly}
        />
        {operatorDetails && (
          <DynamicListValueFields
            value={value}
            onChange={setValue}
            argumentTypes={operatorDetails.arguments_types}
            noOfArguments={operatorDetails.arguments}
            helperText={operatorDetails.display_2}
            validationError={validationError}
            isReadOnly={isReadOnly}
            filter={filter}
            operator={operator ?? ""}
          />
        )}
      </HStack>

      <RemoveRowCloseButton
        onClick={onRemoveRow}
        hidden={isReadOnly || !showRemoveButton}
      />
    </HStack>
  );
}
