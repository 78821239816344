import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LayoutFullscreen from "../../layout/LayoutFullScreen";
import urls from "../../urls";
import { routes } from "./accountRoutes";

export default function Landing() {
  return (
    <BrowserRouter>
      <LayoutFullscreen>
        <Routes>
          {routes.map(({ path, component: Component }, i) => {
            return <Route key={i} path={path} element={<Component />} />;
          })}
          <Route path="*" element={<Navigate replace to={urls.login} />} />
        </Routes>
      </LayoutFullscreen>
    </BrowserRouter>
  );
}
