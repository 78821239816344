import {
  ColSave,
  DestinationTable,
  SyncGetResp,
  TableLink,
} from "../../../../common/types/connection";
import { CUSTOM_TABLE } from "../../../../common/constants/campaign";
import { PERSON_ENTITY } from "../../../../common/constants/campaign";

export function getSortedColumnsList(
  destinationTables: DestinationTable[],
  activeTableName: string
) {
  const destinationTableColumns = destinationTables.find(
    (dt) => dt.table_name === activeTableName
  )?.columns;

  const destinationTableColumnsSorted = destinationTableColumns
    ?.slice()
    .sort((x, y) => +y.required - +x.required);
  return destinationTableColumnsSorted ?? [];
}

export function getUnSelectedTables(
  selectedTables: TableLink[],
  sourceTables: string[],
  destinationTables: DestinationTable[]
) {
  const selectedSourceTables = selectedTables.map((st) => st.table_name);
  const unSelectedSourceTables = sourceTables.filter(
    (source) => !selectedSourceTables.includes(source)
  );

  const selectedDestinationTables = selectedTables.map(
    (st) => st.destination_table_name
  );
  const unSelectedDestinationTables = destinationTables.filter(
    (table) =>
      !selectedDestinationTables.includes(table.table_name) ||
      table.multi_source
  );
  return { unSelectedSourceTables, unSelectedDestinationTables };
}

export function formatErrorMsg(
  activeTable: TableLink,
  invalidList: number[],
  pkValid: boolean
) {
  let errorMsg = "";
  if (invalidList.includes(activeTable.tempId)) {
    if (activeTable.destination_table_name === CUSTOM_TABLE) {
      errorMsg = "Atleast one column required";
    } else {
      errorMsg = "There are some unmapped required fields";
    }
  }
  if (activeTable.destination_table_name === PERSON_ENTITY && !pkValid) {
    if (invalidList.includes(activeTable.tempId)) {
      errorMsg += " and ";
    }
    errorMsg += "Select a primary key";
  }
  return errorMsg;
}

export function formatSyncDetails(
  syncDetails: SyncGetResp,
  tableList: DestinationTable[] | null
): TableLink[] {
  return syncDetails.tables.map((syncedTable) => {
    const selectedColumns = syncedTable.columns.map((syncedTableColumn) => {
      const destinationTableColumns = tableList?.find(
        (table) => table.table_name === syncedTable.destination_table_name
      )?.columns;
      const destinationColumn = destinationTableColumns?.find(
        (column) => column.name === syncedTableColumn.destination_column_name
      );
      const required = destinationColumn?.required;
      return {
        column_name: syncedTableColumn.column_name,
        destination_column_name: syncedTableColumn.destination_column_name,
        checked: true,
        disabled: true,
        primary_key: syncedTableColumn.primary_key,
        isDestinationPresent: !!destinationColumn,
        required,
      } as ColSave;
    });

    return {
      tempId: Math.random(),
      table_name: syncedTable.table_name,
      destination_table_name:
        syncedTable.destination_table_name !== syncedTable.table_name
          ? syncedTable.destination_table_name
          : CUSTOM_TABLE,
      columns: selectedColumns,
      active: false,
      disabled: true,
    };
  });
}

export function formatTableColumns(
  columns: ColSave[]
): { column_name: string; destination_column_name?: string }[] {
  return columns
    .filter(
      ({ checked, destination_column_name }) =>
        checked || destination_column_name
    )
    .map(({ column_name, destination_column_name, primary_key, required }) => {
      const data = {
        column_name,
        primary_key,
        required,
        destination_column_name: destination_column_name || undefined,
      };
      return data;
    });
}
