import * as React from "react";
import { EditorView, ViewPlugin } from "@codemirror/view";
import { Extension, Compartment } from "@codemirror/state";
import { LanguageSupport } from "@codemirror/language";
import { CompletionSource } from "@codemirror/autocomplete";

type Bundle = typeof import("./codemirrorModules");

interface Theme {
  base: Extension;
  highlight: Extension;
}

interface ExtentsionOptions {
  lang: string;
  theme: Theme;
  autoCompleteOptions?: CompletionSource;
  viewPlugins?: ViewPlugin<any>[];
  extraExtensions?: Extension[];
}

interface Editor {
  view: EditorView;
  codemirror: Bundle;
  setTheme: (theme: Theme) => void;
  loadExentions: (options: ExtentsionOptions) => Promise<Extension>;
}

export default function useCodemirror(): [
  null | Editor,
  (div: null | HTMLDivElement) => void
] {
  const [el, setEl] = React.useState<HTMLDivElement | null>(null);
  const [editor, setEditor] = React.useState<Editor | null>(null);

  const themeCompartment = new Compartment();
  const highlightCompartment = new Compartment();

  React.useEffect(() => {
    if (!el) {
      return;
    }

    let didCancel = false;

    let view: null | EditorView;

    async function createEditor() {
      const codemirror = await import("./codemirrorModules");

      if (!el || didCancel) {
        return;
      }

      view = new codemirror.view.EditorView({
        parent: el,
      });

      setEditor({
        view,
        codemirror,
        setTheme: (theme: Theme) => {
          if (view) {
            view.dispatch({
              effects: [
                themeCompartment.reconfigure(theme.base),
                highlightCompartment.reconfigure(theme.highlight),
              ],
            });
          }
        },
        loadExentions: (options: ExtentsionOptions) =>
          loadExentions({
            codemirror,
            languageName: options.lang,
            theme: options.theme,
            themeCompartment,
            highlightCompartment,
            autoCompleteOptions: options.autoCompleteOptions,
            viewPlugins: options.viewPlugins,
            extraExtensions: options.extraExtensions,
          }),
      });
    }

    createEditor();

    return () => {
      didCancel = true;

      if (view) {
        view.destroy();
        view = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);

  return [editor, setEl];
}

export function getTheme(codemirror: Bundle, kind: "light" | "dark"): Theme {
  if (kind === "dark") {
    return {
      base: codemirror.themeOneDark.oneDarkTheme,
      highlight: codemirror.themeOneDark.oneDarkHighlightStyle,
    };
  }

  return {
    base: codemirror.view.EditorView.theme({
      "&": {
        backgroundColor: "white",
      },
    }),
    highlight: codemirror.highlight.defaultHighlightStyle,
  };
}

async function loadExentions({
  languageName,
  codemirror,
  theme,
  themeCompartment,
  highlightCompartment,
  autoCompleteOptions,
  viewPlugins = [],
  extraExtensions = [],
}: {
  languageName: string;
  theme: Theme;
  codemirror: Bundle;
  themeCompartment: Compartment;
  highlightCompartment: Compartment;
  autoCompleteOptions?: CompletionSource;
  viewPlugins?: ViewPlugin<any>[];
  extraExtensions?: Extension[];
}): Promise<Extension> {
  const languageDescription =
    codemirror.language.LanguageDescription.matchLanguageName(
      codemirror.languageData.languages,
      languageName,
      true
    );

  let languageSupport: null | LanguageSupport = null;

  if (languageDescription) {
    languageSupport = await languageDescription.load();
  }

  const extensions = [
    codemirror.view.highlightSpecialChars(),
    codemirror.history.history(),
    codemirror.view.drawSelection(),
    codemirror.state.EditorState.allowMultipleSelections.of(true),
    codemirror.language.indentOnInput(),
    codemirror.matchbrackets.bracketMatching(),
    codemirror.closebrackets.closeBrackets(),
    codemirror.autocomplete.autocompletion(
      autoCompleteOptions
        ? {
            activateOnTyping: true,
            override: [autoCompleteOptions],
          }
        : undefined
    ),
    codemirror.rectangularSelection.rectangularSelection(),
    codemirror.view.highlightActiveLine(),
    codemirror.search.highlightSelectionMatches(),
    ...(languageSupport ? [languageSupport] : []),
    themeCompartment.of(theme.base),
    highlightCompartment.of(theme.highlight),
    codemirror.view.keymap.of([
      ...codemirror.commands.defaultKeymap,
      ...codemirror.closebrackets.closeBracketsKeymap,
      ...codemirror.search.searchKeymap,
      ...codemirror.history.historyKeymap,
      ...codemirror.fold.foldKeymap,
      ...codemirror.comment.commentKeymap,
      ...codemirror.autocomplete.completionKeymap,
      ...codemirror.lint.lintKeymap,
    ]),
    ...viewPlugins,
    ...extraExtensions,
  ];

  return extensions;
}
