import { Icon, Box, IconButton, HStack, Tooltip } from "@chakra-ui/react";
import { inviteUsers, resendInvite, updateUser } from "./userSlice";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Auth, Roles } from "../../../common/types/auth";
import {
  ROLE_DISPLAY_VALUE,
  USER_MODAL_TYPES,
} from "../../../common/constants/auth";
import { Formik, Form, FieldArray, FormikProps } from "formik";
import { useDispatch } from "react-redux";
import DropdownWithSearch from "../../../components/DropdownWithSearch";
import IModal from "../../../components/IModal";
import { BUTTON } from "../../../components/IButton";
import InputFieldWithError from "../../../components/InputFieldWithError";

function ModalInputs({
  formik,
  index,
  deactiveEmail,
}: {
  formik: FormikProps<{
    forms: {
      name: string;
      email: string;
      role_names: Roles[];
    }[];
  }>;
  index: number;
  deactiveEmail: boolean;
}) {
  const role = Object.entries(ROLE_DISPLAY_VALUE).find(
    ([_, value]) => value === formik.values.forms[index].role_names[0]
  );
  let roleDropdownData = {
    label: "User",
    value: ROLE_DISPLAY_VALUE.User,
  };
  if (role) {
    roleDropdownData = {
      label: role[0],
      value: role[1],
    };
  }

  return (
    <HStack key={index} alignItems="flex-end" justifyContent="space-around">
      <InputFieldWithError
        labelText="Full name"
        name={`forms.${index}.name`}
        type="text"
        placeholder="name"
        value={formik.values.forms[index].name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
      />

      <InputFieldWithError
        labelText="Email"
        name={`forms.${index}.email`}
        type="email"
        isDisabled={deactiveEmail}
        autoComplete="email"
        placeholder="Email"
        value={formik.values.forms[index].email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isRequired
      />
      <HStack py={2}>
        <DropdownWithSearch
          options={Object.entries(ROLE_DISPLAY_VALUE).map(([key, value]) => ({
            label: key,
            value: value,
          }))}
          onChange={(option) =>
            formik.setFieldValue(`forms.${index}.role_names`, [
              option?.value ?? "",
            ])
          }
          value={roleDropdownData}
          controlStyle={{ width: "120px", fontSize: "16px" }}
        />
      </HStack>
    </HStack>
  );
}

function RowButtons({
  disableAddButton,
  isAddButton,
  removeRow,
  addRow,
}: {
  disableAddButton: boolean;
  isAddButton: boolean;
  removeRow: () => void;
  addRow: () => void;
}) {
  return (
    <HStack mb="10px !important">
      <Tooltip
        label="Sending an invite requires at least one user's information."
        placement="bottom-start"
        isDisabled={!disableAddButton}
      >
        <IconButton
          bgColor="grey.300"
          color="black"
          name="remove-row"
          rounded="full"
          icon={<Icon as={FaMinus} />}
          onClick={removeRow}
          aria-label="remove-row"
          variant={BUTTON.SECONDARY}
          height="32px"
          width="32px"
          isDisabled={disableAddButton}
        />
      </Tooltip>
      {isAddButton ? (
        <IconButton
          bgColor="brand.blue"
          colorScheme="blue"
          rounded="full"
          name="add-row"
          height="32px"
          width="32px"
          variant={BUTTON.PRIMARY}
          icon={<Icon as={FaPlus} />}
          onClick={addRow}
          aria-label="add-row"
        />
      ) : (
        <Box boxSize="32px"></Box>
      )}
    </HStack>
  );
}

export default function UserManagementModal({
  onClose,
  isOpen,
  data,
  type,
}: {
  onClose: () => void;
  isOpen: boolean;
  data: Auth | null;
  type: USER_MODAL_TYPES;
}) {
  const formInitialValues = {
    name: type !== USER_MODAL_TYPES.INVITE && data ? data.name : "",
    email: type !== USER_MODAL_TYPES.INVITE && data ? data.email : "",
    role_names:
      type !== USER_MODAL_TYPES.INVITE && data
        ? [data.roles[0].role_name as Roles]
        : [ROLE_DISPLAY_VALUE.User as Roles],
  };

  const dispatch = useDispatch();

  function modalSubmission(values: {
    forms: {
      name: string;
      email: string;
      role_names: Roles[];
    }[];
  }) {
    if (type === USER_MODAL_TYPES.INVITE) {
      dispatch(inviteUsers(values.forms));
      onClose();
    }
    if (type === USER_MODAL_TYPES.RESEND && data) {
      dispatch(resendInvite({ account_id: data.id, ...values.forms[0] }));
      onClose();
    }
    if (type === USER_MODAL_TYPES.EDIT && data) {
      const { name, role_names } = values.forms[0];
      let updatedData = {};
      if (data.name !== name) updatedData = { name };
      if (data.roles[0].role_name !== role_names[0])
        updatedData = { ...updatedData, role_names };
      dispatch(updateUser({ account_id: data.id, ...updatedData }));
      onClose();
    }
  }

  return (
    <IModal
      onClose={onClose}
      isOpen={isOpen}
      size="2xl"
      header={{
        title: type === USER_MODAL_TYPES.EDIT ? "Edit user" : "Invite users",
      }}
      primaryButton={{
        label:
          type === USER_MODAL_TYPES.EDIT
            ? "Update user"
            : `Send invite ${type === USER_MODAL_TYPES.RESEND ? "again" : ""}`,
        props: {
          type: "submit",
          form: "user-form",
        },
      }}
      secondaryButton={{
        label: "cancel",
        props: { onClick: onClose },
      }}
    >
      <Formik
        initialValues={{ forms: [formInitialValues] }}
        onSubmit={modalSubmission}
      >
        {(formik) => (
          <Form id="user-form">
            <FieldArray
              name="forms"
              render={(arrayHelpers) => (
                <Box>
                  {formik.values.forms.map((_, index) => (
                    <HStack
                      key={index}
                      alignItems="flex-end"
                      my="2"
                      justifyContent="space-around"
                    >
                      <ModalInputs
                        index={index}
                        formik={formik}
                        deactiveEmail={type === USER_MODAL_TYPES.EDIT}
                      />
                      {type === USER_MODAL_TYPES.INVITE && (
                        <RowButtons
                          disableAddButton={formik.values.forms.length === 1}
                          isAddButton={index === formik.values.forms.length - 1}
                          removeRow={() => arrayHelpers.remove(index)}
                          addRow={() => arrayHelpers.push(formInitialValues)}
                        />
                      )}
                    </HStack>
                  ))}
                </Box>
              )}
            />
          </Form>
        )}
      </Formik>
    </IModal>
  );
}
