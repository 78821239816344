import { Box, Radio, RadioGroup, Text, VStack } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { ConnectionListItem, SOURCES } from "../../common/types/unifiedMapping";
import IModal from "../IModal";

export default function AutomapModal({
  crmSources,
  isOpen,
  onCancel,
  next,
}: {
  crmSources: ConnectionListItem[];
  isOpen: boolean;
  onCancel: () => void;
  next: (selectedSource: SOURCES) => void;
}) {
  const [selectedSourceType, setSelectedSourceType] = useState<SOURCES>(
    SOURCES.SALESFORCE
  );

  const isConnectionPresent: { hubspot: boolean; salesforce: boolean } =
    useMemo(() => {
      let newIsConnectionPresent = { hubspot: false, salesforce: false };

      crmSources.forEach((source) => {
        if (source.source === SOURCES.HUBSPOT) {
          newIsConnectionPresent.hubspot = true;
        }
        if (source.source === SOURCES.SALESFORCE) {
          newIsConnectionPresent.salesforce = true;
        }
      });
      return newIsConnectionPresent;
    }, [crmSources]);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onCancel}
      size="md"
      header={{
        title: "Auto-Map Fields",
      }}
      primaryButton={{
        label: "Next",
        props: {
          onClick: () => next(selectedSourceType),
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onCancel,
        },
      }}
    >
      <VStack spacing={3}>
        <Text>
          {`Inflection will automatically map commonly used fields for
          ${isConnectionPresent.salesforce ? "Salesforce" : ""} ${
            isConnectionPresent.hubspot ? "Hubspot" : ""
          }`}
        </Text>

        {isConnectionPresent.hubspot && isConnectionPresent.salesforce && (
          <RadioGroup
            w="100%"
            onChange={(value: SOURCES) => setSelectedSourceType(value)}
            value={selectedSourceType}
            mt={2}
          >
            <VStack alignItems="flex-start" mx="5">
              <Radio value={SOURCES.SALESFORCE}>
                <Box p={2}>
                  <Text fontSize="16px" fontWeight="600">
                    Salesforce
                  </Text>
                </Box>
              </Radio>
              <Radio value={SOURCES.HUBSPOT}>
                <Box p={2} mb={2}>
                  <Text fontSize="16px" fontWeight="600">
                    Hubspot
                  </Text>
                </Box>
              </Radio>
            </VStack>
          </RadioGroup>
        )}

        <Text bg="gray.100" p="3" rounded="md">
          Note: Auto mapping fields would overwrite your current mapping
        </Text>
      </VStack>
    </IModal>
  );
}
