import { Box, Text, Flex, Icon, BoxProps } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import {
  BsFillCheckCircleFill,
  BsFillXCircleFill,
  BsCircleFill,
} from "react-icons/bs";
import {
  PASSWORD_REQUIREMENTS,
  PASSWORD_REQ_REGEX,
} from "../common/constants/auth";

const PASSWORD_REQ_HELPER_TXT = {
  minNumber: `${PASSWORD_REQUIREMENTS.MIN_NUMBER} number`,
  minLength: `${PASSWORD_REQUIREMENTS.MIN_LENGTH} characters`,
  minUpperCase: `${PASSWORD_REQUIREMENTS.MIN_UPPER_CASE} upper case letter`,
  minSpecialCharacter: `${PASSWORD_REQUIREMENTS.MIN_SPECIAL_CHAR} special character`,
  minLowerCase: `${PASSWORD_REQUIREMENTS.MIN_LOWER_CASE} lower case letter`,
};

export function IconLabelPair({
  requirementMet,
  text,
  isFocussed,
}: {
  requirementMet: boolean;
  text: string;
  isFocussed: boolean;
}) {
  let StatusIcon;
  if (requirementMet) {
    StatusIcon = (
      <Icon
        as={BsFillCheckCircleFill}
        boxSize={3.5}
        color="green.600"
        aria-label="check-circle"
      />
    );
  } else if (isFocussed) {
    StatusIcon = (
      <Icon
        as={BsCircleFill}
        boxSize={3.5}
        color="gray.200"
        aria-label="blank-circle"
      />
    );
  } else {
    StatusIcon = (
      <Icon
        as={BsFillXCircleFill}
        boxSize={3.5}
        color="red.600"
        aria-label="x-circle"
      />
    );
  }
  return (
    <Flex alignItems="center" gap={2}>
      {StatusIcon}
      <Text>
        At least{" "}
        <Text as="span" fontWeight="bold">
          {text}
        </Text>
      </Text>
    </Flex>
  );
}

export default function PasswordValidationContent({
  heading = "Password must meet the following requirements:",
  value,
  isFocussed,
  onClose,
  onOpen,
  ...props
}: BoxProps & {
  heading?: string;
  value: string;
  isFocussed: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}) {
  const [passwordCheck, setPasswordCheck] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    isLengthValid: false,
  });

  useEffect(() => {
    const pass = checkPasswordRequirements(value);
    if (pass && !isFocussed) {
      onClose?.();
    } else if (!pass && value.length > 0) {
      onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isFocussed]);

  function checkPasswordRequirements(password: string): boolean {
    const passwordReqCheck = {
      hasUpperCase: PASSWORD_REQ_REGEX.REGEX_FOR_UPPER_CASE.test(password),
      hasNumber: PASSWORD_REQ_REGEX.REGEX_FOR_NUMBER.test(password),
      hasSpecialCharacter:
        PASSWORD_REQ_REGEX.REGEX_FOR_SPECIAL_CHARACTERS.test(password),
      hasLowerCase: PASSWORD_REQ_REGEX.REGEX_FOR_LOWER_CASE.test(password),
      isLengthValid: password.length >= PASSWORD_REQUIREMENTS.MIN_LENGTH,
    };

    setPasswordCheck({ ...passwordReqCheck });
    return Object.values(passwordReqCheck).every((reqCheck) => reqCheck);
  }

  const passwordRequirementsDisplay = [
    {
      requirementMet: passwordCheck.hasSpecialCharacter,
      helperTxt: PASSWORD_REQ_HELPER_TXT.minSpecialCharacter,
    },
    {
      requirementMet: passwordCheck.hasUpperCase,
      helperTxt: PASSWORD_REQ_HELPER_TXT.minUpperCase,
    },
    {
      requirementMet: passwordCheck.hasLowerCase,
      helperTxt: PASSWORD_REQ_HELPER_TXT.minLowerCase,
    },
    {
      requirementMet: passwordCheck.hasNumber,
      helperTxt: PASSWORD_REQ_HELPER_TXT.minNumber,
    },
    {
      requirementMet: passwordCheck.isLengthValid,
      helperTxt: PASSWORD_REQ_HELPER_TXT.minLength,
    },
  ];

  return (
    <Box>
      <Text width="100%" pb={4} fontSize={14}>
        {heading}
      </Text>
      <Box
        fontSize={12}
        justifyContent="start"
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="start"
        gap={3}
        {...props}
      >
        {passwordRequirementsDisplay.map(({ requirementMet, helperTxt }) => {
          return (
            <IconLabelPair
              requirementMet={requirementMet}
              text={helperTxt}
              isFocussed={isFocussed}
            />
          );
        })}
      </Box>
    </Box>
  );
}
