import { Box, BoxProps } from "@chakra-ui/react";

export default function CenteredTextBox({
  message,
  ...props
}: { message: string } & BoxProps) {
  return (
    <Box
      w="100%"
      minH="200px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      {...props}
    >
      {message}
    </Box>
  );
}
