import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOADING_STATES } from "../../../../common/constants/common";
import { SYNC_ERROR_RESYNC_ARCHIVE_PAGINATION } from "../../../../common/constants/salesforce";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
  initializePaginationWithLoadingState,
} from "../../../../common/helper/commonHelper";
import {
  LoadingWithData,
  PaginationTypeWithLoadingState,
} from "../../../../common/types/common";
import {
  SalesforceConnectionV2,
  SfVerificationParams,
  InitSalesforceConReqType,
  SfCampaignSyncError,
  SfSyncRunsGet,
  SfSyncRunRecord,
  SfSyncErrorsGet,
  SfSyncErrorRecord,
  SF_SYNC_ERROR_TYPE,
  SfSyncAffectedRecordsGet,
  SfSyncAffectedRecord,
  SfSyncRunsSummaryGet,
  SfSyncRunsSummaryType,
  SfSyncErrorsResyncArchiveReq,
  SfColumnsOfAllSyncObjects,
  SfSyncErrorResyncArchiveWithPagination,
  SalesforcePersonRemapReq,
  SalesforcePersonSyncDetails,
  SyncObjectsSettings,
  SfUpdateSyncObjectSettings,
  SalesforceSyncScheduleDetails,
} from "../../../../common/types/salesforce";
import { RootState } from "../../../../store";
import { expireApiStatus } from "../../../../common/slices/apiStatusSlice";
import { CONNECTION_LIST_ALL_ACTION } from "../connectionSlice";
import { getSalesforceCampaignSyncErrorsApi } from "../../../../common/api/salesforceIntegrations/campaign";
import { toast } from "react-toastify";
import { SalesforceUnifiedMappingColumns } from "../../../../common/types/salesforce";
import {
  initSalesforceConnectionApi,
  reauthenticateSalesforceApi,
  verifySalesforceConnectionApi,
  deleteSalesforceConnectionApi,
  getSalesforceConnectionApi,
  pauseSalesforceSyncApi,
  resumeSalesforceSyncApi,
  finalizeSalesforceSetupApi,
  updateSalesforceConnectionApi,
  listAllSalesforceConnectionsApi,
  listSalesforceSyncRunsApi,
  listSalesforceSyncErrorsApi,
  listSalesforceSyncErrorsAffectedRecordsApi,
  getSalesforceSyncRunsSummaryApi,
  resyncSalesforceSyncErrorsApi,
  archiveSalesforceSyncErrorsApi,
  listSfSelectiveSyncBooleanColumnsApi,
  listAllSalesforceSyncObjectColumnsApi,
  startSalesforceSyncApi,
  resyncPersonFromSalesforceConnectionApi,
  remapPersonFromSalesforceConnectionApi,
  getPersonSalesforceSyncStatusApi,
  getSfSyncRunScheduleDetailsApi,
} from "../../../../common/api/salesforceIntegrations/salesforceConnection";
import { cloneDeep } from "lodash";

const transformSyncObjectSettings = (
  syncObjectSettings: SyncObjectsSettings | null
): SfUpdateSyncObjectSettings => {
  if (!syncObjectSettings) return null;
  const updatedSettings: any = cloneDeep(syncObjectSettings);

  Object.entries(updatedSettings).forEach(
    ([key, objectSetting]: [string, any]) => {
      if (objectSetting?.readSettings?.selectiveSyncSettings) {
        objectSetting.readSettings.selectiveSyncSettings = {
          fieldName:
            objectSetting.readSettings.selectiveSyncSettings.field?.name ?? "",
        };
      }
    }
  );

  return updatedSettings;
};

interface SalesforceInitState {
  sfConnection: null | SalesforceConnectionV2;
  sfConnectionList: PaginationTypeWithLoadingState<SalesforceConnectionV2>;
  oAuthUrl: LoadingWithData<string>;
  verifyingSfConnection: LOADING_STATES;
  updatingSfConnection: LOADING_STATES;
  completingSfSetup: LOADING_STATES;
  fetchingSfConnection: LOADING_STATES;
  deletingSfConnection: LOADING_STATES;
  pausingSfSync: LOADING_STATES;
  resumingSfSync: LOADING_STATES;
  startingSync: LOADING_STATES;
  sfSyncRuns: PaginationTypeWithLoadingState<SfSyncRunRecord>;
  sfCampaignSyncErrors: PaginationTypeWithLoadingState<SfCampaignSyncError>;
  sfSyncErrors: {
    [key: string]: SfSyncErrorResyncArchiveWithPagination<SfSyncErrorRecord>;
  };

  sfSyncErrorAffectedRecords: PaginationTypeWithLoadingState<SfSyncAffectedRecord>;
  sfSyncRunsSummary: LoadingWithData<SfSyncRunsSummaryType>;
  //boolean columns for selective syn
  sfSelectiveSyncColumns: LoadingWithData<SfColumnsOfAllSyncObjects>;
  //all field columns for unified mapping can be used for different salesforce connection
  sfUnifiedMappingSyncObjectColumns: {
    [connectionId: string]: SalesforceUnifiedMappingColumns;
  };
  resyncingPerson: LOADING_STATES;
  personSfSyncDetails: LoadingWithData<SalesforcePersonSyncDetails>;
  syncRunScheduleDetails: LoadingWithData<SalesforceSyncScheduleDetails>;
}

const initialState: SalesforceInitState = {
  sfConnection: null,
  sfConnectionList: initializePaginationWithLoadingState({}),
  oAuthUrl: initializeLoadingData(""),
  fetchingSfConnection: LOADING_STATES.INIT,
  updatingSfConnection: LOADING_STATES.INIT,
  verifyingSfConnection: LOADING_STATES.INIT,
  deletingSfConnection: LOADING_STATES.INIT,
  pausingSfSync: LOADING_STATES.INIT,
  resumingSfSync: LOADING_STATES.INIT,
  completingSfSetup: LOADING_STATES.INIT,
  startingSync: LOADING_STATES.INIT,
  sfSyncRuns: initializePaginationWithLoadingState({}),
  sfCampaignSyncErrors: initializePaginationWithLoadingState({}),
  sfSyncErrors: {
    [SF_SYNC_ERROR_TYPE.OTHERS]: SYNC_ERROR_RESYNC_ARCHIVE_PAGINATION,
    [SF_SYNC_ERROR_TYPE.PERMISSION]: SYNC_ERROR_RESYNC_ARCHIVE_PAGINATION,
  },
  sfSyncErrorAffectedRecords: initializePaginationWithLoadingState({}),
  sfSyncRunsSummary: initializeLoadingData({}),
  sfSelectiveSyncColumns: initializeLoadingData({}),
  sfUnifiedMappingSyncObjectColumns: {},
  resyncingPerson: LOADING_STATES.INIT,
  personSfSyncDetails: initializeLoadingData({}),
  syncRunScheduleDetails: initializeLoadingData({}),
};

export const initSalesforceConnection = createAsyncThunk(
  "connection/init-sf-connection",
  async (data: InitSalesforceConReqType, { dispatch }) => {
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return initSalesforceConnectionApi(data);
  }
);

export const verifySalesforceConnection = createAsyncThunk(
  "connection/verify-salesforce",
  async (data: SfVerificationParams) => {
    return await verifySalesforceConnectionApi(data);
  }
);

//only name , sync settings and inflection api limits can be updated
export const updateSalesforceConnection = createAsyncThunk(
  "connection/sf-update",
  async (connectionDetails: SalesforceConnectionV2) => {
    return await updateSalesforceConnectionApi(connectionDetails.connectionId, {
      connectionName: connectionDetails.connectionName,
      syncSettings: {
        objectSettings: transformSyncObjectSettings(
          connectionDetails.syncSettings?.objectSettings ?? null
        ),
      },
      inflectionLimits: connectionDetails.inflectionLimits,
    });
  }
);

export const completeSalesforceSetup = createAsyncThunk(
  "connection/sf-setup-complete",
  async (data: { connectionId: string; autoMapAndStartSync: boolean }) => {
    return await finalizeSalesforceSetupApi(data);
  }
);

export const reauthenticateSalesforce = createAsyncThunk(
  "connection/re-auth-salesforce",
  async (connectionId: string) => {
    return await reauthenticateSalesforceApi(connectionId);
  }
);

export const listAllSalesforceConnections = createAsyncThunk(
  "connection/get-sf-connection-list",
  async () => {
    return await listAllSalesforceConnectionsApi();
  }
);

export const getSalesforceConnectionDetails = createAsyncThunk(
  "connection/get-sf-connection-details",
  async (connectionId: string) => {
    return await getSalesforceConnectionApi(connectionId);
  }
);

export const deleteSalesforceConnection = createAsyncThunk(
  "connection/delete-sf-connection",
  async (connectionId: string, { dispatch }) => {
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return await deleteSalesforceConnectionApi(connectionId);
  }
);

export const pauseSalesforceSync = createAsyncThunk(
  "connection/pause-sf-sync",
  async (connectionId: string) => {
    return await pauseSalesforceSyncApi(connectionId);
  }
);

export const resumeSalesforceSync = createAsyncThunk(
  "connection/resume-sf-sync",
  async (connectionId: string) => {
    return await resumeSalesforceSyncApi(connectionId);
  }
);

export const listSalesforceSyncRuns = createAsyncThunk(
  "connection/sf-sync-runs",
  async (
    data: Omit<SfSyncRunsGet, "pageNumber" | "pageSize">,
    { getState }
  ) => {
    const {
      salesforce: {
        sfSyncRuns: { currentPageNo, pageSize },
      },
    } = getState() as RootState;
    return await listSalesforceSyncRunsApi({
      ...data,
      pageSize,
      pageNumber: currentPageNo,
    });
  }
);

export const listSalesforceSyncErrors = createAsyncThunk(
  "connection/sf-sync-errors",
  async (
    data: Omit<SfSyncErrorsGet, "pageNumber" | "pageSize">,
    { getState }
  ) => {
    const {
      salesforce: { sfSyncErrors },
    } = getState() as RootState;
    const { currentPageNo, pageSize } = sfSyncErrors[data.errorType];

    return await listSalesforceSyncErrorsApi({
      ...data,
      pageSize,
      pageNumber: currentPageNo,
    });
  }
);

export const listSalesforceSyncAffectedRecords = createAsyncThunk(
  "connection/sf-sync-affected-records",
  async (
    data: Omit<SfSyncAffectedRecordsGet, "pageNumber" | "pageSize">,
    { getState }
  ) => {
    const {
      salesforce: { sfSyncErrorAffectedRecords },
    } = getState() as RootState;
    const { currentPageNo, pageSize } = sfSyncErrorAffectedRecords;

    return await listSalesforceSyncErrorsAffectedRecordsApi({
      ...data,
      pageSize,
      pageNumber: currentPageNo,
    });
  }
);

export const getSalesforceCampaignSyncErrors = createAsyncThunk(
  "connection/get-sf-campaign-sync-errors",
  async ({
    startTimestamp,
    endTimestamp,
  }: {
    startTimestamp: number;
    endTimestamp: number;
  }) => {
    return await getSalesforceCampaignSyncErrorsApi(
      startTimestamp,
      endTimestamp
    );
  }
);

export const getSalesforceSyncRunsSummary = createAsyncThunk(
  "connection/sf-sync-runs-summary",
  async (data: SfSyncRunsSummaryGet) => {
    return await getSalesforceSyncRunsSummaryApi(data);
  }
);

export const resyncSalesforceSyncErrors = createAsyncThunk(
  "connection/sf-sync-errors-resync",
  async ({
    data,
    errorType,
  }: {
    data: SfSyncErrorsResyncArchiveReq;
    errorType: SF_SYNC_ERROR_TYPE;
  }) => {
    return await resyncSalesforceSyncErrorsApi(data);
  }
);

export const archiveSalesforceSyncErrors = createAsyncThunk(
  "connection/sf-sync-errors-archive",
  async ({
    data,
    errorType,
  }: {
    data: SfSyncErrorsResyncArchiveReq;
    errorType: SF_SYNC_ERROR_TYPE;
  }) => {
    return await archiveSalesforceSyncErrorsApi(data);
  }
);

export const listSfSelectiveSyncBooleanColumns = createAsyncThunkWrapper({
  actionName: "connections/sf-selective-sync-fields",
  dispatchFn: async (connectionId: string) => {
    return await listSfSelectiveSyncBooleanColumnsApi({
      connectionId,
      forceRefresh: true,
      lenientStaleCheck: false,
    });
  },
  isCachable: true,
});

// this will give all the columns of all type mapped to inflection type
export const listAllSalesforceSyncObjectColumns = createAsyncThunk(
  "connections/sf-table-columns",
  async (connectionId: string) => {
    return await listAllSalesforceSyncObjectColumnsApi({
      connectionId,
      forceRefresh: true,
      lenientStaleCheck: false,
    });
  }
);

export const startSalesforceSync = createAsyncThunk(
  "connection/sf-sync-start",
  async (connectionId: string) => {
    return await startSalesforceSyncApi(connectionId);
  }
);

//person from salesforce connection apis
export const resyncPersonFromSalesforceConnection = createAsyncThunk(
  "connection/sf-resync-person",
  async (internalPersonEmail: string) => {
    return await resyncPersonFromSalesforceConnectionApi(internalPersonEmail);
  }
);

export const remapPersonFromSalesforceConnection = createAsyncThunk(
  "connection/sf-remap-person",
  async (data: SalesforcePersonRemapReq) => {
    return await remapPersonFromSalesforceConnectionApi(data);
  }
);

export const getPersonSalesforceSyncStatus = createAsyncThunk(
  "connection/sf-remap-person",
  async (internalPersonEmail: string) => {
    return await getPersonSalesforceSyncStatusApi(internalPersonEmail);
  }
);

export const getSfSyncRunScheduleDetails = createAsyncThunk(
  "connection/sf-sync-run-schedule-details",
  async (connectionId: string) => {
    return await getSfSyncRunScheduleDetailsApi(connectionId);
  }
);

const salesforceSlice = createSlice({
  name: "salesforce",
  initialState,
  reducers: {
    resetSalesforceEntireState(state) {
      Object.assign(state, initialState);
    },
    setSalesforceSyncRunsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.sfSyncRuns.totalPageCount ?? 1)) {
        state.sfSyncRuns.currentPageNo = pageNo;
        state.sfSyncRuns.changingPage = true;
      }
    },
    setSalesforceSyncErrorsPage(
      state,
      { payload }: PayloadAction<{ errorType: string; pageNo: number }>
    ) {
      const { pageNo, errorType } = payload;

      if (
        pageNo &&
        errorType &&
        pageNo <= (state.sfSyncErrors[errorType].totalPageCount ?? 1)
      ) {
        state.sfSyncErrors[errorType].currentPageNo = pageNo;
        state.sfSyncErrors[errorType].changingPage = true;
      }
    },
    setAffectedRecordsPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (
        pageNo &&
        pageNo <= (state.sfSyncErrorAffectedRecords.totalPageCount ?? 1)
      ) {
        state.sfSyncErrorAffectedRecords.currentPageNo = pageNo;
        state.sfSyncErrorAffectedRecords.changingPage = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      //init salesforce connection
      .addCase(initSalesforceConnection.pending, (state) => {
        state.oAuthUrl.loading = LOADING_STATES.LOADING;
      })
      .addCase(initSalesforceConnection.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.oAuthUrl.data = payload.data.oauthUrl;
          state.oAuthUrl.loading = LOADING_STATES.SUCCESS;
        } else {
          state.oAuthUrl.loading = LOADING_STATES.FAILED;
          toast.error("Salesforce connection failed");
        }
      })
      .addCase(initSalesforceConnection.rejected, (state) => {
        state.oAuthUrl.loading = LOADING_STATES.FAILED;
        toast.error("Salesforce connection failed");
      })

      .addCase(reauthenticateSalesforce.pending, (state) => {
        state.oAuthUrl.loading = LOADING_STATES.LOADING;
      })
      .addCase(reauthenticateSalesforce.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.oAuthUrl.data = payload.data.oauthUrl;
          state.oAuthUrl.loading = LOADING_STATES.SUCCESS;
        } else {
          state.oAuthUrl.loading = LOADING_STATES.FAILED;
          toast.error("Salesforce reauthentication failed");
        }
      })
      .addCase(reauthenticateSalesforce.rejected, (state) => {
        state.oAuthUrl.loading = LOADING_STATES.FAILED;
        toast.error("Salesforce reauthentication failed");
      })

      //verify sf connection after login
      .addCase(verifySalesforceConnection.pending, (state) => {
        state.verifyingSfConnection = LOADING_STATES.LOADING;
      })
      .addCase(verifySalesforceConnection.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnection = payload.data;
          state.verifyingSfConnection = LOADING_STATES.SUCCESS;
        } else {
          state.verifyingSfConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(verifySalesforceConnection.rejected, (state) => {
        state.verifyingSfConnection = LOADING_STATES.FAILED;
      })

      // start the first sync
      .addCase(startSalesforceSync.pending, (state) => {
        state.startingSync = LOADING_STATES.LOADING;
      })
      .addCase(startSalesforceSync.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.startingSync = LOADING_STATES.SUCCESS;
          state.sfConnection = payload.data;
        } else {
          state.startingSync = LOADING_STATES.FAILED;
          toast.error("Error while trying to start the sync");
        }
      })
      .addCase(startSalesforceSync.rejected, (state) => {
        state.startingSync = LOADING_STATES.FAILED;
        toast.error("Error while trying to start the sync");
      })

      // salesforce get details
      .addCase(getSalesforceConnectionDetails.pending, (state, { meta }) => {
        state.fetchingSfConnection = LOADING_STATES.LOADING;
        state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
          ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
          loading: LOADING_STATES.LOADING,
        };
      })
      .addCase(
        getSalesforceConnectionDetails.fulfilled,
        (state, { payload, meta }) => {
          if (payload.data) {
            state.fetchingSfConnection = LOADING_STATES.SUCCESS;
            state.sfConnection = payload.data;
            state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
              ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
              loading: LOADING_STATES.SUCCESS,
              sfConnectionDetails: payload.data,
            };
          } else {
            state.fetchingSfConnection = LOADING_STATES.FAILED;
          }
        }
      )
      .addCase(getSalesforceConnectionDetails.rejected, (state, { meta }) => {
        state.fetchingSfConnection = LOADING_STATES.FAILED;
        state.sfUnifiedMappingSyncObjectColumns[meta.arg].loading =
          LOADING_STATES.FAILED;
      })

      //update the connection settings
      .addCase(updateSalesforceConnection.pending, (state) => {
        state.updatingSfConnection = LOADING_STATES.LOADING;
      })
      .addCase(updateSalesforceConnection.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnection = payload.data;
          state.updatingSfConnection = LOADING_STATES.SUCCESS;
          toast.success("Sync settings Updated successfully");
        } else {
          toast.error("Failed to update sync settings");
          state.updatingSfConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(updateSalesforceConnection.rejected, (state) => {
        state.updatingSfConnection = LOADING_STATES.FAILED;
      })

      // Pause sync
      .addCase(pauseSalesforceSync.pending, (state) => {
        state.pausingSfSync = LOADING_STATES.LOADING;
      })
      .addCase(pauseSalesforceSync.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnection = payload.data;
          state.pausingSfSync = LOADING_STATES.SUCCESS;
          toast.success("Sync paused successfully");
        } else {
          state.pausingSfSync = LOADING_STATES.FAILED;
          toast.error("Error while pausing the sync");
        }
      })
      .addCase(pauseSalesforceSync.rejected, (state) => {
        state.pausingSfSync = LOADING_STATES.FAILED;
        toast.error("Error while pausing the sync");
      })

      // Resume sync
      .addCase(resumeSalesforceSync.pending, (state) => {
        state.resumingSfSync = LOADING_STATES.LOADING;
      })
      .addCase(resumeSalesforceSync.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnection = payload.data;
          state.resumingSfSync = LOADING_STATES.SUCCESS;
          toast.success("Sync resumed successfully");
        } else {
          state.resumingSfSync = LOADING_STATES.FAILED;
          toast.error("Error while resuming the sync");
        }
      })
      .addCase(resumeSalesforceSync.rejected, (state) => {
        state.resumingSfSync = LOADING_STATES.FAILED;
        toast.error("Error while resuming the sync");
      })

      // Delete sf connection stop syncing
      .addCase(deleteSalesforceConnection.pending, (state) => {
        state.deletingSfConnection = LOADING_STATES.LOADING;
      })
      .addCase(deleteSalesforceConnection.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.deletingSfConnection = LOADING_STATES.SUCCESS;
          toast.success("Connection deleted successfully");
        } else {
          state.deletingSfConnection = LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteSalesforceConnection.rejected, (state) => {
        state.deletingSfConnection = LOADING_STATES.FAILED;
      })

      //complete salesforce init setup with auto map
      .addCase(completeSalesforceSetup.pending, (state) => {
        state.completingSfSetup = LOADING_STATES.LOADING;
      })
      .addCase(completeSalesforceSetup.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnection = payload.data;
          state.completingSfSetup = LOADING_STATES.SUCCESS;
          toast.success("Setup completed successfully");
        } else {
          state.completingSfSetup = LOADING_STATES.FAILED;
          toast.error("Failed to complete setup");
        }
      })
      .addCase(completeSalesforceSetup.rejected, (state) => {
        state.completingSfSetup = LOADING_STATES.FAILED;
        toast.error("Failed to complete setup");
      })

      // Get campaign sync errors list
      .addCase(getSalesforceCampaignSyncErrors.pending, (state) => {
        state.sfCampaignSyncErrors.loadingList = LOADING_STATES.LOADING;
      })
      .addCase(
        getSalesforceCampaignSyncErrors.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            state.sfCampaignSyncErrors.loadingList = LOADING_STATES.SUCCESS;
            state.sfCampaignSyncErrors.list = payload.data;
            state.sfCampaignSyncErrors.totalPageCount = 1;
            state.sfCampaignSyncErrors.currentPageNo = 1;
            state.sfCampaignSyncErrors.count = payload.data.length;
          } else {
            state.sfCampaignSyncErrors.loadingList = LOADING_STATES.FAILED;
          }
        }
      )
      .addCase(getSalesforceCampaignSyncErrors.rejected, (state) => {
        state.sfCampaignSyncErrors.loadingList = LOADING_STATES.FAILED;
      })

      // Get sync history list
      .addCase(listSalesforceSyncRuns.pending, (state) => {
        state.sfSyncRuns.loadingList = LOADING_STATES.LOADING;
        state.sfSyncRuns.changingPage = true;
      })
      .addCase(listSalesforceSyncRuns.fulfilled, (state, action) => {
        state.sfSyncRuns.loadingList = LOADING_STATES.SUCCESS;
        state.sfSyncRuns.changingPage = false;
        state.sfSyncRuns.list = action.payload.data.records;
        state.sfSyncRuns.totalPageCount = action.payload.pagination.totalPages;
        state.sfSyncRuns.count = action.payload.pagination.totalElements;
      })
      .addCase(listSalesforceSyncRuns.rejected, (state) => {
        state.sfSyncRuns.loadingList = LOADING_STATES.FAILED;
        state.sfSyncRuns.changingPage = false;
      })

      // Get sync errors list for error type
      .addCase(listSalesforceSyncErrors.pending, (state, { meta }) => {
        const errorType = meta.arg.errorType;
        state.sfSyncErrors[errorType] = {
          ...state.sfSyncErrors[errorType],
          loadingList: LOADING_STATES.LOADING,
        };
      })
      .addCase(
        listSalesforceSyncErrors.fulfilled,
        (state, { payload, meta }) => {
          const errorType = meta.arg.errorType;
          state.sfSyncErrors[errorType] = {
            ...state.sfSyncErrors[errorType],
            loadingList: LOADING_STATES.SUCCESS,
            changingPage: false,
            list: payload.data.records,
            totalPageCount: payload.pagination.totalPages,
            count: payload.pagination.totalElements,
          };
        }
      )
      .addCase(listSalesforceSyncErrors.rejected, (state, { meta }) => {
        const errorType = meta.arg.errorType;
        state.sfSyncErrors[errorType] = {
          ...state.sfSyncErrors[errorType],
          loadingList: LOADING_STATES.FAILED,
          changingPage: false,
        };
      })

      // Get affected records for the error class
      .addCase(listSalesforceSyncAffectedRecords.pending, (state) => {
        state.sfSyncErrorAffectedRecords.loadingList = LOADING_STATES.LOADING;
      })
      .addCase(
        listSalesforceSyncAffectedRecords.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            state.sfSyncErrorAffectedRecords.loadingList =
              LOADING_STATES.SUCCESS;
            state.sfSyncErrorAffectedRecords.changingPage = false;
            state.sfSyncErrorAffectedRecords.list = payload.data.records;
            state.sfSyncErrorAffectedRecords.totalPageCount =
              payload.pagination.totalPages;
            state.sfSyncErrorAffectedRecords.count =
              payload.pagination.totalElements;
          } else {
            state.sfSyncErrorAffectedRecords.loadingList =
              LOADING_STATES.FAILED;
            state.sfSyncErrorAffectedRecords.changingPage = false;
          }
        }
      )
      .addCase(listSalesforceSyncAffectedRecords.rejected, (state) => {
        state.sfSyncErrorAffectedRecords.loadingList = LOADING_STATES.FAILED;
        state.sfSyncErrorAffectedRecords.changingPage = false;
      })

      //Sync Runs summary for a time period
      .addCase(getSalesforceSyncRunsSummary.pending, (state) => {
        state.sfSyncRunsSummary.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSalesforceSyncRunsSummary.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfSyncRunsSummary.loading = LOADING_STATES.SUCCESS;
          state.sfSyncRunsSummary.data = payload.data;
        } else {
          state.sfSyncRunsSummary.loading = LOADING_STATES.FAILED;
        }
      })
      .addCase(getSalesforceSyncRunsSummary.rejected, (state) => {
        state.sfSyncRunsSummary.loading = LOADING_STATES.FAILED;
      })

      //resync salesforce sync errors - using error classes
      .addCase(resyncSalesforceSyncErrors.pending, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].resyncingRecords =
          LOADING_STATES.LOADING;
      })
      .addCase(resyncSalesforceSyncErrors.fulfilled, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].resyncingRecords =
          LOADING_STATES.SUCCESS;
      })
      .addCase(resyncSalesforceSyncErrors.rejected, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].resyncingRecords =
          LOADING_STATES.FAILED;
      })

      //archive salesforce sync errors - using error classes
      .addCase(archiveSalesforceSyncErrors.pending, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].archivingRecords =
          LOADING_STATES.LOADING;
      })
      .addCase(archiveSalesforceSyncErrors.fulfilled, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].archivingRecords =
          LOADING_STATES.SUCCESS;
      })
      .addCase(archiveSalesforceSyncErrors.rejected, (state, { meta }) => {
        state.sfSyncErrors[meta.arg.errorType].archivingRecords =
          LOADING_STATES.FAILED;
      })

      //get all salesforce connections
      .addCase(listAllSalesforceConnections.pending, (state) => {
        state.sfConnectionList.loadingList = LOADING_STATES.LOADING;
      })
      .addCase(listAllSalesforceConnections.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.sfConnectionList.loadingList = LOADING_STATES.SUCCESS;
          state.sfConnectionList.list = payload.data.records;
          state.sfConnectionList.count = payload.pagination.totalElements;
        } else {
          state.sfConnectionList.loadingList = LOADING_STATES.FAILED;
        }
      })
      .addCase(listAllSalesforceConnections.rejected, (state) => {
        state.sfConnectionList.loadingList = LOADING_STATES.FAILED;
      })

      //  get selective sync fields returns only boolean fields
      .addCase(listSfSelectiveSyncBooleanColumns.pending, (state) => {
        state.sfSelectiveSyncColumns.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        listSfSelectiveSyncBooleanColumns.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            state.sfSelectiveSyncColumns = {
              data: payload.data,
              loading: LOADING_STATES.SUCCESS,
            };
          } else {
            state.sfSelectiveSyncColumns.loading = LOADING_STATES.FAILED;
          }
        }
      )
      .addCase(listSfSelectiveSyncBooleanColumns.rejected, (state) => {
        state.sfSelectiveSyncColumns.loading = LOADING_STATES.FAILED;
      })

      //list all salesforce sync object columns to map
      .addCase(
        listAllSalesforceSyncObjectColumns.pending,
        (state, { meta }) => {
          state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
            ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
            loading: LOADING_STATES.LOADING,
          };
        }
      )
      .addCase(
        listAllSalesforceSyncObjectColumns.fulfilled,
        (state, { payload, meta }) => {
          if (payload.data) {
            state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
              ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
              loading: LOADING_STATES.SUCCESS,
              syncObjectColumns: payload.data,
            };
          } else {
            state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
              ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
              loading: LOADING_STATES.FAILED,
            };
          }
        }
      )
      .addCase(
        listAllSalesforceSyncObjectColumns.rejected,
        (state, { meta }) => {
          state.sfUnifiedMappingSyncObjectColumns[meta.arg] = {
            ...state.sfUnifiedMappingSyncObjectColumns[meta.arg],
            loading: LOADING_STATES.FAILED,
          };
        }
      )

      //person resync from salesforce
      .addCase(resyncPersonFromSalesforceConnection.pending, (state) => {
        state.resyncingPerson = LOADING_STATES.LOADING;
      })
      .addCase(
        resyncPersonFromSalesforceConnection.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            state.resyncingPerson = LOADING_STATES.SUCCESS;
          } else {
            state.resyncingPerson = LOADING_STATES.FAILED;
          }
        }
      )
      .addCase(resyncPersonFromSalesforceConnection.rejected, (state) => {
        state.resyncingPerson = LOADING_STATES.FAILED;
      })

      //get person sync status from salesforce connection
      .addCase(getPersonSalesforceSyncStatus.pending, (state) => {
        state.personSfSyncDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getPersonSalesforceSyncStatus.fulfilled,
        (state, { payload }) => {
          if (payload.data) {
            state.personSfSyncDetails.loading = LOADING_STATES.SUCCESS;
            state.personSfSyncDetails.data = payload.data;
          } else {
            state.personSfSyncDetails.loading = LOADING_STATES.FAILED;
          }
        }
      )
      .addCase(getPersonSalesforceSyncStatus.rejected, (state) => {
        state.personSfSyncDetails.loading = LOADING_STATES.FAILED;
      })

      //Get sync run next and last schedule timestamp details
      .addCase(getSfSyncRunScheduleDetails.pending, (state) => {
        state.syncRunScheduleDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getSfSyncRunScheduleDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.syncRunScheduleDetails.loading = LOADING_STATES.SUCCESS;
          state.syncRunScheduleDetails.data = payload.data;
        } else {
          state.syncRunScheduleDetails.loading = LOADING_STATES.FAILED;
        }
      })
      .addCase(getSfSyncRunScheduleDetails.rejected, (state) => {
        state.syncRunScheduleDetails.loading = LOADING_STATES.FAILED;
      });
  },
});

export const selectSalesforce = (state: RootState) => state.salesforce;
export const {
  resetSalesforceEntireState,
  setSalesforceSyncRunsPage,
  setAffectedRecordsPage,
  setSalesforceSyncErrorsPage,
} = salesforceSlice.actions;

export default salesforceSlice.reducer;
