import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { cloneDeep, omit } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  formatDataForDisplay,
  isLoading,
  isSuccess,
} from "../../../../common/helper/commonHelper";
import { TableList } from "../../../../common/types/campaign";
import {
  ProductPersonType,
  MAP_TO_DESTINATION_TYPE,
} from "../../../../common/types/person";
import CenteredTextBox from "../../../../components/CenteredTextBox";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import { selectPerson } from "../personDbSlice";
import DisplayGrid from "./DisplayGrid";
import OrgTraitModal from "./OrgTraitModal";
import { createNewProperties, getUnmappedFieldKeys } from "../helper";
import ContentContainer from "../../../../components/v2/ContentContainer";

type ProductPersonData = {
  [key: string]: ProductPersonType[];
};

export default function ProductPerson({
  allTableList,
}: {
  allTableList: TableList;
}) {
  const { productPersonList } = useSelector(selectPerson);
  const [orgId, setOrgId] = useState("");

  const showOrgTraits = useCallback((organisationId: string) => {
    setOrgId(organisationId);
  }, []);

  const createNewPerson = useCallback(
    (filteredPersonKeys: string[], productPerson: any) => {
      let newPerson: ProductPersonType[] = [];
      filteredPersonKeys.sort();
      newPerson = filteredPersonKeys.map((value: string) =>
        formatDataForDisplay(
          value as string,
          productPerson[value as string],
          MAP_TO_DESTINATION_TYPE[
            allTableList["internal_person_organisation_mapping"]?.[value].type
          ]
        )
      );
      return newPerson;
    },
    [allTableList]
  );

  const addOrgId = useCallback(
    (newPerson: ProductPersonType[]) => {
      newPerson.forEach((value) => {
        if (value.key === "org_id") {
          value.onDataClick = showOrgTraits;
        }
      });
    },
    [showOrgTraits]
  );

  const fields: {
    mappedFields: ProductPersonData;
    unMappedFields: ProductPersonData;
  } = useMemo(() => {
    let newFields = { mappedFields: {}, unMappedFields: {} };
    if (isSuccess(productPersonList.loading)) {
      const mappedData: ProductPersonData = {};
      const unmappedData: ProductPersonData = {};

      productPersonList.data.forEach((productPerson) => {
        const filteredObjects = omit(productPerson, [
          "properties",
          "properties_timestamp",
        ]);
        const filteredPersonKeys = Object.keys(filteredObjects);
        const productUserId = productPerson["product_user_id"];
        const orgId = productPerson["org_id"];
        let newPerson: ProductPersonType[] = [];

        newPerson = createNewPerson(
          filteredPersonKeys as string[],
          productPerson
        );
        addOrgId(newPerson);
        mappedData[productUserId + orgId] = newPerson;

        const unMappedFieldKeys = getUnmappedFieldKeys(productPersonList.data);

        const { properties } = productPerson;
        const propertiesClone = cloneDeep(properties ?? {});
        unMappedFieldKeys.forEach((unmapped) => {
          if (!(unmapped in propertiesClone)) {
            propertiesClone[unmapped] = "";
          }
        });

        unmappedData[productUserId + orgId] =
          createNewProperties(propertiesClone);

        newFields.mappedFields = mappedData;
        newFields.unMappedFields = unmappedData;
      });
    }
    return newFields;
  }, [addOrgId, createNewPerson, productPersonList]);

  function displayFields() {
    function queryDetails(details: ProductPersonType[], checkValue: string) {
      let result = "";
      details.forEach((detail) => {
        if (detail.key === checkValue) {
          result = detail.details.value;
        }
      });
      return result;
    }

    return Object.entries(fields.mappedFields).map(
      ([title, details], index) => {
        const organisationId = queryDetails(details, "org_id");
        return (
          <AccordionItem borderColor="white" key={index}>
            <AccordionButton>
              <Box
                display="flex"
                textAlign="left"
                fontWeight="600"
                alignItems="center"
              >
                <AccordionIcon fontWeight="600" fontSize="24px" mr={2} />
                {queryDetails(details, "product_user_id")}
                {organisationId && (
                  <>
                    <Text>(</Text>
                    <Text
                      onClick={() => showOrgTraits(organisationId)}
                      cursor="pointer"
                      _hover={{ color: "blue.500" }}
                      as="u"
                    >
                      {organisationId}
                    </Text>
                    <Text>)</Text>
                  </>
                )}
              </Box>
            </AccordionButton>
            <AccordionPanel px={10}>
              <DisplayGrid entries={details} />
              {fields.unMappedFields[title] &&
              fields.unMappedFields[title].length !== 0 ? (
                <Accordion allowToggle>
                  <AccordionItem borderColor="white">
                    <AccordionButton>
                      <Box>
                        <AccordionIcon
                          fontWeight="400"
                          fontSize="24px"
                          mr={2}
                        />
                        User Properties
                      </Box>
                    </AccordionButton>
                    <AccordionPanel px={10}>
                      <DisplayGrid entries={fields.unMappedFields[title]} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : null}
            </AccordionPanel>
          </AccordionItem>
        );
      }
    );
  }

  return (
    <SpinnerContainer loading={isLoading(productPersonList.loading)} h="500px">
      {productPersonList.data.length ? (
        <ContentContainer flexDir="column">
          <Accordion
            allowMultiple
            defaultIndex={productPersonList.data.length === 1 ? 0 : undefined}
          >
            {displayFields()}
          </Accordion>
          <OrgTraitModal
            isOpen={!!orgId}
            onClose={() => setOrgId("")}
            orgId={orgId}
            allTableList={allTableList}
          />
        </ContentContainer>
      ) : (
        <CenteredTextBox color="gray.400" message="No product user data" />
      )}
    </SpinnerContainer>
  );
}
