import { HStack, Text } from "@chakra-ui/react";
import ComingSoonText from "./ComingSoonText";
export default function TableHeader({
  headerText,
  isComingSoon = false,
}: {
  headerText: string;
  isComingSoon?: boolean;
}) {
  return (
    <HStack>
      <Text>{headerText}</Text>
      {isComingSoon ? <ComingSoonText /> : null}
    </HStack>
  );
}
