import {
  Box,
  HStack,
  Icon,
  IconButton,
  Text,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import IButton, { BUTTON } from "../IButton";
import { cloneDeep } from "lodash";
import { MdCheck, MdClose, MdEdit } from "react-icons/md";
import {
  DateRangePicker,
  Range,
  createStaticRanges,
  StaticRange,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
  MAPPED_CUSTOM_DATE_RANGES,
  SUBFILTER_TYPE,
  TAG_CONNECTOR,
} from "../../common/constants/common";
import { formatISO } from "date-fns";
import { selectAccount } from "../../pages/account/accountSlice";
import { useSelector } from "react-redux";
import UserNameWithAvatar from "../UserNameWithAvatar";
import ISkeleton, { SKELETON_VARIANT } from "../ISkeleton";
import {
  ListDateFilterType,
  ListDlFilterType,
  ListFilterType,
  ListFilterValueType,
  ListTagFilterType,
  MainFilter,
} from "../../common/types/common";
import { selectTag } from "../../pages/dashboard/tag/tagSlice";
import {
  getCustomDateRangeLabel,
  isLoading,
  addOrRemoveArrayValue,
  getPersonLabel,
  searchInValue,
} from "../../common/helper/commonHelper";
import { formatDateString } from "../DateTimeRangeFilter";
import SelectionRow from "./SelectionRow";
import { CAMPAIGN_CONTEXT, DynamicListType } from "../../common/types/campaign";
import DynamicList from "../dynamic-list/DynamicList";
import CommonDynamicListDrawer from "../dynamic-list/CommonDynamicListDrawer";
import { FaPlus } from "react-icons/fa";

export default function FilterSelection({
  isReadOnly,
  filter,
  updateSubFilter,
  setSubFilter,
  selectedFilters,
  clearSelectedFilter,
  openFilterOnEditMode,
  searchText = "",
}: {
  isReadOnly: boolean;
  filter: MainFilter;
  updateSubFilter?: (filter: string, subFilter: ListFilterValueType) => void;
  setSubFilter?: (
    filter: string,
    subFilter: ListDateFilterType | ListTagFilterType | ListDlFilterType
  ) => void;
  clearSelectedFilter: (filter: string) => void;
  selectedFilters?: ListFilterType;
  openFilterOnEditMode?: () => void;
  searchText?: string;
}) {
  function selectSubListFilters(subFilter: ListFilterValueType) {
    updateSubFilter?.(filter.value, subFilter);
  }

  function selectNonListSubFilter(
    subFilter: ListTagFilterType | ListDateFilterType | ListDlFilterType
  ) {
    setSubFilter?.(filter.value, subFilter);
  }

  function clearFilter() {
    clearSelectedFilter(filter.value);
  }

  const filterProps = {
    isReadOnly,
    filter,
    openFilterOnEditMode,
    setDateFilter: selectNonListSubFilter,
    clearAll: clearFilter,
    searchText,
  };

  switch (filter.subFilterType) {
    case SUBFILTER_TYPE.LIST:
      return (
        <FilterSelectionList
          {...filterProps}
          selectedFilters={selectedFilters as ListFilterValueType[]}
          selectFilter={selectSubListFilters}
        />
      );
    case SUBFILTER_TYPE.USER:
      return (
        <FilterSelectionUserList
          {...filterProps}
          selectFilter={selectSubListFilters}
          selectedFilters={selectedFilters as ListFilterValueType[]}
        />
      );
    case SUBFILTER_TYPE.TAGS:
      return (
        <FilterSelectionTags
          {...filterProps}
          setSubFilter={selectNonListSubFilter}
          selectedFilters={selectedFilters as ListTagFilterType}
        />
      );
    case SUBFILTER_TYPE.DATE:
      return (
        <FilterSelectionDate
          {...filterProps}
          selectedFilters={selectedFilters as ListDateFilterType}
        />
      );
    case SUBFILTER_TYPE.DL:
      return (
        <FilterSelectionDl
          {...filterProps}
          selectedFilters={selectedFilters as ListDlFilterType}
          setDlFilter={selectNonListSubFilter}
        />
      );
  }
}

function FilterSelectionList({
  isReadOnly,
  isTruncatable,
  filter,
  selectFilter,
  selectedFilters,
  clearAll,
  isListLoading,
  openFilterOnEditMode,
  searchText = "",
}: {
  isReadOnly: boolean;
  isTruncatable?: boolean;
  filter: MainFilter;
  selectFilter: (subFilter: ListFilterValueType) => void;
  clearAll: () => void;
  selectedFilters?: ListFilterValueType[];
  isListLoading?: boolean;
  openFilterOnEditMode?: () => void;
  searchText?: string;
}) {
  if (isReadOnly) {
    const selectedSubFilters =
      filter.subFilters
        ?.filter((subFilter) => selectedFilters?.includes(subFilter.value))
        .map((subFilter) => subFilter.displayText) ?? [];
    return (
      <FiltersDisplayItem
        mainFilterName={filter.display}
        subFilterDisplays={selectedSubFilters}
        clearFilter={clearAll}
        openFilterOnEditMode={openFilterOnEditMode}
        isTruncatable={isTruncatable}
      />
    );
  } else {
    return (
      <VStack w="100%" maxH="calc(100vh - 175px)" overflow="auto" pt={1}>
        <ISkeleton
          variant={SKELETON_VARIANT.LIST}
          isLoaded={!isListLoading}
          rowCount={8}
          w="100%"
          height="30px"
        >
          {filter.subFilters
            ?.filter((details) =>
              searchInValue(details.displayText, searchText)
            )
            .map(({ displayText, displayCell, icon, value }) => {
              const isSelected = !!selectedFilters?.includes(value);
              return (
                <SelectionRow
                  isSelected={isSelected}
                  onClick={() => selectFilter(value)}
                  icon={icon}
                  displayText={displayText}
                  displayCell={displayCell}
                >
                  {isSelected && <MdCheck />}
                </SelectionRow>
              );
            })}
        </ISkeleton>
      </VStack>
    );
  }
}

function FilterSelectionDate({
  isReadOnly,
  filter,
  setDateFilter,
  selectedFilters,
  clearAll,
  openFilterOnEditMode,
}: {
  isReadOnly: boolean;
  filter: MainFilter;
  setDateFilter: (subFilter: ListDateFilterType) => void;
  clearAll: () => void;
  selectedFilters?: ListDateFilterType;
  openFilterOnEditMode?: () => void;
}) {
  const INPUT_RANGE = useMemo(() => {
    return {
      startDate: selectedFilters?.start_date
        ? new Date(selectedFilters?.start_date)
        : getStartDate(CUSTOM_DATE_RANGE_LABELS.ALL_TIME),
      endDate: new Date(selectedFilters?.end_date ?? Date.now()),
      key: "selection",
    };
  }, [selectedFilters]);

  const [dateRange, setDateRange] = useState<Range>(INPUT_RANGE);
  const theme = useTheme();
  const defaultCustomRanges: StaticRange[] = createStaticRanges(
    MAPPED_CUSTOM_DATE_RANGES
  );

  useEffect(() => {
    setDateRange({
      startDate: selectedFilters?.start_date
        ? new Date(selectedFilters?.start_date)
        : getStartDate(CUSTOM_DATE_RANGE_LABELS.ALL_TIME),
      endDate: new Date(selectedFilters?.end_date ?? Date.now()),
      key: "selection",
    });
  }, [selectedFilters]);

  function onClear() {
    setDateRange({
      startDate: getStartDate(CUSTOM_DATE_RANGE_LABELS.ALL_TIME),
      endDate: new Date(),
      key: "selection",
    });
    clearAll();
  }

  function setDateFilterRange(dates: Range) {
    if (dates.endDate instanceof Date && dates.startDate instanceof Date) {
      // Clone the date objects to avoid mutating the originals
      const endDate = new Date(dates.endDate);
      const startDate = new Date(dates.startDate);

      // Set time to the end of the day (23:59:59) for endDate
      endDate.setHours(23, 59, 59, 999);
      // Set time to the start of the day (00:00:00) for startDate
      startDate.setHours(0, 0, 0, 0);

      setDateFilter({
        start_date: formatISO(startDate),
        end_date: formatISO(endDate),
      });
    }
  }

  if (isReadOnly) {
    const startDate = formatDateString({
      date: formatISO(dateRange.startDate!),
    });
    const endDate = formatDateString({ date: formatISO(dateRange.endDate!) });
    const displayLabel =
      getCustomDateRangeLabel(dateRange.startDate!, dateRange.endDate!) ||
      `${startDate} - ${endDate}`;
    return (
      <FiltersDisplayItem
        mainFilterName={filter.display}
        subFilterDisplays={[displayLabel]}
        clearFilter={clearAll}
        openFilterOnEditMode={openFilterOnEditMode}
      />
    );
  } else {
    return (
      <Box
        bg="white"
        top="35px"
        height="365px"
        border="1px solid lightgray"
        borderRadius="3px"
        boxShadow="inset 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.2)"
      >
        <DateRangePicker
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          retainEndDateOnFirstSelection
          editableDateInputs
          ranges={[dateRange]}
          fixedHeight={true}
          onChange={(range) => {
            setDateFilterRange(range.selection);
          }}
          inputRanges={[]}
          staticRanges={defaultCustomRanges}
          rangeColors={[theme.__cssVars["--chakra-colors-blue-700"]]}
        />

        <HStack position="relative" bottom="-35%" float="right" mr={2}>
          <IButton variant={BUTTON.SECONDARY} onClick={onClear} mr={1}>
            Clear
          </IButton>
        </HStack>
      </Box>
    );
  }
}

function FilterSelectionUserList({
  isReadOnly,
  filter,
  selectFilter,
  selectedFilters,
  clearAll,
  openFilterOnEditMode,
  searchText = "",
}: {
  isReadOnly: boolean;
  filter: MainFilter;
  selectFilter: (subFilter: ListFilterValueType) => void;
  clearAll: () => void;
  selectedFilters?: ListFilterValueType[];
  openFilterOnEditMode?: () => void;
  searchText?: string;
}) {
  const { usersList, fetchingUsersList } = useSelector(selectAccount);

  const userListFilter: MainFilter = useMemo(() => {
    return {
      ...filter,
      subFilters: Object.values(usersList)
        .filter((user) => searchInValue(user.name, searchText))
        .map(({ name, id, status }) => {
          return {
            displayCell: (
              <UserNameWithAvatar username={name} accountStatus={status} />
            ),
            displayText: name,
            value: id,
          };
        }),
    };
  }, [usersList, filter, searchText]);

  return (
    <FilterSelectionList
      isReadOnly={isReadOnly}
      filter={userListFilter}
      selectFilter={selectFilter}
      selectedFilters={selectedFilters}
      clearAll={clearAll}
      isListLoading={fetchingUsersList}
      openFilterOnEditMode={openFilterOnEditMode}
      isTruncatable
    />
  );
}

function FilterSelectionTags({
  isReadOnly,
  filter,
  setSubFilter,
  selectedFilters,
  clearAll,
  openFilterOnEditMode,
  searchText = "",
}: {
  isReadOnly: boolean;
  filter: MainFilter;
  setSubFilter: (subFilter: ListTagFilterType) => void;
  clearAll: () => void;
  selectedFilters?: ListTagFilterType;
  openFilterOnEditMode?: () => void;
  searchText?: string;
}) {
  const { tagSummaryList } = useSelector(selectTag);

  function handleTagSelect(tagId: string) {
    let subFilter = cloneDeep(selectedFilters);
    const updatedTagList = addOrRemoveArrayValue(
      subFilter?.filter_list,
      tagId
    ) as string[];
    subFilter = {
      connector: TAG_CONNECTOR.OR,
      filter_list: updatedTagList,
    };
    if (subFilter.filter_list.length) {
      setSubFilter(subFilter);
    } else {
      clearAll();
    }
  }

  const tagsList = useMemo(() => {
    return Object.entries(tagSummaryList.data)
      .filter(([_, tagName]) => searchInValue(tagName, searchText))
      .map(([tagId, tagName]) => {
        return {
          tagId: tagId,
          name: tagName ?? "",
          selected: !!selectedFilters?.filter_list?.includes(tagId),
        };
      });
  }, [tagSummaryList.data, selectedFilters, searchText]);

  if (isReadOnly) {
    const selectedSubFilters =
      selectedFilters?.filter_list.map(
        (subFilter) => `#${tagSummaryList.data[subFilter]}`
      ) ?? [];
    return (
      <FiltersDisplayItem
        mainFilterName={filter.display}
        subFilterDisplays={selectedSubFilters}
        clearFilter={clearAll}
        openFilterOnEditMode={openFilterOnEditMode}
        isTruncatable
      />
    );
  } else {
    return (
      <VStack w="100%" maxH="calc(100vh - 175px)" overflow="auto" pt={1}>
        <ISkeleton
          variant={SKELETON_VARIANT.LIST}
          isLoaded={!isLoading(tagSummaryList.loading)}
          rowCount={8}
          w="100%"
          height="30px"
        >
          {tagsList.map(({ tagId, name, selected }) => {
            return (
              <SelectionRow
                isSelected={selected}
                onClick={() => handleTagSelect(tagId)}
                displayText={`#${name}`}
              >
                {selected && <MdCheck />}
              </SelectionRow>
            );
          })}
        </ISkeleton>
      </VStack>
    );
  }
}

function FilterSelectionDl({
  isReadOnly,
  filter,
  setDlFilter,
  selectedFilters,
  clearAll,
  openFilterOnEditMode,
}: {
  isReadOnly: boolean;
  filter: MainFilter;
  setDlFilter: (subFilter: ListDlFilterType) => void;
  clearAll: () => void;
  selectedFilters?: ListDlFilterType;
  openFilterOnEditMode?: () => void;
}) {
  const [dynamicList, setDynamicList] = useState<DynamicListType[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setDynamicList(selectedFilters?.conditions ?? []);
  }, [selectedFilters]);

  function onClear() {
    setDynamicList([]);
    clearAll();
  }

  if (isReadOnly) {
    return (
      <FiltersDisplayItem
        mainFilterName={filter.display}
        subFilterDisplays={["Applied"]}
        clearFilter={clearAll}
        openFilterOnEditMode={openFilterOnEditMode}
      />
    );
  } else {
    return (
      <Box pt={2} w="100%">
        <HStack justifyContent="space-between">
          <Text fontSize="13px" color="brandBlue.500">
            All {getPersonLabel(CAMPAIGN_CONTEXT.PERSON)}s who have,
          </Text>
          <HStack spacing={0} hidden={!dynamicList.length}>
            <IconButton
              variant="ghost"
              onClick={onOpen}
              fontSize="15px"
              color="brandBlue.400"
              icon={<MdEdit />}
              aria-label="edit-filter"
              title="Edit"
            />
            <IconButton
              variant="ghost"
              onClick={onClear}
              fontSize="16px"
              color="brandBlue.400"
              icon={<MdClose />}
              aria-label="clear-filter"
              title="Clear"
            />
          </HStack>
        </HStack>
        {dynamicList.length ? (
          <DynamicList
            dynamicListData={dynamicList}
            onChange={() => {}}
            sidebar={false}
            activeErrorCheck={false}
            readOnlyMode
            campaignContext={CAMPAIGN_CONTEXT.PERSON}
          />
        ) : (
          <Box fontSize="14px" mt={2}>
            <IButton
              variant={BUTTON.SECONDARY}
              onClick={onOpen}
              color="brand.blue"
              leftIcon={<FaPlus fontSize="12px" />}
            >
              Add condition
            </IButton>
          </Box>
        )}
        <CommonDynamicListDrawer
          isOpen={isOpen}
          onClose={onClose}
          dynamicListData={dynamicList}
          title="Advanced filter"
          submitButtonProps={{
            label: "Add filter",
            onSubmit: (dynamicList: DynamicListType[]) => {
              onClose();
              setDynamicList(dynamicList);
              setDlFilter({ conditions: dynamicList });
            },
            props: {
              isLoading: false,
            },
          }}
          campaignContext={CAMPAIGN_CONTEXT.PERSON}
        />
      </Box>
    );
  }
}

export function FilterDisplayItemContainer({
  onClick,
  children,
  title,
  isTruncatable,
}: {
  onClick?: () => void;
  children: ReactNode;
  title: string;
  isTruncatable?: boolean;
}) {
  return (
    <HStack
      justifyContent="space-between"
      border="1px solid"
      borderColor="brandBlue.500"
      borderRadius="md"
      color="brandBlue.500"
      cursor="pointer"
      maxWidth="fit-content"
      fontSize="14px"
      px={3}
      py={1}
      bgColor="brandBlue.100"
      _hover={{
        bgColor: "grayV2.200",
      }}
      onClick={onClick}
      minWidth={isTruncatable ? "150px" : "fit-content"}
      flexShrink={isTruncatable ? "1" : "0"}
      spacing="1"
      isTruncated
      title={title}
    >
      {children}
    </HStack>
  );
}

export function FiltersDisplayItem({
  mainFilterName,
  subFilterDisplays,
  clearFilter,
  openFilterOnEditMode,
  isTruncatable,
}: {
  mainFilterName: string;
  subFilterDisplays: string[];
  clearFilter: () => void;
  openFilterOnEditMode?: () => void;
  isTruncatable?: boolean;
}) {
  function onClear(e: MouseEvent) {
    e.stopPropagation();
    clearFilter();
  }

  return (
    <FilterDisplayItemContainer
      onClick={openFilterOnEditMode}
      title={`${mainFilterName}: ${subFilterDisplays.join(", ")}`}
      isTruncatable={isTruncatable}
    >
      <HStack w="calc(100% - 18px)" spacing={1}>
        <Text fontWeight="600">{mainFilterName}:</Text>
        <Text isTruncated>{subFilterDisplays.join(", ")}</Text>
      </HStack>
      <Icon
        w="18px"
        fontSize="14px"
        as={MdClose}
        onClick={onClear}
        _hover={{
          color: "brandBlue.800",
          fontSize: "16px",
        }}
      />
    </FilterDisplayItemContainer>
  );
}
