import { AxiosRequestConfig } from "axios";
import { CORE_INTEGRATIONS_BASE_URL } from "../../constants";
import { RequestParams } from "../../types/common";
import { inflectionRequest } from "../api";

const baseUrls = {
  1: CORE_INTEGRATIONS_BASE_URL,
};

export async function coreIntegrationsRequest<T>(
  data: RequestParams<typeof baseUrls> & AxiosRequestConfig
): Promise<T> {
  return await inflectionRequest({
    ...data,
    baseUrl: baseUrls[data.version || 1],
  });
}
