import { cssVar, defineStyleConfig } from "@chakra-ui/react";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const skeletonStyle = defineStyleConfig({
  variants: {
    custom: {
      _light: {
        [$startColor.variable]: "colors.grayV2.100",
        [$endColor.variable]: "colors.grayV2.300",
      },
    },
  },
  defaultProps: {
    variant: "custom",
  },
});

export default skeletonStyle;
