import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { ResumeSyncButton } from "./salesforceSyncRun/SyncStatusEditButtons";

export default function SfSyncStatusResumeBanner({
  resumeSync,
  isLoading,
}: {
  resumeSync: () => void;
  isLoading: boolean;
}) {
  return (
    <Box
      border="1px"
      borderColor="gray.100"
      bg="blue.50"
      px={2}
      py={3}
      mt={2}
      borderRadius="xl"
    >
      <HStack justify="space-between">
        <HStack fontSize="sm" align="center" color="gray.800">
          <Icon color="gray.900" as={FaExclamationTriangle} />
          <Text fontWeight="semibold">Salesforce connection is paused.</Text>
          <Text>
            The connection between Inflection and Salesforce is currently
            paused. No data is being exchanged.
          </Text>
        </HStack>
        <ResumeSyncButton
          isLoading={isLoading}
          onClick={resumeSync}
          _hover={{ bg: "brand.blue", color: "white" }}
          colorScheme="blue"
          variant="ghost"
        />
      </HStack>
    </Box>
  );
}
