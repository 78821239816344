import { Text, Box, Badge } from "@chakra-ui/react";
import { Flex, VStack } from "@chakra-ui/layout";
import {
  NodeCriteriaTypes,
  NodeGroup,
} from "../../../../../common/types/dynamicList";
import {
  EVENT_CATEGORY,
  LOGICAL_OPERATORS,
} from "../../../../../common/constants/dynamicList";
import {
  createGroupId,
  getPersonLabel,
} from "../../../../../common/helper/commonHelper";
import { CAMPAIGN_CONTEXT } from "../../../../../common/types/campaign";
import React from "react";

function onNavigation(id: string) {
  let myElement = document.getElementById(id);
  if (myElement) {
    myElement.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
    myElement.style.animationName = "shake";
    myElement.style.animationDuration = "0.7s";
    myElement.style.animationIterationCount = "1";
    myElement.style.animationDelay = "0.5s";
    setTimeout(() => {
      let myElement = document.getElementById(id);
      if (myElement) {
        myElement.style.animationName = "";
      }
    }, 3000);
  }
}

function LogicGateButton({
  logicalOperator,
}: {
  logicalOperator: LOGICAL_OPERATORS | null;
}) {
  const isAnd = logicalOperator && logicalOperator === LOGICAL_OPERATORS.AND;
  return (
    <>
      {logicalOperator && logicalOperator !== LOGICAL_OPERATORS.FIRST && (
        <Box
          width="40px"
          textAlign="center"
          bg="gray.100"
          color={isAnd ? "brand.blue" : "green"}
          borderRadius="4px"
          px="1"
          boxShadow="sm"
          mb={2}
        >
          <Text fontWeight="bold" fontSize="12px">
            {isAnd ? "AND" : "OR"}
          </Text>
        </Box>
      )}
    </>
  );
}

function SingleConditionGroup({ label, id }: { label: string; id: string }) {
  return (
    <Box px="2" w="100%">
      <Badge
        fontSize="10px"
        bgColor="black"
        color="white"
        cursor="pointer"
        onClick={() => onNavigation(id)}
        maxW="100%"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {label}
      </Badge>
    </Box>
  );
}

function SingleNode({
  label,
  id,
  nodeData,
}: {
  label: string;
  id: string;
  nodeData: NodeCriteriaTypes;
}) {
  return (
    <Box
      rounded="lg"
      borderColor="gray.200"
      borderWidth="1px"
      w="100%"
      shadow="sm"
    >
      <Flex
        roundedTop="lg"
        alignItems="center"
        p="2"
        w="100%"
        bg="gray.100"
        borderBottomWidth="1px"
        borderColor="gray.200"
      >
        <Badge
          fontSize="10px"
          colorScheme="teal"
          cursor="pointer"
          onClick={() => onNavigation(id)}
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="solid"
        >
          {label}
        </Badge>
      </Flex>

      <VStack width="100%" alignItems="flex-start">
        {"conditionGroups" in nodeData &&
          nodeData.conditionGroups.map((node, index) => {
            return (
              <>
                {nodeData.eventCategory === EVENT_CATEGORY.PRODUCT_ACTIVITY && (
                  <Box key={index} w="100%">
                    <Box px="2">
                      <LogicGateButton logicalOperator={node.logicalOperator} />
                    </Box>

                    <SingleConditionGroup
                      id={createGroupId(id, "property", index + 1)}
                      label={`Property group 
                    ${index + 1}`}
                    />
                  </Box>
                )}
              </>
            );
          })}
      </VStack>
    </Box>
  );
}

function SingleNodeGroup({
  label,
  id,
  nodeGroup,
}: {
  label: string;
  id: string;
  nodeGroup: NodeGroup;
}) {
  return (
    <Box
      p="2"
      rounded="lg"
      bg="grayV2.100"
      borderWidth="1px"
      width="100%"
      alignItems="flex-start"
    >
      <Flex
        mb="3"
        roundedTop="lg"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        bg="grayV2.100"
      >
        <Badge
          fontSize="10px"
          colorScheme="blue"
          onClick={() => onNavigation(id)}
          cursor="pointer"
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="solid"
        >
          {label}
        </Badge>
      </Flex>
      <VStack alignItems="flex-start">
        {nodeGroup.nodes.map((nodeData, index) => {
          return (
            <Box key={index} w="100%">
              <LogicGateButton logicalOperator={nodeData.logicalOperator} />
              <SingleNode
                id={createGroupId(id, "filter", index + 1)}
                label={`Filter group ${index + 1}`}
                nodeData={nodeData}
              />
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}

const AudienceCriteriaSideBar = React.memo(
  ({
    nodeGroups,
    campaignContext,
  }: {
    nodeGroups: NodeGroup[];
    campaignContext: CAMPAIGN_CONTEXT;
  }) => {
    return (
      <VStack width="220px" alignItems="flex-start">
        <Text
          fontSize="sm"
          mr="3"
          mb="5"
          color="blue.900"
          fontWeight="semibold"
        >
          All {getPersonLabel(campaignContext)}s who have,
        </Text>
        <VStack
          height="calc(100vh - 230px)"
          overflowY="auto"
          alignItems="flex-start"
          width="100%"
          pr="2"
        >
          {!!nodeGroups.length && (
            <>
              {nodeGroups.map((nodeGroup, index) => {
                return (
                  <Box key={index} w="100%">
                    <LogicGateButton
                      logicalOperator={nodeGroup.logicalOperator}
                    />

                    <SingleNodeGroup
                      nodeGroup={nodeGroup}
                      label={`Outer group ${index + 1}`}
                      id={createGroupId("", "outer", index + 1)}
                    />
                  </Box>
                );
              })}
            </>
          )}
        </VStack>
      </VStack>
    );
  }
);

export default AudienceCriteriaSideBar;
