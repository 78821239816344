import { CAMPAIGN_CONTEXT, EmailDraftParams } from "../../types/campaign";
import { PersonLookup } from "../../types/person";
import { PaginatedList, SearchAssetsRequestType } from "../../types/common";
import {
  BeeAuthType,
  SavedRow,
  SavedRowBasic,
  SavedRowResp,
  SenderMeta,
  TemplateAdditionalProperties,
  TemplateBase,
  TemplateExhaustive,
  TEMPLATE_EDITOR_MODE,
  VisualTemplateData,
  TemplateCcBccRequest,
  GetTemplateResp,
} from "../../types/template";
import { campaignRequest as request } from "./api";
import { PREVIEW_MODES } from "../../constants/template";
import { SortAssetsRequestsType } from "../../../components/SortFilter";

export async function getTemplateApi({
  template_id,
  view,
}: {
  template_id: string;
  view?: PREVIEW_MODES;
}): Promise<{
  template: GetTemplateResp;
}> {
  return await request({
    url: "/assets/templates/get",
    data: { template_id, view },
    shouldRetryApiCall: true,
  });
}

export async function deleteTemplateApi(template_id: string) {
  return await request({
    url: "/assets/templates/delete",
    data: { template_id },
  });
}

export async function listTemplatesApi({
  pageSize,
  pageNo,
  view,
  searchKeyword,
  columnsToSearchIn,
  sortByOption,
  sortOrder,
  filters,
}: SearchAssetsRequestType &
  SortAssetsRequestsType & {
    view?: PREVIEW_MODES;
  }): Promise<PaginatedList<TemplateExhaustive>> {
  return await request({
    url: "/assets/templates/list",
    data: {
      page_number: pageNo,
      page_size: pageSize,
      query: { search: { keyword: searchKeyword, fields: columnsToSearchIn } },
      sort_by: {
        column: sortByOption,
        order: sortOrder,
      },
      view,
      filters,
    },
    shouldRetryApiCall: true,
  });
}

export async function updateTemplateNameApi(
  templateId: string,
  name: string
): Promise<{ template: boolean }> {
  return await request({
    url: "/assets/templates/name.update",
    data: { template_id: templateId, name },
  });
}

export async function updateTemplateApi(
  data: TemplateBase &
    TemplateAdditionalProperties &
    SenderMeta &
    TemplateCcBccRequest
): Promise<{
  template: TemplateExhaustive;
}> {
  return await request({
    url: "/assets/templates/update",
    data,
  });
}

export async function createTemplateApi(
  name: string,
  templateType: TEMPLATE_EDITOR_MODE
): Promise<{
  template: TemplateExhaustive;
}> {
  return await request({
    url: "/assets/templates/create",
    data: { name, template_type: templateType },
  });
}

export async function sendPreviewEmailApi(
  data: EmailDraftParams,
  campaignContext: CAMPAIGN_CONTEXT,
  personIds: Omit<PersonLookup, "email">[]
): Promise<{ template: boolean }> {
  return await request({
    url: "/assets/templates/send.email",
    data: { ...data, person_ids: personIds, campaign_context: campaignContext },
  });
}

export async function listVisualTemplatesApi(): Promise<{
  response: VisualTemplateData[];
}> {
  return await request({
    method: "POST",
    url: "/assets/templates/visual.list",
    data: {},
  });
}

export async function getVisualTemplateJsonApi(
  templateName: string
): Promise<{ name: string; json_content: string }> {
  return await request({
    method: "POST",
    url: "/assets/templates/template.json",
    data: { name: templateName },
  });
}

export async function loginBeeApi(): Promise<BeeAuthType> {
  return await request({
    method: "POST",
    url: "/assets/templates/bee.auth",
    data: {},
    shouldRetryApiCall: true,
  });
}

export async function cloneTemplateApi(
  templateId: string
): Promise<{ template: TemplateExhaustive }> {
  return await request({
    method: "POST",
    url: "/assets/templates/template.clone",
    data: { template_id: templateId },
  });
}

export async function saveCustomRowApi(
  data: SavedRowBasic
): Promise<{ bee_row: SavedRowResp }> {
  return await request({
    url: "/assets/templates/bee.row.save",
    data,
  });
}

export async function updateCustomRowApi(
  data: SavedRow
): Promise<{ bee_row: SavedRowResp }> {
  return await request({
    url: "/assets/templates/bee.row.update",
    data,
  });
}

export async function listCustomRowsApi(): Promise<{ records: SavedRow[] }> {
  return await request({
    url: "/assets/templates/bee.row.list",
  });
}

export async function deleteCustomRowApi(
  beeRowId: string
): Promise<{ bee_row_delete: boolean }> {
  return await request({
    url: "/assets/templates/bee.row.delete",
    data: { bee_row_id: beeRowId },
  });
}
