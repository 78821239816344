import CreatableSelect, { CreatableProps } from "react-select/creatable";
import { GroupBase } from "react-select";
import { Text } from "@chakra-ui/react";
import { useChakraColors } from "../common/hooks/commonHooks";

export default function CreatableTextInput<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  invalidMsg,
  components,
  ...props
}: {
  invalidMsg?: string;
} & CreatableProps<Option, IsMulti, Group>) {
  const { gray100, gray200, gray300, gray400, gray800, red500 } =
    useChakraColors();

  return (
    <>
      <CreatableSelect
        components={{
          DropdownIndicator: null,
          ...components,
        }}
        styles={{
          control: (base: any, state: any) => ({
            ...base,
            borderColor: invalidMsg ? red500 : gray200,
            boxShadow: invalidMsg ? `0 0 0 1px ${red500}` : "",
            fontSize: "13px",
            cursor: "text",
            opacity: 1,
            backgroundColor: "white",
            borderRadius: "6px",
            fontColor: gray800,
          }),
          option: (base: any, state: any) => ({
            ...base,
            fontSize: "13px",
            color: state.isDisabled ? gray400 : gray800,
            backgroundColor: state.isSelected
              ? gray300
              : state.isFocused
              ? gray100
              : "white",
            cursor: "pointer",
            "&:hover": {
              backgroundColor:
                state.isSelected || state.isDisabled ? undefined : gray100,
            },
          }),
          menu: (base: any) => ({
            ...base,
            minWidth: "max-content",
          }),
          menuList: (base: any) => ({
            ...base,
            "::-webkit-scrollbar": {
              width: "6px",
            },
            "::-webkit-scrollbar-track": {
              background: "white",
            },
            "::-webkit-scrollbar-thumb": {
              background: gray200,
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: gray300,
            },
          }),
        }}
        isSearchable
        {...props}
      />
      {invalidMsg && (
        <Text mt={1} fontSize="12px" color="red.500">
          {invalidMsg}
        </Text>
      )}
    </>
  );
}
