import { Center, CenterProps, Spinner } from "@chakra-ui/react";
import { ReactNode } from "react";

export default function SpinnerContainer({
  loading,
  height = "100px",
  width = "100%",
  children,
  ...props
}: {
  loading: boolean;
  height?: string | number;
  width?: string | number;
  children: ReactNode;
} & CenterProps) {
  return (
    <>
      {loading ? (
        <Center height={height} width={width} {...props}>
          <Spinner />
        </Center>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
