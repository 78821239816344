import {
  Grid,
  HStack,
  Checkbox,
  Text,
  TextProps,
  CheckboxProps,
  Skeleton,
  Box,
} from "@chakra-ui/react";
import IconWithTooltip from "../../../../components/IconWithTooltip";
import {
  COLUMN_FILTERS,
  MappedFieldsWithDestId,
  DestIdType,
} from "../../../../common/types/contactUpload";
import { IconOptionsType } from "../../../../common/types/flow";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";
import {
  CustomIconOption,
  CustomIconSingleValue,
} from "../../campaign/flow/components/OptionHandler";
import { isSalesforcePreference } from "../../../../common/helper/unifiedMappingHelper";

const COLUMN_FILTER_OPTIONS = Object.values(COLUMN_FILTERS).map((filter) => {
  return {
    value: filter,
  };
});

function InfoToolTip({ tooltip }: { tooltip?: string }) {
  return (
    <>
      {tooltip && (
        <IconWithTooltip
          label={tooltip}
          fontSize="xs"
          color="gray.400"
          tooltipProps={{
            placement: "right",
            maxW: "400px",
            fontSize: "12px",
            py: 1,
          }}
        />
      )}
    </>
  );
}
export function StatsRow({
  label,
  data,
  loading,
  tooltip,
  ...props
}: {
  label: string;
  data: number;
  loading?: boolean;
  tooltip?: string;
} & TextProps) {
  return (
    <Grid fontSize="sm" templateColumns="1fr 2fr" w="100%">
      <HStack>
        <Text>{label}</Text>
        <InfoToolTip tooltip={tooltip} />
      </HStack>
      <Skeleton w="100px" h="20px" isLoaded={!loading}>
        <Text fontWeight={600} {...props}>
          {data}
        </Text>
      </Skeleton>
    </Grid>
  );
}

export function CheckboxWithLabel({
  label,
  tooltip,
  ...props
}: {
  label: string;
  tooltip?: string;
} & CheckboxProps) {
  return (
    <HStack>
      <Checkbox {...props}>
        <Text fontSize="14px" color="gray.600">
          {label}
        </Text>
      </Checkbox>
      <InfoToolTip tooltip={tooltip} />
    </HStack>
  );
}

export function CsvColumnFilter({
  value,
  onFilterChange,
}: {
  value: COLUMN_FILTERS;
  onFilterChange: (value: COLUMN_FILTERS) => void;
}) {
  return (
    <DropdownWithSearch
      options={COLUMN_FILTER_OPTIONS}
      onChange={(option) =>
        onFilterChange(option?.value ?? COLUMN_FILTERS.ALL_FIELDS)
      }
      value={COLUMN_FILTER_OPTIONS.find((filter) => value === filter.value)}
      getOptionLabel={(option) => option.value}
      controlStyle={{
        width: "120px",
      }}
    />
  );
}

export function DestinationField({
  personDestColumns,
  fieldData: { csvColumn, destId },
  onDestinationChange,
  isDestFieldsLoading,
  mappedFields,
  isDisabled,
}: {
  personDestColumns: IconOptionsType[];
  fieldData: MappedFieldsWithDestId;
  onDestinationChange: (csvColumn: string, destId: DestIdType | null) => void;
  isDestFieldsLoading: boolean;
  mappedFields: MappedFieldsWithDestId[];
  isDisabled?: boolean;
}) {
  return (
    <Box pl={2} w="270px">
      <DropdownWithSearch
        options={personDestColumns}
        value={
          destId
            ? personDestColumns.find(({ value }) => value === destId)
            : null
        }
        onChange={(option) =>
          onDestinationChange(csvColumn, option?.value ?? null)
        }
        isLoading={isDestFieldsLoading}
        isDisabled={isDisabled}
        isOptionDisabled={(option) =>
          mappedFields.some(({ destId }) => option.value === destId) ||
          isSalesforcePreference(option.salesforcePreference)
        }
        isClearable
        isSearchable
        placeholder="None (Unmapped)"
        components={{
          Option: CustomIconOption,
          SingleValue: CustomIconSingleValue,
        }}
      />
    </Box>
  );
}
