// TODO - change to nested route urls

const urls = {
  home: "/",

  register: "/register",
  login: "/login/start",
  loginPassword: "/login/password",
  loginSso: "/login/sso",
  loginSsoRedirect: "/sso-redirect",
  passwordCreate: "/password/create/:token",
  emailForgot: "/forgot-password/email",
  passwordForgot: "/password/reset/:token",
  verifyToken: "/verify-email/:token",
  unsubscribed: "/unsubscribed",

  salesforceRoute: "/salesforce",
  salesforceRedirect: "/salesforce/oauth.complete",
  salesforceDetails: "/salesforce/:id",

  campaign: "/campaign", // Old url to redirect
  campaignEdit: "/campaign/:id", // Old url to redirect
  journey: "/journey",
  journeyEdit: "/journey/:id",
  batchJourneyEdit: "/journey/scheduled/:id",
  triggerJourneyEdit: "/journey/trigger/:id",

  unifiedMapping: "/connections",
  segment: "/connections/segment/details",

  connectionDetails: "/connections/:id",
  connectionConfiguration: "/connections/configuration",
  connectionConfigurationEdit: "/connections/configuration/:id",

  impersonate: "/internal/impersonate",
  loginWithToken: "/internal/login-with-token",

  database: "/database",
  person: "/database/contacts",
  personDetails: "/database/contacts/:id",
  account: "/database/accounts",
  accountDetails: "/database/accounts/:id",

  assets: "/assets",

  staticList: "/assets/static-list",
  staticListContacts: "/assets/static-list/:id",

  tokenDetailsEdit: "/assets/token/:id",
  token: "/assets/token",

  template: "/assets/template", // Old url to redirect
  templateEdit: "/assets/template/:id", // Old url to redirect
  email: "/assets/email",
  emailEdit: "/assets/email/:id",

  webhook: "/assets/webhook",
  webhookEdit: "/assets/webhook/:id",

  form: "/assets/form",
  formEdit: "/assets/form/:id",
  formProvider: "/connections/form-provider",
  formIntegration: "/connections/form-provider/:vendor",

  settings: "/settings",
  ssoConfiguration: "settings/sso-configuration",
  emailSettings: "settings/email-settings",
  users: "settings/users",
};

export default urls;
