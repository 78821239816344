import { Icon } from "@chakra-ui/react";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import IButton from "../IButton";

export default function SidebarBackButton({
  onClick,
  text,
}: {
  onClick: () => void;
  text?: string;
}) {
  return (
    <IButton
      size="md"
      aria-label="Go back"
      variant="link"
      name="return-button"
      onClick={onClick}
      fontSize="xs"
      fontWeight="bold"
      leftIcon={<Icon fontSize="12px" as={FaArrowLeft} />}
    >
      {text ?? "Back"}
    </IButton>
  );
}
