import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth, Roles, UserInviteType } from "../../../common/types/auth";
import {
  listUserAccountsApi,
  inviteUserApi,
  deleteInviteApi,
  resendInviteApi,
  updateUserApi,
  deactivateUserApi,
} from "../../../common/api/auth/auth";
import { RootState } from "../../../store";
import { LoadingWithData, PaginationType } from "../../../common/types/common";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import { toast } from "react-toastify";
import { initializeLoadingData } from "../../../common/helper/commonHelper";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import { USER_LIST_ACTION } from "../../account/accountSlice";

interface UserState {
  userInviteList: PaginationType<Auth>;
  invitingUser: boolean;
  refreshList: boolean;
  deletingInvite: boolean;
  updatingUser: boolean;
  resendingInvite: boolean;
  deactivatedAccount: LoadingWithData<Auth>;
}

const initialState: UserState = {
  userInviteList: INITIAL_PAGINATION,
  invitingUser: false,
  refreshList: true,
  deletingInvite: false,
  updatingUser: false,
  resendingInvite: false,
  deactivatedAccount: initializeLoadingData({}),
};

export const getUserInviteList = createAsyncThunk(
  "user/invite-list",
  async (_, thunkApi) => {
    const { user } = thunkApi.getState() as RootState;
    return await listUserAccountsApi(
      user.userInviteList.pageSize,
      user.userInviteList.currentPageNo
    );
  }
);

export const inviteUsers = createAsyncThunk(
  "user/invite",
  async (users: UserInviteType[]) => {
    return await inviteUserApi(users);
  }
);

export const deleteInvite = createAsyncThunk(
  "user/delete-invite",
  async (account_id: number) => {
    return await deleteInviteApi(account_id);
  }
);

export const resendInvite = createAsyncThunk(
  "user/resend-invite",
  async (data: {
    account_id: number;
    name: string;
    email: string;
    role_names: Roles[];
  }) => {
    return await resendInviteApi(data);
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (
    data: { account_id: number; name?: string; role_names?: Roles[] },
    { dispatch }
  ) => {
    const response = await updateUserApi(data);
    dispatch(expireApiStatus([{ actionName: USER_LIST_ACTION }]));
    return response;
  }
);

export const deactivateUser = createAsyncThunk(
  "user/deactivate",
  async (accountId: number, { dispatch }) => {
    const response = await deactivateUserApi(accountId);
    dispatch(expireApiStatus([{ actionName: USER_LIST_ACTION }]));
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInviteListPage(state, action: PayloadAction<number>) {
      const pageNo = action.payload;
      if (pageNo && pageNo <= (state.userInviteList.totalPageCount ?? 1)) {
        state.userInviteList.currentPageNo = pageNo;
        state.userInviteList.changingPage = true;
        state.refreshList = true;
      }
    },
    resetUserInviteListPage(state) {
      state.userInviteList = initialState.userInviteList;
      state.invitingUser = initialState.invitingUser;
      state.refreshList = initialState.refreshList;
      state.deletingInvite = initialState.deletingInvite;
      state.updatingUser = initialState.updatingUser;
      state.resendingInvite = initialState.resendingInvite;
    },
  },
  extraReducers: (builder) => {
    builder
      //user list
      .addCase(getUserInviteList.pending, (state) => {
        state.userInviteList.fetchingList = true;
      })
      .addCase(getUserInviteList.fulfilled, (state, action) => {
        state.userInviteList.list = action.payload.records;
        state.userInviteList.totalPageCount = action.payload.page_count;
        state.userInviteList.count = action.payload.record_count;
        state.userInviteList.fetchingList = false;
        state.userInviteList.changingPage = false;
        state.refreshList = false;
      })
      .addCase(getUserInviteList.rejected, (state) => {
        state.userInviteList.totalPageCount = 0;
        state.userInviteList.fetchingList = false;
        state.userInviteList.changingPage = false;
        state.refreshList = false;
      })
      //inviting users
      .addCase(inviteUsers.pending, (state) => {
        state.invitingUser = true;
      })
      .addCase(inviteUsers.fulfilled, (state, action) => {
        state.invitingUser = false;
        state.refreshList = true;
        if (action.payload.unsuccessful.length) {
          const emails = action.payload.unsuccessful.map(
            (invites) => invites.email
          );
          toast.error("Invitation to " + emails.join(", ") + " failed");
        }
      })
      .addCase(inviteUsers.rejected, (state) => {
        state.invitingUser = false;
      })
      //delete invite
      .addCase(deleteInvite.pending, (state) => {
        state.deletingInvite = true;
      })
      .addCase(deleteInvite.fulfilled, (state, action) => {
        state.deletingInvite = false;
        if (action.payload.success) {
          toast.success("Invite deleted successfully");
          state.refreshList = true;
        } else {
          toast.error("Invite deletion failed");
        }
      })
      .addCase(deleteInvite.rejected, (state) => {
        state.deletingInvite = false;
      })
      //resend invite
      .addCase(resendInvite.pending, (state) => {
        state.resendingInvite = true;
      })
      .addCase(resendInvite.fulfilled, (state) => {
        state.resendingInvite = false;
        state.refreshList = true;
        toast.success("Invite resent successfully");
      })
      .addCase(resendInvite.rejected, (state) => {
        state.resendingInvite = false;
      })
      //user update
      .addCase(updateUser.pending, (state) => {
        state.updatingUser = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.updatingUser = false;
        state.refreshList = true;
        toast.success("User updated successfully");
      })
      .addCase(updateUser.rejected, (state) => {
        state.updatingUser = false;
      })
      //user deactivate
      .addCase(deactivateUser.pending, (state) => {
        state.deactivatedAccount.loading = LOADING_STATES.LOADING;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        if (!action.payload.success.is_active) {
          state.deactivatedAccount.loading = LOADING_STATES.SUCCESS;
          state.deactivatedAccount.data = action.payload.success;
          state.refreshList = true;
        } else {
          toast.error("Account deactivation failed");
          state.refreshList = false;
        }
      })
      .addCase(deactivateUser.rejected, (state) => {
        state.deactivatedAccount.loading = LOADING_STATES.FAILED;
      });
  },
});

export const { setUserInviteListPage, resetUserInviteListPage } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
