import { IconButton, ButtonProps } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import IButton, { BUTTON } from "../IButton";
import { RxPlus } from "react-icons/rx";

export default function AddPropertyButton({
  onClick,
  iconOnly = false,
  capitalize = true,
  ...props
}: {
  onClick: () => void;
  iconOnly?: boolean;
  capitalize?: boolean;
} & ButtonProps) {
  return (
    <>
      {iconOnly ? (
        <IconButton
          aria-label="Add new property"
          size="xs"
          colorScheme="blue"
          name="add-new-property"
          icon={<FaPlus fontSize="12px" />}
          onClick={onClick}
          {...props}
        />
      ) : (
        <IButton
          variant={BUTTON.SECONDARY}
          color="brand.blue"
          capitalize={capitalize}
          fontWeight="400"
          name="add-new-property"
          leftIcon={<RxPlus fontSize="12px" />}
          onClick={onClick}
          children="Add new property"
          {...props}
        />
      )}
    </>
  );
}
