import { Flex, Icon, Badge, Text, HStack } from "@chakra-ui/react";
import {
  FaAt,
  FaAlignLeft,
  FaToggleOn,
  FaCalendar,
  FaHashtag,
} from "react-icons/fa";
import {
  DESTINATION_TYPES,
  SUB_TYPES,
} from "../../common/types/unifiedMapping";
import LockIconWithTooltip from "../LockIconWithTooltip";

export function iconFinder(type: DESTINATION_TYPES | SUB_TYPES | string) {
  switch (type) {
    case SUB_TYPES.EMAIL:
      return FaAt;
    case DESTINATION_TYPES.STRING:
      return FaAlignLeft;
    case DESTINATION_TYPES.BOOLEAN:
      return FaToggleOn;
    case DESTINATION_TYPES.DATETIME:
      return FaCalendar;
    case DESTINATION_TYPES.INTEGER:
    case DESTINATION_TYPES.FLOAT:
    case DESTINATION_TYPES.LONG:
      return FaHashtag;
    default:
      return FaAlignLeft;
  }
}

export function MappingTableColumnTitle({
  type,
  name,
  removable,
  tooltipMessage,
}: {
  type: DESTINATION_TYPES | SUB_TYPES;
  name: string;
  removable: boolean;
  tooltipMessage?: string;
}) {
  return (
    <Flex alignItems="start" justifyContent="flex-start" width="100%" p="2">
      <Icon as={iconFinder(type)} mr="3" mt="1" color="gray.600"></Icon>
      <HStack mr={3} alignItems="start" flexWrap="wrap">
        <HStack spacing="1" ml="2">
          <Text
            flex="1"
            fontSize="sm"
            wordBreak={"break-word"}
            style={{ hyphens: "auto" }}
            as="span"
          >
            {name}
          </Text>
          {!!tooltipMessage && <LockIconWithTooltip message={tooltipMessage} />}
        </HStack>
        {removable && <Badge>custom</Badge>}
      </HStack>
    </Flex>
  );
}
