import { ALERT_TYPE, DeletionModalLabels } from "../common/constants/common";
import { Text } from "@chakra-ui/react";
import IModal from "./IModal";
import { ReactNode } from "react";

function DeleteConfirmationModalContent({
  itemSpecificName,
  deleteItemType,
}: {
  itemSpecificName?: string;
  deleteItemType?: string;
}) {
  return (
    <Text fontSize="14px" color="blackAlpha.700">
      Are you sure about deleting{" "}
      <Text
        as="span"
        fontWeight={itemSpecificName ? "bold" : ""}
        color={itemSpecificName ? "blackAlpha.900" : "blackAlpha.700"}
      >
        {itemSpecificName ?? "this"}
      </Text>
      {` ${deleteItemType}` ?? ""}? Once deleted, it cannot be undone
    </Text>
  );
}

export function DeleteConfirmationModal({
  isOpen,
  onClose,
  submitHandler,
  deleteItemType,
  itemSpecificName,
  isLoading = false,
  type = ALERT_TYPE.DELETE,
  customHeader,
  customContent,
}: {
  isOpen: boolean;
  onClose: () => void;
  submitHandler: () => void;
  deleteItemType?: string;
  itemSpecificName?: string | "this";
  isLoading?: boolean;
  type?: ALERT_TYPE;
  customHeader?: string;
  customContent?: ReactNode;
}) {
  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      header={{
        title:
          customHeader ??
          `${DeletionModalLabels[type].label} ${deleteItemType}`,
      }}
      deleteButton={{
        label: DeletionModalLabels[type].label,
        props: {
          onClick: submitHandler,
          isLoading: isLoading,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onClose,
        },
      }}
      size="lg"
    >
      {customContent ?? (
        <DeleteConfirmationModalContent
          itemSpecificName={itemSpecificName}
          deleteItemType={deleteItemType}
        />
      )}
    </IModal>
  );
}
