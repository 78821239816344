import {
  FileValidationResp,
  GetUrlResp,
  CsvMappingResp,
  ErrorsWithMeta,
  ContactImportCsv,
  ContactImportResp,
  GetUploadSummaryResp,
  CsvMappingWithDestId,
  GetUploadStatusResp,
} from "../../types/contactUpload";
import { campaignRequestV2 } from "./api";

const UPLOADER_BASE_URL = "/assets/contacts/uploads";

function uploaderBaseUrlWithId(uploadId: string) {
  return `${UPLOADER_BASE_URL}/${uploadId}`;
}

export async function getUploadUrlApi(): Promise<ErrorsWithMeta<GetUrlResp>> {
  return await campaignRequestV2({
    url: `${UPLOADER_BASE_URL}/file`,
  });
}

export async function validateFileApi(
  uploadId: string
): Promise<ErrorsWithMeta<{ file: FileValidationResp }>> {
  return await campaignRequestV2({
    method: "GET",
    url: `${uploaderBaseUrlWithId(uploadId)}/file`,
  });
}

export async function getSmartMappingApi({
  uploadId,
  fieldNamesToMap,
  refreshMapping = false, // change mapping for same fields
}: {
  uploadId: string;
  fieldNamesToMap: string[];
  refreshMapping?: boolean;
}): Promise<ErrorsWithMeta<CsvMappingResp>> {
  return await campaignRequestV2({
    method: "GET",
    url: `${uploaderBaseUrlWithId(uploadId)}/mapping`,
    params: {
      refresh: refreshMapping,
      fields: fieldNamesToMap,
    },
    paramsSerializer: {
      indexes: null,
    },
  });
}

export async function updateCsvMappingApi({
  uploadId,
  mappedFields,
}: {
  uploadId: string;
  mappedFields: CsvMappingWithDestId;
}): Promise<ErrorsWithMeta<CsvMappingResp>> {
  return await campaignRequestV2({
    method: "PUT",
    url: `${uploaderBaseUrlWithId(uploadId)}/mapping`,
    data: { file_to_datastore_mapping: mappedFields },
  });
}

export async function getUploadSummaryApi(
  uploadId: string
): Promise<ErrorsWithMeta<GetUploadSummaryResp>> {
  return await campaignRequestV2({
    method: "GET",

    url: `${uploaderBaseUrlWithId(uploadId)}/report`,
  });
}

export async function getErrorReportDownloadUrlApi(
  uploadId: string
): Promise<ErrorsWithMeta<GetUrlResp>> {
  return await campaignRequestV2({
    method: "GET",
    url: `${uploaderBaseUrlWithId(uploadId)}/report/file`,
  });
}

// FLAG: depreceate this after completely moving to FLINK
export async function importContactsFromCsvApi({
  uploadId,
  testRun,
  staticListId,
  updateDbWithBlankValueInCsv,
  updateOnlyEmptyFieldsInDb,
  importAction,
}: ContactImportCsv): Promise<ErrorsWithMeta<ContactImportResp>> {
  return await campaignRequestV2({
    url: `${uploaderBaseUrlWithId(uploadId)}/records`,
    data: {
      dry_run: testRun ?? false,
      upload_options: {
        import_method: importAction,
        update_empty_fields_only: updateOnlyEmptyFieldsInDb,
        overwrite_with_blank_values: updateDbWithBlankValueInCsv,
        static_list_id: staticListId ? staticListId : null,
      },
    },
  });
}

export async function importContactsFromCsvAsyncApi({
  uploadId,
  testRun,
  staticListId,
  updateDbWithBlankValueInCsv,
  updateOnlyEmptyFieldsInDb,
  importAction,
}: ContactImportCsv): Promise<ErrorsWithMeta<ContactImportResp>> {
  return await campaignRequestV2({
    url: `${uploaderBaseUrlWithId(uploadId)}/records_async`,
    data: {
      dry_run: testRun ?? false,
      upload_options: {
        import_method: importAction,
        update_empty_fields_only: updateOnlyEmptyFieldsInDb,
        overwrite_with_blank_values: updateDbWithBlankValueInCsv,
        static_list_id: staticListId || null,
      },
    },
  });
}

export async function getUploadStatusApi(
  uploadId: string
): Promise<ErrorsWithMeta<GetUploadStatusResp>> {
  return await campaignRequestV2({
    url: `${uploaderBaseUrlWithId(uploadId)}/status`,
  });
}
