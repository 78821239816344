import { ChangeEvent, useState, useCallback } from "react";
import { Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createPersonLegacyApi } from "../../../../common/api/campaign/person";
import {
  isIngestionTypeFlink,
  onEnterKeySubmit,
  validateEmail,
  isFulfilled,
  handleTransactionStatus,
} from "../../../../common/helper/commonHelper";
import IModal from "../../../../components/IModal";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { useAppDispatch } from "../../../../store";
import urls from "../../../../urls";
import {
  createPersonLegacyWithEmail,
  createPersonWithEmail,
  resetTransactionId,
  getPersonTransactionIdStatus,
  getPersonWithEmail,
} from "../personDbSlice";
import {
  usePreventRefresh,
  useExponentialPolling,
} from "../../../../common/hooks/commonHooks";
import { selectFeatureFlag } from "../../../../common/slices/featureFlagSlice";
import {
  createPersonApi,
  getPersonWithEmailApi,
  getPersonTransactionIdStatusApi,
} from "../../../../common/api/core-integrations/contact";
import { toast } from "react-toastify";
import { PreventNavigationModal } from "../../../../components/PreventNavigationModal";

export default function CreatePersonModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [creatingPerson, setCreatingPerson] = useState(false);
  const [activeClick, setActiveClick] = useState(false);
  const [polling, setPolling] = useState(false);

  const {
    contactIngestionEtl: { data: contactIngestionType },
  } = useSelector(selectFeatureFlag);

  usePreventRefresh({ preventRefresh: creatingPerson });

  function handleEmailChange(input: string) {
    setEmail(input);
    setIsValid(!!input && validateEmail(input));
  }

  const closeWithResetData = useCallback(() => {
    setEmail("");
    setIsValid(false);
    setCreatingPerson(false);
    setActiveClick(false);
    dispatch(resetTransactionId());
    onClose();
  }, [onClose, dispatch]);

  const navigateToPerson = useCallback(async () => {
    const getPersonResponse = await dispatch(getPersonWithEmail(email));
    const successResponse = getPersonResponse.payload as Awaited<
      ReturnType<typeof getPersonWithEmailApi>
    >;
    if (successResponse && successResponse.data) {
      closeWithResetData();
      navigate(`${urls.person}/${successResponse.data.id}`);
    } else {
      closeWithResetData();
      toast.error("Error fetching contact");
    }
  }, [dispatch, email, navigate, closeWithResetData]);

  async function createPerson() {
    setActiveClick(true);
    if (isValid) {
      setCreatingPerson(true);
      if (isIngestionTypeFlink(contactIngestionType)) {
        const createPersonResponse = await dispatch(
          createPersonWithEmail(email)
        );
        const successResponse = createPersonResponse.payload as Awaited<
          ReturnType<typeof createPersonApi>
        >;
        handleTransactionStatus(successResponse?.data?.status)
          .onDone(async () => {
            await navigateToPerson();
            setCreatingPerson(false);
          })
          .onPending(() => {
            setPolling(true);
          })
          .onDefault(() => {
            setCreatingPerson(false);
          });
      } else {
        const createPersonResponse = await dispatch(
          createPersonLegacyWithEmail(email)
        );
        const successResponse = createPersonResponse.payload as Awaited<
          ReturnType<typeof createPersonLegacyApi>
        >;
        if (
          successResponse &&
          successResponse.success &&
          successResponse.person_id
        ) {
          closeWithResetData();
          navigate(`${urls.person}/${successResponse.person_id}`);
        }
        setCreatingPerson(false);
      }
    }
  }

  const pollContactCreateStatus = useCallback(async () => {
    const {
      meta: { requestStatus },
      payload,
    } = await dispatch(getPersonTransactionIdStatus());

    function handleCreationFailure(errorMsg = "Contact creation failed") {
      setCreatingPerson(false);
      setPolling(false);
      toast.error(errorMsg);
    }

    if (!isFulfilled(requestStatus)) {
      handleCreationFailure();
      return;
    }

    const { data, errors } = payload as Awaited<
      ReturnType<typeof getPersonTransactionIdStatusApi>
    >;

    if (!!errors) {
      handleCreationFailure();
      return;
    }

    handleTransactionStatus(data?.status)
      .onError(() => {
        if (
          data?.error &&
          data.error.includes("Insert operation attempted for existing entity")
        ) {
          handleCreationFailure("Contact already exists");
        } else {
          handleCreationFailure();
        }
        return;
      })
      .onDone(async () => {
        setCreatingPerson(false);
        setPolling(false);
        toast.success("Contact created successfully");
        navigateToPerson();
        closeWithResetData();
      });
  }, [dispatch, closeWithResetData, navigateToPerson]);

  useExponentialPolling({
    onPolling: pollContactCreateStatus,
    shouldPoll: polling,
    initialWaitTime: 1,
  });

  function closeIfNotLoading() {
    if (!creatingPerson) {
      closeWithResetData();
    }
  }

  return (
    <IModal
      header={{ title: "Create a new contact" }}
      primaryButton={{
        label: "Create contact",
        props: {
          isDisabled: activeClick && !isValid,
          onClick: createPerson,
          isLoading: creatingPerson,
          loadingText: "Creating...",
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: closeIfNotLoading,
          hidden: creatingPerson,
        },
      }}
      isOpen={isOpen}
      onClose={closeIfNotLoading}
      hideCloseButton={creatingPerson}
    >
      <InputFieldWithError
        labelText="Email"
        formLabelProps={{ color: "gray.500", fontWeight: "500" }}
        type="email"
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleEmailChange(e.target.value)
        }
        placeholder="Enter email"
        onKeyDown={(e) => onEnterKeySubmit(e, createPerson)}
        errorMsg={activeClick && !isValid ? "Valid email is required" : ""}
        isDisabled={creatingPerson}
        autoFocus
      />
      <PreventNavigationModal
        isActive={creatingPerson}
        cancelText="Cancel"
        confirmText="Confirm"
        children={
          <Text>
            If you navigate to another tab, contact creation may fail.
          </Text>
        }
      />
    </IModal>
  );
}
