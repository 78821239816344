import { FaUserPlus } from "react-icons/fa";
import { CAMPAIGN_CONTEXT } from "../../../../../common/types/campaign";
import CommonDrawer from "../../components/CommonDrawer";
import SectionWrapper, {
  SectionItem,
  SectionLink,
} from "./components/SectionWrapper";
import { useDisclosure } from "@chakra-ui/react";
import { getPersonLabel } from "../../../../../common/helper/commonHelper";
import TriggerCampaignAudienceCriteria from "../../trigger-campaign/TriggerCampaignAudienceCriteria";
import { NodeGroup } from "../../../../../common/types/dynamicList";
import AudienceCriteria from "../../Audience-criteria/AudienceCriteria";

export default function TriggerAudienceFilterSection({
  readonly,
  errorText,
  data,
  onChange,
  saveDraft,
  campaignContext,
  isLoading,
  activeErrorCheck,
  validateOnClose,
}: {
  readonly: boolean;
  errorText: string;
  data: NodeGroup[];
  onChange: (audienceCriteria: NodeGroup[]) => void;
  saveDraft: (audienceCriteria: NodeGroup[]) => void;
  campaignContext: CAMPAIGN_CONTEXT;
  isLoading: boolean;
  activeErrorCheck: boolean;
  validateOnClose: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onHandleClose = () => {
    if (activeErrorCheck) validateOnClose();
    onClose();
  };
  return (
    <>
      <SectionWrapper
        icon={FaUserPlus}
        title="Audience"
        isReadOnly={readonly}
        onRightButtonClick={onOpen}
        isErrorHidden={!activeErrorCheck}
        errorText={errorText}
        isLoading={isLoading}
        isEmpty={!data.length}
      >
        {data.length ? (
          <SectionItem
            infoText={`Consider a ${getPersonLabel(
              campaignContext
            )} for the workflow only if they meet the following criteria`}
            errorText={activeErrorCheck ? errorText : ""}
          >
            <AudienceCriteria
              audienceCriteriaData={data}
              onChange={onChange}
              campaignContext={campaignContext}
              readOnlyMode
            />
          </SectionItem>
        ) : (
          <SectionLink
            onOpen={onOpen}
            title="Click to set up audience filter"
            LinkIcon={FaUserPlus}
            errorText={errorText}
          />
        )}
      </SectionWrapper>
      <CommonDrawer
        title="Audience"
        isOpen={isOpen}
        onClose={onHandleClose}
        size="3xl"
      >
        <TriggerCampaignAudienceCriteria
          audienceCriteria={data}
          setAudienceCriteria={onChange}
          campaignContext={campaignContext}
          activeErrorCheck={activeErrorCheck}
          isReadOnly={readonly}
        />
      </CommonDrawer>
    </>
  );
}
