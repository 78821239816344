import {
  isFulfilled,
  isLoading,
} from "../../../../../../common/helper/commonHelper";
import SfPermissionsDrawer from "./SfPermissionsDrawer";
import SfSettingsDrawer from "./SfSettingsDrawer";
import SfSummaryDrawer from "./SfSummaryDrawer";
import SfOverrideModal from "./SfOverrideModal";
import { useSelector } from "react-redux";
import {
  completeSalesforceSetup,
  getSalesforceConnectionDetails,
  selectSalesforce,
  updateSalesforceConnection,
} from "../../salesforceSlice";
import { useNavigate } from "react-router-dom";
import urls from "../../../../../../urls";
import {
  SalesforceConnectionV2,
  SF_INIT_STEPS,
} from "../../../../../../common/types/salesforce";
import { useAppDispatch } from "../../../../../../store";

export default function SalesforceConnectionInit({
  currentStep,
  goToNextStep,
  goToPrevStep,
  onCloseSfInit,
}: {
  currentStep: SF_INIT_STEPS | null;
  goToNextStep: VoidFunction;
  goToPrevStep: VoidFunction;
  onCloseSfInit: VoidFunction;
}) {
  const {
    sfConnection,
    updatingSfConnection,
    fetchingSfConnection,
    completingSfSetup,
  } = useSelector(selectSalesforce);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function isCurrentStep(step: SF_INIT_STEPS) {
    return currentStep === step;
  }

  function redirectToConnections() {
    navigate(urls.unifiedMapping);
  }

  function onReCheckPermissions() {
    if (sfConnection?.connectionId) {
      dispatch(getSalesforceConnectionDetails(sfConnection?.connectionId));
    }
  }

  async function completeSfSync(
    autoMap: boolean,
    connectionId = sfConnection?.connectionId
  ) {
    if (connectionId) {
      const resp = await dispatch(
        completeSalesforceSetup({ connectionId, autoMapAndStartSync: autoMap })
      );
      if (isFulfilled(resp.meta.requestStatus)) {
        onCloseSfInit();
      }
    }
  }

  function onSaveInitialSettings(connectionDetails: SalesforceConnectionV2) {
    dispatch(updateSalesforceConnection(connectionDetails));
    //automatically goes to next step depending on state
  }

  return (
    <>
      {sfConnection && (
        <>
          <SfPermissionsDrawer
            isOpen={isCurrentStep(SF_INIT_STEPS.CHECK_PERMISSIONS)}
            permissions={sfConnection.permissions}
            goToNextStep={goToNextStep}
            onClose={redirectToConnections}
            onRecheckPermission={onReCheckPermissions}
            fetchingConnection={isLoading(fetchingSfConnection)}
          />

          <SfSettingsDrawer
            isOpen={isCurrentStep(SF_INIT_STEPS.SYNC_SETTINGS)}
            connectionDetails={sfConnection}
            loading={isLoading(updatingSfConnection)}
            onSaveSettings={onSaveInitialSettings}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            onClose={redirectToConnections}
          />

          <SfSummaryDrawer
            isOpen={isCurrentStep(SF_INIT_STEPS.SYNC_SUMMARY)}
            connectionDetails={sfConnection}
            completeSfSync={completeSfSync}
            completingSetup={isLoading(completingSfSetup)}
            goToNextStep={goToNextStep}
            goToPrevStep={goToPrevStep}
            onClose={redirectToConnections}
          />
          <SfOverrideModal
            isOpen={isCurrentStep(SF_INIT_STEPS.CONFIRM_SYNC)}
            completeSfSync={completeSfSync}
            completingSetup={isLoading(completingSfSetup)}
            goToPrevStep={goToPrevStep}
          />
        </>
      )}
    </>
  );
}
