import { Spinner, Icon, HStack, Tooltip, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { MdCheck, MdInsertDriveFile } from "react-icons/md";
import { getExportStatusApi } from "../../../../common/api/campaign/export";
import { useExponentialPolling } from "../../../../common/hooks/commonHooks";
import {
  EXPORT_STATUS,
  ExportProgressConfig,
} from "../../../../common/types/export";
import { useAppDispatch } from "../../../../store";
import {
  getExportStatus,
  selectExport,
  abortExport,
  setExportStatusDownloaded,
  setExportStatusFailed,
} from "../exportSlice";
import {
  isFulfilled,
  downloadFileWithoutPageReload,
  appendDateTime,
  isPendingExport,
} from "../../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import { IoWarning } from "react-icons/io5";

function ExportStatusIcon({ exportStatus }: { exportStatus: EXPORT_STATUS }) {
  switch (exportStatus) {
    case EXPORT_STATUS.QUEUED:
    case EXPORT_STATUS.STARTED:
    case EXPORT_STATUS.EXPORTED:
      return <Spinner size="sm" />;
    case EXPORT_STATUS.DOWNLOADED:
      return <Icon as={MdCheck} fontSize="xl" color="green.400" />;
    default:
      return <></>;
  }
}

export default function ExportItem({
  exportData: { exportId, filename, currentStatus, urls, requestedAt },
}: {
  exportData: ExportProgressConfig & { exportId: string };
}) {
  const { abortExports } = useSelector(selectExport);
  const [polling, setPolling] = useState(true);
  const dispatch = useAppDispatch();
  const fileNameDateTime = appendDateTime(filename, requestedAt);

  const getCurrentStatus = useCallback(async () => {
    const {
      meta: { requestStatus: updatedStatusRequest },
      payload: updatedStatusResp,
    } = await dispatch(getExportStatus(exportId));
    if (isFulfilled(updatedStatusRequest)) {
      const { current_status } = updatedStatusResp as Awaited<
        ReturnType<typeof getExportStatusApi>
      >;
      return current_status;
    }
    return null;
  }, [dispatch, exportId]);

  const pollExportStatus = useCallback(async () => {
    const currentStatusOfExport = await getCurrentStatus();

    const stopPolling =
      abortExports ||
      !currentStatusOfExport ||
      (currentStatusOfExport && !isPendingExport(currentStatusOfExport));

    setPolling(!stopPolling);
  }, [abortExports, getCurrentStatus]);

  function refreshDownloadUrl() {
    getCurrentStatus();
  }

  useExponentialPolling({
    onPolling: pollExportStatus,
    shouldPoll: polling,
  });

  useEffect(() => {
    if (abortExports && isPendingExport(currentStatus)) {
      dispatch(abortExport(exportId));
      setPolling(false);
    }
  }, [abortExports, currentStatus, dispatch, exportId]);

  const downloadFileSetStatus = useCallback(
    async (url: string) => {
      try {
        downloadFileWithoutPageReload(url, filename + ".csv");
        dispatch(setExportStatusDownloaded(exportId));
      } catch (e) {
        dispatch(setExportStatusFailed(exportId));
      }
    },
    [dispatch, exportId, filename]
  );

  useEffect(() => {
    if (
      urls?.length === 1 &&
      !abortExports &&
      currentStatus === EXPORT_STATUS.EXPORTED
    ) {
      downloadFileSetStatus(urls[0]);
    }
  }, [urls, currentStatus, abortExports, downloadFileSetStatus]);

  const isFailedExport = currentStatus === EXPORT_STATUS.FAILED;

  return (
    <HStack
      px={3}
      py={4}
      justifyContent="space-between"
      key={exportId}
      w="100%"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <HStack
        justifyContent="start"
        onClick={refreshDownloadUrl}
        cursor={
          currentStatus === EXPORT_STATUS.DOWNLOADED ? "pointer" : "default"
        }
      >
        {isFailedExport ? (
          <Icon as={IoWarning} fontSize="lg" color="red.500" />
        ) : (
          <Icon as={MdInsertDriveFile} fontSize="lg" color="text.200" />
        )}
        <Tooltip label={fileNameDateTime} fontSize="xs">
          <Text
            fontSize="sm"
            maxW="200px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {fileNameDateTime}
          </Text>
        </Tooltip>
      </HStack>
      <ExportStatusIcon exportStatus={currentStatus} />
    </HStack>
  );
}
