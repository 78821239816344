import { defineStyleConfig } from "@chakra-ui/react";

//TODO: styling of input and dropdown needs to be consistant even in disabled state

const inputStyle = defineStyleConfig({
  baseStyle: {
    field: {
      border: "1px solid grayV2.200",
      _disabled: {
        background: "hsl(0, 10%, 100%)",
        opacity: 0.6,
        color: "rgba(0,0,0,0.6)",
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: "14px",
      },
      addon: {
        fontSize: "14px",
      },
    },
  },
  variants: {
    outline: {
      field: {
        background: "white",
        borderRadius: "md",
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "outline",
  },
});

export default inputStyle;
