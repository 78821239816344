import { Badge, Button, Center, HStack, Icon, Text } from "@chakra-ui/react";
import {
  components,
  GroupBase,
  OptionProps,
  SingleValueProps,
} from "react-select";
import { READONLY_SF_SYNC_MESSAGE } from "../../../../../common/constants/connection";
import { isSalesforcePreference } from "../../../../../common/helper/unifiedMappingHelper";
import { IconOptionsType } from "../../../../../common/types/flow";
import LockIconWithTooltip from "../../../../../components/LockIconWithTooltip";

export function CustomIconOption({
  children,
  data,
  ...props
}: OptionProps<IconOptionsType, false, GroupBase<IconOptionsType>>) {
  return (
    <HStack>
      <Button
        justifyContent="flex-start"
        variant="ghost"
        alignItems="center"
        p={3}
        fontSize="14px"
        bg={props.isSelected ? "gray.300" : ""}
        _hover={props.isSelected ? {} : { bg: "gray.100" }}
        onClick={() => props.setValue(data, "select-option")}
        isDisabled={props.isDisabled && !props.isSelected}
        fontWeight="normal"
        w="100%"
        borderRadius={0}
        leftIcon={<Icon as={data.icon} fontSize="xs" />}
      >
        {data.label}

        <Center p="2" color="gray.600" py="0.5px" position="absolute" right={2}>
          {isSalesforcePreference(data.salesforcePreference) && (
            <LockIconWithTooltip message={READONLY_SF_SYNC_MESSAGE} />
          )}
        </Center>
        {data.required && (
          <Badge
            fontSize="10px"
            backgroundColor="oneOffs.tableheader"
            variant="solid"
            py="0.5px"
            position="absolute"
            right={2}
          >
            Required
          </Badge>
        )}
      </Button>
    </HStack>
  );
}

export function CustomIconSingleValue({
  children,
  ...props
}: SingleValueProps<IconOptionsType, false, GroupBase<IconOptionsType>>) {
  const values = props.getValue();
  const data = values.length ? values[0] : null;

  return (
    <components.SingleValue {...props}>
      <HStack justifyContent="flex-start" alignItems="center" fontSize="14px">
        <Icon as={data?.icon} color="black.200" fontSize="xs"></Icon>
        <Text
          maxW="100%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {data?.label}
        </Text>
      </HStack>
    </components.SingleValue>
  );
}
