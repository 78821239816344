import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  checkPasswordTokenIsValidApi,
  checkEmailTokenIsValidApi,
  createPasswordApi,
  listUserAccountsApi,
  loginApi,
  loginSsoApi,
  lookupApi,
  registerApi,
  validateTokenApi,
  resetPasswordApi,
  forgotPasswordEmailApi,
  getOrgListApi,
  getAccountListApi,
  impersonateApi,
  logoutUserApi,
} from "../../common/api/auth/auth";
import { LOADING_STATES } from "../../common/constants/common";
import {
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from "../../common/helper/authHelper";
import {
  Auth,
  Organisation,
  USER_ACCOUNT_STATUS,
} from "../../common/types/auth";
import {
  CreatePasswordReq,
  LoginParams,
  TokenWithType,
  Token,
  RegisterParams,
} from "../../common/types/common";
import { RootState } from "../../store";
import {
  setRollbarData,
  removeRollbarData,
} from "../../common/helper/rollbarHelper";
import {
  createAsyncThunkWrapper,
  createUserMapping,
} from "../../common/helper/commonHelper";

export const USER_LIST_ACTION = "auth/get-user-list";

interface TokenState {
  token: string;
  tokenValid: boolean;
  tokenChecking: boolean;
}

type AuthState = {
  isAuthenticated: boolean;
  user: Auth;
  userOrg: Organisation;
  userEmail: string;
  loginLoading: boolean;
  loadingLookup: boolean;
  authType: string;
  samlRedirectUrl: string;
  invalidCredsErrorMsg: string;
  logoutSuccess: LOADING_STATES;

  usersList: { [id: string]: Auth };
  fetchingUsersList: boolean;
  password: TokenState & {
    set: boolean;
    pending: boolean;
  };
  register: {
    loading: boolean;
    success: boolean;
  };
  registerValidation: TokenState;
  forgotPassword: {
    emailSending: boolean;
    emailSent: boolean;
  };

  organisationList: {
    pending: boolean;
    list: Organisation[];
  };
  personList: {
    pending: boolean;
    list: Auth[];
  };

  impersonateStatus: LOADING_STATES;
};

const initialState: AuthState = {
  isAuthenticated: !!getAuthToken() || false,
  userOrg: {
    id: 0,
    name: "",
    slug: "",
    domain: "",
    is_active: false,
    time_created: "",
    time_updated: "",
  },
  user: {
    id: 0,
    organisation_id: 0,
    name: "",
    email: "",
    is_active: false,
    is_email_verified: false,
    is_federated_user: false,
    roles: [],
    time_created: "",
    time_updated: "",
    status: USER_ACCOUNT_STATUS.INACTIVE,
  },
  userEmail: "",
  loginLoading: false,
  loadingLookup: false,
  authType: "",
  samlRedirectUrl: "",
  invalidCredsErrorMsg: "",
  logoutSuccess: LOADING_STATES.INIT,

  usersList: {},
  fetchingUsersList: true,

  password: {
    token: "",
    tokenValid: false,
    tokenChecking: false,
    set: false,
    pending: false,
  },
  register: {
    loading: false,
    success: false,
  },
  registerValidation: {
    token: "",
    tokenValid: false,
    tokenChecking: false,
  },

  forgotPassword: {
    emailSending: false,
    emailSent: false,
  },

  organisationList: {
    pending: false,
    list: [],
  },
  personList: {
    pending: false,
    list: [],
  },
  impersonateStatus: LOADING_STATES.INIT,
};

/**
 * Set the logged-in state with data containing
 * token and account information (authToken, loginUser)
 */
function loginWithToken(state: AuthState, action: PayloadAction<any>) {
  state.user = { ...action.payload.account, roles: action.payload.roles };
  state.userOrg = action.payload.organisation;
  state.isAuthenticated = true;
  state.loginLoading = false;
  // This is a side-effect. Figure out a way to make this cleaner
  setAuthToken(action.payload.token.token);
  const { id, email, organisation_id, name: username } = action.payload.account;

  setRollbarData({
    id,
    email,
    username,
    organisation_id,
    organisation_name: action.payload.organisation.name,
  });
}

export const login = createAsyncThunk(
  "auth/login",
  async (params: LoginParams, thunkApi) => {
    try {
      return await loginApi(params, true);
    } catch (err: any) {
      // TODO - make type 'unknown' here and fix type error
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue({ data: err.response.data });
    }
  }
);

export const loginSso = createAsyncThunk(
  "auth/login-sso",
  async ({ code }: { code: string }) => {
    return await loginSsoApi(code);
  }
);

export const lookup = createAsyncThunk(
  "auth/lookup",
  async ({ email }: { email: string }) => {
    return await lookupApi(email);
  }
);

export const validateToken = createAsyncThunk("auth/validate", async () => {
  return await validateTokenApi();
});

export const registerUser = createAsyncThunk(
  "auth/register",
  async (data: RegisterParams) => {
    return registerApi(data);
  }
);

export const checkPasswordTokenIsValid = createAsyncThunk(
  "auth/check-pass-token-valid",
  async (data: TokenWithType) => {
    return checkPasswordTokenIsValidApi(data);
  }
);

export const getUserList = createAsyncThunkWrapper({
  actionName: USER_LIST_ACTION,
  dispatchFn: async () => {
    return await listUserAccountsApi(1000, 1);
  },
  isCachable: true,
});

export const checkEmailTokenIsValid = createAsyncThunk(
  "auth/check-email-token-valid",
  async (data: Token) => {
    return checkEmailTokenIsValidApi(data);
  }
);

export const createPasswordRequest = createAsyncThunk(
  "auth/create-password",
  async (data: CreatePasswordReq) => {
    return createPasswordApi(data);
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "auth/reset-password",
  async (data: CreatePasswordReq) => {
    return resetPasswordApi(data);
  }
);

export const forgotPasswordEmail = createAsyncThunk(
  "auth/send-forgotpassword-email",
  async (email: string, thunkApi) => {
    try {
      return await forgotPasswordEmailApi(email, true);
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return thunkApi.rejectWithValue({ data: err.response.data });
    }
  }
);

export const getOrganisationList = createAsyncThunk(
  "internal/org-list",
  async () => {
    return getOrgListApi();
  }
);

export const getAccountList = createAsyncThunk(
  "internal/account-list",
  async (organisation_id: number) => {
    return getAccountListApi(organisation_id);
  }
);

export const impersonate = createAsyncThunk(
  "internal/impersonate",
  async (data: { organisation_id: number; account_id: number }) => {
    return impersonateApi(data);
  }
);

export const logoutUser = createAsyncThunk("auth/logoutUser", async () => {
  return logoutUserApi();
});

const accountSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.userEmail = action.payload;
    },
    resetLoginCreds(state) {
      state.userEmail = initialState.userEmail;
      state.authType = initialState.authType;
      state.samlRedirectUrl = initialState.samlRedirectUrl;
    },
    setPasswordToken(state, action) {
      state.password.token = action.payload;
    },
    resetPasswordToken(state) {
      state.password.token = initialState.password.token;
      state.password.tokenValid = initialState.password.tokenValid;
    },
    setRegisterValidationToken(state, action) {
      state.registerValidation.token = action.payload;
    },
    resetRegisterSuccess(state) {
      state.register.success = false;
    },
    resetForgotPasswordEmailSuccess(state) {
      state.forgotPassword.emailSent = false;
    },
    resetImpersonationStatus(state) {
      state.impersonateStatus = LOADING_STATES.INIT;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, loginWithToken)
      .addCase(login.pending, (state) => {
        state.invalidCredsErrorMsg = "";
        state.loginLoading = true;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loginLoading = false;
        // TODO change any type
        if (action.payload && action.payload.data) {
          if (
            [
              "InvalidPasswordException",
              "AccountDoesNotExistsException",
            ].includes(action.payload.data.exception)
          ) {
            state.invalidCredsErrorMsg =
              "Username and password do not match. Please try again";
          } else if (action.payload.data.exception) {
            state.invalidCredsErrorMsg = action.payload.data.description;
          } else if (
            action.payload.data.errors &&
            action.payload.data.errors.password
          ) {
            state.invalidCredsErrorMsg = action.payload.data.errors
              .password[0] as string;
          } else {
            toast.error("Login failed");
          }
        } else {
          toast.error("Login failed");
        }
      })

      .addCase(validateToken.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = { ...action.payload.account, roles: action.payload.roles };
        state.userOrg = action.payload.organisation;
      })
      .addCase(validateToken.rejected, (state) => {
        removeAuthToken();
        removeRollbarData();
        state.isAuthenticated = false;
        state.user = initialState.user;
      })

      .addCase(registerUser.rejected, (state) => {
        state.register = { loading: false, success: false };
      })
      .addCase(registerUser.pending, (state) => {
        state.register.loading = true;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.register = { loading: false, success: true };
      })

      .addCase(lookup.pending, (state) => {
        state.loadingLookup = true;
        state.invalidCredsErrorMsg = "";
      })
      .addCase(lookup.fulfilled, (state, action) => {
        state.authType = action.payload.auth_type;
        state.samlRedirectUrl = action.payload.redirect_url || "";
        state.loadingLookup = false;
      })
      .addCase(lookup.rejected, (state) => {
        state.loadingLookup = false;
      })

      // SSO Login
      .addCase(loginSso.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginSso.fulfilled, (state, action) => {
        state.loginLoading = false;
        loginWithToken(state, action);
      })
      .addCase(loginSso.rejected, (state) => {
        state.loginLoading = false;
      })

      // Users list
      .addCase(getUserList.pending, (state) => {
        state.fetchingUsersList = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.fetchingUsersList = false;
        state.usersList = createUserMapping(action.payload.records);
      })
      .addCase(getUserList.rejected, (state) => {
        state.fetchingUsersList = false;
      })

      //Email verification
      .addCase(checkEmailTokenIsValid.pending, (state) => {
        state.registerValidation.tokenChecking = true;
      })
      .addCase(checkEmailTokenIsValid.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.registerValidation.tokenValid = true;
          toast.success("Email verified successfully!");
        } else state.registerValidation.tokenValid = false;
        state.registerValidation.tokenChecking = false;
      })
      .addCase(checkEmailTokenIsValid.rejected, (state) => {
        state.registerValidation.tokenChecking = false;
        state.registerValidation.tokenValid = false;
      })

      // Password create
      .addCase(checkPasswordTokenIsValid.pending, (state) => {
        state.password.tokenChecking = true;
      })
      .addCase(checkPasswordTokenIsValid.fulfilled, (state) => {
        state.password.tokenChecking = false;
        state.password.tokenValid = true;
      })
      .addCase(checkPasswordTokenIsValid.rejected, (state) => {
        state.password.tokenChecking = false;
        state.password.tokenValid = false;
      })
      .addCase(createPasswordRequest.pending, (state) => {
        state.password.pending = true;
      })
      .addCase(createPasswordRequest.fulfilled, (state) => {
        state.password.set = true;
        state.password.pending = false;
        toast.success("Password created successfully");
      })
      .addCase(createPasswordRequest.rejected, (state) => {
        state.password.pending = false;
      })

      // Password reset
      .addCase(resetPasswordRequest.pending, (state) => {
        state.password.pending = true;
      })
      .addCase(resetPasswordRequest.fulfilled, (state) => {
        state.password.set = true;
        state.password.pending = false;
        toast.success("Password reset successful");
      })
      .addCase(resetPasswordRequest.rejected, (state) => {
        state.password.pending = false;
      })
      .addCase(forgotPasswordEmail.pending, (state) => {
        state.forgotPassword.emailSending = true;
      })
      .addCase(forgotPasswordEmail.fulfilled, (state) => {
        state.forgotPassword.emailSending = false;
        state.forgotPassword.emailSent = true;
      })
      .addCase(
        forgotPasswordEmail.rejected,
        (state, action: PayloadAction<any>) => {
          state.forgotPassword.emailSending = false;
          state.forgotPassword.emailSent = false;
          if (
            action.payload &&
            action.payload.data &&
            action.payload.data.exception === "AccountDoesNotExistsException"
          ) {
            toast.error("Couldn't find your Inflection account");
          } else {
            toast.error("Reset link sending failed");
          }
        }
      )

      //impersonation
      .addCase(getOrganisationList.pending, (state) => {
        state.organisationList.pending = true;
      })
      .addCase(getOrganisationList.fulfilled, (state, action) => {
        state.organisationList.list = action.payload.organisations;
        state.organisationList.pending = false;
      })
      .addCase(getOrganisationList.rejected, (state) => {
        state.organisationList.pending = false;
      })
      .addCase(getAccountList.pending, (state) => {
        state.personList.pending = true;
      })
      .addCase(getAccountList.fulfilled, (state, action) => {
        state.personList.list = action.payload.records;
        state.personList.pending = false;
      })
      .addCase(getAccountList.rejected, (state) => {
        state.personList.pending = false;
      })
      .addCase(impersonate.fulfilled, (state, action) => {
        state.impersonateStatus = LOADING_STATES.SUCCESS;
        loginWithToken(state, action);
        toast.success("Impersonation successful");
      })
      .addCase(impersonate.pending, (state) => {
        state.impersonateStatus = LOADING_STATES.LOADING;
      })
      .addCase(impersonate.rejected, (state) => {
        state.impersonateStatus = LOADING_STATES.FAILED;
      })

      .addCase(logoutUser.pending, (state) => {
        state.logoutSuccess = LOADING_STATES.LOADING;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.logoutSuccess = LOADING_STATES.SUCCESS;
          removeAuthToken();
          removeRollbarData();
          state.isAuthenticated = false;
          state.user = initialState.user;
        } else {
          state.logoutSuccess = LOADING_STATES.FAILED;
          toast.error("Logout failed!");
        }
      })
      .addCase(logoutUser.rejected, (state) => {
        state.logoutSuccess = LOADING_STATES.FAILED;
        toast.error("Logout failed!");
      });
  },
});

export const {
  setEmail,
  resetLoginCreds,
  setPasswordToken,
  resetPasswordToken,
  setRegisterValidationToken,
  resetRegisterSuccess,
  resetForgotPasswordEmailSuccess,
  resetImpersonationStatus,
} = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
