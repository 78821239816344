import React from "react";
import IModal from "../../../../components/IModal";
import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";

export default function DisconnectProvider({
  isOpen,
  providerName,
  onClose,
  onDisconnect,
}: {
  isOpen: boolean;
  providerName: string;
  onClose: () => void;
  onDisconnect: () => void;
}) {
  return (
    <IModal
      isOpen={isOpen}
      size="lg"
      onClose={onClose}
      header={{ title: `Disconnect ${providerName}`, capitalize: false }}
      secondaryButton={{ label: "cancel", props: { onClick: onClose } }}
      deleteButton={{ label: "Disconnect", props: { onClick: onDisconnect } }}
    >
      <VStack spacing={2} alignItems="flex-start" mb={3}>
        <HStack>
          <Icon as={FaInfoCircle} size="12px" color="red.500" />
          <Text fontWeight="medium" fontSize="sm">
            Do you want to disconnect {providerName} from Inflection?
          </Text>
        </HStack>
        <Text color="text.50" fontSize="sm">
          Once disconnected, we will no longer sync form data into Inflection.
        </Text>
      </VStack>
    </IModal>
  );
}
