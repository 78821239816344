import {
  OAuthUrl,
  SalesforceConnectionV2,
  SfResponse,
  SfVerificationParams,
  SfConnectionUpdateType,
  InitSalesforceConReqType,
  SfPagination,
  SfSyncErrorRecord,
  SfSyncErrorsGet,
  SfSyncRunRecord,
  SfSyncRunsGet,
  SfSyncAffectedRecordsGet,
  SfSyncAffectedRecord,
  SfSyncRunsSummaryGet,
  SfSyncRunsSummaryType,
  SfSyncErrorsResyncArchiveReq,
  SfColumnsOfAllSyncObjects,
  SalesforceColumnsGet,
  SalesforcePersonRemapReq,
  SalesforcePersonSyncDetails,
} from "../../types/salesforce";
import { sfIntegrationsRequest } from "./api";
import { SalesforceSyncScheduleDetails } from "../../types/salesforce";

function getSfConnectionPath(append?: string) {
  return `/salesforce/connections${append ? `/${append}` : ""}`;
}

export async function initSalesforceConnectionApi(
  data: InitSalesforceConReqType
): Promise<SfResponse<OAuthUrl>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath("init"),
    data,
  });
}

export async function verifySalesforceConnectionApi(
  reqData: SfVerificationParams
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath("verify"),
    data: reqData,
  });
}

export async function reauthenticateSalesforceApi(
  connectionId: string
): Promise<SfResponse<OAuthUrl>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath(`${connectionId}/re-auth`),
  });
}

export async function finalizeSalesforceSetupApi({
  connectionId,
  autoMapAndStartSync,
}: {
  connectionId: string;
  autoMapAndStartSync: boolean;
}): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath(`${connectionId}/finalize`),
    data: {
      autoMapAndStartSync,
    },
  });
}

export async function getSalesforceConnectionApi(
  connectionId: string
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: getSfConnectionPath(connectionId),
  });
}

export async function updateSalesforceConnectionApi(
  connectionId: string,
  reqData: SfConnectionUpdateType
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    method: "PUT",
    url: getSfConnectionPath(`${connectionId}/preferences`),
    data: reqData,
  });
}

export async function deleteSalesforceConnectionApi(
  connectionId: string
): Promise<SfResponse<boolean>> {
  return await sfIntegrationsRequest({
    method: "DELETE",
    url: getSfConnectionPath(connectionId),
  });
}

export async function resumeSalesforceSyncApi(
  connectionId: string
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath(`${connectionId}/resume`),
  });
}

export async function pauseSalesforceSyncApi(
  connectionId: string
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath(`${connectionId}/pause`),
  });
}

export async function listAllSalesforceConnectionsApi(): Promise<
  SfPagination<SalesforceConnectionV2[]>
> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: getSfConnectionPath(),
  });
}

export async function getSalesforceSyncRunsSummaryApi(
  reqData: SfSyncRunsSummaryGet
): Promise<SfResponse<SfSyncRunsSummaryType>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sync-runs/summary",
    params: reqData,
  });
}

export async function listSalesforceSyncRunsApi(
  reqData: SfSyncRunsGet
): Promise<SfPagination<SfSyncRunRecord[] | null>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sync-runs",
    params: reqData,
  });
}

export async function listSalesforceSyncErrorsApi(
  reqData: SfSyncErrorsGet
): Promise<SfPagination<SfSyncErrorRecord[] | null>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sync-runs/errors",
    params: reqData,
  });
}

export async function listSalesforceSyncErrorsAffectedRecordsApi(
  reqData: SfSyncAffectedRecordsGet
): Promise<SfPagination<SfSyncAffectedRecord[] | null>> {
  const { searchQuery } = reqData;
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sync-runs/errors/records",
    params: {
      ...reqData,
      searchQuery: searchQuery || null,
    },
  });
}

export async function resyncSalesforceSyncErrorsApi(
  reqData: SfSyncErrorsResyncArchiveReq
): Promise<SfResponse<boolean>> {
  return await sfIntegrationsRequest({
    url: "salesforce/sync-runs/errors/resync",
    params: reqData,
  });
}

export async function archiveSalesforceSyncErrorsApi(
  reqData: SfSyncErrorsResyncArchiveReq
): Promise<SfResponse<boolean>> {
  return await sfIntegrationsRequest({
    url: "salesforce/sync-runs/errors/archive",
    params: reqData,
  });
}

export async function listSfSelectiveSyncBooleanColumnsApi(
  reqParams: SalesforceColumnsGet
): Promise<SfResponse<SfColumnsOfAllSyncObjects>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sfdc-objects/selective-sync-fields",
    params: reqParams,
  });
}

export async function listAllSalesforceSyncObjectColumnsApi(
  reqParams: SalesforceColumnsGet
): Promise<SfResponse<SfColumnsOfAllSyncObjects>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sfdc-objects/describe",
    params: reqParams,
    shouldRetryApiCall: true,
  });
}

export async function startSalesforceSyncApi(
  connectionId: string
): Promise<SfResponse<SalesforceConnectionV2>> {
  return await sfIntegrationsRequest({
    url: getSfConnectionPath(`${connectionId}/start`),
  });
}

//contact apis
export async function resyncPersonFromSalesforceConnectionApi(
  internalPersonEmail: string
): Promise<SfResponse<{}>> {
  return await sfIntegrationsRequest({
    url: "salesforce/person/resync",
    params: { email: internalPersonEmail },
  });
}

export async function remapPersonFromSalesforceConnectionApi(
  reqData: SalesforcePersonRemapReq
): Promise<SfResponse<{}>> {
  return await sfIntegrationsRequest({
    method: "PUT",
    url: "salesforce/person/link",
    data: reqData,
  });
}

export async function getPersonSalesforceSyncStatusApi(
  internalPersonEmail: string
): Promise<SfResponse<SalesforcePersonSyncDetails>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/person/status",
    params: { email: internalPersonEmail },
  });
}

export async function getSfSyncRunScheduleDetailsApi(
  connectionId: string
): Promise<SfResponse<SalesforceSyncScheduleDetails>> {
  return await sfIntegrationsRequest({
    method: "GET",
    url: "salesforce/sync-runs/schedule-details",
    params: { connectionId },
  });
}
