import {
  Box,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Image,
  VStack,
  Grid,
  Icon,
  Link,
  Center,
} from "@chakra-ui/react";
import {
  FaArrowDown,
  FaArrowRight,
  FaArrowUp,
  FaExclamationCircle,
} from "react-icons/fa";
import salesforceLogo from "../../../../../../common/img/logo/salesforce.png";
import inflectionLogo from "../../../../../../common/img/logo/Inflection.jpg";
import {
  SfSyncRunDetails,
  SyncedObjSummary,
  SyncObjectSyncRunDetails,
} from "../../../../../../common/types/salesforce";

export enum SYNC_OBJ_CELL_TYPE {
  CONTACT = "Contact",
  LEAD = "Lead",
  PERSON = "Person",
  ACCOUNT = "Account",
  TOTAL_SUMMARY = "Total",
  OPPORTUNITY = "Opportunity",
}

type SyncRunObj = SfSyncRunDetails & {
  name: string;
  syncPermission: boolean;
};

const EMPTY_RUN = {
  readCount: 0,
  writeCount: 0,
  readErrorCount: 0,
  writeErrorCount: 0,
};

function InflectionImageComponent() {
  return <Image src={inflectionLogo} alt="Inflection" boxSize="15px" />;
}

function SalesforceImageContent() {
  return <Image src={salesforceLogo} alt="Salesforce" boxSize="15px" />;
}

function sumCountInArray(data: SyncRunObj[], key: keyof SfSyncRunDetails) {
  return data.map((x) => x[key]).reduce((sum, value) => sum + value, 0);
}

function getRequiredSyncDataDetails(
  historyType: SYNC_OBJ_CELL_TYPE,
  syncRun: SyncObjectSyncRunDetails,
  syncedObjSummary: SyncedObjSummary
): SyncRunObj[] {
  const syncDataArr = [
    {
      name: "Contact",
      syncPermission: syncedObjSummary.contact,
      ...(syncRun.contact ?? EMPTY_RUN),
    },
    {
      name: "Lead",
      syncPermission: syncedObjSummary.lead,
      ...(syncRun.lead ?? EMPTY_RUN),
    },
    {
      name: "Account",
      syncPermission: syncedObjSummary.account,
      ...(syncRun.account ?? EMPTY_RUN),
    },
    {
      name: "Opportunity",
      syncPermission: syncedObjSummary.opportunity,
      ...(syncRun.opportunity ?? EMPTY_RUN),
    },
  ];

  const recordSummary = {
    readCount: sumCountInArray(syncDataArr, "readCount"),
    writeCount: sumCountInArray(syncDataArr, "writeCount"),
    readErrorCount: sumCountInArray(syncDataArr, "readErrorCount"),
    writeErrorCount: sumCountInArray(syncDataArr, "writeErrorCount"),
    name: "Total Records",
    syncPermission: true,
  };

  switch (historyType) {
    case SYNC_OBJ_CELL_TYPE.CONTACT:
      return [syncDataArr[0]];
    case SYNC_OBJ_CELL_TYPE.LEAD:
      return [syncDataArr[1]];
    case SYNC_OBJ_CELL_TYPE.PERSON:
      return [syncDataArr[0], syncDataArr[1]];
    case SYNC_OBJ_CELL_TYPE.ACCOUNT:
      return [syncDataArr[2]];
    case SYNC_OBJ_CELL_TYPE.OPPORTUNITY:
      return [syncDataArr[3]];
    case SYNC_OBJ_CELL_TYPE.TOTAL_SUMMARY:
      return [recordSummary];
    default:
      return [{ ...EMPTY_RUN, name: "", syncPermission: false }];
  }
}

function SyncData({
  childrenLeft,
  childrenRight,
}: {
  childrenLeft: JSX.Element;
  childrenRight: JSX.Element;
}) {
  return (
    <Box px="20px" pt="15px" pb="10px">
      <Grid templateColumns="6fr 1fr 6fr" gap={4}>
        <VStack alignItems="flex-start" py="2">
          <HStack>
            <Box>
              <SalesforceImageContent />
            </Box>
            <FaArrowRight />
            <Box>
              <InflectionImageComponent />
            </Box>
          </HStack>
          <VStack
            fontSize="smaller"
            pt="5px"
            spacing={0}
            alignItems="flex-start"
          >
            {childrenLeft}
          </VStack>
        </VStack>
        <Center>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="100%"
          />
        </Center>
        <VStack alignItems="flex-start" py="2">
          <HStack>
            <Box>
              <InflectionImageComponent />
            </Box>
            <FaArrowRight />
            <Box>
              <SalesforceImageContent />
            </Box>
          </HStack>
          <VStack
            fontSize="smaller"
            pt="5px"
            spacing={1}
            alignItems="flex-start"
          >
            {childrenRight}
          </VStack>
        </VStack>
      </Grid>
    </Box>
  );
}

function SyncReadObjInfo({ syncData }: { syncData: SyncRunObj[] }) {
  return (
    <>
      {syncData.map((data) => {
        return (
          <Text>
            {data.name} Imported : {data.readCount}
          </Text>
        );
      })}

      <Text>Errors : {sumCountInArray(syncData, "readErrorCount")}</Text>
    </>
  );
}

function SyncWriteObjInfo({ syncData }: { syncData: SyncRunObj[] }) {
  return (
    <>
      {syncData.map((data) => {
        return (
          <Text>
            {data.name} Updated : {data.writeCount}
          </Text>
        );
      })}

      <Text>Errors : {sumCountInArray(syncData, "writeErrorCount")}</Text>
    </>
  );
}

export default function SyncRunTableCell({
  cellType,
  syncedObjectSummary,
  syncRun,
  showErrorsDuringDuration,
  currentDateTimestamp,
}: {
  cellType: SYNC_OBJ_CELL_TYPE;
  syncedObjectSummary: SyncedObjSummary;
  syncRun: SyncObjectSyncRunDetails;
  showErrorsDuringDuration: (date: Date) => void;
  currentDateTimestamp: number | string;
}) {
  const syncData = getRequiredSyncDataDetails(
    cellType,
    syncRun,
    syncedObjectSummary
  );
  const atleastOneObjectSynced = syncData.some((data) => data.syncPermission);
  const totalReads = sumCountInArray(syncData, "readCount");
  const totalWrites = sumCountInArray(syncData, "writeCount");
  const totalReadError = sumCountInArray(syncData, "readErrorCount");
  const totalWriteError = sumCountInArray(syncData, "writeErrorCount");
  const totalErrors = totalReadError + totalWriteError;

  function SyncSummaryValues() {
    return (
      <Box pt="12px">
        <HStack bg="blackAlpha.50" py={2} px={5}>
          <Icon as={FaArrowDown} color="blue.600" />
          <Text>{totalReads}</Text>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="25px"
          />
          <Icon as={FaArrowUp} color="green.400" />
          <Text>{totalWrites}</Text>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="25px"
          />
          <FaExclamationCircle color={totalErrors === 0 ? "gray" : "red"} />
          <Text>{totalErrors}</Text>
        </HStack>
      </Box>
    );
  }

  function navigateToSyncHealthTab() {
    showErrorsDuringDuration(new Date(currentDateTimestamp));
  }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <HStack py="15px" w="100%">
          <HStack wrap="wrap" w="70px" justifyContent="center">
            <Icon
              as={FaArrowDown}
              color={atleastOneObjectSynced ? "blue.600" : "gray"}
              boxSize={3}
            />

            <Text>{totalReads}</Text>
            {atleastOneObjectSynced && (
              <Link onClick={navigateToSyncHealthTab}>
                <Text color="red" as="u">
                  ({totalReadError})
                </Text>
              </Link>
            )}
          </HStack>
          <Divider
            orientation="vertical"
            borderColor="blackAlpha.400"
            height="30px"
          />
          <HStack wrap="wrap" w="70px" justifyContent="center">
            <Icon
              as={FaArrowUp}
              color={atleastOneObjectSynced ? "green.400" : "gray"}
              boxSize={3}
            />
            <Text>{totalWrites}</Text>

            {atleastOneObjectSynced && (
              <Link onClick={navigateToSyncHealthTab}>
                <Text color="red" as="u">
                  ({totalWriteError})
                </Text>
              </Link>
            )}
          </HStack>
        </HStack>
      </PopoverTrigger>
      {atleastOneObjectSynced && (
        <PopoverContent width="400px">
          <PopoverBody>
            <Box pt="5px">
              <Box pt="10px" pl="25px">
                <Text>{cellType} Sync History</Text>
              </Box>
              <SyncSummaryValues />
              <>
                <SyncData
                  childrenLeft={<SyncReadObjInfo syncData={syncData} />}
                  childrenRight={<SyncWriteObjInfo syncData={syncData} />}
                />
              </>
            </Box>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
}
