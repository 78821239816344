import { HStack, Circle, Box, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import IModal from "../../../../components/IModal";
import {
  addSuffixForPlural,
  isLoading,
  isUploadContactsToSl,
} from "../../../../common/helper/commonHelper";
import { useSelector } from "react-redux";
import { selectContactUpload } from "../contactUploadSlice";
import { UPLOAD_CONTACTS_TO } from "../../../../common/types/contactUpload";
import { selectStaticList } from "../../staticlist/staticlistSlice";

function ContactsSummary({
  numContacts,
  text,
}: {
  numContacts: number;
  text: string | ReactNode;
}) {
  return (
    <HStack my={1} alignItems="center">
      <Circle size="6px" bg="gray.700" mt={1} />
      <Text>
        <Text as="span" fontWeight="600" color="gray.700">
          {numContacts}{" "}
        </Text>
        {text}
      </Text>
    </HStack>
  );
}

export default function ConfirmImportModal({
  isOpen,
  uploadTo,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  uploadTo: UPLOAD_CONTACTS_TO;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const {
    fileUpload: { fileName },
    contactImportCsv: {
      loading: importLoading,
      data: { newContacts, updatableContacts, staticList },
    },
  } = useSelector(selectContactUpload);
  const {
    staticListDetails: { data: staticListDetails },
  } = useSelector(selectStaticList);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      header={{
        title: "Confirm Import",
        capitalize: true,
      }}
      primaryButton={{
        label: "Import Contacts",
        props: { onClick: onConfirm, isLoading: isLoading(importLoading) },
      }}
      secondaryButton={{
        label: "Back",
        props: { onClick: onClose, isLoading: isLoading(importLoading) },
      }}
      size="lg"
    >
      <Box fontSize="14px">
        <Text>
          Are you sure you would like to
          {isUploadContactsToSl(uploadTo)
            ? ` update "${staticListDetails?.name}" `
            : ` import contacts `}
          from {fileName}?
        </Text>

        <Box pt={2}>
          <Text my={2}>This import consists of</Text>
          <Box px={3}>
            <ContactsSummary
              numContacts={newContacts}
              text={`New  ${addSuffixForPlural(
                "contact",
                newContacts
              )} will be created`}
            />
            <ContactsSummary
              numContacts={updatableContacts}
              text={`Existing ${addSuffixForPlural(
                "contact",
                updatableContacts
              )} will have data updated`}
            />
            {staticList?.name && !isUploadContactsToSl(uploadTo) && (
              <ContactsSummary
                numContacts={staticList.insertCount}
                text={
                  <>
                    {`${addSuffixForPlural(
                      "Contact",
                      staticList.insertCount
                    )} will be added to `}
                    <Text as="span" fontWeight="semibold">
                      {staticList?.name}
                    </Text>
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    </IModal>
  );
}
