import { useRef, useCallback, useMemo } from "react";
import {
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import { ConfirmationModal } from "./ConfirmationModal";
import { Text } from "@chakra-ui/react";

//Modal to prevent the user form navigating from one page to another when they have unsaved changes.
//used in tokens and templates.

const BLOCKED_STATE = "blocked";

export function PreventNavigationModal({
  isActive,
  title = "Are you sure you want to leave this page?",
  cancelText = "Continue Editing",
  confirmText = "Discard Changes",
  children,
  onCancel, // stay on the same
  onConfirm, //leave the page
}: {
  isActive: boolean;
  title?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  children?: any;
}) {
  const shouldBlockNavigation = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isActive && currentLocation.pathname !== nextLocation.pathname,
    [isActive]
  );
  const blocker = useBlocker(shouldBlockNavigation);

  const isPromptOpen = useMemo(
    () => blocker.state === BLOCKED_STATE,
    [blocker.state]
  );

  const cancelRef = useRef(null);

  const handleCancel = useCallback(() => {
    if (blocker.state === BLOCKED_STATE) {
      blocker.reset();
    }
    onCancel?.();
  }, [onCancel, blocker]);

  const handleConfirm = useCallback(() => {
    if (blocker.state === BLOCKED_STATE) {
      blocker.proceed();
    }
    onConfirm?.();
  }, [onConfirm, blocker]);

  return (
    <>
      {isPromptOpen ? (
        <ConfirmationModal
          isOpen={isPromptOpen}
          cancelButtonText={cancelText}
          onCancel={handleCancel}
          confirmButtonText={confirmText}
          submitHandler={handleConfirm}
          cancelRef={cancelRef}
          title={title}
        >
          {children || (
            <Text>
              You have unsaved changes which will be discarded on leaving this
              page!
            </Text>
          )}
        </ConfirmationModal>
      ) : null}
    </>
  );
}
