import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
  Image,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { listPerson, selectPerson, resetPersonList } from "./personDbSlice";
import { Person } from "../../../common/types/person";
import urls from "../../../urls";
import { FaChevronDown } from "react-icons/fa";
import CreatePersonModal from "./components/CreatePersonModal";
import { CommonListHeader } from "../../../components/CommonListHeader";
import { useAppDispatch } from "../../../store";
import { DataTable } from "../../../components/data-table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import LayoutWithTable from "../../../layout/LayoutWithTable";
import { FormatDate } from "../../../components/DateTimeRangeFilter";
import { DisplayCount } from "../../../components/NumberFormatHeader";
import { usePaginatedData } from "../../../common/hooks/commonHooks";
import ContactUploaderCsv from "../contactUpload/ContactUploaderCsv";
import { UPLOAD_CONTACTS_TO } from "../../../common/types/contactUpload";
import {
  addSuffixForPlural,
  getTableRowLinkProps,
} from "../../../common/helper/commonHelper";
import InitialEmptyState from "../../../components/InitialEmptyState";
import ContactsEmptyState from "../../../common/img/emptyStateLogos/contacts.svg";
import { PaginationFilterParams } from "../../../common/types/common";
import { TABLE_FILTER_VARIANTS } from "../../../common/constants/common";
import { getHeightOfFilter } from "../../../common/helper/filterHelper";

enum ACTIONS {
  CREATE = "create",
  IMPORT = "import",
}

function ActionsMenu({ onClick }: { onClick: (action: ACTIONS) => void }) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme="blue"
        rightIcon={<FaChevronDown />}
        px="16px"
        py="4px"
      >
        Actions
      </MenuButton>
      <MenuList zIndex="3">
        <MenuItem onClick={() => onClick(ACTIONS.CREATE)}>
          Create Contact
        </MenuItem>
        <MenuItem onClick={() => onClick(ACTIONS.IMPORT)}>
          Import Contacts
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

const COLUMNS_TO_SEARCH_IN = ["email", "first_name", "last_name"];

export default function Persons() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isOpen: isCsvModalOpen,
    onClose: onCsvModalClose,
    onOpen: onCsvModalOpen,
  } = useDisclosure();

  const {
    isOpen: isCreateModalOpen,
    onClose: onCreateModalClose,
    onOpen: onCreateModalOpen,
  } = useDisclosure();

  const { person } = useSelector(selectPerson);

  const fetchContactsList = useCallback(
    ({ pageNo, searchKeyword = "", filters }: PaginationFilterParams) => {
      dispatch(
        listPerson({
          pageNo,
          searchKeyword,
          columnsToSearchIn: COLUMNS_TO_SEARCH_IN,
          filters,
        })
      );
    },
    [dispatch]
  );

  const {
    fetchingList,
    isFiltersApplied,
    searchKeyword,
    filters,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  } = usePaginatedData({
    fetchList: fetchContactsList,
    fetchingList: person.fetchingList,
  });

  const columnHelper = createColumnHelper<Person>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: "Email",
        size: 300,
      }),
      columnHelper.accessor("first_name", {
        header: "First Name",
        size: 250,
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        size: 250,
      }),
      columnHelper.accessor("title", {
        header: "Title",
        cell: (info) => (
          <Text whiteSpace="pre-line" wordBreak="break-word">
            {info.getValue()}
          </Text>
        ),
        size: 250,
      }),
      columnHelper.accessor("company_name", {
        header: "Company",
        size: 250,
      }),
      columnHelper.accessor("phone_number", {
        header: "Phone",
        size: 200,
      }),
      columnHelper.accessor("person_created_time", {
        header: "Created Date",
        cell: (info) => <FormatDate date={info.getValue()} />,
        size: 200,
      }),
    ],
    [columnHelper]
  );

  const openPerson = useCallback(
    (person: Person) => {
      navigate(`${urls.person}/${person.id}`);
    },
    [navigate]
  );

  useEffect(() => {
    return () => {
      dispatch(resetPersonList());
    };
  }, [dispatch]);

  function actionsClickHandler(action: ACTIONS) {
    switch (action) {
      case ACTIONS.CREATE:
        onCreateModalOpen();
        break;
      case ACTIONS.IMPORT:
        onCsvModalOpen();
        break;
    }
  }

  function onImportContactsViaCsv() {
    setTimeout(() => {
      handlePageChange(1);
    }, 500);
  }

  const isInitEmpty = !(person.totalPageCount || isFiltersApplied);

  return (
    <>
      <CommonListHeader
        heading="Contacts"
        searchInputProps={{
          placeholder: "Search contacts",
          name: "search-input",
          onSearch: handleSearchChange,
          defaultValue: searchKeyword,
          hidden: isInitEmpty,
        }}
        filterProps={{
          filters: filters,
          handleFilterChange: handleFilterChange,
          variant: TABLE_FILTER_VARIANTS.CONTACTS,
          hidden: isInitEmpty,
        }}
        componentAfterHeader={
          <DisplayCount
            count={person.count || null}
            countLabel={addSuffixForPlural("contact", person.count)}
          />
        }
        ctaMenu={<ActionsMenu onClick={actionsClickHandler} />}
      />
      {!isInitEmpty || fetchingList ? (
        <LayoutWithTable reduceHeightBy={getHeightOfFilter(filters)}>
          <DataTable
            fetchingList={fetchingList}
            changingPage={person.changingPage}
            list={person.list}
            totalPageSize={person.pageSize}
            totalPageCount={person.totalPageCount}
            currentPage={person.currentPageNo}
            onRowClick={openPerson}
            setPage={handlePageChange}
            columns={columns}
            emptyMsg={`No contacts found.${
              isFiltersApplied
                ? " Please change the search / filter values"
                : ""
            }`}
            getTableRowLinkProps={(data) =>
              getTableRowLinkProps({ to: urls.person, editParam: "id" }, data)
            }
          />
        </LayoutWithTable>
      ) : (
        <InitialEmptyState
          mainText="Add contacts to craft personalized and impactful campaigns"
          message="No contacts"
          additionalActions={<ActionsMenu onClick={actionsClickHandler} />}
        >
          <Image src={ContactsEmptyState} alt="Contacts" />
        </InitialEmptyState>
      )}

      <CreatePersonModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
      />
      <ContactUploaderCsv
        isOpen={isCsvModalOpen}
        onImport={onImportContactsViaCsv}
        onClose={onCsvModalClose}
        uploadTo={UPLOAD_CONTACTS_TO.PERSON_DB}
      />
    </>
  );
}
