import { IoMdSend } from "react-icons/io";
import { Text } from "@chakra-ui/react";
import IModal from "../../../../components/IModal";
import InputFieldWithError from "../../../../components/InputFieldWithError";
import { useEffect, useState } from "react";
import ValidationMessage from "../../../../components/ValidationMessage";
import { validateEmail } from "../../../../common/helper/commonHelper";
import { useAppDispatch } from "../../../../store";
import { sendEmailToDev } from "../settingsSlice";

export default function SendToDevCta({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpen) {
      setEmail("");
      setError("");
      setShowError(false);
    }
  }, [isOpen]);

  function onSend() {
    setShowError(true);
    setAndValidateEmail(email);
    if (!error && !!email) {
      dispatch(sendEmailToDev(email));
      onClose();
    }
  }

  function setAndValidateEmail(email: string) {
    setEmail(email);
    validateEmail(email) ? setError("") : setError("Invalid email");
  }

  const errorCheck = !!error && showError;

  return (
    <IModal
      header={{ title: "Send to developer" }}
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        label: "Send",
        props: {
          rightIcon: <IoMdSend />,
          isDisabled: errorCheck,
          onClick: onSend,
        },
      }}
    >
      <Text fontSize="14px">Enter your developer's email ID</Text>
      <InputFieldWithError
        errorMsg={errorCheck ? <ValidationMessage error={error} /> : null}
        placeholder="example@email.com"
        value={email}
        onChange={(e) => setAndValidateEmail(e.target.value)}
      />
      <Text fontSize="14px">
        Send the tracking code by email to your developer to embed in your
        website.
      </Text>
    </IModal>
  );
}
