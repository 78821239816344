import { Flex, FlexProps, ButtonProps, Badge } from "@chakra-ui/react";
import { LOGICAL_OPERATORS } from "../../../../../common/constants/dynamicList";
import IButton from "../../../../../components/IButton";
import React from "react";

interface PropTypes extends FlexProps {
  hideLines?: boolean;
  operator: LOGICAL_OPERATORS;
  handleChange: (op: LOGICAL_OPERATORS) => void;
  isReadOnly?: boolean;
}

function ButtonAnd({
  onClick,
  active,
  ...sx
}: { onClick: () => void; active?: boolean } & ButtonProps) {
  return (
    <IButton
      size="xs"
      rounded="2xl"
      width="40px"
      name="logic-gate-and-button"
      bg={active ? "blue.500" : undefined}
      colorScheme={active ? "blue" : undefined}
      variant={!active ? "ghost" : undefined}
      onClick={onClick}
      capitalize={false}
      {...sx}
    >
      {active ? "and.." : "and"}
    </IButton>
  );
}

function ButtonOr({
  onClick,
  active,
  ...sx
}: { onClick: () => void; active?: boolean } & ButtonProps) {
  return (
    <IButton
      size="xs"
      rounded="2xl"
      width="30px"
      name="logic-gate-or-button"
      bg={active ? "green.300" : undefined}
      colorScheme={active ? "green" : undefined}
      variant={!active ? "ghost" : undefined}
      onClick={onClick}
      capitalize={false}
      {...sx}
    >
      {active ? "or.." : "or"}
    </IButton>
  );
}

const LogicGate = React.memo((props: PropTypes) => {
  const { hideLines, operator, handleChange, isReadOnly, ...rest } = props;
  const isFirst = operator === LOGICAL_OPERATORS.FIRST;
  const isAnd = operator === LOGICAL_OPERATORS.AND;

  if (isReadOnly) {
    return (
      <>
        {!isFirst && (
          <Badge
            variant="solid"
            fontSize="10px"
            height="fit-content"
            colorScheme={isAnd ? "blue" : "green"}
          >
            {isAnd ? "And" : "Or"}
          </Badge>
        )}
      </>
    );
  } else {
    return (
      <>
        {!isFirst && (
          <Flex
            h="35px"
            alignItems="center"
            justifyContent="flex-start"
            mb={2}
            {...rest}
          >
            <Flex rounded="2xl" border="1px" borderColor="gray.300">
              <ButtonAnd
                active={isAnd}
                onClick={() => handleChange(LOGICAL_OPERATORS.AND)}
              />
              <ButtonOr
                onClick={() => handleChange(LOGICAL_OPERATORS.OR)}
                active={!isAnd}
              />
            </Flex>
          </Flex>
        )}
      </>
    );
  }
});

export default LogicGate;
