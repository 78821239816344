import {
  HStack,
  IconButton,
  Text,
  StackProps,
  IconButtonProps,
} from "@chakra-ui/react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

type NavigationButtonType = Partial<IconButtonProps>;

export default function PageNavigationButtons({
  pageNo,
  totalPageCount,
  forwardButtonProps,
  backwardButtonProps,
  ...containerProps
}: {
  pageNo: number;
  totalPageCount: number;
  forwardButtonProps?: NavigationButtonType;
  backwardButtonProps?: NavigationButtonType;
} & StackProps) {
  return (
    <HStack justifyContent="space-around" {...containerProps}>
      <IconButton
        bg="transparent !important"
        isDisabled={pageNo === 1}
        aria-label="left-icon"
        variant="ghost"
        icon={<FaCaretLeft fontSize="14px" />}
        size="xs"
        {...backwardButtonProps}
      />
      <Text>
        {pageNo}/{totalPageCount}
      </Text>
      <IconButton
        bg="transparent !important"
        isDisabled={pageNo === totalPageCount}
        aria-label="Right-forward-icon"
        variant="ghost"
        icon={<FaCaretRight fontSize="14px" />}
        size="xs"
        {...forwardButtonProps}
      />
    </HStack>
  );
}
