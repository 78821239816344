import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItem,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import {
  NODE_GROUP_TYPES,
  TRIGGER_DL_NODE_TYPES,
  EVENT_CATEGORY,
} from "../../../../../common/constants/dynamicList";
import React, { useMemo } from "react";
import { cloneDeep } from "lodash";
import { CAMPAIGN_CONTEXT } from "../../../../../common/types/campaign";

const NODE_GROUP_MENU: {
  label: string;
  options: {
    label: string;
    nodeGroupType: NODE_GROUP_TYPES;
    nodeType?: TRIGGER_DL_NODE_TYPES;
    eventCategory?: EVENT_CATEGORY;
  }[];
}[] = [
  {
    label: "Contact properties",
    options: [
      {
        label: "A person property",
        nodeType: TRIGGER_DL_NODE_TYPES.PERSON,
        nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
      },
      {
        label: "Performed a product event",
        nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
        nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
        eventCategory: EVENT_CATEGORY.PRODUCT_ACTIVITY,
      },
      {
        label: "Performed a marketing activity",
        nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
        nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
        eventCategory: EVENT_CATEGORY.MARKETING_ACTIVITY,
      },
      {
        label: "List membership",
        nodeType: TRIGGER_DL_NODE_TYPES.LIST_MEMBERSHIP,
        nodeGroupType: NODE_GROUP_TYPES.INDIVIDUAL,
      },
    ],
  },
  {
    label: "Grouped properties",
    options: [
      {
        label: "Organization members",
        nodeGroupType: NODE_GROUP_TYPES.ORGANISATION,
      },

      {
        label: "Account members",
        nodeGroupType: NODE_GROUP_TYPES.ACCOUNT,
      },
    ],
  },
];

const AddNodeGroupButton = React.memo(
  ({
    onAddNodeGroup,
    onAddNode,
    campaignContext,
  }: {
    onAddNodeGroup?: (
      nodeGroupType: NODE_GROUP_TYPES,
      nodeType?: TRIGGER_DL_NODE_TYPES,
      eventCategory?: EVENT_CATEGORY
    ) => void;
    onAddNode?: (
      nodeType: TRIGGER_DL_NODE_TYPES,
      eventCategory?: EVENT_CATEGORY
    ) => void;
    campaignContext?: CAMPAIGN_CONTEXT;
  }) => {
    const filteredNodeGroupMenu = useMemo(
      () =>
        cloneDeep(NODE_GROUP_MENU).map((menu) => {
          menu.options = menu.options.filter((option) => {
            if (option.nodeGroupType === NODE_GROUP_TYPES.ORGANISATION) {
              return campaignContext === CAMPAIGN_CONTEXT.ORG;
            } else if (option.nodeGroupType === NODE_GROUP_TYPES.ACCOUNT) {
              return campaignContext === CAMPAIGN_CONTEXT.PERSON;
            }

            return true;
          });
          return menu;
        }),
      [campaignContext]
    );
    return (
      <Menu>
        <MenuButton
          size={"sm"}
          as={Button}
          bg="grayV2.100"
          color="brand.blue"
          borderColor="brand.blue"
          borderWidth="1px"
          _hover={{ bg: "grayV2.200" }}
          _active={{ bg: "grayV2.200" }}
          leftIcon={<FaPlus color="brand.blue" fontSize="10px" />}
        >
          Add condition
        </MenuButton>
        <MenuList>
          {Object.values(filteredNodeGroupMenu).map((menu, index) => {
            return (
              !!menu.options.length && (
                <MenuOptionGroup title={menu.label} key={index}>
                  {menu.options.map((option, key) => {
                    return (
                      <MenuItem
                        fontSize="14px"
                        onClick={() => {
                          onAddNodeGroup?.(
                            option.nodeGroupType,
                            option.nodeType,
                            option.eventCategory
                          );
                          option?.nodeType &&
                            onAddNode?.(option.nodeType, option.eventCategory);
                        }}
                        name={`add-${option.nodeGroupType}`}
                        key={key}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </MenuOptionGroup>
              )
            );
          })}
        </MenuList>
      </Menu>
    );
  }
);

export default AddNodeGroupButton;
