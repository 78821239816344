import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Button,
  Icon,
} from "@chakra-ui/react";
import { FaDesktop, FaEnvelope, FaRegIdCard } from "react-icons/fa";
import {
  EVENT_CATEGORY,
  TRIGGER_DL_NODE_TYPES,
} from "../../../../../common/constants/dynamicList";
import { TiDocumentText } from "react-icons/ti";

interface MenuItemType {
  name: string;
  event?: EVENT_CATEGORY;
  nodeType: TRIGGER_DL_NODE_TYPES;
  icon: any;
}

interface FilteredItemsType {
  [key: string]: MenuItemType[];
}

function AddTriggerEvents({
  isNodeAdded,
  onClick,
}: {
  isNodeAdded: boolean;
  onClick: (nodeType: TRIGGER_DL_NODE_TYPES, event?: EVENT_CATEGORY) => void;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchTerm, setSearchTerm] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null); // Ref for the input element

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  // };

  const initialItems = useMemo(() => {
    return {
      "Single Events": [
        {
          name: "Product Event",
          event: EVENT_CATEGORY.PRODUCT_ACTIVITY,
          nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
          icon: FaDesktop,
        },
        {
          name: "Marketing Event",
          event: EVENT_CATEGORY.MARKETING_ACTIVITY,
          nodeType: TRIGGER_DL_NODE_TYPES.COMPARISON,
          icon: FaEnvelope,
        },
        {
          name: "Property Change",
          event: undefined,
          nodeType: TRIGGER_DL_NODE_TYPES.PERSON,
          icon: FaRegIdCard,
        },
      ],
      "Sequential Events": [
        {
          name: "Sequence of Product Events",
          event: EVENT_CATEGORY.PRODUCT_ACTIVITY,
          nodeType: TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN,
          icon: FaDesktop,
        },
        {
          name: "Sequence of Marketing Activity",
          event: EVENT_CATEGORY.MARKETING_ACTIVITY,
          nodeType: TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN,
          icon: FaEnvelope,
        },
      ],
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const visibleItems = useMemo(() => {
    return Object.entries(initialItems).reduce((acc, [group, items]) => {
      const filtered = items.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filtered.length > 0) {
        acc[group] = filtered;
      }
      return acc;
    }, {} as FilteredItemsType);
  }, [searchTerm, initialItems]);

  return (
    <Menu placement={!isNodeAdded ? "bottom" : "bottom-start"}>
      <MenuButton
        as={Button}
        bg="grayV2.100"
        color="brand.blue"
        borderColor="brand.blue"
        borderWidth="1px"
        _hover={{ bg: "grayV2.200" }}
        _active={{ bg: "grayV2.200" }}
        leftIcon={<TiDocumentText color="brand.blue" fontSize="14px" />}
      >
        {isNodeAdded ? "Add another trigger" : "Add trigger"}
      </MenuButton>
      <MenuList>
        {/* <Box px={4} py={2}>
          <Input
            ref={inputRef}
            placeholder="Search Event Categories"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box> */}
        {Object.entries(visibleItems).map(([group, items]) => (
          <MenuGroup
            color="brandBlue.500"
            textAlign={"left"}
            key={group}
            title={group}
            fontSize="12px"
            fontWeight="bold"
          >
            {items.map((filteredItem) => (
              <MenuItem
                fontSize="14px"
                textAlign={"left"}
                key={filteredItem.name}
                onClick={() =>
                  onClick(filteredItem.nodeType, filteredItem.event)
                }
              >
                <Icon
                  color="brandBlue.500"
                  as={filteredItem.icon}
                  marginRight={2}
                ></Icon>
                {filteredItem.name}
              </MenuItem>
            ))}
          </MenuGroup>
        ))}
      </MenuList>
    </Menu>
  );
}

export default AddTriggerEvents;
