import {
  Box,
  Checkbox,
  CheckboxProps,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { cloneDeep, set } from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { isLoading } from "../../../../../common/helper/commonHelper";
import { SegmentDestinationSettings } from "../../../../../common/types/connection";
import SpinnerContainer from "../../../../../components/SpinnerContainer";
import { selectConnection } from "../../connectionSlice";

type CustomCheckBoxType = {
  label: string;
  name: string;
  isChecked?: boolean;
  type?: "inside" | "normal";
} & CheckboxProps;

export default function SegmentDestinationDetails({
  isReadOnly = false,
  destinationSettings,
  setDestinationSettings,
}: {
  isReadOnly: boolean;
  destinationSettings: SegmentDestinationSettings;
  setDestinationSettings: Dispatch<SetStateAction<SegmentDestinationSettings>>;
}) {
  const {
    segment: {
      syncDetails: { loading: segmentSyncDetailsLoading },
    },
  } = useSelector(selectConnection);

  const destinationCheckBoxes: CustomCheckBoxType[] = useMemo(() => {
    return [
      {
        label: "Email Delivered",
        name: "sync_email_delivered",
        isChecked: destinationSettings.sync_email_delivered,
        w: "150px",
      },
      {
        label: "Email Opened",
        name: "sync_email_opened.is_enabled",
        isChecked: destinationSettings.sync_email_opened?.is_enabled,
      },
      {
        type: "inside",
        label: "Send Unique opens only",
        name: "sync_email_opened.unique_opens_only",
        isChecked: destinationSettings.sync_email_opened?.unique_opens_only,
        isDisabled:
          isReadOnly || !destinationSettings.sync_email_opened?.is_enabled,
      },
      {
        label: "Email Bounced",
        name: "sync_email_bounced",
        isChecked: destinationSettings.sync_email_bounced,
      },
      {
        label: "Email Reported as Spam",
        name: "sync_email_spam",
        isChecked: destinationSettings.sync_email_spam,
        w: "180px",
      },
      {
        label: "Email Clicked",
        name: "sync_email_link_click.is_enabled",
        isChecked: destinationSettings.sync_email_link_click?.is_enabled,
      },
      {
        type: "inside",
        label: "Send Unique clicks only",
        name: "sync_email_link_click.unique_clicks_only",
        isChecked:
          destinationSettings.sync_email_link_click?.unique_clicks_only,
        isDisabled:
          isReadOnly || !destinationSettings.sync_email_link_click?.is_enabled,
      },
      {
        label: "Email Unsubscribes",
        name: "sync_email_unsubscribed",
        isChecked: destinationSettings.sync_email_unsubscribed,
        w: "160px",
      },
    ];
  }, [destinationSettings, isReadOnly]);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDestinationSettings((prev) => {
      let clonePrev = cloneDeep(prev);
      set(clonePrev, e.target.name, e.target.checked);
      return clonePrev;
    });
  }

  function CustomCheckBox({
    type = "normal",
    label,
    name,
    isChecked,
    ...props
  }: CustomCheckBoxType) {
    const checkProps =
      type === "normal"
        ? undefined
        : {
            borderLeft: "1px solid",
            borderColor: "gray.200",
            pl: 5,
            w: "200px",
          };

    return (
      <Checkbox
        size="sm"
        py={1}
        w="120px"
        {...checkProps}
        name={name}
        isChecked={isChecked ?? false}
        isDisabled={isReadOnly}
        onChange={onChange}
        {...props}
      >
        {label}
      </Checkbox>
    );
  }

  useEffect(() => {
    if (!destinationSettings.sync_email_opened?.is_enabled) {
      setDestinationSettings((prev) => {
        return {
          ...prev,
          sync_email_opened: {
            is_enabled: false,
            unique_opens_only: false,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationSettings.sync_email_opened?.is_enabled]);

  useEffect(() => {
    if (!destinationSettings.sync_email_link_click?.is_enabled) {
      setDestinationSettings((prev) => {
        return {
          ...prev,
          sync_email_link_click: {
            is_enabled: false,
            unique_clicks_only: false,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationSettings.sync_email_link_click?.is_enabled]);

  return (
    <Box py={2}>
      <SpinnerContainer loading={isLoading(segmentSyncDetailsLoading)}>
        <Heading fontSize="16px">Email Deliverability Events</Heading>
        <Text fontSize="14px" color="gray.500" my={3}>
          Select the Inflection events to send back to Segment.
        </Text>

        <Stack spacing={4} fontSize="12px" color="blue.800" py={3}>
          {destinationCheckBoxes.map((checkBoxProps) => {
            return <CustomCheckBox {...checkBoxProps} />;
          })}
        </Stack>
      </SpinnerContainer>
    </Box>
  );
}
