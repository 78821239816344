import { AxiosRequestConfig } from "axios";
import {
  CAMPAIGN_BASE_URL_V1,
  CAMPAIGN_BASE_URL_V2,
  CAMPAIGN_BASE_URL_V3,
} from "../../constants";
import { RequestParams } from "../../types/common";
import { inflectionRequest } from "../api";

const baseUrls = {
  1: CAMPAIGN_BASE_URL_V1,
  2: CAMPAIGN_BASE_URL_V2,
  3: CAMPAIGN_BASE_URL_V3,
};

type RequestDataType = RequestParams<typeof baseUrls> & AxiosRequestConfig;

export async function campaignRequest<T>(data: RequestDataType): Promise<T> {
  return await inflectionRequest({
    ...data,
    baseUrl: baseUrls[data.version || 1],
  });
}

export async function campaignRequestV2<T>(data: RequestDataType): Promise<T> {
  return await campaignRequest({
    ...data,
    version: 2,
  });
}
