import { Box, Flex, useDisclosure, VStack } from "@chakra-ui/react";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import EditableHeading from "../../../components/EditableHeading";
import SideBar from "../../../components/SideBar";
import ContentContainerWithHeader from "../../../components/v2/ContentContainerWithHeader";
import urls from "../../../urls";
import SubHeader from "../../../components/SubHeader";
import FormSubmission from "./FormSubmission";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import {
  getFormDetails,
  resetFormData,
  selectForm,
  updateFormDetails,
} from "./formSlice";
import { useSelector } from "react-redux";
import EditNameModal, {
  ASSET_NAME_ACTIONS,
} from "../../../components/EditNameModal";
import { FORM_VENDORS } from "../../../common/types/form";
import { isFulfilled, isLoading } from "../../../common/helper/commonHelper";
import { toast } from "react-toastify";
import FormMapping from "./FormMapping";
import FormErrors from "./FormErrors";
import FormDetails from "./FormDetails";
const FORM_MAPPING = "Form mapping";
const SIDEBAR_ITEMS = ["Form details", FORM_MAPPING, "Submissions", "Errors"];

export default function Form() {
  const [selectedOption, setSelectedOption] = useState(0);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { formDetails } = useSelector(selectForm);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formName, setFormName] = useState("");

  const tabs = useMemo(() => {
    return {
      [SIDEBAR_ITEMS[0]]: <FormDetails />,
      [SIDEBAR_ITEMS[1]]: <FormMapping />,
      [SIDEBAR_ITEMS[2]]: (
        <FormSubmission
          formId={formDetails.data.form_id}
          vendor={formDetails.data.vendor}
        />
      ),
      [SIDEBAR_ITEMS[3]]: <FormErrors />,
    };
  }, [formDetails.data]);

  useEffect(() => {
    if (id) dispatch(getFormDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setFormName(formDetails.data.name);
  }, [formDetails.data.name]);

  useEffect(() => {
    return () => {
      dispatch(resetFormData());
    };
  }, [dispatch]);

  async function renameFormHandler(name: string) {
    onClose();
    const updateNameResponse = await dispatch(
      updateFormDetails({ formId: formDetails.data.form_id, name })
    );
    if (isFulfilled(updateNameResponse.meta.requestStatus)) {
      toast.success("Form name updated successfully!");
      setFormName(name);
    }
  }

  return (
    <Box w="100%">
      <SubHeader
        heading={
          <EditableHeading
            name={formName}
            openModal={onOpen}
            isLoading={isLoading(formDetails.loading)}
            disableEditTooltip={
              formDetails.data.vendor === FORM_VENDORS.WEBFLOW
                ? "Webflow form names can't be edited"
                : ""
            }
          />
        }
        returnUrl={urls.form}
      />
      <Flex w="100%" h="calc(100vh - 50px)">
        <VStack width="380px" spacing="0" alignItems="flex-start" px="20px">
          <SideBar
            options={SIDEBAR_ITEMS}
            selected={selectedOption}
            select={setSelectedOption}
          />
        </VStack>
        <ContentContainerWithHeader height="100%">
          {get(tabs, SIDEBAR_ITEMS[selectedOption])}
        </ContentContainerWithHeader>
      </Flex>
      <EditNameModal
        value={formName}
        action={ASSET_NAME_ACTIONS.RENAME}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={renameFormHandler}
        asset="form"
      />
    </Box>
  );
}
