import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CAMPAIGN_CONTEXT,
  EmailTokenColumn,
  EmailTokenDetails,
  EmailTokenFunctions,
  EmailTokenPreviewData,
  EmailTokenUpdate,
} from "../../../common/types/campaign";
import { PERSON_ORG_MAPPING } from "../../../common/types/person";
import {
  createEmailTokenApi,
  getEmailTokenApi,
  getEmailTokenColumnsApi,
  getEmailTokenFunctionsApi,
  getEmailTokenListAllApi,
  getEmailTokenListApi,
  getEmailTokenPreviewApi,
  removeEmailTokenApi,
  updateEmailTokenApi,
  verifyTokenApi,
} from "../../../common/api/campaign/tokens";
import { RootState } from "../../../store";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import { toast } from "react-toastify";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
  isLoading,
} from "../../../common/helper/commonHelper";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetWithPage,
} from "../../../common/types/common";
import { personLookupApi } from "../../../common/api/campaign/person";
import { PreviewPerson } from "../../../common/types/token";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";

const TOKEN_LIST_ALL_ACTION = "email-token/list-all";
const FUNCTION_LIST_ALL_ACTION = "email-token/get-all-functions";
const COLUMN_LIST_ALL_ACTION = "email-token/get-all-columns";

interface EmailTokenState {
  emailTokenList: {
    list: PaginationType<EmailTokenDetails>;
    listAll: LoadingWithData<EmailTokenDetails[]>;
    creatingEmailToken: LOADING_STATES;
    createdEmailTokenId: string;
    deletingToken: LOADING_STATES;
  };

  emailTokenEdit: {
    tokenDetails: EmailTokenDetails | null;
    fetchingTokenDetails: LOADING_STATES;
    updatingTokenDetails: LOADING_STATES;
    isTokenDefinitionDirty: boolean;
    isTokenHeaderDirty: boolean;
  };

  emailTokenPreview: {
    personIds: LoadingWithData<{ [email: string]: PreviewPerson[] }>;
    preview: EmailTokenPreviewData[];
    fetchingPreview: LOADING_STATES;
  };

  allColumnsList: LoadingWithData<EmailTokenColumn[]>;

  allFunctionsList: LoadingWithData<EmailTokenFunctions>;
}

const initialState: EmailTokenState = {
  emailTokenList: {
    list: INITIAL_PAGINATION,
    listAll: initializeLoadingData([]),
    creatingEmailToken: LOADING_STATES.INIT,
    createdEmailTokenId: "",
    deletingToken: LOADING_STATES.INIT,
  },
  emailTokenEdit: {
    tokenDetails: null,
    fetchingTokenDetails: LOADING_STATES.INIT,
    updatingTokenDetails: LOADING_STATES.INIT,
    isTokenDefinitionDirty: false,
    isTokenHeaderDirty: false,
  },

  emailTokenPreview: {
    personIds: initializeLoadingData({}),
    fetchingPreview: LOADING_STATES.INIT,
    preview: [],
  },

  allColumnsList: initializeLoadingData([]),
  allFunctionsList: initializeLoadingData({}),
};

export const listEmailTokens = createAsyncThunk(
  "email-token/list",
  async (
    { pageNo, searchKeyword, columnsToSearchIn, filters }: SearchAssetWithPage,
    thunkApi
  ) => {
    const {
      emailToken: { emailTokenList },
    } = thunkApi.getState() as RootState;
    return await getEmailTokenListApi({
      pageSize: emailTokenList.list.pageSize,
      pageNo,
      searchKeyword,
      columnsToSearchIn,
      filters,
    });
  },
  {
    condition: (_, { getState }) => {
      const {
        emailToken: {
          emailTokenList: {
            list: { fetchingList },
          },
        },
      } = getState() as RootState;
      if (fetchingList) {
        return false;
      }
    },
  }
);

export const listAllEmailTokens = createAsyncThunkWrapper({
  actionName: TOKEN_LIST_ALL_ACTION,
  dispatchFn: async () => {
    return await getEmailTokenListAllApi();
  },
  isCachable: true,
});

export const getEmailToken = createAsyncThunk(
  "email-token/get",
  async (tokenId: string) => {
    return await getEmailTokenApi(tokenId);
  }
);

export const createEmailToken = createAsyncThunk(
  "email-token/create",
  async (tokenName: string, { dispatch }) => {
    const response = await createEmailTokenApi(tokenName);
    dispatch(expireApiStatus([{ actionName: TOKEN_LIST_ALL_ACTION }]));
    return response;
  }
);

export const updateEmailToken = createAsyncThunk(
  "email-token/update",
  async (token: EmailTokenUpdate, { dispatch }) => {
    const response = await updateEmailTokenApi(token);
    dispatch(expireApiStatus([{ actionName: TOKEN_LIST_ALL_ACTION }]));
    return response;
  }
);

export const deleteEmailToken = createAsyncThunk(
  "email-token/delete",
  async (tokenId: string, { dispatch }) => {
    const response = await removeEmailTokenApi(tokenId);
    dispatch(expireApiStatus([{ actionName: TOKEN_LIST_ALL_ACTION }]));
    return response;
  }
);

export const verifyToken = createAsyncThunk(
  "email-token/verify",
  async (token: string) => {
    return await verifyTokenApi(token);
  }
);

export const getAllFunctions = createAsyncThunkWrapper({
  actionName: FUNCTION_LIST_ALL_ACTION,
  dispatchFn: async (_, { getState }) => {
    const {
      settings: { personOrgMapping },
    } = getState() as RootState;
    return await getEmailTokenFunctionsApi(personOrgMapping);
  },
  isCachable: true,
});

export const getAllColumns = createAsyncThunkWrapper({
  actionName: COLUMN_LIST_ALL_ACTION,
  dispatchFn: async (_, { getState }) => {
    const {
      settings: { personOrgMapping },
    } = getState() as RootState;
    return await getEmailTokenColumnsApi(personOrgMapping);
  },
  isCachable: true,
});

export const lookupPersonForToken = createAsyncThunk(
  "email-token/add-person-to-preview",
  async (email: string, { getState }) => {
    const { settings } = getState() as RootState;
    const campaignContext =
      settings.personOrgMapping === PERSON_ORG_MAPPING.ONE_TO_NONE
        ? CAMPAIGN_CONTEXT.PERSON
        : CAMPAIGN_CONTEXT.ORG;
    return await personLookupApi(email, campaignContext);
  }
);

export const getEmailTokenPreview = createAsyncThunk(
  "email-token/tokens-preview",
  async (person: PreviewPerson[], { getState }) => {
    const {
      emailToken: {
        emailTokenEdit,
        emailTokenPreview: { preview },
      },
    } = getState() as RootState;
    const data = Object.values(preview).map(
      ({ email, org_id, product_user_id, id, campaign_context }) => {
        return {
          email,
          org_id,
          product_user_id,
          id,
          campaign_context,
        };
      }
    );
    return await getEmailTokenPreviewApi({
      token_id: emailTokenEdit.tokenDetails?.token_id ?? "",
      person_ids: [...data, ...person],
    });
  },
  {
    condition: (person: PreviewPerson[], { getState }) => {
      const {
        emailToken: { emailTokenPreview, emailTokenEdit },
      } = getState() as RootState;

      if (
        !Object.keys(emailTokenPreview.personIds).length ||
        (!person.length && !emailTokenPreview.preview.length) ||
        !emailTokenEdit.tokenDetails?.token_id ||
        emailTokenEdit.isTokenDefinitionDirty ||
        isLoading(emailTokenPreview.fetchingPreview)
      ) {
        return false;
      }
    },
  }
);

const emailTokenSlice = createSlice({
  name: "emailToken",
  initialState,
  reducers: {
    resetFlags(state) {
      state.emailTokenEdit.fetchingTokenDetails = LOADING_STATES.INIT;
      state.emailTokenList.creatingEmailToken = LOADING_STATES.INIT;
      state.emailTokenList.createdEmailTokenId = "";
      state.emailTokenList.deletingToken = LOADING_STATES.INIT;
    },
    resetTokenData(state) {
      state.emailTokenEdit.tokenDetails = null;
      state.emailTokenEdit.isTokenDefinitionDirty = false;
      state.emailTokenEdit.fetchingTokenDetails = LOADING_STATES.INIT;
      state.emailTokenEdit.updatingTokenDetails = LOADING_STATES.INIT;
      state.emailTokenPreview.preview = [];
      state.emailTokenPreview.personIds = initializeLoadingData({});
    },
    resetTokenList(state) {
      state.emailTokenList.list = INITIAL_PAGINATION;
    },
    setTokenDefinitionDirty(state, { payload }: { payload: boolean }) {
      state.emailTokenEdit.isTokenDefinitionDirty = payload;
    },
    setTokenHeaderDirty(state, { payload }: { payload: boolean }) {
      state.emailTokenEdit.isTokenHeaderDirty = payload;
    },
    resetDeleteFlag(state) {
      state.emailTokenList.deletingToken = LOADING_STATES.INIT;
    },
    resetPersonLookup(state) {
      state.emailTokenPreview.personIds = initializeLoadingData({});
    },
  },
  extraReducers: (builder) => {
    builder
      // token list
      .addCase(listEmailTokens.pending, (state, action) => {
        state.emailTokenList.list.fetchingList = true;
        state.emailTokenList.list.currentPageNo = action.meta.arg.pageNo;
      })
      .addCase(listEmailTokens.fulfilled, (state, action) => {
        state.emailTokenList.list.list = action.payload.records;
        state.emailTokenList.list.totalPageCount =
          action.payload.page_count ?? 1;
        state.emailTokenList.list.count = action.payload.record_count;
        state.emailTokenList.list.fetchingList = false;
        state.emailTokenList.list.changingPage = false;
      })
      .addCase(listEmailTokens.rejected, (state) => {
        state.emailTokenList.list.fetchingList = false;
        state.emailTokenList.list.changingPage = false;
      })
      //list all email tokens
      .addCase(listAllEmailTokens.pending, (state) => {
        state.emailTokenList.listAll.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllEmailTokens.fulfilled, (state, { payload }) => {
        state.emailTokenList.listAll.loading = LOADING_STATES.SUCCESS;
        state.emailTokenList.listAll.data = payload.tokens;
      })
      .addCase(listAllEmailTokens.rejected, (state) => {
        state.emailTokenList.listAll.loading = LOADING_STATES.FAILED;
      })
      // token get
      .addCase(getEmailToken.pending, (state) => {
        state.emailTokenEdit.fetchingTokenDetails = LOADING_STATES.LOADING;
      })
      .addCase(getEmailToken.fulfilled, (state, action) => {
        state.emailTokenEdit.fetchingTokenDetails = LOADING_STATES.SUCCESS;
        state.emailTokenEdit.tokenDetails = action.payload.token;
      })
      .addCase(getEmailToken.rejected, (state) => {
        state.emailTokenEdit.fetchingTokenDetails = LOADING_STATES.FAILED;
      })
      // token create
      .addCase(createEmailToken.pending, (state) => {
        state.emailTokenList.creatingEmailToken = LOADING_STATES.LOADING;
      })
      .addCase(createEmailToken.fulfilled, (state, action) => {
        state.emailTokenList.creatingEmailToken = LOADING_STATES.SUCCESS;
        state.emailTokenList.createdEmailTokenId =
          action.payload.token.token_id;
        toast.success("Token successfully created");
      })
      .addCase(createEmailToken.rejected, (state) => {
        state.emailTokenList.creatingEmailToken = LOADING_STATES.FAILED;
      })
      // token update
      .addCase(updateEmailToken.pending, (state) => {
        state.emailTokenEdit.updatingTokenDetails = LOADING_STATES.LOADING;
      })
      .addCase(updateEmailToken.fulfilled, (state, action) => {
        state.emailTokenEdit.updatingTokenDetails = LOADING_STATES.SUCCESS;
        state.emailTokenEdit.tokenDetails = action.payload.token;
        state.emailTokenEdit.isTokenDefinitionDirty = false;
        state.emailTokenEdit.isTokenHeaderDirty = false;
        toast.success("Token successfully updated");
      })
      .addCase(updateEmailToken.rejected, (state) => {
        state.emailTokenEdit.updatingTokenDetails = LOADING_STATES.FAILED;
      })
      // add person to preview table
      .addCase(lookupPersonForToken.pending, (state) => {
        state.emailTokenPreview.personIds.loading = LOADING_STATES.LOADING;
      })
      .addCase(lookupPersonForToken.fulfilled, (state, action) => {
        if (action.payload.rows.length) {
          state.emailTokenPreview.personIds.loading = LOADING_STATES.SUCCESS;
          const data = {
            [action.meta.arg]: action.payload.rows.map((row) => {
              return { ...row, campaign_context: CAMPAIGN_CONTEXT.PERSON };
            }),
          };
          state.emailTokenPreview.personIds.data = data;
        } else {
          state.emailTokenPreview.personIds.data = {};
          state.emailTokenPreview.personIds.loading = LOADING_STATES.FAILED;
        }
      })
      .addCase(lookupPersonForToken.rejected, (state) => {
        state.emailTokenPreview.personIds.loading = LOADING_STATES.FAILED;
      })
      // delete token
      .addCase(deleteEmailToken.pending, (state) => {
        state.emailTokenList.deletingToken = LOADING_STATES.LOADING;
      })
      .addCase(deleteEmailToken.fulfilled, (state, action) => {
        if (action.payload.token) {
          state.emailTokenList.deletingToken = LOADING_STATES.SUCCESS;
          toast.success("Token deleted successfully");
        } else {
          state.emailTokenList.deletingToken = LOADING_STATES.FAILED;
        }
      })
      .addCase(deleteEmailToken.rejected, (state) => {
        state.emailTokenList.deletingToken = LOADING_STATES.FAILED;
      })
      // Functions list
      .addCase(getAllFunctions.pending, (state) => {
        state.allFunctionsList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getAllFunctions.fulfilled, (state, action) => {
        state.allFunctionsList.data = action.payload.functions;
        state.allFunctionsList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getAllFunctions.rejected, (state) => {
        state.allFunctionsList.loading = LOADING_STATES.FAILED;
      })
      // Column accessors list
      .addCase(getAllColumns.pending, (state) => {
        state.allColumnsList.loading = LOADING_STATES.LOADING;
      })
      .addCase(getAllColumns.fulfilled, (state, action) => {
        state.allColumnsList.data = action.payload.columns;
        state.allColumnsList.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getAllColumns.rejected, (state) => {
        state.allColumnsList.loading = LOADING_STATES.FAILED;
      })
      // Email preview
      .addCase(getEmailTokenPreview.pending, (state) => {
        state.emailTokenPreview.fetchingPreview = LOADING_STATES.LOADING;
      })
      .addCase(getEmailTokenPreview.fulfilled, (state, action) => {
        state.emailTokenPreview.preview = action.payload.result;
        state.emailTokenPreview.fetchingPreview = LOADING_STATES.SUCCESS;
      })
      .addCase(getEmailTokenPreview.rejected, (state) => {
        state.emailTokenPreview.fetchingPreview = LOADING_STATES.FAILED;
      });
  },
});

export const {
  resetFlags,
  resetTokenData,
  setTokenDefinitionDirty,
  setTokenHeaderDirty,
  resetDeleteFlag,
  resetPersonLookup,
  resetTokenList,
} = emailTokenSlice.actions;

export const selectEmailToken = (state: RootState) => state.emailToken;

export default emailTokenSlice.reducer;
