import {
  Grid,
  Box,
  Flex,
  Text,
  useDisclosure,
  UnorderedList,
  ListIcon,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useState, useEffect, useRef, useMemo, ReactNode } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import {
  ALWAYS_USE_SALESFORCE_OPTION,
  DW_SYNC_PREFERENCES,
  NONE_DESTINATION,
  SF_SYNC_PREFERENCES,
} from "../../../../../../../../../../../common/constants/unifiedMapping";
import {
  findFields,
  findSource,
  isSalesforcePreference,
} from "../../../../../../../../../../../common/helper/unifiedMappingHelper";
import { SALESFORCE_OBJECT_TYPE } from "../../../../../../../../../../../common/types/salesforceLegacyService";
import {
  MAPPING_ASSET_TYPE,
  ColumnMappingDetails,
} from "../../../../../../../../../../../common/types/connection";
import {
  ConnectionListItem,
  Source,
  Destination,
  SYNC_PREFERENCE,
  MappingElement,
  SOURCES,
  SUB_TYPES,
  SyncPreference,
  FormFieldDataTypes,
  DwDestination,
  MappedFieldsDict,
} from "../../../../../../../../../../../common/types/unifiedMapping";
import DropdownWithSearch from "../../../../../../../../../../../components/DropdownWithSearch";
import RemoveRowButton from "../../../../../../../../../../../components/RemoveRowButton";
import { selectConnection } from "../../../../../../../../connectionSlice";
import { MappingTableColumnTitle } from "../../../../../../../../../../../components/unified-mapping/MappingTableColumnTitle";
import { ReadonlyField } from "../../../../../../../../../../../components/unified-mapping/ReadOnlyFieldMapping";
import { ConnectionNameWithSourceLogo } from "../../../../../../../../../../../components/unified-mapping/ConnectionNameWithSourceLogoMapping";
import {
  DW_SOURCES,
  READONLY_SF_SYNC_MESSAGE,
} from "../../../../../../../../../../../common/constants/connection";
import { ConfirmationModal } from "../../../../../../../../../../../components/ConfirmationModal";
import { isSuccess } from "../../../../../../../../../../../common/helper/commonHelper";
import IModal from "../../../../../../../../../../../components/IModal";
import { BsDot } from "react-icons/bs";
import {
  SyncedObjSummary,
  SalesforceConnectionsSummary,
} from "../../../../../../../../../../../common/types/salesforce";

enum FORM_FIELD {
  SOURCE = "source",
  CONTACT = "contact",
  LEAD = "lead",
  SYNC = "sync",
}

const UNSUBSCRIBED_CONTACT_FIELD = "unsubscribed";

function ListItem({ children }: { children: ReactNode }) {
  return (
    <Flex>
      <Box>
        <ListIcon as={BsDot} />
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
}

function transformData(
  data: ColumnMappingDetails[],
  assetType: MAPPING_ASSET_TYPE
) {
  return data
    ?.filter((x) => x.asset_type === assetType)
    .map((x) => x.name)
    .join(", ");
}

function InfoModal({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: ColumnMappingDetails[];
}) {
  const [campaigns, setCampaigns] = useState("");
  const [forms, setForms] = useState("");

  useEffect(() => {
    setCampaigns(transformData(data, MAPPING_ASSET_TYPE.CAMPAIGN));
    setForms(transformData(data, MAPPING_ASSET_TYPE.FORMS));
  }, [data]);

  return (
    <IModal
      isOpen={isOpen}
      onClose={onClose}
      header={{
        title: "Field unavailable for “Always use Salesforce” sync",
      }}
      primaryButton={{
        label: "Okay",
        props: {
          onClick: onClose,
        },
      }}
      size="lg"
    >
      <Text fontSize="sm">
        Fields that are set to “Always use Salesforce” are read-only. This
        means, they cannot be utilised for updates coming from other sections of
        Inflection.
      </Text>
      <Text mt="2" fontSize="sm">
        This field is currently being updated by
      </Text>
      <UnorderedList fontSize="sm">
        {forms && (
          <ListItem>
            <Text as="span" fontWeight="semibold">
              {forms}
            </Text>{" "}
            {forms.includes(", ") ? "forms" : "form"}, mapped to a form field
          </ListItem>
        )}
        {campaigns && (
          <ListItem>
            <Text as="span" fontWeight="semibold">
              {campaigns}
            </Text>{" "}
            {campaigns.includes(", ") ? "journeys" : "journey"}, where it is
            being updated from a flowstep
          </ListItem>
        )}
      </UnorderedList>
    </IModal>
  );
}

export function ColumnRow({
  rows,
  sources,
  remove,
  contact = null,
  lead = null,
  syncPreference = null,
  selectedSource,
  hideTitle,
  disableSource,
  onChange,
  data,
  readonly,
  objectSettings,
  hideSyncPref,
  salesforceConnections,
}: {
  rows: MappedFieldsDict;
  sources: ConnectionListItem[];
  remove: () => void;
  selectedSource: Source | null;
  contact: Destination | DwDestination | null;
  lead: Destination | null;
  syncPreference: SYNC_PREFERENCE | null;
  hideTitle?: boolean;
  disableSource?: boolean;
  onChange: (data: MappingElement) => void;
  data: MappingElement;
  readonly: boolean;
  objectSettings: SyncedObjSummary | null;
  hideSyncPref?: boolean;
  salesforceConnections?: SalesforceConnectionsSummary | null;
}) {
  const {
    unifiedConnection: { dwColumnList, mappedColumns, mapping },
  } = useSelector(selectConnection);

  // originalSyncPref is the currently saved value and syncPreference is the local state of the same.
  // Based on the original value of Sync Preference (current saved in server) we need to show two different warnings to the user.
  const originalSyncPref = useRef(syncPreference);

  useEffect(() => {
    originalSyncPref.current = syncPreference;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapping]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenWarning,
    onOpen: onOpenWarning,
    onClose: onCloseWarning,
  } = useDisclosure();

  const mappedFormsAndJourneys = useMemo(() => {
    if (mappedColumns.data) {
      return mappedColumns.data[data.destination.name];
    } else {
      return null;
    }
  }, [mappedColumns, data.destination.name]);

  const isColumnMappedToFormsOrJourneys = useMemo(
    () => !!mappedFormsAndJourneys,
    [mappedFormsAndJourneys]
  );

  const [sourceField, setSourceField] = useState<ConnectionListItem | null>(
    findSource(selectedSource, sources)
  );
  const [contactField, setContactField] = useState<
    Destination | null | DwDestination
  >(contact);
  const [leadField, setLeadField] = useState<Destination | null>(lead);

  const [syncPreferenceField, setSyncPreferenceField] =
    useState<SyncPreference | null>(
      findSyncPreference(syncPreference, selectedSource?.type ?? null)
    );

  const isSalesforce = useMemo(
    () => !!sourceField && sourceField.source === SOURCES.SALESFORCE,
    [sourceField]
  );

  const cancelRef = useRef(null);

  function findSyncPreference(
    val: SYNC_PREFERENCE | null,
    source: SOURCES | null
  ) {
    if (source) {
      if (source === SOURCES.SALESFORCE) {
        return val
          ? SF_SYNC_PREFERENCES.find((x) => x.value === val) || null
          : null;
      } else if (DW_SOURCES.includes(source)) {
        return val
          ? DW_SYNC_PREFERENCES.find((x) => x.value === val) || null
          : DW_SYNC_PREFERENCES[0];
      }
    }
    return null;
  }

  function propagateChange({
    source,
    contact,
    lead,
    syncPreference,
  }: {
    source: ConnectionListItem;
    contact: Destination | DwDestination | null;
    lead: Destination | null;
    syncPreference: SyncPreference | null;
  }) {
    const dataCopy = cloneDeep(data);
    if (dataCopy.source?.length) {
      dataCopy.source?.forEach((x) => {
        x.connection_id = source?.connection_id || "";
        x.type = source?.source || x.type;
        x.field.contact = contact;
        x.field.lead = lead;
        x.sync_preference = syncPreference?.value || null;
      });
    } else {
      dataCopy.source = [
        {
          connection_id: source.connection_id || "",
          field: {
            contact: contact,
            lead: lead,
          },
          sync_preference: syncPreference?.value || null,
          type: source.source,
        },
      ];
    }
    onChange({ ...dataCopy });
  }

  function updateSyncPerferenceDropdown(data: SyncPreference) {
    setSyncPreferenceField(data);
    propagateChange({
      source: sourceField!,
      contact: contactField,
      lead: leadField,
      syncPreference: data,
    });
  }

  function onChangeLocal(field: FORM_FIELD, data: FormFieldDataTypes) {
    switch (field) {
      case FORM_FIELD.SOURCE:
        const sourceFieldData = data as ConnectionListItem;
        if (sourceField?.connection_id !== sourceFieldData.connection_id) {
          setSourceField(sourceFieldData);
          propagateChange({
            source: sourceFieldData,
            contact: null,
            lead: null,
            syncPreference: null,
          });
        }
        break;
      case FORM_FIELD.CONTACT:
        setContactField(data as Destination);
        propagateChange({
          source: sourceField!,
          contact: data as Destination,
          lead: leadField,
          syncPreference: syncPreferenceField,
        });
        break;
      case FORM_FIELD.LEAD:
        setLeadField(data as Destination);
        propagateChange({
          source: sourceField!,
          lead: data as Destination,
          contact: contactField,
          syncPreference: syncPreferenceField,
        });
        break;
      case FORM_FIELD.SYNC:
        // if no saved preference and user selecting sf preference
        if (
          !originalSyncPref.current &&
          isSalesforcePreference((data as SyncPreference).value)
        ) {
          onOpen();
          break;
        }

        // if non SF preference and user selecting SF preference
        if (
          isColumnMappedToFormsOrJourneys &&
          syncPreferenceField?.value &&
          !isSalesforcePreference(syncPreferenceField?.value) &&
          isSalesforcePreference((data as SyncPreference).value)
        ) {
          // open popup "Field unavailable for “Always use Salesforce” sync"
          onOpenWarning?.();
          break;
        }

        updateSyncPerferenceDropdown(data as SyncPreference);
        break;
    }
  }

  function handleConfirm() {
    updateSyncPerferenceDropdown(ALWAYS_USE_SALESFORCE_OPTION);
    onClose();
  }

  function handleCancel() {
    onClose();
  }

  useEffect(() => {
    // Set local state when input params are updated
    setSourceField(findSource(selectedSource, sources));
    setContactField(contact);
    setLeadField(lead);

    setTimeout(() => {
      // sync preference dropdown not getting displayed with
      setSyncPreferenceField(
        findSyncPreference(syncPreference, selectedSource?.type ?? null)
      );
    });
  }, [selectedSource, lead, contact, syncPreference, sources]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (!firstRender.current) {
      setContactField(null);
      setLeadField(null);
      setSyncPreferenceField(null);
    } else {
      firstRender.current = false;
    }
  }, [sourceField]);

  function filterFields(
    objectType: SALESFORCE_OBJECT_TYPE
  ): Destination[] | DwDestination[] {
    let fields: Destination[] | DwDestination[] = [];
    if (sourceField) {
      if (isSalesforce) {
        fields = findFields({
          objects:
            (sourceField &&
              salesforceConnections?.[sourceField.connection_id]
                ?.syncObjectColumns) ??
            [],
          objectType,
          fieldDataType: data.destination.type,
        });
      } else if (DW_SOURCES.includes(sourceField.source)) {
        fields = dwColumnList[sourceField.connection_id]?.data ?? [];
        return fields.filter((x) => x.type === data.destination.type);
      }
    }
    return [NONE_DESTINATION, ...fields];
  }

  //disable "Always use sf" and "prefer sf unless blank" for unsubscribed field
  function disableSfPreferenceForUnsub(value: SYNC_PREFERENCE) {
    return (
      data?.destination.name === UNSUBSCRIBED_CONTACT_FIELD &&
      isSalesforce &&
      value !== SYNC_PREFERENCE.TWO_WAY
    );
  }

  return (
    <Flex width="100%" p="12px" alignItems="center">
      <Grid
        width="100%"
        templateColumns="repeat(5, 1fr)"
        gap={4}
        alignItems="center"
      >
        {/* DESTINATION */}
        {/* ------------------------------ */}

        {hideTitle ? (
          <Box></Box>
        ) : (
          <MappingTableColumnTitle
            type={
              data.destination.sub_type === SUB_TYPES.EMAIL
                ? SUB_TYPES.EMAIL
                : data.destination.type
            }
            name={data.destination.display}
            removable={data.destination.custom}
            tooltipMessage={
              originalSyncPref.current &&
              isSalesforcePreference(originalSyncPref.current)
                ? READONLY_SF_SYNC_MESSAGE
                : ""
            }
          />
        )}

        {/* SOURCE */}
        {/* ----------------------------- */}

        {/* Source type and connection id */}
        {disableSource || readonly ? (
          <Flex>
            {sourceField ? (
              <ConnectionNameWithSourceLogo
                px="2"
                source={sourceField.source}
                label={sourceField.name}
              />
            ) : (
              <Text color="gray.500" my={3} fontSize="14px" px="2">
                Unmapped
              </Text>
            )}
          </Flex>
        ) : (
          <DropdownWithSearch
            isSearchable={true}
            value={sourceField}
            getOptionValue={(x) => x.connection_id}
            getOptionLabel={(x) => x.name}
            options={sources || []}
            onChange={(option) => onChangeLocal(FORM_FIELD.SOURCE, option)}
            components={{
              Control: ({ children, ...rest }) => (
                <components.Control {...rest}>
                  <Flex ml="2" w="100%">
                    <ConnectionNameWithSourceLogo
                      source={rest.getValue()[0]?.source}
                      label={""}
                    />
                    {children}
                  </Flex>
                </components.Control>
              ),
              Option: ({ children, ...rest }) => (
                <components.Option {...rest}>
                  <Flex ml="2" w="100%">
                    <ConnectionNameWithSourceLogo
                      source={rest.data.source}
                      label={children?.toString() || ""}
                    />
                  </Flex>
                </components.Option>
              ),
            }}
          />
        )}

        {/* SOURCE > field > contact */}
        {readonly ? (
          objectSettings && !objectSettings.contact ? (
            <Box></Box>
          ) : (
            <ReadonlyField>{contactField?.display} </ReadonlyField>
          )
        ) : ((isSalesforce && objectSettings?.contact) || !isSalesforce) &&
          sourceField ? (
          <DropdownWithSearch
            isSearchable={true}
            getOptionValue={(x) => x.name}
            value={contactField}
            getOptionLabel={(x) => x.display}
            isOptionDisabled={(option) =>
              option.name !== NONE_DESTINATION.name &&
              rows.contact[sourceField.connection_id]?.includes(option?.name)
            }
            options={filterFields(SALESFORCE_OBJECT_TYPE.CONTACT)}
            onChange={(option) => onChangeLocal(FORM_FIELD.CONTACT, option)}
          />
        ) : (
          <Box></Box>
        )}

        {/* SOURCE > field > Lead */}
        {readonly ? (
          objectSettings && !objectSettings.lead ? (
            <Box></Box>
          ) : (
            <ReadonlyField>{leadField?.display} </ReadonlyField>
          )
        ) : isSalesforce && objectSettings?.lead && sourceField ? (
          <DropdownWithSearch
            isSearchable={true}
            value={leadField}
            getOptionValue={(x) => x.name}
            getOptionLabel={(x) => x.display}
            isOptionDisabled={(option) =>
              option.name !== NONE_DESTINATION.name &&
              rows.lead[sourceField.connection_id]?.includes(option?.name)
            }
            options={filterFields(SALESFORCE_OBJECT_TYPE.LEAD) as Destination[]}
            onChange={(option) => onChangeLocal(FORM_FIELD.LEAD, option)}
          />
        ) : (
          <Box></Box>
        )}

        {/* SOURCE > SYNC PREFERENCE */}
        {readonly || hideSyncPref ? (
          <ReadonlyField>{syncPreferenceField?.label}</ReadonlyField>
        ) : (
          sourceField && (
            <DropdownWithSearch
              isSearchable={true}
              value={syncPreferenceField}
              onChange={(option) => onChangeLocal(FORM_FIELD.SYNC, option)}
              options={isSalesforce ? SF_SYNC_PREFERENCES : DW_SYNC_PREFERENCES}
              isOptionDisabled={(option) =>
                disableSfPreferenceForUnsub(option.value)
              }
            />
          )
        )}
      </Grid>
      {data.destination.custom && !readonly ? (
        <RemoveRowButton onClick={remove} />
      ) : (
        <Box width="32px"></Box>
      )}
      <ConfirmationModal
        isOpen={isOpen}
        cancelButtonText="Cancel"
        onCancel={handleCancel}
        confirmButtonText="Proceed"
        submitHandler={handleConfirm}
        cancelRef={cancelRef}
        title="Salesforce preferred sync"
      >
        <Text fontSize="sm">
          Salesforce preferred fields cannot be mapped to Form fields and cannot
          be updated manually and through the Update value flow step
        </Text>
      </ConfirmationModal>
      {isSuccess(mappedColumns.loading) && (
        <InfoModal
          isOpen={isOpenWarning}
          onClose={onCloseWarning}
          data={mappedFormsAndJourneys!}
        />
      )}
    </Flex>
  );
}
