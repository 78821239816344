import { ReactNode } from "react";
import { Text } from "@chakra-ui/react";

export default function BolderText({ children }: { children: ReactNode }) {
  return (
    <Text as="span" color="gray.700" fontWeight="semibold">
      {children}
    </Text>
  );
}
