import { HStack, Text, Image } from "@chakra-ui/react";
import { FORM_VENDORS } from "../../../../common/types/form";
import { FORM_PROVIDER_DETAILS } from "../helper";

export default function FormProviderWithLogo({
  vendor,
}: {
  vendor: FORM_VENDORS;
}) {
  const { imgSrc, name } = FORM_PROVIDER_DETAILS[vendor] ?? {};
  return (
    <HStack>
      <Image src={imgSrc} alt={name} boxSize="18px" />
      <Text fontSize="sm">{name}</Text>
    </HStack>
  );
}
