import { HStack, StackProps, Text, TextProps } from "@chakra-ui/react";
import { addPunctuationSeparators } from "../common/helper/commonHelper";
import { isEmpty } from "lodash";
import { EMPTY_CONTEXT } from "../common/constants/common";

export default function ReadOnlyList({
  list,
  textProps,
  containerProps,
}: {
  list: (string | number)[];
  textProps?: TextProps;
  containerProps?: StackProps;
}) {
  const isEmptyList = isEmpty(list);

  return (
    <HStack
      alignItems="flex-start"
      justifyContent="flex-start"
      {...containerProps}
    >
      <Text
        fontSize="14px"
        {...textProps}
        color={isEmptyList ? "gray.400" : undefined}
      >
        {isEmptyList ? EMPTY_CONTEXT : addPunctuationSeparators(list)}
      </Text>
    </HStack>
  );
}
