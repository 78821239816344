import { Box, Heading, Text } from "@chakra-ui/react";

export function Header({ title, desc }: { title: string; desc: string }) {
  return (
    <Box w="100%">
      <Heading fontSize="md" mb="2">
        {title}
      </Heading>
      <Text fontSize="md">{desc}</Text>
    </Box>
  );
}
