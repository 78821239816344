import { HStack, VStack, Text, Divider, useMediaQuery } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useContext, useMemo } from "react";
import { FaDesktop } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  ComparisonNode,
  Condition,
  ConditionGroup,
  FrequencyCondition,
  TargetValueTypes,
  TimeframeCondition,
} from "../../../../../common/types/dynamicList";
import { FILTER_TYPE } from "../../../../../common/constants/campaign";
import {
  COMPARISON_OPERATORS,
  LOGICAL_OPERATORS,
} from "../../../../../common/constants/dynamicList";
import {
  createGroupId,
  getOperatorDetails,
  isLoading,
} from "../../../../../common/helper/commonHelper";
import { OperatorType } from "../../../../../common/types/campaign";
import IButton from "../../../../../components/IButton";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";
import AddPropertyButton from "../../../../../components/dynamic-list/AddPropertyButton";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import {
  selectDynamicList,
  getEventKeys,
} from "../../../../../components/dynamic-list/dynamicListSlice";
import { useAppDispatch } from "../../../../../store";
import NodeGroupBox from "../components/NodeGroupBox";
import { AudienceCriteriaContext } from "../AudienceCriteria";
import LogicGate from "../components/LogicGate";
import { getLogicalOperator } from "../helper";
import AudienceCriteriaValueFields from "../components/AudienceCriteriaValueFields";
import {
  FrequencySelectorV2,
  TimeFrameSelectorV2,
} from "../components/CommonNodeCriteriaFilters";
import { DL_TARGET_VALUE_TYPES } from "../../../../../common/constants/dynamicList";
import { RxPlus } from "react-icons/rx";

function ConditionGroupContainer({
  conditionGroups,
  parentId,
  eventName,
  onChange,
  isReadOnly,
}: {
  conditionGroups: ConditionGroup[];
  parentId: string;
  eventName: string | null;
  onChange: (data: ConditionGroup[]) => void;
  isReadOnly?: boolean;
}) {
  function onRemoveConditionGroup(index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy.splice(index, 1);
    if (dataCopy.length) {
      dataCopy[0].logicalOperator = LOGICAL_OPERATORS.FIRST;
    }
    onChange(dataCopy);
  }

  function onChangeConditionGroup(updateData: ConditionGroup, index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy[index] = updateData;
    onChange(dataCopy);
  }

  function setLogicalOperator(op: LOGICAL_OPERATORS, index: number) {
    const dataCopy = cloneDeep(conditionGroups);
    dataCopy[index].logicalOperator = op;
    onChange(dataCopy);
  }

  return (
    <>
      {conditionGroups.map((conditionGroup, index) => (
        <>
          <LogicGate
            operator={conditionGroup.logicalOperator}
            handleChange={(op) => setLogicalOperator(op, index)}
            isReadOnly={isReadOnly}
          />
          <SingleConditionGroup
            eventName={eventName}
            conditionGroup={conditionGroup}
            id={createGroupId(parentId, "filter", index)}
            label={`Property group ${index + 1}`}
            onRemove={() => onRemoveConditionGroup(index)}
            onChange={(data) => onChangeConditionGroup(data, index)}
            isReadOnly={isReadOnly}
          />
        </>
      ))}
    </>
  );
}

function SingleConditionGroup({
  eventName,
  conditionGroup,
  label,
  id,
  onRemove,
  onChange,
  isReadOnly,
}: {
  eventName: string | null;
  conditionGroup: ConditionGroup;
  label: string;
  id: string;
  onRemove: () => void;
  onChange: (data: ConditionGroup) => void;
  isReadOnly?: boolean;
}) {
  function onAddCondition() {
    const dataCopy = cloneDeep(conditionGroup);

    const newCondition: Condition = {
      propertyName: null,
      comparisonOperator: null,
      targetValue: [],
      targetValueType: null,
      logicalOperator: getLogicalOperator(dataCopy.conditions.length),
    };

    dataCopy.conditions.push(newCondition);
    onChange(dataCopy);
  }

  function onRemoveCondition(index: number) {
    const dataCopy = cloneDeep(conditionGroup);
    dataCopy.conditions.splice(index, 1);
    if (dataCopy.conditions.length) {
      dataCopy.conditions[0].logicalOperator = LOGICAL_OPERATORS.FIRST;
    }
    onChange(dataCopy);
  }

  function onChangeCondition(newData: Condition, index: number) {
    const dataCopy = cloneDeep(conditionGroup);
    dataCopy.conditions[index] = newData;
    onChange(dataCopy);
  }

  const totalConditions = conditionGroup.conditions.length;

  if (isReadOnly) {
    return (
      <HStack position="relative">
        <Divider
          orientation="vertical"
          h="100%"
          position="absolute"
          ml="-16px"
          mb="4px"
          borderColor="brandBlue.500"
        />

        <VStack
          spacing="1"
          p={2}
          my={2}
          borderColor="gray.300"
          borderWidth="1px"
          bg="grayV2.300"
          rounded="md"
          ml="0px !important"
        >
          {conditionGroup.conditions.map(
            (condition: Condition, index: number) => {
              return (
                <ConditionRow
                  eventName={eventName}
                  condition={condition}
                  onRemove={() => onRemoveCondition(index)}
                  onChange={(data) => onChangeCondition(data, index)}
                  onAddRow={onAddCondition}
                  showAddButton={index === totalConditions - 1}
                  showRemoveButton={totalConditions !== 1}
                  isReadOnly={isReadOnly}
                />
              );
            }
          )}
        </VStack>
      </HStack>
    );
  } else {
    return (
      <NodeGroupBox
        nodeName={label}
        onRemove={onRemove}
        id={id}
        bg="grayV2.300"
        type="property"
        icon={FaDesktop}
      >
        {conditionGroup.conditions.length && (
          <HStack position="relative" ml={6} mb={3}>
            <Divider
              orientation="vertical"
              h="100%"
              mt="4px"
              position="absolute"
              borderColor="brandBlue.500"
            />
            <VStack spacing="1">
              {conditionGroup.conditions.map(
                (condition: Condition, index: number) => {
                  return (
                    <ConditionRow
                      condition={condition}
                      eventName={eventName}
                      onRemove={() => onRemoveCondition(index)}
                      onChange={(data) => onChangeCondition(data, index)}
                      onAddRow={onAddCondition}
                      showAddButton={index === totalConditions - 1}
                      showRemoveButton={totalConditions !== 1}
                    />
                  );
                }
              )}
            </VStack>
          </HStack>
        )}
      </NodeGroupBox>
    );
  }
}

function ConditionRow({
  condition,
  eventName,
  onChange,
  showRemoveButton,
  onRemove,
  showAddButton,
  onAddRow,
  isReadOnly,
}: {
  condition: Condition;
  eventName: string | null;
  onChange: (data: Condition) => void;
  showRemoveButton: boolean;
  onRemove: () => void;
  showAddButton: boolean;
  onAddRow: () => void;
  isReadOnly?: boolean;
}) {
  const {
    productEventKeysList: {
      data: productEventKeysList,
      loading: fetchingProductEventKeysList,
    },
  } = useSelector(selectDynamicList);

  const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

  const [isSmallerThan1024] = useMediaQuery("(max-width: 1024px)");

  const operatorDetails = useMemo(
    () =>
      getOperatorDetails(
        condition.comparisonOperator,
        operators,
        OperatorType.STRING
      ),
    [operators, condition.comparisonOperator]
  );

  function changeFieldName(field: string) {
    const dataCopy = cloneDeep(condition);

    dataCopy.propertyName = field;
    dataCopy.comparisonOperator = null;
    dataCopy.targetValue = [];
    dataCopy.targetValueType = null;

    onChange(dataCopy);
  }

  function setLogicalOperator(op: LOGICAL_OPERATORS) {
    const dataCopy = cloneDeep(condition);
    dataCopy.logicalOperator = op;
    onChange(dataCopy);
  }

  function setComparisonOperator(operator: COMPARISON_OPERATORS) {
    const dataCopy = cloneDeep(condition);
    dataCopy.comparisonOperator = operator;
    //target type string for product activities
    dataCopy.targetValueType = DL_TARGET_VALUE_TYPES.STRING;
    dataCopy.targetValue = [];

    onChange(dataCopy);
  }

  function handleValueChange(val: TargetValueTypes) {
    const dataCopy = cloneDeep(condition);

    dataCopy.targetValue = val;
    onChange(dataCopy);
  }

  function getEventList() {
    return eventName
      ? productEventKeysList[eventName]?.map((x) => ({
          label: x,
          value: x,
        }))
      : [];
  }

  const wrapperStyle = isReadOnly
    ? {
        p: "0",
        alignItems: "baseline",
      }
    : {
        py: "2",
        px: "3",
        alignItems: "flex-start",
      };

  const isInvalidPropertyName = activeErrorCheck && !condition.propertyName;
  const isInvalidOperator = activeErrorCheck && !condition.comparisonOperator;

  return (
    <HStack width="100%" ml="0px !important" wrap="wrap" {...wrapperStyle}>
      <LogicGate
        id="logic-gate"
        operator={condition.logicalOperator}
        handleChange={(op) => setLogicalOperator(op as LOGICAL_OPERATORS)}
        isReadOnly={isReadOnly}
      />
      {isReadOnly ? (
        <Text fontSize="sm">where</Text>
      ) : (
        <HStack h="33px">
          <Text fontSize="sm">where...</Text>
        </HStack>
      )}
      <HStack>
        <ValueSelectFields
          options={getEventList() ?? []}
          value={condition.propertyName || null}
          onChange={changeFieldName}
          validationError={isInvalidPropertyName ? "Invalid property" : ""}
          loading={isLoading(fetchingProductEventKeysList)}
          isReadOnly={isReadOnly}
        />
      </HStack>

      {condition.propertyName && operators && (
        <HStack>
          <ValueSelectFields
            options={Object.values(operators[OperatorType.STRING]).map(
              (op) => ({
                label: op.display,
                value: op.id,
              })
            )}
            value={condition.comparisonOperator || ""}
            onChange={(op) => setComparisonOperator(op as COMPARISON_OPERATORS)}
            validationError={isInvalidOperator ? "Invalid operator" : ""}
            isReadOnly={isReadOnly}
          />
        </HStack>
      )}

      {condition.propertyName &&
        condition.comparisonOperator &&
        operatorDetails && (
          <HStack>
            <AudienceCriteriaValueFields
              value={condition.targetValue}
              onChange={handleValueChange}
              argumentTypes={operatorDetails.arguments_types}
              helperText={operatorDetails.display_2}
              noOfArguments={operatorDetails.arguments}
              filterValue={condition.propertyName}
              activeErrorCheck={activeErrorCheck}
              isReadOnly={isReadOnly}
            />
          </HStack>
        )}

      {!isReadOnly && showAddButton && condition.comparisonOperator && (
        <AddPropertyButton iconOnly={isSmallerThan1024} onClick={onAddRow} />
      )}

      {!isReadOnly && showRemoveButton && (
        <RemoveRowCloseButton onClick={onRemove} />
      )}
    </HStack>
  );
}

function ActivitySelector({
  productActivityList,
  onChange,
  value,
  validationError,
  isReadOnly,
}: {
  productActivityList: string[];
  onChange: (value: string) => void;
  value: string | null;
  validationError?: string;
  isReadOnly?: boolean;
}) {
  return (
    <HStack alignItems="center" spacing="3">
      {!isReadOnly && <Text fontSize="sm">...performed an activity of...</Text>}
      <HStack id="product-activity-selector">
        <ValueSelectFields
          options={productActivityList?.map((x) => ({ label: x, value: x }))}
          value={value || ""}
          onChange={onChange}
          validationError={validationError}
          isReadOnly={isReadOnly}
        />
      </HStack>
    </HStack>
  );
}

const ProductActivityNodeCriteria = React.memo(
  ({
    nodeData,
    label,
    id,
    onChange,
    onRemove,
    isReadOnly,
  }: {
    nodeData: ComparisonNode;
    label: string;
    id: string;
    onChange: (data: ComparisonNode) => void;
    onRemove: () => void;
    isReadOnly?: boolean;
  }) => {
    const dispatch = useAppDispatch();

    const {
      productEventKeysList: {
        data: productEventKeysList,
        loading: fetchingProductEventKeysList,
      },
      productEventNamesList: { data: productEventNamesList },
    } = useSelector(selectDynamicList);

    const { activeErrorCheck, operators } = useContext(AudienceCriteriaContext);

    function fetchEventKeys(event: string) {
      if (
        event &&
        !productEventKeysList[event] &&
        !isLoading(fetchingProductEventKeysList)
      ) {
        const data = {
          entity: FILTER_TYPE.PERSON,
          event_names: [event],
          max_result: 10000,
        };
        dispatch(getEventKeys(data));
      }
    }

    function onTimeFrameChange(timeFrame: TimeframeCondition | null) {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.timeframe = timeFrame;
      onChange(dataCopy);
    }

    function onActivityChange(value: string) {
      fetchEventKeys(value);
      const dataCopy = cloneDeep(nodeData);
      // reset data in property groups when filter group activity changes
      if (nodeData.eventName !== value) {
        dataCopy.conditionGroups.forEach((conditionGroup) => {
          conditionGroup.conditions.forEach((condition) => {
            condition.propertyName = null;
            condition.comparisonOperator = null;
            condition.targetValue = [];
            condition.targetValueType = null;
          });
        });
        dataCopy.eventName = value;
        onChange(dataCopy);
      }
    }

    function onFrequencyChange(frequency: FrequencyCondition | null) {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.frequencyCondition = frequency;
      onChange(dataCopy);
    }

    function onAddTimeFrame() {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.timeframe = {
        operator: null,
        unit: null,
        type: null,
        start: null,
        end: null,
      };
      onChange(dataCopy);
    }

    const hasTimeFrame = !!nodeData.timeframe;

    function onConditionGroupsChange(newData: ConditionGroup[]) {
      const dataCopy = cloneDeep(nodeData);
      dataCopy.conditionGroups = newData;
      onChange(dataCopy);
    }

    function onAddConditionGroup() {
      const dataCopy = cloneDeep(nodeData);
      const newConditionGroup: ConditionGroup = {
        logicalOperator: getLogicalOperator(dataCopy.conditionGroups.length),
        conditions: [
          {
            logicalOperator: LOGICAL_OPERATORS.FIRST,
            comparisonOperator: null,
            propertyName: null,
            targetValueType: null,
            targetValue: [],
          },
        ],
      };

      dataCopy.conditionGroups.push(newConditionGroup);

      onChange(dataCopy);
    }

    const wrapperStyle = isReadOnly
      ? {
          px: "0",
          py: "0",
          gridGap: "1",
        }
      : {
          px: "3",
          py: "2",
          gridGap: "3",
          bg: "grayV2.200",
        };
    const isInvalidEventName = activeErrorCheck && !nodeData.eventName;
    return (
      <NodeGroupBox
        id={id}
        nodeName={label}
        onRemove={onRemove}
        icon={FaDesktop}
        isReadOnly={isReadOnly}
      >
        <VStack alignItems="flex-start" {...wrapperStyle}>
          <>
            <HStack
              width="100%"
              wrap="wrap"
              alignItems="flex-start"
              gridGap={isReadOnly ? undefined : "2"}
              spacing="1"
            >
              <ActivitySelector
                productActivityList={productEventNamesList || []}
                value={nodeData.eventName}
                onChange={(value) => onActivityChange(value)}
                validationError={isInvalidEventName ? "Invalid event" : ""}
                isReadOnly={isReadOnly}
              />
              <FrequencySelectorV2
                frequencyCondition={nodeData.frequencyCondition ?? null}
                numberOperators={operators[OperatorType.INTEGER]}
                isReadOnly={isReadOnly}
                activeErrorCheck={activeErrorCheck}
                onFrequencyChange={onFrequencyChange}
                buttonProps={{ hidden: !nodeData.eventName }}
              />
            </HStack>
            {!isReadOnly && !hasTimeFrame && (
              <IButton
                hidden={!nodeData.eventName}
                name={`add-timeframe-${id}`}
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddTimeFrame}
                variant="link"
                color="brand.blue"
                mt="5px !important"
                fontWeight="400"
              >
                Add time frame
              </IButton>
            )}

            {hasTimeFrame && (
              <>
                {!isReadOnly && <Divider />}
                <TimeFrameSelectorV2
                  showRemoveButton={true}
                  validationError={activeErrorCheck ? "" : ""}
                  isReadOnly={isReadOnly}
                  timeFrame={
                    nodeData.timeframe ?? {
                      type: null,
                      operator: null,
                      unit: null,
                      start: null,
                      end: null,
                    }
                  }
                  onChangeTimeFrame={onTimeFrameChange}
                  activeErrorCheck={activeErrorCheck}
                />
              </>
            )}

            <ConditionGroupContainer
              eventName={nodeData.eventName}
              conditionGroups={nodeData.conditionGroups}
              parentId={id}
              onChange={onConditionGroupsChange}
              isReadOnly={isReadOnly}
            />

            {!isReadOnly && (
              <IButton
                size="sm"
                leftIcon={<RxPlus fontSize="12px" />}
                onClick={onAddConditionGroup}
                isDisabled={!nodeData.eventName}
                name="add-property-group"
                variant="link"
                color="brand.blue"
                mt="5px !important"
                fontWeight="400"
              >
                Add Property Group
              </IButton>
            )}
          </>
        </VStack>
      </NodeGroupBox>
    );
  }
);

export default ProductActivityNodeCriteria;
