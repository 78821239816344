import { Box, Center } from "@chakra-ui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  isInit,
  isSuccess,
  isLoading,
  useQuery,
  isFailed,
} from "../../../../common/helper/commonHelper";
import PrimaryHeader from "../../../../components/PrimaryHeader";
import SpinnerContainer from "../../../../components/SpinnerContainer";
import urls from "../../../../urls";
import {
  selectSalesforce,
  resetSalesforceEntireState,
  verifySalesforceConnection,
} from "./salesforceSlice";
import { useAppDispatch } from "../../../../store";

export default function SalesforceAuthSuccess() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const code = query.get("code");
  const connectionId = query.get("state");

  const { verifyingSfConnection } = useSelector(selectSalesforce);

  useEffect(() => {
    if (code && connectionId && isInit(verifyingSfConnection)) {
      dispatch(verifySalesforceConnection({ code, state: connectionId }));
    }
  }, [dispatch, navigate, verifyingSfConnection, code, connectionId]);

  useEffect(() => {
    if (isSuccess(verifyingSfConnection)) {
      const salesforceDetailsUrl = `${urls.salesforceRoute}/${connectionId}`;
      navigate(salesforceDetailsUrl);
    } else if (isFailed(verifyingSfConnection)) {
      navigate(urls.unifiedMapping);
    }
  }, [navigate, verifyingSfConnection, connectionId]);

  useEffect(() => {
    return () => {
      dispatch(resetSalesforceEntireState());
    };
  }, [dispatch]);

  return (
    <Box>
      <PrimaryHeader heading="Salesforce" />
      <SpinnerContainer
        height="90vh"
        loading={isLoading(verifyingSfConnection)}
      >
        <Center
          h="90vh"
          hidden={
            isLoading(verifyingSfConnection) || isInit(verifyingSfConnection)
          }
        >
          Successfully Connected! Redirecting...
        </Center>
      </SpinnerContainer>
    </Box>
  );
}
