import {
  Box,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { FaRedo } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  getPersonLabel,
  isLoading,
  isSuccess,
} from "../../common/helper/commonHelper";
import { CAMPAIGN_CONTEXT, DynamicListType } from "../../common/types/campaign";
import DynamicList from "./DynamicList";
import IButton, { BUTTON } from "../IButton";
import { useAppDispatch } from "../../store";
import { removeConnector } from "../../pages/dashboard/campaign/helper/formatHelper";
import { validateDynamicList } from "../../pages/dashboard/campaign/helper/validationHelper";
import {
  previewStaticListDl,
  resetDlUploadState,
  selectStaticList,
} from "../../pages/dashboard/staticlist/staticlistSlice";
import { selectDynamicList } from "./dynamicListSlice";

export default function CommonDynamicListDrawer({
  dynamicListData,
  isOpen,
  onClose,
  submitButtonProps: { label, onSubmit, props: buttonProps },
  showDLCount = false,
  campaignContext = CAMPAIGN_CONTEXT.PERSON,
  extraMessage,
  title = "Dynamic list",
}: {
  dynamicListData?: DynamicListType[];
  isOpen: boolean;
  onClose: () => void;
  submitButtonProps: {
    label: string;
    onSubmit: (dynamicList: DynamicListType[]) => void;
    props: ButtonProps;
  };
  showDLCount?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
  extraMessage?: ReactNode;
  title?: string;
}) {
  const dispatch = useAppDispatch();
  const [dynamicList, setDynamicList] = useState<DynamicListType[]>([]);
  const [activeErrorCheck, setActiveErrorCheck] = useState(false);
  const { staticListDl } = useSelector(selectStaticList);
  const { operatorsArgCount } = useSelector(selectDynamicList);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setDynamicList(dynamicListData ?? []);
  }, [dynamicListData, isOpen]);

  const onCloseModal = useCallback(() => {
    setDynamicList([]);
    dispatch(resetDlUploadState());
    setActiveErrorCheck(false);
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (isSuccess(staticListDl.inserting)) onCloseModal();
  }, [staticListDl.inserting, onCloseModal]);

  const refreshIfValid = useCallback(
    (dynamicList: DynamicListType[], valid: boolean) => {
      if (valid) dispatch(previewStaticListDl(removeConnector(dynamicList)));
    },
    [dispatch]
  );

  function previewDl() {
    setActiveErrorCheck(true);
    refreshIfValid(dynamicList, valid);
  }

  function onDlChange(data: DynamicListType[]) {
    const { dynamicListValid, dynamicListValidated } = validateDynamicList(
      data,
      operatorsArgCount
    );
    setValid(dynamicListValid);
    setDynamicList(dynamicListValidated);
    if (isEmpty(dynamicListValidated)) {
      dispatch(resetDlUploadState());
    }
  }

  function onSave() {
    setActiveErrorCheck(true);
    if (valid) {
      onSubmit(removeConnector(dynamicList));
    }
  }

  function PreviewButtonWithCount() {
    return (
      <HStack>
        <Text fontSize="14px" mr={3}>
          {staticListDl.count} {getPersonLabel(campaignContext)}s selected
        </Text>
        <IButton
          variant={BUTTON.SECONDARY}
          leftIcon={<Icon color="gray.400" as={FaRedo} />}
          onClick={previewDl}
          isLoading={isLoading(staticListDl.previewing)}
          name="refresh-list"
        >
          Refresh
        </IButton>
      </HStack>
    );
  }

  return (
    <Drawer isOpen={isOpen} onClose={onCloseModal} size="3xl" variant="primary">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="16px">{title}</DrawerHeader>
        <DrawerBody>
          {extraMessage}
          <Text
            fontSize="sm"
            mr="3"
            mb="5"
            color="blue.900"
            fontWeight="semibold"
          >
            All {getPersonLabel(campaignContext)}s who have,
          </Text>
          <Box px={3}>
            {isOpen && (
              <DynamicList
                dynamicListData={dynamicList}
                onChange={onDlChange}
                sidebar={false}
                campaignContext={campaignContext}
                activeErrorCheck={activeErrorCheck}
              />
            )}
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Box>{showDLCount ? <PreviewButtonWithCount /> : <></>}</Box>
            <HStack>
              <IButton
                variant={BUTTON.SECONDARY}
                onClick={onCloseModal}
                name="modal-secondary-button"
              >
                Cancel
              </IButton>
              <IButton
                variant={BUTTON.PRIMARY}
                name="modal-primary-button"
                onClick={onSave}
                isDisabled={activeErrorCheck && !valid}
                {...buttonProps}
              >
                {label}
              </IButton>
            </HStack>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
