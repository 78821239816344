import { Box, Tooltip, Icon } from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";

export default function ComingSoonText() {
  return (
    <Tooltip label="Coming Soon!">
      <Box as="span" pt="3px">
        <Icon fontSize="17px">
          <FaInfoCircle />
        </Icon>
      </Box>
    </Tooltip>
  );
}
