import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthedConstants,
  getDomainList,
} from "../../common/slices/constantsSlice";
import {
  getContactIngestionEtlService,
  getTriggerCampaignEnabledOrgs,
} from "../../common/slices/featureFlagSlice";
import { routes } from "./dashboardRoutes";
import urls from "../../urls";
import ProtectedRoute from "../../components/ProtectedRoute";
import LayoutWithSharedComponents from "../../components/LayoutWithSharedComponents";
import { useCallback, useEffect, useMemo } from "react";
import { fetchSettings, getTenantTimezone } from "./settings/settingsSlice";
import FullStory from "react-fullstory";
import { FULLSTORY_KEY } from "../../common/constants";
import {
  isProduction,
  isUnsubLandingPage,
} from "../../common/helper/commonHelper";
import { selectAccount } from "../account/accountSlice";
import { listTagSummary } from "./tag/tagSlice";
import { abortAllExports } from "./export/exportSlice";
import Fallback from "../../components/Fallback";

const INFLECTION_EMAIL_DOMAIN = "@inflection.io";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { user } = useSelector(selectAccount);

  function isInflectionUserCheck(email: string) {
    return email && email.includes(INFLECTION_EMAIL_DOMAIN);
  }

  useEffect(() => {
    dispatch(getTriggerCampaignEnabledOrgs());
    dispatch(getContactIngestionEtlService());
    dispatch(getAuthedConstants());
    dispatch(getTenantTimezone());
    dispatch(getDomainList());
    dispatch(fetchSettings());
    dispatch(listTagSummary());
    return () => {
      dispatch(abortAllExports());
    };
  }, [dispatch]);

  const canEnableFullStory = useCallback((email: string) => {
    return (
      isProduction() &&
      email &&
      !isInflectionUserCheck(email) &&
      !isUnsubLandingPage()
    );
  }, []);

  useEffect(() => {
    if ((window as any).FS && user && canEnableFullStory(user.email)) {
      (window as any).FS.identify(user.id, {
        displayName: user.name,
        orgId: user.organisation_id,
        email: user.email,
      });
    }
  }, [canEnableFullStory, user]);

  const routesData = useMemo(
    () => (
      <Route path="/" element={<LayoutWithSharedComponents />}>
        {routes.map((route, i) => {
          if (route.protected) {
            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <ProtectedRoute
                    title={route.name}
                    component={route.component}
                  />
                }
              />
            );
          } else {
            return (
              <Route key={i} path={route.path} element={<route.component />} />
            );
          }
        })}
      </Route>
    ),
    []
  );

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements([
          routesData,
          <Route path="*" element={<Navigate replace to={urls.home} />} />,
        ])
      ),
    [routesData]
  );

  return (
    <>
      {canEnableFullStory(user?.email) && <FullStory org={FULLSTORY_KEY} />}
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </>
  );
}
