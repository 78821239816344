import { ButtonProps, Flex } from "@chakra-ui/react";
import IButton, { BUTTON } from "../../../../../components/IButton";

export default function ConnectionButtons({
  closeModal,
  label = "Proceed",
  ...props
}: ButtonProps & {
  label?: string;
  closeModal: () => void;
}) {
  return (
    <Flex justifyContent="flex-end" mt="20px">
      <IButton
        variant="ghost"
        onClick={closeModal}
        isDisabled={props.isLoading}
        name="cancel-button"
        mr={3}
        fontSize="12px"
      >
        Cancel
      </IButton>
      <IButton
        capitalize={false}
        fontSize="12px"
        p="10px"
        variant={BUTTON.PRIMARY}
        {...props}
      >
        {label}
      </IButton>
    </Flex>
  );
}
