import { Text } from "@chakra-ui/react";
import IModal from "../../../../components/IModal";

export default function CampaignModal({
  primaryButton,
  isOpenModal,
  onCancel,
  submitHandler,
  header,
  text,
}: {
  isOpenModal: boolean;
  onCancel: () => void;
  submitHandler: () => void;
  header: string;
  text: string;
  primaryButton: string;
}) {
  return (
    <IModal
      isOpen={isOpenModal}
      onClose={onCancel}
      header={{
        title: header,
      }}
      primaryButton={{
        label: primaryButton,
        props: {
          onClick: submitHandler,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: {
          onClick: onCancel,
        },
      }}
    >
      <Text fontSize="12px">{text}</Text>
    </IModal>
  );
}
