import {
  Box,
  Circle,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  InputProps,
  Tag,
  TagCloseButton,
  TagCloseButtonProps,
  TagLabel,
  TagLabelProps,
  TagProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { useCallback, useId, useMemo } from "react";
import { FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CODEMIRROR_SIZE } from "../common/constants/common";
import { isValidTokenOrColumnAccessor } from "../common/helper/codemirrorHelper";
import { CodeMirrorTypeWithAutoCompleteFilter } from "../common/types/common";
import { useChakraColors } from "../common/hooks/commonHooks";
import { selectEmailToken } from "../pages/dashboard/emailtoken/emailTokenSlice";
import OptionalCodemirrorInput from "./codemirror/OptionalCodemirrorInput";
import { BUTTON } from "./IButton";
import ITitle from "./ITitle";
import ReadOnlyList from "./ReadOnlyList";

export type ChipsStyleProps = {
  chipsProps?: TagProps;
  chipsLabelProps?: TagLabelProps;
  chipsCloseButtonProps?: TagCloseButtonProps;
};

export type ChipsInputProps = {
  labels: string[];
  onChipDelete: (index: number) => void;
  isClearable?: boolean;
  clearInputs?: () => void;
  errorMsg?: string;
  helperText?: string;
  isCodeMirror?: boolean;
  maxInputLength?: number;
  chipsContainerProps?: FlexProps;
  chipsProps?: ChipsStyleProps;
  helperTextProps?: TextProps;
  inputProps?: InputProps;
  isReadOnly?: boolean;
  readOnlyLabels?: string[];
} & CodeMirrorTypeWithAutoCompleteFilter;

export function Chips({
  label,
  allowTokens = false,
  chipsProps,
  chipsLabelProps,
  chipsCloseButtonProps,
  isDisabled,
  isReadOnly,
}: {
  label: string;
  allowTokens?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
} & ChipsStyleProps) {
  const {
    allColumnsList: { data: columnsList },
    emailTokenList: {
      listAll: { data: tokensList },
    },
  } = useSelector(selectEmailToken);

  const isValidToken = isValidTokenOrColumnAccessor(
    label as string,
    tokensList,
    columnsList
  );

  function getChipBgColor() {
    if (allowTokens && isValidToken.isValidEmailVariable) {
      if (isValidToken.isToken) return "blue.200";
      if (isValidToken.isColumnAccessor) return "green.200";
    }
    if (isReadOnly) return "white";
    return "gray.200";
  }

  return (
    <Tag
      size="md"
      borderRadius="full"
      mx={1}
      mt={2}
      bg={getChipBgColor()}
      border="1px solid"
      borderColor={isReadOnly ? "gray.200" : "transparent"}
      maxW="100%"
      {...chipsProps}
    >
      <TagLabel {...chipsLabelProps}>
        <Text isTruncated>{label}</Text>
      </TagLabel>
      <TagCloseButton
        {...chipsCloseButtonProps}
        hidden={chipsCloseButtonProps?.hidden || isDisabled}
      />
    </Tag>
  );
}

export default function ChipsInput({
  labels,
  onChipDelete,
  errorMsg,
  helperText,
  isCodeMirror = false,
  maxInputLength = 50,
  chipsContainerProps,
  chipsProps,
  helperTextProps,
  inputProps,
  isClearable,
  isDisabled,
  clearInputs,
  isReadOnly = false,
  readOnlyLabels,
  ...props
}: ChipsInputProps) {
  const { gray200, gray300, blue500, red500 } = useChakraColors();

  const uniqueId = useId();

  const getUniqueIds = useMemo(() => {
    return {
      inputId: "input-hidden-" + uniqueId,
      chipsInputId: "chips-input-" + uniqueId,
    };
  }, [uniqueId]);

  const onFocus = useCallback(
    (preventScroll = false) => {
      if (isCodeMirror) {
        const codeMirrorDiv = document
          .getElementById(getUniqueIds.chipsInputId)
          ?.getElementsByClassName("cm-content")[0] as HTMLDivElement;
        if (codeMirrorDiv) {
          codeMirrorDiv.focus({ preventScroll });
          codeMirrorDiv.click();
        }
      } else {
        const inputElement = document.getElementById(
          getUniqueIds.inputId
        ) as HTMLInputElement;
        inputElement?.focus({ preventScroll });
        inputElement?.click();
      }
    },
    [isCodeMirror, getUniqueIds]
  );

  return isReadOnly ? (
    <ReadOnlyList list={labels} />
  ) : (
    <Box width="100%">
      <Box maxWidth="100%" position="relative">
        <Flex
          scrollBehavior="smooth"
          bg="white"
          minHeight="100px"
          maxHeight="200px"
          overflowY="scroll"
          wrap="wrap"
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          _hover={{ borderColor: !errorMsg ? "gray.300" : "" }}
          css={{
            "::-webkit-scrollbar": {
              width: "4px",
            },
            "::-webkit-scrollbar-track": {
              background: "white",
              margin: "7px",
            },
            "::-webkit-scrollbar-thumb": {
              background: gray200,
              borderRadius: "2px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: gray300,
            },
            ":focus-within": {
              boxShadow: `0 0 0 1px ${blue500}`,
              borderColor: blue500,
            },
          }}
          border="1px solid"
          borderColor={errorMsg ? "red.500" : "gray.200"}
          boxShadow={errorMsg ? `0 0 0 1px ${red500}` : ""}
          onClick={() => onFocus()}
          id={getUniqueIds.chipsInputId}
          cursor="text"
          borderRadius="7px"
          px="2px"
          py="4px"
          {...chipsContainerProps}
        >
          {readOnlyLabels?.map((label, index) => {
            return (
              <Chips
                label={label}
                key={index}
                allowTokens={isCodeMirror}
                chipsCloseButtonProps={{ hidden: true }}
                isReadOnly
              />
            );
          })}

          {labels.map((label, index) => {
            return (
              <Chips
                label={label}
                key={index + (readOnlyLabels?.length ?? 0)}
                allowTokens={isCodeMirror}
                chipsCloseButtonProps={{
                  onClick: () => {
                    onFocus(true);
                    onChipDelete(index);
                  },
                }}
                isDisabled={isDisabled}
                {...chipsProps}
              />
            );
          })}

          {labels && labels.length < maxInputLength && (
            <OptionalCodemirrorInput
              type={CODEMIRROR_SIZE.SINGLE_LINE}
              isCodemirror={isCodeMirror}
              inputProps={{
                focusBorderColor: "transparent",
                borderColor: "transparent",
                _hover: { borderColor: "transparent" },
                w: "100%",
                h: "40px",
                ...inputProps,
                id: getUniqueIds.inputId,
                px: "8px",
                autoComplete: "off",
              }}
              {...props}
              style={{
                marginTop: "5px",
                fontSize: "14px",
              }}
              className="codemirror-normal-input codemirror-tag-input"
              reinitCodemirror={props.reinitialize}
              isDisabled={isDisabled}
            />
          )}
        </Flex>

        <HStack position="absolute" bottom="7px" right="4px" zIndex={2}>
          <Circle
            bg="gray.100"
            size="23px"
            fontSize="10px"
            border="1px solid gray"
          >
            {labels.length}
          </Circle>
          {isClearable && (
            <Box>
              <IconButton
                onClick={clearInputs}
                aria-label="Search"
                rounded="full"
                variant={BUTTON.SECONDARY}
                size="xs"
                icon={<FaTrash fontSize="10px" />}
                hidden={isDisabled}
              />
            </Box>
          )}
        </HStack>
      </Box>

      {errorMsg ? (
        <ITitle
          px={1}
          title={errorMsg}
          fontSize="12px"
          mt="8px"
          color="red.500"
          capitalize={false}
        />
      ) : (
        helperText && (
          <ITitle
            px={1}
            title={helperText}
            fontSize="12px"
            color="gray.400"
            mt="8px"
            {...helperTextProps}
          />
        )
      )}
    </Box>
  );
}
