import { HStack, Text, TextProps } from "@chakra-ui/react";
import { capitalize as capitalizeFn } from "lodash";
import ComingSoonText from "./ComingSoonText";

export default function ITitle({
  title,
  capitalize = true,
  isComingSoon = false,
  ...props
}: {
  title: string;
  capitalize?: boolean;
  isComingSoon?: boolean;
} & TextProps) {
  return (
    <HStack>
      <Text {...props}>{capitalize ? capitalizeFn(title) : title}</Text>
      {isComingSoon ? <ComingSoonText /> : null}
    </HStack>
  );
}
