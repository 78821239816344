import { VStack, Divider, Text } from "@chakra-ui/react";
import { convertToString } from "../../../../common/helper/commonHelper";
import ITitle from "../../../../components/ITitle";
import ISkeleton, { SKELETON_VARIANT } from "../../../../components/ISkeleton";

export function SyncStats({
  label,
  value,
  isLoading = false,
  isComingSoon = false,
}: {
  label: string;
  value: string | number | null;
  isLoading?: boolean;
  isComingSoon?: boolean;
}) {
  return (
    <VStack alignItems="left">
      <ITitle
        fontSize="sm"
        fontWeight="semibold"
        color="gray.400"
        title={label}
        isComingSoon={isComingSoon}
      />
      <ISkeleton
        variant={SKELETON_VARIANT.TEXT}
        isLoaded={!isLoading}
        noOfLines={1}
        w="80px"
        skeletonHeight={3}
      >
        <Text
          fontSize="md"
          fontWeight="semibold"
          color={isComingSoon ? "gray.400" : "gray.600"}
        >
          {convertToString(value, "-")}
        </Text>
      </ISkeleton>

      <Divider width="100px" height="2px" />
    </VStack>
  );
}
