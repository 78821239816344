import { Box, Flex, useBoolean, useMediaQuery } from "@chakra-ui/react";
import { createContext, useEffect } from "react";
import SideNavbar from "../components/SideNavbar";
import { Outlet } from "react-router-dom";

export const LayoutContext = createContext({ isSidebarCollapsed: false });

// Layout with NavBar (eg: app pages)
export default function LayoutWithSideNavBar(props: any) {
  const [collapsed, setCollapsed] = useBoolean(true);
  const [isLessThan1440] = useMediaQuery("(max-width: 1440px)");

  useEffect(() => {
    if (isLessThan1440) {
      setCollapsed.on();
    }
  }, [isLessThan1440, setCollapsed]);

  return (
    <LayoutContext.Provider value={{ isSidebarCollapsed: collapsed }}>
      <Flex>
        <SideNavbar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Box
          minH="100vh"
          maxH="100vh"
          overflow="auto"
          w={`calc(100vw - ${collapsed ? "80px" : "220px"})`}
          bg="grayV2.100"
          transition="width 0.1s ease-in"
        >
          {props.children || <Outlet />}
        </Box>
      </Flex>
    </LayoutContext.Provider>
  );
}
