import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  CAMPAIGN_CONTEXT,
  DynamicListType,
} from "../../../common/types/campaign";
import DynamicListPreview from "../../../components/dynamic-list/DynamicListPreview";
import DynamicList from "../../../components/dynamic-list/DynamicList";
import { toast } from "react-toastify";
import { validateDynamicList } from "./helper/validationHelper";
import IButton, { BUTTON } from "../../../components/IButton";
import { useState } from "react";
import { DISABLED_EDIT_CLASSNAME } from "../../../common/constants/campaign";
import { selectDynamicList } from "../../../components/dynamic-list/dynamicListSlice";

function DynamicListHeader({
  showDynamicListPreview,
}: {
  showDynamicListPreview: () => void;
}) {
  return (
    <Flex justifyContent="flex-end" alignItems="center" mb="2">
      <IButton
        size="sm"
        leftIcon={<FaEye />}
        onClick={showDynamicListPreview}
        name="preview-dl"
        variant={BUTTON.SECONDARY}
      >
        Preview Dynamic List
      </IButton>
    </Flex>
  );
}

export default function CampaignDynamicList({
  dynamicList,
  dynamicListId,
  setDynamicList,
  saveDraft,
  activeErrorCheck,
  isReadOnly,
  campaignContext,
}: {
  dynamicListId?: string;
  dynamicList: DynamicListType[];
  setDynamicList: (data: DynamicListType[]) => void;
  saveDraft: (data: DynamicListType[], skipDebounce?: boolean) => void;
  activeErrorCheck: boolean;
  isReadOnly?: boolean;
  campaignContext?: CAMPAIGN_CONTEXT;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { operatorsArgCount } = useSelector(selectDynamicList);
  const [showErrors, setShowErrors] = useState(false);

  function validateAndContinue(
    callback: (param?: any) => void,
    ...args: any[]
  ) {
    const { dynamicListValid, dynamicListValidated } = validateDynamicList(
      dynamicList,
      operatorsArgCount
    );
    setShowErrors(false);
    setDynamicList(dynamicListValidated);
    if (dynamicListValid) {
      saveDraft(dynamicListValidated, true);
      callback.apply(null, [args[0]]);
    } else {
      setShowErrors(true);
      toast.warn("Some fields are invalid");
    }
  }

  return (
    <>
      <DynamicListHeader
        showDynamicListPreview={() => validateAndContinue(onOpen)}
      />
      <Box className={isReadOnly ? DISABLED_EDIT_CLASSNAME : ""} w="100%">
        <DynamicList
          dynamicListData={dynamicList}
          onChange={(data) => {
            setDynamicList(data);
            saveDraft(data);
          }}
          activeErrorCheck={activeErrorCheck || showErrors}
          campaignContext={campaignContext}
        />
      </Box>
      <DynamicListPreview
        dynamicListId={dynamicListId}
        dynamicListQuery={dynamicList}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}
