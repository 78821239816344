import { Checkbox, Heading, VStack, Text, Divider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import InputFieldWithError from "../../../../../components/InputFieldWithError";
import MemoizedCommonDrawer from "../../../campaign/components/CommonDrawer";
import ConnectionButtons from "../../connectionDetails/components/ConnectionButtons";

export default function SalesforceConnectionModal({
  isOpen,
  onClose,
  submit,
  isInitializingConnection,
}: {
  isOpen: boolean;
  onClose: () => void;
  submit: (connectionName: string) => void;
  isInitializingConnection?: boolean;
}) {
  const [adminAccess, setAdminAccess] = useState(false);
  const [apiAccess, setApiAccess] = useState(false);
  const [connectionName, setConnectionName] = useState("");
  const [invalidCredsErrorMsg, setInvalidCredsErrorMsg] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setAdminAccess(false);
      setApiAccess(false);
      setConnectionName("");
      setInvalidCredsErrorMsg("");
    }
  }, [isOpen]);

  function submitHandler() {
    submit(connectionName);
    //drawer shouldn't close immediately
    setTimeout(() => {
      !isInitializingConnection && onClose();
    }, 1000);
  }

  return (
    <MemoizedCommonDrawer
      size="sm"
      placement="right"
      title="Salesforce"
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack alignItems="flex-start" spacing="4">
        <Heading as="h4" fontSize="md">
          Ready to connect Salesforce and Inflection?
        </Heading>
        <Text fontSize="sm">
          There are a few steps to follow, but the process should only take
          about 10-15 minutes. Before you start, make sure:
        </Text>

        <Checkbox
          isChecked={adminAccess}
          onChange={(e) => setAdminAccess(e.target.checked)}
          mt={12}
        >
          <Text fontSize="sm">
            Have administrator access to your Salesforce account
          </Text>
        </Checkbox>

        <Checkbox
          isChecked={apiAccess}
          onChange={(e) => setApiAccess(e.target.checked)}
          mt={12}
        >
          <Text fontSize="sm">Your Salesforce edition has API access</Text>
        </Checkbox>

        <Divider />
        <InputFieldWithError
          labelText="Connection name"
          errorMsg={invalidCredsErrorMsg}
          value={connectionName}
          onChange={(e) => setConnectionName(e.target.value)}
          isRequired={true}
          autoFocus
        />
      </VStack>
      <ConnectionButtons
        closeModal={onClose}
        label="Save and login to Salesforce"
        name="save-button"
        isLoading={isInitializingConnection}
        onClick={submitHandler}
        isDisabled={!apiAccess || !adminAccess || !connectionName}
      />
    </MemoizedCommonDrawer>
  );
}
