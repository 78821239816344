import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount, validateToken } from "./pages/account/accountSlice";
import { getAuthToken } from "./common/helper/authHelper";
import Dashboard from "./pages/dashboard/Dashboard";
import Landing from "./pages/account/Landing";
import { Global, css } from "@emotion/react";
import "reactflow/dist/style.css";
import VersionMismatchBanner from "./components/VersionMismatchBanner";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export default function App() {
  const { isAuthenticated } = useSelector(selectAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(validateToken());
  }, [dispatch]);

  return (
    <>
      <Global styles={GlobalStyles} />
      <VersionMismatchBanner />
      {getAuthToken() || isAuthenticated ? <Dashboard /> : <Landing />}
    </>
  );
}
