import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import {
  createSegmentReadApiKey,
  deactivateSegmentReadApiKey,
  resetSegmentSyncData,
  selectConnection,
} from "../../connectionSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../store";
import SelectSegmentConnectionType from "./SelectSegmentConnectionType";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import urls from "../../../../../urls";
import SegmentSyncModals from "./SegmentSyncs";

export default function SegmentConnectionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [modal, setModal] = useState<0 | 1>(0);

  const {
    segment: {
      createSync: {
        readApiKey: { data },
      },
    },
  } = useSelector(selectConnection);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const segmentSyncActions = useCallback(
    (action: "cancel" | "save" | "next" | "prev") => {
      switch (action) {
        case "cancel":
          setModal(0);
          onClose();
          dispatch(resetSegmentSyncData());
          if (data.id) {
            dispatch(deactivateSegmentReadApiKey(data.id));
          }
          break;

        case "save":
          setModal(0);
          onClose();
          dispatch(resetSegmentSyncData());
          navigate(urls.segment);
          break;

        case "next":
          setModal(1);
          dispatch(createSegmentReadApiKey());
          break;

        case "prev":
          setModal(0);
          dispatch(deactivateSegmentReadApiKey(data.id));
          break;
      }
    },
    [data.id, dispatch, navigate, onClose]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => segmentSyncActions("cancel")}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        {modal ? (
          <SegmentSyncModals
            onSave={() => segmentSyncActions("save")}
            onCancel={() => segmentSyncActions("cancel")}
            goToPrevStep={() => segmentSyncActions("prev")}
          />
        ) : (
          <SelectSegmentConnectionType
            onCancel={() => segmentSyncActions("cancel")}
            goToNextStep={() => segmentSyncActions("next")}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
