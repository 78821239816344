import { useSelector } from "react-redux";
import { setActivityPage, selectPerson } from "../personDbSlice";
import ReactJson from "react-json-view";
import { DataTable } from "../../../../components/data-table/DataTable";
import { PersonEvent } from "../../../../common/types/person";
import { createColumnHelper } from "@tanstack/react-table";
import { useAppDispatch } from "../../../../store";
import { FormatDate } from "../../../../components/DateTimeRangeFilter";
import { Box, Text } from "@chakra-ui/react";

const columnHelper = createColumnHelper<PersonEvent>();

const columns = [
  columnHelper.accessor("event_name", {
    header: "Event Name",
    size: 400,
  }),
  columnHelper.accessor("event_date", {
    header: "Date",
    cell: (data) => (
      <FormatDate date={data.getValue()} showTime={true} splitLines={true} />
    ),
    size: 250,
  }),
  columnHelper.accessor("event_properties", {
    header: "Event Properties",
    cell: (info) => {
      const jsonData = JSON.parse(info.getValue());
      return jsonData ? (
        <ReactJson
          src={jsonData}
          collapsed={true}
          enableClipboard={false}
          name={false}
        />
      ) : (
        <Text>null</Text>
      );
    },
  }),
];

export default function PersonActivities() {
  const { personActivity } = useSelector(selectPerson);
  const dispatch = useAppDispatch();

  return (
    <Box h="calc(100vh - 50px)">
      <DataTable
        fetchingList={
          personActivity.fetchingList || personActivity.totalPageCount === null
        }
        changingPage={personActivity.changingPage}
        list={personActivity.list}
        totalPageSize={personActivity.pageSize}
        totalPageCount={personActivity.totalPageCount}
        currentPage={personActivity.currentPageNo}
        setPage={(pageNo) => dispatch(setActivityPage(pageNo))}
        columns={columns}
        emptyMsg="No activities found"
      />
    </Box>
  );
}
