import {
  Box,
  BoxProps,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useTheme,
  useOutsideClick,
  HStack,
  InputProps,
} from "@chakra-ui/react";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { format } from "date-fns";
import {
  DateRangePicker,
  Range,
  createStaticRanges,
  StaticRange,
} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaCalendarAlt } from "react-icons/fa";
import IButton, { BUTTON } from "./IButton";
import { MAPPED_CUSTOM_DATE_RANGES } from "../common/constants/common";
import {
  getCustomDateRangeLabel,
  getTextWidth,
} from "../common/helper/commonHelper";

export default function CustomDateRangePicker({
  startDate,
  endDate,
  onDateRangeChange,
  inputProps,
  ...props
}: {
  startDate: Date;
  endDate: Date;
  onDateRangeChange: (start: Date, end: Date) => void;
  inputProps?: InputProps;
} & BoxProps) {
  const INPUT_RANGE = useMemo(() => {
    return {
      startDate,
      endDate,
      key: "selection",
    };
  }, [startDate, endDate]);

  const [dateRange, setDateRange] = useState<Range>(INPUT_RANGE);
  const [displayLabel, setDisplayLabel] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dateRangeRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const defaultCustomRanges: StaticRange[] = createStaticRanges(
    MAPPED_CUSTOM_DATE_RANGES
  );

  useOutsideClick({
    ref: dateRangeRef,
    handler: () => {
      setLabelToDisplay(INPUT_RANGE.startDate, INPUT_RANGE.endDate);
      setDateRange(INPUT_RANGE);
      onClose();
    },
  });

  const setLabelToDisplay = useCallback((start: Date, end: Date) => {
    setDisplayLabel(getCustomDateRangeLabel(start, end));
  }, []);

  useEffect(() => {
    setLabelToDisplay(startDate, endDate);
  }, [startDate, endDate, setLabelToDisplay]);

  function onSubmit() {
    // make enddate inclusive for custom date ranges alone
    if (!displayLabel) {
      dateRange.endDate?.setHours(23);
      dateRange.endDate?.setMinutes(59);
      dateRange.endDate?.setSeconds(59);

      dateRange.startDate?.setHours(0);
      dateRange.startDate?.setMinutes(0);
      dateRange.startDate?.setSeconds(0);
    }

    onDateRangeChange(
      dateRange.startDate ?? INPUT_RANGE.startDate,
      dateRange.endDate ?? INPUT_RANGE.endDate
    );
    onClose();
  }

  function onClear() {
    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setDisplayLabel("");
  }

  const formatDate = useCallback((date: Date | undefined) => {
    return format(date ?? 0, "MMM dd,yyyy");
  }, []);

  const inputValue = displayLabel
    ? displayLabel
    : `${formatDate(dateRange.startDate)} - ${formatDate(dateRange.endDate)}`;

  return (
    <Box position="relative" display="inline-block">
      <InputGroup
        size="sm"
        width={`calc(${getTextWidth(inputValue, 14) + "px"} + 70px)`}
        maxWidth="225px"
        onClick={() => onOpen()}
        borderColor={isOpen ? "blue.500" : "gray.200"}
      >
        <InputRightElement>
          <Icon
            as={FaCalendarAlt}
            color="gray.400"
            fontSize="14px"
            cursor="pointer"
          />
        </InputRightElement>
        <Input
          value={inputValue}
          readOnly
          bg="white"
          cursor="pointer"
          fontSize="14px"
          borderRadius="5px"
          {...inputProps}
        />
      </InputGroup>
      {isOpen && (
        <Box
          ref={dateRangeRef}
          bg="white"
          position="absolute"
          transform="translateX(-100%)"
          left={`calc(${getTextWidth(inputValue, 14) + "px"} + 70px)`}
          top="35px"
          height="365px"
          border="1px solid lightgray"
          borderRadius="3px"
          boxShadow="inset 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.2)"
          zIndex="999"
          {...props}
        >
          <DateRangePicker
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            retainEndDateOnFirstSelection
            editableDateInputs
            ranges={[dateRange]}
            fixedHeight={true}
            onChange={(range) => {
              setDateRange(range.selection);
              setLabelToDisplay(
                range.selection.startDate ?? INPUT_RANGE.startDate,
                range.selection.endDate ?? INPUT_RANGE.endDate
              );
            }}
            inputRanges={[]}
            staticRanges={defaultCustomRanges}
            rangeColors={[theme.__cssVars["--chakra-colors-blue-700"]]}
          />

          <HStack position="relative" bottom="-35%" float="right" mr={2}>
            <IButton variant={BUTTON.SECONDARY} onClick={onClear} mr={1}>
              Clear
            </IButton>
            <IButton variant={BUTTON.PRIMARY} onClick={onSubmit}>
              Apply
            </IButton>
          </HStack>
        </Box>
      )}
    </Box>
  );
}
