import { TypeOptions, toast } from "react-toastify";

type errorMessageType = {
  timeStamp: Date;
  message: string;
};

type apiTimestampDictType = {
  [errorMessage: string]: errorMessageType;
};

let apiErrorsDict: apiTimestampDictType = {};

const TIME_GAP_BETWEEN_TOAST_ERRORS = 10000;
const ONE_HOUR_MS = 1000 * 60 * 60;

function isOlderThan(timeStamp: Date, timeDuration: number) {
  return new Date().valueOf() - timeStamp.valueOf() > timeDuration;
}

export const cleanUpApiErrorDict = () => {
  for (const key in apiErrorsDict) {
    if (
      isOlderThan(apiErrorsDict[key].timeStamp, TIME_GAP_BETWEEN_TOAST_ERRORS)
    ) {
      delete apiErrorsDict[key];
    }
  }
};

setInterval(() => {
  cleanUpApiErrorDict();
}, ONE_HOUR_MS);

export const consolidateApiErrors = function (
  toastMessage: string,
  toastType?: TypeOptions
) {
  const currentTime: Date = new Date();
  let showToast = true;
  if (apiErrorsDict[toastMessage]) {
    const timeStamp = apiErrorsDict[toastMessage].timeStamp;
    if (!isOlderThan(timeStamp, TIME_GAP_BETWEEN_TOAST_ERRORS)) {
      showToast = false;
    }
  }

  if (showToast) {
    apiErrorsDict[toastMessage] = {
      timeStamp: currentTime,
      message: toastMessage,
    };
    toast(apiErrorsDict[toastMessage].message, { type: toastType });
  }
};

const RETRY_STATUS_CODES = [503, 504];

export const checkErrorStatusCode = function (error: any) {
  return RETRY_STATUS_CODES.includes(error.response.status);
};

export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}
