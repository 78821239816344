import { Text, Box } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { TOKEN_RETURN_TYPE } from "../../../../common/constants/token";

export default function TokenPreviewData({
  data,
  returnType,
}: {
  data: any;
  returnType: string;
}) {
  const isArray = returnType === TOKEN_RETURN_TYPE.ARRAY;
  const arrayLimit = 10;
  const showCustomObjectSize = isArray && data?.length > arrayLimit;

  if (
    [TOKEN_RETURN_TYPE.ARRAY, TOKEN_RETURN_TYPE.HASHMAP].includes(
      returnType as TOKEN_RETURN_TYPE
    ) &&
    data
  ) {
    return (
      <Box>
        <ReactJson
          style={{ maxWidth: "100%", overflowX: "auto" }}
          src={isArray ? data.slice(0, arrayLimit) : data}
          collapsed={true}
          enableClipboard={false}
          collapseStringsAfterLength={100}
          displayObjectSize={!showCustomObjectSize}
        />
        {showCustomObjectSize && (
          <Text
            style={{
              color: "rgba(0, 0, 0, 0.3)",
              fontStyle: "italic",
              margin: "4px 6px 0px 0px",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            {`showing ${arrayLimit} out of ${data.length} items ...`}
          </Text>
        )}
      </Box>
    );
  } else {
    return <Box>{String(data)}</Box>;
  }
}
