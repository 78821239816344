import { isEmpty } from "lodash";
import {
  COMPARISON_OPERATORS,
  COMPARISON_OPERATORS_ARGUMENT_COUNT,
  OPERATOR_ARG_COUNT,
  TIME_FRAME_RELATIVE_OPERATOR,
  TRIGGER_DL_NODE_TYPES,
} from "../../../../common/constants/dynamicList";
import { isBlank } from "../../../../common/helper/commonHelper";
import {
  ComparisonNode,
  Condition,
  ConditionGroup,
  FrequencyCondition,
  NodeCriteriaTypes,
  NodeGroup,
  Pattern,
  Query,
  SequencePatternNode,
  TargetValueTypes,
  TimeframeCondition,
} from "../../../../common/types/dynamicList";
import { isGroupNode } from "../../../../common/helper/trigger";

export function validateAudienceCriteria(audienceCriteria: Query | null) {
  if (!audienceCriteria || !audienceCriteria?.nodeGroups?.length) {
    return true;
  } else {
    const isNodeGroupsValid = audienceCriteria.nodeGroups.every((nodeGroup) =>
      validateNodeGroup(nodeGroup)
    );
    return isNodeGroupsValid;
  }
}

export function validateTriggerCriteria(triggerCriteria: Query | null) {
  if (!triggerCriteria?.nodeGroups?.length) {
    return false;
  } else {
    const isNodeGroupsValid = triggerCriteria.nodeGroups.every((nodeGroup) =>
      validateNodeGroup(nodeGroup)
    );
    return isNodeGroupsValid;
  }
}

export function validateExitCriteria(exitCriteria: Query | null) {
  if (!exitCriteria || !exitCriteria?.nodeGroups[0]?.nodes?.length) {
    return true;
  } else if (!exitCriteria?.nodeGroups?.length) {
    return false;
  } else {
    const isNodeGroupsValid = exitCriteria.nodeGroups.every((nodeGroup) =>
      validateNodeGroup(nodeGroup)
    );
    return isNodeGroupsValid;
  }
}

export function validateNodeGroup(nodeGroup: NodeGroup) {
  if (isGroupNode(nodeGroup.nodeGroupType) && !nodeGroup.groupOperator) {
    return false;
  }
  if (!nodeGroup.nodes.length) {
    return false;
  } else {
    const isNodesValid = nodeGroup.nodes.every((node) => validateNode(node));
    return isNodesValid;
  }
}

export function validateNode(node: NodeCriteriaTypes) {
  switch (node.nodeType) {
    case TRIGGER_DL_NODE_TYPES.LIST_MEMBERSHIP:
      const condition = node.listMembershipCondition;
      return !!(
        condition.membershipOperator &&
        condition.listId &&
        condition.listType
      );

    case TRIGGER_DL_NODE_TYPES.PERSON:
      node = node as ComparisonNode;
      const personConditionGroups = node.conditionGroups;
      if (!personConditionGroups.length) return false;
      const isPersonConditionGroupsValid = personConditionGroups.every(
        (conditionGroup) => validateConditionGroup(conditionGroup)
      );
      return isPersonConditionGroupsValid;

    case TRIGGER_DL_NODE_TYPES.COMPARISON:
      node = node as ComparisonNode;
      if (!node.eventName) return false;
      if (
        node.frequencyCondition &&
        !validateFrequencyFilter(node.frequencyCondition)
      ) {
        return false;
      }
      if (node.timeframe && !validateTimeFrame(node.timeframe)) {
        return false;
      }
      const conditionGroups = node.conditionGroups;
      const isConditionGroupsValid = conditionGroups.every((conditionGroup) =>
        validateConditionGroup(conditionGroup)
      );
      return !(conditionGroups?.length && !isConditionGroupsValid);

    case TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN:
      node = node as SequencePatternNode;
      const patternGroups = node.patternGroup;
      const isPatternGroupsValid = patternGroups.every((patternGroup) =>
        validatePatternGroup(patternGroup)
      );
      return !(patternGroups?.length && !isPatternGroupsValid);
  }
}

export function validatePatternGroup(pattern: Pattern) {
  if (!pattern.eventName) return false;
  const conditionGroups = pattern.conditionGroups;
  const isConditionGroupsValid = conditionGroups.every((conditionGroup) =>
    validateConditionGroup(conditionGroup)
  );
  return !(conditionGroups?.length && !isConditionGroupsValid);
}

export function validateConditionGroup(conditionGroup: ConditionGroup) {
  if (!conditionGroup.conditions.length) return false;
  const isValidConditions = conditionGroup.conditions.every((condition) =>
    validateCondition(condition)
  );
  return isValidConditions;
}

export function validateCondition(condition: Condition) {
  if (!condition.propertyName) {
    return false;
  } else {
    return validateOperatorAndValue(
      condition.comparisonOperator,
      condition.targetValue
    );
  }
}

export function validateOperatorAndValue(
  comparisonOperator: COMPARISON_OPERATORS | null,
  targetValue: TargetValueTypes | (number | null)[]
) {
  if (!comparisonOperator) {
    return false;
  } else {
    const argumentCount =
      COMPARISON_OPERATORS_ARGUMENT_COUNT[comparisonOperator];
    if (argumentCount === OPERATOR_ARG_COUNT.ZERO) {
      return true;
    } else {
      if (isEmpty(targetValue) || targetValue?.some((val) => isBlank(val))) {
        return false;
      }
      switch (argumentCount) {
        case OPERATOR_ARG_COUNT.ONE:
          return targetValue?.length !== 1;
        case OPERATOR_ARG_COUNT.TWO:
          return targetValue?.length !== 2;
      }
    }
    return true;
  }
}

export function validateFrequencyFilter(frequencyFilter: FrequencyCondition) {
  return validateOperatorAndValue(
    frequencyFilter.comparisonOperator,
    frequencyFilter.targetValue
  );
}

export function validateTimeFrame(timeFrame: TimeframeCondition) {
  if (timeFrame.type && timeFrame.operator && timeFrame.unit) {
    switch (timeFrame.operator) {
      case TIME_FRAME_RELATIVE_OPERATOR.PAST:
        return !!timeFrame.start;

      case COMPARISON_OPERATORS.EQUALS:
      case COMPARISON_OPERATORS.NOT_EQUALS:
      case COMPARISON_OPERATORS.GT:
      case COMPARISON_OPERATORS.GTE:
      case COMPARISON_OPERATORS.LT:
      case COMPARISON_OPERATORS.LTE:
        return timeFrame.start || timeFrame.end;

      case COMPARISON_OPERATORS.BETWEEN_INCLUSIVE:
      case COMPARISON_OPERATORS.NOT_BETWEEN_INCLUSIVE:
        return timeFrame.start && timeFrame.end;
    }
  }

  return false;
}
