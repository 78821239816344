import { VStack } from "@chakra-ui/react";
import ReactJson from "react-json-view";
import { EMPTY_CONTEXT } from "../../../../common/constants/common";
import { FormError } from "../../../../common/types/form";
import LabelValuePair from "../../../../components/LabelValuePair";
import MemoizedCommonDrawer from "../../campaign/components/CommonDrawer";
import FormProviderWithLogo from "./FormProviderWithLogo";

export default function ErrorsSidebar({
  selectedError,
  onClose,
}: {
  selectedError: FormError | null;
  onClose: () => void;
}) {
  return (
    <MemoizedCommonDrawer
      title="Error details"
      placement="right"
      isOpen={!!selectedError}
      onClose={onClose}
      size="sm"
    >
      <VStack alignItems="flex-start" spacing="5">
        <LabelValuePair label="ID" value={selectedError?.id} />
        <LabelValuePair
          label="Form Provider"
          value={
            selectedError?.vendor ? (
              <FormProviderWithLogo vendor={selectedError.vendor} />
            ) : (
              EMPTY_CONTEXT
            )
          }
        />
        <LabelValuePair
          label="Error Date"
          value={selectedError?.received_date}
        />
        <LabelValuePair
          label="Submission Data"
          value={
            <ReactJson
              src={
                selectedError?.submission
                  ? JSON.parse(selectedError?.submission)
                  : ""
              }
              collapsed={false}
              enableClipboard={false}
              name={false}
            />
          }
        />
      </VStack>
    </MemoizedCommonDrawer>
  );
}
