import { Alert, AlertIcon, ModalProps } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import GlobalEmailConfiguration from "../pages/dashboard/settings/components/GlobalEmailConfiguration";
import {
  createGlobalEmailConfig,
  selectSettings,
} from "../pages/dashboard/settings/settingsSlice";
import IModal from "./IModal";
import { cloneDeep, set, isNull } from "lodash";
import {
  EMAIL_CONFIG_ERRORS_INIT,
  EMAIL_CONFIG_FIELDS,
  GLOBAL_EMAIL_CONFIG_INIT,
  GLOBAL_EMAIL_CONFIG_VALID_FIELDS,
  GLOBAL_EMAIL_SENDER_META,
} from "../common/constants/template";
import { GlobalEmailConfigType } from "../common/types/campaign";
import { CcBccListType } from "../common/types/template";
import { useState } from "react";
import { validateEmailConfigInputs } from "../common/helper/commonHelper";
import { useAppDispatch } from "../store";

export default function GlobalDefaultsModal({
  isOpen,
  onClose,
  ...props
}: {
  isOpen: boolean;
  onClose: () => void;
} & Partial<ModalProps>) {
  const { globalDefault } = useSelector(selectSettings);
  const [globalEmailConfig, setGlobalEmailConfig] =
    useState<GlobalEmailConfigType | null>(globalDefault.data);
  const [errors, setErrors] = useState(EMAIL_CONFIG_ERRORS_INIT);

  const dispatch = useAppDispatch();

  function onChangeEmailConfig(
    name: EMAIL_CONFIG_FIELDS,
    value: string | CcBccListType
  ) {
    const newEmailConfig = isNull(globalEmailConfig)
      ? GLOBAL_EMAIL_CONFIG_INIT
      : cloneDeep(globalEmailConfig);
    set(newEmailConfig, name, value);

    setGlobalEmailConfig((prev) => newEmailConfig);

    setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  function onSubmit() {
    if (globalEmailConfig) {
      const { validity, errors } = validateEmailConfigInputs(
        {
          ...globalEmailConfig,
          ...GLOBAL_EMAIL_SENDER_META,
        },
        GLOBAL_EMAIL_CONFIG_VALID_FIELDS
      );
      if (validity) {
        dispatch(createGlobalEmailConfig(globalEmailConfig));
      }
      setErrors(errors);
    }
  }

  return (
    <IModal
      isOpen={isOpen}
      modalContentProps={{ minW: "xl" }}
      onClose={onClose}
      header={{ title: "Global email configuration" }}
      primaryButton={{
        label: "Save configurations",
        props: {
          isLoading: globalDefault.updating,
          onClick: onSubmit,
        },
      }}
      secondaryButton={{
        label: "Cancel",
        props: { onClick: onClose, isLoading: globalDefault.updating },
      }}
      {...props}
    >
      <>
        <Alert status="warning" fontSize="14px">
          <AlertIcon />
          Configuring the global email settings is mandatory before you start
          creating emails or journeys. These settings apply to all email
          templates under this organisation.
        </Alert>
        <GlobalEmailConfiguration
          emailConfig={globalEmailConfig}
          onChange={onChangeEmailConfig}
          errors={errors}
          additionalCcBccContent={<></>}
        />
      </>
    </IModal>
  );
}
