import Rollbar from "rollbar";
import { ROLLBAR_ACCESS_TOKEN, ROLLBAR_DATA_KEY } from "../constants";
import { isProduction } from "./commonHelper";
import { RollbarDataType } from "../types/common";
import { INIT_ROLLBAR_DATA } from "../constants/common";

//rollbar configuration
const rollbarConfig: Rollbar.Configuration = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  includeItemsInTelemetry: false, //exclude previously logged items to Rollbar in the queue of telemetry events
  captureUncaught: true, //Capture uncaught exceptions
  captureUnhandledRejections: true, //Capture unhandled rejections
  enabled: isProduction(),
  autoInstrument: {
    //events to automatically collect
    network: true,
    navigation: true,
    dom: true,
    connectivity: true,
    log: false,
  },
  payload: {
    environment: process.env.NODE_ENV,
    person: INIT_ROLLBAR_DATA,
  },
};

export let rollbar = new Rollbar(rollbarConfig);

export function getRollbarData(): RollbarDataType | null {
  const rollbarData = localStorage.getItem(ROLLBAR_DATA_KEY);
  return rollbarData ? JSON.parse(rollbarData) : null;
}

export function setRollbarData(rollbarData: RollbarDataType): void {
  localStorage.setItem(ROLLBAR_DATA_KEY, JSON.stringify(rollbarData));
}

export function removeRollbarData(): void {
  localStorage.removeItem(ROLLBAR_DATA_KEY);
}
