import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CONTACTS_INGESTION_SERVICE } from "../types/featureFlag";
import { LoadingWithData } from "../types/common";
import {
  createAsyncThunkWrapper,
  initializeLoadingData,
} from "../helper/commonHelper";
import { TRIGGER_ENABLED_TENANTS_URL } from "../constants";
import { getContactIngestionEtlServiceApi } from "../api/integrations/generic";
import { LOADING_STATES } from "../constants/common";

type FeatureFlagInitState = {
  contactIngestionEtl: LoadingWithData<null | CONTACTS_INGESTION_SERVICE>;
  triggerCampaignEnabledOrgs: {
    tenants: number[];
  };
};

const initialState: FeatureFlagInitState = {
  contactIngestionEtl: initializeLoadingData(null),
  triggerCampaignEnabledOrgs: {
    tenants: [],
  },
};

export const getContactIngestionEtlService = createAsyncThunkWrapper({
  actionName: "contact-etl",
  dispatchFn: async () => {
    return await getContactIngestionEtlServiceApi();
  },
  isCachable: true,
});

export const getTriggerCampaignEnabledOrgs = createAsyncThunkWrapper({
  actionName: "feature-flag/triggerCampaignTenants",
  dispatchFn: async () => {
    const response = await fetch(TRIGGER_ENABLED_TENANTS_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  },
  isCachable: true,
});

const featureFlagSlice = createSlice({
  name: "featureFlag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTriggerCampaignEnabledOrgs.fulfilled, (state, action) => {
        state.triggerCampaignEnabledOrgs = action.payload;
      })

      .addCase(getContactIngestionEtlService.pending, (state) => {
        state.contactIngestionEtl.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getContactIngestionEtlService.fulfilled,
        (state, { payload }) => {
          state.contactIngestionEtl = {
            data: payload.etl,
            loading: LOADING_STATES.SUCCESS,
          };
        }
      )
      .addCase(getContactIngestionEtlService.rejected, (state) => {
        state.contactIngestionEtl = {
          data: null,
          loading: LOADING_STATES.FAILED,
        };
      });
  },
});

export const selectFeatureFlag = (state: RootState) => state.featureFlag;
export default featureFlagSlice.reducer;
