export interface AuthResp {
  token: Token;
  account: Auth;
  roles: RoleType[];
  organisation: Organisation;
}

export type Roles = "CUSTOMER_ORG_ADMIN" | "CUSTOMER_ORG_USER";

export interface RoleType {
  role_id: number;
  role_name: Roles;
}

export interface Auth {
  id: number;
  organisation_id: number;
  name: string;
  email: string;
  is_active: boolean;
  is_email_verified: boolean;
  is_federated_user: boolean;
  roles: RoleType[];
  time_created: string;
  time_updated: string;
  status: USER_ACCOUNT_STATUS;
}

export interface Organisation {
  id: number;
  name: string;
  slug: string;
  domain: string;
  is_active: boolean;
  time_created: string;
  time_updated: string;
}

export interface Token {
  account_id: number;
  organisation_id: number;
  time_created: string;
  time_expiry: string;
  token: string;
  ttl: number;
  has_expired: boolean;
}

export interface SamlConfigResp {
  id?: number;
  organisation_id: number;
  created_by_id: number;
  entity_id: string;
  sso_url: string;
  certificate: string;
  idp: null;
  time_created: string;
  time_updated: string;
}
export interface SamlConfigReq {
  id?: number;
  entity_id: string;
  sso_url: string;
  certificate: string;
}

export interface RegisterResp {
  account: Auth;
  organisation: Organisation;
}

export interface InvitedUser {
  email: string;
  request_id: string;
  name: string;
  account_id: number;
  request_time: string;
  request_type: string;
  request_expiry: string;
  completed: boolean;
  completed_time: string | null;
  extra_data: {
    invited_by_email: string;
    invited_by_name: string;
    organisation: string;
    ttl: number;
  };
}

export interface UserInviteType {
  name: string;
  email: string;
  role_names: Roles[];
}

export interface ApiKey {
  id: string;
  label: string;
  key_type: string;
  state: string;
  created_by_account_id: number;
  time_created: string;
  time_updated: string;
}

export interface ApiKeyData extends ApiKey {
  api_key: string;
}

export interface UserListMap {
  [id: string]: Auth;
}

export enum USER_ACCOUNT_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
  ERROR = "error",
}
