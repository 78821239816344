import { HStack, Text } from "@chakra-ui/react";
import { CONNECTOR } from "../../common/types/campaign";
import DynamicListLogicGate from "./DynamicListLogicGate";

export default function PropertyGroupsHeader({
  title,
  subTitle,
  connector,
  onConnectorChange,
  displayConnector = true,
  isReadOnly,
}: {
  connector: CONNECTOR;
  onConnectorChange: (connector: CONNECTOR) => void;
  title?: string;
  subTitle?: string;
  isReadOnly?: boolean;
  displayConnector?: boolean;
}) {
  const style = title ? { spacing: 2, pt: 2 } : { spacing: 0 };

  return (
    <HStack {...style}>
      <Text
        fontWeight={700}
        hidden={!title}
        fontSize={isReadOnly ? "xs" : "sm"}
      >
        {title}
      </Text>
      {displayConnector && !isReadOnly && (
        <HStack justifyContent="flex-start">
          <Text borderLeft="1px solid" borderLeftColor="gray.400" pl="10px">
            {subTitle}
          </Text>
          <DynamicListLogicGate
            operator={connector}
            handleChange={onConnectorChange}
            isReadOnly={isReadOnly}
            connectorProps={{ bg: "white" }}
          />
        </HStack>
      )}
    </HStack>
  );
}
