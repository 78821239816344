import {
  CAMPAIGN_TARGET,
  JOURNEY_TYPES,
  TRIGGER_CAMPAIGN_STATES,
} from "../../constants/trigger";
import {
  ApiResponseWithMeta,
  ApiResponseWithPaginationMeta,
  ListFilterParams,
} from "../../types/common";
import {
  TriggerCampaign,
  UpdatableDataWithTriggerStatus,
} from "../../types/trigger";
import { realtimeCampaignRequest } from "./api";

const BASIC_URL = "campaigns";
const ID_PLACEHOLDER = "campaignId";
function getTriggerApiEndpoints(campaignId = "") {
  let urls: { [key: string]: string } = {
    basicUrl: `${BASIC_URL}`,
    basicUrlWithId: `${BASIC_URL}/${ID_PLACEHOLDER}`,
    pauseUrl: `${BASIC_URL}/${ID_PLACEHOLDER}/pause`,
    resumeUrl: `${BASIC_URL}/${ID_PLACEHOLDER}/resume`,
    archiveUrl: `${BASIC_URL}/${ID_PLACEHOLDER}/archive`,
    cloneUrl: `${BASIC_URL}/${ID_PLACEHOLDER}/clone`,
    renameUrl: `${BASIC_URL}/${ID_PLACEHOLDER}/rename`,
    filterUrl: `${BASIC_URL}/filter`,
  };
  Object.entries(urls).forEach(([key, value]) => {
    urls[key] = value.replace(ID_PLACEHOLDER, campaignId);
  });
  return urls;
}

export async function createTriggerCampaignApi(
  campaignName: string,
  campaignContext: CAMPAIGN_TARGET
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints().basicUrl,
    method: "POST",
    data: {
      campaignName: campaignName,
      currentState: TRIGGER_CAMPAIGN_STATES.DRAFT,
      campaignTarget: campaignContext,
      campaignType: JOURNEY_TYPES.TRIGGER,
    },
    shouldRetryApiCall: true,
  });
}

export async function getTriggerCampaignApi(
  campaignId: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).basicUrlWithId,
    method: "GET",
    shouldRetryApiCall: true,
  });
}

export async function updateTriggerCampaignApi(
  campaignId: string,
  data: UpdatableDataWithTriggerStatus
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).basicUrlWithId,
    method: "PUT",
    data: data,
    shouldRetryApiCall: true,
  });
}

export async function deleteTriggerCampaignApi(
  campaignId: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).basicUrlWithId,
    method: "DELETE",
    shouldRetryApiCall: true,
  });
}

export async function pauseTriggerCampaignApi(
  campaignId: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).pauseUrl,
    method: "POST",
    shouldRetryApiCall: true,
  });
}

export async function resumeTriggerCampaignApi(
  campaignId: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).resumeUrl,
    method: "POST",
    shouldRetryApiCall: true,
  });
}

export async function archiveTriggerCampaignApi(
  campaignId: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).archiveUrl,
    method: "POST",
    shouldRetryApiCall: true,
  });
}

export async function cloneTriggerCampaignApi(
  campaignId: string,
  campaignName: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).cloneUrl,
    method: "POST",
    data: {
      campaignName,
    },
  });
}

export async function renameTriggerCampaignApi(
  campaignId: string,
  campaignName: string
): Promise<ApiResponseWithMeta<TriggerCampaign>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints(campaignId).renameUrl,
    method: "POST",
    data: {
      campaignName,
    },
  });
}

export async function listTriggerCampaignApi(
  params: ListFilterParams
): Promise<ApiResponseWithPaginationMeta<TriggerCampaign[]>> {
  return realtimeCampaignRequest({
    url: getTriggerApiEndpoints().filterUrl,
    params: params,
    method: "POST",
    shouldRetryApiCall: true,
  });
}
