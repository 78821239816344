import { Flex, HStack, Icon, Kbd, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaDownload, FaExclamationTriangle } from "react-icons/fa";
import { VERSION_MISMATCH_BANNER_ID } from "../common/constants";
import { HAS_REFRESHED } from "../common/constants/common";
import Banner from "./Banner";
import IButton from "./IButton";

export default function VersionMismatchBanner() {
  const [hasRefreshed, setHasRefreshed] = useState(false);

  useEffect(() => {
    const hasRefreshed = sessionStorage.getItem(HAS_REFRESHED);
    setHasRefreshed(hasRefreshed === "true");
  }, []);

  function updateNow() {
    sessionStorage.setItem(HAS_REFRESHED, "true");
    window.location.reload();
  }

  function isMac() {
    const navApp = navigator.userAgent.toLowerCase();
    return navApp.indexOf("mac") !== -1;
  }

  return (
    <Flex id={VERSION_MISMATCH_BANNER_ID} display="none">
      {hasRefreshed ? (
        <Banner bg="red.200" py="3">
          <HStack>
            <Icon as={FaExclamationTriangle}></Icon>
            <Text fontSize="sm" fontWeight="semibold">
              Your browser couldn't load the latest version of Inflection.io.
              Please hit
            </Text>
            <HStack spacing="1">
              <Kbd>{isMac() ? "Command" : "Control"} </Kbd>
              <Text>+</Text> <Kbd>Shift</Kbd> <Text>+</Text>
              <Kbd>R</Kbd>
            </HStack>
            <Text fontSize="sm" fontWeight="semibold">
              to force load the latest version.
            </Text>
          </HStack>
        </Banner>
      ) : (
        <Banner bg="yellow.100" py="3">
          <Text fontSize="sm" fontWeight="semibold">
            A newer version of Inflection.io is available. Refresh your page to
            load the latest version
          </Text>
          <IButton
            textDecoration="underline"
            rightIcon={<FaDownload fontSize="12px" />}
            variant="link"
            color="yellow.600"
            fontWeight="semibold"
            fontSize="sm"
            onClick={updateNow}
          >
            Update Now!
          </IButton>
        </Banner>
      )}
    </Flex>
  );
}
