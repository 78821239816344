import { Text, Box, Badge } from "@chakra-ui/react";
import { Flex, VStack } from "@chakra-ui/layout";
import {
  DynamicListType,
  CONNECTOR,
  DynamicListChild,
  CAMPAIGN_CONTEXT,
} from "../../common/types/campaign";
import { FILTER_TYPE } from "../../common/constants/campaign";
import {
  createGroupId,
  getPersonLabel,
} from "../../common/helper/commonHelper";
import {
  findFirstGroupIndex,
  isTypeGroup,
} from "../../common/helper/dynamicListHelper";

const FILTERS_WITH_PROPERTY_GROUP = [
  FILTER_TYPE.PRODUCT_ACTIVITY,
  FILTER_TYPE.WEBSITE_ACTIVITY,
];

function onNavigation(id: string) {
  let myElement = document.getElementById(id);
  if (myElement) {
    myElement.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
    myElement.style.animationName = "shake";
    myElement.style.animationDuration = "0.7s";
    myElement.style.animationIterationCount = "1";
    myElement.style.animationDelay = "0.5s";
    setTimeout(() => {
      let myElement = document.getElementById(id);
      if (myElement) {
        myElement.style.animationName = "";
      }
    }, 3000);
  }
}

function LogicGateButton({ connector }: { connector: CONNECTOR }) {
  const isAnd = connector === CONNECTOR.AND;
  return (
    <Box
      width="40px"
      textAlign="center"
      bg="gray.100"
      color={isAnd ? "brand.blue" : "green"}
      borderRadius="4px"
      px="1"
      boxShadow="sm"
      mb={2}
    >
      <Text fontWeight="bold" fontSize="12px">
        {isAnd ? "AND" : "OR"}
      </Text>
    </Box>
  );
}

function PropertyGroup({
  label,
  id,
  connector,
  showConnector,
}: {
  label: string;
  id: string;
  connector: CONNECTOR;
  showConnector?: boolean;
}) {
  return (
    <Box w="100%">
      <Box px="2" hidden={!showConnector}>
        <LogicGateButton connector={connector} />
      </Box>

      <Box px="2" w="100%">
        <Badge
          fontSize="10px"
          bgColor="black"
          color="white"
          cursor="pointer"
          onClick={() => onNavigation(id)}
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {label}
        </Badge>
      </Box>
    </Box>
  );
}

function FilterGroup({
  label,
  id,
  data,
}: {
  label: string;
  id: string;
  data: DynamicListChild;
}) {
  const latestIndex = findFirstGroupIndex(data.children);
  const filterType = data.children[0]?.filter_type ?? "";
  return (
    <Box
      rounded="lg"
      borderColor="gray.200"
      borderWidth="1px"
      w="100%"
      shadow="sm"
    >
      <Flex
        roundedTop="lg"
        alignItems="center"
        p="2"
        w="100%"
        bg="gray.100"
        borderBottomWidth="1px"
        borderColor="gray.200"
      >
        <Badge
          fontSize="10px"
          colorScheme="teal"
          cursor="pointer"
          onClick={() => onNavigation(id)}
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="solid"
        >
          {label}
        </Badge>
      </Flex>
      {filterType && FILTERS_WITH_PROPERTY_GROUP.includes(filterType) && (
        <VStack width="100%" alignItems="flex-start">
          {data.children.map((item, index) => {
            if (isTypeGroup(item.type)) {
              switch (filterType) {
                case FILTER_TYPE.WEBSITE_ACTIVITY:
                  return (
                    <>
                      {item.children?.length &&
                        item.children?.map((propertyGroup, ind) => {
                          const propertyGroupId = createGroupId(
                            id,
                            "property",
                            ind + 1
                          );
                          return (
                            <PropertyGroup
                              id={propertyGroupId}
                              key={propertyGroupId}
                              label={
                                propertyGroup.name ||
                                `Property group ${ind + 1}`
                              }
                              connector={propertyGroup.connector}
                              showConnector={ind > 0}
                            />
                          );
                        })}
                    </>
                  );
                default:
                  const propertyGroupId = createGroupId(
                    id,
                    "property",
                    index - (latestIndex || 0) + 1
                  );
                  return (
                    <PropertyGroup
                      id={propertyGroupId}
                      key={propertyGroupId}
                      label={
                        item.name ||
                        `Property group 
                  ${index - (latestIndex || 0) + 1}`
                      }
                      connector={item.connector}
                      showConnector={latestIndex !== index}
                    />
                  );
              }
            }
            return <></>;
          })}
        </VStack>
      )}
    </Box>
  );
}

function OuterGroup({
  label,
  id,
  data,
}: {
  label: string;
  id: string;
  data: DynamicListType;
}) {
  return (
    <Box
      p="2"
      rounded="lg"
      bg="grayV2.100"
      borderWidth="1px"
      width="100%"
      alignItems="flex-start"
    >
      <Flex
        mb="3"
        roundedTop="lg"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        bg="grayV2.100"
      >
        <Badge
          fontSize="10px"
          colorScheme="blue"
          onClick={() => onNavigation(id)}
          cursor="pointer"
          maxW="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="solid"
        >
          {label}
        </Badge>
      </Flex>
      <VStack alignItems="flex-start">
        {data.children.map((filterGroup, index) => {
          return (
            <Box key={index} w="100%">
              {filterGroup.connector && index !== 0 && (
                <LogicGateButton connector={filterGroup.connector} />
              )}
              <FilterGroup
                id={createGroupId(id, "filter", index + 1)}
                label={
                  filterGroup.name
                    ? filterGroup.name
                    : `Filter group ${index + 1}`
                }
                data={filterGroup}
              />
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
}

export default function DynamicListSideBar({
  dynamicListData,
  campaignContext,
}: {
  dynamicListData: DynamicListType[];
  campaignContext: CAMPAIGN_CONTEXT;
}) {
  return (
    <VStack width="220px" alignItems="flex-start">
      <Text fontSize="sm" mr="3" mb="5" color="blue.900" fontWeight="semibold">
        All {getPersonLabel(campaignContext)}s who have,
      </Text>
      <VStack
        height="calc(100vh - 230px)"
        overflowY="auto"
        alignItems="flex-start"
        width="100%"
        pr="2"
      >
        {!!dynamicListData.length && (
          <>
            {dynamicListData.map((group, index) => {
              return (
                <Box key={index} w="100%">
                  {group.connector && index !== 0 && (
                    <LogicGateButton connector={group.connector} />
                  )}
                  <OuterGroup
                    data={group}
                    label={group.name || `Outer group ${index + 1}`}
                    id={createGroupId("", "outer", index + 1)}
                  />
                </Box>
              );
            })}
          </>
        )}
      </VStack>
    </VStack>
  );
}
