import { Badge, Center } from "@chakra-ui/react";
import { NodeProps } from "reactflow";
import { WIDGET_CONTAINER_CLASS } from "./constants";
import {
  EdgeLabelNodeArgs,
  EDGE_LABEL_VARIANTS,
} from "../../../../../common/types/flow";
import { FlowStepSourceHandle, FlowStepTargetHandle } from "./FlowHandle";
import { useSelector } from "react-redux";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";
import {
  getBranchingOperationLabel,
  getOperatorDetailsForBranching,
} from "./helpers";
import { FLOW_ACTIONS } from "../../../../../common/constants/campaign";
import { isLoading } from "../../../../../common/helper/commonHelper";

export default function EdgeLabel({ data }: NodeProps<EdgeLabelNodeArgs>) {
  const {
    computeBooleanOperators,
    operators,
    computeOperators: { loading: newOperatorLoading },
  } = useSelector(selectDynamicList);

  switch (data.variant) {
    case EDGE_LABEL_VARIANTS.BRANCH_BY_FILTER:
      const { label } = data;
      return (
        <Center w="sm" className={WIDGET_CONTAINER_CLASS}>
          <Badge
            bg="gray.300"
            px={2}
            py={1}
            bgColor="white"
            fontSize="10px"
            border="1px solid"
            borderColor="gray.300"
            color="gray.500"
            maxW="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <FlowStepTargetHandle />
            {label}
            <FlowStepSourceHandle />
          </Badge>
        </Center>
      );
    case EDGE_LABEL_VARIANTS.BRANCH_BY_DATA:
      const { type, operator, value, isDefault } = data;

      const isLoadingOperator =
        type === FLOW_ACTIONS.BRANCH_BY_VALUE
          ? isLoading(operators.loading)
          : isLoading(newOperatorLoading);

      const operatorDetails = getOperatorDetailsForBranching(
        type,
        computeBooleanOperators,
        operators.data,
        operator ?? ""
      );

      let branchingLabel = operatorDetails
        ? getBranchingOperationLabel(
            operatorDetails,
            value,
            type === FLOW_ACTIONS.BRANCH_BY_VALUE
          )
        : "(empty)";

      branchingLabel = isDefault ? "Default" : branchingLabel;

      return (
        <Center w="sm" className={WIDGET_CONTAINER_CLASS}>
          <Badge
            bg="gray.300"
            px={2}
            py={1}
            bgColor="white"
            fontSize="10px"
            border="1px solid"
            borderColor="gray.300"
            color="gray.500"
            maxW="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <FlowStepTargetHandle />
            {isLoadingOperator ? "Loading..." : branchingLabel}
            <FlowStepSourceHandle />
          </Badge>
        </Center>
      );

    default:
      return <></>;
  }
}
