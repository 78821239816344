import { Box, Text, HStack, VStack, Flex, Badge } from "@chakra-ui/react";
import AddTriggerEvents from "./trigger-criteria/components/AddTriggerEvents";
import {
  NodeCriteriaTypes,
  NodeGroup,
  OperatorListV2,
  SequencePatternNode,
} from "../../../common/types/dynamicList";
import ProductEventGroup from "./trigger-criteria/components/ProductEventGroup";
import MarketingEventGroup from "./trigger-criteria/components/MarketingEventGroup";
import { ComparisonNode } from "../../../common/types/dynamicList";
import { EVENT_CATEGORY } from "../../../common/constants/dynamicList";
import { createGroupId } from "../../../common/helper/commonHelper";
import { FILTER_TYPE } from "../../../common/constants/campaign";
import { cloneDeep } from "lodash";
import { TRIGGER_DL_NODE_TYPES } from "../../../common/constants/dynamicList";
import TriggerGroupWrapper from "./trigger-criteria/components/TriggerGroupWrapper";
import { useEffect, createContext, useRef } from "react";
import { TRIGGER_CAMPAIGN_DL_OPERATORS } from "../../../common/constants/dynamicList";
import { useSelector } from "react-redux";
import { selectDynamicList } from "../../../components/dynamic-list/dynamicListSlice";
import { CAMPAIGN_CONTEXT } from "../../../common/types/campaign";
import { getFilterList } from "../../../components/dynamic-list/dynamicListSlice";
import {
  getEventNames,
  getEventKeys,
} from "../../../components/dynamic-list/dynamicListSlice";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { isInit } from "../../../common/helper/commonHelper";
import { fillFirstNodeData } from "../../../common/helper/trigger";
import { PatternGroupBox } from "./trigger-criteria/components/PatternGroupBox";
import { getLogicalOperatorForTrigger } from "./Audience-criteria/helper";
import PersonEventGroup from "./trigger-criteria/components/PersonEventGroup";

const OUTER_GROUP_ID = "exit-criteria-outer-group";

export const TriggerCriteriaContext = createContext({
  operators: TRIGGER_CAMPAIGN_DL_OPERATORS,
  activeErrorCheck: false,
  campaignContext: CAMPAIGN_CONTEXT.PERSON,
});

export default function TriggerCriteriaList({
  isReadOnly,
  data,
  onChange,
  operators = TRIGGER_CAMPAIGN_DL_OPERATORS,
  campaignContext = CAMPAIGN_CONTEXT.PERSON,
  activeErrorCheck,
}: {
  isReadOnly?: boolean;
  data: NodeGroup;
  onChange: (data: NodeGroup) => void;
  operators?: OperatorListV2;
  campaignContext?: CAMPAIGN_CONTEXT;
  activeErrorCheck: boolean;
}) {
  const dispatch = useDispatch();
  const hasOnceRun = useRef(false);

  const { productEventKeysList, productEventNamesList, filterList } =
    useSelector(selectDynamicList);

  useEffect(() => {
    if (data && !hasOnceRun.current) {
      // Fetch product_event_properties list for all product_event_names in the campaign dynamiclist get
      // create an array of event names in the product activity filter nodeGroup
      const eventNamesArray: string[][] = [data].map((nodeGroup) => {
        const eventNames: string[] = nodeGroup.nodes.map((nodeData) => {
          if (nodeData.nodeType === TRIGGER_DL_NODE_TYPES.COMPARISON) {
            return (nodeData as ComparisonNode).eventName ?? "";
          }
          return "";
        });
        return eventNames.filter((x) => x);
      });

      const eventKeysAlreadyFetched = Object.keys(
        productEventKeysList.data || {}
      );
      const eventKeysToBeFetched = eventNamesArray
        .flat()
        .filter((event) => !eventKeysAlreadyFetched.includes(event));

      if (!isEmpty(eventKeysToBeFetched)) {
        dispatch(
          getEventKeys({
            max_result: 1000,
            entity: FILTER_TYPE.PERSON,
            event_names: eventKeysToBeFetched,
          })
        );
      }
      hasOnceRun.current = true;
    }
  }, [data, dispatch, productEventKeysList.data]);

  useEffect(() => {
    if (!productEventNamesList.data && isInit(productEventNamesList.loading)) {
      const data = {
        max_result: 10000,
        entity: FILTER_TYPE.PERSON,
      };
      dispatch(getEventNames(data));
    }
  }, [dispatch, productEventNamesList]);

  useEffect(() => {
    const personFilterList = filterList[campaignContext]?.[FILTER_TYPE.PERSON];
    const productActivitiesFilterList =
      filterList[campaignContext]?.[FILTER_TYPE.PRODUCT_ACTIVITY];
    if (!personFilterList || isInit(personFilterList.loading)) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PERSON,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
    if (
      !productActivitiesFilterList ||
      isInit(productActivitiesFilterList.loading)
    ) {
      dispatch(
        getFilterList({
          filterType: FILTER_TYPE.PRODUCT_ACTIVITY,
          campaignContext: campaignContext ?? CAMPAIGN_CONTEXT.PERSON,
        })
      );
    }
  }, [dispatch, filterList, campaignContext]);

  function onAddFilterGroup(
    nodeType: TRIGGER_DL_NODE_TYPES,
    event?: EVENT_CATEGORY
  ) {
    const dataCopy = cloneDeep(data);
    let newFilterGroup = fillFirstNodeData(nodeType, event);
    newFilterGroup = {
      ...newFilterGroup,
      logicalOperator: getLogicalOperatorForTrigger(data.nodes.length),
    };
    dataCopy.nodes.push(newFilterGroup);
    onChange(dataCopy);
  }

  function onRemoveFilterGroup(index: number) {
    const dataCopy = cloneDeep(data);
    dataCopy.nodes = dataCopy.nodes.filter((_, i) => i !== index);
    onChange(dataCopy);
  }

  function updateFilterGroup(index: number, newData: ComparisonNode) {
    const dataCopy = cloneDeep(data);
    dataCopy.nodes[index] = newData;
    onChange(dataCopy);
  }

  function renderFilterGroup(filterGroup: NodeCriteriaTypes, index: number) {
    switch (filterGroup.nodeType) {
      case TRIGGER_DL_NODE_TYPES.COMPARISON:
        return renderComparisonGroup(filterGroup as ComparisonNode, index);
      case TRIGGER_DL_NODE_TYPES.SEQUENCE_PATTERN:
        return renderPatternGroup(filterGroup as SequencePatternNode, index);
      case TRIGGER_DL_NODE_TYPES.PERSON:
        return renderPersonGroup(filterGroup as ComparisonNode, index);
    }
  }

  function renderPatternGroup(filterGroup: SequencePatternNode, index: number) {
    return (
      <PatternGroupBox
        data={data}
        node={filterGroup}
        index={index}
        isReadOnly={isReadOnly}
        onChange={onChange}
      />
    );
  }

  function renderPersonGroup(filterGroup: ComparisonNode, index: number) {
    return (
      <TriggerGroupWrapper
        isReadOnly={isReadOnly}
        type={TRIGGER_DL_NODE_TYPES.PERSON}
        nodeType={filterGroup.nodeType}
        index={index}
      >
        <PersonEventGroup
          data={filterGroup}
          id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
          onChange={(data) => updateFilterGroup(index, data)}
          onRemove={() => onRemoveFilterGroup(index)}
          isReadOnly={isReadOnly}
        />
      </TriggerGroupWrapper>
    );
  }

  function renderComparisonGroup(filterGroup: ComparisonNode, index: number) {
    if (!filterGroup?.eventCategory) {
      return <></>;
    }
    switch (filterGroup.eventCategory) {
      case EVENT_CATEGORY.PRODUCT_ACTIVITY:
        return (
          <TriggerGroupWrapper
            isReadOnly={isReadOnly}
            type={filterGroup.eventCategory}
            nodeType={filterGroup.nodeType}
            index={index}
          >
            <ProductEventGroup
              data={filterGroup}
              id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
              onChange={(data) => updateFilterGroup(index, data)}
              onRemove={() => onRemoveFilterGroup(index)}
              isReadOnly={isReadOnly}
            />
          </TriggerGroupWrapper>
        );
      case EVENT_CATEGORY.MARKETING_ACTIVITY:
        return (
          <TriggerGroupWrapper
            isReadOnly={isReadOnly}
            type={filterGroup.eventCategory}
            nodeType={filterGroup.nodeType}
            index={index}
          >
            <MarketingEventGroup
              data={filterGroup}
              id={createGroupId(OUTER_GROUP_ID, "filter", index + 1)}
              onChange={(data) => updateFilterGroup(index, data)}
              onRemove={() => onRemoveFilterGroup(index)}
              isReadOnly={isReadOnly}
            />
          </TriggerGroupWrapper>
        );
      default:
        throw new Error("Invalid Filter Type");
    }
  }
  return (
    <TriggerCriteriaContext.Provider
      value={{ operators, activeErrorCheck: activeErrorCheck, campaignContext }}
    >
      {!isReadOnly && (
        <Text fontSize="sm">
          Journey Trigger criteria would define which event(s) would cause a
          member to be added to the workflow
        </Text>
      )}
      {!isReadOnly && data?.nodes.length > 0 && (
        <Text fontSize="12px" marginTop={2} color="brand.blue">
          Qualify a contact/product-user for the workflow when <b>any of the</b>{" "}
          following triggers occur:
        </Text>
      )}
      <VStack
        w="100%"
        alignItems="flex-start"
        spacing="2"
        marginTop={isReadOnly ? 2 : 8}
        marginBottom={isReadOnly ? 2 : 0}
      >
        {data.nodes.map((filterGroup, index) => {
          return (
            <>
              <Flex key={index} w="100%">
                {renderFilterGroup(filterGroup, index)}
              </Flex>
              {data.nodes.length > 1 && index !== data.nodes.length - 1 && (
                <Badge
                  fontSize="10px"
                  px={3}
                  my={2}
                  mx={3}
                  borderRadius="4px"
                  colorScheme="blue"
                  variant="solid"
                >
                  Or
                </Badge>
              )}
            </>
          );
        })}
      </VStack>
      {!isReadOnly && (
        <HStack
          justifyContent={data.nodes.length ? "left" : "center"}
          pt={data.nodes.length ? 4 : 20}
        >
          <Box width="40%" justifyContent="center">
            {!data?.nodes.length && (
              <>
                <Text textAlign="center" fontSize="small" color="brand.blue">
                  You can add a single event or a sequence of events as triggers
                </Text>
                <Text textAlign="center" fontSize="small" color="brand.blue">
                  Click the button below to add the first trigger
                </Text>
              </>
            )}
            <Box textAlign={data.nodes.length ? "left" : "center"} mt={4}>
              <AddTriggerEvents
                isNodeAdded={data.nodes.length > 0}
                onClick={onAddFilterGroup}
              />
            </Box>
          </Box>
        </HStack>
      )}
    </TriggerCriteriaContext.Provider>
  );
}
