import {
  Text,
  Grid,
  GridItem,
  Icon,
  HStack,
  Link,
  Box,
} from "@chakra-ui/react";
import { FaAlignLeft, FaExternalLinkAlt } from "react-icons/fa";
import {
  PersonDestination,
  ProductPersonType,
} from "../../../../common/types/person";
import {
  DESTINATION_TYPES,
  INFERRED_TYPES,
} from "../../../../common/types/unifiedMapping";
import { EditableFieldByType } from "../../../../components/EditableFieldByType";
import {
  DESTINATION_TYPE_SPECIFIC_ICONS,
  DESTINATION_TYPE_SPECIFIC_ICONS_FOR_INFERRED,
} from "../../../../common/constants/unifiedMapping";
import {
  getUtcOffsetedTimezone,
  isBlank,
} from "../../../../common/helper/commonHelper";
import { isObject } from "lodash";
import LockIconWithTooltip from "../../../../components/LockIconWithTooltip";
import { READONLY_SF_SYNC_MESSAGE } from "../../../../common/constants/connection";
import { isSalesforcePreference } from "../../../../common/helper/unifiedMappingHelper";
import {
  ALL_TIMEZONES_WITH_ABBREVIATION,
  EMPTY_CONTEXT,
} from "../../../../common/constants/common";
import DropdownWithSearch from "../../../../components/DropdownWithSearch";

const ID_FOR_TIMEZONE_FIELD = "timezone"; // id here refers to identifier since name is the only unique field/idenitfier
const TIME_ZONE_OPTIONS = ALL_TIMEZONES_WITH_ABBREVIATION.map((timezone) => ({
  label: getUtcOffsetedTimezone(timezone.timezoneName),
  value: timezone.timezoneName,
}));

function formatValue(details: PersonDestination) {
  if (isBlank(details.value)) {
    return EMPTY_CONTEXT;
  }
  // the only differentiating factor between every field and timezone field currenlty is name, hence handled likewise
  else if (
    details.name === ID_FOR_TIMEZONE_FIELD ||
    (details.name === INFERRED_TYPES.TIMEZONE && details.value !== null)
  ) {
    return getUtcOffsetedTimezone(details.value);
  }
  if (
    (details.type === DESTINATION_TYPES.BOOLEAN ||
      [true, false].includes(details.value)) &&
    details.value !== null
  ) {
    return details.value ? "True" : "False";
  }
  if (details.type === DESTINATION_TYPES.DATETIME && details.value !== null) {
    return new Date(details.value).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  }
  if (
    (details.type === DESTINATION_TYPES.JSON || isObject(details.value)) &&
    details.value !== null
  ) {
    return JSON.stringify(details.value);
  }
  return `${details.value}`;
}

// displays field according to corresponding setting values obtained from BE
function ContactFieldBasedOnSettings({
  itemKey,
  details,
  onDataClick,
  redirectPage,
  isHyperLink,
  onChangeHandler,
  enableEdit,
}: {
  itemKey: string;
  details: PersonDestination;
  onDataClick: ((organisationId: string) => void) | undefined;
  redirectPage: (() => void) | undefined;
  isHyperLink: boolean | undefined;
  onChangeHandler: (key: string, value: any) => void;
  enableEdit: boolean;
}) {
  const isLocked = isSalesforcePreference(details.sync_preference);

  const isFieldEditable =
    enableEdit && !isLocked && (!details.read_only || details.updatable);
  const isValueEmpty = isBlank(details.value);
  const formattedDetails = formatValue(details);

  const textStyle =
    !isBlank(details.value) && onDataClick
      ? {
          style: { textDecoration: "underline" },
          _hover: { color: "blue.400" },
        }
      : {};

  if (isFieldEditable && details.name === ID_FOR_TIMEZONE_FIELD) {
    return (
      <DropdownWithSearch
        isSearchable
        options={TIME_ZONE_OPTIONS}
        value={TIME_ZONE_OPTIONS.find(
          (option) => option.value === details.value
        )}
        onChange={(option) => {
          onChangeHandler(itemKey, option?.value);
        }}
        controlStyle={{
          width: "100%",
        }}
      />
    );
  } else if (isFieldEditable) {
    return (
      <EditableFieldByType
        type={details.type}
        value={details.value}
        maxlength={details.length ?? undefined}
        onChange={(value: any) => onChangeHandler(itemKey, value)}
      />
    );
  } else if (isHyperLink) {
    return (
      <HStack pl="4">
        <Link target="_blank" onClick={redirectPage} color="blue.600">
          <HStack alignItems="center" spacing="1">
            {isLocked && (
              <LockIconWithTooltip message={READONLY_SF_SYNC_MESSAGE} />
            )}
            <Text ml="1px">{formattedDetails}</Text>
            <Icon fontSize="19px" pt="1px" pl="6px">
              <FaExternalLinkAlt />
            </Icon>
          </HStack>
        </Link>
      </HStack>
    );
  } else {
    return (
      <HStack alignItems="center" spacing="1">
        {isLocked && (
          <Box>
            <LockIconWithTooltip message={READONLY_SF_SYNC_MESSAGE} />
          </Box>
        )}
        <Text
          fontWeight="500"
          wordBreak="break-word"
          alignItems="center"
          color={isValueEmpty ? "gray.500" : "brand.black"}
          onClick={() => onDataClick && onDataClick(formattedDetails)}
          cursor={onDataClick ? "pointer" : "default"}
          {...textStyle}
        >
          {formattedDetails}
        </Text>
      </HStack>
    );
  }
}

export default function DisplayGrid({
  entries,
  editDetails,
  enableEdit,
}: {
  entries: ProductPersonType[];
  editDetails?: (key: string, value: string) => void;
  enableEdit?: boolean;
}) {
  function onChangeHandler(key: string, value: any) {
    editDetails && editDetails(key, value);
  }

  function showAssociatedIcon(details: PersonDestination) {
    // if inferred is true, then use mapping for icons for inferred types, since destination type specific icons are based on the type of the field
    // which is string for inferred timezone as well, hence handles separately based on the name of the field

    if (!details.inferred) {
      if (DESTINATION_TYPE_SPECIFIC_ICONS[details.type]) {
        return DESTINATION_TYPE_SPECIFIC_ICONS[details.type];
      } else {
        return FaAlignLeft;
      }
    } else {
      if (
        DESTINATION_TYPE_SPECIFIC_ICONS_FOR_INFERRED[
          details.name as INFERRED_TYPES
        ]
      ) {
        return DESTINATION_TYPE_SPECIFIC_ICONS_FOR_INFERRED[
          details.name as INFERRED_TYPES
        ];
      } else {
        return FaAlignLeft;
      }
    }
  }

  return (
    <Grid templateColumns={`repeat(2, 1fr)`} my={2} gridColumnGap={8}>
      {entries &&
        entries.map(
          ({ details, key, onDataClick, isHyperLink, redirectPage }, index) => {
            return (
              <GridItem key={key} colSpan={1}>
                <Grid
                  templateColumns={`repeat(5, 1fr)`}
                  my={3}
                  alignItems="top"
                >
                  <GridItem colSpan={2}>
                    <HStack
                      justifyContent="flex-start"
                      alignItems="top"
                      wordBreak="break-word"
                      color={"blackAlpha.600"}
                    >
                      <Icon
                        fontSize="14"
                        as={showAssociatedIcon(details)}
                        mt="5px"
                      />
                      <Text fontWeight="500" textTransform="capitalize" pr={2}>
                        {details.display ?? key}
                      </Text>
                    </HStack>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <ContactFieldBasedOnSettings
                      itemKey={key}
                      details={details}
                      onDataClick={onDataClick}
                      redirectPage={redirectPage}
                      isHyperLink={isHyperLink}
                      onChangeHandler={onChangeHandler}
                      enableEdit={!!enableEdit}
                    />
                  </GridItem>
                </Grid>
              </GridItem>
            );
          }
        )}
    </Grid>
  );
}
