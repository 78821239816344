import { Box, HStack, VStack, Stack, Divider } from "@chakra-ui/layout";
import { Icon, Text } from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { FaRegIdCard } from "react-icons/fa";
import {
  ComparisonNode,
  Condition,
  TargetValueTypes,
} from "../../../../../common/types/dynamicList";
import {
  COMPARISON_OPERATORS,
  DL_TARGET_VALUE_TYPES,
  OPERATOR_ARG_COUNT,
  TARGET_VALUE_TYPE_MAP,
} from "../../../../../common/constants/dynamicList";
import {
  FILTER_TYPE,
  FILTER_TABLE_NAME,
} from "../../../../../common/constants/campaign";
import { TableList, OperatorType } from "../../../../../common/types/campaign";
import { ValueSelectFields } from "../../../../../components/dynamic-list/DynamicListValueFields";
import { selectDynamicList } from "../../../../../components/dynamic-list/dynamicListSlice";
import { FilterGroupBox } from "./FilterGroupBox";
import { RxDragHandleDots2 } from "react-icons/rx";
import { TriggerCriteriaContext } from "../../TriggerCriteriaList";
import AudienceCriteriaValueFields from "../../Audience-criteria/components/AudienceCriteriaValueFields";
import RemoveRowCloseButton from "../../../../../components/RemoveRowCloseButton";

enum Fields {
  LOGICAL_OPERATOR = "logical_operator",
  PROPERTY_NAME = "property_name",
  OPERATOR = "operator",
  VALUE = "value",
}

function getFirstConditionGroup(data: ComparisonNode) {
  return data.conditionGroups.length ? data.conditionGroups[0] : null;
}

type onConditionChangeType = (
  field: Fields,
  value: TargetValueTypes | COMPARISON_OPERATORS | string,
  valueType?: keyof typeof TARGET_VALUE_TYPE_MAP
) => void;

function PersonUpdateCondition({
  currentOperator,
  targetValue = null,
  fieldType,
  onChange,
  isReadOnly,
}: {
  currentOperator?: COMPARISON_OPERATORS;
  targetValue?: string | null;
  fieldType: OperatorType;
  onChange: (data: [string, string | null]) => void;
  isReadOnly: boolean;
}) {
  const { operators, activeErrorCheck } = useContext(TriggerCriteriaContext);

  function onConditionChange(
    field: Fields.OPERATOR | Fields.VALUE,
    value: COMPARISON_OPERATORS | TargetValueTypes,
    valueType?: DL_TARGET_VALUE_TYPES
  ) {
    let newData: [string, string | null] = [currentOperator ?? "", targetValue];
    switch (field) {
      case Fields.OPERATOR:
        if (currentOperator !== value) {
          newData[0] = value as COMPARISON_OPERATORS;
          //todo: separate component for boolean type
          if (valueType && valueType === DL_TARGET_VALUE_TYPES.BOOLEAN) {
            switch (value) {
              case COMPARISON_OPERATORS.CONTAINS:
                newData[1] = "true";
                break;
              case COMPARISON_OPERATORS.EQUALS:
                newData[1] = "false";
                break;
            }
          } else {
            newData[1] = null;
          }
        }
        break;
      case Fields.VALUE:
        newData[1] = value?.[0] ?? "";
        break;
    }
    onChange(newData);
  }

  const getOperatorList = useCallback(
    (colType: OperatorType) => {
      return operators?.[colType];
    },
    [operators]
  );

  const operatorList = useMemo(
    () => getOperatorList(fieldType),
    [getOperatorList, fieldType]
  );

  const operatorDetails = useMemo(
    () =>
      currentOperator && operatorList ? operatorList[currentOperator] : null,
    [currentOperator, operatorList]
  );

  function setOperator(value: COMPARISON_OPERATORS) {
    onConditionChange(Fields.OPERATOR, value, operatorList?.[value]?.valueType);
  }

  function setValue(value: TargetValueTypes) {
    onConditionChange(Fields.VALUE, value);
  }

  function filterOperatorsWithArguments(operators: typeof operatorList) {
    const allowedArgCounts = [OPERATOR_ARG_COUNT.ONE, OPERATOR_ARG_COUNT.ZERO];
    return Object.values(operators)
      .filter((op) =>
        allowedArgCounts.includes(op.arguments as OPERATOR_ARG_COUNT)
      )
      .map((op) => ({
        label: op.display,
        value: op.id,
      }));
  }

  return (
    <HStack>
      {operatorList && (
        <Box>
          <ValueSelectFields
            options={filterOperatorsWithArguments(operatorList)}
            value={currentOperator || null}
            onChange={(op) => setOperator(op as COMPARISON_OPERATORS)}
            validationError={
              !currentOperator && activeErrorCheck ? "Invalid operator" : ""
            }
            isReadOnly={isReadOnly}
          />
        </Box>
      )}
      {currentOperator && operatorDetails && (
        <HStack>
          <AudienceCriteriaValueFields
            value={targetValue ? [targetValue] : []}
            onChange={(val) => setValue(val)}
            argumentTypes={operatorDetails.arguments_types}
            helperText={operatorDetails.display_2}
            isReadOnly={isReadOnly}
            noOfArguments={operatorDetails.arguments}
            activeErrorCheck={activeErrorCheck}
          />
        </HStack>
      )}
    </HStack>
  );
}

function PersonUpdateConditionConditions({
  targetValue,
  fieldType,
  onChange,
  isReadOnly,
  comparisonOperator,
}: {
  targetValue: (string | null)[];
  fieldType: OperatorType;
  onChange: (data: (string | null)[]) => void;
  isReadOnly: boolean;
  comparisonOperator:
    | COMPARISON_OPERATORS.CHANGES_FROM_TO
    | COMPARISON_OPERATORS.CHANGES_TO;
}) {
  const isChangesToOperator = useMemo(
    () => comparisonOperator === COMPARISON_OPERATORS.CHANGES_TO,
    [comparisonOperator]
  );

  const fromValue = useMemo(() => targetValue[1], [targetValue]);
  const fromOperator = useMemo(
    () => targetValue[0] as COMPARISON_OPERATORS | undefined,
    [targetValue]
  );

  const toValue = useMemo(() => {
    return isChangesToOperator ? targetValue[1] : targetValue[3];
  }, [isChangesToOperator, targetValue]);
  const toOperator = useMemo(() => {
    return (isChangesToOperator ? targetValue[0] : targetValue[2]) as
      | COMPARISON_OPERATORS
      | undefined;
  }, [isChangesToOperator, targetValue]);

  function onDataChange(index: number, data: [string, string | null]) {
    const newTargetValue = cloneDeep(targetValue);
    newTargetValue[index] = data[0];
    newTargetValue[index + 1] = data[1];
    onChange(newTargetValue);
  }

  const wrapperStyle = isReadOnly
    ? {
        px: "0",
        py: "0",
        spacing: "1",
        ml: "0px !important",
      }
    : {
        px: "12px",
        ml: "12px !important",
        borderLeftWidth: "1px",
        borderLeftColor: "grayV2.500",
      };

  return (
    <VStack
      alignItems="left"
      {...wrapperStyle}
      mt={!isReadOnly ? "8px !important" : "0px !important"}
    >
      {!isChangesToOperator && (
        <>
          <Text fontSize="12px" color="brand.blue">
            Previous value
          </Text>
          <PersonUpdateCondition
            currentOperator={fromOperator}
            targetValue={fromValue}
            fieldType={fieldType}
            onChange={(data) => onDataChange(0, data)}
            isReadOnly={isReadOnly}
          />
        </>
      )}
      <Text fontSize="12px" color="brand.blue">
        To new value
      </Text>
      <PersonUpdateCondition
        currentOperator={toOperator}
        targetValue={toValue}
        fieldType={fieldType}
        onChange={(data) => onDataChange(isChangesToOperator ? 0 : 2, data)}
        isReadOnly={isReadOnly}
      />
    </VStack>
  );
}

function PersonFilterCondition({
  onConditionChange,
  condition,
  isReadOnly,
  onRemove,
}: {
  onConditionChange: onConditionChangeType;
  condition: Condition;
  isReadOnly?: boolean;
  onRemove: () => void;
}) {
  const { filterList } = useSelector(selectDynamicList);

  const { operators, activeErrorCheck, campaignContext } = useContext(
    TriggerCriteriaContext
  );

  const tableList = useMemo(
    () => filterList[campaignContext]?.[FILTER_TYPE.PERSON],
    [campaignContext, filterList]
  );

  const { propertyName, comparisonOperator, targetValue } = condition;

  const formatTableList = useCallback((list: TableList) => {
    return Object.entries((list as TableList)[FILTER_TABLE_NAME.PERSON] ?? {})
      .filter(([key, value]) => !value.ui_hidden)
      .map(([key, value]) => {
        return { label: value.display_name || key, value: key };
      });
  }, []);

  const contactUpdateOperators = useMemo(() => {
    return operators[OperatorType.CONTACT_UPDATE_SPECIFIC];
  }, [operators]);

  const tableArray: { label: string; value: string }[] = useMemo(
    () => formatTableList(tableList?.data ?? {}),
    [formatTableList, tableList?.data]
  );

  const colType = useMemo(
    () => (propertyName ? findColType(propertyName, tableList?.data) : null),
    [propertyName, tableList]
  );

  function setPropertyName(value: string) {
    onConditionChange(
      Fields.PROPERTY_NAME,
      value,
      findColType(value, tableList?.data) as keyof typeof TARGET_VALUE_TYPE_MAP
    );
  }

  function setOperator(value: COMPARISON_OPERATORS) {
    onConditionChange(Fields.OPERATOR, value);
  }

  function setValue(value: TargetValueTypes) {
    onConditionChange(Fields.VALUE, value);
  }

  function findColType(filter: string, filterList?: TableList): OperatorType {
    return (
      ((filterList as TableList)?.[FILTER_TYPE.PERSON]?.[filter]
        ?.type as OperatorType) ?? undefined
    );
  }

  const wrapperStyle = isReadOnly
    ? {
        spacing: "1",
      }
    : {
        spacing: "2",
        gridGap: "2",
      };

  const isInvalidPropertyName = activeErrorCheck && !propertyName;
  const isInvalidOperator = activeErrorCheck && !comparisonOperator;

  function renderPersonFilterCondition() {
    return (
      <>
        <Box id="person-activity-selector">
          <ValueSelectFields
            options={tableArray}
            value={propertyName || null}
            onChange={setPropertyName}
            validationError={isInvalidPropertyName ? "Invalid property" : ""}
            isReadOnly={isReadOnly}
          />
        </Box>

        {propertyName && colType && (
          <Box>
            <ValueSelectFields
              options={Object.values(contactUpdateOperators).map((op) => ({
                label: op.display,
                value: op.id,
              }))}
              value={comparisonOperator || null}
              onChange={(op) => setOperator(op as COMPARISON_OPERATORS)}
              validationError={isInvalidOperator ? "Invalid operator" : ""}
              isReadOnly={isReadOnly}
            />
          </Box>
        )}
        {!isReadOnly && (
          <RemoveRowCloseButton onClick={onRemove}></RemoveRowCloseButton>
        )}
      </>
    );
  }

  function renderUpdateCondition() {
    return (
      <>
        {propertyName && colType && comparisonOperator && (
          <PersonUpdateConditionConditions
            targetValue={targetValue ?? []}
            fieldType={colType}
            onChange={setValue}
            isReadOnly={!!isReadOnly}
            comparisonOperator={
              comparisonOperator as
                | COMPARISON_OPERATORS.CHANGES_TO
                | COMPARISON_OPERATORS.CHANGES_FROM_TO
            }
          />
        )}
      </>
    );
  }

  if (isReadOnly) {
    return (
      <VStack alignItems="flex-start" spacing="1">
        <HStack width="100%" wrap="wrap" {...wrapperStyle}>
          {renderPersonFilterCondition()}
        </HStack>
        <Stack direction="row" position="relative">
          <Divider
            orientation="vertical"
            h="100%"
            ml="-16px"
            position="absolute"
            borderColor="brandBlue.500"
          ></Divider>
          {renderUpdateCondition()}
        </Stack>
      </VStack>
    );
  } else {
    return (
      <VStack alignItems="flex-start" spacing="1">
        <HStack alignItems="flex-start" wrap="wrap" {...wrapperStyle}>
          <HStack h="33px">
            <Text fontSize="sm">Contact field</Text>
          </HStack>
          {renderPersonFilterCondition()}
        </HStack>
        {renderUpdateCondition()}
      </VStack>
    );
  }
}

const PersonEventGroup = React.memo(
  ({
    data,
    id,
    onChange,
    onRemove,
    isReadOnly,
    isDraggable,
  }: {
    data: ComparisonNode;
    id: string;
    onChange: (data: any) => void;
    onRemove: () => void;
    isReadOnly?: boolean;
    isDraggable?: boolean;
  }) => {
    function onConditionChange(
      field: Fields,
      index: number,
      value: COMPARISON_OPERATORS | TargetValueTypes | string,

      valueType?: keyof typeof TARGET_VALUE_TYPE_MAP
    ) {
      const dataCopy = cloneDeep(data);

      // all filters/conditions are added to first condition group
      const firstConditionGroup = getFirstConditionGroup(dataCopy);
      if (firstConditionGroup) {
        const condition = firstConditionGroup.conditions[index];
        switch (field) {
          case Fields.PROPERTY_NAME:
            if (condition.propertyName !== value && valueType) {
              condition.propertyName = value as string;
              condition.comparisonOperator = null;
              condition.targetValue = [];
              condition.targetValueType = TARGET_VALUE_TYPE_MAP[valueType];
            }
            break;
          case Fields.OPERATOR:
            if (condition.comparisonOperator !== value) {
              condition.comparisonOperator = value as COMPARISON_OPERATORS;
              switch (value) {
                case COMPARISON_OPERATORS.CHANGES_TO:
                  condition.targetValue = [];
                  break;
                case COMPARISON_OPERATORS.CHANGES_FROM_TO:
                  condition.targetValue = [];
                  break;
              }
            }
            break;
          case Fields.VALUE:
            condition.targetValue = value as TargetValueTypes;
            break;
        }
        dataCopy.conditionGroups = [firstConditionGroup];
      }

      onChange(dataCopy);
    }

    const wrapperStyle = isReadOnly
      ? {
          px: "0",
          py: "0",
          spacing: "1",
        }
      : {
          px: "3",
          py: "3",
          spacing: "0",
        };

    return (
      <FilterGroupBox
        id={id}
        onRemove={onRemove}
        icon={FaRegIdCard}
        isReadOnly={isReadOnly}
        rounded="lg"
        borderColor="grayV2.200"
        borderWidth="1px"
        bg="grayV2.200"
        hideRemove={true}
      >
        <HStack>
          {!isReadOnly && isDraggable && (
            <Icon cursor="grab" ml={4} as={RxDragHandleDots2} />
          )}
          <VStack alignItems="flex-start" {...wrapperStyle} w="100%">
            {data.conditionGroups[0].conditions.length &&
              data.conditionGroups[0].conditions.map((condition, index) => {
                return (
                  <Box key={index} width="100%">
                    <PersonFilterCondition
                      condition={condition}
                      onConditionChange={(field, value, valueType) =>
                        onConditionChange(field, index, value, valueType)
                      }
                      onRemove={onRemove}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                );
              })}
          </VStack>
        </HStack>
      </FilterGroupBox>
    );
  }
);

export default PersonEventGroup;
