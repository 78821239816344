import { SALESFORCE_SYNC_STATE } from "../constants/campaign";
import { INCLUSION_LIST_TYPES } from "./campaign";
import { UserSummary, PaginatedList } from "./common";
import { SelectiveSync } from "./unifiedMapping";

export type InclusionList = null | {
  list_id: string;
  list_type: INCLUSION_LIST_TYPES;
};

export interface SalesforceConnection {
  connection_id: string;
  access_token: string | null;
  updated_at: string;
  has_minimum_permission: boolean;
  permissions: Permissions;
  name: string;
  org_jobs_max: number | null;
  org_daily_max: number | null;
  created_at: string;
  is_sync_enabled: boolean;
  created_by: UserSummary;
  updated_by: UserSummary;
  sync_settings: SyncSettings | null;
  object_settings: ObjectSettings | null;
  instance_url: string | null;
  is_sync_active: boolean;
  connection_type: string;
  setup_complete: boolean;
  inclusion_list: InclusionList;
  selective_sync: SelectiveSync;
  // old connections before this setting was introduced won't have these keys below,
  // so will be undefined
  // ----------
  salesforce_record_create: SALESFORCE_RECORD_CREATE | undefined;
  salesforce_record_delete: SALESFORCE_RECORD_DELETE | undefined;
  records_create_settings: Partial<ObjectCreateRule> | undefined;
  records_delete_settings: Partial<ObjectDeleteRule> | undefined;
  // ----------
  last_synced_time: string | undefined;
  next_schedule_time: string | undefined;
}

export interface ObjectSettings {
  lead: boolean;
  account: boolean;
  opportunity: boolean;
  contact: boolean;
}

export interface SyncSettings {
  max_allocated?: number;
  unit?: string;
  frequency?: string;
}

export interface Permissions {
  lead: Permission | null;
  account: Permission | null;
  opportunity: Permission | null;
  contact: Permission | null;
}

export interface Permission {
  full: boolean;
  write: boolean;
  read: boolean;
}

export interface SalesforceSyncRun {
  sync_run_id: string;
  organisation_id: string;
  start_time: string;
  connection_id: string;
  sync_read_count: SyncCount;
  sync_write_count: SyncCount;
  state: SALESFORCE_SYNC_STATE;
}

export interface SyncCount {
  account: SyncCountDetails;
  opportunity: SyncCountDetails;
  lead: SyncCountDetails;
  contact: SyncCountDetails;
}

export interface SyncCountDetails {
  total: number;
  updated: number;
  inserted: number;
  failed: number;
}

export interface ErrorTypes {
  error_types: ErrorType[];
}

export interface ErrorType {
  name: string;
  display: SALESFORCE_SYNC_ERROR_TYPE;
}

export interface SalesforceSyncHistoryDetails {
  total_account_count: number;
  total_lead_count: number;
  total_opportunity_count: number;
  total_contact_count: number;
  total_new_account_count: number;
  total_new_lead_count: number;
  total_new_opportunity_count: number;
  total_new_contact_count: number;
  next_sync_time: string | null;
  last_sync_time: string | null;
}

export enum INFLECTION_RECORD_DELETION {
  DELETE_RECORD = "delete_record",
  DO_NOTHING = "do_nothing",
}

export enum INFLECTION_RECORD_CREATION {
  CREATE_RECORD = "create_record",
  DO_NOTHING = "do_nothing",
}

export enum SALESFORCE_SYNC_ERROR_TYPE {
  OTHERS = "Others",
  PERMISSION = "Permission",
  NONE = "",
}

export type ObjectCreateRule = {
  [key in SALESFORCE_OBJECT_TYPE]: INFLECTION_RECORD_CREATION;
};

export type ObjectDeleteRule = {
  [key in SALESFORCE_OBJECT_TYPE]: INFLECTION_RECORD_DELETION;
};

export interface SalesforceSyncError {
  sync_run_id: string;
  sync_type: string;
  error_class: string;
  count: number;
  error_type: string;
  last_occurred: string;
  first_occurred: string;
  object_type: string;
  error_text: string;
}

export interface SalesforceSyncErrorQuery {
  object_email: string;
  object_type: string;
  _created_time: string;
  salesforce_id: string;
  error_class: string;
  name: string;
  inflection_id: string;

  // used in table but not present in interface
  error_text: string;
}

export interface SfErrors {
  errors: PaginatedList<SalesforceSyncErrorQuery>;
}

export interface SfResyncArchiveDataType {
  errorClassList: string[];
  startTime: string;
  endTime: string;
}

export interface SetupCompleteData {
  connection_id: string;
  automap: boolean;
  start_sync: boolean;
}

export enum SALESFORCE_OBJECT_TYPE {
  CONTACT = "contact",
  LEAD = "lead",
  ACCOUNT = "account",
  OPPORTUNITY = "opportunity",
}

export enum SALESFORCE_RECORD_CREATE {
  CONTACT = "contact",
  LEAD = "lead",
  DO_NOTHING = "do_nothing",
}

export enum SALESFORCE_RECORD_DELETE {
  DELETE_RECORD = "delete_record",
  DO_NOTHING = "do_nothing",
}
