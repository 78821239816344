import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  LoadingWithData,
  PaginationType,
  SearchAssetWithPage,
} from "../../../common/types/common";
import {
  FORM_VENDORS,
  FormDetailsSummary,
  FormSummary,
  FormSubmissionDetails,
  MappingItems,
  FormProviderDetails,
  GetFormSubmissionStats,
  ProviderDetails,
  FormSubmissionStats,
  FormError,
} from "../../../common/types/form";
import { initializeLoadingData } from "../../../common/helper/commonHelper";
import {
  INITIAL_PAGINATION,
  LOADING_STATES,
} from "../../../common/constants/common";
import {
  formsUpdateMappingApi,
  formsAddIntegrationApi,
  getFormDetailsApi,
  getFormSubmissionsApi,
  listAllFormsApi,
  listFormsApi,
  listFormProvidersApi,
  getFormSubmissionStatsApi,
  updateFormDetailsApi,
  getFormErrorsApi,
  getFormConnectionErrorsApi,
  getFormSubmissionDetailsApi,
  updateFormDescriptionApi,
} from "../../../common/api/integrations/form";
import { toast } from "react-toastify";
import { deactivateIntegrationsApiKeyApi } from "../../../common/api/integrations/generic";
import { STATUS } from "../../../common/constants/AccountContactMapping";
import { STATUS as VENDOR_STATUS } from "../../../common/types/unifiedMapping";
import { CONNECTION_LIST_ALL_ACTION } from "../connection/connectionSlice";
import { expireApiStatus } from "../../../common/slices/apiStatusSlice";
import { isAfter, sub } from "date-fns";

interface FormsInitState {
  formsList: PaginationType<FormDetailsSummary>;
  allFormsSummary: LoadingWithData<FormSummary[]>;
  formDetails: LoadingWithData<FormDetailsSummary>;
  formErrors: PaginationType<FormError>;
  formErrors30days: LoadingWithData<FormError[]>;
  formProviderErrors: PaginationType<FormError>;
  formSubmissionDetail: LoadingWithData<FormSubmissionDetails>;
  formSubmissionStat: LoadingWithData<FormSubmissionStats>;
  formSubmissionList: PaginationType<FormSubmissionDetails>;
  formProviderDetails: LoadingWithData<
    FormProviderDetails & { shared_key: string }
  >;
  providersList: LoadingWithData<ProviderDetails[]>;
  disconnectingProvider: LOADING_STATES;
  vendorStatus: { [key in FORM_VENDORS]: VENDOR_STATUS } | null;
}

const initialState: FormsInitState = {
  formsList: INITIAL_PAGINATION,
  allFormsSummary: initializeLoadingData([]),
  formDetails: initializeLoadingData({}),
  formErrors: INITIAL_PAGINATION,
  formErrors30days: initializeLoadingData([]),
  formProviderErrors: INITIAL_PAGINATION,
  formSubmissionDetail: initializeLoadingData({}),
  formSubmissionStat: initializeLoadingData({}),
  formSubmissionList: INITIAL_PAGINATION,
  formProviderDetails: initializeLoadingData({}),
  providersList: initializeLoadingData([]),
  disconnectingProvider: LOADING_STATES.INIT,
  vendorStatus: null,
};

export const listAllForms = createAsyncThunk(
  "form/list.all",
  async (vendor?: FORM_VENDORS) => {
    return await listAllFormsApi(vendor);
  }
);

export const listForms = createAsyncThunk(
  "form/list",
  async ({ pageNo, searchKeyword, filters }: SearchAssetWithPage, thunkApi) => {
    const {
      form: {
        formsList: { pageSize },
      },
    } = thunkApi.getState() as RootState;
    return await listFormsApi({
      pageNo,
      pageSize,
      searchKeyword,
      filters,
    });
  }
);

export const getFormDetails = createAsyncThunk(
  "form/get",
  async (formId: string) => {
    return await getFormDetailsApi(formId);
  }
);

export const getFormSubmissionDetails = createAsyncThunk(
  "form/get-submission-details",
  async (submissionId: string) => {
    return await getFormSubmissionDetailsApi(submissionId);
  }
);

export const updateFormDetails = createAsyncThunk(
  "form/update",
  async ({ formId, name }: { formId: string; name: string }) => {
    return await updateFormDetailsApi(formId, name);
  }
);

export const updateFormDescription = createAsyncThunk(
  "form/update",
  async ({ formId, description }: { formId: string; description: string }) => {
    return await updateFormDescriptionApi(formId, description);
  }
);

export const getFormSubmissions = createAsyncThunk(
  "form/get-submissions",
  async (
    { formId, formType }: { formId: string; formType: FORM_VENDORS },
    thunkApi
  ) => {
    const {
      form: {
        formSubmissionList: { pageSize, currentPageNo },
      },
    } = thunkApi.getState() as RootState;
    return await getFormSubmissionsApi({
      page_number: currentPageNo,
      page_size: pageSize,
      form_id: formId,
      source: formType,
    });
  }
);

export const updateFormMapping = createAsyncThunk(
  "form/update-mapping",
  async ({
    formId,
    emailColumn,
    mapping,
  }: {
    formId: string;
    emailColumn: string;
    mapping: MappingItems[];
  }) => {
    return await formsUpdateMappingApi({
      email_source_field: emailColumn,
      form_id: formId,
      webform_mapping: { mapping: mapping },
    });
  }
);

export const integrateFormsProvider = createAsyncThunk(
  "form/add-provider",
  async (vendor: FORM_VENDORS, { dispatch }) => {
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return await formsAddIntegrationApi({ vendor, vendor_data: {} });
  }
);

export const listFormProviders = createAsyncThunk(
  "form/list-providers",
  async (vendor?: FORM_VENDORS) => {
    return await listFormProvidersApi(vendor);
  }
);

export const formListSubmissionStats = createAsyncThunk(
  "form/submission-stats",
  async (data: GetFormSubmissionStats) => {
    return getFormSubmissionStatsApi(data);
  }
);

export const getSubmissionStatsForSingleForm = createAsyncThunk(
  "form/submission-stats-single-form",
  async ({ source, formId }: { source: FORM_VENDORS; formId: string }) => {
    return getFormSubmissionStatsApi({
      source: source,
      form_ids: [formId],
    });
  }
);

export const disconnectFormsProvider = createAsyncThunk(
  "form/disconnect-provider",
  async (apiKey: string, { dispatch }) => {
    dispatch(
      expireApiStatus([
        { actionName: CONNECTION_LIST_ALL_ACTION, expireAll: true },
      ])
    );
    return await deactivateIntegrationsApiKeyApi(apiKey);
  }
);

export const getFormErrors30days = createAsyncThunk(
  "form/get-form-errors-30-days",
  async ({ source, formId }: { source: FORM_VENDORS; formId: string }) => {
    return getFormErrorsApi({
      source,
      form_id: formId,
    });
  }
);

export const getFormErrors = createAsyncThunk(
  "form/get-form-errors",
  async (
    {
      source,
      formId,
    }: {
      source: FORM_VENDORS;
      formId: string;
    },
    thunkApi
  ) => {
    const {
      form: {
        formErrors: { pageSize, currentPageNo },
      },
    } = thunkApi.getState() as RootState;
    return getFormErrorsApi({
      source,
      form_id: formId,
      page_size: pageSize,
      page_number: currentPageNo,
    });
  }
);

export const getFormProviderErrors = createAsyncThunk(
  "form/get-form-connection-errors",
  async (
    {
      source,
    }: {
      source: FORM_VENDORS;
    },
    thunkApi
  ) => {
    const {
      form: {
        formProviderErrors: { pageSize, currentPageNo },
      },
    } = thunkApi.getState() as RootState;
    return getFormConnectionErrorsApi(source, pageSize, currentPageNo);
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    resetformProviderDetails(state) {
      state.formProviderDetails = initializeLoadingData({});
    },

    setFormSubmissionsPage(state, { payload: pageNo }: { payload: number }) {
      if (pageNo && pageNo <= (state.formSubmissionList.totalPageCount ?? 1)) {
        state.formSubmissionList.currentPageNo = pageNo;
        state.formSubmissionList.changingPage = true;
      }
    },
    setFormErrorsPage(state, { payload: pageNo }: { payload: number }) {
      if (pageNo && pageNo <= (state.formErrors.totalPageCount ?? 1)) {
        state.formErrors.currentPageNo = pageNo;
        state.formErrors.changingPage = true;
      }
    },
    setFormProviderErrorsPage(state, { payload: pageNo }: { payload: number }) {
      if (pageNo && pageNo <= (state.formProviderErrors.totalPageCount ?? 1)) {
        state.formProviderErrors.currentPageNo = pageNo;
        state.formProviderErrors.changingPage = true;
      }
    },
    resetFormData(state) {
      state.formDetails = initializeLoadingData({});
      state.formSubmissionStat = initializeLoadingData({});
      state.formSubmissionList = initialState.formSubmissionList;
      state.formErrors = initialState.formErrors;
      state.formProviderErrors = initialState.formProviderErrors;
      state.formErrors30days = initialState.formErrors30days;
    },
    resetProvidersList(state) {
      state.providersList = initializeLoadingData([]);
    },
    resetFormList(state) {
      state.formsList = INITIAL_PAGINATION;
    },
  },
  extraReducers: (builder) => {
    builder

      //list all forms
      .addCase(listAllForms.pending, (state) => {
        state.allFormsSummary.loading = LOADING_STATES.LOADING;
      })
      .addCase(listAllForms.fulfilled, (state, { payload }) => {
        state.allFormsSummary.data = payload.forms.records;
        state.allFormsSummary.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(listAllForms.rejected, (state) => {
        state.allFormsSummary.loading = LOADING_STATES.FAILED;
      })

      // list forms pagination
      .addCase(listForms.pending, (state, action) => {
        state.formsList.fetchingList = true;
        state.formsList.changingPage = false;
        state.formsList.currentPageNo = action.meta.arg.pageNo;
      })
      .addCase(listForms.fulfilled, (state, { payload: { forms } }) => {
        state.formsList.fetchingList = false;
        state.formsList.list = forms.records.map((form) => {
          return {
            ...form,
            submissions: null,
            submissionStatsLoading: LOADING_STATES.INIT,
          };
        });
        state.formsList.totalPageCount = forms.page_count;
        state.formsList.count = forms.record_count;
      })
      .addCase(listForms.rejected, (state) => {
        state.formsList.fetchingList = false;
      })

      // get form details
      .addCase(getFormDetails.pending, (state) => {
        state.formDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(getFormDetails.fulfilled, (state, { payload }) => {
        state.formDetails.data = payload.form;
        state.formDetails.loading = LOADING_STATES.SUCCESS;
      })
      .addCase(getFormDetails.rejected, (state) => {
        state.formDetails.loading = LOADING_STATES.FAILED;
      })

      // get form submission list
      .addCase(getFormSubmissions.pending, (state) => {
        state.formSubmissionList.fetchingList = true;
      })
      .addCase(getFormSubmissions.fulfilled, (state, { payload }) => {
        state.formSubmissionList.fetchingList = false;
        state.formSubmissionList.changingPage = false;
        state.formSubmissionList.list = payload.submissions.records;
        state.formSubmissionList.totalPageCount =
          payload.submissions.page_count;
        state.formSubmissionList.count = payload.submissions.record_count;
      })
      .addCase(getFormSubmissions.rejected, (state) => {
        state.formSubmissionList.fetchingList = false;
      })

      // add provider
      .addCase(integrateFormsProvider.pending, (state) => {
        state.formProviderDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(integrateFormsProvider.fulfilled, (state, { payload }) => {
        const sharedKey = payload.webhook_url.split("/").pop() ?? "";
        state.formProviderDetails = {
          data: {
            webhook_url: payload.webhook_url,
            shared_key: sharedKey,
          },
          loading: LOADING_STATES.SUCCESS,
        };
        toast.success("Form provider integrated successfully");
      })
      .addCase(integrateFormsProvider.rejected, (state) => {
        state.formProviderDetails.loading = LOADING_STATES.FAILED;
        toast.error("Form provider integration failed");
      })

      //list the providers and their details
      .addCase(listFormProviders.pending, (state) => {
        state.providersList.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        listFormProviders.fulfilled,
        (state, { payload: { integrations } }) => {
          state.providersList.data = integrations;
          state.providersList.loading = LOADING_STATES.SUCCESS;
          state.vendorStatus = Object.assign(
            {},
            ...integrations.map(({ key_type, state }) => ({
              [key_type]: state,
            }))
          );
        }
      )
      .addCase(listFormProviders.rejected, (state) => {
        state.providersList.loading = LOADING_STATES.FAILED;
      })

      //deactivate provider api key to disconnect the provider
      .addCase(disconnectFormsProvider.pending, (state) => {
        state.disconnectingProvider = LOADING_STATES.LOADING;
      })
      .addCase(disconnectFormsProvider.fulfilled, (state, { payload }) => {
        if (payload.api_key.state === STATUS.INACTIVE) {
          state.disconnectingProvider = LOADING_STATES.SUCCESS;
          toast.success("Form provider disconnected successfully");
        } else {
          state.disconnectingProvider = LOADING_STATES.FAILED;
          toast.error("Failed to disconnect Form provider");
        }
      })
      .addCase(disconnectFormsProvider.rejected, (state) => {
        state.disconnectingProvider = LOADING_STATES.FAILED;
        toast.error("Failed to disconnect Form provider");
      })

      //get submission stats
      .addCase(formListSubmissionStats.pending, (state) => {
        state.formsList.list =
          state.formsList.list?.map((form) => {
            return {
              ...form,
              submissions: null,
              submissionStatsLoading: LOADING_STATES.LOADING,
            };
          }) ?? state.formsList.list;
      })
      .addCase(formListSubmissionStats.fulfilled, (state, { payload }) => {
        state.formsList.list =
          state.formsList.list?.map((form) => {
            return {
              ...form,
              submissions: payload.submission_stats[form.form_id].count,
              submissionStatsLoading: LOADING_STATES.SUCCESS,
            };
          }) ?? state.formsList.list;
      })
      .addCase(formListSubmissionStats.rejected, (state) => {
        state.formsList.list =
          state.formsList.list?.map((form) => {
            return {
              ...form,
              submissions: null,
              submissionStatsLoading: LOADING_STATES.FAILED,
            };
          }) ?? state.formsList.list;
      })

      //get submission stat for single form
      .addCase(getSubmissionStatsForSingleForm.pending, (state) => {
        state.formSubmissionStat.loading = LOADING_STATES.LOADING;
      })
      .addCase(
        getSubmissionStatsForSingleForm.fulfilled,
        (state, { payload }) => {
          state.formSubmissionStat.data = payload.submission_stats;
          state.formSubmissionStat.loading = LOADING_STATES.SUCCESS;
        }
      )
      .addCase(getSubmissionStatsForSingleForm.rejected, (state) => {
        state.formSubmissionStat.loading = LOADING_STATES.FAILED;
      })

      //get form provider errors
      .addCase(getFormProviderErrors.pending, (state) => {
        state.formProviderErrors.fetchingList = true;
      })
      .addCase(getFormProviderErrors.fulfilled, (state, { payload }) => {
        state.formProviderErrors.fetchingList = false;
        state.formProviderErrors.changingPage = false;
        state.formProviderErrors.list = payload.submission_stats.records;
        state.formProviderErrors.totalPageCount =
          payload.submission_stats.page_count;
        state.formProviderErrors.count = payload.submission_stats.record_count;
      })
      .addCase(getFormProviderErrors.rejected, (state) => {
        state.formProviderErrors.fetchingList = false;
      })

      //get form errors
      .addCase(getFormErrors.pending, (state) => {
        state.formErrors.fetchingList = true;
      })
      .addCase(getFormErrors.fulfilled, (state, { payload }) => {
        state.formErrors.fetchingList = false;
        state.formErrors.changingPage = false;
        state.formErrors.list = payload.submission_stats.records;
        state.formErrors.totalPageCount = payload.submission_stats.page_count;
        state.formErrors.count = payload.submission_stats.record_count;
      })
      .addCase(getFormErrors.rejected, (state) => {
        state.formErrors.fetchingList = false;
      })

      //get 30 days form errors
      .addCase(getFormErrors30days.pending, (state) => {
        state.formErrors30days.loading = LOADING_STATES.LOADING;
      })
      .addCase(getFormErrors30days.fulfilled, (state, { payload }) => {
        state.formErrors30days.loading = LOADING_STATES.SUCCESS;
        const dateBefore30days = sub(new Date(), { days: 30 });
        state.formErrors30days.data = payload.submission_stats.records.filter(
          (x) => isAfter(new Date(x.received_date), dateBefore30days)
        );
      })
      .addCase(getFormErrors30days.rejected, (state) => {
        state.formErrors30days.loading = LOADING_STATES.FAILED;
      })

      // update form description
      .addCase(updateFormDescription.pending, (state) => {
        state.formDetails.loading = LOADING_STATES.LOADING;
      })
      .addCase(updateFormDescription.fulfilled, (state, { payload, meta }) => {
        state.formDetails.loading = LOADING_STATES.SUCCESS;
        if (payload.success) {
          toast.success("Successfully updated form description");
          state.formDetails.data.description = meta.arg.description;
        } else {
          toast.error("Form description update failed");
        }
      })
      .addCase(updateFormDescription.rejected, (state) => {
        state.formDetails.loading = LOADING_STATES.FAILED;
      });
  },
});

export const selectForm = (state: RootState) => state.form;
export const {
  resetformProviderDetails,
  setFormSubmissionsPage,
  setFormErrorsPage,
  setFormProviderErrorsPage,
  resetFormData,
  resetProvidersList,
  resetFormList,
} = formSlice.actions;
export default formSlice.reducer;
